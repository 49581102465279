import { memo, useEffect } from 'react';
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

import { sxProp } from './common';

export const LinkButton = memo(({ onCaptureLink, disabled }) => {
    const handleInsertLink = () => {
        if (typeof onCaptureLink === 'function') {
            onCaptureLink(true);
        }
    };

    useEffect(() => {
        if (typeof onCaptureLink === 'function') {
            onCaptureLink(false);
        }
    }, [onCaptureLink]);

    return (
        <Box sx={sxProp.flex}>
            <IconButton
                tabIndex={1}
                title="Insert link"
                aria-label="format-link"
                disableFocusRipple
                sx={sxProp.inactiveBtn}
                disabled={disabled}
                onClick={handleInsertLink}
                disableRipple
            >
                <LinkIcon />
            </IconButton>
        </Box>
    );
});

LinkButton.propTypes = {
    onCaptureLink: PropTypes.func,
    disabled: PropTypes.bool,
};
