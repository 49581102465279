import { changeKeywordsFilter, changeStandardFilter } from 'views/Search/searchSlice';

export function deleteEntry(value, array, updateFunc) {
    const index = array.findIndex((entry) => entry === value);
    if (index >= 0) {
        const tmp = [...array];
        tmp.splice(index, 1);
        updateFunc(tmp);
    }
}

export function deleteKeyword(keyword, keywords, dispatch) {
    deleteEntry(keyword, keywords, (array) => {
        dispatch(changeKeywordsFilter(array));
    });
}

export function deleteTextFilter(filter, filters, property, dispatch) {
    deleteEntry(filter, filters, (array) => {
        dispatch(changeStandardFilter({ property, matchText: array }));
    });
}

export function parseAddressComponents(addressComponents) {
    let result = {};
    if (Array.isArray(addressComponents)) {
        result = addressComponents.reduce((current, { long_name, types }) => {
            types.forEach((type) => {
                if (type === 'administrative_area_level_1') {
                    current.state = long_name;
                } else if (type === 'locality') {
                    current.city = long_name;
                }
                current[type] = long_name;
            });
            return current;
        }, {});
    }
    return result;
}

export function getCityStateLabel(location) {
    if (location.city && location.state) {
        return `${location.city}, ${location.state}`;
    }
    if (location.city && !location.state) {
        return location.city;
    }
    return location.state;
}

export const expandAncestors = (code, expandedStates, data) => {
    if (!code) return;
    const parent = data.find((item) => item.code === code);
    expandedStates[parent.code] = true;
    expandAncestors(parent.parentCode, expandedStates, data);
};

export const checkChildren = (code, inputValue, data) => {
    const children = data.filter((item) => item.parentCode === code);
    if (children.length) {
        return children.some((child) => {
            if (child.name.toLowerCase().includes(inputValue) || child.code.includes(inputValue)) {
                return true;
            }
            return checkChildren(child.code, inputValue, data);
        });
    }
    return false;
};

export const filteredOptions = (options, inputValue, expandedStates, data) => {
    return options.filter((option) => {
        if (option.name.toLowerCase().includes(inputValue) || option.code.includes(inputValue)) {
            if (option.parentCode) {
                expandAncestors(option.parentCode, expandedStates, data);
            }
            return true;
        }

        const parent = data.find((item) => item.code === option.parentCode);

        if (
            option.parentCode &&
            (parent.name.toLowerCase().includes(inputValue) || parent.code.includes(inputValue))
        ) {
            expandAncestors(option.parentCode, expandedStates, data);
            return true;
        }

        return checkChildren(option.code, inputValue, data);
    });
};

export const updateParent = (parentCode, childCode, newSelectedCodes, isChecked, data) => {
    const parent = data.find((item) => item.code === parentCode);
    if (!parent) return;

    const siblings = data.filter((item) => item.parentCode === parent.code);
    const allSelected = siblings.every(
        (sibling) =>
            newSelectedCodes.includes(sibling.code) || newSelectedCodes.includes(parent.parentCode)
    );
    const someSelected = siblings.some((sibling) => newSelectedCodes.includes(sibling.code));
    const parentIndex = newSelectedCodes.indexOf(parent.code);

    if (!isChecked) {
        if (parentIndex !== -1) {
            newSelectedCodes.splice(parentIndex, 1);
            siblings.forEach((sibling) => {
                if (sibling.code !== childCode && !newSelectedCodes.includes(sibling.code)) {
                    newSelectedCodes.push(sibling.code);
                }
            });
        }
        if (allSelected && parentIndex === -1) {
            siblings.forEach((sibling) => {
                if (sibling.code !== childCode && !newSelectedCodes.includes(sibling.code)) {
                    newSelectedCodes.push(sibling.code);
                }
            });
        }
    }

    if (isChecked) {
        if (allSelected && parentIndex === -1) {
            newSelectedCodes.push(parent.code); // Add parent if all children are selected

            siblings.forEach((sibling) => {
                const siblingIndex = newSelectedCodes.indexOf(sibling.code);
                if (siblingIndex !== -1) {
                    newSelectedCodes.splice(siblingIndex, 1);
                }
            });
        } else if (someSelected && parentIndex !== -1) {
            newSelectedCodes.splice(parentIndex, 1); // Remove parent if some children are selected
        }
    }

    if (parent.parentCode) {
        updateParent(parent.parentCode, parent.code, newSelectedCodes, isChecked, data);
    }
};

export const updateChildren = (code, newSelectedCodes, data) => {
    const children = data.filter((item) => item.parentCode === code);
    children.forEach((child) => {
        const childIndex = newSelectedCodes.indexOf(child.code);
        if (childIndex !== -1) {
            newSelectedCodes.splice(childIndex, 1);
        }
        updateChildren(child.code, newSelectedCodes, data); // Recursively update children
    });
};

export const updateRelationships = (option, isChecked, newCodes, index, data) => {
    if (isChecked) {
        if (index === -1) {
            newCodes.push(option.code);
        }
    } else {
        if (index !== -1) {
            newCodes.splice(index, 1); // Remove code if unchecked
        }
    }
    updateChildren(option.code, newCodes, data);
    if (option.parentCode) {
        updateParent(option.parentCode, option.code, newCodes, isChecked, data);
    }
};

export const highlightMatch = (text, query) => {
    if (!query) return text;

    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
        regex.test(part) ? (
            <strong data-testid={text} key={index}>
                {part}
            </strong>
        ) : (
            part
        )
    );
};
