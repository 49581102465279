import Box from '@mui/system/Box';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import { styled, lighten } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { Typography } from 'components/Typography';
import { NylasConnectConfirmDialog } from './NylasConnectConfirmDialog';
import { useConnectEmail } from 'hooks/useConnectEmail';

const ConnectButton = styled(Box, { shouldForwardProp: (prop) => prop !== 'isInline' })(
    ({ theme, isInline }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: isInline ? theme.spacing(14) : theme.spacing(12),
        height: theme.spacing(5),
        marginRight: theme.spacing(1.5),
        borderRadius: theme.spacing(0.5),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
        '&:hover': {
            backgroundColor: lighten(theme.palette.success.main, 0.1),
        },
    })
);

export function UserMenuNylasButton(props) {
    const { id, email, inline, caption, CaptionProps, MenuItemProps } = props;

    const { redirectUrl, open, isLoading, handleOpen, handleClose } = useConnectEmail(id, email);

    return (
        <>
            <MenuItem
                aria-label="nylas-link-button"
                component={ButtonBase}
                onClick={handleOpen}
                disabled={!redirectUrl}
                disableRipple
                {...MenuItemProps}
            >
                <ConnectButton isInline={inline} data-trackid="avatar-menu-connect">
                    {isLoading && <CircularProgress size={24} />}
                    {!isLoading && (
                        <Typography variant="titleSmall">{caption || 'Connect'}</Typography>
                    )}
                </ConnectButton>
                <Box sx={{ width: inline ? 296 : 185, display: 'flex', flexDirection: 'column' }}>
                    {!inline && (
                        <Typography sx={{ color: 'error.main' }} variant="titleSmall">
                            Email account not connected
                        </Typography>
                    )}
                    <Typography
                        sx={{ whiteSpace: 'normal' }}
                        variant={inline ? 'bodySmall' : 'titleXSmall'}
                        {...CaptionProps}
                    >
                        Connect to engage with profiles via SMS and email
                    </Typography>
                </Box>
            </MenuItem>
            <NylasConnectConfirmDialog
                open={open}
                onClose={handleClose}
                redirectUrl={redirectUrl}
            />
        </>
    );
}

UserMenuNylasButton.propTypes = {
    id: PropTypes.string,
    inline: PropTypes.bool,
    email: PropTypes.string,
    caption: PropTypes.string,
    CaptionProps: PropTypes.object,
    MenuItemProps: PropTypes.object,
};
