import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Checkbox } from './styledComponents';
import { Typography } from 'components/Typography';

export function FilterLabel(props) {
    const { label, checked, onChange, dataTrackId } = props;
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{label}</div>
            <div>
                <FormControlLabel
                    labelPlacement="end"
                    sx={{ m: 0 }}
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={onChange}
                            disableRipple
                            inputProps={{
                                'data-trackid': dataTrackId,
                            }}
                            sx={{
                                mt: 0,
                                mr: 0.5,
                                '& .MuiSvgIcon-root': {
                                    fontSize: 16,
                                },
                            }}
                        />
                    }
                    disableTypography
                    label={
                        <Typography variant="labelMedium" component="label">
                            Exclude
                        </Typography>
                    }
                />
            </div>
        </Box>
    );
}

FilterLabel.propTypes = {
    label: PropTypes.node,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    dataTrackId: PropTypes.string,
};
