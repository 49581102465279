import { useState, useCallback, useEffect } from 'react';
import produce from 'immer';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { useCreateMediaMutation } from 'services/knowledgeBases';
import { useFileTagAutocomplete } from './useFileTagAutocomplete';
import { ENTITY_TYPE_ENUMS } from 'utils/entityEnums';
import { useMediaPermissions } from './useMediaPermissions';

export function useCreateMedia({
    attachments,
    onClose,
    entityId,
    entityType,
    attachToTalent,
    clearAttachments,
    onRemoveAttachment,
    onSuccess = () => {},
}) {
    const user = useSelector((state) => state.user);
    const { organization } = user;

    const [displayNames, setDisplayNames] = useState({});

    const { organizationAccess, handleChangeAccess, handleChangePermissions } = useMediaPermissions(
        {
            organizationId: organization?.id,
        }
    );

    const [createMedia, result] = useCreateMediaMutation();
    const { data, isSuccess, isError, isLoading, error } = result;

    const {
        multiTagsValue,
        tagOptions,
        handleChange,
        handleInputValue,
        handleInputEnter,
        setMultiTagsValue,
        handleRemoveTag,
    } = useFileTagAutocomplete();

    const handleNameChange = useCallback((event) => {
        setDisplayNames((prev) =>
            produce(prev, (draft) => {
                draft[event.target.dataset.key] = event.target.value;
            })
        );
    }, []);

    const handleRemoveDisplayName = useCallback((fileId) => {
        setDisplayNames((prev) => {
            const updatedDisplayNames = { ...prev };

            if (updatedDisplayNames.hasOwnProperty(fileId)) {
                delete updatedDisplayNames[fileId];
            }

            return updatedDisplayNames;
        });
    }, []);

    const handleCloseModal = useCallback(() => {
        onClose();
        clearAttachments();
        setDisplayNames({});
        setMultiTagsValue({});
    }, [clearAttachments, onClose, setMultiTagsValue]);

    const handleRemoveUploadMedia = useCallback(
        (fileId) => {
            onRemoveAttachment(fileId);
            handleRemoveDisplayName(fileId);
            handleRemoveTag(fileId);
        },
        [handleRemoveDisplayName, handleRemoveTag, onRemoveAttachment]
    );

    const handleAddMedia = () => {
        const payload = attachments.map((attachment) => {
            const mediaData = {
                ...attachment,
                displayName: displayNames[attachment?.handle] || attachment?.filename,
                tags: multiTagsValue[attachment?.handle] || [],
                name: attachment?.filename,
                filestackUrl: attachment?.url,
                contentType: attachment?.mimetype,
                access: {
                    org_access: {
                        [organization.id]: organizationAccess,
                    },
                },
                suggestedTags: undefined,
            };

            if (entityId && entityType && attachToTalent) {
                mediaData['entityConnections'] = [
                    {
                        entityId: entityId,
                        type: ENTITY_TYPE_ENUMS[entityType.toUpperCase()].value,
                    },
                ];
            }
            return mediaData;
        });
        createMedia({
            media: payload,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Successfully created media');
            onSuccess(data?.mediaIds, displayNames);
            handleCloseModal();
        }
        if (isError) {
            toast.error(error?.data?.message || 'Error creating media');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, isError, error]);

    return {
        multiTagsValue,
        displayNames,
        tagOptions,
        handleAddMedia,
        handleNameChange,
        handleTagChange: handleChange,
        handleTagInputValue: handleInputValue,
        handleTagInputEnter: handleInputEnter,
        handleCloseModal,
        handleRemoveUploadMedia,
        isLoading,
        organizationAccess,
        handleChangeAccess,
        handleChangePermissions,
    };
}
