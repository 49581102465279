import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';

import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import { tooltipClasses } from '@mui/material/Tooltip';

import { Typography } from 'components/Typography';
import { ShareIcon } from 'components/Icons';
import { LightTooltip } from 'components/Tooltip';
import { useShareMediaAccess } from 'views/UserSettings/features/YourMedia/components/MediaModals/hooks/useShareMediaAccess';
import { FileIcon } from './FileIcon';
import { ManageMediaModal } from './MediaModals';
import { ATTACHMENT_TYPE } from 'utils/entityEnums';
import { TruncatedText } from 'views/Projects/components/TruncatedText';

const FilenameStyleSx = {
    textOverflow: 'ellipsis',
    fontSize: '12px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0.1px',
    color: (theme) => alpha(theme.palette.text.primary, 0.72),
};

const ShareIconButton = styled(ShareIcon)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
    cursor: 'pointer',
}));

const StyledTooltip = styled(LightTooltip)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: theme.palette.text.primary,
        background: theme.palette.common.white,
    },
    [`& .${tooltipClasses.arrow}::before`]: {
        background: theme.palette.common.white,
    },
}));

export function MediaInfo(props) {
    const {
        attachment,
        showManageMediaModal = true,
        showPermissionTooltip = false,
        onMediaUpdated = () => {},
    } = props;

    const { displayName, name: filename, mediaType, size } = attachment;

    const { canManageMedia, canEditPermissions, showShareIcon } = useShareMediaAccess({
        access: attachment?.access,
        owner: attachment?.owner,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <FileIcon mediaType={mediaType} />
                <Box
                    sx={{
                        ml: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '85%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <StyledTooltip
                            aria-label="name-tooltip"
                            title={
                                showPermissionTooltip && !canManageMedia
                                    ? 'You do not have permissions to manage this file'
                                    : ''
                            }
                            placement="top"
                            arrow
                        >
                            <ButtonBase
                                sx={{ alignSelf: 'start', maxWidth: 'inherit' }}
                                aria-label="media-name"
                                onClick={
                                    showManageMediaModal && canManageMedia ? handleOpen : undefined
                                }
                            >
                                <Typography variant="bodyBase" color="primary" noWrap>
                                    {displayName}
                                </Typography>
                            </ButtonBase>
                        </StyledTooltip>
                        {showShareIcon && showManageMediaModal && (
                            <StyledTooltip
                                title={canEditPermissions ? 'Manage Sharing' : 'Shared with you'}
                                placement="top"
                                arrow
                            >
                                <Box onClick={canManageMedia ? handleOpen : undefined}>
                                    <ShareIconButton
                                        fontSize="small"
                                        data-testid="media-share-icon"
                                    />
                                </Box>
                            </StyledTooltip>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <TruncatedText text={filename} noWrap style={FilenameStyleSx} />
                        <Typography
                            variant="labelSmall"
                            sx={{
                                minWidth: 'fit-content',
                                ml: (t) => t.spacing(1),
                                color: (t) => alpha(t.palette.text.primary, 0.48),
                            }}
                        >
                            {filesize(size, { standard: 'jedec' })}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {showManageMediaModal && (
                <ManageMediaModal
                    isOpen={isModalOpen}
                    onClose={handleClose}
                    file={attachment}
                    canEditPermissions={canEditPermissions}
                    onMediaUpdated={onMediaUpdated}
                />
            )}
        </>
    );
}

MediaInfo.propTypes = {
    attachment: PropTypes.shape(ATTACHMENT_TYPE),
    showManageMediaModal: PropTypes.bool,
    showPermissionTooltip: PropTypes.bool,
    access: PropTypes.object,
    onMediaUpdated: PropTypes.func,
};
