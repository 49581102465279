import SvgIcon from '@mui/material/SvgIcon';

export function CopyIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <path
                d="M9.30458 0H5.84534C4.35559 0 3.14992 1.20631 3.14992 2.69684V3.15789H3.11836C1.40137 3.15789 0 4.55368 0 6.27789V8.88C0 10.5979 1.39506 12 3.11836 12H5.7191C7.43609 12 8.83746 10.5979 8.83746 8.88H7.57497C7.57497 9.90316 6.74172 10.7368 5.7191 10.7368H3.11836C2.09574 10.7368 1.26249 9.90316 1.26249 8.88V6.27789C1.26249 5.25473 2.09574 4.42105 3.11836 4.42105H3.14992V6.15789C3.14992 7.64842 4.35559 8.85473 5.84534 8.85473H9.30458C10.7943 8.85473 12 7.64842 12 6.15789V2.69684C12 1.20631 10.7943 0 9.30458 0ZM10.7375 6.15789C10.7375 6.94737 10.0936 7.59157 9.30458 7.59157H5.84534C5.05628 7.59157 4.41241 6.94737 4.41241 6.15789V2.69684C4.41241 1.90736 5.05628 1.26316 5.84534 1.26316H9.30458C10.0936 1.26316 10.7375 1.90736 10.7375 2.69684V6.15789Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
