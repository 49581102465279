import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearMessage,
    handleCloseMessages,
    handleOpenMessages,
    handleMinimized,
    resetMessageState,
    setClearInput,
    setMessageType,
} from '../messageSlice';
import { useCallback, useEffect } from 'react';
import { useTalentProfileStore } from 'features/TalentProfileDrawer/hooks/store';
import { useTalentData } from './useTalentData';
import { NOTES_SOURCES } from 'features/TalentNotes/utils/constants';
import { useTalentProfileDrawer } from 'features/TalentProfileDrawer/hooks/useTalentProfileDrawer';

export function useMessageDrawer() {
    const { isOpen, isMinimized, messageType } = useSelector((state) => state.message);
    const { selectedProfiles } = useTalentData();

    const { isTalentProfileDrawerOpen: showTalentProfile, setShowTalentProfile } =
        useTalentProfileDrawer();

    const allTalentIds = useMemo(
        () => selectedProfiles?.map(({ talentId }) => talentId) || [],
        [selectedProfiles]
    );

    const { setMessageTalentIds, clearMessageTalents, setClosed } = useTalentProfileStore();

    const dispatch = useDispatch();

    const handleOpen = useCallback(() => {
        dispatch(handleOpenMessages());
        setShowTalentProfile(true);
    }, [dispatch, setShowTalentProfile]);

    const resetMessages = useCallback(() => {
        dispatch(clearMessage());
        dispatch(setClearInput(true));
    }, [dispatch]);

    const closeTalentProfile = useCallback(() => {
        clearMessageTalents();
        setShowTalentProfile(false);
    }, [clearMessageTalents, setShowTalentProfile]);

    const handleClose = useCallback(
        (override) => {
            dispatch(handleCloseMessages());
            dispatch(resetMessageState(override));
        },
        [dispatch]
    );

    const handleMinimizeToggle = useCallback(() => {
        dispatch(handleMinimized());
    }, [dispatch]);

    const handleMessageType = useCallback(
        (messageType) => {
            dispatch(setMessageType(messageType));
        },
        [dispatch]
    );

    const openTalentProfile = useCallback(
        (talentIds, startTalentId, config) => {
            setMessageTalentIds(talentIds, startTalentId, config);
            setShowTalentProfile(true);
        },
        [setShowTalentProfile, setMessageTalentIds]
    );

    useEffect(() => {
        if (isOpen) {
            setClosed();
            if (allTalentIds[0] && !isMinimized) {
                openTalentProfile(allTalentIds, allTalentIds[0], {
                    notesSourceConfig: {
                        source: NOTES_SOURCES.PROFILE,
                        sourceId: allTalentIds[0],
                    },
                });
            }
        }
    }, [isOpen, setClosed, allTalentIds, openTalentProfile, isMinimized]);

    return {
        isOpen,
        isMinimized,
        handleOpen,
        handleClose,
        messageType,
        handleMessageType,
        handleMinimizeToggle,
        showTalentProfile,
        closeTalentProfile,
        openTalentProfile,
        resetMessages,
    };
}
