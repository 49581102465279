import SvgIcon from '@mui/material/SvgIcon';

export function SmileyHappyIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6 12C21.6 6.708 17.292 2.4 12 2.4C6.708 2.4 2.4 6.708 2.4 12C2.4 17.292 6.708 21.6 12 21.6C17.292 21.6 21.6 17.292 21.6 12ZM0 12C0 5.376 5.376 0 12 0C18.624 0 24 5.376 24 12C24 18.624 18.624 24 12 24C5.376 24 0 18.624 0 12ZM9.57308 9.01128C9.57308 9.99877 8.77254 10.7993 7.78506 10.7993C6.79757 10.7993 5.99707 9.99877 5.99707 9.01128C5.99707 8.0238 6.79757 7.22329 7.78506 7.22329C8.77254 7.22329 9.57308 8.0238 9.57308 9.01128ZM16.1874 10.7993C17.1749 10.7993 17.9754 9.99877 17.9754 9.01128C17.9754 8.0238 17.1749 7.22329 16.1874 7.22329C15.1999 7.22329 14.3994 8.0238 14.3994 9.01128C14.3994 9.99877 15.1999 10.7993 16.1874 10.7993ZM6.1915 14.6636C6.5875 16.4876 8.47149 19.1637 11.9875 19.1637H11.9995H12.0835C15.4795 19.1277 17.4835 16.4637 17.8195 14.5917C17.9395 13.9437 17.4955 13.3197 16.8475 13.1997C16.1875 13.0797 15.5755 13.5237 15.4555 14.1717C15.3115 14.9637 14.2555 16.7397 12.0475 16.7637C9.64751 16.8357 8.69948 14.8917 8.53148 14.1477C8.38748 13.4997 7.75152 13.0916 7.10352 13.2356C6.45552 13.3796 6.0475 14.0156 6.1915 14.6636Z"
                fill="currentColor"
                fillOpacity="0.48"
            />
        </SvgIcon>
    );
}
