import SvgIcon from '@mui/material/SvgIcon';

export function CautionIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M12.035 14.4316C11.4851 14.4316 11.0351 13.9817 11.0351 13.4317V9.42211C11.0351 8.87216 11.4851 8.42221 12.035 8.42221C12.585 8.42221 13.035 8.87216 13.035 9.42211V13.4317C13.035 13.9817 12.585 14.4316 12.035 14.4316Z"
                fill="currentColor"
            />
            <path
                d="M12.035 17.4311C12.5873 17.4311 13.035 16.9834 13.035 16.4312C13.035 15.879 12.5873 15.4313 12.035 15.4313C11.4828 15.4313 11.0351 15.879 11.0351 16.4312C11.0351 16.9834 11.4828 17.4311 12.035 17.4311Z"
                fill="currentColor"
            />
            <path
                d="M12.015 4.97246C12.225 4.97246 12.7649 5.03246 13.0849 5.5824L19.8442 17.1513C20.1742 17.7112 19.9542 18.2112 19.8442 18.3912C19.7342 18.5712 19.4143 19.0111 18.7643 19.0111H5.25565C4.60571 19.0111 4.28574 18.5712 4.17575 18.3912C4.06576 18.2012 3.85578 17.7112 4.17575 17.1513L10.9351 5.5824C11.2551 5.03246 11.795 4.97246 12.005 4.97246H12.015ZM12.005 2.97266C10.9151 2.97266 9.8352 3.5126 9.20526 4.5825L2.44592 16.1514C1.18604 18.3112 2.74589 21.0309 5.24565 21.0309H18.7543C21.2541 21.0309 22.814 18.3212 21.5541 16.1514L14.7947 4.5825C14.1648 3.5126 13.0849 2.97266 11.995 2.97266H12.005Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
