export const downloadFile = async (fileUrl, fileName) => {
    const response = await fetch(fileUrl, {
        responseType: 'blob',
    });

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
