import SvgIcon from '@mui/material/SvgIcon';

export function LinkIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M5.83879 13.3053C3.6902 11.1532 3.6902 7.63509 5.83879 5.50176L5.98826 5.35208C8.0808 3.25617 11.7054 3.25617 13.7979 5.35208L16.4883 8.04682C17.217 8.77665 18.394 8.77665 19.1227 8.04682C19.8513 7.317 19.8513 6.13804 19.1227 5.40822L16.4323 2.71347C14.676 0.954409 12.3593 0 9.89308 0C7.42687 0 5.09145 0.954409 3.35389 2.71347L3.20442 2.86316C-0.401474 6.47485 -0.401474 12.3696 3.20442 15.9813L5.89484 18.6761C6.2685 19.0503 6.73557 19.2188 7.22134 19.2188C7.70711 19.2188 8.17421 19.0316 8.54787 18.6761C9.27653 17.9462 9.27653 16.7673 8.54787 16.0374L5.85746 13.3427L5.83879 13.3053Z"
                fill="currentColor"
            />
            <path
                d="M29.7722 16.0562L27.1752 13.455C26.4466 12.7251 25.2695 12.7251 24.5409 13.455C23.8122 14.1848 23.8122 15.3637 24.5409 16.0936L27.1378 18.6948C28.1841 19.7427 28.7633 21.1275 28.7633 22.6059C28.7633 24.0842 28.1841 25.469 27.1378 26.517L27.0071 26.648C24.8585 28.8 21.346 28.8 19.1974 26.648L16.507 23.9532C15.7783 23.2234 14.6013 23.2234 13.8727 23.9532C13.144 24.6831 13.144 25.862 13.8727 26.5918L16.563 29.2866C18.3753 31.0831 20.7294 32 23.1022 32C25.475 32 27.8478 31.1018 29.6601 29.2866L29.7909 29.1556C31.5471 27.3965 32.5 25.076 32.5 22.6059C32.5 20.1357 31.5471 17.7965 29.7909 16.0562H29.7722Z"
                fill="currentColor"
            />
            <path
                d="M21.7757 23.186C22.2615 23.186 22.7286 22.9988 23.1022 22.6433C23.8309 21.9134 23.8309 20.7345 23.1022 20.0047L12.5274 9.41285C11.7988 8.68302 10.6217 8.68302 9.89308 9.41285C9.16443 10.1427 9.16443 11.3216 9.89308 12.0514L20.4679 22.6433C20.8416 23.0175 21.3086 23.186 21.7944 23.186H21.7757Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
