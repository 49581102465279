import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import MuiIconButton from '@mui/material/IconButton';

import { Typography } from 'components/Typography';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons';

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    padding: theme.spacing(0.5),
    '&:active': {
        backgroundColor: 'red',
    },
    '&:hover': {
        backgroundColor: alpha(theme.palette.text.primary, 0.08),
    },
    transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.standard,
    }),
}));

export function Pagination(props) {
    const {
        page,
        pageLength,
        infinite,
        total,
        onChangePage,
        showTotal,
        hidePageRange,
        hideFirstPage,
        prevTrackId,
        nextTrackId,
        IconButtonSx,
        disabled,
        disableNextPageButton,
        disablePrevPageButton,
        totalResultBypassMode,
    } = props;

    const pageEnd = page * pageLength;

    const from = total === 0 ? 0 : (page - 1) * pageLength + 1;
    const to = pageEnd > total ? total : pageEnd;
    const isStartGreaterThanEnd = from > to;

    const isLastPage =
        (!infinite && total === 0) ||
        isStartGreaterThanEnd ||
        (!infinite && page === Math.ceil(total / pageLength)) ||
        (!total && total !== 0 && !infinite);

    const isNextButtonDisabled = totalResultBypassMode
        ? disableNextPageButton
        : isLastPage || disabled;

    const isPrevButtonDisabled = totalResultBypassMode
        ? disablePrevPageButton
        : page === 1 || isStartGreaterThanEnd || disabled;

    const handleFirstPage = () => {
        if (typeof onChangePage === 'function') {
            onChangePage(1);
        }
    };

    const handlePreviousPage = () => {
        if (typeof onChangePage === 'function') {
            onChangePage(page - 1);
        }
    };

    const handleNextPage = () => {
        if (typeof onChangePage === 'function') {
            onChangePage(page + 1);
        }
    };

    return (
        <Box>
            {!hideFirstPage && page > 1 && (
                <IconButton
                    color="inherit"
                    onClick={handleFirstPage}
                    aria-label="first-page"
                    disabled={isPrevButtonDisabled ? disablePrevPageButton : page === 1 || disabled}
                    sx={IconButtonSx}
                    disableRipple
                >
                    <ChevronLeftIcon sx={{ position: 'absolute', right: 0 }} />
                    <ChevronLeftIcon sx={{ color: 'transparent' }} />
                    <ChevronLeftIcon sx={{ position: 'absolute', left: 0 }} />
                </IconButton>
            )}
            <IconButton
                color="inherit"
                onClick={handlePreviousPage}
                aria-label="previous-page"
                data-trackid={prevTrackId}
                disabled={isPrevButtonDisabled}
                sx={IconButtonSx}
                disableRipple
            >
                <ChevronLeftIcon />
            </IconButton>

            {!hidePageRange && (
                <Typography variant="titleSmall" sx={{ px: 0 }}>
                    {from}-{to}
                    {showTotal && <>&nbsp;of&nbsp;{total}</>}
                </Typography>
            )}

            <IconButton
                color="inherit"
                onClick={handleNextPage}
                aria-label="next-page"
                data-trackid={nextTrackId}
                disabled={isNextButtonDisabled}
                sx={IconButtonSx}
                disableRipple
            >
                <ChevronRightIcon />
            </IconButton>
        </Box>
    );
}

Pagination.propTypes = {
    page: PropTypes.number,
    pageLength: PropTypes.number,
    onChangePage: PropTypes.func,
    hidePageRange: PropTypes.bool,
    hideFirstPage: PropTypes.bool,
    disabled: PropTypes.bool,
    infinite: PropTypes.bool,
    showTotal: PropTypes.bool,
    total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    prevTrackId: PropTypes.string,
    nextTrackId: PropTypes.string,
    IconButtonSx: PropTypes.object,
    disableNextPageButton: PropTypes.bool,
    disablePrevPageButton: PropTypes.bool,
    totalResultBypassMode: PropTypes.bool,
};
