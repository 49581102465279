export const extractUserIds = (text) => {
    const matches = text.match(/{{(.*?)}}/g);
    if (!matches) return [];
    return matches.map((match) => match.replace('{{', '').replace('}}', ''));
};

export const extractMentions = (userIds, mentions) => {
    return userIds.map((userId) => {
        return mentions.find((mention) => mention.keycloak_id === userId);
    });
};
