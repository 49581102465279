import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { Typography } from 'components/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import { useCallbackRef } from 'hooks/useCallbackRef';

const SummaryHtmlContainer = styled(Typography)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    '& *': {
        // rare scenario where a word is longer than the width of the container
        overflow: 'auto',
    },
    '& p': {
        margin: 0,
    },
}));

export function TalentBio(props) {
    const { bio } = props;

    const collapsedSize = 100;
    const [isExpanded, setIsExpanded] = useState(false);
    const [collapseElementHeight, setCollapseElementHeight] = useState(collapsedSize);
    const [showViewFullSummary, setShowViewFullSummary] = useState(false);

    const [contentRef, clientHeight] = useCallbackRef();

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (collapsedSize < clientHeight) {
            setShowViewFullSummary(true);
            setCollapseElementHeight(collapsedSize);
        } else {
            setShowViewFullSummary(false);
            setCollapseElementHeight(clientHeight);
        }
    }, [clientHeight]);

    if (!bio) return null;

    return (
        <Box sx={{ mb: 2 }}>
            <Box>
                <Typography variant="labelSmall" color="textPrimary">
                    PERSONAL SUMMARY
                </Typography>
            </Box>
            <Box sx={{ position: 'relative', maxWidth: '100%' }}>
                <Collapse in={isExpanded} collapsedSize={collapseElementHeight}>
                    <Box ref={contentRef}>
                        <SummaryHtmlContainer
                            variant="body"
                            dangerouslySetInnerHTML={{ __html: bio }}
                        />
                    </Box>
                </Collapse>
                {showViewFullSummary && (
                    <Box>
                        <ButtonBase
                            aria-label="profile-summary-show-more"
                            onClick={toggleExpanded}
                            sx={{ ml: 'auto' }}
                            disableRipple
                        >
                            <Typography color="primary" variant="bodySmallBold">
                                {isExpanded ? 'Show Less' : 'View Full Summary'}
                            </Typography>
                        </ButtonBase>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

TalentBio.propTypes = {
    bio: PropTypes.string,
};
