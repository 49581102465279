import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { Typography } from 'components/Typography';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        padding: theme.spacing(2, 2.5, 1, 2.5),
        width: theme.spacing(50),
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    width: '100%',
    textTransform: 'none',
    height: theme.spacing(5),
}));

export function AccessPermissionsWarningModal(props) {
    const { isOpen, onClose, onConfirm } = props;
    return (
        <StyledDialog open={isOpen} onClose={onClose}>
            <Box aria-label="access-permissions-warning-dialog">
                <Box id="manage-media-modal" sx={{ display: 'flex', mb: 1.5 }}>
                    <Typography variant="titleMedium" sx={{ mr: 'auto' }}>
                        This will remove attachments to all shared features. Are you sure you want
                        to do this?
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        mb: 1.5,
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <StyledButton onClick={onClose} aria-label="cancel-change-access">
                        <Typography color="text.primary" variant="titleSmall">
                            Cancel
                        </Typography>
                    </StyledButton>
                    <StyledButton
                        variant="contained"
                        color="error"
                        onClick={onConfirm}
                        aria-label="change-access"
                    >
                        <Typography variant="titleSmall">Change Access</Typography>
                    </StyledButton>
                </Box>
            </Box>
        </StyledDialog>
    );
}

AccessPermissionsWarningModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
};
