import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import { ContactsGrid } from './components/ContactsGrid';
import { ContactsHeader } from './components/ContactsHeader';
import { useProcessedContacts } from './hooks/useProcessedContacts';
import { ChevronLeftIcon } from 'components/Icons';
import { Typography } from 'components/Typography';

const ContactsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(8, 8),
    maxWidth: theme.spacing(127),
    alignSelf: 'center',
    justifySelf: 'center',
}));

export function Contacts(props) {
    const { onClose, dialogMessageType, showBackButton } = props;

    const { state } = useLocation();
    const { profiles, messageType, shortlistId } = state || {};

    const { isFetching, policyData } = useProcessedContacts({
        profiles,
        messageType,
        shortlistId,
        skip: Boolean(onClose),
    });
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // Custom message to display to the user
            const message =
                'Are you sure you want to navigate away from this page? Your changes will not be saved.';
            e.returnValue = message; // Required for Chrome
            return message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <ContactsContainer>
            {showBackButton && (
                <Box sx={{ display: 'flex' }}>
                    <Button
                        onClick={handleBack}
                        data-testid="back-button-contacts-view"
                        sx={{
                            color: 'text.primary',
                            textTransform: 'none',
                            borderRadius: 2,
                        }}
                        disableRipple
                    >
                        <ChevronLeftIcon />
                        <Typography>Back</Typography>
                    </Button>
                </Box>
            )}
            <ContactsHeader messageType={messageType} shortlistId={shortlistId} onClose={onClose} />
            <ContactsGrid
                messageType={dialogMessageType || messageType}
                isFetching={isFetching}
                policies={policyData}
            />
        </ContactsContainer>
    );
}

Contacts.propTypes = {
    dialogMessageType: PropTypes.string,
    onClose: PropTypes.func,
    showBackButton: PropTypes.bool,
};
