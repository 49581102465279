import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { MESSAGE_TYPES } from 'utils/constants';
import { useUpdateMedia } from './useUpdateMedia';
import { usePicker } from 'features/AttachmentUpload/hooks';
import { useFileTagAutocomplete } from './useFileTagAutocomplete';
import { accessResponseToAccessValue, useMediaPermissions } from './useMediaPermissions';

const mapFilestackAttachment = (attachment) => {
    return {
        displayName: attachment.filename,
        name: attachment.filename,
        filestackUrl: attachment.url,
        size: attachment.size,
        contentType: attachment.mimetype,
        handle: attachment.handle,
    };
};

export function useMediaManager(file, isOpen, onClose, onMediaUpdated) {
    const { mediaId, tags, size, displayName: initialDisplayName } = file;
    const filename = file.name || file.filename;
    const user = useSelector((state) => state.user);
    const { organization } = user;

    const [displayName, setDisplayName] = useState(initialDisplayName);
    const [attachments, setAttachments] = useState([]);

    const { organizationAccess, handleChangeAccess, handleChangePermissions, resetPermissions } =
        useMediaPermissions({
            organizationId: organization?.id,
            access: file?.access,
        });

    const {
        value,
        inputValue,
        tagOptions,
        handleChange,
        handleInputValue,
        handleInputEnter,
        setValue,
    } = useFileTagAutocomplete(tags);

    const isDirty = useMemo(() => {
        return (
            displayName !== initialDisplayName ||
            !isEqual(value, tags) ||
            !isEqual(
                accessResponseToAccessValue(file?.access?.org_access[organization?.id]),
                organizationAccess
            )
        );
    }, [
        displayName,
        file?.access?.org_access,
        initialDisplayName,
        organization?.id,
        organizationAccess,
        tags,
        value,
    ]);

    const replacementFile = useMemo(() => {
        if (attachments.length) {
            return mapFilestackAttachment(attachments[0]);
        }
        return null;
    }, [attachments]);

    const handleSetNewDefaultDisplayName = (files) => {
        if (files.length) {
            setDisplayName(files[0].filename);
        }
    };

    const [handleInitiateUpload] = usePicker(
        user,
        setAttachments,
        '',
        '',
        MESSAGE_TYPES.EMAIL,
        handleSetNewDefaultDisplayName
    );

    const handleOpenPicker = () => {
        if (attachments.length > 0) {
            setAttachments([]);
        }
        handleInitiateUpload(0, 1)();
    };

    const handleDisplayNameChange = (event) => {
        setDisplayName(event.target.value);
    };

    const handleReplaceAttachment = (index) => {
        handleOpenPicker();
        setAttachments([]);
        setDisplayName(initialDisplayName);
    };

    const onSuccess = (data) => {
        onMediaUpdated?.(data);
        onClose?.();
    };

    const { handleUpdateMedia, isLoading } = useUpdateMedia({
        onSuccess,
    });

    const handleSaveMedia = useCallback(() => {
        const body = {
            ...(replacementFile || {}),
            mediaId,
        };

        if (!isEqual(displayName, initialDisplayName)) {
            body['displayName'] = displayName;
        }
        if (!isEqual(value, tags)) {
            body['tags'] = value;
        }

        if (
            !isEqual(
                accessResponseToAccessValue(file?.access?.org_access[organization?.id]),
                organizationAccess
            )
        ) {
            body['access'] = {
                org_access: {
                    [organization.id]: organizationAccess,
                },
            };
        }

        handleUpdateMedia(body);
    }, [
        replacementFile,
        mediaId,
        displayName,
        initialDisplayName,
        value,
        tags,
        file?.access?.org_access,
        organization?.id,
        organizationAccess,
        handleUpdateMedia,
    ]);

    useEffect(() => {
        if (!isOpen) {
            setAttachments([]);
            setDisplayName(initialDisplayName);
            resetPermissions();
        }
    }, [isOpen, initialDisplayName, resetPermissions]);

    useEffect(() => {
        if (isOpen) {
            setValue(tags);
        }
    }, [isOpen, setValue, tags]);

    return {
        isUpdatingMedia: isLoading,
        size: replacementFile?.size || size,
        filename: replacementFile?.name || filename,
        value,
        isDirty,
        inputValue,
        tagOptions,
        displayName,
        organizationAccess,
        setAttachments,
        handleChange,
        handleInputValue,
        handleInputEnter,
        handleSaveMedia,
        handleChangeAccess,
        handleChangePermissions,
        handleReplaceAttachment,
        handleDisplayNameChange,
    };
}
