import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const activityApi = createApi({
    reducerPath: 'activityApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/activity/v1/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['Activity'],
    endpoints: (builder) => ({
        getActivity: builder.query({
            query: ({ params }) => ({
                url: 'activity',
                params,
            }),
            providesTags: ['Activity'],
        }),
        getBulkActivity: builder.query({
            query: ({ body, params }) => ({
                url: 'activity/bulk-activity',
                method: 'POST',
                body,
                params,
            }),
        }),
    }),
});

export const { useGetActivityQuery, useGetBulkActivityQuery } = activityApi;
