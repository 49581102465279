import { formatDuration, intervalToDuration, parse } from 'date-fns';
import uniqBy from 'lodash/uniqBy';
import { MESSAGE_TYPES } from 'utils/constants';
import { PhoneNumberWithoutCountryCode } from 'utils/formatters/formatNumber';

export const truncateText = (lngth, text) =>
    text && text.length > lngth ? text.substring(0, lngth - 3) + '...' : text;

export const getFormattedDuration = (time) => {
    if (time === 0) {
        return time;
    }
    return formatDuration(intervalToDuration({ start: 0, end: time * 1000 }), {
        format: ['years', 'months', 'days', 'hours', 'minutes'],
        delimiter: ', ',
    });
};

export const isAboveMaxSafeInteger = (number) => {
    if (typeof number !== 'number' || Number.isNaN(number)) {
        return false;
    }

    return number > Number.MAX_SAFE_INTEGER || !Number.isSafeInteger(number);
};

export const sortArrayItemsByDate = (isSortAsc, items) => {
    if (!items) return [];
    const sortedItems = [...items].sort((a, b) => {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        return isSortAsc ? dateA - dateB : dateB - dateA;
    });
    return sortedItems;
};

export const getCooldownProps = (contact, policies, messageType) => {
    const value = contact.phone || contact.email;
    const contactDetails =
        messageType === MESSAGE_TYPES.SMS
            ? formatSmsPhoneNumberProperties(policies?.[messageType]?.contactDetails)
            : policies?.[messageType]?.contactDetails;
    const contactDetail = contactDetails?.[value];
    return {
        isCoolingDown: contactDetail?.policyViolated,
        policyViolationReason: contactDetail?.policyViolationReason || '',
    };
};

const formatSmsPhoneNumberProperties = (contactDetails) => {
    const staging = {};
    if (
        contactDetails !== null &&
        contactDetails !== undefined &&
        typeof contactDetails === 'object'
    ) {
        Object.entries(contactDetails).forEach(([key, value]) => {
            const newKey = PhoneNumberWithoutCountryCode(key);
            staging[newKey] = value;
        });
    }

    return staging;
};

export const handleDisabledPropagation = (e) => {
    const isDisabled = e.currentTarget?.dataset?.disabled === 'true';
    if (isDisabled) {
        e.stopPropagation();
    }
};

export const areAllElementsIncluded = (target = [], arr = []) =>
    target.every((item) => arr.includes(item));

// eslint-disable-next-line quotes
const BE_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSSSS";
// return true if the second date string is at a future point in time from the first date string
export const compareDateStrings = (dateString1, dateString2) => {
    const date1 = parse(dateString1, BE_DATE_FORMAT, new Date());
    const date2 = parse(dateString2, BE_DATE_FORMAT, new Date());
    return date2 > date1;
};

export const getUniqueAttachments = (attachments) => {
    const uniqueIdentifier = (obj) => obj.mediaId || obj.handle;
    return uniqBy(attachments, uniqueIdentifier);
};

export const getContactAccessor = (messageType) => {
    return messageType === MESSAGE_TYPES.EMAIL ? 'emails' : 'phones';
};

export const getContactValue = (messageType) => {
    return messageType === MESSAGE_TYPES.EMAIL ? 'email' : 'phone';
};

export const getAbsoluteUrl = (url) => {
    const trimmedUrl = url.trim();
    return /^https?:\/\//.test(trimmedUrl) ? trimmedUrl : `https://${trimmedUrl}`; //regex checks if the url starts with http or https
};
