import PropTypes from 'prop-types';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import MuiAppBar from '@mui/material/AppBar';
import ButtonBase from '@mui/material/ButtonBase';
import { styled, alpha } from '@mui/material/styles';

import {
    AI_TOOLS_PATHS,
    CONVERSATIONS_PATHS,
    PROJECTS_PATHS,
    SEARCH_PATHS,
    SHORTLIST_PATHS,
} from './constants';
import { useForcedFlow } from 'features/ForcedFlow';
import { clearUser } from 'features/Authentication';
import { NavbarMenu } from './components/NavbarMenu';
import { SearchMenu } from './components/SearchMenu';
import { useKeycloak } from 'features/Authentication';
import { useSwitchboard } from 'features/Switchboard';
import { useGetUserEmailStatusQuery } from 'services/users';
import { ShortlistsMenu } from './components/ShortlistsMenu';
import { UserMenu } from 'features/Navbar/components/UserMenu';
import { useGetMessageMetricsQuery } from 'services/conversations';
import { UserMenuNylasButton } from './components/UserMenuNylasButton';
import { useUpgradeAccountPrompt } from 'components/Dialog/UpgradeAccountPrompt';
import { SearchIcon, ShortlistAddIcon, MessagesIcon, AiIcon, ProjectsIcon } from 'components/Icons';
import { MESSAGE_METRIC_ENUMS } from 'views/Communications/CommunicationsMetrics/SendMetrics/SendMetricBar';
import { useGetDrilldownUrlQuery } from 'views/Communications/CommunicationsMetrics/SendMetrics/useGetDrilldownUrlQuery';

const logoSrc = '/assets/logos/nebula-horizonal.png';

const Logo = styled('img')({
    height: 24,
    marginRight: 24,
});

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    backgroundColor: theme.palette.text.primary,
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.32), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
}));

const AiToolsIcon = styled(AiIcon)(() => ({
    height: 26,
    width: 26,
    marginBottom: -2,
}));

const UpgradeButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isUpgradeRequested',
})(({ theme, isUpgradeRequested }) => ({
    background: isUpgradeRequested
        ? 'linear-gradient(90.28deg, rgba(80, 76, 224, 0.24) 0.17%, rgba(49, 141, 222, 0.24) 99.72%)'
        : theme.palette.common.matchInputGradient,
    borderRadius: theme.shape.borderRadius,
    textTransform: 'capitalize',
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.4),
    color: theme.palette.common.white,
    '&.MuiButton-text': {
        color: theme.palette.common.white,
    },
}));

export const onLogout = (keycloak, dispatch) => {
    if (keycloak?.authenticated) {
        // https://github.com/PostHog/posthog-js/issues/323#issuecomment-1334299857
        if (window.posthog?.__loaded) {
            // https://posthog.com/docs/libraries/js#reset-after-logout
            window.posthog.reset();
        }
        dispatch(clearUser());
        keycloak.logout();
    }
};

function renderMenuItemForDisconnectedUser(user) {
    return <UserMenuNylasButton id={user?.id} email={user?.email} />;
}

export function Navbar(props) {
    const { user } = props;

    const query = useGetDrilldownUrlQuery(MESSAGE_METRIC_ENUMS.sent.label);

    const isWorkflowEnabled = useSwitchboard('FEATURE_MESSAGE_SEQUENCING');
    const isTalkTrackEnabled = useSwitchboard('FEATURE_TALKTRACKS');
    const isSmsEnabled = useSwitchboard('FEATURE_SMS_CAPABILITY');
    const isProjectsFeatureEnabled = useSwitchboard('FEATURE_PROJECTS');
    const isNylasEmailAuthFeatureEnabled = useSwitchboard('FEATURE_NYLAS_EMAIL_AUTHENTICATION');
    const isShortlistFeedbackEnabled = useSwitchboard('FEATURE_REQUEST_FEEDBACK');
    const { isForcedFlow } = useForcedFlow();

    const dispatch = useDispatch();

    const { keycloak } = useKeycloak();
    const isPremiumUser = user?.isPaid;
    const skipFetchEmailStatus = !isNylasEmailAuthFeatureEnabled || !user?.email;

    const { data: isEmailConnected } = useGetUserEmailStatusQuery(user?.id, {
        skip: skipFetchEmailStatus,
    });

    const { data: sendMetrics } = useGetMessageMetricsQuery(query, {
        skip: !query,
    });

    const showMessagingBar = isEmailConnected || (!!sendMetrics && sendMetrics.totalResults > 0);

    const handleLogout = useCallback(() => {
        onLogout(keycloak, dispatch);
    }, [keycloak, dispatch]);

    const sequencePath = isWorkflowEnabled
        ? {
              label: 'Sequences',
              to: 'sequences',
              trackId: 'messaging-menu-paths',
          }
        : undefined;

    const { openModal, isLoading } = useUpgradeAccountPrompt();

    const handleTalkTrackClick = (e) => {
        if (!isPremiumUser || (isPremiumUser && !isSmsEnabled)) {
            e.preventDefault();
            openModal('upgrade');
        }
    };

    const talkTrackPath = isTalkTrackEnabled
        ? {
              label: 'Digital Assistant',
              to: 'talk-tracks',
              onClick: handleTalkTrackClick,
              trackId: 'messaging-menu-digital-assistant',
          }
        : undefined;

    return (
        <AppBar position="static">
            <Toolbar>
                <ButtonBase
                    disableRipple
                    component={Link}
                    to="/"
                    aria-label="navigate-to-search"
                    data-trackid="main-nav-nebula-icon"
                >
                    <Logo src={logoSrc} alt="nebula-logo" />
                </ButtonBase>
                <Box sx={{ flexGrow: 1 }} />
                {!isForcedFlow && (
                    <>
                        <NavbarMenu
                            label="Tools"
                            Icon={AiToolsIcon}
                            menuOptions={AI_TOOLS_PATHS}
                            data-trackid="main-nav-ai-tools-menu"
                        />
                        <NavbarMenu
                            label="Search"
                            Icon={SearchIcon}
                            data-trackid="main-nav-search-menu"
                            menuOptions={SEARCH_PATHS}
                            renderMenuItems={(menuOptions, { handleClose }) => (
                                <SearchMenu menuOptions={menuOptions} onSelect={handleClose} />
                            )}
                        />
                        <NavbarMenu
                            label="Shortlists"
                            data-trackid="main-nav-shortlist-menu"
                            Icon={ShortlistAddIcon}
                            menuOptions={
                                isShortlistFeedbackEnabled ? SHORTLIST_PATHS : [SHORTLIST_PATHS[0]]
                            }
                            renderMenuItems={(menuOptions, { handleClose }) => (
                                <ShortlistsMenu menuOptions={menuOptions} onSelect={handleClose} />
                            )}
                        />
                        {showMessagingBar && (
                            <NavbarMenu
                                label="Messaging"
                                data-trackid="main-nav-messaging-menu"
                                Icon={MessagesIcon}
                                menuOptions={[...CONVERSATIONS_PATHS, sequencePath, talkTrackPath]}
                                renderMenuItems={
                                    !isEmailConnected
                                        ? () => renderMenuItemForDisconnectedUser(user)
                                        : null
                                }
                            />
                        )}

                        {isProjectsFeatureEnabled && (
                            <NavbarMenu
                                label="Projects"
                                Icon={ProjectsIcon}
                                menuOptions={PROJECTS_PATHS}
                                data-trackid="main-nav-projects-menu"
                            />
                        )}
                    </>
                )}
                {!isPremiumUser && (
                    <UpgradeButton
                        disabled={isLoading}
                        aria-label="upgrade-request-navbar-cta"
                        onClick={() => openModal('upgrade')}
                    >
                        Upgrade Your Account
                    </UpgradeButton>
                )}
                <Divider
                    sx={{
                        mr: 2.5,
                        borderColor: (theme) => alpha(theme.palette.common.charcoal, 0.48),
                    }}
                    orientation="vertical"
                    flexItem
                />
                <UserMenu user={{ ...user, isEmailConnected }} onLogout={handleLogout} />
            </Toolbar>
        </AppBar>
    );
}

Navbar.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
        isEmailConnected: PropTypes.bool,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        isPaid: PropTypes.bool,
    }),
};
