import cloneDeep from 'lodash/cloneDeep';

export const TEMP_TALENT_ID_STORAGE = 'temp_talent_id_filter';

export function retrieveTempTalentIdFilter() {
    const talentIds = JSON.parse(cloneDeep(localStorage.getItem(TEMP_TALENT_ID_STORAGE)));
    localStorage.removeItem(TEMP_TALENT_ID_STORAGE);
    return talentIds;
}

export function storeTempTalentIdFilter(talentIds) {
    localStorage.removeItem(TEMP_TALENT_ID_STORAGE);
    localStorage.setItem(TEMP_TALENT_ID_STORAGE, JSON.stringify(talentIds));
}
