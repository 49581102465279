import { useState } from 'react';
import PropTypes from 'prop-types';

import { MessageCompose } from './components';

export function Edit(props) {
    const {
        messageId,
        media,
        message,
        subject,
        messageType,
        onCancelEdit,
        onFinishEdit,
        MessageSubInfoProps,
    } = props;

    const [currentMessageType, setCurrentMessageType] = useState(messageType);

    const handleChangeMessageType = (value) => {
        setCurrentMessageType(value);
    };

    return (
        <MessageCompose
            variant="edit"
            messageType={currentMessageType}
            onChangeMessageType={handleChangeMessageType}
            message={message}
            subject={subject}
            media={media}
            messageId={messageId}
            onCancelEdit={onCancelEdit}
            onFinishEdit={onFinishEdit}
            MessageSubInfoProps={MessageSubInfoProps}
            isEditing
        />
    );
}

Edit.propTypes = {
    messageId: PropTypes.string,
    message: PropTypes.string,
    subject: PropTypes.string,
    media: PropTypes.array,
    messageType: PropTypes.string,
    onCancelEdit: PropTypes.func,
    onFinishEdit: PropTypes.func,
    MessageSubInfoProps: PropTypes.objectOf(
        PropTypes.shape({
            status: PropTypes.string,
            messageType: PropTypes.string,
            messageId: PropTypes.string,
            conversationId: PropTypes.string,
            sendDate: PropTypes.string,
            isMatchingMessages: PropTypes.bool,
            isScheduledMessage: PropTypes.bool,
            isDigitalAssistantMessage: PropTypes.bool,
            isSequenceMessage: PropTypes.bool,
            onJumpToMessage: PropTypes.func,
            onEditMessage: PropTypes.func,
        })
    ),
};
