import Box from '@mui/material/Box';
import { InfoIcon } from 'components/Icons';

import { MESSAGE_TYPES } from 'utils/constants';
import { Typography } from 'components/Typography';

import { useMessageData } from 'features/MessageDrawer/hooks/useMessageData';
import { useMessageAttachments } from 'features/MessageDrawer/hooks/useMessageAttachments';
import { AttachmentList } from './AttachmentList';

export function ComposeFooter() {
    const { messageType } = useMessageData();

    const { attachments, handleRemoveMessageAttachment } = useMessageAttachments();

    return (
        <>
            {messageType === MESSAGE_TYPES.SMS && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <InfoIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                    <Typography variant="labelXSmall" sx={{ color: 'text.secondary' }}>
                        SMS Messages have a 1600 character limit
                    </Typography>
                </Box>
            )}
            {attachments?.length > 0 && (
                <AttachmentList
                    attachments={attachments}
                    handleRemoveMessageAttachment={handleRemoveMessageAttachment}
                />
            )}
        </>
    );
}
