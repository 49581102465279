import { useEffect, useMemo } from 'react';
import { useGetKeycloakUrlQuery } from 'services/switchboard';
import { getKeycloakClient } from '../KeycloakProvider';

export function useKeycloakRegistration() {
    const { data: keycloakUrl, isSuccess } = useGetKeycloakUrlQuery();

    const keycloakClient = useMemo(() => {
        return getKeycloakClient(keycloakUrl);
    }, [keycloakUrl]);

    useEffect(() => {
        if (isSuccess && keycloakClient) {
            keycloakClient.register();
        }
    }, [isSuccess, keycloakClient]);
}
