import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetUserEmailStatusQuery } from 'services/users';

export function ProtectedEmailConnectionRoute(props) {
    const { children, redirectPath = '/' } = props;

    const user = useSelector((state) => state.user);

    const {
        data: isEmailConnected,
        isFetching,
        isUninitialized,
    } = useGetUserEmailStatusQuery(user?.id, {
        skip: !user?.email,
    });

    if (!isUninitialized && !isFetching && !isEmailConnected) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
}

ProtectedEmailConnectionRoute.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    redirectPath: PropTypes.string,
};
