import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { CloseIcon } from 'components/Icons';
import { StyledChip } from 'views/Search/features/FiltersBar/components/styledComponents';

const StyledText = styled('div')(({ theme }) => ({
    color: theme.palette.common.white,
}));

export function FilterDateChip({ chipName, handleDelete, icon, text, sxProps }) {
    return (
        <Stack
            direction="row"
            spacing={1}
            data-testid="selected-chip"
            sx={{
                py: 1,
                px: 1,
                alignItems: 'center',
            }}
        >
            {icon || <StyledText>{text}</StyledText>}
            <StyledChip
                label={chipName}
                data-testid="selected-chip-name"
                onDelete={handleDelete}
                deleteIcon={<CloseIcon aria-label="selected-chip-delete" />}
                size="small"
                sx={sxProps}
            />
        </Stack>
    );
}

FilterDateChip.propTypes = {
    chipName: PropTypes.string,
    handleDelete: PropTypes.func,
    icon: PropTypes.object,
    text: PropTypes.string,
    sxProps: PropTypes.object,
};
