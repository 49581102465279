import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import MuiMenu from '@mui/material/Menu';
import Dialog from '@mui/material/Dialog';

import MuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import { useRef, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';

import { Typography } from 'components/Typography';
import { ChevronDownIcon } from 'components/Icons';

import { useSwitchboard } from 'features/Switchboard';
import { ConnectedIndicator } from './ConnectedIndicator';
import { ForcedFlowLock } from 'components/ForcedFlowLock';
import { UserMenuNylasButton } from './UserMenuNylasButton';
import { USER_NAVIGATION } from 'views/UserSettings/utils/constants';
import { useLazyCustomerPortalQuery } from 'services/organizations';

const userNavLinks = Object.entries(USER_NAVIGATION).filter(
    ([_, { navId }]) =>
        ![
            USER_NAVIGATION.DIGITAL_ASSISTANT.navId,
            USER_NAVIGATION.SCHEDULES.navId,
            USER_NAVIGATION.EMAIL_SIGNATURES.navId,
            USER_NAVIGATION.HIDDEN_PROFILES.navId,
            USER_NAVIGATION.CONTACT_US.navId,
            USER_NAVIGATION.YOUR_MEDIA.navId,
        ].includes(navId)
);

const Avatar = styled(MuiAvatar)(({ theme }) => ({
    width: 32,
    height: 32,
    marginRight: theme.spacing(1.5),
    fontSize: '1rem',
    color: theme.palette.common.white,
    background: theme.palette.common.matchInputGradient,
}));

const Button = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        position: 'relative',
        zIndex: open ? theme.zIndex.modal + 1 : 'none',
    })
);

const MenuIconContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        position: 'absolute',
        right: 0,
        top: 8,
        borderRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: open ? 0 : theme.shape.borderRadius,
        borderBottomRightRadius: open ? 0 : theme.shape.borderRadius,
        boxShadow: theme.shadows[open ? 1 : 0],
        backgroundColor: open ? theme.palette.background.sidebar : theme.palette.text.primary,
        paddingBottom: open ? theme.spacing(0.5) : 'none',
        transition: theme.transitions.create(['background-color'], {
            duration: theme.transitions.duration.shortest,
        }),
        '& > svg': {
            transform: `rotate(${open ? 180 : 0}deg)`,
            transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.shortest,
            }),
        },
        '&::after': {
            content: '""',
            display: open ? 'block' : 'none',
            position: 'absolute',
            bottom: -4,
            width: '100%',
            height: 4,
            backgroundColor: theme.palette.background.sidebar,
        },
    })
);

const Menu = styled(MuiMenu)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.background.sidebar,
        color: theme.palette.common.white,
        borderTopRightRadius: 0,
    },
}));

export function UserMenu({ user, onLogout }) {
    const { id, email, firstName = '', lastName = '', isEmailConnected } = user || {};

    const isNylasEmailAuthFeatureEnabled = useSwitchboard('FEATURE_NYLAS_EMAIL_AUTHENTICATION');
    const isSchedulingEnabled = useSwitchboard('FEATURE_SCHEDULING');
    const isEmailSignatureEnabled = useSwitchboard('FEATURE_NN-1643_SIGNATURE_SETTINGS_UI');
    const isHideTalentFeatureEnabled = useSwitchboard('FEATURE_HIDE_TALENT_PROFILES');
    const isMediaFeatureEnabled = useSwitchboard('FEATURE_MEDIA_MANAGEMENT');

    const [openDialog, setOpenDialog] = useState(false);
    const [loadingText, setLoadingText] = useState('Please Wait...');
    const [customerPortal] = useLazyCustomerPortalQuery();

    const { pathname } = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuAnchorRef = useRef(null);

    const handleToggleOpen = () => {
        setMenuOpen((isOpen) => !isOpen);
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    const handleLogout = () => {
        if (typeof onLogout === 'function') {
            onLogout();
        }
        setMenuOpen(false);
    };

    const handleCustomerPortalRedirect = async () => {
        setOpenDialog(true);
        const { isError, isSuccess, data } = await customerPortal();

        if (isError) {
            setOpenDialog(false);
            toast.error('An Error occured');
            setLoadingText('Please Wait...');
            return;
        }
        if (isSuccess) {
            setLoadingText('Redirecting....');
        }
        if (data.url) {
            window.location.href = data.url;
        }
    };

    const replace = pathname?.includes('user-settings');

    return (
        <Stack direction="row">
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
                {isNylasEmailAuthFeatureEnabled && (
                    <ConnectedIndicator isEmailConnected={isEmailConnected} />
                )}
            </Box>
            <Avatar alt={`${firstName} ${lastName}`}>{firstName?.[0]}</Avatar>
            <Button
                open={menuOpen}
                onClick={handleToggleOpen}
                id="user-menu-button"
                data-trackid="main-nav-avatar-menu"
                aria-controls={menuOpen ? 'user-menu' : undefined}
                aria-expanded={menuOpen ? 'true' : undefined}
                aria-haspopup="true"
                disableRipple
            >
                <Typography variant="bodySmall" sx={{ mr: 3 }}>
                    {email}
                </Typography>
                <MenuIconContainer ref={menuAnchorRef} open={menuOpen}>
                    <ChevronDownIcon fontSize="small" />
                </MenuIconContainer>
            </Button>
            <Menu
                open={menuOpen}
                anchorEl={menuAnchorRef.current}
                onClose={handleClose}
                TransitionComponent={Grow}
                elevation={1}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                disablePortal
            >
                {isNylasEmailAuthFeatureEnabled && !isEmailConnected && (
                    <ForcedFlowLock>
                        <UserMenuNylasButton id={id} email={email} />
                    </ForcedFlowLock>
                )}
                {(user?.isSalesManager || user?.isOrganizationAdmin) && (
                    <ForcedFlowLock>
                        <MenuItem
                            component={Link}
                            to="/admin"
                            onClick={handleClose}
                            disableRipple
                            data-trackid={
                                user?.isOrganizationAdmin
                                    ? 'avatar-menu-org-admin'
                                    : 'avatar-menu-admin'
                            }
                        >
                            <Typography variant="bodySmall" align="right">
                                {user?.isOrganizationAdmin
                                    ? 'Organization Admin'
                                    : 'Admin Settings'}
                            </Typography>
                        </MenuItem>
                    </ForcedFlowLock>
                )}
                {user?.isOrganizationAdmin && (
                    <ForcedFlowLock>
                        <MenuItem
                            data-trackid="avatar-menu-plan-management"
                            onClick={() => handleCustomerPortalRedirect()}
                            disableRipple
                        >
                            <Typography variant="bodySmall" align="right">
                                Plan Management
                            </Typography>
                        </MenuItem>
                    </ForcedFlowLock>
                )}
                {isMediaFeatureEnabled && (
                    <MenuItem
                        component={Link}
                        to="/user-settings/your-media"
                        onClick={handleClose}
                        disableRipple
                        replace={replace}
                    >
                        <Typography variant="bodySmall" align="right">
                            {USER_NAVIGATION.YOUR_MEDIA.title}
                        </Typography>
                    </MenuItem>
                )}
                {user &&
                    userNavLinks.map(([_, { navId, title, headerNavTrackId }], index) => (
                        <ForcedFlowLock key={index}>
                            <MenuItem
                                key={navId}
                                component={Link}
                                to={
                                    navId === 'notes-nav-header'
                                        ? '/profiles-with-notes'
                                        : `/user-settings#${navId}`
                                }
                                onClick={handleClose}
                                disableRipple
                                replace={replace}
                                data-trackid={headerNavTrackId}
                            >
                                <Typography variant="bodySmall" align="right">
                                    {title}
                                </Typography>
                            </MenuItem>
                        </ForcedFlowLock>
                    ))}

                {isEmailConnected && isSchedulingEnabled && (
                    <ForcedFlowLock>
                        <MenuItem
                            data-trackid={USER_NAVIGATION.SCHEDULES.headerNavTrackId}
                            component={Link}
                            to={`/user-settings#${USER_NAVIGATION.SCHEDULES.navId}`}
                            onClick={handleClose}
                            disableRipple
                            replace={replace}
                        >
                            <Typography variant="bodySmall" align="right">
                                {USER_NAVIGATION.SCHEDULES.title}
                            </Typography>
                        </MenuItem>
                    </ForcedFlowLock>
                )}
                {isEmailConnected && isEmailSignatureEnabled && (
                    <ForcedFlowLock>
                        <MenuItem
                            data-trackid={USER_NAVIGATION.EMAIL_SIGNATURES.headerNavTrackId}
                            component={Link}
                            to={`/user-settings#${USER_NAVIGATION.EMAIL_SIGNATURES.navId}`}
                            onClick={handleClose}
                            disableRipple
                            replace={replace}
                        >
                            <Typography variant="bodySmall" align="right">
                                {USER_NAVIGATION.EMAIL_SIGNATURES.title}
                            </Typography>
                        </MenuItem>
                    </ForcedFlowLock>
                )}
                {isHideTalentFeatureEnabled && (
                    <ForcedFlowLock>
                        <MenuItem
                            component={Link}
                            to={`/user-settings#${USER_NAVIGATION.HIDDEN_PROFILES.navId}`}
                            onClick={handleClose}
                            disableRipple
                            replace={replace}
                        >
                            <Typography variant="bodySmall" align="right">
                                {USER_NAVIGATION.HIDDEN_PROFILES.title}
                            </Typography>
                        </MenuItem>
                    </ForcedFlowLock>
                )}
                <MenuItem
                    component={Link}
                    to={`/user-settings#${USER_NAVIGATION.CONTACT_US.navId}`}
                    onClick={handleClose}
                    disableRipple
                    replace={replace}
                >
                    <Typography variant="bodySmall" align="right">
                        {USER_NAVIGATION.CONTACT_US.title}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout} disableRipple data-trackid="avatar-menu-log-out">
                    <Typography variant="bodySmall" align="right">
                        Log Out
                    </Typography>
                </MenuItem>
            </Menu>
            <Dialog open={openDialog}>
                <DialogContent sx={{ background: 'transparent' }}>
                    <DialogContentText>{loadingText}</DialogContentText>
                    <CircularProgress
                        color="inherit"
                        size={20}
                        sx={{ marginTop: '10px', marginLeft: '35px' }}
                    />
                </DialogContent>
            </Dialog>
        </Stack>
    );
}

UserMenu.propTypes = {
    user: PropTypes.shape({
        isEmailConnected: PropTypes.bool,
        id: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        isSalesManager: PropTypes.bool,
        isOrganizationAdmin: PropTypes.bool,
    }),
    onLogout: PropTypes.func,
};
