import SvgIcon from '@mui/material/SvgIcon';

export function ChevronLeftIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M13.9498 8.22176C14.3403 7.83123 14.3403 7.19807 13.9498 6.80754C13.5593 6.41702 12.9261 6.41702 12.5356 6.80754L8.29293 11.0502C8.24411 11.099 8.2014 11.1516 8.16479 11.2071C7.90851 11.5952 7.95122 12.1227 8.29293 12.4644L12.5356 16.707C12.9261 17.0976 13.5593 17.0976 13.9498 16.707C14.3403 16.3165 14.3403 15.6833 13.9498 15.2928L10.4142 11.7573L13.9498 8.22176Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </SvgIcon>
    );
}
