import PropTypes from 'prop-types';
import omitBy from 'lodash/omitBy';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import isEmpty from 'lodash/isEmpty';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';

import { pluralize } from 'utils/copy';
import { Sidebar } from 'components/Sidebar';
import { FilterAltIcon } from 'components/Icons';
import { formatNumber } from 'utils/formatters';
import { Typography } from 'components/Typography';
import { runSearch } from 'views/Search/searchSlice';
import { useSwitchboard } from 'features/Switchboard';
import { ApplyChangesButton } from 'components/Buttons';
import { SEARCH_MIN_CHAR_COUNT } from 'utils/constants';
import { FilterResultChip } from './components/styledComponents';
import { ShowAllHiddenProfiles } from './components/ShowAllHiddenProfiles';
import { FilterCertificationsAndSkills } from './components/FilterCertificationsAndSkills';
import {
    FilterName,
    ActiveFilters,
    FilterKeywords,
    FilterLocation,
    FilterEducation,
    FilterEmployment,
    PendingHighlights,
} from './components';

const MAX_RESULTS = 10000;

const isFilterAppliedSelector = (state) => {
    const currentAppliedFilters = state.search.currentSearch.filters;
    const prunedFilters = omitBy(currentAppliedFilters, (val) => isEmpty(val));
    return !isEmpty(prunedFilters);
};

const FilterIcon = styled(FilterAltIcon)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: 20,
    padding: theme.spacing(0, 0.4),
}));

export function FiltersBar({ isZeroState }) {
    const dispatch = useDispatch();

    const isDirty = useSelector((state) => state.search.isDirty);
    const matchText = useSelector((state) => state.search.matchText);
    const totalResults = useSelector((state) => state.search.totalResults);
    const isFilterApplied = useSelector(isFilterAppliedSelector);

    const isHideTalentFeatureEnabled = useSwitchboard('FEATURE_HIDE_TALENT_PROFILES');

    const handleApplySearchChanges = useCallback(() => {
        dispatch(runSearch());
    }, [dispatch]);

    return (
        <Sidebar ExpandIcon={FilterIcon} forceCollapsible={isZeroState}>
            {!isZeroState && (
                <Box
                    sx={{
                        mt: -6,
                        pt: 6,
                        pb: 3,
                        position: 'sticky',
                        top: -48,
                        zIndex: 1,
                        backgroundColor: 'background.sidebar',
                    }}
                >
                    <ApplyChangesButton
                        onClick={handleApplySearchChanges}
                        disabled={!isDirty || matchText.length < SEARCH_MIN_CHAR_COUNT}
                        data-trackid="filter-menu-apply-changes"
                    />
                </Box>
            )}
            {!isZeroState && (
                <Box sx={{ pb: 3 }}>
                    <PendingHighlights />
                </Box>
            )}
            <Box
                sx={{
                    display: 'flex',
                    color: 'common.white',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'background.sidebar',
                    pb: 2,
                    mb: -2,
                    ...(isFilterApplied &&
                        totalResults > 0 && {
                            position: 'sticky',
                            top: 84,
                            zIndex: 1,
                        }),
                }}
            >
                <Typography variant="labelLarge" sx={{ mr: 'auto' }}>
                    Filter Current Search
                </Typography>
                {isFilterApplied && totalResults > 0 && (
                    <FilterResultChip
                        label={`${formatNumber(totalResults)}${
                            totalResults === MAX_RESULTS ? '+' : ''
                        } ${pluralize('Result', totalResults)}`}
                    />
                )}
            </Box>

            <ActiveFilters />

            <FilterCertificationsAndSkills />

            <FilterKeywords />

            <FilterLocation />

            <FilterEducation />

            <FilterEmployment />

            <FilterName />

            {isHideTalentFeatureEnabled && <ShowAllHiddenProfiles />}
        </Sidebar>
    );
}

FiltersBar.propTypes = {
    isZeroState: PropTypes.bool,
    isMessageDrawerOpened: PropTypes.bool,
};
