import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from 'components/Typography';
import {
    addSearchSelection,
    addShortlistSelection,
    removeSearchSelection,
    selectSearchTalentById,
    removeShortlistSelection,
    selectShortlistTalentById,
} from 'features/TalentCard/TalentBatchActions';

const CheckboxContainer = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: theme.spacing(5.5),
    width: theme.spacing(8.5),
    color: theme.palette.text.primary,
    '&:hover': {
        '& .MuiCheckbox-root': {
            color: theme.palette.success.main,
        },
    },
}));

const CardCheckbox = styled(Checkbox)(({ theme }) => ({
    padding: 0,
    transition: theme.transitions.create(['color'], {
        duration: theme.transitions.duration.shortest,
    }),
}));

const getSelectById = (isSearch) => (isSearch ? selectSearchTalentById : selectShortlistTalentById);
const getAddSelection = (isSearch) => (isSearch ? addSearchSelection : addShortlistSelection);
const getRemoveSelection = (isSearch) =>
    isSearch ? removeSearchSelection : removeShortlistSelection;

export function TalentCardCheckbox(props) {
    const { talent, isSearch } = props;

    const dispatch = useDispatch();
    const checked = !!useSelector((state) => getSelectById(isSearch)(state, talent.talentId));

    const handleChange = () => {
        if (checked) {
            dispatch(getRemoveSelection(isSearch)(talent.talentId));
        } else {
            dispatch(getAddSelection(isSearch)(talent));
        }
    };

    return (
        <CheckboxContainer
            onClick={handleChange}
            color="success"
            aria-label="talent-checkbox-button"
            disableRipple
        >
            <CardCheckbox
                inputProps={{ 'aria-label': 'talent-checkbox' }}
                value={checked}
                checked={checked}
                disableRipple
                color="success"
            />
            <Typography variant="labelXSmall">SELECT</Typography>
        </CheckboxContainer>
    );
}

TalentCardCheckbox.propTypes = {
    talent: PropTypes.object,
    isSearch: PropTypes.bool,
};
