import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { ChevronDownIcon } from 'components/Icons';
import { MediaSectionLabel } from 'views/UserSettings/features/YourMedia/components/MediaModals/MediaSectionLabel';
import { FormControl } from '@mui/material';
import { MESSAGE_LENGTH_OPTIONS } from 'features/MessageDrawer/utils';

export function MessageLengthSelector(props) {
    const { value, setValue } = props;

    const handleChangeOption = (event) => {
        setValue(event.target.value);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <MediaSectionLabel>LENGTH</MediaSectionLabel>
            <FormControl>
                <Select
                    displayEmpty
                    value={value}
                    onChange={handleChangeOption}
                    IconComponent={ChevronDownIcon}
                    inputProps={{
                        'aria-label': 'message length options',
                        'data-testid': 'message-gen-length-options',
                        sx: {
                            color: value === '' ? 'common.lightGray' : 'text.primary',
                            py: 1.5,
                        },
                    }}
                    sx={{
                        '.MuiSelect-icon': {
                            color: 'primary.main',
                        },
                    }}
                >
                    {value === '' && (
                        <MenuItem
                            value=""
                            sx={{
                                color: 'text.disabled',
                                opacity: '1 !important',
                                background: 'transparent !important',
                            }}
                            disabled
                        >
                            Choose a length for your message
                        </MenuItem>
                    )}
                    <MenuItem
                        data-testid={`${MESSAGE_LENGTH_OPTIONS.short.value}-option`}
                        value={MESSAGE_LENGTH_OPTIONS.short.value}
                    >
                        {MESSAGE_LENGTH_OPTIONS.short.label}
                    </MenuItem>
                    <MenuItem
                        data-testid={`${MESSAGE_LENGTH_OPTIONS.medium.value}-option`}
                        value={MESSAGE_LENGTH_OPTIONS.medium.value}
                    >
                        {MESSAGE_LENGTH_OPTIONS.medium.label}
                    </MenuItem>
                    <MenuItem
                        data-testid={`${MESSAGE_LENGTH_OPTIONS.long.value}-option`}
                        value={MESSAGE_LENGTH_OPTIONS.long.value}
                    >
                        {MESSAGE_LENGTH_OPTIONS.long.label}
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

MessageLengthSelector.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
};
