import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { LoadingTab } from './LoadingTab';
import { Typography } from 'components/Typography';
import { ContactList } from './ContactList';
import { CustomContactInputButtonToggle } from './CustomContactInputButtonToggle';
import { CONTACT_TYPES } from '../utils/constants';
import { CUSTOM_CONTACT_CATEGORIES_DROPDOWN_OPTIONS } from 'utils/constants';
import { useSwitchboard } from 'features/Switchboard';
import { useSelector } from 'react-redux';

const heightStyles = {
    height: '250px',
    overflow: 'hidden',
};

export function PhonesTab(props) {
    const {
        phones,
        loadingState,
        hasLookup,
        talentId,
        onPrimaryContactChange,
        categories,
        onCreateCategory,
        savedCategories,
        onCreateCustomContact,
        handleFlagContact,
        stalePhoneContacts,
    } = props;

    const {
        isLoading,
        isPrimaryContactChangeLoading,
        isCreatingCategory,
        isCreatingCustomContact,
        isFlagingContact,
    } = loadingState || {};

    const isPremiumUser = useSelector((state) => state.user?.isPaid);
    const isCustomContactEnabled = useSwitchboard('FEATURE_CUSTOM_CONTACTS');
    const showAddPhoneButton = isPremiumUser && isCustomContactEnabled;

    if (isLoading) {
        return (
            <div style={{ ...heightStyles }}>
                <LoadingTab count={phones?.length || 4} />
            </div>
        );
    }

    const renderMainView = () => {
        if (!phones?.length && hasLookup) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                    <Typography variant="body">No contact numbers are available</Typography>
                </Box>
            );
        }

        return (
            <ContactList
                contacts={phones}
                isPrimaryContactChangeLoading={isPrimaryContactChangeLoading}
                onPrimaryContactChange={onPrimaryContactChange}
                categories={categories}
                onCreateCategory={onCreateCategory}
                isCreatingCategory={isCreatingCategory}
                savedCategories={savedCategories}
                handleFlagContact={handleFlagContact}
                isFlagingContact={isFlagingContact}
                talentId={talentId}
                contactType={CONTACT_TYPES.PHONE}
                staleContacts={stalePhoneContacts}
                hasLookup={hasLookup}
            />
        );
    };

    return (
        <Box>
            {showAddPhoneButton && (
                <CustomContactInputButtonToggle
                    onCreateCustomContact={onCreateCustomContact}
                    contactType={CONTACT_TYPES.PHONE}
                    contactCategories={CUSTOM_CONTACT_CATEGORIES_DROPDOWN_OPTIONS}
                    isLoading={isCreatingCustomContact}
                />
            )}
            {renderMainView()}
        </Box>
    );
}

PhonesTab.propTypes = {
    phones: PropTypes.arrayOf(
        PropTypes.shape({
            contactType: PropTypes.string,
            number: PropTypes.string,
        })
    ),
    loadingState: PropTypes.shape({
        isLoading: PropTypes.bool,
        isLookupLoading: PropTypes.bool,
        isPrimaryContactChangeLoading: PropTypes.bool,
        isCreatingCustomContact: PropTypes.bool,
        isFlagingContact: PropTypes.bool,
    }),
    talentId: PropTypes.string,
    hasLookup: PropTypes.bool,
    onPrimaryContactChange: PropTypes.func,
    onCreateCustomContact: PropTypes.func,
    categories: PropTypes.arrayOf(PropTypes.string),
    onCreateCategory: PropTypes.func,
    handleFlagContact: PropTypes.func,
    savedCategories: PropTypes.arrayOf(PropTypes.string),
    stalePhoneContacts: PropTypes.arrayOf(PropTypes.shape({ contactId: PropTypes.string })),
};
