import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useRedirectError = (isLoading) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!isLoading && searchParams.has('errorMessage')) {
            toast.error(searchParams.get('errorMessage'));
            searchParams.delete('errorMessage');
            setSearchParams(searchParams);
        }
    }, [isLoading, searchParams, setSearchParams]);

    return null;
};
