import { styled, alpha } from '@mui/material/styles';
import { Chip } from '@mui/material';

import { TYPOGRAPHY_VARIANTS } from 'components/Typography';

export const ContactStatusBadge = styled(Chip, { shouldForwardProp: (prop) => prop !== 'color' })(
    ({ theme, color }) => ({
        backgroundColor: alpha(
            color === 'text'
                ? theme.palette.text.primary
                : theme.palette[color]?.main || theme.palette.text.primary,
            color === 'text' ? 0.12 : 0.24
        ),
        borderRadius: theme.spacing(0.5),
        color: alpha(theme.palette.text.primary, 0.72),
        height: theme.spacing(1.75),
        marginLeft: theme.spacing(1),
        ...TYPOGRAPHY_VARIANTS.labelXSmall,
        '& .MuiChip-label': {
            padding: theme.spacing(0.125, 0.5),
        },
    })
);
