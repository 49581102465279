import PropTypes from 'prop-types';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha, styled } from '@mui/material/styles';

import { usePoolsMap } from 'hooks/usePoolsMap';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography';
import { Typography } from '@mui/material';
import { truncateText } from 'utils/helpers';

const SourcePoolChip = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isPoolPublic',
})(({ theme, isSelected, isPoolPublic }) => {
    const backgroundColor = isPoolPublic
        ? 'linear-gradient(90.28deg, #504CE0 0.17%, #318DDE 99.72%)'
        : '#5F2BE1';
    const textColor = isSelected ? '#fff' : alpha(theme.palette.text.primary, 0.48);
    return {
        ...TYPOGRAPHY_VARIANTS['labelSmall'],
        padding: '2px 4px 2px 4px',
        display: 'flex',
        minWidth: '52px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
        height: '20px',
        transition: 'background 0.3s ease',
        background: isSelected ? backgroundColor : 'transparent',
        color: textColor,
        '&:hover': {
            background: backgroundColor,
            color: textColor,
        },
    };
});

const SourcePoolChipText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
    fontWeight: 700,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        color: 'white',
    },
    color: isSelected ? 'white' : alpha(theme.palette.text.primary, 0.48),
}));

const Wrapper = styled('div')(() => ({
    display: 'flex',
    gap: 1,
}));

export function SourcePoolProfileSelector(props) {
    const { profiles, selectedProfile, onSelect } = props;

    const poolsMap = usePoolsMap();

    const createHandleSelectProfile = (profile, index) => (e) => {
        e.stopPropagation();
        onSelect(profile, index);
    };

    return (
        <Wrapper sx={{ display: 'flex', gap: 1 }}>
            {profiles.map((profile, index) => (
                <SourcePoolChip
                    disableRipple
                    isSelected={selectedProfile?.profileId === profile.profileId}
                    isPoolPublic={poolsMap[profile.talentPoolId]?.isPublic}
                    key={profile.profileId}
                    onClick={createHandleSelectProfile(profile, index)}
                >
                    <SourcePoolChipText
                        variant="labelXSmall"
                        isSelected={selectedProfile?.profileId === profile.profileId}
                    >
                        {truncateText(10, poolsMap[profile.talentPoolId]?.name)}
                    </SourcePoolChipText>
                </SourcePoolChip>
            ))}
        </Wrapper>
    );
}

SourcePoolProfileSelector.propTypes = {
    profiles: PropTypes.array,
    selectedProfile: PropTypes.object,
    onSelect: PropTypes.func,
};
