import PropTypes from 'prop-types';
import MuiMenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

import { Typography } from 'components/Typography';
import {
    AddToExcludeFilterButton,
    AddToFilterButton,
} from 'features/TalentCard/components/AddToFilterButton';

const MenuItemOption = styled(MuiMenuItem)(({ theme }) => ({
    color: theme.palette.common.white,
    padding: theme.spacing(0, 0.75),
    paddingRight: theme.spacing(1.5),
    height: theme.spacing(3.5),
    cursor: 'initial',
    '&:not(:hover) > .filter-ctas': {
        display: 'none',
    },
}));

const MenuItemIconWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(3),
    paddingRight: theme.spacing(0.5),
}));

const MenuItemCTAWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    columnGap: theme.spacing(2),
}));

export function FilterMenuOption(props) {
    const { onAddFilter, onAddExcludeFilter, Icon, iconFontSize, name, label } = props;

    return (
        <MenuItemOption disableRipple>
            <MenuItemIconWrapper>
                <Icon sx={{ fontSize: iconFontSize, opacity: 0.48 }} />
            </MenuItemIconWrapper>
            <Typography variant="bodyXSmall" sx={{ fontWeight: 400 }}>
                {label}
            </Typography>
            <MenuItemCTAWrapper className="filter-ctas">
                <AddToFilterButton
                    data-trackid={`add-as-filter-${name}-include`}
                    aria-label={`add-to-${name}-filter`}
                    onClick={onAddFilter}
                    sx={{
                        '&:hover': {
                            '& svg': {
                                color: 'success.main',
                            },
                        },
                    }}
                />
                <AddToExcludeFilterButton
                    data-trackid={`add-as-filter-${name}-exclude`}
                    aria-label={`add-to-exclude-${name}-filter`}
                    onClick={onAddExcludeFilter}
                    sx={{
                        '&:hover': {
                            '& svg': {
                                color: 'error.main',
                            },
                        },
                    }}
                />
            </MenuItemCTAWrapper>
        </MenuItemOption>
    );
}

FilterMenuOption.propTypes = {
    Icon: PropTypes.elementType,
    iconFontSize: PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.node,
    onAddFilter: PropTypes.func,
    onAddExcludeFilter: PropTypes.func,
};
