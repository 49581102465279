import { CREDITS } from 'utils/constants';
import { formatNumber } from 'utils/formatters';
import { isAboveMaxSafeInteger } from 'utils/helpers';
import { useGetSignedInOrganizationQuery } from 'services/organizations';
import { useMemo } from 'react';

const creditThreshold = {
    [CREDITS.JD]: 1,
    [CREDITS.SEARCH]: 10,
    [CREDITS.LOOKUP]: 3,
};

export function useCreditUsage(creditType) {
    const { data, isUninitialized, isFetching, isSuccess, isError } =
        useGetSignedInOrganizationQuery(null, { refetchOnMountOrArgChange: true });

    const isLoading = isUninitialized || isFetching;

    const values = useMemo(() => {
        let isBelowThreshold = false;
        let remainingCredits;
        let usedCredits;
        let totalCredits;
        let isUnlimited = false;
        if (isSuccess) {
            const available = Math.max(data.metrics[creditType].availableTokens, 0);
            const used = Math.max(data.metrics[creditType].tokenUsage, 0);
            const limit = Math.max(data.metrics[creditType].tokenLimit, 0);

            isBelowThreshold = available <= creditThreshold[creditType];
            remainingCredits = available > 999 ? '999+' : formatNumber(available);
            usedCredits = used > 999 ? '999+' : formatNumber(used);
            totalCredits = limit > 999 ? '999+' : formatNumber(limit);
            isUnlimited = isAboveMaxSafeInteger(available);
        }
        return {
            data,
            isLoading,
            isBelowThreshold,
            remainingCredits,
            usedCredits,
            totalCredits,
            isUnlimited,
            isError,
        };
    }, [creditType, data, isSuccess, isLoading, isError]);

    return values;
}
