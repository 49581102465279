import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';

import { TYPOGRAPHY_VARIANTS } from 'components/Typography';
import { LoadWrapper } from 'components/Loaders';
import { ChevronDownIcon, CloseIcon, CheckIcon } from 'components/Icons';

function getStyles(value, category) {
    const styles =
        category === value
            ? { ...TYPOGRAPHY_VARIANTS.bodyBaseBold }
            : {
                  ...TYPOGRAPHY_VARIANTS.titleSmall,
                  fontWeight: 500,
                  color: 'rgba(38, 55, 70, 0.72)',
              };
    return {
        ...styles,
        textTransform: 'capitalize',
    };
}

export function CategorySelect({ categories, onCreateCategory, isCreatingCategory, onCancel }) {
    const [selectedCategory, setSelectedCategory] = useState(categories[0] || '');

    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <FormControl sx={{ minWidth: 156, mr: 0.5 }}>
                <Select
                    value={selectedCategory}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'contact-category-select' }}
                    SelectDisplayProps={{
                        style: {
                            paddingTop: 5,
                            paddingBottom: 5,
                            ...TYPOGRAPHY_VARIANTS.bodyBaseBold,
                            textTransform: 'capitalize',
                        },
                    }}
                    IconComponent={(props) => <ChevronDownIcon {...props} />}
                >
                    {categories?.map((value) => (
                        <MenuItem
                            key={value}
                            value={value}
                            style={getStyles(value, selectedCategory)}
                        >
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <LoadWrapper isLoading={isCreatingCategory}>
                <IconButton
                    color="primary"
                    aria-label="create-contact-category-icon"
                    disableRipple
                    onClick={() => onCreateCategory(selectedCategory, onCancel)}
                >
                    <CheckIcon />
                </IconButton>
            </LoadWrapper>
            <IconButton
                aria-label="cancel-contact-category-creation-icon"
                sx={{ color: 'rgba(38, 55, 70, 0.24)' }}
                disableRipple
                onClick={onCancel}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    );
}

CategorySelect.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.string),
    onCreateCategory: PropTypes.func,
    isCreatingCategory: PropTypes.bool,
    onCancel: PropTypes.func,
};
