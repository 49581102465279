import { createSlice } from '@reduxjs/toolkit';

export const initialContactDetailState = {
    isModalOpen: false,
    selectedTalent: {},
    initialTab: 0,
    isProjectOutreachContactLookup: false,
};

const contactDetailSlice = createSlice({
    name: 'contactDetails',
    initialState: initialContactDetailState,
    reducers: {
        handleOpenModal: (
            state,
            { payload: { selectedTalent, initialTab = 0, isProjectOutreachContactLookup = false } }
        ) => {
            state.isModalOpen = true;
            state.selectedTalent = selectedTalent;
            state.initialTab = initialTab;
            if (isProjectOutreachContactLookup) {
                state.isProjectOutreachContactLookup = isProjectOutreachContactLookup;
            }
        },
        handleCloseModal: (state) => {
            state.isModalOpen = false;
        },
        updateSelectedTalent: (state, payload) => {
            state.selectedTalent = payload;
        },
        resetModalData: () => {
            return initialContactDetailState;
        },
    },
});

export const { handleOpenModal, handleCloseModal, resetModalData, updateSelectedTalent } =
    contactDetailSlice.actions;

export const contactDetailReducer = contactDetailSlice.reducer;
