import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Outlet, Navigate, useMatch } from 'react-router-dom';

import { FiltersBar } from 'views/Search/features/FiltersBar';

const outletOuterContainerStyles = {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
};

const OutletInnerContainerStyles = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isZeroState',
})(({ theme, isZeroState }) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    maxWidth: theme.spacing(127),
    marginRight: 'auto',
    padding: theme.spacing(4, 7),
    ...(isZeroState && {
        maxWidth: theme.spacing(147.125),
        [theme.breakpoints.up('lg')]: {
            margin: 'auto',
            paddingLeft: 0,
            paddingRight: 0,
        },
    }),
}));

export function Search() {
    const { isOpen, isMinimized, showTalentProfile } = useSelector((state) => state.message);

    const match = useMatch('search/*');
    const isZeroState = !useMatch('search/results');
    const isMessageDrawerOpened = showTalentProfile && !isMinimized && isOpen;
    if (!match) {
        return <Navigate to="search" />;
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <FiltersBar
                isZeroState={Boolean(isZeroState) || isMessageDrawerOpened}
                isMessageDrawerOpened={isMessageDrawerOpened}
            />
            <Box id="search-view-window" sx={outletOuterContainerStyles}>
                <OutletInnerContainerStyles isZeroState={Boolean(isZeroState)}>
                    <Outlet />
                </OutletInnerContainerStyles>
            </Box>
        </Box>
    );
}
