import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';

import { Typography } from 'components/Typography';

const DialogButton = styled(Button)(({ theme }) => ({
    boxSizing: 'border-box',
    width: theme.spacing(10),
    textTransform: 'unset',
}));

export function TcpaDialog(props) {
    const {
        open,
        onClose,
        onConfirm,
    } = props;

    return (
        <Dialog
            closeAfterTransition
            onClose={onClose}
            open={open}
            aria-label="tcpa-dialog"
            sx={{ backgroundColor: 'rgba(38, 55, 70, 0.72)' }}
            PaperProps={{
                sx: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 400,
                    p: [4, 4, 2],
                },
            }}
        >
            <Typography
                variant="titleMedium"
                sx={{ color: 'text.primary', textAlign: 'center', mb: 0.5 }}
            >
                Your message cannot be sent because it is outside of the TCPA communication window.
                Do you want to schedule this message to send in the next available time frame?
            </Typography>
            <DialogActions sx={{ width: '100%', padding: 0 }}>
                <DialogButton
                    aria-label="tcpa-dialog-back"
                    onClick={onClose}
                    sx={{ color: 'text.primary', width: '100%', border: '1px #000000 solid' }}
                >
                    <Typography variant="titleSmall" color="black">
                        Cancel
                    </Typography>
                </DialogButton>
                <DialogButton
                    aria-label="tcpa-dialog-confirm"
                    onClick={onConfirm}
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'common.white',
                        width: '100%',
                        ':hover': {
                            color: 'text.primary',
                        },
                    }}
                >
                    <Typography variant="titleSmall">Yes</Typography>
                </DialogButton>
            </DialogActions>
        </Dialog>
    );
}

TcpaDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
};
