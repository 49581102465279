import { useEffect } from 'react';
import GoogleAnalytics from 'react-ga4';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useSwitchboard } from 'features/Switchboard';
import { useGoogleAnalytics } from './useGoogleAnalytics';

export function GoogleAnalyticsTracker() {
    const GA = useGoogleAnalytics();
    const location = useLocation();
    const isOn = useSwitchboard('FEATURE_GOOGLE_ANALYTICS');
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (isOn) {
            GoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        }
    }, [isOn]);

    useEffect(() => {
        GA.set({ page_title: location.pathname });
    }, [location, GA]);

    useEffect(() => {
        if (user?.id) {
            GA.set({ user_id: user.id });
        }
    }, [user, GA]);

    return null;
}
