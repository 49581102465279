import { useEffect, useState, useMemo } from 'react';
import { createEditor, Transforms, Editor } from 'slate';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import pipe from 'lodash/fp/pipe';

import { parseValue } from '../utils';
import { withMedia } from '../plugins';
import useSlateEditor from './useSlateEditor';

const DEFAULT_EDITOR_VALUE = [
    {
        type: 'div',
        children: [{ text: '' }],
    },
];

const createEditorWithPlugins = pipe(withReact, withHistory, withMedia);

export function useEditor(props) {
    const { clearInput, initialValue, value } = props;
    const [initialEditorValue, setInitialEditorValue] = useState(DEFAULT_EDITOR_VALUE);
    const [editor] = useState(() => createEditorWithPlugins(createEditor()));
    const { setEditorContext: setEditor } = useSlateEditor();

    const editorValue = useMemo(() => {
        if (value) return parseValue(value);
        return null;
    }, [value]);

    const setEditorContext = () => {
        setEditor(editor);
    };

    useEffect(() => {
        if (clearInput) {
            Transforms.delete(editor, {
                at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                },
            });
        }
    }, [editor, clearInput]);

    useEffect(() => {
        if (initialValue) {
            const valueToSet = parseValue(initialValue);
            setInitialEditorValue(valueToSet);
            Transforms.delete(editor, {
                at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                },
            });
            Transforms.removeNodes(editor, {
                at: [0],
            });
            Transforms.insertNodes(editor, valueToSet);
        }
    }, [initialValue, editor]);

    useEffect(() => {
        return () => {
            setEditor(null);
        };
    }, [setEditor]);

    return {
        editor,
        editorValue,
        initialEditorValue,
        setEditorContext,
    };
}
