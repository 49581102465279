import SvgIcon from '@mui/material/SvgIcon';

export function LocationIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M7.92857 2C9.23571 2 10.4893 2.51926 11.4136 3.44355C12.3379 4.36783 12.8571 5.62143 12.8571 6.92857C12.8571 8.969 11.3019 11.1069 8.25714 13.3905C8.16235 13.4616 8.04706 13.5 7.92857 13.5C7.81008 13.5 7.69479 13.4616 7.6 13.3905C4.55524 11.1069 3 8.969 3 6.92857C3 5.62143 3.51926 4.36783 4.44354 3.44355C5.36783 2.51926 6.62143 2 7.92857 2ZM7.92857 3.09524C6.91191 3.09524 5.93688 3.49911 5.218 4.218C4.49911 4.93688 4.09524 5.91191 4.09524 6.92857C4.09524 8.41262 5.2551 10.1245 7.62629 12.0247L7.92857 12.2635L8.23086 12.0247C10.602 10.1245 11.7619 8.41262 11.7619 6.92857C11.7619 5.91191 11.358 4.93688 10.6391 4.218C9.92026 3.49911 8.94523 3.09524 7.92857 3.09524ZM7.92857 5.83333C8.21905 5.83333 8.49762 5.94872 8.70302 6.15412C8.90842 6.35952 9.02381 6.6381 9.02381 6.92857C9.02381 7.21905 8.90842 7.49762 8.70302 7.70302C8.49762 7.90842 8.21905 8.02381 7.92857 8.02381C7.6381 8.02381 7.35952 7.90842 7.15412 7.70302C6.94872 7.49762 6.83333 7.21905 6.83333 6.92857C6.83333 6.6381 6.94872 6.35952 7.15412 6.15412C7.35952 5.94872 7.6381 5.83333 7.92857 5.83333Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
