import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parseISO, addDays } from 'date-fns';
import Box from '@mui/material/Box';

import { changeFilterStartDate, changeFilterEndDate } from 'views/Communications';
import { FilterDateChip } from 'components/FilterDateChip';
import { FilterDate } from 'components/FilterDate';
import { CalendarIcon } from 'components/Icons';

const FilterDateChipThemeProps = {
    '&.MuiChip-root': {
        marginTop: 0,
        marginLeft: (theme) => theme.spacing(2),
        color: (theme) => theme.palette.common.white,
        fontWeight: 'normal',
        font: 'inherit',
        border: 'none',
        fontSize: (theme) => theme.spacing(1.5),
        height: (theme) => theme.spacing(2.5),
        backgroundColor: (theme) => theme.palette.primary.main,
        alignItems: 'center',
    },
};

export function FilterDateRange() {
    const startDate = useSelector((state) => state.communications.filters.startDate.value);
    const endDate = useSelector((state) => state.communications.filters.endDate.value);

    const startDateStr = useSelector((state) => state.communications.filters.startDate.label);
    const endDateStr = useSelector((state) => state.communications.filters.endDate.label);

    const dispatch = useDispatch();

    const handleClearDate = useCallback(
        (event) => {
            event?.stopPropagation();
            dispatch(changeFilterStartDate(''));
            dispatch(changeFilterEndDate(''));
        },

        [dispatch]
    );

    const setStartDate = useCallback(
        (value) => {
            //prevent serializable warning on Date object with toString
            dispatch(changeFilterStartDate(value.toISOString()));
        },
        [dispatch]
    );

    const setEndDate = useCallback(
        (value) => {
            //prevent serializable warning on Date object with toString
            // we use add days in include the end date within the range filter
            dispatch(changeFilterEndDate(addDays(value, 1).toISOString()));
        },
        [dispatch]
    );

    const startDateObj = useMemo(() => (startDate ? parseISO(startDate) : null), [startDate]);

    return (
        <>
            {!endDateStr ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: (theme) => theme.spacing(1, 1.5, 0.5, 2),
                        height: (theme) => theme.spacing(5),
                        alignItems: 'flex-end',
                    }}
                >
                    <FilterDate
                        value={startDate}
                        handleDateChange={setStartDate}
                        placeholder="Start date"
                        name="startDate"
                        disableFuture
                        autoFocus
                    />

                    {startDateStr && (
                        <FilterDate
                            value={endDate}
                            handleDateChange={setEndDate}
                            placeholder="End date"
                            name="endDate"
                            minDate={startDateObj}
                            disableFuture
                            autoFocus
                        />
                    )}
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: (theme) => theme.spacing(1.25, 0.5, 0.25, 0.5),
                        height: (theme) => theme.spacing(5),
                        alignItems: 'center',
                    }}
                >
                    <FilterDateChip
                        chipName={`${startDateStr}-${endDateStr}`}
                        handleDelete={handleClearDate}
                        text="To"
                        icon={<CalendarIcon sx={{ color: 'common.white' }} />}
                        sxProps={FilterDateChipThemeProps}
                    />
                </Box>
            )}
        </>
    );
}
