import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';

import {
    FilterAccordion,
    FilterSummary,
    FilterDetails,
    SummaryChip,
    SummaryActions,
    SummaryAction,
    StyledPaper,
    FilterChipContainer,
} from './styledComponents';
import {
    addLocationFilter,
    addExcludedLocationFilter,
    clearLocationFilters,
    deleteStandardFilter,
    deleteStandardExcludeFilter,
    convertExcludeToInclude,
    convertIncludeToExclude,
} from 'views/Search/searchSlice';

import { FilterLabel } from './FilterLabel';
import { getCityStateLabel } from './utils';
import { IncludedChip } from './IncludedChip';
import { ExcludedChip } from './ExcludedChip';
import { Typography } from 'components/Typography';
import { AutocompleteSx, InputProps } from './props';
import { IncludeExcludeLabel } from './IncludeExcludeLabel';
import { LocationAutocomplete } from './LocationAutocomplete';
import { LocationRadiusSlider } from './LocationRadiusSlider';
import { LocationDropDownMenu } from './LocationDropdownMenu';
import { ChevronDownIcon, LocationIcon } from 'components/Icons';
import { LocationFilterChipDropDown } from './LocationFilterChipDropDown';
import { LOCATION_FILTER, LOCATION_FILTER_UNIT, FILTER_RADII } from './constants';

const staticFilterValue = []; //this prevents an infinite re-rendering loop

export function getLocationChipLabel(location) {
    const radialLabel = `${location.locationString}`;
    const exactLabel = getCityStateLabel(location);

    return location.exactMatch ? exactLabel : radialLabel;
}

export function FilterLocation() {
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.search.filters.radialLocation || []);
    const excludedLocations = useSelector(
        (state) => state.search.exclusionFilters.radialLocation || []
    );

    const locationKeys = [...locations, ...excludedLocations].map((location) => location.key);

    const [excludeMatch, setExcludeMatch] = useState(false);
    const [locationUnit, setLocationUnit] = useState(LOCATION_FILTER_UNIT.MI);
    const [locationRadius, setLocationRadius] = useState(0);
    const [isSliderVisible, setIsSliderVisible] = useState(false);
    const [resetAutocomplete, setResetAutocomplete] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleLocationChange = useCallback(
        (event, location) => {
            const isExactMatch = locationRadius === 0;
            const action = excludeMatch ? addExcludedLocationFilter : addLocationFilter;
            const key = `${isExactMatch}-${locationRadius}-${locationUnit}-${location.placeId}`;

            if (locationKeys.includes(key)) {
                return;
            } else {
                dispatch(
                    action({
                        key,
                        radius: locationRadius,
                        unit: locationUnit,
                        locationString: event.target.value[0],
                        latitude: location.lat,
                        longitude: location.lng,
                        city: location.city,
                        state: location.state,
                        exactMatch: isExactMatch,
                        isState: location.isState,
                        excludeMatch,
                    })
                );
            }
        },
        [dispatch, locationRadius, locationUnit, locationKeys, excludeMatch]
    );

    useEffect(() => {
        if (resetAutocomplete) {
            setResetAutocomplete(false);
        }
    }, [resetAutocomplete]);
    const handleDeleteLocation = (property, index) => {
        dispatch(deleteStandardFilter({ property, index }));
    };

    const handleDeleteExcludedLocation = (property, index) => {
        dispatch(deleteStandardExcludeFilter({ property, index }));
    };

    const handleClearLocations = (event) => {
        event.stopPropagation();
        dispatch(clearLocationFilters());
    };

    const toggleSliderVisibility = () => {
        setIsSliderVisible(!isSliderVisible);
    };
    const handleSliderChange = (newLocationRadius) => {
        const isSwitchingToOrFromExact =
            (locationRadius === 0 && newLocationRadius !== 0) ||
            (locationRadius !== 0 && newLocationRadius === 0);

        setResetAutocomplete(isSwitchingToOrFromExact);
        setLocationRadius(newLocationRadius);
    };

    const handleExcludeMatchChange = (event) => {
        setExcludeMatch(event.target.checked);
    };

    const handleClickInclude = (property, index) => {
        dispatch(convertExcludeToInclude({ property, index }));
    };

    const handleClickExclude = (property, index) => {
        dispatch(convertIncludeToExclude({ property, index }));
    };

    const activeFiltersCount = locations.length + excludedLocations.length;

    return (
        <FilterAccordion
            id="filter-location"
            data-trackid={
                isOpen ? 'location-filters-drawer-collapse' : 'location-filters-drawer-expand'
            }
            onChange={(e, expanded) => setIsOpen(expanded)}
        >
            <FilterSummary
                expandIcon={<ChevronDownIcon />}
                aria-controls="filter-location-content"
                aria-label="filter-location-header"
            >
                <LocationIcon sx={{ color: alpha('#ffffff', 0.48) }} />
                <Typography
                    variant="labelSmall"
                    sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
                >
                    Location (USA ONLY)
                </Typography>
                <SummaryActions>
                    {Boolean(activeFiltersCount) && (
                        <SummaryAction
                            variant="text"
                            onClick={handleClearLocations}
                            aria-label="filter-locations-clear"
                        >
                            Clear
                        </SummaryAction>
                    )}
                    <SummaryChip label={activeFiltersCount} />
                </SummaryActions>
            </FilterSummary>

            <FilterDetails id="filter-location-content">
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <LocationAutocomplete
                        fullWidth
                        disableTags
                        disableClearable
                        multiple
                        clearOnBlur={resetAutocomplete || false}
                        openOnFocus={false}
                        forcePopupIcon={false}
                        PaperComponent={StyledPaper}
                        onChange={handleLocationChange}
                        value={staticFilterValue}
                        setDropDownVisibility={setIsSliderVisible}
                        shouldResetInputValue={resetAutocomplete}
                        shouldShowState={locationRadius === 0}
                        label={
                            <FilterLabel
                                label="Filter candidates by location"
                                checked={excludeMatch}
                                onChange={handleExcludeMatchChange}
                                dataTrackId="location-filters-drawer-exclude"
                            />
                        }
                        sx={{ flex: 1 }}
                        TextInputProps={{
                            sx: AutocompleteSx,
                            InputProps: {
                                ...InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LocationDropDownMenu
                                            locationUnit={locationUnit}
                                            locationRadius={locationRadius}
                                            openMenu={isSliderVisible}
                                            handleButtonClick={toggleSliderVisibility}
                                            setIsSliderVisible={setIsSliderVisible}
                                        >
                                            <LocationRadiusSlider
                                                locationRadius={locationRadius}
                                                locationUnit={locationUnit}
                                                onChange={handleSliderChange}
                                                setLocationUnit={setLocationUnit}
                                                markValueMapping={FILTER_RADII}
                                            />
                                        </LocationDropDownMenu>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                </Box>
                {Boolean(locations.length) && (
                    <>
                        <IncludeExcludeLabel label="Include" />
                        <FilterChipContainer>
                            {locations.map((location, index) => (
                                <IncludedChip
                                    key={location.key}
                                    label={
                                        <>
                                            <LocationFilterChipDropDown
                                                location={location}
                                                index={index}
                                                locations={locations}
                                            />
                                            {getLocationChipLabel(location)}
                                        </>
                                    }
                                    index={index}
                                    filterKey={LOCATION_FILTER}
                                    onClick={handleClickExclude}
                                    onDelete={handleDeleteLocation}
                                    isCustomLabel
                                    disableTooltip
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}

                {Boolean(excludedLocations.length) && (
                    <>
                        <IncludeExcludeLabel label="Exclude" />
                        <FilterChipContainer>
                            {excludedLocations.map((location, index) => (
                                <ExcludedChip
                                    key={location.key}
                                    label={
                                        <>
                                            <LocationFilterChipDropDown
                                                location={location}
                                                index={index}
                                                locations={excludedLocations}
                                            />
                                            {getLocationChipLabel(location)}
                                        </>
                                    }
                                    index={index}
                                    filterKey={LOCATION_FILTER}
                                    onClick={handleClickInclude}
                                    onDelete={handleDeleteExcludedLocation}
                                    isCustomLabel
                                    disableTooltip
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
            </FilterDetails>
        </FilterAccordion>
    );
}
