import SvgIcon from '@mui/material/SvgIcon';

export function NoteUserIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M13.1429 2H8C7.77274 2.00023 7.55486 2.09061 7.39416 2.2513C7.23346 2.412 7.14308 2.62988 7.14286 2.85714V7.14286H2.85714C2.62988 7.14308 2.412 7.23346 2.2513 7.39416C2.09061 7.55486 2.00023 7.77274 2 8V14H14V2.85714C13.9998 2.62988 13.9094 2.412 13.7487 2.2513C13.588 2.09061 13.3701 2.00023 13.1429 2ZM5 13.1429V10.1429H6.71429V13.1429H5ZM13.1429 13.1429H7.57143V9.71429C7.57143 9.60062 7.52628 9.49161 7.4459 9.41124C7.36553 9.33087 7.25652 9.28571 7.14286 9.28571H4.57143C4.45776 9.28571 4.34876 9.33087 4.26838 9.41124C4.18801 9.49161 4.14286 9.60062 4.14286 9.71429V13.1429H2.85714V8H8V2.85714H13.1429V13.1429Z"
                fill="currentColor"
            />
            <path
                d="M8.85714 4.57143H9.71429V5.42857H8.85714V4.57143ZM11.4286 4.57143H12.2857V5.42857H11.4286V4.57143ZM8.85714 7.14286H9.71429V8H8.85714V7.14286ZM11.4286 7.14286H12.2857V8H11.4286V7.14286ZM8.85714 9.71429H9.71429V10.5714H8.85714V9.71429ZM11.4286 9.71429H12.2857V10.5714H11.4286V9.71429Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
