import SvgIcon from '@mui/material/SvgIcon';

export function RemoveIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M14.427 15.2361L17.0234 17.8324C17.1307 17.9397 17.2762 18 17.4279 18C17.5796 18 17.7251 17.9397 17.8324 17.8324C17.9397 17.7251 18 17.5796 18 17.4279C18 17.2762 17.9397 17.1307 17.8324 17.0234L2.97663 2.16756C2.92351 2.11444 2.86044 2.0723 2.79103 2.04355C2.72162 2.0148 2.64723 2 2.5721 2C2.49697 2 2.42258 2.0148 2.35317 2.04355C2.28376 2.0723 2.22069 2.11444 2.16756 2.16756C2.11444 2.22069 2.0723 2.28376 2.04355 2.35317C2.0148 2.42258 2 2.49697 2 2.5721C2 2.64723 2.0148 2.72162 2.04355 2.79103C2.0723 2.86044 2.11444 2.92351 2.16756 2.97663L4.7639 5.57297C3.65286 6.88591 3.07585 8.56845 3.14729 10.2869C3.21873 12.0054 3.93342 13.6342 5.1496 14.8504C6.36579 16.0666 7.99463 16.7813 9.71309 16.8527C11.4315 16.9242 13.1141 16.3471 14.427 15.2361ZM13.6157 14.4247C12.519 15.3221 11.1281 15.7799 9.71282 15.7093C8.29759 15.6388 6.95906 15.0449 5.9571 14.0429C4.95514 13.0409 4.36122 11.7024 4.29066 10.2872C4.2201 8.87195 4.67793 7.48098 5.57526 6.38433L13.6157 14.4236V14.4247ZM15.7138 10C15.7138 10.9531 15.4806 11.8524 15.067 12.6432L15.9069 13.4831C16.6805 12.1735 16.9967 10.6439 16.8057 9.13499C16.6147 7.62604 15.9274 6.22342 14.852 5.14786C13.7766 4.07229 12.374 3.38484 10.8651 3.19369C9.3562 3.00254 7.82655 3.31854 6.51689 4.09196L7.35681 4.93189C8.22788 4.47737 9.20144 4.25516 10.1835 4.2867C11.1655 4.31825 12.1228 4.60249 12.9629 5.11198C13.803 5.62146 14.4975 6.33897 14.9794 7.19521C15.4613 8.05145 15.7142 9.01748 15.7138 10Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
