import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

import { Typography } from 'components/Typography';
import { MessageLengthSelector } from './MessageLengthSelector';
import { MessageSourceSelector } from './MessageSourceSelector';
import { MessageOptionSourceSelector } from './MessageSourceOptionSelector';
import { MESSAGE_LENGTH_OPTIONS, MESSAGE_SOURCE_OPTIONS } from 'features/MessageDrawer/utils';
import { useStreamMessage } from 'features/MessageDrawer/hooks/useStreamMessage';

const borderGradient = 'linear-gradient(180deg, #7950DF -3.05%, #3A8DD3 100%)';

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    backgroundImage: borderGradient,
    backgroundOrigin: 'border-box',
    boxShadow: 'inset 0 100vw white',
    border: '1px solid transparent',
    borderRadius: theme.spacing(0.5),
}));

const ApplyButton = styled(ButtonBase)(({ theme }) => ({
    backgroundImage: borderGradient,
    backgroundOrigin: 'border-box',
    boxShadow: 'inset 0 100vw white',
    border: '1px solid transparent',
    borderRadius: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1.25, 0),
}));

const resolveSource = (pathname) => {
    switch (pathname) {
        case '/search/results':
            return MESSAGE_SOURCE_OPTIONS.talentSearchHistory.value;
        default:
            return '';
    }
};

export function MessageGenerationOptions(props) {
    const { overlayHeight } = props;
    const isCollapsed = useSelector((state) => state.message.isMessageGenerationCollapsed);

    const [source, setSource] = useState('');
    const [sourceId, setSourceId] = useState('');
    const [length, setLength] = useState(MESSAGE_LENGTH_OPTIONS.medium.value);

    const { handleGenerateMessage } = useStreamMessage();

    const location = useLocation();

    const initialSource = resolveSource(location.pathname);

    const handleChangeSource = (event) => {
        setSource(event.target.value);
    };

    const resetSourceId = () => {
        setSourceId('');
    };

    const handleApply = () => {
        handleGenerateMessage({
            source,
            sourceId,
            length,
        });
    };

    return (
        <Collapse
            in={isCollapsed}
            sx={{
                boxSizing: 'border-box',
                position: 'absolute',
                zIndex: 1,
                width: '100%',
                top: 69,
            }}
        >
            <Container sx={{ minHeight: overlayHeight }}>
                <MessageSourceSelector
                    initialSource={initialSource}
                    value={source}
                    handleChangeSource={handleChangeSource}
                    resetSourceId={resetSourceId}
                />
                <MessageOptionSourceSelector
                    source={source}
                    initialSource={initialSource}
                    value={sourceId}
                    setValue={setSourceId}
                />
                <MessageLengthSelector value={length} setValue={setLength} />
                {source && sourceId && length && (
                    <ApplyButton onClick={handleApply} aria-label="apply-options-button">
                        <Typography variant="titleSmall">Apply</Typography>
                    </ApplyButton>
                )}
            </Container>
        </Collapse>
    );
}

MessageGenerationOptions.propTypes = {
    overlayHeight: PropTypes.number,
};
