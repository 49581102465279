import SvgIcon from '@mui/material/SvgIcon';

export function FormattingIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M14.0008 2.66663H6.66081C6.29414 2.66663 5.99414 2.96663 5.99414 3.33329C5.99414 3.69996 6.29414 3.99996 6.66081 3.99996H9.68748V12.6533C9.68748 13.02 9.98748 13.32 10.3541 13.32C10.7208 13.32 11.0208 13.02 11.0208 12.6533V3.99996H14.0008C14.3675 3.99996 14.6675 3.69996 14.6675 3.33329C14.6675 2.96663 14.3675 2.66663 14.0008 2.66663Z"
                fill="url(#paint0_linear_19546_6199)"
            />
            <path
                d="M7.33335 6H2.01335C1.64668 6 1.34668 6.3 1.34668 6.66667C1.34668 7.03333 1.64668 7.33333 2.01335 7.33333H4.01335V12.7C4.01335 13.0667 4.31335 13.3667 4.68001 13.3667C5.04668 13.3667 5.34668 13.0667 5.34668 12.7V7.33333H7.33335C7.70002 7.33333 8.00002 7.03333 8.00002 6.66667C8.00002 6.3 7.70002 6 7.33335 6Z"
                fill="url(#paint1_linear_19546_6199)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_19546_6199"
                    x1="5.99414"
                    y1="6.49283"
                    x2="14.6697"
                    y2="6.5268"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_19546_6199"
                    x1="1.34668"
                    y1="8.64578"
                    x2="8.0017"
                    y2="8.67469"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}
