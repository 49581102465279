import SvgIcon from '@mui/material/SvgIcon';

export function StarIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M10.5313 6.62817C10.6243 6.43962 10.7682 6.28086 10.9467 6.16985C11.1253 6.05884 11.3313 6 11.5415 6C11.7517 6 11.9578 6.05884 12.1363 6.16985C12.3148 6.28086 12.4587 6.43962 12.5517 6.62817L14.1173 9.79878L17.6173 10.307C17.8254 10.3372 18.0209 10.4251 18.1816 10.5606C18.3423 10.6962 18.4619 10.874 18.5268 11.074C18.5917 11.274 18.5993 11.4881 18.5488 11.6922C18.4983 11.8963 18.3917 12.0822 18.241 12.2288L15.7082 14.6969L16.3066 18.1829C16.3422 18.3899 16.3191 18.6028 16.2401 18.7975C16.161 18.9922 16.0291 19.1608 15.8592 19.2844C15.6893 19.408 15.4882 19.4817 15.2787 19.497C15.0691 19.5123 14.8595 19.4687 14.6734 19.3711L11.5408 17.7239L8.40963 19.3711C8.22356 19.4687 8.01391 19.5123 7.80436 19.497C7.59482 19.4817 7.39374 19.408 7.22384 19.2844C7.05395 19.1608 6.92202 18.9922 6.84296 18.7975C6.7639 18.6028 6.74086 18.3899 6.77646 18.1829L7.37341 14.6969L4.84058 12.2288C4.68989 12.0821 4.58329 11.8961 4.53285 11.6919C4.48242 11.4877 4.49018 11.2735 4.55524 11.0734C4.6203 10.8734 4.74007 10.6956 4.90098 10.5602C5.06189 10.4247 5.25751 10.337 5.46569 10.307L8.96575 9.80019L10.5299 6.62817H10.5313ZM11.5422 7.76294L10.1625 10.5548C10.0819 10.7184 9.9628 10.86 9.81541 10.9675C9.66802 11.0749 9.49675 11.1449 9.31632 11.1715L6.233 11.6192L8.46454 13.793C8.59526 13.9205 8.69304 14.0778 8.74945 14.2515C8.80586 14.4251 8.81921 14.6099 8.78836 14.7898L8.2618 17.859L11.0185 16.4089C11.18 16.3241 11.3598 16.2797 11.5422 16.2797C11.7247 16.2797 11.9044 16.3241 12.066 16.4089L14.8226 17.859L14.2961 14.7898C14.2652 14.6099 14.2786 14.4251 14.335 14.2515C14.3914 14.0778 14.4892 13.9205 14.6199 13.793L16.85 11.6178L13.7681 11.1701C13.5874 11.1437 13.4159 11.0738 13.2682 10.9663C13.1206 10.8589 13.0013 10.7172 12.9206 10.5534L11.5408 7.76294H11.5422Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
