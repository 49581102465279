import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';

import { TYPOGRAPHY_VARIANTS } from 'components/Typography';

export const StyledDropdownMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 4,
        marginTop: theme.spacing(1),
        minWidth: 200,
        maxWidth: 500,
        boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
        filter: 'drop-shadow(0px 1px 2px rgba(63, 83, 100, 0.32))',
        '& .MuiMenu-list': {
            padding: '0',
        },
        '& .MuiMenuItem-root': {
            ...TYPOGRAPHY_VARIANTS.bodyBaseBold,
            color: theme.palette.primary.main,
        },
    },
}));
