import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

export function ChevronDownIcon(props) {
    const { gradient, ...restProps } = props;
    const fill = gradient ? 'url(#paint0_linear_2667_36330)' : 'currentColor';
    return (
        <SvgIcon viewBox="0 0 12 12" {...restProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.17139 4.18766C8.92118 3.93745 8.5155 3.93745 8.26528 4.18766L6 6.45294L3.73472 4.18766C3.4845 3.93745 3.07882 3.93745 2.82861 4.18766C2.57839 4.43788 2.57839 4.84356 2.82861 5.09377L5.54694 7.81211C5.79716 8.06233 6.20284 8.06233 6.45306 7.81211L9.17139 5.09377C9.42161 4.84356 9.42161 4.43788 9.17139 4.18766Z"
                fill={fill}
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2667_36330"
                    x1="-3.36069e-08"
                    y1="5.62821"
                    x2="12.0022"
                    y2="5.74012"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

ChevronDownIcon.propTypes = {
    gradient: PropTypes.bool,
};
