import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { KEYCLOAK_ROLES } from 'utils/constants';

// export for tests
export const isPendoInitialized = { current: false };

export function PendoTracker() {
    const user = useSelector((state) => state.user);

    useEffect(() => {
        const pendo = window.pendo;

        if (!user?.id || !pendo) return;

        const orgId = user.attributes?.organizationId?.[0];
        const orgName = user.attributes?.organizationName?.[0];

        const pendoMethod = isPendoInitialized.current ? pendo.identify : pendo.initialize;

        pendoMethod({
            visitor: {
                id: user.id,
                email: user.email,
                full_name: `${user.firstName} ${user.lastName}`,
                role: user.isSalesManager ? KEYCLOAK_ROLES.SALES_MANAGER : null,
            },
            account: {
                id: orgId,
                name: orgName,
            },
        });

        isPendoInitialized.current = true;
    }, [user]);

    return null;
}
