import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { useState, useCallback, useEffect, forwardRef } from 'react';

import {
    AutocompleteRoot as Root,
    StyledAutocomplete,
    AutocompleteChip as StyledChip,
} from 'views/Search/features/FiltersBar/components/styledComponents';
import { Typography } from 'components/Typography';
import { TextInput } from 'components/Inputs/TextInput';
import { ChevronDownIcon, CloseIcon, CheckCircleIcon } from 'components/Icons';
import { getOptionLabel, filterOptions, getValues, deleteChip } from './utils';

export const MultiTextInput = forwardRef((props, ref) => {
    const {
        id,
        name,
        inlineTags,
        onChange,
        exact,
        value,
        label,
        placeholder,
        error,
        TextInputProps,
        inputProps,
        ChipProps,
        PopupIcon,
        disableTags,
        options: _options,
        ...restProps
    } = props;

    const readOnly = props.readOnly || props.InputProps?.readOnly;
    const disabled = props.disabled || props.InputProps?.disabled;

    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [optionsLoaded, setOptionsLoaded] = useState(false);

    const onChangeEvent = useCallback(
        (values) => ({
            target: {
                id,
                name,
                value: values,
            },
        }),
        [name, id]
    );

    const handleFetchOptions = useCallback(async () => {
        const loadedOptions = typeof _options === 'function' ? await _options() : _options || [];
        setOptions([
            // Add free text values to options (if any)
            ...value
                .filter((val) => !loadedOptions.find((op) => val === op.value))
                .map((val) => ({ value: val, label: val })),
            ...loadedOptions,
        ]);
        setOptionsLoaded(true);
    }, [_options, value]);

    const handleUpdateOptions = useCallback((newOption) => {
        setOptions((currentOptions) => [{ value: newOption, label: newOption }, ...currentOptions]);
    }, []);

    const handleUpdate = useCallback(
        (newValues) => {
            onChange(onChangeEvent(newValues));
        },
        [onChange, onChangeEvent]
    );

    const handleDeleteChip = useCallback(
        (value, values) => deleteChip(value, values, handleUpdate),
        [handleUpdate]
    );

    const handleInputChange = useCallback(
        (event) => {
            if (!exact) setInputValue(event.target.value);
        },
        [exact]
    );

    const handleChange = (event, newValue) => {
        if (event && event.code === 'Backspace') return false;
        handleUpdate(getValues(newValue, value));
    };

    const handleKeyDown = (event) => {
        if (!exact) {
            if (event.code === 'Enter') {
                const newValue = [{ value: event.target.value }];
                handleChange(undefined, newValue);
                setInputValue('');
            } else if (event.code === 'Escape') {
                setInputValue('');
            }
        }
    };

    const getInputProps = ({ InputProps }) => {
        if (!optionsLoaded) {
            return {
                ...InputProps,
                endAdornment: (
                    <>
                        <CircularProgress color="inherit" size={20} />
                        {InputProps.endAdornment}
                    </>
                ),
            };
        }
        return InputProps;
    };

    // Fetch and initalize options
    useEffect(() => {
        let isMounted = true;
        if (isMounted && !optionsLoaded) {
            handleFetchOptions();
        }
        return () => {
            isMounted = false;
        };
    }, [handleFetchOptions, optionsLoaded]);

    // On value updates, add values to options that are not currently in the options array
    useEffect(() => {
        const newValue = value[value.length - 1];
        if (
            !exact &&
            optionsLoaded &&
            newValue &&
            !options.find((option) => option.value === newValue)
        ) {
            // Add new free text values to options (so they can be selected/deselected)
            handleUpdateOptions(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, exact, handleUpdateOptions]);

    const filterOptionsFunc = filterOptions(exact);

    const PopupIconNode = PopupIcon || ChevronDownIcon;

    const InputLabelProps = TextInputProps?.InputLabelProps;

    return (
        <Root>
            <StyledAutocomplete
                ref={ref}
                multiple
                autoHighlight
                clearOnBlur={false}
                id={id}
                disabled={disabled || readOnly}
                disableClearable={disabled || readOnly}
                aria-readonly={readOnly}
                options={options}
                value={value}
                inputValue={inputValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                getOptionLabel={getOptionLabel}
                filterOptions={filterOptionsFunc}
                isOptionEqualToValue={(option, value) => value === option.value}
                popupIcon={<PopupIconNode color={disabled || readOnly ? 'disabled' : 'primary'} />}
                noOptionsText={
                    exact ? (
                        <Typography variant="labelLarge" noWrap {...InputLabelProps}>
                            No options
                        </Typography>
                    ) : (
                        <Typography variant="labelLarge" noWrap {...InputLabelProps}>
                            Type any value
                        </Typography>
                    )
                }
                renderOption={(props, option, { selected }) => (
                    <li {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="titleSmall" color="textPrimary" noWrap>
                            {option.inputLabel || option.label}
                        </Typography>
                        {selected ? (
                            <CheckCircleIcon
                                color="success"
                                sx={{
                                    ml: 'auto',
                                }}
                            />
                        ) : (
                            <CheckCircleIcon
                                sx={{
                                    ml: 'auto',
                                    color: 'transparent',
                                }}
                            />
                        )}
                    </li>
                )}
                renderTags={(values) => {
                    if (disableTags) {
                        return null;
                    }
                    const tooltip = values.join(', ');
                    if (Boolean(inlineTags)) {
                        return (
                            <>
                                {values.slice(0, inlineTags).map((value) => (
                                    <StyledChip
                                        size="small"
                                        label={value}
                                        variant="outlined"
                                        deleteIcon={<CloseIcon />}
                                        key={`chip-display-${value}`}
                                        disabled={disabled || readOnly}
                                        onDelete={() => handleDeleteChip(value, values)}
                                        color={disabled || readOnly ? 'disabled' : 'primary'}
                                        {...ChipProps}
                                    />
                                ))}
                                {values.length > inlineTags && (
                                    <StyledChip
                                        variant="outlined"
                                        color={disabled || readOnly ? 'disabled' : 'primary'}
                                        disabled={disabled || readOnly}
                                        title={tooltip}
                                        label="..."
                                        size="small"
                                        {...ChipProps}
                                    />
                                )}
                            </>
                        );
                    }
                    return (
                        <StyledChip
                            key={`chip-display-${tooltip}`}
                            sx={{
                                ml: 2,
                            }}
                            title={tooltip}
                            variant="outlined"
                            color={disabled || readOnly ? 'disabled' : 'primary'}
                            disabled={disabled || readOnly}
                            label={values.length}
                            size="small"
                            {...ChipProps}
                        />
                    );
                }}
                renderInput={(params) => (
                    <TextInput
                        {...params}
                        label={label}
                        autoComplete="off"
                        error={error}
                        placeholder={
                            placeholder || (exact ? 'Select an option...' : 'Type any value...')
                        }
                        InputProps={getInputProps(params)}
                        inputProps={{
                            ...params.inputProps,
                            ...inputProps,
                        }}
                        disabled={disabled}
                        readOnly={readOnly}
                        onChange={handleInputChange}
                        {...TextInputProps}
                    />
                )}
                {...restProps}
            />
        </Root>
    );
});

MultiTextInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.node,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    className: PropTypes.string,
    exact: PropTypes.bool,
    inlineTags: PropTypes.number,
    disableTags: PropTypes.bool,
    PopupIcon: PropTypes.any,
    error: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    ChipProps: PropTypes.object,
    InputProps: PropTypes.object,
    TextInputProps: PropTypes.object,
    inputProps: PropTypes.object,
};
