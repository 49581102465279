import {
    LOCATION_FILTER,
    EXPERIENCE_FILTER,
    EMPLOYMENT_FILTER,
} from 'views/Search/features/FiltersBar/components/constants';
import { getIndustryLabel } from 'views/Search/features/FiltersBar/components/FilterIndustries';
import { getLocationChipLabel } from 'views/Search/features/FiltersBar/components/FilterLocation';
import { getOccupationLabel } from 'views/Search/features/FiltersBar/components/FilterOccupations';

export const selectStandardFilters = (filters = {}) => {
    const standardFilters = [];
    Object.entries(filters).forEach(([key, values]) => {
        if (key !== 'keywords' && key !== 'poolIds' && values?.length) {
            if (key === EXPERIENCE_FILTER.YEARS_OF_EXPERIENCE && Boolean(values)) {
                let minCopy = values[0];
                let maxCopy = values[1];
                if (values[1] === 100) {
                    maxCopy = '30+';
                }
                standardFilters.push({
                    property: key,
                    value: `Between ${minCopy} and ${maxCopy} years of experience`,
                });
            } else
                values.forEach((value, index) => {
                    if (value) {
                        standardFilters.push({ property: key, value, index });
                    }
                });
        }
    });
    return standardFilters;
};

export const selectStandardExcludedFilters = (exclusionFilters = {}) => {
    const standardFilters = [];
    Object.entries(exclusionFilters).forEach(([key, values]) => {
        if (values?.length) {
            values.forEach((value, index) => {
                if (value) {
                    standardFilters.push({ property: key, value, index });
                }
            });
        }
    });
    return standardFilters;
};

export const getFilterLabel = (property, value) => {
    if (property === LOCATION_FILTER) {
        return getLocationChipLabel(value);
    } else if (
        property === EMPLOYMENT_FILTER.OCCUPATION ||
        property === EMPLOYMENT_FILTER.CURRENT_OCCUPATION
    ) {
        return getOccupationLabel(value);
    } else if (
        property === EMPLOYMENT_FILTER.INDUSTRY ||
        property === EMPLOYMENT_FILTER.CURRENT_INDUSTRY
    ) {
        return getIndustryLabel(value);
    } else {
        return value;
    }
};
