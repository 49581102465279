import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_PAYLOAD, editSchedule } from 'views/UserSettings/features/Scheduling';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { AddIcon } from 'components/Icons';

const Card = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    width: theme.spacing(49),
    maxWidth: theme.spacing(49),
    height: theme.spacing(16.25),
    margin: theme.spacing(0.5, 0.25),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0.5, 'auto', 0.5, 'auto'),
        padding: theme.spacing(1),
        width: '100%',
        maxWidth: '100%',
    },
    border: `1px dashed ${theme.palette.primary.main}`,
}));

const ActionButton = styled(Button)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.1,
    textTransform: 'none',
    color: theme.palette.primary.main,
}));

export function AddScheduling() {
    const dispatch = useDispatch();

    const handleAddSchedule = useCallback(() => {
        dispatch(editSchedule({ payload: DEFAULT_PAYLOAD }));
    }, [dispatch]);

    return (
        <Card>
            <ActionButton
                startIcon={<AddIcon />}
                onClick={handleAddSchedule}
                disableFocusRipple
                disableRipple
                aria-label="add-scheduling-page"
            >
                Add Scheduling Link
            </ActionButton>
        </Card>
    );
}
