import { alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import {
    FilterAccordion,
    FilterSummary,
    FilterDetails,
    SummaryChip,
    SummaryActions,
    StyledPaper,
    SummaryAction,
    FilterChipContainer,
} from './styledComponents';
import { FilterChip } from './FilterChip';
import { deleteTextFilter } from './utils';
import { Typography } from 'components/Typography';
import { MultiTextInput } from 'components/Inputs';
import { CANDIDATE_INFO_FILTER } from './constants';
import { IncludeExcludeLabel } from './IncludeExcludeLabel';
import { TextInputProps, InfoTextInputProps } from './props';
import { changeStandardFilter } from 'views/Search/searchSlice';
import { CloseIcon, PeopleIcon, ChevronDownIcon } from 'components/Icons';

const tooltipTitle = 'ByeBias must be turned off to use name filter';

const selectCandidateNamesFilterValue = (state) => {
    const filter = state.search.filters[CANDIDATE_INFO_FILTER.CANDIDATE_NAME];
    return filter || [];
};

export function FilterName() {
    const namesFilterValue = useSelector(selectCandidateNamesFilterValue);
    const isByeBiasOn = useSelector((state) => state.byeBias.on);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();

    const handleCandidateNameChange = useCallback(
        (event) => {
            dispatch(
                changeStandardFilter({
                    property: CANDIDATE_INFO_FILTER.CANDIDATE_NAME,
                    matchText: event.target.value,
                })
            );
        },
        [dispatch]
    );

    const handleDeleteCandidateName = (value, values, property) =>
        deleteTextFilter(value, values, property, dispatch);

    const handleClearName = useCallback(
        (event) => {
            event.stopPropagation();
            dispatch(
                changeStandardFilter({
                    property: CANDIDATE_INFO_FILTER.CANDIDATE_NAME,
                    matchText: [],
                })
            );
        },
        [dispatch]
    );

    useEffect(() => {
        if (isByeBiasOn && namesFilterValue.length) {
            dispatch(
                changeStandardFilter({
                    property: CANDIDATE_INFO_FILTER.CANDIDATE_NAME,
                    matchText: [],
                })
            );
        }
    }, [isByeBiasOn, namesFilterValue, dispatch]);

    return (
        <FilterAccordion
            id="filter-candidate-name"
            onChange={(event, expanded) => setOpen(expanded)}
            data-trackid={
                open ? 'candidate-name-filters-collapse' : 'candidate-name-filters-expand'
            }
        >
            <FilterSummary
                expandIcon={<ChevronDownIcon />}
                aria-controls="filter-candidate-name-content"
                aria-label="filter-candidate-name-header"
            >
                <PeopleIcon
                    sx={{
                        color: alpha('#ffffff', 0.48),
                    }}
                />
                <Typography
                    variant="labelSmall"
                    sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
                >
                    Candidate Name
                </Typography>
                <SummaryActions>
                    {Boolean(namesFilterValue.length) && (
                        <SummaryAction
                            variant="text"
                            onClick={handleClearName}
                            aria-label="filter-keyword-clear"
                            data-trackid="candidate-name-filters-clear"
                        >
                            Clear
                        </SummaryAction>
                    )}
                    <SummaryChip label={namesFilterValue.length} />
                </SummaryActions>
            </FilterSummary>
            <FilterDetails id="filter-candidate-name-content">
                <MultiTextInput
                    fullWidth
                    disableTags
                    disableClearable
                    disabled={isByeBiasOn}
                    value={namesFilterValue}
                    openOnFocus={false}
                    forcePopupIcon={false}
                    PaperComponent={StyledPaper}
                    onChange={handleCandidateNameChange}
                    TextInputProps={
                        isByeBiasOn ? InfoTextInputProps({ tooltipTitle }) : TextInputProps
                    }
                    inputProps={{
                        'data-trackid': 'candidate-name-filter-input',
                    }}
                    id="filter-keyword-multi-input"
                    label="Filter by Candidate Name"
                />
                {Boolean(namesFilterValue.length) && (
                    <>
                        <IncludeExcludeLabel label="Include" />
                        <FilterChipContainer>
                            {namesFilterValue.map((candidateName) => (
                                <FilterChip
                                    key={`candidate-name-chip-${candidateName}`}
                                    label={candidateName}
                                    deleteIcon={
                                        <CloseIcon
                                            aria-label={`candidate-name-chip-delete-${candidateName}`}
                                        />
                                    }
                                    onDelete={() =>
                                        handleDeleteCandidateName(
                                            candidateName,
                                            namesFilterValue,
                                            CANDIDATE_INFO_FILTER.CANDIDATE_NAME
                                        )
                                    }
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
            </FilterDetails>
        </FilterAccordion>
    );
}
