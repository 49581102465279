import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import { SearchIcon } from 'components/Icons';

const IconAvatar = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
}));

export function ContactIcon(props) {
    const { icon, count, onClick, isLoading, ...restProps } = props;

    const highCount = count > 9;
    const padding = highCount ? 0.5 : 0;
    const countLabel = highCount ? '9+' : count;

    let badgeContent = <SearchIcon sx={{ width: 10, height: 10 }} />;
    if (typeof count === 'number') {
        badgeContent = <Box sx={{ mt: -0.25 }}>{countLabel}</Box>;
    }

    if (isLoading) {
        return (
            <Skeleton
                variant="circular"
                animation="wave"
                width={32}
                height={32}
                title="Loading..."
            />
        );
    }

    return (
        <Badge
            badgeContent={badgeContent}
            sx={{ '& .MuiBadge-badge': { p: padding, height: 16, minWidth: 16 } }}
            overlap="circular"
            color="secondary"
        >
            <IconAvatar role="button" onClick={onClick} {...restProps}>
                {icon}
            </IconAvatar>
        </Badge>
    );
}

ContactIcon.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.node,
    count: PropTypes.number,
    isLoading: PropTypes.bool,
};
