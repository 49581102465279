export function useTagSuggestions({
    attachments,
    setAttachments,
    handleTagChange,
    multiTagsValue,
    currentTab,
}) {
    const isResumeTagDetected = (attachment) => {
        const resumeKeywords = ['resume', 'cv'];
        const requiredFileTypes = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'text/plain',
            'text/rtf',
        ];
        const hasResumeKeywords = resumeKeywords.some((keyword) =>
            attachment.filename.toLowerCase().includes(keyword)
        );
        const hasRequiredFileTypes = requiredFileTypes.some((type) =>
            attachment.mimetype.includes(type)
        );

        return hasResumeKeywords && hasRequiredFileTypes;
    };

    const getTagSuggestions = (attachment) => {
        const suggestions = [];
        if (isResumeTagDetected(attachment)) suggestions.push('Resume');
        return suggestions;
    };

    const attachmentsWithSuggestedTags = attachments.map((attachment) => {
        return {
            ...attachment,
            suggestedTags: attachment?.suggestedTags || getTagSuggestions(attachment),
        };
    });

    const filterOutClickedTag = (tagToRemove, handle) => {
        return attachmentsWithSuggestedTags
            .filter((attachment) => attachment.handle !== handle)
            .map((attachment) => attachment.suggestedTags)
            .flat()
            .filter((tag) => tag !== tagToRemove);
    };

    const onSuggestedTagClick = (handle, tag) => {
        setAttachments((attachments) =>
            attachments.map((attachment) => {
                if (attachment.handle === handle) {
                    return {
                        ...attachment,
                        suggestedTags: filterOutClickedTag(tag, handle),
                    };
                }
                return attachment;
            })
        );
        handleTagChange(null, [...(multiTagsValue[handle] || []), tag], handle);
    };

    return {
        attachmentsWithSuggestedTags,
        onSuggestedTagClick,
    };
}
