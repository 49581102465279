import { STATUS_MAP, STATUS_ORIGIN_ENUMS } from './constants';

export const getTalentEmailStatus = (talent) => {
    if (talent?.userEmailStatus) {
        return {
            currentStatus: talent.userEmailStatus,
            lastEmailDate: talent.lastUserEmailDate,
            origin: STATUS_ORIGIN_ENUMS.USER,
        };
    }
    if (talent?.organizationEmailStatus) {
        return {
            currentStatus: talent.organizationEmailStatus,
            lastEmailDate: talent.lastOrganizationEmailDate,
            origin: STATUS_ORIGIN_ENUMS.ORGANIZATION,
        };
    }
    return {};
};

export const getContactStatusMessage = (origin, name = 'Candidate', currentStatus, time) => {
    if (!currentStatus || !origin) {
        return `${name} has not been contacted`;
    }
    if (currentStatus === STATUS_MAP.FAILED) {
        return 'Message has failed to send';
    }
    if (origin === STATUS_ORIGIN_ENUMS.USER) {
        return `${name} was contacted on ${time}`;
    }
    if (origin === STATUS_ORIGIN_ENUMS.ORGANIZATION) {
        return `${name} was previously contacted by a member of your organization on ${time}`;
    }
};
