import { create } from 'zustand';

const initialState = {
    isModalOpen: false,
    view: 'upgrade',
    isLoading: false,
};

export const useUpgradeAccountPromptStore = create((set) => ({
    ...initialState,
    stripeConfig: null,
    openModal: (view = 'upgrade') => set({ isModalOpen: true, view }),
    closeModal: () => set({ isModalOpen: false, view: 'upgrade' }),
    setLoading: (isLoading) => set({ isLoading }),
    setStripeConfig: (stripeConfig) => set({ stripeConfig }),
}));
