import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Typography } from 'components/Typography';
import { RichTextInput } from 'components/Inputs';
import { useMessageEditor } from 'features/MessageDrawer/hooks/useMessageEditor';
import { useDynamicFields } from 'features/DynamicFields';
import { TARGET_ENUMS } from 'features/MessageDrawer/utils';

const TextInputProps = {
    'aria-label': 'subject-input',
    'data-trackid': 'message-drawer-subject-input',
};

export function SubjectEditor(props) {
    // @TODO: add subject validation - can be done with props or within this component up to dev's discretion
    const { autofocus, isFetching } = props;

    const { innerHtml, initialBody, clearInput, validationError, innerHtmlRef, handleMessageBody } =
        useMessageEditor({
            target: TARGET_ENUMS.SUBJECT,
        });

    const { dynamicFieldDecorator } = useDynamicFields();

    const errorExists = Boolean(validationError);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="labelSmall" sx={{ mb: 1 }}>
                SUBJECT
            </Typography>
            <RichTextInput
                autoFocus={autofocus}
                name="compose-rich-text-subject"
                value={innerHtml}
                onChange={handleMessageBody}
                disabled={isFetching}
                inputProps={TextInputProps}
                initialValue={initialBody}
                clearInput={clearInput}
                fullWidth
                isToolbarDisabled
                isInline
                error={errorExists}
                initiateMount={false}
                decorator={dynamicFieldDecorator}
                ref={innerHtmlRef}
                bodyHeight="30px"
            />
            {errorExists && (
                <Typography data-testid="subject-error-message" sx={{ fontSize: 10, color: 'red' }}>
                    {validationError}
                </Typography>
            )}
        </Box>
    );
}

SubjectEditor.propTypes = {
    autofocus: PropTypes.bool,
    isFetching: PropTypes.bool,
};
