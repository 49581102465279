import { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { SignatureIcon } from 'components/Icons';
import { SignatureDropdown } from './SignatureDropdown';

const StyledIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'disableShadow',
})(({ theme, disableShadow }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    boxShadow: disableShadow
        ? 'inherit'
        : '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    '&.Mui-disabled': { opacity: 0.32 },
}));

export function SignatureButton(props) {
    const {
        signatures,
        isFetching,
        onUseSignature,
        onRefreshSignature,
        disableShadow,
        ...restProps
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <StyledIconButton
                data-testid="signature-icon-button"
                onClick={handleClick}
                disableShadow={disableShadow}
                {...restProps}
            >
                <SignatureIcon sx={{ fontSize: 14 }} gradient />
            </StyledIconButton>
            <SignatureDropdown
                anchorEl={anchorEl}
                onClose={handleClose}
                signatures={signatures}
                isFetching={isFetching}
                onUseSignature={onUseSignature}
                onRefreshSignature={onRefreshSignature}
            />
        </>
    );
}

SignatureButton.propTypes = {
    signatures: PropTypes.array,
    isFetching: PropTypes.bool,
    disableShadow: PropTypes.bool,
    onUseSignature: PropTypes.func,
    onRefreshSignature: PropTypes.func,
};
