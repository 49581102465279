import SvgIcon from '@mui/material/SvgIcon';

export function IndustryIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 1.73354C0 0.865746 0.632431 0.00292969 1.6 0.00292969H3.42857C4.39614 0.00292969 5.02857 0.865746 5.02857 1.73354V5.45026L8.45783 3.34052C8.93447 3.04773 9.47912 3.10139 9.87982 3.37382C10.2717 3.64026 10.5143 4.09969 10.5143 4.61354V5.45026L13.9435 3.34052C14.4202 3.04773 14.9648 3.10139 15.3655 3.37382C15.7574 3.64026 16 4.09969 16 4.61354V13.7499C16 14.905 15.1619 16.0029 13.9429 16.0029H2.05714C0.838145 16.0029 0 14.905 0 13.7499V1.73354ZM1.46927 1.4459C1.41678 1.50588 1.37143 1.60527 1.37143 1.73354V13.7499C1.37143 14.3253 1.76185 14.6315 2.05714 14.6315H13.9429C14.2381 14.6315 14.6286 14.3253 14.6286 13.7499V4.61354C14.6286 4.5788 14.6221 4.55431 14.6148 4.53772L9.14286 7.90417V4.61354C9.14286 4.5788 9.13641 4.55431 9.12911 4.53772L3.65714 7.90417V1.73354C3.65714 1.60527 3.61179 1.50588 3.5593 1.4459C3.50811 1.3874 3.46057 1.37436 3.42857 1.37436H1.6C1.568 1.37436 1.52046 1.3874 1.46927 1.4459Z"
                fill="currentColor"
                fillOpacity="0.72"
            />
        </SvgIcon>
    );
}
