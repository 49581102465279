import { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { Box, styled } from '@mui/material';

const StyledPopper = styled(Popper)(() => ({
    zIndex: 2000,
}));

const PopperRoot = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    maxWidth: 1000,
}));

const determineTransform = (placement) => {
    if (placement === 'bottom') {
        return '0 100%';
    }
    if (placement === 'top') {
        return '100% 0';
    }
    if (placement === 'right') {
        return '100% 100%';
    }
    if (placement === 'left') {
        return '0 0';
    }
    return '0 100%';
};

const Arrow = styled('span')(({ theme, placement }) => ({
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    color: theme.palette.background.paper,
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        boxShadow: theme.shadows[1],
        backgroundColor: 'currentColor',
        transform: 'rotate(45deg)',
        transformOrigin: determineTransform(placement),
    },
    ...(placement === 'bottom' && {
        top: 0,
        left: 0,
        marginTop: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
    }),
    ...(placement === 'top' && {
        bottom: 0,
        left: 0,
        marginBottom: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
    }),
    ...(placement === 'right' && {
        left: 0,
        marginLeft: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
    }),
    ...(placement === 'left' && {
        right: 0,
        marginRight: '-0.71em',
        height: '1em',
        width: '0.71em',
        marginTop: 4,
        marginBottom: 4,
    }),
}));

export function PopperWithArrow(props) {
    const {
        open,
        placement = 'bottom',
        arrow = true,
        onClose = () => {},
        children,
        content,
        modifiers = [],
        arrowTransform,
        sx,
    } = props;
    const [childNode, setChildNode] = useState(null);
    const [arrowRef, setArrowRef] = useState(null);

    return (
        <div>
            {cloneElement(children, { ...children.props, ref: setChildNode })}
            <StyledPopper
                open={open}
                anchorEl={childNode}
                placement={placement}
                transition
                modifiers={[
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'window',
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: arrow,
                        options: {
                            element: arrowRef,
                        },
                    },
                    ...modifiers,
                ]}
                sx={sx}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <ClickAwayListener onClickAway={onClose}>
                                <PopperRoot>
                                    {arrow ? (
                                        <Arrow
                                            data-testid="popper-arrow"
                                            // arrowTransform acts as an override, allowing developer to manually configure arrow position
                                            sx={{
                                                ...(arrowTransform && {
                                                    transform: `translate(${arrowTransform.x}px, ${arrowTransform.y}px) !important`,
                                                }),
                                            }}
                                            ref={setArrowRef}
                                            placement={placement}
                                        />
                                    ) : null}
                                    <Box>{content}</Box>
                                </PopperRoot>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </StyledPopper>
        </div>
    );
}

PopperWithArrow.propTypes = {
    open: PropTypes.bool,
    placement: PropTypes.string,
    arrow: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.element,
    content: PropTypes.element,
    modifiers: PropTypes.array,
    arrowTransform: PropTypes.object,
    sx: PropTypes.object,
};
