import { useCallback, useEffect, useState } from 'react';
import { useGetNylasAuthenticationUrlMutation } from 'services/conversations';

export const useConnectEmail = (id, email) => {
    const [redirectUrl, setRedirectUrl] = useState('');
    const [open, setOpen] = useState(false);

    const [getAuthUrl, { isLoading }] = useGetNylasAuthenticationUrlMutation();

    const handleGetAuthUrl = useCallback(
        async (userId, userEmail) => {
            const res = await getAuthUrl({ userId, userEmail });
            const { data } = res || {};
            if (data?.auth_url) {
                setRedirectUrl(`${data.auth_url}`);
            }
        },
        [getAuthUrl]
    );

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (id && email) {
            handleGetAuthUrl(id, email);
        }
    }, [id, email, handleGetAuthUrl]);

    return {
        redirectUrl,
        open,
        isLoading,
        handleOpen,
        handleClose,
    };
};
