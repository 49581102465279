import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TARGET_ENUMS } from '../utils';
import { MESSAGE_TYPES } from 'utils/constants';
import { setActiveTemplateId, setTemplate } from 'features/MessageTemplate';
import { processSignature } from '../utils/helpers';
import {
    clearMessage,
    setInitialBody,
    setMessageType,
    setScheduleModalOpen,
    setScheduledDate,
    setSignature,
    toggleFormatting,
} from '../messageSlice';
import { useDynamicFields } from 'features/DynamicFields';

export function useMessageData(props) {
    const { shouldProcessDynamicFields } = props || {};
    const dispatch = useDispatch();

    const {
        isCollapsed,
        isFormatting,
        isScheduleModalOpen,
        subject,
        body,
        messageType,
        selectedAttachments,
        scheduledDate,
        scheduledTime,
        isGeneratingMessage,
    } = useSelector((state) => state.message);

    const { handleRetrieveDefaultDynamicFields } = useDynamicFields();

    const handleUseTemplate = useCallback(
        (template) => {
            const { subject, body, signature, messageTypes } = template || {};

            let templateBody = `<div>${body}</div>`;
            if (signature?.signature && messageType === MESSAGE_TYPES.EMAIL) {
                templateBody += `<br/>${signature.signature}`;
            }
            dispatch(setActiveTemplateId(template.templateId));
            dispatch(setTemplate(template));
            dispatch(
                setInitialBody({
                    target: TARGET_ENUMS.SUBJECT,
                    initialBody: subject,
                })
            );
            dispatch(
                setInitialBody({
                    target: TARGET_ENUMS.BODY,
                    initialBody: templateBody,
                })
            );

            const isEmailOrSmsType = messageTypes?.length > 1 || false;
            let targetMessageType = messageTypes?.[0] || MESSAGE_TYPES.EMAIL;
            if (isEmailOrSmsType && messageType) {
                targetMessageType = messageType;
            }
            dispatch(setMessageType(targetMessageType));
        },
        [dispatch, messageType]
    );

    const handleClearMessage = useCallback(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    const handleToggleFormatting = useCallback(() => {
        dispatch(toggleFormatting());
    }, [dispatch]);

    const handleSetSignature = useCallback(
        (signature) => {
            const signatureBody = processSignature(body.innerHtml, signature);
            dispatch(setSignature({ signatureBody, signature }));
        },
        [dispatch, body.innerHtml]
    );

    const handleConfirmScheduledDate = useCallback(
        ({ date, time }) => {
            dispatch(setScheduledDate({ date, time }));
        },
        [dispatch]
    );

    const handleToggleScheduleModal = useCallback(() => {
        dispatch(setScheduleModalOpen(!isScheduleModalOpen));
    }, [dispatch, isScheduleModalOpen]);

    const handleCloseScheduleModal = useCallback(() => {
        dispatch(setScheduleModalOpen(false));
    }, [dispatch]);

    useEffect(() => {
        if (shouldProcessDynamicFields) {
            const combinedText = `${subject.innerText} ${body.innerText}`;
            handleRetrieveDefaultDynamicFields(combinedText);
        }
    }, [subject.innerText, body.innerText, shouldProcessDynamicFields]);

    return {
        isGeneratingMessage,
        isCollapsed,
        isFormatting,
        isScheduleModalOpen,
        subject,
        body,
        messageType,
        selectedAttachments,
        scheduledDate,
        scheduledTime,
        handleUseTemplate,
        handleClearMessage,
        handleToggleFormatting,
        handleSetSignature,
        handleConfirmScheduledDate,
        handleToggleScheduleModal,
        handleCloseScheduleModal,
    };
}
