import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { alpha, styled } from '@mui/material/styles';

import { EventInfoPanel } from './panels';
import { Typography } from 'components/Typography';
import { clearEdits } from 'views/UserSettings/features/Scheduling';
import { CloseIcon, DeleteIcon } from 'components/Icons';
import { IconButton } from '@mui/material';
import {
    useCreateScheduleMutation,
    useUpdateScheduleMutation,
    useDeleteScheduleMutation,
} from 'services/scheduler';

const DialogButton = styled(Button)(({ theme }) => ({
    boxSizing: 'border-box',
    textTransform: 'unset',
    padding: theme.spacing(1.2),
    borderRadius: theme.spacing(0.5),
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
}));

const Content = styled(DialogContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

export function ScheduleDialog() {
    const dispatch = useDispatch();

    const isEditing = useSelector((state) => state.schedule.isEditing);
    const validation = useSelector((state) => state.schedule.validation);
    const payload = useSelector((state) => state.schedule.payload);

    const [createSchedule, { isLoading: isCreating }] = useCreateScheduleMutation();
    const [updateSchedule, { isLoading: isUpdating }] = useUpdateScheduleMutation();
    const [deleteSchedule, { isLoading: isDeleting }] = useDeleteScheduleMutation();

    const isLoading = isUpdating || isCreating || isDeleting;

    const isInvalidData = validation.externalUrl || validation.pageTitle;

    const handleClose = () => {
        dispatch(clearEdits());
    };

    const handleDelete = async () => {
        await deleteSchedule(payload.id);
        handleClose();
    };

    const handleSubmit = async () => {
        const callback = payload.id ? updateSchedule : createSchedule;
        const res = await callback(payload);

        if (res?.error) {
            toast.error(res.error.data?.message || 'An error occurred while creating the link');
        } else {
            handleClose();
            toast.success('Scheduling link has been created successfully');
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            open={isEditing}
            aria-label="schedule-page-dialog"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: (theme) => theme.palette.text.primary,
                }}
            >
                <Typography
                    variant="titleMedium"
                    sx={{ color: (theme) => alpha(theme.palette.common.white, 0.72) }}
                >
                    Scheduling Page
                </Typography>
                <IconButton
                    onClick={handleClose}
                    aria-label="contact-dialog-cancel"
                    data-trackid="create-sched-link-modal-cancel"
                    disabled={isLoading}
                    sx={{ ml: 'auto', color: 'common.white' }}
                >
                    <CloseIcon sx={{ fontSize: 16 }} />
                </IconButton>
            </DialogTitle>
            <Content>
                <EventInfoPanel />
            </Content>
            <DialogActions>
                {payload?.id && (
                    <Button
                        startIcon={<DeleteIcon />}
                        sx={{ ml: 2, mr: 'auto', textTransform: 'unset' }}
                        disableRipple
                        disableTouchRipple
                        onClick={handleDelete}
                        disabled={isLoading}
                        aria-label="contact-dialog-delete-schedule"
                    >
                        <Typography variant="titleSmall">
                            {isDeleting ? 'Deleting' : 'Delete'}
                        </Typography>
                    </Button>
                )}
                <DialogButton
                    color="primary"
                    variant="contained"
                    aria-label="contact-dialog-confirm"
                    data-trackid="create-sched-link-modal-add"
                    disabled={isInvalidData || isLoading}
                    onClick={handleSubmit}
                >
                    <Typography variant="titleSmall">
                        {isUpdating || isCreating ? 'Saving' : 'Add Scheduling Link'}
                    </Typography>
                </DialogButton>
            </DialogActions>
        </Dialog>
    );
}
