import { createSlice } from '@reduxjs/toolkit';

import { JD_PROMPT_OTHER_OPTION, JD_TONE_OPTIONS } from './constants';

export const initialJDBuilderState = {
    isDirty: false,
    jobTitle: '',
    companyDescription: '',
    candidateDescription: '',
    jobDescription: '',
    tone: '',
    toneOther: '',
    name: '',
    selectedId: '',
    iconReaction: 'default',
};

export const jdBuilderSlice = createSlice({
    name: 'jdBuilder',
    initialState: initialJDBuilderState,
    reducers: {
        changeName: (state, action) => {
            state.name = action.payload;
        },
        changeJobTitle: (state, action) => {
            state.jobTitle = action.payload;
        },
        changeCompanyDescription: (state, action) => {
            state.companyDescription = action.payload;
        },
        changeCandidateDescription: (state, action) => {
            state.candidateDescription = action.payload;
        },
        changeJobDescription: (state, action) => {
            state.isDirty = true;
            state.jobDescription = action.payload;
        },
        changeTone: (state, action) => {
            if (action.payload.other) {
                state.toneOther = action.payload.value;
            } else {
                state.toneOther = '';
                state.tone = action.payload.value;
            }
        },
        resetJDBuilder: (state) => {
            return initialJDBuilderState;
        },
        changeDirtyState: (state, action) => {
            state.isDirty = action.payload;
        },
        loadSavedJD: (state, action) => {
            state.isDirty = false;
            state.selectedId = action.payload.id || '';
            state.name = action.payload.name || '';
            state.jobTitle = action.payload.jobTitle || '';
            state.companyDescription = action.payload.companyDescription || '';
            state.candidateDescription = action.payload.candidateDescription || '';
            state.jobDescription = action.payload.jobDescription || '';

            const tone = action.payload.tone || '';
            const toneOptions = Object.values(JD_TONE_OPTIONS).map(({ value }) => value);
            if (toneOptions.includes(tone)) {
                state.tone = tone;
            } else {
                state.tone = JD_PROMPT_OTHER_OPTION;
                state.toneOther = tone;
            }
        },
        setIconReaction: (state, action) => {
            state.iconReaction = action.payload;
        },
    },
});

export const {
    setIconReaction,
    resetJDBuilder,
    changeName,
    loadSavedJD,
    changeTone,
    changeJobTitle,
    changeDirtyState,
    changeJobDescription,
    changeCompanyDescription,
    changeCandidateDescription,
} = jdBuilderSlice.actions;

export const jdBuilderReducer = jdBuilderSlice.reducer;
