import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { forwardRef, useState, cloneElement } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { NewWindowIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { useSwitchboard } from 'features/Switchboard';
import { useLazyGetCompanyDataQuery } from 'services/insights';

const StyledWrapper = styled('span')(({ theme }) => ({
    cursor: 'pointer',
    '& span, & p': {
        color: theme.palette.primary.main,
    },
}));

/* eslint-disable-next-line react/prop-types */
const SecondaryTooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
}));

const StyledTooltip = styled(
    /* eslint-disable-next-line react/prop-types */
    forwardRef(({ className, ...props }, ref) => (
        <MuiTooltip {...props} ref={ref} classes={{ popper: className }} />
    ))
)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
        backgroundColor: 'transparent',
        border: 'none',
    },
}));

function TooltipContent(props) {
    const { isLoading, isError, companyData } = props;
    const { name, website } = companyData || {};
    let absoluteURL = website;
    if (typeof website === 'string' && !website.match(/https?/i)) {
        absoluteURL = '//' + website;
    }
    return (
        <Paper elevation={3}>
            <Box sx={{ px: 2, py: 1 }}>
                {isLoading && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color="primary.main" variant="bodyBaseBold">
                            Loading...
                        </Typography>
                        <CircularProgress size={16} sx={{ ml: 1 }} />
                    </Box>
                )}
                {!isLoading && isError && (
                    <div>
                        <Typography
                            color="error.main"
                            variant="bodySmallBold"
                            component="p"
                            textAlign="center"
                        >
                            Error Loading Company Data
                        </Typography>
                        <Typography color="error.main" variant="bodySmall" textAlign="center">
                            Please try again in a few minutes
                        </Typography>
                    </div>
                )}
                {!isLoading && !isError && !website && (
                    <div>
                        <Typography
                            color="error.main"
                            variant="bodySmallBold"
                            component="p"
                            textAlign="center"
                        >
                            No Company Link Found
                        </Typography>
                    </div>
                )}
                {!isLoading && !isError && name && website && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography color="text.primary" variant="bodyBaseBold" component="p">
                            {name}
                        </Typography>
                        <SecondaryTooltip title="Following this link sends you to a website outside of Nebula">
                            <Typography
                                aria-label={`external-link-to-${name}`}
                                data-trackid="company-link-open-tooltip"
                                color="primary.main"
                                variant="bodyBaseBold"
                                component="a"
                                href={absoluteURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                {website}&nbsp;
                                <NewWindowIcon sx={{ color: 'primary.main', fontSize: 14 }} />
                            </Typography>
                        </SecondaryTooltip>
                    </Box>
                )}
            </Box>
        </Paper>
    );
}

export function CompanyLink(props) {
    const { companyName, children, disableWrapper, ...restProps } = props;

    const isEnabled = useSwitchboard('FEATURE_NN-1320_COMPANY_LINKS');

    const [getCompanyData, response] = useLazyGetCompanyDataQuery();
    const { data: companyData, isError, isFetching } = response;

    const [isTooltipOpen, setTooltipOpen] = useState(false);

    const handleOpenTooltip = () => {
        setTooltipOpen(true);
        getCompanyData(companyName);
    };

    const handleCloseTooltip = () => {
        setTooltipOpen(false);
    };

    if (!isEnabled || !companyName) {
        return children;
    }

    let innerContent = (
        <StyledWrapper
            role="button"
            aria-label="get-company-info"
            data-trackid="company-link-open-link"
            onClick={handleOpenTooltip}
            {...restProps}
        >
            {children}
        </StyledWrapper>
    );

    if (disableWrapper) {
        const childrenProps = {
            'aria-label': 'get-company-info',
            'data-trackid': 'company-link-open-link',
            role: 'button',
            onClick: handleOpenTooltip,
        };
        innerContent = Array.isArray(children)
            ? children.map((child) => cloneElement(child, childrenProps))
            : cloneElement(children, childrenProps);
    }

    return (
        <ClickAwayListener onClickAway={handleCloseTooltip}>
            <StyledTooltip
                placement="top"
                open={isTooltipOpen}
                onClose={handleCloseTooltip}
                PopperProps={{ disablePortal: true }}
                title={
                    <TooltipContent
                        isLoading={isFetching}
                        isError={isError}
                        companyData={companyData}
                    />
                }
                disableFocusListener
                disableHoverListener
                disableTouchListener
            >
                {innerContent}
            </StyledTooltip>
        </ClickAwayListener>
    );
}

CompanyLink.propTypes = {
    companyName: PropTypes.string,
    children: PropTypes.node,
    disableWrapper: PropTypes.bool,
};

TooltipContent.propTypes = {
    companyData: PropTypes.any,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
};
