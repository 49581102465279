import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Routes as RouterRoutes, Route } from 'react-router-dom';

import { TooSmall } from 'views/TooSmall';
import { useWindowSize } from 'hooks/useWindowSize';
import { ViewContainer } from 'components/ViewContainer';
import { ProtectedRoute } from 'features/Authentication';
import { ProtectedEmailConnectionRoute } from 'features/Authentication';

const Dashboard = lazy(() => import('views/Dashboard'));
const Search = lazy(() => import('views/Search'));
const NewSearch = lazy(() => import('views/Search/NewSearch'));
const Talent = lazy(() => import('views/Talent'));
const Enroll = lazy(() => import('views/Enroll'));
const SearchResults = lazy(() => import('views/Search/SearchResults'));
const LinkedInSearch = lazy(() => import('views/Search/LinkedInSearch'));
const NotFound = lazy(() => import('views/NotFound'));
const Shortlist = lazy(() => import('views/Shortlist'));
const JDBuilder = lazy(() => import('views/JDBuilder'));
const Sequences = lazy(() => import('views/Sequences'));
const TalkTracks = lazy(() => import('views/TalkTracks'));
const SearchHistory = lazy(() => import('views/SearchHistory'));
const Sequence = lazy(() => import('views/Sequences/Sequence'));
const Communications = lazy(() => import('views/Communications'));
const TalkTrack = lazy(() => import('views/TalkTracks/TalkTrack'));
const Users = lazy(() => import('views/SalesAdminDashboard/Users'));
const SequenceIndex = lazy(() => import('views/Sequences/SequenceIndex'));
const ForcedFlowRedirect = lazy(() => import('components/ForcedFlowLock'));
const SalesAdminDashboard = lazy(() => import('views/SalesAdminDashboard'));
const EmptyShortlist = lazy(() => import('views/Shortlist/EmptyShortlist'));
const AdminTools = lazy(() => import('views/SalesAdminDashboard/AdminTools'));
const JDBuilderOutput = lazy(() => import('views/JDBuilder/JDBuilderOutput'));
const Messages = lazy(() => import('views/Communications/Messages'));
const Compose = lazy(() => import('views/Communications/features/Compose'));
const Contacts = lazy(() => import('views/Communications/features/Contacts'));
const JDBuilderPrompts = lazy(() => import('views/JDBuilder/JDBuilderPrompts'));
const Recipients = lazy(() => import('views/Sequences/Recipients/default'));
const TalkTracksIndex = lazy(() => import('views/TalkTracks/TalkTracksIndex'));
const ShortlistDetails = lazy(() => import('views/Shortlist/ShortlistDetails'));
const ShortlistFeedback = lazy(() => import('views/Shortlist/ShortlistFeedback'));
const UserSettings = lazy(() => import('views/UserSettings'));
const UserSettingsContent = lazy(() => import('views/UserSettings/userSettingsDefault'));
const Organizations = lazy(() => import('views/SalesAdminDashboard/Organizations'));
const TalkTrackRecipients = lazy(() => import('views/TalkTracks/Recipients'));
const KnowledgeBaseDetail = lazy(() => import('views/UserSettings/features/KnowledgeBases'));
const YourMedia = lazy(() => import('views/UserSettings/features/YourMedia'));
const MessagingHome = lazy(() => import('views/Communications/CommunicationsMetrics'));
const IncompleteUsers = lazy(() => import('views/SalesAdminDashboard/IncompleteUsers'));
const EngagementMetricsDrilldown = lazy(() =>
    import('views/Communications/CommunicationsMetrics/EngagementMetricsDrilldown')
);
const EmailNotConnected = lazy(() => import('views/EmailNotConnected'));
const ProfilesWithNotes = lazy(() => import('views/ProfilesWithNotes'));
const ProjectsOutlet = lazy(() => import('views/Projects/ProjectsOutlet'));
const Projects = lazy(() => import('views/Projects'));
const Project = lazy(() => import('views/Projects/Project'));
const SubscriptionComplete = lazy(() => import('views/SubscriptionComplete'));
const TalentDrawerTestPage = lazy(() => import('views/TalentDrawerTestPage'));
const FeedbackLists = lazy(() => import('views/FeedbackLists'));
const FeedbackListDetails = lazy(() => import('views/FeedbackLists/FeedbackListDetails'));

function SuspenseLoader({ children }) {
    return (
        <Suspense
            fallback={
                <ViewContainer
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={120} />
                </ViewContainer>
            }
        >
            {children}
        </Suspense>
    );
}

SuspenseLoader.propTypes = {
    children: PropTypes.node,
};

export function Routes(props) {
    const { user } = props;

    const windowSize = useWindowSize();

    const isForcedFlow = user?.settings?.forcedFlow;
    const isAdmin = user?.isSalesManager || user?.isOrganizationAdmin;
    const isAdminStatusLoading = typeof user?.isSalesManager !== 'boolean';

    // semi arbitrary break-point but around where the app starts to look really bad
    if (windowSize.width < 768) {
        return <TooSmall />;
    }

    if (isForcedFlow) {
        return (
            <SuspenseLoader>
                <RouterRoutes>
                    <Route index element={<Dashboard />} />
                    <Route path="job-description-builder" element={<JDBuilder />}>
                        <Route index element={<JDBuilderPrompts />} />
                        <Route path="generate" element={<JDBuilderOutput />}>
                            <Route path=":jobDescriptionId" element={null} />
                        </Route>
                    </Route>
                    <Route path="*" element={<ForcedFlowRedirect />} />
                </RouterRoutes>
            </SuspenseLoader>
        );
    }

    return (
        <SuspenseLoader>
            <RouterRoutes>
                <Route index element={<Dashboard />} />
                <Route path="search" element={<Search />}>
                    <Route index element={<NewSearch />} />
                    <Route path="results" element={<SearchResults />} />
                </Route>
                <Route path="search/li-url" element={<LinkedInSearch />}>
                    <Route path=":talentId" element={<Talent />} />
                </Route>
                <Route path="search-history" element={<SearchHistory />} />
                <Route path="shortlist" element={<Shortlist />}>
                    <Route path="empty" element={<EmptyShortlist />} />
                    <Route path=":shortlistId" element={<ShortlistDetails />} />
                    <Route
                        path=":shortlistId/feedback/:feedbackId"
                        element={<ShortlistFeedback />}
                    />
                </Route>
                <Route path="shortlist-feedback" element={<FeedbackLists />}>
                    <Route
                        path=":feedbackListId/:derivedFromType/:derivedFromId"
                        element={<FeedbackListDetails />}
                    />
                </Route>
                <Route path="talent/:talentId" element={<Talent />} />
                <Route element={<ProtectedEmailConnectionRoute />}>
                    <Route path="communications" element={<Communications />}>
                        <Route index element={<MessagingHome />} />
                        <Route
                            path="engagement/drilldown"
                            element={<EngagementMetricsDrilldown />}
                        />
                        <Route path="contacts" element={<Contacts />} />
                        <Route path="compose" element={<Compose />} />
                        <Route path="messages/:conversationId" element={<Messages />} />
                    </Route>
                </Route>
                <Route path="user-settings" element={<UserSettings user={user} />}>
                    <Route index element={<UserSettingsContent user={user} />} />
                    <Route path="knowledge-bases" element={<KnowledgeBaseDetail />}>
                        <Route path=":knowledgeBaseId" />
                    </Route>
                    <Route path="your-media" element={<YourMedia />} />
                </Route>
                <Route
                    element={<ProtectedEmailConnectionRoute redirectPath="/email-disconnected" />}
                >
                    <Route path="sequences" element={<Sequences />}>
                        <Route index element={<SequenceIndex />} />
                        <Route path=":sequenceId" element={<Sequence />} />
                        <Route path=":sequenceId/recipients" element={<Recipients />} />
                    </Route>
                </Route>
                <Route
                    element={<ProtectedEmailConnectionRoute redirectPath="/email-disconnected" />}
                >
                    <Route path="enroll" element={<Enroll />} />
                </Route>
                <Route
                    element={<ProtectedEmailConnectionRoute redirectPath="/email-disconnected" />}
                >
                    <Route path="talk-tracks" element={<TalkTracks />}>
                        <Route index element={<TalkTracksIndex />} />
                        <Route path=":talkTrackId" element={<TalkTrack />} />
                        <Route path=":talkTrackId/recipients" element={<TalkTrackRecipients />} />
                    </Route>
                </Route>
                <Route path="job-description-builder" element={<JDBuilder />}>
                    <Route index element={<JDBuilderPrompts />} />
                    <Route path="generate" element={<JDBuilderOutput />}>
                        <Route path=":jobDescriptionId" element={null} />
                    </Route>
                </Route>
                <Route path="profiles-with-notes" element={<ProfilesWithNotes />} />
                <Route
                    element={
                        <ProtectedRoute isAllowed={isAdmin} isLoading={isAdminStatusLoading} />
                    }
                >
                    <Route path="admin" element={<SalesAdminDashboard />}>
                        <Route path="users" element={<Users />} />
                        <Route
                            element={
                                <ProtectedRoute
                                    isAllowed={user?.isNovaToolsAdmin}
                                    isLoading={isAdminStatusLoading}
                                />
                            }
                        >
                            <Route path="tools" element={<AdminTools />} />
                        </Route>
                        <Route
                            element={
                                <ProtectedRoute
                                    isAllowed={user?.isSalesManager}
                                    isLoading={isAdminStatusLoading}
                                />
                            }
                        >
                            <Route path="organizations" element={<Organizations />} />
                        </Route>
                        <Route
                            element={
                                <ProtectedRoute
                                    isAllowed={user?.isSalesManager}
                                    isLoading={isAdminStatusLoading}
                                />
                            }
                        >
                            <Route path="incomplete-users" element={<IncompleteUsers />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="projects" element={<ProjectsOutlet />}>
                    <Route index element={<Projects />} />
                    <Route path=":projectId" element={<Project />}>
                        <Route path=":projectTab" element={null} />
                    </Route>
                </Route>
                <Route path="email-disconnected" element={<EmailNotConnected user={user} />} />
                <Route path="subscription-complete" element={<SubscriptionComplete />} />
                <Route path="talent-drawer-test" element={<TalentDrawerTestPage />} />
                <Route path="*" element={<NotFound />} />
            </RouterRoutes>
        </SuspenseLoader>
    );
}

Routes.propTypes = {
    user: PropTypes.any,
};
