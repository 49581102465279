export function getInitials(name, separator = '.', options = { offset: 0, isUpperCase: true }) {
    if (!name || name?.trim() === '') {
        return '';
    }

    let staging = name;
    if (options.isUpperCase) {
        staging = name.toUpperCase(); // Normalize in case name is in lowercase
    }

    return (
        staging
            .split(' ')
            .filter((_word, index) => index <= 1) // Retrieve first two parts of name
            .map((word, i) => {
                return i >= options.offset ? word[0] : word;
            })
            .join(separator) + separator.trim() // Removes trailing white space for cases when separator is a space
    );
}
