import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const shortlistBatchActionAdapter = createEntityAdapter({
    selectId: (profile) => profile.talentId,
});

export const shortlistBatchActionSlice = createSlice({
    name: 'shortlistBatchActions',
    initialState: shortlistBatchActionAdapter.getInitialState(),
    reducers: {
        addShortlistSelection: shortlistBatchActionAdapter.addOne,
        addManyShortlistSelections: shortlistBatchActionAdapter.addMany,
        updateShortlistSelection: shortlistBatchActionAdapter.updateOne,
        removeShortlistSelection: shortlistBatchActionAdapter.removeOne,
        removeManyShortlistSelection: shortlistBatchActionAdapter.removeMany,
        clearShortlistSelections: shortlistBatchActionAdapter.removeAll,
    },
});

export const {
    addShortlistSelection,
    removeShortlistSelection,
    clearShortlistSelections,
    addManyShortlistSelections,
    removeManyShortlistSelection,
    updateShortlistSelection,
} = shortlistBatchActionSlice.actions;

export const {
    selectAll: selectShortlistTalent,
    selectIds: selectShortlistTalentIds,
    selectById: selectShortlistTalentById,
    selectTotal: selectShortlistTalentTotal,
} = shortlistBatchActionAdapter.getSelectors((state) => state.shortlistBatchActions);

export const shortlistBatchActionsReducer = shortlistBatchActionSlice.reducer;
