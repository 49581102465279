import { theme } from 'theme';

export const TALENT_IDS_KEY = '@talentIds';
export const TALENT_SOURCE_KEY = '@talentSource';
export const ENROLL_CHANNEL_KEY = '@enrollChannel';
export const ENROLL_SOURCE_URL = '@sourceURL';

export const ENROLL_STEPS = {
    talkTracks: {
        STEPS: {
            1: {
                id: 1,
                stepName: 'Select Digital Assistant',
                stepHeader: 'Select Digital Assistant',
                errors: {
                    noActiveTemplate: 'You have no active Digital Assistant',
                    noActiveTemplateBtnTxt: 'Add Digital Assistant',
                },
            },
            2: { id: 2, stepName: 'Lookup Contacts (%qty%)', stepHeader: 'Lookup Contacts' },
            3: { id: 3, stepName: 'Select Phone', stepHeader: 'Select Phone' },
        },
        CHANNEL_META: {
            sidebarHeader: 'Digital Assistant Enroll',
            enrolleesMaxLimit: 200,
            maxLimitError:
                'Digital Assistant Maximum Enroll limit reached. Exceeded by %qty% talent(s)',
            proceedToChannelBtnTxt: 'Proceed To Digital Assistant',
            url: '/talk-tracks/%qty%/recipients',
            missingKbError: 'The selected KB is missing Key fields needed in this template.',
            filterOptionsMap: {
                all: 'All Channels',
                sms: 'SMS',
            },
            color: 'rgb(49, 141, 222)',
        },
    },
    sequences: {
        STEPS: {
            1: {
                id: 1,
                stepName: 'Select Sequence',
                stepHeader: 'Select Sequence',
                errors: {
                    noActiveTemplate: 'You have no active Sequences',
                    noActiveTemplateBtnTxt: 'Add Sequence',
                },
            },
            2: { id: 2, stepName: 'Lookup Contacts (%qty%)', stepHeader: 'Lookup Contacts' },
            3: {
                id: 3,
                stepName: 'Select Emails and Phone',
                stepHeader: 'Select Emails and Phone',
            },
        },
        CHANNEL_META: {
            sidebarHeader: 'Sequence Enroll',
            enrolleesMaxLimit: 200,
            maxLimitError: 'Sequence Maximum Enroll limit reached. Exceeded by %qty% talent(s)',
            proceedToChannelBtnTxt: 'Proceed To Sequence',
            url: '/sequences/%qty%/recipients',
            missingKbError: '',
            filterOptionsMap: {
                all: 'All Channels',
                email: 'Email',
                sms: 'SMS',
            },
            color: theme.palette.secondary.main,
        },
    },
};

export const ENROLL_CHANNELS = {
    SEQUENCES: 'sequences',
    TT: 'talkTracks',
};

export const MESSAGE_MEDIUMS = {
    email: 'email',
    sms: 'sms',
};

export const CONTACT_MEDIUMS = {
    [MESSAGE_MEDIUMS.email]: 'emails',
    [MESSAGE_MEDIUMS.sms]: 'phones',
};

export const WORKFLOW_INSTANCE_STATUS = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    PAUSED: 'paused',
};
