import SvgIcon from '@mui/material/SvgIcon';

export function EyeIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <path
                d="M5.9822 10.6115C3.33764 10.6115 1.42468 8.67454 0.519172 7.52316C-0.19444 6.61166 -0.170458 5.35834 0.579134 4.47082C1.76649 3.06159 3.67944 1.38251 6.01217 1.38251C8.3449 1.38251 10.2579 3.06759 11.4392 4.48882C12.1768 5.37633 12.1888 6.67163 11.4632 7.56514C10.3358 8.95638 8.47084 10.6175 5.98819 10.6175L5.9822 10.6115ZM6.01217 2.58185C4.14119 2.58185 2.52207 4.03306 1.49663 5.2444C1.11884 5.69416 1.10684 6.32382 1.46665 6.78556C2.24023 7.76903 3.85335 9.41813 5.98819 9.41813C8.00309 9.41813 9.57424 8.0029 10.5337 6.80955C10.9055 6.3538 10.8995 5.71215 10.5157 5.2564C9.68218 4.26094 7.99709 2.58185 6.01217 2.58185Z"
                fill="currentColor"
            />
            <path
                d="M6.0061 8.39266C4.68083 8.39266 3.60741 7.31924 3.60741 5.99396C3.60741 4.66868 4.68083 3.59527 6.0061 3.59527C7.33138 3.59527 8.4048 4.66868 8.4048 5.99396C8.4048 7.31924 7.33138 8.39266 6.0061 8.39266ZM6.0061 4.79461C5.34646 4.79461 4.80676 5.33432 4.80676 5.99396C4.80676 6.6536 5.34646 7.19331 6.0061 7.19331C6.66575 7.19331 7.20545 6.6536 7.20545 5.99396C7.20545 5.33432 6.66575 4.79461 6.0061 4.79461Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
