import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const knowledgeBasesApi = createApi({
    reducerPath: 'knowledgeBasesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/knowledgebases/v1',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['KnowledgeBases'],
    endpoints: (builder) => ({
        getKnowledgeBases: builder.query({
            query: (params) => ({
                url: '/knowledgebase',
                params,
            }),
            providesTags: [{ type: 'KnowledgeBases', id: 'LIST' }],
        }),
        createKnowledgeBase: builder.mutation({
            query: (body) => ({
                url: '/knowledgebase',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'KnowledgeBases', id: 'LIST' }],
        }),
        editKnowledgeBase: builder.mutation({
            query: (body) => ({
                url: `/knowledgebase/${body.id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'KnowledgeBases', id: 'LIST' },
                { type: 'KnowledgeBases', id },
            ],
        }),
        getKnowledgeBase: builder.query({
            query: (id) => `/knowledgebase/${id}`,
        }),
        getMediaUrl: builder.query({
            query: (mediaId) => ({
                url: `/media/${mediaId}/download`,
                method: 'GET',
            }),
        }),
        getUploadCredentials: builder.query({
            query: () => ({
                url: '/media/upload-credentials',
                method: 'GET',
            }),
        }),
        updateMediaFile: builder.mutation({
            query: ({ mediaId, ...body }) => ({
                url: `/media/file/${mediaId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'Media', id: 'LIST' }],
        }),
        deleteMedia: builder.mutation({
            query: (body) => ({
                url: '/media',
                method: 'DELETE',
                body,
            }),
            invalidatesTags: [{ type: 'Media', id: 'LIST' }],
        }),
        getMedia: builder.query({
            query: (params) => ({
                url: '/media',
                method: 'GET',
                params,
            }),
            providesTags: [{ type: 'Media', id: 'LIST' }],
        }),

        createMedia: builder.mutation({
            query: (body) => ({
                url: '/media/file',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Media', id: 'LIST' }],
        }),

        updateMediaConnections: builder.mutation({
            query: ({ body, mediaId }) => ({
                url: `/media/${mediaId}/connections`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'Media', id: 'LIST' }],
        }),

        bulkUpdateMediaConnections: builder.mutation({
            query: (body) => ({
                url: '/media/connections',
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'Media', id: 'LIST' }],
        }),

        getMediaTags: builder.query({
            query: (params) => ({
                url: '/media/tags',
                method: 'GET',
                params,
            }),
            providesTags: [{ type: 'MediaTags', id: 'LIST' }],
        }),
        getBulkMedia: builder.mutation({
            query: (body) => ({
                url: '/media/bulk-media',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Media', id: 'LIST' }],
        }),
        getMediaDetails: builder.query({
            query: (mediaId) => ({
                url: '/media/bulk-media',
                method: 'POST',
                body: { mediaIds: [mediaId] },
            }),
        }),
    }),
});

export const {
    useGetKnowledgeBasesQuery,
    useGetKnowledgeBaseQuery,
    useGetMediaTagsQuery,
    useLazyGetKnowledgeBaseQuery,
    useCreateKnowledgeBaseMutation,
    useEditKnowledgeBaseMutation,
    useLazyGetUploadCredentialsQuery,
    useLazyGetMediaUrlQuery,
    useGetMediaQuery,
    useLazyGetMediaQuery,
    useUpdateMediaFileMutation,
    useDeleteMediaMutation,
    useCreateMediaMutation,
    useUpdateMediaConnectionsMutation,
    useGetBulkMediaMutation,
    useBulkUpdateMediaConnectionsMutation,
    useGetMediaDetailsQuery,
} = knowledgeBasesApi;
