import { useMemo, useState } from 'react';
import chunk from 'lodash/chunk';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';

import { Typography } from 'components/Typography';
import { RecipientPill } from '../RecipientPill';
import { useMessageDrawer, useTalentData } from 'features/MessageDrawer/hooks';
import { FindRightContact } from 'components/MessageContent/FindRightContact';
import { NOTES_SOURCES } from 'features/TalentNotes/utils/constants';

const ROWS = 3;

export function MessageRecipients(props) {
    const [showMore, setShowMore] = useState(false);

    const { selectedProfiles } = useTalentData();
    const { openTalentProfile } = useMessageDrawer();

    const previewProfiles = useMemo(() => {
        return chunk(
            selectedProfiles.map((entry, i) => ({ ...entry, index: i })).slice(0, 10),
            ROWS
        );
    }, [selectedProfiles]);

    const allProfiles = useMemo(() => {
        return chunk(
            selectedProfiles.map((entry, i) => ({ ...entry, index: i })),
            ROWS
        );
    }, [selectedProfiles]);

    const isSingleTalent = selectedProfiles.length === 1;

    const toggleShowMore = () => {
        setShowMore((prevState) => !prevState);
    };

    const allTalentIds = selectedProfiles.map(({ talentId }) => talentId);

    const handleShowTalentProfile = (talentId) => {
        openTalentProfile(allTalentIds, talentId, {
            notesSourceConfig: {
                source: NOTES_SOURCES.PROFILE,
                sourceId: talentId,
            },
        });
    };

    return (
        <Box
            aria-label="message-recipients"
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ mb: 1 }}>
                <Typography variant="labelSmall">TO</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    width: 'fill',
                }}
            >
                <Collapse in={!showMore}>
                    {previewProfiles.map((profiles, i) => (
                        <Box
                            key={`row-${i}`}
                            sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                        >
                            {profiles.map(({ index, talentId }) => (
                                <RecipientPill
                                    key={`preview-rows-${talentId}`}
                                    onClick={handleShowTalentProfile}
                                    profileIndex={index}
                                />
                            ))}
                        </Box>
                    ))}
                </Collapse>
                <Collapse in={showMore}>
                    {allProfiles.map((profiles, i) => (
                        <Box
                            key={`row-${i}`}
                            sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                        >
                            {profiles.map(({ index, talentId }) => (
                                <RecipientPill
                                    key={`all-rows-${talentId}`}
                                    onClick={handleShowTalentProfile}
                                    profileIndex={index}
                                />
                            ))}
                        </Box>
                    ))}
                </Collapse>
                {selectedProfiles.length > 10 && (
                    <Box>
                        <Button
                            variant="text"
                            onClick={toggleShowMore}
                            disableRipple
                            disableTouchRipple
                            sx={{
                                textTransform: 'none',
                                '&:hover': { backgroundColor: 'inherit' },
                            }}
                        >
                            {showMore ? 'Show less' : 'Show more'}
                        </Button>
                    </Box>
                )}
            </Box>
            {!isSingleTalent && <FindRightContact />}
        </Box>
    );
}

MessageRecipients.propTypes = {};
