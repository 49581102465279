import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import { SCHEDULED_DATE_FMT, SCHEDULED_TIME_FMT, MESSAGE_TYPES } from 'utils/constants';

export const TIME_TEXT_ENUMS = {
    PAST_ERROR: 'Time cannot be in the past',
    STANDARD_HELPER_TEXT: 'Schedule between 8 AM and 8 PM e.g. 10:00 AM',
};

export function getIsValidTime(date, time, messageType) {
    const isSmsMessageType = messageType === MESSAGE_TYPES.SMS;
    const helperText = isSmsMessageType ? TIME_TEXT_ENUMS.STANDARD_HELPER_TEXT : '';
    if (date && time) {
        const now = new Date();
        const userDate = parse(date, SCHEDULED_DATE_FMT, now);
        const userTime = parse(time, SCHEDULED_TIME_FMT, startOfDay(userDate));
        if (isValid(userTime)) {
            if (isAfter(now, userTime)) return [false, TIME_TEXT_ENUMS.PAST_ERROR];
            if (isSmsMessageType) return withinRange(userTime, helperText);
            return [true, helperText];
        }
    }
    return [false, helperText];
}

function withinRange(userTime, helperText) {
    const hourOfDay = userTime.getHours();
    const isWithinRange = hourOfDay >= 8 && hourOfDay <= 20;
    return [isWithinRange, helperText];
}
