import { useState } from 'react';
import Box from '@mui/material/Box';

import { Tooltip } from 'components/Tooltip';
import { RichTextInput } from 'components/Inputs';
import { Typography } from 'components/Typography';
import { useForcedFlow } from 'features/ForcedFlow';
import { SignatureButton } from './SignatureButton';
import { useTemplateSignature } from 'features/Signature';
import { FormattingButton } from 'components/MessageContent/FormattingButton';
import { defaultTooltips, getTooltipProps } from 'features/MessageDrawer/utils/tooltipHelpers';

export function SignatureForm() {
    const {
        signatureData,
        isFetchingData,
        handleUseSignature,
        handleChangeSignature,
        initialBody,
        innerHtml,
        validationMessage,
        handleRefreshSignature,
    } = useTemplateSignature();
    const { isForcedFlow } = useForcedFlow();

    const [isFormatting, setIsFormatting] = useState(false);
    const [tooltipsState, setTooltipsState] = useState(defaultTooltips);

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <Typography variant="labelSmall">Signature</Typography>
                    <Typography
                        variant="bodySmall"
                        sx={{ ml: 0.5, color: 'rgba(38, 55, 70, 0.48)' }}
                    >
                        Will not be used for SMS Templates
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip
                        {...getTooltipProps(tooltipsState, 'formatting-options', setTooltipsState)}
                    >
                        <span>
                            <FormattingButton
                                isCollapsed={false}
                                isFormatting={isFormatting}
                                onToggleFormatting={() =>
                                    setIsFormatting((prevState) => !prevState)
                                }
                            />
                        </span>
                    </Tooltip>
                    {!isForcedFlow && (
                        <Tooltip
                            {...getTooltipProps(
                                tooltipsState,
                                'signatures-tooltip',
                                setTooltipsState
                            )}
                        >
                            <span>
                                <SignatureButton
                                    signatures={signatureData}
                                    isFetching={isFetchingData}
                                    onUseSignature={handleUseSignature}
                                    onRefreshSignature={handleRefreshSignature}
                                />
                            </span>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <RichTextInput
                value={innerHtml}
                isFormatting={isFormatting}
                initialValue={initialBody}
                name="signature-rich-text"
                InputProps={{
                    'aria-label': 'signature-input',
                    'data-trackid': 'template-signature-input',
                }}
                formattingButtonPopperStyles={{
                    zIndex: 9998,
                    width: 440,
                }}
                helperText={validationMessage}
                error={Boolean(validationMessage)}
                onChange={handleChangeSignature}
                fullWidth
                initiateMount={false}
                formattingToolbarPlacement="bottom"
            />
        </>
    );
}
