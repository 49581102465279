import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { Typography } from 'components/Typography';
import { useGetMessageMetricsQuery } from 'services/conversations';
import { Link } from 'react-router-dom';
import { useGetDrilldownUrlQuery } from './useGetDrilldownUrlQuery';

const FunnelBar = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(11.25),
    marginBottom: theme.spacing(0.5),
}));

export const MESSAGE_METRIC_ENUMS = {
    sent: {
        label: 'Sent',
        messageQuery: 'sent,failed,pending,opened,link_accessed,replied',
        metricBarLabel: 'Number of messages that you sent using Nebula',
        tooltip:
            'The total of all outgoing email and SMS messages sent using Nebula. This includes direct messages, path messages, and messages sent via Digital Assistant. You can filter what is displayed in this view by using the "Filter Messages" option.',
        padding: 0,
        styles: {
            background: 'rgba(41, 204, 154, 0.08)',
            width: 412,
        },
    },
    delivered: {
        label: 'Delivered',
        messageQuery: 'sent,opened,link_accessed,replied',
        metricBarLabel: 'Number of messages that were successfully delivered',
        tooltip:
            'The number of messages that were successfully delivered to the defined email address or phone number. This excludes any bounced messages or messages sent to invalid email addresses or phone numbers',
        padding: 36,
        styles: {
            background: 'rgba(41, 204, 154, 0.24)',
            width: 340,
        },
    },
    opened: {
        label: 'Opened',
        messageQuery: 'opened,link_accessed,replied',
        metricBarLabel: 'Number of messages that were opened by the recipient',
        tooltip:
            'The total of messages that were opened by the recipient. SMS messages will only be counted toward this metric if the recipient also replied to your message.',
        padding: 76,
        styles: {
            background: 'rgba(41, 204, 154, 0.48)',
            width: 258,
        },
    },
    clicked: {
        label: 'Clicked',
        messageQuery: 'link_accessed',
        metricBarLabel: 'Number of messages where a link was clicked by the recipient',
        tooltip:
            'The number of messages where the recipient clicked a link in the message content. Only messages containing links can be counted toward this metric.',
        padding: 112,
        styles: {
            background: 'rgba(41, 204, 154, 0.72)',
            width: 178,
        },
    },
    engaged: {
        label: 'Engaged',
        messageQuery: 'replied',
        metricBarLabel: 'Number of messages where the recipient replied',
        tooltip: 'The total of messages where the recipient replied and engaged in conversation.',
        padding: 150,
        styles: {
            background: 'rgb(41, 204, 154)',
            width: 100,
            boxShadow: '0px 4px 64px 0px rgba(41, 204, 154, 0.48)',
        },
    },
};

export function SendMetricBar(props) {
    const { metricType } = props;

    const query = useGetDrilldownUrlQuery(metricType);

    const skipWithoutDateFilter = () => {
        if (query?.dateRange && !query?.dateGte) {
            return false;
        }
        if (query?.dateGte && !query?.dateRange) {
            return false;
        }
        return true;
    };
    const { data, isSuccess, isFetching } = useGetMessageMetricsQuery(query, {
        skip: !query || skipWithoutDateFilter(),
    });

    const styles = MESSAGE_METRIC_ENUMS[metricType].styles;
    const label = MESSAGE_METRIC_ENUMS[metricType].label;

    return (
        <FunnelBar sx={styles}>
            {isSuccess && (
                <Typography sx={{ color: 'white', fontSize: 32 }} variant="headline">
                    {data?.totalResults}
                </Typography>
            )}
            {isFetching && <CircularProgress size={20} />}
            {!isFetching && (
                <Typography
                    data-trackid={`total-messaging-${metricType}`}
                    component={Link}
                    to={`/communications/engagement/drilldown#${metricType}`}
                    state={{ metricType }}
                    sx={{ color: 'white' }}
                    variant="body"
                >
                    {label}
                </Typography>
            )}
        </FunnelBar>
    );
}

SendMetricBar.propTypes = {
    metricType: PropTypes.string,
};
