import PropTypes from 'prop-types';

import { Typography } from 'components/Typography';

export function TokenLookupStage(props) {
    const { tokensUsed = 0 } = props;

    return (
        <>
            <Typography
                variant="titleMedium"
                sx={{ color: 'text.primary', textAlign: 'center', mb: 0.5 }}
            >
                You will use {tokensUsed} lookup credit{tokensUsed === 1 ? '' : 's'} in this
                request.
                {tokensUsed > 10 && (
                    <>
                        <br />
                        This request may take a few minutes to process.
                    </>
                )}
            </Typography>
        </>
    );
}

TokenLookupStage.propTypes = {
    tokensUsed: PropTypes.number,
};
