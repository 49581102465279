import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import { usePaginationSort } from 'views/SalesAdminDashboard/hooks/usePaginationSort';
import { useGetMessagesDrilldownQuery } from 'services/conversations';
import { Typography } from '@mui/material';
import { ContactStatusBadge } from 'features/TalentCard/ContactDetails/ContactStatusBadge.styled';
import { theme } from 'theme';
import { formatLocalDate, formatPhoneNumber } from 'utils/formatters';
import { validateEmail } from 'utils/validation/validateEmail';
import { getStateCodeByStateName } from 'us-state-codes';
import { useGetDrilldownUrlQuery } from './SendMetrics/useGetDrilldownUrlQuery';

const getDefaultSource = (value, col) => {
    if (!value) return 'N/A';
    const isEmail = validateEmail(value);
    if (isEmail) {
        if (col === 'email') {
            return value;
        } else return 'N/A';
    } else {
        if (col === 'phone') {
            return value;
        } else return 'N/A';
    }
};

const renderDateCell = (params) => {
    const date = params.value;
    const formattedDate = Boolean(date) ? formatLocalDate(date, 'Pp') : '-';

    return (
        <Typography
            sx={(theme) => ({
                fontSize: '12px',
                textAlign: 'center',
                color: theme.palette.grey[500],
            })}
        >
            {formattedDate}
        </Typography>
    );
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '.MuiDataGrid-sortIcon': {
        color: theme.palette.primary.main,
    },
    '.MuiDataGrid-colCellWrapper': {
        borderLeft: 'none',
    },
    '.MuiDataGrid-columnHeaders ': {
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        borderRadius: 0,
    },
    '.MuiDataGrid-columnSeparator': {
        display: 'none',
    },
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },

    '.MuiDataGrid-cell': {
        borderBottom: 'none',
    },
    border: 'none',
    fontSize: '12px',
}));

export const MessageStatusBadge = styled(ContactStatusBadge)(({ theme, label }) => {
    let backgroundColor = theme.palette['text'].main;
    let textcolor = alpha(theme.palette.text.primary, 0.8);
    if (label === 'delivered' || label === 'sent') {
        backgroundColor = alpha(theme.palette.primary.main, 0.24);
    } else if (label === 'opened') {
        backgroundColor = 'rgba(95, 43, 225, 0.24)';
    } else if (label === 'replied') {
        backgroundColor = 'rgba(41, 204, 154, 1)';
        textcolor = 'white';
    } else if (label === 'bounced' || label === 'failed' || label === 'scheduled') {
        backgroundColor = 'rgba(228, 104, 104, 0.25)';
    }

    return {
        margin: '0px',
        padding: '10px',
        color: textcolor || 'GrayText',
        backgroundColor: backgroundColor || alpha(theme.palette.text.primary, 0.24),
    };
});

const Container = styled(Box)(({ theme }) => ({
    width: 'auto',
    height: theme.spacing(80),
    padding: '32px 0px',
    margin: 'auto',
    background: theme.palette.background.default,
}));

export const getTalentColumns = () =>
    [
        {
            field: 'status',
            headerName: 'STATUS',
            sortable: false,
            renderCell: (params) => {
                return <MessageStatusBadge label={params.value} />;
            },
        },
        {
            field: 'recipient',
            headerName: 'RECIPIENT',
            sortable: false,
            valueGetter: (params) => {
                return params.row.talentProfile.talentName || 'N/A';
            },
            renderCell: (params) => {
                const talentName = params.row.talentProfile.talentName;
                const talentId = params.row.talentId;
                return !!talentName ? (
                    <RouterLink
                        style={{
                            textDecoration: 'none',
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                        }}
                        to={`/talent/${talentId}`}
                    >
                        {talentName}
                    </RouterLink>
                ) : (
                    'N/A'
                );
            },
        },
        {
            field: 'title',
            headerName: 'TITLE',
            valueGetter: (params) => {
                return params.row.talentProfile.talentTitle || 'N/A';
            },
        },
        {
            field: 'location',
            headerName: 'LOCATION',
            valueGetter: (params) => {
                let city = params.row.talentProfile.talentLocation?.city;
                let state = params.row.talentProfile.talentLocation?.state;
                state = !!state ? getStateCodeByStateName(state) : '';
                return `${city}, ${state}`;
            },
        },
        {
            field: 'email',
            headerName: 'EMAIL',
            hideable: false,
            valueGetter: (params) => {
                if (params.row.messageType === 'default')
                    return getDefaultSource(params.row.data.to, 'email');
                return params.row.messageType === 'sms' ? 'N/A' : params.row.data.to;
            },
        },
        {
            field: 'phone',
            headerName: 'PHONE',
            valueGetter: (params) => {
                if (params.row.messageType === 'default')
                    return getDefaultSource(params.row.data.to, 'phone');
                const phoneNumber = params.row.messageType === 'email' ? 'N/A' : params.row.data.to;
                if (phoneNumber === 'N/A') return phoneNumber;
                return formatPhoneNumber(phoneNumber, 'US') || phoneNumber;
            },
        },
        {
            field: 'messageType',
            headerName: 'CHANNEL',
            valueGetter: (params) => {
                const channel = params.value;
                return !!channel ? channel.toUpperCase() : 'N/A';
            },
        },
        {
            field: 'sendDate',
            headerName: 'SEND DATE/TIME',
            renderCell: renderDateCell,
        },
        {
            field: 'optOutDate',
            headerName: 'OPT OUT DATE/TIME',
            renderCell: renderDateCell,
            align: 'center',
        },
    ].map((col) => {
        const sortable = col.field === 'sendDate';
        let minWidth = 120;
        if (col.field.includes('Date') || col.field === 'title' || col.field === 'recipient') {
            minWidth = 145;
        }
        if (col.field === 'messageType') {
            minWidth = 60;
        }
        return { ...col, sortable, minWidth: minWidth, filterable: false };
    });
export function TalentChart() {
    const { page, setPage, sortModel, setSortModel } = usePaginationSort();
    const [rowCount, setRowCount] = useState(0);

    const pageLength = 10;

    const { hash } = useLocation();

    const query = useGetDrilldownUrlQuery(hash.split('&')[0].replace('#', ''));

    const { data, isFetching } = useGetMessagesDrilldownQuery({
        params: {
            ...query,
            page: page + 1,
            pageSize: pageLength,
            sortBySendDate: sortModel.find((model) => model.field === 'sendDate')?.sort || 'desc',
        },
    });

    useEffect(() => {
        setRowCount((prevState) =>
            data?.totalResults !== undefined ? data.totalResults : prevState
        );
    }, [data?.totalResults]);

    useEffect(() => {
        setSortModel([{ field: 'sendDate', sort: 'desc' }]);
    }, [setSortModel]);

    return (
        <Container>
            <StyledDataGrid
                loading={isFetching}
                paginationMode="server"
                onPageChange={setPage}
                sortingMode="server"
                onSortModelChange={setSortModel}
                filterMode="server"
                getRowId={(row) => row.messageId}
                aria-label="users-data-grid"
                rows={data?.results || []}
                columns={getTalentColumns()}
                rowsPerPageOptions={[10]}
                page={page}
                pageSize={pageLength}
                rowCount={rowCount}
                autoHeight
                isRowSelectable={() => false}
                disableVirtualization
                autoPageSize
            />
        </Container>
    );
}
