import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import every from 'lodash/every';
import find from 'lodash/find';

import { CREDITS } from 'utils/constants';
import {
    useGetTalentContactsQuery,
    useSetContactAsPrimaryMutation,
    useUnsetContactAsPrimaryMutation,
    useGetCategoriesQuery,
    useCreateCategoryMutation,
    useGetUserContactSettingsQuery,
    useCreateCustomContactMutation,
    useFlagContactMutation,
} from 'services/contacts';
import { useLookupTalentContacts } from 'hooks/useLookupTalentContacts';
import { useZeroCreditPrompt } from 'features/ZeroCreditPrompt';
import { CONTACT_TYPES } from '../utils/constants';
import { useUserVerfiedContactsStore } from 'services/store/store';

function sortByWeight(a, b) {
    return a?.categoryWeight - b?.categoryWeight;
}

export function useContactDetails(props) {
    const { talent, isOpen, isProjectOutreachContactLookup } = props;

    const talentId = talent?.talentId;

    const { verifiedContacts, updateVerifiedContacts, updateReportedContacts, reportedContacts } =
        useUserVerfiedContactsStore();

    const contactsResponse = useGetTalentContactsQuery([talentId], { skip: !isOpen || !talentId });
    const { isSuccess, isFetching, isError } = contactsResponse;
    const contactDetails = contactsResponse?.data?.[talentId];

    const requiresLookup = useMemo(() => {
        if (isSuccess && find(contactDetails, { requiresLookup: false })) {
            return false;
        }
        if (
            isSuccess &&
            (contactDetails?.emails?.data?.length > 0 || contactDetails?.phones?.data?.length > 0)
        ) {
            return false;
        }
        if (isSuccess && every(contactDetails, { requiresLookup: true })) {
            return true;
        }
        return true;
    }, [isSuccess, contactDetails]);

    const [createCategory, { isLoading: isCreatingCategory }] = useCreateCategoryMutation();
    const [createCustomContact, { isLoading: isCreatingCustomContact }] =
        useCreateCustomContactMutation();

    const [setContactAsPrimary, { isLoading: isSettingContact }] = useSetContactAsPrimaryMutation();

    const [unsetContactAsPrimary, { isLoading: isUnsettingContact }] =
        useUnsetContactAsPrimaryMutation();

    const [flagContact, { isLoading: isFlaggingContact }] = useFlagContactMutation();

    const isPrimaryContactChangeLoading = isSettingContact || isUnsettingContact;
    const {
        data: userContactSettings,
        isFetching: isFetchingUserContactSettings,
        isError: isErrorUserContactSettings,
    } = useGetUserContactSettingsQuery(talentId, {
        skip: !isOpen || !contactDetails || requiresLookup,
    });

    const [lookupContacts, contactsLookupResult, cancel] = useLookupTalentContacts(
        isProjectOutreachContactLookup
    );

    const {
        isFetching: isLookupLoading,
        error: lookupError,
        isSuccess: isLookupSuccessful,
    } = contactsLookupResult;

    const { data: categoriesData } = useGetCategoriesQuery(null, { skip: !isOpen });
    const { setZeroCreditPromptOpen } = useZeroCreditPrompt();

    const loadingState = {
        isLoading: isFetching || isFetchingUserContactSettings,
        isLookupLoading,
        isLookupSuccessful,
        isPrimaryContactChangeLoading,
        isCreatingCategory,
        isFlaggingContact,
        isCreatingCustomContact,
    };

    const primaryContactPhoneID = userContactSettings?.primary?.PHONE;
    const primaryContactEmailID = userContactSettings?.primary?.EMAIL;

    const emailContacts = useMemo(() => {
        return contactDetails?.emails?.data
            ?.map((data) => ({
                ...data,
                primary: primaryContactEmailID === data.contactId,
                categoryWeight: userContactSettings?.contactDataSettings?.find(
                    (i) => i.contactDataId === data.contactId
                )?.categoryWeight,
            }))
            .sort(sortByWeight);
    }, [
        contactDetails?.emails?.data,
        userContactSettings?.contactDataSettings,
        primaryContactEmailID,
    ]);

    const phoneContacts = useMemo(() => {
        return contactDetails?.phones?.data
            ?.map((data) => ({
                ...data,
                primary: primaryContactPhoneID === data.contactId,
                categoryWeight: userContactSettings?.contactDataSettings?.find(
                    (i) => i.contactDataId === data.contactId
                )?.categoryWeight,
            }))
            .sort(sortByWeight);
    }, [
        contactDetails?.phones?.data,
        userContactSettings?.contactDataSettings,
        primaryContactPhoneID,
    ]);

    const handleLookup = () => {
        lookupContacts([talentId]);
    };

    useEffect(() => {
        if (lookupError) {
            if (lookupError?.status === 403) {
                setZeroCreditPromptOpen(CREDITS.LOOKUP);
            } else if (lookupError) {
                toast.error(
                    'There was an error looking up contacts. Please try again in a few minutes.'
                );
            }
        }
    }, [lookupError, setZeroCreditPromptOpen]);

    const handlePrimaryContactToggle = async (contactId, contactMedium = null) => {
        const callback = contactMedium ? unsetContactAsPrimary : setContactAsPrimary;
        const response = await callback({
            talentId,
            body: { ...(contactMedium && { unset: contactMedium }) },
            ...(!contactMedium && { params: { primary: contactId } }),
        });
        if (response?.error) {
            toast.error('Failed to update contact');
            return;
        }
        toast.success(contactMedium ? 'Unset primary contact' : 'Primary contact set successfully');
    };

    const handleCreateCustomContact = async ({ value, category, medium, primary }) => {
        if (!talentId) return;
        const response = await createCustomContact({
            talentId,
            payload: {
                contactMedium: medium,
                contact: value,
                category,
                url: medium === CONTACT_TYPES.SOCIAL ? value : '',
                primary,
            },
        });
        if (response?.error) {
            toast.error(response.error.data?.message || 'Failed to create custom contact');
            return;
        }
        toast.success('Custom contact created successfully');
    };

    const handleCreateCategory = async (category, onClose) => {
        const response = await createCategory({ talentId, category });
        if (response?.error) {
            if (response?.error?.data?.message?.includes('Settings already has category')) {
                toast.error(`Category ${category} already exists`);
            } else {
                toast.error('Failed to create category');
            }
            return;
        }
        toast.success('Category created successfully');
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const handleFlagContact = async (payload) => {
        const res = await flagContact({ ...payload, talentId });
        if (res.error) {
            res.error.data.message
                ? toast.error('You have already flagged this contact')
                : toast.error('There was an error updating contact');
            return;
        }
        const {
            body: { feedback, contactId },
        } = payload;
        if (feedback) {
            updateVerifiedContacts([...verifiedContacts, contactId]);
            toast.success('Contact has been updated successfully');
            return;
        }
        updateReportedContacts([...reportedContacts, contactId]);
        toast.success('Contact has been reported successfully');
    };

    useEffect(() => {
        if (isError || isErrorUserContactSettings) {
            toast.error(
                'There was an error loading contact details. Please try again in a few minutes.'
            );
        }
    }, [isError, isErrorUserContactSettings]);

    return {
        categoriesData,
        contactDetails,
        handleLookup,
        cancel,
        handlePrimaryContactToggle,
        handleFlagContact,
        handleCreateCategory,
        handleCreateCustomContact,
        loadingState,
        primaryContactPhoneID,
        primaryContactEmailID,
        emailContacts,
        phoneContacts,
        userContactSettings: requiresLookup ? {} : userContactSettings,
    };
}
