import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';

import { Contacts } from './Contacts';
import { CloseIcon } from 'components/Icons';

export function ContactsDialog(props) {
    const { messageType, open, onClose } = props;

    return (
        <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            aria-describedby="alert-dialog-slide-description"
            fullScreen
            maxWidth="sm"
        >
            <Box sx={{ display: 'flex' }}>
                <IconButton
                    onClick={onClose}
                    aria-label="close-contacts-dialog"
                    sx={{ ml: 'auto' }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Contacts dialogMessageType={messageType} onClose={onClose} />
        </Dialog>
    );
}

ContactsDialog.propTypes = {
    messageType: PropTypes.string,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
};
