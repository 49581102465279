import { MESSAGE_TYPES } from 'utils/constants';

export function parseInvalidFields(bodyHtml, subjectHtml, messageType) {
    const isEmail = messageType === MESSAGE_TYPES.EMAIL;

    if (bodyHtml) {
        if (isEmail) {
            return (
                bodyHtml?.includes('data-dynamic-field="false"') ||
                subjectHtml?.includes('data-dynamic-field="false"')
            );
        } else {
            return bodyHtml.includes('data-dynamic-field="false"');
        }
    }

    return false;
}
