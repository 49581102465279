import { useRef, useEffect, useCallback, useState } from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';

import {
    useLookupTalentContactsMutation,
    useLazyGetContactsResolveTaskQuery,
    useLazyGetTalentContactsQuery,
    contactsApi,
} from 'services/contacts';
import { TASK_STATES } from 'utils/constants';
import { DEFAULT_STATE, useAsyncPoll } from './useAsyncPoll';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { organizationsApi } from 'services/organizations';

function onTaskComplete(res) {
    return res?.data?.status === TASK_STATES.EXECUTION_COMPLETE;
}

export function parse(src) {
    if (src) {
        return Object.entries(src).reduce((acc, [k, v]) => {
            acc[k] = {};
            acc[k].emails = v.emails.data;
            acc[k].socials = v.socials.data;
            acc[k].phones = v.phones.data;
            return acc;
        }, {});
    }
    return null;
}

/**
 * Shadow usage of useLookupTalentContactsMutation,
 * abstract the polling and async code without needing to rewrite so many parts of the FE
 * Any and all exceptions are safely passed up to the code invoking this hook
 */
export function useLookupTalentContacts(isProjectOutreachContactLookup) {
    const queryClient = useQueryClient();
    const talentIds = useRef([]);
    const options = useRef(null);
    const data = useRef(null); //https://redux-toolkit.js.org/rtk-query/usage/queries#frequently-used-query-hook-return-values (isLoading and cached data)
    const taskId = useRef('');
    const [state, setState] = useState(DEFAULT_STATE);

    const dispatch = useDispatch();

    const [initiateLookup] = useLookupTalentContactsMutation();
    const [getResolveTask] = useLazyGetContactsResolveTaskQuery();
    const [pollResolveTask, { isError: isPollingError, error }, retry, reset] = useAsyncPoll(
        getResolveTask,
        onTaskComplete,
        150
    );

    const [getTalentContacts] = useLazyGetTalentContactsQuery();

    const processContacts = useCallback(async () => {
        if (talentIds.current.length === 0) return;
        const res = await getTalentContacts(talentIds.current);
        if (res.data) data.current = parse(res.data);
        setState(
            produce({ ...DEFAULT_STATE, data: data.current, reset }, (draft) => {
                draft.isSuccess = Boolean(res.data);
                draft.isError = Boolean(res.error);
                draft.error = res.error;
            })
        );
        dispatch(contactsApi.util.invalidateTags(['Contacts']));
        dispatch(organizationsApi.util.invalidateTags(['Organizations']));
        if (isProjectOutreachContactLookup) {
            queryClient.invalidateQueries('projectTalents');
        }
    }, [getTalentContacts, reset, dispatch, isProjectOutreachContactLookup, queryClient]);

    const execute = useCallback(() => {
        pollResolveTask({ taskId: taskId.current }, options.current)
            .then((res) => {
                if (onTaskComplete(res)) {
                    processContacts();
                }
            })
            .catch((error) => {
                toast.error('An error occurred before lookup task completed');
            });
    }, [pollResolveTask, processContacts]);

    const taskPoll = useCallback(() => {
        if (Boolean(retry) && Boolean(taskId.current)) {
            setTimeout(execute, 500);
        }
    }, [execute, retry]);

    useEffect(() => {
        taskPoll();
    }, [taskPoll]);

    useEffect(() => {
        setState((prev) =>
            produce(prev, (draft) => {
                draft.reset = reset;
            })
        );
    }, [reset]); //ensure callback is set before first invocation

    const cancel = useCallback(() => {
        talentIds.current = [];
        setState(
            produce({ ...DEFAULT_STATE, data: data.current, reset }, (draft) => {
                draft.isLoading = !Boolean(data.current);
            })
        );
    }, [reset]);

    const invoke = useCallback(
        async (ids, opts) => {
            reset();
            talentIds.current = ids;
            options.current = opts;
            setState(
                produce({ ...DEFAULT_STATE, data: data.current, reset }, (draft) => {
                    draft.isLoading = !Boolean(data.current);
                    draft.isFetching = true;
                })
            );
            const res = await initiateLookup(talentIds.current, options.current);
            if (res.data) {
                taskId.current = res.data.taskId;
                if (taskId.current) {
                    execute();
                } else if (talentIds.current) {
                    processContacts();
                }
            } else {
                setState(
                    produce({ ...DEFAULT_STATE, data: data.current, reset }, (draft) => {
                        draft.isError = Boolean(res.error);
                        draft.error = res.error;
                    })
                );
            }
        },
        [initiateLookup, reset, execute, processContacts]
    );

    useEffect(() => {
        if (isPollingError && error) {
            setState(
                produce({ ...DEFAULT_STATE, data: data.current, reset }, (draft) => {
                    draft.isError = Boolean(error);
                    draft.error = error;
                })
            );
        }
    }, [isPollingError, error, reset]);

    return [invoke, state, cancel];
}
