import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setProfiles, setContacts } from '../messageSlice';

export function useTalentData() {
    const { selectedProfiles, selectedContacts } = useSelector((state) => state.message);
    const dispatch = useDispatch();

    const handleSetProfiles = useCallback(
        (profiles) => {
            dispatch(setProfiles(profiles));
        },
        [dispatch]
    );

    const handleSetContacts = useCallback(
        (contacts) => {
            dispatch(setContacts(contacts));
        },
        [dispatch]
    );

    return {
        selectedProfiles,
        selectedContacts,
        handleSetProfiles,
        handleSetContacts,
    };
}
