import { memo } from 'react';
import { Editor } from 'slate';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { sxProp } from './common';

export const toggleMark = (editor, key, value = true) => {
    const callback = isMarkActive(editor, key) ? Editor.removeMark : Editor.addMark;
    callback(editor, key, value);
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const MarkButton = memo(({ format, label, Icon, disabled }) => {
    const editor = useSlate();

    return (
        <Box className={sxProp.flex}>
            <IconButton
                tabIndex={1}
                title={label}
                disabled={disabled}
                disableFocusRipple
                aria-label={`format-${format}`}
                sx={isMarkActive(editor, format) ? sxProp.activeBtn : sxProp.inactiveBtn}
                onClick={(event) => {
                    event.preventDefault();
                    toggleMark(editor, format);
                }}
                disableRipple
            >
                <Icon />
            </IconButton>
        </Box>
    );
});

MarkButton.propTypes = {
    format: PropTypes.string,
    label: PropTypes.string,
    Icon: PropTypes.any,
    disabled: PropTypes.bool,
};
