/**
 * max token length for openai request is 4097 so setting this max
 * length on the input prompt should keep us within that restraint
 */
export const MAX_JD_PROMPT_LENGTH = 1920;

export const PROMPT_TYPES = {
    text: 'text',
    select: 'select',
};

export const JD_TONE_OPTIONS = {
    professional: { label: 'Professional', value: 'professional' },
    startup: { label: 'Startup', value: 'startup' },
    witty: { label: 'Witty', value: 'witty' },
    enthusiastic: { label: 'Enthusiastic', value: 'enthusiastic' },
};

export const JD_PROMPT_OTHER_OPTION = 'custom';

export const JOB_TITLE_JD_PROMPT = {
    type: PROMPT_TYPES.text,
    promptKey: 'jobTitlePrompt',
    prompt: 'What is the title of the role you are looking to fill?',
    hint: 'Tip: Be sure to include the roles seniority by including text like "Junior", "Senior", "Director."',
    placeholder: 'Senior Product Manager - Insights and Analytics',
    dataTrackId: 'jd-questions-q1-input',
};

export const COMPANY_JD_PROMPT = {
    type: PROMPT_TYPES.text,
    promptKey: 'companyPrompt',
    prompt: 'In a few words or adjectives, how would you describe the hiring organization?',
    hint: "Tip: Be sure to include the company's industry in your description.",
    placeholder: 'Ambitious tech startup in the talent acquisition space',
    dataTrackId: 'jd-questions-q2-input',
};

export const CANDIDATE_JD_PROMPT = {
    type: PROMPT_TYPES.text,
    promptKey: 'candidatePrompt',
    prompt: 'How would you describe your ideal hire using a few skills and adjectives?',
    hint: 'Tip: Be sure to limit your response to only essential skills and qualities.',
    placeholder:
        'Highly Analytical, Data visualization tools like looker, Fascinated with AI and machine learning',
    dataTrackId: 'jd-questions-q3-input',
};

export const TONE_JD_PROMPT = {
    type: PROMPT_TYPES.select,
    promptKey: 'tonePrompt',
    prompt: 'What tone would you like me to use when I draft this job description?',
    hint: '',
    placeholder: 'Choose a tone',
    dataTrackId: 'jd-questions-q4-input',
    options: [
        { label: JD_TONE_OPTIONS.professional.label, value: JD_TONE_OPTIONS.professional.value },
        { label: JD_TONE_OPTIONS.startup.label, value: JD_TONE_OPTIONS.startup.value },
        { label: JD_TONE_OPTIONS.witty.label, value: JD_TONE_OPTIONS.witty.value },
        { label: JD_TONE_OPTIONS.enthusiastic.label, value: JD_TONE_OPTIONS.enthusiastic.value },
        { label: 'Custom', value: JD_PROMPT_OTHER_OPTION },
    ],
};

export const WARNING_LOCAL_STORAGE_KEY = 'jd-search-warning';

export const REACTIONS = {
    SMILE: 'smile',
    SAD: 'sad',
    DEFAULT: 'default',
};
