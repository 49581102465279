import SvgIcon from '@mui/material/SvgIcon';

export function EllipsesIcon(props) {
    return (
        <SvgIcon viewBox="0 0 17 16" {...props}>
            <path
                d="M8.5005 6.3999C7.62049 6.3999 6.90047 7.11992 6.90047 7.99993C6.90047 8.87994 7.62049 9.59992 8.5005 9.59992C9.38051 9.59992 10.1005 8.87994 10.1005 7.99993C10.1005 7.11992 9.38051 6.3999 8.5005 6.3999Z"
                fill="#263746"
                fillOpacity="0.72"
            />
            <path
                d="M14.9 6.3999C14.02 6.3999 13.3 7.11992 13.3 7.99993C13.3 8.87994 14.02 9.59992 14.9 9.59992C15.78 9.59992 16.5 8.87994 16.5 7.99993C16.5 7.11992 15.78 6.3999 14.9 6.3999Z"
                fill="#263746"
                fillOpacity="0.72"
            />
            <path
                d="M2.10003 6.3999C1.22002 6.3999 0.5 7.11992 0.5 7.99993C0.5 8.87994 1.22002 9.59992 2.10003 9.59992C2.98004 9.59992 3.70004 8.87994 3.70004 7.99993C3.70004 7.11992 2.98004 6.3999 2.10003 6.3999Z"
                fill="#263746"
                fillOpacity="0.72"
            />
        </SvgIcon>
    );
}
