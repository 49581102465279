import SvgIcon from '@mui/material/SvgIcon';

export function ShortlistProspectIcon(props) {
    return (
        <SvgIcon viewBox="0 0 152 124" sx={{ width: 152, height: 124 }} {...props}>
            <g clipPath="url(#clip0_2866_66265)" color="white">
                <path
                    d="M-12.5 19.2857C-12.9734 19.2857 -13.3571 19.6695 -13.3571 20.1429C-13.3571 20.6162 -12.9734 21 -12.5 21V19.2857ZM68.5286 21C69.002 21 69.3857 20.6162 69.3857 20.1429C69.3857 19.6695 69.002 19.2857 68.5286 19.2857V21ZM-12.5 21H28.0143V19.2857H-12.5V21ZM28.0143 21H68.5286V19.2857H28.0143V21Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 23.8572C-12.9734 23.8572 -13.3571 24.2409 -13.3571 24.7143C-13.3571 25.1877 -12.9734 25.5714 -12.5 25.5714V23.8572ZM68.5286 25.5714C69.002 25.5714 69.3857 25.1877 69.3857 24.7143C69.3857 24.2409 69.002 23.8572 68.5286 23.8572V25.5714ZM-12.5 25.5714H28.0143V23.8572H-12.5V25.5714ZM28.0143 25.5714H68.5286V23.8572H28.0143V25.5714Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 28.4286C-12.9734 28.4286 -13.3571 28.8123 -13.3571 29.2857C-13.3571 29.7591 -12.9734 30.1428 -12.5 30.1428V28.4286ZM68.5286 30.1428C69.002 30.1428 69.3857 29.7591 69.3857 29.2857C69.3857 28.8123 69.002 28.4286 68.5286 28.4286V30.1428ZM-12.5 30.1428H28.0143V28.4286H-12.5V30.1428ZM28.0143 30.1428H68.5286V28.4286H28.0143V30.1428Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <rect
                    x="73.0996"
                    y="20.1429"
                    width="6.4"
                    height="6.4"
                    rx="0.914286"
                    stroke="#318DDE"
                    strokeOpacity="0.24"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M-12.5 39.2857C-12.9734 39.2857 -13.3571 39.6695 -13.3571 40.1429C-13.3571 40.6162 -12.9734 41 -12.5 41V39.2857ZM68.5286 41C69.002 41 69.3857 40.6162 69.3857 40.1429C69.3857 39.6695 69.002 39.2857 68.5286 39.2857V41ZM-12.5 41H28.0143V39.2857H-12.5V41ZM28.0143 41H68.5286V39.2857H28.0143V41Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 43.8572C-12.9734 43.8572 -13.3571 44.2409 -13.3571 44.7143C-13.3571 45.1877 -12.9734 45.5714 -12.5 45.5714V43.8572ZM68.5286 45.5714C69.002 45.5714 69.3857 45.1877 69.3857 44.7143C69.3857 44.2409 69.002 43.8572 68.5286 43.8572V45.5714ZM-12.5 45.5714H28.0143V43.8572H-12.5V45.5714ZM28.0143 45.5714H68.5286V43.8572H28.0143V45.5714Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 48.4286C-12.9734 48.4286 -13.3571 48.8123 -13.3571 49.2857C-13.3571 49.7591 -12.9734 50.1428 -12.5 50.1428V48.4286ZM68.5286 50.1428C69.002 50.1428 69.3857 49.7591 69.3857 49.2857C69.3857 48.8123 69.002 48.4286 68.5286 48.4286V50.1428ZM-12.5 50.1428H28.0143V48.4286H-12.5V50.1428ZM28.0143 50.1428H68.5286V48.4286H28.0143V50.1428Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <rect
                    x="73.0996"
                    y="40.1429"
                    width="6.4"
                    height="6.4"
                    rx="0.914286"
                    stroke="#318DDE"
                    strokeOpacity="0.24"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M-12.5 59.2857C-12.9734 59.2857 -13.3571 59.6695 -13.3571 60.1429C-13.3571 60.6162 -12.9734 61 -12.5 61V59.2857ZM68.5286 61C69.002 61 69.3857 60.6162 69.3857 60.1429C69.3857 59.6695 69.002 59.2857 68.5286 59.2857V61ZM-12.5 61H28.0143V59.2857H-12.5V61ZM28.0143 61H68.5286V59.2857H28.0143V61Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 63.8572C-12.9734 63.8572 -13.3571 64.2409 -13.3571 64.7143C-13.3571 65.1877 -12.9734 65.5714 -12.5 65.5714V63.8572ZM68.5286 65.5714C69.002 65.5714 69.3857 65.1877 69.3857 64.7143C69.3857 64.2409 69.002 63.8572 68.5286 63.8572V65.5714ZM-12.5 65.5714H28.0143V63.8572H-12.5V65.5714ZM28.0143 65.5714H68.5286V63.8572H28.0143V65.5714Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 68.4286C-12.9734 68.4286 -13.3571 68.8123 -13.3571 69.2857C-13.3571 69.7591 -12.9734 70.1428 -12.5 70.1428V68.4286ZM68.5286 70.1428C69.002 70.1428 69.3857 69.7591 69.3857 69.2857C69.3857 68.8123 69.002 68.4286 68.5286 68.4286V70.1428ZM-12.5 70.1428H28.0143V68.4286H-12.5V70.1428ZM28.0143 70.1428H68.5286V68.4286H28.0143V70.1428Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <rect
                    x="73.0996"
                    y="60.1429"
                    width="6.4"
                    height="6.4"
                    rx="0.914286"
                    stroke="#318DDE"
                    strokeOpacity="0.24"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M-12.5 79.2857C-12.9734 79.2857 -13.3571 79.6695 -13.3571 80.1429C-13.3571 80.6162 -12.9734 81 -12.5 81V79.2857ZM68.5286 81C69.002 81 69.3857 80.6162 69.3857 80.1429C69.3857 79.6695 69.002 79.2857 68.5286 79.2857V81ZM-12.5 81H28.0143V79.2857H-12.5V81ZM28.0143 81H68.5286V79.2857H28.0143V81Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 83.8572C-12.9734 83.8572 -13.3571 84.2409 -13.3571 84.7143C-13.3571 85.1877 -12.9734 85.5714 -12.5 85.5714V83.8572ZM68.5286 85.5714C69.002 85.5714 69.3857 85.1877 69.3857 84.7143C69.3857 84.2409 69.002 83.8572 68.5286 83.8572V85.5714ZM-12.5 85.5714H28.0143V83.8572H-12.5V85.5714ZM28.0143 85.5714H68.5286V83.8572H28.0143V85.5714Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 88.4286C-12.9734 88.4286 -13.3571 88.8123 -13.3571 89.2857C-13.3571 89.7591 -12.9734 90.1428 -12.5 90.1428V88.4286ZM68.5286 90.1428C69.002 90.1428 69.3857 89.7591 69.3857 89.2857C69.3857 88.8123 69.002 88.4286 68.5286 88.4286V90.1428ZM-12.5 90.1428H28.0143V88.4286H-12.5V90.1428ZM28.0143 90.1428H68.5286V88.4286H28.0143V90.1428Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <rect
                    x="73.0996"
                    y="80.1429"
                    width="6.4"
                    height="6.4"
                    rx="0.914286"
                    stroke="#318DDE"
                    strokeOpacity="0.24"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M-12.5 99.2857C-12.9734 99.2857 -13.3571 99.6695 -13.3571 100.143C-13.3571 100.616 -12.9734 101 -12.5 101V99.2857ZM68.5286 101C69.002 101 69.3857 100.616 69.3857 100.143C69.3857 99.6695 69.002 99.2857 68.5286 99.2857V101ZM-12.5 101H28.0143V99.2857H-12.5V101ZM28.0143 101H68.5286V99.2857H28.0143V101Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 103.857C-12.9734 103.857 -13.3571 104.241 -13.3571 104.714C-13.3571 105.188 -12.9734 105.571 -12.5 105.571V103.857ZM68.5286 105.571C69.002 105.571 69.3857 105.188 69.3857 104.714C69.3857 104.241 69.002 103.857 68.5286 103.857V105.571ZM-12.5 105.571H28.0143V103.857H-12.5V105.571ZM28.0143 105.571H68.5286V103.857H28.0143V105.571Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <path
                    d="M-12.5 108.429C-12.9734 108.429 -13.3571 108.812 -13.3571 109.286C-13.3571 109.759 -12.9734 110.143 -12.5 110.143V108.429ZM68.5286 110.143C69.002 110.143 69.3857 109.759 69.3857 109.286C69.3857 108.812 69.002 108.429 68.5286 108.429V110.143ZM-12.5 110.143H28.0143V108.429H-12.5V110.143ZM28.0143 110.143H68.5286V108.429H28.0143V110.143Z"
                    fill="#318DDE"
                    fillOpacity="0.24"
                />
                <rect
                    x="73.0996"
                    y="100.143"
                    width="6.4"
                    height="6.4"
                    rx="0.914286"
                    stroke="#318DDE"
                    strokeOpacity="0.24"
                    strokeWidth="1.71429"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g filter="url(#filter0_dd_2866_66265)" color="white">
                <g clipPath="url(#clip1_2866_66265)">
                    <rect x="41.5" y="14" width="64" height="64" rx="32" fill="#F3F8FD" />
                    <path
                        d="M30.5 19.2857C30.0266 19.2857 29.6429 19.6695 29.6429 20.1429C29.6429 20.6162 30.0266 21 30.5 21V19.2857ZM69.5286 21C70.002 21 70.3857 20.6162 70.3857 20.1429C70.3857 19.6695 70.002 19.2857 69.5286 19.2857V21ZM30.5 21H50.0143V19.2857H30.5V21ZM50.0143 21H69.5286V19.2857H50.0143V21Z"
                        fill="url(#paint0_linear_2866_66265)"
                    />
                    <path
                        d="M30.5 23.8572C30.0266 23.8572 29.6429 24.2409 29.6429 24.7143C29.6429 25.1877 30.0266 25.5714 30.5 25.5714V23.8572ZM69.5286 25.5714C70.002 25.5714 70.3857 25.1877 70.3857 24.7143C70.3857 24.2409 70.002 23.8572 69.5286 23.8572V25.5714ZM30.5 25.5714H50.0143V23.8572H30.5V25.5714ZM50.0143 25.5714H69.5286V23.8572H50.0143V25.5714Z"
                        fill="url(#paint1_linear_2866_66265)"
                    />
                    <path
                        d="M30.5 28.4286C30.0266 28.4286 29.6429 28.8123 29.6429 29.2857C29.6429 29.7591 30.0266 30.1428 30.5 30.1428V28.4286ZM69.5286 30.1428C70.002 30.1428 70.3857 29.7591 70.3857 29.2857C70.3857 28.8123 70.002 28.4286 69.5286 28.4286V30.1428ZM30.5 30.1428H50.0143V28.4286H30.5V30.1428ZM50.0143 30.1428H69.5286V28.4286H50.0143V30.1428Z"
                        fill="url(#paint2_linear_2866_66265)"
                    />
                    <rect
                        x="74.0996"
                        y="20.1429"
                        width="6.4"
                        height="6.4"
                        rx="0.914286"
                        stroke="url(#paint3_linear_2866_66265)"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <g clipPath="url(#clip2_2866_66265)">
                        <path
                            d="M84.5 25C84.5 21.6863 87.1863 19 90.5 19C93.8137 19 96.5 21.6863 96.5 25C96.5 28.3137 93.8137 31 90.5 31C87.1863 31 84.5 28.3137 84.5 25Z"
                            fill="#29CC9A"
                            fillOpacity="0.12"
                        />
                        <path
                            d="M93.8552 22.605C93.8087 22.5581 93.7534 22.5209 93.6925 22.4955C93.6316 22.4702 93.5662 22.4571 93.5002 22.4571C93.4342 22.4571 93.3689 22.4702 93.3079 22.4955C93.247 22.5209 93.1917 22.5581 93.1452 22.605L89.4202 26.335L87.8552 24.765C87.807 24.7184 87.75 24.6817 87.6876 24.6571C87.6251 24.6325 87.5585 24.6204 87.4914 24.6216C87.4243 24.6228 87.3581 24.6371 87.2965 24.6639C87.235 24.6906 87.1793 24.7292 87.1327 24.7775C87.0861 24.8258 87.0494 24.8827 87.0248 24.9452C87.0002 25.0076 86.9882 25.0742 86.9893 25.1413C86.9905 25.2084 87.0049 25.2746 87.0316 25.3362C87.0584 25.3977 87.097 25.4534 87.1452 25.5L89.0652 27.42C89.1117 27.4669 89.167 27.5041 89.2279 27.5294C89.2889 27.5548 89.3542 27.5679 89.4202 27.5679C89.4862 27.5679 89.5516 27.5548 89.6125 27.5294C89.6734 27.5041 89.7287 27.4669 89.7752 27.42L93.8552 23.34C93.906 23.2932 93.9465 23.2363 93.9742 23.1731C94.0019 23.1098 94.0162 23.0415 94.0162 22.9725C94.0162 22.9034 94.0019 22.8351 93.9742 22.7719C93.9465 22.7086 93.906 22.6518 93.8552 22.605Z"
                            fill="#29CC9A"
                        />
                    </g>
                    <path
                        d="M30.5 39.2857C30.0266 39.2857 29.6429 39.6695 29.6429 40.1429C29.6429 40.6162 30.0266 41 30.5 41V39.2857ZM69.5286 41C70.002 41 70.3857 40.6162 70.3857 40.1429C70.3857 39.6695 70.002 39.2857 69.5286 39.2857V41ZM30.5 41H50.0143V39.2857H30.5V41ZM50.0143 41H69.5286V39.2857H50.0143V41Z"
                        fill="url(#paint4_linear_2866_66265)"
                    />
                    <path
                        d="M30.5 43.8572C30.0266 43.8572 29.6429 44.2409 29.6429 44.7143C29.6429 45.1877 30.0266 45.5714 30.5 45.5714V43.8572ZM69.5286 45.5714C70.002 45.5714 70.3857 45.1877 70.3857 44.7143C70.3857 44.2409 70.002 43.8572 69.5286 43.8572V45.5714ZM30.5 45.5714H50.0143V43.8572H30.5V45.5714ZM50.0143 45.5714H69.5286V43.8572H50.0143V45.5714Z"
                        fill="url(#paint5_linear_2866_66265)"
                    />
                    <path
                        d="M30.5 48.4286C30.0266 48.4286 29.6429 48.8123 29.6429 49.2857C29.6429 49.7591 30.0266 50.1428 30.5 50.1428V48.4286ZM69.5286 50.1428C70.002 50.1428 70.3857 49.7591 70.3857 49.2857C70.3857 48.8123 70.002 48.4286 69.5286 48.4286V50.1428ZM30.5 50.1428H50.0143V48.4286H30.5V50.1428ZM50.0143 50.1428H69.5286V48.4286H50.0143V50.1428Z"
                        fill="url(#paint6_linear_2866_66265)"
                    />
                    <rect
                        x="74.0996"
                        y="40.1429"
                        width="6.4"
                        height="6.4"
                        rx="0.914286"
                        stroke="url(#paint7_linear_2866_66265)"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <g clipPath="url(#clip3_2866_66265)">
                        <path
                            d="M84.5 45C84.5 41.6863 87.1863 39 90.5 39C93.8137 39 96.5 41.6863 96.5 45C96.5 48.3137 93.8137 51 90.5 51C87.1863 51 84.5 48.3137 84.5 45Z"
                            fill="#29CC9A"
                            fillOpacity="0.12"
                        />
                        <path
                            d="M93.8552 42.605C93.8087 42.5581 93.7534 42.5209 93.6925 42.4955C93.6316 42.4702 93.5662 42.4571 93.5002 42.4571C93.4342 42.4571 93.3689 42.4702 93.3079 42.4955C93.247 42.5209 93.1917 42.5581 93.1452 42.605L89.4202 46.335L87.8552 44.765C87.807 44.7184 87.75 44.6817 87.6876 44.6571C87.6251 44.6325 87.5585 44.6204 87.4914 44.6216C87.4243 44.6228 87.3581 44.6371 87.2965 44.6639C87.235 44.6906 87.1793 44.7292 87.1327 44.7775C87.0861 44.8258 87.0494 44.8827 87.0248 44.9452C87.0002 45.0076 86.9882 45.0742 86.9893 45.1413C86.9905 45.2084 87.0049 45.2746 87.0316 45.3362C87.0584 45.3977 87.097 45.4534 87.1452 45.5L89.0652 47.42C89.1117 47.4669 89.167 47.5041 89.2279 47.5294C89.2889 47.5548 89.3542 47.5679 89.4202 47.5679C89.4862 47.5679 89.5516 47.5548 89.6125 47.5294C89.6734 47.5041 89.7287 47.4669 89.7752 47.42L93.8552 43.34C93.906 43.2932 93.9465 43.2363 93.9742 43.1731C94.0019 43.1098 94.0162 43.0415 94.0162 42.9725C94.0162 42.9034 94.0019 42.8351 93.9742 42.7719C93.9465 42.7086 93.906 42.6518 93.8552 42.605Z"
                            fill="#29CC9A"
                        />
                    </g>
                    <path
                        d="M30.5 59.2857C30.0266 59.2857 29.6429 59.6695 29.6429 60.1429C29.6429 60.6162 30.0266 61 30.5 61V59.2857ZM69.5286 61C70.002 61 70.3857 60.6162 70.3857 60.1429C70.3857 59.6695 70.002 59.2857 69.5286 59.2857V61ZM30.5 61H50.0143V59.2857H30.5V61ZM50.0143 61H69.5286V59.2857H50.0143V61Z"
                        fill="url(#paint8_linear_2866_66265)"
                    />
                    <path
                        d="M30.5 63.8572C30.0266 63.8572 29.6429 64.2409 29.6429 64.7143C29.6429 65.1877 30.0266 65.5714 30.5 65.5714V63.8572ZM69.5286 65.5714C70.002 65.5714 70.3857 65.1877 70.3857 64.7143C70.3857 64.2409 70.002 63.8572 69.5286 63.8572V65.5714ZM30.5 65.5714H50.0143V63.8572H30.5V65.5714ZM50.0143 65.5714H69.5286V63.8572H50.0143V65.5714Z"
                        fill="url(#paint9_linear_2866_66265)"
                    />
                    <path
                        d="M30.5 68.4286C30.0266 68.4286 29.6429 68.8123 29.6429 69.2857C29.6429 69.7591 30.0266 70.1428 30.5 70.1428V68.4286ZM69.5286 70.1428C70.002 70.1428 70.3857 69.7591 70.3857 69.2857C70.3857 68.8123 70.002 68.4286 69.5286 68.4286V70.1428ZM30.5 70.1428H50.0143V68.4286H30.5V70.1428ZM50.0143 70.1428H69.5286V68.4286H50.0143V70.1428Z"
                        fill="url(#paint10_linear_2866_66265)"
                    />
                    <rect
                        x="74.0996"
                        y="60.1429"
                        width="6.4"
                        height="6.4"
                        rx="0.914286"
                        stroke="url(#paint11_linear_2866_66265)"
                        strokeWidth="1.71429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <g clipPath="url(#clip4_2866_66265)">
                        <path
                            d="M84.5 65C84.5 61.6863 87.1863 59 90.5 59C93.8137 59 96.5 61.6863 96.5 65C96.5 68.3137 93.8137 71 90.5 71C87.1863 71 84.5 68.3137 84.5 65Z"
                            fill="#29CC9A"
                            fillOpacity="0.12"
                        />
                        <path
                            d="M93.8552 62.605C93.8087 62.5581 93.7534 62.5209 93.6925 62.4955C93.6316 62.4702 93.5662 62.4571 93.5002 62.4571C93.4342 62.4571 93.3689 62.4702 93.3079 62.4955C93.247 62.5209 93.1917 62.5581 93.1452 62.605L89.4202 66.335L87.8552 64.765C87.807 64.7184 87.75 64.6817 87.6876 64.6571C87.6251 64.6325 87.5585 64.6204 87.4914 64.6216C87.4243 64.6228 87.3581 64.6371 87.2965 64.6639C87.235 64.6906 87.1793 64.7292 87.1327 64.7775C87.0861 64.8258 87.0494 64.8827 87.0248 64.9452C87.0002 65.0076 86.9882 65.0742 86.9893 65.1413C86.9905 65.2084 87.0049 65.2746 87.0316 65.3362C87.0584 65.3977 87.097 65.4534 87.1452 65.5L89.0652 67.42C89.1117 67.4669 89.167 67.5041 89.2279 67.5294C89.2889 67.5548 89.3542 67.5679 89.4202 67.5679C89.4862 67.5679 89.5516 67.5548 89.6125 67.5294C89.6734 67.5041 89.7287 67.4669 89.7752 67.42L93.8552 63.34C93.906 63.2932 93.9465 63.2363 93.9742 63.1731C94.0019 63.1098 94.0162 63.0415 94.0162 62.9725C94.0162 62.9034 94.0019 62.8351 93.9742 62.7719C93.9465 62.7086 93.906 62.6518 93.8552 62.605Z"
                            fill="#29CC9A"
                        />
                    </g>
                </g>
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M110.876 72.8119L146.218 108.154C149.135 111.071 149.135 115.801 146.218 118.718C143.301 121.635 138.571 121.635 135.654 118.718L100.312 83.3756C92.7259 88.8348 83.58 92.167 73.5835 92.167C48.1312 92.167 27.5 71.5357 27.5 46.0835C27.5 20.6312 48.1312 0 73.5835 0C99.0357 0 119.667 20.6312 119.667 46.0835C119.667 56.08 116.335 65.2259 110.876 72.8119ZM73.5835 14.1795C55.93 14.1795 41.6795 28.43 41.6795 46.0835C41.6795 63.737 55.93 77.9874 73.5835 77.9874C91.237 77.9874 105.487 63.737 105.487 46.0835C105.487 28.43 91.237 14.1795 73.5835 14.1795Z"
                fill="#318DDE"
                fillOpacity="0.08"
            />
            <defs>
                <filter
                    id="filter0_dd_2866_66265"
                    x="33.5"
                    y="8"
                    width="80"
                    height="80"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="2"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect1_dropShadow_2866_66265"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="3" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2866_66265"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.247059 0 0 0 0 0.32549 0 0 0 0 0.392157 0 0 0 0.32 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_2866_66265"
                        result="effect2_dropShadow_2866_66265"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_2866_66265"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_2866_66265"
                    x1="30.5"
                    y1="20.502"
                    x2="68.2104"
                    y2="27.5807"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2866_66265"
                    x1="30.5"
                    y1="25.0734"
                    x2="68.2104"
                    y2="32.1521"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2866_66265"
                    x1="30.5"
                    y1="29.6449"
                    x2="68.2104"
                    y2="36.7235"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_2866_66265"
                    x1="74.0996"
                    y1="22.4414"
                    x2="80.5012"
                    y2="22.4722"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_2866_66265"
                    x1="30.5"
                    y1="40.502"
                    x2="68.2104"
                    y2="47.5807"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_2866_66265"
                    x1="30.5"
                    y1="45.0734"
                    x2="68.2104"
                    y2="52.1521"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_2866_66265"
                    x1="30.5"
                    y1="49.6449"
                    x2="68.2104"
                    y2="56.7235"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_2866_66265"
                    x1="74.0996"
                    y1="42.4414"
                    x2="80.5012"
                    y2="42.4722"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_2866_66265"
                    x1="30.5"
                    y1="60.502"
                    x2="68.2104"
                    y2="67.5807"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_2866_66265"
                    x1="30.5"
                    y1="65.0734"
                    x2="68.2104"
                    y2="72.1521"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_2866_66265"
                    x1="30.5"
                    y1="69.6449"
                    x2="68.2104"
                    y2="76.7235"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_2866_66265"
                    x1="74.0996"
                    y1="62.4414"
                    x2="80.5012"
                    y2="62.4722"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <clipPath id="clip0_2866_66265">
                    <rect x="0.5" y="19" width="96" height="96" rx="48" fill="white" />
                </clipPath>
                <clipPath id="clip1_2866_66265">
                    <rect x="41.5" y="14" width="64" height="64" rx="32" fill="white" />
                </clipPath>
                <clipPath id="clip2_2866_66265">
                    <path
                        d="M84.5 25C84.5 21.6863 87.1863 19 90.5 19C93.8137 19 96.5 21.6863 96.5 25C96.5 28.3137 93.8137 31 90.5 31C87.1863 31 84.5 28.3137 84.5 25Z"
                        fill="white"
                    />
                </clipPath>
                <clipPath id="clip3_2866_66265">
                    <path
                        d="M84.5 45C84.5 41.6863 87.1863 39 90.5 39C93.8137 39 96.5 41.6863 96.5 45C96.5 48.3137 93.8137 51 90.5 51C87.1863 51 84.5 48.3137 84.5 45Z"
                        fill="white"
                    />
                </clipPath>
                <clipPath id="clip4_2866_66265">
                    <path
                        d="M84.5 65C84.5 61.6863 87.1863 59 90.5 59C93.8137 59 96.5 61.6863 96.5 65C96.5 68.3137 93.8137 71 90.5 71C87.1863 71 84.5 68.3137 84.5 65Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
