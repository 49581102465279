import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const projectsApi = createApi({
    reducerPath: 'projectsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/projects/v1/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['RoleDetails'],
    endpoints: (builder) => ({
        getProjectRoleDetails: builder.query({
            query: (projectId) => `roles/${projectId}`,
            providesTags: (result, error, projectId) => [{ type: 'RoleDetails', id: projectId }],
        }),
        updateProjectRoleDetails: builder.mutation({
            query: ({ projectId, ...body }) => ({
                url: `roles/${projectId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { projectId }) => {
                if (!error) {
                    return [{ type: 'RoleDetails', id: projectId }];
                }
                return [];
            },
        }),
    }),
});

export const { useGetProjectRoleDetailsQuery, useUpdateProjectRoleDetailsMutation } = projectsApi;
