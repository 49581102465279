import PropTypes from 'prop-types';
import { lighten, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { Typography } from 'components/Typography';

const DialogButton = styled(Button)(({ theme }) => ({
    boxSizing: 'border-box',
    width: theme.spacing(20.5),
    padding: theme.spacing(1.5, 1),
    textTransform: 'unset',
}));

export function ConnectionWarningDialog(props) {
    const { isOpen, onClose, onConfirm, isLoading } = props;

    return (
        <Dialog onClose={onClose} open={isOpen} aria-label="connection-warning-dialog">
            <DialogContent sx={{ textAlign: 'center', width: 400 }}>
                <Typography variant="titleMedium">
                    This resume is already attached to a profile. Are you sure you want to attach
                    this resume to the selected profile instead?
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', pb: 1.5, gap: 1 }}>
                <DialogButton aria-label="connection-warning-dialog-close" onClick={onClose}>
                    <Typography variant="titleSmall" color="textPrimary">
                        Cancel
                    </Typography>
                </DialogButton>
                <DialogButton
                    sx={(theme) => ({
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: lighten(theme.palette.primary.main, 0.1),
                        },
                    })}
                    aria-label="connection-warning-dialog-confirm"
                    onClick={onConfirm}
                >
                    <Typography variant="titleSmall" color="white">
                        {isLoading && <CircularProgress size={16} />}
                        {!isLoading && 'Confirm'}
                    </Typography>
                </DialogButton>
            </DialogActions>
        </Dialog>
    );
}

ConnectionWarningDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    isLoading: PropTypes.func,
};
