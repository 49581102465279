import PropTypes from 'prop-types';
import { useState, useCallback, useEffect, forwardRef } from 'react';

import debounce from 'lodash/debounce';
import { Typography } from 'components/Typography';
import { TextInput } from 'components/Inputs/TextInput';
import { useLazyGetAutocompleteResultsQuery } from 'services/search';
import {
    AutocompleteRoot,
    StyledAutocomplete,
} from 'views/Search/features/FiltersBar/components/styledComponents';

export const CertificationsAndSkillsAutocomplete = forwardRef((props, ref) => {
    const {
        sx,
        id,
        name,
        inlineTags,
        onChange,
        value,
        label,
        placeholder,
        error,
        TextInputProps,
        disableTags,
        isSkills,
        ...restProps
    } = props;

    const disabled = props.disabled || props.InputProps?.disabled;

    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const filterName = isSkills ? 'skill' : 'certification';

    const [getAutocompleteResults, autocompleteResults] = useLazyGetAutocompleteResultsQuery();

    const { data: getResolvedTitleData, isLoading, isFetching } = autocompleteResults;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetch = useCallback(
        debounce((title) => {
            getAutocompleteResults({
                title,
                key: isSkills ? 'normalizedSkills.name' : 'certifications',
            });
        }, 300),
        [getAutocompleteResults]
    );

    useEffect(() => {
        if (inputValue.length >= 3) {
            fetch(inputValue);
        }
    }, [fetch, inputValue]);

    useEffect(() => {
        if (!isLoading && !isFetching && getResolvedTitleData) {
            let newOptions = getResolvedTitleData?.map((result) => {
                return result?.value;
            });
            setOptions(newOptions);
        }
    }, [getResolvedTitleData, isFetching, isLoading]);

    const handleChange = (_event, values) => {
        if (values) {
            onChange(values);
            setOptions([]);
        }
    };

    const onInputChange = useCallback(
        (event, newInputValue) => {
            if (newInputValue.length === 0) {
                if (open) setOpen(false);
            } else {
                if (!open) setOpen(true);
            }
            setInputValue(newInputValue);
        },
        [open]
    );

    const getInputProps = ({ InputProps }) => {
        return {
            ...InputProps,
            ...TextInputProps?.InputProps,
            endAdornment: TextInputProps?.InputProps?.endAdornment || InputProps.endAdornment,
        };
    };

    const InputLabelProps = TextInputProps?.InputLabelProps;

    return (
        <AutocompleteRoot sx={sx}>
            <StyledAutocomplete
                ref={ref}
                multiple
                renderTags={() => null}
                disablePortal
                clearOnBlur={false}
                filterSelectedOptions
                autoHighlight
                clearOnEscape
                id={id}
                aria-label={`${filterName}-filter-autocomplete`}
                disabled={disabled}
                disableClearable={disabled}
                options={options}
                value={value}
                open={open}
                onClose={() => setOpen(false)}
                onChange={handleChange}
                isOptionEqualToValue={(option, val) => val === option}
                onInputChange={onInputChange}
                noOptionsText={
                    <Typography variant="labelLarge" {...InputLabelProps}>
                        No options
                    </Typography>
                }
                renderOption={(_props, option) => {
                    return (
                        <li {..._props}>
                            <Typography variant="titleSmall" color="textPrimary">
                                {option}
                            </Typography>
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextInput
                        {...params}
                        label={label}
                        autoComplete="off"
                        error={error}
                        placeholder={placeholder || `Enter a ${filterName}`}
                        disabled={disabled}
                        {...TextInputProps}
                        InputProps={getInputProps(params)}
                    />
                )}
                {...restProps}
            />
        </AutocompleteRoot>
    );
});

CertificationsAndSkillsAutocomplete.propTypes = {
    sx: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.node,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    className: PropTypes.string,
    inlineTags: PropTypes.number,
    disableTags: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    InputProps: PropTypes.object,
    TextInputProps: PropTypes.object,
    isSkills: PropTypes.bool,
};
