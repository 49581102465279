import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { SendMessageButton } from '../MessageContent/MessageComposeComponents/SendMessageButton';

const FooterContainer = styled(Box)(({ theme }) => ({
    height: '60px',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 5,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    paddingTop: '15px',
    paddingRight: '24px',
    paddingBottom: '15px',
    paddingLeft: '24px',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export function DrawerFooter() {
    return (
        <FooterContainer>
            <SendMessageButton />
        </FooterContainer>
    );
}
