import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';

import { MAX_SIGNATURE_CHARACTERS, signatureValidation } from 'utils/validation';
import { useGetAllSignaturesQuery, useLazyGetAllSignaturesQuery } from 'services/conversations';
import {
    setSelectedSignature,
    changeSignatureBody,
    setHasSignatureContentChange,
    setIsValidSignature,
    setIsValidMaxSignatureLength,
} from '../SignatureSlice';

export function useTemplateSignature(skipQuery = false) {
    const [signatureData, setSignatureData] = useState([]);
    const { selectedSignature, signatureBody } = useSelector((state) => state.signature);
    const dispatch = useDispatch();

    const [initialBody, setInitialBody] = useState('');
    const [validationMessage, setValidationMessage] = useState('');

    const { data, isFetching: isFetchingData } = useGetAllSignaturesQuery({
        skip: skipQuery,
    });

    const [getAllSignatures] = useLazyGetAllSignaturesQuery();

    const handleUseSignature = (signature) => {
        const { signature: content } = signature || {};
        dispatch(setSelectedSignature(signature));
        setInitialBody(content);
        const { isValid } = signatureValidation(content);
        dispatch(setIsValidSignature(isValid));
    };

    const handleRefreshSignature = useCallback(async () => {
        const response = await getAllSignatures();
        if (response?.error) {
            toast.error('Error retrieving signatures');
        }
        if (response?.isSuccess) {
            setSignatureData(response?.data);
        }
    }, [getAllSignatures]);

    const handleChangeSignature = (event) => {
        if (initialBody) {
            setInitialBody('');
        }
        const { isValid, escapedHtmlSignature } = signatureValidation(event.target.value);

        if (escapedHtmlSignature?.length > MAX_SIGNATURE_CHARACTERS) {
            setValidationMessage(
                `You have exceeded the maximum ${MAX_SIGNATURE_CHARACTERS} characters.`
            );
            dispatch(setIsValidMaxSignatureLength(true));
        } else {
            setValidationMessage('');
            dispatch(setIsValidMaxSignatureLength(false));
        }

        if (!isEqual(event.target.value, selectedSignature?.signature)) {
            dispatch(setHasSignatureContentChange(true));
        } else {
            dispatch(setHasSignatureContentChange(false));
        }
        dispatch(setIsValidSignature(isValid));
        dispatch(changeSignatureBody(event.target.value));
    };

    useEffect(() => {
        if (data) {
            setSignatureData(data);
        }
    }, [data]);

    return {
        signatureData,
        innerHtml: signatureBody,
        initialBody,
        isFetchingData,
        handleUseSignature,
        handleChangeSignature,
        validationMessage,
        handleRefreshSignature,
    };
}
