import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import { Typography } from 'components/Typography';

export function OverwriteRmmTemplateWarningDialog(props) {
    const {
        processRmmMessage,
        target,
        active
    } = props;

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (Boolean(target.id.length) && Boolean(active.id.length) && target.id !== active.id) {
            setShowModal(true);
        }
    }, [target, active, setShowModal]);

    return (
        <Dialog open={showModal} data-testid="overwrite-rmm-dialog">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 400,
                    p: [4, 4, 2, 4],
                }}
            >
                <Typography variant="titleMedium" sx={{ textAlign: 'center' }}>
                    This will overwrite any unsaved changes to your current message template.
                </Typography>
                <Box sx={{ display: 'flex', mt: 4 }}>
                    <Button
                        variant="outlined"
                        sx={{
                            display: 'flex',
                            flex: '1 0 0',
                            p: [1, 1.25],
                            mr: 0.5,
                            textTransform: 'none',
                        }}
                        onClick={() => setShowModal(false)}
                    >
                        <Typography variant="titleSmall">Go Back</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            display: 'flex',
                            flex: '1 0 0',
                            p: [1, 1.25],
                            ml: 0.5,
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            processRmmMessage(target);
                            setShowModal(false);
                        }}
                    >
                        <Typography variant="titleSmall">Continue</Typography>
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
}

OverwriteRmmTemplateWarningDialog.propTypes = {
    processRmmMessage: PropTypes.func,
    target: PropTypes.object,
    active: PropTypes.object,
};
