import { useRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { MESSAGE_TYPES, MINIMUM_EMAIL_SUBJECT_LENGTH } from 'utils/constants';
import { getBodyValidator } from 'views/Communications/features/Compose/utils';

import {
    useCreateMessageTemplateMutation,
    useUpdateMessageTemplateMutation,
    useDeleteMessageTemplateMutation,
    useCreateEmailSignatureMutation,
    useUpdateEmailSignatureMutation,
    useLazyGetAllSignaturesQuery,
} from 'services/conversations';
import {
    changeMessageChannels,
    changeTemplateBody,
    changeTemplateName,
    changeTemplateSubject,
    resetActiveTemplateState,
    setIsUpdatingTemplate,
    setMessageChannels,
    setTemplateId,
    resetTemplate as resetTemplateAction,
} from '../MessageTemplateSlice';

import {
    handleError,
    handleDeleteTemplateSuccess,
    handleSaveTemplateSuccess,
    handleResponse,
} from '../utils/handleMessageTemplateResponse';
import { getSelectedChannels } from '../utils/messageTemplateHelpers';
import { changeDefaultDynamicFields, useDynamicFields } from 'features/DynamicFields';
import { parseInvalidFields } from 'utils/validation/parseInvalidFields';
import {
    resetSignature,
    setSelectedSignature,
    changeSignatureBody,
    SIGNATURE_TYPES,
} from 'features/Signature';

export function useMessageTemplate(props) {
    const { onClose, open, setClearInput } = props || {};

    const {
        template,
        isUpdatingTemplate,
        templateId,
        templateName,
        templateSubject,
        templateBody,
        messageChannels,
        activeTemplateId,
    } = useSelector((state) => state.messageTemplate);

    const {
        selectedSignature,
        signatureBody,
        hasSignatureContentChange,
        isValidSignature,
        isValidMaxSignatureLength,
    } = useSelector((state) => state.signature);

    const dispatch = useDispatch();

    const editorTemplateBodyRef = useRef();
    const editorTemplateSubjectRef = useRef();

    const [innerText, setInnerText] = useState('');
    const [innerHtml, setInnerHtml] = useState('');
    const [innerSubjectText, setInnerSubjectText] = useState('');
    const [innerSubjectHtml, setInnerSubjectHtml] = useState('');
    const [invalidDynamicFields, setInvalidDynamicFields] = useState(false);

    const {
        defaultDynamicFields,
        handleRetrieveDefaultDynamicFields,
        validateDynamicFieldsValues,
    } = useDynamicFields();

    const [createTemplate, result] = useCreateMessageTemplateMutation();

    const { isLoading } = result;

    const [updateTemplate, updateResult] = useUpdateMessageTemplateMutation();
    const { isLoading: isFetchingUpdate } = updateResult;

    const [deleteTemplate, deleteResult] = useDeleteMessageTemplateMutation();
    const { isLoading: isFetchingDelete } = deleteResult;

    const [getAllSignatures] = useLazyGetAllSignaturesQuery();

    const [createEmailSignature, { isLoading: isCreating }] = useCreateEmailSignatureMutation();
    const [updateEmailSignature, { isLoading: isUpdating }] = useUpdateEmailSignatureMutation();

    const handleChangeBody = (event) => {
        const subjectText =
            editorTemplateSubjectRef.current?.plainText() ||
            editorTemplateSubjectRef.current?.innerHTML();
        const combinedText = `${subjectText} ${editorTemplateBodyRef.current?.plainText()}`;

        handleRetrieveDefaultDynamicFields(combinedText);
        setInnerText(event.target.plainText);
        setInnerHtml(event.target.value);
    };

    const handleChangeName = (event) => {
        dispatch(changeTemplateName(event.target.value));
    };

    const handleChangeSubject = (event) => {
        const combinedText = `${editorTemplateSubjectRef.current?.plainText()} ${editorTemplateBodyRef.current?.plainText()}`;
        handleRetrieveDefaultDynamicFields(combinedText);
        setInnerSubjectText(event.target.plainText);
        setInnerSubjectHtml(event.target.value);
    };

    const handleToggleChannel = (type) => {
        dispatch(changeMessageChannels(type));
    };

    const resetTemplate = useCallback(() => {
        setInnerText('');
        setInnerHtml('');
        setInnerSubjectText('');
        setInnerSubjectHtml('');
        dispatch(resetActiveTemplateState());
        dispatch(resetSignature());
    }, [dispatch]);

    const selectedChannels = useMemo(() => getSelectedChannels(messageChannels), [messageChannels]);

    const messageBody = selectedChannels?.[0] === MESSAGE_TYPES.EMAIL ? innerHtml : innerText;

    const handleViewTemplate = (template) => {
        const {
            templateId: id,
            name,
            subject,
            body,
            messageTypes,
            defaultDynamicFields: dynamicFields,
            signature,
        } = template || {};
        dispatch(setIsUpdatingTemplate(true));
        dispatch(setTemplateId(id));
        dispatch(changeTemplateName(name));
        dispatch(changeTemplateSubject(subject));
        dispatch(changeTemplateBody(body));
        dispatch(setMessageChannels(messageTypes));
        dispatch(changeDefaultDynamicFields(dynamicFields));
        dispatch(setSelectedSignature(signature || {}));
        dispatch(changeSignatureBody(signature?.signature || ''));
    };

    const getMessageTemplatePayload = (signatureId) => {
        const isSmsMessage = selectedChannels?.[0] === MESSAGE_TYPES.SMS;

        const message = {
            name: templateName,
            body: messageBody,
            templateType: 'user_template',
            messageTypes: selectedChannels,
            defaultDynamicFields,
        };

        if (isSmsMessage) {
            message.messageTypes = [MESSAGE_TYPES.SMS];
            message.subject = '';
            message.signatureId = '';
        } else {
            message.subject = innerSubjectHtml;
            message.signatureId = signatureId;
        }

        return message;
    };

    const getSaveType = (id, body) => {
        if (isUpdatingTemplate) {
            return updateTemplate({ templateId: id, body });
        }
        return createTemplate({ body });
    };

    const handleUpdateSignature = async () => {
        let signatureId;
        let signatureData;
        if (
            hasSignatureContentChange &&
            isValidSignature &&
            selectedChannels?.[0] !== MESSAGE_TYPES.SMS
        ) {
            const signatures = await getAllSignatures();
            if (signatures?.error) {
                toast.error('Could not process request. Please try again.');
                return { error: true };
            }
            if (signatures.data.some((signature) => signature.name === templateName)) {
                toast.error(
                    'This name is already used for another template. Please give your template a unique name.'
                );
                return { error: true };
            }
            if (
                selectedSignature?.id &&
                selectedSignature?.signatureType === SIGNATURE_TYPES.SYSTEM_CREATED
            ) {
                const response = await updateEmailSignature({
                    id: selectedSignature?.id,
                    name: selectedSignature?.name,
                    signature: signatureBody,
                    signature_type: SIGNATURE_TYPES.SYSTEM_CREATED,
                });
                if (response?.error) {
                    handleError(response);
                    return { error: true };
                }

                signatureId = response?.data?.id;
                signatureData = response?.data;
            } else {
                const response = await createEmailSignature({
                    name: templateName,
                    signature: signatureBody,
                    signature_type: SIGNATURE_TYPES.SYSTEM_CREATED,
                });
                if (response?.error) {
                    handleError(response);
                    return { error: true };
                }

                signatureId = response?.data?.id;
                signatureData = response?.data;
            }
        }

        if (selectedSignature?.id && !hasSignatureContentChange) {
            signatureId = selectedSignature.id;
        }
        const signature = signatureData || selectedSignature || {};

        return { signatureId, signatureData: signature };
    };

    const handleSaveTemplate = async (onResponse) => {
        const { signatureId, signatureData, error: signatureError } = await handleUpdateSignature();
        if (signatureError) {
            return;
        }
        const body = getMessageTemplatePayload(signatureId);
        const res = await getSaveType(templateId || template?.templateId, body);
        const onSuccess = () => {
            handleSaveTemplateSuccess(resetTemplate, onClose);
        };
        const onError = () => {
            handleError(res);
        };
        handleResponse(res?.error, onSuccess, onError);
        if (typeof onResponse === 'function') {
            onResponse(res, signatureData);
        }
    };

    const handleDeleteTemplate = async (templateId) => {
        const res = await deleteTemplate({ templateId });
        const onSuccess = () => {
            handleDeleteTemplateSuccess(
                templateId,
                template,
                dispatch,
                resetTemplateAction,
                setClearInput
            );
        };
        const onError = () => {
            handleError(res);
        };
        handleResponse(res?.error, onSuccess, onError);
    };

    const disableSaveTemplate = useMemo(
        () =>
            editorTemplateSubjectRef?.current?.plainText?.()?.trimEnd?.()?.length <=
                MINIMUM_EMAIL_SUBJECT_LENGTH ||
            selectedChannels.length === 0 ||
            templateName?.trim?.()?.length === 0 ||
            !getBodyValidator(
                selectedChannels?.[0],
                innerText?.trim?.()?.length,
                innerSubjectText?.trim?.()?.length
            ) ||
            !validateDynamicFieldsValues() ||
            isValidMaxSignatureLength,
        [
            templateName,
            selectedChannels,
            innerText,
            innerSubjectText,
            validateDynamicFieldsValues,
            isValidMaxSignatureLength,
        ]
    );

    useEffect(() => {
        const bodyRenderedHtml = editorTemplateBodyRef.current?.innerHTML();
        const subjectRenderedHtml = editorTemplateSubjectRef.current?.innerHTML();
        setInvalidDynamicFields(
            parseInvalidFields(bodyRenderedHtml, subjectRenderedHtml, selectedChannels?.[0])
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerHtml, innerSubjectHtml]);

    useEffect(() => {
        if (!open) {
            resetTemplate();
        }
    }, [template, open, resetTemplate]);

    return {
        isFetching: isFetchingUpdate || isLoading || isFetchingDelete || isCreating || isUpdating,
        innerText,
        innerHtml,
        innerSubjectText,
        innerSubjectHtml,
        initialBody: templateBody,
        initialSubject: templateSubject,
        activeTemplateId,
        templateId,
        isUpdatingTemplate,
        templateName,
        messageChannels,
        selectedChannels,
        disableSaveTemplate,
        handleChangeBody,
        handleChangeName,
        handleChangeSubject,
        handleToggleChannel,
        handleSaveTemplate,
        handleViewTemplate,
        handleDeleteTemplate,
        invalidDynamicFields,
        editorTemplateBodyRef,
        editorTemplateSubjectRef,
    };
}
