import { toast } from 'react-toastify';
import { useRef, useCallback, useEffect } from 'react';

import { useDetectInternetConnectionStatus } from 'hooks/useDetectInternetConnectionStatus';

export function OfflineNotification() {
    const toastId = useRef(null);
    const isOnline = useDetectInternetConnectionStatus();

    const showNotification = useCallback(() => {
        toastId.current = toast.error('Internet connection is unavaliable', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
        });
    }, []);

    const dismissNotification = useCallback(() => {
        toast.dismiss(toastId.current);
        toastId.current = null;
    }, []);

    useEffect(() => {
        if (!isOnline) {
            showNotification();
        } else {
            toastId.current && dismissNotification();
        }
    }, [isOnline, showNotification, dismissNotification]);

    return null;
}
