import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { prepareAuthHeaders } from 'services/utils';

const baseUrl = '/api/document-generator/v1';

// using RTK-Query is not recommended for downloading files: https://github.com/reduxjs/redux-toolkit/issues/1522
const downloadTalentDocument = async (talent, docType) => {
    try {
        const headers = await prepareAuthHeaders(new Headers());
        const response = await fetch(`${baseUrl}/talent/${talent.profileId}?type=${docType}`, {
            headers,
            responseType: 'blob',
        });

        if (!response.ok) {
            let errorMessage = 'Error downloading talent document';
            const _errorMessage = await response.text();
            if (_errorMessage) {
                errorMessage = _errorMessage;
            }

            return { isError: true, error: { status: response.status, message: errorMessage } };
        }

        const blob = await response.blob();

        // Create a url for the blob.
        const url = window.URL.createObjectURL(blob);

        // Get the filename from the Content-Disposition header.
        const contentDisposition = response.headers.get('content-disposition');
        const match = contentDisposition && contentDisposition.match(/filename="(.+)"/);
        const filename = match ? match[1] : `${talent.name}.${docType}`;

        // Create a link element.
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        // link.target = '_blank';

        // Append the link to the body and click it to trigger the download.
        document.body.appendChild(link);
        link.click();

        // Remove the link element from the body.
        document.body.removeChild(link);

        // Release the URL object to free up resources.
        window.URL.revokeObjectURL(url);

        return { isError: false, isSuccess: true };
    } catch (error) {
        return { isError: true, error };
    }
};

export const useDownloadTalentDocument = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleRequest = async (...args) => {
        setIsLoading(true);
        const { isError } = await downloadTalentDocument(...args);
        if (isError) {
            toast.error(
                'There was an error downloading your document. Please try again in a few minutes.'
            );
        }
        setIsLoading(false);
    };

    return useMemo(() => {
        return [handleRequest, { isLoading }];
    }, [isLoading]);
};
