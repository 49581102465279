import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import MuiDivider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import { differenceInDays, parseISO } from 'date-fns';

import { EmailsTab } from './EmailsTab';
import { PhonesTab } from './PhonesTab';
import { SocialsTab } from './SocialsTab';
import { Typography } from 'components/Typography';
import {
    MatchIcon,
    CloseIcon,
    LocationIcon,
    ArrowRefreshIcon,
    CheckCircleIcon,
} from 'components/Icons';
import { getTalentName, getTalentLocationString } from 'utils/formatters/talentFormatting';
import { useGetContactMetadataQuery, useGetTalentLookupsQuery } from 'services/contacts';
import { formatPhoneNumber } from 'utils/formatters';
import { StarOutlined } from '@mui/icons-material';
import { useContactDetails } from '../hooks/useContactDetails';
import { CONTACT_MEDIUM } from '../utils/constants';
import CoolDownTooltip from '../CoolDownTooltip';
import { resetModalData } from './contactDetailSlice';
import isEmpty from 'lodash/isEmpty';
import { LookupButton } from './LookupButton';
import { LookupTab } from './LookupTab';
import { pluralize } from 'utils/copy';
import { CREDITS } from 'utils/constants';
import { useCreditUsage } from 'hooks/useCreditUsage';
import { StyledCreditLink } from 'features/CreditUsage/CreditUsage';

const unavailableSrc = '/assets/logos/Nebula-color-stacked.png';

function contactsTabsA11yProps(index) {
    return {
        id: `contacts-tab-${index}`,
        'aria-controls': `contacts-tabpanel-${index}`,
    };
}

const TalentAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: alpha(theme.palette.text.primary, 0.12),
}));

const FallbackImg = styled('img')({
    width: '75%',
});

const Divider = styled(MuiDivider)({
    borderBottomWidth: 2,
    marginTop: -2,
});

const Bold = styled(Box)(() => ({
    fontWeight: 'bold',
}));

const Tab = styled((props) => <MuiTab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    '&.Mui-selected': {
        fontWeight: 500,
        backgroundSize: '100%',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundColor: theme.palette.secondary.main,
        backgroundImage: theme.palette.common.matchInputGradient,
    },
}));

const TabPanelBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2, 2.5),
    minHeight: theme.spacing(12),
    maxHeight: theme.spacing(42),
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
        minWidth: theme.spacing(63),
    },
}));

function TabPanel(props) {
    const { children, value, index, ...restProps } = props;
    return (
        <TabPanelBox
            role="tabpanel"
            hidden={value !== index}
            id={contactsTabsA11yProps(index)['aria-controls']}
            aria-labelledby={contactsTabsA11yProps(index).id}
            {...restProps}
        >
            {value === index && children}
        </TabPanelBox>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function processLastLookup(talentLookups, isOutdatedFn, isFirstLookupFn, elapsedDaysFn) {
    if (talentLookups.totalResults) {
        const startDateStr = talentLookups.results[0].created;
        const start = parseISO(startDateStr + 'Z');
        const end = new Date();

        const difference = differenceInDays(end, start);

        elapsedDaysFn(difference);
        isOutdatedFn(difference >= 30);
        isFirstLookupFn(false);
    } else {
        isOutdatedFn(false);
        isFirstLookupFn(true);
    }
}

export function ContactDetailsModal() {
    const {
        isModalOpen: isOpen,
        selectedTalent: talent,
        initialTab,
        isProjectOutreachContactLookup,
    } = useSelector((state) => state.contactDetails);

    const talentId = talent?.id || talent?.talentId;
    const { data } = useGetContactMetadataQuery([talentId], { skip: !talentId });

    const {
        emails: emailsCount,
        phones: phonesCount,
        socials: socialsCount,
    } = data?.contactCounts?.[talentId] || {};

    const talentLookupArgs = useMemo(
        () => ({
            talentId,
            params: {
                contactMedium: 'PHONE,EMAIL,SOCIAL',
            },
        }),
        [talentId]
    );

    const { data: talentLookups, refetch } = useGetTalentLookupsQuery(talentLookupArgs, {
        skip: !talentId,
        refetchOnMountOrArgChange: true,
    });

    const { isUnlimited, usedCredits, totalCredits, isBelowThreshold } = useCreditUsage(
        CREDITS.LOOKUP
    );
    const [staleContactDetails, setStaleContactDetails] = useState(null);
    const isFirstMount = useRef(true);

    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState(initialTab);
    const [elapsedDays, setElapsedDays] = useState(0);
    const [isFirstLookup, setIsFirstLookup] = useState(false);
    const [isLookupOutdated, setIsLookupOutdated] = useState(false);

    const byeBias = useSelector((state) => state.byeBias.on) || talent?.isAnonymized;

    const talentName = getTalentName(talent, byeBias);

    const { smsPolicyViolations } = talent || {};

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleResetData = useCallback(() => {
        dispatch(resetModalData());
    }, [dispatch]);

    const {
        categoriesData,
        contactDetails,
        handleLookup,
        handlePrimaryContactToggle,
        handleCreateCategory,
        handleCreateCustomContact,
        handleFlagContact,
        loadingState,
        primaryContactPhoneID,
        primaryContactEmailID,
        emailContacts,
        phoneContacts,
        userContactSettings,
        cancel,
    } = useContactDetails({ talent, isOpen, isProjectOutreachContactLookup });

    const isLookupSuccessful = loadingState?.isLookupSuccessful;

    const showRefresh = isLookupOutdated && !isFirstLookup;

    const isUpToDate = !isFirstLookup && !isLookupOutdated;

    const showLookupButton = !isLookupSuccessful && (isLookupOutdated || isFirstLookup);

    useEffect(() => {
        if (talentLookups) {
            processLastLookup(talentLookups, setIsLookupOutdated, setIsFirstLookup, setElapsedDays);
        }
    }, [talentLookups]);

    const handleClose = useCallback(() => {
        isFirstMount.current = true;
        dispatch(resetModalData());
    }, [dispatch]);

    useEffect(() => {
        const lookupRequired =
            contactDetails?.emails?.requiresLookup ||
            contactDetails?.phones?.requiresLookup ||
            contactDetails?.socials?.requiresLookup;

        // this sets the data that is used to find new contacts after lookup
        if (isFirstMount.current && !isEmpty(contactDetails) && (lookupRequired || showRefresh)) {
            setStaleContactDetails(contactDetails);
            isFirstMount.current = false;
        }

        // this prevents displaying the new contact label when lookup is not required
        if (isFirstMount.current && !isEmpty(contactDetails) && (!lookupRequired || !showRefresh)) {
            setStaleContactDetails(contactDetails);
            isFirstMount.current = false;
        }
    }, [contactDetails, isOpen, isFirstMount, dispatch, showRefresh]);

    useEffect(() => {
        setCurrentTab(initialTab);
    }, [initialTab]);

    useEffect(() => {
        return () => {
            handleResetData();
        };
    }, [handleResetData]);

    useEffect(() => {
        if (isOpen) {
            cancel?.();
            refetch();
        }
    }, [cancel, isOpen, refetch]);

    const primaryPhone =
        phoneContacts?.find?.(({ contactId }) => contactId === userContactSettings?.primary?.PHONE)
            ?.phone || '';

    return (
        <Dialog
            open={isOpen}
            maxWidth="md"
            aria-labelledby="contact-details-modal"
            onClose={(event, reason) => {
                if (
                    event.key === 'Escape' ||
                    (event.type === 'click' && reason === 'backdropClick')
                ) {
                    handleClose();
                }
            }}
        >
            <Box sx={{ px: 2.5, pt: 2, pb: 1 }}>
                <Box id="contact-details-modal" sx={{ display: 'flex' }}>
                    <Typography variant="labelSmall" sx={{ mr: 'auto' }}>
                        Contact Information
                    </Typography>
                    <IconButton
                        color="primary"
                        onClick={handleClose}
                        aria-label="close-contact-details-modal"
                        data-trackid="lookup-modal-close"
                        sx={{ p: 0, mt: -0.75, mr: -1.5 }}
                        disableRipple
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'end', pt: 1 }}>
                        <CoolDownTooltip
                            message={smsPolicyViolations?.policyViolationReason}
                            showTooltip={
                                smsPolicyViolations?.somePolicyViolated ||
                                smsPolicyViolations?.allPolicyViolated
                            }
                            placement="left"
                        >
                            <TalentAvatar src={byeBias ? null : talent?.imageUrl}>
                                <FallbackImg src={unavailableSrc} alt="nova-avatar" />
                            </TalentAvatar>
                        </CoolDownTooltip>
                        <Box sx={{ pl: 1.5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="smallHeadline" noWrap title={talentName}>
                                    {talentName}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', pb: 0.25 }}>
                                <LocationIcon
                                    sx={{
                                        fontSize: 18,
                                        ml: -0.25,
                                        pr: 0.25,
                                        opacity: 0.32,
                                        color: 'common.charcoal',
                                    }}
                                />
                                <Typography variant="body" noWrap>
                                    {getTalentLocationString(talent)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            pt: 3,
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}
                    >
                        {primaryContactEmailID && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                    gap: 1,
                                }}
                            >
                                <Typography variant="bodySmallBold" fontWeight="bold">
                                    {emailContacts?.find?.(
                                        ({ contactId }) =>
                                            contactId === userContactSettings?.primary?.EMAIL
                                    )?.email || ''}
                                </Typography>
                                <StarOutlined
                                    color="primary"
                                    fontSize="small"
                                    sx={{ cursor: 'pointer' }}
                                    aria-label="Primary Email Contact"
                                    onClick={() =>
                                        handlePrimaryContactToggle(
                                            primaryContactEmailID,
                                            CONTACT_MEDIUM.EMAIL
                                        )
                                    }
                                />
                            </Box>
                        )}
                        {primaryContactPhoneID && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                    gap: 1,
                                }}
                            >
                                <Typography variant="bodySmall" noWrap>
                                    {formatPhoneNumber(primaryPhone) || primaryPhone}
                                </Typography>
                                <StarOutlined
                                    color="primary"
                                    fontSize="small"
                                    sx={{ cursor: 'pointer' }}
                                    aria-label="Primary Phone Contact"
                                    onClick={() =>
                                        handlePrimaryContactToggle(
                                            primaryContactPhoneID,
                                            CONTACT_MEDIUM.PHONE
                                        )
                                    }
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            {(isLookupSuccessful || isUpToDate) && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        height: '36px',
                        gap: '8px',
                        paddingX: '24px',
                        background: (theme) => alpha(theme.palette.success.main, 0.12),
                    }}
                >
                    <CheckCircleIcon color="success" sx={{ mr: 1, fontSize: '20px' }} />
                    <Typography variant="bodyBaseBold" sx={{ maxWidth: '320px' }}>
                        Candidate lookup complete.
                    </Typography>
                </Box>
            )}
            {showLookupButton && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: showRefresh ? 'space-between' : 'center',
                        height: '86px',
                        paddingX: '24px',
                        background:
                            'linear-gradient(90.28deg, rgba(80, 76, 224, 0.12) 0%, rgba(49, 141, 222, 0.12) 100%)',
                    }}
                >
                    {showRefresh && (
                        <Typography variant="titleSmall500" sx={{ maxWidth: '320px' }}>
                            It's been {elapsedDays} {pluralize('day', elapsedDays)} since you last
                            looked up this candidate's contact information.
                        </Typography>
                    )}
                    <LookupTab>
                        <LookupButton
                            id="look-up-contacts"
                            aria-label="look-up-contacts"
                            onClick={handleLookup}
                            Icon={showRefresh ? ArrowRefreshIcon : MatchIcon}
                            label={
                                showRefresh
                                    ? 'Look for new contact information'
                                    : 'Look up all available contact information'
                            }
                            disabled={loadingState?.isLookupLoading}
                            isLoading={loadingState?.isLookupLoading}
                        />
                        <Typography
                            sx={{
                                mt: 1,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 12,
                                lineHeight: '14px',
                                letterSpacing: 0.1,
                            }}
                        >
                            {isUnlimited ? (
                                <>
                                    <Bold component="span">Unlimited</Bold> Lookup Credits
                                </>
                            ) : (
                                <>
                                    <Bold component="span">{usedCredits}</Bold>/{totalCredits}{' '}
                                    <StyledCreditLink
                                        aria-label="go-to-settings"
                                        to="/user-settings"
                                        target="_blank"
                                        belowThreshold={isBelowThreshold}
                                    >
                                        Lookup Credits
                                    </StyledCreditLink>
                                </>
                            )}
                        </Typography>
                    </LookupTab>
                </Box>
            )}
            <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                variant="fullWidth"
                aria-label="contacts-tabs"
            >
                <Tab
                    data-trackid="lookup-modal-email-tab"
                    label={isNil(emailsCount) ? 'Email' : `Email (${emailsCount})`}
                    {...contactsTabsA11yProps(0)}
                />
                <Tab
                    data-trackid="lookup-modal-sms-tab"
                    label={
                        isNil(phonesCount) ? 'Contact Numbers' : `Contact Numbers (${phonesCount})`
                    }
                    {...contactsTabsA11yProps(1)}
                />
                <Tab
                    data-trackid="lookup-modal-social-tab"
                    label={isNil(socialsCount) ? 'Social' : `Social (${socialsCount})`}
                    {...contactsTabsA11yProps(2)}
                />
            </Tabs>
            <Divider />
            <TabPanel value={currentTab} index={0}>
                <EmailsTab
                    loadingState={loadingState}
                    talentName={talentName}
                    talentId={talentId}
                    emails={emailContacts}
                    onLookup={handleLookup}
                    onPrimaryContactChange={handlePrimaryContactToggle}
                    hasLookup={!!contactDetails && !contactDetails?.emails?.requiresLookup}
                    categories={categoriesData?.categories}
                    onCreateCategory={handleCreateCategory}
                    onCreateCustomContact={handleCreateCustomContact}
                    savedCategories={userContactSettings?.categories}
                    handleFlagContact={handleFlagContact}
                    staleEmailContacts={staleContactDetails?.emails?.data}
                />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <PhonesTab
                    loadingState={loadingState}
                    talentName={talentName}
                    talentId={talentId}
                    phones={phoneContacts}
                    onLookup={handleLookup}
                    onPrimaryContactChange={handlePrimaryContactToggle}
                    handleFlagContact={handleFlagContact}
                    hasLookup={!!contactDetails && !contactDetails?.phones?.requiresLookup}
                    categories={categoriesData?.categories}
                    onCreateCategory={handleCreateCategory}
                    onCreateCustomContact={handleCreateCustomContact}
                    savedCategories={userContactSettings?.categories}
                    stalePhoneContacts={staleContactDetails?.phones?.data}
                />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <SocialsTab
                    loadingState={loadingState}
                    talentName={talentName}
                    socials={contactDetails?.socials?.data}
                    onLookup={handleLookup}
                    onCreateCustomContact={handleCreateCustomContact}
                    hasLookup={!!contactDetails && !contactDetails?.socials?.requiresLookup}
                    handleFlagContact={handleFlagContact}
                    staleSocialContacts={staleContactDetails?.socials?.data}
                />
            </TabPanel>
        </Dialog>
    );
}
