import { useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import { ActivityCard } from 'features/Activity';
import { Typography } from 'components/Typography';
import { Pagination } from 'components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from 'features/Activity/activitySlice';
import { useGetActivityQuery } from 'services/activity';
import { ENTITY_TYPE_ENUMS } from 'utils/entityEnums';

const ActivityContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(7.5),
    overflow: 'auto',
}));

const PaginationBox = styled(Box)(({ theme }) => ({
    height: '60px',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 5,
    boxShadow: '0px -2px 7px rgba(50, 50, 50, 0.17)',
    boxSizing: 'border-box',
    paddingTop: '15px',
    paddingRight: '15px',
    paddingBottom: '15px',
    paddingLeft: '25px',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export function ProfileActivity(props) {
    const {
        talentData,
        shouldFetchActivity,
        activities: fetchedActivities,
        totalResults: fetchedActivitiesTotalResults,
        isLoading: activitiesLoading,
        onActivitiesLoaded,
    } = props;

    const currentTalentId = talentData?.id || talentData?.talentId;

    const { activityParams } = useSelector((state) => state.activity);
    const { page, pageSize } = activityParams;

    const { data: activityData, isLoading: currentActivitiesLoading } = useGetActivityQuery(
        {
            params: { ...activityParams, talentIds: [currentTalentId] },
        },
        {
            skip: !currentTalentId || !shouldFetchActivity,
        }
    );

    const activities = useMemo(
        () => (shouldFetchActivity ? activityData?.results || [] : fetchedActivities),
        [activityData, fetchedActivities, shouldFetchActivity]
    );

    const isLoading = currentActivitiesLoading || activitiesLoading;
    const totalResults = shouldFetchActivity
        ? activities?.totalResults || 0
        : fetchedActivitiesTotalResults;

    useEffect(() => {
        onActivitiesLoaded?.(activityData || {});
    }, [activityData, onActivitiesLoaded]);

    const dispatch = useDispatch();

    const handleChangePage = (targetPage) => {
        dispatch(setPage(targetPage));
    };

    return (
        <ActivityContainer>
            {isLoading && (
                <Box
                    aria-label="attachment-loading-progress"
                    sx={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={50} />
                </Box>
            )}

            {!isLoading && !activities.length && (
                <Box
                    sx={{
                        display: 'flex',
                        height: '80%',
                        minHeight: '250px',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="bodySmall"
                        color="text.primary"
                        sx={{ opacity: 0.48, alignSelf: 'center' }}
                    >
                        No profile activity
                    </Typography>
                </Box>
            )}
            {!isLoading &&
                activities &&
                activities.map((activity, index) => (
                    <Fragment key={activity.activityId}>
                        <ActivityCard
                            actor={activity.performedBy}
                            createdDate={activity.created}
                            entityConnections={activity.entityConnections}
                            activityType={activity.activityType}
                            activitySubType={activity.activitySubType}
                            metadata={activity.metadata}
                            talentName={talentData?.name || ''}
                            ignoredFields={[ENTITY_TYPE_ENUMS.TALENT.label]}
                        />
                        {index !== activities.length - 1 && <Divider sx={{ my: 1 }} />}
                    </Fragment>
                ))}
            {Boolean(activities.length) && (
                <PaginationBox>
                    <Pagination
                        page={page}
                        pageLength={pageSize}
                        onChangePage={handleChangePage}
                        total={totalResults}
                        showTotal
                    />
                </PaginationBox>
            )}
        </ActivityContainer>
    );
}

ProfileActivity.propTypes = {
    talentData: PropTypes.object,
    activities: PropTypes.array,
    totalResults: PropTypes.number,
    isLoading: PropTypes.bool,
    shouldFetchActivity: PropTypes.bool,
    onActivitiesLoaded: PropTypes.func,
};
