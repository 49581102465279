import SvgIcon from '@mui/material/SvgIcon';

export function CheckCircleOutlineIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4ZM12 5C11.0807 5 10.1705 5.18106 9.32122 5.53284C8.47194 5.88463 7.70026 6.40024 7.05025 7.05025C6.40024 7.70026 5.88463 8.47194 5.53284 9.32122C5.18106 10.1705 5 11.0807 5 12C5 12.9193 5.18106 13.8295 5.53284 14.6788C5.88463 15.5281 6.40024 16.2997 7.05025 16.9497C7.70026 17.5998 8.47194 18.1154 9.32122 18.4672C10.1705 18.8189 11.0807 19 12 19C13.8565 19 15.637 18.2625 16.9497 16.9497C18.2625 15.637 19 13.8565 19 12C19 10.1435 18.2625 8.36301 16.9497 7.05025C15.637 5.7375 13.8565 5 12 5ZM15.358 9.646C15.4408 9.72873 15.492 9.8379 15.5026 9.95449C15.5132 10.0711 15.4826 10.1877 15.416 10.284L15.358 10.354L11.354 14.358C11.2713 14.4408 11.1621 14.492 11.0455 14.5026C10.9289 14.5132 10.8123 14.4826 10.716 14.416L10.646 14.358L8.646 12.358C8.55742 12.2698 8.50508 12.1516 8.4993 12.0268C8.49352 11.9019 8.53472 11.7794 8.61478 11.6834C8.69483 11.5874 8.80794 11.5249 8.93182 11.5082C9.05569 11.4914 9.18134 11.5217 9.284 11.593L9.354 11.651L11 13.298L14.651 9.646C14.7448 9.55226 14.8719 9.49961 15.0045 9.49961C15.1371 9.49961 15.2642 9.55226 15.358 9.646Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
