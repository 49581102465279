import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AvatarGroup from '@mui/material/AvatarGroup';
import { styled, alpha } from '@mui/material/styles';

import { TalentPill } from './TalentPill';
import { useDialog } from 'components/Dialog';
import { getInitials } from 'utils/formatters';
import { Typography } from 'components/Typography';
import { ContactsDialog } from 'views/Communications/features/Contacts';

const NovaAvatars = styled(AvatarGroup, { shouldForwardProp: (prop) => prop !== 'isExpandable' })(
    ({ theme, isExpandable }) => ({
        '& .MuiAvatarGroup-avatar': {
            fontFamily: theme.typography.fontFamily,
            fontSize: '14px',
            color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.background.default, 0.94),
            width: theme.spacing(3),
            height: theme.spacing(3),
            cursor: isExpandable ? 'pointer' : 'inherit',
        },
    })
);

const unavailableSrc = '/assets/favicons/apple-icon-precomposed.png';

const ITEMS_PER_ROW = 4;

export const MessageComposeHeader = memo((props) => {
    const { messageType, profiles } = props;

    const location = useLocation();
    const { isSupportProfile } = location?.state || {};

    const [expanded, setExpanded] = useState(false);
    const [fullProfiles, setFullProfiles] = useState([]);
    const [avatarProfiles, setAvatarProfiles] = useState([]);

    const byeBias = useSelector((state) => state.byeBias.on);
    const replyTo = useSelector((state) => state.user.email);

    const { isOpen, handleOpen, handleClose } = useDialog();

    const numberOfProfiles = profiles?.length;

    useEffect(() => {
        if (numberOfProfiles > ITEMS_PER_ROW && !expanded) {
            setFullProfiles(profiles.slice(0, ITEMS_PER_ROW));
            setAvatarProfiles(profiles.slice(ITEMS_PER_ROW));
        } else {
            setAvatarProfiles([]);
            setFullProfiles(profiles);
        }
    }, [profiles, expanded, numberOfProfiles]);

    const handleExpand = () => {
        if (numberOfProfiles > ITEMS_PER_ROW) {
            setExpanded(true);
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}
            >
                <ContactsDialog open={isOpen} onClose={handleClose} messageType={messageType} />
                <Box sx={{ display: 'flex', alignItems: 'start', mb: 2 }}>
                    <Typography variant="labelSmall" sx={{ display: 'inline-flex', pr: 2 }}>
                        TO
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: expanded ? 'wrap' : 'inherit',
                            alignItems: 'center',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            px: 1.25,
                            py: 0.5,
                            borderRadius: 1,
                            width: '100%',
                            maxHeight: '80px',
                            overflowY: expanded ? 'scroll' : 'inherit',
                        }}
                    >
                        {fullProfiles?.map(({ name, talentId, imageUrl }) => (
                            <TalentPill
                                key={talentId}
                                talentId={talentId}
                                talentName={byeBias ? getInitials(name) : name}
                                imageUrl={byeBias ? unavailableSrc : imageUrl}
                                isSupportProfile={isSupportProfile}
                                onOpenContacts={handleOpen}
                            />
                        ))}
                        {Boolean(avatarProfiles?.length) && (
                            <NovaAvatars
                                max={ITEMS_PER_ROW}
                                isExpandable={numberOfProfiles > ITEMS_PER_ROW}
                                onClick={handleExpand}
                                title={numberOfProfiles > ITEMS_PER_ROW && 'Expand'}
                                aria-label="nova-talent-avatars"
                            >
                                {avatarProfiles?.map(({ name, talentId, imageUrl }) => (
                                    <TalentPill
                                        key={talentId}
                                        talentId={talentId}
                                        talentName={name}
                                        imageUrl={imageUrl}
                                        isSupportProfile={isSupportProfile}
                                        onOpenContacts={handleOpen}
                                        hideName
                                    />
                                ))}
                            </NovaAvatars>
                        )}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="labelSmall" sx={{ display: 'inline-flex', pr: 2 }}>
                        FROM
                    </Typography>
                    <Typography variant="bodyBase" sx={{ display: 'inline-flex' }}>
                        {replyTo || ''}
                    </Typography>
                </Box>
            </Box>
        </>
    );
});

MessageComposeHeader.propTypes = {
    messageType: PropTypes.string,
    profiles: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            talentId: PropTypes.string,
            imageUrl: PropTypes.string,
        })
    ),
};
