import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from 'components/Typography';
import { alpha, styled } from '@mui/material/styles';

import { Slider } from 'components/Slider';
import { DarkSwitch } from 'components/Switch';
import { FILTER_RADII, LOCATION_FILTER_UNIT } from './constants';

const RadiusSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-rail': {
        backgroundColor: alpha(theme.palette.common.charcoal, 0.76),
    },
    '& .MuiSlider-track': {
        background: alpha(theme.palette.common.white, 0.12),
    },
    '& .MuiSlider-thumb': {
        background: theme.palette.primary.main,
    },
    '& .MuiSlider-mark': {
        opacity: 0.5,
    },
    '& .MuiSlider-markLabel': {
        color: theme.palette.common.lightGray,
        textTransform: 'uppercase',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: 0.5,
    },
}));

export function LocationRadiusSlider(props) {
    const {
        locationRadius,
        onChange,
        onChangeCommitted = () => {},
        locationUnit,
        setLocationUnit,
        markValueMapping,
    } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'space-between',
            }}
        >
            <Typography
                variant="body"
                sx={{
                    alignSelf: 'center',
                    color: (theme) => alpha(theme.palette.common.white, 0.72),
                }}
            >
                Choose a search radius
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    pl: 2.5,
                    pr: 1,
                }}
            >
                <Box sx={{ flex: 1, ml: 1 }}>
                    <RadiusSlider
                        onChangeCommitted={onChangeCommitted}
                        onChange={(event, radius) => {
                            onChange(FILTER_RADII[radius]);
                        }}
                        aria-label="location-radius-slider"
                        data-trackid="location-filter-radial-slider"
                        valueLabelDisplay="on"
                        valueLabelFormat={(label) => {
                            if (label !== 0 && label !== 1000) {
                                return `${label} `;
                            }
                            return '';
                        }}
                        value={markValueMapping.indexOf(locationRadius)}
                        scale={(value) => markValueMapping[value]}
                        marks={FILTER_RADII.map((radius, index) => {
                            if (radius === 1000) {
                                return {
                                    value: index,
                                    label: `${radius} ${locationUnit}`,
                                };
                            } else if (radius === 0) {
                                return {
                                    value: index,
                                    label: 'Exact',
                                };
                            }
                            return {
                                value: index,
                            };
                        })}
                        min={0}
                        max={6}
                    />
                </Box>
                <Box sx={{ display: 'flex', ml: 1.5 }}>
                    <Typography
                        variant="labelXSmall"
                        color="common.white"
                        alignSelf="flex-end"
                        pb={0.5}
                    >
                        KM
                    </Typography>
                    <DarkSwitch
                        sx={{
                            mt: '2px',
                            '& .MuiSwitch-thumb': {
                                backgroundColor: 'primary.main',
                                '&::before': {
                                    opacity: 1,
                                    backgroundColor: 'primary.main',
                                },
                            },
                        }}
                        aria-label="filter-location-unit-switch"
                        inputProps={{
                            'data-trackid': 'location-filter-km-mi-toggle',
                        }}
                        size="small"
                        checked={locationUnit === LOCATION_FILTER_UNIT.MI}
                        onChange={(e) => {
                            setLocationUnit(
                                e.target.checked ? LOCATION_FILTER_UNIT.MI : LOCATION_FILTER_UNIT.KM
                            );
                        }}
                    />
                    <Typography
                        variant="labelXSmall"
                        alignSelf="flex-end"
                        color="common.white"
                        pb={0.5}
                    >
                        MI
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
LocationRadiusSlider.propTypes = {
    locationRadius: PropTypes.number,
    locationUnit: PropTypes.string,
    setLocationUnit: PropTypes.func,
    markValueMapping: PropTypes.array,
    onChange: PropTypes.func,
    onChangeCommitted: PropTypes.func,
};
