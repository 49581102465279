export const occupationData = [
    {
        name: 'Architecture and Engineering Occupations',
        code: '17-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Architects, Surveyors, and Cartographers',
        code: '17-1000',
        parentCode: '17-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Architects, Except Naval',
        code: '17-1010',
        parentCode: '17-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Surveyors, Cartographers, and Photogrammetrists',
        code: '17-1020',
        parentCode: '17-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Drafters, Engineering Technicians, and Mapping Technicians',
        code: '17-3000',
        parentCode: '17-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Drafters',
        code: '17-3010',
        parentCode: '17-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Engineering Technologists and Technicians, Except Drafters',
        code: '17-3020',
        parentCode: '17-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Surveying and Mapping Technicians',
        code: '17-3030',
        parentCode: '17-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Engineers',
        code: '17-2000',
        parentCode: '17-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Aerospace Engineers',
        code: '17-2010',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Agricultural Engineers',
        code: '17-2020',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Bioengineers and Biomedical Engineers',
        code: '17-2030',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Chemical Engineers',
        code: '17-2040',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Civil Engineers',
        code: '17-2050',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Computer Hardware Engineers',
        code: '17-2060',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Electrical and Electronics Engineers',
        code: '17-2070',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Environmental Engineers',
        code: '17-2080',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Industrial Engineers, Including Health and Safety',
        code: '17-2110',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Marine Engineers and Naval Architects',
        code: '17-2120',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Materials Engineers',
        code: '17-2130',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Mechanical Engineers',
        code: '17-2140',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Mining and Geological Engineers, Including Mining Safety Engineers',
        code: '17-2150',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Engineers',
        code: '17-2190',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Nuclear Engineers',
        code: '17-2160',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Petroleum Engineers',
        code: '17-2170',
        parentCode: '17-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Arts, Design, Entertainment, Sports, and Media Occupations',
        code: '27-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Art and Design Workers',
        code: '27-1000',
        parentCode: '27-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Artists and Related Workers',
        code: '27-1010',
        parentCode: '27-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Designers',
        code: '27-1020',
        parentCode: '27-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Entertainers and Performers, Sports and Related Workers',
        code: '27-2000',
        parentCode: '27-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Actors, Producers, and Directors',
        code: '27-2010',
        parentCode: '27-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Athletes, Coaches, Umpires, and Related Workers',
        code: '27-2020',
        parentCode: '27-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Dancers and Choreographers',
        code: '27-2030',
        parentCode: '27-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Entertainers and Performers, Sports and Related Workers',
        code: '27-2090',
        parentCode: '27-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Musicians, Singers, and Related Workers',
        code: '27-2040',
        parentCode: '27-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Media and Communication Equipment Workers',
        code: '27-4000',
        parentCode: '27-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Broadcast, Sound, and Lighting Technicians',
        code: '27-4010',
        parentCode: '27-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Media and Communication Equipment Workers',
        code: '27-4090',
        parentCode: '27-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Photographers',
        code: '27-4020',
        parentCode: '27-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Television, Video, and Film Camera Operators and Editors',
        code: '27-4030',
        parentCode: '27-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Media and Communication Workers',
        code: '27-3000',
        parentCode: '27-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Broadcast Announcers and Radio Disc Jockeys',
        code: '27-3010',
        parentCode: '27-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Media and Communication Workers',
        code: '27-3090',
        parentCode: '27-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'News Analysts, Reporters and Journalists',
        code: '27-3020',
        parentCode: '27-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Public Relations Specialists',
        code: '27-3030',
        parentCode: '27-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Writers and Editors',
        code: '27-3040',
        parentCode: '27-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Building and Grounds Cleaning and Maintenance Occupations',
        code: '37-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Building Cleaning and Pest Control Workers',
        code: '37-2000',
        parentCode: '37-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Building Cleaning Workers',
        code: '37-2010',
        parentCode: '37-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Pest Control Workers',
        code: '37-2020',
        parentCode: '37-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Grounds Maintenance Workers',
        code: '37-3000',
        parentCode: '37-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Grounds Maintenance Workers',
        code: '37-3010',
        parentCode: '37-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Building and Grounds Cleaning and Maintenance Workers',
        code: '37-1000',
        parentCode: '37-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Building and Grounds Cleaning and Maintenance Workers',
        code: '37-1010',
        parentCode: '37-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Business and Financial Operations Occupations',
        code: '13-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Business Operations Specialists',
        code: '13-1000',
        parentCode: '13-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Agents and Business Managers of Artists, Performers, and Athletes',
        code: '13-1010',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Buyers and Purchasing Agents',
        code: '13-1020',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Claims Adjusters, Appraisers, Examiners, and Investigators',
        code: '13-1030',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Compensation, Benefits, and Job Analysis Specialists',
        code: '13-1140',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Compliance Officers',
        code: '13-1040',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Cost Estimators',
        code: '13-1050',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Fundraisers',
        code: '13-1130',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Human Resources Workers',
        code: '13-1070',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Logisticians and Project Management Specialists',
        code: '13-1080',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Management Analysts',
        code: '13-1110',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Market Research Analysts and Marketing Specialists',
        code: '13-1160',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Meeting, Convention, and Event Planners',
        code: '13-1120',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Business Operations Specialists',
        code: '13-1190',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Training and Development Specialists',
        code: '13-1150',
        parentCode: '13-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Financial Specialists',
        code: '13-2000',
        parentCode: '13-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Accountants and Auditors',
        code: '13-2010',
        parentCode: '13-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Budget Analysts',
        code: '13-2030',
        parentCode: '13-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Credit Analysts',
        code: '13-2040',
        parentCode: '13-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Credit Counselors and Loan Officers',
        code: '13-2070',
        parentCode: '13-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Financial Analysts and Advisors',
        code: '13-2050',
        parentCode: '13-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Financial Examiners',
        code: '13-2060',
        parentCode: '13-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Financial Specialists',
        code: '13-2090',
        parentCode: '13-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Property Appraisers and Assessors',
        code: '13-2020',
        parentCode: '13-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Tax Examiners, Collectors and Preparers, and Revenue Agents',
        code: '13-2080',
        parentCode: '13-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Community and Social Service Occupations',
        code: '21-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Counselors, Social Workers, and Other Community and Social Service Specialists',
        code: '21-1000',
        parentCode: '21-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Counselors',
        code: '21-1010',
        parentCode: '21-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Community and Social Service Specialists',
        code: '21-1090',
        parentCode: '21-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Social Workers',
        code: '21-1020',
        parentCode: '21-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Religious Workers',
        code: '21-2000',
        parentCode: '21-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Clergy',
        code: '21-2010',
        parentCode: '21-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Directors, Religious Activities and Education',
        code: '21-2020',
        parentCode: '21-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Religious Workers',
        code: '21-2090',
        parentCode: '21-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Computer and Mathematical Occupations',
        code: '15-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Computer Occupations',
        code: '15-1200',
        parentCode: '15-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Computer and Information Analysts',
        code: '15-1210',
        parentCode: '15-1200',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Computer and Information Research Scientists',
        code: '15-1220',
        parentCode: '15-1200',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Computer Support Specialists',
        code: '15-1230',
        parentCode: '15-1200',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Database and Network Administrators and Architects',
        code: '15-1240',
        parentCode: '15-1200',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Computer Occupations',
        code: '15-1290',
        parentCode: '15-1200',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Software and Web Developers, Programmers, and Testers',
        code: '15-1250',
        parentCode: '15-1200',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Mathematical Science Occupations',
        code: '15-2000',
        parentCode: '15-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Actuaries',
        code: '15-2010',
        parentCode: '15-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Data Scientists',
        code: '15-2050',
        parentCode: '15-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Mathematicians',
        code: '15-2020',
        parentCode: '15-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Mathematical Science Occupations',
        code: '15-2090',
        parentCode: '15-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Operations Research Analysts',
        code: '15-2030',
        parentCode: '15-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Statisticians',
        code: '15-2040',
        parentCode: '15-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Construction and Extraction Occupations',
        code: '47-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Construction Trades Workers',
        code: '47-2000',
        parentCode: '47-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Boilermakers',
        code: '47-2010',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Brickmasons, Blockmasons, and Stonemasons',
        code: '47-2020',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Carpenters',
        code: '47-2030',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Carpet, Floor, and Tile Installers and Finishers',
        code: '47-2040',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Cement Masons, Concrete Finishers, and Terrazzo Workers',
        code: '47-2050',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Construction Equipment Operators',
        code: '47-2070',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Construction Laborers',
        code: '47-2060',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Drywall Installers, Ceiling Tile Installers, and Tapers',
        code: '47-2080',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Electricians',
        code: '47-2110',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Glaziers',
        code: '47-2120',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Insulation Workers',
        code: '47-2130',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Painters and Paperhangers',
        code: '47-2140',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Pipelayers, Plumbers, Pipefitters, and Steamfitters',
        code: '47-2150',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Plasterers and Stucco Masons',
        code: '47-2160',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Reinforcing Iron and Rebar Workers',
        code: '47-2170',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Roofers',
        code: '47-2180',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Sheet Metal Workers',
        code: '47-2210',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Solar Photovoltaic Installers',
        code: '47-2230',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Structural Iron and Steel Workers',
        code: '47-2220',
        parentCode: '47-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Extraction Workers',
        code: '47-5000',
        parentCode: '47-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Derrick, Rotary Drill, and Service Unit Operators, Oil and Gas',
        code: '47-5010',
        parentCode: '47-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Explosives Workers, Ordnance Handling Experts, and Blasters',
        code: '47-5030',
        parentCode: '47-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Helpers--Extraction Workers',
        code: '47-5080',
        parentCode: '47-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Extraction Workers',
        code: '47-5090',
        parentCode: '47-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Rock Splitters, Quarry',
        code: '47-5050',
        parentCode: '47-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Roustabouts, Oil and Gas',
        code: '47-5070',
        parentCode: '47-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Surface Mining Machine Operators and Earth Drillers',
        code: '47-5020',
        parentCode: '47-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Underground Mining Machine Operators',
        code: '47-5040',
        parentCode: '47-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Helpers, Construction Trades',
        code: '47-3000',
        parentCode: '47-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Helpers, Construction Trades',
        code: '47-3010',
        parentCode: '47-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Construction and Related Workers',
        code: '47-4000',
        parentCode: '47-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Construction and Building Inspectors',
        code: '47-4010',
        parentCode: '47-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Elevator and Escalator Installers and Repairers',
        code: '47-4020',
        parentCode: '47-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Fence Erectors',
        code: '47-4030',
        parentCode: '47-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Hazardous Materials Removal Workers',
        code: '47-4040',
        parentCode: '47-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Highway Maintenance Workers',
        code: '47-4050',
        parentCode: '47-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Construction and Related Workers',
        code: '47-4090',
        parentCode: '47-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Rail-Track Laying and Maintenance Equipment Operators',
        code: '47-4060',
        parentCode: '47-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Septic Tank Servicers and Sewer Pipe Cleaners',
        code: '47-4070',
        parentCode: '47-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Construction and Extraction Workers',
        code: '47-1000',
        parentCode: '47-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Construction Trades and Extraction Workers',
        code: '47-1010',
        parentCode: '47-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Educational Instruction and Library Occupations',
        code: '25-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Librarians, Curators, and Archivists',
        code: '25-4000',
        parentCode: '25-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Archivists, Curators, and Museum Technicians',
        code: '25-4010',
        parentCode: '25-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Librarians and Media Collections Specialists',
        code: '25-4020',
        parentCode: '25-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Library Technicians',
        code: '25-4030',
        parentCode: '25-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Educational Instruction and Library Occupations',
        code: '25-9000',
        parentCode: '25-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Farm and Home Management Educators',
        code: '25-9020',
        parentCode: '25-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Instructional Coordinators',
        code: '25-9030',
        parentCode: '25-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Educational Instruction and Library Workers',
        code: '25-9090',
        parentCode: '25-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Teaching Assistants',
        code: '25-9040',
        parentCode: '25-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Teachers and Instructors',
        code: '25-3000',
        parentCode: '25-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Adult Basic Education, Adult Secondary Education, and English as a Second Language Instructors',
        code: '25-3010',
        parentCode: '25-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Teachers and Instructors',
        code: '25-3090',
        parentCode: '25-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Self-Enrichment Teachers',
        code: '25-3020',
        parentCode: '25-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Substitute Teachers, Short-Term',
        code: '25-3030',
        parentCode: '25-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Tutors',
        code: '25-3040',
        parentCode: '25-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Postsecondary Teachers',
        code: '25-1000',
        parentCode: '25-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Postsecondary Teachers',
        code: '25-1090',
        parentCode: '25-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Preschool, Elementary, Middle, Secondary, and Special Education Teachers',
        code: '25-2000',
        parentCode: '25-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Elementary and Middle School Teachers',
        code: '25-2020',
        parentCode: '25-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Preschool and Kindergarten Teachers',
        code: '25-2010',
        parentCode: '25-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Secondary School Teachers',
        code: '25-2030',
        parentCode: '25-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Special Education Teachers',
        code: '25-2050',
        parentCode: '25-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Farming, Fishing, and Forestry Occupations',
        code: '45-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Agricultural Workers',
        code: '45-2000',
        parentCode: '45-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Agricultural Inspectors',
        code: '45-2010',
        parentCode: '45-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Animal Breeders',
        code: '45-2020',
        parentCode: '45-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Graders and Sorters, Agricultural Products',
        code: '45-2040',
        parentCode: '45-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Agricultural Workers',
        code: '45-2090',
        parentCode: '45-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Fishing and Hunting Workers',
        code: '45-3000',
        parentCode: '45-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Fishing and Hunting Workers',
        code: '45-3030',
        parentCode: '45-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Forest, Conservation, and Logging Workers',
        code: '45-4000',
        parentCode: '45-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Forest and Conservation Workers',
        code: '45-4010',
        parentCode: '45-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Logging Workers',
        code: '45-4020',
        parentCode: '45-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Farming, Fishing, and Forestry Workers',
        code: '45-1000',
        parentCode: '45-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Farming, Fishing, and Forestry Workers',
        code: '45-1010',
        parentCode: '45-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Food Preparation and Serving Related Occupations',
        code: '35-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Cooks and Food Preparation Workers',
        code: '35-2000',
        parentCode: '35-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Cooks',
        code: '35-2010',
        parentCode: '35-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Food Preparation Workers',
        code: '35-2020',
        parentCode: '35-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Food and Beverage Serving Workers',
        code: '35-3000',
        parentCode: '35-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Bartenders',
        code: '35-3010',
        parentCode: '35-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Fast Food and Counter Workers',
        code: '35-3020',
        parentCode: '35-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Food Servers, Nonrestaurant',
        code: '35-3040',
        parentCode: '35-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Waiters and Waitresses',
        code: '35-3030',
        parentCode: '35-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Food Preparation and Serving Related Workers',
        code: '35-9000',
        parentCode: '35-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Dining Room and Cafeteria Attendants and Bartender Helpers',
        code: '35-9010',
        parentCode: '35-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Dishwashers',
        code: '35-9020',
        parentCode: '35-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop',
        code: '35-9030',
        parentCode: '35-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Food Preparation and Serving Related Workers',
        code: '35-9090',
        parentCode: '35-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Food Preparation and Serving Workers',
        code: '35-1000',
        parentCode: '35-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Supervisors of Food Preparation and Serving Workers',
        code: '35-1010',
        parentCode: '35-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Healthcare Practitioners and Technical Occupations',
        code: '29-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Health Technologists and Technicians',
        code: '29-2000',
        parentCode: '29-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Clinical Laboratory Technologists and Technicians',
        code: '29-2010',
        parentCode: '29-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Diagnostic Related Technologists and Technicians',
        code: '29-2030',
        parentCode: '29-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Emergency Medical Technicians and Paramedics',
        code: '29-2040',
        parentCode: '29-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Health Practitioner Support Technologists and Technicians',
        code: '29-2050',
        parentCode: '29-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Licensed Practical and Licensed Vocational Nurses',
        code: '29-2060',
        parentCode: '29-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Medical Records Specialists',
        code: '29-2070',
        parentCode: '29-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Health Technologists and Technicians',
        code: '29-2090',
        parentCode: '29-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Opticians, Dispensing',
        code: '29-2080',
        parentCode: '29-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Healthcare Diagnosing or Treating Practitioners',
        code: '29-1000',
        parentCode: '29-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Audiologists',
        code: '29-1180',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Chiropractors',
        code: '29-1010',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Dentists',
        code: '29-1020',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Dietitians and Nutritionists',
        code: '29-1030',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Healthcare Diagnosing or Treating Practitioners',
        code: '29-1290',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Nurse Anesthetists',
        code: '29-1150',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Nurse Midwives',
        code: '29-1160',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Nurse Practitioners',
        code: '29-1170',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Optometrists',
        code: '29-1040',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Pharmacists',
        code: '29-1050',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Physician Assistants',
        code: '29-1070',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Physicians',
        code: '29-1210',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Podiatrists',
        code: '29-1080',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Registered Nurses',
        code: '29-1140',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Surgeons',
        code: '29-1240',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Therapists',
        code: '29-1120',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Veterinarians',
        code: '29-1130',
        parentCode: '29-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Healthcare Practitioners and Technical Occupations',
        code: '29-9000',
        parentCode: '29-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Health Information Technologists and Medical Registrars',
        code: '29-9020',
        parentCode: '29-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Health Practitioners and Technical Workers',
        code: '29-9090',
        parentCode: '29-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Healthcare Support Occupations',
        code: '31-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Home Health and Personal Care Aides; and Nursing Assistants, Orderlies, and Psychiatric Aides',
        code: '31-1100',
        parentCode: '31-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Home Health and Personal Care Aides',
        code: '31-1120',
        parentCode: '31-1100',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Nursing Assistants, Orderlies, and Psychiatric Aides',
        code: '31-1130',
        parentCode: '31-1100',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Occupational Therapy and Physical Therapist Assistants and Aides',
        code: '31-2000',
        parentCode: '31-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Occupational Therapy Assistants and Aides',
        code: '31-2010',
        parentCode: '31-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Physical Therapist Assistants and Aides',
        code: '31-2020',
        parentCode: '31-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Healthcare Support Occupations',
        code: '31-9000',
        parentCode: '31-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Massage Therapists',
        code: '31-9010',
        parentCode: '31-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Healthcare Support Occupations',
        code: '31-9090',
        parentCode: '31-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Installation, Maintenance, and Repair Occupations',
        code: '49-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Electrical and Electronic Equipment Mechanics, Installers, and Repairers',
        code: '49-2000',
        parentCode: '49-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Computer, Automated Teller, and Office Machine Repairers',
        code: '49-2010',
        parentCode: '49-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Electrical and Electronic Equipment Mechanics, Installers, and Repairers',
        code: '49-2090',
        parentCode: '49-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Radio and Telecommunications Equipment Installers and Repairers',
        code: '49-2020',
        parentCode: '49-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Installation, Maintenance, and Repair Occupations',
        code: '49-9000',
        parentCode: '49-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Control and Valve Installers and Repairers',
        code: '49-9010',
        parentCode: '49-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Heating, Air Conditioning, and Refrigeration Mechanics and Installers',
        code: '49-9020',
        parentCode: '49-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Home Appliance Repairers',
        code: '49-9030',
        parentCode: '49-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Industrial Machinery Installation, Repair, and Maintenance Workers',
        code: '49-9040',
        parentCode: '49-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Line Installers and Repairers',
        code: '49-9050',
        parentCode: '49-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Maintenance and Repair Workers, General',
        code: '49-9070',
        parentCode: '49-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Installation, Maintenance, and Repair Workers',
        code: '49-9090',
        parentCode: '49-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Precision Instrument and Equipment Repairers',
        code: '49-9060',
        parentCode: '49-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Wind Turbine Service Technicians',
        code: '49-9080',
        parentCode: '49-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Installation, Maintenance, and Repair Workers',
        code: '49-1000',
        parentCode: '49-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Mechanics, Installers, and Repairers',
        code: '49-1010',
        parentCode: '49-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Vehicle and Mobile Equipment Mechanics, Installers, and Repairers',
        code: '49-3000',
        parentCode: '49-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Aircraft Mechanics and Service Technicians',
        code: '49-3010',
        parentCode: '49-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Automotive Technicians and Repairers',
        code: '49-3020',
        parentCode: '49-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Bus and Truck Mechanics and Diesel Engine Specialists',
        code: '49-3030',
        parentCode: '49-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Heavy Vehicle and Mobile Equipment Service Technicians and Mechanics',
        code: '49-3040',
        parentCode: '49-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Vehicle and Mobile Equipment Mechanics, Installers, and Repairers',
        code: '49-3090',
        parentCode: '49-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Small Engine Mechanics',
        code: '49-3050',
        parentCode: '49-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Legal Occupations',
        code: '23-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Lawyers, Judges, and Related Workers',
        code: '23-1000',
        parentCode: '23-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Judges, Magistrates, and Other Judicial Workers',
        code: '23-1020',
        parentCode: '23-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Lawyers and Judicial Law Clerks',
        code: '23-1010',
        parentCode: '23-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Legal Support Workers',
        code: '23-2000',
        parentCode: '23-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Miscellaneous Legal Support Workers',
        code: '23-2090',
        parentCode: '23-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Paralegals and Legal Assistants',
        code: '23-2010',
        parentCode: '23-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Life, Physical, and Social Science Occupations',
        code: '19-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Life Scientists',
        code: '19-1000',
        parentCode: '19-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Agricultural and Food Scientists',
        code: '19-1010',
        parentCode: '19-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Biological Scientists',
        code: '19-1020',
        parentCode: '19-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Conservation Scientists and Foresters',
        code: '19-1030',
        parentCode: '19-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Medical Scientists',
        code: '19-1040',
        parentCode: '19-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Life Scientists',
        code: '19-1090',
        parentCode: '19-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Life, Physical, and Social Science Technicians',
        code: '19-4000',
        parentCode: '19-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Agricultural and Food Science Technicians',
        code: '19-4010',
        parentCode: '19-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Biological Technicians',
        code: '19-4020',
        parentCode: '19-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Chemical Technicians',
        code: '19-4030',
        parentCode: '19-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Environmental Science and Geoscience Technicians',
        code: '19-4040',
        parentCode: '19-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Forest and Conservation Technicians',
        code: '19-4070',
        parentCode: '19-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Life, Physical, and Social Science Technicians',
        code: '19-4090',
        parentCode: '19-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Nuclear Technicians',
        code: '19-4050',
        parentCode: '19-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Social Science Research Assistants',
        code: '19-4060',
        parentCode: '19-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Occupational Health and Safety Specialists and Technicians',
        code: '19-5000',
        parentCode: '19-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Occupational Health and Safety Specialists and Technicians',
        code: '19-5010',
        parentCode: '19-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Physical Scientists',
        code: '19-2000',
        parentCode: '19-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Astronomers and Physicists',
        code: '19-2010',
        parentCode: '19-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Atmospheric and Space Scientists',
        code: '19-2020',
        parentCode: '19-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Chemists and Materials Scientists',
        code: '19-2030',
        parentCode: '19-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Environmental Scientists and Geoscientists',
        code: '19-2040',
        parentCode: '19-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Physical Scientists',
        code: '19-2090',
        parentCode: '19-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Social Scientists and Related Workers',
        code: '19-3000',
        parentCode: '19-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Economists',
        code: '19-3010',
        parentCode: '19-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Social Scientists and Related Workers',
        code: '19-3090',
        parentCode: '19-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Psychologists',
        code: '19-3030',
        parentCode: '19-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Sociologists',
        code: '19-3040',
        parentCode: '19-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Survey Researchers',
        code: '19-3020',
        parentCode: '19-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Urban and Regional Planners',
        code: '19-3050',
        parentCode: '19-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Management Occupations',
        code: '11-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Advertising, Marketing, Promotions, Public Relations, and Sales Managers',
        code: '11-2000',
        parentCode: '11-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Advertising and Promotions Managers',
        code: '11-2010',
        parentCode: '11-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Marketing and Sales Managers',
        code: '11-2020',
        parentCode: '11-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Public Relations and Fundraising Managers',
        code: '11-2030',
        parentCode: '11-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Operations Specialties Managers',
        code: '11-3000',
        parentCode: '11-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Administrative Services and Facilities Managers',
        code: '11-3010',
        parentCode: '11-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Compensation and Benefits Managers',
        code: '11-3110',
        parentCode: '11-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Computer and Information Systems Managers',
        code: '11-3020',
        parentCode: '11-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Financial Managers',
        code: '11-3030',
        parentCode: '11-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Human Resources Managers',
        code: '11-3120',
        parentCode: '11-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Industrial Production Managers',
        code: '11-3050',
        parentCode: '11-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Purchasing Managers',
        code: '11-3060',
        parentCode: '11-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Training and Development Managers',
        code: '11-3130',
        parentCode: '11-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Transportation, Storage, and Distribution Managers',
        code: '11-3070',
        parentCode: '11-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Management Occupations',
        code: '11-9000',
        parentCode: '11-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Architectural and Engineering Managers',
        code: '11-9040',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Construction Managers',
        code: '11-9020',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Education and Childcare Administrators',
        code: '11-9030',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Emergency Management Directors',
        code: '11-9160',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Entertainment and Recreation Managers',
        code: '11-9070',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Farmers, Ranchers, and Other Agricultural Managers',
        code: '11-9010',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Food Service Managers',
        code: '11-9050',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Lodging Managers',
        code: '11-9080',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Medical and Health Services Managers',
        code: '11-9110',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Managers',
        code: '11-9190',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Natural Sciences Managers',
        code: '11-9120',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Personal Service Managers',
        code: '11-9170',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Postmasters and Mail Superintendents',
        code: '11-9130',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Property, Real Estate, and Community Association Managers',
        code: '11-9140',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Social and Community Service Managers',
        code: '11-9150',
        parentCode: '11-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Top Executives',
        code: '11-1000',
        parentCode: '11-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Chief Executives',
        code: '11-1010',
        parentCode: '11-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'General and Operations Managers',
        code: '11-1020',
        parentCode: '11-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Legislators',
        code: '11-1030',
        parentCode: '11-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Military-only occupations',
        code: '55-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Military-only occupations',
        code: '55-9000',
        parentCode: '55-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Military-only occupations',
        code: '55-9990',
        parentCode: '55-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Office and Administrative Support Occupations',
        code: '43-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Communications Equipment Operators',
        code: '43-2000',
        parentCode: '43-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Miscellaneous Communications Equipment Operators',
        code: '43-2090',
        parentCode: '43-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Switchboard Operators, Including Answering Service',
        code: '43-2010',
        parentCode: '43-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Telephone Operators',
        code: '43-2020',
        parentCode: '43-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Financial Clerks',
        code: '43-3000',
        parentCode: '43-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Bill and Account Collectors',
        code: '43-3010',
        parentCode: '43-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Billing and Posting Clerks',
        code: '43-3020',
        parentCode: '43-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Bookkeeping, Accounting, and Auditing Clerks',
        code: '43-3030',
        parentCode: '43-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Gambling Cage Workers',
        code: '43-3040',
        parentCode: '43-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Financial Clerks',
        code: '43-3090',
        parentCode: '43-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Payroll and Timekeeping Clerks',
        code: '43-3050',
        parentCode: '43-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Procurement Clerks',
        code: '43-3060',
        parentCode: '43-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Tellers',
        code: '43-3070',
        parentCode: '43-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Information and Record Clerks',
        code: '43-4000',
        parentCode: '43-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Brokerage Clerks',
        code: '43-4010',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Correspondence Clerks',
        code: '43-4020',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Court, Municipal, and License Clerks',
        code: '43-4030',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Credit Authorizers, Checkers, and Clerks',
        code: '43-4040',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Customer Service Representatives',
        code: '43-4050',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Eligibility Interviewers, Government Programs',
        code: '43-4060',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'File Clerks',
        code: '43-4070',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Hotel, Motel, and Resort Desk Clerks',
        code: '43-4080',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Human Resources Assistants, Except Payroll and Timekeeping',
        code: '43-4160',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Interviewers, Except Eligibility and Loan',
        code: '43-4110',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Library Assistants, Clerical',
        code: '43-4120',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Loan Interviewers and Clerks',
        code: '43-4130',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Information and Record Clerks',
        code: '43-4190',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'New Accounts Clerks',
        code: '43-4140',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Order Clerks',
        code: '43-4150',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Receptionists and Information Clerks',
        code: '43-4170',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Reservation and Transportation Ticket Agents and Travel Clerks',
        code: '43-4180',
        parentCode: '43-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Material Recording, Scheduling, Dispatching, and Distributing Workers',
        code: '43-5000',
        parentCode: '43-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Cargo and Freight Agents',
        code: '43-5010',
        parentCode: '43-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Couriers and Messengers',
        code: '43-5020',
        parentCode: '43-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Dispatchers',
        code: '43-5030',
        parentCode: '43-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Meter Readers, Utilities',
        code: '43-5040',
        parentCode: '43-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Postal Service Workers',
        code: '43-5050',
        parentCode: '43-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Production, Planning, and Expediting Clerks',
        code: '43-5060',
        parentCode: '43-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Shipping, Receiving, and Inventory Clerks',
        code: '43-5070',
        parentCode: '43-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Weighers, Measurers, Checkers, and Samplers, Recordkeeping',
        code: '43-5110',
        parentCode: '43-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Office and Administrative Support Workers',
        code: '43-9000',
        parentCode: '43-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Data Entry and Information Processing Workers',
        code: '43-9020',
        parentCode: '43-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Desktop Publishers',
        code: '43-9030',
        parentCode: '43-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Insurance Claims and Policy Processing Clerks',
        code: '43-9040',
        parentCode: '43-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Mail Clerks and Mail Machine Operators, Except Postal Service',
        code: '43-9050',
        parentCode: '43-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Office and Administrative Support Workers',
        code: '43-9190',
        parentCode: '43-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Office Clerks, General',
        code: '43-9060',
        parentCode: '43-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Office Machine Operators, Except Computer',
        code: '43-9070',
        parentCode: '43-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Proofreaders and Copy Markers',
        code: '43-9080',
        parentCode: '43-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Statistical Assistants',
        code: '43-9110',
        parentCode: '43-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Secretaries and Administrative Assistants',
        code: '43-6000',
        parentCode: '43-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Secretaries and Administrative Assistants',
        code: '43-6010',
        parentCode: '43-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Office and Administrative Support Workers',
        code: '43-1000',
        parentCode: '43-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Office and Administrative Support Workers',
        code: '43-1010',
        parentCode: '43-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Personal Care and Service Occupations',
        code: '39-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Animal Care and Service Workers',
        code: '39-2000',
        parentCode: '39-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Animal Caretakers',
        code: '39-2020',
        parentCode: '39-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Animal Trainers',
        code: '39-2010',
        parentCode: '39-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Baggage Porters, Bellhops, and Concierges',
        code: '39-6000',
        parentCode: '39-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Baggage Porters, Bellhops, and Concierges',
        code: '39-6010',
        parentCode: '39-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Entertainment Attendants and Related Workers',
        code: '39-3000',
        parentCode: '39-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Gambling Services Workers',
        code: '39-3010',
        parentCode: '39-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Entertainment Attendants and Related Workers',
        code: '39-3090',
        parentCode: '39-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Motion Picture Projectionists',
        code: '39-3020',
        parentCode: '39-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Ushers, Lobby Attendants, and Ticket Takers',
        code: '39-3030',
        parentCode: '39-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Funeral Service Workers',
        code: '39-4000',
        parentCode: '39-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Embalmers and Crematory Operators',
        code: '39-4010',
        parentCode: '39-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Funeral Attendants',
        code: '39-4020',
        parentCode: '39-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Morticians, Undertakers, and Funeral Arrangers',
        code: '39-4030',
        parentCode: '39-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Personal Care and Service Workers',
        code: '39-9000',
        parentCode: '39-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Childcare Workers',
        code: '39-9010',
        parentCode: '39-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Personal Care and Service Workers',
        code: '39-9090',
        parentCode: '39-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Recreation and Fitness Workers',
        code: '39-9030',
        parentCode: '39-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Residential Advisors',
        code: '39-9040',
        parentCode: '39-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Personal Appearance Workers',
        code: '39-5000',
        parentCode: '39-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Barbers, Hairdressers, Hairstylists and Cosmetologists',
        code: '39-5010',
        parentCode: '39-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Personal Appearance Workers',
        code: '39-5090',
        parentCode: '39-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Personal Care and Service Workers',
        code: '39-1000',
        parentCode: '39-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Entertainment and Recreation Workers',
        code: '39-1010',
        parentCode: '39-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'First-Line Supervisors of Personal Service Workers',
        code: '39-1020',
        parentCode: '39-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Tour and Travel Guides',
        code: '39-7000',
        parentCode: '39-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Tour and Travel Guides',
        code: '39-7010',
        parentCode: '39-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Production Occupations',
        code: '51-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Assemblers and Fabricators',
        code: '51-2000',
        parentCode: '51-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Aircraft Structure, Surfaces, Rigging, and Systems Assemblers',
        code: '51-2010',
        parentCode: '51-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Electrical, Electronics, and Electromechanical Assemblers',
        code: '51-2020',
        parentCode: '51-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Engine and Other Machine Assemblers',
        code: '51-2030',
        parentCode: '51-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Fiberglass Laminators and Fabricators',
        code: '51-2050',
        parentCode: '51-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Assemblers and Fabricators',
        code: '51-2090',
        parentCode: '51-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Structural Metal Fabricators and Fitters',
        code: '51-2040',
        parentCode: '51-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Timing Device Assemblers and Adjusters',
        code: '51-2060',
        parentCode: '51-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Food Processing Workers',
        code: '51-3000',
        parentCode: '51-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Bakers',
        code: '51-3010',
        parentCode: '51-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Butchers and Other Meat, Poultry, and Fish Processing Workers',
        code: '51-3020',
        parentCode: '51-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Food Processing Workers',
        code: '51-3090',
        parentCode: '51-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Metal Workers and Plastic Workers',
        code: '51-4000',
        parentCode: '51-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Forming Machine Setters, Operators, and Tenders, Metal and Plastic',
        code: '51-4020',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Machine Tool Cutting Setters, Operators, and Tenders, Metal and Plastic',
        code: '51-4030',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Machinists',
        code: '51-4040',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Metal Furnace Operators, Tenders, Pourers, and Casters',
        code: '51-4050',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Metal Workers and Plastic Workers',
        code: '51-4190',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Model Makers and Patternmakers, Metal and Plastic',
        code: '51-4060',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Molders and Molding Machine Setters, Operators, and Tenders, Metal and Plastic',
        code: '51-4070',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
        code: '51-4080',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Tool and Die Makers',
        code: '51-4110',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Welding, Soldering, and Brazing Workers',
        code: '51-4120',
        parentCode: '51-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Production Occupations',
        code: '51-9000',
        parentCode: '51-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Chemical Processing Machine Setters, Operators, and Tenders',
        code: '51-9010',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Computer Numerically Controlled Tool Operators and Programmers',
        code: '51-9160',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Crushing, Grinding, Polishing, Mixing, and Blending Workers',
        code: '51-9020',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Cutting Workers',
        code: '51-9030',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Dental and Ophthalmic Laboratory Technicians and Medical Appliance Technicians',
        code: '51-9080',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders',
        code: '51-9040',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders',
        code: '51-9050',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Inspectors, Testers, Sorters, Samplers, and Weighers',
        code: '51-9060',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Jewelers and Precious Stone and Metal Workers',
        code: '51-9070',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Production Workers',
        code: '51-9190',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Packaging and Filling Machine Operators and Tenders',
        code: '51-9110',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Painting Workers',
        code: '51-9120',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Photographic Process Workers and Processing Machine Operators',
        code: '51-9150',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Semiconductor Processing Technicians',
        code: '51-9140',
        parentCode: '51-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Plant and System Operators',
        code: '51-8000',
        parentCode: '51-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Miscellaneous Plant and System Operators',
        code: '51-8090',
        parentCode: '51-8000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Power Plant Operators, Distributors, and Dispatchers',
        code: '51-8010',
        parentCode: '51-8000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Stationary Engineers and Boiler Operators',
        code: '51-8020',
        parentCode: '51-8000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Water and Wastewater Treatment Plant and System Operators',
        code: '51-8030',
        parentCode: '51-8000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Printing Workers',
        code: '51-5100',
        parentCode: '51-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Printing Workers',
        code: '51-5110',
        parentCode: '51-5100',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Production Workers',
        code: '51-1000',
        parentCode: '51-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Production and Operating Workers',
        code: '51-1010',
        parentCode: '51-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Textile, Apparel, and Furnishings Workers',
        code: '51-6000',
        parentCode: '51-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Laundry and Dry-Cleaning Workers',
        code: '51-6010',
        parentCode: '51-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Textile, Apparel, and Furnishings Workers',
        code: '51-6090',
        parentCode: '51-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Pressers, Textile, Garment, and Related Materials',
        code: '51-6020',
        parentCode: '51-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Sewing Machine Operators',
        code: '51-6030',
        parentCode: '51-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Shoe and Leather Workers',
        code: '51-6040',
        parentCode: '51-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Tailors, Dressmakers, and Sewers',
        code: '51-6050',
        parentCode: '51-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Textile Machine Setters, Operators, and Tenders',
        code: '51-6060',
        parentCode: '51-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Woodworkers',
        code: '51-7000',
        parentCode: '51-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Cabinetmakers and Bench Carpenters',
        code: '51-7010',
        parentCode: '51-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Furniture Finishers',
        code: '51-7020',
        parentCode: '51-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Woodworkers',
        code: '51-7090',
        parentCode: '51-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Model Makers and Patternmakers, Wood',
        code: '51-7030',
        parentCode: '51-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Woodworking Machine Setters, Operators, and Tenders',
        code: '51-7040',
        parentCode: '51-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Protective Service Occupations',
        code: '33-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Firefighting and Prevention Workers',
        code: '33-2000',
        parentCode: '33-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Fire Inspectors',
        code: '33-2020',
        parentCode: '33-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Firefighters',
        code: '33-2010',
        parentCode: '33-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Law Enforcement Workers',
        code: '33-3000',
        parentCode: '33-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Bailiffs, Correctional Officers, and Jailers',
        code: '33-3010',
        parentCode: '33-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Detectives and Criminal Investigators',
        code: '33-3020',
        parentCode: '33-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Fish and Game Wardens',
        code: '33-3030',
        parentCode: '33-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Parking Enforcement Workers',
        code: '33-3040',
        parentCode: '33-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Police Officers',
        code: '33-3050',
        parentCode: '33-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Protective Service Workers',
        code: '33-9000',
        parentCode: '33-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Animal Control Workers',
        code: '33-9010',
        parentCode: '33-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Protective Service Workers',
        code: '33-9090',
        parentCode: '33-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Private Detectives and Investigators',
        code: '33-9020',
        parentCode: '33-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Security Guards and Gambling Surveillance Officers',
        code: '33-9030',
        parentCode: '33-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Protective Service Workers',
        code: '33-1000',
        parentCode: '33-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Firefighting and Prevention Workers',
        code: '33-1020',
        parentCode: '33-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'First-Line Supervisors of Law Enforcement Workers',
        code: '33-1010',
        parentCode: '33-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous First-Line Supervisors, Protective Service Workers',
        code: '33-1090',
        parentCode: '33-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Sales and Related Occupations',
        code: '41-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Other Sales and Related Workers',
        code: '41-9000',
        parentCode: '41-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Miscellaneous Sales and Related Workers',
        code: '41-9090',
        parentCode: '41-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Models, Demonstrators, and Product Promoters',
        code: '41-9010',
        parentCode: '41-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Real Estate Brokers and Sales Agents',
        code: '41-9020',
        parentCode: '41-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Sales Engineers',
        code: '41-9030',
        parentCode: '41-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Telemarketers',
        code: '41-9040',
        parentCode: '41-9000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Retail Sales Workers',
        code: '41-2000',
        parentCode: '41-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Cashiers',
        code: '41-2010',
        parentCode: '41-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Counter and Rental Clerks and Parts Salespersons',
        code: '41-2020',
        parentCode: '41-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Retail Salespersons',
        code: '41-2030',
        parentCode: '41-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Sales Representatives, Services',
        code: '41-3000',
        parentCode: '41-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Advertising Sales Agents',
        code: '41-3010',
        parentCode: '41-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Insurance Sales Agents',
        code: '41-3020',
        parentCode: '41-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Sales Representatives, Services',
        code: '41-3090',
        parentCode: '41-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Securities, Commodities, and Financial Services Sales Agents',
        code: '41-3030',
        parentCode: '41-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Travel Agents',
        code: '41-3040',
        parentCode: '41-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Sales Representatives, Wholesale and Manufacturing',
        code: '41-4000',
        parentCode: '41-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Sales Representatives, Wholesale and Manufacturing',
        code: '41-4010',
        parentCode: '41-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Sales Workers',
        code: '41-1000',
        parentCode: '41-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Sales Workers',
        code: '41-1010',
        parentCode: '41-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Transportation and Material Moving Occupations',
        code: '53-0000',
        parentCode: null,
        hasChildren: true,
        level: 0,
    },
    {
        name: 'Air Transportation Workers',
        code: '53-2000',
        parentCode: '53-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Air Traffic Controllers and Airfield Operations Specialists',
        code: '53-2020',
        parentCode: '53-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Aircraft Pilots and Flight Engineers',
        code: '53-2010',
        parentCode: '53-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Flight Attendants',
        code: '53-2030',
        parentCode: '53-2000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Material Moving Workers',
        code: '53-7000',
        parentCode: '53-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Conveyor Operators and Tenders',
        code: '53-7010',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Crane and Tower Operators',
        code: '53-7020',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Dredge Operators',
        code: '53-7030',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Hoist and Winch Operators',
        code: '53-7040',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Industrial Truck and Tractor Operators',
        code: '53-7050',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Laborers and Material Movers',
        code: '53-7060',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Material Moving Workers',
        code: '53-7190',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Pumping Station Operators',
        code: '53-7070',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Refuse and Recyclable Material Collectors',
        code: '53-7080',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Tank Car, Truck, and Ship Loaders',
        code: '53-7120',
        parentCode: '53-7000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Motor Vehicle Operators',
        code: '53-3000',
        parentCode: '53-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Ambulance Drivers and Attendants, Except Emergency Medical Technicians',
        code: '53-3010',
        parentCode: '53-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Driver/Sales Workers and Truck Drivers',
        code: '53-3030',
        parentCode: '53-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Motor Vehicle Operators',
        code: '53-3090',
        parentCode: '53-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Passenger Vehicle Drivers',
        code: '53-3050',
        parentCode: '53-3000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Other Transportation Workers',
        code: '53-6000',
        parentCode: '53-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Bridge and Lock Tenders',
        code: '53-6010',
        parentCode: '53-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Transportation Workers',
        code: '53-6090',
        parentCode: '53-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Parking Attendants',
        code: '53-6020',
        parentCode: '53-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Passenger Attendants',
        code: '53-6060',
        parentCode: '53-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Traffic Technicians',
        code: '53-6040',
        parentCode: '53-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Transportation Inspectors',
        code: '53-6050',
        parentCode: '53-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Transportation Service Attendants',
        code: '53-6030',
        parentCode: '53-6000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Rail Transportation Workers',
        code: '53-4000',
        parentCode: '53-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Locomotive Engineers and Operators',
        code: '53-4010',
        parentCode: '53-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Miscellaneous Rail Transportation Workers',
        code: '53-4090',
        parentCode: '53-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Railroad Brake, Signal, and Switch Operators and Locomotive Firers',
        code: '53-4020',
        parentCode: '53-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Railroad Conductors and Yardmasters',
        code: '53-4030',
        parentCode: '53-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Subway and Streetcar Operators',
        code: '53-4040',
        parentCode: '53-4000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Supervisors of Transportation and Material Moving Workers',
        code: '53-1000',
        parentCode: '53-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'First-Line Supervisors of Transportation and Material Moving Workers',
        code: '53-1040',
        parentCode: '53-1000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Water Transportation Workers',
        code: '53-5000',
        parentCode: '53-0000',
        hasChildren: true,
        level: 1,
    },
    {
        name: 'Sailors and Marine Oilers',
        code: '53-5010',
        parentCode: '53-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Ship and Boat Captains and Operators',
        code: '53-5020',
        parentCode: '53-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Ship Engineers',
        code: '53-5030',
        parentCode: '53-5000',
        hasChildren: false,
        level: 2,
    },
    {
        name: 'Unclassified Occupation',
        code: '99-0000',
        parentCode: null,
        level: 0,
    },
];
