import SvgIcon from '@mui/material/SvgIcon';

export function PastOccupationsIcon(props) {
    return (
        <SvgIcon viewBox="0 0 18 18" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8571 4.50014H17.3571L18 5.143V8.50706C17.5199 7.91378 16.9413 7.4035 16.2891 7.00112L16.7143 6.763V5.78585H1.28571V6.69871L6.42857 9.643V9.00014L7.08429 8.35728H7.6248C6.976 9.11356 6.4917 10.0151 6.22737 11.0064L1.28571 8.17728V14.7859H6.31242C6.45486 15.2367 6.64337 15.6671 6.87241 16.0716H0.642857L0 15.4287V5.143L0.642857 4.50014H5.14286V2.57157L5.78571 1.92871H12.2143L12.8571 2.57157V4.50014ZM11.5714 3.21443H6.42857V4.50014H11.5714V3.21443Z"
                fill="currentColor"
            />
            <path
                d="M12.7896 10.9644V13.125L14.7187 12.9234"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M8.25 12.75C8.25 10.2715 10.2715 8.25 12.75 8.25C15.2285 8.25 17.25 10.2715 17.25 12.75C17.25 15.2285 15.2285 17.25 12.75 17.25C10.2715 17.25 8.25 15.2285 8.25 12.75Z"
                stroke="currentColor"
                strokeWidth="1.5"
                fill="none"
            />
        </SvgIcon>
    );
}
