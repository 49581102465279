import { Typography } from 'components/Typography';
import isValid from 'date-fns/isValid';
import PropTypes from 'prop-types';

const getDateRange = (dateFrom, dateTo) => {
    let rangeString;
    if (!dateFrom || typeof dateFrom !== 'string') {
        return null;
    }

    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);

    if (!isValid(startDate) || !isValid(endDate)) {
        rangeString = null;
    } else {
        const startDateString = startDate.getFullYear();
        const endDateString = !!dateTo ? ` - ${endDate.getFullYear()}` : '';
        rangeString = `${startDateString} ${endDateString}`;
    }
    return rangeString;
};

export function TalentExperienceTimeLines({ timelineItem }) {
    const dateRange = getDateRange(timelineItem.dateFrom, timelineItem.dateTo);

    return (
        <>
            {dateRange && (
                <Typography sx={{ ml: 0.5, pl: 0.5, borderLeft: 'solid 1px lightgrey' }}>
                    {dateRange}
                </Typography>
            )}
        </>
    );
}

TalentExperienceTimeLines.propTypes = {
    timelineItem: PropTypes.object,
};
