import SvgIcon from '@mui/material/SvgIcon';

export function ProjectsIcon(props) {
    return (
        <SvgIcon sx={{ marginTop: '3px' }} viewBox="0 0 16 16" {...props}>
            <path
                d="M13.8276 2.59236H10.1031C9.51963 2.59236 8.98413 2.3526 8.7044 1.96099C8.08897 1.10584 7.07392 0.59436 5.99494 0.59436H3.797C1.97471 0.59436 0.496094 2.02491 0.496094 3.77516V10.8241C0.496094 12.2467 1.69497 13.4055 3.16559 13.4055H14.0834C15.3781 13.4055 16.4571 12.3826 16.4811 11.1278L16.4971 5.18176C16.4971 3.75919 15.2982 2.60034 13.8276 2.60034V2.59236ZM14.8986 10.2567L14.8826 11.0879C14.8826 11.4795 14.5149 11.7991 14.0834 11.7991H3.16559C2.57415 11.7991 2.09459 11.3596 2.09459 10.8161V3.76719C2.09459 2.89606 2.86187 2.18476 3.797 2.18476H5.99494C6.5624 2.18476 7.08991 2.4485 7.40162 2.88806C7.98507 3.70325 8.99213 4.18276 10.0951 4.18276H13.8196C14.411 4.18276 14.8906 4.62233 14.8906 5.16579V10.2487L14.8986 10.2567Z"
                fill="currentColor"
            />
            <path
                d="M9.67171 6.0607L7.90536 7.96281L7.28994 7.30746C6.98623 6.98777 6.4827 6.97178 6.163 7.27548C5.8433 7.57918 5.82732 8.08268 6.13103 8.40236L7.15407 9.48128C7.35388 9.68907 7.62563 9.80896 7.91336 9.80896C8.20109 9.80896 8.48083 9.68108 8.67265 9.47328L10.8306 7.14761C11.1343 6.81993 11.1104 6.31643 10.7907 6.02073C10.471 5.71703 9.95944 5.74102 9.66372 6.0607H9.67171Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
