const BASE_TALKTRACK_URL = '/api/messaging-workflow/v1';
export const TALKTRACK_API_ENDPOINT = `${BASE_TALKTRACK_URL}/talk-tracks`;

const buildGetRequest = (url, params) => {
    return url + '?' + new URLSearchParams(params).toString();
};

const getFetch = async (url, headers) => {
    const response = await fetch(url, {
        method: 'GET',
        headers,
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

const postFetch = async (url, data = {}, headers) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data }),
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * POST /api/messaging-workflow/v1/talk-tracks
 * Creates a new talk-track template and returns the talk-track_id
 */
export const createNewTalkTrackApi = async ({ data, headers }) => {
    const url = TALKTRACK_API_ENDPOINT;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * GET /api/messaging-workflow/v1/talk-tracks
 * Gets all talk-track templates
 */

export const getAllTalkTracksApi = async ({ queryKey, headers }) => {
    const [, params] = queryKey;
    const url = buildGetRequest(TALKTRACK_API_ENDPOINT, { pageSize: 1000, ...params });
    const response = await fetch(url, {
        method: 'GET',
        headers,
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * GET /api/messaging-workflow/v1/talk-tracks/metrics
 * Returns talk-tracks metrics
 */
export const getTalkTracksMetrics = async ({ headers }) => {
    const response = await getFetch(`${TALKTRACK_API_ENDPOINT}/metrics`, headers);
    return response;
};

/**
 *
 * GET /api/messaging-workflow/v1/talk-tracks/:talkTrackId
 * Returns a talk-track template
 */
export const getTalkTrackApi = async ({ queryKey, headers }) => {
    const [, { talkTrackId }] = queryKey;

    const response = await fetch(`${TALKTRACK_API_ENDPOINT}/${talkTrackId}`, {
        method: 'GET',
        headers,
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * GET /api/messaging-workflow/v1/talk-tracks/:talkTrackId/enrollees
 * Returns a talk-track enrollees
 */
export const getTalkTrackEnrollees = async ({ queryKey, headers }) => {
    const [, { talkTrackId, pageSize = 10 }] = queryKey;
    const url = buildGetRequest(`${TALKTRACK_API_ENDPOINT}/${talkTrackId}/enrollees`, { pageSize });
    return await getFetch(url, headers);
};

/**
 *
 * PUT /api/messaging-workflow/v1/talk-tracks/:talkTrackId
 * Updates existing talk-track template and returns the updated template
 */

export const updateTalkTracks = async ({ talkTrackId, data, headers }) => {
    const response = await fetch(`${TALKTRACK_API_ENDPOINT}/${talkTrackId}`, {
        method: 'PUT',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data }),
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * POST /api/messaging-workflow/v1/talk-tracks/:talkTrackId/enroll
 * Enrolls talents to a talk-track
 */
export const enrollToTalkTrack = async ({ params, data, headers }) => {
    const url = `${TALKTRACK_API_ENDPOINT}/${params.channelId}/enroll`;
    return await postFetch(url, data, headers);
};

/**
 *
 * POST /api/messaging-workflow/v1/talk-tracks/:talkTrackId/enrollees/:instanceId/unenroll
 * Unenrolls a recipient
 */

export const unenrollRecipientApi = ({ talkTrackId, instanceId, headers }) => {
    const url = `${TALKTRACK_API_ENDPOINT}/${talkTrackId}/enrollees/${instanceId}/unenroll`;
    return postFetch(url, {}, headers);
};

/**
 *
 * PUT /api/messaging-workflow/v1/talk-tracks/:talkTrackId/status
 * Updates talkTracks status and returns the updated template
 */

export const updateTalkTackStatusApi = ({ talkTrackId, data, headers }) => {
    const url = `${TALKTRACK_API_ENDPOINT}/${talkTrackId}/status`;
    return postFetch(url, data, headers);
};
