import { useEffect, useState } from 'react';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import findKey from 'lodash/findKey';
import merge from 'lodash/merge';
import find from 'lodash/find';

import { useLookupTalentContacts } from 'hooks/useLookupTalentContacts';
import { STAGE_ENUMS } from './useOrganizationTokenDialog';
import { MESSAGE_TYPES } from 'utils/constants';
import isEmpty from 'lodash/isEmpty';

export function useTokenLookupStage(props) {
    const {
        profiles,
        messageType,
        selectedTalents,
        initialLookups,
        setCurrentStage,
        handleConfirm,
        handleCloseMenu,
        isOpen,
        isProjectOutreachContactLookup,
        handleClose,
    } = props;
    const [talentLookups, setTalentLookups] = useState([]);
    const [allLookupsFailed, setAllLookupsFailed] = useState(true);

    const [lookupTalentContacts, piplResult] = useLookupTalentContacts(
        isProjectOutreachContactLookup
    );

    const {
        data: piplData,
        isError: piplError,
        isSuccess: piplSuccess,
        isFetching: isLookupLoading,
        reset: resetPiplLookup,
    } = piplResult;

    const filterProfiles = (talentProfiles) => {
        return talentProfiles.reduce((filtered, nextProfile) => {
            const nextTalent = find(talentLookups, ['talentId', nextProfile.talentId]);
            if (!nextTalent?.lookupFailed) {
                filtered.push(nextProfile);
            }
            return filtered;
        }, []);
    };

    const handleLookupStageConfirm = (talents) => {
        if (isArray(talents)) {
            const talentIds = talents.map((talent) => talent.talentId);
            lookupTalentContacts(talentIds);
        }
        if (isString(talents)) {
            lookupTalentContacts([talents]);
        }
    };

    const handleLookupErrorStageConfirm = () => {
        handleConfirm(filterProfiles(profiles));
    };

    useEffect(() => {
        if (initialLookups) {
            setTalentLookups(initialLookups);
        }
    }, [initialLookups]);

    useEffect(() => {
        if (piplSuccess && piplData) {
            const failedLookupsExist = findKey(piplData, (lookup) => {
                if (
                    (messageType === MESSAGE_TYPES.EMAIL && !lookup?.emails?.length) ||
                    (messageType === MESSAGE_TYPES.SMS && !lookup?.phones?.length)
                ) {
                    return lookup;
                }
            });
            if (failedLookupsExist) {
                setCurrentStage(STAGE_ENUMS.LOOKUP_ERROR);
            }

            if (isOpen && !failedLookupsExist) {
                handleConfirm();
                handleCloseMenu();
                handleClose();
            }
        }

        if (piplError) {
            setCurrentStage(STAGE_ENUMS.PIPL_ERROR);
        }
    }, [
        piplError,
        piplSuccess,
        piplData,
        handleConfirm,
        handleCloseMenu,
        setCurrentStage,
        messageType,
        isOpen,
        handleClose,
    ]);

    useEffect(() => {
        if (piplSuccess && piplData) {
            const nextLookUps = selectedTalents.map((talent) => {
                const talentCopy = { ...talent };
                const lookup = piplData[talent.talentId];
                talentCopy['lookupFailed'] =
                    (messageType === MESSAGE_TYPES.EMAIL && !lookup?.emails?.length) ||
                    (messageType === MESSAGE_TYPES.SMS && !lookup?.phones?.length) ||
                    false;
                return talentCopy;
            });
            if (!isEqual(talentLookups, nextLookUps)) {
                if (!isEmpty(talentLookups)) {
                    setTalentLookups(merge(talentLookups, nextLookUps));
                } else {
                    setTalentLookups(nextLookUps);
                }
            }
        }
    }, [piplSuccess, piplData, selectedTalents, talentLookups, messageType]);

    useEffect(() => {
        if (talentLookups?.length) {
            const successfulLookup = find(talentLookups, { lookupFailed: false });
            if (successfulLookup) {
                setAllLookupsFailed(!Boolean(successfulLookup));
            }
        }
    }, [talentLookups, setCurrentStage]);

    return {
        talentLookups,
        isLookupLoading,
        allLookupsFailed,
        resetPiplLookup,
        handleLookupStageConfirm,
        handleLookupErrorStageConfirm,
    };
}
