import SvgIcon from '@mui/material/SvgIcon';

export function MessageIcon(props) {
    return (
        <SvgIcon viewBox="0 0 22 22" {...props}>
            <path
                d="M3.88892 8.55821V14.7331C3.88892 15.3932 4.15114 16.0263 4.61789 16.4931C5.08465 16.9598 5.71771 17.222 6.3778 17.222H16.3334C16.9935 17.222 17.6265 16.9598 18.0933 16.4931C18.56 16.0263 18.8222 15.3932 18.8222 14.7331V7.26648C18.8222 6.60638 18.56 5.97332 18.0933 5.50657C17.6265 5.03981 16.9935 4.77759 16.3334 4.77759H6.3778C5.71771 4.77759 5.08465 5.03981 4.61789 5.50657C4.15114 5.97332 3.88892 6.60638 3.88892 7.26648V8.55821ZM6.3778 6.02203H16.3334C16.6634 6.02203 16.9799 6.15314 17.2133 6.38652C17.4467 6.6199 17.5778 6.93643 17.5778 7.26648V8.18737L11.3556 11.5374L5.13336 8.18737V7.26648C5.13336 6.93643 5.26447 6.6199 5.49785 6.38652C5.73123 6.15314 6.04776 6.02203 6.3778 6.02203ZM5.13336 9.60106L11.0606 12.7918C11.1513 12.8406 11.2526 12.8662 11.3556 12.8662C11.4585 12.8662 11.5599 12.8406 11.6505 12.7918L17.5778 9.60106V14.7331C17.5778 15.0632 17.4467 15.3797 17.2133 15.6131C16.9799 15.8465 16.6634 15.9776 16.3334 15.9776H6.3778C6.04776 15.9776 5.73123 15.8465 5.49785 15.6131C5.26447 15.3797 5.13336 15.0632 5.13336 14.7331V9.60106Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
