import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';

import { Tooltip } from 'components/Tooltip';
import { StyledChip } from './styledComponents';

export function FilterChip(props) {
    const { label, disableTooltip, ...restProps } = props;

    const chipRef = useRef(null);
    const [isOverflowed, setIsOverflowed] = useState(false);

    useEffect(() => {
        let hasOverflow = false;
        if (chipRef.current) {
            const labelElement = chipRef.current.querySelector('.MuiChip-label');
            hasOverflow = labelElement.clientWidth < labelElement.scrollWidth;
        }
        if (!disableTooltip) {
            setIsOverflowed(hasOverflow);
        }
    }, [disableTooltip]);

    return (
        <Tooltip
            title={<>{label}</>}
            disableFocusListener={!isOverflowed}
            disableHoverListener={!isOverflowed}
            disableTouchListener={!isOverflowed}
        >
            <StyledChip ref={chipRef} label={label} {...restProps} />
        </Tooltip>
    );
}

FilterChip.propTypes = {
    label: PropTypes.node,
    disableTooltip: PropTypes.bool,
};
