import SvgIcon from '@mui/material/SvgIcon';

export function TextIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M8.13333 13.7268L8.5216 13.4673C8.47892 13.4035 8.42115 13.3512 8.35343 13.3151C8.2857 13.2789 8.2101 13.2601 8.13333 13.2601V13.7268ZM10 16.5249L9.61173 16.7835C9.65435 16.8474 9.71209 16.8998 9.77982 16.936C9.84755 16.9723 9.92318 16.9912 10 16.9912C10.0768 16.9912 10.1525 16.9723 10.2202 16.936C10.2879 16.8998 10.3456 16.8474 10.3883 16.7835L10 16.5249ZM11.8667 13.7268V13.2601C11.7899 13.2601 11.7143 13.2789 11.6466 13.3151C11.5788 13.3512 11.5211 13.4035 11.4784 13.4673L11.8667 13.7268ZM7.74507 13.9853L9.61173 16.7835L10.3883 16.2655L8.5216 13.4673L7.74507 13.9853ZM10.3883 16.7835L12.2549 13.9853L11.4784 13.4673L9.61173 16.2655L10.3883 16.7835ZM11.8667 14.1935H15.6V13.2601H11.8667V14.1935ZM15.6 14.1935C16.3737 14.1935 17 13.5681 17 12.7935H16.0667C16.0667 13.052 15.8585 13.2601 15.6 13.2601V14.1935ZM17 12.7935V4.4H16.0667V12.7944H17V12.7935ZM17 4.4C17 3.62533 16.3737 3 15.6 3V3.93333C15.8585 3.93333 16.0667 4.14147 16.0667 4.4H17ZM15.6 3H4.4V3.93333H15.6V3ZM4.4 3C3.62627 3 3 3.62533 3 4.4H3.93333C3.93333 4.14147 4.14147 3.93333 4.4 3.93333V3ZM3 4.4V12.7935H3.93333V4.4H3ZM3 12.7935C3 13.5681 3.62627 14.1935 4.4 14.1935V13.2601C4.33868 13.2603 4.27794 13.2483 4.22127 13.2249C4.16459 13.2015 4.1131 13.1671 4.06974 13.1237C4.02638 13.0804 3.99201 13.0289 3.96861 12.9722C3.9452 12.9155 3.93321 12.8548 3.93333 12.7935H3ZM4.4 14.1935H8.13333V13.2601H4.4V14.1935ZM9.53333 9.53333H10.4667V8.6H9.53333V9.53333ZM6.73333 9.53333H7.66667V8.6H6.73333V9.53333ZM12.3333 9.53333H13.2667V8.6H12.3333V9.53333Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
