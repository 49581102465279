import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import { Typography } from 'components/Typography';
import { CheckIcon } from 'components/Icons';
import { Box } from '@mui/system';

function createData(featureName, current, premium) {
    return { featureName, current, premium };
}

const rows = [
    createData('Projects', 'No', 'Unlimited'),
    createData('Match Results', 'Limited', 'Unlimited'),
    createData('Communication Channels', 'Email Only', 'Unlimited'),
    createData('Contact Lookups', 'Limited', 'Unlimited'),
    createData('Sequences', 'Limited', 'Unlimited'),
    createData('Job Descriptions', 'Limited', 'Unlimited'),
    createData('Recruitment Marketing Messages', 'Limited', 'Unlimited'),
];

export default function UpgradeAccountTable({ handleRequestUpgrade }) {
    return (
        <>
            <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                    '& .MuiPaper-root': {
                        boxShadow: 'none',
                    },
                    '& .MuiTableCell-root': { px: 0 },
                }}
            >
                <Table sx={{ width: '100%' }} aria-label="upgrade-account-table">
                    <TableHead>
                        <TableRow sx={{ 'td, th': { border: 0 }, overflow: 'hidden' }}>
                            <TableCell sx={{ width: '180px' }}></TableCell>
                            <TableCell align="center" sx={{ width: '70px' }}>
                                <Typography variant="labelLarge">CURRENT</Typography>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    width: '110px',
                                    backgroundColor: (theme) =>
                                        alpha(theme.palette.success.main, 0.1),
                                    color: 'success.main',
                                }}
                            >
                                <Typography variant="labelLarge">PREMIUM</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>
            <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                    maxHeight: '285px',
                    width: 'calc(100% + 4.5px)',
                    '& .MuiPaper-root': {
                        boxShadow: 'none',
                    },
                    '& .MuiTableCell-root': { px: 0 },
                }}
            >
                <Table>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.featureName}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                }}
                            >
                                <TableCell sx={{ width: '180px' }}>
                                    <Typography variant="bodyBaseBold">
                                        {row.featureName}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center" sx={{ width: '70px' }}>
                                    {row.current}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        width: '110px',
                                        backgroundColor: (theme) =>
                                            alpha(theme.palette.success.main, 0.1),
                                    }}
                                >
                                    <div>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {row.featureName && (
                                                <CheckIcon
                                                    sx={{ color: 'success.main', mr: 0.5 }}
                                                />
                                            )}
                                            <Typography variant="bodyBaseBold">
                                                {row.premium}
                                            </Typography>
                                        </Box>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                    '& .MuiPaper-root': {
                        boxShadow: 'none',
                    },
                    '& .MuiTableCell-root': { px: 0 },
                }}
            >
                <Table sx={{ width: '100%' }}>
                    <TableBody>
                        <TableRow sx={{ 'td, th': { border: 0 } }}>
                            <TableCell sx={{ width: '180px' }}></TableCell>
                            <TableCell align="center" sx={{ width: '70px' }}>
                                <Typography
                                    variant="bodyBaseBold"
                                    sx={{
                                        color: (theme) => alpha(theme.palette.text.primary, 0.72),
                                    }}
                                >
                                    Free
                                </Typography>
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    width: '110px',
                                    backgroundColor: (theme) =>
                                        alpha(theme.palette.success.main, 0.1),
                                }}
                            >
                                <div>
                                    <Typography
                                        variant="bodyBaseBold"
                                        onClick={handleRequestUpgrade}
                                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        View Pricing
                                    </Typography>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

UpgradeAccountTable.propTypes = {
    handleRequestUpgrade: PropTypes.func,
};
