import SvgIcon from '@mui/material/SvgIcon';

export function FilterIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M6.66667 11.6666C7.58714 11.6666 8.33333 10.9204 8.33333 9.99992C8.33333 9.07944 7.58714 8.33325 6.66667 8.33325C5.74619 8.33325 5 9.07944 5 9.99992C5 10.9204 5.74619 11.6666 6.66667 11.6666Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.6665 11.6667V18.3333M6.6665 5V8.33333V5Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6667 16.6666C12.5871 16.6666 13.3333 15.9204 13.3333 14.9999C13.3333 14.0794 12.5871 13.3333 11.6667 13.3333C10.7462 13.3333 10 14.0794 10 14.9999C10 15.9204 10.7462 16.6666 11.6667 16.6666Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6665 16.6667V18.3333M11.6665 5V13.3333V5Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.6667 9.16659C17.5871 9.16659 18.3333 8.42039 18.3333 7.49992C18.3333 6.57944 17.5871 5.83325 16.6667 5.83325C15.7462 5.83325 15 6.57944 15 7.49992C15 8.42039 15.7462 9.16659 16.6667 9.16659Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.6665 9.16667V18.3333M16.6665 5V5.83333V5Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
}
