import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import produce from 'immer';
import { CheckIcon, CloseIcon, StarIcon, WorldIcon } from 'components/Icons';
import { CONTACT_TYPES } from '../utils/constants';
import { useState } from 'react';
import { Box, CircularProgress, Fade, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StarOutlined } from '@mui/icons-material';
import { theme } from 'theme';
import { TEST_IDS } from 'utils/constants';
import { CustomContactCategoryDropdown } from './CustomContactCategoryDropdown';
import { validateEmail } from 'utils/validation/validateEmail';
import { validatePhoneNumber } from 'utils/validation/validatePhoneNumber';
import { validateUrl } from 'utils/validation/url';

const CustomContactCategoryInput = styled(InputBase)(() => ({
    fontSize: theme.typography.fontSize,
    minWidth: 120,
    flex: 'auto',
}));

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export function CustomContactInput({ contactType, contactCategories, onClose, onSave, isLoading }) {
    const defaultFormValue = {
        primary: false,
        option:
            contactCategories.find(({ value }) => value === 'uncategorized') ||
            contactCategories[0] ||
            null,
        textValue: '',
    };

    const [formValues, setFormValues] = useState(defaultFormValue);
    const [errorState, setErrorState] = useState(false);

    const validateValues = (values) => {
        if (contactType === CONTACT_TYPES.EMAIL) {
            return validateEmail(values.textValue);
        } else if (contactType === CONTACT_TYPES.PHONE) {
            return validatePhoneNumber(values.textValue);
        } else if (contactType === CONTACT_TYPES.SOCIAL) {
            return validateUrl(values.textValue);
        }
    };

    const resetFormValues = () => {
        setFormValues(defaultFormValue);
    };

    const togglePrimaryStatus = () => {
        if (isLoading) return;
        setFormValues((state) =>
            produce(state, (draft) => {
                draft.primary = !draft.primary;
            })
        );
    };
    const socialContact = contactType === CONTACT_TYPES.SOCIAL;

    let inputPlaceHolder = '';
    if (contactType === CONTACT_TYPES.EMAIL) inputPlaceHolder = 'first.last@domainname.com';
    else if (contactType === CONTACT_TYPES.PHONE) inputPlaceHolder = 'ex. 555-239-3924';
    else if (contactType === CONTACT_TYPES.SOCIAL) inputPlaceHolder = 'Enter URL';

    const getErrorMessage = () => {
        if (contactType === CONTACT_TYPES.EMAIL) return 'Please enter a valid email';
        else if (contactType === CONTACT_TYPES.PHONE) return 'Please enter a valid phone number';
        else if (contactType === CONTACT_TYPES.SOCIAL) return 'Please enter a valid URL';
    };

    return (
        <Box
            sx={{
                position: 'relative',
            }}
        >
            <Fade in={errorState} timeout={500}>
                <Typography
                    data-testid="erorr-message"
                    sx={{ fontSize: '10px', color: 'red', position: 'absolute', top: '-5px' }}
                >
                    {getErrorMessage()}
                </Typography>
            </Fade>

            <FlexBox
                sx={{
                    justifyContent: 'space-between',
                    height: 40,
                    gap: 1,
                }}
            >
                {socialContact && (
                    <WorldIcon sx={{ fontSize: '18px', color: theme.palette.primary.light }} />
                )}
                <FlexBox
                    sx={{
                        flex: 1,
                        justifyContent: 'flex-start',
                        borderBottom: errorState
                            ? `1px solid ${theme.palette.error.light}`
                            : `1px solid ${theme.palette.primary.light}`,
                    }}
                >
                    <CustomContactCategoryInput
                        data-testid="custom-contact-input"
                        placeholder={inputPlaceHolder}
                        value={formValues.textValue}
                        onChange={(e) => {
                            if (!e.target.value) setErrorState(false);
                            if (isLoading) return;
                            setFormValues((state) =>
                                produce(state, (draft) => {
                                    draft.textValue = e.target.value;
                                })
                            );
                        }}
                    />
                    {!socialContact && (
                        <CustomContactCategoryDropdown
                            label="Category"
                            options={contactCategories}
                            selectedOption={formValues.option}
                            onSelectedOptionChange={(option) => {
                                if (isLoading) return;
                                setFormValues((state) =>
                                    produce(state, (draft) => {
                                        draft.option = option;
                                    })
                                );
                            }}
                        />
                    )}
                    {!socialContact && (
                        <FlexBox>
                            {formValues.primary ? (
                                <StarOutlined
                                    color="primary"
                                    fontSize="medium"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={togglePrimaryStatus}
                                />
                            ) : (
                                <StarIcon
                                    data-testid={TEST_IDS.STAR_ICON}
                                    color="primary"
                                    fontSize="medium"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={togglePrimaryStatus}
                                />
                            )}
                        </FlexBox>
                    )}
                </FlexBox>
                <FlexBox sx={{ width: 70, justifyContent: 'flex-end', gap: 0.5 }}>
                    {!isLoading ? (
                        <CheckIcon
                            color="primary"
                            data-testid="check-icon"
                            sx={{ cursor: 'pointer' }}
                            fontSize="medium"
                            onClick={() => {
                                if (isLoading) return;
                                if (!validateValues(formValues)) {
                                    setErrorState(true);
                                    return;
                                }
                                setErrorState(false);
                                onSave(formValues);
                            }}
                        />
                    ) : (
                        <CircularProgress
                            data-testid={TEST_IDS.PROGRESS_ICON}
                            size={16}
                            color="primary"
                            thickness={2}
                        />
                    )}
                    <CloseIcon
                        data-testid="close-icon"
                        sx={{ cursor: isLoading ? 'default' : 'pointer' }}
                        fontSize="small"
                        color={theme.palette.action.hover}
                        onClick={() => {
                            if (isLoading) return;
                            resetFormValues();
                            onClose?.();
                            setErrorState(false);
                        }}
                    />
                </FlexBox>
            </FlexBox>
        </Box>
    );
}

CustomContactInput.propTypes = {
    contactType: PropTypes.oneOf(Object.values(CONTACT_TYPES)),
    contactCategories: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    isLoading: PropTypes.bool,
};
