import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';

import { Typography } from 'components/Typography';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';

const CollapseButton = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
}));

const ExpandButtonBackground = styled(Box)(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: theme.spacing(20),
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 85%)',
    pointerEvents: 'none',
    zIndex: 1,
}));

const StyledButton = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.75),
    pointerEvents: 'initial',
}));

export function ExpandButton(props) {
    const { displayExpandButton, isCollapsable, collapsed, onCollapse, onExpand } = props;

    if (displayExpandButton && isCollapsable && !collapsed) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CollapseButton aria-label="collapse-card" onClick={onCollapse} disableRipple>
                    <Typography variant="labelSmall" color="textPrimary">
                        Collapse
                    </Typography>
                    <ChevronUpIcon color="primary" />
                </CollapseButton>
            </Box>
        );
    }

    if (displayExpandButton && collapsed) {
        return (
            <ExpandButtonBackground>
                <StyledButton aria-label="expand-card" onClick={onExpand} disableRipple>
                    <Typography variant="labelSmall" color="textPrimary">
                        Expand
                    </Typography>
                    <ChevronDownIcon color="primary" />
                </StyledButton>
            </ExpandButtonBackground>
        );
    }

    return null;
}

ExpandButton.propTypes = {
    displayExpandButton: PropTypes.bool,
    isCollapsable: PropTypes.bool,
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
    onExpand: PropTypes.func,
};
