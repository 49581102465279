import SvgIcon from '@mui/material/SvgIcon';

export function FilledStatusIndicator(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <rect y="1" width="12" height="12" rx="6" fill="currentColor" />
            <path
                d="M9.35497 4.60493C9.30849 4.55807 9.25319 4.52087 9.19226 4.49548C9.13133 4.4701 9.06598 4.45703 8.99997 4.45703C8.93397 4.45703 8.86862 4.4701 8.80769 4.49548C8.74676 4.52087 8.69146 4.55807 8.64498 4.60493L4.91998 8.33493L3.35497 6.76493C3.30671 6.71831 3.24974 6.68165 3.18732 6.65705C3.12489 6.63245 3.05823 6.62039 2.99114 6.62155C2.92405 6.62271 2.85784 6.63707 2.7963 6.66382C2.73476 6.69056 2.67909 6.72917 2.63247 6.77743C2.58586 6.82569 2.5492 6.88266 2.5246 6.94509C2.49999 7.00752 2.48793 7.07418 2.48909 7.14127C2.49025 7.20836 2.50461 7.27456 2.53136 7.3361C2.55811 7.39764 2.59671 7.45331 2.64497 7.49993L4.56498 9.41993C4.61146 9.46679 4.66676 9.50399 4.72769 9.52938C4.78862 9.55476 4.85397 9.56783 4.91998 9.56783C4.98598 9.56783 5.05133 9.55476 5.11226 9.52938C5.17319 9.50399 5.22849 9.46679 5.27498 9.41993L9.35497 5.33993C9.40573 5.29311 9.44623 5.23628 9.47393 5.17303C9.50164 5.10978 9.51594 5.04148 9.51594 4.97243C9.51594 4.90338 9.50164 4.83508 9.47393 4.77183C9.44623 4.70858 9.40573 4.65175 9.35497 4.60493Z"
                fill="white"
            />
        </SvgIcon>
    );
}
