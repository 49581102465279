import { MESSAGE_SOURCES } from 'views/Communications/utils/constants';

export const ALL_MESSAGE_SOURCES = [
    MESSAGE_SOURCES.DIRECT,
    MESSAGE_SOURCES.SEQUENCE,
    MESSAGE_SOURCES.TALKTRACK,
];

export const buildSendMethodFilter = (messageChannels, includeDefault) => {
    const { DIRECT, SEQUENCE, BOT } = messageChannels;
    let sendMethod = [];
    if (includeDefault) {
        sendMethod.push('default');
    }
    if (DIRECT) {
        sendMethod.push(MESSAGE_SOURCES.DIRECT);
    }
    if (SEQUENCE) {
        sendMethod.push(MESSAGE_SOURCES.SEQUENCE);
    }
    if (BOT) {
        sendMethod.push(MESSAGE_SOURCES.TALKTRACK);
    }
    return sendMethod.join(',');
};

export const buildMessageTypeFilter = (messageTypes) => {
    const { SMS, EMAIL } = messageTypes;
    if (!SMS && !EMAIL) {
        return '';
    }
    if (!SMS && EMAIL) {
        return 'email';
    }
    if (!EMAIL && SMS) {
        return 'sms';
    }
};
