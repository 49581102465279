import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { useState, useCallback, useEffect, forwardRef, useMemo, useRef } from 'react';

import {
    AutocompleteRoot,
    StyledAutocomplete,
} from 'views/Search/features/FiltersBar/components/styledComponents';
import { parseAddressComponents } from './utils';
import { Typography } from 'components/Typography';
import { TextInput } from 'components/Inputs/TextInput';
import { getOptionLabel, getValues } from 'components/Inputs/MultiTextInput/utils';

const autocompleteService = { current: null };
const geocoderService = { current: null };

export const LocationAutocomplete = forwardRef((props, ref) => {
    const {
        sx,
        id,
        name,
        inlineTags,
        onChange,
        value,
        label,
        placeholder,
        error,
        TextInputProps,
        PopupIcon,
        disableTags,
        setDropDownVisibility,
        shouldResetInputValue,
        shouldShowState,
        ...restProps
    } = props;

    const disabled = props.disabled || props.InputProps?.disabled;

    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const serviceLoadingRef = useRef(false);

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        []
    );

    useEffect(() => {
        async function initServices() {
            serviceLoadingRef.current = true;
            const { AutocompleteService } = await window.google.maps.importLibrary('places');
            const { Geocoder } = window.google.maps;
            autocompleteService.current = new AutocompleteService();
            geocoderService.current = new Geocoder();
            serviceLoadingRef.current = false;
        }

        if (!autocompleteService.current && !serviceLoadingRef.current) {
            initServices();
        }
    }, []);

    useEffect(() => {
        const typesArray = ['locality', 'postal_code'];
        if (shouldShowState) {
            typesArray.push('administrative_area_level_1');
        }
        let active = true;

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? value : []);
            return undefined;
        }

        fetch(
            {
                input: inputValue,
                // https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
                componentRestrictions: { country: ['us', 'ca', 'pr', 'vi'] },
                types: typesArray,
            },
            (results) => {
                if (active) {
                    let newOptions = [];

                    if (value.length) {
                        newOptions = [...value];
                    }

                    if (results) {
                        newOptions = [
                            ...newOptions,
                            ...results.map((o) => ({
                                ...o,
                                label: o.description,
                                value: o.description,
                            })),
                        ];
                    }

                    setOptions(newOptions);
                }
            }
        );

        return () => {
            active = false;
        };
    }, [inputValue, value, fetch, shouldShowState]);

    const onChangeEvent = useCallback(
        (values) => ({
            target: {
                id,
                name,
                value: values,
            },
        }),
        [name, id]
    );

    const handleUpdate = useCallback(
        (newValues, location) => {
            onChange(onChangeEvent(newValues), location);
        },
        [onChange, onChangeEvent]
    );

    const handleChange = (_event, newValue) => {
        if (newValue[0].place_id) {
            geocoderService.current
                .geocode({ placeId: newValue[0].place_id })
                .then(({ results }) => {
                    if (results.length) {
                        const { city, state } = parseAddressComponents(
                            results[0].address_components
                        );
                        const lat = results[0].geometry.location.lat();
                        const lng = results[0].geometry.location.lng();
                        const isState = results[0].types?.includes('administrative_area_level_1');

                        handleUpdate(getValues(newValue, value), {
                            placeId: `${newValue[0].place_id}`,
                            state,
                            city,
                            lat,
                            lng,
                            isState,
                        });
                    }
                });
        }
    };

    const onInputChange = useCallback(
        (event, newInputValue) => {
            if (newInputValue.length === 0) {
                if (open) setOpen(false);
            } else {
                if (!open) setOpen(true);
            }
            if (!shouldResetInputValue) {
                setDropDownVisibility(false);
            }
            setInputValue(newInputValue);
        },
        [open, setDropDownVisibility, shouldResetInputValue]
    );

    const getInputProps = ({ InputProps }) => {
        return {
            ...InputProps,
            ...TextInputProps?.InputProps,
            endAdornment: TextInputProps?.InputProps?.endAdornment || InputProps.endAdornment,
        };
    };

    const InputLabelProps = TextInputProps?.InputLabelProps;

    return (
        <AutocompleteRoot sx={sx}>
            <StyledAutocomplete
                ref={ref}
                multiple
                disablePortal
                autoHighlight
                clearOnEscape
                id={id}
                aria-label="location-filter-autocomplete"
                disabled={disabled}
                disableClearable={disabled}
                options={options}
                value={value}
                open={open}
                onClose={() => setOpen(false)}
                onChange={handleChange}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={(option, val) => val === option.value}
                onInputChange={onInputChange}
                noOptionsText={
                    <Typography variant="labelLarge" {...InputLabelProps}>
                        No options
                    </Typography>
                }
                renderOption={(_props, option) => {
                    return (
                        <li {..._props} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="titleSmall" color="textPrimary">
                                {option.inputLabel || option.label}
                            </Typography>
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextInput
                        {...params}
                        label={label}
                        autoComplete="off"
                        error={error}
                        placeholder={placeholder || 'Enter a US city, state, or zip'}
                        disabled={disabled}
                        {...TextInputProps}
                        InputProps={getInputProps(params)}
                        inputProps={{
                            ...params.inputProps,
                            'data-trackid': 'location-filters-drawer-input',
                        }}
                    />
                )}
                {...restProps}
            />
        </AutocompleteRoot>
    );
});

LocationAutocomplete.propTypes = {
    sx: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.node,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    className: PropTypes.string,
    inlineTags: PropTypes.number,
    disableTags: PropTypes.bool,
    PopupIcon: PropTypes.any,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    InputProps: PropTypes.object,
    TextInputProps: PropTypes.object,
    setDropDownVisibility: PropTypes.func,
    shouldResetInputValue: PropTypes.bool,
    shouldShowState: PropTypes.bool,
};
