import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { ToastContainer as ToastifyContainer } from 'react-toastify';

import { theme } from 'theme';
import { CloseIcon } from 'components/Icons';
import { TYPOGRAPHY_VARIANTS } from 'components/Typography';

const StyledContainer = styled(ToastifyContainer)(() => ({
    // theme can not be accessed from props here because react-toastify injects it's own theme prop
    '.Toastify__toast': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background.sidebar,
        '.close-button': {
            color: theme.palette.common.white,
        },
    },
    '.Toastify__toast-body': {
        ...TYPOGRAPHY_VARIANTS.labelSmall,
        lineHeight: 'initial',
    },
    '.Toastify__toast--info': {
        backgroundColor: theme.palette.primary.main,
    },
    '.Toastify__toast--success': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
        '.close-button': {
            color: theme.palette.common.white,
        },
    },
    '.Toastify__toast--warning': {
        backgroundColor: theme.palette.warning.main,
    },
    '.Toastify__toast--error': {
        backgroundColor: theme.palette.error.main,
    },
}));

function CloseButton({ closeToast }) {
    return (
        <IconButton
            className="close-button"
            aria-label="dismiss-notification"
            sx={{ alignSelf: 'flex-start' }}
            onClick={closeToast}
            size="small"
            disableRipple
        >
            <CloseIcon fontSize="small" />
        </IconButton>
    );
}

export function ToastContainer() {
    return (
        <StyledContainer
            icon={false}
            position="bottom-right"
            closeButton={CloseButton}
            pauseOnFocusLoss
            pauseOnHover
        />
    );
}

CloseButton.propTypes = {
    closeToast: PropTypes.func,
};
