import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiCheckbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from '@mui/material/styles';
import { forwardRef, useCallback, useEffect } from 'react';

import { highlightMatch } from './utils';
import { Typography } from 'components/Typography';
import { TextInput } from 'components/Inputs/TextInput';
import { CheckIcon, ChevronDownIcon, ExcludeIcon } from 'components/Icons';
import {
    AutocompleteRoot,
    StyledAutocomplete,
} from 'views/Search/features/FiltersBar/components/styledComponents';

const StyledChevronDownIcon = styled(ChevronDownIcon, {
    shouldForwardProp: (prop) => prop !== 'isExpanded',
})(({ theme, isExpanded }) => ({
    fontSize: 12,
    color: theme.palette.primary.main,
    transform: `rotate(${isExpanded ? 180 : 0}deg)`,
    transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
    }),
}));

const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
    padding: 0,
    marginRight: 3,
    marginTop: 4,
    transition: theme.transitions.create(['color'], {
        duration: theme.transitions.duration.shortest,
    }),
    color: alpha(theme.palette.common.white, 0.48),
    '&.Mui-checked': {
        color: theme.palette.success.main,
    },
    '&.MuiCheckbox-indeterminate': {
        color: theme.palette.common.white,
    },
    '& svg': {
        width: 14,
        height: 14,
    },
}));

const StyledExcludeIcon = styled(ExcludeIcon)(({ theme }) => ({
    padding: 0,
    marginRight: 3,
    marginTop: 4,
    fontSize: '12px',
    color: theme.palette.error.main,
}));

const StyledListItem = styled('li')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:hover) .toggleExclude': {
        display: 'none',
    },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: 0,
    paddingLeft: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: alpha(theme.palette.common.white, 0.48),
}));

export const OccupationsAndIndustriesAutocomplete = forwardRef((props, ref) => {
    const {
        sx,
        id,
        value,
        label,
        error,
        TextInputProps,
        onChange,
        expanded,
        toggleExpand,
        selected,
        excluded,
        toggleSelect,
        toggleExclude,
        setExpanded,
        filterOptions,
        inputValue,
        onInputChange,
        filterData,
        includeCodeInLabel,
        ...restProps
    } = props;

    const getStatus = useCallback(
        (option, optionsList) => {
            const isCodeSelected = (code) => optionsList.includes(code);

            if (isCodeSelected(option.code)) {
                return true;
            }
            const parent = filterData.find((item) => item.code === option.parentCode);
            if (parent) {
                return getStatus(parent, optionsList);
            }
            return false;
        },
        [filterData]
    );

    const isIndeterminate = useCallback(
        (option) => {
            if (!option.hasChildren) {
                return false;
            }
            const children = filterData.filter((item) => item.parentCode === option.code);

            const someSelected = children.some((child) => selected.includes(child.code));
            const allSelected = children.every((child) => selected.includes(child.code));

            if (someSelected && !allSelected) {
                return true;
            }
            return children.some((child) => isIndeterminate(child));
        },
        [filterData, selected]
    );

    const renderOption = useCallback(
        (props, option) => {
            const isExpandable = option.hasChildren;
            const isVisible = !option.parentCode || expanded[option.parentCode];
            const isExcludedOption = getStatus(option, excluded);
            const isIncludedOption = getStatus(option, selected);
            const isIndeterminateOption = isIndeterminate(option);

            if (!isVisible) return null;

            return (
                <StyledListItem
                    {...props}
                    style={{
                        paddingLeft: option.level > 0 ? (2 + option.level) * 8 : 8,
                    }}
                    key={option.code}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            width: '90%',
                        }}
                    >
                        {isExpandable && (
                            <IconButton
                                aria-label={
                                    expanded[option.code]
                                        ? `collapse-${option.name}`
                                        : `expand-${option.name}`
                                }
                                size="small"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    toggleExpand(option.code);
                                }}
                                disableRipple
                            >
                                {expanded[option.code] ? (
                                    <StyledChevronDownIcon isExpanded />
                                ) : (
                                    <StyledChevronDownIcon />
                                )}
                            </IconButton>
                        )}
                        {isExcludedOption && (
                            <StyledExcludeIcon sx={{ paddingLeft: option.hasChildren ? 0 : 3 }} />
                        )}

                        {!isExcludedOption && (
                            <Checkbox
                                inputProps={{ 'aria-label': `${option.name}-checkbox` }}
                                disableRipple
                                checked={isIncludedOption}
                                sx={{ paddingLeft: option.hasChildren ? 0 : 3 }}
                                indeterminate={isIndeterminateOption}
                                onChange={(event) => {
                                    let isChecked = event.target.checked;
                                    toggleSelect(option, isChecked);
                                    if (isChecked) {
                                        toggleExclude(option, false);
                                    }
                                }}
                            />
                        )}
                        <Typography
                            variant={
                                isIncludedOption || isIndeterminateOption || isExcludedOption
                                    ? 'bodyBaseBold'
                                    : 'body'
                            }
                        >
                            {highlightMatch(
                                includeCodeInLabel
                                    ? `${option.code} - ${option.name}`
                                    : option.name,
                                inputValue
                            )}
                        </Typography>
                    </Box>
                    <Box>
                        {isExcludedOption && (
                            <StyledIconButton
                                aria-label={`include-${option.name}`}
                                sx={{
                                    p: 0,
                                    pl: 0.5,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={() => {
                                    toggleExclude(option, false);
                                    toggleSelect(option, true);
                                }}
                                disableRipple
                            >
                                <CheckIcon
                                    className="toggleExclude"
                                    sx={{ fontSize: '20.5px', m: -1.25 }}
                                />
                            </StyledIconButton>
                        )}
                        {!isExcludedOption && (
                            <StyledIconButton
                                aria-label={`exclude-${option.name}`}
                                onClick={() => {
                                    toggleExclude(option, true);
                                    toggleSelect(option, false);
                                }}
                                disableRipple
                            >
                                <ExcludeIcon className="toggleExclude" sx={{ fontSize: '12px' }} />
                            </StyledIconButton>
                        )}
                    </Box>
                </StyledListItem>
            );
        },
        [
            excluded,
            expanded,
            getStatus,
            inputValue,
            isIndeterminate,
            selected,
            toggleExclude,
            toggleExpand,
            toggleSelect,
            includeCodeInLabel,
        ]
    );

    const getInputProps = ({ InputProps }) => {
        return {
            ...InputProps,
            ...TextInputProps?.InputProps,
            endAdornment: TextInputProps?.InputProps?.endAdornment || InputProps.endAdornment,
        };
    };

    useEffect(() => {
        const initStates = (defaultValue) =>
            filterData.reduce((acc, item) => {
                acc[item.code] = defaultValue;
                return acc;
            }, {});

        setExpanded(initStates(false));
    }, [filterData, setExpanded]);

    return (
        <AutocompleteRoot sx={sx}>
            <StyledAutocomplete
                ref={ref}
                renderTags={() => null}
                disablePortal
                autoHighlight
                clearOnEscape
                disableCloseOnSelect
                disableListWrap
                onClose={() => {
                    if (!selected.length) {
                        setExpanded({});
                    }
                }}
                id={id}
                options={filterData}
                value={value}
                inputValue={inputValue}
                onInputChange={onInputChange}
                getOptionLabel={(option) => option.name}
                filterOptions={filterOptions}
                ListboxProps={{ sx: { maxHeight: 232 } }}
                renderOption={renderOption}
                renderInput={(params) => (
                    <TextInput
                        {...params}
                        label={label}
                        autoComplete="off"
                        error={error}
                        placeholder="Type any value..."
                        {...TextInputProps}
                        InputProps={getInputProps(params)}
                    />
                )}
                {...restProps}
            />
        </AutocompleteRoot>
    );
});

OccupationsAndIndustriesAutocomplete.propTypes = {
    sx: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.node,
    value: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.bool,
    InputProps: PropTypes.object,
    TextInputProps: PropTypes.object,
    onChange: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    expanded: PropTypes.object,
    toggleExpand: PropTypes.func,
    selected: PropTypes.array,
    excluded: PropTypes.array,
    toggleSelect: PropTypes.func,
    toggleExclude: PropTypes.func,
    setExpanded: PropTypes.func,
    filterOptions: PropTypes.func,
    inputValue: PropTypes.string,
    onInputChange: PropTypes.func,
    filterData: PropTypes.array,
    includeCodeInLabel: PropTypes.bool,
};
