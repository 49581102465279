import { useEffect, useState } from 'react';

import { useGetMessageTemplatesQuery } from 'services/conversations';
import { TEMPLATE_VIEWS, MAX_USER_TEMPLATES } from '../utils/constants';

// Handles template button ctas
// Perhaps this should also handle CTA option render TBD
export function useTemplateButton() {
    const [messageTemplateOpen, setMessageTemplateOpen] = useState(false);
    const [isCreateTemplateDisabled, setIsCreateTemplateDisabled] = useState(false);
    const [currentTemplateView, setCurrentTemplateView] = useState(TEMPLATE_VIEWS.CREATE);

    const result = useGetMessageTemplatesQuery();
    const { data, isFetching: isFetchingTemplates } = result || {};

    const handleOpenTemplate = () => {
        setMessageTemplateOpen(true);
    };

    const handleCloseTemplate = () => {
        setMessageTemplateOpen(false);
    };

    const handleTemplateListsView = () => {
        setCurrentTemplateView(TEMPLATE_VIEWS.LISTS);
    };

    const handleTemplateCreateView = () => {
        setCurrentTemplateView(TEMPLATE_VIEWS.CREATE);
    };

    useEffect(() => {
        if (data?.length === MAX_USER_TEMPLATES) {
            setIsCreateTemplateDisabled(true);
        } else {
            setIsCreateTemplateDisabled(false);
        }
    }, [data]);

    return {
        messageTemplateOpen,
        isFetchingTemplates,
        isCreateTemplateDisabled,
        handleOpenTemplate,
        handleCloseTemplate,
        templates: data,
        handleTemplateListsView,
        handleTemplateCreateView,
        currentTemplateView,
    };
}
