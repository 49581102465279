import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import MuiButton from '@mui/material/Button';
import { useKeycloak } from '@react-keycloak/web';

import { useImpersonateStore } from './store';
import { jwtDecode } from './impersonateUtils';
import { KEYCLOAK_ROLES } from 'utils/constants';
import { setUser } from 'features/Authentication';
import { useGetImpersonationTokenMutation, useLazyGetSignedInUserQuery } from 'services/users';
import { useClearStateAndCache } from './hooks';

const Button = styled(MuiButton)(({ theme }) => ({
    color: theme.palette.common.white,
    textTransform: 'unset',
    padding: theme.spacing(1.5),
    height: theme.spacing(3.5),
    minWidth: 0,
}));

export function ImpersonateUser(props) {
    const { user } = props;
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();
    const [getSignedInUser] = useLazyGetSignedInUserQuery();

    const [getToken] = useGetImpersonationTokenMutation();

    const setIsImpersonating = useImpersonateStore((state) => state.setIsImpersonating);

    const resetAllStateAndCache = useClearStateAndCache();

    const handleClick = async () => {
        const { data, error } = await getToken(user.id);

        const newAccessToken = data?.accessToken;
        const newRefreshToken = data?.refreshToken;

        if (!error && newAccessToken && newRefreshToken) {
            keycloak.token = newAccessToken;
            keycloak.tokenParsed = jwtDecode(newAccessToken);
            keycloak.sessionId = keycloak.tokenParsed.sid;
            keycloak.authenticated = true;
            keycloak.subject = keycloak.tokenParsed.sub;
            keycloak.realmAccess = keycloak.tokenParsed.realm_access;
            keycloak.resourceAccess = keycloak.tokenParsed.resource_access;
            keycloak.refreshToken = newRefreshToken;
            keycloak.refreshTokenParsed = jwtDecode(newRefreshToken);
            keycloak.idToken = newAccessToken;
            delete keycloak.idToken;
            delete keycloak.idTokenParsed;

            resetAllStateAndCache();

            const userProfile = await keycloak.loadUserProfile();
            const { data: userData } = await getSignedInUser();

            dispatch(
                setUser({
                    isSalesManager: keycloak.hasRealmRole(KEYCLOAK_ROLES.SALES_MANAGER),
                    isOrganizationAdmin: keycloak.hasRealmRole(KEYCLOAK_ROLES.ORGANIZATION_ADMIN),
                    isNovaToolsAdmin: keycloak.hasRealmRole(KEYCLOAK_ROLES.NOVA_TOOLS_ADMIN),
                    isNovaQuicklaunchDev: keycloak.hasRealmRole(
                        KEYCLOAK_ROLES.NOVA_QUICKLAUNCH_DEV
                    ),
                    isNovaQuicklaunchAdmin: keycloak.hasRealmRole(
                        KEYCLOAK_ROLES.NOVA_QUICKLAUNCH_ADMIN
                    ),
                    displayName: `${userProfile.firstName} ${userProfile.lastName}`,
                    ...userProfile,
                    ...userData,
                })
            );
            setIsImpersonating();
        } else {
            toast.error('Failed to impersonate user.');
        }
    };

    return (
        <Button onClick={handleClick} variant="contained" color="success">
            Sign In As This User
        </Button>
    );
}

ImpersonateUser.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string,
    }),
};
