import SvgIcon from '@mui/material/SvgIcon';

export function ArrowDownIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M28.8157 18.5741C28.1923 17.9507 27.1853 17.9507 26.562 18.5741L18.1064 27.0616V1.91871C18.1064 1.03959 17.4031 0.320312 16.508 0.320312C15.6129 0.320312 14.9096 1.03959 14.9096 1.91871V27.0616L6.43808 18.5741C5.8147 17.9507 4.80771 17.9507 4.18433 18.5741C3.56095 19.1974 3.56095 20.2044 4.18433 20.8278L14.9735 31.649C15.2133 31.8887 15.517 32.0486 15.8367 32.1605C16.0445 32.2564 16.2682 32.3203 16.524 32.3203C16.7797 32.3203 16.9875 32.2564 17.1953 32.1605C17.515 32.0486 17.8187 31.9047 18.0585 31.649L28.8477 20.8278C29.471 20.2044 29.471 19.1974 28.8477 18.5741H28.8157Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
