import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import { useSwitchboard } from 'features/Switchboard';
import { SendMessageIcon, TimeIcon } from 'components/Icons';
import { useSendMessage } from 'features/MessageDrawer/hooks/useSendMessage';
import { useMessageData } from 'features/MessageDrawer/hooks/useMessageData';
import { useSelector } from 'react-redux';
import { ScheduledMessagePopup } from 'components/Dialog';
import { useRef, useState } from 'react';
import { getContactAccessor } from 'utils/helpers';
import { useTalentData, useMessageDrawer } from 'features/MessageDrawer/hooks';

const SendNowSxProps = {
    py: (theme) => theme.spacing(1),
    m: (theme) => theme.spacing(0, 1, 0, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
};

const ScheduleIconSxProps = {
    p: (theme) => theme.spacing(1, 1, 1, 0),
    m: (theme) => theme.spacing(0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flex: 1,
};

const hasAvailableContactForMessageType = (selectedContacts, accessor) => {
    return Object.entries(selectedContacts).some(([_, contactData]) =>
        Boolean(contactData[accessor].data.length)
    );
};

export function SendMessageButton(props) {
    const { isEditing } = props;
    const anchor = useRef();
    const [showCalendar, setShowCalendar] = useState(false);

    const isScheduleFeatureEnabled = useSwitchboard('FEATURE_SCHEDULED_MESSAGES');
    const { selectedContacts } = useTalentData();

    const selectedMessageType = useSelector((state) => state.message.messageType);
    const accessor = getContactAccessor(selectedMessageType);

    const { validationError: bodyError } = useSelector((state) => state.message.body);
    const { validationError: subjectError } = useSelector((state) => state.message.subject);

    const isGeneratingMessage = useSelector((state) => state.message.isGeneratingMessage);

    const { handleClose, showTalentProfile } = useMessageDrawer();

    const sendMessageArgs = {
        onSuccess: () => {
            handleClose({ showTalentProfile, isMinimized: !showTalentProfile });
        },
    };

    const { isSending, handleSend } = useSendMessage(sendMessageArgs);

    const {
        isScheduleModalOpen,
        messageType,
        handleConfirmScheduledDate,
        handleToggleScheduleModal,
        handleCloseScheduleModal,
    } = useMessageData();

    const disabled =
        Boolean(bodyError) ||
        Boolean(subjectError) ||
        isSending ||
        isGeneratingMessage ||
        !hasAvailableContactForMessageType(selectedContacts, accessor);

    return (
        <>
            <Button
                data-trackid="message-send-now"
                role="button"
                variant="contained"
                color="success"
                aria-label="compose-message-send-button"
                disabled={disabled}
                ref={anchor}
                onClick={(e) => {
                    handleSend();
                }}
                sx={{
                    padding: (theme) => theme.spacing(0, 0, 0, 1),
                    color: (theme) => theme.palette.common.white,
                    width: '100%',
                    textTransform: 'none',
                }}
            >
                <Box sx={SendNowSxProps}>
                    {isSending ? (
                        <CircularProgress size={20} sx={{ color: 'gray', mr: 1 }} />
                    ) : (
                        <SendMessageIcon sx={{ mr: 1 }} />
                    )}
                    Send
                </Box>
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ borderColor: 'white' }}
                />
                {!isEditing && isScheduleFeatureEnabled && (
                    <Box
                        sx={ScheduleIconSxProps}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleToggleScheduleModal();
                        }}
                        data-testid="message-schedule-button"
                        data-trackid="message-send-later-options"
                    >
                        <TimeIcon
                            title="Open scheduled message popup"
                            aria-label="Open scheduled message popup"
                            sx={{ ml: 0.75, fontSize: 24 }}
                        />
                    </Box>
                )}
            </Button>
            {isScheduleFeatureEnabled && (
                <ScheduledMessagePopup
                    anchorEl={anchor.current}
                    open={isScheduleModalOpen}
                    onClose={handleCloseScheduleModal}
                    onSend={handleSend}
                    onConfirm={handleConfirmScheduledDate}
                    showCalendar={showCalendar}
                    setShowCalendar={setShowCalendar}
                    messageType={messageType}
                    extendedPopperSxProps={{
                        zIndex: 10000,
                    }}
                    placement="top-end"
                />
            )}
        </>
    );
}

SendMessageButton.propTypes = {
    isEditing: PropTypes.bool,
};
