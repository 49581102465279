import SvgIcon from '@mui/material/SvgIcon';

export function ExpandIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M9.93781 20.3984L4.07117 26.2616V25.2134C4.07117 24.2362 3.18228 23.4189 2.2934 23.4366C1.31562 23.4366 0.515625 24.2362 0.515625 25.2134V30.2415C0.515625 30.7923 0.746736 31.3253 1.12007 31.7162C1.51118 32.1071 2.02673 32.3203 2.56007 32.3203H7.62671C8.60449 32.3203 9.40448 31.5208 9.40448 30.5436C9.40448 29.5664 8.60449 28.7668 7.62671 28.7668H6.59561L12.4445 22.9214C13.1378 22.2285 13.1378 21.1091 12.4445 20.4162C11.7511 19.7232 10.6311 19.7232 9.93781 20.4162V20.3984Z"
                fill="currentColor"
            />
            <path
                d="M32.5156 2.40007C32.5156 1.84928 32.3023 1.31623 31.9112 0.925351C31.5201 0.53447 31.0223 0.303501 30.4534 0.321268H25.4045C24.4268 0.321268 23.6268 1.1208 23.6268 2.098C23.6268 3.07521 24.4268 3.87474 25.4045 3.87474H26.4356L20.5868 9.7202C19.8934 10.4131 19.8934 11.5325 20.5868 12.2254C20.9423 12.563 21.3868 12.7406 21.849 12.7406C22.3112 12.7406 22.7557 12.563 23.1112 12.2254L28.9601 6.37994V7.4282C28.9601 8.40541 29.7601 9.20494 30.7379 9.20494C31.6801 9.18717 32.5156 8.40541 32.5156 7.4282V2.40007Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
