import SvgIcon from '@mui/material/SvgIcon';

export function SaveAltIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M16 5.3511C16 4.47999 15.6448 3.62666 15.0233 3.02222L12.9456 0.959988C12.3241 0.346655 11.5072 0 10.6282 0H4.54605C4.54605 0 4.51942 0 4.51054 0C4.50167 0 4.4839 0 4.47502 0H3.83574C2.80578 0 1.84683 0.408888 1.11875 1.13778C0.390672 1.86667 0 2.83555 0 3.85777L0.0177618 12.16C0.0177618 13.1911 0.426187 14.1511 1.14539 14.88C1.87347 15.6 2.8324 16 3.85349 16H3.86238L11.5339 15.9822C11.5339 15.9822 11.5871 16 11.6138 16C11.6404 16 11.667 15.9822 11.6937 15.9822H12.1643C14.2775 15.9822 16 14.2489 16 12.1245L15.9822 5.34223L16 5.3511ZM10.6282 1.77778C10.6282 1.77778 10.6992 1.77779 10.7347 1.78668V4.67556C10.7347 5.03112 10.4506 5.32444 10.0954 5.32444H6.03774C5.68258 5.32444 5.39845 5.03112 5.39845 4.67556V1.77778H10.6193H10.6282ZM10.7436 14.2045L5.40732 14.2222V11.3156C5.40732 10.9511 5.69145 10.6578 6.04661 10.6578H10.1043C10.4595 10.6578 10.7436 10.9511 10.7436 11.3156V14.2133V14.2045ZM12.5194 14.1689V11.3156C12.5194 9.97334 11.4362 8.87999 10.1043 8.87999H6.04661C4.71476 8.87999 3.63152 9.97334 3.63152 11.3156V14.2045C3.17869 14.1511 2.7525 13.9555 2.41509 13.6267C2.02442 13.2355 1.81131 12.72 1.81131 12.1689L1.79357 3.86667C1.79357 3.31556 2.00666 2.79112 2.39733 2.40001C2.73474 2.06223 3.16982 1.86667 3.6404 1.81334V4.68443C3.6404 6.02666 4.72365 7.11111 6.0555 7.11111H10.1132C11.4451 7.11111 12.5283 6.01777 12.5283 4.68443V3.03111L13.7802 4.27555C14.0733 4.56 14.2242 4.94221 14.2242 5.3511L14.2419 12.1333C14.2419 13.1556 13.4961 14 12.5283 14.1689H12.5194Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
