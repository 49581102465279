import SvgIcon from '@mui/material/SvgIcon';

export function NotesCalendarIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M12.2104 1.68421V0.842105C12.2104 0.378947 11.8314 0 11.3683 0C10.9051 0 10.5262 0.378947 10.5262 0.842105V1.68421H5.47353V0.842105C5.47353 0.378947 5.09458 0 4.63142 0C4.16827 0 3.78932 0.378947 3.78932 0.842105V1.68421C1.92827 1.68421 0.420898 3.19158 0.420898 5.05263V12.6316C0.420898 14.4926 1.92827 16 3.78932 16H12.2104C14.0714 16 15.5788 14.4926 15.5788 12.6316V5.05263C15.5788 3.19158 14.0714 1.68421 12.2104 1.68421ZM13.8946 12.6316C13.8946 13.5579 13.1367 14.3158 12.2104 14.3158H3.78932C2.863 14.3158 2.10511 13.5579 2.10511 12.6316V5.05263C2.10511 4.12632 2.863 3.36842 3.78932 3.36842V4.21053C3.78932 4.67368 4.16827 5.05263 4.63142 5.05263C5.09458 5.05263 5.47353 4.67368 5.47353 4.21053V3.36842H10.5262V4.21053C10.5262 4.67368 10.9051 5.05263 11.3683 5.05263C11.8314 5.05263 12.2104 4.67368 12.2104 4.21053V3.36842C13.1367 3.36842 13.8946 4.12632 13.8946 5.05263V12.6316Z"
                fill="currentColor"
            />
            <path
                d="M6.07143 7.83165C5.75985 7.52007 5.18721 7.52007 4.87563 7.83165C4.79984 7.90744 4.7409 8.00008 4.69879 8.10113C4.65669 8.2106 4.63142 8.32006 4.63142 8.42954C4.63142 8.53901 4.65669 8.64849 4.69879 8.74954C4.7409 8.85059 4.79984 8.94323 4.87563 9.01902C5.03563 9.17902 5.25458 9.27164 5.47353 9.27164C5.583 9.27164 5.69248 9.24639 5.79353 9.20428C5.89459 9.16218 5.98722 9.10323 6.07143 9.01902C6.14722 8.94323 6.20616 8.85059 6.24827 8.74954C6.29879 8.64849 6.31563 8.53901 6.31563 8.42954C6.31563 8.32006 6.29879 8.2106 6.24827 8.10113C6.20616 8.00008 6.14722 7.90744 6.07143 7.83165Z"
                fill="currentColor"
            />
            <path
                d="M6.07143 10.3578C6.02932 10.3157 5.98721 10.2821 5.94511 10.2568C5.89458 10.2231 5.84406 10.1978 5.79353 10.1726C5.74301 10.1557 5.69247 10.1389 5.64195 10.1305C5.53247 10.1052 5.41458 10.1052 5.31353 10.1305C5.25458 10.1305 5.20405 10.1557 5.15353 10.1726C5.103 10.1978 5.05247 10.2231 5.01037 10.2568C4.95984 10.2821 4.91773 10.3157 4.87563 10.3578C4.79984 10.4336 4.7409 10.5263 4.69879 10.6273C4.65669 10.7368 4.63142 10.8378 4.63142 10.9557C4.63142 11.1831 4.72405 11.3936 4.87563 11.5452C5.03563 11.7052 5.25458 11.7978 5.47353 11.7978C5.69248 11.7978 5.91143 11.7052 6.07143 11.5452C6.23143 11.3936 6.31563 11.1831 6.31563 10.9557C6.31563 10.8378 6.29037 10.7368 6.24827 10.6273C6.20616 10.5263 6.14722 10.4336 6.07143 10.3578Z"
                fill="currentColor"
            />
            <path
                d="M8.59775 7.8318C8.55564 7.7897 8.51353 7.75601 8.47142 7.72233C8.4209 7.69706 8.37038 7.6718 8.31985 7.64654C8.26932 7.6297 8.21879 7.61286 8.16826 7.60444C7.99984 7.57076 7.83142 7.58759 7.67984 7.64654C7.57879 7.68864 7.48616 7.75601 7.40195 7.8318C7.32616 7.90759 7.26722 8.00023 7.22511 8.10128C7.18301 8.21076 7.15774 8.32022 7.15774 8.42969C7.15774 8.53916 7.18301 8.64864 7.22511 8.74969C7.26722 8.85075 7.32616 8.94339 7.40195 9.01917C7.48616 9.10338 7.57879 9.16233 7.67984 9.20444C7.78089 9.24654 7.89037 9.2718 7.99985 9.2718C8.10932 9.2718 8.2188 9.24654 8.31985 9.20444C8.4209 9.16233 8.51354 9.10338 8.59775 9.01917C8.67354 8.94339 8.73248 8.85075 8.77458 8.74969C8.82511 8.64864 8.84195 8.53916 8.84195 8.42969C8.84195 8.32022 8.82511 8.21076 8.77458 8.10128C8.73248 8.00023 8.67354 7.90759 8.59775 7.8318Z"
                fill="currentColor"
            />
            <path
                d="M8.59775 10.3579C8.52196 10.2821 8.42932 10.2147 8.31985 10.1726C8.11774 10.0884 7.88195 10.0884 7.67984 10.1726C7.57879 10.2147 7.48616 10.2821 7.40195 10.3579C7.32616 10.4337 7.26722 10.5263 7.22511 10.6274C7.18301 10.7369 7.15774 10.8379 7.15774 10.9558C7.15774 11.0653 7.18301 11.1747 7.22511 11.2758C7.26722 11.3768 7.32616 11.4695 7.40195 11.5453C7.48616 11.6295 7.57879 11.6884 7.67984 11.7305C7.78089 11.7726 7.89037 11.7979 7.99985 11.7979C8.22721 11.7979 8.43775 11.7053 8.59775 11.5453C8.67354 11.4695 8.73247 11.3768 8.783 11.2758C8.8251 11.1747 8.84195 11.0653 8.84195 10.9558C8.84195 10.8463 8.82511 10.7369 8.77458 10.6274C8.73248 10.5263 8.67354 10.4337 8.59775 10.3579Z"
                fill="currentColor"
            />
            <path
                d="M11.1241 7.83145C11.0483 7.75567 10.9556 7.6883 10.8462 7.64619C10.5346 7.51988 10.1641 7.59567 9.92826 7.83145C9.85247 7.90724 9.79353 7.99988 9.75143 8.10093C9.70932 8.21041 9.68406 8.31987 9.68406 8.42934C9.68406 8.53882 9.70932 8.64829 9.75143 8.74935C9.79353 8.8504 9.85247 8.94304 9.92826 9.01883C10.0125 9.10304 10.1051 9.16199 10.2062 9.20409C10.3072 9.2462 10.4167 9.27145 10.5262 9.27145C10.6356 9.27145 10.7451 9.2462 10.8462 9.20409C10.9472 9.16199 11.0399 9.10304 11.1241 9.01883C11.1999 8.94304 11.2588 8.8504 11.3009 8.74935C11.3514 8.64829 11.3683 8.53882 11.3683 8.42934C11.3683 8.31987 11.3514 8.21041 11.3009 8.10093C11.2588 7.99988 11.1999 7.90724 11.1241 7.83145Z"
                fill="currentColor"
            />
            <path
                d="M11.1241 10.3578C11.082 10.3157 11.0398 10.2821 10.9977 10.2568C10.9472 10.2231 10.8967 10.1978 10.8462 10.1726C10.7956 10.1557 10.7451 10.1389 10.6946 10.1305C10.5851 10.1052 10.4672 10.1052 10.3662 10.1305C10.3072 10.1305 10.2567 10.1557 10.2062 10.1726C10.1556 10.1978 10.1051 10.2231 10.063 10.2568C10.0125 10.2821 9.97037 10.3157 9.92826 10.3578C9.85247 10.4336 9.79353 10.5263 9.75143 10.6273C9.70932 10.7368 9.68406 10.8378 9.68406 10.9557C9.68406 11.1831 9.77668 11.3936 9.92826 11.5452C10.0883 11.7052 10.3072 11.7978 10.5262 11.7978C10.7451 11.7978 10.9641 11.7052 11.1241 11.5452C11.2841 11.3936 11.3683 11.1831 11.3683 10.9557C11.3683 10.8378 11.343 10.7368 11.3009 10.6273C11.2588 10.5263 11.1999 10.4336 11.1241 10.3578Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
