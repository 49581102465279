export const messagingWorkflowFilterOptions = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Goal Met', value: 'goalmet' },
    { label: 'Unenrolled', value: 'unenrolled' },
];

export const MULTI_SELECT_FILTER_TYPES = {
    attachments: 'attachments',
    digitalAssistant: 'digitalAssistant',
    sequences: 'sequences',
};