import { createSlice } from '@reduxjs/toolkit';

export const initialByeBiasState = {
    on: false,
};

export const byeBiasSlice = createSlice({
    name: 'byeBias',
    initialState: initialByeBiasState,
    reducers: {
        toggleByeBias: (state) => {
            state.on = !state.on;
        },
    },
});

export const { toggleByeBias } = byeBiasSlice.actions;

export const byeBiasReducer = byeBiasSlice.reducer;
