import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';
import { organizationsApi } from './organizations';

const compensationBaseUrl = '/api/compensation/v1';
const telemetryBaseUrl = '/api/telemetry/v1';
const searchBaseUrl = '/api/search/v1';
const aggregatorBaseUrl = 'api/aggregator/v1';

export const searchApi = createApi({
    reducerPath: 'searchApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['SavedSearches', 'HiddenTalents', 'SearchHistory'],
    endpoints: (builder) => ({
        getProfileSearchResults: builder.query({
            query: ({ body, params }) => ({
                url: `${aggregatorBaseUrl}/match/talent/profiles`,
                method: 'POST',
                body,
                params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so the following will refresh token count
                    dispatch(
                        organizationsApi.util.invalidateTags([{ type: 'Organizations', id: 'ME' }])
                    );

                    dispatch(
                        searchApi.util.invalidateTags([{ type: 'SearchHistory', id: 'LIST' }])
                    );
                } catch (error) {
                    // do nothing
                }
            },
        }),
        getSavedSearch: builder.query({
            query: (id) => `${searchBaseUrl}/saved-searches/${id}`,
        }),
        getSavedSearches: builder.query({
            query: () => `${searchBaseUrl}/saved-searches`,
            providesTags: (result) => [
                ...(result?.map(({ id }) => ({ type: 'SavedSearches', id })) || []),
                { type: 'SavedSearches', id: 'LIST' },
            ],
        }),
        createSavedSearch: builder.mutation({
            query: (body) => ({
                url: `${searchBaseUrl}/saved-searches`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'SavedSearches', id: 'LIST' }],
        }),
        editSavedSearch: builder.mutation({
            query: ({ id, savedSearch }) => ({
                url: `${searchBaseUrl}/saved-searches/${id}`,
                method: 'PUT',
                body: savedSearch,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'SavedSearches', id }],
        }),
        deleteSavedSearch: builder.mutation({
            query: (id) => ({
                url: `${searchBaseUrl}/saved-searches/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'SavedSearches', id: 'LIST' }],
        }),
        getSearchCompensation: builder.query({
            query: (body) => ({
                url: `${compensationBaseUrl}/talent/search-results`,
                method: 'POST',
                body,
            }),
        }),
        getSearchGeography: builder.query({
            query: (body) => ({
                url: `${compensationBaseUrl}/role-density/search-results`,
                method: 'POST',
                body,
            }),
        }),
        getSearchGraduates: builder.query({
            query: (body) => ({
                url: `${compensationBaseUrl}/role-graduates/search-results`,
                method: 'POST',
                body,
            }),
        }),
        getSearchCompetition: builder.query({
            query: ({ duration, includeRecruiters, body }) => ({
                url: `${compensationBaseUrl}/role-posting/search-results`,
                method: 'POST',
                params: {
                    duration,
                    topN: 60,
                    includeRecruiters,
                },
                body,
            }),
        }),
        getSearchHistory: builder.query({
            query: ({ page, pageLength }) => ({
                url: `${searchBaseUrl}/search-history`,
                method: 'POST',
                body: {
                    page,
                    pageSize: pageLength,
                    'body.page': 1,
                    url: 'http://*/api/search/v1/match/talent/*spend=*',
                },
            }),
            providesTags: (result) => [
                ...(result?.results?.map(({ id }) => ({ type: 'SearchHistory', id })) || []),
                { type: 'SearchHistory', id: 'LIST' },
            ],
        }),
        getSearchHistoryDetail: builder.query({
            query: (telemetryId) => `${searchBaseUrl}/search-history/${telemetryId}`,
        }),
        hideTalentInSearchResults: builder.mutation({
            query: ({ talentId, body }) => ({
                url: `${searchBaseUrl}/hidden/${talentId}`,
                body,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'HiddenTalents', id: 'LIST' }],
        }),
        getHiddenTalents: builder.query({
            query: ({ page, pageSize }) => ({
                url: `${searchBaseUrl}/hidden`,
                method: 'GET',
                params: { page, pageSize },
            }),
            providesTags: [{ type: 'HiddenTalents', id: 'LIST' }],
        }),
        unhideAllTalents: builder.mutation({
            query: () => ({
                url: `${searchBaseUrl}/hidden`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'HiddenTalents', id: 'LIST' }],
        }),

        rewriteSearch: builder.query({
            query: ({ matchText }) => ({
                url: `${searchBaseUrl}/fast/extract`,
                method: 'POST',
                body: {
                    matchText,
                },
            }),
        }),
        getAutocompleteResults: builder.query({
            query: ({ key, title }) => ({
                url: `${searchBaseUrl}/autocomplete/`,
                method: 'GET',
                params: {
                    key: key,
                    value: title,
                },
            }),
        }),
        logSearchEvent: builder.mutation({
            query: (reqBody) => ({
                url: `${telemetryBaseUrl}/events/search_actions`,
                method: 'POST',
                body: reqBody,
            }),
        }),
    }),
});

export const {
    useLazyGetProfileSearchResultsQuery,
    useLazyGetSavedSearchQuery,
    useGetSavedSearchesQuery,
    useLazyGetSavedSearchesQuery,
    useCreateSavedSearchMutation,
    useEditSavedSearchMutation,
    useDeleteSavedSearchMutation,
    useLazyGetSearchCompensationQuery,
    useLazyGetSearchGeographyQuery,
    useLazyGetSearchGraduatesQuery,
    useGetSearchHistoryQuery,
    useLazyGetSearchHistoryQuery,
    useLazyGetSearchCompetitionQuery,
    useLazyGetSearchHistoryDetailQuery,
    useLazyRewriteSearchQuery,
    useHideTalentInSearchResultsMutation,
    useLazyGetHiddenTalentsQuery,
    useUnhideAllTalentsMutation,
    useLazyGetAutocompleteResultsQuery,
    useLogSearchEventMutation,
} = searchApi;
