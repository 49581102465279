import { useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { PaperIcon } from 'components/Icons';
import { LoadWrapper } from 'components/Loaders';
import { RecentTemplate } from './RecentTemplate';
import { StyledDropdownMenu } from 'components/MessageContent/StyledDropdownMenu';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeTemplateBody,
    changeTemplateName,
    changeTemplateSubject,
    setIsUpdatingTemplate,
    setTemplateId,
} from '../MessageTemplateSlice';
import { changeDefaultDynamicFields, useDynamicFields } from 'features/DynamicFields';
import { setSelectedSignature, changeSignatureBody } from 'features/Signature';
import { removeSignatureInMessageBody } from '../utils/messageTemplateHelpers';
import { Typography } from 'components/Typography';

const Button = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'disableShadow' && prop !== 'compact',
})(({ theme, disableShadow, compact }) => ({
    height: theme.spacing(3),
    width: compact ? theme.spacing(3) : 'auto',
    borderRadius: compact ? '50%' : theme.spacing(2),
    boxShadow: disableShadow
        ? 'inherit'
        : '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    '&.Mui-disabled': { opacity: 0.32 },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
}));

export const TemplateButton = memo((props) => {
    const {
        initialHtml,
        initialSubject,
        onSaveNewTemplate,
        isCreateTemplateDisabled,
        isFetchingTemplates,
        onTemplateCreateView,
        onTemplateListsView,
        onUseTemplate,
        templates,
        messageType,
        disableShadow,
        compact = false,
        ...restProps
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { template } = useSelector((state) => state.messageTemplate);
    const dispatch = useDispatch();

    const { defaultDynamicFields } = useDynamicFields();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUpdateTemplate = () => {
        const signature = template?.signature?.signature || '';
        const bodyWithoutSignature = removeSignatureInMessageBody(initialHtml, signature);

        handleClose();
        onSaveNewTemplate();
        onTemplateCreateView?.();
        dispatch(setIsUpdatingTemplate(true));
        dispatch(setTemplateId(template.templateId));
        dispatch(changeTemplateName(template.name));
        dispatch(changeTemplateBody(bodyWithoutSignature));
        dispatch(changeTemplateSubject(initialSubject));
        dispatch(changeDefaultDynamicFields(defaultDynamicFields || {}));
        dispatch(setSelectedSignature(template?.signature || {}));
        dispatch(changeSignatureBody(signature));
    };

    const handleSaveNewTemplate = () => {
        handleClose();
        onSaveNewTemplate();
        onTemplateCreateView?.();
        dispatch(setIsUpdatingTemplate(false));
        dispatch(changeTemplateName(template?.name || ''));
        dispatch(changeTemplateBody(initialHtml));
        dispatch(changeTemplateSubject(initialSubject));
        dispatch(changeDefaultDynamicFields(defaultDynamicFields || {}));
        dispatch(setSelectedSignature(template?.signature || {}));
        dispatch(changeSignatureBody(template?.signature?.signature || ''));
    };

    const handleViewTemplates = () => {
        handleClose();
        onSaveNewTemplate();
        onTemplateListsView?.();
    };

    const recentTemplates = useMemo(
        () =>
            templates
                ?.filter((template) => template?.messageTypes?.includes(messageType))
                .slice(0, 5),
        [templates, messageType]
    );

    return (
        <>
            <Button
                aria-label="template-icon-button"
                onClick={handleClick}
                disableShadow={disableShadow}
                compact={compact}
                disableRipple
                {...restProps}
            >
                <PaperIcon sx={{ fontSize: 12, mr: 1 }} gradient />
                {!compact && (
                    <Typography variant="bodyXSmall" color="primary" sx={{ fontWeight: 500 }}>
                        Message Templates
                    </Typography>
                )}
            </Button>
            <StyledDropdownMenu
                id="template-dropdown-menu"
                MenuListProps={{
                    'aria-labelledby': 'template-dropdown-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <LoadWrapper isLoading={isFetchingTemplates}>
                    <MenuItem onClick={handleUpdateTemplate} disabled={!template?.templateId}>
                        Update Template
                    </MenuItem>
                </LoadWrapper>
                <LoadWrapper isLoading={isFetchingTemplates}>
                    <MenuItem
                        onClick={handleSaveNewTemplate}
                        disabled={isCreateTemplateDisabled}
                        disableRipple
                    >
                        Save As New Template
                    </MenuItem>
                </LoadWrapper>
                <MenuItem onClick={handleViewTemplates} disableRipple>
                    View Templates
                </MenuItem>
                {recentTemplates?.length > 0 && (
                    <RecentTemplate
                        templates={recentTemplates}
                        onClose={handleClose}
                        onUseTemplate={onUseTemplate}
                    />
                )}
            </StyledDropdownMenu>
        </>
    );
});

TemplateButton.propTypes = {
    onSaveNewTemplate: PropTypes.func,
    isFetchingTemplates: PropTypes.bool,
    isCreateTemplateDisabled: PropTypes.bool,
    disableShadow: PropTypes.bool,
    compact: PropTypes.bool,
    onTemplateCreateView: PropTypes.func,
    onTemplateListsView: PropTypes.func,
    onUseTemplate: PropTypes.func,
    templates: PropTypes.array,
    messageType: PropTypes.string,
    initialHtml: PropTypes.string,
    initialSubject: PropTypes.string,
};
