import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, styled } from '@mui/material';
import { EyeIcon, PlusIcon, PlusIconDark } from 'components/Icons';
import { Typography } from 'components/Typography';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import { useQueryClient } from '@tanstack/react-query';
import { useCustomMutation } from 'hooks/useCustomQuery';
import { createNoteApi } from 'features/TalentNotes/services';
import { getValueByMode } from 'utils/copy/getValueByMode';
import { useNotesStore } from '../../../store/useNotesStore';
import { NotesSelectDropDown } from '../NotesSelectDropDown';
import { NOTE_PRIVACY_OPTIONS } from 'features/TalentNotes/utils/constants';
import { sortArrayItemsByDate } from 'utils/helpers';
import { MentionEditor } from 'components/MentionEditor';
import { EditorState, ContentState } from 'draft-js';
import { MentionPrivacyDialog } from '../MentionPrivacyDialog.js/MentionPrivacyDialog';
import debounce from 'lodash/debounce';
import { useLazyGetOrganizationUsersQuery } from 'services/organizations';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';

const MentionEditorContainer = styled(Box)(() => ({
    resize: 'none',
    fontFamily: 'inherit',
    background: 'transparent',
    fontSize: 15,
    outlineWidth: 0,
    boxSizing: 'border-box',
    overflowX: 'hidden',
    borderRadius: '5px',
    border: 'solid 1px rgba(38, 55, 70, 0.12)',
    '&:focus, &:focus-within': {
        borderColor: '#3A8DD3',
    },
    '& .public-DraftEditor-content': {
        minHeight: '30px',
        maxHeight: '176px',
        width: '390px',
    },
    '& .mentionSuggestions': {
        zIndex: 1000,
        borderRadius: '4px',
        background: '#fff',
        boxShadow: '0 0 0 1px rgba(16,22,26,.2), 0 2px 4px rgba(16,22,26,.4)',
        overflow: 'hidden',
        maxHeight: '210px',
        overflowY: 'auto !important',
    },
    '& .mentionSuggestionsContainer': {
        height: '210px',
        position: 'absolute',
    },
    '& .mention': {
        textDecoration: 'none',
        color: '#3A8DD3',
        fontWeight: '700',
    },
    padding: '6px',
    overflowY: 'scroll',
}));

export function NotesFooter({ updateNotes, notes, talentId, notesCanvasRef, source, sourceId }) {
    const [focused, setFocused] = useState(false);
    const [privacy, setPrivacy] = useState('private');
    const { isAscMode, notesEnvUrl: envUrl } = useNotesStore((state) => state);
    const [noteDetails, setNoteDetails] = useState('');
    const [createNoteBtnhover, setCreateNoteBtnhover] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [openMentionPrivacyDialog, setOpenMentionPrivacyDialog] = useState(false);
    const [editorKey, setEditorKey] = useState(generateRandomNumber());
    const mentionEditorContainerRef = useRef(null);
    const queryClient = useQueryClient();
    const rawData = EditorState.createWithContent(ContentState.createFromText(noteDetails));
    const [triggerGetOrganizationUsers, { data: orgUsersData }] =
        useLazyGetOrganizationUsersQuery();

    const [logSearchEvent] = useSearchTelemetry();

    const { mutate, isLoading: isCreatingNote } = useCustomMutation(createNoteApi, {
        onSuccess: (data) => {
            const updatedNotes = sortArrayItemsByDate(isAscMode, [...notes, ...data]);
            updateNotes(updatedNotes);
            setNoteDetails('');
            setEditorKey(generateRandomNumber());
            isAscMode ? scrollToBottom() : scrollToTop();
            setOpenMentionPrivacyDialog(false);
        },
        onError: () => {
            toast.error('Sorry, something went wrong creating note.');
        },
        onSettled: () => {
            queryClient.invalidateQueries('talent-notes');
        },
    });

    function generateRandomNumber() {
        let array = new Uint32Array(1);
        window.crypto?.getRandomValues(array);
        return array[0];
    }

    const mappedOrgUsers = orgUsersData?.map((user) => ({
        ...user,
        name: `${user.firstName} ${user.lastName}`,
        email: user.primaryEmail,
    }));

    const handleMutate = (content, privacyType) => {
        setFocused(false);
        mutate({
            data: [
                {
                    entity_id: talentId,
                    content,
                    privacy: privacyType,
                    source,
                    source_id: sourceId,
                },
            ],
            envUrl,
        });
    };

    const createNote = () => {
        const noteTextHasMentions = /{{(.*?)}}/g.test(noteDetails);
        if (noteTextHasMentions && privacy === 'private') {
            setOpenMentionPrivacyDialog(true);
            return;
        }
        if (!noteDetails || isCreatingNote) return;
        handleMutate(noteDetails, privacy);
        logSearchEvent(SEARCH_EVENT_TYPES.ADD_NOTE, { profileId: talentId });
    };

    const onMouseEnter = () => {
        setCreateNoteBtnhover(true);
    };

    const onMouseLeave = () => {
        setCreateNoteBtnhover(false);
    };
    const handleOnBlur = () => {
        if (createNoteBtnhover) {
            // on textArea blur, but user has clicked the create-note button
            createNote();
            setFocused(false);
        } else {
            setFocused(false);
        }
    };

    const scrollToTop = () => {
        if (notesCanvasRef.current) {
            notesCanvasRef.current.scrollTop = 0;
        }
    };

    const scrollToBottom = () => {
        if (notesCanvasRef.current) {
            notesCanvasRef.current.scrollTop = notesCanvasRef.current.scrollHeight;
        }
    };

    const handleCloseMentionPrivacyDialog = () => {
        setOpenMentionPrivacyDialog(false);
    };

    const keepTagsAndPostNoteToOrganization = () => {
        handleMutate(noteDetails, 'org');
    };

    const removeTagsAndPostPrivateNote = () => {
        const strippedMentionsNoteDetails = noteDetails.replace(/{{(.*?)}}/g, '');
        setNoteDetails(strippedMentionsNoteDetails);
        handleMutate(strippedMentionsNoteDetails, privacy);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchOrgUsers = useCallback(
        debounce((searchTerm) => {
            triggerGetOrganizationUsers(searchTerm);
        }, 300),
        [triggerGetOrganizationUsers]
    );

    useEffect(() => {
        fetchOrgUsers(searchTerm);
    }, [fetchOrgUsers, searchTerm]);

    return (
        <Box
            sx={{
                position: 'absolute',
                width: '100%',
                minHeight: '100px',
                bottom: 0,
                left: 0,
                zIndex: 5,
                boxShadow: '0px -2px 7px rgba(50, 50, 50, 0.17)',
                boxSizing: 'border-box',
                paddingTop: '5px',
                paddingRight: '15px',
                paddingBottom: '15px',
                paddingLeft: '25px',
                background: 'white',
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '2px',
                        paddingLeft: '2px',
                        paddingTop: '5px',
                    }}
                >
                    <Typography
                        variant="bodySmall"
                        sx={{ fontWeight: 'bold', display: 'flex', fontSize: '13px' }}
                    >
                        <EyeIcon sx={{ fontSize: 12, mr: 0.5, alignSelf: 'center' }} />
                        {getValueByMode(privacy === 'private', 'Only You', 'Your Org')}
                    </Typography>
                    <NotesSelectDropDown setPrivacy={setPrivacy} options={NOTE_PRIVACY_OPTIONS} />
                </Box>
                <Box
                    sx={{
                        width: '27px',
                        height: '27px',
                        position: 'absolute',
                        boxSizing: 'border-box',
                        right: 17,
                        bottom: 7,
                        cursor: 'pointer',
                    }}
                    onClick={createNote}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    aria-label="create-note"
                    data-trackid="create-note-add-button"
                >
                    {isCreatingNote ? (
                        <CircularProgress size={22} />
                    ) : (
                        getValueByMode(
                            focused,
                            <PlusIcon sx={{ width: '25px', height: '25px' }} />,
                            <PlusIconDark sx={{ width: '25px', height: '25px' }} />
                        )
                    )}
                </Box>
                <MentionEditorContainer
                    key={editorKey}
                    ref={mentionEditorContainerRef}
                    onBlur={handleOnBlur}
                    onFocus={() => setFocused(true)}
                    data-trackid="create-note-input-field"
                >
                    <MentionEditor
                        editorState={rawData}
                        suggestions={mappedOrgUsers}
                        onChange={setNoteDetails}
                        onSearchChange={setSearchTerm}
                        handleSubmit={createNote}
                        mentionEditorContainerRef={mentionEditorContainerRef}
                    />
                </MentionEditorContainer>
                <MentionPrivacyDialog
                    open={openMentionPrivacyDialog}
                    onClose={handleCloseMentionPrivacyDialog}
                    removeTagsAndPostPrivateNote={removeTagsAndPostPrivateNote}
                    keepTagsAndPostNoteToOrganization={keepTagsAndPostNoteToOrganization}
                    isLoading={isCreatingNote}
                />
            </Box>
        </Box>
    );
}
NotesFooter.propTypes = {
    updateNotes: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
    isAscMode: PropTypes.bool,
    talentId: PropTypes.string,
    notesCanvasRef: PropTypes.object,
    source: PropTypes.string,
    sourceId: PropTypes.string,
};
