import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Typography } from 'components/Typography';
import { ExpandButton } from 'components/ExpandableCard';
import { AttachmentDownload } from './AttachmentDownload';
import { DownloadIcon } from 'components/Icons';

import { Interweave } from 'interweave';
import { useMediaDownload } from './useMediaDownload';
import { ConfirmDownloadDialog } from './ConfirmDownloadDialog';

const collapsedSize = 240;

const AttachmentSxProps = {
    display: 'flex',
    overflowX: 'auto',
    pt: 1,
    pb: 0.25,
};

const MessageContentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    boxSizing: 'border-box',
    maxWidth: 744,
}));

export const MessageContent = forwardRef((props, ref) => {
    const {
        isUser,
        collapsed,
        isCollapsable,
        displayExpandButton,
        media,
        message,
        onExpand,
        onCollapse,
        onAttachmentDownload,
    } = props;

    const {
        handleDownload,
        isOpen,
        handleClose,
        filename,
        isError,
        isFetching,
        url,
        handleConfirm,
    } = useMediaDownload({ onAttachmentDownload });

    return (
        <>
            <MessageContentContainer
                sx={{
                    padding: `16px 16px ${collapsed ? '16px' : 0} 16px`,
                    maxHeight: collapsed ? collapsedSize : 'auto',
                    overflow: collapsed ? 'hidden' : 'unset',
                    ...(collapsed && {
                        [`borderBottom${isUser ? 'Left' : 'Right'}Radius`]: '16px',
                    }),
                }}
                ref={ref}
            >
                <Typography variant="body" sx={{ wordWrap: 'break-word' }}>
                    <Interweave content={message} />
                </Typography>
                <Box>
                    <Box sx={AttachmentSxProps}>
                        {media?.map((m) => (
                            <AttachmentDownload
                                key={m.mediaId}
                                label={m.name}
                                title={m.name}
                                deleteIcon={<DownloadIcon aria-label={`download-file-${m.name}`} />}
                                onDelete={handleDownload(m.mediaId, m.name)}
                            />
                        ))}
                    </Box>
                </Box>
                <ExpandButton
                    displayExpandButton={displayExpandButton}
                    isCollapsable={isCollapsable}
                    collapsed={collapsed}
                    onExpand={onExpand}
                    onCollapse={onCollapse}
                />
            </MessageContentContainer>
            <ConfirmDownloadDialog
                url={url}
                filename={filename}
                onClose={handleClose}
                isFetching={isFetching}
                isError={isError}
                onConfirm={handleConfirm}
                isOpen={isOpen}
            />
        </>
    );
});

MessageContent.propTypes = {
    isUser: PropTypes.bool,
    collapsed: PropTypes.bool,
    isCollapsable: PropTypes.bool,
    displayExpandButton: PropTypes.bool,
    message: PropTypes.string,
    onExpand: PropTypes.func,
    onCollapse: PropTypes.func,
    onAttachmentDownload: PropTypes.func,
    media: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            mediaId: PropTypes.string,
        })
    ),
};
