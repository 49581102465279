import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Typography } from 'components/Typography';
import { PERMISSIONS_OPTIONS } from 'utils/entityEnums';

export function PermissionsControlDropdown(props) {
    const { anchorEl, open, onClose, onChangeValue } = props;

    return (
        <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
            <MenuItem
                data-testid="editor-permissions-option"
                onClick={() => onChangeValue(PERMISSIONS_OPTIONS.EDITOR.value)}
            >
                <Typography>{PERMISSIONS_OPTIONS.EDITOR.label}</Typography>
            </MenuItem>
            <MenuItem
                data-testid="viewer-permissions-option"
                onClick={() => onChangeValue(PERMISSIONS_OPTIONS.VIEWER.value)}
            >
                <Typography>{PERMISSIONS_OPTIONS.VIEWER.label}</Typography>
            </MenuItem>
        </Menu>
    );
}

PermissionsControlDropdown.propTypes = {
    accessValue: PropTypes.string,
    anchorEl: PropTypes.element,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChangeValue: PropTypes.func,
};
