import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { WarningIcon } from 'components/Icons';
import { TEST_IDS } from 'utils/constants';
import { Typography } from 'components/Typography';
import { formatPhoneNumber } from 'utils/formatters/formatNumber';
import { CONTACT_STATUS_MAP } from '../utils/constants';
import { ContactStatusBadge } from './ContactStatusBadge.styled';
import { CopyContactButton } from './CopyContactButton';

export function PhoneListItem({
    contactData,
    contact,
    isCustomContact,
    isVerified,
    isReported,
    isNewContact,
}) {
    const { status } = contact;

    const phoneNumber = formatPhoneNumber(contactData) || contactData;

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', pt: 1, pb: 1 }}
            data-testid={TEST_IDS.PHONE_SECONDARY_VIEW}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {status === CONTACT_STATUS_MAP.OPT_OUT && (
                    <WarningIcon
                        sx={{ fontSize: 13, mr: 0.4 }}
                        color="error"
                        data-testid={TEST_IDS.WARNING_INDICATOR}
                    />
                )}
                <Typography
                    variant="body"
                    color={status === CONTACT_STATUS_MAP.OPT_OUT ? 'error' : 'text'}
                >
                    {phoneNumber}
                </Typography>
                <Box sx={{ ml: 1 }}>
                    <CopyContactButton ariaLabel="copy-phone-number" contactData={phoneNumber} />
                </Box>
                {status === CONTACT_STATUS_MAP.OPT_OUT && (
                    <ContactStatusBadge label="Opted out" color="error" sx={{ ml: 1 }} />
                )}
                {isNewContact && <ContactStatusBadge label="New" color="success" />}
                {isCustomContact && <ContactStatusBadge label="Custom" color="text" />}
                {isVerified && <ContactStatusBadge label="Verified" color="success" />}
                {isReported && (
                    <ContactStatusBadge label="Reported" color="error" sx={{ mt: 0.4 }} />
                )}
            </Box>
        </Box>
    );
}

PhoneListItem.propTypes = {
    contactData: PropTypes.string.isRequired,
    contact: PropTypes.shape({
        contactId: PropTypes.string.isRequired,
        status: PropTypes.oneOf(Object.values(CONTACT_STATUS_MAP)),
    }).isRequired,
    isCustomContact: PropTypes.bool,
    isVerified: PropTypes.bool,
    isReported: PropTypes.bool,
    isNewContact: PropTypes.bool,
};
