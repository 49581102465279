import SvgIcon from '@mui/material/SvgIcon';

export function WorldIcon(props) {
    return (
        <SvgIcon viewBox="0 0 18 18" {...props}>
            <path
                d="M16.5 9C16.5 4.8675 13.1325 1.5 9 1.5C4.8675 1.5 1.5 4.8675 1.5 9C1.5 13.1325 4.86 16.5 9 16.5C13.1325 16.5 16.5 13.1325 16.5 9ZM14.9475 8.25H12.66C12.525 6.045 11.805 4.4475 11.0775 3.375C13.14 4.14 14.6625 6 14.9475 8.25ZM9 14.775C8.3175 14.13 7.03501 12.5625 6.83251 9.75H11.16C10.9575 12.5625 9.67502 14.1375 8.99252 14.775H9ZM6.83251 8.25C7.03501 5.4375 8.3175 3.86249 9 3.22499C9.6825 3.86999 10.965 5.4375 11.1675 8.25H6.84002H6.83251ZM6.92251 3.375C6.19501 4.4475 5.47502 6.0375 5.34002 8.25H3.05251C3.33751 6 4.86001 4.14 6.92251 3.375ZM3.05251 9.75H5.34002C5.47502 11.955 6.19501 13.5525 6.92251 14.625C4.86001 13.86 3.33751 12 3.05251 9.75ZM11.0775 14.625C11.805 13.5525 12.525 11.9625 12.66 9.75H14.9475C14.6625 12 13.14 13.86 11.0775 14.625Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
