import PropTypes from 'prop-types';

import { Tooltip } from 'components/Tooltip';

export function IconWithTooltip(props) {
    const { title, Icon, IconProps } = props;
    return (
        <Tooltip title={title} arrow>
            <span>
                <Icon {...IconProps} data-testid="icon-with-tooltip" />
            </span>
        </Tooltip>
    );
}

IconWithTooltip.propTypes = {
    title: PropTypes.string,
    Icon: PropTypes.elementType,
    IconProps: PropTypes.object,
};
