import PropTypes from 'prop-types';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import MuiDivider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { Typography } from 'components/Typography';
import { TALENT_DRAWER_TABS } from 'utils/constants';

const Tabs = styled(MuiTabs)(({ theme }) => ({
    minHeight: theme.spacing(5),
}));

const Tab = styled((props) => <MuiTab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    padding: theme.spacing(1.5),
    minHeight: theme.spacing(5),
    '&:not(.Mui-selected) .insights-toggle-expand, &.Mui-disabled .insights-toggle-expand': {
        pointerEvents: 'none',
        opacity: 0,
    },
}));

const Divider = styled(MuiDivider)({
    borderBottomWidth: 2,
    marginTop: -2,
});

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 6,
        fontSize: '9px',
        border: `3px solid ${theme.palette.background.paper}`,
        color: 'white',
        padding: '0 2px',
        lineHeight: '16px',
    },
}));

function TabLabel(props) {
    const { label, count } = props;

    return (
        <Box sx={{ display: 'flex' }}>
            <Typography>{label}</Typography>{' '}
            {Boolean(count) && count > 0 && (
                <StyledBadge
                    badgeContent={count}
                    color="secondary"
                    max={9}
                    style={{ marginTop: 6, marginLeft: 10 }}
                />
            )}
        </Box>
    );
}

export function ProfileTabs(props) {
    const {
        onChangeTab,
        currentTab,
        notesCount,
        attachmentsCount,
        activitiesCount,
        isMediaFeatureEnabled,
        isActivityTrackingEnabled,
    } = props;

    return (
        <>
            <Tabs
                value={currentTab}
                onChange={onChangeTab}
                aria-label="profile-drawer-tabs"
                variant="fullWidth"
            >
                <Tab
                    aria-label="profile-tab"
                    data-trackid="profile-drawer-tabs-profile-click"
                    label={<TabLabel label="Profile" />}
                    value={TALENT_DRAWER_TABS.PROFILE_TAB}
                />
                {isActivityTrackingEnabled && (
                    <Tab
                        aria-label="activity-tab"
                        data-trackid="profile-drawer-tabs-activity-click"
                        label={<TabLabel label="Activity" count={activitiesCount} />}
                        value={TALENT_DRAWER_TABS.ACTIVITY_TAB}
                    />
                )}
                <Tab
                    aria-label="notes-tab"
                    data-trackid="profile-drawer-tabs-notes-click"
                    label={<TabLabel label="Notes" count={notesCount} />}
                    value={TALENT_DRAWER_TABS.NOTES_TAB}
                />
                {isMediaFeatureEnabled && (
                    <Tab
                        aria-label="attachment-tab"
                        data-trackid="profile-drawer-tabs-attachments-click"
                        label={<TabLabel label="Attachments" count={attachmentsCount} />}
                        value={TALENT_DRAWER_TABS.ATTACHMENTS_TAB}
                    />
                )}
            </Tabs>
            <Divider />
        </>
    );
}

ProfileTabs.propTypes = {
    onChangeTab: PropTypes.func,
    currentTab: PropTypes.number,
    notesCount: PropTypes.number,
    attachmentsCount: PropTypes.number,
    isMediaFeatureEnabled: PropTypes.bool,
    isActivityTrackingEnabled: PropTypes.bool,
    activitiesCount: PropTypes.number,
};

TabLabel.propTypes = {
    label: PropTypes.string,
    count: PropTypes.number,
};
