import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { Link, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from 'components/Typography';
import { useSwitchboard } from 'features/Switchboard';
import { resetSearch } from 'views/Search/searchSlice';
import { usePreviousSavedSearch } from 'views/Search/features/SavedSearches/hooks/usePreviousSavedSearch';

export function SearchMenu(props) {
    const { menuOptions, onSelect } = props;

    const _menuOptions = [...menuOptions];

    const isLinkedInURLSearchEnabled = useSwitchboard('FEATURE_LI_URL_SEARCH');

    const dispatch = useDispatch();

    const [, setPreviousSavedSearch] = usePreviousSavedSearch();

    const isZeroState = useSelector((state) => state.search.zeroState);
    const isOnSearchPage = useMatch('/search/*');

    const handleNewSearchClick = () => {
        setPreviousSavedSearch(null);
        dispatch(resetSearch());
    };

    if (isLinkedInURLSearchEnabled) {
        _menuOptions.push({
            label: 'Search by LinkedIn URL',
            to: 'search/li-url',
            trackId: 'search-menu-li-url',
        });
    }

    const staticMenuItems = _menuOptions.map((menuOption) => {
        let handleClick = onSelect;
        if (menuOption.to === 'search') {
            handleClick = () => {
                handleNewSearchClick();
                onSelect();
            };
        }
        return (
            <MenuItem
                key={menuOption.to}
                component={Link}
                to={menuOption.to}
                state={menuOption.state}
                disableRipple
                onClick={handleClick}
                data-trackid={menuOption.trackId}
            >
                <Typography variant="titleSmall">{menuOption.label}</Typography>
            </MenuItem>
        );
    });

    if (isZeroState || isOnSearchPage) {
        return staticMenuItems;
    }

    return (
        <>
            <MenuItem
                component={Link}
                to="search"
                disableRipple
                onClick={onSelect}
                data-trackid="search-menu-resume-current-search"
            >
                <Typography variant="titleSmall">Resume Current Search</Typography>
            </MenuItem>
            {staticMenuItems}
        </>
    );
}

SearchMenu.propTypes = {
    menuOptions: PropTypes.array,
    onSelect: PropTypes.func,
};
