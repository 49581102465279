import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { Typography } from 'components/Typography';
import { ActivityContent } from './ActivityContent';
import { ActivityHeader } from './ActivityHeader';
import { EntityConnections } from './EntityConnections';
import { getInitials } from 'utils/formatters';

const CardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1, 0),
    width: '100%',
}));

const AvatarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.common.white,
    background: 'linear-gradient(90deg, #29CC9A 0.17%, #318DDE 99.72%)',
}));

export function ActivityCard(props) {
    const {
        activityType,
        activitySubType,
        actor,
        createdDate,
        metadata,
        entityConnections,
        talentName,
        ignoredFields,
    } = props;

    const { name, id } = actor || {};

    const userId = useSelector((state) => state.user.id);

    const actorInitials = getInitials(name, '');

    let actorName = userId === id ? 'You' : name;

    return (
        <CardContainer>
            <Box sx={{ mr: 1 }}>
                <AvatarContainer data-testid="activity-avatar">
                    <Typography variant="bodyXSmall" sx={{ fontWeight: 800 }}>
                        {actorInitials}
                    </Typography>
                </AvatarContainer>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 0.5 }}>
                <ActivityHeader name={actorName || ''} createdDate={createdDate} />
                <ActivityContent
                    activityType={activityType}
                    activitySubType={activitySubType}
                    metadata={metadata}
                    entityConnections={entityConnections}
                />
                <EntityConnections
                    entityConnections={entityConnections}
                    actor={actor}
                    talentName={talentName}
                    ignoredFields={ignoredFields}
                    metadata={metadata}
                />
            </Box>
        </CardContainer>
    );
}

ActivityCard.propTypes = {
    activityType: PropTypes.string,
    activitySubType: PropTypes.string,
    createdDate: PropTypes.string,
    metadata: PropTypes.object,
    entityConnections: PropTypes.array,
    actor: PropTypes.shape({
        type: PropTypes.string,
        orgId: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
    }),
    talentName: PropTypes.string,
    ignoredFields: PropTypes.array,
};
