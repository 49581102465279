import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import {
    changeStandardExcludeFilter,
    changeStandardFilter,
    convertExcludeToInclude,
    convertIncludeToExclude,
    deleteStandardExcludeFilter,
    deleteStandardFilter,
} from 'views/Search/searchSlice';

export const useStandardFiltersState = (filterKey) => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.search.filters[filterKey] || []);
    const excludedFilters = useSelector((state) => state.search.exclusionFilters[filterKey] || []);
    const [excludeMatch, setExcludeMatch] = useState(false);

    const handleDelete = (key, index) => {
        dispatch(deleteStandardFilter({ property: key, index }));
    };

    const handleDeleteExcluded = (key, index) => {
        dispatch(deleteStandardExcludeFilter({ property: key, index }));
    };

    const handleChange = useCallback(
        (value) => {
            const action = excludeMatch ? changeStandardExcludeFilter : changeStandardFilter;
            dispatch(action({ property: filterKey, matchText: value }));
        },
        [excludeMatch, dispatch, filterKey]
    );

    const handleClickInclude = (key, index) => {
        dispatch(convertExcludeToInclude({ property: key, index }));
    };

    const handleClickExclude = (key, index) => {
        dispatch(convertIncludeToExclude({ property: key, index }));
    };

    const handleExcludeMatchChange = (event) => {
        setExcludeMatch(event.target.checked);
    };

    return {
        filters,
        excludedFilters,
        excludeMatch,
        handleDelete,
        handleDeleteExcluded,
        handleChange,
        handleClickInclude,
        handleClickExclude,
        handleExcludeMatchChange,
    };
};
