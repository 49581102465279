import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import { ToastContainer } from 'components/ToastContainer';

import {
    useCreateUserOnSignUpMutation,
    useUpdateUserOnSignUpMutation,
    useLazyGetBlacklistedEmailStatusQuery,
} from 'services/users';
import { toast } from 'react-toastify';
import { UserInfoForm } from './components/UserInfoForm';
import { UserInfoLogo } from './components/UserInfoLogo';
import { extractCompanyName } from './utils/extractCompanyName';
import { useKeycloak } from 'features/Authentication';
import { formatMobilePhoneNumber } from './utils/formatMobilePhoneNumber';

const Container = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
}));

const MainContainer = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    overflow: 'auto',
}));

export function UserInfo({
    onEmailValidationChange,
    setProfileComplete,
    userData,
    isChangingBlacklistedEmail,
}) {
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [recaptchaError, setRecaptchaError] = useState('');
    const [userProfile, setUserProfile] = useState(null);
    const [searchParams] = useSearchParams();
    const utmCampaign = searchParams.get('utm_campaign');

    const { keycloak, initialized } = useKeycloak();

    const [
        getBlackListedEmailStatus,
        { data: isEmailBlacklisted, isSuccess: isEmailSuccess, isError: isEmailError },
    ] = useLazyGetBlacklistedEmailStatusQuery();
    const [updateUserOnSignUp, { isError, isLoading, isSuccess }] = useUpdateUserOnSignUpMutation();

    const [createUserOnSignUp] = useCreateUserOnSignUpMutation();

    const loadUserProfile = useCallback(async () => {
        if (keycloak.authenticated) {
            const keycloakUserProfile = await keycloak.loadUserProfile();
            setUserProfile(keycloakUserProfile);
        }
    }, [keycloak]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch,
        clearErrors,
        setError,
    } = useForm();

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
        setRecaptchaError('');
    };

    const onSubmit = async (data) => {
        if (!recaptchaValue) {
            setRecaptchaError('Please complete the reCAPTCHA.');
            return;
        }

        const { firstName, lastName, jobTitle, mobilePhoneNumber, workEmail } = data;
        const mobilePhoneNumberWithCountryCode = `+${formatMobilePhoneNumber(mobilePhoneNumber)}`;
        const utmId = localStorage.getItem('utmId');
        const utmPayload = utmId ? { utm_campaign: utmId, utm_source: 'nova_user' } : {};
        const reqBody = {
            firstName,
            lastName,
            mobilePhoneNumber: mobilePhoneNumberWithCountryCode,
            jobTitle,
            ...(isChangingBlacklistedEmail ? { workEmail, fromBlacklistRedirect: 1 } : {}),
            ...utmPayload,
        };
        if (keycloak) {
            await keycloak.updateToken(-1);
        }
        updateUserOnSignUp(reqBody);
        getBlackListedEmailStatus();
    };

    const setFormValues = useCallback(
        (data) => {
            const { firstName, lastName, email, jobTitle } = data;
            const fieldValueMap = {
                firstName,
                lastName,
                organization: extractCompanyName(email),
                workEmail: isChangingBlacklistedEmail ? '' : email,
                ...(jobTitle ? { jobTitle } : {}),
            };

            Object.entries(fieldValueMap).forEach(([fieldName, fieldValue]) => {
                if (fieldValue) {
                    setValue(fieldName, fieldValue);
                }
            });
        },
        [setValue, isChangingBlacklistedEmail]
    );

    useEffect(() => {
        loadUserProfile();
    }, [loadUserProfile]);

    useEffect(() => {
        if (keycloak && initialized && !userData) {
            if (utmCampaign) {
                createUserOnSignUp({
                    utm_source: 'nova_user',
                    utm_campaign: utmCampaign,
                });
                localStorage.setItem('utmId', utmCampaign);
            } else {
                createUserOnSignUp({});
            }
        }
    }, [createUserOnSignUp, initialized, keycloak, userData, utmCampaign]);

    useEffect(() => {
        if (keycloak && initialized) {
            // If the token expires, update it automatically
            keycloak.onTokenExpired = () => keycloak.updateToken();
        }

        // Return cleanup function
        return () => {
            if (keycloak) {
                keycloak.onTokenExpired = null;
            }
        };
    }, [initialized, keycloak]);

    useEffect(() => {
        if (userProfile) {
            setFormValues(userProfile);
        }
    }, [userProfile, setFormValues]);

    useEffect(() => {
        if (userData && isChangingBlacklistedEmail) {
            setFormValues(userData);
        }
    }, [userData, isChangingBlacklistedEmail, setFormValues]);

    useEffect(() => {
        if (isSuccess && isEmailSuccess && !isEmailBlacklisted) {
            setProfileComplete(true);
            onEmailValidationChange(true);
            reset();
        }
    }, [
        isSuccess,
        setProfileComplete,
        reset,
        isEmailSuccess,
        isEmailBlacklisted,
        onEmailValidationChange,
    ]);

    useEffect(() => {
        if (isSuccess && isEmailSuccess && isEmailBlacklisted) {
            setProfileComplete(true);
            onEmailValidationChange(false);
        }
    }, [
        isSuccess,
        setProfileComplete,
        onEmailValidationChange,
        isEmailSuccess,
        isEmailBlacklisted,
    ]);

    useEffect(() => {
        if (isSuccess && isChangingBlacklistedEmail) {
            keycloak.login();
        }
    }, [isSuccess, keycloak, isChangingBlacklistedEmail]);

    useEffect(() => {
        if (isError || isEmailError) {
            toast.error('error occured while completing signup');
        }
    }, [isError, isEmailError]);

    return (
        <Container data-testid="user-info">
            <MainContainer>
                <UserInfoLogo />
                <UserInfoForm
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                    register={register}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    handleRecaptchaChange={handleRecaptchaChange}
                    recaptchaError={recaptchaError}
                    userProfile={userProfile}
                    userData={userData}
                    isChangingBlacklistedEmail={isChangingBlacklistedEmail}
                    setError={setError}
                    watch={watch}
                    clearErrors={clearErrors}
                    setValue={setValue}
                />
            </MainContainer>
            <ToastContainer />
        </Container>
    );
}

UserInfo.propTypes = {
    setProfileComplete: PropTypes.func,
    onEmailValidationChange: PropTypes.func,
    userData: PropTypes.object,
    isChangingBlacklistedEmail: PropTypes.bool,
};
