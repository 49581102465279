import { convertFromRaw, EditorState, genKey } from 'draft-js';
import { extractMentions, extractUserIds } from './extractMentions';

export const convertApiFormatToEditorState = (content, _mentions) => {
    const mentions = extractMentions(extractUserIds(content), _mentions); // for this logic to work, the mentions array must be in the same order as the ids in the text content
    const blocks = [];
    const entityMap = mentions.reduce((acc, mention, index) => {
        acc[index] = {
            data: { mention },
            mutability: 'IMMUTABLE',
            type: 'mention',
        };
        return acc;
    }, {});
    let entityKey = 0;
    let mentionIndex = 0;

    content.split('\n').forEach((block) => {
        const mentionsInBlock = [];
        const entityRanges = [];
        const blockUpdatedWithMention = block.replace(/{{(.*?)}}/g, (match) => {
            if (mentions[mentionIndex]) {
                mentionsInBlock.push(mentions[mentionIndex]);
                return mentions[mentionIndex++]?.name;
            } else {
                return match;
            }
        });
        let newIndex = 0;
        let updatedLength = 0;

        mentionsInBlock.forEach(({ name }) => {
            const mentionIndexInBlock = blockUpdatedWithMention.slice(newIndex).indexOf(name);
            const mentionLength = name.length;
            entityRanges.push({
                key: entityKey,
                length: mentionLength,
                offset: updatedLength + mentionIndexInBlock,
            });
            newIndex += mentionLength + mentionIndexInBlock;
            updatedLength += mentionLength + mentionIndexInBlock;
            entityKey++;
        });

        blocks.push({
            depth: 0,
            entityRanges,
            inlineStyleRanges: [],
            key: genKey(),
            text: blockUpdatedWithMention,
            type: 'unstyled',
        });
    });

    return EditorState.createWithContent(convertFromRaw({ blocks, entityMap }));
};
