import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMatch, useNavigate } from 'react-router-dom';

import { usePrompt } from 'hooks/usePrompt';
import { clearContacts } from '../features';
import {
    useSendConversationMessageMutation,
    useUpdateMessageMutation,
} from 'services/conversations';
import { MESSAGE_TYPES, SCHEDULED_DATE_FMT, SCHEDULED_TIME_FMT, SEND_TYPE } from 'utils/constants';
import { parseInvalidFields } from 'utils/validation/parseInvalidFields';
import { setActiveTemplateId, setTemplate } from 'features/MessageTemplate';

import { useDynamicFields } from 'features/DynamicFields';
import parse from 'date-fns/parse';
import { DEFAULT_DYNAMIC_FIELD_FALLBACKS_OPENAI } from 'features/DynamicFields/constants';
import { useTalentContact } from './useTalentContact';
import { useRTE } from './useRTE';
import { useBulkMessageTemplateText } from './useBulkMessageTemplateText';
import { useScheduledMessageDateTime } from './useScheduledMessageDateTime';
import { handleMessageSendError } from '../utils';
import { showNavigateToProjectToast } from 'views/Projects/utils/showNavigateToProjectToast';

export function generateTimestamp(scheduledDate, scheduledTime) {
    const dateString = `${scheduledDate} ${scheduledTime}`;
    const dateFormat = `${SCHEDULED_DATE_FMT} ${SCHEDULED_TIME_FMT}`;
    return parse(dateString, dateFormat, new Date()).toISOString();
}

const SIGNATURE_DIV_PREFIX = '<div id="nova-signature-block"';

export const SIGNATURE_DIV = `${SIGNATURE_DIV_PREFIX}>`;

const wrapWithElement = (htmlString) => {
    const regex = /<div>.*?<\/div>/g;
    const extractedText = htmlString.replace(regex, '');

    const wrappedText = `<div>${extractedText}</div>`;
    return htmlString.replace(extractedText, wrappedText);
};

const shouldKeepAttachment = (attachment, removedAttachmentId) => {
    return (
        attachment?.handle !== removedAttachmentId && attachment?.mediaId !== removedAttachmentId
    );
};

export function processSignature(innerHtml, signature) {
    let processedString = innerHtml;

    const offset = innerHtml.search(SIGNATURE_DIV_PREFIX); //search on prefix to avoid style overrides

    if (offset >= 0) {
        processedString = innerHtml.slice(0, offset);
        if (!processedString.includes('<')) {
            processedString = `<div>${processedString}</div>`;
        }
        processedString = wrapWithElement(processedString);
    }
    return `${processedString}<br/>${SIGNATURE_DIV}${signature.signature}</div>`;
}

function removeSignature(sourceHtml, setHtml) {
    let changes = sourceHtml?.search(SIGNATURE_DIV_PREFIX);
    if (changes >= 0) {
        setHtml(sourceHtml?.substring(0, changes));
    }
}

export function useMessageCompose(props) {
    const {
        variant = 'compose',
        messageType,
        profiles,
        conversationId,
        shortlistId,
        initialSubject,
        onChangeMessageType,
        isEditing,
        message,
        media,
        refreshMessages,
    } = props;

    const [contact, setContact] = useState({});

    const [initialBody, setInitialBody] = useState('');
    const [initialSubjectValue, setInitialSubjectValue] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [greeting, setGreeting] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [clearInput, setClearInput] = useState(false);

    const [invalidDynamicFields, setInvalidDynamicFields] = useState(false);

    const editorBodyRef = useRef();
    const editorSubjectRef = useRef();

    const { innerHtml, innerText, handleMessageBody, resetMessageBody } = useRTE({
        initialBody,
        resetInitialBody: () => setInitialBody(''),
    });

    const {
        scheduledDate,
        setScheduledDate,
        scheduledTime,
        setScheduledTime,
        openTcpaDialog,
        setOpenTcpaDialog,
        handleConfirmScheduledDate,
    } = useScheduledMessageDateTime();

    const {
        setInnerHtml: setInnerSubjectHtml,
        innerHtml: innerSubjectHtml,
        innerText: innerSubjectText,
        handleMessageBody: handleSubject,
    } = useRTE({
        initialBody: initialSubject,
        setInitialValue: setInitialSubjectValue,
    });

    const isOnCompose = useMatch('communications/compose');
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const isFrcEnabled = useSelector((state) => state.communications.isFrcEnabled);

    const [prompt, setPrompt] = usePrompt(
        'Are you sure you want to navigate away from this page? Your changes will not be saved.'
    );

    const {
        defaultDynamicFields,
        handleRetrieveDefaultDynamicFields,
        handleResetDynamicFields,
        addDefaultDynamicFields,
    } = useDynamicFields();

    const skipContactEmails =
        messageType === MESSAGE_TYPES.EMAIL && !innerSubjectText && !innerText;
    const skipContactSms = messageType === MESSAGE_TYPES.SMS && !innerText;
    const talentContact = useTalentContact({
        conversationId,
        messageType,
        profiles,
        contact,
        skip: skipContactEmails || skipContactSms,
    });

    const [sendMessage, { isLoading: isSending }] = useSendConversationMessageMutation();
    const [updateMessage, { isLoading: isUpdating }] = useUpdateMessageMutation();

    const validationText = messageType === MESSAGE_TYPES.EMAIL ? innerHtml : innerText;

    const setSignature = useCallback(
        (signature) => {
            const signatureBody = processSignature(innerHtml, signature);
            setInitialBody(signatureBody);
        },
        [innerHtml]
    );

    useBulkMessageTemplateText({
        onSuccess: setInitialBody,
        showBulkMessageText:
            !isFrcEnabled &&
            !profiles.every((profile) => {
                const key = messageType === MESSAGE_TYPES.SMS ? 'phone' : messageType;
                return !profile?.[key] || profile[key]?.length === 1;
            }),
    });

    const handleContact = useCallback((value) => {
        setContact(value);
    }, []);

    useEffect(() => {
        const combinedText = `${editorSubjectRef.current?.plainText()} ${editorBodyRef.current?.plainText()}`;
        handleRetrieveDefaultDynamicFields(combinedText);
    }, [innerHtml, innerText, innerSubjectHtml, innerSubjectText]);

    const handleGreeting = (value) => {
        setGreeting(value);
    };

    const resetMessage = useCallback(() => {
        resetMessageBody();
        setInitialBody('');
        setScheduledDate('');
        setScheduledTime('');
        setGreeting(false);
        setAttachments([]);
        handleResetDynamicFields();
    }, [resetMessageBody, handleResetDynamicFields]);

    const handleUseTemplate = (template) => {
        const { subject, body, signature, messageTypes } = template || {};

        let templateBody = `<div>${body}</div>`;
        if (signature?.signature && messageType === MESSAGE_TYPES.EMAIL) {
            templateBody += `<br/>${signature.signature}`;
        }
        dispatch(setActiveTemplateId(template.templateId));
        dispatch(setTemplate(template));
        setInnerSubjectHtml(subject);
        setInitialSubjectValue(subject);
        setInitialBody(templateBody);
        const isEmailOrSmsType = messageTypes?.length > 1 || false;
        let targetMessageType = messageTypes?.[0] || MESSAGE_TYPES.EMAIL;
        if (isEmailOrSmsType && messageType) {
            targetMessageType = messageType;
        }
        onChangeMessageType?.(targetMessageType);
    };

    useEffect(() => {
        const bodyRenderedHtml = editorBodyRef.current?.innerHTML();
        const subjectRenderedHtml = editorSubjectRef.current?.innerHTML();
        setInvalidDynamicFields(
            parseInvalidFields(bodyRenderedHtml, subjectRenderedHtml, messageType)
        );
    }, [validationText, innerSubjectHtml, messageType]);

    useEffect(() => {
        if (isSent && !prompt && variant !== 'reply') {
            navigate('/communications');
        }
    }, [variant, prompt, isSent, navigate]);

    useEffect(() => {
        if (variant === 'reply') {
            setPrompt(false);
        }
    }, [variant, setPrompt]);

    useEffect(() => {
        // initializes message reset when changing conversations
        if (conversationId && !isEditing) {
            setClearInput(true);
        }
    }, [conversationId, isEditing]);

    useEffect(() => {
        // resets messages
        if (clearInput && (innerText === '' || innerHtml === '<br/>' || Boolean(initialBody))) {
            resetMessage();
            setClearInput(false);
        }
    }, [clearInput, innerText, innerHtml, initialBody, resetMessage]);

    useEffect(() => {
        if (media) setAttachments(media);
    }, [media]);

    useEffect(() => {
        setInitialBody(message);
    }, [message]);

    useEffect(() => {
        //ensure to reset so that emails aren't affected
        setContact({});
    }, [messageType]);

    useEffect(() => {
        if (messageType === MESSAGE_TYPES.SMS) {
            removeSignature(innerHtml, setInitialBody);
        }
    }, [innerHtml, messageType]);

    useEffect(() => {
        if (greeting && !defaultDynamicFields?.['{{firstName}}']) {
            addDefaultDynamicFields({
                name: '{{firstName}}',
                value: DEFAULT_DYNAMIC_FIELD_FALLBACKS_OPENAI['{{firstName}}'],
            });
        }
    }, [addDefaultDynamicFields, greeting, defaultDynamicFields]);

    const handleResponse = (res, type, isComposing, onSuccess, disableRefresh) => {
        if (res?.error) {
            handleMessageSendError(res?.error, type, setOpenTcpaDialog);
        } else {
            handleSuccess({ isComposing, disableRefresh, res });
            dispatch(setActiveTemplateId(''));
            onSuccess?.(res);
            showNavigateToProjectToast();
        }
    };

    const handleSuccess = ({ isComposing, disableRefresh, res }) => {
        if (isComposing) {
            setIsSent(true);
            dispatch(clearContacts());
        }
        resetMessage();
        toast.success('Your message will be sent');
        setPrompt(false);
        setClearInput(true);
        if (!disableRefresh) {
            refreshMessages?.(res);
        }
    };

    const handleRemoveMessageAttachment = (removedAttachmentId) => {
        setAttachments((attachments) =>
            attachments.filter((attachment) =>
                shouldKeepAttachment(attachment, removedAttachmentId)
            )
        );
    };

    const handleSend = async (args = {}) => {
        const { messageId, onSuccess, disableRefresh } = args;
        let prefix = '';

        if (greeting) {
            prefix = '<div>Hi {{firstName}},</div>';
        }

        const html = `${prefix}${innerHtml}`;

        const to = [];
        const bulkContacts = {};

        profiles?.forEach((profile) => {
            to.push(profile?.talentId || profile?.id);
            if (profiles.length > 1) {
                bulkContacts[profile?.talentId] = {
                    email: profile?.email,
                    phone: profile?.phone,
                };
            }
        });

        let sendType = SEND_TYPE.TRAVERSAL;
        if (!isFrcEnabled) sendType = SEND_TYPE.DIRECT;

        const sendArgs = {
            from: user.id,
            subject: innerSubjectText,
            to,
            conversationId,
            defaultDynamicFields,
            customFields: profiles.length > 1 ? bulkContacts : talentContact,
            candidateList: shortlistId,
            message: messageType === MESSAGE_TYPES.SMS ? innerText : html,
        };

        const updateArgs = {
            media: attachments.map(({ mediaId }) => mediaId),
            messageType,
            defaultDynamicFields,
            sendType,
        };

        if (messageId) {
            updateArgs.body = sendArgs.message;
            updateArgs.subject = sendArgs.subject;
        }

        if (scheduledDate && scheduledTime) {
            updateArgs.sendDate = generateTimestamp(scheduledDate, scheduledTime);
        }

        const payload = messageId
            ? { messageType, messageId, conversationId, body: updateArgs }
            : { ...sendArgs, ...updateArgs };

        const action = messageId ? updateMessage : sendMessage;

        const res = await action(payload);

        handleResponse(res, messageType, isOnCompose, onSuccess, disableRefresh);
    };

    return {
        innerText,
        innerHtml,
        initialBody,
        attachments,
        isSending: isSending || isUpdating,
        clearInput,
        handleSend,
        handleSubject,
        handleGreeting,
        handleMessageBody,
        handleContact,
        handleConfirmScheduledDate,
        setAttachments,
        handleUseTemplate,
        setClearInput,
        setSignature,
        openTcpaDialog,
        setOpenTcpaDialog,
        setInitialBody,
        initialSubjectValue,
        innerSubjectText,
        innerSubjectHtml,
        editorBodyRef,
        editorSubjectRef,
        invalidDynamicFields,
        handleRemoveMessageAttachment,
    };
}
