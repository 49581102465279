import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Typography } from 'components/Typography';
import { retrieveTempTalentIdFilter } from 'features/MessageDrawer/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeTalentIdsFilter } from 'views/Communications/communicationsSlice';

const IndicatorContainer = styled(Box)(({ theme }) => ({
    height: theme.spacing(4),
    minHeight: theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFDA7C66',
    gap: theme.spacing(2),
    position: 'relative',
}));

export function FilterByTalentIdsIndicator() {
    const dispatch = useDispatch();

    const filteredTalentIds = useSelector((state) => state.communications.filters.talentIds);

    const handleCloseTab = () => {
        window.top.location.reload();
        window.top.close();
    };

    useEffect(() => {
        const talentIds = retrieveTempTalentIdFilter();
        if (talentIds && !filteredTalentIds?.length) {
            if (talentIds) {
                dispatch(changeTalentIdsFilter(talentIds));
            }
        }
    }, []); // eslint-disable-line

    if (!filteredTalentIds?.length) {
        return null;
    }

    return (
        <IndicatorContainer>
            <Typography variant="bodyBase">
                You're viewing messages from only your selected candidates
            </Typography>
            <ButtonBase data-testid="go-back-to-messages-button" onClick={handleCloseTab}>
                <Typography variant="bodyBase" color="primary.main">
                    Go back to message
                </Typography>
            </ButtonBase>
        </IndicatorContainer>
    );
}
