import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from 'components/Typography';

import { MESSAGE_TYPES } from 'utils/constants';
import { useTemplateSignature } from 'features/Signature';
import { MessageTemplate } from 'features/MessageTemplate';
import { useTemplateButton } from 'features/MessageTemplate/hooks';

import { useNewMediaAttachmentsModal } from 'views/UserSettings/features/YourMedia/components/MediaModals/hooks/useNewMediaAttachmentsModal';
import { EntityConnectionModal } from 'views/UserSettings/features/YourMedia/components/MediaModals/EntityConnectionModal';
import { ENTITY_TYPE_ENUMS } from 'utils/entityEnums';
import { useMessageData } from 'features/MessageDrawer/hooks/useMessageData';
import { useMessageAttachments } from 'features/MessageDrawer/hooks/useMessageAttachments';
import { MessageToolbar } from './MessageToolbar';

export function ComposeHeader(props) {
    const { variant = 'compose' } = props;
    const {
        isCollapsed,
        isFormatting,
        body,
        subject,
        messageType,
        handleToggleFormatting,
        handleUseTemplate,
        handleClearMessage,
        handleSetSignature,
        isGeneratingMessage,
    } = useMessageData();

    const {
        messageTemplateOpen,
        isCreateTemplateDisabled,
        isFetchingTemplates,
        handleOpenTemplate,
        handleCloseTemplate,
        handleTemplateListsView,
        handleTemplateCreateView,
        currentTemplateView,
        templates,
    } = useTemplateButton();

    const { attachments, handleSetAttachments } = useMessageAttachments();

    const {
        attachments: selectedAttachments,
        setAttachments: setSelectedAttachments,
        isModalOpen,
        handleOpen,
        handleClose,
        handleConfirm,
        handleOpenPicker,
        clearAttachments,
        handleRemoveAttachment,
    } = useNewMediaAttachmentsModal({ messageType });

    const { signatureData, isFetchingData, handleRefreshSignature } = useTemplateSignature(
        messageType !== MESSAGE_TYPES.EMAIL
    );

    const isToolbarDisabled = messageType === MESSAGE_TYPES.SMS;

    const onConfirm = (mediaIds, displayNames) => {
        handleSetAttachments(handleConfirm(mediaIds, displayNames));
    };

    const onUploadNewMedia = (currentFilesCount) => {
        handleClose();
        handleOpenPicker(currentFilesCount);
    };

    const onReplaceAttachment = () => {
        handleClose();
        handleOpenPicker();
        clearAttachments();
    };

    return (
        <>
            <Box
                sx={{
                    mb: variant === 'reply' && 1,
                }}
            >
                {variant === 'compose' && (
                    <Typography variant="labelSmall" sx={{ pr: 2.5 }}>
                        MESSAGE
                    </Typography>
                )}
                <MessageToolbar
                    body={body}
                    subject={subject}
                    isCollapsed={isCollapsed}
                    isToolbarDisabled={isToolbarDisabled}
                    isFormatting={isFormatting}
                    attachments={attachments}
                    handleOpen={handleOpen}
                    messageType={messageType}
                    signatureData={signatureData}
                    isFetchingData={isFetchingData}
                    handleSetSignature={handleSetSignature}
                    handleRefreshSignature={handleRefreshSignature}
                    handleToggleFormatting={handleToggleFormatting}
                    handleOpenTemplate={handleOpenTemplate}
                    isCreateTemplateDisabled={isCreateTemplateDisabled}
                    isFetchingTemplates={isFetchingTemplates}
                    handleTemplateCreateView={handleTemplateCreateView}
                    handleTemplateListsView={handleTemplateListsView}
                    handleUseTemplate={handleUseTemplate}
                    templates={templates}
                    isGeneratingMessage={isGeneratingMessage}
                />
            </Box>
            <MessageTemplate
                open={messageTemplateOpen}
                onClose={handleCloseTemplate}
                templates={templates}
                currentTemplateView={currentTemplateView}
                isCreateTemplateDisabled={isCreateTemplateDisabled}
                isFetchingTemplates={isFetchingTemplates}
                onTemplateCreateView={handleTemplateCreateView}
                onUseTemplate={handleUseTemplate}
                onTemplateListsView={handleTemplateListsView}
                messageType={messageType}
                setClearInput={handleClearMessage}
            />
            <EntityConnectionModal
                initialAttachments={attachments}
                entityType={ENTITY_TYPE_ENUMS.CONVERSATION.label}
                isOpen={isModalOpen}
                onClose={handleClose}
                onUploadNewMedia={onUploadNewMedia}
                attachments={selectedAttachments}
                clearAttachments={clearAttachments}
                onRemoveAttachment={handleRemoveAttachment}
                onReplaceAttachment={onReplaceAttachment}
                setAttachments={setSelectedAttachments}
                onConfirm={onConfirm}
                confirmText="Attach media to message"
                messageType={messageType}
            />
        </>
    );
}

ComposeHeader.propTypes = {
    variant: PropTypes.string,
};
