import SvgIcon from '@mui/material/SvgIcon';

export function ChevronUpIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.8286 7.81234C3.07882 8.06255 3.4845 8.06255 3.73472 7.81234L6 5.54706L8.26528 7.81234C8.5155 8.06255 8.92118 8.06255 9.17139 7.81234C9.42161 7.56212 9.42161 7.15644 9.17139 6.90623L6.45305 4.18789C6.20284 3.93767 5.79716 3.93767 5.54694 4.18789L2.82861 6.90622C2.57839 7.15644 2.57839 7.56212 2.8286 7.81234Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
