export const AI_TOOLS_PATHS = [
    {
        label: 'JD Builder',
        to: 'job-description-builder',
        state: { resetPrompts: true },
        trackId: 'ai-tools-menu-jd-builder',
    },
];

export const CONVERSATIONS_PATHS = [
    {
        label: 'Messaging Home',
        to: 'communications',
        trackId: 'messaging-menu-messaging-home',
    },
];

export const SHORTLIST_PATHS = [
    { label: 'Your Shortlists', to: 'shortlist' },
    { label: 'Review Prospects', to: 'shortlist-feedback' },
];

export const SEARCH_PATHS = [
    { label: 'Start a New Search', to: 'search', trackId: 'search-menu-start-a-new-search' },
    { label: 'Search History', to: 'search-history', trackId: 'search-menu-search-history' },
];

export const PROJECTS_PATHS = [
    { label: 'Your Projects', to: 'projects', trackId: 'projects-menu-your-projects' },
    {
        label: 'Create a New Project',
        to: 'projects?open_modal=true',
        trackId: 'projects-menu-create-a-new-project',
    },
];
