import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { ContactDetailsOverview } from './ContactDetailsOverview';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';
import { handleOpenModal } from 'features/TalentCard/ContactDetails/contactDetailSlice';

export function ContactDetails(props) {
    const { talent, isLoading, onlySocialIcon, dataTrackIds } = props;

    const talentId = talent?.talentId || 'unspecified';
    const { contactCounts } = talent || {};

    const dispatch = useDispatch();

    const [logSearchEvent] = useSearchTelemetry();

    const handleOpen = useCallback(
        (tabIndex) => {
            dispatch(
                handleOpenModal({
                    talentId,
                    selectedTalent: talent,
                    initialTab: tabIndex,
                })
            );
            logSearchEvent(SEARCH_EVENT_TYPES.OPEN_CONTACT, { profileId: talentId });
        },
        [dispatch, talent, talentId, logSearchEvent]
    );

    return (
        <>
            <ContactDetailsOverview
                counts={contactCounts}
                isLoading={isLoading}
                onClick={handleOpen}
                onlySocialIcon={onlySocialIcon}
                dataTrackIds={dataTrackIds}
            />
        </>
    );
}

ContactDetails.propTypes = {
    talent: PropTypes.object,
    isLoading: PropTypes.bool,
    onlySocialIcon: PropTypes.bool,
    dataTrackIds: PropTypes.object,
};
