import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const messagingWorkflowApi = createApi({
    reducerPath: 'messagingWorkflowApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/messaging-workflow/v1',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getNodePathMetrics: builder.query({
            query: (sequenceId) => ({
                url: `/metrics/${sequenceId}/nodes`,
            }),
        }),
    }),
});

export const { useGetNodePathMetricsQuery } = messagingWorkflowApi;
