import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCurrentKeycloakClient } from 'features/Authentication';
import { useSwitchboard } from 'features/Switchboard';
import { theme } from 'theme';
import { useFilteredItems } from './hooks/useFilteredItems';
import { productLinkActions } from './navigationConstants';

/* 
  These Actions are only visible if the user has the nova-quicklaunch-admin role.
  They are added on the end of the list but are searchable like all other items
*/
const adminOnlyActions = (user) => [
    {
        name: 'Toggle Forced Flow',
        color: theme.palette.secondary.main,
        action: async () => {
            const keycloak = getCurrentKeycloakClient();
            const token = keycloak.token;
            const url = `/api/user-mgmt/v1/users/update/${user.id}`;
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };

            try {
                await fetch(url, {
                    method: 'PUT',
                    headers: headers,
                    body: JSON.stringify({
                        settings: {
                            forcedFlow: !user.settings.forcedFlow,
                        },
                    }),
                });
                window.location.reload();
            } catch (e) {
                return null;
            }
        },
    },
];

/* 
  These Actions are only visible if the user has the nova-quicklaunch-dev role.
  They are added on the end of the list but are searchable like all other items
*/

const devOnlyActions = (user) => [
    {
        name: 'Copy Keycloak Token',
        color: theme.palette.secondary.main,
        action: () => {
            const keycloak = getCurrentKeycloakClient();
            navigator.clipboard.writeText(keycloak.token);
        },
    },
    {
        name: 'Toggle Mock Service Worker',
        color: theme.palette.secondary.main,
        action: () => {
            if (localStorage.getItem('mswState') === 'enabled') {
                localStorage.setItem('mswState', 'disabled');
            } else {
                localStorage.setItem('mswState', 'enabled');
            }
            window.location.reload();
        },
        enabled: () => {
            return process.env.NODE_ENV === 'development';
        },
    },
];

/**
 * ActionList defines what's visible in the list in the ClippyModal
 * If there is no searchterm we show recent history and products.
 * If there is a searchterm we filter all items to match the searchterm
 */
export function ActionList({ searchTerm, setOpen, user, maxItems }) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();
    const isNovaQuicklaunchDev = user?.isNovaQuicklaunchDev || false;
    const isNovaQuicklaunchAdmin = user?.isNovaQuicklaunchAdmin || false;
    const isPathsEnabled = useSwitchboard('FEATURE_MESSAGE_SEQUENCING');
    const isTalkTrackEnabled = useSwitchboard('FEATURE_TALKTRACKS');
    const isProjectsFeatureEnabled = useSwitchboard('FEATURE_PROJECTS');

    const filteredItems = useFilteredItems(
        productLinkActions,
        devOnlyActions(user),
        adminOnlyActions(user),
        searchTerm,
        {
            isPathsEnabled,
            isTalkTrackEnabled,
            isProjectsFeatureEnabled,
            isNovaQuicklaunchDev,
            isNovaQuicklaunchAdmin,
        }
    );

    // If an Item has an action property, call it
    // If an Item has a url redirect to that url.
    const handleListExecute = useCallback(
        (index) => {
            setSelectedIndex(index);
            const item = filteredItems[index];
            setOpen(false);
            if ('url' in item) {
                navigate(item.url);
            } else if ('action' in item) {
                item['action']();
            }
        },
        [filteredItems, navigate, setOpen]
    );

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowUp') {
                setSelectedIndex((currentSelected) => {
                    let newSelected = currentSelected - 1;
                    if (newSelected < 0) {
                        newSelected = filteredItems.length - 1;
                    }
                    return newSelected;
                });
            } else if (event.key === 'ArrowDown') {
                setSelectedIndex((currentSelected) => {
                    let newSelected = currentSelected + 1;
                    if (newSelected >= filteredItems.length) {
                        newSelected = 0;
                    }
                    return newSelected;
                });
            } else if (event.key === 'Enter') {
                handleListExecute(selectedIndex);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [filteredItems, selectedIndex, handleListExecute]);

    return (
        <List component="nav">
            {filteredItems.map((item, index) => (
                <ListItem
                    button
                    selected={selectedIndex === index}
                    onClick={() => handleListExecute(index)}
                    sx={{
                        bgcolor: selectedIndex === index ? 'primary.main' : 'transparent',
                    }}
                    key={index}
                >
                    <ListItemText
                        primary={
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: selectedIndex === index ? 'bold' : 'normal',
                                    color: 'color' in item ? item['color'] : 'black',
                                }}
                            >
                                {item.name}
                            </Typography>
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
}

ActionList.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    setOpen: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    maxItems: PropTypes.number.isRequired,
};
