import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import CircularProgress from '@mui/material/CircularProgress';

import { cooldownBlue } from 'theme';
import { Tooltip } from 'components/Tooltip';
import { MESSAGE_TYPES } from 'utils/constants';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { ActionsMenuItem } from 'components/ActionsMenu';
import { TextIcon, EmailIcon, CooldownIcon } from 'components/Icons';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';
import { useContext } from 'react';
import { TalentCardContext } from 'features/TalentCardModular/TalentCardContext';
import { useTalentProfileStore } from 'features/TalentProfileDrawer/hooks/store';

const TOOLTIP_TITLE = 'Connect your email account to engage with candidates';

function Spinner({ sx }) {
    return <CircularProgress size="1em" sx={{ color: 'text.primary', ...sx }} />;
}

Spinner.propTypes = {
    sx: PropTypes.object,
};

export function MessageButtons(props) {
    const {
        talentId,
        talentIds,
        onMessage,
        isLoading,
        disabled,
        isLookupLoading,
        smsPoliciesViolation,
        user,
        variant,
        contactCounts,
    } = props;

    const { profiles, profileIndex } = useContext(TalentCardContext);

    const { setInitialProfileId } = useTalentProfileStore((state) => state);

    const isBulk = variant === 'bulk';

    const isDisabled = disabled || !user?.isEmailConnected || isLoading;

    const disabledTooltip = user?.isEmailConnected ? '' : TOOLTIP_TITLE;

    const [logSearchEvent] = useSearchTelemetry();

    return (
        <>
            <Tooltip
                title={smsPoliciesViolation?.policyViolationReason || disabledTooltip}
                placement="right"
                arrow
            >
                <span>
                    <ActionsMenuItem
                        aria-label={isBulk ? 'bulk-compose-sms' : `compose-sms-${talentId}`}
                        data-trackid={isBulk ? 'bulk-actions-sms' : 'talent-actions-sms'}
                        onClick={() => {
                            if (Boolean(profiles?.length))
                                setInitialProfileId(profiles[profileIndex].profileId);
                            onMessage(MESSAGE_TYPES.SMS);
                            logSearchEvent(SEARCH_EVENT_TYPES.SMS_CLICK, {
                                profileIds: isBulk ? talentIds : [talentId],
                            });
                        }}
                        label="Send an SMS"
                        Icon={
                            isLookupLoading
                                ? Spinner
                                : smsPoliciesViolation?.somePolicyViolated
                                ? CooldownIcon
                                : TextIcon
                        }
                        IconProps={
                            smsPoliciesViolation?.somePolicyViolated ? { color: cooldownBlue } : {}
                        }
                        disabled={
                            isLookupLoading ||
                            isDisabled ||
                            smsPoliciesViolation?.allPolicyViolated ||
                            (!isBulk &&
                                !isNil(contactCounts?.['phones']) &&
                                contactCounts?.['phones'] === 0)
                        }
                    />
                </span>
            </Tooltip>

            <Tooltip title={disabledTooltip} placement="right" arrow>
                <span>
                    <ActionsMenuItem
                        aria-label={isBulk ? 'bulk-compose-email' : `compose-email-${talentId}`}
                        data-trackid={isBulk ? 'bulk-actions-email' : 'talent-actions-email'}
                        onClick={() => {
                            if (Boolean(profiles?.length))
                                setInitialProfileId(profiles[profileIndex].profileId);
                            onMessage(MESSAGE_TYPES.EMAIL);
                            logSearchEvent(SEARCH_EVENT_TYPES.EMAIL_CLICK, {
                                profileIds: isBulk ? talentIds : [talentId],
                            });
                        }}
                        disabled={
                            isLookupLoading ||
                            isDisabled ||
                            (!isBulk &&
                                !isNil(contactCounts?.['emails']) &&
                                contactCounts?.['emails'] === 0)
                        }
                        label="Send an Email"
                        Icon={isLookupLoading ? Spinner : EmailIcon}
                    />
                </span>
            </Tooltip>
        </>
    );
}

MessageButtons.propTypes = {
    user: PropTypes.object,
    variant: PropTypes.string,
    talentId: PropTypes.string,
    talentIds: PropTypes.array,
    onMessage: PropTypes.func,
    fullWidth: PropTypes.bool,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    isLookupLoading: PropTypes.bool,
    smsPoliciesViolation: PropTypes.object,
    handleCloseMenu: PropTypes.func,
    contactCounts: PropTypes.shape({
        emails: PropTypes.number,
        phones: PropTypes.number,
        socials: PropTypes.number,
    }),
};
