import { styled } from '@mui/material/styles';

export const ViewContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'inner' })(
    ({ theme, inner }) => ({
        height: '100vh',
        maxHeight: inner ? `calc(100vh - ${theme.mixins.toolbar.minHeight}px)` : '100vh',
        width: '100vw',
        maxWidth: '100vw',
        overflow: 'hidden',
        background: theme.palette.background.default,
    })
);
