import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import { Tooltip } from 'components/Tooltip';
import { SequenceIcon } from 'components/Icons';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { useCustomQuery } from 'hooks/useCustomQuery';
import { useSwitchboard } from 'features/Switchboard';
import { ActionsMenuItem } from 'components/ActionsMenu';
import { getSequencesApi } from 'views/Sequences/services';
import { ENROLL_CHANNELS } from 'views/Enroll/utils/constants';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';

export function SequenceButton(props) {
    const { talents, source, user, contactCounts, variant } = props;
    const navigate = useNavigate();
    const isWorkflowEnabled = useSwitchboard('FEATURE_MESSAGE_SEQUENCING');
    const isEmailConnected = user?.isEmailConnected;

    const [logSearchEvent] = useSearchTelemetry();

    const { data } = useCustomQuery(
        ['sequences', { type: 'sequence', count: true }],
        getSequencesApi,
        {
            refetchOnWindowFocus: false,
            disabled: isWorkflowEnabled,
        }
    );

    const hasContacts =
        talents?.length === 1 ? contactCounts?.emails || contactCounts?.phones : true;

    const isDisabled =
        data?.totalResults === 0 || !talents?.length || !isEmailConnected || !hasContacts;

    const isBulk = variant === 'bulk';

    const getTooltipTitle = () => {
        if (!isEmailConnected) {
            return 'Connect your email account to engage with candidates';
        } else if (!hasContacts) {
            return isNull(contactCounts?.emails)
                ? 'Perform a lookup to enable this feature'
                : 'Cannot use this feature with this talent';
        } else {
            // eslint-disable-next-line quotes
            return "You don't have active sequences";
        }
    };

    const handleClick = () => {
        logSearchEvent(SEARCH_EVENT_TYPES.PATHS_CLICK, {
            profileIds: talents.map((talent) => talent.talentId),
        });
        navigate('/enroll', {
            state: {
                talents: talents.map((talent) => ({
                    talentId: talent.talentId,
                    profileId: talent.profileId,
                })),
                sourceURL: window.location.pathname,
                source: source || 'direct',
                channel: ENROLL_CHANNELS.SEQUENCES,
            },
        });
    };

    const button = (
        <ActionsMenuItem
            data-testid="sequence-button"
            data-trackid={isBulk ? 'bulk-actions-paths' : 'talent-actions-paths'}
            disabled={isDisabled}
            onClick={handleClick}
            Icon={SequenceIcon}
            label="Sequences"
        />
    );

    if (!isWorkflowEnabled) {
        return null;
    }

    if (isDisabled) {
        return (
            <Tooltip title={getTooltipTitle()} placement="right" arrow>
                <Box display="inline-block">{button}</Box>
            </Tooltip>
        );
    }

    return button;
}

SequenceButton.propTypes = {
    fullWidth: PropTypes.bool,
    talents: PropTypes.arrayOf(PropTypes.object),
    style: PropTypes.object,
    source: PropTypes.string,
    iconOnly: PropTypes.bool,
    user: PropTypes.object,
    contactCounts: PropTypes.object,
    variant: PropTypes.string,
};
