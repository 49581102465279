import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { ChevronDownIcon } from 'components/Icons';
import { MediaSectionLabel } from 'views/UserSettings/features/YourMedia/components/MediaModals/MediaSectionLabel';
import { MESSAGE_SOURCE_OPTIONS } from 'features/MessageDrawer/utils';
import { FormControl } from '@mui/material';

export function MessageSourceSelector(props) {
    const { initialSource, value, handleChangeSource, resetSourceId } = props;

    useEffect(() => {
        if (value === '' && initialSource) {
            handleChangeSource({ target: { value: initialSource } });
        }
    }, [initialSource, handleChangeSource, value]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <MediaSectionLabel>CHOOSE A SOURCE</MediaSectionLabel>
            <FormControl>
                <Select
                    displayEmpty
                    value={value}
                    onChange={(event) => {
                        handleChangeSource(event);
                        resetSourceId();
                    }}
                    IconComponent={ChevronDownIcon}
                    inputProps={{
                        'aria-label': 'message source options',
                        'data-testid': 'message-gen-source-options',
                        sx: {
                            color: value === '' ? 'text.disabled' : 'text.primary',
                            py: 1.5,
                        },
                    }}
                    sx={{
                        '.MuiSelect-icon': {
                            color: 'primary.main',
                        },
                    }}
                >
                    {value === '' && (
                        <MenuItem
                            value=""
                            sx={{
                                color: 'text.disabled',
                                opacity: '1 !important',
                                background: 'transparent !important',
                            }}
                            disabled
                        >
                            Select one...
                        </MenuItem>
                    )}
                    <MenuItem
                        data-testid={`${MESSAGE_SOURCE_OPTIONS.talentJobDescription.value}-option`}
                        value={MESSAGE_SOURCE_OPTIONS.talentJobDescription.value}
                    >
                        {MESSAGE_SOURCE_OPTIONS.talentJobDescription.label}
                    </MenuItem>
                    <MenuItem
                        data-testid={`${MESSAGE_SOURCE_OPTIONS.talentSavedSearch.value}-option`}
                        value={MESSAGE_SOURCE_OPTIONS.talentSavedSearch.value}
                    >
                        {MESSAGE_SOURCE_OPTIONS.talentSavedSearch.label}
                    </MenuItem>
                    <MenuItem
                        data-testid={`${MESSAGE_SOURCE_OPTIONS.talentSearchHistory.value}-option`}
                        value={MESSAGE_SOURCE_OPTIONS.talentSearchHistory.value}
                    >
                        {MESSAGE_SOURCE_OPTIONS.talentSearchHistory.label}
                    </MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

MessageSourceSelector.propTypes = {
    initialSource: PropTypes.string,
    value: PropTypes.string,
    handleChangeSource: PropTypes.func,
    resetSourceId: PropTypes.func,
};
