import PropTypes from 'prop-types';
import Box from '@mui/system/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { Typography } from 'components/Typography';
import { formatISODate } from 'utils/formatters';

const ListItemContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

const DialogButton = styled(Button)(({ theme }) => ({
    boxSizing: 'border-box',
    width: theme.spacing(10),
    textTransform: 'unset',
}));

export function PreviousContactDialog(props) {
    const { open, onClose, onConfirm, candidates, isSingleCandidate } = props;

    return (
        <Dialog onClose={onClose} open={open} aria-label="contact-dialog">
            <DialogTitle>Previously Contacted Candidates</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You or someone else in your organization has previously contacted
                    {isSingleCandidate ? ' this candidate' : ' some candidates'}. Do you still want
                    to proceed with this message?
                </DialogContentText>
                {!isSingleCandidate && candidates?.length && (
                    <List>
                        {candidates.map((candidate) => (
                            <ListItem key={candidate.id}>
                                <ListItemContainer>
                                    <Typography>{candidate.name}</Typography>
                                    <Typography>
                                        Last Contacted:&nbsp;
                                        {formatISODate(candidate.lastUserEmailDate)}
                                    </Typography>
                                </ListItemContainer>
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <DialogButton aria-label="contact-dialog-back" onClick={onClose}>
                    <Typography variant="titleSmall" color="textSecondary">
                        Go Back
                    </Typography>
                </DialogButton>
                <DialogButton aria-label="contact-dialog-confirm" onClick={onConfirm}>
                    <Typography variant="titleSmall" color="primary">
                        Confirm
                    </Typography>
                </DialogButton>
            </DialogActions>
        </Dialog>
    );
}

PreviousContactDialog.propTypes = {
    open: PropTypes.bool,
    isSingleCandidate: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    candidates: PropTypes.array,
};
