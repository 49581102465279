import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTalentProfileDrawer } from './hooks/useTalentProfileDrawer';
import { ProfileTabs } from './components/ProfileTabs';
import { AllTalentNotes } from './components/AllTalentNotes';
import { TalentProfileDrawerHeader } from './components/TalentProfileHeader';
import { useTalentNotesInfo } from './hooks/useTalentNotesInfo';
import { useTalentProfileInfo } from './hooks/useTalentProfileInfo';
import { TalentProfileInfo } from './components/TalentProfileInfo';
import { ProfileAttachments } from './components/ProfileAttachments';
import { useSwitchboard } from 'features/Switchboard';
import { useGetMediaQuery } from 'services/knowledgeBases';
import { ProfileActivity } from './components/ProfileActivity';
import { useGetActivityQuery } from 'services/activity';
import { TALENT_DRAWER_TABS } from 'utils/constants';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function TalentProfileDrawerContent({ onClose, mentionEditorRef, inMessageDrawer = false }) {
    const isMediaFeatureEnabled = useSwitchboard('FEATURE_MEDIA_MANAGEMENT');
    const isActivityTrackingFeatureEnabled = useSwitchboard('FEATURE_ACTIVITY_TRACKING');
    const { showTalentProfile } = useSelector((state) => state.message);

    const { pathname } = useLocation();

    const {
        isTalentProfileDrawerOpen,
        closeTalentProfileDrawer,
        currentTalentId,
        config,
        talentProfile,
        contactMeta,
        isFetchingTalentData,
        getTalentProfileData,
        getContactMeta,
        disablePrevTalentProfileButton,
        disableNextTalentProfileButton,
        setNextTalentId,
        setPreviousTalentId,
        talentProfiles,
        setSelectedTalentProfile,
    } = useTalentProfileDrawer({ inMessageDrawer });


    const [currentTab, setCurrentTab] = useState(TALENT_DRAWER_TABS.PROFILE_TAB);
    const [sortByLastUpdated, setSortByLastUpdated] = useState('desc');
    const [activitiesCount, setActivitiesCount] = useState(0);
    const [attachmentsCount, setAttachmentsCount] = useState(0);

    const { activityParams } = useSelector((state) => state.activity);

    const { isFetchingData, talentData, isExperienceDataHidden } = useTalentProfileInfo({
        isTalentProfileDrawerOpen,
        currentTalentId,
        getTalentProfileData,
        getContactMeta,
        isFetchingTalentData,
        contactMeta,
        talentProfile,
    });

    const {
        handleOptimisticDelete,
        newNotes,
        isLoading,
        talentNotesCountObj,
        setFilter,
        setIsAscMode,
        setNotes,
    } = useTalentNotesInfo({
        shouldFetchData: (isTalentProfileDrawerOpen && currentTalentId) || showTalentProfile,
        currentTab,
        currentTalentId,
    });

    const { data: media, isLoading: isFetchingMedia } = useGetMediaQuery(
        {
            talentId: currentTalentId,
            sortByLastUpdated,
        },
        {
            skip: !currentTalentId || inMessageDrawer,
        }
    );

    const { data: activityData, isLoading: isFetchingActivity } = useGetActivityQuery(
        {
            params: { ...activityParams, talentIds: [currentTalentId] },
        },
        {
            skip: !currentTalentId || inMessageDrawer,
        }
    );

    useEffect(() => {
        if (isFetchingData) {
            setActivitiesCount(0);
            setAttachmentsCount(0);
        }
    }, [isFetchingData]);

    useEffect(() => {
        if (config) {
            const openDefaultTab = config?.defaultOpenTab || TALENT_DRAWER_TABS.PROFILE_TAB;
            setCurrentTab(openDefaultTab);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTalentId, config]);

    const handleChangeTab = (_, newValue) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        return () => {
            closeTalentProfileDrawer(false);
        };
    }, [closeTalentProfileDrawer, pathname]);

    useEffect(() => {
        if (!isTalentProfileDrawerOpen && !showTalentProfile) {
            setCurrentTab(TALENT_DRAWER_TABS.PROFILE_TAB);
            setIsAscMode(true);
            setFilter('any');
        }
    }, [isTalentProfileDrawerOpen, setFilter, setIsAscMode, showTalentProfile]);

    return (
        <>
            <TalentProfileDrawerHeader
                setSelectedTalentProfile={setSelectedTalentProfile}
                currentTab={currentTab}
                selectedProfile={talentProfile}
                talentProfiles={talentProfiles}
                handleCloseDrawer={onClose || closeTalentProfileDrawer}
                isLoading={!isFetchingData && Boolean(talentData)}
                talentData={talentData}
                onNextProfile={setNextTalentId}
                onPreviousProfile={setPreviousTalentId}
                disablePrevTalentProfileButton={disablePrevTalentProfileButton}
                disableNextTalentProfileButton={disableNextTalentProfileButton}
                showSourcePoolChip={
                    talentProfiles.length > 1 ||
                    (Boolean(talentProfile) && talentProfile.talentPoolId !== '1')
                }
            />

            {isFetchingData ? (
                <Box
                    sx={{
                        display: 'flex',
                        height: '50vh',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={50} />
                </Box>
            ) : (
                <>
                    <Box sx={{ mt: 2 }}>
                        <ProfileTabs
                            onChangeTab={handleChangeTab}
                            currentTab={currentTab}
                            notesCount={talentNotesCountObj?.[currentTalentId]}
                            attachmentsCount={media?.results?.length || attachmentsCount || 0}
                            activitiesCount={activityData?.totalResults || activitiesCount || 0}
                            isMediaFeatureEnabled={isMediaFeatureEnabled}
                            isActivityTrackingEnabled={isActivityTrackingFeatureEnabled}
                        />
                    </Box>
                    {currentTab === TALENT_DRAWER_TABS.PROFILE_TAB && (
                        <TalentProfileInfo
                            talentData={talentData}
                            isExperienceDataHidden={isExperienceDataHidden}
                            isFetchingData={isFetchingData}
                            skillsData={talentData.skillSet}
                            currentTalentId={currentTalentId}
                            isSearch={config?.isSearch}
                        />
                    )}
                    {currentTab === TALENT_DRAWER_TABS.NOTES_TAB && (
                        <AllTalentNotes
                            isInProfileDrawer={true}
                            showNotesFilter={true}
                            notes={newNotes}
                            handleOptimisticDelete={handleOptimisticDelete}
                            mentionEditorRef={mentionEditorRef}
                            isLoading={isLoading}
                            updateNotes={setNotes}
                            talentId={currentTalentId}
                            notesSourceConfig={config?.notesSourceConfig || null}
                        />
                    )}
                    {isMediaFeatureEnabled && currentTab === TALENT_DRAWER_TABS.ATTACHMENTS_TAB && (
                        <ProfileAttachments
                            shouldFetchMedia={inMessageDrawer}
                            attachments={media?.results || []}
                            isLoading={isFetchingMedia}
                            talentId={currentTalentId}
                            sortOrder={sortByLastUpdated}
                            onSortByUploadedButtonClicked={(order) => setSortByLastUpdated(order)}
                            onAttachmentsLoaded={(media) => {
                                setAttachmentsCount(media?.results?.length || 0);
                            }}
                        />
                    )}
                    {currentTab === TALENT_DRAWER_TABS.ACTIVITY_TAB &&
                        isActivityTrackingFeatureEnabled && (
                            <ProfileActivity
                                shouldFetchActivity={inMessageDrawer}
                                activities={activityData?.results || []}
                                totalResults={activityData?.totalResults || 0}
                                isLoading={isFetchingActivity}
                                talentData={talentData}
                                onActivitiesLoaded={(activities) => {
                                    setActivitiesCount(activities?.totalResults || 0);
                                }}
                            />
                        )}
                </>
            )}
        </>
    );
}

TalentProfileDrawerContent.propTypes = {
    onClose: PropTypes.func,
    mentionEditorRef: PropTypes.any,
    inMessageDrawer: PropTypes.bool,
};
