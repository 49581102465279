import { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const filterOptions = (exact) => (options, params) => {
    const filtered = filter(options, params);
    // Suggest the creation of a new value
    if (params.inputValue !== '' && !exact) {
        filtered.unshift({
            value: params.inputValue,
            label: params.inputValue,
            // Setting a seperate label (inputLabel) for the free text options
            // will allow us to avoid including the "Add" in chips
            inputLabel: `Add "${params.inputValue}"`, // free text
        });
    }
    return filtered;
};

export { filterOptions };
