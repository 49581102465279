import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';

import { Typography } from 'components/Typography';
import { PiplErrorStage } from './PiplErrorStage';
import { TokenErrorStage } from './TokenErrorStage';
import { TokenLookupStage } from './TokenLookupStage';
import { LookupErrorStage } from './LookupErrorStage';
import { STAGE_ENUMS } from './useOrganizationTokenDialog';

const DialogButton = styled(Button)(({ theme }) => ({
    boxSizing: 'border-box',
    width: theme.spacing(10),
    textTransform: 'unset',
}));

export function OrganizationTokenDialog(props) {
    const {
        currentStage,
        tokensUsed,
        talentLookups,
        open,
        isLookupLoading,
        allLookupsFailed,
        onClose,
        onConfirm,
        handleCloseMenu,
    } = props;

    return (
        <Dialog
            closeAfterTransition
            onClose={onClose}
            open={open}
            aria-label="token-dialog"
            sx={{ backgroundColor: 'rgba(38, 55, 70, 0.72)' }}
            PaperProps={{
                sx: {
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 400,
                    p: [4, 4, 2],
                },
            }}
        >
            {currentStage === STAGE_ENUMS.TOKEN_LOOKUP && (
                <TokenLookupStage tokensUsed={tokensUsed} />
            )}
            {currentStage === STAGE_ENUMS.TOKEN_ERROR && <TokenErrorStage />}
            {currentStage === STAGE_ENUMS.LOOKUP_ERROR && (
                <LookupErrorStage talentLookups={talentLookups} />
            )}
            {currentStage === STAGE_ENUMS.PIPL_ERROR && <PiplErrorStage />}
            <DialogActions sx={{ width: '100%', padding: 0 }}>
                <DialogButton
                    aria-label="token-dialog-back"
                    onClick={onClose}
                    sx={{ color: 'text.primary', width: '100%', border: '1px #000000 solid' }}
                >
                    <Typography variant="titleSmall" color="black">
                        Go Back
                    </Typography>
                </DialogButton>
                {(currentStage === STAGE_ENUMS.TOKEN_LOOKUP ||
                    (currentStage === STAGE_ENUMS.LOOKUP_ERROR && !allLookupsFailed)) && (
                    <DialogButton
                        disabled={isLookupLoading}
                        aria-label="token-dialog-confirm"
                        onClick={() => {
                            onConfirm();
                            if (currentStage === STAGE_ENUMS.LOOKUP_ERROR) {
                                onClose();
                                handleCloseMenu();
                            }
                        }}
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'common.white',
                            width: '100%',
                            ':hover': {
                                color: 'text.primary',
                            },
                        }}
                    >
                        {!isLookupLoading ? (
                            <Typography variant="titleSmall">
                                {currentStage === STAGE_ENUMS.LOOKUP_ERROR && 'Remove and continue'}
                                {currentStage !== STAGE_ENUMS.LOOKUP_ERROR && 'Confirm'}
                            </Typography>
                        ) : (
                            <CircularProgress sx={{ color: 'text.primary' }} size={20} />
                        )}
                    </DialogButton>
                )}
            </DialogActions>
        </Dialog>
    );
}

OrganizationTokenDialog.propTypes = {
    open: PropTypes.bool,
    isLookupLoading: PropTypes.bool,
    allLookupsFailed: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    tokensUsed: PropTypes.number,
    currentStage: PropTypes.number,
    talentLookups: PropTypes.array,
    handleCloseMenu: PropTypes.func,
};
