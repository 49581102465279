import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useDispatch, useSelector } from 'react-redux';

import { CheckBoxSelectedIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { MEDIA_TYPES } from 'utils/constants';
import { setMediaTypes } from '../../../slices/mediaSlice';
import { SelectButtons } from './SelectButtons';

export function MediaTypeContent() {
    const mediaTypes = useSelector((state) => state.media.mediaTypes);

    const dispatch = useDispatch();

    const handleFileTypeToggle = (event) => {
        const staging = [...mediaTypes];
        if (event.target.checked) {
            staging.push(event.target.value);
        } else {
            const index = staging.findIndex((e) => e === event.target.value);
            if (index >= 0) {
                staging.splice(index, 1);
            }
        }
        dispatch(setMediaTypes(staging));
    };

    const handleDeselectAll = () => {
        dispatch(setMediaTypes([]));
    };

    const handleSelectAll = () => {
        const allValues = Object.values(MEDIA_TYPES).map(({ value }) => value);
        dispatch(setMediaTypes(allValues));
    };

    return (
        <Box
            aria-label="Filter by File type"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 1,
                minWidth: (t) => t.spacing(40),
            }}
        >
            <SelectButtons onSelectAll={handleSelectAll} onDeselectAll={handleDeselectAll} />
            {Object.values(MEDIA_TYPES).map(({ value, label }) => (
                <FormControlLabel
                    key={value}
                    sx={{ mr: 0 }}
                    control={
                        <Checkbox
                            value={value}
                            checked={mediaTypes.includes(value)}
                            onChange={handleFileTypeToggle}
                            checkedIcon={<CheckBoxSelectedIcon color="success" />}
                            inputProps={{ 'aria-label': `Option: ${label}` }}
                            disableRipple
                        />
                    }
                    label={<Typography variant="titleSmall">{label}</Typography>}
                    disableTypography
                />
            ))}
        </Box>
    );
}
