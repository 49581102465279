import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { MESSAGE_TYPES } from 'utils/constants';
import { useLazyGetConversationMessagesQuery } from 'services/conversations';

export function useGetReplyContact({ conversationId, messageType, skip }) {
    const [replyContact, setReplyContact] = useState('');

    const [getMessages] = useLazyGetConversationMessagesQuery();

    const getLatestReplies = useCallback(async () => {
        const requestParams = {
            conversationId,
            messageType,
            page: 1,
            pageSize: 1,
            sendMethod: 'default',
        };
        try {
            const { data, error } = await getMessages(requestParams);
            if (error) {
                throw new Error();
            }
            setReplyContact('');
            switch (messageType) {
                case MESSAGE_TYPES.EMAIL:
                    return `nylas_thread_id,${data?.results[0]?.data?.nylas_message_id}`;
                case MESSAGE_TYPES.SMS:
                    return data?.results[0]?.data?.From;
                default:
                    return null;
            }
        } catch {
            toast.error('Error encountered when retrieving contact data');
        }
    }, [conversationId, messageType, getMessages]);

    useEffect(() => {
        setReplyContact('');
    }, [conversationId]);

    useEffect(() => {
        if (skip) {
            return;
        }
        const fetchContacts = async () => {
            const latestReplyContact = await getLatestReplies();
            if (latestReplyContact) {
                setReplyContact(latestReplyContact);
            }
        };
        if (conversationId) {
            fetchContacts();
        }
    }, [skip, conversationId, messageType, getLatestReplies]);

    return replyContact;
}
