import SvgIcon from '@mui/material/SvgIcon';

export function CommentAddIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M23.5603 0.00976562H9.33811C4.43144 0.00976562 0.449219 3.99199 0.449219 8.89865V19.5653C0.449219 24.472 4.43144 28.4542 9.33811 28.4542H20.0048C22.3514 28.4542 25.3381 32.0098 27.987 32.0098C31.1159 32.0098 32.4492 29.5387 32.4492 26.6764V8.89865C32.4492 3.99199 28.467 0.00976562 23.5603 0.00976562ZM28.8937 26.6764C28.8937 28.4542 28.3248 28.4542 28.0404 28.4542C27.5959 28.4009 26.4048 27.6186 25.6937 27.1564C24.0581 26.0898 22.1914 24.8809 20.0048 24.8809H9.33811C6.40477 24.8809 4.00477 22.4809 4.00477 19.5475V8.88087C4.00477 5.94754 6.40477 3.54754 9.33811 3.54754H23.5603C26.4937 3.54754 28.8937 5.94754 28.8937 8.88087V26.6764Z"
                fill="currentColor"
            />
            <path
                d="M21.7115 12.4187H18.2448V8.8631C18.2448 7.88532 17.4448 7.08532 16.467 7.08532C15.4892 7.08532 14.6892 7.88532 14.6892 8.8631V12.4187H11.1159C10.1381 12.4187 9.33811 13.2187 9.33811 14.1964C9.33811 15.1742 10.1381 15.9742 11.1159 15.9742H14.6892V19.5653C14.6892 20.5431 15.4892 21.3431 16.467 21.3431C17.4448 21.3431 18.2448 20.5431 18.2448 19.5653V15.9742H21.7115C22.6893 15.9742 23.4893 15.1742 23.4893 14.1964C23.4893 13.2187 22.6893 12.4187 21.7115 12.4187Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
