import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { buildMessageTypeFilter, buildSendMethodFilter } from '../utils/filterBuilders';
import { MESSAGE_METRIC_ENUMS } from './SendMetricBar';

export const useGetDrilldownUrlQuery = (metricType) => {
    const { startDate, endDate, messageChannels, messageTypes } = useSelector(
        (state) => state.communications.metricFilters
    );
    const { SMS, EMAIL } = messageTypes;

    const query = useMemo(() => {
        const params = {
            includeStatus: MESSAGE_METRIC_ENUMS[metricType]?.messageQuery,
        };
        if (startDate?.value || endDate?.value) {
            if (!endDate?.value) {
                params['dateGte'] = startDate.value;
            } else {
                params['dateRange'] = [startDate.value, endDate.value];
            }
        }
        params['sendMethod'] = buildSendMethodFilter(messageChannels);
        if (!EMAIL || !SMS) {
            params['messageType'] = buildMessageTypeFilter(messageTypes);
        }
        return params;
    }, [endDate, startDate, metricType, messageChannels, messageTypes, EMAIL, SMS]);

    return query;
};
