import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { talentApi } from 'services/talent';
import { searchApi } from 'services/search';
import { WATCH_TYPES } from 'utils/constants';
import { prepareAuthHeaders } from 'services/utils';

const feedbackBaseUrl = 'api/feedback/v1';

export const watchApi = createApi({
    reducerPath: 'watchApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['WatchList'],
    endpoints: (builder) => ({
        createWatchList: builder.mutation({
            query: ({ shortlistId, userId }) => ({
                url: `${feedbackBaseUrl}/watch`,
                method: 'POST',
                body: {
                    derivedFromType: WATCH_TYPES.SHORTLIST,
                    derivedFromId: shortlistId,
                    executionFrequency: 0,
                    resultsPerExecution: 10,
                    onResults: [
                        { action: 'feedback-request', properties: { feedbackUsers: [userId] } },
                    ],
                },
                params: { runOnCreation: true },
            }),
            invalidatesTags: (result, error, { shortlistId }) => [
                { type: 'WatchList', id: shortlistId },
            ],
        }),
        createSearchWatch: builder.mutation({
            query: ({ name, frequency, outputPerRun, viewedIds, userId, searchQuery }) => ({
                url: `${feedbackBaseUrl}/watch`,
                method: 'POST',
                body: {
                    derivedFromType: WATCH_TYPES.SEARCH,
                    derivedFromQuery: searchQuery,
                    derivedFromName: name,
                    exclusionList: viewedIds,
                    executionFrequency: frequency,
                    resultsPerExecution: outputPerRun,
                    onResults: [
                        { action: 'feedback-request', properties: { feedbackUsers: [userId] } },
                    ],
                },
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // a new shortlist and saved-search will be created
                    dispatch(talentApi.util.invalidateTags([{ type: 'Shortlists', id: 'LISTS' }]));
                    dispatch(
                        searchApi.util.invalidateTags([{ type: 'SavedSearches', id: 'LIST' }])
                    );
                } catch (error) {
                    // do nothing
                }
            },
        }),
        getWatchList: builder.query({
            query: (shortlistId) => ({
                url: `${feedbackBaseUrl}/watch/status`,
                method: 'GET',
                params: {
                    derivedFromType: WATCH_TYPES.SHORTLIST,
                    derivedFromId: shortlistId,
                },
            }),
            providesTags: (result, error, shortlistId) => {
                return [{ type: 'WatchList', id: shortlistId }];
            },
        }),
        updateWatchList: builder.mutation({
            query: ({ active, watchId, executionFrequency = 0 }) => ({
                url: `${feedbackBaseUrl}/watch/${watchId}/settings`,
                method: 'PUT',
                body: {
                    executionFrequency,
                    maxExecutions: 10,
                    active,
                },
            }),
            invalidatesTags: (result, error, { shortlistId }) => [
                { type: 'WatchList', id: shortlistId },
            ],
        }),
    }),
});

export const {
    useGetWatchListQuery,
    useUpdateWatchListMutation,
    useCreateWatchListMutation,
    useCreateSearchWatchMutation,
} = watchApi;
