import PropTypes from 'prop-types';
import map from 'lodash/map';
import Box from '@mui/material/Box';

import { useDynamicFields } from '../hooks';
import { Typography } from 'components/Typography';
import { DynamicFieldRow } from './DynamicFieldRow';
import { StyledDropdownMenu } from 'components/MessageContent/StyledDropdownMenu';

export function DynamicFieldsDropdown(props) {
    const { anchorEl, onClose } = props;
    const { dynamicFields, addDefaultDynamicFields } = useDynamicFields();
    const open = Boolean(anchorEl);

    return (
        <StyledDropdownMenu
            id="dynamic-fields-dropdown-menu"
            MenuListProps={{
                'aria-labelledby': 'dynamic-fields-dropdown-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Box sx={{ display: 'flex' }}>
                {map(Object.keys(dynamicFields), (collection, index) => {
                    return (
                        <Box key={`${collection}-${index}`}>
                            <Typography
                                variant="labelXSmall"
                                sx={{ color: 'rgba(38, 55, 70, 0.72)', px: 2, pt: 2 }}
                            >
                                {collection}
                            </Typography>
                            <DynamicFieldRow
                                dynamicFields={dynamicFields[collection]}
                                onClose={onClose}
                                addDefaultDynamicFields={addDefaultDynamicFields}
                            />
                        </Box>
                    );
                })}
            </Box>
        </StyledDropdownMenu>
    );
}

DynamicFieldsDropdown.propTypes = {
    anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(Element), PropTypes.oneOf([null])]),
    onClose: PropTypes.func,
};
