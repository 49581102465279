import {
    handle403Error,
    handle401Error,
    handle404Error,
    handle400Error,
    handleGenericError,
    handle409Error,
} from './handleMessageComposeError';

export function handleMessageSendError(error, type, setOpenTcpaDialog) {
    const errorData = error?.data;
    switch (error?.status) {
        case 403:
            handle403Error();
            break;
        case 401:
            handle401Error();
            break;
        case 404:
            handle404Error(type, errorData);
            break;
        case 400:
        case 424:
        case 451:
            handle400Error(errorData, setOpenTcpaDialog);
            break;
        case 409:
            handle409Error(errorData);
            break;
        default:
            handleGenericError();
    }
}
