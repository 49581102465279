import { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';

import { MESSAGE_TYPES } from 'utils/constants';
import { SESSION_STORAGE } from 'utils/constants';
import { Typography } from 'components/Typography';
import { removeManyContacts, selectAllContacts, setContacts } from '../ContactSelectionSlice';
import { FindRightContact } from 'components/MessageContent/FindRightContact';
import { filterValidOptions } from '../utils/contactProcessHelpers';

const ConfirmButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    color: theme.palette.common.white,
    padding: 8,
    '&:hover': {
        backgroundColor: alpha(theme.palette.success.main, 0.8),
    },
}));

export function ContactsHeader(props) {
    const { messageType: initialMessageType, shortlistId: initialShortlistId, onClose } = props;

    const [messageType, setMesssageType] = useState('');
    const [shortlistId, setShortlistId] = useState('');

    const isOnContacts = useMatch('communications/contacts');
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const talents = useSelector(selectAllContacts);
    const isDisabled = useMemo(() => !talents.some((talent) => talent.selected), [talents]);

    const noOptions = useMemo(() => {
        const accessor = messageType === MESSAGE_TYPES.SMS ? 'phones' : 'emails';
        return !talents.some((talent) => talent[accessor]?.data?.some(filterValidOptions));
    }, [talents, messageType]);

    const handleConfirm = () => {
        const filteredTalentsIds = talents
            .filter((talent) => !talent.selected)
            .reduce((acc, currentTalent) => acc.concat(currentTalent.talentId), []);
        dispatch(removeManyContacts(filteredTalentsIds));

        if (isOnContacts) {
            navigate('/communications/compose', {
                state: {
                    messageType,
                    shortlistId,
                },
            });
        }
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    useEffect(() => {
        if (!initialMessageType && !initialShortlistId) {
            const cache = window.sessionStorage.getItem(SESSION_STORAGE.CONTACT_VERIFICATION);
            if (cache) {
                const { contacts, messageType, shortlistId } = JSON.parse(cache);
                if (contacts.length > 0) {
                    dispatch(setContacts(contacts));
                    setMesssageType(messageType);
                    setShortlistId(shortlistId);
                }
            }
        } else {
            setMesssageType(initialMessageType);
            setShortlistId(initialShortlistId);
        }
    }, [initialMessageType, initialShortlistId, dispatch]);

    return (
        <Box sx={{ display: 'flex', width: '100%', mb: 3 }}>
            <Typography variant="headline" sx={{ paddingLeft: 1.5 }}>
                Verify Selections
            </Typography>
            <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'row' }}>
                <FindRightContact sx={{ mr: 8 }} isDisabled={noOptions} />
                <ConfirmButton
                    variant="contained"
                    color="success"
                    aria-label="contacts-confirm-button"
                    onClick={handleConfirm}
                    disableElevation
                    disabled={isDisabled}
                >
                    Confirm Selections
                </ConfirmButton>
            </Box>
        </Box>
    );
}

ContactsHeader.propTypes = {
    messageType: PropTypes.string,
    shortlistId: PropTypes.string,
    onClose: PropTypes.func,
};
