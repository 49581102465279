import PropTypes from 'prop-types';

import { Tooltip } from 'components/Tooltip';
import { AnyAllSelect } from './AnyAllSelect';
import { Typography } from 'components/Typography';
import { CheckIcon, ExcludeIcon } from 'components/Icons';
import { FilterCaption, StyledIncludeExcludeLabel } from './styledComponents';

export function IncludeExcludeLabel(props) {
    const { label, conditionalLabel = 'ANY', filterKey, enableAnyAllSelect } = props;

    const isInclude = label === 'Include';

    return (
        <>
            {isInclude && (
                <FilterCaption variant="labelMedium">
                    <CheckIcon
                        sx={{
                            cursor: 'auto',
                            fontSize: '13px',
                            marginRight: '3px',
                            color: 'success.main',
                        }}
                    />
                    Include
                    {enableAnyAllSelect && <AnyAllSelect filterKey={filterKey} />}
                    {!enableAnyAllSelect && (
                        <Tooltip
                            placement="top"
                            title={
                                <Typography variant="bodySmall">
                                    Results must include
                                    <Typography variant="bodySmallBold">
                                        &nbsp;at least one&nbsp;
                                    </Typography>
                                    of these values
                                </Typography>
                            }
                        >
                            <StyledIncludeExcludeLabel>
                                {conditionalLabel}
                            </StyledIncludeExcludeLabel>
                        </Tooltip>
                    )}
                    of these values
                </FilterCaption>
            )}
            {!isInclude && (
                <FilterCaption variant="labelMedium">
                    <ExcludeIcon sx={{ fontSize: '13px', color: 'error.main', mr: '3px' }} />
                    Exclude
                    <Tooltip
                        placement="top"
                        title={
                            <Typography variant="bodySmall">
                                Results must exclude all of these values
                            </Typography>
                        }
                    >
                        <StyledIncludeExcludeLabel>ALL</StyledIncludeExcludeLabel>
                    </Tooltip>
                    of these values
                </FilterCaption>
            )}
        </>
    );
}

IncludeExcludeLabel.propTypes = {
    label: PropTypes.string,
    conditionalLabel: PropTypes.string,
    enableAnyAllSelect: PropTypes.bool,
    filterKey: PropTypes.string,
};
