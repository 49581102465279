import SvgIcon from '@mui/material/SvgIcon';

export function ChevronRightIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M9.29289 15.2929C8.90237 15.6834 8.90237 16.3166 9.29289 16.7071C9.68342 17.0976 10.3166 17.0976 10.7071 16.7071L14.9497 12.4645C14.9986 12.4157 15.0413 12.363 15.0779 12.3076C15.3342 11.9194 15.2915 11.392 14.9497 11.0503L10.7071 6.80761C10.3166 6.41709 9.68342 6.41709 9.29289 6.80761C8.90237 7.19814 8.90237 7.8313 9.29289 8.22182L12.8284 11.7574L9.29289 15.2929Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
            />
        </SvgIcon>
    );
}
