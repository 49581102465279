import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';

import { Typography } from 'components/Typography';
import { useRetrieveEntityConnection } from 'hooks/useRetrieveEntityConnection';
import { ENTITY_TYPE_ENUMS } from 'utils/entityEnums';
import { LinkOutIcon } from 'components/Icons';
import { Tooltip } from 'components/Tooltip';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';

const UNREMOVABLE_CONNECTIONS = [
    ENTITY_TYPE_ENUMS.CONVERSATION.label,
    ENTITY_TYPE_ENUMS.PROJECT.label,
    ENTITY_TYPE_ENUMS.INTEGRATION.label,
];

export function FileEntityRelationship(props) {
    const {
        type,
        entityId,
        userId,
        data,
        handleRemoveMediaConnection,
        isRemovingMediaConnection,
        isReadOnly = false,
        talentName,
        actorType,
        metadata,
        onEntityConnectionFetchError,
    } = props;
    const entityMap = ENTITY_TYPE_ENUMS[type.toUpperCase()];
    const Icon = entityMap.Icon;

    const user = useSelector((state) => state.user);

    const { connectionString, connectionUrl, isLoading } = useRetrieveEntityConnection({
        type,
        entityId,
        data,
        talentName,
        onEntityConnectionFetchError,
        metadata,
    });

    if (
        type === ENTITY_TYPE_ENUMS.CONVERSATION.label &&
        actorType === 'user' &&
        user?.id !== userId
    ) {
        return null;
    }

    return isLoading ? (
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} aria-label="loading-placeholder" />
    ) : (
        <Box
            data-testid={`${type}-${entityId}`}
            sx={{ display: 'flex', alignItems: 'end', mb: 0.25 }}
            aria-label="entity-box"
        >
            <Box sx={{ display: 'flex', width: 140 }}>
                <Icon
                    sx={{
                        color:
                            type === ENTITY_TYPE_ENUMS.SEQUENCE.label ? '#5F2BE1' : 'primary.main',
                        fontSize: 14,
                        mr: 1,
                    }}
                />
                <Typography
                    variant="labelXSmall"
                    sx={{
                        color: type === ENTITY_TYPE_ENUMS.SEQUENCE.label ? '#5F2BE1' : 'text.main',
                    }}
                >
                    {entityMap.value}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: 272, alignItems: 'end' }}>
                <Tooltip title={connectionString}>
                    <ButtonBase LinkComponent={Link} to={connectionUrl || ''} target="_blank">
                        <Typography
                            color={Boolean(connectionUrl) ? 'primary.main' : ''}
                            noWrap
                            variant="bodyBase"
                            sx={{
                                fontWeight: 500,
                                maxWidth: 300,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {connectionString}
                        </Typography>
                        {Boolean(connectionUrl) && (
                            <LinkOutIcon
                                sx={{
                                    ml: connectionString ? 1 : 0,
                                    color: 'primary.main',
                                    fontSize: 12,
                                }}
                            />
                        )}
                    </ButtonBase>
                </Tooltip>
            </Box>
            {!isReadOnly && (
                <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                    {!includes(UNREMOVABLE_CONNECTIONS, type) && (
                        <ButtonBase
                            aria-label="remove-connection"
                            onClick={() => handleRemoveMediaConnection(type, entityId)}
                            disabled={isRemovingMediaConnection}
                        >
                            {isRemovingMediaConnection ? <CircularProgress size={16} /> : null}
                            <Typography color="error.main" variant="titleSmall">
                                Remove
                            </Typography>
                        </ButtonBase>
                    )}
                </Box>
            )}
        </Box>
    );
}

FileEntityRelationship.propTypes = {
    isReadOnly: PropTypes.bool,
    type: PropTypes.string,
    entityId: PropTypes.string,
    userId: PropTypes.string,
    data: PropTypes.object,
    handleRemoveMediaConnection: PropTypes.func,
    isRemovingMediaConnection: PropTypes.bool,
    talentName: PropTypes.string,
    actorType: PropTypes.string,
    onEntityConnectionFetchError: PropTypes.func,
};
