import SvgIcon from '@mui/material/SvgIcon';

export function CheckFeatureIcon(props) {
    return (
        <SvgIcon viewBox="0 0 27 27" {...props}>
            <circle cx="13.5" cy="13.5" r="13.5" fill="#29CC9A" />
            <path
                d="M11.8937 17.5189C11.525 17.5189 11.1875 17.3689 10.9312 17.1001L8.04997 14.0626C7.81247 13.8126 7.82498 13.4189 8.07498 13.1814C8.32498 12.9439 8.71873 12.9564 8.95623 13.2064L11.8375 16.2439C11.8375 16.2439 11.875 16.2751 11.8937 16.2751C11.8937 16.2751 11.9312 16.2626 11.95 16.2439L18.0437 9.68137C18.2812 9.42512 18.675 9.41262 18.925 9.65012C19.175 9.88762 19.1937 10.2814 18.9562 10.5314L12.8625 17.0939C12.6062 17.3689 12.2625 17.5189 11.8937 17.5251H11.8875L11.8937 17.5189Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
