import filesize from 'filesize';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

import { FileIcon } from '../FileIcon';
import { Typography } from 'components/Typography';
import { ConfirmReplacementDialog } from './ConfirmReplacementDialog';
import { useCallback, useState } from 'react';

export function FileInfo(props) {
    const { filename: name, size, mediaType, onReplaceAttachment, mediaId } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleConfirm = useCallback(() => {
        setIsOpen(false);
        onReplaceAttachment(mediaId);
    }, [mediaId, onReplaceAttachment]);

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <FileIcon mediaType={mediaType} IconStyleSx={{ width: '40px', height: '40px' }} />
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                    <Typography variant="titleSmall">{name}</Typography>
                    <Typography
                        variant="body"
                        sx={{
                            ml: (t) => t.spacing(0.5),
                            color: (t) => alpha(t.palette.text.primary, 0.48),
                        }}
                    >
                        {filesize(size, { standard: 'jedec' })}
                    </Typography>
                    <ButtonBase data-testid="replace-media-button" onClick={handleOpen}>
                        <Typography
                            aria-label="replace-media"
                            variant="titleSmall"
                            sx={{
                                color: (t) => t.palette.primary.main,
                                cursor: 'pointer',
                                ml: (t) => t.spacing(0.5),
                            }}
                        >
                            Replace
                        </Typography>
                    </ButtonBase>
                </Box>
            </Box>
            <ConfirmReplacementDialog
                isOpen={isOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
            />
        </>
    );
}

FileInfo.propTypes = {
    filename: PropTypes.string,
    size: PropTypes.number,
    mediaType: PropTypes.string,
    mediaId: PropTypes.string,
    onReplaceAttachment: PropTypes.func,
};
