import PropTypes from 'prop-types';

import { useIsAuthenticated } from 'hooks/useIsAuthenticated';

export function ProtectedElement({ roles, children }) {
    const authenticated = useIsAuthenticated(roles);
    return authenticated && children;
}

ProtectedElement.propTypes = {
    roles: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
