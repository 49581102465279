import SvgIcon from '@mui/material/SvgIcon';

export function StatClickedIcon(props) {
    return (
        <SvgIcon viewBox="0 0 25 24" {...props}>
            <path
                d="M24.0613 21.4437L17.9124 15.2784L20.8369 14.3461C22.5166 13.8048 23.6414 12.3161 23.7164 10.5567C23.7914 8.72216 22.7266 7.06811 21.0169 6.3764L6.27455 0.331401C4.66984 -0.330238 2.91515 0.0156589 1.71537 1.21864C0.515584 2.43665 0.170652 4.18094 0.830532 5.78993L6.85946 20.5565C7.53434 22.2106 9.09403 23.2632 10.8637 23.2632C10.9237 23.2632 10.9837 23.2632 11.0437 23.2632C12.8133 23.188 14.2981 22.0602 14.823 20.3761L15.7678 17.3837L21.9317 23.564C22.2316 23.8647 22.6066 24 22.9965 24C23.3864 24 23.7614 23.8497 24.0613 23.564C24.6462 22.9775 24.6462 22.0301 24.0613 21.4437ZM13.0683 16.0002L11.9735 19.4738C11.7636 20.1655 11.1637 20.2558 10.9237 20.2708C10.4588 20.2708 9.90389 20.0753 9.64893 19.4287L3.62005 4.66213C3.3351 3.97042 3.69504 3.51931 3.86001 3.3539C4.02498 3.18849 4.48989 2.82755 5.16476 3.11325L19.9071 9.15825C20.5519 9.42892 20.7619 10.0004 20.7469 10.4365C20.7469 10.6771 20.6419 11.2634 19.952 11.489L16.4727 12.6018C14.853 13.1131 13.5782 14.3912 13.0683 16.0152V16.0002Z"
                fill="currentColor"
                fillOpacity="0.48"
            />
        </SvgIcon>
    );
}
