import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';

import { TrashIcon } from 'components/Icons';
import { useDeleteMessageMutation } from 'services/conversations';

const handleResponse = (res, onSuccess) => {
    if (res?.error) {
        if (res.error.data.message?.includes('Message matching query does not exist')) {
            toast.error('Sorry, message does not exist');
        } else {
            toast.error(
                `Something went wrong, please try again shortly. ${res.error.data.message}`
            );
        }
    } else {
        toast.success('Successfully deleted message');
        onSuccess();
    }
};

export function DeleteMessageButton(props) {
    const { messageType, messageId, conversationId, getInitialMessages } = props;

    const [deleteMessage, { isFetching }] = useDeleteMessageMutation();

    const handeRefresh = () => getInitialMessages();

    const handleDeleteMessage = async () => {
        const result = await deleteMessage({ messageType, messageId, conversationId });
        handleResponse(result, handeRefresh);
    };

    return (
        <IconButton
            disableRipple
            disabled={isFetching}
            aria-label="Delete message"
            onClick={handleDeleteMessage}
        >
            <TrashIcon color="error" sx={{ fontSize: 16 }} />
        </IconButton>
    );
}

DeleteMessageButton.propTypes = {
    messageId: PropTypes.string,
    messageType: PropTypes.string,
    conversationId: PropTypes.string,
    getInitialMessages: PropTypes.func,
};
