import {
    ArrowCircleRight,
    BriefcaseIcon,
    DigitalAssistantIcon,
    DigitalAssistantIconWithGradientColor,
    EmailIcon,
    KnowledgeBasesIcon,
    SearchIcon,
    SequenceIcon,
    ShortlistIcon,
    SmsIcon,
} from 'components/Icons';
import { brightBlue, darkGray, purple } from 'theme';

export const projectDetailsPrefillFields = [
    { id: '1', label: 'Hiring Org', value: '', prefilled: true },
    { id: '2', label: 'Hiring Manager', value: '', prefilled: true },
    { id: '3', label: 'Team/Division', value: '', prefilled: true },
    { id: '4', label: 'Total Budget', value: '', prefilled: true },
    { id: '5', label: 'Search Start', value: '', prefilled: true },
    { id: '6', label: 'Target Fill', value: '', prefilled: true },
];

export const projectLinksPrefillFields = [
    {
        id: '1',
        label: 'Application',
        value: null,
        type: null,
        prefilled: true,
    },
    {
        id: '2',
        label: 'Scheduling',
        value: null,
        type: null,
        prefilled: true,
    },
    { id: '3', label: 'Job Description', value: null, type: null, prefilled: true },
    { id: '4', label: 'Company Information', value: null, type: null, prefilled: true },
];

export const projectLinkedResourcesPrefillFields = [
    {
        id: '1',
        resourceId: '',
        resourceType: 'digital_assistant',
        name: '',
    },
    {
        id: '2',
        resourceId: '',
        resourceType: 'path',
        name: '',
    },
    {
        id: '3',
        resourceId: '',
        resourceType: 'knowledgebase',
        name: '',
    },
    {
        id: '4',
        resourceId: '',
        resourceType: 'shortlist',
        name: '',
    },
    {
        id: '5',
        resourceId: '',
        resourceType: 'search',
        name: '',
    },
    {
        id: '6',
        resourceId: '',
        resourceType: 'job_description',
        name: '',
    },
];

export const PROJECT_LINKED_RESOURCES_TYPES = {
    DIGITAL_ASSISTANT: 'digital_assistant',
    PATH: 'path',
    KNOWLEDGEBASE: 'knowledgebase',
    SHORTLIST: 'shortlist',
    SEARCH: 'search',
    JOB_DESCRIPTION: 'job_description',
};

export const projectResourceConfig = {
    digital_assistant: {
        title: 'Digital Assistant',
        style: {
            background: 'linear-gradient(90deg, #504CE0 0.17%, #318DDE 99.72%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        },
        icon: <DigitalAssistantIconWithGradientColor sx={{ height: '14px', width: '14px' }} />,
    },
    path: {
        title: 'Sequences',
        style: { color: purple },
        icon: <SequenceIcon sx={{ height: '16px', width: '16px', color: purple }} />,
    },
    knowledgebase: {
        title: 'Knowledge base',
        style: { color: darkGray, fontWeight: 400 },
        icon: <KnowledgeBasesIcon sx={{ height: '14px', width: '14px', color: brightBlue }} />,
    },
    shortlist: {
        title: 'Shortlist',
        style: { color: darkGray, fontWeight: 400 },
        icon: <ShortlistIcon sx={{ height: '16px', width: '16px', color: brightBlue }} />,
    },
    search: {
        title: 'Saved Searches',
        style: { color: darkGray, fontWeight: 400 },
        icon: <SearchIcon sx={{ height: '16px', width: '16px', color: brightBlue }} />,
    },
    job_description: {
        title: 'Job Descriptions',
        style: { color: darkGray, fontWeight: 400 },
        icon: <BriefcaseIcon sx={{ height: '16px', width: '16px', color: brightBlue }} />,
    },
};

export const PROJECT_INFO_CARD_VARIANTS = {
    LIGHT: 'light',
    DARK: 'dark',
};

export const OUTREACH_STATUSES = {
    NOT_MESSAGED: 'not_messaged',
    MESSAGED: 'messaged',
    OPENED: 'opened',
    REPLIED: 'replied',
    FAILED: 'failed',
    SCHEDULED: 'scheduled',
    PENDING: 'pending',
};

export const TALENT_STATUSES = {
    ACTIVE: 'active',
    ARCHIVED: 'archived',
};

export const outreachStatusConfig = {
    not_messaged: {
        title: 'Not Messaged',
        backgroundColor: 'rgba(95, 43, 225, 0.24)',
    },
    messaged: {
        title: 'Messaged',
        backgroundColor: 'rgba(38, 55, 70, 0.12)',
    },
    opened: {
        title: 'Opened',
        backgroundColor: 'rgba(38, 55, 70, 0.12)',
    },
    replied: {
        title: 'Replied',
        backgroundColor: 'rgba(41, 204, 154, 0.24)',
    },
    failed: {
        title: 'Failed',
        backgroundColor: 'rgba(228, 104, 104, 0.24)',
    },
    scheduled: {
        title: 'Scheduled',
        backgroundColor: 'rgba(38, 55, 70, 0.12)',
    },
    pending: {
        title: 'Pending',
        backgroundColor: 'rgba(38, 55, 70, 0.12)',
    },
};

export const OUTREACH_METHODS = {
    PATH: 'path',
    DIGITAL_ASSISTANT: 'digital_assistant',
    EMAIL: 'email',
    SMS: 'sms',
    CONTACTED_EXTERNALLY: 'contacted_externally',
};

export const outreachMethodConfig = {
    [OUTREACH_METHODS.SMS]: {
        title: 'SMS',
        getIcon: (iconStyle) => <SmsIcon {...iconStyle} />,
        trackId: 'outreach-reach-out-sms',
    },
    [OUTREACH_METHODS.EMAIL]: {
        title: 'Email',
        getIcon: (iconStyle) => <EmailIcon {...iconStyle} />,
        trackId: 'outreach-reach-out-email',
    },
    [OUTREACH_METHODS.PATH]: {
        title: 'Sequences',
        getIcon: (iconStyle) => <SequenceIcon {...iconStyle} />,
        trackId: 'outreach-reach-out-path',
    },
    [OUTREACH_METHODS.DIGITAL_ASSISTANT]: {
        title: 'Digital Assistant',
        getIcon: (iconStyle) => <DigitalAssistantIcon {...iconStyle} />,
        trackId: 'outreach-reach-out-da',
    },
    [OUTREACH_METHODS.CONTACTED_EXTERNALLY]: {
        title: 'Contacted Externally',
        getIcon: (iconStyle) => <ArrowCircleRight {...iconStyle} />,
        trackId: 'outreach-reach-out-contacted-externally',
    },
};

export const TAB_STORAGE_KEY = '@Projects:tab';

export const PROJECT_COLLABORATOR_ROLES = {
    PROJECT_OWNER: 'owner',
    SOURCER: 'sourcer',
    COLLOBORATOR: 'collaborator',
    INTERVIEWER: 'interviewer',
    ORG_ADMIN: 'org-admin',
};

export const PROJECT_ACCESS_ACTIONS = {
    ACCESS: 'access',
    OUTREACH_EDIT: 'outreach-edit',
    OUTREACH_COMMENT: 'outreach-comment',
    OUTREACH_VIEW: 'outreach-view',
    INTERVIEW_VIEW: 'interview-view',
    INTERVIEW_EDIT: 'interview-edit',
    INTERVIEW_COMMENT: 'interview-comment',
    DETAILS_VIEW: 'details-view',
    DETAILS_EDIT: 'details-edit',
    ROLE_DETAILS_VIEW: 'role-details-view',
    ROLE_DETAILS_EDIT: 'role-details-edit',
};

export const PRIVACY_STATUSES = {
    ONLY_YOU: 'only_you',
    YOUR_ORG: 'your_org',
};

export const PROJECT_ID_STORAGE_KEY = '@Project:ID';

export const PROJECT_TABS = {
    overview: '',
    'role-details': 'role-details',
    outreach: 'outreach',
    interviews: 'interviews',
    reporting: 'reporting',
};
