import { useCallback, useState } from 'react';
import { useAddMediaModal } from './useAddMediaModal';

export function useNewMediaAttachmentsModal(props) {
    const { messageType } = props || {};
    const [attachments, setAttachments] = useState([]);

    const {
        isModalOpen,
        handleOpen,
        handleClose,
        handleOpenPicker,
        clearAttachments,
        handleRemoveAttachment,
    } = useAddMediaModal({ setAttachments, messageType });

    const handleConfirm = useCallback(
        (mediaIds, displayNames) => {
            let newAttachments = [];
            const isNewlyUploadedFiles = !attachments[0]?.mediaId;

            if (mediaIds) {
                newAttachments = attachments.map((attachment, index) => ({
                    ...attachment,
                    mediaId: mediaIds[index],
                    displayName: displayNames[attachment?.handle] || attachment?.filename,
                }));
            } else {
                newAttachments = [...attachments];
            }

            setAttachments(
                isNewlyUploadedFiles ? [...newAttachments, ...attachments] : newAttachments
            );

            return newAttachments;
        },

        [attachments, setAttachments]
    );

    return {
        isModalOpen,
        handleOpen,
        handleClose,
        handleConfirm,
        handleOpenPicker,
        attachments,
        clearAttachments,
        handleRemoveAttachment,
        setAttachments,
    };
}
