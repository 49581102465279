import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Typography } from 'components/Typography';
import { ACCESS_OPTIONS } from 'utils/entityEnums';

export function AccessControlDropdown(props) {
    const { anchorEl, open, onClose, onChangeValue } = props;

    return (
        <Menu open={open} onClose={onClose} anchorEl={anchorEl} data-testid="access-control-menu">
            <MenuItem
                onClick={() => onChangeValue(ACCESS_OPTIONS.YOU.value)}
                data-testid="user-access-option"
            >
                <Typography>{ACCESS_OPTIONS.YOU.selectionCopy}</Typography>
            </MenuItem>
            <MenuItem
                onClick={() => onChangeValue(ACCESS_OPTIONS.ORG.value)}
                data-testid="org-access-option"
            >
                <Typography>{ACCESS_OPTIONS.ORG.selectionCopy}</Typography>
            </MenuItem>
        </Menu>
    );
}

AccessControlDropdown.propTypes = {
    accessValue: PropTypes.string,
    anchorEl: PropTypes.element,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onChangeValue: PropTypes.func,
};
