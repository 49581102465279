import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { ActionList } from './ActionList';

/**
 * ClippyModal - Main modal window that appears.
 * This component will show a new modal that gives the user various quick action items
 * The actions that are shown are based on the users feature flags and if they're internal or not.
 * Trigger the modal with Ctrl-k
 */
export function ClippyModal({ user }) {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handleClose = () => setOpen(false);

    const handleKeyDown = useCallback(
        (event) => {
            if (event.key === 'k' && event.ctrlKey) {
                setOpen((current) => setOpen(!current));
            } else if (open && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
                event.preventDefault();
            }
        },
        [open]
    );

    useEffect(() => {
        if (!open) {
            setSearchTerm('');
        }
    }, [open]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        width: '500px',
        maxWidth: '600px',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: 2,
        outline: 'none',
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TextField
                        fullWidth
                        placeholder="Quick jump to..."
                        size="small"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: 'grey.500',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'grey.500',
                                },
                            },
                        }}
                        autoFocus
                    />
                    <ActionList
                        searchTerm={searchTerm}
                        setOpen={setOpen}
                        user={user}
                        maxItems={8}
                    />
                </Box>
            </Modal>
        </>
    );
}

ClippyModal.propTypes = {
    user: PropTypes.object.isRequired,
};
