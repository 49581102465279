import { styled, alpha } from '@mui/material/styles';
import { Tooltip } from 'components/Tooltip';
import PropTypes from 'prop-types';

export const HighlightedSpan = styled('mark', {
    shouldForwardProp: (prop) => prop !== 'isValid',
})(({ theme, isValid }) => ({
    background: alpha(isValid ? theme.palette.primary.main : theme.palette.error.main, 0.12),
    color: isValid ? theme.palette.primary.main : theme.palette.error.main,
}));

export function Leaf({ attributes, children, leaf }) {
    if (leaf.highlighted) {
        children = (
            <Tooltip title={leaf?.description || ''} placement="top">
                <HighlightedSpan
                    data-testid="leaf-mark"
                    isValid={leaf?.isDynamicFieldValid}
                    data-dynamic-field={leaf?.isDynamicFieldValid}
                    {...attributes}
                >
                    {children}
                </HighlightedSpan>
            </Tooltip>
        );
    }
    if (leaf.bold) {
        children = (
            <strong data-testid="leaf-bold" {...attributes}>
                {children}
            </strong>
        );
    }
    if (leaf.code) {
        children = (
            <code data-testid="leaf-code" {...attributes}>
                {children}
            </code>
        );
    }
    if (leaf.italic) {
        children = (
            <em data-testid="leaf-italic" {...attributes}>
                {children}
            </em>
        );
    }
    if (leaf.underline) {
        children = (
            <u data-testid="leaf-underline" {...attributes}>
                {children}
            </u>
        );
    }
    if (leaf.strikethrough) {
        children = (
            <del data-testid="leaf-strikethrough" {...attributes}>
                {children}
            </del>
        );
    }
    return <span {...attributes}>{children}</span>;
}

Leaf.propTypes = {
    attributes: PropTypes.object,
    children: PropTypes.node,
    leaf: PropTypes.object,
};

export function LeafWrapper(props) {
    return <Leaf {...props} />;
}
