import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function ConfirmDialog(props) {
    const {
        title,
        children,
        open,
        setOpen,
        onConfirm,
        closeOnConfirm,
        disableButtons,
        cancelTrackId,
        confirmTrackid,
    } = props;
    return (
        <Dialog
            open={open}
            keepMounted
            onClose={() => setOpen(false)}
            aria-describedby="alert-dialog-slide-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={disableButtons}
                    data-testid="cancel-confirm"
                    data-trackid={cancelTrackId}
                    onClick={() => setOpen(false)}
                >
                    No
                </Button>
                <Button
                    data-testid="accept-confirm"
                    data-trackid={confirmTrackid}
                    disabled={disableButtons}
                    onClick={() => {
                        closeOnConfirm && setOpen(false);
                        onConfirm();
                    }}
                >
                    {disableButtons && (
                        <CircularProgress
                            color="inherit"
                            size={10}
                            sx={{ marginRight: '5px', marginTop: '3px' }}
                        />
                    )}{' '}
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onConfirm: PropTypes.func,
    children: PropTypes.string,
    closeOnConfirm: PropTypes.bool,
    disableButtons: PropTypes.bool,
    cancelTrackId: PropTypes.string,
    confirmTrackid: PropTypes.string,
};
