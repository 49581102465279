import { Transforms } from 'slate';

export function withMedia(editor) {
    const { isVoid, isInline } = editor;

    editor.isInline = (element) => {
        return element.type === 'link' ? true : isInline(element);
    };

    editor.isVoid = (element) => {
        return element.type === 'image' ? true : isVoid(element);
    };

    editor.insertData = (data) => {
        const text = data.getData('text/plain');

        if (text) {
            const lines = text.split(/\r\n|\r|\n/);
            let split = false;

            for (const line of lines) {
                if (split) {
                    Transforms.splitNodes(editor, { always: true });
                }

                Transforms.insertText(editor, line);
                split = true;
            }
        }
    };

    return editor;
}
