import { useEffect, useState } from 'react';

// Helper function for checking if entityId has been connected to media
export const isMediaRelatedToEntity = (mediaData, selectedEntityId) =>
    Boolean(mediaData.entityConnections) &&
    mediaData.entityConnections.filter(
        ({ type, entityId }) => type === 'Profile' && entityId === selectedEntityId
    ).length > 0;


export function useRowSelection(props) {
    const {
        onAlreadyAttachedMediaClick,
        maxAttachmentsSize,
        onFileSizeLimitExceeded,
        maxAttachmentsSelectionCount,
        onAttachmentSelectionCountExceeded,
        initialAttachments,
        isRowSelectable,
        onSelectedRowsChange,
        clearSelectedRows,
        entityId,
        data,
        isFetching,
    } = props;

    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);

    useEffect(() => {
        if (initialAttachments.length) {
            setSelectedRow([...initialAttachments]);
            onSelectedRowsChange(initialAttachments);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialAttachments]);

    useEffect(() => {
        if (isFetching || !data?.results) setSelectionModel([]);
        else {
            setTimeout(() =>
                setSelectionModel(
                    selectedRow
                        .filter((media) =>
                            data?.results?.find?.(
                                (fetchedMedia) => fetchedMedia.mediaId === media.mediaId
                            )
                        )
                        .map((media) => media.mediaId)
                )
            );
        }
    }, [data?.results, isFetching, selectedRow]);

    useEffect(() => {
        if (clearSelectedRows) setSelectedRow([]);
    }, [clearSelectedRows]);

    const handleCellClick = (cell) => {
        if (!isRowSelectable) return;

        const media = cell.row;

        const isDeSelection = Boolean(
            selectedRow.find((selectedMedia) => selectedMedia.mediaId === media.mediaId)
        );

        const isSelection = !isDeSelection;

        const newSize =
            selectedRow.map((media) => media.size).reduce((prev, curr) => prev + curr, 0) +
            media.size;

        if (isSelection && newSize >= maxAttachmentsSize) {
            onFileSizeLimitExceeded?.(newSize);
            return;
        }

        if (
            isSelection &&
            maxAttachmentsSelectionCount !== 1 &&
            selectedRow.length === maxAttachmentsSelectionCount
        ) {
            onAttachmentSelectionCountExceeded?.();
            return;
        }

        if (isMediaRelatedToEntity(cell.row, entityId)) {
            onAlreadyAttachedMediaClick?.(cell.row);
            return;
        }

        let newRows = [];
        if (maxAttachmentsSelectionCount === 1) {
            newRows = isSelection ? [media] : [];
        } else {
            newRows = isSelection
                ? [...selectedRow, media]
                : selectedRow.filter((selectedMedia) => selectedMedia.mediaId !== media.mediaId);
        }
        setSelectedRow(newRows);
        onSelectedRowsChange(newRows);
    };

    return {
        selectionModel,
        handleCellClick,
    };
}
