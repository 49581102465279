import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

export function ClipboardIcon(props) {
    const { gradient, ...restProps } = props;
    const fill = gradient ? 'url(#clipboard_icon_gradient)' : 'currentColor';
    return (
        <SvgIcon viewBox="0 0 16 16" {...restProps}>
            <path
                d="M11.1599 1.99341H7.50657C5.93324 1.99341 4.65991 3.26673 4.65991 4.84007V5.32674H4.62659C2.81325 5.32674 1.33325 6.80007 1.33325 8.62007V11.3667C1.33325 13.1801 2.80659 14.6601 4.62659 14.6601H7.37325C9.18658 14.6601 10.6666 13.1801 10.6666 11.3667H9.33325C9.33325 12.4467 8.45325 13.3267 7.37325 13.3267H4.62659C3.54659 13.3267 2.66659 12.4467 2.66659 11.3667V8.62007C2.66659 7.54007 3.54659 6.66008 4.62659 6.66008H4.65991V8.49341C4.65991 10.0667 5.93324 11.3401 7.50657 11.3401H11.1599C12.7332 11.3401 14.0066 10.0667 14.0066 8.49341V4.84007C14.0066 3.26673 12.7332 1.99341 11.1599 1.99341ZM12.6732 8.49341C12.6732 9.32674 11.9932 10.0067 11.1599 10.0067H7.50657C6.67324 10.0067 5.99325 9.32674 5.99325 8.49341V4.84007C5.99325 4.00673 6.67324 3.32674 7.50657 3.32674H11.1599C11.9932 3.32674 12.6732 4.00673 12.6732 4.84007V8.49341Z"
                fill={fill}
            />
            <defs>
                <linearGradient
                    id="clipboard_icon_gradient"
                    x1="1.33325"
                    y1="6.5427"
                    x2="14.0097"
                    y2="6.6037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

ClipboardIcon.propTypes = {
    gradient: PropTypes.bool,
};
