import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

export function BriefcaseIcon(props) {
    const { gradient, ...restProps } = props;
    const fill = gradient ? 'url(#briefcase_icon_gradient)' : 'currentColor';
    return (
        <SvgIcon viewBox="0 0 32 32" {...restProps}>
            <path
                d="M22.2786 8.00006H21.332V7.8134C21.332 5.6934 19.612 3.97339 17.492 3.97339H14.5053C12.3853 3.97339 10.6653 5.6934 10.6653 7.8134V8.00006H9.85197C6.65197 8.00006 4.052 10.6001 4.052 13.8001V22.2001C4.052 25.4001 6.65197 28.0001 9.85197 28.0001H22.2786C25.4786 28.0001 28.0786 25.4001 28.0786 22.2001V13.8001C28.0786 10.6001 25.4786 8.00006 22.2786 8.00006ZM13.332 7.8134C13.332 7.16006 13.8653 6.64006 14.5053 6.64006H17.492C18.1454 6.64006 18.6653 7.1734 18.6653 7.8134V8.00006H13.332V7.8134ZM25.4119 22.2001C25.4119 23.9201 24.012 25.3334 22.2786 25.3334H9.85197C8.13197 25.3334 6.71867 23.9334 6.71867 22.2001V13.8001C6.71867 12.0801 8.11864 10.6667 9.85197 10.6667H22.2786C23.9986 10.6667 25.4119 12.0667 25.4119 13.8001V22.2001Z"
                fill={fill}
            />
            <defs>
                <linearGradient
                    id="briefcase_icon_gradient"
                    x1="4.052"
                    y1="12.6027"
                    x2="28.0846"
                    y2="12.7183"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

BriefcaseIcon.propTypes = {
    gradient: PropTypes.bool,
};
