import { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MessageHeader } from './MessageHeader';
import { MESSAGE_TYPES } from 'utils/constants';
import { RichTextInput } from 'components/Inputs';
import { MessageSubject } from './MessageSubject';
import { MessageFooter } from './MessageFooter';
import { useDynamicFields } from 'features/DynamicFields';

const RichTextInputProps = {
    'aria-label': 'compose-message-input',
    'data-trackid': 'conversation-message-body-input',
};

export const MessageBody = memo((props) => {
    const {
        innerText,
        innerHtml,
        initialBody,
        onChange,
        onChangeMessageType,
        onChangeSubject,
        onChangeGreeting,
        onUseTemplate,
        clearInput,
        isFetching,
        variant = 'compose',
        messageType,
        isEditMode,
        autofocus,
        openInNewTab,
        attachments,
        setAttachments,
        onAttachmentDelete,
        onUseSignature,
        subjectError,
        bodyError,
        setClearInput,
        initiateMount,
        disableGreeting,
        editorBodyRef,
        editorSubjectRef,
        showDynamicFields,
        bodyHeight,
        initialSubject,
        subject,
        isCollapsed,
        hasDeclineText,
    } = props;

    const { dynamicFieldDecorator, flatDynamicFields } = useDynamicFields(showDynamicFields);

    const [isFormatting, setIsFormatting] = useState(false);

    const handleToggleFormatting = useCallback(() => {
        setIsFormatting((prevState) => !prevState);
    }, []);

    useEffect(() => {
        if (isCollapsed) {
            setIsFormatting(false);
        }
    }, [isCollapsed]);

    const isToolbarDisabled = messageType === MESSAGE_TYPES.SMS;

    return (
        <>
            {messageType === MESSAGE_TYPES.EMAIL && (
                <MessageSubject
                    initialSubject={initialSubject}
                    isFetching={isFetching}
                    onChangeSubject={onChangeSubject}
                    autofocus={autofocus}
                    error={subjectError}
                    decorator={dynamicFieldDecorator}
                    initiateMount={initiateMount}
                    ref={editorSubjectRef}
                    value={subject}
                />
            )}
            <RichTextInput
                CustomHeader={MessageHeader}
                CustomHeaderProps={{
                    variant,
                    isFetching,
                    messageType,
                    onChangeMessageType,
                    onChange: onChangeGreeting,
                    greeting: !disableGreeting && messageType !== MESSAGE_TYPES.SMS,
                }}
                CustomFooter={MessageFooter}
                CustomFooterProps={{
                    messageType,
                    subject,
                    innerText,
                    innerHtml,
                    openInNewTab,
                    onUseTemplate,
                    attachments,
                    setAttachments,
                    onAttachmentDelete,
                    flatDynamicFields,
                    setClearInput,
                    onUseSignature,
                    isEditMode,
                    isFormatting,
                    isToolbarDisabled,
                    onToggleFormatting: handleToggleFormatting,
                }}
                ref={editorBodyRef}
                name={`compose-rich-text-${variant}`}
                value={innerHtml}
                onChange={onChange}
                disabled={isFetching}
                clearInput={clearInput}
                initialValue={initialBody}
                InputProps={RichTextInputProps}
                isCondensed={variant === 'reply'}
                isFormatting={isFormatting}
                isToolbarDisabled={isToolbarDisabled}
                fullWidth
                isEditMode={variant !== 'edit' && isEditMode ? true : false}
                decorator={dynamicFieldDecorator}
                error={bodyError}
                initiateMount={initiateMount}
                bodyHeight={bodyHeight}
                hasDeclineText={hasDeclineText}
            />
        </>
    );
});

MessageBody.propTypes = {
    onChange: PropTypes.func,
    onChangeSubject: PropTypes.func,
    onChangeGreeting: PropTypes.func,
    onChangeMessageType: PropTypes.func,
    onUseTemplate: PropTypes.func,
    isFetching: PropTypes.bool,
    clearInput: PropTypes.bool,
    variant: PropTypes.string,
    subject: PropTypes.string,
    innerText: PropTypes.string,
    innerHtml: PropTypes.string,
    initialBody: PropTypes.string,
    messageType: PropTypes.string,
    isEditMode: PropTypes.bool,
    autofocus: PropTypes.bool,
    openInNewTab: PropTypes.bool,
    attachments: PropTypes.array,
    setAttachments: PropTypes.func,
    onAttachmentDelete: PropTypes.func,
    subjectError: PropTypes.string,
    bodyError: PropTypes.bool,
    setClearInput: PropTypes.func,
    initiateMount: PropTypes.bool,
    onUseSignature: PropTypes.func,
    disableGreeting: PropTypes.bool,
    showDynamicFields: PropTypes.bool,
    bodyHeight: PropTypes.string,
    editorBodyRef: PropTypes.object,
    editorSubjectRef: PropTypes.object,
    initialSubject: PropTypes.string,
    isCollapsed: PropTypes.bool,
    hasDeclineText: PropTypes.bool,
};
