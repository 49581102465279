function getValues(newValues, oldValues) {
    if (newValues) {
        const newValue = newValues[newValues.length - 1];
        if (newValue && typeof newValue.value === 'string') {
            // if entered free text or selected option
            // prevent duplicate values
            if (newValue.value && !oldValues.includes(newValue.value) && newValue !== '') {
                return [...oldValues, newValue.value];
            } else {
                // user entered duplicate value, return old collection as is
                return oldValues;
            }
        } else {
            // delete/clear
            return newValues;
        }
    }
    // hit enter with no input value entered
    return oldValues;
}

export { getValues };
