import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import MuiMenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { NotesIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { useSwitchboard } from 'features/Switchboard';
import { useTalentProfileDrawer } from 'features/TalentProfileDrawer/hooks/useTalentProfileDrawer';
import { TALENT_DRAWER_TABS } from 'utils/constants';

const StyledButton = styled(IconButton)(({ theme }) => ({
    height: theme.spacing(3.25),
    width: theme.spacing(9.0),
    borderRadius: theme.spacing(0.5),
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1.1),
    background: 'transparent',
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 5,
        fontSize: '9px',
        border: `3px solid ${theme.palette.background.paper}`,
        padding: '0 2px',
    },
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    width: '100%',
    padding: theme.spacing(1),
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
    '&.Mui-disabled': {
        opacity: 1,
        'svg, .MuiTypography-root': {
            opacity: 0.35,
        },
    },
}));

export function NotesButton(props) {
    const {
        style,
        talentNotesCount,
        talentData,
        openNotesDrawer,
        scrollToNoteId,
        source,
        sourceId,
        menuItem,
        onMenuClose,
        ...restProps
    } = props;

    const isNotesFeatureEnabled = useSwitchboard('FEATURE_NN-2502_TALENT_NOTES');
    const { openTalentProfileDrawer } = useTalentProfileDrawer();

    useEffect(() => {
        if (openNotesDrawer && isNotesFeatureEnabled) {
            handleOpenNotesDrawer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openNotesDrawer, isNotesFeatureEnabled]);

    const handleOpenNotesDrawer = () => {
        openTalentProfileDrawer(
            talentData?.talentId,
            {
                defaultOpenTab: TALENT_DRAWER_TABS.NOTES_TAB,
                notesSourceConfig: { source, sourceId },
            },
            undefined,
            talentData.profileId
        );
    };

    if (!isNotesFeatureEnabled) {
        return null;
    }

    let buttonContent = (
        <StyledButton
            data-testid="notes-button"
            onClick={handleOpenNotesDrawer}
            disableRipple
            {...restProps}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: 14,
                    padding: 0,
                    height: '100%',
                }}
            >
                <NotesIcon
                    data-testid="notes-icon"
                    sx={{
                        width: '20px',
                        height: '15px',
                        position: 'absolute',
                        top: '-7px',
                        left: '-8px',
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Typography variant="bodySmall" sx={{ mr: '10px' }}>
                    Notes
                </Typography>
                {talentNotesCount > 0 && (
                    <StyledBadge
                        badgeContent={talentNotesCount}
                        color="secondary"
                        max={9}
                        style={style}
                    />
                )}
            </Box>
        </StyledButton>
    );

    if (menuItem) {
        buttonContent = (
            <MenuItem
                data-testid="notes-button"
                onClick={handleOpenNotesDrawer}
                dense
                {...restProps}
            >
                <NotesIcon data-testid="notes-icon" sx={{ fontSize: 20 }} />
                <Typography variant="titleSmall" sx={{ ml: 1, textTransform: 'none' }}>
                    View/Add Notes
                </Typography>
            </MenuItem>
        );
    }

    return <>{buttonContent}</>;
}

NotesButton.propTypes = {
    style: PropTypes.object,
    talentData: PropTypes.object.isRequired,
    talentNotesCount: PropTypes.number.isRequired,
    openNotesDrawer: PropTypes.string,
    scrollToNoteId: PropTypes.string,
    sourceId: PropTypes.string,
    source: PropTypes.string,
    onMenuClose: PropTypes.func,
    menuItem: PropTypes.bool,
};
