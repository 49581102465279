import { useState } from 'react';
import PropTypes from 'prop-types';

import {
    useContactDialog,
    PreviousContactDialog,
    OrganizationTokenDialog,
    useOrganizationTokenDialog,
} from 'components/Dialog';
import { MessageButtons } from './MessageButtons';
import { getTalentCardHeader } from 'utils/formatters/talentFormatting';
import { SequenceButton } from 'features/TalentCard/TalentSearchActions/SequenceButton';
import { TalkTrackButton } from 'features/TalentCard/TalentSearchActions/TalkTrackButton';
import { useSelector } from 'react-redux';
import { useGetUserEmailStatusQuery } from 'services/users';
import { useMessageDrawer, useTalentData } from 'features/MessageDrawer/hooks';

function getSmsPolicyViolationStatus(candidates) {
    const allPolicyViolated = candidates.every(
        (candidate) => candidate?.smsPolicyViolations?.allPolicyViolated
    );
    const somePolicyViolated = candidates.some(
        (candidate) => candidate?.smsPolicyViolations?.somePolicyViolated
    );
    const candidate = candidates.find((candidate) =>
        Boolean(candidate?.smsPolicyViolations?.policyViolationReason)
    );
    let policyViolationReason = candidate?.smsPolicyViolations?.policyViolationReason || '';

    const isBulk = candidates?.length > 1;
    if (isBulk && somePolicyViolated) {
        policyViolationReason = 'One or more profiles cannot be sent any SMS messages at this time';
    }

    return {
        allPolicyViolated,
        somePolicyViolated,
        policyViolationReason,
    };
}

export function ContactCtas(props) {
    const {
        selectedCandidates = [],
        talentEmailStatuses,
        shortlistId = '',
        disabled,
        talentSource,
        variant,
        handleCloseMenu = () => {},
    } = props;

    const [shouldReserveEmails, setShouldReserveEmails] = useState(false);
    const talentId = selectedCandidates.length === 1 && selectedCandidates[0].talentId;
    const contactCounts = selectedCandidates.length ? selectedCandidates[0].contactCounts : {};
    const user = useSelector((state) => state.user);

    const { data: isEmailConnected } = useGetUserEmailStatusQuery(user?.id, {
        skip: !user,
        refetchOnMountOrArgChange: true,
    });

    const smsPoliciesViolation = getSmsPolicyViolationStatus(selectedCandidates);

    const profiles = selectedCandidates.map((talent) => ({
        name: talent.name,
        talentId: talent.talentId,
        imageUrl: talent.imageUrl,
        title: getTalentCardHeader(talent),
        occupation: talent.occupation,
        experience: talent.experience,
    }));

    const { handleSetProfiles } = useTalentData();

    const {
        isOpen: isContactDialogOpen,
        handleConfirm: handleConfirmContactDialog,
        handleMessage,
        handleClose: handleCloseContactDialog,
        messageType,
    } = useContactDialog({
        setShouldReserveEmails,
        talentEmailStatuses,
        selectedCandidates,
        profiles,
        handleSetProfiles,
    });

    const {
        tokensUsed,
        talentLookups,
        currentStage,
        isOpen,
        isLoading,
        isLookupLoading,
        allLookupsFailed,
        handleConfirm,
        handleClose,
    } = useOrganizationTokenDialog({
        isContactDialogOpen,
        shouldReserveEmails,
        setShouldReserveEmails,
        handleCloseMenu,
        selectedTalents: selectedCandidates,
        navigationState: {
            profiles,
            messageType,
            shortlistId,
        },
    });

    const { handleMessageType, resetMessages } = useMessageDrawer();

    const talentIds = selectedCandidates.map((item) => item.talentId);

    return (
        <>
            <PreviousContactDialog
                open={isContactDialogOpen}
                onConfirm={handleConfirmContactDialog}
                onClose={handleCloseContactDialog}
                isSingleCandidate={selectedCandidates.length === 1}
            />
            <OrganizationTokenDialog
                tokensUsed={tokensUsed}
                talentLookups={talentLookups}
                currentStage={currentStage}
                open={isOpen}
                isLookupLoading={isLookupLoading}
                allLookupsFailed={allLookupsFailed}
                onConfirm={() => {
                    handleConfirm();
                }}
                onClose={handleClose}
                handleCloseMenu={handleCloseMenu}
            />
            <MessageButtons
                variant={variant}
                talentId={talentId || ''}
                talentIds={talentIds}
                isLoading={isLoading}
                onMessage={(messageType) => {
                    resetMessages();
                    handleMessage(messageType);
                    handleMessageType(messageType);
                }}
                contactCounts={contactCounts}
                isLookupLoading={isLookupLoading}
                disabled={disabled}
                smsPoliciesViolation={smsPoliciesViolation}
                user={{ ...user, isEmailConnected }}
                handleCloseMenu={handleCloseMenu}
            />
            <SequenceButton
                contactCounts={contactCounts}
                source={talentSource}
                user={{ ...user, isEmailConnected }}
                variant={variant}
                talents={selectedCandidates}
            />
            <TalkTrackButton
                talents={selectedCandidates}
                contactCounts={contactCounts}
                source={talentSource}
                user={{ ...user, isEmailConnected }}
                smsPoliciesViolation={smsPoliciesViolation}
                variant={variant}
            />
        </>
    );
}

ContactCtas.propTypes = {
    selectedCandidates: PropTypes.array,
    talentEmailStatuses: PropTypes.object,
    shortlistId: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    talentSource: PropTypes.string,
    handleCloseMenu: PropTypes.func,
};
