import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from 'components/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

export function RemoveAttachmentDialog({
    showDialog = false,
    onRemove,
    onCancel,
    isRemovingConnection,
    dialogCopy = 'talent profile',
}) {
    return (
        <Dialog open={showDialog} aria-label="confirm-remove-attachment" maxWidth="xs">
            <DialogContent
                sx={{
                    p: 4,
                    textAlign: 'center',
                }}
            >
                <Typography variant="titleMedium" sx={{ textAlign: 'center' }}>
                    This action will remove the selected <br /> attachment from this {dialogCopy}.
                    This file will still be accessible within Nebula.
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', p: 4, pt: 0 }}>
                <Button
                    aria-label="cancel-remove-connection-button"
                    fullWidth
                    variant="contained"
                    onClick={onCancel}
                    disabled={isRemovingConnection}
                    sx={{
                        backgroundColor: 'white',
                        border: '1px solid #eee',
                        color: 'text.primary',
                        p: 1,
                        '&:hover': {
                            backgroundColor: 'inherit',
                        },
                    }}
                >
                    <Typography variant="titleSmall" sx={{ textTransform: 'capitalize' }}>
                        Cancel
                    </Typography>
                </Button>
                <Button
                    aria-label="remove-attachment-dialog-button"
                    variant="contained"
                    onClick={onRemove}
                    disabled={isRemovingConnection}
                    fullWidth
                    sx={{ p: 1 }}
                >
                    {isRemovingConnection ? (
                        <CircularProgress size={16} sx={{ color: 'white' }} />
                    ) : null}{' '}
                    <Typography variant="titleSmall" sx={{ textTransform: 'capitalize' }}>
                        Remove Attachment
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

RemoveAttachmentDialog.propTypes = {
    showDialog: PropTypes.bool,
    onRemove: PropTypes.func,
    onCancel: PropTypes.func,
    isRemovingConnection: PropTypes.bool,
    dialogCopy: PropTypes.string,
};
