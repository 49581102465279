import { Interweave } from 'interweave';
import { Link } from 'react-router-dom';
import { parseJSON, toDate } from 'date-fns';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { LinkOutIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { useTalentData } from 'features/MessageDrawer/hooks';
import { formatDateDistanceToNow, getInitials } from 'utils/formatters';
import { MessageStatusBadge } from 'views/Communications/CommunicationsMetrics/TalentChart';
import { storeTempTalentIdFilter } from 'features/MessageDrawer/utils';
import { useSelector } from 'react-redux';

const TalentAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: 'linear-gradient(90.28deg, #29CC9A 0.17%, #318DDE 99.72%)',
    '&:hover': {
        cursor: 'pointer',
    },
}));

export function MessageHistoryContent(props) {
    const { conversations } = props;
    const user = useSelector((state) => state.user);

    const { selectedProfiles } = useTalentData();
    const talentIds = selectedProfiles.map((profile) => profile.talentId);

    const handleNavigate = () => {
        storeTempTalentIdFilter(talentIds);
    };

    const firstConversationLink = conversations?.[0]
        ? `/communications/messages/${conversations[0].conversationId}`
        : '/communications';

    if (selectedProfiles.length === 1 && conversations?.length === 1) {
        const sendDate = toDate(parseJSON(conversations[0]?.messages[0]?.sendDate)) || new Date();
        const isUser = conversations[0]?.messages[0]?.sender?.type === 'user';
        return (
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <TalentAvatar src={isUser ? '' : selectedProfiles[0]?.imageUrl}>
                    <Typography variant="labelMedium">
                        {getInitials(isUser ? user.displayName : selectedProfiles[0]?.name, '')}
                    </Typography>
                </TalentAvatar>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                    <Typography
                        variant="bodyBase"
                        sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            color: 'text.primary',
                        }}
                    >
                        <Interweave content={conversations[0].messages[0]?.body}></Interweave>
                    </Typography>
                    <MessageStatusBadge
                        sx={{ maxWidth: 'fit-content', mt: 0.4 }}
                        label={conversations[0].messages[0].status}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="bodySmall">{formatDateDistanceToNow(sendDate)}</Typography>
                    <IconButton
                        aria-label="link to conversations"
                        LinkComponent={Link}
                        to={firstConversationLink}
                        target="_blank"
                        onClick={handleNavigate}
                    >
                        <LinkOutIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
                    </IconButton>
                </Box>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box sx={{ display: 'flex' }}>
                {selectedProfiles?.slice(0, 4)?.map((profile, index) => (
                    <TalentAvatar
                        key={`${profile?.id || profile?.talentId}-avatar`}
                        sx={{ ml: index > 0 ? -1 : 0 }}
                        src={profile?.imageUrl}
                    >
                        <Typography variant="labelMedium">
                            {getInitials(profile?.name, '')}
                        </Typography>
                    </TalentAvatar>
                ))}
            </Box>
            <Typography color="primary.main" variant="bodySmall">
                {conversations?.length} Recent Messages
            </Typography>
            <IconButton
                aria-label="link to conversations"
                LinkComponent={Link}
                to={firstConversationLink}
                target="_blank"
                onClick={handleNavigate}
            >
                <LinkOutIcon sx={{ fontSize: 16, color: 'text.secondary' }} />
            </IconButton>{' '}
        </Box>
    );
}

MessageHistoryContent.propTypes = {
    conversations: PropTypes.array,
};
