import PropTypes from 'prop-types';
import { LinkElement } from './LinkElement';

export const getStyle = (alignParam, initialStyleParam) => {
    if (alignParam) {
        return {
            textAlign: alignParam,
        };
    } else if (initialStyleParam) {
        return initialStyleParam;
    } else {
        return { textAlign: 'left' };
    }
};

export function Element(props) {
    const { attributes, children, element } = props;
    const { align, style: initialStyle } = element;
    const style = getStyle(align, initialStyle);

    switch (element.type) {
        case 'paragraph':
            return (
                <p {...attributes} style={style} data-testid="paragraph">
                    {children}
                </p>
            );
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            );
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            );
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            );
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            );
        case 'heading-three':
            return (
                <h3 style={style} {...attributes}>
                    {children}
                </h3>
            );
        case 'heading-four':
            return (
                <h4 style={style} {...attributes}>
                    {children}
                </h4>
            );
        case 'heading-five':
            return (
                <h5 style={style} {...attributes}>
                    {children}
                </h5>
            );
        case 'heading-six':
            return (
                <h6 style={style} {...attributes}>
                    {children}
                </h6>
            );
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            );
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        case 'div':
            return (
                <div style={style} {...attributes}>
                    {children}
                </div>
            );
        case 'link':
            return (
                <LinkElement attributes={attributes} element={element}>
                    {children}
                </LinkElement>
            );
        default:
            return (
                <span style={style} {...attributes}>
                    {children}
                </span>
            );
    }
}

Element.propTypes = {
    attributes: PropTypes.object,
    children: PropTypes.node,
    element: PropTypes.object,
};

export function ElementWrapper(props) {
    return <Element {...props} />;
}
