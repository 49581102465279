import SvgIcon from '@mui/material/SvgIcon';

export function AltCheckCircleIcon(props) {
    return (
        <SvgIcon viewBox="0 0 64 64" {...props}>
            <path
                d="M55.9727 29.3327C54.5061 29.3327 53.3061 30.5327 53.3061 31.9993C53.3061 43.7327 43.7594 53.306 31.9994 53.306C20.2394 53.306 10.6927 43.7593 10.6927 31.9993C10.6927 20.2393 20.2394 10.6927 31.9994 10.6927C33.4661 10.6927 34.6661 9.49271 34.6661 8.02604C34.6661 6.55938 33.4661 5.35938 31.9994 5.35938C17.3061 5.35938 5.35938 17.306 5.35938 31.9993C5.35938 46.6927 17.3061 58.6393 31.9994 58.6393C46.6927 58.6393 58.6394 46.6927 58.6394 31.9993C58.6394 30.5327 57.4394 29.3327 55.9727 29.3327Z"
                fill="currentColor"
            />
            <path
                d="M23.2251 27.467C22.1851 26.427 20.5051 26.427 19.4651 27.467C18.4251 28.507 18.4251 30.1871 19.4651 31.2271L28.1584 39.8937C29.1184 40.8537 30.425 41.4136 31.785 41.4136C31.8917 41.4136 31.9984 41.4136 32.105 41.4136C33.5717 41.3336 34.9317 40.6136 35.8384 39.4403L52.7717 17.6538C53.6784 16.4805 53.465 14.8271 52.3184 13.9204C51.145 13.0137 49.4917 13.2272 48.5851 14.3739L31.785 36.0005L23.2517 27.467H23.2251Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
