import { Typography } from 'components/Typography';

export function PiplErrorStage() {
    return (
        <Typography
            variant="titleMedium"
            sx={{ color: 'text.primary', textAlign: 'center', mb: 3 }}
        >
            There was an internal error when looking up talents. Please go back and try again in a
            moment.
        </Typography>
    );
}
