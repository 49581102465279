import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import { styled, alpha, lighten } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';

import { Typography } from 'components/Typography';

const FormLabel = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'error',
})(({ theme, disabled, error }) => {
    const styles = { transition: theme.transitions.create(['color']) };
    if (disabled) {
        styles.color = alpha(theme.palette.common.white, theme.palette.action.disabledOpacity);
    } else if (error) {
        styles.color = theme.palette.error.main;
    } else {
        styles.color = 'inherit';
    }
    return styles;
});

const Input = styled(InputBase)(({ theme }) => ({
    '&.MuiInputBase-root': {
        'label + &': {
            marginTop: theme.spacing(1),
        },
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.divider}`,
        transition: theme.transitions.create(['border-color']),
        padding: 0,
        '&:focus-within': {
            borderColor: lighten(theme.palette.text.primary, 0.1),
        },
        '&.Mui-error': {
            borderColor: theme.palette.error.main,
        },
    },
    '& .MuiInputBase-input': {
        position: 'relative',
        backgroundColor: 'inherit',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1.125, 4, 1.25, 2),
        fontSize: 16,
        fontWeight: 400,
        color: 'inherit',
        fontFamily: theme.typography.fontFamily,
        '.Mui-disabled > &': {
            color: alpha(theme.palette.text.primary, theme.palette.action.disabledOpacity),
        },
    },
}));

export const TextInput = forwardRef((props, ref) => {
    const {
        className,
        readOnly: _readOnly,
        FormHelperTextProps,
        InputLabelProps,
        InputProps,
        inputProps,
        autoFocus,
        disabled,
        error,
        name,
        type,
        id,
        value,
        label,
        placeholder,
        helperText,
        inputRef,
        onBlur,
        onChange,
        onFocus,
        trackid,
        ...restProps
    } = props;

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
    const inputLabelId = label && id ? `${id}-label` : undefined;
    const readOnly = InputProps?.readOnly || _readOnly;
    const dataTrackId = trackid ? { 'data-trackid': trackid } : {};

    return (
        <FormControl
            className={className}
            disabled={disabled || readOnly}
            aria-readonly={readOnly}
            aria-disabled={disabled}
            error={error}
            ref={ref}
            sx={{ boxSizing: 'border-box' }}
            {...restProps}
        >
            {label && (
                <FormLabel
                    htmlFor={id}
                    id={inputLabelId}
                    component="label"
                    variant="labelMedium"
                    color="textPrimary"
                    error={error}
                    disabled={disabled}
                    {...InputLabelProps}
                >
                    {label}
                </FormLabel>
            )}

            <Input
                aria-describedby={helperTextId}
                autoFocus={autoFocus}
                name={name}
                type={type}
                value={value}
                id={id}
                inputRef={inputRef}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                placeholder={placeholder}
                inputProps={{ ...inputProps, ...dataTrackId }}
                {...InputProps}
            />

            <Collapse in={Boolean(helperText)}>
                <FormHelperText id={helperTextId} {...FormHelperTextProps}>
                    {helperText}
                </FormHelperText>
            </Collapse>
        </FormControl>
    );
});

TextInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    inputProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.any,
    inputRef: PropTypes.any,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    autoFocus: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
    trackid: PropTypes.string,
};
