import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';
import { talentApi } from './talent';

const aggregatorBaseUrl = 'api/aggregator/v1';
const feedbackBaseUrl = 'api/feedback/v1';

export const feedbackApi = createApi({
    reducerPath: 'feedbackApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['Collaborators', 'FeedbackLists', 'FeedbackList', 'Comments'],
    endpoints: (builder) => ({
        sendFeedback: builder.mutation({
            query: (body) => ({
                url: `${feedbackBaseUrl}/feedback`,
                method: 'POST',
                body,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so the following will refresh the shortlist
                    dispatch(talentApi.util.invalidateTags([{ type: 'Shortlists', id: 'LISTS' }]));
                } catch (error) {
                    // do nothing
                }
            },
        }),
        getFeedbackListsMetadata: builder.query({
            query: () => ({
                url: `${aggregatorBaseUrl}/lists/feedback/collaborator/metadata`,
                method: 'GET',
            }),
        }),
        getCollaborators: builder.query({
            query: (feedbackId) => {
                return {
                    url: `${aggregatorBaseUrl}/feedback/${feedbackId}/collaborators`,
                    method: 'GET',
                };
            },
            providesTags: [{ type: 'Collaborators', id: 'LISTS' }],
        }),
        addCollaborator: builder.mutation({
            query: ({ feedbackId, body }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: [{ type: 'Collaborators', id: 'LISTS' }],
            async onQueryStarted({ shortlistId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so the following will refresh the Shortlists
                    dispatch(
                        talentApi.util.invalidateTags([{ type: 'Shortlists', id: shortlistId }])
                    );
                } catch (error) {
                    // do nothing
                }
            },
        }),
        removeCollaborator: builder.mutation({
            query: ({ feedbackId, collaboratorEmail }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}/${encodeURIComponent(
                        collaboratorEmail
                    )}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'Collaborators', id: 'LISTS' }],
            async onQueryStarted({ shortlistId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so the following will refresh the Shortlists
                    dispatch(
                        talentApi.util.invalidateTags([{ type: 'Shortlists', id: shortlistId }])
                    );
                } catch (error) {
                    // do nothing
                }
            },
        }),
        editSchedulingLink: builder.mutation({
            query: ({ collaboratorId, feedbackId, schedulingLink }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}/${collaboratorId}`,
                    method: 'PUT',
                    body: { schedulingLink },
                };
            },
            invalidatesTags: [{ type: 'Collaborators', id: 'LISTS' }],
        }),
        updateAnonymizeFeedbackList: builder.mutation({
            query: ({ feedbackId, anonymize }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}`,
                    method: 'PUT',
                    body: {
                        anonymize,
                    },
                };
            },
            async onQueryStarted({ shortlistId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so the following will refresh the Shortlist
                    dispatch(
                        talentApi.util.invalidateTags([{ type: 'Shortlist', id: shortlistId }])
                    );
                } catch (error) {
                    // do nothing
                }
            },
        }),
        getCollaboratingFeedbackLists: builder.query({
            query: (args) => {
                return {
                    url: `${aggregatorBaseUrl}/lists/feedback/collaborator`,
                    method: 'GET',
                    params: args,
                };
            },
            providesTags: (data) =>
                data
                    ? [
                          ...data.results.map(({ derivedFromId: id }) => ({
                              type: 'FeedbackLists',
                              id,
                          })),
                          { type: 'FeedbackLists', id: 'LISTS' },
                      ]
                    : [{ type: 'FeedbackLists', id: 'LISTS' }],
        }),
        getCollaboratingFeedbackListTalent: builder.query({
            query: ({ feedbackId, derivedFromType, derivedFromId, page, pageSize }) => ({
                url: `${aggregatorBaseUrl}/feedback/${feedbackId}/collaborator`,
                method: 'GET',
                params: { page, pageSize, derivedFromType, derivedFromId },
            }),
            providesTags: (result, error, { feedbackId }) => [
                { type: 'FeedbackList', id: feedbackId },
            ],
        }),
        submitFeedbackResponse: builder.mutation({
            query: ({ feedbackListId, schedulingLink, message, feedback }) => {
                const body = { feedback: [] };
                if (schedulingLink) {
                    body.schedulingLink = schedulingLink;
                }
                if (message) {
                    body.message = message;
                }
                for (const talentId in feedback) {
                    body.feedback.push({
                        talentId,
                        score: feedback[talentId],
                    });
                }
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackListId}`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: (result, error, { feedbackListId }) => {
                if (!error) {
                    return [
                        { type: 'FeedbackList', id: feedbackListId },
                        { type: 'FeedbackLists', id: 'LISTS' },
                    ];
                }
                return [];
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so this refreshes the prospect count shown on the shortlist page
                    dispatch(
                        talentApi.util.invalidateTags([
                            { type: 'Shortlist', id: 'LIST' },
                            { type: 'Shortlists', id: 'LISTS' },
                        ])
                    );
                } catch (error) {
                    // do nothing
                }
            },
        }),
        updateIsNoteStatus: builder.mutation({
            query: ({ feedbackId, saveCommentsAsNote }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}`,
                    method: 'PUT',
                    body: {
                        saveCommentsAsNote,
                    },
                };
            },
            invalidatesTags: (results, error, { feedbackId }) => [
                { type: 'FeedbackList', id: feedbackId },
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(talentApi.util.invalidateTags([{ type: 'Shortlist', id: 'LIST' }]));
                } catch (error) {
                    // do nothing
                }
            },
        }),
        getComments: builder.query({
            query: ({ threadId, feedbackId }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}/thread/${threadId}`,
                    method: 'GET',
                };
            },
            providesTags: (results, error, { threadId, feedbackId }) => [
                { type: 'Comments', id: `${feedbackId}-${threadId}` },
            ],
        }),
        addComment: builder.mutation({
            query: ({ threadId, feedbackId, comment }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}/thread/${threadId}`,
                    method: 'POST',
                    body: { comment },
                };
            },
            invalidatesTags: (results, error, { threadId, feedbackId }) => [
                { type: 'Comments', id: `${feedbackId}-${threadId}` },
                { type: 'FeedbackList', id: feedbackId },
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so this refreshes the comment count shown on the talent cards
                    dispatch(talentApi.util.invalidateTags([{ type: 'Shortlist', id: 'LIST' }]));
                } catch (error) {
                    // do nothing
                }
            },
        }),
        editComment: builder.mutation({
            query: ({ threadId, feedbackId, commentId, body }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}/thread/${threadId}/comment/${commentId}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: (results, error, { threadId, feedbackId }) => [
                { type: 'Comments', id: `${feedbackId}-${threadId}` },
            ],
        }),
        deleteComment: builder.mutation({
            query: ({ threadId, feedbackId, commentId }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}/thread/${threadId}/comment/${commentId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: (results, error, { threadId, feedbackId }) => [
                { type: 'Comments', id: `${feedbackId}-${threadId}` },
                { type: 'FeedbackList', id: feedbackId },
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so this refreshes the comment count shown on the talent cards
                    dispatch(talentApi.util.invalidateTags([{ type: 'Shortlist', id: 'LIST' }]));
                } catch (error) {
                    // do nothing
                }
            },
        }),
        getAllTalent: builder.query({
            query: ({ feedbackId }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}/talent`,
                    method: 'GET',
                };
            },
        }),
        resetExpiration: builder.mutation({
            query: ({ feedbackId }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}`,
                    method: 'PUT',
                    body: {
                        resetExpiration: true,
                    },
                };
            },
            async onQueryStarted({ shortlistId }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so the following will refresh the Shortlist
                    dispatch(
                        talentApi.util.invalidateTags([
                            { type: 'Shortlists', id: shortlistId },
                            { type: 'Shortlist', id: 'LIST' },
                        ])
                    );
                } catch (error) {
                    // do nothing
                }
            },
        }),
        updateShareProfilePool: builder.mutation({
            query: ({ feedbackId, poolType }) => {
                return {
                    url: `${feedbackBaseUrl}/feedback/${feedbackId}`,
                    method: 'PUT',
                    body: {
                        poolType,
                    },
                };
            },
        }),
    }),
});

export const {
    useSendFeedbackMutation,
    useGetFeedbackListsMetadataQuery,
    useLazyGetCollaboratorsQuery,
    useRemoveCollaboratorMutation,
    useGetCollaboratorsQuery,
    useAddCollaboratorMutation,
    useEditSchedulingLinkMutation,
    useUpdateAnonymizeFeedbackListMutation,
    useGetCollaboratingFeedbackListsQuery,
    useGetCollaboratingFeedbackListTalentQuery,
    useSubmitFeedbackResponseMutation,
    useUpdateIsNoteStatusMutation,
    useGetCommentsQuery,
    useAddCommentMutation,
    useGetAllTalentQuery,
    useEditCommentMutation,
    useDeleteCommentMutation,
    useResetExpirationMutation,
    useUpdateShareProfilePoolMutation,
} = feedbackApi;
