import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiCollapse from '@mui/material/Collapse';

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
}));

export const ExpandableCard = forwardRef((props, ref) => {
    const { children, collapsed, collapsedSize } = props;

    return (
        <Container ref={ref}>
            <MuiCollapse sx={{ width: '100%' }} in={!collapsed} collapsedSize={collapsedSize}>
                {children}
            </MuiCollapse>
        </Container>
    );
});

ExpandableCard.propTypes = {
    children: PropTypes.node,
    collapsed: PropTypes.bool,
    collapsedSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
