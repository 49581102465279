import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';

import { MESSAGE_TYPES } from 'utils/constants';
import { MessageLabelBoxContent } from './MessageLabelBoxContent';
import { MessageLabelTypography } from './MessageLabelTypography.styled';

export function MessageTypeLabel(props) {
    const {
        messageType,
        isDigitalAssistantMessage,
        isSequenceMessage,
        sender,
        recipient,
        isOutgoing,
    } = props;

    if (isDigitalAssistantMessage) {
        return (
            <MessageLabelTypography
                variant="labelXSmall"
                sx={{
                    background: 'linear-gradient(90.28deg, #504CE0 0.17%, #318DDE 99.72%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                }}
            >
                DIGITAL ASSISTANT
            </MessageLabelTypography>
        );
    }
    if (isSequenceMessage) {
        return (
            <MessageLabelTypography variant="labelXSmall" sx={{ color: alpha('#5F2BE1', 0.72) }}>
                SEQUENCES
            </MessageLabelTypography>
        );
    }
    if (messageType === MESSAGE_TYPES.EMAIL) {
        return (
            <MessageLabelBoxContent
                isOutgoing={isOutgoing}
                sender={sender}
                recipient={recipient}
                messageType={MESSAGE_TYPES.EMAIL}
            />
        );
    }
    if (messageType === MESSAGE_TYPES.SMS) {
        return (
            <MessageLabelBoxContent
                sender={sender}
                recipient={recipient}
                messageType={MESSAGE_TYPES.SMS}
                isOutgoing={isOutgoing}
            />
        );
    }
    return null;
}

MessageTypeLabel.propTypes = {
    sender: PropTypes.string,
    recipient: PropTypes.string,
    messageType: PropTypes.string,
    isDigitalAssistantMessage: PropTypes.bool,
    isSequenceMessage: PropTypes.bool,
    isOutgoing: PropTypes.bool,
};
