export const USER_NAVIGATION = {
    USER_SETTINGS: {
        headerNavTrackId: 'avatar-menu-user-settings',
        sideNavTrackId: 'user-settings-nav-user-settings',
        navId: 'user-settings-nav-header',
        label: 'User Settings',
        title: 'User Settings',
        index: 0,
    },
    ORGANIZATION_CREDITS: {
        headerNavTrackId: 'avatar-menu-org-credits',
        sideNavTrackId: 'user-settings-nav-org-credits',
        navId: 'organization-credits-nav-header',
        label: 'Organization Credits',
        title: 'Organization Credits',
        index: 1,
    },
    KNOWLEDGE_BASES: {
        headerNavTrackId: 'avatar-menu-knowledge-bases',
        sideNavTrackId: 'user-settings-nav-knowledge-bases',
        navId: 'knowledge-bases-nav-header',
        label: 'Knowledge Bases',
        title: 'Knowledge Bases',
        subtitle:
            'A Knowledge Base is a place to save information about a job or company you want to use in Digital Assistant Talk Tracks',
        index: 2,
    },
    DIGITAL_ASSISTANT: {
        headerNavTrackId: 'digital-assistant-nav-header',
        navId: 'digital-assistant-nav-header',
        label: 'Digital Assistant Settings',
        title: 'Digital Assistant',
        index: -1,
    },
    SCHEDULES: {
        headerNavTrackId: 'avatar-menu-scheduling',
        sideNavTrackId: 'user-settings-nav-scheduling',
        navId: 'schedule-nav-header',
        label: 'Scheduling',
        title: 'Scheduling',
        index: 4,
    },
    EMAIL_SIGNATURES: {
        headerNavTrackId: 'avatar-menu-email-signatures',
        sideNavTrackId: 'user-settings-nav-email-signatures',
        navId: 'email-signature-nav-header',
        label: 'Email Signatures',
        title: 'Email Signatures',
        index: 5,
    },
    HIDDEN_PROFILES: {
        headerNavTrackId: 'avatar-menu-hidden-profiles',
        sideNavTrackId: 'user-settings-nav-hidden-profiles',
        navId: 'hidden-profiles-nav-header',
        label: 'Hidden Profiles',
        title: 'Hidden Profiles',
        index: 6,
    },
    CONTACT_US: {
        navId: 'contact-us-nav-header',
        label: 'Need Help? Contact Us',
        title: 'Need Help? Contact Us',
        index: 100,
    },
    YOUR_MEDIA: {
        navId: 'your-media-nav-header',
        label: 'Your Media',
        title: 'Your Media',
        index: 0.5,
    },
};
