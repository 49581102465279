import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Typography } from 'components/Typography';
import { MESSAGE_TYPES } from 'utils/constants';
import { useMessageDrawer } from 'features/MessageDrawer/hooks';

const MessageTypeToggleButton = styled(ToggleButton)(({ theme }) => ({
    width: '100%',
    borderRadius: '62px !important',
    border: 'none',
    padding: 0,
    height: '34px',
    boxSizing: 'border-box',
    '&.Mui-selected': {
        border: '0.5px solid #2637461F !important',
        color: theme.palette.text.primary,
        backgroundColor: '#F3F8FD',
    },
}));

export function MessageTypeToggle() {
    const { handleMessageType, messageType } = useMessageDrawer();

    const handleSelectMessageType = (e, value) => {
        if (value !== null) {
            handleMessageType(value);
        }
    };

    return (
        <ToggleButtonGroup
            value={messageType}
            exclusive
            sx={{
                width: '100%',
                alignContent: 'center',
                borderRadius: '62px',
                border: '1px solid #2637461F',
                boxSizing: 'border-box',
                height: '36px',
            }}
            onChange={handleSelectMessageType}
            aria-label="select message type"
        >
            <MessageTypeToggleButton
                disableRipple
                value={MESSAGE_TYPES.EMAIL}
                aria-label={MESSAGE_TYPES.EMAIL}
            >
                <Typography variant="bodyBaseBold">{MESSAGE_TYPES.EMAIL}</Typography>
            </MessageTypeToggleButton>
            <MessageTypeToggleButton
                disableRipple
                value={MESSAGE_TYPES.SMS}
                aria-label={MESSAGE_TYPES.SMS}
            >
                <Typography variant="bodyBaseBold">{MESSAGE_TYPES.SMS}</Typography>
            </MessageTypeToggleButton>
        </ToggleButtonGroup>
    );
}
