import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import { useDispatch, useSelector } from 'react-redux';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import {
    addKeywordFilter,
    addProfileHighlight,
    changeStandardExcludeFilter,
    changeStandardFilter,
} from 'views/Search';
import {
    EDUCATION_FILTER,
    EMPLOYMENT_FILTER,
} from 'views/Search/features/FiltersBar/components/constants';
import { Typography } from 'components/Typography';
import { FilterMenu } from './components/FilterMenu';
import { MatchInputMenu } from './components/MatchInputMenu';
import { PlusAltIcon, MatchBoldIcon, FilterAltIcon, ChevronDownIcon } from 'components/Icons';

const ShadowWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(2.5),
}));

const ChevronIcon = styled(ChevronDownIcon, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme, isOpen }) => ({
    transform: `rotate(${isOpen ? 180 : 0}deg)`,
    transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
    }),
}));

const MatchInputMenuButton = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== 'isStandalone',
})(({ theme, isStandalone }) => ({
    borderRadius: theme.shape.borderRadius,
    borderTopRightRadius: isStandalone ? theme.shape.borderRadius : 0,
    borderBottomRightRadius: isStandalone ? theme.shape.borderRadius : 0,
    padding: theme.spacing(0, 1),
    width: theme.spacing(22.5),
    height: theme.spacing(2.5),
    color: theme.palette.common.white,
    background: 'linear-gradient(93deg, #504CE0 0%, #318DDE 100%)',
    zIndex: theme.zIndex.appBar - 1,
}));

const FilterMenuButton = styled(ButtonBase)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.5),
    width: theme.spacing(10.5),
    height: theme.spacing(2.5),
    color: theme.palette.common.white,
    background: theme.palette.background.sidebar,
    zIndex: theme.zIndex.appBar - 1,
}));

const selectDegrees = (state) => state.search.filters[EDUCATION_FILTER.DEGREES] || [];
const selectSchools = (state) => state.search.filters[EDUCATION_FILTER.SCHOOLS] || [];
const selectJobTitle = (state) => state.search.filters[EMPLOYMENT_FILTER.JOB_TITLE] || [];
const selectCurrentJobTitle = (state) =>
    state.search.filters[EMPLOYMENT_FILTER.CURRENT_JOB_TITLE] || [];
const selectCurrentCompanyName = (state) =>
    state.search.filters[EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME] || [];
const selectCompanyName = (state) => state.search.filters[EMPLOYMENT_FILTER.COMPANY_NAME] || [];

const selectExcludeDegrees = (state) =>
    state.search.exclusionFilters[EDUCATION_FILTER.DEGREES] || [];
const selectExcludeSchools = (state) =>
    state.search.exclusionFilters[EDUCATION_FILTER.SCHOOLS] || [];
const selectExcludeJobTitle = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.JOB_TITLE] || [];
const selectExcludeCurrentJobTitle = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.CURRENT_JOB_TITLE] || [];
const selectExcludeCurrentCompanyName = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME] || [];
const selectExcludeCompanyName = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.COMPANY_NAME] || [];

export function Highlighter(props) {
    const { onActionTaken, onClickAway, selection } = props;

    const dispatch = useDispatch();
    const degrees = useSelector(selectDegrees);
    const schools = useSelector(selectSchools);
    const jobTitles = useSelector(selectJobTitle);
    const currentJobTitles = useSelector(selectCurrentJobTitle);
    const currentCompanyNames = useSelector(selectCurrentCompanyName);
    const companyNames = useSelector(selectCompanyName);

    const excludedDegrees = useSelector(selectExcludeDegrees);
    const excludedSchools = useSelector(selectExcludeSchools);
    const excludedJobTitles = useSelector(selectExcludeJobTitle);
    const excludedCurrentJobTitles = useSelector(selectExcludeCurrentJobTitle);
    const excludedCurrentCompanyNames = useSelector(selectExcludeCurrentCompanyName);
    const excludedCompanyNames = useSelector(selectExcludeCompanyName);

    const [matchInputMenuAnchorEl, setMatchInputMenuAnchorEl] = useState(false);

    const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState(false);

    const hideFilterMenu = selection?.value?.length >= 100;

    const filterPropertyMap = {
        [EDUCATION_FILTER.DEGREES]: degrees,
        [EDUCATION_FILTER.SCHOOLS]: schools,
        [EMPLOYMENT_FILTER.JOB_TITLE]: jobTitles,
        [EMPLOYMENT_FILTER.CURRENT_JOB_TITLE]: currentJobTitles,
        [EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME]: currentCompanyNames,
        [EMPLOYMENT_FILTER.COMPANY_NAME]: companyNames,
    };

    const exclusionFilterPropertyMap = {
        [EDUCATION_FILTER.DEGREES]: excludedDegrees,
        [EDUCATION_FILTER.SCHOOLS]: excludedSchools,
        [EMPLOYMENT_FILTER.JOB_TITLE]: excludedJobTitles,
        [EMPLOYMENT_FILTER.CURRENT_JOB_TITLE]: excludedCurrentJobTitles,
        [EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME]: excludedCurrentCompanyNames,
        [EMPLOYMENT_FILTER.COMPANY_NAME]: excludedCompanyNames,
    };

    const handleMouse = (event) => {
        // without this, the click removes the selected text and makes this component dissapear
        event.stopPropagation();
        event.preventDefault();
    };

    const handleMatchInputMenuClick = (event) => {
        setMatchInputMenuAnchorEl(event.currentTarget);
    };

    const handleFilterMenuClick = (event) => {
        setFilterMenuAnchorEl(event.currentTarget);
    };

    const handleAction = () => {
        if (typeof onActionTaken === 'function') {
            onActionTaken();
        }
    };

    const handleAddEmphasis = () => {
        dispatch(addProfileHighlight(selection));
        handleAction();
    };

    const handleAddEmphasisPlus = () => {
        dispatch(addProfileHighlight({ ...selection, emphasize: true }));
        handleAction();
    };

    const handleAddKeywordFilter = () => {
        dispatch(addKeywordFilter({ isExclude: false, keyword: selection.value }));
        handleAction();
    };

    const handleAddKeywordExcludeFilter = () => {
        dispatch(addKeywordFilter({ isExclude: true, keyword: selection.value }));
        handleAction();
    };

    const createStandardFilterHandler = (property) => () => {
        dispatch(
            changeStandardFilter({
                property,
                matchText: [...filterPropertyMap[property], selection.value],
            })
        );
        handleAction();
    };

    const createStandardExcludeFilterHandler = (property) => () => {
        dispatch(
            changeStandardExcludeFilter({
                property,
                matchText: [...exclusionFilterPropertyMap[property], selection.value],
            })
        );
        handleAction();
    };

    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClickAway}>
            <ShadowWrapper>
                <MatchInputMenuButton
                    aria-label="open-add-to-profile-description-menu"
                    data-trackid="profile-highlight-add-to-profile-description"
                    onClick={handleMatchInputMenuClick}
                    onMouseDown={handleMouse}
                    onMouseUp={handleMouse}
                    isStandalone={hideFilterMenu}
                    disableRipple
                >
                    <PlusAltIcon sx={{ fontSize: 6, mr: '1px' }} />
                    <MatchBoldIcon sx={{ fontSize: 17.5, mb: '-1.5px' }} />
                    <Box sx={{ pl: 0.5, pr: 0.25, mt: '-1.5px' }}>
                        <Typography variant="bodyXSmall" color="common.white">
                            Add to Profile Description
                        </Typography>
                    </Box>
                    <ChevronIcon
                        isOpen={Boolean(matchInputMenuAnchorEl)}
                        sx={{ fontSize: 15, mb: '-1px', ml: 'auto', mr: '-3.5px' }}
                    />
                </MatchInputMenuButton>
                {!hideFilterMenu && (
                    <FilterMenuButton
                        aria-label="open-add-to-filter-menu"
                        data-trackid="profile-highlight-add-as-filter"
                        onClick={handleFilterMenuClick}
                        onMouseDown={handleMouse}
                        onMouseUp={handleMouse}
                        disableRipple
                    >
                        <PlusAltIcon sx={{ fontSize: 6, mr: '1px' }} />
                        <FilterAltIcon sx={{ fontSize: 10 }} />
                        <Box sx={{ pl: 0.75, pr: 0.5, mt: '-1.5px' }}>
                            <Typography variant="bodyXSmall" color="common.white">
                                Filter
                            </Typography>
                        </Box>
                        <ChevronIcon
                            isOpen={Boolean(filterMenuAnchorEl)}
                            sx={{ fontSize: 15, mb: '-1px', ml: 'auto', mr: '-3.5px' }}
                        />
                    </FilterMenuButton>
                )}
                <MatchInputMenu
                    anchorEl={matchInputMenuAnchorEl}
                    open={Boolean(matchInputMenuAnchorEl)}
                    onMouseDown={handleMouse}
                    onMouseUp={handleMouse}
                    onClose={() => setMatchInputMenuAnchorEl(null)}
                    onAdd={handleAddEmphasis}
                    onAddAndEmphasize={handleAddEmphasisPlus}
                />
                <FilterMenu
                    anchorEl={filterMenuAnchorEl}
                    open={Boolean(filterMenuAnchorEl)}
                    onMouseDown={handleMouse}
                    onMouseUp={handleMouse}
                    onClose={() => setFilterMenuAnchorEl(null)}
                    onAddKeywordFilter={handleAddKeywordFilter}
                    onAddKeywordExcludeFilter={handleAddKeywordExcludeFilter}
                    createStandardFilterHandler={createStandardFilterHandler}
                    createStandardExcludeFilterHandler={createStandardExcludeFilterHandler}
                />
            </ShadowWrapper>
        </ClickAwayListener>
    );
}

Highlighter.propTypes = {
    onActionTaken: PropTypes.func,
    onClickAway: PropTypes.func,
    selection: PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
    }),
};
