import { CONTACT_STATUS_MAP } from 'features/TalentCard/utils/constants';

export function filterValidOptions(contact) {
    return contact.status !== CONTACT_STATUS_MAP.OPT_OUT && !contact.isCoolingDown;
}

export function reorderContacts(contacts) {
    const primaryContacts = [];
    const verifiedContacts = [];
    const otherContacts = [];

    for (const contact of contacts) {
        if (contact?.isPrimary) {
            primaryContacts.push(contact);
        } else if (contact?.status === CONTACT_STATUS_MAP.VERIFIED) {
            verifiedContacts.push(contact);
        } else {
            otherContacts.push(contact);
        }
    }

    return primaryContacts.concat(verifiedContacts, otherContacts);
}
