import PropTypes from 'prop-types';

import {
    PinterestIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedInIcon,
    WorldIcon,
} from 'components/Icons';
import { SOCIAL_NETWORKS } from '../utils/constants';

export function SocialMediaIconType(props) {
    const { socialMediaType } = props;

    if (socialMediaType === SOCIAL_NETWORKS.INSTAGRAM) {
        return <InstagramIcon data-testid="instagram-icon" />;
    }
    if (socialMediaType === SOCIAL_NETWORKS.FACEBOOK) {
        return <FacebookIcon data-testid="facebook-icon" />;
    }
    if (socialMediaType === SOCIAL_NETWORKS.TWITTER) {
        return <TwitterIcon data-testid="twitter-icon" />;
    }
    if (socialMediaType === SOCIAL_NETWORKS.PINTEREST) {
        return <PinterestIcon data-testid="pinterest-icon" />;
    }
    if (socialMediaType === SOCIAL_NETWORKS.LINKEDIN) {
        return <LinkedInIcon data-testid="linkedin-icon" sx={{ color: 'primary.main' }} />;
    }
    return <WorldIcon data-testid="world-icon" sx={{ color: 'primary.main' }} />;
}

SocialMediaIconType.propTypes = {
    socialMediaType: PropTypes.string,
};
