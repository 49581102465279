import Chip from '@mui/material/Chip';
import { styled, alpha } from '@mui/material/styles';

import { TYPOGRAPHY_VARIANTS } from 'components/Typography';

export const AttachmentChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.common.white,
    marginRight: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.25, 0.5),
    maxWidth: theme.spacing(19),
    height: theme.spacing(2.5),
    gap: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    textTransform: 'none',
    ...TYPOGRAPHY_VARIANTS.titleSmall,
    '& .MuiChip-deleteIcon': {
        marginRight: theme.spacing(1),
        fontSize: theme.spacing(1.75),
        color: alpha(theme.palette.common.white, 0.72),
        '&:hover': { color: theme.palette.error.main },
    },
}));
