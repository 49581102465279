import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { alpha, styled } from '@mui/material/styles';
import { useCallback, useRef, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import InputAdornment from '@mui/material/InputAdornment';

import { IncludedChip } from './IncludedChip';
import { ExcludedChip } from './ExcludedChip';
import { Tooltip } from 'components/Tooltip';
import { EMPLOYMENT_FILTER } from './constants';
import { Typography } from 'components/Typography';
import { AutocompleteSx, InputProps } from './props';
import { IncludeExcludeLabel } from './IncludeExcludeLabel';
import { occupationData } from './filterData/occupationData';
import { filteredOptions, updateRelationships } from './utils';
import { employmentMenuProps, IconContainer } from './FilterEmployment';
import { changeStandardExcludeFilter, changeStandardFilter } from 'views/Search/searchSlice';
import { OccupationsAndIndustriesAutocomplete } from './OccupationsAndIndustriesAutocomplete';
import {
    CheckCircleIcon,
    ChevronDownIcon,
    InfoIcon,
    PastOccupationsIcon,
    SuitcaseIcon,
} from 'components/Icons';
import {
    CustomWidthTooltip,
    FilterChipContainer,
    FilterDetails,
    FilterMenuItem,
    StyledPaper,
    StyledSelect,
} from './styledComponents';

const StyledPastOccupationsIcon = styled(PastOccupationsIcon)(({ theme }) => ({
    fontSize: '14px',
    marginLeft: theme.spacing(0.6),
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginBottom: theme.spacing(-0.5),
    marginLeft: theme.spacing(0.3),
    fontSize: '16px',
    cursor: 'pointer',
    color: theme.palette.common.white,
    mb: -0.5,
    ml: 0.3,
}));

const occupationsLabel = {
    [EMPLOYMENT_FILTER.OCCUPATION]: 'Filter by Past & Current Occupation',
    [EMPLOYMENT_FILTER.CURRENT_OCCUPATION]: 'Filter by Current Occupation',
};

const occupationsTooltip =
    'This filter searches on standardized occupation categories assigned to candidates by Nebula. For example, searching ‘Engineering’ will show all candidates in engineering-related roles, regardless of their specific job title.';

const staticValue = [];

export const getOccupationLabel = (value) => {
    const occupation = occupationData.find((item) => item.code === value);
    return occupation ? occupation.name : '';
};

export function FilterOccupations(props) {
    const {
        handleClickExclude,
        occupations,
        excludedOccupations,
        currentOccupations,
        excludedCurrentOccupations,
        handleDeleteEmployment,
        handleDeleteExcludedEmployment,
        handleClickInclude,
    } = props;

    const [occupationFilter, setOccupationFilter] = useState(EMPLOYMENT_FILTER.OCCUPATION);
    const occupationsMenu = useRef();
    const dispatch = useDispatch();

    const isFilteringOccupations = occupationFilter === EMPLOYMENT_FILTER.OCCUPATION;
    const isFilteringCurrentOccupations = occupationFilter === EMPLOYMENT_FILTER.CURRENT_OCCUPATION;

    const [expanded, setExpanded] = useState({});
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleOccupationChange = useCallback(
        (selectedValues) => {
            dispatch(
                changeStandardFilter({
                    property: occupationFilter,
                    matchText: selectedValues,
                })
            );
        },
        [dispatch, occupationFilter]
    );

    const handleOccupationExcludeChange = useCallback(
        (excludedValues) => {
            dispatch(
                changeStandardExcludeFilter({
                    property: occupationFilter,
                    matchText: excludedValues,
                })
            );
        },
        [dispatch, occupationFilter]
    );

    const toggleSelect = useCallback(
        (option, isChecked) => {
            let newSelectedCodes =
                occupationFilter === EMPLOYMENT_FILTER.OCCUPATION
                    ? [...occupations]
                    : [...currentOccupations];

            const index = newSelectedCodes.indexOf(option.code);
            updateRelationships(option, isChecked, newSelectedCodes, index, occupationData);
            handleOccupationChange(newSelectedCodes);
        },
        [currentOccupations, handleOccupationChange, occupationFilter, occupations]
    );

    const toggleExclude = useCallback(
        (option, isChecked) => {
            let newExcludedCodes =
                occupationFilter === EMPLOYMENT_FILTER.OCCUPATION
                    ? [...excludedOccupations]
                    : [...excludedCurrentOccupations];

            const index = newExcludedCodes.indexOf(option.code);
            updateRelationships(option, isChecked, newExcludedCodes, index, occupationData);
            handleOccupationExcludeChange(newExcludedCodes);
        },
        [
            excludedCurrentOccupations,
            excludedOccupations,
            handleOccupationExcludeChange,
            occupationFilter,
        ]
    );

    const toggleExpand = useCallback(
        (code) => {
            const newState = { ...expanded, [code]: !expanded[code] };
            const collapseChildren = (code) => {
                const children = occupationData.filter((item) => item.parentCode === code);
                if (children.length) {
                    children.forEach((child) => {
                        newState[child.code] = false;
                        if (child.hasChildren) {
                            collapseChildren(child.code);
                        }
                    });
                }
            };
            if (!newState[code]) {
                collapseChildren(code);
            }

            setExpanded(newState);
        },
        [expanded]
    );

    const filterOptions = useCallback(
        (options, params) => {
            const inputValue = params.inputValue.toLowerCase();
            const expandedStates = { ...expanded };

            if (!inputValue) return options;

            const newOptions = filteredOptions(options, inputValue, expandedStates, occupationData);

            // Update the expanded state if changed.
            if (JSON.stringify(expanded) !== JSON.stringify(expandedStates)) {
                setTimeout(() => setExpanded(expandedStates), 0);
            }
            return newOptions;
        },
        [expanded, setExpanded]
    );

    const handleOccupationFilter = useCallback((event) => {
        setOccupationFilter(event.target.value);
    }, []);

    return (
        <FilterDetails id="filter-occupation-content">
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <OccupationsAndIndustriesAutocomplete
                    filterData={occupationData}
                    filterOptions={filterOptions}
                    expanded={expanded}
                    toggleExpand={toggleExpand}
                    selected={
                        occupationFilter === EMPLOYMENT_FILTER.OCCUPATION
                            ? occupations
                            : currentOccupations
                    }
                    excluded={
                        occupationFilter === EMPLOYMENT_FILTER.OCCUPATION
                            ? excludedOccupations
                            : excludedCurrentOccupations
                    }
                    toggleSelect={toggleSelect}
                    toggleExclude={toggleExclude}
                    setExpanded={setExpanded}
                    key={occupationFilter}
                    fullWidth
                    disableClearable
                    multiple
                    ref={occupationsMenu}
                    openOnFocus
                    forcePopupIcon={false}
                    PaperComponent={StyledPaper}
                    onChange={handleOccupationChange}
                    value={staticValue}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    label={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <Box sx={{ display: 'flex' }}>
                                    {`${occupationsLabel[occupationFilter]} Category`}
                                    <CustomWidthTooltip
                                        title={
                                            <Typography variant="bodySmall">
                                                {occupationsTooltip}
                                            </Typography>
                                        }
                                        placement="top"
                                        arrow
                                        sx={{ ml: 0.5 }}
                                    >
                                        <Box>
                                            <StyledInfoIcon />
                                        </Box>
                                    </CustomWidthTooltip>
                                </Box>
                            </div>
                        </Box>
                    }
                    aria-label="occupations-filter-autocomplete"
                    noOptionsText={
                        <Typography variant="labelLarge" noWrap>
                            No Occupation Categories Found
                        </Typography>
                    }
                    sx={{ flex: 1 }}
                    TextInputProps={{
                        sx: AutocompleteSx,
                        InputProps: {
                            ...InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start" sx={{ mr: 0 }}>
                                        <StyledSelect
                                            inputProps={{
                                                id: 'filter-occupation-menu',
                                                'aria-label': 'occupation-filter-type-select',
                                            }}
                                            value={occupationFilter}
                                            onChange={handleOccupationFilter}
                                            IconComponent={ChevronDownIcon}
                                            MenuProps={employmentMenuProps(occupationsMenu, -0.5)}
                                            renderValue={(value) => {
                                                if (value === EMPLOYMENT_FILTER.OCCUPATION) {
                                                    return (
                                                        <PastOccupationsIcon
                                                            sx={{
                                                                color: alpha('#ffffff', 0.48),
                                                                fontSize: '14px',
                                                                padding: '5px 5px',
                                                                margin: 0,
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <SuitcaseIcon
                                                        value={value}
                                                        sx={{
                                                            color: alpha('#ffffff', 0.48),
                                                        }}
                                                    />
                                                );
                                            }}
                                        >
                                            <FilterMenuItem
                                                active={isFilteringOccupations}
                                                value={EMPLOYMENT_FILTER.OCCUPATION}
                                                aria-label={`${EMPLOYMENT_FILTER.OCCUPATION}-menu-item`}
                                            >
                                                <ListItemIcon sx={{ color: 'common.white' }}>
                                                    <StyledPastOccupationsIcon />
                                                </ListItemIcon>
                                                <Typography variant="titleSmall">
                                                    {`${
                                                        occupationsLabel[
                                                            EMPLOYMENT_FILTER.OCCUPATION
                                                        ]
                                                    }s`}
                                                </Typography>
                                                {isFilteringOccupations && (
                                                    <CheckCircleIcon
                                                        color="success"
                                                        sx={{ ml: 'auto' }}
                                                    />
                                                )}
                                            </FilterMenuItem>
                                            <FilterMenuItem
                                                active={isFilteringCurrentOccupations}
                                                value={EMPLOYMENT_FILTER.CURRENT_OCCUPATION}
                                                aria-label={`${EMPLOYMENT_FILTER.CURRENT_OCCUPATION}-menu-item`}
                                            >
                                                <ListItemIcon sx={{ color: 'common.white' }}>
                                                    <SuitcaseIcon />
                                                </ListItemIcon>
                                                <Typography variant="titleSmall">
                                                    {
                                                        occupationsLabel[
                                                            EMPLOYMENT_FILTER.CURRENT_OCCUPATION
                                                        ]
                                                    }
                                                </Typography>
                                                {isFilteringCurrentOccupations && (
                                                    <CheckCircleIcon
                                                        color="success"
                                                        sx={{ ml: 'auto' }}
                                                    />
                                                )}
                                            </FilterMenuItem>
                                        </StyledSelect>
                                    </InputAdornment>
                                </>
                            ),
                        },
                    }}
                />
            </Box>
            <>
                {Boolean(occupations.length) && (
                    <>
                        <IncludeExcludeLabel
                            label="Include"
                            filterKey={EMPLOYMENT_FILTER.OCCUPATION}
                            enableAnyAllSelect
                        />
                        <FilterChipContainer>
                            {occupations.map((occupationCode, index) => (
                                <IncludedChip
                                    key={`${EMPLOYMENT_FILTER.OCCUPATION}-included-chip-${occupationCode}-${index}`}
                                    index={index}
                                    label={
                                        <>
                                            <Tooltip title="Past & Current Occupations Category Filter">
                                                <IconContainer>
                                                    <StyledPastOccupationsIcon />
                                                </IconContainer>
                                            </Tooltip>
                                            {getOccupationLabel(occupationCode)}
                                        </>
                                    }
                                    onClick={handleClickExclude}
                                    onDelete={() =>
                                        handleDeleteEmployment(EMPLOYMENT_FILTER.OCCUPATION, index)
                                    }
                                    filterKey={EMPLOYMENT_FILTER.OCCUPATION}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
                {Boolean(currentOccupations.length) && (
                    <>
                        <IncludeExcludeLabel
                            label="Include"
                            filterKey={EMPLOYMENT_FILTER.CURRENT_OCCUPATION}
                            enableAnyAllSelect
                        />
                        <FilterChipContainer>
                            {currentOccupations.map((occupationCode, index) => (
                                <IncludedChip
                                    key={`${EMPLOYMENT_FILTER.CURRENT_OCCUPATION}-included-chip-${occupationCode}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Current Occupations Category Filter">
                                                <IconContainer>
                                                    <SuitcaseIcon />
                                                </IconContainer>
                                            </Tooltip>
                                            {getOccupationLabel(occupationCode)}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickExclude}
                                    onDelete={() =>
                                        handleDeleteEmployment(
                                            EMPLOYMENT_FILTER.CURRENT_OCCUPATION,
                                            index
                                        )
                                    }
                                    filterKey={EMPLOYMENT_FILTER.CURRENT_OCCUPATION}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
                {(Boolean(excludedOccupations.length) ||
                    Boolean(excludedCurrentOccupations.length)) && (
                    <>
                        <IncludeExcludeLabel label="Exclude" />
                        <FilterChipContainer>
                            {excludedOccupations.map((occupationCode, index) => (
                                <ExcludedChip
                                    key={`${EMPLOYMENT_FILTER.OCCUPATION}-excluded-chip-${occupationCode}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Past & Current Occupations Category Filter">
                                                <IconContainer>
                                                    <StyledPastOccupationsIcon />
                                                </IconContainer>
                                            </Tooltip>
                                            {getOccupationLabel(occupationCode)}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={() =>
                                        handleDeleteExcludedEmployment(
                                            EMPLOYMENT_FILTER.OCCUPATION,
                                            index
                                        )
                                    }
                                    filterKey={EMPLOYMENT_FILTER.OCCUPATION}
                                    isCustomLabel
                                />
                            ))}
                            {excludedCurrentOccupations.map((occupationCode, index) => (
                                <ExcludedChip
                                    key={`${EMPLOYMENT_FILTER.CURRENT_OCCUPATION}-excluded-chip-${occupationCode}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Current Occupations Category Filter">
                                                <IconContainer>
                                                    <SuitcaseIcon />
                                                </IconContainer>
                                            </Tooltip>
                                            {getOccupationLabel(occupationCode)}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={() =>
                                        handleDeleteExcludedEmployment(
                                            EMPLOYMENT_FILTER.CURRENT_OCCUPATION,
                                            index
                                        )
                                    }
                                    filterKey={EMPLOYMENT_FILTER.CURRENT_OCCUPATION}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
            </>
        </FilterDetails>
    );
}

FilterOccupations.propTypes = {
    handleClickExclude: PropTypes.func,
    handleClickInclude: PropTypes.func,
    occupations: PropTypes.array,
    excludedOccupations: PropTypes.array,
    currentOccupations: PropTypes.array,
    excludedCurrentOccupations: PropTypes.array,
    handleDeleteEmployment: PropTypes.func,
    handleDeleteExcludedEmployment: PropTypes.func,
};
