const BASE_PROJECTS_URL = '/api/projects/v1/projects';

const buildGetRequest = (url, params) => {
    if (params && Object.keys(params).length > 0) {
        return url + '?' + new URLSearchParams(params).toString();
    } else {
        return url;
    }
};

const getFetch = async (url, headers) => {
    const response = await fetch(url, {
        method: 'GET',
        headers,
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

const mutateFetch = async (method, url, headers, body) => {
    const response = await fetch(url, {
        method,
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        const errorBody = await response.json();
        throw new Error(errorBody.message || response.status);
    }
    return response.status === 204 ? null : response.json();
};

/**
 *
 * GET /api/projects/v1/projects
 * Returns a list of projects
 */
export const getProjectsApi = async ({ queryKey, headers }) => {
    const [, params] = queryKey;
    const url = buildGetRequest(BASE_PROJECTS_URL, params);
    return await getFetch(url, headers);
};

/**
 *
 * GET /api/projects/v1/projects/:projectId
 * Returns a project
 */

export const getProjectApi = async ({ queryKey, headers }) => {
    const [, { projectId }] = queryKey;
    const url = `${BASE_PROJECTS_URL}/${projectId}`;
    return await getFetch(url, headers);
};

/**
 *
 * PATCH /api/projects/v1/projects/:projectId
 * Updates a project
 */
export const updateProjectApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}`;
    return await mutateFetch('PUT', url, headers, data);
};

/**
 *
 * POST /api/projects/v1/projects
 * Creates a new project
 */
export const createProjectApi = async ({ data, headers }) => {
    return await mutateFetch('POST', BASE_PROJECTS_URL, headers, data);
};

/**
 *
 * GET /api/projects/v1/projects/image
 * Returns a signed url to resource
 */

export const signResourceUrl = async ({ queryKey, headers }) => {
    const [, params] = queryKey;
    const url = buildGetRequest(`${BASE_PROJECTS_URL}/image`, params);
    return await getFetch(url, headers);
};

/**
 *
 * GET /api/talent-mgmt/v1/lists
 * Returns a list of shortlists
 */

export const getShortlistsApi = async ({ queryKey, headers }) => {
    const [, params] = queryKey;
    const url = buildGetRequest('/api/talent-mgmt/v1/lists/', params);
    return await getFetch(url, headers);
};

/**
 *
 * GET /api/search/v1/saved-searches
 * Returns a list of saved searches
 */

export const getSavedSearchesApi = async ({ queryKey, headers }) => {
    return await getFetch('/api/search/v1/saved-searches', headers);
};

/**
 *
 * GET /api/projects/v1/projects/:projectId/talents
 * Returns a list of talents for a project
 */

export const getProjectTalentsApi = async ({ queryKey, headers }) => {
    const [, { projectId }] = queryKey;
    const url = `${BASE_PROJECTS_URL}/${projectId}/talents`;
    return await getFetch(url, headers);
};

/**
 *
 * POST /api/projects/v1/projects/:projectId/talents
 * Adds talents to a project
 */

export const addTalentsToProjectApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/talents`;
    const response = await mutateFetch('POST', url, headers, data);
    return response;
};

/**
 *
 * GET /api/projects/v1/projects?view=context_menu&status=active
 * Get all current user's projects and their talents
 */
export const getMyProjectsWithTalents = async ({ headers }) => {
    const url = `${BASE_PROJECTS_URL}?view=context_menu&status=active`;
    const response = await getFetch(url, headers);
    return response;
};

/**
 *
 * PUT /api/projects/v1/projects/:projectId/talents/:talentId/status
 * Update a talent's status
 */
export const updateTalentStatusApi = async ({ projectId, talentId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/talents/${talentId}/status`;
    const response = await mutateFetch('PUT', url, headers, data);
    return response;
};

/**
 *
 * POST /api/projects/v1/projects/:projectId/talents/:talentId/interviews
 * Add a talent to an interview
 */
export const moveToInterviewApi = async ({ projectId, talentId, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/talents/${talentId}/interviews`;
    const response = await mutateFetch('POST', url, headers);
    return response;
};

/**
 *
 * GET /api/projects/v1/projects/{{project_id}}/interviews
 * Get project's interview stages
 */
export const getProjectInterviewStagesApi = async ({ queryKey, headers }) => {
    const [, { projectId }] = queryKey;
    const url = `${BASE_PROJECTS_URL}/${projectId}/interviews`;
    const response = await getFetch(url, headers);
    return response;
};

/**
 *
 * POST  /api/projects/v1/projects/{{project_id}}/interviews
 * Add new interview stage
 */
export const createInterviewStageApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/interviews`;
    const response = await mutateFetch('POST', url, headers, data);
    return response;
};

/**
 *
 * POST /api/projects/v1/projects/{{project_id}}/talents/{{talent_id}}/outreach
 * Reach out to a talent
 */
export const projectTalentOutreachApi = async ({ projectId, talentId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/talents/${talentId}/outreach`;
    const response = await mutateFetch('POST', url, headers, data);
    return response;
};

/*
 * DELETE  /api/projects/v1/projects/:projectId/interviews/:interviewId
 * Deletes an interview stage
 */
export const deleteInterviewStageApi = async ({ projectId, interviewId, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/interviews/${interviewId}`;
    const response = await mutateFetch('DELETE', url, headers);
    return response;
};

/**
 *
 * PUT  /api/projects/v1/projects/:projectId/interviews/:interviewId
 * updates an interview stage name
 */
export const updateInterviewStageNameApi = async ({ projectId, interviewId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/interviews/${interviewId}`;
    const response = await mutateFetch('PUT', url, headers, data);
    return response;
};

/**
 *
 * PUT  /api/projects/v1/projects/:projectId/interviews
 * re-arranges the order of interview stages
 */
export const reorderInterviewStagesApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/interviews`;
    const response = await mutateFetch('PUT', url, headers, data);
    return response;
};

/**
 *
 * PUT /api/projects/v1/projects/:projectId/talents/:talentId/interviews
 * re-arranges the order of interview stages
 */
export const moveTalentWithinInterviewStageApi = async ({ projectId, talentId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/talents/${talentId}/interviews`;
    const response = await mutateFetch('PUT', url, headers, data);
    return response;
};

/**
 *
 * POST /api/aggregator/v1/talent/contact-metadata
 * Get contact metadata for a list of talents
 */
export const getTalentsContactMetadataApi = async ({ data, headers }) => {
    const url = '/api/aggregator/v1/talent/contact-metadata';
    const response = await mutateFetch('POST', url, headers, data);
    return response;
};

/**
 *
 * GET /api/job-description/v1/jd
 * Get job descriptions
 */
export const getJobDescriptions = async ({ headers }) => {
    const url = '/api/job-description/v1/jd';
    const response = await getFetch(url, headers);
    return response;
};

/**
 *
 * POST /api/user-mgmt/v1/users/keycloak/simple-view
 * Get the details of keycloak users
 */
export const getKeycloakUsersDetailsApi = async ({ data, headers }) => {
    const url = '/api/user-mgmt/v1/users/keycloak/simple-view';
    const response = await mutateFetch('POST', url, headers, data);
    return response;
};

/**
 *
 * PUT /api/projects/v1/projects/:projectId/bulk-talent-status
 * Bulk update of talents statuses
 */
export const bulkUpdateTalentStatusApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/bulk-talent-status`;
    const response = await mutateFetch('PUT', url, headers, data);
    return response;
};

/**
 *
 * PUT /api/projects/v1/projects/:projectId/interview-statuses
 * Update project interview statuses
 */

export const updateProjectInterviewStatusesApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/interview-statuses`;
    const response = await mutateFetch('PUT', url, headers, data);
    return response;
};

/**
 *
 * POST /api/projects/v1/projects/:projectId/talents/:talentId/interview-status
 * Set interview status for a talent
 */

export const setTalentInterviewStatusApi = async ({ projectId, talentId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/talents/${talentId}/interview-status`;
    const response = await mutateFetch('POST', url, headers, data);
    return response;
};

/**
 * GET /api/projects/v1/projects/:projectId/roles-definitions
 * Gets the users who are collborating in a project
 */

export const getProjectCollaboratorsApi = async ({ queryKey, headers }) => {
    const [, { projectId, ...params }] = queryKey;
    const url = buildGetRequest(
        `${BASE_PROJECTS_URL}/${projectId}/roles-definitions?pageSize=1000`,
        params
    );
    const response = await getFetch(url, headers);
    return response;
};

/**
 *
 * GET /api/user-mgmt/v1/users/:orgId/roles
 * Gets the users in an organization
 */

export const getUsersByRoleApi = async ({ queryKey, headers }) => {
    const [, { orgId, ...params }] = queryKey;
    const url = buildGetRequest(`/api/user-mgmt/v1/users/roles/${orgId}`, params);
    const response = await getFetch(url, headers);
    return response;
};

/* POST /api/projects/v1/projects/:projectId/bulk/actions
 * Get bulk actions for a project
 */

export const getProjectBulkActionsApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/bulk/actions`;
    const response = await mutateFetch('POST', url, headers, data);
    return response;
};

/**
 *
 * GET /api/projects/v1/projects/owners
 * Gets the owners of projects the user has access to
 */

export const getAccessibleProjectOwners = async ({ headers }) => {
    const response = await getFetch(`${BASE_PROJECTS_URL}/owners`, headers);
    return response;
};

/**
 * PUT /api/projects/v1/projects/:projectId/definitions
 * Add org users to the collaborators list
 */

export const updateProjectCollaboratorsApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/roles-definitions`;
    const response = await mutateFetch('PUT', url, headers, data);
    return response;
};

/**
 * DELETE /api/projects/v1/projects/:projectId/roles-definitions
 * Remove org users from the collaborators list
 */
export const removeProjectCollaboratorsApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/roles-definitions`;
    const response = await mutateFetch('DELETE', url, headers, data);
    return response;
};

/**
 *
 * GET /api/projects/v1/metrics/:projectId
 * Get project metrics
 */
export const getProjectMetricsApi = async ({ queryKey, headers }) => {
    const [, { projectId }] = queryKey;
    const url = `/api/projects/v1/metrics/${projectId}`;
    const response = await getFetch(url, headers);
    return response;
};


/**
 *
 * POST /api/projects/v1/projects/:projectId/talents/interviews/bulk
 * Bulk move talents to interviews
 */
export const bulkMoveTalentsToInterviewsApi = async ({ projectId, data, headers }) => {
    const url = `${BASE_PROJECTS_URL}/${projectId}/talents/interviews/bulk`;
    const response = await mutateFetch('POST', url, headers, data);
    return response;
};