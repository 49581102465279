import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Switch } from 'components/Switch';
import { InfoIcon } from 'components/Icons';
import { Tooltip } from 'components/Tooltip';
import { toggleByeBias } from './byeBiasSlice';
import { Typography } from 'components/Typography';

const defaultTooltip =
    'Our algorithms always ignore demographic information. ByeBias™ hides names and photos to prevent you from being influenced by unconscious biases.';

export function ByeBias(props) {
    const { valueLabel, showTooltip, tooltipTitle } = props;

    const dispatch = useDispatch();

    const isOn = useSelector((state) => state.byeBias.on);

    const tooltip = tooltipTitle || defaultTooltip;

    const handleChange = () => {
        dispatch(toggleByeBias());
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Switch
                        checked={isOn}
                        onChange={handleChange}
                        inputProps={{
                            'data-trackid': isOn ? 'byebias-off' : 'byebias-on',
                        }}
                    />
                }
                disableTypography
                sx={{ mr: 0 }}
                label={
                    <>
                        <Typography variant="titleSmall" color="textPrimary" sx={{ mb: 0.25 }}>
                            ByeBias&trade; {valueLabel && `is ${isOn ? 'on' : 'off'}`}
                        </Typography>
                        {showTooltip && (
                            <Tooltip placement="top" title={tooltip}>
                                <Box sx={{ display: 'flex' }}>
                                    <InfoIcon sx={{ color: 'text.primary', opacity: 0.48 }} />
                                </Box>
                            </Tooltip>
                        )}
                    </>
                }
            />
        </FormGroup>
    );
}

ByeBias.propTypes = {
    valueLabel: PropTypes.bool,
    showTooltip: PropTypes.bool,
    tooltipTitle: PropTypes.string,
};
