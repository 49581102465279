import { useCallback, useRef, useState } from 'react';
import produce from 'immer';

export const DEFAULT_STATE = {
    error: null,
    isLoading: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
};

export function useAsyncPoll(func, isSuccessful = (res) => true, maxInvocations = 10) {
    const counter = useRef(0);
    const data = useRef(null); //https://redux-toolkit.js.org/rtk-query/usage/queries#frequently-used-query-hook-return-values (isLoading and cached data)
    const [invocations, setInvocations] = useState(0);
    const [result, setResult] = useState(DEFAULT_STATE);

    const clear = useCallback(() => {
        counter.current = 0;
        setInvocations(counter.current);
    }, []);

    const handleResolve = useCallback(
        (res) => {
            if (isSuccessful(res)) {
                clear();
                data.current = res.data;
                setResult(
                    produce({ ...DEFAULT_STATE, data: data.current }, (draft) => {
                        draft.isSuccess = true;
                    })
                );
            } else if (counter.current === maxInvocations) {
                clear();
                setResult(
                    produce({ ...DEFAULT_STATE, data: data.current }, (draft) => {
                        draft.isError = true;
                        draft.error = { message: 'Maximum amount of invocations reached' };
                    })
                );
            } else {
                counter.current = counter.current + 1; //important as i=0; -> i++; behaves oddly
                setInvocations(counter.current);
            }
            return res;
        },
        [clear, isSuccessful, maxInvocations]
    );

    const handleReject = useCallback(
        (res) => {
            clear();
            setResult(
                produce({ ...DEFAULT_STATE, data: data.current }, (draft) => {
                    draft.isError = true;
                    draft.error = res.error;
                })
            );
        },
        [clear]
    );

    const asyncCallback = useCallback(
        (args, options) => {
            return new Promise((onFulfilled, onRejected) => {
                func(args, options).then(onFulfilled, onRejected);
            });
        },
        [func]
    );

    const callback = useCallback(
        (args, options) => {
            setResult(
                produce({ ...DEFAULT_STATE, data: data.current }, (draft) => {
                    draft.isFetching = true;
                    draft.isLoading = !Boolean(data.current);
                })
            );
            return asyncCallback(args, options).then(handleResolve, handleReject);
        },
        [asyncCallback, handleResolve, handleReject]
    );

    return [callback, result, invocations, clear];
}
