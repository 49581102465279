import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import { Tooltip } from 'components/Tooltip';

const tooltipCopy =
    'Complete building your Job Description and run your first Search to unlock these features!';

const ChildWrapper = styled('div')(() => ({
    cursor: 'not-allowed',
    '& > *': {
        pointerEvents: 'none',
    },
}));

export function ForcedFlowLock(props) {
    const { children } = props;

    const isForcedFlow = useSelector((state) => state.user?.settings?.forcedFlow);

    if (!isForcedFlow) {
        return children;
    }

    return (
        <Tooltip title={tooltipCopy}>
            <ChildWrapper>
                {Array.isArray(children)
                    ? children.map((child) => cloneElement(child, { disabled: true }))
                    : cloneElement(children, { disabled: true })}
            </ChildWrapper>
        </Tooltip>
    );
}

ForcedFlowLock.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
