import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { SHORTLIST_ACTIVITY_SUBTYPE } from 'utils';

export function ShortlistContent({ activitySubType }) {
    const label = useMemo(() => {
        switch (activitySubType) {
            case SHORTLIST_ACTIVITY_SUBTYPE.ADDED:
                return 'Added to Shortlist';
            default:
                return 'Removed from Shortlist';
        }
    }, [activitySubType]);

    return <>{label}</>;
}

ShortlistContent.propTypes = {
    activitySubType: PropTypes.string,
};
