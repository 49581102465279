import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ButtonBase from '@mui/material/ButtonBase';
import { Interweave, Matcher } from 'interweave';
import { styled, alpha, CircularProgress } from '@mui/material';

import { Tooltip } from 'components/Tooltip';
import { MESSAGE_TYPES } from 'utils/constants';
import { Typography } from 'components/Typography';
import { ChannelToggleButton } from './ChannelToggleButton';
import { useDynamicFields } from 'features/DynamicFields';

const Wrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1.7),
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const TemplateActionButtons = styled(Box)(() => ({
    display: 'flex',
}));

const HighlightedSpan = styled('mark')(({ theme }) => ({
    background: alpha(theme.palette.primary.main, 0.12),
    color: theme.palette.primary.main,
}));

class DynamicFieldMatcher extends Matcher {
    match(string) {
        const result = string.match(/\{\{[^}]*\}\}/);

        if (!result) {
            return null;
        }

        return {
            index: result.index,
            length: result[0].length,
            match: result[0],
            extraProp: 'dynamicField',
            valid: true,
        };
    }

    asTag() {
        return 'mark';
    }
}

export const TemplateListItem = memo((props) => {
    const {
        template,
        onClose,
        onTemplateCreateView,
        onUseTemplate,
        onViewTemplate,
        onDeleteTemplate,
        disableUseTemplate,
        ...rest
    } = props;

    const { dynamicFieldsData } = useDynamicFields();

    const [isDeletingTemplate, setIsDeletingTemplate] = useState(false);

    const handleViewTemplate = () => {
        onTemplateCreateView();
        onViewTemplate(template);
    };

    const handleUseTemplate = () => {
        onClose();
        onUseTemplate(template);
    };

    const handleDeleteTemplate = async () => {
        setIsDeletingTemplate(true);
        await onDeleteTemplate(template.templateId);
        setIsDeletingTemplate(false);
    };

    return (
        <Wrapper {...rest}>
            <Typography
                variant="labelSmall"
                sx={{
                    color: 'common.charcoal',
                }}
            >
                {template?.name}
            </Typography>
            <Box
                sx={{
                    maxHeight: 68,
                    overflow: 'hidden',
                    // Text ellipses implementation for multi line previews
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                }}
            >
                <Interweave
                    content={template?.body}
                    matchers={[
                        new DynamicFieldMatcher('dynamicField', {}, (match) => {
                            return (
                                <Tooltip
                                    title={dynamicFieldsData?.[match.children]?.description || ''}
                                    placement="top"
                                >
                                    <HighlightedSpan>{match.children}</HighlightedSpan>
                                </Tooltip>
                            );
                        }),
                    ]}
                />
            </Box>
            <StyledBox>
                <Box>
                    <ChannelToggleButton
                        type={MESSAGE_TYPES.EMAIL}
                        selected={template?.messageTypes?.includes(MESSAGE_TYPES.EMAIL)}
                        data-trackid="templates-list-email-channel"
                    />
                    <ChannelToggleButton
                        type={MESSAGE_TYPES.SMS}
                        selected={template?.messageTypes?.includes(MESSAGE_TYPES.SMS)}
                        data-trackid="templates-list-sms-channel"
                    />
                </Box>
                <TemplateActionButtons>
                    <ButtonBase
                        aria-label="delete-template"
                        data-trackid="templates-list-delete"
                        onClick={handleDeleteTemplate}
                        disableRipple
                    >
                        <Typography
                            variant="titleSmall"
                            color="error"
                            sx={{
                                fontWeight: 500,
                                marginRight: 2,
                            }}
                        >
                            {isDeletingTemplate ? <CircularProgress size={18} /> : 'Delete'}
                        </Typography>
                    </ButtonBase>
                    <ButtonBase
                        aria-label="view-edit-template"
                        disableRipple
                        onClick={handleViewTemplate}
                        data-trackid="templates-list-view-edit-template"
                    >
                        <Typography
                            variant="titleSmall"
                            color="primary"
                            sx={{
                                fontWeight: 500,
                                marginRight: 2,
                            }}
                        >
                            View/Edit
                        </Typography>
                    </ButtonBase>
                    {!disableUseTemplate && (
                        <ButtonBase
                            aria-label="use-template"
                            disableRipple
                            onClick={handleUseTemplate}
                        >
                            <Typography
                                variant="titleSmall"
                                color="primary"
                                sx={{
                                    fontWeight: 500,
                                }}
                            >
                                Use Template
                            </Typography>
                        </ButtonBase>
                    )}
                </TemplateActionButtons>
            </StyledBox>
            <Divider />
        </Wrapper>
    );
});

TemplateListItem.propTypes = {
    template: PropTypes.object,
    onClose: PropTypes.func,
    onUseTemplate: PropTypes.func,
    onViewTemplate: PropTypes.func,
    onTemplateCreateView: PropTypes.func,
    onDeleteTemplate: PropTypes.func,
    disableUseTemplate: PropTypes.bool,
};
