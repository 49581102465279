import SvgIcon from '@mui/material/SvgIcon';

export function ProjectIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M16.6633 4.49042H12.008C11.2787 4.49042 10.6094 4.19071 10.2597 3.7012C9.49051 2.63227 8.22178 1.99292 6.87312 1.99292H4.12587C1.84815 1.99292 0 3.78111 0 5.96892V14.7801C0 16.5583 1.4985 18.0069 3.33666 18.0069H16.983C18.6014 18.0069 19.95 16.7282 19.98 15.1597L20 7.72717C20 5.94895 18.5015 4.50039 16.6633 4.50039V4.49042ZM18.002 14.0708L17.982 15.1098C17.982 15.5993 17.5225 15.9989 16.983 15.9989H3.33666C2.5974 15.9989 1.998 15.4494 1.998 14.7701V5.95895C1.998 4.87004 2.95704 3.98092 4.12587 3.98092H6.87312C7.58242 3.98092 8.24176 4.3106 8.63137 4.86005C9.36064 5.87903 10.6194 6.47842 11.998 6.47842H16.6533C17.3926 6.47842 17.992 7.02789 17.992 7.70721V14.0608L18.002 14.0708Z"
                fill="currentColor"
            />
            <path
                d="M11.4688 8.82585L9.26101 11.2035L8.49179 10.3843C8.11216 9.98469 7.48279 9.9647 7.08319 10.3443C6.68359 10.7239 6.66361 11.3533 7.04323 11.7529L8.32195 13.1016C8.5717 13.3613 8.91136 13.5112 9.271 13.5112C9.63064 13.5112 9.9803 13.3513 10.2201 13.0916L12.9174 10.1845C13.297 9.77489 13.267 9.14551 12.8674 8.77588C12.4678 8.39626 11.8284 8.42625 11.4588 8.82585H11.4688Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
