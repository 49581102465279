import { create } from 'zustand';

export const useTalentProfileStore = create((set) => ({
    isOpen: false,
    talentId: null,
    config: null,
    messageTalentIds: [],
    currentTalentIdIndex: 0,
    initialProfileId: null,
    selectedProfile: null,
    messageTalentProfile: null,
    profiles: [],
    setInitialProfileId: (initialProfileId) => set({ initialProfileId }),
    setTalentProfiles: (profiles, preSelectedProfile) =>
        set((state) => {
            return {
                profiles,
                selectedProfile: preSelectedProfile || profiles[0] || state.selectedProfile,
            };
        }),
    setSelectedTalentProfile: (selectedProfile) => set({ selectedProfile }),
    setOpen: (talentId, config) => set({ isOpen: true, talentId, config }),
    setClosed: () => set({ isOpen: false }),
    setShowTalentProfile: (isOpen) => set({ isOpen }),
    setMessageTalentIds: (talentIds, startTalentId, config) =>
        set((state) => ({
            messageTalentIds: talentIds,
            currentTalentIdIndex: startTalentId ? talentIds.indexOf(startTalentId) || 0 : 0,
            talentId: startTalentId || talentIds[0],
            config,
        })),

    setNextTalentId: () =>
        set((state) => {
            const { messageTalentIds, currentTalentIdIndex } = state;
            if (!messageTalentIds.length) return state;
            const newIndex = currentTalentIdIndex + 1;
            const nextTalentId = messageTalentIds[newIndex];
            return {
                initialProfileId: null,
                selectedProfile: null,
                talentId: nextTalentId,
                currentTalentIdIndex: newIndex,
                config: Boolean(state.config)
                    ? {
                          ...state.config,
                          notesSourceConfig: {
                              ...state.config.notesSourceConfig,
                              sourceId: nextTalentId,
                          },
                      }
                    : state.config,
            };
        }),

    setPreviousTalentId: () =>
        set((state) => {
            const { messageTalentIds, currentTalentIdIndex } = state;
            if (!messageTalentIds.length) return state;
            const newIndex = currentTalentIdIndex - 1;
            const previousTalentId = messageTalentIds[newIndex];
            return {
                initialProfileId: null,
                selectedProfile: null,
                talentId: previousTalentId,
                currentTalentIdIndex: newIndex,
                config: Boolean(state.config)
                    ? {
                          ...state.config,
                          notesSourceConfig: {
                              ...state.config.notesSourceConfig,
                              sourceId: previousTalentId,
                          },
                      }
                    : state.config,
            };
        }),

    clearMessageTalents: () =>
        set({
            messageTalentIds: [],
            currentTalentIdIndex: 0,
            config: null,
            initialProfileId: null,
        }),
}));
