import { useState, useEffect } from 'react';

export function useDialog(props) {
    const { onConfirm, autoOpenDialog = false } = props || {};
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        setIsOpen(false);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        autoOpenDialog && handleOpen();
    }, [autoOpenDialog]);

    return {
        isOpen,
        handleConfirm,
        handleOpen,
        handleClose,
    };
}
