import Box from '@mui/material/Box';
import { Outlet, Navigate } from 'react-router-dom';

import { useSwitchboard } from 'features/Switchboard';

const outletContainerStyles = {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
};

export function JDBuilder() {
    const isEnabled = useSwitchboard('FEATURE_NN-1764_JD_BUILDER_UI');

    if (!isEnabled) {
        return <Navigate to="not-found" />;
    }

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box sx={outletContainerStyles}>
                <Outlet />
            </Box>
        </Box>
    );
}
