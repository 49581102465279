import SvgIcon from '@mui/material/SvgIcon';

export function TrashIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M27.8108 4.81592H22.8988C22.8028 2.12792 20.6108 0 17.9228 0H15.1068C12.4188 0 10.2428 2.144 10.1308 4.8H5.21884C4.37084 4.864 3.71484 5.552 3.71484 6.4C3.71484 7.248 4.43484 8 5.31484 8H5.39484L6.37084 25.9199C6.37084 29.2799 9.09084 32 12.4508 32H20.5788C23.9228 32 26.6588 29.264 26.6588 26L27.6348 8H27.7148C28.5948 8 29.3148 7.28 29.3148 6.4C29.3148 5.52 28.6428 4.86392 27.8108 4.81592ZM15.1228 3.2H17.9388C18.8668 3.2 19.5868 3.904 19.6988 4.8H13.3788C13.4748 3.904 14.2108 3.2 15.1388 3.2H15.1228ZM23.4748 25.9199C23.4748 27.5039 22.1789 28.8 20.5949 28.8H12.4668C10.8828 28.8 9.58684 27.5039 9.58684 25.8399L8.62684 8H24.4508L23.4908 25.9199H23.4748Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
