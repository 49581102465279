import SvgIcon from '@mui/material/SvgIcon';

export function MediaGeneralIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.40795 31.984H22.592V32C26.016 32 28.7999 29.408 28.7999 26.224V11.808C28.7999 10.256 28.128 8.752 26.928 7.664L20.288 1.648C19.12 0.576004 17.584 0 15.952 0H9.40795C5.98395 0 3.19995 2.592 3.19995 5.776V26.208C3.19995 29.392 5.98395 31.984 9.40795 31.984ZM6.39995 5.76C6.39995 4.336 7.74395 3.184 9.40795 3.184L9.39195 3.168H15.8187V8.53296C15.8187 10.9929 17.6184 13.2377 20.1298 13.2377H25.5999V26.192C25.5999 27.616 24.256 28.768 22.592 28.768H9.40795C7.74395 28.768 6.39995 27.616 6.39995 26.192V5.76ZM25.3952 10.8377C25.2551 10.5297 25.0447 10.2442 24.768 10L18.2187 4.06622V8.53296C18.2187 9.94422 19.2047 10.8377 20.1298 10.8377H25.3952Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
