export function orderContactsByCategory(source) {
    const ordered = [...source];

    ordered.sort((a, b) => {
        const categoryA = source.indexOf(a);
        const categoryB = source.indexOf(b);

        // Move "uncategorized" to the end
        if (a === 'uncategorized') return 1;
        if (b === 'uncategorized') return -1;

        return categoryA - categoryB;
    });

    return ordered;
}
