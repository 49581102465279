import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState, useCallback, useRef } from 'react';
import { Routes } from 'Routes';
import { Navbar } from 'features/Navbar';
import { SystemDown } from 'views/SystemDown';
import { KEYCLOAK_ROLES } from 'utils/constants';
import { setUser } from 'features/Authentication';
import { UserInfo } from 'views/UserInfo/UserInfo';
import { useKeycloak } from 'features/Authentication';
import { useSwitchboard } from 'features/Switchboard';
import { ViewContainer } from 'components/ViewContainer';
import { ToastContainer } from 'components/ToastContainer';
import { ZeroCreditPrompt } from 'features/ZeroCreditPrompt';
import { GoogleAnalyticsTracker } from 'features/GoogleAnalytics';
import { EnvironmentIndicator } from 'features/EnvironmentIndicator';
import { OfflineNotification } from 'components/OfflineNotification';
import { useGetSignedInUserQuery } from 'services/users';
import { BlacklistedDomainErrorPage } from 'views/UserInfo/components/BlacklistedDomainErrorPage';
import { UserRequiresApprovalPage } from 'views/UserInfo/components/UserRequiresApprovalPage';
import { UpgradeAccountPrompt } from 'components/Dialog/UpgradeAccountPrompt/UpgradeAccountPrompt';
import { WelcomeBackPrompt } from 'features/WelcomeBackPrompt';
import { useRedirectError } from 'hooks/useRedirectError';
import { EmailRefresher } from 'views/EmailNotConnected/EmailRefresher';
import { ClippyModal } from 'features/ClippyModal';
import { MessageDrawer } from 'features/MessageDrawer';
import { ContactDetailsModal } from 'features/TalentCard/ContactDetails';
import { ImpersonateIndicator } from 'views/SalesAdminDashboard/Users/features/ImpersonateUser';

function App() {
    const { isLoading: isSwitchboardLoading } = useSwitchboard();
    const [isKeycloakLoading, setIsKeycloakLoading] = useState(true);
    const [isKeycloakError, setIsKeycloakError] = useState(false);
    const [isProfileComplete, setProfileComplete] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isChangingBlacklistedEmail, setIsChangingBlacklistedEmail] = useState(false);
    const keycloakUserData = useRef(null);

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();

    const {
        data: userData,
        isFetching: isUserDataLoading,
        isError: isErrorLoadingUser,
        error: userError,
    } = useGetSignedInUserQuery(null, { skip: !keycloak.authenticated });

    const handleEmailValidationChange = (value) => {
        setIsEmailValid(value);
    };

    const loadUserProfile = useCallback(async () => {
        if (keycloak.authenticated && userData) {
            try {
                if (!keycloakUserData.current) {
                    keycloakUserData.current = await keycloak.loadUserProfile();
                }
                const userProfile = keycloakUserData.current;
                dispatch(
                    setUser({
                        isSalesManager: keycloak.hasRealmRole(KEYCLOAK_ROLES.SALES_MANAGER),
                        isOrganizationAdmin: keycloak.hasRealmRole(
                            KEYCLOAK_ROLES.ORGANIZATION_ADMIN
                        ),
                        isNovaToolsAdmin: keycloak.hasRealmRole(KEYCLOAK_ROLES.NOVA_TOOLS_ADMIN),
                        isNovaQuicklaunchDev: keycloak.hasRealmRole(
                            KEYCLOAK_ROLES.NOVA_QUICKLAUNCH_DEV
                        ),
                        isNovaQuicklaunchAdmin: keycloak.hasRealmRole(
                            KEYCLOAK_ROLES.NOVA_QUICKLAUNCH_ADMIN
                        ),
                        isImpersonator: keycloak.tokenParsed?.resource_access?.[
                            'realm-management'
                        ]?.roles?.includes(KEYCLOAK_ROLES.IMPERSONATORS),
                        displayName: `${userProfile.firstName} ${userProfile.lastName}`,
                        ...userProfile,
                        ...userData,
                    })
                );
                setIsKeycloakLoading(false);
            } catch {
                setIsKeycloakError(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, keycloak, keycloak.authenticated, dispatch]);

    const checkUserProfileComplete = useCallback(async () => {
        if (keycloak.authenticated) {
            try {
                if (!keycloakUserData.current) {
                    keycloakUserData.current = await keycloak.loadUserProfile();
                }
                const userProfile = keycloakUserData.current;
                if (userData) {
                    if (userData.signupComplete && userProfile.id) {
                        setProfileComplete(true);
                    } else {
                        setProfileComplete(false);
                    }
                } else if (!isUserDataLoading) {
                    setProfileComplete(false);
                }
            } catch {
                setIsKeycloakError(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, keycloak, keycloak.authenticated, isUserDataLoading]);

    const isApplicationLoading =
        isSwitchboardLoading ||
        ((isProfileComplete === null || isKeycloakLoading) && isUserDataLoading);

    useRedirectError(isApplicationLoading);

    useEffect(() => {
        checkUserProfileComplete();
    }, [checkUserProfileComplete]);

    useEffect(() => {
        loadUserProfile();
    }, [loadUserProfile]);

    useEffect(() => {
        if (keycloak && initialized) {
            // If the token expires, update it automatically
            keycloak.onTokenExpired = () => keycloak.updateToken();
        }
        return () => {
            if (keycloak) {
                keycloak.onTokenExpired = () => {};
            }
        };
    }, [initialized, keycloak]);

    if (isKeycloakError || (isErrorLoadingUser && userError?.status !== 404)) {
        return <SystemDown />;
    }

    if (isApplicationLoading) {
        return (
            <ViewContainer sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={120} />
            </ViewContainer>
        );
    }

    if (
        isChangingBlacklistedEmail ||
        (!isProfileComplete && keycloak.authenticated && !userData?.signupComplete)
    ) {
        return (
            <ViewContainer>
                <UserInfo
                    userData={userData}
                    setProfileComplete={setProfileComplete}
                    onEmailValidationChange={handleEmailValidationChange}
                    isChangingBlacklistedEmail={isChangingBlacklistedEmail}
                />
            </ViewContainer>
        );
    }

    if (!isEmailValid && isProfileComplete) {
        return (
            <ViewContainer>
                <BlacklistedDomainErrorPage
                    setIsChangingBlacklistedEmail={setIsChangingBlacklistedEmail}
                    userData={userData}
                />
            </ViewContainer>
        );
    }

    if (isEmailValid && isProfileComplete && userData?.isDeleted && keycloak.authenticated) {
        return (
            <ViewContainer>
                <UserRequiresApprovalPage />
            </ViewContainer>
        );
    }

    return (
        <ViewContainer>
            <ClippyModal user={user} />
            <EnvironmentIndicator />
            <ImpersonateIndicator />
            <GoogleAnalyticsTracker />
            <OfflineNotification />
            <EmailRefresher />
            <Navbar user={user} />

            <ViewContainer inner>
                <Routes user={user} />
            </ViewContainer>

            <ToastContainer />
            <ZeroCreditPrompt />
            <UpgradeAccountPrompt />
            <WelcomeBackPrompt />
            <MessageDrawer />
            <ContactDetailsModal />
        </ViewContainer>
    );
}

export default App;
