import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'components/Tooltip';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { useCustomQuery } from 'hooks/useCustomQuery';
import { useSwitchboard } from 'features/Switchboard';
import { DigitalAssistantIcon } from 'components/Icons';
import { ActionsMenuItem } from 'components/ActionsMenu';
import { ENROLL_CHANNELS } from 'views/Enroll/utils/constants';
import { getAllTalkTracksApi } from 'views/TalkTracks/service';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';
import { useUpgradeAccountPrompt } from 'components/Dialog/UpgradeAccountPrompt';

export function TalkTrackButton(props) {
    const { talents, source, user, contactCounts, smsPoliciesViolation, variant } = props;
    const navigate = useNavigate();
    const isTalkTrackEnabled = useSwitchboard('FEATURE_TALKTRACKS');
    const isSmsEnabled = useSwitchboard('FEATURE_SMS_CAPABILITY');

    const [logSearchEvent] = useSearchTelemetry();

    const { data } = useCustomQuery(['talk-tracks', { count: true }], getAllTalkTracksApi, {
        refetchOnWindowFocus: false,
        disabled: isTalkTrackEnabled,
    });
    const hasContacts = talents?.length === 1 ? contactCounts?.phones : true;
    const isPaid = user?.isPaid;
    const isEmailConnected = user?.isEmailConnected;
    const isDisabled =
        data?.totalResults === 0 ||
        !talents?.length ||
        !isEmailConnected ||
        !hasContacts ||
        smsPoliciesViolation?.allPolicyViolated;

    const isBulk = variant === 'bulk';

    const { openModal } = useUpgradeAccountPrompt();

    const handleClick = () => {
        logSearchEvent(SEARCH_EVENT_TYPES.DA_CLICK, {
            profileIds: talents.map((talent) => talent.talentId),
        });
        if (!isPaid || (!isSmsEnabled && isPaid)) {
            openModal();
            return;
        }
        navigate('/enroll', {
            state: {
                talents: talents.map((talent) => ({
                    talentId: talent.talentId,
                    profileId: talent.profileId,
                })),
                source: source || 'direct',
                channel: ENROLL_CHANNELS.TT,
            },
        });
    };

    const getTooltipTitle = () => {
        if (!isEmailConnected) {
            return 'Connect your email account to engage with candidates';
        } else if (!hasContacts) {
            return isNull(contactCounts?.phones)
                ? 'Perform a lookup to enable this feature'
                : 'Cannot use this feature with this talent';
        } else if (smsPoliciesViolation?.allPolicyViolated) {
            return smsPoliciesViolation?.policyViolationReason;
        } else {
            // eslint-disable-next-line quotes
            return "You don't have active digital assistant";
        }
    };

    const button = (
        <ActionsMenuItem
            data-testid="digital-assistant-button"
            data-trackid={
                isBulk ? 'bulk-actions-digital-assistant' : 'talent-actions-digital-assistant'
            }
            disabled={isDisabled}
            onClick={handleClick}
            Icon={DigitalAssistantIcon}
            label="Digital Assistant"
        />
    );

    if (!isTalkTrackEnabled) {
        return null;
    }

    if (isDisabled) {
        return (
            <Tooltip title={getTooltipTitle()} placement="right" arrow>
                <Box>{button}</Box>
            </Tooltip>
        );
    }

    return button;
}

TalkTrackButton.propTypes = {
    fullWidth: PropTypes.bool,
    talents: PropTypes.arrayOf(PropTypes.object),
    style: PropTypes.object,
    source: PropTypes.string,
    iconOnly: PropTypes.bool,
    user: PropTypes.object,
    contactCounts: PropTypes.object,
    smsPoliciesViolation: PropTypes.object,
    variant: PropTypes.string,
};
