import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import intervalToDuration from 'date-fns/intervalToDuration';

import { parseDate } from 'features/TalentCard/utils/parseDate';
import { getInitials } from 'utils/formatters';

export function getTalentCardHeader(talent) {
    const badDataString = 'No Experience Data Available';
    if (!talent?.occupation && (!talent?.experience?.[0] || !Array.isArray(talent?.experience))) {
        return badDataString;
    }

    const experience = talent.experience[0];

    if (experience?.title && experience?.company) {
        return `${experience.title}, ${experience.company}`;
    } else if (experience?.title) {
        return experience.title;
    } else if (experience?.company) {
        return experience.company;
    } else if (talent.occupation) {
        return talent.occupation;
    }
    return badDataString;
}

export function getTalentCardSubHeader(talent) {
    let isInvalid = false;
    if (hasValidExperienceData(talent)) {
        isInvalid = true;
        return ['', '', isInvalid];
    }

    const { dateFrom, dateTo } = talent.experience[0];
    const startDate = parseDate(dateFrom);
    const endDate = parseDate(dateTo);

    let experienceRangeString;
    let experienceDurationString;

    if (!isValid(startDate) || !isValid(endDate)) {
        isInvalid = true;
    } else {
        const startDateString = format(startDate, 'yyyy');
        const endDateString = Boolean(dateTo) ? format(endDate, 'yyyy') : 'Present';
        experienceRangeString = `${startDateString} - ${endDateString}`;

        const { years, months } = intervalToDuration({
            start: startDate,
            end: Boolean(dateTo) ? endDate : new Date(),
        });
        const yearsDuration = `${years} yr${years === 1 ? '' : 's'}`;
        const monthsDuration = `${months} mo${months === 1 ? '' : 's'}`;
        if (years && months) {
            experienceDurationString = `${yearsDuration} ${monthsDuration}`;
        } else if (years && !months) {
            experienceDurationString = yearsDuration;
        } else {
            experienceDurationString = monthsDuration;
        }
    }

    return [experienceRangeString, experienceDurationString, isInvalid];
}

export function getTalentLocationString(talent) {
    // TODO - should "United States" really be the fallback when there's no/data?
    const fallback = 'United States';
    if (!talent?.location?.city && !talent?.location?.state && !talent?.location?.countryName) {
        return talent?.experience?.[0]?.location || talent?.experience?.[0]?.company || fallback;
    }

    const location = talent.location;
    return getLocationString(location, fallback);
}

export function getTalentName(talent, byeBias) {
    if (!talent?.name) {
        return 'Missing A Name';
    }
    if (byeBias) {
        return getInitials(talent.name);
    }
    return toTitleCase(talent.name);
}

function getLocationString(location, fallback) {
    if (!location.city && !location.state && !location.countryName) {
        return fallback;
    } else if (!location.city && !location.state) {
        return location.countryName;
    } else if (!location.city && !location.countryName) {
        return location.state;
    } else if (!location.state && !location.countryName) {
        return location.city;
    } else if (!location.city) {
        return `${location.state}, ${location.countryName}`;
    } else if (!location.state) {
        return `${location.city}, ${location.countryName}`;
    }
    return `${location.city}, ${location.state}`;
}

function hasValidExperienceData(talent) {
    return (
        !talent?.experience?.[0] ||
        !talent?.experience?.[0]?.dateFrom ||
        !Array.isArray(talent?.experience)
    );
}

const capitalize = (w) => w.charAt(0).toUpperCase() + w.slice(1);

export const toTitleCase = (phrase) => {
    return phrase
        .toLowerCase()
        .split(' ')
        .map((word) => {
            const _split = word.split('-');

            return _split.length ? _split.map(capitalize).join('-') : capitalize(word);
        })
        .join(' ');
};
