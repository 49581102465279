import PropTypes from 'prop-types';
import Box from '@mui/system/Box';
import Switch from '@mui/material/Switch';
import { alpha } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';

import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';
import { setFindTheRightContact } from 'views/Communications/communicationsSlice';
import { InfoIcon } from 'components/Icons';

const TOOLTIP_TITLE =
    'We’ll message each available contact, in order, until the message is delivered. ' +
    'Successfully-contacted profiles who reply will be set as the primary contact.';

export function FindRightContact(props) {
    const { sx, isDisabled } = props;

    const dispatch = useDispatch();

    const isFrcEnabled = useSelector((state) => state.communications.isFrcEnabled);

    const handleClick = () => {
        dispatch(setFindTheRightContact(!isFrcEnabled));
    };

    return (
        <Box
            aria-label="Formatting options"
            data-testid="formatting-button"
            sx={{ display: 'flex', alignItems: 'center', ...sx }}
        >
            <Switch
                checked={isFrcEnabled}
                onClick={handleClick}
                disabled={isDisabled}
                inputProps={{ 'aria-label': 'Find the Right Contact toggle' }}
            />
            <Typography variant="titleSmall">Find the Right Contact</Typography>
            <Tooltip title={TOOLTIP_TITLE}>
                <span
                    style={{
                        padding: 0,
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <InfoIcon
                        fontSize="small"
                        sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.48) }}
                    />
                </span>
            </Tooltip>
        </Box>
    );
}

FindRightContact.propTypes = {
    sx: PropTypes.object,
    isDisabled: PropTypes.bool,
};
