import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAttachments } from '../messageSlice';

const shouldKeepAttachment = (attachment, removedAttachmentId) => {
    return (
        attachment?.handle !== removedAttachmentId && attachment?.mediaId !== removedAttachmentId
    );
};

export function useMessageAttachments() {
    const dispatch = useDispatch();

    const { selectedAttachments } = useSelector((state) => state.message);

    const handleSetAttachments = useCallback(
        (attachments) => {
            dispatch(setAttachments(attachments));
        },
        [dispatch]
    );

    const handleRemoveMessageAttachment = useCallback(
        (removedAttachmentId) => {
            const filteredAttachments = selectedAttachments.filter((attachment) =>
                shouldKeepAttachment(attachment, removedAttachmentId)
            );
            dispatch(setAttachments(filteredAttachments));
        },
        [dispatch, selectedAttachments]
    );

    return {
        attachments: selectedAttachments,
        handleSetAttachments,
        handleRemoveMessageAttachment,
    };
}
