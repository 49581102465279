import SvgIcon from '@mui/material/SvgIcon';

export function GoalsMetTrophyIcon(props) {
    return (
        <SvgIcon viewBox="0 0 88 89" {...props}>
            <path
                d="M79.0635 9.84843H73.3007C72.6659 4.37592 68.0756 0.125 62.4596 0.125H25.7846C20.1686 0.125 15.5783 4.37592 14.9434 9.84843H9.03431C4.05317 9.84843 0 13.9038 0 18.8877V24.5559C0 33.7419 6.29953 41.3641 14.7968 43.5628V44.0027C14.7968 57.4397 25.6868 68.3848 39.1164 68.4825V78.3527H34.2818C31.5959 78.3527 29.3983 80.5514 29.3983 83.2388C29.3983 85.9262 31.5959 88.125 34.2818 88.125H53.8646C56.5505 88.125 58.7481 85.9262 58.7481 83.2388C58.7481 80.5514 56.5505 78.3527 53.8646 78.3527H48.8834V68.5314C62.3618 68.5314 73.3496 57.5375 73.3496 44.0516V43.5628C81.7492 41.3152 88.0001 33.693 88.0001 24.6048V18.9366C88.0001 13.9527 83.9469 9.89734 78.9657 9.89734L79.0635 9.84843ZM9.81554 24.507V19.6208H14.8457V33.1556C11.8179 31.4454 9.81554 28.2204 9.81554 24.507ZM78.3309 24.507C78.3309 28.1227 76.3775 31.2987 73.4474 33.0088V19.6208H78.3309V24.507Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
