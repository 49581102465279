import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const fluffApi = createApi({
    reducerPath: 'fluffApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/search/v1/fluff-detection',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getFluffDetection: builder.query({
            query: (body) => ({
                url: '',
                method: 'POST',
                body,
            }),
        }),
        sendFluffRemovalMetric: builder.mutation({
            query: (body) => ({
                url: '/remove',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useLazyGetFluffDetectionQuery, useSendFluffRemovalMetricMutation } = fluffApi;
