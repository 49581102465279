import SvgIcon from '@mui/material/SvgIcon';

export function FluffIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M2.12278 2L0.5 3.59375L9.40611 12.3062L6.25 19.5H10.0833L12.0894 14.925L19.3217 22L20.9444 20.4062L2.47417 2.34375L2.12278 2ZM5.61111 2V2.225L9.21444 5.75H12.2747L11.3547 7.84375L14.0381 10.4688L16.1081 5.75H23.5V2H5.61111Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
