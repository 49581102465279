import SvgIcon from '@mui/material/SvgIcon';

export function OpenLinkInNewTabIcon(props) {
    return (
        <SvgIcon viewBox="0 0 15 15" {...props}>
            <path
                d="M10 10.6667H2C1.63333 10.6667 1.33333 10.3667 1.33333 10V2C1.33333 1.63333 1.63333 1.33333 2 1.33333H5.33333C5.7 1.33333 6 1.03333 6 0.666667C6 0.3 5.7 0 5.33333 0H1.33333C0.979711 0 0.640573 0.140476 0.390524 0.390524C0.140476 0.640573 0 0.979711 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V6.66667C12 6.3 11.7 6 11.3333 6C10.9667 6 10.6667 6.3 10.6667 6.66667V10C10.6667 10.3667 10.3667 10.6667 10 10.6667ZM7.33333 0.666667C7.33333 1.03333 7.63333 1.33333 8 1.33333H9.72667L3.64 7.42C3.51535 7.54465 3.44532 7.71372 3.44532 7.89C3.44532 8.06628 3.51535 8.23535 3.64 8.36C3.76465 8.48465 3.93372 8.55468 4.11 8.55468C4.28628 8.55468 4.45535 8.48465 4.58 8.36L10.6667 2.27333V4C10.6667 4.36667 10.9667 4.66667 11.3333 4.66667C11.7 4.66667 12 4.36667 12 4V0.666667C12 0.3 11.7 0 11.3333 0H8C7.63333 0 7.33333 0.3 7.33333 0.666667Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
