import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

export function AttachmentIcon(props) {
    const { fill, ...restProps } = props;

    return (
        <SvgIcon viewBox="0 0 14 16" {...restProps}>
            <path
                d="M1.82882 8.48519L7.48582 2.82819C8.04848 2.26553 8.81161 1.94943 9.60732 1.94943C10.403 1.94943 11.1662 2.26553 11.7288 2.82819C12.2915 3.39084 12.6076 4.15397 12.6076 4.94969C12.6076 5.7454 12.2915 6.50853 11.7288 7.07119L5.01083 13.7882C4.72792 14.0614 4.34902 14.2126 3.95572 14.2092C3.56243 14.2058 3.18621 14.048 2.9081 13.7699C2.62998 13.4918 2.47223 13.1156 2.46881 12.7223C2.4654 12.329 2.61659 11.9501 2.88982 11.6672L8.89983 5.65719C8.99371 5.56343 9.04651 5.43622 9.0466 5.30354C9.0467 5.17086 8.99408 5.04357 8.90032 4.94969C8.80657 4.8558 8.67936 4.803 8.54668 4.80291C8.414 4.80282 8.28671 4.85543 8.19283 4.94919L2.18282 10.9592C1.71731 11.4287 1.45672 12.0634 1.45807 12.7246C1.45942 13.3857 1.72261 14.0194 2.19005 14.487C2.65748 14.9545 3.2911 15.2179 3.95224 15.2194C4.61338 15.221 5.24822 14.9606 5.71782 14.4952L12.4358 7.77819C13.1861 7.02802 13.6077 6.01053 13.6078 4.94954C13.6079 3.88855 13.1865 2.87098 12.4363 2.12069C11.6862 1.37039 10.6687 0.948824 9.60768 0.94873C8.54669 0.948637 7.52912 1.37002 6.77882 2.12019L1.12082 7.77819C1.02975 7.87249 0.979348 7.99879 0.980488 8.12989C0.981627 8.26099 1.03421 8.38639 1.12692 8.4791C1.21962 8.5718 1.34503 8.62438 1.47612 8.62552C1.60722 8.62666 1.73352 8.57627 1.82782 8.48519H1.82882Z"
                fill={fill || 'url(#attachment_icon_linear_gradient)'}
            />
            <defs>
                <linearGradient
                    id="attachment_icon_linear_gradient"
                    x1="0.980469"
                    y1="6.07413"
                    x2="13.611"
                    y2="6.12788"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

AttachmentIcon.propTypes = {
    fill: PropTypes.string,
};
