import { getCurrentKeycloakClient } from 'features/Authentication';

export async function prepareAuthHeaders(headers, args) {
    const { forceTokenRefresh } = args || {};
    const keycloak = getCurrentKeycloakClient();
    if (keycloak) {
        await keycloak.updateToken?.(forceTokenRefresh ? -1 : 20);
        if (keycloak.token) {
            headers.set('Authorization', `Bearer ${keycloak.token}`);
        }
    }
    return headers;
}
