export function simulateTextStream(text, callback, finishedCallback) {
    const words = text.split(' ');
    let subset = '';
    let i = 0;
    const intervalId = setInterval(() => {
        subset += words[i] + ' ';
        i++;
        callback(subset.trim());
        if (i === words.length) {
            clearInterval(intervalId);
            finishedCallback();
        }
    }, 144);

    // return a cancel function
    return (options) => {
        clearInterval(intervalId);
        if (options?.finish) {
            finishedCallback();
        }
    };
}
