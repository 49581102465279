import { useCallback, useEffect, useRef, useState } from 'react';

export function useRTE(props) {
    const { initialBody, resetInitialBody, setInitialValue } = props;

    const [innerText, setInnerText] = useState('');
    const [innerHtml, updateInnerHtml] = useState('');

    const innerHtmlRef = useRef('');

    const setInnerHtml = useCallback((value) => {
        innerHtmlRef.current = value;
        updateInnerHtml(innerHtmlRef.current);
    }, []);

    const handleMessageBody = (event) => {
        if (initialBody && Boolean(resetInitialBody)) {
            resetInitialBody();
        }

        setInnerText(event.target.plainText);
        setInnerHtml(event.target.value);
    };

    useEffect(() => {
        if (initialBody) {
            setInitialValue?.(initialBody.trim());
        }
    }, [initialBody, setInitialValue]);

    const resetMessageBody = useCallback(() => {
        setInnerText('');
        setInnerHtml('');
    }, [setInnerHtml]);

    return {
        setInnerHtml,
        innerText,
        innerHtml,
        handleMessageBody,
        resetMessageBody,
    };
}
