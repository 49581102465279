import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { Typography } from 'components/Typography';

export function LookupErrorStage(props) {
    const { talentLookups } = props;

    return (
        <>
            <Typography
                variant="titleMedium"
                sx={{ color: 'text.primary', textAlign: 'center', mb: 0.5 }}
            >
                We&apos;re missing contact data for some of the selected candidates.
            </Typography>
            <Typography
                variant="bodyLarge"
                sx={{ color: 'text.primary', textAlign: 'center', mb: 3 }}
            >
                Please review the list below and remove failed candidate lookups in order to send
                your message.
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mb: 3,
                    maxHeight: 250,
                    overflowY: 'auto',
                }}
            >
                {talentLookups?.map((talent) => (
                    <Box key={talent?.id} sx={{ color: 'text.primary' }}>
                        <Typography variant="bodyLarge" sx={{ mr: 1 }}>
                            {talent?.name}
                        </Typography>
                        <Typography
                            variant="bodyLarge"
                            sx={{ color: !talent?.lookupFailed ? 'success.main' : 'error.main' }}
                        >
                            {!talent?.lookupFailed ? 'Success' : 'Not Found'}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </>
    );
}

LookupErrorStage.propTypes = {
    talentLookups: PropTypes.array,
};
