import remove from 'lodash/remove';
import orderBy from 'lodash/orderBy';
import includes from 'lodash/includes';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { SEARCH_EMPHASIS_WEIGHT } from 'utils/constants';
import { parseTalentForSimilarSearch } from 'utils/parseForSearch';

export const initialSearchState = {
    searchId: '',
    isDirty: false,
    isOutOfCredits: false,
    zeroState: true,
    selectedSavedSearch: null,
    page: 1,
    pageLength: 10,
    matchText: '',
    totalResults: 0,
    searchEmphasis: [],
    andFilters: ['keywords'],
    filters: {
        keywords: [],
        radialLocation: [],
    },
    exclusionFilters: {
        radialLocation: [],
    },
    fast: {
        originalInput: '',
        searchSkills: [],
        searchJobTitles: [],
    },
    results: [],
    viewedIds: [],
    shouldRunSearch: false,
    shouldSpendCredit: false,
    hiddenTalentIds: [],
    showAllHiddenTalents: false,
    unappliedProfileHighlights: [],
    currentSearch: {
        matchText: '',
        showAllHiddenTalents: false,
        andFilters: [],
        filters: {
            keywords: [],
            radialLocation: [],
        },
        exclusionFilters: {
            radialLocation: [],
        },
        searchEmphasis: [],
        fast: {
            originalInput: '',
            searchSkills: [],
            searchJobTitles: [],
        },
    },
    findSimilar: {
        talent: { id: '', name: '' },
        originalSearch: {},
    },
};

export const searchSlice = createSlice({
    name: 'search',
    initialState: initialSearchState,
    reducers: {
        startSearch: (state) => {
            state.zeroState = false;
            state.page = 1;
            state.totalResults = 0;
            state.viewedIds = [];
            state.shouldRunSearch = true;
            state.shouldSpendCredit = true;
            state.isOutOfCredits = false;
            state.isDirty = false;
            state.hiddenTalentIds = [];
            state.currentSearch = {
                matchText: state.matchText,
                showAllHiddenTalents: state.showAllHiddenTalents,
                andFilters: state.andFilters,
                filters: state.filters,
                exclusionFilters: state.exclusionFilters,
                searchEmphasis: state.searchEmphasis,
                fast: state.fast,
            };
        },
        runSearch: (state) => {
            state.page = 1;
            state.isDirty = false;
            state.totalResults = 0;
            state.viewedIds = [];
            state.shouldRunSearch = true;
            state.hiddenTalentIds = [];
            state.isOutOfCredits = false;
            state.shouldSpendCredit = false;
            state.searchEmphasis.forEach((emphasis) => {
                delete emphasis.unapplied;
            });
            const unappliedProfileHighlights = [...state.unappliedProfileHighlights];
            if (unappliedProfileHighlights.length) {
                state.matchText += '\n';
            }
            unappliedProfileHighlights.forEach((highlight) => {
                state.matchText += `\n${highlight.value}`;
                if (highlight.emphasize) {
                    // matchText has already been appended
                    const startIndex = state.matchText.length - highlight.value.length - 1;
                    const endIndex = state.matchText.length;
                    state.searchEmphasis.push({
                        source: 'PROFILE_TEXT',
                        startIndex,
                        endIndex,
                        weight: SEARCH_EMPHASIS_WEIGHT,
                    });
                }
            });
            state.unappliedProfileHighlights = [];
            state.currentSearch = {
                matchText: state.matchText,
                showAllHiddenTalents: state.showAllHiddenTalents,
                andFilters: state.andFilters,
                filters: state.filters,
                exclusionFilters: state.exclusionFilters,
                searchEmphasis: state.searchEmphasis,
                fast: state.fast,
            };
        },
        findSimilar: (state, action) => {
            const { talent } = action.payload;
            if (!talent?.talentId) {
                return state;
            }

            const nextState = { ...initialSearchState };

            const [searchEmphasis, matchText] = parseTalentForSimilarSearch(talent);

            nextState.matchText = matchText;
            nextState.searchEmphasis = searchEmphasis;
            nextState.zeroState = false;
            nextState.viewedIds = [];
            nextState.shouldRunSearch = true;
            nextState.shouldSpendCredit = true;
            nextState.hiddenTalentIds = [];
            nextState.currentSearch = {
                ...initialSearchState.currentSearch,
                searchEmphasis,
                matchText,
            };
            nextState.findSimilar = {
                talent: { id: talent.talentId, name: talent.name },
                originalSearch: state.currentSearch,
            };
            return nextState;
        },
        goBackToZeroStateWithMatchText: (state) => {
            const nextState = { ...initialSearchState };
            nextState.matchText = state.currentSearch.matchText;
            nextState.searchEmphasis = state.currentSearch.searchEmphasis;
            return nextState;
        },
        goBackToZeroStateFromEnhancedSearch: (state) => {
            const nextState = { ...initialSearchState };
            nextState.matchText = state.currentSearch.fast.originalInput;
            return nextState;
        },
        goToOriginalSearchFromFindSimilar: (state) => {
            const findSimilarSearch = { ...state.findSimilar };
            state.page = 1;
            state.totalResults = 0;
            state.isDirty = false;
            state.viewedIds = [];
            state.shouldRunSearch = true;
            state.isOutOfCredits = false;
            state.shouldSpendCredit = false;
            state.unappliedProfileHighlights = [];
            state.hiddenTalentIds = [];
            state.currentSearch = findSimilarSearch.originalSearch;
            state.matchText = findSimilarSearch.originalSearch.matchText;
            state.matchText = findSimilarSearch.originalSearch.matchText;
            state.andFilters = findSimilarSearch.originalSearch.andFilters;
            state.filters = findSimilarSearch.originalSearch.filters;
            state.exclusionFilters = findSimilarSearch.originalSearch.exclusionFilters;
            state.searchEmphasis = findSimilarSearch.originalSearch.searchEmphasis;
            state.fast = findSimilarSearch.originalSearch.fast;
            state.findSimilar = initialSearchState.findSimilar;
        },
        addProfileHighlight: (state, action) => {
            state.unappliedProfileHighlights.push(action.payload);
            state.isDirty = true;
        },
        hideTalent: (state, action) => {
            if (!includes(state.hiddenTalentIds, action.payload)) {
                state.hiddenTalentIds.push(action.payload);
            }
        },
        unHideTalent: (state, action) => {
            remove(state.hiddenTalentIds, (id) => id === action.payload);
        },
        toggleShowAllHiddenTalents: (state) => {
            state.showAllHiddenTalents = !state.showAllHiddenTalents;
            state.isDirty = true;
            state.selectedSavedSearch = null;
        },
        removeProfileHighlight: (state, action) => {
            remove(state.unappliedProfileHighlights, (highlight) => {
                return highlight.id === action.payload.id;
            });
        },
        removeAllProfileHighlights: (state) => {
            state.unappliedProfileHighlights = [];
        },
        removeAllUnappliedSearchEmphasis: (state) => {
            remove(state.searchEmphasis, (emphasis) => {
                return Boolean(emphasis.unapplied);
            });
        },
        removeFluff: (state, action) => {
            const fluffToRemove = orderBy(action.payload, ['startIndex'], ['desc']);
            let nextMatchText = state.matchText;
            fluffToRemove.forEach((fluff) => {
                const charDiff = fluff.startIndex - fluff.endIndex;
                const beforeFluff = nextMatchText.slice(0, fluff.startIndex);
                const afterFluff = nextMatchText.slice(fluff.endIndex);
                nextMatchText = beforeFluff + afterFluff;

                // logic assumes no overlap between empahsis and fluff
                state.searchEmphasis.forEach((emphasis) => {
                    if (emphasis.startIndex >= fluff.startIndex) {
                        emphasis.startIndex += charDiff;
                    }
                    if (emphasis.endIndex > fluff.startIndex) {
                        emphasis.endIndex += charDiff;
                    }
                });
            });
            state.matchText = nextMatchText;
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        changeMatchText: (state, action) => {
            const { matchText, cursorPosition, charDiff } = action.payload;
            state.matchText = matchText;
            if ('cursorPosition' in action.payload && 'charDiff' in action.payload) {
                state.searchEmphasis.forEach((emphasis) => {
                    if (emphasis.startIndex >= cursorPosition) {
                        emphasis.startIndex += charDiff;
                    }
                    if (emphasis.endIndex > cursorPosition) {
                        emphasis.endIndex += charDiff;
                    }
                });
            }
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        clearMatchText: (state) => {
            state.matchText = '';
            state.searchEmphasis = [];
            state.findSimilar = initialSearchState.findSimilar;
            state.fast = initialSearchState.fast;
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        addKeywordFilter: (state, action) => {
            const { isExclude, asWritten, keyword } = action.payload;
            const currentKeywords = state.filters.keywords;
            const effectiveKeyword =
                /^"([^"]*)"$/.test(keyword) || asWritten ? keyword : `"${keyword}"`;
            const excludedKeyword = `NOT (${effectiveKeyword})`;
            if (isExclude && currentKeywords.indexOf(excludedKeyword) === -1) {
                state.filters.keywords.push(excludedKeyword);
                state.selectedSavedSearch = null;
                state.isDirty = true;
            } else if (!isExclude && currentKeywords.indexOf(effectiveKeyword) === -1) {
                state.filters.keywords.push(effectiveKeyword);
                state.selectedSavedSearch = null;
                state.isDirty = true;
            }
        },
        toggleKeywordFilter: (state, action) => {
            const { convertToExclude, keyword } = action.payload;
            const currentIndex = state.filters.keywords.indexOf(keyword);
            if (convertToExclude && currentIndex !== -1) {
                const excludedKeyword = `NOT (${keyword})`;
                if (state.filters.keywords.indexOf(excludedKeyword) === -1) {
                    state.filters.keywords[currentIndex] = excludedKeyword;
                } else {
                    state.filters.keywords.splice(currentIndex, 1);
                }
                state.selectedSavedSearch = null;
                state.isDirty = true;
            } else if (currentIndex !== -1) {
                const includedKeyword = keyword.replace(/^NOT \((.+)\)$/, '$1');
                if (state.filters.keywords.indexOf(includedKeyword) === -1) {
                    state.filters.keywords[currentIndex] = includedKeyword;
                } else {
                    state.filters.keywords.splice(currentIndex, 1);
                }
                state.selectedSavedSearch = null;
                state.isDirty = true;
            }
        },
        changeKeywordsFilter: (state, action) => {
            state.filters.keywords = action.payload;
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        addLocationFilter: (state, action) => {
            state.filters.radialLocation.push(action.payload);
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        addExcludedLocationFilter: (state, action) => {
            state.exclusionFilters.radialLocation.push(action.payload);
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        clearLocationFilters: (state) => {
            state.filters.radialLocation = [];
            state.exclusionFilters.radialLocation = [];
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        changeStandardFilter: (state, action) => {
            const { property, matchText } = action.payload;
            state.filters[property] = matchText;
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        changeStandardExcludeFilter: (state, action) => {
            const { property, matchText } = action.payload;
            state.exclusionFilters[property] = matchText;
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        convertIncludeToExclude: (state, action) => {
            const { property, index } = action.payload;
            const includeFilter = state.filters[property].splice(index, 1)[0];
            if (Array.isArray(state.exclusionFilters[property])) {
                state.exclusionFilters[property].push(includeFilter);
            } else {
                state.exclusionFilters[property] = [includeFilter];
            }
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        convertExcludeToInclude: (state, action) => {
            const { property, index } = action.payload;
            const ExcludeFilter = state.exclusionFilters[property].splice(index, 1)[0];
            if (Array.isArray(state.filters[property])) {
                state.filters[property].push(ExcludeFilter);
            } else {
                state.filters[property] = [ExcludeFilter];
            }
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        deleteStandardFilter: (state, action) => {
            const { property, index } = action.payload;
            state.filters[property].splice(index, 1);
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        deleteStandardExcludeFilter: (state, action) => {
            const { property, index } = action.payload;
            state.exclusionFilters[property].splice(index, 1);
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        removeStandardFilter: (state, action) => {
            delete state.filters[action.payload];
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        clearFilters: (state) => {
            state.filters = { keywords: [], radialLocation: [] };
            state.exclusionFilters = { radialLocation: [] };
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        changePage: (state, action) => {
            state.page = action.payload;
            state.shouldRunSearch = true;
            state.shouldSpendCredit = false;
            state.hiddenTalentIds = [];
        },
        changePageLength: (state, action) => {
            state.pageLength = action.payload;
            state.shouldRunSearch = true;
            state.shouldSpendCredit = false;
            state.hiddenTalentIds = [];
        },
        resetSearch: () => {
            return initialSearchState;
        },
        resetMultipleStandardFilters: (state, action) => {
            const properties = action.payload;
            properties.forEach((property) => {
                state.filters[property] = [];
                state.exclusionFilters[property] = [];
            });
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        runSearchFromJD: (state, action) => {
            const nextState = { ...initialSearchState };
            nextState.matchText = action.payload.matchText || '';
            nextState.searchEmphasis = action.payload.searchEmphasis || [];
            nextState.zeroState = false;
            nextState.viewedIds = [];
            nextState.shouldRunSearch = true;
            nextState.shouldSpendCredit = true;
            nextState.hiddenTalentIds = [];
            nextState.currentSearch = {
                matchText: nextState.matchText,
                showAllHiddenTalents: nextState.showAllHiddenTalents,
                andFilters: nextState.andFilters,
                filters: nextState.filters,
                exclusionFilters: nextState.exclusionFilters,
                searchEmphasis: nextState.searchEmphasis,
                fast: nextState.fast,
            };
            return nextState;
        },
        loadSavedSearch: (state, action) => {
            const savedSearch = action.payload.query;
            const nextState = { ...initialSearchState };
            nextState.matchText = savedSearch.matchText;
            nextState.showAllHiddenTalents = savedSearch.showAllHiddenTalents ?? false;
            if (action.payload.name && action.payload.id) {
                nextState.selectedSavedSearch = {
                    id: action.payload.id,
                    name: action.payload.name,
                };
            } else {
                nextState.selectedSavedSearch = null;
            }
            if (savedSearch.andFilters) {
                nextState.andFilters = savedSearch.andFilters;
            }
            if (savedSearch.filters) {
                nextState.filters = savedSearch.filters;
            }
            if (savedSearch.exclusionFilters) {
                nextState.exclusionFilters = savedSearch.exclusionFilters;
            }
            if (savedSearch.searchEmphasis) {
                nextState.searchEmphasis = savedSearch.searchEmphasis;
            } else {
                nextState.searchEmphasis = [];
            }
            if (savedSearch.fast) {
                nextState.fast = savedSearch.fast;
            }
            nextState.page = 1;
            nextState.totalResults = 0;
            nextState.zeroState = false;
            nextState.shouldSpendCredit = true;
            nextState.viewedIds = [];
            nextState.shouldRunSearch = true;
            nextState.isDirty = false;
            nextState.hiddenTalentIds = [];
            nextState.findSimilar = initialSearchState.findSimilar;
            nextState.currentSearch = {
                matchText: nextState.matchText,
                showAllHiddenTalents: nextState.showAllHiddenTalents,
                andFilters: nextState.andFilters,
                filters: nextState.filters,
                exclusionFilters: nextState.exclusionFilters,
                searchEmphasis: nextState.searchEmphasis,
                fast: nextState.fast,
            };
            return nextState;
        },
        clearSelectedSavedSearch: (state) => {
            state.selectedSavedSearch = null;
        },
        addSearchEmphasis: (state, action) => {
            state.searchEmphasis.push(action.payload);
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        removeSearchEmphasis: (state, action) => {
            const { startIndex, endIndex } = action.payload;
            remove(state.searchEmphasis, (emphasis) => {
                return emphasis.startIndex === startIndex && emphasis.endIndex === endIndex;
            });
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        clearSearchEmphasis: (state) => {
            state.searchEmphasis = [];
            state.selectedSavedSearch = null;
            state.isDirty = true;
        },
        changeTotalResult: (state, action) => {
            state.totalResults = action.payload;
        },
        completeSearchRequest: (state, action) => {
            const currentViewedIds = [...state.viewedIds];
            const ids = action.payload.map((talent) => talent[0].talentId);
            state.results = action.payload;
            state.viewedIds = [...new Set(currentViewedIds.concat(ids))]; // Use a Set to de-dupe (edge-case)
            state.shouldRunSearch = false;
        },
        changeIsOutOfCredits: (state, action) => {
            state.isOutOfCredits = action.payload;
        },
        changeSearchId: (state, action) => {
            state.searchId = action.payload;
        },
        toggleAndFilter: (state, action) => {
            const filterKey = action.payload;
            if (includes(state.andFilters, filterKey)) {
                remove(state.andFilters, (_filterKey) => _filterKey === filterKey);
            } else {
                state.andFilters.push(filterKey);
            }
            state.isDirty = true;
            state.selectedSavedSearch = null;
        },
    },
});

export const {
    runSearch,
    findSimilar,
    goBackToZeroStateWithMatchText,
    goBackToZeroStateFromEnhancedSearch,
    goToOriginalSearchFromFindSimilar,
    addProfileHighlight,
    removeProfileHighlight,
    removeAllProfileHighlights,
    removeAllUnappliedSearchEmphasis,
    changeStandardFilter,
    changeIsOutOfCredits,
    resetMultipleStandardFilters,
    changeStandardExcludeFilter,
    removeStandardFilter,
    changeMatchText,
    clearMatchText,
    changeKeywordsFilter,
    changePageLength,
    changePage,
    startSearch,
    resetSearch,
    addLocationFilter,
    addExcludedLocationFilter,
    clearLocationFilters,
    clearFilters,
    deleteStandardFilter,
    deleteStandardExcludeFilter,
    convertIncludeToExclude,
    convertExcludeToInclude,
    loadSavedSearch,
    clearSelectedSavedSearch,
    addSearchEmphasis,
    removeSearchEmphasis,
    clearSearchEmphasis,
    changeTotalResult,
    removeFluff,
    runSearchFromJD,
    completeSearchRequest,
    addKeywordFilter,
    toggleKeywordFilter,
    hideTalent,
    toggleShowAllHiddenTalents,
    changeSearchId,
    toggleAndFilter,
} = searchSlice.actions;

export const searchReducer = searchSlice.reducer;

const hiddenTalentIds = (state) => state.search.hiddenTalentIds;

const searchResults = (state) => state.search.results;

export const getVisibleTalentsSelector = createSelector(
    [searchResults, hiddenTalentIds],
    (results, hiddenTalentIds) => {
        return results.filter((talent) => {
            return !includes(hiddenTalentIds, talent?.[0].talentId) && !talent?.[0]?.hidden;
        }); //talent is not in hiddenTalentIds and talent is not hidden
    }
);

export const getHiddenTalentCountSelector = createSelector(
    [searchResults, hiddenTalentIds],
    (results, hiddenTalentIds) => {
        return results.filter((talent) => {
            return includes(hiddenTalentIds, talent?.[0].talentId) || talent?.[0]?.hidden;
        }).length;
    }
);
