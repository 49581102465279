import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from 'components/Typography';
import { Certification } from 'features/TalentCard/components/Certification';

export function TalentCertifications(props) {
    const { certifications = [] } = props;

    const sanitizedCertifications = certifications
        .filter((cert) => Boolean(cert))
        .map((cert) => ({ name: cert }));

    if (!sanitizedCertifications.length) {
        return null;
    }

    return (
        <Box sx={{ mb: 1.3 }}>
            <Box sx={{ mb: 0.8 }}>
                <Typography variant="labelSmall" color="textPrimary">
                    Certifications
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {sanitizedCertifications.map((certification, index) => (
                    <Certification
                        key={certification.name + index}
                        certification={certification}
                        showDivider={sanitizedCertifications.length - 1 !== index}
                        sx={{ mb: 1, position: 'relative' }}
                    />
                ))}
            </Box>
        </Box>
    );
}

TalentCertifications.propTypes = {
    certifications: PropTypes.array,
};
