export const CONVERSATION_BOT_TYPES = {
    AUTOMATED_OUTCOME: 'automated_outcome',
    SEQUENCE: 'sequence',
    TALKTRACK: 'talktrack',
};

export const CONVERSATION_BOT_STATUS = {
    ACTIVE: 'active',
    ENROLLED: 'enrolled',
    UNENROLLED: 'unenrolled',
    GOAL_MET: 'goal_met',
};

export const MESSAGE_SOURCES = {
    DIRECT: 'direct',
    SEQUENCE: 'sequence',
    TALKTRACK: 'talkTrack',
};

export const THREAD_COLUMN_PROPS = {
    ROOT: (theme) => ({
        width: '100%',
        margin: theme.spacing(0.5, 'auto', 0),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    }),
    CONTAINER: (theme) => ({
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
    }),
    MAIN: (theme) => ({
        width: '100%',
        maxWidth: theme.spacing(93),
        [theme.breakpoints.down('lg')]: {
            margin: '0 auto',
        },
    }),
    SIDE: (theme, justifyContent = 'right') => ({
        minWidth: theme.spacing(20),
        maxWidth: theme.spacing(20),
        [theme.breakpoints.down('lg')]: {
            margin: theme.spacing(1),
            maxWidth: theme.spacing('100%'),
            justifyContent,
        },
    }),
};
