import PropTypes from 'prop-types';
import { useMemo, useCallback, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';

import { getInitials } from 'utils/formatters';
import { ChevronDownIcon, ToFromIcon, CloseIcon } from 'components/Icons';
import {
    changeTalentIdFilter,
    clearTalentIdFilter,
} from 'views/Communications/communicationsSlice';
import { Typography, TYPOGRAPHY_VARIANTS } from 'components/Typography';

const TypographyEmailSx = {
    ml: 2,
    color: (theme) => alpha(theme.palette.common.white, 0.48),
};

const OptionSx = {
    overflow: 'hidden',
    maskImage: (t) => `linear-gradient(to left, transparent, 15%, ${t.palette.text.primary})`,
};

const PaperSxProps = {
    mt: 0,
    background: (theme) =>
        `linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), ${theme.palette.text.primary};`,
    color: (theme) => theme.palette.common.white,
    '& .MuiAutocomplete-noOptions': {
        color: (theme) => theme.palette.common.white,
    },
};

const TalentAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: theme.spacing(1.25),
    background:
        'linear-gradient(180deg, rgba(65, 232, 211, 0) 0%, rgba(65, 232, 211, 0.7) 100%), #3A8DD3',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    height: theme.spacing(3),
    borderRadius: theme.spacing(3),
    color: theme.palette.common.white,
    background: alpha(theme.palette.common.white, 0.08),
    ...TYPOGRAPHY_VARIANTS.titleSmall,
    '& .MuiChip-avatar': {
        marginLeft: 0,
    },
    '& .MuiChip-deleteIcon': {
        marginRight: theme.spacing(1),
        fontSize: theme.spacing(1.75),
        color: alpha(theme.palette.common.white, 0.48),
        '&:hover': { color: theme.palette.error.main },
    },
}));

export function FilterCandidateName({ talents, isLoading }) {
    const dispatch = useDispatch();

    const talentId = useSelector((state) => state.communications.filters.talentId);

    const [lookupText, setLookupText] = useState('');

    const handleOnChange = useCallback(
        (_, value) => {
            dispatch(changeTalentIdFilter(value.talentId));
        },
        [dispatch]
    );

    const handleClear = useCallback(() => {
        dispatch(clearTalentIdFilter());
    }, [dispatch]);

    const handleChangeLookupText = useCallback((event) => {
        setLookupText(event.target.value);
    }, []);

    const printOptionLabel = useCallback((option) => option.name || '', []);

    const isOptionEqualToValue = useCallback((option, value) => {
        return value?.length ? value === option.talentId : false;
    }, []);

    const talent = useMemo(() => {
        return talents?.[talentId] || {};
    }, [talentId, talents]);

    const results = useMemo(() => {
        return talents ? Object.values(talents) : [];
    }, [talents]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: (theme) => theme.spacing(1, 1.5, 0.5, 1.75),
                height: (theme) => theme.spacing(5),
                alignItems: 'flex-end',
            }}
        >
            <ToFromIcon sx={{ color: 'common.white', mr: 2 }} />
            <Autocomplete
                fullWidth
                value={talentId}
                onChange={handleOnChange}
                options={results}
                disableClearable
                forcePopupIcon={false}
                getOptionLabel={printOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                loading={isLoading}
                popupIcon={<ChevronDownIcon sx={{ color: 'common.white' }} />}
                renderOption={(props, option) => (
                    <Box {...props} sx={OptionSx}>
                        <Box>
                            <Typography variant="titleSmall" noWrap>
                                {option.name}
                            </Typography>
                        </Box>
                        <Typography sx={TypographyEmailSx} variant="titleSmall">
                            {option.email}
                        </Typography>
                    </Box>
                )}
                renderInput={(params) => (
                    <>
                        {talentId ? (
                            <StyledChip
                                {...params.inputProps}
                                label={talent.name}
                                onDelete={handleClear}
                                deleteIcon={<CloseIcon aria-label="Clear talent names" />}
                                avatar={
                                    <TalentAvatar
                                        alt="Talent Avatar"
                                        src={talent.imageUrl}
                                        title={talent.name}
                                    >
                                        {getInitials(talent.name, '')}
                                    </TalentAvatar>
                                }
                            />
                        ) : (
                            <TextField
                                {...params}
                                sx={{
                                    input: {
                                        color: 'common.white',
                                        borderBottom: '2px solid rgba(255, 255, 255, 0.12)',
                                        pb: 1,
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                }}
                                value={lookupText}
                                onChange={handleChangeLookupText}
                                placeholder="e.g. Jane Doe"
                                variant="standard"
                            />
                        )}
                    </>
                )}
                PaperComponent={({ children }) => <Paper sx={PaperSxProps}>{children}</Paper>}
            />
        </Box>
    );
}

FilterCandidateName.propTypes = {
    talents: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        imageUrl: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
};
