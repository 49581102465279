import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { MESSAGE_TYPES } from 'utils/constants';
import { Typography } from 'components/Typography';
import { ChevronDownIcon } from 'components/Icons';
import { useSelector } from 'react-redux';
import { useUpgradeAccountPrompt } from 'components/Dialog/UpgradeAccountPrompt';
import { useSwitchboard } from 'features/Switchboard';

const StyledButton = styled(Button)(({ theme }) => ({
    width: theme.spacing(10),
    marginRight: theme.spacing(1),
    justifyContent: 'left',
}));

export function MessageTypeDropdown(props) {
    const { messageType = 'email', onChangeMessageType } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    const isPaid = useSelector((state) => state.user?.isPaid);
    const { openModal } = useUpgradeAccountPrompt();
    const isSmsEnabled = useSwitchboard('FEATURE_SMS_CAPABILITY');

    const handleTypeClick = (type) => {
        if (type === MESSAGE_TYPES.SMS && (!isPaid || (!isSmsEnabled && isPaid))) {
            openModal();
            return;
        }

        onChangeMessageType(type);
    };

    return (
        <>
            <StyledButton
                aria-label="message-type-dropdown-button"
                aria-controls={open ? 'message-type-menu' : null}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : null}
                onClick={handleOpen}
                data-trackid="conversation-message-contact-method-dropdown"
            >
                <ChevronDownIcon sx={{ mr: 1, fontSize: 20 }} />
                <Typography variant="labelSmall">{messageType}</Typography>
            </StyledButton>
            <Menu id="message-type-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                {Object.values(MESSAGE_TYPES).map((type) => (
                    <MenuItem
                        aria-label={type}
                        data-trackid={`contact-method-dropdown-${type}`}
                        key={type}
                        value={type}
                        onClick={() => {
                            handleTypeClick(type);
                            handleClose();
                        }}
                    >
                        <Typography variant="labelSmall">{type}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

MessageTypeDropdown.propTypes = {
    messageType: PropTypes.string,
    onChangeMessageType: PropTypes.func,
};
