import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { useLogSearchEventMutation } from 'services/search';

export function useSearchTelemetry() {
    const isSearch = useMatch('/search/results');
    const byeBias = useSelector((state) => state.byeBias.on);
    const searchId = useSelector((state) => state.search.searchId);

    const [logSearchEvent, result] = useLogSearchEventMutation();

    const logEvent = async (actionType, supplementalData) => {
        await logSearchEvent({
            searchId,
            actionType,
            details: { byeBias, ...supplementalData },
        });
    };

    // safeguard around collecting data from other areas of the platform
    if (!isSearch) {
        return [() => {}, {}];
    }

    return [logEvent, result];
}
