import { useEffect, useState, useRef, memo } from 'react';
import { useSlate, ReactEditor } from 'slate-react';
import { Transforms, Editor, Range, Element } from 'slate';
import Box from '@mui/material/Box';
import Code from '@mui/icons-material/Code';
import LooksOnetIcon from '@mui/icons-material/LooksOne';
import LooksTwotIcon from '@mui/icons-material/LooksTwo';
import FormatQuotetIcon from '@mui/icons-material/FormatQuote';
import FormatItalictIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedtIcon from '@mui/icons-material/FormatUnderlined';
import FormatBoldOutlinedtIcon from '@mui/icons-material/FormatBoldOutlined';
import FormatListBulletedtIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedtIcon from '@mui/icons-material/FormatListNumbered';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import PropTypes from 'prop-types';

import { MarkButton } from './MarkButton';
import { LinkButton } from './LinkButton';
import { BlockButton } from './BlockButton';
import { TextInput } from '../TextInput';
import { Collapse, IconButton } from '@mui/material';
import { CheckCircleIcon, CloseIcon } from 'components/Icons';

const TextInputProps = {
    margin: 'dense',
    sx: { m: 0.25 },
    'aria-label': 'format-link-capture-address',
};

export const removeLink = (editor, opts = {}) => {
    Transforms.unwrapNodes(editor, {
        ...opts,
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
    });
};

const insertLink = (editor, href = '') => {
    if (!href) return;
    const { selection } = editor;
    ReactEditor.focus(editor);
    if (!selection) return;
    const [parentNode] = Editor.parent(editor, selection.focus?.path);
    if (parentNode.type === 'link') {
        removeLink(editor);
    }
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
        type: 'link',
        href,
        children: isCollapsed ? [{ text: href }] : [],
    };
    if (isCollapsed) {
        Transforms.insertNodes(editor, link);
    } else {
        Transforms.wrapNodes(editor, link, { split: true });
        Transforms.select(editor, Editor.end(editor, []));
    }
};

export const Toolbar = memo(({ disabled, readOnly, onCancelCapture }) => {
    const inputRef = useRef();
    const editor = useSlate();

    const [captureLink, setCaptureLink] = useState(false);
    const [workingHref, setWorkingHref] = useState('');

    const handleWorkingHref = (event) => {
        setWorkingHref(event.target.value);
    };

    const handleReset = () => {
        setCaptureLink(false);
        setWorkingHref('');
    };

    const handleCancelHref = () => {
        handleReset();
        if (typeof onCancelCapture === 'function') onCancelCapture({ target: { value: true } });
    };

    const handleSubmitHref = () => {
        insertLink(editor, workingHref);
        handleReset();
    };

    const handleSelectAll = () => {
        Transforms.select(editor, []);
    };

    useEffect(() => {
        if (captureLink) inputRef.current.focus();
    }, [captureLink]);

    return (
        <>
            <Box
                sx={{
                    display: disabled || readOnly ? 'none' : 'flex',
                    maxWidth: 'inherited',
                    overflowX: 'auto',
                }}
            >
                <MarkButton
                    format="bold"
                    label="Bold"
                    Icon={FormatBoldOutlinedtIcon}
                    disabled={captureLink}
                />
                <MarkButton
                    format="italic"
                    label="Italic"
                    Icon={FormatItalictIcon}
                    disabled={captureLink}
                />
                <MarkButton
                    format="underline"
                    label="Underlined"
                    Icon={FormatUnderlinedtIcon}
                    disabled={captureLink}
                />
                <MarkButton format="code" label="Code" Icon={Code} disabled={captureLink} />
                <BlockButton
                    format="heading-one"
                    label="Heading 1"
                    Icon={LooksOnetIcon}
                    disabled={captureLink}
                />
                <BlockButton
                    format="heading-two"
                    label="Heading 2"
                    Icon={LooksTwotIcon}
                    disabled={captureLink}
                />
                <BlockButton
                    format="block-quote"
                    label="Quote"
                    Icon={FormatQuotetIcon}
                    disabled={captureLink}
                />
                <BlockButton
                    format="numbered-list"
                    label="Numbered List"
                    Icon={FormatListNumberedtIcon}
                    disabled={captureLink}
                />
                <BlockButton
                    format="bulleted-list"
                    label="Unordered List"
                    Icon={FormatListBulletedtIcon}
                    disabled={captureLink}
                />
                <BlockButton
                    format="left"
                    label="Align left"
                    Icon={FormatAlignLeftIcon}
                    disabled={captureLink}
                />
                <BlockButton
                    format="center"
                    label="Align center"
                    Icon={FormatAlignCenterIcon}
                    disabled={captureLink}
                />
                <BlockButton
                    format="right"
                    label="Align right"
                    Icon={FormatAlignRightIcon}
                    disabled={captureLink}
                />
                <BlockButton
                    format="justify"
                    label="Justify"
                    Icon={FormatAlignJustifyIcon}
                    disabled={captureLink}
                />
                <LinkButton disabled={captureLink} onCaptureLink={setCaptureLink} />
                <input
                    type="hidden"
                    aria-label="format-text-select-all"
                    onClick={handleSelectAll}
                />
            </Box>
            <Collapse in={captureLink}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextInput
                        inputRef={inputRef}
                        margin="dense"
                        size="small"
                        fullWidth
                        placeholder="e.g https://www.nebula.io/"
                        value={workingHref}
                        onChange={handleWorkingHref}
                        inputProps={TextInputProps}
                    />
                    <IconButton
                        onClick={handleSubmitHref}
                        color="success"
                        aria-label="format-link-add"
                    >
                        <CheckCircleIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleCancelHref}
                        color="error"
                        aria-label="format-link-cancel"
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Collapse>
        </>
    );
});

Toolbar.propTypes = {
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onCancelCapture: PropTypes.func,
};
