import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import {
    CONVERSATION_BOT_TYPES,
    CONVERSATION_BOT_STATUS,
} from 'views/Communications/utils/constants';
import {
    SequenceArrowIcon,
    DigitalAssistantIcon,
    DigitalAssistantIconWithGradientColor,
} from 'components/Icons';
import { Tooltip } from 'components/Tooltip';

const Container = styled(Box)(() => ({
    display: 'flex',
    direction: 'rtl',
}));

const IconContainer = styled(Box)(() => ({
    display: 'inline-flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '40px',
    ':not(:first-of-type)': {
        marginRight: '-4px',
    },
}));

const BOT_TYPES = {
    [CONVERSATION_BOT_TYPES.AUTOMATED_OUTCOME]: {
        type: CONVERSATION_BOT_TYPES.AUTOMATED_OUTCOME,
        icon: (
            <IconContainer
                sx={{ background: 'linear-gradient(90.28deg, #504CE0 0.17%, #318DDE 99.72%)' }}
                key={CONVERSATION_BOT_TYPES.AUTOMATED_OUTCOME}
            >
                <DigitalAssistantIcon
                    sx={{ fontSize: 12 }}
                    data-testid={`${CONVERSATION_BOT_TYPES.AUTOMATED_OUTCOME}-icon`}
                />
            </IconContainer>
        ),
    },
    [CONVERSATION_BOT_TYPES.TALKTRACK]: {
        type: CONVERSATION_BOT_TYPES.TALKTRACK,
        icon: (
            <IconContainer sx={{ background: '#fff' }} key={CONVERSATION_BOT_TYPES.TALKTRACK}>
                <DigitalAssistantIconWithGradientColor
                    sx={{ fontSize: 12 }}
                    data-testid={`${CONVERSATION_BOT_TYPES.TALKTRACK}-icon`}
                />
            </IconContainer>
        ),
    },
    [CONVERSATION_BOT_TYPES.SEQUENCE]: {
        type: CONVERSATION_BOT_TYPES.SEQUENCE,
        icon: (
            <IconContainer sx={{ background: '#5F2BE1' }} key={CONVERSATION_BOT_TYPES.SEQUENCE}>
                <SequenceArrowIcon
                    sx={{ fontSize: 12 }}
                    data-testid={`${CONVERSATION_BOT_TYPES.SEQUENCE}-icon`}
                />
            </IconContainer>
        ),
    },
};

export const getTooltipMessage = (bots) => {
    const sequenceBot = bots.find((bot) => bot.botType === CONVERSATION_BOT_TYPES.SEQUENCE);
    const hasSequence = sequenceBot && sequenceBot.botStatus === CONVERSATION_BOT_STATUS.ACTIVE;

    const talkTrackBot = bots.find((bot) => bot.botType === CONVERSATION_BOT_TYPES.TALKTRACK);
    const hasTalkTrack = talkTrackBot && talkTrackBot.botStatus === CONVERSATION_BOT_STATUS.ACTIVE;

    const automatedOutcomeBot = bots.find(
        (bot) => bot.botType === CONVERSATION_BOT_TYPES.AUTOMATED_OUTCOME
    );
    const hasAutomatedOutcome =
        automatedOutcomeBot && automatedOutcomeBot.botStatus === CONVERSATION_BOT_STATUS.ACTIVE;

    if (hasTalkTrack && hasSequence) {
        return 'Enrolled in Digital Assistant and Sequences';
    } else if (hasSequence) {
        return 'Enrolled in Sequences';
    } else if (hasTalkTrack) {
        return 'Enrolled in Digital Assistant';
    } else if (hasAutomatedOutcome) {
        return 'Enrolled in Automated Outcomes';
    }
    return '';
};

export function MessageBotIcons(props) {
    const { bots } = props;

    const activeBotWithDefinedConfig = bots.filter(
        (bot) => Boolean(BOT_TYPES[bot.botType]) && bot.botStatus === CONVERSATION_BOT_STATUS.ACTIVE
    );

    if (!activeBotWithDefinedConfig.length) return null;

    return (
        <Tooltip title={getTooltipMessage(activeBotWithDefinedConfig)}>
            <Container>
                {activeBotWithDefinedConfig.map((bot) => BOT_TYPES[bot.botType]?.icon)}
            </Container>
        </Tooltip>
    );
}

MessageBotIcons.propTypes = {
    bots: PropTypes.arrayOf(PropTypes.object),
};
