import MuiSwitch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';

export const Switch = styled(MuiSwitch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        '&:hover': {
            backgroundColor: 'unset',
        },
        '&.Mui-checked': {
            '&:hover': {
                backgroundColor: 'unset',
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: theme.palette.common.white,
                '&::before': {
                    background: theme.palette.common.matchInputGradient,
                    opacity: 1,
                },
            },
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 0.12,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.32), 0px 2px 6px rgba(0, 0, 0, 0.15)',
        '&::before': {
            content: '""',
            display: 'block',
            borderRadius: '50%',
            backgroundColor: theme.palette.text.primary,
            opacity: 0.12,
            width: '100%',
            height: '100%',
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: theme.palette.text.primary,
        opacity: 0.24,
    },
}));

export const DarkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            '& .MuiSwitch-thumb': {
                backgroundColor: theme.palette.primary.main,
                '&::before': {
                    background: theme.palette.primary.main,
                    opacity: 1,
                },
            },
            '& + .MuiSwitch-track': {
                background: alpha(theme.palette.common.white, 0.12),
                opacity: 1,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.common.blueGray,
        '&::before': {
            opacity: 1,
            backgroundColor: theme.palette.common.blueGray,
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: alpha(theme.palette.common.charcoal, 0.76),
        opacity: 1,
    },
}));
