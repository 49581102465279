import { useContext } from 'react';

import { SwitchboardContext } from '../SwitchboardProvider';

export function useSwitchboard(featureName) {
    const switchboard = useContext(SwitchboardContext);

    if (featureName) {
        if (switchboard.features.__FEATURE_EVERYTHING__) return true;
        return !!switchboard.features[featureName]; // returns a boolean
    }
    return switchboard; // returns an object
}

export function useNovaEnv() {
    const switchboard = useContext(SwitchboardContext);
    return switchboard.novaEnv;
}
