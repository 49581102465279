import { useMutation, useQuery, useQueries } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak/web';

const generateHeaders = (token) => {
    return { Authorization: `Bearer ${token}` };
};

const refreshToken = (keycloak, minValidity) => {
    return new Promise((resolve, reject) => {
        keycloak
            .updateToken(minValidity)
            .then((e) => {
                resolve(keycloak.token);
            })
            .catch(() => {
                reject();
            });
    });
};

/**
 * Modified the mutate function to also pass along the keycloak token as an auth header
 */
export const useCustomMutation = (mutateFn, options) => {
    const { keycloak } = useKeycloak();
    const { mutate, mutateAsync, ...rest } = useMutation(mutateFn, { ...options });

    return {
        mutate: async (params, mutationOptions) => {
            const token = await refreshToken(keycloak, 20);
            const headers = generateHeaders(token);
            return mutate({ ...params, headers }, { ...mutationOptions });
        },
        mutateAsync: async (params, mutationOptions) => {
            const token = await refreshToken(keycloak, 20);
            const headers = generateHeaders(token);
            return mutateAsync({ ...params, headers }, { ...mutationOptions });
        },
        ...rest,
    };
};

export const useGetQueryHeader = () => {
    const { keycloak } = useKeycloak();
    return {
        getHeader: async () => {
            const token = await refreshToken(keycloak, 20);
            const headers = generateHeaders(token);
            return headers;
        },
    };
};

/**
 * Passes the keycloak token as an auth header to the queryFn
 */
export const useCustomQuery = (queryKey, queryFn, options) => {
    const { keycloak, initialized } = useKeycloak();

    return useQuery({
        ...options,
        queryKey: queryKey,
        queryFn: async (cxt) => {
            const token = await refreshToken(keycloak, 20);
            const headers = generateHeaders(token);
            return queryFn({ ...cxt, headers });
        },
        enabled: options?.disabled === undefined ? initialized : options.disabled,
        meta: { token: keycloak.token },
    });
};

export const useCustomQueries = (queryArr, options) => {
    const { keycloak, initialized } = useKeycloak();
    return useQueries({
        queries: queryArr.map((query) => {
            return {
                queryKey: [query.key, query.params],
                queryFn: async (cxt) => {
                    const token = await refreshToken(keycloak, 20);
                    const headers = generateHeaders(token);
                    return query.queryFn({ ...cxt, headers });
                },
            };
        }),
        enabled: options.disabled === undefined ? initialized : options.disabled,
        meta: { token: keycloak.token },
    });
};

export const QUERY_KEY = {
    ENROLEES: 'enrollees',
    SEQUENCES: 'sequences',
    METRICS: 'metrics',
    CONTACTS_INFO: 'ContactsInfo',
    USER_KNOWLEDGEBASE: 'userKnowledgeBase',
    TALK_TRACK_ENROLLEES: 'talkTrackenrollees',
    TALENTS_CONTACT_SETTINGS: 'TalentsContactSettings',
    ENROLLMENT_STATUS: 'EnrollmentStatus',
    TALENTS: 'Talents',
    TALK_TRACKS: 'talkTracks',
};
