import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { NotesCanvas } from 'features/TalentNotes/NotesDrawer/components/NotesCanvas';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { SortNotesActions } from 'features/TalentNotes/NotesDrawer/components/SortNotesActions';

export function AllTalentNotes(props) {
    const {
        talentId,
        mentionEditorRef,
        notes,
        updateNotes,
        handleOptimisticDelete,
        isLoading,
        notesSourceConfig,
        isInProfileDrawer,
        showNotesFilter,
    } = props;
    const { source, sourceId } = notesSourceConfig || {};
    const loggedInUser = useSelector((state) => state.user);

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    height: '200px',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress size={20} data-testid="loading-notes" />
            </Box>
        );
    }

    return (
        <>
            {showNotesFilter && <SortNotesActions isInProfileDrawer={isInProfileDrawer} />}
            <NotesCanvas
                notes={notes}
                handleOptimisticDelete={handleOptimisticDelete}
                loggedInUser={loggedInUser}
                mentionEditorRef={mentionEditorRef}
                updateNotes={updateNotes}
                talentId={talentId}
                isInProfileDrawer={isInProfileDrawer}
                source={source}
                sourceId={sourceId}
            />
        </>
    );
}

AllTalentNotes.propTypes = {
    talentId: PropTypes.string,
    mentionEditorRef: PropTypes.object,
    ttId: PropTypes.object,
    isLoading: PropTypes.bool,
    notes: PropTypes.array,
    updateNotes: PropTypes.func,
    handleOptimisticDelete: PropTypes.func,
    notesSourceConfig: PropTypes.object,
    isInProfileDrawer: PropTypes.bool,
    showNotesFilter: PropTypes.bool,
};
