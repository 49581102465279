import SvgIcon from '@mui/material/SvgIcon';

export function ReportContactIcon(props) {
    return (
        <SvgIcon viewBox="0 0 14 14" {...props}>
            <path
                d="M7.02453 8.70084C6.63957 8.70084 6.3246 8.38587 6.3246 8.00091V5.19418C6.3246 4.80922 6.63957 4.49425 7.02453 4.49425C7.4095 4.49425 7.72447 4.80922 7.72447 5.19418V8.00091C7.72447 8.38587 7.4095 8.70084 7.02453 8.70084Z"
                fill="currentColor"
            />
            <path
                d="M7.02453 10.8005C7.41109 10.8005 7.72447 10.4871 7.72447 10.1005C7.72447 9.71399 7.41109 9.40061 7.02453 9.40061C6.63797 9.40061 6.3246 9.71399 6.3246 10.1005C6.3246 10.4871 6.63797 10.8005 7.02453 10.8005Z"
                fill="currentColor"
            />
            <path
                d="M7.01049 2.07943C7.15748 2.07943 7.53545 2.12142 7.75943 2.50639L12.491 10.6046C12.7219 10.9966 12.568 11.3465 12.491 11.4725C12.414 11.5985 12.19 11.9065 11.735 11.9065H2.27895C1.824 11.9065 1.60002 11.5985 1.52302 11.4725C1.44603 11.3395 1.29904 10.9966 1.52302 10.6046L6.25456 2.50639C6.47854 2.12142 6.85651 2.07943 7.0035 2.07943H7.01049ZM7.0035 0.679565C6.24057 0.679565 5.48464 1.05753 5.04368 1.80645L0.312143 9.90468C-0.569772 11.4165 0.522125 13.3204 2.27196 13.3204H11.728C13.4779 13.3204 14.5698 11.4235 13.6879 9.90468L8.9563 1.80645C8.51535 1.05753 7.75943 0.679565 6.9965 0.679565H7.0035Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
