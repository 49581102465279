import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';

import { TextInput } from 'components/Inputs';
import { Typography } from 'components/Typography';
import { setValidation, updatePayload } from 'views/UserSettings/features/Scheduling';
import { useEffect } from 'react';
import { validateUrl } from 'utils/validation/url';

const MIN_CHARACTERS = 6;
const MAX_CHARACTERS = 128;

export function EventInfoPanel() {
    const id = useSelector((state) => state.schedule.payload.id);
    const pageTitle = useSelector((state) => state.schedule.payload.pageTitle);
    const externalUrl = useSelector((state) => state.schedule.payload.externalUrl);
    const schedules = useSelector((state) => state.schedule.schedules);
    const validation = useSelector((state) => state.schedule.validation);

    const dispatch = useDispatch();

    const handleTitle = (event) => {
        dispatch(updatePayload({ pageTitle: event.target.value.trimStart() }));
    };

    const handleUrl = (event) => {
        dispatch(updatePayload({ externalUrl: event.target.value.trimStart() }));
    };

    useEffect(() => {
        const isValid = validateUrl(externalUrl);
        dispatch(
            setValidation({ externalUrl: isValid ? '' : 'Please supply a secure and valid URL.' })
        );
    }, [dispatch, externalUrl]);

    useEffect(() => {
        const sanitizedString = pageTitle.trim();
        const sanitizedLength = sanitizedString.length;
        let isValid = sanitizedLength >= MIN_CHARACTERS && sanitizedLength <= MAX_CHARACTERS;
        let errorMessage;
        if (!isValid)
            errorMessage = `Text must be between ${MIN_CHARACTERS} and ${MAX_CHARACTERS} characters.`;

        isValid = !schedules.some(
            (schedule) =>
                schedule.pageTitle.toLowerCase() === sanitizedString.toLowerCase() &&
                id !== schedule.id
        );
        if (!isValid) errorMessage = 'This schedule name already exists.';

        dispatch(setValidation({ pageTitle: errorMessage }));
    }, [dispatch, id, schedules, pageTitle]);

    return (
        <Box
            sx={{
                p: 3,
                display: 'flex',
                flex: '0 0 100%',
                boxSizing: 'border-box',
                flexDirection: 'column',
            }}
        >
            <Typography variant="titleMedium">Display Text</Typography>
            <TextInput
                value={pageTitle}
                onChange={handleTitle}
                fullWidth
                error={Boolean(pageTitle) && Boolean(validation.pageTitle)}
                helperText={validation.pageTitle}
                inputProps={{
                    'aria-label': 'schedule-name',
                    minLength: MIN_CHARACTERS,
                    maxLength: MAX_CHARACTERS,
                }}
                placeholder="Ex: “Book time with a recruiter” or “Schedule 30 min meeting”"
            />

            <Typography variant="titleMedium" sx={{ mt: 5 }}>
                URL
            </Typography>
            <TextInput
                value={externalUrl}
                onChange={handleUrl}
                type="url"
                fullWidth
                error={Boolean(externalUrl) && Boolean(validation.externalUrl)}
                helperText={validation.externalUrl}
                inputProps={{ 'aria-label': 'schedule-location' }}
                placeholder="https://nebula.io"
            />
        </Box>
    );
}
