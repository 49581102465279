import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { CloseIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { MessageHistory } from '../MessageContent/MessageHistory';

export function DrawerHeader(props) {
    const { onClose } = props;

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pb: 2 }}>
                <Typography variant="headline" color="text.primary">
                    Message
                </Typography>
                <IconButton
                    aria-label="close-message-drawer"
                    onClick={onClose}
                    sx={{ ml: 'auto', mt: 1, p: 0 }}
                >
                    <CloseIcon sx={{ color: 'common.charcoal', opacity: 0.6, fontSize: 24 }} />
                </IconButton>
            </Box>
            <MessageHistory />
        </Box>
    );
}

DrawerHeader.propTypes = {
    onClose: PropTypes.func,
};
