import { useEffect, useState } from 'react';
import { useGetMessageTemplatesQuery } from 'services/conversations';

export const DEFAULT_DECLINE_TEXT =
    'To opt out of future messages, simply type ‘Decline’ anytime. The following message, sent via Nebula on behalf of a recruiter is regarding an employment opportunity:';

export function useDeclineText(props) {
    const { hasDeclineText } = props;
    const [declineText, setDeclineText] = useState(null);

    const { data: declineTextTemplate, isLoading } = useGetMessageTemplatesQuery(
        {
            name: 'Prepended Opt-out Text Template',
            templateType: 'system_template',
        },
        {
            skip: !hasDeclineText,
        }
    );

    useEffect(() => {
        if (hasDeclineText && !isLoading && declineTextTemplate?.length) {
            setDeclineText(declineTextTemplate[0]?.body || DEFAULT_DECLINE_TEXT);
        }
    }, [declineTextTemplate, hasDeclineText, isLoading]);

    return {
        declineText,
        isLoading,
    };
}
