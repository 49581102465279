import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import { MESSAGE_TYPES } from 'utils/constants';
import { EmailIcon, TextIcon } from 'components/Icons';

const ChannelButton = styled(ToggleButton)(({ theme }) => ({
    background: theme.palette.common.white,
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    padding: theme.spacing(0.5),
    borderRadius: '100%',
    '&:hover': {
        background: theme.palette.success.main,
        color: theme.palette.common.white,
    },
    '&:first-of-type': { marginRight: theme.spacing(0.5) },
    '&.Mui-selected': {
        background: theme.palette.success.main,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.common.white,
            color: theme.palette.success.main,
        },
    },
}));

export function ChannelToggleButton(props) {
    const { type, selected, onChange, ...restProps } = props;

    return (
        <ChannelButton
            value="selected"
            aria-label={`channel-toggle-${type}`}
            selected={selected}
            onChange={() => onChange?.(type)}
            {...restProps}
        >
            {type === MESSAGE_TYPES.EMAIL && <EmailIcon sx={{ fontSize: 16 }} />}
            {type === MESSAGE_TYPES.SMS && <TextIcon sx={{ fontSize: 16 }} />}
        </ChannelButton>
    );
}

ChannelToggleButton.propTypes = {
    type: PropTypes.string,
    selected: PropTypes.bool,
    onChange: PropTypes.func,
};
