import Box from '@mui/material/Box';
import { alpha, lighten } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';

import { InfoIcon } from 'components/Icons';
import { Tooltip } from 'components/Tooltip';

export const InputProps = {
    variant: 'bodySmall',
    sx: {
        my: 1,
        '&.MuiInputBase-root': {
            color: (theme) => theme.palette.common.white,
            backgroundColor: '#19222F', // this is an opaque version of alpha(theme.palette.common.charcoal, 0.48)
            border: 'none',
        },
    },
};

export const AutocompleteSx = {
    '& .MuiAutocomplete-input': {
        padding: 0,
    },
    '& .MuiAutocomplete-inputRoot': {
        mt: 0.25,
        mb: 0,
        backgroundColor: 'text.primary',
        color: 'common.white',
        borderRadius: 0.25,
    },
    '& .MuiOutlinedInput-root': {
        padding: 0.5,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: (theme) => `1px solid ${lighten(theme.palette.text.primary, 0.05)}`,
        borderRadius: '4px 0 0 4px',
    },
    '& .MuiInputBase-root': {
        '&:focus-within': {
            borderColor: 'text.primary',
        },
    },
};

export const TextInputProps = {
    sx: AutocompleteSx,
    InputProps,
};

export const AnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};

export const TransformOrigin = {
    vertical: 'top',
    horizontal: 'left',
};

// eslint-disable-next-line react/prop-types
export function InfoTextInputProps({ tooltipTitle }) {
    return {
        ...TextInputProps,
        InputProps: {
            ...TextInputProps.InputProps,
            endAdornment: (
                <InputAdornment
                    position="end"
                    sx={{
                        ml: 0,
                    }}
                >
                    <Tooltip title={tooltipTitle} placement="bottom-end">
                        <Box
                            sx={{
                                display: 'flex',
                                position: 'absolute',
                                right: 0,
                            }}
                        >
                            <InfoIcon
                                sx={{
                                    mr: 1,
                                    cursor: 'pointer',
                                    color: (theme) => alpha(theme.palette.common.white, 0.48),
                                }}
                            />
                        </Box>
                    </Tooltip>
                    {TextInputProps.InputProps.endAdornment}
                </InputAdornment>
            ),
        },
    };
}
