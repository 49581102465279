import ButtonBase from '@mui/material/ButtonBase';
import { alpha, styled } from '@mui/material/styles';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogContentText from '@mui/material/DialogContentText';

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
    padding: theme.spacing(4),
}));

export const DialogContentText = styled(MuiDialogContentText)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0.1,
    textAlign: 'center',
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
    padding: theme.spacing(2, 4),
}));

export const DialogButton = styled(ButtonBase)(({ theme }) => ({
    flex: 1,
    boxSizing: 'border-box',
    height: theme.spacing(5),
    minWidth: theme.spacing(10),
    textTransform: 'unset',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
    '&.Mui-disabled': {
        boxShadow: theme.shadows[0],
        borderColor: theme.palette.action.disabledBackground,
        backgroundColor: theme.palette.action.disabledBackground,
    },
    '& > .MuiTypography-root': {
        fontWeight: 500,
        color: theme.palette.common.white,
    },
    '&.Mui-disabled > span': {
        color: theme.palette.action.disabled,
    },
}));

export const DialogButtonSecondary = styled(DialogButton)(({ theme }) => ({
    boxShadow: theme.shadows[0],
    backgroundColor: 'transparent',
    border: `1px solid ${alpha(theme.palette.text.primary, 0.48)}`,
    '&.Mui-disabled': {
        borderColor: theme.palette.action.disabledBackground,
        backgroundColor: 'transparent',
    },
    '& > .MuiTypography-root': {
        color: theme.palette.text.primary,
    },
}));
