import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { EditIcon, SendMessageIcon, ViewIcon } from 'components/Icons';
import { Typography, TYPOGRAPHY_VARIANTS } from 'components/Typography';
import { editSchedule } from 'views/UserSettings/features/Scheduling';

const Card = styled(Box)(({ theme }) => ({
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    width: theme.spacing(49),
    maxWidth: theme.spacing(49),
    padding: theme.spacing(2),
    minHeight: theme.spacing(16),
    margin: theme.spacing(0.5, 0.25),
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0.5, 'auto', 0.5, 'auto'),
        padding: theme.spacing(1),
        width: '100%',
        maxWidth: '100%',
    },
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);',
    backgroundColor: '#ffffff',
}));

const Content = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const ActionButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: 0.1,
    fontStyle: 'normal',
    marginLeft: 'auto',
    textTransform: 'none',
    color: theme.palette.primary.main,
}));

export function ScheduleSummary(props) {
    const { id, pageTitle, externalUrl } = props;

    const dispatch = useDispatch();

    const handleEdit = useCallback(() => {
        dispatch(editSchedule({ payload: { pageTitle, externalUrl, id } }));
    }, [dispatch, pageTitle, externalUrl, id]);

    const Component = externalUrl ? Link : Typography;
    const ComponentProps = externalUrl
        ? {
              sx: TYPOGRAPHY_VARIANTS.bodyBase,
              href: externalUrl,
              underline: 'none',
              target: '_blank',
              noWrap: true,
          }
        : { variant: 'bodyBase', noWrap: true };

    return (
        <Card>
            <Content>
                <Typography variant="smallHeadline" noWrap>
                    {pageTitle}
                </Typography>
            </Content>
            <Content>
                <Component {...ComponentProps}>{externalUrl || 'Location TBD'}</Component>
            </Content>
            <Content sx={{ mt: 2 }}>
                <ActionButton
                    startIcon={<SendMessageIcon />}
                    aria-label="send-schedule-invite"
                    sx={{ display: 'none' }}
                    disableFocusRipple
                    disableRipple
                >
                    Send Invite
                </ActionButton>
                <ActionButton
                    startIcon={<ViewIcon />}
                    aria-label="view-schedule"
                    sx={{ display: 'none' }}
                    disableFocusRipple
                    disableRipple
                >
                    View
                </ActionButton>
                <ActionButton
                    startIcon={<EditIcon />}
                    onClick={handleEdit}
                    aria-label="edit-schedule"
                    disableFocusRipple
                    disableRipple
                >
                    Edit
                </ActionButton>
            </Content>
        </Card>
    );
}

ScheduleSummary.propTypes = {
    id: PropTypes.number,
    pageTitle: PropTypes.string,
    externalUrl: PropTypes.string,
};
