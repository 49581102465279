import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

const WaveSkeleton = styled(Skeleton, {
    shouldForwardProp: (prop) => prop !== 'removeTransform',
})(({ removeTransform, variant }) => ({
    backgroundColor: 'rgba(45, 141, 211, 0.11)',
    ...(variant === 'text' && {
        borderRadius: 0,
    }),
    ...(removeTransform && {
        transformOrigin: '0 0',
        transform: 'scale(1)',
    }),
}));

export function LoadingPlaceholder(props) {
    const { children, isLoading, isPlaceholder, removeTransform, ...restProps } = props;

    if (isLoading || (isPlaceholder && !children)) {
        return (
            <WaveSkeleton
                animation="wave"
                height={32}
                removeTransform={removeTransform}
                {...restProps}
            />
        );
    }

    if (!children) {
        return null;
    }

    return children;
}

LoadingPlaceholder.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    isLoading: PropTypes.bool,
    isPlaceholder: PropTypes.bool,
    removeTransform: PropTypes.bool,
};
