import { usePicker } from 'features/AttachmentUpload/hooks';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { MESSAGE_TYPES } from 'utils/constants';

export function useAddMediaModal({ setAttachments, messageType }) {
    const user = useSelector((state) => state.user);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpen = () => {
        setIsModalOpen(true);
    };

    const [handleInitiateUpload] = usePicker(
        user,
        setAttachments,
        '',
        '',
        messageType || MESSAGE_TYPES.EMAIL,
        handleOpen
    );

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const handleRemoveAttachment = useCallback(
        (removedAttachmentId) => {
            setAttachments((attachments) =>
                attachments.filter((attachment) => attachment.handle !== removedAttachmentId)
            );
        },
        [setAttachments]
    );

    const handleOpenPicker = useCallback(
        (currentFilesCount) => {
            handleInitiateUpload(currentFilesCount, 5)();
        },
        [handleInitiateUpload]
    );

    const clearAttachments = useCallback(() => {
        setAttachments([]);
    }, [setAttachments]);

    return {
        isModalOpen,
        handleOpen,
        handleClose,
        handleOpenPicker,
        clearAttachments,
        handleRemoveAttachment,
    };
}
