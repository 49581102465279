import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { useInView } from 'react-intersection-observer';

import { getInitials } from 'utils/formatters';
import { MessageContent, MessageSubInfo } from './components';
import { Edit } from 'views/Communications/features/Compose/Edit';
import { ExpandableCard, useExpandableCard } from 'components/ExpandableCard';
import { resetMessageMatchText, scrollToMessage } from 'views/Communications/communicationsSlice';
import { useUpdateMessageStatusMutation } from 'services/conversations';
import { THREAD_COLUMN_PROPS } from 'views/Communications/utils/constants';
import { Typography } from 'components/Typography';

const collapsedSize = 240;

const CardBackground = styled(Paper, {
    shouldForwardProp: (prop) =>
        prop !== 'isUser' && prop !== 'collapsed' && prop !== 'isScheduledMessage',
})(({ theme, isUser, collapsed, backgroundColor }) => ({
    position: 'relative',
    p: 0,
    borderRadius: `
        16px
        16px
        ${isUser || !collapsed ? '0px' : '16px'}
        ${!isUser || !collapsed ? '0px' : '16px'}
        `,
    [`borderBottom${isUser ? 'Right' : 'Left'}Radius`]: 0,
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.32), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    background: backgroundColor,
}));

const ConversationAvatar = styled(Avatar)(({ theme }) => ({
    alignSelf: 'flex-end',
    textDecoration: 'none',
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginLeft: theme.spacing(2),
    background:
        'linear-gradient(180deg, rgba(65, 232, 211, 0) 0%, rgba(65, 232, 211, 0.7) 100%), #3A8DD3',
    '&:hover': {
        cursor: 'pointer',
    },
}));

const Container = styled(Box)(({ theme }) => ({
    ...THREAD_COLUMN_PROPS.CONTAINER(theme),
}));

const Main = styled(Box)(({ theme }) => ({
    ...THREAD_COLUMN_PROPS.MAIN(theme),
}));

const Side = styled(Box)(({ theme }) => ({
    ...THREAD_COLUMN_PROPS.SIDE(theme),
    display: 'flex',
    alignItems: 'end',
}));

const Root = styled(Box)(({ theme }) => ({
    ...THREAD_COLUMN_PROPS.ROOT(theme),
}));

function AvatarWrapper(props) {
    const {
        avatarUrl,
        isUser,
        isDigitalAssistantMessage,
        isSequenceMessage,
        name,
        talentId,
        children,
    } = props;
    if (isDigitalAssistantMessage) {
        return (
            <Container>
                <Side />
                <Main>{children}</Main>
                <Side>
                    <ConversationAvatar
                        data-testid="rey-avatar"
                        sx={{
                            mb: 3.75,
                            background: 'linear-gradient(90.28deg, #504CE0 0.17%, #318DDE 99.72%)',
                        }}
                    >
                        <Typography sx={{ textAlign: 'center', fontSize: 12 }} variant="titleSmall">
                            Digital Assistant
                        </Typography>
                    </ConversationAvatar>
                </Side>
            </Container>
        );
    }
    if (isSequenceMessage) {
        return (
            <Container>
                <Side />
                <Main>{children}</Main>
                <Side>
                    <ConversationAvatar
                        data-testid="sequence-avatar"
                        sx={{
                            mb: 3.75,
                            background: '5F2BE11F',
                        }}
                    >
                        {getInitials(name, '')}
                    </ConversationAvatar>
                </Side>
            </Container>
        );
    }
    if (!isUser) {
        return (
            <Container>
                <Side sx={{ justifyContent: 'end' }}>
                    <ConversationAvatar
                        component="a"
                        sx={{ ml: 0, mr: 2, mb: 3.75 }}
                        src={avatarUrl}
                        aria-label="open-talent-in-new-tab"
                        data-trackid="conversation-talent-avatar"
                        href={`/talent/${talentId}`}
                        target="_blank"
                    >
                        {getInitials(name, '')}
                    </ConversationAvatar>
                </Side>
                <Main>{children}</Main>
                <Side />
            </Container>
        );
    }
    if (isUser) {
        return (
            <Container>
                <Side />
                <Main>{children}</Main>
                <Side>
                    <ConversationAvatar sx={{ mb: 3.75 }} data-trackid="conversation-user-avatar">
                        {getInitials(name, '')}
                    </ConversationAvatar>
                </Side>
            </Container>
        );
    }

    return null;
}

AvatarWrapper.propTypes = {
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    isUser: PropTypes.bool,
    isDigitalAssistantMessage: PropTypes.bool,
    isSequenceMessage: PropTypes.bool,
    talentId: PropTypes.string,
    children: PropTypes.node,
};

export function Message(props) {
    const {
        isRead: initialIsRead,
        isUser,
        isScheduledMessage,
        isDigitalAssistantMessage,
        isSequenceMessage,
        firstMessage,
        avatarUrl,
        subject,
        message,
        media,
        messageId,
        conversationId,
        messageType,
        sendDate,
        sendType,
        name,
        talentId,
        getMessagePage,
        getInitialMessages,
        status,
        sender,
        recipient,
        contactTraversal,
        talent,
    } = props;

    const {
        containerRef,
        contentRef,
        collapsed,
        isCollapsable,
        displayExpandButton,
        handleExpand,
        handleCollapse,
        resetScroll,
    } = useExpandableCard();

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const shouldScrollToMessage = useSelector(
        (state) => state.communications.shouldScrollToMessage
    );
    const isMatchingMessages = useSelector((state) => state.communications.isMatchingMessages);
    const selectedMessageId = useSelector((state) => state.communications.selectedMessageId);

    const [isRead, setIsRead] = useState(initialIsRead);
    const [isEditing, setIsEditing] = useState(false);

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    const [updateMessageStatus, result] = useUpdateMessageStatusMutation();
    const { isFetching } = result;

    const handleSearchedMessageClick = () => {
        getMessagePage(messageId);
        dispatch(resetMessageMatchText());
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const handleFinishEdit = () => {
        setIsEditing(false);
        getInitialMessages();
    };

    const handleEditMessage = () => {
        setIsEditing(true);
    };

    const handleMessageScroll = useCallback(() => {
        containerRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
        dispatch(scrollToMessage({ shouldScrollToMessage: false, messageId: '' }));
    }, [containerRef, dispatch]);

    const setEmailAsRead = useCallback(async () => {
        if (!isRead && !isFetching && inView) {
            const payload = { messageId, messageType, body: { isRead: true } };
            const res = await updateMessageStatus(payload);
            if (res?.error) toast.error('Message could not be marked as read, please try again');
            else setIsRead(true);
        }
    }, [messageId, messageType, isFetching, isRead, inView, updateMessageStatus]);

    useEffect(() => {
        if (firstMessage) {
            containerRef?.current?.scrollIntoView?.();
        }
    }, [conversationId, firstMessage, containerRef]);

    useEffect(() => {
        if ((containerRef, shouldScrollToMessage && messageId === selectedMessageId)) {
            handleMessageScroll();
        }
    }, [containerRef, shouldScrollToMessage, messageId, selectedMessageId, handleMessageScroll]);

    useEffect(() => {
        setEmailAsRead();
    }, [setEmailAsRead]);

    const backgroundColor = (() => {
        if (isDigitalAssistantMessage) {
            return 'linear-gradient(90.28deg, rgba(80, 76, 224, 0.12) 0.17%, rgba(49, 141, 222, 0.12) 99.72%)';
        }
        if (isSequenceMessage) {
            return '#5F2BE11F';
        }
        if (isScheduledMessage) {
            return 'rgba(243, 248, 253, 1)';
        }
        return 'fff';
    })();

    return (
        <>
            <Box
                ref={ref}
                component="div"
                sx={{
                    display: 'flex',
                    height: 'fit-content',
                    mt: 2,
                    width: '100%',
                }}
            >
                <Box sx={{ display: 'flex', width: '100%' }}>
                    {!isEditing && (
                        <AvatarWrapper
                            avatarUrl={avatarUrl}
                            isUser={isUser}
                            isDigitalAssistantMessage={isDigitalAssistantMessage}
                            isScheduledMessage={isScheduledMessage}
                            isSequenceMessage={isSequenceMessage}
                            name={isUser || isSequenceMessage ? user.displayName : name}
                            talentId={talentId}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <ExpandableCard
                                    ref={containerRef}
                                    collapsedSize={!isCollapsable ? 'auto' : collapsedSize}
                                >
                                    <CardBackground
                                        elevation={0}
                                        isUser={isUser}
                                        collapsed={collapsed}
                                        backgroundColor={backgroundColor}
                                    >
                                        <MessageContent
                                            ref={contentRef}
                                            isUser={isUser}
                                            collapsed={collapsed}
                                            firstMessage={firstMessage}
                                            isCollapsable={isCollapsable}
                                            displayExpandButton={displayExpandButton}
                                            subject={subject}
                                            message={message}
                                            media={media}
                                            onExpand={handleExpand}
                                            onCollapse={() => handleCollapse(resetScroll)}
                                        />
                                    </CardBackground>
                                </ExpandableCard>
                                <MessageSubInfo
                                    status={status}
                                    sender={sender}
                                    recipient={recipient}
                                    messageType={messageType}
                                    isOutgoing={isUser}
                                    messageId={messageId}
                                    conversationId={conversationId}
                                    sendDate={sendDate}
                                    sendType={sendType}
                                    isMatchingMessages={isMatchingMessages}
                                    isScheduledMessage={isScheduledMessage}
                                    isDigitalAssistantMessage={isDigitalAssistantMessage}
                                    isSequenceMessage={isSequenceMessage}
                                    onJumpToMessage={handleSearchedMessageClick}
                                    onEditMessage={handleEditMessage}
                                    getInitialMessages={getInitialMessages}
                                    contactTraversal={contactTraversal}
                                    talent={talent}
                                />
                            </Box>
                        </AvatarWrapper>
                    )}
                    {isEditing && (
                        <Root sx={{ alignItems: 'center' }}>
                            <Edit
                                messageId={messageId}
                                message={message}
                                subject={subject}
                                media={media}
                                messageType={messageType}
                                onCancelEdit={handleCancelEdit}
                                onFinishEdit={handleFinishEdit}
                                MessageSubInfoProps={{
                                    status,
                                    messageType,
                                    messageId,
                                    conversationId,
                                    sendDate,
                                    isMatchingMessages,
                                    isScheduledMessage,
                                    isDigitalAssistantMessage,
                                    isSequenceMessage,
                                    getInitialMessages,
                                    onJumpToMessage: handleSearchedMessageClick,
                                    onEditMessage: handleEditMessage,
                                }}
                            />
                        </Root>
                    )}
                </Box>
            </Box>
        </>
    );
}

Message.propTypes = {
    isRead: PropTypes.bool,
    isUser: PropTypes.bool,
    firstMessage: PropTypes.bool,
    sendDate: PropTypes.string,
    sendType: PropTypes.string,
    name: PropTypes.string,
    subject: PropTypes.string,
    message: PropTypes.string,
    avatarUrl: PropTypes.string,
    messageId: PropTypes.string,
    messageType: PropTypes.string,
    getMessagePage: PropTypes.func,
    getInitialMessages: PropTypes.func,
    media: PropTypes.array,
    talentId: PropTypes.string,
    conversationId: PropTypes.string,
    isScheduledMessage: PropTypes.bool,
    isDigitalAssistantMessage: PropTypes.bool,
    isSequenceMessage: PropTypes.bool,
    status: PropTypes.string,
    sender: PropTypes.string,
    recipient: PropTypes.string,
    contactTraversal: PropTypes.array,
    talent: PropTypes.object,
};
