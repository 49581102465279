import { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { Typography } from 'components/Typography';

export const RecentTemplate = memo((props) => {
    const { templates, onClose, onUseTemplate } = props;

    return (
        <Box>
            <Typography
                variant="labelXSmall"
                sx={{ color: 'rgba(38, 55, 70, 0.72)', pl: 2, pt: 1 }}
            >
                Recent Templates
            </Typography>
            {templates?.map((template) => (
                <MenuItem
                    key={template?.templateId}
                    onClick={() => {
                        onUseTemplate(template);
                        onClose();
                    }}
                    disableRipple
                    aria-label="recent-template"
                >
                    {template?.name}
                </MenuItem>
            ))}
        </Box>
    );
});

RecentTemplate.propTypes = {
    onClose: PropTypes.func,
    onUseTemplate: PropTypes.func,
    templates: PropTypes.array,
};
