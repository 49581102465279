import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Add from '@mui/icons-material/Add';
import { Typography } from 'components/Typography';

export function SuggestedTags({ suggestedTags, onSuggestedTagClick, handle }) {
    return (
        <Box sx={{ display: 'flex' }}>
            <Typography
                variant="bodySmall"
                sx={{
                    mr: 0.5,
                    color: (theme) => alpha(theme.palette.text.primary, 0.72),
                }}
            >
                Suggested Tags:
            </Typography>
            <Box sx={{ display: 'flex' }}>
                {suggestedTags.map((suggestedTag) => (
                    <ButtonBase
                        aria-label="suggested-tag-button"
                        key={suggestedTag}
                        disableRipple
                        onClick={() => onSuggestedTagClick(handle, suggestedTag)}
                        sx={{
                            mr: 0.5,
                            height: '20px',
                            padding: '2px 8px',
                            borderRadius: '56px',
                            background: (theme) => theme.palette.background.default,
                        }}
                    >
                        <Typography
                            variant="bodyBase500"
                            sx={{
                                color: 'primary.main',
                                mr: 0.5,
                                mb: 0.5,
                            }}
                        >
                            {suggestedTag}
                        </Typography>
                        <Add
                            sx={{
                                fontSize: 12,
                                color: (theme) => alpha(theme.palette.text.primary, 0.48),
                            }}
                        />
                    </ButtonBase>
                ))}
            </Box>
        </Box>
    );
}

SuggestedTags.propTypes = {
    suggestedTags: PropTypes.array.isRequired,
    onSuggestedTagClick: PropTypes.func.isRequired,
    handle: PropTypes.string.isRequired,
};
