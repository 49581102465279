import SvgIcon from '@mui/material/SvgIcon';

export function InfoIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M7.5 8.5C7.5 8.36739 7.55268 8.24021 7.64645 8.14645C7.74021 8.05268 7.86739 8 8 8C8.13261 8 8.25979 8.05268 8.35355 8.14645C8.44732 8.24021 8.5 8.36739 8.5 8.5V10C8.5 10.1326 8.44732 10.2598 8.35355 10.3536C8.25979 10.4473 8.13261 10.5 8 10.5C7.86739 10.5 7.74021 10.4473 7.64645 10.3536C7.55268 10.2598 7.5 10.1326 7.5 10V8.5ZM8 5.75C7.80109 5.75 7.61032 5.82902 7.46967 5.96967C7.32902 6.11032 7.25 6.30109 7.25 6.5C7.25 6.69891 7.32902 6.88968 7.46967 7.03033C7.61032 7.17098 7.80109 7.25 8 7.25C8.19891 7.25 8.38968 7.17098 8.53033 7.03033C8.67098 6.88968 8.75 6.69891 8.75 6.5C8.75 6.30109 8.67098 6.11032 8.53033 5.96967C8.38968 5.82902 8.19891 5.75 8 5.75ZM3 8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3C9.32608 3 10.5979 3.52678 11.5355 4.46447C12.4732 5.40215 13 6.67392 13 8C13 9.32608 12.4732 10.5979 11.5355 11.5355C10.5979 12.4732 9.32608 13 8 13C6.67392 13 5.40215 12.4732 4.46447 11.5355C3.52678 10.5979 3 9.32608 3 8ZM8 4C6.93913 4 5.92172 4.42143 5.17157 5.17157C4.42143 5.92172 4 6.93913 4 8C4 9.06087 4.42143 10.0783 5.17157 10.8284C5.92172 11.5786 6.93913 12 8 12C9.06087 12 10.0783 11.5786 10.8284 10.8284C11.5786 10.0783 12 9.06087 12 8C12 6.93913 11.5786 5.92172 10.8284 5.17157C10.0783 4.42143 9.06087 4 8 4Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
