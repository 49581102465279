import SvgIcon from '@mui/material/SvgIcon';

export function InfinityIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M5.73135 17.6571C5.00299 17.6571 4.26269 17.5138 3.54627 17.2272C1.37314 16.3437 0.0238806 14.3377 0 11.9974V11.8541C0.0358209 9.6332 1.38508 7.69889 3.51045 6.83919C5.70747 5.95561 8.19104 6.45707 9.82687 8.11678C10.2925 8.58245 10.2925 9.34663 9.82687 9.80036C9.36119 10.266 8.59701 10.266 8.14328 9.80036C6.97313 8.61827 5.44478 8.64216 4.40598 9.06007C3.16418 9.56156 2.41194 10.6362 2.38806 11.9257C2.38806 13.6093 3.46269 14.6243 4.44179 15.0183C5.43284 15.4243 6.88955 15.4362 8.03582 14.2899L14.2687 8.02127C15.9164 6.36157 18.2925 5.88397 20.4537 6.76755C22.6149 7.65113 23.9761 9.65708 24 11.9974C23.9642 14.3735 22.6149 16.3078 20.4896 17.1556C18.2926 18.0392 15.8209 17.5377 14.1731 15.878C13.7075 15.4123 13.7075 14.6482 14.1731 14.1944C14.6388 13.7288 15.3911 13.7288 15.8567 14.1944C17.0269 15.3765 18.5552 15.3526 19.594 14.9347C20.8358 14.4332 21.5881 13.3586 21.6119 12.0691C21.6 10.3855 20.5373 9.38245 19.5582 8.97648C18.5672 8.57051 17.1105 8.55859 15.9642 9.70486L9.73134 15.9735C8.63283 17.084 7.20001 17.669 5.73135 17.669V17.6571Z"
                fill="currentColor"
                fillOpacity="0.48"
            />
        </SvgIcon>
    );
}
