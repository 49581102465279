import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';

import { TYPOGRAPHY_VARIANTS } from 'components/Typography';

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1, 1.5),
    alignItems: 'flex-end',
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: alpha(theme.palette.common.white, 0.72),
    padding: '0px 0px 4px 0px',
    '&.Mui-checked': {
        color: alpha(theme.palette.common.white, 0.72),
    },
}));

const LabelWrapper = styled(Box)(({ theme }) => ({
    borderBottom: `2px solid ${alpha(theme.palette.common.white, 0.12)}`,
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
}));

const Label = styled(InputLabel)(({ theme }) => ({
    color: alpha(theme.palette.common.white, 0.48),
    cursor: 'pointer',
    ...TYPOGRAPHY_VARIANTS.bodyBase,
}));

export function CheckboxField({ checked = false, onChange, label }) {
    return (
        <Wrapper>
            <StyledCheckbox
                inputProps={{
                    'aria-label': label,
                }}
                id={label}
                {...{ checked, onChange }}
            />
            <Box sx={{ flex: 1 }}>
                <LabelWrapper>
                    <Label htmlFor={label}>{label}</Label>
                </LabelWrapper>
            </Box>
        </Wrapper>
    );
}

CheckboxField.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    label: PropTypes.string,
};
