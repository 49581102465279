import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { DarkSwitch } from 'components/Switch';
import FormGroup from '@mui/material/FormGroup';
import ButtonBase from '@mui/material/ButtonBase';
import { Typography } from 'components/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OpenLinkInNewTabIcon } from 'components/Icons';
import { USER_NAVIGATION } from 'views/UserSettings/utils/constants';
import { toggleShowAllHiddenTalents } from 'views/Search/searchSlice';

const Button = styled(ButtonBase)(({ theme }) => ({
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    color: theme.palette.primary.main,
}));

const SwitchLabelWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -0.4,
}));

export function ShowAllHiddenProfiles() {
    const dispatch = useDispatch();

    const showAllHiddenProfiles = useSelector((state) => state.search.showAllHiddenTalents);
    const handleToggleShowAllHiddenTalents = () => {
        dispatch(toggleShowAllHiddenTalents());
    };

    return (
        <Box sx={{ mt: 1 }}>
            <FormGroup>
                <FormControlLabel
                    control={
                        <DarkSwitch
                            checked={showAllHiddenProfiles}
                            onChange={handleToggleShowAllHiddenTalents}
                        />
                    }
                    disableTypography
                    label={
                        <SwitchLabelWrapper>
                            <Typography variant="bodySmall" color="common.white">
                                Show &nbsp;
                            </Typography>
                            <Button
                                component={Link}
                                to={`/user-settings#${USER_NAVIGATION.HIDDEN_PROFILES.navId}`}
                                target="_blank"
                                disableRipple
                                aria-label="link-to-manage-hidden-talent"
                            >
                                <Typography variant="bodySmall">Hidden Profiles</Typography>
                                <OpenLinkInNewTabIcon sx={{ fontSize: 12, mx: 0.5, mb: -0.4 }} />
                            </Button>
                        </SwitchLabelWrapper>
                    }
                />
            </FormGroup>
        </Box>
    );
}
