import { useMemo, useCallback, useEffect } from 'react';

import { useTalentProfileStore } from './store';
import { useGetTalentProfilesQuery } from 'services/talent';
import { useGetContactMetadataQuery } from 'services/contacts';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { handleCloseMessages, handleMinimized } from 'features/MessageDrawer/messageSlice';

export function useTalentProfileDrawer(props) {
    const { inMessageDrawer } = props || {};
    const {
        isOpen,
        setOpen,
        setClosed,
        talentId,
        config,
        messageTalentIds,
        currentTalentIdIndex,
        setNextTalentId,
        setPreviousTalentId,
        setMessageTalentIds,
        clearMessageTalents,
        selectedProfile: talentProfile,
        profiles,
        setTalentProfiles,
        setSelectedTalentProfile,
        initialProfileId,
        setInitialProfileId,
        setShowTalentProfile,
    } = useTalentProfileStore((state) => state);
    const { showTalentProfile } = useSelector((state) => state.message);

    const dispatch = useDispatch();

    const shouldFetchData = Boolean((isOpen && talentId) || showTalentProfile);

    const {
        data: talentProfiles,
        isFetching,
        isError: getProfileErr,
    } = useGetTalentProfilesQuery(
        {
            talentId,
            params: {
                sortByCompletenessScore: 'desc',
                sortByLastUpdated: 'desc',
            },
        },
        {
            skip: !shouldFetchData,
        }
    );
    const {
        data: contactMeta,
        isFetching: isFetchingContactMeta,
        isError: isContactMetaError,
    } = useGetContactMetadataQuery([talentId], { skip: !shouldFetchData });

    useEffect(() => {
        return () => {
            if (inMessageDrawer) {
                setSelectedTalentProfile(null);
                setInitialProfileId(null);
            }
        };
    }, []);

    useEffect(() => {
        if (getProfileErr) {
            toast.error('Failed to load Talent Profile.');
        }
    }, [getProfileErr]);

    useEffect(() => {
        if (isContactMetaError) {
            toast.error('Failed to load Contact Data.');
        }
    }, [isContactMetaError]);

    useEffect(() => {
        if (isFetching || !talentProfiles) return;

        setTalentProfiles(
            talentProfiles.results,
            talentProfiles.results.find((prf) => prf.profileId === initialProfileId)
        );
    }, [
        setTalentProfiles,
        isFetching,
        talentProfiles,
        setSelectedTalentProfile,
        setInitialProfileId,
        initialProfileId,
    ]);

    const openProfileDrawer = useCallback(
        (talentId, config, talentIds = [], profileId) => {
            setInitialProfileId(profileId);
            setOpen(talentId, config);
            if (talentIds.length > 1) {
                setMessageTalentIds(talentIds, talentId, config);
            }
            if (!inMessageDrawer) {
                dispatch(handleCloseMessages());
                dispatch(handleMinimized(false));
            }
        },
        [setOpen, setMessageTalentIds, setInitialProfileId, inMessageDrawer, dispatch]
    );

    const closeProfileDrawer = useCallback(
        (clearProfile = true) => {
            setClosed();
            if (clearProfile) setSelectedTalentProfile(null);
            clearMessageTalents();
        },
        [setClosed, clearMessageTalents, setSelectedTalentProfile]
    );

    return useMemo(
        () => ({
            isTalentProfileDrawerOpen: isOpen,
            openTalentProfileDrawer: openProfileDrawer,
            config,
            closeTalentProfileDrawer: closeProfileDrawer,
            isFetchingTalentData: isFetching || isFetchingContactMeta,
            priorityErr: getProfileErr,
            contactMeta,
            talentProfile,
            currentTalentId: talentId,
            talentProfiles: profiles,
            messageTalentIds,
            setNextTalentId,
            setPreviousTalentId,
            setSelectedTalentProfile,
            setShowTalentProfile,
            disablePrevTalentProfileButton: currentTalentIdIndex === 0,
            disableNextTalentProfileButton:
                messageTalentIds.length === 0 ||
                currentTalentIdIndex === messageTalentIds.length - 1,
        }),
        [
            isOpen,
            openProfileDrawer,
            setShowTalentProfile,
            config,
            closeProfileDrawer,
            isFetching,
            isFetchingContactMeta,
            getProfileErr,
            contactMeta,
            talentProfile,
            talentId,
            setSelectedTalentProfile,
            messageTalentIds,
            setNextTalentId,
            setPreviousTalentId,
            currentTalentIdIndex,
            profiles,
        ]
    );
}
