import SvgIcon from '@mui/material/SvgIcon';

export function SmileySadIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6 12C21.6 6.708 17.292 2.4 12 2.4C6.708 2.4 2.4 6.708 2.4 12C2.4 17.292 6.708 21.6 12 21.6C17.292 21.6 21.6 17.292 21.6 12ZM0 12C0 5.376 5.376 0 12 0C18.624 0 24 5.376 24 12C24 18.624 18.624 24 12 24C5.376 24 0 18.624 0 12ZM9.97902 9.49976C9.97902 10.3227 9.31193 10.9897 8.48903 10.9897C7.66612 10.9897 6.99902 10.3227 6.99902 9.49976C6.99902 8.67685 7.66612 8.00977 8.48903 8.00977C9.31193 8.00977 9.97902 8.67685 9.97902 9.49976ZM15.491 10.9897C16.3139 10.9897 16.981 10.3227 16.981 9.49976C16.981 8.67685 16.3139 8.00977 15.491 8.00977C14.6681 8.00977 14.001 8.67685 14.001 9.49976C14.001 10.3227 14.6681 10.9897 15.491 10.9897ZM14.8808 16.1406C14.9608 16.6206 15.3808 16.9606 15.8608 16.9606L15.8508 16.9706C15.9108 16.9706 15.9708 16.9606 16.0308 16.9506C16.5808 16.8506 16.9408 16.3306 16.8408 15.7906C16.5608 14.2306 14.8908 12.0206 12.0608 11.9806H11.9908C9.06081 11.9806 7.50081 14.2206 7.16081 15.7306C7.04081 16.2606 7.3808 16.8006 7.9208 16.9206C8.4608 17.0406 8.99081 16.7006 9.11081 16.1606C9.24081 15.5506 10.0208 13.9806 11.9908 13.9806H12.0408C13.8808 14.0006 14.7608 15.4706 14.8808 16.1406Z"
                fill="currentColor"
                fillOpacity="0.48"
            />
        </SvgIcon>
    );
}
