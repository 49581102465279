import SvgIcon from '@mui/material/SvgIcon';

export function BouncedIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <path
                d="M6.15122 2.43937C6.15122 2.32284 6.10493 2.21109 6.02253 2.12869C5.94013 2.04629 5.82838 2 5.71185 2H0.439373C0.322844 2 0.211088 2.04629 0.128689 2.12869C0.0462909 2.21109 0 2.32284 0 2.43937V7.71185C0 7.82838 0.0462909 7.94014 0.128689 8.02253C0.211088 8.10493 0.322844 8.15122 0.439373 8.15122C0.555902 8.15122 0.667658 8.10493 0.750057 8.02253C0.832455 7.94014 0.878746 7.82838 0.878746 7.71185V3.50002L6.50008 9.12136C6.58248 9.20373 6.69421 9.25 6.81072 9.25C6.92723 9.25 7.03896 9.20373 7.12136 9.12136L12.1741 4.06857C12.2565 3.98607 12.3028 3.87421 12.3027 3.75762C12.3026 3.64103 12.2562 3.52924 12.1737 3.44686C12.0912 3.36447 11.9794 3.31823 11.8628 3.31831C11.7462 3.3184 11.6344 3.36479 11.552 3.44729L6.81028 8.18901L1.50002 2.87875H5.71185C5.82838 2.87875 5.94013 2.83245 6.02253 2.75006C6.10493 2.66766 6.15122 2.5559 6.15122 2.43937Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
