import SvgIcon from '@mui/material/SvgIcon';

export function StatEngagedIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M17.3333 0H6.66667C2.98667 0 0 2.98667 0 6.66667V14.6667C0 18.3467 2.98667 21.3333 6.66667 21.3333H14.6667C16.4267 21.3333 18.6667 24 20.6533 24C23 24 24 22.1467 24 20V6.66667C24 2.98667 21.0133 0 17.3333 0ZM21.3333 20C21.3333 21.3333 20.9067 21.3333 20.6934 21.3333C20.36 21.2933 19.4667 20.7067 18.9333 20.36C17.7067 19.56 16.3067 18.6533 14.6667 18.6533H6.66667C4.46667 18.6533 2.66667 16.8533 2.66667 14.6533V6.65334C2.66667 4.45334 4.46667 2.65334 6.66667 2.65334H17.3333C19.5333 2.65334 21.3333 4.45334 21.3333 6.65334V20Z"
                fill="currentColor"
                fillOpacity="0.48"
            />
            <path
                d="M15.0267 7.05334L10.7067 11.7067L8.96003 9.86667C8.45337 9.33334 7.61332 9.30667 7.07999 9.81333C6.54666 10.32 6.52002 11.16 7.02668 11.6933L9.33333 14.1333C9.69333 14.5067 10.1867 14.72 10.7067 14.72C11.2267 14.72 11.7333 14.4933 12.0933 14.1067L16.9733 8.85334C17.48 8.30667 17.44 7.46667 16.9067 6.97334C16.36 6.46667 15.52 6.50667 15.0267 7.04V7.05334Z"
                fill="currentColor"
                fillOpacity="0.48"
            />
        </SvgIcon>
    );
}
