import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { Typography } from 'components/Typography';

const ContentContainer = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    padding: theme.spacing(4),
    background: theme.palette.background.default,
}));

export function TooSmall() {
    return (
        <Dialog fullScreen open>
            <ContentContainer>
                <Typography variant="smallHeadline" textAlign="center" gutterBottom>
                    There is a lot of powerful stuff going on within our universe.
                </Typography>
                <Typography variant="smallHeadline" textAlign="center">
                    <b>Please utilize a larger screen to experience all that we have to offer.</b>
                </Typography>
            </ContentContainer>
        </Dialog>
    );
}
