import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { usersApi } from 'services/users';

export function EmailRefresher() {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(usersApi.util.invalidateTags(['emailConnectedStatus']));
    }, [dispatch, pathname]);

    return null;
}
