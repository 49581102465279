import SvgIcon from '@mui/material/SvgIcon';

export function NewPeopleIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M6.99987 9.49984C9.47983 9.49984 11.4998 7.47987 11.4998 4.99992C11.4998 2.51996 9.47983 0.5 6.99987 0.5C4.51992 0.5 2.49995 2.51996 2.49995 4.99992C2.49995 7.47987 4.51992 9.49984 6.99987 9.49984ZM6.99987 2.49996C8.37985 2.49996 9.49983 3.61994 9.49983 4.99992C9.49983 6.37989 8.37985 7.49987 6.99987 7.49987C5.6199 7.49987 4.49992 6.37989 4.49992 4.99992C4.49992 3.61994 5.6199 2.49996 6.99987 2.49996Z"
                fill="currentColor"
            />
            <path
                d="M9.99982 10.4998H3.99993C1.78997 10.4998 0 12.2898 0 14.4997V15.4997C0 17.7097 1.78997 19.4997 3.99993 19.4997H9.99982C12.2098 19.4997 13.9997 17.7097 13.9997 15.4997V14.4997C13.9997 12.2898 12.2098 10.4998 9.99982 10.4998ZM11.9998 15.4997C11.9998 16.5997 11.0998 17.4997 9.99982 17.4997H3.99993C2.89995 17.4997 1.99996 16.5997 1.99996 15.4997V14.4997C1.99996 13.3998 2.89995 12.4998 3.99993 12.4998H9.99982C11.0998 12.4998 11.9998 13.3998 11.9998 14.4997V15.4997Z"
                fill="currentColor"
            />
            <path
                d="M17.31 11.4998H15.3601C14.8101 11.4998 14.3601 11.9498 14.3601 12.4998C14.3601 13.0498 14.8101 13.4998 15.3601 13.4998H17.31C17.69 13.4998 18 13.8097 18 14.1897V15.4997C18 16.5997 17.1001 17.4997 16.0001 17.4997H15.3601C14.8101 17.4997 14.3601 17.9497 14.3601 18.4997C14.3601 19.0497 14.8101 19.4997 15.3601 19.4997H16.0001C18.21 19.4997 20 17.7097 20 15.4997V14.1897C20 12.7097 18.79 11.4998 17.31 11.4998Z"
                fill="currentColor"
            />
            <path
                d="M15.9997 10.4998C17.6497 10.4998 18.9997 9.14984 18.9997 7.49987C18.9997 5.8499 17.6497 4.49993 15.9997 4.49993C14.3497 4.49993 12.9998 5.8499 12.9998 7.49987C12.9998 9.14984 14.3497 10.4998 15.9997 10.4998ZM15.9997 6.49989C16.5497 6.49989 16.9997 6.94988 16.9997 7.49987C16.9997 8.04986 16.5497 8.49985 15.9997 8.49985C15.4497 8.49985 14.9997 8.04986 14.9997 7.49987C14.9997 6.94988 15.4497 6.49989 15.9997 6.49989Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
