import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import MuiIconButton from '@mui/material/IconButton';

import { CloseIcon } from 'components/Icons';
import { useImpersonateStore } from './store';
import { Typography } from 'components/Typography';

const Outline = styled('div')(({ theme }) => ({
    position: 'fixed',
    boxSizing: 'border-box',
    top: 0,
    height: '100dvh',
    width: '100dvw',
    zIndex: 9999999,
    borderColor: theme.palette.error.main,
    borderStyle: 'solid',
    borderWidth: theme.spacing(1),
    pointerEvents: 'none',
    '& *': {
        pointerEvents: 'none',
    },
}));

const TabContainer = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    [theme.breakpoints.up('md')]: {
        left: theme.spacing(42),
    },
    [theme.breakpoints.down('md')]: {
        right: 0,
        top: '5dvh',
    },
}));

const Tab = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(0.25, 2, 1),
    [theme.breakpoints.up('md')]: {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderTopLeftRadius: theme.shape.borderRadius,
        padding: theme.spacing(2, 1),
    },
}));

const Type = styled(Typography)(({ theme }) => ({
    fontSize: '.8rem',
    fontWeight: 600,
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
        writingMode: 'vertical-lr',
        transform: 'rotate(0deg)',
    },
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    padding: 0,
    color: theme.palette.text.primary,
    pointerEvents: 'initial',
    [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(1.75),
        marginBottom: -3,
    },
    [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(1.75),
        marginLeft: -3,
    },
}));

export function ImpersonateIndicator() {
    const currentUser = useSelector((state) => state.user);
    const isImpersonating = useImpersonateStore((state) => state.isImpersonating);

    if (!isImpersonating) {
        return null;
    }

    const handleExit = () => {
        window.location.reload();
    };

    return (
        <Outline>
            <TabContainer>
                <Tab>
                    <Type>You Are Signed In As: {currentUser?.displayName}</Type>
                    <IconButton aria-label="exit-impersonation" onClick={handleExit} disableRipple>
                        <CloseIcon sx={{ width: 16 }} />
                    </IconButton>
                </Tab>
            </TabContainer>
        </Outline>
    );
}
