import { ReactKeycloakProvider, useKeycloak as _useKeycloak } from '@react-keycloak/web';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import Keycloak from 'keycloak-js';
import PropTypes from 'prop-types';

import { useGetKeycloakUrlQuery } from 'services/switchboard';
import { RegisterRedirect } from './RegisterRedirect';

const KeycloakMetadataContext = createContext({ initialized: false });

const initOptions = { onLoad: 'login-required', checkLoginIframe: false };

//exported for test
export const keycloakClient = { current: null };

export const getKeycloakClient = (keycloakUrl) => {
    if (!keycloakUrl) {
        console.error('Invalid Keycloak URL');
        return;
    }
    if (!keycloakClient.current) {
        keycloakClient.current = new Keycloak({
            url: keycloakUrl,
            realm: process.env.REACT_APP_KEYCLOAK_REALM,
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        });
    }
    return keycloakClient.current;
};

export const getCurrentKeycloakClient = () => {
    if (!keycloakClient.current) {
        console.error('A Keycloak client has not been instantiated.');
        return;
    }
    return keycloakClient.current;
};

export const useKeycloak = () => {
    const keycloakMetadata = useContext(KeycloakMetadataContext);
    let reactkeycloakInitialized = false;
    let reactkeycloak = {};
    try {
        const { initialized, ...rest } = _useKeycloak();
        reactkeycloakInitialized = initialized;
        reactkeycloak = rest;
    } catch {
        // Pass
    }
    return {
        keycloak: {},
        ...reactkeycloak,
        initialized: reactkeycloakInitialized && keycloakMetadata.initialized,
    };
};

export function KeycloakProvider(props) {
    const { children } = props;

    const { data: keycloakUrl, isSuccess } = useGetKeycloakUrlQuery();
    const [redirectToRegisterPage, setRedirectToRegisterPage] = useState(false);

    const keycloakMetadata = useMemo(() => {
        return { initialized: isSuccess };
    }, [isSuccess]);

    let url = useMemo(() => {
        return new URL(window.location);
    }, []);

    useEffect(() => {
        if (url.searchParams.has('register')) {
            url.searchParams.delete('register');
            window.history.pushState({}, '', url);
            setRedirectToRegisterPage(true);
        }
    }, [url]);

    if (!isSuccess) {
        return (
            <KeycloakMetadataContext.Provider value={keycloakMetadata}>
                {children}
            </KeycloakMetadataContext.Provider>
        );
    }

    if (redirectToRegisterPage) {
        return (
            <KeycloakMetadataContext.Provider value={keycloakMetadata}>
                <ReactKeycloakProvider
                    authClient={getKeycloakClient(keycloakUrl)}
                    initOptions={initOptions}
                >
                    {' '}
                    <RegisterRedirect />
                </ReactKeycloakProvider>
            </KeycloakMetadataContext.Provider>
        );
    }

    return (
        <KeycloakMetadataContext.Provider value={keycloakMetadata}>
            <ReactKeycloakProvider
                authClient={getKeycloakClient(keycloakUrl)}
                initOptions={initOptions}
            >
                {children}
            </ReactKeycloakProvider>
        </KeycloakMetadataContext.Provider>
    );
}

KeycloakProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
