import SvgIcon from '@mui/material/SvgIcon';

export function AltDownloadIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M13.25 16C13.6642 16 14 15.6642 14 15.25C14 14.8358 13.6642 14.5 13.25 14.5L2.74992 14.5C2.33571 14.5 1.99992 14.8358 1.99992 15.25C1.99992 15.6642 2.33571 16 2.74992 16L13.25 16ZM7.24061 13.1723L1.84597 7.76174L1.83799 7.75374C1.5263 7.44206 1.5263 6.93856 1.83799 6.62687C2.14968 6.31518 2.65318 6.31518 2.96487 6.62687L7.20066 10.8706L7.20066 0.7992C7.20066 0.35964 7.55232 3.55427e-07 7.99987 3.94554e-07C8.44743 4.3368e-07 8.79908 0.359641 8.79908 0.799201L8.79907 10.8706L13.0269 6.62687C13.1867 6.47503 13.3865 6.3951 13.5943 6.3951C13.8021 6.3951 14.0019 6.47503 14.1617 6.62687C14.4734 6.93856 14.4734 7.44206 14.1617 7.75375L8.7671 13.1643C8.63923 13.2922 8.49537 13.3721 8.33553 13.4201C8.23164 13.468 8.11975 13.5 7.99987 13.5C7.87999 13.5 7.7681 13.468 7.6642 13.4201C7.51235 13.3721 7.36049 13.2922 7.24061 13.1723Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
