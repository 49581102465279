import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

import { SignatureList } from './SignatureList';
import { LoadWrapper } from 'components/Loaders';
import { USER_NAVIGATION } from 'views/UserSettings/utils/constants';
import { StyledDropdownMenu } from 'components/MessageContent/StyledDropdownMenu';

export function SignatureDropdown(props) {
    const { anchorEl, onClose, signatures, isFetching, onUseSignature, onRefreshSignature } = props;
    const open = Boolean(anchorEl);

    return (
        <StyledDropdownMenu
            id="signature-dropdown-menu"
            MenuListProps={{
                'aria-labelledby': 'signature-dropdown-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <MenuItem
                component={Link}
                to={`/user-settings#${USER_NAVIGATION.EMAIL_SIGNATURES.navId}`}
                target="_blank"
                disableRipple
            >
                Create New Signature
            </MenuItem>
            <LoadWrapper isLoading={isFetching}>
                {signatures?.length > 0 && (
                    <SignatureList
                        signatures={signatures}
                        onClose={onClose}
                        onUseSignature={onUseSignature}
                        onRefreshSignature={onRefreshSignature}
                    />
                )}
            </LoadWrapper>
        </StyledDropdownMenu>
    );
}

SignatureDropdown.propTypes = {
    anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(Element), PropTypes.oneOf([null])]),
    onClose: PropTypes.func,
    signatures: PropTypes.array,
    isFetching: PropTypes.bool,
    onUseSignature: PropTypes.func,
    onRefreshSignature: PropTypes.func,
};
