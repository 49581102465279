export const industryData = [
    {
        code: '11',
        name: 'Agriculture, Forestry, Fishing and Hunting',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '111',
        name: 'Crop Production',
        level: 1,
        parentCode: '11',
        hasChildren: true,
    },
    {
        code: '1111',
        name: 'Oilseed and Grain Farming',
        level: 2,
        parentCode: '111',
        hasChildren: true,
    },
    {
        code: '11111',
        name: 'Soybean Farming',
        level: 3,
        parentCode: '1111',
        hasChildren: true,
    },
    {
        code: '111110',
        name: 'Soybean Farming',
        level: 4,
        parentCode: '11111',
        hasChildren: false,
    },
    {
        code: '11112',
        name: 'Oilseed (except Soybean) Farming',
        level: 3,
        parentCode: '1111',
        hasChildren: true,
    },
    {
        code: '111120',
        name: 'Oilseed (except Soybean) Farming',
        level: 4,
        parentCode: '11112',
        hasChildren: false,
    },
    {
        code: '11113',
        name: 'Dry Pea and Bean Farming',
        level: 3,
        parentCode: '1111',
        hasChildren: true,
    },
    {
        code: '111130',
        name: 'Dry Pea and Bean Farming',
        level: 4,
        parentCode: '11113',
        hasChildren: false,
    },
    {
        code: '11114',
        name: 'Wheat Farming',
        level: 3,
        parentCode: '1111',
        hasChildren: true,
    },
    {
        code: '111140',
        name: 'Wheat Farming',
        level: 4,
        parentCode: '11114',
        hasChildren: false,
    },
    {
        code: '11115',
        name: 'Corn Farming',
        level: 3,
        parentCode: '1111',
        hasChildren: true,
    },
    {
        code: '111150',
        name: 'Corn Farming',
        level: 4,
        parentCode: '11115',
        hasChildren: false,
    },
    {
        code: '11116',
        name: 'Rice Farming',
        level: 3,
        parentCode: '1111',
        hasChildren: true,
    },
    {
        code: '111160',
        name: 'Rice Farming',
        level: 4,
        parentCode: '11116',
        hasChildren: false,
    },
    {
        code: '11119',
        name: 'Other Grain Farming',
        level: 3,
        parentCode: '1111',
        hasChildren: true,
    },
    {
        code: '111191',
        name: 'Oilseed and Grain Combination Farming',
        level: 4,
        parentCode: '11119',
        hasChildren: false,
    },
    {
        code: '111199',
        name: 'All Other Grain Farming',
        level: 4,
        parentCode: '11119',
        hasChildren: false,
    },
    {
        code: '1112',
        name: 'Vegetable and Melon Farming',
        level: 2,
        parentCode: '111',
        hasChildren: true,
    },
    {
        code: '11121',
        name: 'Vegetable and Melon Farming',
        level: 3,
        parentCode: '1112',
        hasChildren: true,
    },
    {
        code: '111211',
        name: 'Potato Farming',
        level: 4,
        parentCode: '11121',
        hasChildren: false,
    },
    {
        code: '111219',
        name: 'Other Vegetable (except Potato) and Melon Farming',
        level: 4,
        parentCode: '11121',
        hasChildren: false,
    },
    {
        code: '1113',
        name: 'Fruit and Tree Nut Farming',
        level: 2,
        parentCode: '111',
        hasChildren: true,
    },
    {
        code: '11131',
        name: 'Orange Groves',
        level: 3,
        parentCode: '1113',
        hasChildren: true,
    },
    {
        code: '111310',
        name: 'Orange Groves',
        level: 4,
        parentCode: '11131',
        hasChildren: false,
    },
    {
        code: '11132',
        name: 'Citrus (except Orange) Groves',
        level: 3,
        parentCode: '1113',
        hasChildren: true,
    },
    {
        code: '111320',
        name: 'Citrus (except Orange) Groves',
        level: 4,
        parentCode: '11132',
        hasChildren: false,
    },
    {
        code: '11133',
        name: 'Noncitrus Fruit and Tree Nut Farming',
        level: 3,
        parentCode: '1113',
        hasChildren: true,
    },
    {
        code: '111331',
        name: 'Apple Orchards',
        level: 4,
        parentCode: '11133',
        hasChildren: false,
    },
    {
        code: '111332',
        name: 'Grape Vineyards',
        level: 4,
        parentCode: '11133',
        hasChildren: false,
    },
    {
        code: '111333',
        name: 'Strawberry Farming',
        level: 4,
        parentCode: '11133',
        hasChildren: false,
    },
    {
        code: '111334',
        name: 'Berry (except Strawberry) Farming',
        level: 4,
        parentCode: '11133',
        hasChildren: false,
    },
    {
        code: '111335',
        name: 'Tree Nut Farming',
        level: 4,
        parentCode: '11133',
        hasChildren: false,
    },
    {
        code: '111336',
        name: 'Fruit and Tree Nut Combination Farming',
        level: 4,
        parentCode: '11133',
        hasChildren: false,
    },
    {
        code: '111339',
        name: 'Other Noncitrus Fruit Farming',
        level: 4,
        parentCode: '11133',
        hasChildren: false,
    },
    {
        code: '1114',
        name: 'Greenhouse, Nursery, and Floriculture Production',
        level: 2,
        parentCode: '111',
        hasChildren: true,
    },
    {
        code: '11141',
        name: 'Food Crops Grown Under Cover',
        level: 3,
        parentCode: '1114',
        hasChildren: true,
    },
    {
        code: '111411',
        name: 'Mushroom Production',
        level: 4,
        parentCode: '11141',
        hasChildren: false,
    },
    {
        code: '111419',
        name: 'Other Food Crops Grown Under Cover',
        level: 4,
        parentCode: '11141',
        hasChildren: false,
    },
    {
        code: '11142',
        name: 'Nursery and Floriculture Production',
        level: 3,
        parentCode: '1114',
        hasChildren: true,
    },
    {
        code: '111421',
        name: 'Nursery and Tree Production',
        level: 4,
        parentCode: '11142',
        hasChildren: false,
    },
    {
        code: '111422',
        name: 'Floriculture Production',
        level: 4,
        parentCode: '11142',
        hasChildren: false,
    },
    {
        code: '1119',
        name: 'Other Crop Farming',
        level: 2,
        parentCode: '111',
        hasChildren: true,
    },
    {
        code: '11191',
        name: 'Tobacco Farming',
        level: 3,
        parentCode: '1119',
        hasChildren: true,
    },
    {
        code: '111910',
        name: 'Tobacco Farming',
        level: 4,
        parentCode: '11191',
        hasChildren: false,
    },
    {
        code: '11192',
        name: 'Cotton Farming',
        level: 3,
        parentCode: '1119',
        hasChildren: true,
    },
    {
        code: '111920',
        name: 'Cotton Farming',
        level: 4,
        parentCode: '11192',
        hasChildren: false,
    },
    {
        code: '11193',
        name: 'Sugarcane Farming',
        level: 3,
        parentCode: '1119',
        hasChildren: true,
    },
    {
        code: '111930',
        name: 'Sugarcane Farming',
        level: 4,
        parentCode: '11193',
        hasChildren: false,
    },
    {
        code: '11194',
        name: 'Hay Farming',
        level: 3,
        parentCode: '1119',
        hasChildren: true,
    },
    {
        code: '111940',
        name: 'Hay Farming',
        level: 4,
        parentCode: '11194',
        hasChildren: false,
    },
    {
        code: '11199',
        name: 'All Other Crop Farming',
        level: 3,
        parentCode: '1119',
        hasChildren: true,
    },
    {
        code: '111991',
        name: 'Sugar Beet Farming',
        level: 4,
        parentCode: '11199',
        hasChildren: false,
    },
    {
        code: '111992',
        name: 'Peanut Farming',
        level: 4,
        parentCode: '11199',
        hasChildren: false,
    },
    {
        code: '111998',
        name: 'All Other Miscellaneous Crop Farming',
        level: 4,
        parentCode: '11199',
        hasChildren: false,
    },
    {
        code: '112',
        name: 'Animal Production and Aquaculture',
        level: 1,
        parentCode: '11',
        hasChildren: true,
    },
    {
        code: '1121',
        name: 'Cattle Ranching and Farming',
        level: 2,
        parentCode: '112',
        hasChildren: true,
    },
    {
        code: '11211',
        name: 'Beef Cattle Ranching and Farming, including Feedlots',
        level: 3,
        parentCode: '1121',
        hasChildren: true,
    },
    {
        code: '112111',
        name: 'Beef Cattle Ranching and Farming',
        level: 4,
        parentCode: '11211',
        hasChildren: false,
    },
    {
        code: '112112',
        name: 'Cattle Feedlots',
        level: 4,
        parentCode: '11211',
        hasChildren: false,
    },
    {
        code: '11212',
        name: 'Dairy Cattle and Milk Production',
        level: 3,
        parentCode: '1121',
        hasChildren: true,
    },
    {
        code: '112120',
        name: 'Dairy Cattle and Milk Production',
        level: 4,
        parentCode: '11212',
        hasChildren: false,
    },
    {
        code: '11213',
        name: 'Dual-Purpose Cattle Ranching and Farming',
        level: 3,
        parentCode: '1121',
        hasChildren: true,
    },
    {
        code: '112130',
        name: 'Dual-Purpose Cattle Ranching and Farming',
        level: 4,
        parentCode: '11213',
        hasChildren: false,
    },
    {
        code: '1122',
        name: 'Hog and Pig Farming',
        level: 2,
        parentCode: '112',
        hasChildren: true,
    },
    {
        code: '11221',
        name: 'Hog and Pig Farming',
        level: 3,
        parentCode: '1122',
        hasChildren: true,
    },
    {
        code: '112210',
        name: 'Hog and Pig Farming',
        level: 4,
        parentCode: '11221',
        hasChildren: false,
    },
    {
        code: '1123',
        name: 'Poultry and Egg Production',
        level: 2,
        parentCode: '112',
        hasChildren: true,
    },
    {
        code: '11231',
        name: 'Chicken Egg Production',
        level: 3,
        parentCode: '1123',
        hasChildren: true,
    },
    {
        code: '112310',
        name: 'Chicken Egg Production',
        level: 4,
        parentCode: '11231',
        hasChildren: false,
    },
    {
        code: '11232',
        name: 'Broilers and Other Meat Type Chicken Production',
        level: 3,
        parentCode: '1123',
        hasChildren: true,
    },
    {
        code: '112320',
        name: 'Broilers and Other Meat Type Chicken Production',
        level: 4,
        parentCode: '11232',
        hasChildren: false,
    },
    {
        code: '11233',
        name: 'Turkey Production',
        level: 3,
        parentCode: '1123',
        hasChildren: true,
    },
    {
        code: '112330',
        name: 'Turkey Production',
        level: 4,
        parentCode: '11233',
        hasChildren: false,
    },
    {
        code: '11234',
        name: 'Poultry Hatcheries',
        level: 3,
        parentCode: '1123',
        hasChildren: true,
    },
    {
        code: '112340',
        name: 'Poultry Hatcheries',
        level: 4,
        parentCode: '11234',
        hasChildren: false,
    },
    {
        code: '11239',
        name: 'Other Poultry Production',
        level: 3,
        parentCode: '1123',
        hasChildren: true,
    },
    {
        code: '112390',
        name: 'Other Poultry Production',
        level: 4,
        parentCode: '11239',
        hasChildren: false,
    },
    {
        code: '1124',
        name: 'Sheep and Goat Farming',
        level: 2,
        parentCode: '112',
        hasChildren: true,
    },
    {
        code: '11241',
        name: 'Sheep Farming',
        level: 3,
        parentCode: '1124',
        hasChildren: true,
    },
    {
        code: '112410',
        name: 'Sheep Farming',
        level: 4,
        parentCode: '11241',
        hasChildren: false,
    },
    {
        code: '11242',
        name: 'Goat Farming',
        level: 3,
        parentCode: '1124',
        hasChildren: true,
    },
    {
        code: '112420',
        name: 'Goat Farming',
        level: 4,
        parentCode: '11242',
        hasChildren: false,
    },
    {
        code: '1125',
        name: 'Aquaculture',
        level: 2,
        parentCode: '112',
        hasChildren: true,
    },
    {
        code: '11251',
        name: 'Aquaculture',
        level: 3,
        parentCode: '1125',
        hasChildren: true,
    },
    {
        code: '112511',
        name: 'Finfish Farming and Fish Hatcheries',
        level: 4,
        parentCode: '11251',
        hasChildren: false,
    },
    {
        code: '112512',
        name: 'Shellfish Farming',
        level: 4,
        parentCode: '11251',
        hasChildren: false,
    },
    {
        code: '112519',
        name: 'Other Aquaculture',
        level: 4,
        parentCode: '11251',
        hasChildren: false,
    },
    {
        code: '1129',
        name: 'Other Animal Production',
        level: 2,
        parentCode: '112',
        hasChildren: true,
    },
    {
        code: '11291',
        name: 'Apiculture',
        level: 3,
        parentCode: '1129',
        hasChildren: true,
    },
    {
        code: '112910',
        name: 'Apiculture',
        level: 4,
        parentCode: '11291',
        hasChildren: false,
    },
    {
        code: '11292',
        name: 'Horses and Other Equine Production',
        level: 3,
        parentCode: '1129',
        hasChildren: true,
    },
    {
        code: '112920',
        name: 'Horses and Other Equine Production',
        level: 4,
        parentCode: '11292',
        hasChildren: false,
    },
    {
        code: '11293',
        name: 'Fur-Bearing Animal and Rabbit Production',
        level: 3,
        parentCode: '1129',
        hasChildren: true,
    },
    {
        code: '112930',
        name: 'Fur-Bearing Animal and Rabbit Production',
        level: 4,
        parentCode: '11293',
        hasChildren: false,
    },
    {
        code: '11299',
        name: 'All Other Animal Production',
        level: 3,
        parentCode: '1129',
        hasChildren: true,
    },
    {
        code: '112990',
        name: 'All Other Animal Production',
        level: 4,
        parentCode: '11299',
        hasChildren: false,
    },
    {
        code: '113',
        name: 'Forestry and Logging',
        level: 1,
        parentCode: '11',
        hasChildren: true,
    },
    {
        code: '1131',
        name: 'Timber Tract Operations',
        level: 2,
        parentCode: '113',
        hasChildren: true,
    },
    {
        code: '11311',
        name: 'Timber Tract Operations',
        level: 3,
        parentCode: '1131',
        hasChildren: true,
    },
    {
        code: '113110',
        name: 'Timber Tract Operations',
        level: 4,
        parentCode: '11311',
        hasChildren: false,
    },
    {
        code: '1132',
        name: 'Forest Nurseries and Gathering of Forest Products',
        level: 2,
        parentCode: '113',
        hasChildren: true,
    },
    {
        code: '11321',
        name: 'Forest Nurseries and Gathering of Forest Products',
        level: 3,
        parentCode: '1132',
        hasChildren: true,
    },
    {
        code: '113210',
        name: 'Forest Nurseries and Gathering of Forest Products',
        level: 4,
        parentCode: '11321',
        hasChildren: false,
    },
    {
        code: '1133',
        name: 'Logging',
        level: 2,
        parentCode: '113',
        hasChildren: true,
    },
    {
        code: '11331',
        name: 'Logging',
        level: 3,
        parentCode: '1133',
        hasChildren: true,
    },
    {
        code: '113310',
        name: 'Logging',
        level: 4,
        parentCode: '11331',
        hasChildren: false,
    },
    {
        code: '114',
        name: 'Fishing, Hunting and Trapping',
        level: 1,
        parentCode: '11',
        hasChildren: true,
    },
    {
        code: '1141',
        name: 'Fishing',
        level: 2,
        parentCode: '114',
        hasChildren: true,
    },
    {
        code: '11411',
        name: 'Fishing',
        level: 3,
        parentCode: '1141',
        hasChildren: true,
    },
    {
        code: '114111',
        name: 'Finfish Fishing',
        level: 4,
        parentCode: '11411',
        hasChildren: false,
    },
    {
        code: '114112',
        name: 'Shellfish Fishing',
        level: 4,
        parentCode: '11411',
        hasChildren: false,
    },
    {
        code: '114119',
        name: 'Other Marine Fishing',
        level: 4,
        parentCode: '11411',
        hasChildren: false,
    },
    {
        code: '1142',
        name: 'Hunting and Trapping',
        level: 2,
        parentCode: '114',
        hasChildren: true,
    },
    {
        code: '11421',
        name: 'Hunting and Trapping',
        level: 3,
        parentCode: '1142',
        hasChildren: true,
    },
    {
        code: '114210',
        name: 'Hunting and Trapping',
        level: 4,
        parentCode: '11421',
        hasChildren: false,
    },
    {
        code: '115',
        name: 'Support Activities for Agriculture and Forestry',
        level: 1,
        parentCode: '11',
        hasChildren: true,
    },
    {
        code: '1151',
        name: 'Support Activities for Crop Production',
        level: 2,
        parentCode: '115',
        hasChildren: true,
    },
    {
        code: '11511',
        name: 'Support Activities for Crop Production',
        level: 3,
        parentCode: '1151',
        hasChildren: true,
    },
    {
        code: '115111',
        name: 'Cotton Ginning',
        level: 4,
        parentCode: '11511',
        hasChildren: false,
    },
    {
        code: '115112',
        name: 'Soil Preparation, Planting, and Cultivating',
        level: 4,
        parentCode: '11511',
        hasChildren: false,
    },
    {
        code: '115113',
        name: 'Crop Harvesting, Primarily by Machine',
        level: 4,
        parentCode: '11511',
        hasChildren: false,
    },
    {
        code: '115114',
        name: 'Postharvest Crop Activities (except Cotton Ginning)',
        level: 4,
        parentCode: '11511',
        hasChildren: false,
    },
    {
        code: '115115',
        name: 'Farm Labor Contractors and Crew Leaders',
        level: 4,
        parentCode: '11511',
        hasChildren: false,
    },
    {
        code: '115116',
        name: 'Farm Management Services',
        level: 4,
        parentCode: '11511',
        hasChildren: false,
    },
    {
        code: '1152',
        name: 'Support Activities for Animal Production',
        level: 2,
        parentCode: '115',
        hasChildren: true,
    },
    {
        code: '11521',
        name: 'Support Activities for Animal Production',
        level: 3,
        parentCode: '1152',
        hasChildren: true,
    },
    {
        code: '115210',
        name: 'Support Activities for Animal Production',
        level: 4,
        parentCode: '11521',
        hasChildren: false,
    },
    {
        code: '1153',
        name: 'Support Activities for Forestry',
        level: 2,
        parentCode: '115',
        hasChildren: true,
    },
    {
        code: '11531',
        name: 'Support Activities for Forestry',
        level: 3,
        parentCode: '1153',
        hasChildren: true,
    },
    {
        code: '115310',
        name: 'Support Activities for Forestry',
        level: 4,
        parentCode: '11531',
        hasChildren: false,
    },
    {
        code: '21',
        name: 'Mining, Quarrying, and Oil and Gas Extraction',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '211',
        name: 'Oil and Gas Extraction',
        level: 1,
        parentCode: '21',
        hasChildren: true,
    },
    {
        code: '2111',
        name: 'Oil and Gas Extraction',
        level: 2,
        parentCode: '211',
        hasChildren: true,
    },
    {
        code: '21112',
        name: 'Crude Petroleum Extraction',
        level: 3,
        parentCode: '2111',
        hasChildren: true,
    },
    {
        code: '211120',
        name: 'Crude Petroleum Extraction',
        level: 4,
        parentCode: '21112',
        hasChildren: false,
    },
    {
        code: '21113',
        name: 'Natural Gas Extraction',
        level: 3,
        parentCode: '2111',
        hasChildren: true,
    },
    {
        code: '211130',
        name: 'Natural Gas Extraction',
        level: 4,
        parentCode: '21113',
        hasChildren: false,
    },
    {
        code: '212',
        name: 'Mining (except Oil and Gas)',
        level: 1,
        parentCode: '21',
        hasChildren: true,
    },
    {
        code: '2121',
        name: 'Coal Mining',
        level: 2,
        parentCode: '212',
        hasChildren: true,
    },
    {
        code: '21211',
        name: 'Coal Mining',
        level: 3,
        parentCode: '2121',
        hasChildren: true,
    },
    {
        code: '212111',
        name: 'Bituminous Coal and Lignite Surface Mining',
        level: 4,
        parentCode: '21211',
        hasChildren: false,
    },
    {
        code: '212112',
        name: 'Bituminous Coal Underground Mining',
        level: 4,
        parentCode: '21211',
        hasChildren: false,
    },
    {
        code: '212113',
        name: 'Anthracite Mining',
        level: 4,
        parentCode: '21211',
        hasChildren: false,
    },
    {
        code: '2122',
        name: 'Metal Ore Mining',
        level: 2,
        parentCode: '212',
        hasChildren: true,
    },
    {
        code: '21221',
        name: 'Iron Ore Mining',
        level: 3,
        parentCode: '2122',
        hasChildren: true,
    },
    {
        code: '212210',
        name: 'Iron Ore Mining',
        level: 4,
        parentCode: '21221',
        hasChildren: false,
    },
    {
        code: '21222',
        name: 'Gold Ore and Silver Ore Mining',
        level: 3,
        parentCode: '2122',
        hasChildren: true,
    },
    {
        code: '212221',
        name: 'Gold Ore Mining',
        level: 4,
        parentCode: '21222',
        hasChildren: false,
    },
    {
        code: '212222',
        name: 'Silver Ore Mining',
        level: 4,
        parentCode: '21222',
        hasChildren: false,
    },
    {
        code: '21223',
        name: 'Copper, Nickel, Lead, and Zinc Mining',
        level: 3,
        parentCode: '2122',
        hasChildren: true,
    },
    {
        code: '212230',
        name: 'Copper, Nickel, Lead, and Zinc Mining',
        level: 4,
        parentCode: '21223',
        hasChildren: false,
    },
    {
        code: '21229',
        name: 'Other Metal Ore Mining',
        level: 3,
        parentCode: '2122',
        hasChildren: true,
    },
    {
        code: '212291',
        name: 'Uranium-Radium-Vanadium Ore Mining',
        level: 4,
        parentCode: '21229',
        hasChildren: false,
    },
    {
        code: '212299',
        name: 'All Other Metal Ore Mining',
        level: 4,
        parentCode: '21229',
        hasChildren: false,
    },
    {
        code: '2123',
        name: 'Nonmetallic Mineral Mining and Quarrying',
        level: 2,
        parentCode: '212',
        hasChildren: true,
    },
    {
        code: '21231',
        name: 'Stone Mining and Quarrying',
        level: 3,
        parentCode: '2123',
        hasChildren: true,
    },
    {
        code: '212311',
        name: 'Dimension Stone Mining and Quarrying',
        level: 4,
        parentCode: '21231',
        hasChildren: false,
    },
    {
        code: '212312',
        name: 'Crushed and Broken Limestone Mining and Quarrying',
        level: 4,
        parentCode: '21231',
        hasChildren: false,
    },
    {
        code: '212313',
        name: 'Crushed and Broken Granite Mining and Quarrying',
        level: 4,
        parentCode: '21231',
        hasChildren: false,
    },
    {
        code: '212319',
        name: 'Other Crushed and Broken Stone Mining and Quarrying',
        level: 4,
        parentCode: '21231',
        hasChildren: false,
    },
    {
        code: '21232',
        name: 'Sand, Gravel, Clay, and Ceramic and Refractory Minerals Mining and Quarrying',
        level: 3,
        parentCode: '2123',
        hasChildren: true,
    },
    {
        code: '212321',
        name: 'Construction Sand and Gravel Mining',
        level: 4,
        parentCode: '21232',
        hasChildren: false,
    },
    {
        code: '212322',
        name: 'Industrial Sand Mining',
        level: 4,
        parentCode: '21232',
        hasChildren: false,
    },
    {
        code: '212324',
        name: 'Kaolin and Ball Clay Mining',
        level: 4,
        parentCode: '21232',
        hasChildren: false,
    },
    {
        code: '212325',
        name: 'Clay and Ceramic and Refractory Minerals Mining',
        level: 4,
        parentCode: '21232',
        hasChildren: false,
    },
    {
        code: '21239',
        name: 'Other Nonmetallic Mineral Mining and Quarrying',
        level: 3,
        parentCode: '2123',
        hasChildren: true,
    },
    {
        code: '212391',
        name: 'Potash, Soda, and Borate Mineral Mining',
        level: 4,
        parentCode: '21239',
        hasChildren: false,
    },
    {
        code: '212392',
        name: 'Phosphate Rock Mining',
        level: 4,
        parentCode: '21239',
        hasChildren: false,
    },
    {
        code: '212393',
        name: 'Other Chemical and Fertilizer Mineral Mining',
        level: 4,
        parentCode: '21239',
        hasChildren: false,
    },
    {
        code: '212399',
        name: 'All Other Nonmetallic Mineral Mining',
        level: 4,
        parentCode: '21239',
        hasChildren: false,
    },
    {
        code: '213',
        name: 'Support Activities for Mining',
        level: 1,
        parentCode: '21',
        hasChildren: true,
    },
    {
        code: '2131',
        name: 'Support Activities for Mining',
        level: 2,
        parentCode: '213',
        hasChildren: true,
    },
    {
        code: '21311',
        name: 'Support Activities for Mining',
        level: 3,
        parentCode: '2131',
        hasChildren: true,
    },
    {
        code: '213111',
        name: 'Drilling Oil and Gas Wells',
        level: 4,
        parentCode: '21311',
        hasChildren: false,
    },
    {
        code: '213112',
        name: 'Support Activities for Oil and Gas Operations',
        level: 4,
        parentCode: '21311',
        hasChildren: false,
    },
    {
        code: '213113',
        name: 'Support Activities for Coal Mining',
        level: 4,
        parentCode: '21311',
        hasChildren: false,
    },
    {
        code: '213114',
        name: 'Support Activities for Metal Mining',
        level: 4,
        parentCode: '21311',
        hasChildren: false,
    },
    {
        code: '213115',
        name: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining',
        level: 4,
        parentCode: '21311',
        hasChildren: false,
    },
    {
        code: '22',
        name: 'Utilities',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '221',
        name: 'Utilities',
        level: 1,
        parentCode: '22',
        hasChildren: true,
    },
    {
        code: '2211',
        name: 'Electric Power Generation, Transmission and Distribution',
        level: 2,
        parentCode: '221',
        hasChildren: true,
    },
    {
        code: '22111',
        name: 'Electric Power Generation',
        level: 3,
        parentCode: '2211',
        hasChildren: true,
    },
    {
        code: '221111',
        name: 'Hydroelectric Power Generation',
        level: 4,
        parentCode: '22111',
        hasChildren: false,
    },
    {
        code: '221112',
        name: 'Fossil Fuel Electric Power Generation',
        level: 4,
        parentCode: '22111',
        hasChildren: false,
    },
    {
        code: '221113',
        name: 'Nuclear Electric Power Generation',
        level: 4,
        parentCode: '22111',
        hasChildren: false,
    },
    {
        code: '221114',
        name: 'Solar Electric Power Generation',
        level: 4,
        parentCode: '22111',
        hasChildren: false,
    },
    {
        code: '221115',
        name: 'Wind Electric Power Generation',
        level: 4,
        parentCode: '22111',
        hasChildren: false,
    },
    {
        code: '221116',
        name: 'Geothermal Electric Power Generation',
        level: 4,
        parentCode: '22111',
        hasChildren: false,
    },
    {
        code: '221117',
        name: 'Biomass Electric Power Generation',
        level: 4,
        parentCode: '22111',
        hasChildren: false,
    },
    {
        code: '221118',
        name: 'Other Electric Power Generation',
        level: 4,
        parentCode: '22111',
        hasChildren: false,
    },
    {
        code: '22112',
        name: 'Electric Power Transmission, Control, and Distribution',
        level: 3,
        parentCode: '2211',
        hasChildren: true,
    },
    {
        code: '221121',
        name: 'Electric Bulk Power Transmission and Control',
        level: 4,
        parentCode: '22112',
        hasChildren: false,
    },
    {
        code: '221122',
        name: 'Electric Power Distribution',
        level: 4,
        parentCode: '22112',
        hasChildren: false,
    },
    {
        code: '2212',
        name: 'Natural Gas Distribution',
        level: 2,
        parentCode: '221',
        hasChildren: true,
    },
    {
        code: '22121',
        name: 'Natural Gas Distribution',
        level: 3,
        parentCode: '2212',
        hasChildren: true,
    },
    {
        code: '221210',
        name: 'Natural Gas Distribution',
        level: 4,
        parentCode: '22121',
        hasChildren: false,
    },
    {
        code: '2213',
        name: 'Water, Sewage and Other Systems',
        level: 2,
        parentCode: '221',
        hasChildren: true,
    },
    {
        code: '22131',
        name: 'Water Supply and Irrigation Systems',
        level: 3,
        parentCode: '2213',
        hasChildren: true,
    },
    {
        code: '221310',
        name: 'Water Supply and Irrigation Systems',
        level: 4,
        parentCode: '22131',
        hasChildren: false,
    },
    {
        code: '22132',
        name: 'Sewage Treatment Facilities',
        level: 3,
        parentCode: '2213',
        hasChildren: true,
    },
    {
        code: '221320',
        name: 'Sewage Treatment Facilities',
        level: 4,
        parentCode: '22132',
        hasChildren: false,
    },
    {
        code: '22133',
        name: 'Steam and Air-Conditioning Supply',
        level: 3,
        parentCode: '2213',
        hasChildren: true,
    },
    {
        code: '221330',
        name: 'Steam and Air-Conditioning Supply',
        level: 4,
        parentCode: '22133',
        hasChildren: false,
    },
    {
        code: '23',
        name: 'Construction',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '236',
        name: 'Construction of Buildings',
        level: 1,
        parentCode: '23',
        hasChildren: true,
    },
    {
        code: '2361',
        name: 'Residential Building Construction',
        level: 2,
        parentCode: '236',
        hasChildren: true,
    },
    {
        code: '23611',
        name: 'Residential Building Construction',
        level: 3,
        parentCode: '2361',
        hasChildren: true,
    },
    {
        code: '236115',
        name: 'New Single-Family Housing Construction (except For-Sale Builders)',
        level: 4,
        parentCode: '23611',
        hasChildren: false,
    },
    {
        code: '236116',
        name: 'New Multifamily Housing Construction (except For-Sale Builders)',
        level: 4,
        parentCode: '23611',
        hasChildren: false,
    },
    {
        code: '236117',
        name: 'New Housing For-Sale Builders',
        level: 4,
        parentCode: '23611',
        hasChildren: false,
    },
    {
        code: '236118',
        name: 'Residential Remodelers',
        level: 4,
        parentCode: '23611',
        hasChildren: false,
    },
    {
        code: '2362',
        name: 'Nonresidential Building Construction',
        level: 2,
        parentCode: '236',
        hasChildren: true,
    },
    {
        code: '23621',
        name: 'Industrial Building Construction',
        level: 3,
        parentCode: '2362',
        hasChildren: true,
    },
    {
        code: '236210',
        name: 'Industrial Building Construction',
        level: 4,
        parentCode: '23621',
        hasChildren: false,
    },
    {
        code: '23622',
        name: 'Commercial and Institutional Building Construction',
        level: 3,
        parentCode: '2362',
        hasChildren: true,
    },
    {
        code: '236220',
        name: 'Commercial and Institutional Building Construction',
        level: 4,
        parentCode: '23622',
        hasChildren: false,
    },
    {
        code: '237',
        name: 'Heavy and Civil Engineering Construction',
        level: 1,
        parentCode: '23',
        hasChildren: true,
    },
    {
        code: '2371',
        name: 'Utility System Construction',
        level: 2,
        parentCode: '237',
        hasChildren: true,
    },
    {
        code: '23711',
        name: 'Water and Sewer Line and Related Structures Construction',
        level: 3,
        parentCode: '2371',
        hasChildren: true,
    },
    {
        code: '237110',
        name: 'Water and Sewer Line and Related Structures Construction',
        level: 4,
        parentCode: '23711',
        hasChildren: false,
    },
    {
        code: '23712',
        name: 'Oil and Gas Pipeline and Related Structures Construction',
        level: 3,
        parentCode: '2371',
        hasChildren: true,
    },
    {
        code: '237120',
        name: 'Oil and Gas Pipeline and Related Structures Construction',
        level: 4,
        parentCode: '23712',
        hasChildren: false,
    },
    {
        code: '23713',
        name: 'Power and Communication Line and Related Structures Construction',
        level: 3,
        parentCode: '2371',
        hasChildren: true,
    },
    {
        code: '237130',
        name: 'Power and Communication Line and Related Structures Construction',
        level: 4,
        parentCode: '23713',
        hasChildren: false,
    },
    {
        code: '2372',
        name: 'Land Subdivision',
        level: 2,
        parentCode: '237',
        hasChildren: true,
    },
    {
        code: '23721',
        name: 'Land Subdivision',
        level: 3,
        parentCode: '2372',
        hasChildren: true,
    },
    {
        code: '237210',
        name: 'Land Subdivision',
        level: 4,
        parentCode: '23721',
        hasChildren: false,
    },
    {
        code: '2373',
        name: 'Highway, Street, and Bridge Construction',
        level: 2,
        parentCode: '237',
        hasChildren: true,
    },
    {
        code: '23731',
        name: 'Highway, Street, and Bridge Construction',
        level: 3,
        parentCode: '2373',
        hasChildren: true,
    },
    {
        code: '237310',
        name: 'Highway, Street, and Bridge Construction',
        level: 4,
        parentCode: '23731',
        hasChildren: false,
    },
    {
        code: '2379',
        name: 'Other Heavy and Civil Engineering Construction',
        level: 2,
        parentCode: '237',
        hasChildren: true,
    },
    {
        code: '23799',
        name: 'Other Heavy and Civil Engineering Construction',
        level: 3,
        parentCode: '2379',
        hasChildren: true,
    },
    {
        code: '237990',
        name: 'Other Heavy and Civil Engineering Construction',
        level: 4,
        parentCode: '23799',
        hasChildren: false,
    },
    {
        code: '238',
        name: 'Specialty Trade Contractors',
        level: 1,
        parentCode: '23',
        hasChildren: true,
    },
    {
        code: '2381',
        name: 'Foundation, Structure, and Building Exterior Contractors',
        level: 2,
        parentCode: '238',
        hasChildren: true,
    },
    {
        code: '23811',
        name: 'Poured Concrete Foundation and Structure Contractors',
        level: 3,
        parentCode: '2381',
        hasChildren: true,
    },
    {
        code: '238110',
        name: 'Poured Concrete Foundation and Structure Contractors',
        level: 4,
        parentCode: '23811',
        hasChildren: false,
    },
    {
        code: '23812',
        name: 'Structural Steel and Precast Concrete Contractors',
        level: 3,
        parentCode: '2381',
        hasChildren: true,
    },
    {
        code: '238120',
        name: 'Structural Steel and Precast Concrete Contractors',
        level: 4,
        parentCode: '23812',
        hasChildren: false,
    },
    {
        code: '23813',
        name: 'Framing Contractors',
        level: 3,
        parentCode: '2381',
        hasChildren: true,
    },
    {
        code: '238130',
        name: 'Framing Contractors',
        level: 4,
        parentCode: '23813',
        hasChildren: false,
    },
    {
        code: '23814',
        name: 'Masonry Contractors',
        level: 3,
        parentCode: '2381',
        hasChildren: true,
    },
    {
        code: '238140',
        name: 'Masonry Contractors',
        level: 4,
        parentCode: '23814',
        hasChildren: false,
    },
    {
        code: '23815',
        name: 'Glass and Glazing Contractors',
        level: 3,
        parentCode: '2381',
        hasChildren: true,
    },
    {
        code: '238150',
        name: 'Glass and Glazing Contractors',
        level: 4,
        parentCode: '23815',
        hasChildren: false,
    },
    {
        code: '23816',
        name: 'Roofing Contractors',
        level: 3,
        parentCode: '2381',
        hasChildren: true,
    },
    {
        code: '238160',
        name: 'Roofing Contractors',
        level: 4,
        parentCode: '23816',
        hasChildren: false,
    },
    {
        code: '23817',
        name: 'Siding Contractors',
        level: 3,
        parentCode: '2381',
        hasChildren: true,
    },
    {
        code: '238170',
        name: 'Siding Contractors',
        level: 4,
        parentCode: '23817',
        hasChildren: false,
    },
    {
        code: '23819',
        name: 'Other Foundation, Structure, and Building Exterior Contractors',
        level: 3,
        parentCode: '2381',
        hasChildren: true,
    },
    {
        code: '238190',
        name: 'Other Foundation, Structure, and Building Exterior Contractors',
        level: 4,
        parentCode: '23819',
        hasChildren: false,
    },
    {
        code: '2382',
        name: 'Building Equipment Contractors',
        level: 2,
        parentCode: '238',
        hasChildren: true,
    },
    {
        code: '23821',
        name: 'Electrical Contractors and Other Wiring Installation Contractors',
        level: 3,
        parentCode: '2382',
        hasChildren: true,
    },
    {
        code: '238210',
        name: 'Electrical Contractors and Other Wiring Installation Contractors',
        level: 4,
        parentCode: '23821',
        hasChildren: false,
    },
    {
        code: '23822',
        name: 'Plumbing, Heating, and Air-Conditioning Contractors',
        level: 3,
        parentCode: '2382',
        hasChildren: true,
    },
    {
        code: '238220',
        name: 'Plumbing, Heating, and Air-Conditioning Contractors',
        level: 4,
        parentCode: '23822',
        hasChildren: false,
    },
    {
        code: '23829',
        name: 'Other Building Equipment Contractors',
        level: 3,
        parentCode: '2382',
        hasChildren: true,
    },
    {
        code: '238290',
        name: 'Other Building Equipment Contractors',
        level: 4,
        parentCode: '23829',
        hasChildren: false,
    },
    {
        code: '2383',
        name: 'Building Finishing Contractors',
        level: 2,
        parentCode: '238',
        hasChildren: true,
    },
    {
        code: '23831',
        name: 'Drywall and Insulation Contractors',
        level: 3,
        parentCode: '2383',
        hasChildren: true,
    },
    {
        code: '238310',
        name: 'Drywall and Insulation Contractors',
        level: 4,
        parentCode: '23831',
        hasChildren: false,
    },
    {
        code: '23832',
        name: 'Painting and Wall Covering Contractors',
        level: 3,
        parentCode: '2383',
        hasChildren: true,
    },
    {
        code: '238320',
        name: 'Painting and Wall Covering Contractors',
        level: 4,
        parentCode: '23832',
        hasChildren: false,
    },
    {
        code: '23833',
        name: 'Flooring Contractors',
        level: 3,
        parentCode: '2383',
        hasChildren: true,
    },
    {
        code: '238330',
        name: 'Flooring Contractors',
        level: 4,
        parentCode: '23833',
        hasChildren: false,
    },
    {
        code: '23834',
        name: 'Tile and Terrazzo Contractors',
        level: 3,
        parentCode: '2383',
        hasChildren: true,
    },
    {
        code: '238340',
        name: 'Tile and Terrazzo Contractors',
        level: 4,
        parentCode: '23834',
        hasChildren: false,
    },
    {
        code: '23835',
        name: 'Finish Carpentry Contractors',
        level: 3,
        parentCode: '2383',
        hasChildren: true,
    },
    {
        code: '238350',
        name: 'Finish Carpentry Contractors',
        level: 4,
        parentCode: '23835',
        hasChildren: false,
    },
    {
        code: '23839',
        name: 'Other Building Finishing Contractors',
        level: 3,
        parentCode: '2383',
        hasChildren: true,
    },
    {
        code: '238390',
        name: 'Other Building Finishing Contractors',
        level: 4,
        parentCode: '23839',
        hasChildren: false,
    },
    {
        code: '2389',
        name: 'Other Specialty Trade Contractors',
        level: 2,
        parentCode: '238',
        hasChildren: true,
    },
    {
        code: '23891',
        name: 'Site Preparation Contractors',
        level: 3,
        parentCode: '2389',
        hasChildren: true,
    },
    {
        code: '238910',
        name: 'Site Preparation Contractors',
        level: 4,
        parentCode: '23891',
        hasChildren: false,
    },
    {
        code: '23899',
        name: 'All Other Specialty Trade Contractors',
        level: 3,
        parentCode: '2389',
        hasChildren: true,
    },
    {
        code: '238990',
        name: 'All Other Specialty Trade Contractors',
        level: 4,
        parentCode: '23899',
        hasChildren: false,
    },
    {
        code: '31-33',
        name: 'Manufacturing',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '311',
        name: 'Food Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3111',
        name: 'Animal Food Manufacturing',
        level: 2,
        parentCode: '311',
        hasChildren: true,
    },
    {
        code: '31111',
        name: 'Animal Food Manufacturing',
        level: 3,
        parentCode: '3111',
        hasChildren: true,
    },
    {
        code: '311111',
        name: 'Dog and Cat Food Manufacturing',
        level: 4,
        parentCode: '31111',
        hasChildren: false,
    },
    {
        code: '311119',
        name: 'Other Animal Food Manufacturing',
        level: 4,
        parentCode: '31111',
        hasChildren: false,
    },
    {
        code: '3112',
        name: 'Grain and Oilseed Milling',
        level: 2,
        parentCode: '311',
        hasChildren: true,
    },
    {
        code: '31121',
        name: 'Flour Milling and Malt Manufacturing',
        level: 3,
        parentCode: '3112',
        hasChildren: true,
    },
    {
        code: '311211',
        name: 'Flour Milling',
        level: 4,
        parentCode: '31121',
        hasChildren: false,
    },
    {
        code: '311212',
        name: 'Rice Milling',
        level: 4,
        parentCode: '31121',
        hasChildren: false,
    },
    {
        code: '311213',
        name: 'Malt Manufacturing',
        level: 4,
        parentCode: '31121',
        hasChildren: false,
    },
    {
        code: '31122',
        name: 'Starch and Vegetable Fats and Oils Manufacturing',
        level: 3,
        parentCode: '3112',
        hasChildren: true,
    },
    {
        code: '311221',
        name: 'Wet Corn Milling',
        level: 4,
        parentCode: '31122',
        hasChildren: false,
    },
    {
        code: '311224',
        name: 'Soybean and Other Oilseed Processing',
        level: 4,
        parentCode: '31122',
        hasChildren: false,
    },
    {
        code: '311225',
        name: 'Fats and Oils Refining and Blending',
        level: 4,
        parentCode: '31122',
        hasChildren: false,
    },
    {
        code: '31123',
        name: 'Breakfast Cereal Manufacturing',
        level: 3,
        parentCode: '3112',
        hasChildren: true,
    },
    {
        code: '311230',
        name: 'Breakfast Cereal Manufacturing',
        level: 4,
        parentCode: '31123',
        hasChildren: false,
    },
    {
        code: '3113',
        name: 'Sugar and Confectionery Product Manufacturing',
        level: 2,
        parentCode: '311',
        hasChildren: true,
    },
    {
        code: '31131',
        name: 'Sugar Manufacturing',
        level: 3,
        parentCode: '3113',
        hasChildren: true,
    },
    {
        code: '311313',
        name: 'Beet Sugar Manufacturing',
        level: 4,
        parentCode: '31131',
        hasChildren: false,
    },
    {
        code: '311314',
        name: 'Cane Sugar Manufacturing',
        level: 4,
        parentCode: '31131',
        hasChildren: false,
    },
    {
        code: '31134',
        name: 'Nonchocolate Confectionery Manufacturing',
        level: 3,
        parentCode: '3113',
        hasChildren: true,
    },
    {
        code: '311340',
        name: 'Nonchocolate Confectionery Manufacturing',
        level: 4,
        parentCode: '31134',
        hasChildren: false,
    },
    {
        code: '31135',
        name: 'Chocolate and Confectionery Manufacturing',
        level: 3,
        parentCode: '3113',
        hasChildren: true,
    },
    {
        code: '311351',
        name: 'Chocolate and Confectionery Manufacturing from Cacao Beans',
        level: 4,
        parentCode: '31135',
        hasChildren: false,
    },
    {
        code: '311352',
        name: 'Confectionery Manufacturing from Purchased Chocolate',
        level: 4,
        parentCode: '31135',
        hasChildren: false,
    },
    {
        code: '3114',
        name: 'Fruit and Vegetable Preserving and Specialty Food Manufacturing',
        level: 2,
        parentCode: '311',
        hasChildren: true,
    },
    {
        code: '31141',
        name: 'Frozen Food Manufacturing',
        level: 3,
        parentCode: '3114',
        hasChildren: true,
    },
    {
        code: '311411',
        name: 'Frozen Fruit, Juice, and Vegetable Manufacturing',
        level: 4,
        parentCode: '31141',
        hasChildren: false,
    },
    {
        code: '311412',
        name: 'Frozen Specialty Food Manufacturing',
        level: 4,
        parentCode: '31141',
        hasChildren: false,
    },
    {
        code: '31142',
        name: 'Fruit and Vegetable Canning, Pickling, and Drying',
        level: 3,
        parentCode: '3114',
        hasChildren: true,
    },
    {
        code: '311421',
        name: 'Fruit and Vegetable Canning',
        level: 4,
        parentCode: '31142',
        hasChildren: false,
    },
    {
        code: '311422',
        name: 'Specialty Canning',
        level: 4,
        parentCode: '31142',
        hasChildren: false,
    },
    {
        code: '311423',
        name: 'Dried and Dehydrated Food Manufacturing',
        level: 4,
        parentCode: '31142',
        hasChildren: false,
    },
    {
        code: '3115',
        name: 'Dairy Product Manufacturing',
        level: 2,
        parentCode: '311',
        hasChildren: true,
    },
    {
        code: '31151',
        name: 'Dairy Product (except Frozen) Manufacturing',
        level: 3,
        parentCode: '3115',
        hasChildren: true,
    },
    {
        code: '311511',
        name: 'Fluid Milk Manufacturing',
        level: 4,
        parentCode: '31151',
        hasChildren: false,
    },
    {
        code: '311512',
        name: 'Creamery Butter Manufacturing',
        level: 4,
        parentCode: '31151',
        hasChildren: false,
    },
    {
        code: '311513',
        name: 'Cheese Manufacturing',
        level: 4,
        parentCode: '31151',
        hasChildren: false,
    },
    {
        code: '311514',
        name: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing',
        level: 4,
        parentCode: '31151',
        hasChildren: false,
    },
    {
        code: '31152',
        name: 'Ice Cream and Frozen Dessert Manufacturing',
        level: 3,
        parentCode: '3115',
        hasChildren: true,
    },
    {
        code: '311520',
        name: 'Ice Cream and Frozen Dessert Manufacturing',
        level: 4,
        parentCode: '31152',
        hasChildren: false,
    },
    {
        code: '3116',
        name: 'Animal Slaughtering and Processing',
        level: 2,
        parentCode: '311',
        hasChildren: true,
    },
    {
        code: '31161',
        name: 'Animal Slaughtering and Processing',
        level: 3,
        parentCode: '3116',
        hasChildren: true,
    },
    {
        code: '311611',
        name: 'Animal (except Poultry) Slaughtering',
        level: 4,
        parentCode: '31161',
        hasChildren: false,
    },
    {
        code: '311612',
        name: 'Meat Processed from Carcasses',
        level: 4,
        parentCode: '31161',
        hasChildren: false,
    },
    {
        code: '311613',
        name: 'Rendering and Meat Byproduct Processing',
        level: 4,
        parentCode: '31161',
        hasChildren: false,
    },
    {
        code: '311615',
        name: 'Poultry Processing',
        level: 4,
        parentCode: '31161',
        hasChildren: false,
    },
    {
        code: '3117',
        name: 'Seafood Product Preparation and Packaging',
        level: 2,
        parentCode: '311',
        hasChildren: true,
    },
    {
        code: '31171',
        name: 'Seafood Product Preparation and Packaging',
        level: 3,
        parentCode: '3117',
        hasChildren: true,
    },
    {
        code: '311710',
        name: 'Seafood Product Preparation and Packaging',
        level: 4,
        parentCode: '31171',
        hasChildren: false,
    },
    {
        code: '3118',
        name: 'Bakeries and Tortilla Manufacturing',
        level: 2,
        parentCode: '311',
        hasChildren: true,
    },
    {
        code: '31181',
        name: 'Bread and Bakery Product Manufacturing',
        level: 3,
        parentCode: '3118',
        hasChildren: true,
    },
    {
        code: '311811',
        name: 'Retail Bakeries',
        level: 4,
        parentCode: '31181',
        hasChildren: false,
    },
    {
        code: '311812',
        name: 'Commercial Bakeries',
        level: 4,
        parentCode: '31181',
        hasChildren: false,
    },
    {
        code: '311813',
        name: 'Frozen Cakes, Pies, and Other Pastries Manufacturing',
        level: 4,
        parentCode: '31181',
        hasChildren: false,
    },
    {
        code: '31182',
        name: 'Cookie, Cracker, and Pasta Manufacturing',
        level: 3,
        parentCode: '3118',
        hasChildren: true,
    },
    {
        code: '311821',
        name: 'Cookie and Cracker Manufacturing',
        level: 4,
        parentCode: '31182',
        hasChildren: false,
    },
    {
        code: '311824',
        name: 'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour',
        level: 4,
        parentCode: '31182',
        hasChildren: false,
    },
    {
        code: '31183',
        name: 'Tortilla Manufacturing',
        level: 3,
        parentCode: '3118',
        hasChildren: true,
    },
    {
        code: '311830',
        name: 'Tortilla Manufacturing',
        level: 4,
        parentCode: '31183',
        hasChildren: false,
    },
    {
        code: '3119',
        name: 'Other Food Manufacturing',
        level: 2,
        parentCode: '311',
        hasChildren: true,
    },
    {
        code: '31191',
        name: 'Snack Food Manufacturing',
        level: 3,
        parentCode: '3119',
        hasChildren: true,
    },
    {
        code: '311911',
        name: 'Roasted Nuts and Peanut Butter Manufacturing',
        level: 4,
        parentCode: '31191',
        hasChildren: false,
    },
    {
        code: '311919',
        name: 'Other Snack Food Manufacturing',
        level: 4,
        parentCode: '31191',
        hasChildren: false,
    },
    {
        code: '31192',
        name: 'Coffee and Tea Manufacturing',
        level: 3,
        parentCode: '3119',
        hasChildren: true,
    },
    {
        code: '311920',
        name: 'Coffee and Tea Manufacturing',
        level: 4,
        parentCode: '31192',
        hasChildren: false,
    },
    {
        code: '31193',
        name: 'Flavoring Syrup and Concentrate Manufacturing',
        level: 3,
        parentCode: '3119',
        hasChildren: true,
    },
    {
        code: '311930',
        name: 'Flavoring Syrup and Concentrate Manufacturing',
        level: 4,
        parentCode: '31193',
        hasChildren: false,
    },
    {
        code: '31194',
        name: 'Seasoning and Dressing Manufacturing',
        level: 3,
        parentCode: '3119',
        hasChildren: true,
    },
    {
        code: '311941',
        name: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing',
        level: 4,
        parentCode: '31194',
        hasChildren: false,
    },
    {
        code: '311942',
        name: 'Spice and Extract Manufacturing',
        level: 4,
        parentCode: '31194',
        hasChildren: false,
    },
    {
        code: '31199',
        name: 'All Other Food Manufacturing',
        level: 3,
        parentCode: '3119',
        hasChildren: true,
    },
    {
        code: '311991',
        name: 'Perishable Prepared Food Manufacturing',
        level: 4,
        parentCode: '31199',
        hasChildren: false,
    },
    {
        code: '311999',
        name: 'All Other Miscellaneous Food Manufacturing',
        level: 4,
        parentCode: '31199',
        hasChildren: false,
    },
    {
        code: '312',
        name: 'Beverage and Tobacco Product Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3121',
        name: 'Beverage Manufacturing',
        level: 2,
        parentCode: '312',
        hasChildren: true,
    },
    {
        code: '31211',
        name: 'Soft Drink and Ice Manufacturing',
        level: 3,
        parentCode: '3121',
        hasChildren: true,
    },
    {
        code: '312111',
        name: 'Soft Drink Manufacturing',
        level: 4,
        parentCode: '31211',
        hasChildren: false,
    },
    {
        code: '312112',
        name: 'Bottled Water Manufacturing',
        level: 4,
        parentCode: '31211',
        hasChildren: false,
    },
    {
        code: '312113',
        name: 'Ice Manufacturing',
        level: 4,
        parentCode: '31211',
        hasChildren: false,
    },
    {
        code: '31212',
        name: 'Breweries',
        level: 3,
        parentCode: '3121',
        hasChildren: true,
    },
    {
        code: '312120',
        name: 'Breweries',
        level: 4,
        parentCode: '31212',
        hasChildren: false,
    },
    {
        code: '31213',
        name: 'Wineries',
        level: 3,
        parentCode: '3121',
        hasChildren: true,
    },
    {
        code: '312130',
        name: 'Wineries',
        level: 4,
        parentCode: '31213',
        hasChildren: false,
    },
    {
        code: '31214',
        name: 'Distilleries',
        level: 3,
        parentCode: '3121',
        hasChildren: true,
    },
    {
        code: '312140',
        name: 'Distilleries',
        level: 4,
        parentCode: '31214',
        hasChildren: false,
    },
    {
        code: '3122',
        name: 'Tobacco Manufacturing',
        level: 2,
        parentCode: '312',
        hasChildren: true,
    },
    {
        code: '31223',
        name: 'Tobacco Manufacturing',
        level: 3,
        parentCode: '3122',
        hasChildren: true,
    },
    {
        code: '312230',
        name: 'Tobacco Manufacturing',
        level: 4,
        parentCode: '31223',
        hasChildren: false,
    },
    {
        code: '313',
        name: 'Textile Mills',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3131',
        name: 'Fiber, Yarn, and Thread Mills',
        level: 2,
        parentCode: '313',
        hasChildren: true,
    },
    {
        code: '31311',
        name: 'Fiber, Yarn, and Thread Mills',
        level: 3,
        parentCode: '3131',
        hasChildren: true,
    },
    {
        code: '313110',
        name: 'Fiber, Yarn, and Thread Mills',
        level: 4,
        parentCode: '31311',
        hasChildren: false,
    },
    {
        code: '3132',
        name: 'Fabric Mills',
        level: 2,
        parentCode: '313',
        hasChildren: true,
    },
    {
        code: '31321',
        name: 'Broadwoven Fabric Mills',
        level: 3,
        parentCode: '3132',
        hasChildren: true,
    },
    {
        code: '313210',
        name: 'Broadwoven Fabric Mills',
        level: 4,
        parentCode: '31321',
        hasChildren: false,
    },
    {
        code: '31322',
        name: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
        level: 3,
        parentCode: '3132',
        hasChildren: true,
    },
    {
        code: '313220',
        name: 'Narrow Fabric Mills and Schiffli Machine Embroidery',
        level: 4,
        parentCode: '31322',
        hasChildren: false,
    },
    {
        code: '31323',
        name: 'Nonwoven Fabric Mills',
        level: 3,
        parentCode: '3132',
        hasChildren: true,
    },
    {
        code: '313230',
        name: 'Nonwoven Fabric Mills',
        level: 4,
        parentCode: '31323',
        hasChildren: false,
    },
    {
        code: '31324',
        name: 'Knit Fabric Mills',
        level: 3,
        parentCode: '3132',
        hasChildren: true,
    },
    {
        code: '313240',
        name: 'Knit Fabric Mills',
        level: 4,
        parentCode: '31324',
        hasChildren: false,
    },
    {
        code: '3133',
        name: 'Textile and Fabric Finishing and Fabric Coating Mills',
        level: 2,
        parentCode: '313',
        hasChildren: true,
    },
    {
        code: '31331',
        name: 'Textile and Fabric Finishing Mills',
        level: 3,
        parentCode: '3133',
        hasChildren: true,
    },
    {
        code: '313310',
        name: 'Textile and Fabric Finishing Mills',
        level: 4,
        parentCode: '31331',
        hasChildren: false,
    },
    {
        code: '31332',
        name: 'Fabric Coating Mills',
        level: 3,
        parentCode: '3133',
        hasChildren: true,
    },
    {
        code: '313320',
        name: 'Fabric Coating Mills',
        level: 4,
        parentCode: '31332',
        hasChildren: false,
    },
    {
        code: '314',
        name: 'Textile Product Mills',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3141',
        name: 'Textile Furnishings Mills',
        level: 2,
        parentCode: '314',
        hasChildren: true,
    },
    {
        code: '31411',
        name: 'Carpet and Rug Mills',
        level: 3,
        parentCode: '3141',
        hasChildren: true,
    },
    {
        code: '314110',
        name: 'Carpet and Rug Mills',
        level: 4,
        parentCode: '31411',
        hasChildren: false,
    },
    {
        code: '31412',
        name: 'Curtain and Linen Mills',
        level: 3,
        parentCode: '3141',
        hasChildren: true,
    },
    {
        code: '314120',
        name: 'Curtain and Linen Mills',
        level: 4,
        parentCode: '31412',
        hasChildren: false,
    },
    {
        code: '3149',
        name: 'Other Textile Product Mills',
        level: 2,
        parentCode: '314',
        hasChildren: true,
    },
    {
        code: '31491',
        name: 'Textile Bag and Canvas Mills',
        level: 3,
        parentCode: '3149',
        hasChildren: true,
    },
    {
        code: '314910',
        name: 'Textile Bag and Canvas Mills',
        level: 4,
        parentCode: '31491',
        hasChildren: false,
    },
    {
        code: '31499',
        name: 'All Other Textile Product Mills',
        level: 3,
        parentCode: '3149',
        hasChildren: true,
    },
    {
        code: '314994',
        name: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills',
        level: 4,
        parentCode: '31499',
        hasChildren: false,
    },
    {
        code: '314999',
        name: 'All Other Miscellaneous Textile Product Mills',
        level: 4,
        parentCode: '31499',
        hasChildren: false,
    },
    {
        code: '315',
        name: 'Apparel Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3151',
        name: 'Apparel Knitting Mills',
        level: 2,
        parentCode: '315',
        hasChildren: true,
    },
    {
        code: '31511',
        name: 'Hosiery and Sock Mills',
        level: 3,
        parentCode: '3151',
        hasChildren: true,
    },
    {
        code: '315110',
        name: 'Hosiery and Sock Mills',
        level: 4,
        parentCode: '31511',
        hasChildren: false,
    },
    {
        code: '31519',
        name: 'Other Apparel Knitting Mills',
        level: 3,
        parentCode: '3151',
        hasChildren: true,
    },
    {
        code: '315190',
        name: 'Other Apparel Knitting Mills',
        level: 4,
        parentCode: '31519',
        hasChildren: false,
    },
    {
        code: '3152',
        name: 'Cut and Sew Apparel Manufacturing',
        level: 2,
        parentCode: '315',
        hasChildren: true,
    },
    {
        code: '31521',
        name: 'Cut and Sew Apparel Contractors',
        level: 3,
        parentCode: '3152',
        hasChildren: true,
    },
    {
        code: '315210',
        name: 'Cut and Sew Apparel Contractors',
        level: 4,
        parentCode: '31521',
        hasChildren: false,
    },
    {
        code: '31522',
        name: 'Men’s and Boys’ Cut and Sew Apparel Manufacturing',
        level: 3,
        parentCode: '3152',
        hasChildren: true,
    },
    {
        code: '315220',
        name: 'Men’s and Boys’ Cut and Sew Apparel Manufacturing',
        level: 4,
        parentCode: '31522',
        hasChildren: false,
    },
    {
        code: '31524',
        name: 'Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing',
        level: 3,
        parentCode: '3152',
        hasChildren: true,
    },
    {
        code: '315240',
        name: 'Women’s, Girls’, and Infants’ Cut and Sew Apparel Manufacturing',
        level: 4,
        parentCode: '31524',
        hasChildren: false,
    },
    {
        code: '31528',
        name: 'Other Cut and Sew Apparel Manufacturing',
        level: 3,
        parentCode: '3152',
        hasChildren: true,
    },
    {
        code: '315280',
        name: 'Other Cut and Sew Apparel Manufacturing',
        level: 4,
        parentCode: '31528',
        hasChildren: false,
    },
    {
        code: '3159',
        name: 'Apparel Accessories and Other Apparel Manufacturing',
        level: 2,
        parentCode: '315',
        hasChildren: true,
    },
    {
        code: '31599',
        name: 'Apparel Accessories and Other Apparel Manufacturing',
        level: 3,
        parentCode: '3159',
        hasChildren: true,
    },
    {
        code: '315990',
        name: 'Apparel Accessories and Other Apparel Manufacturing',
        level: 4,
        parentCode: '31599',
        hasChildren: false,
    },
    {
        code: '316',
        name: 'Leather and Allied Product Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3161',
        name: 'Leather and Hide Tanning and Finishing',
        level: 2,
        parentCode: '316',
        hasChildren: true,
    },
    {
        code: '31611',
        name: 'Leather and Hide Tanning and Finishing',
        level: 3,
        parentCode: '3161',
        hasChildren: true,
    },
    {
        code: '316110',
        name: 'Leather and Hide Tanning and Finishing',
        level: 4,
        parentCode: '31611',
        hasChildren: false,
    },
    {
        code: '3162',
        name: 'Footwear Manufacturing',
        level: 2,
        parentCode: '316',
        hasChildren: true,
    },
    {
        code: '31621',
        name: 'Footwear Manufacturing',
        level: 3,
        parentCode: '3162',
        hasChildren: true,
    },
    {
        code: '316210',
        name: 'Footwear Manufacturing',
        level: 4,
        parentCode: '31621',
        hasChildren: false,
    },
    {
        code: '3169',
        name: 'Other Leather and Allied Product Manufacturing',
        level: 2,
        parentCode: '316',
        hasChildren: true,
    },
    {
        code: '31699',
        name: 'Other Leather and Allied Product Manufacturing',
        level: 3,
        parentCode: '3169',
        hasChildren: true,
    },
    {
        code: '316992',
        name: "Women's Handbag and Purse Manufacturing ",
        level: 4,
        parentCode: '31699',
        hasChildren: false,
    },
    {
        code: '316998',
        name: 'All Other Leather Good and Allied Product Manufacturing',
        level: 4,
        parentCode: '31699',
        hasChildren: false,
    },
    {
        code: '321',
        name: 'Wood Product Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3211',
        name: 'Sawmills and Wood Preservation',
        level: 2,
        parentCode: '321',
        hasChildren: true,
    },
    {
        code: '32111',
        name: 'Sawmills and Wood Preservation',
        level: 3,
        parentCode: '3211',
        hasChildren: true,
    },
    {
        code: '321113',
        name: 'Sawmills',
        level: 4,
        parentCode: '32111',
        hasChildren: false,
    },
    {
        code: '321114',
        name: 'Wood Preservation',
        level: 4,
        parentCode: '32111',
        hasChildren: false,
    },
    {
        code: '3212',
        name: 'Veneer, Plywood, and Engineered Wood Product Manufacturing',
        level: 2,
        parentCode: '321',
        hasChildren: true,
    },
    {
        code: '32121',
        name: 'Veneer, Plywood, and Engineered Wood Product Manufacturing',
        level: 3,
        parentCode: '3212',
        hasChildren: true,
    },
    {
        code: '321211',
        name: 'Hardwood Veneer and Plywood Manufacturing',
        level: 4,
        parentCode: '32121',
        hasChildren: false,
    },
    {
        code: '321212',
        name: 'Softwood Veneer and Plywood Manufacturing',
        level: 4,
        parentCode: '32121',
        hasChildren: false,
    },
    {
        code: '321213',
        name: 'Engineered Wood Member (except Truss) Manufacturing',
        level: 4,
        parentCode: '32121',
        hasChildren: false,
    },
    {
        code: '321214',
        name: 'Truss Manufacturing',
        level: 4,
        parentCode: '32121',
        hasChildren: false,
    },
    {
        code: '321219',
        name: 'Reconstituted Wood Product Manufacturing',
        level: 4,
        parentCode: '32121',
        hasChildren: false,
    },
    {
        code: '3219',
        name: 'Other Wood Product Manufacturing',
        level: 2,
        parentCode: '321',
        hasChildren: true,
    },
    {
        code: '32191',
        name: 'Millwork',
        level: 3,
        parentCode: '3219',
        hasChildren: true,
    },
    {
        code: '321911',
        name: 'Wood Window and Door Manufacturing',
        level: 4,
        parentCode: '32191',
        hasChildren: false,
    },
    {
        code: '321912',
        name: 'Cut Stock, Resawing Lumber, and Planing',
        level: 4,
        parentCode: '32191',
        hasChildren: false,
    },
    {
        code: '321918',
        name: 'Other Millwork (including Flooring)',
        level: 4,
        parentCode: '32191',
        hasChildren: false,
    },
    {
        code: '32192',
        name: 'Wood Container and Pallet Manufacturing',
        level: 3,
        parentCode: '3219',
        hasChildren: true,
    },
    {
        code: '321920',
        name: 'Wood Container and Pallet Manufacturing',
        level: 4,
        parentCode: '32192',
        hasChildren: false,
    },
    {
        code: '32199',
        name: 'All Other Wood Product Manufacturing',
        level: 3,
        parentCode: '3219',
        hasChildren: true,
    },
    {
        code: '321991',
        name: 'Manufactured Home (Mobile Home) Manufacturing',
        level: 4,
        parentCode: '32199',
        hasChildren: false,
    },
    {
        code: '321992',
        name: 'Prefabricated Wood Building Manufacturing',
        level: 4,
        parentCode: '32199',
        hasChildren: false,
    },
    {
        code: '321999',
        name: 'All Other Miscellaneous Wood Product Manufacturing',
        level: 4,
        parentCode: '32199',
        hasChildren: false,
    },
    {
        code: '322',
        name: 'Paper Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3221',
        name: 'Pulp, Paper, and Paperboard Mills',
        level: 2,
        parentCode: '322',
        hasChildren: true,
    },
    {
        code: '32211',
        name: 'Pulp Mills',
        level: 3,
        parentCode: '3221',
        hasChildren: true,
    },
    {
        code: '322110',
        name: 'Pulp Mills',
        level: 4,
        parentCode: '32211',
        hasChildren: false,
    },
    {
        code: '32212',
        name: 'Paper Mills',
        level: 3,
        parentCode: '3221',
        hasChildren: true,
    },
    {
        code: '322121',
        name: 'Paper (except Newsprint) Mills',
        level: 4,
        parentCode: '32212',
        hasChildren: false,
    },
    {
        code: '322122',
        name: 'Newsprint Mills',
        level: 4,
        parentCode: '32212',
        hasChildren: false,
    },
    {
        code: '32213',
        name: 'Paperboard Mills',
        level: 3,
        parentCode: '3221',
        hasChildren: true,
    },
    {
        code: '322130',
        name: 'Paperboard Mills',
        level: 4,
        parentCode: '32213',
        hasChildren: false,
    },
    {
        code: '3222',
        name: 'Converted Paper Product Manufacturing',
        level: 2,
        parentCode: '322',
        hasChildren: true,
    },
    {
        code: '32221',
        name: 'Paperboard Container Manufacturing',
        level: 3,
        parentCode: '3222',
        hasChildren: true,
    },
    {
        code: '322211',
        name: 'Corrugated and Solid Fiber Box Manufacturing',
        level: 4,
        parentCode: '32221',
        hasChildren: false,
    },
    {
        code: '322212',
        name: 'Folding Paperboard Box Manufacturing',
        level: 4,
        parentCode: '32221',
        hasChildren: false,
    },
    {
        code: '322219',
        name: 'Other Paperboard Container Manufacturing',
        level: 4,
        parentCode: '32221',
        hasChildren: false,
    },
    {
        code: '32222',
        name: 'Paper Bag and Coated and Treated Paper Manufacturing',
        level: 3,
        parentCode: '3222',
        hasChildren: true,
    },
    {
        code: '322220',
        name: 'Paper Bag and Coated and Treated Paper Manufacturing',
        level: 4,
        parentCode: '32222',
        hasChildren: false,
    },
    {
        code: '32223',
        name: 'Stationery Product Manufacturing',
        level: 3,
        parentCode: '3222',
        hasChildren: true,
    },
    {
        code: '322230',
        name: 'Stationery Product Manufacturing',
        level: 4,
        parentCode: '32223',
        hasChildren: false,
    },
    {
        code: '32229',
        name: 'Other Converted Paper Product Manufacturing',
        level: 3,
        parentCode: '3222',
        hasChildren: true,
    },
    {
        code: '322291',
        name: 'Sanitary Paper Product Manufacturing',
        level: 4,
        parentCode: '32229',
        hasChildren: false,
    },
    {
        code: '322299',
        name: 'All Other Converted Paper Product Manufacturing',
        level: 4,
        parentCode: '32229',
        hasChildren: false,
    },
    {
        code: '323',
        name: 'Printing and Related Support Activities',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3231',
        name: 'Printing and Related Support Activities',
        level: 2,
        parentCode: '323',
        hasChildren: true,
    },
    {
        code: '32311',
        name: 'Printing',
        level: 3,
        parentCode: '3231',
        hasChildren: true,
    },
    {
        code: '323111',
        name: 'Commercial Printing (except Screen and Books)',
        level: 4,
        parentCode: '32311',
        hasChildren: false,
    },
    {
        code: '323113',
        name: 'Commercial Screen Printing',
        level: 4,
        parentCode: '32311',
        hasChildren: false,
    },
    {
        code: '323117',
        name: 'Books Printing',
        level: 4,
        parentCode: '32311',
        hasChildren: false,
    },
    {
        code: '32312',
        name: 'Support Activities for Printing',
        level: 3,
        parentCode: '3231',
        hasChildren: true,
    },
    {
        code: '323120',
        name: 'Support Activities for Printing',
        level: 4,
        parentCode: '32312',
        hasChildren: false,
    },
    {
        code: '324',
        name: 'Petroleum and Coal Products Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3241',
        name: 'Petroleum and Coal Products Manufacturing',
        level: 2,
        parentCode: '324',
        hasChildren: true,
    },
    {
        code: '32411',
        name: 'Petroleum Refineries',
        level: 3,
        parentCode: '3241',
        hasChildren: true,
    },
    {
        code: '324110',
        name: 'Petroleum Refineries',
        level: 4,
        parentCode: '32411',
        hasChildren: false,
    },
    {
        code: '32412',
        name: 'Asphalt Paving, Roofing, and Saturated Materials Manufacturing',
        level: 3,
        parentCode: '3241',
        hasChildren: true,
    },
    {
        code: '324121',
        name: 'Asphalt Paving Mixture and Block Manufacturing',
        level: 4,
        parentCode: '32412',
        hasChildren: false,
    },
    {
        code: '324122',
        name: 'Asphalt Shingle and Coating Materials Manufacturing',
        level: 4,
        parentCode: '32412',
        hasChildren: false,
    },
    {
        code: '32419',
        name: 'Other Petroleum and Coal Products Manufacturing',
        level: 3,
        parentCode: '3241',
        hasChildren: true,
    },
    {
        code: '324191',
        name: 'Petroleum Lubricating Oil and Grease Manufacturing',
        level: 4,
        parentCode: '32419',
        hasChildren: false,
    },
    {
        code: '324199',
        name: 'All Other Petroleum and Coal Products Manufacturing',
        level: 4,
        parentCode: '32419',
        hasChildren: false,
    },
    {
        code: '325',
        name: 'Chemical Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3251',
        name: 'Basic Chemical Manufacturing',
        level: 2,
        parentCode: '325',
        hasChildren: true,
    },
    {
        code: '32511',
        name: 'Petrochemical Manufacturing',
        level: 3,
        parentCode: '3251',
        hasChildren: true,
    },
    {
        code: '325110',
        name: 'Petrochemical Manufacturing',
        level: 4,
        parentCode: '32511',
        hasChildren: false,
    },
    {
        code: '32512',
        name: 'Industrial Gas Manufacturing',
        level: 3,
        parentCode: '3251',
        hasChildren: true,
    },
    {
        code: '325120',
        name: 'Industrial Gas Manufacturing',
        level: 4,
        parentCode: '32512',
        hasChildren: false,
    },
    {
        code: '32513',
        name: 'Synthetic Dye and Pigment Manufacturing',
        level: 3,
        parentCode: '3251',
        hasChildren: true,
    },
    {
        code: '325130',
        name: 'Synthetic Dye and Pigment Manufacturing',
        level: 4,
        parentCode: '32513',
        hasChildren: false,
    },
    {
        code: '32518',
        name: 'Other Basic Inorganic Chemical Manufacturing',
        level: 3,
        parentCode: '3251',
        hasChildren: true,
    },
    {
        code: '325180',
        name: 'Other Basic Inorganic Chemical Manufacturing',
        level: 4,
        parentCode: '32518',
        hasChildren: false,
    },
    {
        code: '32519',
        name: 'Other Basic Organic Chemical Manufacturing',
        level: 3,
        parentCode: '3251',
        hasChildren: true,
    },
    {
        code: '325193',
        name: 'Ethyl Alcohol Manufacturing',
        level: 4,
        parentCode: '32519',
        hasChildren: false,
    },
    {
        code: '325194',
        name: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing',
        level: 4,
        parentCode: '32519',
        hasChildren: false,
    },
    {
        code: '325199',
        name: 'All Other Basic Organic Chemical Manufacturing',
        level: 4,
        parentCode: '32519',
        hasChildren: false,
    },
    {
        code: '3252',
        name: 'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing',
        level: 2,
        parentCode: '325',
        hasChildren: true,
    },
    {
        code: '32521',
        name: 'Resin and Synthetic Rubber Manufacturing',
        level: 3,
        parentCode: '3252',
        hasChildren: true,
    },
    {
        code: '325211',
        name: 'Plastics Material and Resin Manufacturing',
        level: 4,
        parentCode: '32521',
        hasChildren: false,
    },
    {
        code: '325212',
        name: 'Synthetic Rubber Manufacturing',
        level: 4,
        parentCode: '32521',
        hasChildren: false,
    },
    {
        code: '32522',
        name: 'Artificial and Synthetic Fibers and Filaments Manufacturing',
        level: 3,
        parentCode: '3252',
        hasChildren: true,
    },
    {
        code: '325220',
        name: 'Artificial and Synthetic Fibers and Filaments Manufacturing',
        level: 4,
        parentCode: '32522',
        hasChildren: false,
    },
    {
        code: '3253',
        name: 'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing',
        level: 2,
        parentCode: '325',
        hasChildren: true,
    },
    {
        code: '32531',
        name: 'Fertilizer Manufacturing',
        level: 3,
        parentCode: '3253',
        hasChildren: true,
    },
    {
        code: '325311',
        name: 'Nitrogenous Fertilizer Manufacturing',
        level: 4,
        parentCode: '32531',
        hasChildren: false,
    },
    {
        code: '325312',
        name: 'Phosphatic Fertilizer Manufacturing',
        level: 4,
        parentCode: '32531',
        hasChildren: false,
    },
    {
        code: '325314',
        name: 'Fertilizer (Mixing Only) Manufacturing',
        level: 4,
        parentCode: '32531',
        hasChildren: false,
    },
    {
        code: '32532',
        name: 'Pesticide and Other Agricultural Chemical Manufacturing',
        level: 3,
        parentCode: '3253',
        hasChildren: true,
    },
    {
        code: '325320',
        name: 'Pesticide and Other Agricultural Chemical Manufacturing',
        level: 4,
        parentCode: '32532',
        hasChildren: false,
    },
    {
        code: '3254',
        name: 'Pharmaceutical and Medicine Manufacturing',
        level: 2,
        parentCode: '325',
        hasChildren: true,
    },
    {
        code: '32541',
        name: 'Pharmaceutical and Medicine Manufacturing',
        level: 3,
        parentCode: '3254',
        hasChildren: true,
    },
    {
        code: '325411',
        name: 'Medicinal and Botanical Manufacturing',
        level: 4,
        parentCode: '32541',
        hasChildren: false,
    },
    {
        code: '325412',
        name: 'Pharmaceutical Preparation Manufacturing',
        level: 4,
        parentCode: '32541',
        hasChildren: false,
    },
    {
        code: '325413',
        name: 'In-Vitro Diagnostic Substance Manufacturing',
        level: 4,
        parentCode: '32541',
        hasChildren: false,
    },
    {
        code: '325414',
        name: 'Biological Product (except Diagnostic) Manufacturing',
        level: 4,
        parentCode: '32541',
        hasChildren: false,
    },
    {
        code: '3255',
        name: 'Paint, Coating, and Adhesive Manufacturing',
        level: 2,
        parentCode: '325',
        hasChildren: true,
    },
    {
        code: '32551',
        name: 'Paint and Coating Manufacturing',
        level: 3,
        parentCode: '3255',
        hasChildren: true,
    },
    {
        code: '325510',
        name: 'Paint and Coating Manufacturing',
        level: 4,
        parentCode: '32551',
        hasChildren: false,
    },
    {
        code: '32552',
        name: 'Adhesive Manufacturing',
        level: 3,
        parentCode: '3255',
        hasChildren: true,
    },
    {
        code: '325520',
        name: 'Adhesive Manufacturing',
        level: 4,
        parentCode: '32552',
        hasChildren: false,
    },
    {
        code: '3256',
        name: 'Soap, Cleaning Compound, and Toilet Preparation Manufacturing',
        level: 2,
        parentCode: '325',
        hasChildren: true,
    },
    {
        code: '32561',
        name: 'Soap and Cleaning Compound Manufacturing',
        level: 3,
        parentCode: '3256',
        hasChildren: true,
    },
    {
        code: '325611',
        name: 'Soap and Other Detergent Manufacturing',
        level: 4,
        parentCode: '32561',
        hasChildren: false,
    },
    {
        code: '325612',
        name: 'Polish and Other Sanitation Good Manufacturing',
        level: 4,
        parentCode: '32561',
        hasChildren: false,
    },
    {
        code: '325613',
        name: 'Surface Active Agent Manufacturing',
        level: 4,
        parentCode: '32561',
        hasChildren: false,
    },
    {
        code: '32562',
        name: 'Toilet Preparation Manufacturing',
        level: 3,
        parentCode: '3256',
        hasChildren: true,
    },
    {
        code: '325620',
        name: 'Toilet Preparation Manufacturing',
        level: 4,
        parentCode: '32562',
        hasChildren: false,
    },
    {
        code: '3259',
        name: 'Other Chemical Product and Preparation Manufacturing',
        level: 2,
        parentCode: '325',
        hasChildren: true,
    },
    {
        code: '32591',
        name: 'Printing Ink Manufacturing',
        level: 3,
        parentCode: '3259',
        hasChildren: true,
    },
    {
        code: '325910',
        name: 'Printing Ink Manufacturing',
        level: 4,
        parentCode: '32591',
        hasChildren: false,
    },
    {
        code: '32592',
        name: 'Explosives Manufacturing',
        level: 3,
        parentCode: '3259',
        hasChildren: true,
    },
    {
        code: '325920',
        name: 'Explosives Manufacturing',
        level: 4,
        parentCode: '32592',
        hasChildren: false,
    },
    {
        code: '32599',
        name: 'All Other Chemical Product and Preparation Manufacturing',
        level: 3,
        parentCode: '3259',
        hasChildren: true,
    },
    {
        code: '325991',
        name: 'Custom Compounding of Purchased Resins',
        level: 4,
        parentCode: '32599',
        hasChildren: false,
    },
    {
        code: '325992',
        name: 'Photographic Film, Paper, Plate, and Chemical Manufacturing',
        level: 4,
        parentCode: '32599',
        hasChildren: false,
    },
    {
        code: '325998',
        name: 'All Other Miscellaneous Chemical Product and Preparation Manufacturing',
        level: 4,
        parentCode: '32599',
        hasChildren: false,
    },
    {
        code: '326',
        name: 'Plastics and Rubber Products Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3261',
        name: 'Plastics Product Manufacturing',
        level: 2,
        parentCode: '326',
        hasChildren: true,
    },
    {
        code: '32611',
        name: 'Plastics Packaging Materials and Unlaminated Film and Sheet Manufacturing',
        level: 3,
        parentCode: '3261',
        hasChildren: true,
    },
    {
        code: '326111',
        name: 'Plastics Bag and Pouch Manufacturing',
        level: 4,
        parentCode: '32611',
        hasChildren: false,
    },
    {
        code: '326112',
        name: 'Plastics Packaging Film and Sheet (including Laminated) Manufacturing',
        level: 4,
        parentCode: '32611',
        hasChildren: false,
    },
    {
        code: '326113',
        name: 'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing',
        level: 4,
        parentCode: '32611',
        hasChildren: false,
    },
    {
        code: '32612',
        name: 'Plastics Pipe, Pipe Fitting, and Unlaminated Profile Shape Manufacturing',
        level: 3,
        parentCode: '3261',
        hasChildren: true,
    },
    {
        code: '326121',
        name: 'Unlaminated Plastics Profile Shape Manufacturing',
        level: 4,
        parentCode: '32612',
        hasChildren: false,
    },
    {
        code: '326122',
        name: 'Plastics Pipe and Pipe Fitting Manufacturing',
        level: 4,
        parentCode: '32612',
        hasChildren: false,
    },
    {
        code: '32613',
        name: 'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
        level: 3,
        parentCode: '3261',
        hasChildren: true,
    },
    {
        code: '326130',
        name: 'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing',
        level: 4,
        parentCode: '32613',
        hasChildren: false,
    },
    {
        code: '32614',
        name: 'Polystyrene Foam Product Manufacturing',
        level: 3,
        parentCode: '3261',
        hasChildren: true,
    },
    {
        code: '326140',
        name: 'Polystyrene Foam Product Manufacturing',
        level: 4,
        parentCode: '32614',
        hasChildren: false,
    },
    {
        code: '32615',
        name: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
        level: 3,
        parentCode: '3261',
        hasChildren: true,
    },
    {
        code: '326150',
        name: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing',
        level: 4,
        parentCode: '32615',
        hasChildren: false,
    },
    {
        code: '32616',
        name: 'Plastics Bottle Manufacturing',
        level: 3,
        parentCode: '3261',
        hasChildren: true,
    },
    {
        code: '326160',
        name: 'Plastics Bottle Manufacturing',
        level: 4,
        parentCode: '32616',
        hasChildren: false,
    },
    {
        code: '32619',
        name: 'Other Plastics Product Manufacturing',
        level: 3,
        parentCode: '3261',
        hasChildren: true,
    },
    {
        code: '326191',
        name: 'Plastics Plumbing Fixture Manufacturing',
        level: 4,
        parentCode: '32619',
        hasChildren: false,
    },
    {
        code: '326199',
        name: 'All Other Plastics Product Manufacturing',
        level: 4,
        parentCode: '32619',
        hasChildren: false,
    },
    {
        code: '3262',
        name: 'Rubber Product Manufacturing',
        level: 2,
        parentCode: '326',
        hasChildren: true,
    },
    {
        code: '32621',
        name: 'Tire Manufacturing',
        level: 3,
        parentCode: '3262',
        hasChildren: true,
    },
    {
        code: '326211',
        name: 'Tire Manufacturing (except Retreading)',
        level: 4,
        parentCode: '32621',
        hasChildren: false,
    },
    {
        code: '326212',
        name: 'Tire Retreading',
        level: 4,
        parentCode: '32621',
        hasChildren: false,
    },
    {
        code: '32622',
        name: 'Rubber and Plastics Hoses and Belting Manufacturing',
        level: 3,
        parentCode: '3262',
        hasChildren: true,
    },
    {
        code: '326220',
        name: 'Rubber and Plastics Hoses and Belting Manufacturing',
        level: 4,
        parentCode: '32622',
        hasChildren: false,
    },
    {
        code: '32629',
        name: 'Other Rubber Product Manufacturing',
        level: 3,
        parentCode: '3262',
        hasChildren: true,
    },
    {
        code: '326291',
        name: 'Rubber Product Manufacturing for Mechanical Use',
        level: 4,
        parentCode: '32629',
        hasChildren: false,
    },
    {
        code: '326299',
        name: 'All Other Rubber Product Manufacturing',
        level: 4,
        parentCode: '32629',
        hasChildren: false,
    },
    {
        code: '327',
        name: 'Nonmetallic Mineral Product Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3271',
        name: 'Clay Product and Refractory Manufacturing',
        level: 2,
        parentCode: '327',
        hasChildren: true,
    },
    {
        code: '32711',
        name: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing',
        level: 3,
        parentCode: '3271',
        hasChildren: true,
    },
    {
        code: '327110',
        name: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing',
        level: 4,
        parentCode: '32711',
        hasChildren: false,
    },
    {
        code: '32712',
        name: 'Clay Building Material and Refractories Manufacturing',
        level: 3,
        parentCode: '3271',
        hasChildren: true,
    },
    {
        code: '327120',
        name: 'Clay Building Material and Refractories Manufacturing',
        level: 4,
        parentCode: '32712',
        hasChildren: false,
    },
    {
        code: '3272',
        name: 'Glass and Glass Product Manufacturing',
        level: 2,
        parentCode: '327',
        hasChildren: true,
    },
    {
        code: '32721',
        name: 'Glass and Glass Product Manufacturing',
        level: 3,
        parentCode: '3272',
        hasChildren: true,
    },
    {
        code: '327211',
        name: 'Flat Glass Manufacturing',
        level: 4,
        parentCode: '32721',
        hasChildren: false,
    },
    {
        code: '327212',
        name: 'Other Pressed and Blown Glass and Glassware Manufacturing',
        level: 4,
        parentCode: '32721',
        hasChildren: false,
    },
    {
        code: '327213',
        name: 'Glass Container Manufacturing',
        level: 4,
        parentCode: '32721',
        hasChildren: false,
    },
    {
        code: '327215',
        name: 'Glass Product Manufacturing Made of Purchased Glass',
        level: 4,
        parentCode: '32721',
        hasChildren: false,
    },
    {
        code: '3273',
        name: 'Cement and Concrete Product Manufacturing',
        level: 2,
        parentCode: '327',
        hasChildren: true,
    },
    {
        code: '32731',
        name: 'Cement Manufacturing',
        level: 3,
        parentCode: '3273',
        hasChildren: true,
    },
    {
        code: '327310',
        name: 'Cement Manufacturing',
        level: 4,
        parentCode: '32731',
        hasChildren: false,
    },
    {
        code: '32732',
        name: 'Ready-Mix Concrete Manufacturing',
        level: 3,
        parentCode: '3273',
        hasChildren: true,
    },
    {
        code: '327320',
        name: 'Ready-Mix Concrete Manufacturing',
        level: 4,
        parentCode: '32732',
        hasChildren: false,
    },
    {
        code: '32733',
        name: 'Concrete Pipe, Brick, and Block Manufacturing',
        level: 3,
        parentCode: '3273',
        hasChildren: true,
    },
    {
        code: '327331',
        name: 'Concrete Block and Brick Manufacturing',
        level: 4,
        parentCode: '32733',
        hasChildren: false,
    },
    {
        code: '327332',
        name: 'Concrete Pipe Manufacturing',
        level: 4,
        parentCode: '32733',
        hasChildren: false,
    },
    {
        code: '32739',
        name: 'Other Concrete Product Manufacturing',
        level: 3,
        parentCode: '3273',
        hasChildren: true,
    },
    {
        code: '327390',
        name: 'Other Concrete Product Manufacturing',
        level: 4,
        parentCode: '32739',
        hasChildren: false,
    },
    {
        code: '3274',
        name: 'Lime and Gypsum Product Manufacturing',
        level: 2,
        parentCode: '327',
        hasChildren: true,
    },
    {
        code: '32741',
        name: 'Lime Manufacturing',
        level: 3,
        parentCode: '3274',
        hasChildren: true,
    },
    {
        code: '327410',
        name: 'Lime Manufacturing',
        level: 4,
        parentCode: '32741',
        hasChildren: false,
    },
    {
        code: '32742',
        name: 'Gypsum Product Manufacturing',
        level: 3,
        parentCode: '3274',
        hasChildren: true,
    },
    {
        code: '327420',
        name: 'Gypsum Product Manufacturing',
        level: 4,
        parentCode: '32742',
        hasChildren: false,
    },
    {
        code: '3279',
        name: 'Other Nonmetallic Mineral Product Manufacturing',
        level: 2,
        parentCode: '327',
        hasChildren: true,
    },
    {
        code: '32791',
        name: 'Abrasive Product Manufacturing',
        level: 3,
        parentCode: '3279',
        hasChildren: true,
    },
    {
        code: '327910',
        name: 'Abrasive Product Manufacturing',
        level: 4,
        parentCode: '32791',
        hasChildren: false,
    },
    {
        code: '32799',
        name: 'All Other Nonmetallic Mineral Product Manufacturing',
        level: 3,
        parentCode: '3279',
        hasChildren: true,
    },
    {
        code: '327991',
        name: 'Cut Stone and Stone Product Manufacturing',
        level: 4,
        parentCode: '32799',
        hasChildren: false,
    },
    {
        code: '327992',
        name: 'Ground or Treated Mineral and Earth Manufacturing',
        level: 4,
        parentCode: '32799',
        hasChildren: false,
    },
    {
        code: '327993',
        name: 'Mineral Wool Manufacturing',
        level: 4,
        parentCode: '32799',
        hasChildren: false,
    },
    {
        code: '327999',
        name: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing',
        level: 4,
        parentCode: '32799',
        hasChildren: false,
    },
    {
        code: '331',
        name: 'Primary Metal Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3311',
        name: 'Iron and Steel Mills and Ferroalloy Manufacturing',
        level: 2,
        parentCode: '331',
        hasChildren: true,
    },
    {
        code: '33111',
        name: 'Iron and Steel Mills and Ferroalloy Manufacturing',
        level: 3,
        parentCode: '3311',
        hasChildren: true,
    },
    {
        code: '331110',
        name: 'Iron and Steel Mills and Ferroalloy Manufacturing',
        level: 4,
        parentCode: '33111',
        hasChildren: false,
    },
    {
        code: '3312',
        name: 'Steel Product Manufacturing from Purchased Steel',
        level: 2,
        parentCode: '331',
        hasChildren: true,
    },
    {
        code: '33121',
        name: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
        level: 3,
        parentCode: '3312',
        hasChildren: true,
    },
    {
        code: '331210',
        name: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel',
        level: 4,
        parentCode: '33121',
        hasChildren: false,
    },
    {
        code: '33122',
        name: 'Rolling and Drawing of Purchased Steel',
        level: 3,
        parentCode: '3312',
        hasChildren: true,
    },
    {
        code: '331221',
        name: 'Rolled Steel Shape Manufacturing',
        level: 4,
        parentCode: '33122',
        hasChildren: false,
    },
    {
        code: '331222',
        name: 'Steel Wire Drawing',
        level: 4,
        parentCode: '33122',
        hasChildren: false,
    },
    {
        code: '3313',
        name: 'Alumina and Aluminum Production and Processing',
        level: 2,
        parentCode: '331',
        hasChildren: true,
    },
    {
        code: '33131',
        name: 'Alumina and Aluminum Production and Processing',
        level: 3,
        parentCode: '3313',
        hasChildren: true,
    },
    {
        code: '331313',
        name: 'Alumina Refining and Primary Aluminum Production',
        level: 4,
        parentCode: '33131',
        hasChildren: false,
    },
    {
        code: '331314',
        name: 'Secondary Smelting and Alloying of Aluminum',
        level: 4,
        parentCode: '33131',
        hasChildren: false,
    },
    {
        code: '331315',
        name: 'Aluminum Sheet, Plate, and Foil Manufacturing',
        level: 4,
        parentCode: '33131',
        hasChildren: false,
    },
    {
        code: '331318',
        name: 'Other Aluminum Rolling, Drawing, and Extruding',
        level: 4,
        parentCode: '33131',
        hasChildren: false,
    },
    {
        code: '3314',
        name: 'Nonferrous Metal (except Aluminum) Production and Processing',
        level: 2,
        parentCode: '331',
        hasChildren: true,
    },
    {
        code: '33141',
        name: 'Nonferrous Metal (except Aluminum) Smelting and Refining',
        level: 3,
        parentCode: '3314',
        hasChildren: true,
    },
    {
        code: '331410',
        name: 'Nonferrous Metal (except Aluminum) Smelting and Refining',
        level: 4,
        parentCode: '33141',
        hasChildren: false,
    },
    {
        code: '33142',
        name: 'Copper Rolling, Drawing, Extruding, and Alloying',
        level: 3,
        parentCode: '3314',
        hasChildren: true,
    },
    {
        code: '331420',
        name: 'Copper Rolling, Drawing, Extruding, and Alloying',
        level: 4,
        parentCode: '33142',
        hasChildren: false,
    },
    {
        code: '33149',
        name: 'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding, and Alloying',
        level: 3,
        parentCode: '3314',
        hasChildren: true,
    },
    {
        code: '331491',
        name: 'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding',
        level: 4,
        parentCode: '33149',
        hasChildren: false,
    },
    {
        code: '331492',
        name: 'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)',
        level: 4,
        parentCode: '33149',
        hasChildren: false,
    },
    {
        code: '3315',
        name: 'Foundries',
        level: 2,
        parentCode: '331',
        hasChildren: true,
    },
    {
        code: '33151',
        name: 'Ferrous Metal Foundries',
        level: 3,
        parentCode: '3315',
        hasChildren: true,
    },
    {
        code: '331511',
        name: 'Iron Foundries',
        level: 4,
        parentCode: '33151',
        hasChildren: false,
    },
    {
        code: '331512',
        name: 'Steel Investment Foundries',
        level: 4,
        parentCode: '33151',
        hasChildren: false,
    },
    {
        code: '331513',
        name: 'Steel Foundries (except Investment)',
        level: 4,
        parentCode: '33151',
        hasChildren: false,
    },
    {
        code: '33152',
        name: 'Nonferrous Metal Foundries',
        level: 3,
        parentCode: '3315',
        hasChildren: true,
    },
    {
        code: '331523',
        name: 'Nonferrous Metal Die-Casting Foundries',
        level: 4,
        parentCode: '33152',
        hasChildren: false,
    },
    {
        code: '331524',
        name: 'Aluminum Foundries (except Die-Casting)',
        level: 4,
        parentCode: '33152',
        hasChildren: false,
    },
    {
        code: '331529',
        name: 'Other Nonferrous Metal Foundries (except Die-Casting)',
        level: 4,
        parentCode: '33152',
        hasChildren: false,
    },
    {
        code: '332',
        name: 'Fabricated Metal Product Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3321',
        name: 'Forging and Stamping',
        level: 2,
        parentCode: '332',
        hasChildren: true,
    },
    {
        code: '33211',
        name: 'Forging and Stamping',
        level: 3,
        parentCode: '3321',
        hasChildren: true,
    },
    {
        code: '332111',
        name: 'Iron and Steel Forging',
        level: 4,
        parentCode: '33211',
        hasChildren: false,
    },
    {
        code: '332112',
        name: 'Nonferrous Forging',
        level: 4,
        parentCode: '33211',
        hasChildren: false,
    },
    {
        code: '332114',
        name: 'Custom Roll Forming',
        level: 4,
        parentCode: '33211',
        hasChildren: false,
    },
    {
        code: '332117',
        name: 'Powder Metallurgy Part Manufacturing',
        level: 4,
        parentCode: '33211',
        hasChildren: false,
    },
    {
        code: '332119',
        name: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive)',
        level: 4,
        parentCode: '33211',
        hasChildren: false,
    },
    {
        code: '3322',
        name: 'Cutlery and Handtool Manufacturing',
        level: 2,
        parentCode: '332',
        hasChildren: true,
    },
    {
        code: '33221',
        name: 'Cutlery and Handtool Manufacturing',
        level: 3,
        parentCode: '3322',
        hasChildren: true,
    },
    {
        code: '332215',
        name: 'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing',
        level: 4,
        parentCode: '33221',
        hasChildren: false,
    },
    {
        code: '332216',
        name: 'Saw Blade and Handtool Manufacturing',
        level: 4,
        parentCode: '33221',
        hasChildren: false,
    },
    {
        code: '3323',
        name: 'Architectural and Structural Metals Manufacturing',
        level: 2,
        parentCode: '332',
        hasChildren: true,
    },
    {
        code: '33231',
        name: 'Plate Work and Fabricated Structural Product Manufacturing',
        level: 3,
        parentCode: '3323',
        hasChildren: true,
    },
    {
        code: '332311',
        name: 'Prefabricated Metal Building and Component Manufacturing',
        level: 4,
        parentCode: '33231',
        hasChildren: false,
    },
    {
        code: '332312',
        name: 'Fabricated Structural Metal Manufacturing',
        level: 4,
        parentCode: '33231',
        hasChildren: false,
    },
    {
        code: '332313',
        name: 'Plate Work Manufacturing',
        level: 4,
        parentCode: '33231',
        hasChildren: false,
    },
    {
        code: '33232',
        name: 'Ornamental and Architectural Metal Products Manufacturing',
        level: 3,
        parentCode: '3323',
        hasChildren: true,
    },
    {
        code: '332321',
        name: 'Metal Window and Door Manufacturing',
        level: 4,
        parentCode: '33232',
        hasChildren: false,
    },
    {
        code: '332322',
        name: 'Sheet Metal Work Manufacturing',
        level: 4,
        parentCode: '33232',
        hasChildren: false,
    },
    {
        code: '332323',
        name: 'Ornamental and Architectural Metal Work Manufacturing',
        level: 4,
        parentCode: '33232',
        hasChildren: false,
    },
    {
        code: '3324',
        name: 'Boiler, Tank, and Shipping Container Manufacturing',
        level: 2,
        parentCode: '332',
        hasChildren: true,
    },
    {
        code: '33241',
        name: 'Power Boiler and Heat Exchanger Manufacturing',
        level: 3,
        parentCode: '3324',
        hasChildren: true,
    },
    {
        code: '332410',
        name: 'Power Boiler and Heat Exchanger Manufacturing',
        level: 4,
        parentCode: '33241',
        hasChildren: false,
    },
    {
        code: '33242',
        name: 'Metal Tank (Heavy Gauge) Manufacturing',
        level: 3,
        parentCode: '3324',
        hasChildren: true,
    },
    {
        code: '332420',
        name: 'Metal Tank (Heavy Gauge) Manufacturing',
        level: 4,
        parentCode: '33242',
        hasChildren: false,
    },
    {
        code: '33243',
        name: 'Metal Can, Box, and Other Metal Container (Light Gauge) Manufacturing',
        level: 3,
        parentCode: '3324',
        hasChildren: true,
    },
    {
        code: '332431',
        name: 'Metal Can Manufacturing',
        level: 4,
        parentCode: '33243',
        hasChildren: false,
    },
    {
        code: '332439',
        name: 'Other Metal Container Manufacturing',
        level: 4,
        parentCode: '33243',
        hasChildren: false,
    },
    {
        code: '3325',
        name: 'Hardware Manufacturing',
        level: 2,
        parentCode: '332',
        hasChildren: true,
    },
    {
        code: '33251',
        name: 'Hardware Manufacturing',
        level: 3,
        parentCode: '3325',
        hasChildren: true,
    },
    {
        code: '332510',
        name: 'Hardware Manufacturing',
        level: 4,
        parentCode: '33251',
        hasChildren: false,
    },
    {
        code: '3326',
        name: 'Spring and Wire Product Manufacturing',
        level: 2,
        parentCode: '332',
        hasChildren: true,
    },
    {
        code: '33261',
        name: 'Spring and Wire Product Manufacturing',
        level: 3,
        parentCode: '3326',
        hasChildren: true,
    },
    {
        code: '332613',
        name: 'Spring Manufacturing',
        level: 4,
        parentCode: '33261',
        hasChildren: false,
    },
    {
        code: '332618',
        name: 'Other Fabricated Wire Product Manufacturing',
        level: 4,
        parentCode: '33261',
        hasChildren: false,
    },
    {
        code: '3327',
        name: 'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing',
        level: 2,
        parentCode: '332',
        hasChildren: true,
    },
    {
        code: '33271',
        name: 'Machine Shops',
        level: 3,
        parentCode: '3327',
        hasChildren: true,
    },
    {
        code: '332710',
        name: 'Machine Shops',
        level: 4,
        parentCode: '33271',
        hasChildren: false,
    },
    {
        code: '33272',
        name: 'Turned Product and Screw, Nut, and Bolt Manufacturing',
        level: 3,
        parentCode: '3327',
        hasChildren: true,
    },
    {
        code: '332721',
        name: 'Precision Turned Product Manufacturing',
        level: 4,
        parentCode: '33272',
        hasChildren: false,
    },
    {
        code: '332722',
        name: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing',
        level: 4,
        parentCode: '33272',
        hasChildren: false,
    },
    {
        code: '3328',
        name: 'Coating, Engraving, Heat Treating, and Allied Activities',
        level: 2,
        parentCode: '332',
        hasChildren: true,
    },
    {
        code: '33281',
        name: 'Coating, Engraving, Heat Treating, and Allied Activities',
        level: 3,
        parentCode: '3328',
        hasChildren: true,
    },
    {
        code: '332811',
        name: 'Metal Heat Treating',
        level: 4,
        parentCode: '33281',
        hasChildren: false,
    },
    {
        code: '332812',
        name: 'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers',
        level: 4,
        parentCode: '33281',
        hasChildren: false,
    },
    {
        code: '332813',
        name: 'Electroplating, Plating, Polishing, Anodizing, and Coloring',
        level: 4,
        parentCode: '33281',
        hasChildren: false,
    },
    {
        code: '3329',
        name: 'Other Fabricated Metal Product Manufacturing',
        level: 2,
        parentCode: '332',
        hasChildren: true,
    },
    {
        code: '33291',
        name: 'Metal Valve Manufacturing',
        level: 3,
        parentCode: '3329',
        hasChildren: true,
    },
    {
        code: '332911',
        name: 'Industrial Valve Manufacturing',
        level: 4,
        parentCode: '33291',
        hasChildren: false,
    },
    {
        code: '332912',
        name: 'Fluid Power Valve and Hose Fitting Manufacturing',
        level: 4,
        parentCode: '33291',
        hasChildren: false,
    },
    {
        code: '332913',
        name: 'Plumbing Fixture Fitting and Trim Manufacturing',
        level: 4,
        parentCode: '33291',
        hasChildren: false,
    },
    {
        code: '332919',
        name: 'Other Metal Valve and Pipe Fitting Manufacturing',
        level: 4,
        parentCode: '33291',
        hasChildren: false,
    },
    {
        code: '33299',
        name: 'All Other Fabricated Metal Product Manufacturing',
        level: 3,
        parentCode: '3329',
        hasChildren: true,
    },
    {
        code: '332991',
        name: 'Ball and Roller Bearing Manufacturing',
        level: 4,
        parentCode: '33299',
        hasChildren: false,
    },
    {
        code: '332992',
        name: 'Small Arms Ammunition Manufacturing',
        level: 4,
        parentCode: '33299',
        hasChildren: false,
    },
    {
        code: '332993',
        name: 'Ammunition (except Small Arms) Manufacturing',
        level: 4,
        parentCode: '33299',
        hasChildren: false,
    },
    {
        code: '332994',
        name: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing',
        level: 4,
        parentCode: '33299',
        hasChildren: false,
    },
    {
        code: '332996',
        name: 'Fabricated Pipe and Pipe Fitting Manufacturing',
        level: 4,
        parentCode: '33299',
        hasChildren: false,
    },
    {
        code: '332999',
        name: 'All Other Miscellaneous Fabricated Metal Product Manufacturing',
        level: 4,
        parentCode: '33299',
        hasChildren: false,
    },
    {
        code: '333',
        name: 'Machinery Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3331',
        name: 'Agriculture, Construction, and Mining Machinery Manufacturing',
        level: 2,
        parentCode: '333',
        hasChildren: true,
    },
    {
        code: '33311',
        name: 'Agricultural Implement Manufacturing',
        level: 3,
        parentCode: '3331',
        hasChildren: true,
    },
    {
        code: '333111',
        name: 'Farm Machinery and Equipment Manufacturing',
        level: 4,
        parentCode: '33311',
        hasChildren: false,
    },
    {
        code: '333112',
        name: 'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing',
        level: 4,
        parentCode: '33311',
        hasChildren: false,
    },
    {
        code: '33312',
        name: 'Construction Machinery Manufacturing',
        level: 3,
        parentCode: '3331',
        hasChildren: true,
    },
    {
        code: '333120',
        name: 'Construction Machinery Manufacturing',
        level: 4,
        parentCode: '33312',
        hasChildren: false,
    },
    {
        code: '33313',
        name: 'Mining and Oil and Gas Field Machinery Manufacturing',
        level: 3,
        parentCode: '3331',
        hasChildren: true,
    },
    {
        code: '333131',
        name: 'Mining Machinery and Equipment Manufacturing',
        level: 4,
        parentCode: '33313',
        hasChildren: false,
    },
    {
        code: '333132',
        name: 'Oil and Gas Field Machinery and Equipment Manufacturing',
        level: 4,
        parentCode: '33313',
        hasChildren: false,
    },
    {
        code: '3332',
        name: 'Industrial Machinery Manufacturing',
        level: 2,
        parentCode: '333',
        hasChildren: true,
    },
    {
        code: '33324',
        name: 'Industrial Machinery Manufacturing',
        level: 3,
        parentCode: '3332',
        hasChildren: true,
    },
    {
        code: '333241',
        name: 'Food Product Machinery Manufacturing',
        level: 4,
        parentCode: '33324',
        hasChildren: false,
    },
    {
        code: '333242',
        name: 'Semiconductor Machinery Manufacturing',
        level: 4,
        parentCode: '33324',
        hasChildren: false,
    },
    {
        code: '333243',
        name: 'Sawmill, Woodworking, and Paper Machinery Manufacturing',
        level: 4,
        parentCode: '33324',
        hasChildren: false,
    },
    {
        code: '333244',
        name: 'Printing Machinery and Equipment Manufacturing',
        level: 4,
        parentCode: '33324',
        hasChildren: false,
    },
    {
        code: '333249',
        name: 'Other Industrial Machinery Manufacturing',
        level: 4,
        parentCode: '33324',
        hasChildren: false,
    },
    {
        code: '3333',
        name: 'Commercial and Service Industry Machinery Manufacturing',
        level: 2,
        parentCode: '333',
        hasChildren: true,
    },
    {
        code: '33331',
        name: 'Commercial and Service Industry Machinery Manufacturing',
        level: 3,
        parentCode: '3333',
        hasChildren: true,
    },
    {
        code: '333314',
        name: 'Optical Instrument and Lens Manufacturing',
        level: 4,
        parentCode: '33331',
        hasChildren: false,
    },
    {
        code: '333316',
        name: 'Photographic and Photocopying Equipment Manufacturing',
        level: 4,
        parentCode: '33331',
        hasChildren: false,
    },
    {
        code: '333318',
        name: 'Other Commercial and Service Industry Machinery Manufacturing',
        level: 4,
        parentCode: '33331',
        hasChildren: false,
    },
    {
        code: '3334',
        name: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
        level: 2,
        parentCode: '333',
        hasChildren: true,
    },
    {
        code: '33341',
        name: 'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
        level: 3,
        parentCode: '3334',
        hasChildren: true,
    },
    {
        code: '333413',
        name: 'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing',
        level: 4,
        parentCode: '33341',
        hasChildren: false,
    },
    {
        code: '333414',
        name: 'Heating Equipment (except Warm Air Furnaces) Manufacturing',
        level: 4,
        parentCode: '33341',
        hasChildren: false,
    },
    {
        code: '333415',
        name: 'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing',
        level: 4,
        parentCode: '33341',
        hasChildren: false,
    },
    {
        code: '3335',
        name: 'Metalworking Machinery Manufacturing',
        level: 2,
        parentCode: '333',
        hasChildren: true,
    },
    {
        code: '33351',
        name: 'Metalworking Machinery Manufacturing',
        level: 3,
        parentCode: '3335',
        hasChildren: true,
    },
    {
        code: '333511',
        name: 'Industrial Mold Manufacturing',
        level: 4,
        parentCode: '33351',
        hasChildren: false,
    },
    {
        code: '333514',
        name: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing',
        level: 4,
        parentCode: '33351',
        hasChildren: false,
    },
    {
        code: '333515',
        name: 'Cutting Tool and Machine Tool Accessory Manufacturing',
        level: 4,
        parentCode: '33351',
        hasChildren: false,
    },
    {
        code: '333517',
        name: 'Machine Tool Manufacturing',
        level: 4,
        parentCode: '33351',
        hasChildren: false,
    },
    {
        code: '333519',
        name: 'Rolling Mill and Other Metalworking Machinery Manufacturing',
        level: 4,
        parentCode: '33351',
        hasChildren: false,
    },
    {
        code: '3336',
        name: 'Engine, Turbine, and Power Transmission Equipment Manufacturing',
        level: 2,
        parentCode: '333',
        hasChildren: true,
    },
    {
        code: '33361',
        name: 'Engine, Turbine, and Power Transmission Equipment Manufacturing',
        level: 3,
        parentCode: '3336',
        hasChildren: true,
    },
    {
        code: '333611',
        name: 'Turbine and Turbine Generator Set Units Manufacturing',
        level: 4,
        parentCode: '33361',
        hasChildren: false,
    },
    {
        code: '333612',
        name: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing',
        level: 4,
        parentCode: '33361',
        hasChildren: false,
    },
    {
        code: '333613',
        name: 'Mechanical Power Transmission Equipment Manufacturing',
        level: 4,
        parentCode: '33361',
        hasChildren: false,
    },
    {
        code: '333618',
        name: 'Other Engine Equipment Manufacturing',
        level: 4,
        parentCode: '33361',
        hasChildren: false,
    },
    {
        code: '3339',
        name: 'Other General Purpose Machinery Manufacturing',
        level: 2,
        parentCode: '333',
        hasChildren: true,
    },
    {
        code: '33391',
        name: 'Pump and Compressor Manufacturing',
        level: 3,
        parentCode: '3339',
        hasChildren: true,
    },
    {
        code: '333912',
        name: 'Air and Gas Compressor Manufacturing',
        level: 4,
        parentCode: '33391',
        hasChildren: false,
    },
    {
        code: '333914',
        name: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing',
        level: 4,
        parentCode: '33391',
        hasChildren: false,
    },
    {
        code: '33392',
        name: 'Material Handling Equipment Manufacturing',
        level: 3,
        parentCode: '3339',
        hasChildren: true,
    },
    {
        code: '333921',
        name: 'Elevator and Moving Stairway Manufacturing',
        level: 4,
        parentCode: '33392',
        hasChildren: false,
    },
    {
        code: '333922',
        name: 'Conveyor and Conveying Equipment Manufacturing',
        level: 4,
        parentCode: '33392',
        hasChildren: false,
    },
    {
        code: '333923',
        name: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing',
        level: 4,
        parentCode: '33392',
        hasChildren: false,
    },
    {
        code: '333924',
        name: 'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing',
        level: 4,
        parentCode: '33392',
        hasChildren: false,
    },
    {
        code: '33399',
        name: 'All Other General Purpose Machinery Manufacturing',
        level: 3,
        parentCode: '3339',
        hasChildren: true,
    },
    {
        code: '333991',
        name: 'Power-Driven Handtool Manufacturing',
        level: 4,
        parentCode: '33399',
        hasChildren: false,
    },
    {
        code: '333992',
        name: 'Welding and Soldering Equipment Manufacturing',
        level: 4,
        parentCode: '33399',
        hasChildren: false,
    },
    {
        code: '333993',
        name: 'Packaging Machinery Manufacturing',
        level: 4,
        parentCode: '33399',
        hasChildren: false,
    },
    {
        code: '333994',
        name: 'Industrial Process Furnace and Oven Manufacturing',
        level: 4,
        parentCode: '33399',
        hasChildren: false,
    },
    {
        code: '333995',
        name: 'Fluid Power Cylinder and Actuator Manufacturing',
        level: 4,
        parentCode: '33399',
        hasChildren: false,
    },
    {
        code: '333996',
        name: 'Fluid Power Pump and Motor Manufacturing',
        level: 4,
        parentCode: '33399',
        hasChildren: false,
    },
    {
        code: '333997',
        name: 'Scale and Balance Manufacturing',
        level: 4,
        parentCode: '33399',
        hasChildren: false,
    },
    {
        code: '333999',
        name: 'All Other Miscellaneous General Purpose Machinery Manufacturing',
        level: 4,
        parentCode: '33399',
        hasChildren: false,
    },
    {
        code: '334',
        name: 'Computer and Electronic Product Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3341',
        name: 'Computer and Peripheral Equipment Manufacturing',
        level: 2,
        parentCode: '334',
        hasChildren: true,
    },
    {
        code: '33411',
        name: 'Computer and Peripheral Equipment Manufacturing',
        level: 3,
        parentCode: '3341',
        hasChildren: true,
    },
    {
        code: '334111',
        name: 'Electronic Computer Manufacturing',
        level: 4,
        parentCode: '33411',
        hasChildren: false,
    },
    {
        code: '334112',
        name: 'Computer Storage Device Manufacturing',
        level: 4,
        parentCode: '33411',
        hasChildren: false,
    },
    {
        code: '334118',
        name: 'Computer Terminal and Other Computer Peripheral Equipment Manufacturing',
        level: 4,
        parentCode: '33411',
        hasChildren: false,
    },
    {
        code: '3342',
        name: 'Communications Equipment Manufacturing',
        level: 2,
        parentCode: '334',
        hasChildren: true,
    },
    {
        code: '33421',
        name: 'Telephone Apparatus Manufacturing',
        level: 3,
        parentCode: '3342',
        hasChildren: true,
    },
    {
        code: '334210',
        name: 'Telephone Apparatus Manufacturing',
        level: 4,
        parentCode: '33421',
        hasChildren: false,
    },
    {
        code: '33422',
        name: 'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
        level: 3,
        parentCode: '3342',
        hasChildren: true,
    },
    {
        code: '334220',
        name: 'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing',
        level: 4,
        parentCode: '33422',
        hasChildren: false,
    },
    {
        code: '33429',
        name: 'Other Communications Equipment Manufacturing',
        level: 3,
        parentCode: '3342',
        hasChildren: true,
    },
    {
        code: '334290',
        name: 'Other Communications Equipment Manufacturing',
        level: 4,
        parentCode: '33429',
        hasChildren: false,
    },
    {
        code: '3343',
        name: 'Audio and Video Equipment Manufacturing',
        level: 2,
        parentCode: '334',
        hasChildren: true,
    },
    {
        code: '33431',
        name: 'Audio and Video Equipment Manufacturing',
        level: 3,
        parentCode: '3343',
        hasChildren: true,
    },
    {
        code: '334310',
        name: 'Audio and Video Equipment Manufacturing',
        level: 4,
        parentCode: '33431',
        hasChildren: false,
    },
    {
        code: '3344',
        name: 'Semiconductor and Other Electronic Component Manufacturing',
        level: 2,
        parentCode: '334',
        hasChildren: true,
    },
    {
        code: '33441',
        name: 'Semiconductor and Other Electronic Component Manufacturing',
        level: 3,
        parentCode: '3344',
        hasChildren: true,
    },
    {
        code: '334412',
        name: 'Bare Printed Circuit Board Manufacturing',
        level: 4,
        parentCode: '33441',
        hasChildren: false,
    },
    {
        code: '334413',
        name: 'Semiconductor and Related Device Manufacturing',
        level: 4,
        parentCode: '33441',
        hasChildren: false,
    },
    {
        code: '334416',
        name: 'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing',
        level: 4,
        parentCode: '33441',
        hasChildren: false,
    },
    {
        code: '334417',
        name: 'Electronic Connector Manufacturing',
        level: 4,
        parentCode: '33441',
        hasChildren: false,
    },
    {
        code: '334418',
        name: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing',
        level: 4,
        parentCode: '33441',
        hasChildren: false,
    },
    {
        code: '334419',
        name: 'Other Electronic Component Manufacturing',
        level: 4,
        parentCode: '33441',
        hasChildren: false,
    },
    {
        code: '3345',
        name: 'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
        level: 2,
        parentCode: '334',
        hasChildren: true,
    },
    {
        code: '33451',
        name: 'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
        level: 3,
        parentCode: '3345',
        hasChildren: true,
    },
    {
        code: '334510',
        name: 'Electromedical and Electrotherapeutic Apparatus Manufacturing',
        level: 4,
        parentCode: '33451',
        hasChildren: false,
    },
    {
        code: '334511',
        name: 'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing',
        level: 4,
        parentCode: '33451',
        hasChildren: false,
    },
    {
        code: '334512',
        name: 'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use',
        level: 4,
        parentCode: '33451',
        hasChildren: false,
    },
    {
        code: '334513',
        name: 'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables',
        level: 4,
        parentCode: '33451',
        hasChildren: false,
    },
    {
        code: '334514',
        name: 'Totalizing Fluid Meter and Counting Device Manufacturing',
        level: 4,
        parentCode: '33451',
        hasChildren: false,
    },
    {
        code: '334515',
        name: 'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals',
        level: 4,
        parentCode: '33451',
        hasChildren: false,
    },
    {
        code: '334516',
        name: 'Analytical Laboratory Instrument Manufacturing',
        level: 4,
        parentCode: '33451',
        hasChildren: false,
    },
    {
        code: '334517',
        name: 'Irradiation Apparatus Manufacturing',
        level: 4,
        parentCode: '33451',
        hasChildren: false,
    },
    {
        code: '334519',
        name: 'Other Measuring and Controlling Device Manufacturing',
        level: 4,
        parentCode: '33451',
        hasChildren: false,
    },
    {
        code: '3346',
        name: 'Manufacturing and Reproducing Magnetic and Optical Media',
        level: 2,
        parentCode: '334',
        hasChildren: true,
    },
    {
        code: '33461',
        name: 'Manufacturing and Reproducing Magnetic and Optical Media',
        level: 3,
        parentCode: '3346',
        hasChildren: true,
    },
    {
        code: '334613',
        name: 'Blank Magnetic and Optical Recording Media Manufacturing',
        level: 4,
        parentCode: '33461',
        hasChildren: false,
    },
    {
        code: '334614',
        name: 'Software and Other Prerecorded Compact Disc, Tape, and Record Reproducing',
        level: 4,
        parentCode: '33461',
        hasChildren: false,
    },
    {
        code: '335',
        name: 'Electrical Equipment, Appliance, and Component Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3351',
        name: 'Electric Lighting Equipment Manufacturing',
        level: 2,
        parentCode: '335',
        hasChildren: true,
    },
    {
        code: '33511',
        name: 'Electric Lamp Bulb and Part Manufacturing',
        level: 3,
        parentCode: '3351',
        hasChildren: true,
    },
    {
        code: '335110',
        name: 'Electric Lamp Bulb and Part Manufacturing',
        level: 4,
        parentCode: '33511',
        hasChildren: false,
    },
    {
        code: '33512',
        name: 'Lighting Fixture Manufacturing',
        level: 3,
        parentCode: '3351',
        hasChildren: true,
    },
    {
        code: '335121',
        name: 'Residential Electric Lighting Fixture Manufacturing',
        level: 4,
        parentCode: '33512',
        hasChildren: false,
    },
    {
        code: '335122',
        name: 'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing',
        level: 4,
        parentCode: '33512',
        hasChildren: false,
    },
    {
        code: '335129',
        name: 'Other Lighting Equipment Manufacturing',
        level: 4,
        parentCode: '33512',
        hasChildren: false,
    },
    {
        code: '3352',
        name: 'Household Appliance Manufacturing',
        level: 2,
        parentCode: '335',
        hasChildren: true,
    },
    {
        code: '33521',
        name: 'Small Electrical Appliance Manufacturing',
        level: 3,
        parentCode: '3352',
        hasChildren: true,
    },
    {
        code: '335210',
        name: 'Small Electrical Appliance Manufacturing',
        level: 4,
        parentCode: '33521',
        hasChildren: false,
    },
    {
        code: '33522',
        name: 'Major Household Appliance Manufacturing',
        level: 3,
        parentCode: '3352',
        hasChildren: true,
    },
    {
        code: '335220',
        name: 'Major Household Appliance Manufacturing',
        level: 4,
        parentCode: '33522',
        hasChildren: false,
    },
    {
        code: '3353',
        name: 'Electrical Equipment Manufacturing',
        level: 2,
        parentCode: '335',
        hasChildren: true,
    },
    {
        code: '33531',
        name: 'Electrical Equipment Manufacturing',
        level: 3,
        parentCode: '3353',
        hasChildren: true,
    },
    {
        code: '335311',
        name: 'Power, Distribution, and Specialty Transformer Manufacturing',
        level: 4,
        parentCode: '33531',
        hasChildren: false,
    },
    {
        code: '335312',
        name: 'Motor and Generator Manufacturing',
        level: 4,
        parentCode: '33531',
        hasChildren: false,
    },
    {
        code: '335313',
        name: 'Switchgear and Switchboard Apparatus Manufacturing',
        level: 4,
        parentCode: '33531',
        hasChildren: false,
    },
    {
        code: '335314',
        name: 'Relay and Industrial Control Manufacturing',
        level: 4,
        parentCode: '33531',
        hasChildren: false,
    },
    {
        code: '3359',
        name: 'Other Electrical Equipment and Component Manufacturing',
        level: 2,
        parentCode: '335',
        hasChildren: true,
    },
    {
        code: '33591',
        name: 'Battery Manufacturing',
        level: 3,
        parentCode: '3359',
        hasChildren: true,
    },
    {
        code: '335911',
        name: 'Storage Battery Manufacturing',
        level: 4,
        parentCode: '33591',
        hasChildren: false,
    },
    {
        code: '335912',
        name: 'Primary Battery Manufacturing',
        level: 4,
        parentCode: '33591',
        hasChildren: false,
    },
    {
        code: '33592',
        name: 'Communication and Energy Wire and Cable Manufacturing',
        level: 3,
        parentCode: '3359',
        hasChildren: true,
    },
    {
        code: '335921',
        name: 'Fiber Optic Cable Manufacturing',
        level: 4,
        parentCode: '33592',
        hasChildren: false,
    },
    {
        code: '335929',
        name: 'Other Communication and Energy Wire Manufacturing',
        level: 4,
        parentCode: '33592',
        hasChildren: false,
    },
    {
        code: '33593',
        name: 'Wiring Device Manufacturing',
        level: 3,
        parentCode: '3359',
        hasChildren: true,
    },
    {
        code: '335931',
        name: 'Current-Carrying Wiring Device Manufacturing',
        level: 4,
        parentCode: '33593',
        hasChildren: false,
    },
    {
        code: '335932',
        name: 'Noncurrent-Carrying Wiring Device Manufacturing',
        level: 4,
        parentCode: '33593',
        hasChildren: false,
    },
    {
        code: '33599',
        name: 'All Other Electrical Equipment and Component Manufacturing',
        level: 3,
        parentCode: '3359',
        hasChildren: true,
    },
    {
        code: '335991',
        name: 'Carbon and Graphite Product Manufacturing',
        level: 4,
        parentCode: '33599',
        hasChildren: false,
    },
    {
        code: '335999',
        name: 'All Other Miscellaneous Electrical Equipment and Component Manufacturing',
        level: 4,
        parentCode: '33599',
        hasChildren: false,
    },
    {
        code: '336',
        name: 'Transportation Equipment Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3361',
        name: 'Motor Vehicle Manufacturing',
        level: 2,
        parentCode: '336',
        hasChildren: true,
    },
    {
        code: '33611',
        name: 'Automobile and Light Duty Motor Vehicle Manufacturing',
        level: 3,
        parentCode: '3361',
        hasChildren: true,
    },
    {
        code: '336111',
        name: 'Automobile Manufacturing',
        level: 4,
        parentCode: '33611',
        hasChildren: false,
    },
    {
        code: '336112',
        name: 'Light Truck and Utility Vehicle Manufacturing',
        level: 4,
        parentCode: '33611',
        hasChildren: false,
    },
    {
        code: '33612',
        name: 'Heavy Duty Truck Manufacturing',
        level: 3,
        parentCode: '3361',
        hasChildren: true,
    },
    {
        code: '336120',
        name: 'Heavy Duty Truck Manufacturing',
        level: 4,
        parentCode: '33612',
        hasChildren: false,
    },
    {
        code: '3362',
        name: 'Motor Vehicle Body and Trailer Manufacturing',
        level: 2,
        parentCode: '336',
        hasChildren: true,
    },
    {
        code: '33621',
        name: 'Motor Vehicle Body and Trailer Manufacturing',
        level: 3,
        parentCode: '3362',
        hasChildren: true,
    },
    {
        code: '336211',
        name: 'Motor Vehicle Body Manufacturing',
        level: 4,
        parentCode: '33621',
        hasChildren: false,
    },
    {
        code: '336212',
        name: 'Truck Trailer Manufacturing',
        level: 4,
        parentCode: '33621',
        hasChildren: false,
    },
    {
        code: '336213',
        name: 'Motor Home Manufacturing',
        level: 4,
        parentCode: '33621',
        hasChildren: false,
    },
    {
        code: '336214',
        name: 'Travel Trailer and Camper Manufacturing',
        level: 4,
        parentCode: '33621',
        hasChildren: false,
    },
    {
        code: '3363',
        name: 'Motor Vehicle Parts Manufacturing',
        level: 2,
        parentCode: '336',
        hasChildren: true,
    },
    {
        code: '33631',
        name: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
        level: 3,
        parentCode: '3363',
        hasChildren: true,
    },
    {
        code: '336310',
        name: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing',
        level: 4,
        parentCode: '33631',
        hasChildren: false,
    },
    {
        code: '33632',
        name: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
        level: 3,
        parentCode: '3363',
        hasChildren: true,
    },
    {
        code: '336320',
        name: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing',
        level: 4,
        parentCode: '33632',
        hasChildren: false,
    },
    {
        code: '33633',
        name: 'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
        level: 3,
        parentCode: '3363',
        hasChildren: true,
    },
    {
        code: '336330',
        name: 'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing',
        level: 4,
        parentCode: '33633',
        hasChildren: false,
    },
    {
        code: '33634',
        name: 'Motor Vehicle Brake System Manufacturing',
        level: 3,
        parentCode: '3363',
        hasChildren: true,
    },
    {
        code: '336340',
        name: 'Motor Vehicle Brake System Manufacturing',
        level: 4,
        parentCode: '33634',
        hasChildren: false,
    },
    {
        code: '33635',
        name: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
        level: 3,
        parentCode: '3363',
        hasChildren: true,
    },
    {
        code: '336350',
        name: 'Motor Vehicle Transmission and Power Train Parts Manufacturing',
        level: 4,
        parentCode: '33635',
        hasChildren: false,
    },
    {
        code: '33636',
        name: 'Motor Vehicle Seating and Interior Trim Manufacturing',
        level: 3,
        parentCode: '3363',
        hasChildren: true,
    },
    {
        code: '336360',
        name: 'Motor Vehicle Seating and Interior Trim Manufacturing',
        level: 4,
        parentCode: '33636',
        hasChildren: false,
    },
    {
        code: '33637',
        name: 'Motor Vehicle Metal Stamping',
        level: 3,
        parentCode: '3363',
        hasChildren: true,
    },
    {
        code: '336370',
        name: 'Motor Vehicle Metal Stamping',
        level: 4,
        parentCode: '33637',
        hasChildren: false,
    },
    {
        code: '33639',
        name: 'Other Motor Vehicle Parts Manufacturing',
        level: 3,
        parentCode: '3363',
        hasChildren: true,
    },
    {
        code: '336390',
        name: 'Other Motor Vehicle Parts Manufacturing',
        level: 4,
        parentCode: '33639',
        hasChildren: false,
    },
    {
        code: '3364',
        name: 'Aerospace Product and Parts Manufacturing',
        level: 2,
        parentCode: '336',
        hasChildren: true,
    },
    {
        code: '33641',
        name: 'Aerospace Product and Parts Manufacturing',
        level: 3,
        parentCode: '3364',
        hasChildren: true,
    },
    {
        code: '336411',
        name: 'Aircraft Manufacturing',
        level: 4,
        parentCode: '33641',
        hasChildren: false,
    },
    {
        code: '336412',
        name: 'Aircraft Engine and Engine Parts Manufacturing',
        level: 4,
        parentCode: '33641',
        hasChildren: false,
    },
    {
        code: '336413',
        name: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing',
        level: 4,
        parentCode: '33641',
        hasChildren: false,
    },
    {
        code: '336414',
        name: 'Guided Missile and Space Vehicle Manufacturing',
        level: 4,
        parentCode: '33641',
        hasChildren: false,
    },
    {
        code: '336415',
        name: 'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing',
        level: 4,
        parentCode: '33641',
        hasChildren: false,
    },
    {
        code: '336419',
        name: 'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing',
        level: 4,
        parentCode: '33641',
        hasChildren: false,
    },
    {
        code: '3365',
        name: 'Railroad Rolling Stock Manufacturing',
        level: 2,
        parentCode: '336',
        hasChildren: true,
    },
    {
        code: '33651',
        name: 'Railroad Rolling Stock Manufacturing',
        level: 3,
        parentCode: '3365',
        hasChildren: true,
    },
    {
        code: '336510',
        name: 'Railroad Rolling Stock Manufacturing',
        level: 4,
        parentCode: '33651',
        hasChildren: false,
    },
    {
        code: '3366',
        name: 'Ship and Boat Building',
        level: 2,
        parentCode: '336',
        hasChildren: true,
    },
    {
        code: '33661',
        name: 'Ship and Boat Building',
        level: 3,
        parentCode: '3366',
        hasChildren: true,
    },
    {
        code: '336611',
        name: 'Ship Building and Repairing',
        level: 4,
        parentCode: '33661',
        hasChildren: false,
    },
    {
        code: '336612',
        name: 'Boat Building',
        level: 4,
        parentCode: '33661',
        hasChildren: false,
    },
    {
        code: '3369',
        name: 'Other Transportation Equipment Manufacturing',
        level: 2,
        parentCode: '336',
        hasChildren: true,
    },
    {
        code: '33699',
        name: 'Other Transportation Equipment Manufacturing',
        level: 3,
        parentCode: '3369',
        hasChildren: true,
    },
    {
        code: '336991',
        name: 'Motorcycle, Bicycle, and Parts Manufacturing',
        level: 4,
        parentCode: '33699',
        hasChildren: false,
    },
    {
        code: '336992',
        name: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing',
        level: 4,
        parentCode: '33699',
        hasChildren: false,
    },
    {
        code: '336999',
        name: 'All Other Transportation Equipment Manufacturing',
        level: 4,
        parentCode: '33699',
        hasChildren: false,
    },
    {
        code: '337',
        name: 'Furniture and Related Product Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3371',
        name: 'Household and Institutional Furniture and Kitchen Cabinet Manufacturing',
        level: 2,
        parentCode: '337',
        hasChildren: true,
    },
    {
        code: '33711',
        name: 'Wood Kitchen Cabinet and Countertop Manufacturing',
        level: 3,
        parentCode: '3371',
        hasChildren: true,
    },
    {
        code: '337110',
        name: 'Wood Kitchen Cabinet and Countertop Manufacturing',
        level: 4,
        parentCode: '33711',
        hasChildren: false,
    },
    {
        code: '33712',
        name: 'Household and Institutional Furniture Manufacturing',
        level: 3,
        parentCode: '3371',
        hasChildren: true,
    },
    {
        code: '337121',
        name: 'Upholstered Household Furniture Manufacturing',
        level: 4,
        parentCode: '33712',
        hasChildren: false,
    },
    {
        code: '337122',
        name: 'Nonupholstered Wood Household Furniture Manufacturing',
        level: 4,
        parentCode: '33712',
        hasChildren: false,
    },
    {
        code: '337124',
        name: 'Metal Household Furniture Manufacturing',
        level: 4,
        parentCode: '33712',
        hasChildren: false,
    },
    {
        code: '337125',
        name: 'Household Furniture (except Wood and Metal) Manufacturing',
        level: 4,
        parentCode: '33712',
        hasChildren: false,
    },
    {
        code: '337127',
        name: 'Institutional Furniture Manufacturing',
        level: 4,
        parentCode: '33712',
        hasChildren: false,
    },
    {
        code: '3372',
        name: 'Office Furniture (including Fixtures) Manufacturing',
        level: 2,
        parentCode: '337',
        hasChildren: true,
    },
    {
        code: '33721',
        name: 'Office Furniture (including Fixtures) Manufacturing',
        level: 3,
        parentCode: '3372',
        hasChildren: true,
    },
    {
        code: '337211',
        name: 'Wood Office Furniture Manufacturing',
        level: 4,
        parentCode: '33721',
        hasChildren: false,
    },
    {
        code: '337212',
        name: 'Custom Architectural Woodwork and Millwork Manufacturing',
        level: 4,
        parentCode: '33721',
        hasChildren: false,
    },
    {
        code: '337214',
        name: 'Office Furniture (except Wood) Manufacturing',
        level: 4,
        parentCode: '33721',
        hasChildren: false,
    },
    {
        code: '337215',
        name: 'Showcase, Partition, Shelving, and Locker Manufacturing',
        level: 4,
        parentCode: '33721',
        hasChildren: false,
    },
    {
        code: '3379',
        name: 'Other Furniture Related Product Manufacturing',
        level: 2,
        parentCode: '337',
        hasChildren: true,
    },
    {
        code: '33791',
        name: 'Mattress Manufacturing',
        level: 3,
        parentCode: '3379',
        hasChildren: true,
    },
    {
        code: '337910',
        name: 'Mattress Manufacturing',
        level: 4,
        parentCode: '33791',
        hasChildren: false,
    },
    {
        code: '33792',
        name: 'Blind and Shade Manufacturing',
        level: 3,
        parentCode: '3379',
        hasChildren: true,
    },
    {
        code: '337920',
        name: 'Blind and Shade Manufacturing',
        level: 4,
        parentCode: '33792',
        hasChildren: false,
    },
    {
        code: '339',
        name: 'Miscellaneous Manufacturing',
        level: 1,
        parentCode: '31-33',
        hasChildren: true,
    },
    {
        code: '3391',
        name: 'Medical Equipment and Supplies Manufacturing',
        level: 2,
        parentCode: '339',
        hasChildren: true,
    },
    {
        code: '33911',
        name: 'Medical Equipment and Supplies Manufacturing',
        level: 3,
        parentCode: '3391',
        hasChildren: true,
    },
    {
        code: '339112',
        name: 'Surgical and Medical Instrument Manufacturing',
        level: 4,
        parentCode: '33911',
        hasChildren: false,
    },
    {
        code: '339113',
        name: 'Surgical Appliance and Supplies Manufacturing',
        level: 4,
        parentCode: '33911',
        hasChildren: false,
    },
    {
        code: '339114',
        name: 'Dental Equipment and Supplies Manufacturing',
        level: 4,
        parentCode: '33911',
        hasChildren: false,
    },
    {
        code: '339115',
        name: 'Ophthalmic Goods Manufacturing',
        level: 4,
        parentCode: '33911',
        hasChildren: false,
    },
    {
        code: '339116',
        name: 'Dental Laboratories',
        level: 4,
        parentCode: '33911',
        hasChildren: false,
    },
    {
        code: '3399',
        name: 'Other Miscellaneous Manufacturing',
        level: 2,
        parentCode: '339',
        hasChildren: true,
    },
    {
        code: '33991',
        name: 'Jewelry and Silverware Manufacturing',
        level: 3,
        parentCode: '3399',
        hasChildren: true,
    },
    {
        code: '339910',
        name: 'Jewelry and Silverware Manufacturing',
        level: 4,
        parentCode: '33991',
        hasChildren: false,
    },
    {
        code: '33992',
        name: 'Sporting and Athletic Goods Manufacturing',
        level: 3,
        parentCode: '3399',
        hasChildren: true,
    },
    {
        code: '339920',
        name: 'Sporting and Athletic Goods Manufacturing',
        level: 4,
        parentCode: '33992',
        hasChildren: false,
    },
    {
        code: '33993',
        name: 'Doll, Toy, and Game Manufacturing',
        level: 3,
        parentCode: '3399',
        hasChildren: true,
    },
    {
        code: '339930',
        name: 'Doll, Toy, and Game Manufacturing',
        level: 4,
        parentCode: '33993',
        hasChildren: false,
    },
    {
        code: '33994',
        name: 'Office Supplies (except Paper) Manufacturing',
        level: 3,
        parentCode: '3399',
        hasChildren: true,
    },
    {
        code: '339940',
        name: 'Office Supplies (except Paper) Manufacturing',
        level: 4,
        parentCode: '33994',
        hasChildren: false,
    },
    {
        code: '33995',
        name: 'Sign Manufacturing',
        level: 3,
        parentCode: '3399',
        hasChildren: true,
    },
    {
        code: '339950',
        name: 'Sign Manufacturing',
        level: 4,
        parentCode: '33995',
        hasChildren: false,
    },
    {
        code: '33999',
        name: 'All Other Miscellaneous Manufacturing',
        level: 3,
        parentCode: '3399',
        hasChildren: true,
    },
    {
        code: '339991',
        name: 'Gasket, Packing, and Sealing Device Manufacturing',
        level: 4,
        parentCode: '33999',
        hasChildren: false,
    },
    {
        code: '339992',
        name: 'Musical Instrument Manufacturing',
        level: 4,
        parentCode: '33999',
        hasChildren: false,
    },
    {
        code: '339993',
        name: 'Fastener, Button, Needle, and Pin Manufacturing',
        level: 4,
        parentCode: '33999',
        hasChildren: false,
    },
    {
        code: '339994',
        name: 'Broom, Brush, and Mop Manufacturing',
        level: 4,
        parentCode: '33999',
        hasChildren: false,
    },
    {
        code: '339995',
        name: 'Burial Casket Manufacturing',
        level: 4,
        parentCode: '33999',
        hasChildren: false,
    },
    {
        code: '339999',
        name: 'All Other Miscellaneous Manufacturing',
        level: 4,
        parentCode: '33999',
        hasChildren: false,
    },
    {
        code: '42',
        name: 'Wholesale Trade',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '423',
        name: 'Merchant Wholesalers, Durable Goods',
        level: 1,
        parentCode: '42',
        hasChildren: true,
    },
    {
        code: '4231',
        name: 'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers',
        level: 2,
        parentCode: '423',
        hasChildren: true,
    },
    {
        code: '42311',
        name: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
        level: 3,
        parentCode: '4231',
        hasChildren: true,
    },
    {
        code: '423110',
        name: 'Automobile and Other Motor Vehicle Merchant Wholesalers',
        level: 4,
        parentCode: '42311',
        hasChildren: false,
    },
    {
        code: '42312',
        name: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
        level: 3,
        parentCode: '4231',
        hasChildren: true,
    },
    {
        code: '423120',
        name: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers',
        level: 4,
        parentCode: '42312',
        hasChildren: false,
    },
    {
        code: '42313',
        name: 'Tire and Tube Merchant Wholesalers',
        level: 3,
        parentCode: '4231',
        hasChildren: true,
    },
    {
        code: '423130',
        name: 'Tire and Tube Merchant Wholesalers',
        level: 4,
        parentCode: '42313',
        hasChildren: false,
    },
    {
        code: '42314',
        name: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
        level: 3,
        parentCode: '4231',
        hasChildren: true,
    },
    {
        code: '423140',
        name: 'Motor Vehicle Parts (Used) Merchant Wholesalers',
        level: 4,
        parentCode: '42314',
        hasChildren: false,
    },
    {
        code: '4232',
        name: 'Furniture and Home Furnishing Merchant Wholesalers',
        level: 2,
        parentCode: '423',
        hasChildren: true,
    },
    {
        code: '42321',
        name: 'Furniture Merchant Wholesalers',
        level: 3,
        parentCode: '4232',
        hasChildren: true,
    },
    {
        code: '423210',
        name: 'Furniture Merchant Wholesalers',
        level: 4,
        parentCode: '42321',
        hasChildren: false,
    },
    {
        code: '42322',
        name: 'Home Furnishing Merchant Wholesalers',
        level: 3,
        parentCode: '4232',
        hasChildren: true,
    },
    {
        code: '423220',
        name: 'Home Furnishing Merchant Wholesalers',
        level: 4,
        parentCode: '42322',
        hasChildren: false,
    },
    {
        code: '4233',
        name: 'Lumber and Other Construction Materials Merchant Wholesalers',
        level: 2,
        parentCode: '423',
        hasChildren: true,
    },
    {
        code: '42331',
        name: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
        level: 3,
        parentCode: '4233',
        hasChildren: true,
    },
    {
        code: '423310',
        name: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers',
        level: 4,
        parentCode: '42331',
        hasChildren: false,
    },
    {
        code: '42332',
        name: 'Brick, Stone, and Related Construction Material Merchant Wholesalers',
        level: 3,
        parentCode: '4233',
        hasChildren: true,
    },
    {
        code: '423320',
        name: 'Brick, Stone, and Related Construction Material Merchant Wholesalers',
        level: 4,
        parentCode: '42332',
        hasChildren: false,
    },
    {
        code: '42333',
        name: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
        level: 3,
        parentCode: '4233',
        hasChildren: true,
    },
    {
        code: '423330',
        name: 'Roofing, Siding, and Insulation Material Merchant Wholesalers',
        level: 4,
        parentCode: '42333',
        hasChildren: false,
    },
    {
        code: '42339',
        name: 'Other Construction Material Merchant Wholesalers',
        level: 3,
        parentCode: '4233',
        hasChildren: true,
    },
    {
        code: '423390',
        name: 'Other Construction Material Merchant Wholesalers',
        level: 4,
        parentCode: '42339',
        hasChildren: false,
    },
    {
        code: '4234',
        name: 'Professional and Commercial Equipment and Supplies Merchant Wholesalers',
        level: 2,
        parentCode: '423',
        hasChildren: true,
    },
    {
        code: '42341',
        name: 'Photographic Equipment and Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4234',
        hasChildren: true,
    },
    {
        code: '423410',
        name: 'Photographic Equipment and Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42341',
        hasChildren: false,
    },
    {
        code: '42342',
        name: 'Office Equipment Merchant Wholesalers',
        level: 3,
        parentCode: '4234',
        hasChildren: true,
    },
    {
        code: '423420',
        name: 'Office Equipment Merchant Wholesalers',
        level: 4,
        parentCode: '42342',
        hasChildren: false,
    },
    {
        code: '42343',
        name: 'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
        level: 3,
        parentCode: '4234',
        hasChildren: true,
    },
    {
        code: '423430',
        name: 'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers',
        level: 4,
        parentCode: '42343',
        hasChildren: false,
    },
    {
        code: '42344',
        name: 'Other Commercial Equipment Merchant Wholesalers',
        level: 3,
        parentCode: '4234',
        hasChildren: true,
    },
    {
        code: '423440',
        name: 'Other Commercial Equipment Merchant Wholesalers',
        level: 4,
        parentCode: '42344',
        hasChildren: false,
    },
    {
        code: '42345',
        name: 'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4234',
        hasChildren: true,
    },
    {
        code: '423450',
        name: 'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42345',
        hasChildren: false,
    },
    {
        code: '42346',
        name: 'Ophthalmic Goods Merchant Wholesalers',
        level: 3,
        parentCode: '4234',
        hasChildren: true,
    },
    {
        code: '423460',
        name: 'Ophthalmic Goods Merchant Wholesalers',
        level: 4,
        parentCode: '42346',
        hasChildren: false,
    },
    {
        code: '42349',
        name: 'Other Professional Equipment and Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4234',
        hasChildren: true,
    },
    {
        code: '423490',
        name: 'Other Professional Equipment and Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42349',
        hasChildren: false,
    },
    {
        code: '4235',
        name: 'Metal and Mineral (except Petroleum) Merchant Wholesalers',
        level: 2,
        parentCode: '423',
        hasChildren: true,
    },
    {
        code: '42351',
        name: 'Metal Service Centers and Other Metal Merchant Wholesalers',
        level: 3,
        parentCode: '4235',
        hasChildren: true,
    },
    {
        code: '423510',
        name: 'Metal Service Centers and Other Metal Merchant Wholesalers',
        level: 4,
        parentCode: '42351',
        hasChildren: false,
    },
    {
        code: '42352',
        name: 'Coal and Other Mineral and Ore Merchant Wholesalers',
        level: 3,
        parentCode: '4235',
        hasChildren: true,
    },
    {
        code: '423520',
        name: 'Coal and Other Mineral and Ore Merchant Wholesalers',
        level: 4,
        parentCode: '42352',
        hasChildren: false,
    },
    {
        code: '4236',
        name: 'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers',
        level: 2,
        parentCode: '423',
        hasChildren: true,
    },
    {
        code: '42361',
        name: 'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
        level: 3,
        parentCode: '4236',
        hasChildren: true,
    },
    {
        code: '423610',
        name: 'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers',
        level: 4,
        parentCode: '42361',
        hasChildren: false,
    },
    {
        code: '42362',
        name: 'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
        level: 3,
        parentCode: '4236',
        hasChildren: true,
    },
    {
        code: '423620',
        name: 'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers',
        level: 4,
        parentCode: '42362',
        hasChildren: false,
    },
    {
        code: '42369',
        name: 'Other Electronic Parts and Equipment Merchant Wholesalers',
        level: 3,
        parentCode: '4236',
        hasChildren: true,
    },
    {
        code: '423690',
        name: 'Other Electronic Parts and Equipment Merchant Wholesalers',
        level: 4,
        parentCode: '42369',
        hasChildren: false,
    },
    {
        code: '4237',
        name: 'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers',
        level: 2,
        parentCode: '423',
        hasChildren: true,
    },
    {
        code: '42371',
        name: 'Hardware Merchant Wholesalers',
        level: 3,
        parentCode: '4237',
        hasChildren: true,
    },
    {
        code: '423710',
        name: 'Hardware Merchant Wholesalers',
        level: 4,
        parentCode: '42371',
        hasChildren: false,
    },
    {
        code: '42372',
        name: 'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
        level: 3,
        parentCode: '4237',
        hasChildren: true,
    },
    {
        code: '423720',
        name: 'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers',
        level: 4,
        parentCode: '42372',
        hasChildren: false,
    },
    {
        code: '42373',
        name: 'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4237',
        hasChildren: true,
    },
    {
        code: '423730',
        name: 'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42373',
        hasChildren: false,
    },
    {
        code: '42374',
        name: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4237',
        hasChildren: true,
    },
    {
        code: '423740',
        name: 'Refrigeration Equipment and Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42374',
        hasChildren: false,
    },
    {
        code: '4238',
        name: 'Machinery, Equipment, and Supplies Merchant Wholesalers',
        level: 2,
        parentCode: '423',
        hasChildren: true,
    },
    {
        code: '42381',
        name: 'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
        level: 3,
        parentCode: '4238',
        hasChildren: true,
    },
    {
        code: '423810',
        name: 'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers',
        level: 4,
        parentCode: '42381',
        hasChildren: false,
    },
    {
        code: '42382',
        name: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
        level: 3,
        parentCode: '4238',
        hasChildren: true,
    },
    {
        code: '423820',
        name: 'Farm and Garden Machinery and Equipment Merchant Wholesalers',
        level: 4,
        parentCode: '42382',
        hasChildren: false,
    },
    {
        code: '42383',
        name: 'Industrial Machinery and Equipment Merchant Wholesalers',
        level: 3,
        parentCode: '4238',
        hasChildren: true,
    },
    {
        code: '423830',
        name: 'Industrial Machinery and Equipment Merchant Wholesalers',
        level: 4,
        parentCode: '42383',
        hasChildren: false,
    },
    {
        code: '42384',
        name: 'Industrial Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4238',
        hasChildren: true,
    },
    {
        code: '423840',
        name: 'Industrial Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42384',
        hasChildren: false,
    },
    {
        code: '42385',
        name: 'Service Establishment Equipment and Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4238',
        hasChildren: true,
    },
    {
        code: '423850',
        name: 'Service Establishment Equipment and Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42385',
        hasChildren: false,
    },
    {
        code: '42386',
        name: 'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
        level: 3,
        parentCode: '4238',
        hasChildren: true,
    },
    {
        code: '423860',
        name: 'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers',
        level: 4,
        parentCode: '42386',
        hasChildren: false,
    },
    {
        code: '4239',
        name: 'Miscellaneous Durable Goods Merchant Wholesalers',
        level: 2,
        parentCode: '423',
        hasChildren: true,
    },
    {
        code: '42391',
        name: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4239',
        hasChildren: true,
    },
    {
        code: '423910',
        name: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42391',
        hasChildren: false,
    },
    {
        code: '42392',
        name: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4239',
        hasChildren: true,
    },
    {
        code: '423920',
        name: 'Toy and Hobby Goods and Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42392',
        hasChildren: false,
    },
    {
        code: '42393',
        name: 'Recyclable Material Merchant Wholesalers',
        level: 3,
        parentCode: '4239',
        hasChildren: true,
    },
    {
        code: '423930',
        name: 'Recyclable Material Merchant Wholesalers',
        level: 4,
        parentCode: '42393',
        hasChildren: false,
    },
    {
        code: '42394',
        name: 'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
        level: 3,
        parentCode: '4239',
        hasChildren: true,
    },
    {
        code: '423940',
        name: 'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers',
        level: 4,
        parentCode: '42394',
        hasChildren: false,
    },
    {
        code: '42399',
        name: 'Other Miscellaneous Durable Goods Merchant Wholesalers',
        level: 3,
        parentCode: '4239',
        hasChildren: true,
    },
    {
        code: '423990',
        name: 'Other Miscellaneous Durable Goods Merchant Wholesalers',
        level: 4,
        parentCode: '42399',
        hasChildren: false,
    },
    {
        code: '424',
        name: 'Merchant Wholesalers, Nondurable Goods',
        level: 1,
        parentCode: '42',
        hasChildren: true,
    },
    {
        code: '4241',
        name: 'Paper and Paper Product Merchant Wholesalers',
        level: 2,
        parentCode: '424',
        hasChildren: true,
    },
    {
        code: '42411',
        name: 'Printing and Writing Paper Merchant Wholesalers',
        level: 3,
        parentCode: '4241',
        hasChildren: true,
    },
    {
        code: '424110',
        name: 'Printing and Writing Paper Merchant Wholesalers',
        level: 4,
        parentCode: '42411',
        hasChildren: false,
    },
    {
        code: '42412',
        name: 'Stationery and Office Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4241',
        hasChildren: true,
    },
    {
        code: '424120',
        name: 'Stationery and Office Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42412',
        hasChildren: false,
    },
    {
        code: '42413',
        name: 'Industrial and Personal Service Paper Merchant Wholesalers',
        level: 3,
        parentCode: '4241',
        hasChildren: true,
    },
    {
        code: '424130',
        name: 'Industrial and Personal Service Paper Merchant Wholesalers',
        level: 4,
        parentCode: '42413',
        hasChildren: false,
    },
    {
        code: '4242',
        name: "Drugs and Druggists' Sundries Merchant Wholesalers ",
        level: 2,
        parentCode: '424',
        hasChildren: true,
    },
    {
        code: '42421',
        name: "Drugs and Druggists' Sundries Merchant Wholesalers ",
        level: 3,
        parentCode: '4242',
        hasChildren: true,
    },
    {
        code: '424210',
        name: "Drugs and Druggists' Sundries Merchant Wholesalers ",
        level: 4,
        parentCode: '42421',
        hasChildren: false,
    },
    {
        code: '4243',
        name: 'Apparel, Piece Goods, and Notions Merchant Wholesalers',
        level: 2,
        parentCode: '424',
        hasChildren: true,
    },
    {
        code: '42431',
        name: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
        level: 3,
        parentCode: '4243',
        hasChildren: true,
    },
    {
        code: '424310',
        name: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers',
        level: 4,
        parentCode: '42431',
        hasChildren: false,
    },
    {
        code: '42432',
        name: "Men's and Boys' Clothing and Furnishings Merchant Wholesalers ",
        level: 3,
        parentCode: '4243',
        hasChildren: true,
    },
    {
        code: '424320',
        name: "Men's and Boys' Clothing and Furnishings Merchant Wholesalers ",
        level: 4,
        parentCode: '42432',
        hasChildren: false,
    },
    {
        code: '42433',
        name: "Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers ",
        level: 3,
        parentCode: '4243',
        hasChildren: true,
    },
    {
        code: '424330',
        name: "Women's, Children's, and Infants' Clothing and Accessories Merchant Wholesalers ",
        level: 4,
        parentCode: '42433',
        hasChildren: false,
    },
    {
        code: '42434',
        name: 'Footwear Merchant Wholesalers',
        level: 3,
        parentCode: '4243',
        hasChildren: true,
    },
    {
        code: '424340',
        name: 'Footwear Merchant Wholesalers',
        level: 4,
        parentCode: '42434',
        hasChildren: false,
    },
    {
        code: '4244',
        name: 'Grocery and Related Product Merchant Wholesalers',
        level: 2,
        parentCode: '424',
        hasChildren: true,
    },
    {
        code: '42441',
        name: 'General Line Grocery Merchant Wholesalers',
        level: 3,
        parentCode: '4244',
        hasChildren: true,
    },
    {
        code: '424410',
        name: 'General Line Grocery Merchant Wholesalers',
        level: 4,
        parentCode: '42441',
        hasChildren: false,
    },
    {
        code: '42442',
        name: 'Packaged Frozen Food Merchant Wholesalers',
        level: 3,
        parentCode: '4244',
        hasChildren: true,
    },
    {
        code: '424420',
        name: 'Packaged Frozen Food Merchant Wholesalers',
        level: 4,
        parentCode: '42442',
        hasChildren: false,
    },
    {
        code: '42443',
        name: 'Dairy Product (except Dried or Canned) Merchant Wholesalers',
        level: 3,
        parentCode: '4244',
        hasChildren: true,
    },
    {
        code: '424430',
        name: 'Dairy Product (except Dried or Canned) Merchant Wholesalers',
        level: 4,
        parentCode: '42443',
        hasChildren: false,
    },
    {
        code: '42444',
        name: 'Poultry and Poultry Product Merchant Wholesalers',
        level: 3,
        parentCode: '4244',
        hasChildren: true,
    },
    {
        code: '424440',
        name: 'Poultry and Poultry Product Merchant Wholesalers',
        level: 4,
        parentCode: '42444',
        hasChildren: false,
    },
    {
        code: '42445',
        name: 'Confectionery Merchant Wholesalers',
        level: 3,
        parentCode: '4244',
        hasChildren: true,
    },
    {
        code: '424450',
        name: 'Confectionery Merchant Wholesalers',
        level: 4,
        parentCode: '42445',
        hasChildren: false,
    },
    {
        code: '42446',
        name: 'Fish and Seafood Merchant Wholesalers',
        level: 3,
        parentCode: '4244',
        hasChildren: true,
    },
    {
        code: '424460',
        name: 'Fish and Seafood Merchant Wholesalers',
        level: 4,
        parentCode: '42446',
        hasChildren: false,
    },
    {
        code: '42447',
        name: 'Meat and Meat Product Merchant Wholesalers',
        level: 3,
        parentCode: '4244',
        hasChildren: true,
    },
    {
        code: '424470',
        name: 'Meat and Meat Product Merchant Wholesalers',
        level: 4,
        parentCode: '42447',
        hasChildren: false,
    },
    {
        code: '42448',
        name: 'Fresh Fruit and Vegetable Merchant Wholesalers',
        level: 3,
        parentCode: '4244',
        hasChildren: true,
    },
    {
        code: '424480',
        name: 'Fresh Fruit and Vegetable Merchant Wholesalers',
        level: 4,
        parentCode: '42448',
        hasChildren: false,
    },
    {
        code: '42449',
        name: 'Other Grocery and Related Products Merchant Wholesalers',
        level: 3,
        parentCode: '4244',
        hasChildren: true,
    },
    {
        code: '424490',
        name: 'Other Grocery and Related Products Merchant Wholesalers',
        level: 4,
        parentCode: '42449',
        hasChildren: false,
    },
    {
        code: '4245',
        name: 'Farm Product Raw Material Merchant Wholesalers',
        level: 2,
        parentCode: '424',
        hasChildren: true,
    },
    {
        code: '42451',
        name: 'Grain and Field Bean Merchant Wholesalers',
        level: 3,
        parentCode: '4245',
        hasChildren: true,
    },
    {
        code: '424510',
        name: 'Grain and Field Bean Merchant Wholesalers',
        level: 4,
        parentCode: '42451',
        hasChildren: false,
    },
    {
        code: '42452',
        name: 'Livestock Merchant Wholesalers',
        level: 3,
        parentCode: '4245',
        hasChildren: true,
    },
    {
        code: '424520',
        name: 'Livestock Merchant Wholesalers',
        level: 4,
        parentCode: '42452',
        hasChildren: false,
    },
    {
        code: '42459',
        name: 'Other Farm Product Raw Material Merchant Wholesalers',
        level: 3,
        parentCode: '4245',
        hasChildren: true,
    },
    {
        code: '424590',
        name: 'Other Farm Product Raw Material Merchant Wholesalers',
        level: 4,
        parentCode: '42459',
        hasChildren: false,
    },
    {
        code: '4246',
        name: 'Chemical and Allied Products Merchant Wholesalers',
        level: 2,
        parentCode: '424',
        hasChildren: true,
    },
    {
        code: '42461',
        name: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
        level: 3,
        parentCode: '4246',
        hasChildren: true,
    },
    {
        code: '424610',
        name: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers',
        level: 4,
        parentCode: '42461',
        hasChildren: false,
    },
    {
        code: '42469',
        name: 'Other Chemical and Allied Products Merchant Wholesalers',
        level: 3,
        parentCode: '4246',
        hasChildren: true,
    },
    {
        code: '424690',
        name: 'Other Chemical and Allied Products Merchant Wholesalers',
        level: 4,
        parentCode: '42469',
        hasChildren: false,
    },
    {
        code: '4247',
        name: 'Petroleum and Petroleum Products Merchant Wholesalers',
        level: 2,
        parentCode: '424',
        hasChildren: true,
    },
    {
        code: '42471',
        name: 'Petroleum Bulk Stations and Terminals',
        level: 3,
        parentCode: '4247',
        hasChildren: true,
    },
    {
        code: '424710',
        name: 'Petroleum Bulk Stations and Terminals',
        level: 4,
        parentCode: '42471',
        hasChildren: false,
    },
    {
        code: '42472',
        name: 'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
        level: 3,
        parentCode: '4247',
        hasChildren: true,
    },
    {
        code: '424720',
        name: 'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)',
        level: 4,
        parentCode: '42472',
        hasChildren: false,
    },
    {
        code: '4248',
        name: 'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers',
        level: 2,
        parentCode: '424',
        hasChildren: true,
    },
    {
        code: '42481',
        name: 'Beer and Ale Merchant Wholesalers',
        level: 3,
        parentCode: '4248',
        hasChildren: true,
    },
    {
        code: '424810',
        name: 'Beer and Ale Merchant Wholesalers',
        level: 4,
        parentCode: '42481',
        hasChildren: false,
    },
    {
        code: '42482',
        name: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
        level: 3,
        parentCode: '4248',
        hasChildren: true,
    },
    {
        code: '424820',
        name: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers',
        level: 4,
        parentCode: '42482',
        hasChildren: false,
    },
    {
        code: '4249',
        name: 'Miscellaneous Nondurable Goods Merchant Wholesalers',
        level: 2,
        parentCode: '424',
        hasChildren: true,
    },
    {
        code: '42491',
        name: 'Farm Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4249',
        hasChildren: true,
    },
    {
        code: '424910',
        name: 'Farm Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42491',
        hasChildren: false,
    },
    {
        code: '42492',
        name: 'Book, Periodical, and Newspaper Merchant Wholesalers',
        level: 3,
        parentCode: '4249',
        hasChildren: true,
    },
    {
        code: '424920',
        name: 'Book, Periodical, and Newspaper Merchant Wholesalers',
        level: 4,
        parentCode: '42492',
        hasChildren: false,
    },
    {
        code: '42493',
        name: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers ",
        level: 3,
        parentCode: '4249',
        hasChildren: true,
    },
    {
        code: '424930',
        name: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers ",
        level: 4,
        parentCode: '42493',
        hasChildren: false,
    },
    {
        code: '42494',
        name: 'Tobacco and Tobacco Product Merchant Wholesalers',
        level: 3,
        parentCode: '4249',
        hasChildren: true,
    },
    {
        code: '424940',
        name: 'Tobacco and Tobacco Product Merchant Wholesalers',
        level: 4,
        parentCode: '42494',
        hasChildren: false,
    },
    {
        code: '42495',
        name: 'Paint, Varnish, and Supplies Merchant Wholesalers',
        level: 3,
        parentCode: '4249',
        hasChildren: true,
    },
    {
        code: '424950',
        name: 'Paint, Varnish, and Supplies Merchant Wholesalers',
        level: 4,
        parentCode: '42495',
        hasChildren: false,
    },
    {
        code: '42499',
        name: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
        level: 3,
        parentCode: '4249',
        hasChildren: true,
    },
    {
        code: '424990',
        name: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers',
        level: 4,
        parentCode: '42499',
        hasChildren: false,
    },
    {
        code: '425',
        name: 'Wholesale Electronic Markets and Agents and Brokers',
        level: 1,
        parentCode: '42',
        hasChildren: true,
    },
    {
        code: '4251',
        name: 'Wholesale Electronic Markets and Agents and Brokers',
        level: 2,
        parentCode: '425',
        hasChildren: true,
    },
    {
        code: '42511',
        name: 'Business to Business Electronic Markets',
        level: 3,
        parentCode: '4251',
        hasChildren: true,
    },
    {
        code: '425110',
        name: 'Business to Business Electronic Markets',
        level: 4,
        parentCode: '42511',
        hasChildren: false,
    },
    {
        code: '42512',
        name: 'Wholesale Trade Agents and Brokers',
        level: 3,
        parentCode: '4251',
        hasChildren: true,
    },
    {
        code: '425120',
        name: 'Wholesale Trade Agents and Brokers',
        level: 4,
        parentCode: '42512',
        hasChildren: false,
    },
    {
        code: '44-45',
        name: 'Retail Trade',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '441',
        name: 'Motor Vehicle and Parts Dealers',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4411',
        name: 'Automobile Dealers',
        level: 2,
        parentCode: '441',
        hasChildren: true,
    },
    {
        code: '44111',
        name: 'New Car Dealers',
        level: 3,
        parentCode: '4411',
        hasChildren: true,
    },
    {
        code: '441110',
        name: 'New Car Dealers',
        level: 4,
        parentCode: '44111',
        hasChildren: false,
    },
    {
        code: '44112',
        name: 'Used Car Dealers',
        level: 3,
        parentCode: '4411',
        hasChildren: true,
    },
    {
        code: '441120',
        name: 'Used Car Dealers',
        level: 4,
        parentCode: '44112',
        hasChildren: false,
    },
    {
        code: '4412',
        name: 'Other Motor Vehicle Dealers',
        level: 2,
        parentCode: '441',
        hasChildren: true,
    },
    {
        code: '44121',
        name: 'Recreational Vehicle Dealers',
        level: 3,
        parentCode: '4412',
        hasChildren: true,
    },
    {
        code: '441210',
        name: 'Recreational Vehicle Dealers',
        level: 4,
        parentCode: '44121',
        hasChildren: false,
    },
    {
        code: '44122',
        name: 'Motorcycle, Boat, and Other Motor Vehicle Dealers',
        level: 3,
        parentCode: '4412',
        hasChildren: true,
    },
    {
        code: '441222',
        name: 'Boat Dealers',
        level: 4,
        parentCode: '44122',
        hasChildren: false,
    },
    {
        code: '441228',
        name: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers',
        level: 4,
        parentCode: '44122',
        hasChildren: false,
    },
    {
        code: '4413',
        name: 'Automotive Parts, Accessories, and Tire Stores',
        level: 2,
        parentCode: '441',
        hasChildren: true,
    },
    {
        code: '44131',
        name: 'Automotive Parts and Accessories Stores',
        level: 3,
        parentCode: '4413',
        hasChildren: true,
    },
    {
        code: '441310',
        name: 'Automotive Parts and Accessories Stores',
        level: 4,
        parentCode: '44131',
        hasChildren: false,
    },
    {
        code: '44132',
        name: 'Tire Dealers',
        level: 3,
        parentCode: '4413',
        hasChildren: true,
    },
    {
        code: '441320',
        name: 'Tire Dealers',
        level: 4,
        parentCode: '44132',
        hasChildren: false,
    },
    {
        code: '442',
        name: 'Furniture and Home Furnishings Stores',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4421',
        name: 'Furniture Stores',
        level: 2,
        parentCode: '442',
        hasChildren: true,
    },
    {
        code: '44211',
        name: 'Furniture Stores',
        level: 3,
        parentCode: '4421',
        hasChildren: true,
    },
    {
        code: '442110',
        name: 'Furniture Stores',
        level: 4,
        parentCode: '44211',
        hasChildren: false,
    },
    {
        code: '4422',
        name: 'Home Furnishings Stores',
        level: 2,
        parentCode: '442',
        hasChildren: true,
    },
    {
        code: '44221',
        name: 'Floor Covering Stores',
        level: 3,
        parentCode: '4422',
        hasChildren: true,
    },
    {
        code: '442210',
        name: 'Floor Covering Stores',
        level: 4,
        parentCode: '44221',
        hasChildren: false,
    },
    {
        code: '44229',
        name: 'Other Home Furnishings Stores',
        level: 3,
        parentCode: '4422',
        hasChildren: true,
    },
    {
        code: '442291',
        name: 'Window Treatment Stores',
        level: 4,
        parentCode: '44229',
        hasChildren: false,
    },
    {
        code: '442299',
        name: 'All Other Home Furnishings Stores',
        level: 4,
        parentCode: '44229',
        hasChildren: false,
    },
    {
        code: '443',
        name: 'Electronics and Appliance Stores',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4431',
        name: 'Electronics and Appliance Stores',
        level: 2,
        parentCode: '443',
        hasChildren: true,
    },
    {
        code: '44314',
        name: 'Electronics and Appliance Stores',
        level: 3,
        parentCode: '4431',
        hasChildren: true,
    },
    {
        code: '443141',
        name: 'Household Appliance Stores',
        level: 4,
        parentCode: '44314',
        hasChildren: false,
    },
    {
        code: '443142',
        name: 'Electronics Stores',
        level: 4,
        parentCode: '44314',
        hasChildren: false,
    },
    {
        code: '444',
        name: 'Building Material and Garden Equipment and Supplies Dealers',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4441',
        name: 'Building Material and Supplies Dealers',
        level: 2,
        parentCode: '444',
        hasChildren: true,
    },
    {
        code: '44411',
        name: 'Home Centers',
        level: 3,
        parentCode: '4441',
        hasChildren: true,
    },
    {
        code: '444110',
        name: 'Home Centers',
        level: 4,
        parentCode: '44411',
        hasChildren: false,
    },
    {
        code: '44412',
        name: 'Paint and Wallpaper Stores',
        level: 3,
        parentCode: '4441',
        hasChildren: true,
    },
    {
        code: '444120',
        name: 'Paint and Wallpaper Stores',
        level: 4,
        parentCode: '44412',
        hasChildren: false,
    },
    {
        code: '44413',
        name: 'Hardware Stores',
        level: 3,
        parentCode: '4441',
        hasChildren: true,
    },
    {
        code: '444130',
        name: 'Hardware Stores',
        level: 4,
        parentCode: '44413',
        hasChildren: false,
    },
    {
        code: '44419',
        name: 'Other Building Material Dealers',
        level: 3,
        parentCode: '4441',
        hasChildren: true,
    },
    {
        code: '444190',
        name: 'Other Building Material Dealers',
        level: 4,
        parentCode: '44419',
        hasChildren: false,
    },
    {
        code: '4442',
        name: 'Lawn and Garden Equipment and Supplies Stores',
        level: 2,
        parentCode: '444',
        hasChildren: true,
    },
    {
        code: '44421',
        name: 'Outdoor Power Equipment Stores',
        level: 3,
        parentCode: '4442',
        hasChildren: true,
    },
    {
        code: '444210',
        name: 'Outdoor Power Equipment Stores',
        level: 4,
        parentCode: '44421',
        hasChildren: false,
    },
    {
        code: '44422',
        name: 'Nursery, Garden Center, and Farm Supply Stores',
        level: 3,
        parentCode: '4442',
        hasChildren: true,
    },
    {
        code: '444220',
        name: 'Nursery, Garden Center, and Farm Supply Stores',
        level: 4,
        parentCode: '44422',
        hasChildren: false,
    },
    {
        code: '445',
        name: 'Food and Beverage Stores',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4451',
        name: 'Grocery Stores',
        level: 2,
        parentCode: '445',
        hasChildren: true,
    },
    {
        code: '44511',
        name: 'Supermarkets and Other Grocery (except Convenience) Stores',
        level: 3,
        parentCode: '4451',
        hasChildren: true,
    },
    {
        code: '445110',
        name: 'Supermarkets and Other Grocery (except Convenience) Stores',
        level: 4,
        parentCode: '44511',
        hasChildren: false,
    },
    {
        code: '44512',
        name: 'Convenience Stores',
        level: 3,
        parentCode: '4451',
        hasChildren: true,
    },
    {
        code: '445120',
        name: 'Convenience Stores',
        level: 4,
        parentCode: '44512',
        hasChildren: false,
    },
    {
        code: '4452',
        name: 'Specialty Food Stores',
        level: 2,
        parentCode: '445',
        hasChildren: true,
    },
    {
        code: '44521',
        name: 'Meat Markets',
        level: 3,
        parentCode: '4452',
        hasChildren: true,
    },
    {
        code: '445210',
        name: 'Meat Markets',
        level: 4,
        parentCode: '44521',
        hasChildren: false,
    },
    {
        code: '44522',
        name: 'Fish and Seafood Markets',
        level: 3,
        parentCode: '4452',
        hasChildren: true,
    },
    {
        code: '445220',
        name: 'Fish and Seafood Markets',
        level: 4,
        parentCode: '44522',
        hasChildren: false,
    },
    {
        code: '44523',
        name: 'Fruit and Vegetable Markets',
        level: 3,
        parentCode: '4452',
        hasChildren: true,
    },
    {
        code: '445230',
        name: 'Fruit and Vegetable Markets',
        level: 4,
        parentCode: '44523',
        hasChildren: false,
    },
    {
        code: '44529',
        name: 'Other Specialty Food Stores',
        level: 3,
        parentCode: '4452',
        hasChildren: true,
    },
    {
        code: '445291',
        name: 'Baked Goods Stores',
        level: 4,
        parentCode: '44529',
        hasChildren: false,
    },
    {
        code: '445292',
        name: 'Confectionery and Nut Stores',
        level: 4,
        parentCode: '44529',
        hasChildren: false,
    },
    {
        code: '445299',
        name: 'All Other Specialty Food Stores',
        level: 4,
        parentCode: '44529',
        hasChildren: false,
    },
    {
        code: '4453',
        name: 'Beer, Wine, and Liquor Stores',
        level: 2,
        parentCode: '445',
        hasChildren: true,
    },
    {
        code: '44531',
        name: 'Beer, Wine, and Liquor Stores',
        level: 3,
        parentCode: '4453',
        hasChildren: true,
    },
    {
        code: '445310',
        name: 'Beer, Wine, and Liquor Stores',
        level: 4,
        parentCode: '44531',
        hasChildren: false,
    },
    {
        code: '446',
        name: 'Health and Personal Care Stores',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4461',
        name: 'Health and Personal Care Stores',
        level: 2,
        parentCode: '446',
        hasChildren: true,
    },
    {
        code: '44611',
        name: 'Pharmacies and Drug Stores',
        level: 3,
        parentCode: '4461',
        hasChildren: true,
    },
    {
        code: '446110',
        name: 'Pharmacies and Drug Stores',
        level: 4,
        parentCode: '44611',
        hasChildren: false,
    },
    {
        code: '44612',
        name: 'Cosmetics, Beauty Supplies, and Perfume Stores',
        level: 3,
        parentCode: '4461',
        hasChildren: true,
    },
    {
        code: '446120',
        name: 'Cosmetics, Beauty Supplies, and Perfume Stores',
        level: 4,
        parentCode: '44612',
        hasChildren: false,
    },
    {
        code: '44613',
        name: 'Optical Goods Stores',
        level: 3,
        parentCode: '4461',
        hasChildren: true,
    },
    {
        code: '446130',
        name: 'Optical Goods Stores',
        level: 4,
        parentCode: '44613',
        hasChildren: false,
    },
    {
        code: '44619',
        name: 'Other Health and Personal Care Stores',
        level: 3,
        parentCode: '4461',
        hasChildren: true,
    },
    {
        code: '446191',
        name: 'Food (Health) Supplement Stores',
        level: 4,
        parentCode: '44619',
        hasChildren: false,
    },
    {
        code: '446199',
        name: 'All Other Health and Personal Care Stores',
        level: 4,
        parentCode: '44619',
        hasChildren: false,
    },
    {
        code: '447',
        name: 'Gasoline Stations',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4471',
        name: 'Gasoline Stations',
        level: 2,
        parentCode: '447',
        hasChildren: true,
    },
    {
        code: '44711',
        name: 'Gasoline Stations with Convenience Stores',
        level: 3,
        parentCode: '4471',
        hasChildren: true,
    },
    {
        code: '447110',
        name: 'Gasoline Stations with Convenience Stores',
        level: 4,
        parentCode: '44711',
        hasChildren: false,
    },
    {
        code: '44719',
        name: 'Other Gasoline Stations',
        level: 3,
        parentCode: '4471',
        hasChildren: true,
    },
    {
        code: '447190',
        name: 'Other Gasoline Stations',
        level: 4,
        parentCode: '44719',
        hasChildren: false,
    },
    {
        code: '448',
        name: 'Clothing and Clothing Accessories Stores',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4481',
        name: 'Clothing Stores',
        level: 2,
        parentCode: '448',
        hasChildren: true,
    },
    {
        code: '44811',
        name: "Men's Clothing Stores ",
        level: 3,
        parentCode: '4481',
        hasChildren: true,
    },
    {
        code: '448110',
        name: "Men's Clothing Stores ",
        level: 4,
        parentCode: '44811',
        hasChildren: false,
    },
    {
        code: '44812',
        name: "Women's Clothing Stores ",
        level: 3,
        parentCode: '4481',
        hasChildren: true,
    },
    {
        code: '448120',
        name: "Women's Clothing Stores ",
        level: 4,
        parentCode: '44812',
        hasChildren: false,
    },
    {
        code: '44813',
        name: "Children's and Infants' Clothing Stores ",
        level: 3,
        parentCode: '4481',
        hasChildren: true,
    },
    {
        code: '448130',
        name: "Children's and Infants' Clothing Stores ",
        level: 4,
        parentCode: '44813',
        hasChildren: false,
    },
    {
        code: '44814',
        name: 'Family Clothing Stores',
        level: 3,
        parentCode: '4481',
        hasChildren: true,
    },
    {
        code: '448140',
        name: 'Family Clothing Stores',
        level: 4,
        parentCode: '44814',
        hasChildren: false,
    },
    {
        code: '44815',
        name: 'Clothing Accessories Stores',
        level: 3,
        parentCode: '4481',
        hasChildren: true,
    },
    {
        code: '448150',
        name: 'Clothing Accessories Stores',
        level: 4,
        parentCode: '44815',
        hasChildren: false,
    },
    {
        code: '44819',
        name: 'Other Clothing Stores',
        level: 3,
        parentCode: '4481',
        hasChildren: true,
    },
    {
        code: '448190',
        name: 'Other Clothing Stores',
        level: 4,
        parentCode: '44819',
        hasChildren: false,
    },
    {
        code: '4482',
        name: 'Shoe Stores',
        level: 2,
        parentCode: '448',
        hasChildren: true,
    },
    {
        code: '44821',
        name: 'Shoe Stores',
        level: 3,
        parentCode: '4482',
        hasChildren: true,
    },
    {
        code: '448210',
        name: 'Shoe Stores',
        level: 4,
        parentCode: '44821',
        hasChildren: false,
    },
    {
        code: '4483',
        name: 'Jewelry, Luggage, and Leather Goods Stores',
        level: 2,
        parentCode: '448',
        hasChildren: true,
    },
    {
        code: '44831',
        name: 'Jewelry Stores',
        level: 3,
        parentCode: '4483',
        hasChildren: true,
    },
    {
        code: '448310',
        name: 'Jewelry Stores',
        level: 4,
        parentCode: '44831',
        hasChildren: false,
    },
    {
        code: '44832',
        name: 'Luggage and Leather Goods Stores',
        level: 3,
        parentCode: '4483',
        hasChildren: true,
    },
    {
        code: '448320',
        name: 'Luggage and Leather Goods Stores',
        level: 4,
        parentCode: '44832',
        hasChildren: false,
    },
    {
        code: '451',
        name: 'Sporting Goods, Hobby, Musical Instrument, and Book Stores',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4511',
        name: 'Sporting Goods, Hobby, and Musical Instrument Stores',
        level: 2,
        parentCode: '451',
        hasChildren: true,
    },
    {
        code: '45111',
        name: 'Sporting Goods Stores',
        level: 3,
        parentCode: '4511',
        hasChildren: true,
    },
    {
        code: '451110',
        name: 'Sporting Goods Stores',
        level: 4,
        parentCode: '45111',
        hasChildren: false,
    },
    {
        code: '45112',
        name: 'Hobby, Toy, and Game Stores',
        level: 3,
        parentCode: '4511',
        hasChildren: true,
    },
    {
        code: '451120',
        name: 'Hobby, Toy, and Game Stores',
        level: 4,
        parentCode: '45112',
        hasChildren: false,
    },
    {
        code: '45113',
        name: 'Sewing, Needlework, and Piece Goods Stores',
        level: 3,
        parentCode: '4511',
        hasChildren: true,
    },
    {
        code: '451130',
        name: 'Sewing, Needlework, and Piece Goods Stores',
        level: 4,
        parentCode: '45113',
        hasChildren: false,
    },
    {
        code: '45114',
        name: 'Musical Instrument and Supplies Stores',
        level: 3,
        parentCode: '4511',
        hasChildren: true,
    },
    {
        code: '451140',
        name: 'Musical Instrument and Supplies Stores',
        level: 4,
        parentCode: '45114',
        hasChildren: false,
    },
    {
        code: '4512',
        name: 'Book Stores and News Dealers',
        level: 2,
        parentCode: '451',
        hasChildren: true,
    },
    {
        code: '45121',
        name: 'Book Stores and News Dealers',
        level: 3,
        parentCode: '4512',
        hasChildren: true,
    },
    {
        code: '451211',
        name: 'Book Stores',
        level: 4,
        parentCode: '45121',
        hasChildren: false,
    },
    {
        code: '451212',
        name: 'News Dealers and Newsstands',
        level: 4,
        parentCode: '45121',
        hasChildren: false,
    },
    {
        code: '452',
        name: 'General Merchandise Stores',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4522',
        name: 'Department Stores',
        level: 2,
        parentCode: '452',
        hasChildren: true,
    },
    {
        code: '45221',
        name: 'Department Stores',
        level: 3,
        parentCode: '4522',
        hasChildren: true,
    },
    {
        code: '452210',
        name: 'Department Stores',
        level: 4,
        parentCode: '45221',
        hasChildren: false,
    },
    {
        code: '4523',
        name: 'General Merchandise Stores, including Warehouse Clubs and Supercenters',
        level: 2,
        parentCode: '452',
        hasChildren: true,
    },
    {
        code: '45231',
        name: 'General Merchandise Stores, including Warehouse Clubs and Supercenters',
        level: 3,
        parentCode: '4523',
        hasChildren: true,
    },
    {
        code: '452311',
        name: 'Warehouse Clubs and Supercenters',
        level: 4,
        parentCode: '45231',
        hasChildren: false,
    },
    {
        code: '452319',
        name: 'All Other General Merchandise Stores',
        level: 4,
        parentCode: '45231',
        hasChildren: false,
    },
    {
        code: '453',
        name: 'Miscellaneous Store Retailers',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4531',
        name: 'Florists',
        level: 2,
        parentCode: '453',
        hasChildren: true,
    },
    {
        code: '45311',
        name: 'Florists',
        level: 3,
        parentCode: '4531',
        hasChildren: true,
    },
    {
        code: '453110',
        name: 'Florists',
        level: 4,
        parentCode: '45311',
        hasChildren: false,
    },
    {
        code: '4532',
        name: 'Office Supplies, Stationery, and Gift Stores',
        level: 2,
        parentCode: '453',
        hasChildren: true,
    },
    {
        code: '45321',
        name: 'Office Supplies and Stationery Stores',
        level: 3,
        parentCode: '4532',
        hasChildren: true,
    },
    {
        code: '453210',
        name: 'Office Supplies and Stationery Stores',
        level: 4,
        parentCode: '45321',
        hasChildren: false,
    },
    {
        code: '45322',
        name: 'Gift, Novelty, and Souvenir Stores',
        level: 3,
        parentCode: '4532',
        hasChildren: true,
    },
    {
        code: '453220',
        name: 'Gift, Novelty, and Souvenir Stores',
        level: 4,
        parentCode: '45322',
        hasChildren: false,
    },
    {
        code: '4533',
        name: 'Used Merchandise Stores',
        level: 2,
        parentCode: '453',
        hasChildren: true,
    },
    {
        code: '45331',
        name: 'Used Merchandise Stores',
        level: 3,
        parentCode: '4533',
        hasChildren: true,
    },
    {
        code: '453310',
        name: 'Used Merchandise Stores',
        level: 4,
        parentCode: '45331',
        hasChildren: false,
    },
    {
        code: '4539',
        name: 'Other Miscellaneous Store Retailers',
        level: 2,
        parentCode: '453',
        hasChildren: true,
    },
    {
        code: '45391',
        name: 'Pet and Pet Supplies Stores',
        level: 3,
        parentCode: '4539',
        hasChildren: true,
    },
    {
        code: '453910',
        name: 'Pet and Pet Supplies Stores',
        level: 4,
        parentCode: '45391',
        hasChildren: false,
    },
    {
        code: '45392',
        name: 'Art Dealers',
        level: 3,
        parentCode: '4539',
        hasChildren: true,
    },
    {
        code: '453920',
        name: 'Art Dealers',
        level: 4,
        parentCode: '45392',
        hasChildren: false,
    },
    {
        code: '45393',
        name: 'Manufactured (Mobile) Home Dealers',
        level: 3,
        parentCode: '4539',
        hasChildren: true,
    },
    {
        code: '453930',
        name: 'Manufactured (Mobile) Home Dealers',
        level: 4,
        parentCode: '45393',
        hasChildren: false,
    },
    {
        code: '45399',
        name: 'All Other Miscellaneous Store Retailers',
        level: 3,
        parentCode: '4539',
        hasChildren: true,
    },
    {
        code: '453991',
        name: 'Tobacco Stores',
        level: 4,
        parentCode: '45399',
        hasChildren: false,
    },
    {
        code: '453998',
        name: 'All Other Miscellaneous Store Retailers (except Tobacco Stores)',
        level: 4,
        parentCode: '45399',
        hasChildren: false,
    },
    {
        code: '454',
        name: 'Nonstore Retailers',
        level: 1,
        parentCode: '44-45',
        hasChildren: true,
    },
    {
        code: '4541',
        name: 'Electronic Shopping and Mail-Order Houses',
        level: 2,
        parentCode: '454',
        hasChildren: true,
    },
    {
        code: '45411',
        name: 'Electronic Shopping and Mail-Order Houses',
        level: 3,
        parentCode: '4541',
        hasChildren: true,
    },
    {
        code: '454110',
        name: 'Electronic Shopping and Mail-Order Houses',
        level: 4,
        parentCode: '45411',
        hasChildren: false,
    },
    {
        code: '4542',
        name: 'Vending Machine Operators',
        level: 2,
        parentCode: '454',
        hasChildren: true,
    },
    {
        code: '45421',
        name: 'Vending Machine Operators',
        level: 3,
        parentCode: '4542',
        hasChildren: true,
    },
    {
        code: '454210',
        name: 'Vending Machine Operators',
        level: 4,
        parentCode: '45421',
        hasChildren: false,
    },
    {
        code: '4543',
        name: 'Direct Selling Establishments',
        level: 2,
        parentCode: '454',
        hasChildren: true,
    },
    {
        code: '45431',
        name: 'Fuel Dealers',
        level: 3,
        parentCode: '4543',
        hasChildren: true,
    },
    {
        code: '454310',
        name: 'Fuel Dealers',
        level: 4,
        parentCode: '45431',
        hasChildren: false,
    },
    {
        code: '45439',
        name: 'Other Direct Selling Establishments',
        level: 3,
        parentCode: '4543',
        hasChildren: true,
    },
    {
        code: '454390',
        name: 'Other Direct Selling Establishments',
        level: 4,
        parentCode: '45439',
        hasChildren: false,
    },
    {
        code: '48-49',
        name: 'Transportation and Warehousing',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '481',
        name: 'Air Transportation',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4811',
        name: 'Scheduled Air Transportation',
        level: 2,
        parentCode: '481',
        hasChildren: true,
    },
    {
        code: '48111',
        name: 'Scheduled Air Transportation',
        level: 3,
        parentCode: '4811',
        hasChildren: true,
    },
    {
        code: '481111',
        name: 'Scheduled Passenger Air Transportation',
        level: 4,
        parentCode: '48111',
        hasChildren: false,
    },
    {
        code: '481112',
        name: 'Scheduled Freight Air Transportation',
        level: 4,
        parentCode: '48111',
        hasChildren: false,
    },
    {
        code: '4812',
        name: 'Nonscheduled Air Transportation',
        level: 2,
        parentCode: '481',
        hasChildren: true,
    },
    {
        code: '48121',
        name: 'Nonscheduled Air Transportation',
        level: 3,
        parentCode: '4812',
        hasChildren: true,
    },
    {
        code: '481211',
        name: 'Nonscheduled Chartered Passenger Air Transportation',
        level: 4,
        parentCode: '48121',
        hasChildren: false,
    },
    {
        code: '481212',
        name: 'Nonscheduled Chartered Freight Air Transportation',
        level: 4,
        parentCode: '48121',
        hasChildren: false,
    },
    {
        code: '481219',
        name: 'Other Nonscheduled Air Transportation',
        level: 4,
        parentCode: '48121',
        hasChildren: false,
    },
    {
        code: '482',
        name: 'Rail Transportation',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4821',
        name: 'Rail Transportation',
        level: 2,
        parentCode: '482',
        hasChildren: true,
    },
    {
        code: '48211',
        name: 'Rail Transportation',
        level: 3,
        parentCode: '4821',
        hasChildren: true,
    },
    {
        code: '482111',
        name: 'Line-Haul Railroads',
        level: 4,
        parentCode: '48211',
        hasChildren: false,
    },
    {
        code: '482112',
        name: 'Short Line Railroads',
        level: 4,
        parentCode: '48211',
        hasChildren: false,
    },
    {
        code: '483',
        name: 'Water Transportation',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4831',
        name: 'Deep Sea, Coastal, and Great Lakes Water Transportation',
        level: 2,
        parentCode: '483',
        hasChildren: true,
    },
    {
        code: '48311',
        name: 'Deep Sea, Coastal, and Great Lakes Water Transportation',
        level: 3,
        parentCode: '4831',
        hasChildren: true,
    },
    {
        code: '483111',
        name: 'Deep Sea Freight Transportation',
        level: 4,
        parentCode: '48311',
        hasChildren: false,
    },
    {
        code: '483112',
        name: 'Deep Sea Passenger Transportation',
        level: 4,
        parentCode: '48311',
        hasChildren: false,
    },
    {
        code: '483113',
        name: 'Coastal and Great Lakes Freight Transportation',
        level: 4,
        parentCode: '48311',
        hasChildren: false,
    },
    {
        code: '483114',
        name: 'Coastal and Great Lakes Passenger Transportation',
        level: 4,
        parentCode: '48311',
        hasChildren: false,
    },
    {
        code: '4832',
        name: 'Inland Water Transportation',
        level: 2,
        parentCode: '483',
        hasChildren: true,
    },
    {
        code: '48321',
        name: 'Inland Water Transportation',
        level: 3,
        parentCode: '4832',
        hasChildren: true,
    },
    {
        code: '483211',
        name: 'Inland Water Freight Transportation',
        level: 4,
        parentCode: '48321',
        hasChildren: false,
    },
    {
        code: '483212',
        name: 'Inland Water Passenger Transportation',
        level: 4,
        parentCode: '48321',
        hasChildren: false,
    },
    {
        code: '484',
        name: 'Truck Transportation',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4841',
        name: 'General Freight Trucking',
        level: 2,
        parentCode: '484',
        hasChildren: true,
    },
    {
        code: '48411',
        name: 'General Freight Trucking, Local',
        level: 3,
        parentCode: '4841',
        hasChildren: true,
    },
    {
        code: '484110',
        name: 'General Freight Trucking, Local',
        level: 4,
        parentCode: '48411',
        hasChildren: false,
    },
    {
        code: '48412',
        name: 'General Freight Trucking, Long-Distance',
        level: 3,
        parentCode: '4841',
        hasChildren: true,
    },
    {
        code: '484121',
        name: 'General Freight Trucking, Long-Distance, Truckload',
        level: 4,
        parentCode: '48412',
        hasChildren: false,
    },
    {
        code: '484122',
        name: 'General Freight Trucking, Long-Distance, Less Than Truckload',
        level: 4,
        parentCode: '48412',
        hasChildren: false,
    },
    {
        code: '4842',
        name: 'Specialized Freight Trucking',
        level: 2,
        parentCode: '484',
        hasChildren: true,
    },
    {
        code: '48421',
        name: 'Used Household and Office Goods Moving',
        level: 3,
        parentCode: '4842',
        hasChildren: true,
    },
    {
        code: '484210',
        name: 'Used Household and Office Goods Moving',
        level: 4,
        parentCode: '48421',
        hasChildren: false,
    },
    {
        code: '48422',
        name: 'Specialized Freight (except Used Goods) Trucking, Local',
        level: 3,
        parentCode: '4842',
        hasChildren: true,
    },
    {
        code: '484220',
        name: 'Specialized Freight (except Used Goods) Trucking, Local',
        level: 4,
        parentCode: '48422',
        hasChildren: false,
    },
    {
        code: '48423',
        name: 'Specialized Freight (except Used Goods) Trucking, Long-Distance',
        level: 3,
        parentCode: '4842',
        hasChildren: true,
    },
    {
        code: '484230',
        name: 'Specialized Freight (except Used Goods) Trucking, Long-Distance',
        level: 4,
        parentCode: '48423',
        hasChildren: false,
    },
    {
        code: '485',
        name: 'Transit and Ground Passenger Transportation',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4851',
        name: 'Urban Transit Systems',
        level: 2,
        parentCode: '485',
        hasChildren: true,
    },
    {
        code: '48511',
        name: 'Urban Transit Systems',
        level: 3,
        parentCode: '4851',
        hasChildren: true,
    },
    {
        code: '485111',
        name: 'Mixed Mode Transit Systems',
        level: 4,
        parentCode: '48511',
        hasChildren: false,
    },
    {
        code: '485112',
        name: 'Commuter Rail Systems',
        level: 4,
        parentCode: '48511',
        hasChildren: false,
    },
    {
        code: '485113',
        name: 'Bus and Other Motor Vehicle Transit Systems',
        level: 4,
        parentCode: '48511',
        hasChildren: false,
    },
    {
        code: '485119',
        name: 'Other Urban Transit Systems',
        level: 4,
        parentCode: '48511',
        hasChildren: false,
    },
    {
        code: '4852',
        name: 'Interurban and Rural Bus Transportation',
        level: 2,
        parentCode: '485',
        hasChildren: true,
    },
    {
        code: '48521',
        name: 'Interurban and Rural Bus Transportation',
        level: 3,
        parentCode: '4852',
        hasChildren: true,
    },
    {
        code: '485210',
        name: 'Interurban and Rural Bus Transportation',
        level: 4,
        parentCode: '48521',
        hasChildren: false,
    },
    {
        code: '4853',
        name: 'Taxi and Limousine Service',
        level: 2,
        parentCode: '485',
        hasChildren: true,
    },
    {
        code: '48531',
        name: 'Taxi Service',
        level: 3,
        parentCode: '4853',
        hasChildren: true,
    },
    {
        code: '485310',
        name: 'Taxi Service',
        level: 4,
        parentCode: '48531',
        hasChildren: false,
    },
    {
        code: '48532',
        name: 'Limousine Service',
        level: 3,
        parentCode: '4853',
        hasChildren: true,
    },
    {
        code: '485320',
        name: 'Limousine Service',
        level: 4,
        parentCode: '48532',
        hasChildren: false,
    },
    {
        code: '4854',
        name: 'School and Employee Bus Transportation',
        level: 2,
        parentCode: '485',
        hasChildren: true,
    },
    {
        code: '48541',
        name: 'School and Employee Bus Transportation',
        level: 3,
        parentCode: '4854',
        hasChildren: true,
    },
    {
        code: '485410',
        name: 'School and Employee Bus Transportation',
        level: 4,
        parentCode: '48541',
        hasChildren: false,
    },
    {
        code: '4855',
        name: 'Charter Bus Industry',
        level: 2,
        parentCode: '485',
        hasChildren: true,
    },
    {
        code: '48551',
        name: 'Charter Bus Industry',
        level: 3,
        parentCode: '4855',
        hasChildren: true,
    },
    {
        code: '485510',
        name: 'Charter Bus Industry',
        level: 4,
        parentCode: '48551',
        hasChildren: false,
    },
    {
        code: '4859',
        name: 'Other Transit and Ground Passenger Transportation',
        level: 2,
        parentCode: '485',
        hasChildren: true,
    },
    {
        code: '48599',
        name: 'Other Transit and Ground Passenger Transportation',
        level: 3,
        parentCode: '4859',
        hasChildren: true,
    },
    {
        code: '485991',
        name: 'Special Needs Transportation',
        level: 4,
        parentCode: '48599',
        hasChildren: false,
    },
    {
        code: '485999',
        name: 'All Other Transit and Ground Passenger Transportation',
        level: 4,
        parentCode: '48599',
        hasChildren: false,
    },
    {
        code: '486',
        name: 'Pipeline Transportation',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4861',
        name: 'Pipeline Transportation of Crude Oil',
        level: 2,
        parentCode: '486',
        hasChildren: true,
    },
    {
        code: '48611',
        name: 'Pipeline Transportation of Crude Oil',
        level: 3,
        parentCode: '4861',
        hasChildren: true,
    },
    {
        code: '486110',
        name: 'Pipeline Transportation of Crude Oil',
        level: 4,
        parentCode: '48611',
        hasChildren: false,
    },
    {
        code: '4862',
        name: 'Pipeline Transportation of Natural Gas',
        level: 2,
        parentCode: '486',
        hasChildren: true,
    },
    {
        code: '48621',
        name: 'Pipeline Transportation of Natural Gas',
        level: 3,
        parentCode: '4862',
        hasChildren: true,
    },
    {
        code: '486210',
        name: 'Pipeline Transportation of Natural Gas',
        level: 4,
        parentCode: '48621',
        hasChildren: false,
    },
    {
        code: '4869',
        name: 'Other Pipeline Transportation',
        level: 2,
        parentCode: '486',
        hasChildren: true,
    },
    {
        code: '48691',
        name: 'Pipeline Transportation of Refined Petroleum Products',
        level: 3,
        parentCode: '4869',
        hasChildren: true,
    },
    {
        code: '486910',
        name: 'Pipeline Transportation of Refined Petroleum Products',
        level: 4,
        parentCode: '48691',
        hasChildren: false,
    },
    {
        code: '48699',
        name: 'All Other Pipeline Transportation',
        level: 3,
        parentCode: '4869',
        hasChildren: true,
    },
    {
        code: '486990',
        name: 'All Other Pipeline Transportation',
        level: 4,
        parentCode: '48699',
        hasChildren: false,
    },
    {
        code: '487',
        name: 'Scenic and Sightseeing Transportation',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4871',
        name: 'Scenic and Sightseeing Transportation, Land',
        level: 2,
        parentCode: '487',
        hasChildren: true,
    },
    {
        code: '48711',
        name: 'Scenic and Sightseeing Transportation, Land',
        level: 3,
        parentCode: '4871',
        hasChildren: true,
    },
    {
        code: '487110',
        name: 'Scenic and Sightseeing Transportation, Land',
        level: 4,
        parentCode: '48711',
        hasChildren: false,
    },
    {
        code: '4872',
        name: 'Scenic and Sightseeing Transportation, Water',
        level: 2,
        parentCode: '487',
        hasChildren: true,
    },
    {
        code: '48721',
        name: 'Scenic and Sightseeing Transportation, Water',
        level: 3,
        parentCode: '4872',
        hasChildren: true,
    },
    {
        code: '487210',
        name: 'Scenic and Sightseeing Transportation, Water',
        level: 4,
        parentCode: '48721',
        hasChildren: false,
    },
    {
        code: '4879',
        name: 'Scenic and Sightseeing Transportation, Other',
        level: 2,
        parentCode: '487',
        hasChildren: true,
    },
    {
        code: '48799',
        name: 'Scenic and Sightseeing Transportation, Other',
        level: 3,
        parentCode: '4879',
        hasChildren: true,
    },
    {
        code: '487990',
        name: 'Scenic and Sightseeing Transportation, Other',
        level: 4,
        parentCode: '48799',
        hasChildren: false,
    },
    {
        code: '488',
        name: 'Support Activities for Transportation',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4881',
        name: 'Support Activities for Air Transportation',
        level: 2,
        parentCode: '488',
        hasChildren: true,
    },
    {
        code: '48811',
        name: 'Airport Operations',
        level: 3,
        parentCode: '4881',
        hasChildren: true,
    },
    {
        code: '488111',
        name: 'Air Traffic Control',
        level: 4,
        parentCode: '48811',
        hasChildren: false,
    },
    {
        code: '488119',
        name: 'Other Airport Operations',
        level: 4,
        parentCode: '48811',
        hasChildren: false,
    },
    {
        code: '48819',
        name: 'Other Support Activities for Air Transportation',
        level: 3,
        parentCode: '4881',
        hasChildren: true,
    },
    {
        code: '488190',
        name: 'Other Support Activities for Air Transportation',
        level: 4,
        parentCode: '48819',
        hasChildren: false,
    },
    {
        code: '4882',
        name: 'Support Activities for Rail Transportation',
        level: 2,
        parentCode: '488',
        hasChildren: true,
    },
    {
        code: '48821',
        name: 'Support Activities for Rail Transportation',
        level: 3,
        parentCode: '4882',
        hasChildren: true,
    },
    {
        code: '488210',
        name: 'Support Activities for Rail Transportation',
        level: 4,
        parentCode: '48821',
        hasChildren: false,
    },
    {
        code: '4883',
        name: 'Support Activities for Water Transportation',
        level: 2,
        parentCode: '488',
        hasChildren: true,
    },
    {
        code: '48831',
        name: 'Port and Harbor Operations',
        level: 3,
        parentCode: '4883',
        hasChildren: true,
    },
    {
        code: '488310',
        name: 'Port and Harbor Operations',
        level: 4,
        parentCode: '48831',
        hasChildren: false,
    },
    {
        code: '48832',
        name: 'Marine Cargo Handling',
        level: 3,
        parentCode: '4883',
        hasChildren: true,
    },
    {
        code: '488320',
        name: 'Marine Cargo Handling',
        level: 4,
        parentCode: '48832',
        hasChildren: false,
    },
    {
        code: '48833',
        name: 'Navigational Services to Shipping',
        level: 3,
        parentCode: '4883',
        hasChildren: true,
    },
    {
        code: '488330',
        name: 'Navigational Services to Shipping',
        level: 4,
        parentCode: '48833',
        hasChildren: false,
    },
    {
        code: '48839',
        name: 'Other Support Activities for Water Transportation',
        level: 3,
        parentCode: '4883',
        hasChildren: true,
    },
    {
        code: '488390',
        name: 'Other Support Activities for Water Transportation',
        level: 4,
        parentCode: '48839',
        hasChildren: false,
    },
    {
        code: '4884',
        name: 'Support Activities for Road Transportation',
        level: 2,
        parentCode: '488',
        hasChildren: true,
    },
    {
        code: '48841',
        name: 'Motor Vehicle Towing',
        level: 3,
        parentCode: '4884',
        hasChildren: true,
    },
    {
        code: '488410',
        name: 'Motor Vehicle Towing',
        level: 4,
        parentCode: '48841',
        hasChildren: false,
    },
    {
        code: '48849',
        name: 'Other Support Activities for Road Transportation',
        level: 3,
        parentCode: '4884',
        hasChildren: true,
    },
    {
        code: '488490',
        name: 'Other Support Activities for Road Transportation',
        level: 4,
        parentCode: '48849',
        hasChildren: false,
    },
    {
        code: '4885',
        name: 'Freight Transportation Arrangement',
        level: 2,
        parentCode: '488',
        hasChildren: true,
    },
    {
        code: '48851',
        name: 'Freight Transportation Arrangement',
        level: 3,
        parentCode: '4885',
        hasChildren: true,
    },
    {
        code: '488510',
        name: 'Freight Transportation Arrangement',
        level: 4,
        parentCode: '48851',
        hasChildren: false,
    },
    {
        code: '4889',
        name: 'Other Support Activities for Transportation',
        level: 2,
        parentCode: '488',
        hasChildren: true,
    },
    {
        code: '48899',
        name: 'Other Support Activities for Transportation',
        level: 3,
        parentCode: '4889',
        hasChildren: true,
    },
    {
        code: '488991',
        name: 'Packing and Crating',
        level: 4,
        parentCode: '48899',
        hasChildren: false,
    },
    {
        code: '488999',
        name: 'All Other Support Activities for Transportation',
        level: 4,
        parentCode: '48899',
        hasChildren: false,
    },
    {
        code: '491',
        name: 'Postal Service',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4911',
        name: 'Postal Service',
        level: 2,
        parentCode: '491',
        hasChildren: true,
    },
    {
        code: '49111',
        name: 'Postal Service',
        level: 3,
        parentCode: '4911',
        hasChildren: true,
    },
    {
        code: '491110',
        name: 'Postal Service',
        level: 4,
        parentCode: '49111',
        hasChildren: false,
    },
    {
        code: '492',
        name: 'Couriers and Messengers',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4921',
        name: 'Couriers and Express Delivery Services',
        level: 2,
        parentCode: '492',
        hasChildren: true,
    },
    {
        code: '49211',
        name: 'Couriers and Express Delivery Services',
        level: 3,
        parentCode: '4921',
        hasChildren: true,
    },
    {
        code: '492110',
        name: 'Couriers and Express Delivery Services',
        level: 4,
        parentCode: '49211',
        hasChildren: false,
    },
    {
        code: '4922',
        name: 'Local Messengers and Local Delivery',
        level: 2,
        parentCode: '492',
        hasChildren: true,
    },
    {
        code: '49221',
        name: 'Local Messengers and Local Delivery',
        level: 3,
        parentCode: '4922',
        hasChildren: true,
    },
    {
        code: '492210',
        name: 'Local Messengers and Local Delivery',
        level: 4,
        parentCode: '49221',
        hasChildren: false,
    },
    {
        code: '493',
        name: 'Warehousing and Storage',
        level: 1,
        parentCode: '48-49',
        hasChildren: true,
    },
    {
        code: '4931',
        name: 'Warehousing and Storage',
        level: 2,
        parentCode: '493',
        hasChildren: true,
    },
    {
        code: '49311',
        name: 'General Warehousing and Storage',
        level: 3,
        parentCode: '4931',
        hasChildren: true,
    },
    {
        code: '493110',
        name: 'General Warehousing and Storage',
        level: 4,
        parentCode: '49311',
        hasChildren: false,
    },
    {
        code: '49312',
        name: 'Refrigerated Warehousing and Storage',
        level: 3,
        parentCode: '4931',
        hasChildren: true,
    },
    {
        code: '493120',
        name: 'Refrigerated Warehousing and Storage',
        level: 4,
        parentCode: '49312',
        hasChildren: false,
    },
    {
        code: '49313',
        name: 'Farm Product Warehousing and Storage',
        level: 3,
        parentCode: '4931',
        hasChildren: true,
    },
    {
        code: '493130',
        name: 'Farm Product Warehousing and Storage',
        level: 4,
        parentCode: '49313',
        hasChildren: false,
    },
    {
        code: '49319',
        name: 'Other Warehousing and Storage',
        level: 3,
        parentCode: '4931',
        hasChildren: true,
    },
    {
        code: '493190',
        name: 'Other Warehousing and Storage',
        level: 4,
        parentCode: '49319',
        hasChildren: false,
    },
    {
        code: '51',
        name: 'Information',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '511',
        name: 'Publishing Industries (except Internet)',
        level: 1,
        parentCode: '51',
        hasChildren: true,
    },
    {
        code: '5111',
        name: 'Newspaper, Periodical, Book, and Directory Publishers',
        level: 2,
        parentCode: '511',
        hasChildren: true,
    },
    {
        code: '51111',
        name: 'Newspaper Publishers',
        level: 3,
        parentCode: '5111',
        hasChildren: true,
    },
    {
        code: '511110',
        name: 'Newspaper Publishers',
        level: 4,
        parentCode: '51111',
        hasChildren: false,
    },
    {
        code: '51112',
        name: 'Periodical Publishers',
        level: 3,
        parentCode: '5111',
        hasChildren: true,
    },
    {
        code: '511120',
        name: 'Periodical Publishers',
        level: 4,
        parentCode: '51112',
        hasChildren: false,
    },
    {
        code: '51113',
        name: 'Book Publishers',
        level: 3,
        parentCode: '5111',
        hasChildren: true,
    },
    {
        code: '511130',
        name: 'Book Publishers',
        level: 4,
        parentCode: '51113',
        hasChildren: false,
    },
    {
        code: '51114',
        name: 'Directory and Mailing List Publishers',
        level: 3,
        parentCode: '5111',
        hasChildren: true,
    },
    {
        code: '511140',
        name: 'Directory and Mailing List Publishers',
        level: 4,
        parentCode: '51114',
        hasChildren: false,
    },
    {
        code: '51119',
        name: 'Other Publishers',
        level: 3,
        parentCode: '5111',
        hasChildren: true,
    },
    {
        code: '511191',
        name: 'Greeting Card Publishers',
        level: 4,
        parentCode: '51119',
        hasChildren: false,
    },
    {
        code: '511199',
        name: 'All Other Publishers',
        level: 4,
        parentCode: '51119',
        hasChildren: false,
    },
    {
        code: '5112',
        name: 'Software Publishers',
        level: 2,
        parentCode: '511',
        hasChildren: true,
    },
    {
        code: '51121',
        name: 'Software Publishers',
        level: 3,
        parentCode: '5112',
        hasChildren: true,
    },
    {
        code: '511210',
        name: 'Software Publishers',
        level: 4,
        parentCode: '51121',
        hasChildren: false,
    },
    {
        code: '512',
        name: 'Motion Picture and Sound Recording Industries',
        level: 1,
        parentCode: '51',
        hasChildren: true,
    },
    {
        code: '5121',
        name: 'Motion Picture and Video Industries',
        level: 2,
        parentCode: '512',
        hasChildren: true,
    },
    {
        code: '51211',
        name: 'Motion Picture and Video Production',
        level: 3,
        parentCode: '5121',
        hasChildren: true,
    },
    {
        code: '512110',
        name: 'Motion Picture and Video Production',
        level: 4,
        parentCode: '51211',
        hasChildren: false,
    },
    {
        code: '51212',
        name: 'Motion Picture and Video Distribution',
        level: 3,
        parentCode: '5121',
        hasChildren: true,
    },
    {
        code: '512120',
        name: 'Motion Picture and Video Distribution',
        level: 4,
        parentCode: '51212',
        hasChildren: false,
    },
    {
        code: '51213',
        name: 'Motion Picture and Video Exhibition',
        level: 3,
        parentCode: '5121',
        hasChildren: true,
    },
    {
        code: '512131',
        name: 'Motion Picture Theaters (except Drive-Ins)',
        level: 4,
        parentCode: '51213',
        hasChildren: false,
    },
    {
        code: '512132',
        name: 'Drive-In Motion Picture Theaters',
        level: 4,
        parentCode: '51213',
        hasChildren: false,
    },
    {
        code: '51219',
        name: 'Postproduction Services and Other Motion Picture and Video Industries',
        level: 3,
        parentCode: '5121',
        hasChildren: true,
    },
    {
        code: '512191',
        name: 'Teleproduction and Other Postproduction Services',
        level: 4,
        parentCode: '51219',
        hasChildren: false,
    },
    {
        code: '512199',
        name: 'Other Motion Picture and Video Industries',
        level: 4,
        parentCode: '51219',
        hasChildren: false,
    },
    {
        code: '5122',
        name: 'Sound Recording Industries',
        level: 2,
        parentCode: '512',
        hasChildren: true,
    },
    {
        code: '51223',
        name: 'Music Publishers',
        level: 3,
        parentCode: '5122',
        hasChildren: true,
    },
    {
        code: '512230',
        name: 'Music Publishers',
        level: 4,
        parentCode: '51223',
        hasChildren: false,
    },
    {
        code: '51224',
        name: 'Sound Recording Studios',
        level: 3,
        parentCode: '5122',
        hasChildren: true,
    },
    {
        code: '512240',
        name: 'Sound Recording Studios',
        level: 4,
        parentCode: '51224',
        hasChildren: false,
    },
    {
        code: '51225',
        name: 'Record Production and Distribution',
        level: 3,
        parentCode: '5122',
        hasChildren: true,
    },
    {
        code: '512250',
        name: 'Record Production and Distribution',
        level: 4,
        parentCode: '51225',
        hasChildren: false,
    },
    {
        code: '51229',
        name: 'Other Sound Recording Industries',
        level: 3,
        parentCode: '5122',
        hasChildren: true,
    },
    {
        code: '512290',
        name: 'Other Sound Recording Industries',
        level: 4,
        parentCode: '51229',
        hasChildren: false,
    },
    {
        code: '515',
        name: 'Broadcasting (except Internet)',
        level: 1,
        parentCode: '51',
        hasChildren: true,
    },
    {
        code: '5151',
        name: 'Radio and Television Broadcasting',
        level: 2,
        parentCode: '515',
        hasChildren: true,
    },
    {
        code: '51511',
        name: 'Radio Broadcasting',
        level: 3,
        parentCode: '5151',
        hasChildren: true,
    },
    {
        code: '515111',
        name: 'Radio Networks',
        level: 4,
        parentCode: '51511',
        hasChildren: false,
    },
    {
        code: '515112',
        name: 'Radio Stations',
        level: 4,
        parentCode: '51511',
        hasChildren: false,
    },
    {
        code: '51512',
        name: 'Television Broadcasting',
        level: 3,
        parentCode: '5151',
        hasChildren: true,
    },
    {
        code: '515120',
        name: 'Television Broadcasting',
        level: 4,
        parentCode: '51512',
        hasChildren: false,
    },
    {
        code: '5152',
        name: 'Cable and Other Subscription Programming',
        level: 2,
        parentCode: '515',
        hasChildren: true,
    },
    {
        code: '51521',
        name: 'Cable and Other Subscription Programming',
        level: 3,
        parentCode: '5152',
        hasChildren: true,
    },
    {
        code: '515210',
        name: 'Cable and Other Subscription Programming',
        level: 4,
        parentCode: '51521',
        hasChildren: false,
    },
    {
        code: '517',
        name: 'Telecommunications',
        level: 1,
        parentCode: '51',
        hasChildren: true,
    },
    {
        code: '5173',
        name: 'Wired and Wireless Telecommunications Carriers',
        level: 2,
        parentCode: '517',
        hasChildren: true,
    },
    {
        code: '51731',
        name: 'Wired and Wireless Telecommunications Carriers',
        level: 3,
        parentCode: '5173',
        hasChildren: true,
    },
    {
        code: '517311',
        name: 'Wired Telecommunications Carriers',
        level: 4,
        parentCode: '51731',
        hasChildren: false,
    },
    {
        code: '517312',
        name: 'Wireless Telecommunications Carriers (except Satellite)',
        level: 4,
        parentCode: '51731',
        hasChildren: false,
    },
    {
        code: '5174',
        name: 'Satellite Telecommunications',
        level: 2,
        parentCode: '517',
        hasChildren: true,
    },
    {
        code: '51741',
        name: 'Satellite Telecommunications',
        level: 3,
        parentCode: '5174',
        hasChildren: true,
    },
    {
        code: '517410',
        name: 'Satellite Telecommunications',
        level: 4,
        parentCode: '51741',
        hasChildren: false,
    },
    {
        code: '5179',
        name: 'Other Telecommunications',
        level: 2,
        parentCode: '517',
        hasChildren: true,
    },
    {
        code: '51791',
        name: 'Other Telecommunications',
        level: 3,
        parentCode: '5179',
        hasChildren: true,
    },
    {
        code: '517911',
        name: 'Telecommunications Resellers',
        level: 4,
        parentCode: '51791',
        hasChildren: false,
    },
    {
        code: '517919',
        name: 'All Other Telecommunications',
        level: 4,
        parentCode: '51791',
        hasChildren: false,
    },
    {
        code: '518',
        name: 'Data Processing, Hosting, and Related Services',
        level: 1,
        parentCode: '51',
        hasChildren: true,
    },
    {
        code: '5182',
        name: 'Data Processing, Hosting, and Related Services',
        level: 2,
        parentCode: '518',
        hasChildren: true,
    },
    {
        code: '51821',
        name: 'Data Processing, Hosting, and Related Services',
        level: 3,
        parentCode: '5182',
        hasChildren: true,
    },
    {
        code: '518210',
        name: 'Data Processing, Hosting, and Related Services',
        level: 4,
        parentCode: '51821',
        hasChildren: false,
    },
    {
        code: '519',
        name: 'Other Information Services',
        level: 1,
        parentCode: '51',
        hasChildren: true,
    },
    {
        code: '5191',
        name: 'Other Information Services',
        level: 2,
        parentCode: '519',
        hasChildren: true,
    },
    {
        code: '51911',
        name: 'News Syndicates',
        level: 3,
        parentCode: '5191',
        hasChildren: true,
    },
    {
        code: '519110',
        name: 'News Syndicates',
        level: 4,
        parentCode: '51911',
        hasChildren: false,
    },
    {
        code: '51912',
        name: 'Libraries and Archives',
        level: 3,
        parentCode: '5191',
        hasChildren: true,
    },
    {
        code: '519120',
        name: 'Libraries and Archives',
        level: 4,
        parentCode: '51912',
        hasChildren: false,
    },
    {
        code: '51913',
        name: 'Internet Publishing and Broadcasting and Web Search Portals',
        level: 3,
        parentCode: '5191',
        hasChildren: true,
    },
    {
        code: '519130',
        name: 'Internet Publishing and Broadcasting and Web Search Portals',
        level: 4,
        parentCode: '51913',
        hasChildren: false,
    },
    {
        code: '51919',
        name: 'All Other Information Services',
        level: 3,
        parentCode: '5191',
        hasChildren: true,
    },
    {
        code: '519190',
        name: 'All Other Information Services',
        level: 4,
        parentCode: '51919',
        hasChildren: false,
    },
    {
        code: '52',
        name: 'Finance and Insurance',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '521',
        name: 'Monetary Authorities-Central Bank',
        level: 1,
        parentCode: '52',
        hasChildren: true,
    },
    {
        code: '5211',
        name: 'Monetary Authorities-Central Bank',
        level: 2,
        parentCode: '521',
        hasChildren: true,
    },
    {
        code: '52111',
        name: 'Monetary Authorities-Central Bank',
        level: 3,
        parentCode: '5211',
        hasChildren: true,
    },
    {
        code: '521110',
        name: 'Monetary Authorities-Central Bank',
        level: 4,
        parentCode: '52111',
        hasChildren: false,
    },
    {
        code: '522',
        name: 'Credit Intermediation and Related Activities',
        level: 1,
        parentCode: '52',
        hasChildren: true,
    },
    {
        code: '5221',
        name: 'Depository Credit Intermediation',
        level: 2,
        parentCode: '522',
        hasChildren: true,
    },
    {
        code: '52211',
        name: 'Commercial Banking',
        level: 3,
        parentCode: '5221',
        hasChildren: true,
    },
    {
        code: '522110',
        name: 'Commercial Banking',
        level: 4,
        parentCode: '52211',
        hasChildren: false,
    },
    {
        code: '52212',
        name: 'Savings Institutions',
        level: 3,
        parentCode: '5221',
        hasChildren: true,
    },
    {
        code: '522120',
        name: 'Savings Institutions',
        level: 4,
        parentCode: '52212',
        hasChildren: false,
    },
    {
        code: '52213',
        name: 'Credit Unions',
        level: 3,
        parentCode: '5221',
        hasChildren: true,
    },
    {
        code: '522130',
        name: 'Credit Unions',
        level: 4,
        parentCode: '52213',
        hasChildren: false,
    },
    {
        code: '52219',
        name: 'Other Depository Credit Intermediation',
        level: 3,
        parentCode: '5221',
        hasChildren: true,
    },
    {
        code: '522190',
        name: 'Other Depository Credit Intermediation',
        level: 4,
        parentCode: '52219',
        hasChildren: false,
    },
    {
        code: '5222',
        name: 'Nondepository Credit Intermediation',
        level: 2,
        parentCode: '522',
        hasChildren: true,
    },
    {
        code: '52221',
        name: 'Credit Card Issuing',
        level: 3,
        parentCode: '5222',
        hasChildren: true,
    },
    {
        code: '522210',
        name: 'Credit Card Issuing',
        level: 4,
        parentCode: '52221',
        hasChildren: false,
    },
    {
        code: '52222',
        name: 'Sales Financing',
        level: 3,
        parentCode: '5222',
        hasChildren: true,
    },
    {
        code: '522220',
        name: 'Sales Financing',
        level: 4,
        parentCode: '52222',
        hasChildren: false,
    },
    {
        code: '52229',
        name: 'Other Nondepository Credit Intermediation',
        level: 3,
        parentCode: '5222',
        hasChildren: true,
    },
    {
        code: '522291',
        name: 'Consumer Lending',
        level: 4,
        parentCode: '52229',
        hasChildren: false,
    },
    {
        code: '522292',
        name: 'Real Estate Credit',
        level: 4,
        parentCode: '52229',
        hasChildren: false,
    },
    {
        code: '522293',
        name: 'International Trade Financing',
        level: 4,
        parentCode: '52229',
        hasChildren: false,
    },
    {
        code: '522294',
        name: 'Secondary Market Financing',
        level: 4,
        parentCode: '52229',
        hasChildren: false,
    },
    {
        code: '522298',
        name: 'All Other Nondepository Credit Intermediation',
        level: 4,
        parentCode: '52229',
        hasChildren: false,
    },
    {
        code: '5223',
        name: 'Activities Related to Credit Intermediation',
        level: 2,
        parentCode: '522',
        hasChildren: true,
    },
    {
        code: '52231',
        name: 'Mortgage and Nonmortgage Loan Brokers',
        level: 3,
        parentCode: '5223',
        hasChildren: true,
    },
    {
        code: '522310',
        name: 'Mortgage and Nonmortgage Loan Brokers',
        level: 4,
        parentCode: '52231',
        hasChildren: false,
    },
    {
        code: '52232',
        name: 'Financial Transactions Processing, Reserve, and Clearinghouse Activities',
        level: 3,
        parentCode: '5223',
        hasChildren: true,
    },
    {
        code: '522320',
        name: 'Financial Transactions Processing, Reserve, and Clearinghouse Activities',
        level: 4,
        parentCode: '52232',
        hasChildren: false,
    },
    {
        code: '52239',
        name: 'Other Activities Related to Credit Intermediation',
        level: 3,
        parentCode: '5223',
        hasChildren: true,
    },
    {
        code: '522390',
        name: 'Other Activities Related to Credit Intermediation',
        level: 4,
        parentCode: '52239',
        hasChildren: false,
    },
    {
        code: '523',
        name: 'Securities, Commodity Contracts, and Other Financial Investments and Related Activities',
        level: 1,
        parentCode: '52',
        hasChildren: true,
    },
    {
        code: '5231',
        name: 'Securities and Commodity Contracts Intermediation and Brokerage',
        level: 2,
        parentCode: '523',
        hasChildren: true,
    },
    {
        code: '52311',
        name: 'Investment Banking and Securities Dealing',
        level: 3,
        parentCode: '5231',
        hasChildren: true,
    },
    {
        code: '523110',
        name: 'Investment Banking and Securities Dealing',
        level: 4,
        parentCode: '52311',
        hasChildren: false,
    },
    {
        code: '52312',
        name: 'Securities Brokerage',
        level: 3,
        parentCode: '5231',
        hasChildren: true,
    },
    {
        code: '523120',
        name: 'Securities Brokerage',
        level: 4,
        parentCode: '52312',
        hasChildren: false,
    },
    {
        code: '52313',
        name: 'Commodity Contracts Dealing',
        level: 3,
        parentCode: '5231',
        hasChildren: true,
    },
    {
        code: '523130',
        name: 'Commodity Contracts Dealing',
        level: 4,
        parentCode: '52313',
        hasChildren: false,
    },
    {
        code: '52314',
        name: 'Commodity Contracts Brokerage',
        level: 3,
        parentCode: '5231',
        hasChildren: true,
    },
    {
        code: '523140',
        name: 'Commodity Contracts Brokerage',
        level: 4,
        parentCode: '52314',
        hasChildren: false,
    },
    {
        code: '5232',
        name: 'Securities and Commodity Exchanges',
        level: 2,
        parentCode: '523',
        hasChildren: true,
    },
    {
        code: '52321',
        name: 'Securities and Commodity Exchanges',
        level: 3,
        parentCode: '5232',
        hasChildren: true,
    },
    {
        code: '523210',
        name: 'Securities and Commodity Exchanges',
        level: 4,
        parentCode: '52321',
        hasChildren: false,
    },
    {
        code: '5239',
        name: 'Other Financial Investment Activities',
        level: 2,
        parentCode: '523',
        hasChildren: true,
    },
    {
        code: '52391',
        name: 'Miscellaneous Intermediation',
        level: 3,
        parentCode: '5239',
        hasChildren: true,
    },
    {
        code: '523910',
        name: 'Miscellaneous Intermediation',
        level: 4,
        parentCode: '52391',
        hasChildren: false,
    },
    {
        code: '52392',
        name: 'Portfolio Management',
        level: 3,
        parentCode: '5239',
        hasChildren: true,
    },
    {
        code: '523920',
        name: 'Portfolio Management',
        level: 4,
        parentCode: '52392',
        hasChildren: false,
    },
    {
        code: '52393',
        name: 'Investment Advice',
        level: 3,
        parentCode: '5239',
        hasChildren: true,
    },
    {
        code: '523930',
        name: 'Investment Advice',
        level: 4,
        parentCode: '52393',
        hasChildren: false,
    },
    {
        code: '52399',
        name: 'All Other Financial Investment Activities',
        level: 3,
        parentCode: '5239',
        hasChildren: true,
    },
    {
        code: '523991',
        name: 'Trust, Fiduciary, and Custody Activities',
        level: 4,
        parentCode: '52399',
        hasChildren: false,
    },
    {
        code: '523999',
        name: 'Miscellaneous Financial Investment Activities',
        level: 4,
        parentCode: '52399',
        hasChildren: false,
    },
    {
        code: '524',
        name: 'Insurance Carriers and Related Activities',
        level: 1,
        parentCode: '52',
        hasChildren: true,
    },
    {
        code: '5241',
        name: 'Insurance Carriers',
        level: 2,
        parentCode: '524',
        hasChildren: true,
    },
    {
        code: '52411',
        name: 'Direct Life, Health, and Medical Insurance Carriers',
        level: 3,
        parentCode: '5241',
        hasChildren: true,
    },
    {
        code: '524113',
        name: 'Direct Life Insurance Carriers',
        level: 4,
        parentCode: '52411',
        hasChildren: false,
    },
    {
        code: '524114',
        name: 'Direct Health and Medical Insurance Carriers',
        level: 4,
        parentCode: '52411',
        hasChildren: false,
    },
    {
        code: '52412',
        name: 'Direct Insurance (except Life, Health, and Medical) Carriers',
        level: 3,
        parentCode: '5241',
        hasChildren: true,
    },
    {
        code: '524126',
        name: 'Direct Property and Casualty Insurance Carriers',
        level: 4,
        parentCode: '52412',
        hasChildren: false,
    },
    {
        code: '524127',
        name: 'Direct Title Insurance Carriers',
        level: 4,
        parentCode: '52412',
        hasChildren: false,
    },
    {
        code: '524128',
        name: 'Other Direct Insurance (except Life, Health, and Medical) Carriers',
        level: 4,
        parentCode: '52412',
        hasChildren: false,
    },
    {
        code: '52413',
        name: 'Reinsurance Carriers',
        level: 3,
        parentCode: '5241',
        hasChildren: true,
    },
    {
        code: '524130',
        name: 'Reinsurance Carriers',
        level: 4,
        parentCode: '52413',
        hasChildren: false,
    },
    {
        code: '5242',
        name: 'Agencies, Brokerages, and Other Insurance Related Activities',
        level: 2,
        parentCode: '524',
        hasChildren: true,
    },
    {
        code: '52421',
        name: 'Insurance Agencies and Brokerages',
        level: 3,
        parentCode: '5242',
        hasChildren: true,
    },
    {
        code: '524210',
        name: 'Insurance Agencies and Brokerages',
        level: 4,
        parentCode: '52421',
        hasChildren: false,
    },
    {
        code: '52429',
        name: 'Other Insurance Related Activities',
        level: 3,
        parentCode: '5242',
        hasChildren: true,
    },
    {
        code: '524291',
        name: 'Claims Adjusting',
        level: 4,
        parentCode: '52429',
        hasChildren: false,
    },
    {
        code: '524292',
        name: 'Third Party Administration of Insurance and Pension Funds',
        level: 4,
        parentCode: '52429',
        hasChildren: false,
    },
    {
        code: '524298',
        name: 'All Other Insurance Related Activities',
        level: 4,
        parentCode: '52429',
        hasChildren: false,
    },
    {
        code: '525',
        name: 'Funds, Trusts, and Other Financial Vehicles',
        level: 1,
        parentCode: '52',
        hasChildren: true,
    },
    {
        code: '5251',
        name: 'Insurance and Employee Benefit Funds',
        level: 2,
        parentCode: '525',
        hasChildren: true,
    },
    {
        code: '52511',
        name: 'Pension Funds',
        level: 3,
        parentCode: '5251',
        hasChildren: true,
    },
    {
        code: '525110',
        name: 'Pension Funds',
        level: 4,
        parentCode: '52511',
        hasChildren: false,
    },
    {
        code: '52512',
        name: 'Health and Welfare Funds',
        level: 3,
        parentCode: '5251',
        hasChildren: true,
    },
    {
        code: '525120',
        name: 'Health and Welfare Funds',
        level: 4,
        parentCode: '52512',
        hasChildren: false,
    },
    {
        code: '52519',
        name: 'Other Insurance Funds',
        level: 3,
        parentCode: '5251',
        hasChildren: true,
    },
    {
        code: '525190',
        name: 'Other Insurance Funds',
        level: 4,
        parentCode: '52519',
        hasChildren: false,
    },
    {
        code: '5259',
        name: 'Other Investment Pools and Funds',
        level: 2,
        parentCode: '525',
        hasChildren: true,
    },
    {
        code: '52591',
        name: 'Open-End Investment Funds',
        level: 3,
        parentCode: '5259',
        hasChildren: true,
    },
    {
        code: '525910',
        name: 'Open-End Investment Funds',
        level: 4,
        parentCode: '52591',
        hasChildren: false,
    },
    {
        code: '52592',
        name: 'Trusts, Estates, and Agency Accounts',
        level: 3,
        parentCode: '5259',
        hasChildren: true,
    },
    {
        code: '525920',
        name: 'Trusts, Estates, and Agency Accounts',
        level: 4,
        parentCode: '52592',
        hasChildren: false,
    },
    {
        code: '52599',
        name: 'Other Financial Vehicles',
        level: 3,
        parentCode: '5259',
        hasChildren: true,
    },
    {
        code: '525990',
        name: 'Other Financial Vehicles',
        level: 4,
        parentCode: '52599',
        hasChildren: false,
    },
    {
        code: '53',
        name: 'Real Estate and Rental and Leasing',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '531',
        name: 'Real Estate',
        level: 1,
        parentCode: '53',
        hasChildren: true,
    },
    {
        code: '5311',
        name: 'Lessors of Real Estate',
        level: 2,
        parentCode: '531',
        hasChildren: true,
    },
    {
        code: '53111',
        name: 'Lessors of Residential Buildings and Dwellings',
        level: 3,
        parentCode: '5311',
        hasChildren: true,
    },
    {
        code: '531110',
        name: 'Lessors of Residential Buildings and Dwellings',
        level: 4,
        parentCode: '53111',
        hasChildren: false,
    },
    {
        code: '53112',
        name: 'Lessors of Nonresidential Buildings (except Miniwarehouses)',
        level: 3,
        parentCode: '5311',
        hasChildren: true,
    },
    {
        code: '531120',
        name: 'Lessors of Nonresidential Buildings (except Miniwarehouses)',
        level: 4,
        parentCode: '53112',
        hasChildren: false,
    },
    {
        code: '53113',
        name: 'Lessors of Miniwarehouses and Self-Storage Units',
        level: 3,
        parentCode: '5311',
        hasChildren: true,
    },
    {
        code: '531130',
        name: 'Lessors of Miniwarehouses and Self-Storage Units',
        level: 4,
        parentCode: '53113',
        hasChildren: false,
    },
    {
        code: '53119',
        name: 'Lessors of Other Real Estate Property',
        level: 3,
        parentCode: '5311',
        hasChildren: true,
    },
    {
        code: '531190',
        name: 'Lessors of Other Real Estate Property',
        level: 4,
        parentCode: '53119',
        hasChildren: false,
    },
    {
        code: '5312',
        name: 'Offices of Real Estate Agents and Brokers',
        level: 2,
        parentCode: '531',
        hasChildren: true,
    },
    {
        code: '53121',
        name: 'Offices of Real Estate Agents and Brokers',
        level: 3,
        parentCode: '5312',
        hasChildren: true,
    },
    {
        code: '531210',
        name: 'Offices of Real Estate Agents and Brokers',
        level: 4,
        parentCode: '53121',
        hasChildren: false,
    },
    {
        code: '5313',
        name: 'Activities Related to Real Estate',
        level: 2,
        parentCode: '531',
        hasChildren: true,
    },
    {
        code: '53131',
        name: 'Real Estate Property Managers',
        level: 3,
        parentCode: '5313',
        hasChildren: true,
    },
    {
        code: '531311',
        name: 'Residential Property Managers',
        level: 4,
        parentCode: '53131',
        hasChildren: false,
    },
    {
        code: '531312',
        name: 'Nonresidential Property Managers',
        level: 4,
        parentCode: '53131',
        hasChildren: false,
    },
    {
        code: '53132',
        name: 'Offices of Real Estate Appraisers',
        level: 3,
        parentCode: '5313',
        hasChildren: true,
    },
    {
        code: '531320',
        name: 'Offices of Real Estate Appraisers',
        level: 4,
        parentCode: '53132',
        hasChildren: false,
    },
    {
        code: '53139',
        name: 'Other Activities Related to Real Estate',
        level: 3,
        parentCode: '5313',
        hasChildren: true,
    },
    {
        code: '531390',
        name: 'Other Activities Related to Real Estate',
        level: 4,
        parentCode: '53139',
        hasChildren: false,
    },
    {
        code: '532',
        name: 'Rental and Leasing Services',
        level: 1,
        parentCode: '53',
        hasChildren: true,
    },
    {
        code: '5321',
        name: 'Automotive Equipment Rental and Leasing',
        level: 2,
        parentCode: '532',
        hasChildren: true,
    },
    {
        code: '53211',
        name: 'Passenger Car Rental and Leasing',
        level: 3,
        parentCode: '5321',
        hasChildren: true,
    },
    {
        code: '532111',
        name: 'Passenger Car Rental',
        level: 4,
        parentCode: '53211',
        hasChildren: false,
    },
    {
        code: '532112',
        name: 'Passenger Car Leasing',
        level: 4,
        parentCode: '53211',
        hasChildren: false,
    },
    {
        code: '53212',
        name: 'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
        level: 3,
        parentCode: '5321',
        hasChildren: true,
    },
    {
        code: '532120',
        name: 'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing',
        level: 4,
        parentCode: '53212',
        hasChildren: false,
    },
    {
        code: '5322',
        name: 'Consumer Goods Rental',
        level: 2,
        parentCode: '532',
        hasChildren: true,
    },
    {
        code: '53221',
        name: 'Consumer Electronics and Appliances Rental',
        level: 3,
        parentCode: '5322',
        hasChildren: true,
    },
    {
        code: '532210',
        name: 'Consumer Electronics and Appliances Rental',
        level: 4,
        parentCode: '53221',
        hasChildren: false,
    },
    {
        code: '53228',
        name: 'Other Consumer Goods Rental',
        level: 3,
        parentCode: '5322',
        hasChildren: true,
    },
    {
        code: '532281',
        name: 'Formal Wear and Costume Rental',
        level: 4,
        parentCode: '53228',
        hasChildren: false,
    },
    {
        code: '532282',
        name: 'Video Tape and Disc Rental',
        level: 4,
        parentCode: '53228',
        hasChildren: false,
    },
    {
        code: '532283',
        name: 'Home Health Equipment Rental',
        level: 4,
        parentCode: '53228',
        hasChildren: false,
    },
    {
        code: '532284',
        name: 'Recreational Goods Rental',
        level: 4,
        parentCode: '53228',
        hasChildren: false,
    },
    {
        code: '532289',
        name: 'All Other Consumer Goods Rental',
        level: 4,
        parentCode: '53228',
        hasChildren: false,
    },
    {
        code: '5323',
        name: 'General Rental Centers',
        level: 2,
        parentCode: '532',
        hasChildren: true,
    },
    {
        code: '53231',
        name: 'General Rental Centers',
        level: 3,
        parentCode: '5323',
        hasChildren: true,
    },
    {
        code: '532310',
        name: 'General Rental Centers',
        level: 4,
        parentCode: '53231',
        hasChildren: false,
    },
    {
        code: '5324',
        name: 'Commercial and Industrial Machinery and Equipment Rental and Leasing',
        level: 2,
        parentCode: '532',
        hasChildren: true,
    },
    {
        code: '53241',
        name: 'Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing',
        level: 3,
        parentCode: '5324',
        hasChildren: true,
    },
    {
        code: '532411',
        name: 'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing',
        level: 4,
        parentCode: '53241',
        hasChildren: false,
    },
    {
        code: '532412',
        name: 'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing',
        level: 4,
        parentCode: '53241',
        hasChildren: false,
    },
    {
        code: '53242',
        name: 'Office Machinery and Equipment Rental and Leasing',
        level: 3,
        parentCode: '5324',
        hasChildren: true,
    },
    {
        code: '532420',
        name: 'Office Machinery and Equipment Rental and Leasing',
        level: 4,
        parentCode: '53242',
        hasChildren: false,
    },
    {
        code: '53249',
        name: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
        level: 3,
        parentCode: '5324',
        hasChildren: true,
    },
    {
        code: '532490',
        name: 'Other Commercial and Industrial Machinery and Equipment Rental and Leasing',
        level: 4,
        parentCode: '53249',
        hasChildren: false,
    },
    {
        code: '533',
        name: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
        level: 1,
        parentCode: '53',
        hasChildren: true,
    },
    {
        code: '5331',
        name: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
        level: 2,
        parentCode: '533',
        hasChildren: true,
    },
    {
        code: '53311',
        name: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
        level: 3,
        parentCode: '5331',
        hasChildren: true,
    },
    {
        code: '533110',
        name: 'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
        level: 4,
        parentCode: '53311',
        hasChildren: false,
    },
    {
        code: '54',
        name: 'Professional, Scientific, and Technical Services',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '541',
        name: 'Professional, Scientific, and Technical Services',
        level: 1,
        parentCode: '54',
        hasChildren: true,
    },
    {
        code: '5411',
        name: 'Legal Services',
        level: 2,
        parentCode: '541',
        hasChildren: true,
    },
    {
        code: '54111',
        name: 'Offices of Lawyers',
        level: 3,
        parentCode: '5411',
        hasChildren: true,
    },
    {
        code: '541110',
        name: 'Offices of Lawyers',
        level: 4,
        parentCode: '54111',
        hasChildren: false,
    },
    {
        code: '54112',
        name: 'Offices of Notaries',
        level: 3,
        parentCode: '5411',
        hasChildren: true,
    },
    {
        code: '541120',
        name: 'Offices of Notaries',
        level: 4,
        parentCode: '54112',
        hasChildren: false,
    },
    {
        code: '54119',
        name: 'Other Legal Services',
        level: 3,
        parentCode: '5411',
        hasChildren: true,
    },
    {
        code: '541191',
        name: 'Title Abstract and Settlement Offices',
        level: 4,
        parentCode: '54119',
        hasChildren: false,
    },
    {
        code: '541199',
        name: 'All Other Legal Services',
        level: 4,
        parentCode: '54119',
        hasChildren: false,
    },
    {
        code: '5412',
        name: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
        level: 2,
        parentCode: '541',
        hasChildren: true,
    },
    {
        code: '54121',
        name: 'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
        level: 3,
        parentCode: '5412',
        hasChildren: true,
    },
    {
        code: '541211',
        name: 'Offices of Certified Public Accountants',
        level: 4,
        parentCode: '54121',
        hasChildren: false,
    },
    {
        code: '541213',
        name: 'Tax Preparation Services',
        level: 4,
        parentCode: '54121',
        hasChildren: false,
    },
    {
        code: '541214',
        name: 'Payroll Services',
        level: 4,
        parentCode: '54121',
        hasChildren: false,
    },
    {
        code: '541219',
        name: 'Other Accounting Services',
        level: 4,
        parentCode: '54121',
        hasChildren: false,
    },
    {
        code: '5413',
        name: 'Architectural, Engineering, and Related Services',
        level: 2,
        parentCode: '541',
        hasChildren: true,
    },
    {
        code: '54131',
        name: 'Architectural Services',
        level: 3,
        parentCode: '5413',
        hasChildren: true,
    },
    {
        code: '541310',
        name: 'Architectural Services',
        level: 4,
        parentCode: '54131',
        hasChildren: false,
    },
    {
        code: '54132',
        name: 'Landscape Architectural Services',
        level: 3,
        parentCode: '5413',
        hasChildren: true,
    },
    {
        code: '541320',
        name: 'Landscape Architectural Services',
        level: 4,
        parentCode: '54132',
        hasChildren: false,
    },
    {
        code: '54133',
        name: 'Engineering Services',
        level: 3,
        parentCode: '5413',
        hasChildren: true,
    },
    {
        code: '541330',
        name: 'Engineering Services',
        level: 4,
        parentCode: '54133',
        hasChildren: false,
    },
    {
        code: '54134',
        name: 'Drafting Services',
        level: 3,
        parentCode: '5413',
        hasChildren: true,
    },
    {
        code: '541340',
        name: 'Drafting Services',
        level: 4,
        parentCode: '54134',
        hasChildren: false,
    },
    {
        code: '54135',
        name: 'Building Inspection Services',
        level: 3,
        parentCode: '5413',
        hasChildren: true,
    },
    {
        code: '541350',
        name: 'Building Inspection Services',
        level: 4,
        parentCode: '54135',
        hasChildren: false,
    },
    {
        code: '54136',
        name: 'Geophysical Surveying and Mapping Services',
        level: 3,
        parentCode: '5413',
        hasChildren: true,
    },
    {
        code: '541360',
        name: 'Geophysical Surveying and Mapping Services',
        level: 4,
        parentCode: '54136',
        hasChildren: false,
    },
    {
        code: '54137',
        name: 'Surveying and Mapping (except Geophysical) Services',
        level: 3,
        parentCode: '5413',
        hasChildren: true,
    },
    {
        code: '541370',
        name: 'Surveying and Mapping (except Geophysical) Services',
        level: 4,
        parentCode: '54137',
        hasChildren: false,
    },
    {
        code: '54138',
        name: 'Testing Laboratories',
        level: 3,
        parentCode: '5413',
        hasChildren: true,
    },
    {
        code: '541380',
        name: 'Testing Laboratories',
        level: 4,
        parentCode: '54138',
        hasChildren: false,
    },
    {
        code: '5414',
        name: 'Specialized Design Services',
        level: 2,
        parentCode: '541',
        hasChildren: true,
    },
    {
        code: '54141',
        name: 'Interior Design Services',
        level: 3,
        parentCode: '5414',
        hasChildren: true,
    },
    {
        code: '541410',
        name: 'Interior Design Services',
        level: 4,
        parentCode: '54141',
        hasChildren: false,
    },
    {
        code: '54142',
        name: 'Industrial Design Services',
        level: 3,
        parentCode: '5414',
        hasChildren: true,
    },
    {
        code: '541420',
        name: 'Industrial Design Services',
        level: 4,
        parentCode: '54142',
        hasChildren: false,
    },
    {
        code: '54143',
        name: 'Graphic Design Services',
        level: 3,
        parentCode: '5414',
        hasChildren: true,
    },
    {
        code: '541430',
        name: 'Graphic Design Services',
        level: 4,
        parentCode: '54143',
        hasChildren: false,
    },
    {
        code: '54149',
        name: 'Other Specialized Design Services',
        level: 3,
        parentCode: '5414',
        hasChildren: true,
    },
    {
        code: '541490',
        name: 'Other Specialized Design Services',
        level: 4,
        parentCode: '54149',
        hasChildren: false,
    },
    {
        code: '5415',
        name: 'Computer Systems Design and Related Services',
        level: 2,
        parentCode: '541',
        hasChildren: true,
    },
    {
        code: '54151',
        name: 'Computer Systems Design and Related Services',
        level: 3,
        parentCode: '5415',
        hasChildren: true,
    },
    {
        code: '541511',
        name: 'Custom Computer Programming Services',
        level: 4,
        parentCode: '54151',
        hasChildren: false,
    },
    {
        code: '541512',
        name: 'Computer Systems Design Services',
        level: 4,
        parentCode: '54151',
        hasChildren: false,
    },
    {
        code: '541513',
        name: 'Computer Facilities Management Services',
        level: 4,
        parentCode: '54151',
        hasChildren: false,
    },
    {
        code: '541519',
        name: 'Other Computer Related Services',
        level: 4,
        parentCode: '54151',
        hasChildren: false,
    },
    {
        code: '5416',
        name: 'Management, Scientific, and Technical Consulting Services',
        level: 2,
        parentCode: '541',
        hasChildren: true,
    },
    {
        code: '54161',
        name: 'Management Consulting Services',
        level: 3,
        parentCode: '5416',
        hasChildren: true,
    },
    {
        code: '541611',
        name: 'Administrative Management and General Management Consulting Services',
        level: 4,
        parentCode: '54161',
        hasChildren: false,
    },
    {
        code: '541612',
        name: 'Human Resources Consulting Services',
        level: 4,
        parentCode: '54161',
        hasChildren: false,
    },
    {
        code: '541613',
        name: 'Marketing Consulting Services',
        level: 4,
        parentCode: '54161',
        hasChildren: false,
    },
    {
        code: '541614',
        name: 'Process, Physical Distribution, and Logistics Consulting Services',
        level: 4,
        parentCode: '54161',
        hasChildren: false,
    },
    {
        code: '541618',
        name: 'Other Management Consulting Services',
        level: 4,
        parentCode: '54161',
        hasChildren: false,
    },
    {
        code: '54162',
        name: 'Environmental Consulting Services',
        level: 3,
        parentCode: '5416',
        hasChildren: true,
    },
    {
        code: '541620',
        name: 'Environmental Consulting Services',
        level: 4,
        parentCode: '54162',
        hasChildren: false,
    },
    {
        code: '54169',
        name: 'Other Scientific and Technical Consulting Services',
        level: 3,
        parentCode: '5416',
        hasChildren: true,
    },
    {
        code: '541690',
        name: 'Other Scientific and Technical Consulting Services',
        level: 4,
        parentCode: '54169',
        hasChildren: false,
    },
    {
        code: '5417',
        name: 'Scientific Research and Development Services',
        level: 2,
        parentCode: '541',
        hasChildren: true,
    },
    {
        code: '54171',
        name: 'Research and Development in the Physical, Engineering, and Life Sciences',
        level: 3,
        parentCode: '5417',
        hasChildren: true,
    },
    {
        code: '541713',
        name: 'Research and Development in Nanotechnology',
        level: 4,
        parentCode: '54171',
        hasChildren: false,
    },
    {
        code: '541714',
        name: 'Research and Development in Biotechnology (except Nanobiotechnology)',
        level: 4,
        parentCode: '54171',
        hasChildren: false,
    },
    {
        code: '541715',
        name: 'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)',
        level: 4,
        parentCode: '54171',
        hasChildren: false,
    },
    {
        code: '54172',
        name: 'Research and Development in the Social Sciences and Humanities',
        level: 3,
        parentCode: '5417',
        hasChildren: true,
    },
    {
        code: '541720',
        name: 'Research and Development in the Social Sciences and Humanities',
        level: 4,
        parentCode: '54172',
        hasChildren: false,
    },
    {
        code: '5418',
        name: 'Advertising, Public Relations, and Related Services',
        level: 2,
        parentCode: '541',
        hasChildren: true,
    },
    {
        code: '54181',
        name: 'Advertising Agencies',
        level: 3,
        parentCode: '5418',
        hasChildren: true,
    },
    {
        code: '541810',
        name: 'Advertising Agencies',
        level: 4,
        parentCode: '54181',
        hasChildren: false,
    },
    {
        code: '54182',
        name: 'Public Relations Agencies',
        level: 3,
        parentCode: '5418',
        hasChildren: true,
    },
    {
        code: '541820',
        name: 'Public Relations Agencies',
        level: 4,
        parentCode: '54182',
        hasChildren: false,
    },
    {
        code: '54183',
        name: 'Media Buying Agencies',
        level: 3,
        parentCode: '5418',
        hasChildren: true,
    },
    {
        code: '541830',
        name: 'Media Buying Agencies',
        level: 4,
        parentCode: '54183',
        hasChildren: false,
    },
    {
        code: '54184',
        name: 'Media Representatives',
        level: 3,
        parentCode: '5418',
        hasChildren: true,
    },
    {
        code: '541840',
        name: 'Media Representatives',
        level: 4,
        parentCode: '54184',
        hasChildren: false,
    },
    {
        code: '54185',
        name: 'Outdoor Advertising',
        level: 3,
        parentCode: '5418',
        hasChildren: true,
    },
    {
        code: '541850',
        name: 'Outdoor Advertising',
        level: 4,
        parentCode: '54185',
        hasChildren: false,
    },
    {
        code: '54186',
        name: 'Direct Mail Advertising',
        level: 3,
        parentCode: '5418',
        hasChildren: true,
    },
    {
        code: '541860',
        name: 'Direct Mail Advertising',
        level: 4,
        parentCode: '54186',
        hasChildren: false,
    },
    {
        code: '54187',
        name: 'Advertising Material Distribution Services',
        level: 3,
        parentCode: '5418',
        hasChildren: true,
    },
    {
        code: '541870',
        name: 'Advertising Material Distribution Services',
        level: 4,
        parentCode: '54187',
        hasChildren: false,
    },
    {
        code: '54189',
        name: 'Other Services Related to Advertising',
        level: 3,
        parentCode: '5418',
        hasChildren: true,
    },
    {
        code: '541890',
        name: 'Other Services Related to Advertising',
        level: 4,
        parentCode: '54189',
        hasChildren: false,
    },
    {
        code: '5419',
        name: 'Other Professional, Scientific, and Technical Services',
        level: 2,
        parentCode: '541',
        hasChildren: true,
    },
    {
        code: '54191',
        name: 'Marketing Research and Public Opinion Polling',
        level: 3,
        parentCode: '5419',
        hasChildren: true,
    },
    {
        code: '541910',
        name: 'Marketing Research and Public Opinion Polling',
        level: 4,
        parentCode: '54191',
        hasChildren: false,
    },
    {
        code: '54192',
        name: 'Photographic Services',
        level: 3,
        parentCode: '5419',
        hasChildren: true,
    },
    {
        code: '541921',
        name: 'Photography Studios, Portrait',
        level: 4,
        parentCode: '54192',
        hasChildren: false,
    },
    {
        code: '541922',
        name: 'Commercial Photography',
        level: 4,
        parentCode: '54192',
        hasChildren: false,
    },
    {
        code: '54193',
        name: 'Translation and Interpretation Services',
        level: 3,
        parentCode: '5419',
        hasChildren: true,
    },
    {
        code: '541930',
        name: 'Translation and Interpretation Services',
        level: 4,
        parentCode: '54193',
        hasChildren: false,
    },
    {
        code: '54194',
        name: 'Veterinary Services',
        level: 3,
        parentCode: '5419',
        hasChildren: true,
    },
    {
        code: '541940',
        name: 'Veterinary Services',
        level: 4,
        parentCode: '54194',
        hasChildren: false,
    },
    {
        code: '54199',
        name: 'All Other Professional, Scientific, and Technical Services',
        level: 3,
        parentCode: '5419',
        hasChildren: true,
    },
    {
        code: '541990',
        name: 'All Other Professional, Scientific, and Technical Services',
        level: 4,
        parentCode: '54199',
        hasChildren: false,
    },
    {
        code: '55',
        name: 'Management of Companies and Enterprises',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '551',
        name: 'Management of Companies and Enterprises',
        level: 1,
        parentCode: '55',
        hasChildren: true,
    },
    {
        code: '5511',
        name: 'Management of Companies and Enterprises',
        level: 2,
        parentCode: '551',
        hasChildren: true,
    },
    {
        code: '55111',
        name: 'Management of Companies and Enterprises',
        level: 3,
        parentCode: '5511',
        hasChildren: true,
    },
    {
        code: '551111',
        name: 'Offices of Bank Holding Companies',
        level: 4,
        parentCode: '55111',
        hasChildren: false,
    },
    {
        code: '551112',
        name: 'Offices of Other Holding Companies',
        level: 4,
        parentCode: '55111',
        hasChildren: false,
    },
    {
        code: '551114',
        name: 'Corporate, Subsidiary, and Regional Managing Offices',
        level: 4,
        parentCode: '55111',
        hasChildren: false,
    },
    {
        code: '56',
        name: 'Administrative and Support and Waste Management and Remediation Services',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '561',
        name: 'Administrative and Support Services',
        level: 1,
        parentCode: '56',
        hasChildren: true,
    },
    {
        code: '5611',
        name: 'Office Administrative Services',
        level: 2,
        parentCode: '561',
        hasChildren: true,
    },
    {
        code: '56111',
        name: 'Office Administrative Services',
        level: 3,
        parentCode: '5611',
        hasChildren: true,
    },
    {
        code: '561110',
        name: 'Office Administrative Services',
        level: 4,
        parentCode: '56111',
        hasChildren: false,
    },
    {
        code: '5612',
        name: 'Facilities Support Services',
        level: 2,
        parentCode: '561',
        hasChildren: true,
    },
    {
        code: '56121',
        name: 'Facilities Support Services',
        level: 3,
        parentCode: '5612',
        hasChildren: true,
    },
    {
        code: '561210',
        name: 'Facilities Support Services',
        level: 4,
        parentCode: '56121',
        hasChildren: false,
    },
    {
        code: '5613',
        name: 'Employment Services',
        level: 2,
        parentCode: '561',
        hasChildren: true,
    },
    {
        code: '56131',
        name: 'Employment Placement Agencies and Executive Search Services',
        level: 3,
        parentCode: '5613',
        hasChildren: true,
    },
    {
        code: '561311',
        name: 'Employment Placement Agencies',
        level: 4,
        parentCode: '56131',
        hasChildren: false,
    },
    {
        code: '561312',
        name: 'Executive Search Services',
        level: 4,
        parentCode: '56131',
        hasChildren: false,
    },
    {
        code: '56132',
        name: 'Temporary Help Services',
        level: 3,
        parentCode: '5613',
        hasChildren: true,
    },
    {
        code: '561320',
        name: 'Temporary Help Services',
        level: 4,
        parentCode: '56132',
        hasChildren: false,
    },
    {
        code: '56133',
        name: 'Professional Employer Organizations',
        level: 3,
        parentCode: '5613',
        hasChildren: true,
    },
    {
        code: '561330',
        name: 'Professional Employer Organizations',
        level: 4,
        parentCode: '56133',
        hasChildren: false,
    },
    {
        code: '5614',
        name: 'Business Support Services',
        level: 2,
        parentCode: '561',
        hasChildren: true,
    },
    {
        code: '56141',
        name: 'Document Preparation Services',
        level: 3,
        parentCode: '5614',
        hasChildren: true,
    },
    {
        code: '561410',
        name: 'Document Preparation Services',
        level: 4,
        parentCode: '56141',
        hasChildren: false,
    },
    {
        code: '56142',
        name: 'Telephone Call Centers',
        level: 3,
        parentCode: '5614',
        hasChildren: true,
    },
    {
        code: '561421',
        name: 'Telephone Answering Services',
        level: 4,
        parentCode: '56142',
        hasChildren: false,
    },
    {
        code: '561422',
        name: 'Telemarketing Bureaus and Other Contact Centers',
        level: 4,
        parentCode: '56142',
        hasChildren: false,
    },
    {
        code: '56143',
        name: 'Business Service Centers',
        level: 3,
        parentCode: '5614',
        hasChildren: true,
    },
    {
        code: '561431',
        name: 'Private Mail Centers',
        level: 4,
        parentCode: '56143',
        hasChildren: false,
    },
    {
        code: '561439',
        name: 'Other Business Service Centers (including Copy Shops)',
        level: 4,
        parentCode: '56143',
        hasChildren: false,
    },
    {
        code: '56144',
        name: 'Collection Agencies',
        level: 3,
        parentCode: '5614',
        hasChildren: true,
    },
    {
        code: '561440',
        name: 'Collection Agencies',
        level: 4,
        parentCode: '56144',
        hasChildren: false,
    },
    {
        code: '56145',
        name: 'Credit Bureaus',
        level: 3,
        parentCode: '5614',
        hasChildren: true,
    },
    {
        code: '561450',
        name: 'Credit Bureaus',
        level: 4,
        parentCode: '56145',
        hasChildren: false,
    },
    {
        code: '56149',
        name: 'Other Business Support Services',
        level: 3,
        parentCode: '5614',
        hasChildren: true,
    },
    {
        code: '561491',
        name: 'Repossession Services',
        level: 4,
        parentCode: '56149',
        hasChildren: false,
    },
    {
        code: '561492',
        name: 'Court Reporting and Stenotype Services',
        level: 4,
        parentCode: '56149',
        hasChildren: false,
    },
    {
        code: '561499',
        name: 'All Other Business Support Services',
        level: 4,
        parentCode: '56149',
        hasChildren: false,
    },
    {
        code: '5615',
        name: 'Travel Arrangement and Reservation Services',
        level: 2,
        parentCode: '561',
        hasChildren: true,
    },
    {
        code: '56151',
        name: 'Travel Agencies',
        level: 3,
        parentCode: '5615',
        hasChildren: true,
    },
    {
        code: '561510',
        name: 'Travel Agencies',
        level: 4,
        parentCode: '56151',
        hasChildren: false,
    },
    {
        code: '56152',
        name: 'Tour Operators',
        level: 3,
        parentCode: '5615',
        hasChildren: true,
    },
    {
        code: '561520',
        name: 'Tour Operators',
        level: 4,
        parentCode: '56152',
        hasChildren: false,
    },
    {
        code: '56159',
        name: 'Other Travel Arrangement and Reservation Services',
        level: 3,
        parentCode: '5615',
        hasChildren: true,
    },
    {
        code: '561591',
        name: 'Convention and Visitors Bureaus',
        level: 4,
        parentCode: '56159',
        hasChildren: false,
    },
    {
        code: '561599',
        name: 'All Other Travel Arrangement and Reservation Services',
        level: 4,
        parentCode: '56159',
        hasChildren: false,
    },
    {
        code: '5616',
        name: 'Investigation and Security Services',
        level: 2,
        parentCode: '561',
        hasChildren: true,
    },
    {
        code: '56161',
        name: 'Investigation, Guard, and Armored Car Services',
        level: 3,
        parentCode: '5616',
        hasChildren: true,
    },
    {
        code: '561611',
        name: 'Investigation Services',
        level: 4,
        parentCode: '56161',
        hasChildren: false,
    },
    {
        code: '561612',
        name: 'Security Guards and Patrol Services',
        level: 4,
        parentCode: '56161',
        hasChildren: false,
    },
    {
        code: '561613',
        name: 'Armored Car Services',
        level: 4,
        parentCode: '56161',
        hasChildren: false,
    },
    {
        code: '56162',
        name: 'Security Systems Services',
        level: 3,
        parentCode: '5616',
        hasChildren: true,
    },
    {
        code: '561621',
        name: 'Security Systems Services (except Locksmiths)',
        level: 4,
        parentCode: '56162',
        hasChildren: false,
    },
    {
        code: '561622',
        name: 'Locksmiths',
        level: 4,
        parentCode: '56162',
        hasChildren: false,
    },
    {
        code: '5617',
        name: 'Services to Buildings and Dwellings',
        level: 2,
        parentCode: '561',
        hasChildren: true,
    },
    {
        code: '56171',
        name: 'Exterminating and Pest Control Services',
        level: 3,
        parentCode: '5617',
        hasChildren: true,
    },
    {
        code: '561710',
        name: 'Exterminating and Pest Control Services',
        level: 4,
        parentCode: '56171',
        hasChildren: false,
    },
    {
        code: '56172',
        name: 'Janitorial Services',
        level: 3,
        parentCode: '5617',
        hasChildren: true,
    },
    {
        code: '561720',
        name: 'Janitorial Services',
        level: 4,
        parentCode: '56172',
        hasChildren: false,
    },
    {
        code: '56173',
        name: 'Landscaping Services',
        level: 3,
        parentCode: '5617',
        hasChildren: true,
    },
    {
        code: '561730',
        name: 'Landscaping Services',
        level: 4,
        parentCode: '56173',
        hasChildren: false,
    },
    {
        code: '56174',
        name: 'Carpet and Upholstery Cleaning Services',
        level: 3,
        parentCode: '5617',
        hasChildren: true,
    },
    {
        code: '561740',
        name: 'Carpet and Upholstery Cleaning Services',
        level: 4,
        parentCode: '56174',
        hasChildren: false,
    },
    {
        code: '56179',
        name: 'Other Services to Buildings and Dwellings',
        level: 3,
        parentCode: '5617',
        hasChildren: true,
    },
    {
        code: '561790',
        name: 'Other Services to Buildings and Dwellings',
        level: 4,
        parentCode: '56179',
        hasChildren: false,
    },
    {
        code: '5619',
        name: 'Other Support Services',
        level: 2,
        parentCode: '561',
        hasChildren: true,
    },
    {
        code: '56191',
        name: 'Packaging and Labeling Services',
        level: 3,
        parentCode: '5619',
        hasChildren: true,
    },
    {
        code: '561910',
        name: 'Packaging and Labeling Services',
        level: 4,
        parentCode: '56191',
        hasChildren: false,
    },
    {
        code: '56192',
        name: 'Convention and Trade Show Organizers',
        level: 3,
        parentCode: '5619',
        hasChildren: true,
    },
    {
        code: '561920',
        name: 'Convention and Trade Show Organizers',
        level: 4,
        parentCode: '56192',
        hasChildren: false,
    },
    {
        code: '56199',
        name: 'All Other Support Services',
        level: 3,
        parentCode: '5619',
        hasChildren: true,
    },
    {
        code: '561990',
        name: 'All Other Support Services',
        level: 4,
        parentCode: '56199',
        hasChildren: false,
    },
    {
        code: '562',
        name: 'Waste Management and Remediation Services',
        level: 1,
        parentCode: '56',
        hasChildren: true,
    },
    {
        code: '5621',
        name: 'Waste Collection',
        level: 2,
        parentCode: '562',
        hasChildren: true,
    },
    {
        code: '56211',
        name: 'Waste Collection',
        level: 3,
        parentCode: '5621',
        hasChildren: true,
    },
    {
        code: '562111',
        name: 'Solid Waste Collection',
        level: 4,
        parentCode: '56211',
        hasChildren: false,
    },
    {
        code: '562112',
        name: 'Hazardous Waste Collection',
        level: 4,
        parentCode: '56211',
        hasChildren: false,
    },
    {
        code: '562119',
        name: 'Other Waste Collection',
        level: 4,
        parentCode: '56211',
        hasChildren: false,
    },
    {
        code: '5622',
        name: 'Waste Treatment and Disposal',
        level: 2,
        parentCode: '562',
        hasChildren: true,
    },
    {
        code: '56221',
        name: 'Waste Treatment and Disposal',
        level: 3,
        parentCode: '5622',
        hasChildren: true,
    },
    {
        code: '562211',
        name: 'Hazardous Waste Treatment and Disposal',
        level: 4,
        parentCode: '56221',
        hasChildren: false,
    },
    {
        code: '562212',
        name: 'Solid Waste Landfill',
        level: 4,
        parentCode: '56221',
        hasChildren: false,
    },
    {
        code: '562213',
        name: 'Solid Waste Combustors and Incinerators',
        level: 4,
        parentCode: '56221',
        hasChildren: false,
    },
    {
        code: '562219',
        name: 'Other Nonhazardous Waste Treatment and Disposal',
        level: 4,
        parentCode: '56221',
        hasChildren: false,
    },
    {
        code: '5629',
        name: 'Remediation and Other Waste Management Services',
        level: 2,
        parentCode: '562',
        hasChildren: true,
    },
    {
        code: '56291',
        name: 'Remediation Services',
        level: 3,
        parentCode: '5629',
        hasChildren: true,
    },
    {
        code: '562910',
        name: 'Remediation Services',
        level: 4,
        parentCode: '56291',
        hasChildren: false,
    },
    {
        code: '56292',
        name: 'Materials Recovery Facilities',
        level: 3,
        parentCode: '5629',
        hasChildren: true,
    },
    {
        code: '562920',
        name: 'Materials Recovery Facilities',
        level: 4,
        parentCode: '56292',
        hasChildren: false,
    },
    {
        code: '56299',
        name: 'All Other Waste Management Services',
        level: 3,
        parentCode: '5629',
        hasChildren: true,
    },
    {
        code: '562991',
        name: 'Septic Tank and Related Services',
        level: 4,
        parentCode: '56299',
        hasChildren: false,
    },
    {
        code: '562998',
        name: 'All Other Miscellaneous Waste Management Services',
        level: 4,
        parentCode: '56299',
        hasChildren: false,
    },
    {
        code: '61',
        name: 'Educational Services',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '611',
        name: 'Educational Services',
        level: 1,
        parentCode: '61',
        hasChildren: true,
    },
    {
        code: '6111',
        name: 'Elementary and Secondary Schools',
        level: 2,
        parentCode: '611',
        hasChildren: true,
    },
    {
        code: '61111',
        name: 'Elementary and Secondary Schools',
        level: 3,
        parentCode: '6111',
        hasChildren: true,
    },
    {
        code: '611110',
        name: 'Elementary and Secondary Schools',
        level: 4,
        parentCode: '61111',
        hasChildren: false,
    },
    {
        code: '6112',
        name: 'Junior Colleges',
        level: 2,
        parentCode: '611',
        hasChildren: true,
    },
    {
        code: '61121',
        name: 'Junior Colleges',
        level: 3,
        parentCode: '6112',
        hasChildren: true,
    },
    {
        code: '611210',
        name: 'Junior Colleges',
        level: 4,
        parentCode: '61121',
        hasChildren: false,
    },
    {
        code: '6113',
        name: 'Colleges, Universities, and Professional Schools',
        level: 2,
        parentCode: '611',
        hasChildren: true,
    },
    {
        code: '61131',
        name: 'Colleges, Universities, and Professional Schools',
        level: 3,
        parentCode: '6113',
        hasChildren: true,
    },
    {
        code: '611310',
        name: 'Colleges, Universities, and Professional Schools',
        level: 4,
        parentCode: '61131',
        hasChildren: false,
    },
    {
        code: '6114',
        name: 'Business Schools and Computer and Management Training',
        level: 2,
        parentCode: '611',
        hasChildren: true,
    },
    {
        code: '61141',
        name: 'Business and Secretarial Schools',
        level: 3,
        parentCode: '6114',
        hasChildren: true,
    },
    {
        code: '611410',
        name: 'Business and Secretarial Schools',
        level: 4,
        parentCode: '61141',
        hasChildren: false,
    },
    {
        code: '61142',
        name: 'Computer Training',
        level: 3,
        parentCode: '6114',
        hasChildren: true,
    },
    {
        code: '611420',
        name: 'Computer Training',
        level: 4,
        parentCode: '61142',
        hasChildren: false,
    },
    {
        code: '61143',
        name: 'Professional and Management Development Training',
        level: 3,
        parentCode: '6114',
        hasChildren: true,
    },
    {
        code: '611430',
        name: 'Professional and Management Development Training',
        level: 4,
        parentCode: '61143',
        hasChildren: false,
    },
    {
        code: '6115',
        name: 'Technical and Trade Schools',
        level: 2,
        parentCode: '611',
        hasChildren: true,
    },
    {
        code: '61151',
        name: 'Technical and Trade Schools',
        level: 3,
        parentCode: '6115',
        hasChildren: true,
    },
    {
        code: '611511',
        name: 'Cosmetology and Barber Schools',
        level: 4,
        parentCode: '61151',
        hasChildren: false,
    },
    {
        code: '611512',
        name: 'Flight Training',
        level: 4,
        parentCode: '61151',
        hasChildren: false,
    },
    {
        code: '611513',
        name: 'Apprenticeship Training',
        level: 4,
        parentCode: '61151',
        hasChildren: false,
    },
    {
        code: '611519',
        name: 'Other Technical and Trade Schools',
        level: 4,
        parentCode: '61151',
        hasChildren: false,
    },
    {
        code: '6116',
        name: 'Other Schools and Instruction',
        level: 2,
        parentCode: '611',
        hasChildren: true,
    },
    {
        code: '61161',
        name: 'Fine Arts Schools',
        level: 3,
        parentCode: '6116',
        hasChildren: true,
    },
    {
        code: '611610',
        name: 'Fine Arts Schools',
        level: 4,
        parentCode: '61161',
        hasChildren: false,
    },
    {
        code: '61162',
        name: 'Sports and Recreation Instruction',
        level: 3,
        parentCode: '6116',
        hasChildren: true,
    },
    {
        code: '611620',
        name: 'Sports and Recreation Instruction',
        level: 4,
        parentCode: '61162',
        hasChildren: false,
    },
    {
        code: '61163',
        name: 'Language Schools',
        level: 3,
        parentCode: '6116',
        hasChildren: true,
    },
    {
        code: '611630',
        name: 'Language Schools',
        level: 4,
        parentCode: '61163',
        hasChildren: false,
    },
    {
        code: '61169',
        name: 'All Other Schools and Instruction',
        level: 3,
        parentCode: '6116',
        hasChildren: true,
    },
    {
        code: '611691',
        name: 'Exam Preparation and Tutoring',
        level: 4,
        parentCode: '61169',
        hasChildren: false,
    },
    {
        code: '611692',
        name: 'Automobile Driving Schools',
        level: 4,
        parentCode: '61169',
        hasChildren: false,
    },
    {
        code: '611699',
        name: 'All Other Miscellaneous Schools and Instruction',
        level: 4,
        parentCode: '61169',
        hasChildren: false,
    },
    {
        code: '6117',
        name: 'Educational Support Services',
        level: 2,
        parentCode: '611',
        hasChildren: true,
    },
    {
        code: '61171',
        name: 'Educational Support Services',
        level: 3,
        parentCode: '6117',
        hasChildren: true,
    },
    {
        code: '611710',
        name: 'Educational Support Services',
        level: 4,
        parentCode: '61171',
        hasChildren: false,
    },
    {
        code: '62',
        name: 'Health Care and Social Assistance',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '621',
        name: 'Ambulatory Health Care Services',
        level: 1,
        parentCode: '62',
        hasChildren: true,
    },
    {
        code: '6211',
        name: 'Offices of Physicians',
        level: 2,
        parentCode: '621',
        hasChildren: true,
    },
    {
        code: '62111',
        name: 'Offices of Physicians',
        level: 3,
        parentCode: '6211',
        hasChildren: true,
    },
    {
        code: '621111',
        name: 'Offices of Physicians (except Mental Health Specialists)',
        level: 4,
        parentCode: '62111',
        hasChildren: false,
    },
    {
        code: '621112',
        name: 'Offices of Physicians, Mental Health Specialists',
        level: 4,
        parentCode: '62111',
        hasChildren: false,
    },
    {
        code: '6212',
        name: 'Offices of Dentists',
        level: 2,
        parentCode: '621',
        hasChildren: true,
    },
    {
        code: '62121',
        name: 'Offices of Dentists',
        level: 3,
        parentCode: '6212',
        hasChildren: true,
    },
    {
        code: '621210',
        name: 'Offices of Dentists',
        level: 4,
        parentCode: '62121',
        hasChildren: false,
    },
    {
        code: '6213',
        name: 'Offices of Other Health Practitioners',
        level: 2,
        parentCode: '621',
        hasChildren: true,
    },
    {
        code: '62131',
        name: 'Offices of Chiropractors',
        level: 3,
        parentCode: '6213',
        hasChildren: true,
    },
    {
        code: '621310',
        name: 'Offices of Chiropractors',
        level: 4,
        parentCode: '62131',
        hasChildren: false,
    },
    {
        code: '62132',
        name: 'Offices of Optometrists',
        level: 3,
        parentCode: '6213',
        hasChildren: true,
    },
    {
        code: '621320',
        name: 'Offices of Optometrists',
        level: 4,
        parentCode: '62132',
        hasChildren: false,
    },
    {
        code: '62133',
        name: 'Offices of Mental Health Practitioners (except Physicians)',
        level: 3,
        parentCode: '6213',
        hasChildren: true,
    },
    {
        code: '621330',
        name: 'Offices of Mental Health Practitioners (except Physicians)',
        level: 4,
        parentCode: '62133',
        hasChildren: false,
    },
    {
        code: '62134',
        name: 'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
        level: 3,
        parentCode: '6213',
        hasChildren: true,
    },
    {
        code: '621340',
        name: 'Offices of Physical, Occupational and Speech Therapists, and Audiologists',
        level: 4,
        parentCode: '62134',
        hasChildren: false,
    },
    {
        code: '62139',
        name: 'Offices of All Other Health Practitioners',
        level: 3,
        parentCode: '6213',
        hasChildren: true,
    },
    {
        code: '621391',
        name: 'Offices of Podiatrists',
        level: 4,
        parentCode: '62139',
        hasChildren: false,
    },
    {
        code: '621399',
        name: 'Offices of All Other Miscellaneous Health Practitioners',
        level: 4,
        parentCode: '62139',
        hasChildren: false,
    },
    {
        code: '6214',
        name: 'Outpatient Care Centers',
        level: 2,
        parentCode: '621',
        hasChildren: true,
    },
    {
        code: '62141',
        name: 'Family Planning Centers',
        level: 3,
        parentCode: '6214',
        hasChildren: true,
    },
    {
        code: '621410',
        name: 'Family Planning Centers',
        level: 4,
        parentCode: '62141',
        hasChildren: false,
    },
    {
        code: '62142',
        name: 'Outpatient Mental Health and Substance Abuse Centers',
        level: 3,
        parentCode: '6214',
        hasChildren: true,
    },
    {
        code: '621420',
        name: 'Outpatient Mental Health and Substance Abuse Centers',
        level: 4,
        parentCode: '62142',
        hasChildren: false,
    },
    {
        code: '62149',
        name: 'Other Outpatient Care Centers',
        level: 3,
        parentCode: '6214',
        hasChildren: true,
    },
    {
        code: '621491',
        name: 'HMO Medical Centers',
        level: 4,
        parentCode: '62149',
        hasChildren: false,
    },
    {
        code: '621492',
        name: 'Kidney Dialysis Centers',
        level: 4,
        parentCode: '62149',
        hasChildren: false,
    },
    {
        code: '621493',
        name: 'Freestanding Ambulatory Surgical and Emergency Centers',
        level: 4,
        parentCode: '62149',
        hasChildren: false,
    },
    {
        code: '621498',
        name: 'All Other Outpatient Care Centers',
        level: 4,
        parentCode: '62149',
        hasChildren: false,
    },
    {
        code: '6215',
        name: 'Medical and Diagnostic Laboratories',
        level: 2,
        parentCode: '621',
        hasChildren: true,
    },
    {
        code: '62151',
        name: 'Medical and Diagnostic Laboratories',
        level: 3,
        parentCode: '6215',
        hasChildren: true,
    },
    {
        code: '621511',
        name: 'Medical Laboratories',
        level: 4,
        parentCode: '62151',
        hasChildren: false,
    },
    {
        code: '621512',
        name: 'Diagnostic Imaging Centers',
        level: 4,
        parentCode: '62151',
        hasChildren: false,
    },
    {
        code: '6216',
        name: 'Home Health Care Services',
        level: 2,
        parentCode: '621',
        hasChildren: true,
    },
    {
        code: '62161',
        name: 'Home Health Care Services',
        level: 3,
        parentCode: '6216',
        hasChildren: true,
    },
    {
        code: '621610',
        name: 'Home Health Care Services',
        level: 4,
        parentCode: '62161',
        hasChildren: false,
    },
    {
        code: '6219',
        name: 'Other Ambulatory Health Care Services',
        level: 2,
        parentCode: '621',
        hasChildren: true,
    },
    {
        code: '62191',
        name: 'Ambulance Services',
        level: 3,
        parentCode: '6219',
        hasChildren: true,
    },
    {
        code: '621910',
        name: 'Ambulance Services',
        level: 4,
        parentCode: '62191',
        hasChildren: false,
    },
    {
        code: '62199',
        name: 'All Other Ambulatory Health Care Services',
        level: 3,
        parentCode: '6219',
        hasChildren: true,
    },
    {
        code: '621991',
        name: 'Blood and Organ Banks',
        level: 4,
        parentCode: '62199',
        hasChildren: false,
    },
    {
        code: '621999',
        name: 'All Other Miscellaneous Ambulatory Health Care Services',
        level: 4,
        parentCode: '62199',
        hasChildren: false,
    },
    {
        code: '622',
        name: 'Hospitals',
        level: 1,
        parentCode: '62',
        hasChildren: true,
    },
    {
        code: '6221',
        name: 'General Medical and Surgical Hospitals',
        level: 2,
        parentCode: '622',
        hasChildren: true,
    },
    {
        code: '62211',
        name: 'General Medical and Surgical Hospitals',
        level: 3,
        parentCode: '6221',
        hasChildren: true,
    },
    {
        code: '622110',
        name: 'General Medical and Surgical Hospitals',
        level: 4,
        parentCode: '62211',
        hasChildren: false,
    },
    {
        code: '6222',
        name: 'Psychiatric and Substance Abuse Hospitals',
        level: 2,
        parentCode: '622',
        hasChildren: true,
    },
    {
        code: '62221',
        name: 'Psychiatric and Substance Abuse Hospitals',
        level: 3,
        parentCode: '6222',
        hasChildren: true,
    },
    {
        code: '622210',
        name: 'Psychiatric and Substance Abuse Hospitals',
        level: 4,
        parentCode: '62221',
        hasChildren: false,
    },
    {
        code: '6223',
        name: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
        level: 2,
        parentCode: '622',
        hasChildren: true,
    },
    {
        code: '62231',
        name: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
        level: 3,
        parentCode: '6223',
        hasChildren: true,
    },
    {
        code: '622310',
        name: 'Specialty (except Psychiatric and Substance Abuse) Hospitals',
        level: 4,
        parentCode: '62231',
        hasChildren: false,
    },
    {
        code: '623',
        name: 'Nursing and Residential Care Facilities',
        level: 1,
        parentCode: '62',
        hasChildren: true,
    },
    {
        code: '6231',
        name: 'Nursing Care Facilities (Skilled Nursing Facilities)',
        level: 2,
        parentCode: '623',
        hasChildren: true,
    },
    {
        code: '62311',
        name: 'Nursing Care Facilities (Skilled Nursing Facilities)',
        level: 3,
        parentCode: '6231',
        hasChildren: true,
    },
    {
        code: '623110',
        name: 'Nursing Care Facilities (Skilled Nursing Facilities)',
        level: 4,
        parentCode: '62311',
        hasChildren: false,
    },
    {
        code: '6232',
        name: 'Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities',
        level: 2,
        parentCode: '623',
        hasChildren: true,
    },
    {
        code: '62321',
        name: 'Residential Intellectual and Developmental Disability Facilities',
        level: 3,
        parentCode: '6232',
        hasChildren: true,
    },
    {
        code: '623210',
        name: 'Residential Intellectual and Developmental Disability Facilities',
        level: 4,
        parentCode: '62321',
        hasChildren: false,
    },
    {
        code: '62322',
        name: 'Residential Mental Health and Substance Abuse Facilities',
        level: 3,
        parentCode: '6232',
        hasChildren: true,
    },
    {
        code: '623220',
        name: 'Residential Mental Health and Substance Abuse Facilities',
        level: 4,
        parentCode: '62322',
        hasChildren: false,
    },
    {
        code: '6233',
        name: 'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
        level: 2,
        parentCode: '623',
        hasChildren: true,
    },
    {
        code: '62331',
        name: 'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
        level: 3,
        parentCode: '6233',
        hasChildren: true,
    },
    {
        code: '623311',
        name: 'Continuing Care Retirement Communities',
        level: 4,
        parentCode: '62331',
        hasChildren: false,
    },
    {
        code: '623312',
        name: 'Assisted Living Facilities for the Elderly',
        level: 4,
        parentCode: '62331',
        hasChildren: false,
    },
    {
        code: '6239',
        name: 'Other Residential Care Facilities',
        level: 2,
        parentCode: '623',
        hasChildren: true,
    },
    {
        code: '62399',
        name: 'Other Residential Care Facilities',
        level: 3,
        parentCode: '6239',
        hasChildren: true,
    },
    {
        code: '623990',
        name: 'Other Residential Care Facilities',
        level: 4,
        parentCode: '62399',
        hasChildren: false,
    },
    {
        code: '624',
        name: 'Social Assistance',
        level: 1,
        parentCode: '62',
        hasChildren: true,
    },
    {
        code: '6241',
        name: 'Individual and Family Services',
        level: 2,
        parentCode: '624',
        hasChildren: true,
    },
    {
        code: '62411',
        name: 'Child and Youth Services',
        level: 3,
        parentCode: '6241',
        hasChildren: true,
    },
    {
        code: '624110',
        name: 'Child and Youth Services',
        level: 4,
        parentCode: '62411',
        hasChildren: false,
    },
    {
        code: '62412',
        name: 'Services for the Elderly and Persons with Disabilities',
        level: 3,
        parentCode: '6241',
        hasChildren: true,
    },
    {
        code: '624120',
        name: 'Services for the Elderly and Persons with Disabilities',
        level: 4,
        parentCode: '62412',
        hasChildren: false,
    },
    {
        code: '62419',
        name: 'Other Individual and Family Services',
        level: 3,
        parentCode: '6241',
        hasChildren: true,
    },
    {
        code: '624190',
        name: 'Other Individual and Family Services',
        level: 4,
        parentCode: '62419',
        hasChildren: false,
    },
    {
        code: '6242',
        name: 'Community Food and Housing, and Emergency and Other Relief Services',
        level: 2,
        parentCode: '624',
        hasChildren: true,
    },
    {
        code: '62421',
        name: 'Community Food Services',
        level: 3,
        parentCode: '6242',
        hasChildren: true,
    },
    {
        code: '624210',
        name: 'Community Food Services',
        level: 4,
        parentCode: '62421',
        hasChildren: false,
    },
    {
        code: '62422',
        name: 'Community Housing Services',
        level: 3,
        parentCode: '6242',
        hasChildren: true,
    },
    {
        code: '624221',
        name: 'Temporary Shelters',
        level: 4,
        parentCode: '62422',
        hasChildren: false,
    },
    {
        code: '624229',
        name: 'Other Community Housing Services',
        level: 4,
        parentCode: '62422',
        hasChildren: false,
    },
    {
        code: '62423',
        name: 'Emergency and Other Relief Services',
        level: 3,
        parentCode: '6242',
        hasChildren: true,
    },
    {
        code: '624230',
        name: 'Emergency and Other Relief Services',
        level: 4,
        parentCode: '62423',
        hasChildren: false,
    },
    {
        code: '6243',
        name: 'Vocational Rehabilitation Services',
        level: 2,
        parentCode: '624',
        hasChildren: true,
    },
    {
        code: '62431',
        name: 'Vocational Rehabilitation Services',
        level: 3,
        parentCode: '6243',
        hasChildren: true,
    },
    {
        code: '624310',
        name: 'Vocational Rehabilitation Services',
        level: 4,
        parentCode: '62431',
        hasChildren: false,
    },
    {
        code: '6244',
        name: 'Child Day Care Services',
        level: 2,
        parentCode: '624',
        hasChildren: true,
    },
    {
        code: '62441',
        name: 'Child Day Care Services',
        level: 3,
        parentCode: '6244',
        hasChildren: true,
    },
    {
        code: '624410',
        name: 'Child Day Care Services',
        level: 4,
        parentCode: '62441',
        hasChildren: false,
    },
    {
        code: '71',
        name: 'Arts, Entertainment, and Recreation',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '711',
        name: 'Performing Arts, Spectator Sports, and Related Industries',
        level: 1,
        parentCode: '71',
        hasChildren: true,
    },
    {
        code: '7111',
        name: 'Performing Arts Companies',
        level: 2,
        parentCode: '711',
        hasChildren: true,
    },
    {
        code: '71111',
        name: 'Theater Companies and Dinner Theaters',
        level: 3,
        parentCode: '7111',
        hasChildren: true,
    },
    {
        code: '711110',
        name: 'Theater Companies and Dinner Theaters',
        level: 4,
        parentCode: '71111',
        hasChildren: false,
    },
    {
        code: '71112',
        name: 'Dance Companies',
        level: 3,
        parentCode: '7111',
        hasChildren: true,
    },
    {
        code: '711120',
        name: 'Dance Companies',
        level: 4,
        parentCode: '71112',
        hasChildren: false,
    },
    {
        code: '71113',
        name: 'Musical Groups and Artists',
        level: 3,
        parentCode: '7111',
        hasChildren: true,
    },
    {
        code: '711130',
        name: 'Musical Groups and Artists',
        level: 4,
        parentCode: '71113',
        hasChildren: false,
    },
    {
        code: '71119',
        name: 'Other Performing Arts Companies',
        level: 3,
        parentCode: '7111',
        hasChildren: true,
    },
    {
        code: '711190',
        name: 'Other Performing Arts Companies',
        level: 4,
        parentCode: '71119',
        hasChildren: false,
    },
    {
        code: '7112',
        name: 'Spectator Sports',
        level: 2,
        parentCode: '711',
        hasChildren: true,
    },
    {
        code: '71121',
        name: 'Spectator Sports',
        level: 3,
        parentCode: '7112',
        hasChildren: true,
    },
    {
        code: '711211',
        name: 'Sports Teams and Clubs',
        level: 4,
        parentCode: '71121',
        hasChildren: false,
    },
    {
        code: '711212',
        name: 'Racetracks',
        level: 4,
        parentCode: '71121',
        hasChildren: false,
    },
    {
        code: '711219',
        name: 'Other Spectator Sports',
        level: 4,
        parentCode: '71121',
        hasChildren: false,
    },
    {
        code: '7113',
        name: 'Promoters of Performing Arts, Sports, and Similar Events',
        level: 2,
        parentCode: '711',
        hasChildren: true,
    },
    {
        code: '71131',
        name: 'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
        level: 3,
        parentCode: '7113',
        hasChildren: true,
    },
    {
        code: '711310',
        name: 'Promoters of Performing Arts, Sports, and Similar Events with Facilities',
        level: 4,
        parentCode: '71131',
        hasChildren: false,
    },
    {
        code: '71132',
        name: 'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
        level: 3,
        parentCode: '7113',
        hasChildren: true,
    },
    {
        code: '711320',
        name: 'Promoters of Performing Arts, Sports, and Similar Events without Facilities',
        level: 4,
        parentCode: '71132',
        hasChildren: false,
    },
    {
        code: '7114',
        name: 'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
        level: 2,
        parentCode: '711',
        hasChildren: true,
    },
    {
        code: '71141',
        name: 'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
        level: 3,
        parentCode: '7114',
        hasChildren: true,
    },
    {
        code: '711410',
        name: 'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
        level: 4,
        parentCode: '71141',
        hasChildren: false,
    },
    {
        code: '7115',
        name: 'Independent Artists, Writers, and Performers',
        level: 2,
        parentCode: '711',
        hasChildren: true,
    },
    {
        code: '71151',
        name: 'Independent Artists, Writers, and Performers',
        level: 3,
        parentCode: '7115',
        hasChildren: true,
    },
    {
        code: '711510',
        name: 'Independent Artists, Writers, and Performers',
        level: 4,
        parentCode: '71151',
        hasChildren: false,
    },
    {
        code: '712',
        name: 'Museums, Historical Sites, and Similar Institutions',
        level: 1,
        parentCode: '71',
        hasChildren: true,
    },
    {
        code: '7121',
        name: 'Museums, Historical Sites, and Similar Institutions',
        level: 2,
        parentCode: '712',
        hasChildren: true,
    },
    {
        code: '71211',
        name: 'Museums',
        level: 3,
        parentCode: '7121',
        hasChildren: true,
    },
    {
        code: '712110',
        name: 'Museums',
        level: 4,
        parentCode: '71211',
        hasChildren: false,
    },
    {
        code: '71212',
        name: 'Historical Sites',
        level: 3,
        parentCode: '7121',
        hasChildren: true,
    },
    {
        code: '712120',
        name: 'Historical Sites',
        level: 4,
        parentCode: '71212',
        hasChildren: false,
    },
    {
        code: '71213',
        name: 'Zoos and Botanical Gardens',
        level: 3,
        parentCode: '7121',
        hasChildren: true,
    },
    {
        code: '712130',
        name: 'Zoos and Botanical Gardens',
        level: 4,
        parentCode: '71213',
        hasChildren: false,
    },
    {
        code: '71219',
        name: 'Nature Parks and Other Similar Institutions',
        level: 3,
        parentCode: '7121',
        hasChildren: true,
    },
    {
        code: '712190',
        name: 'Nature Parks and Other Similar Institutions',
        level: 4,
        parentCode: '71219',
        hasChildren: false,
    },
    {
        code: '713',
        name: 'Amusement, Gambling, and Recreation Industries',
        level: 1,
        parentCode: '71',
        hasChildren: true,
    },
    {
        code: '7131',
        name: 'Amusement Parks and Arcades',
        level: 2,
        parentCode: '713',
        hasChildren: true,
    },
    {
        code: '71311',
        name: 'Amusement and Theme Parks',
        level: 3,
        parentCode: '7131',
        hasChildren: true,
    },
    {
        code: '713110',
        name: 'Amusement and Theme Parks',
        level: 4,
        parentCode: '71311',
        hasChildren: false,
    },
    {
        code: '71312',
        name: 'Amusement Arcades',
        level: 3,
        parentCode: '7131',
        hasChildren: true,
    },
    {
        code: '713120',
        name: 'Amusement Arcades',
        level: 4,
        parentCode: '71312',
        hasChildren: false,
    },
    {
        code: '7132',
        name: 'Gambling Industries',
        level: 2,
        parentCode: '713',
        hasChildren: true,
    },
    {
        code: '71321',
        name: 'Casinos (except Casino Hotels)',
        level: 3,
        parentCode: '7132',
        hasChildren: true,
    },
    {
        code: '713210',
        name: 'Casinos (except Casino Hotels)',
        level: 4,
        parentCode: '71321',
        hasChildren: false,
    },
    {
        code: '71329',
        name: 'Other Gambling Industries',
        level: 3,
        parentCode: '7132',
        hasChildren: true,
    },
    {
        code: '713290',
        name: 'Other Gambling Industries',
        level: 4,
        parentCode: '71329',
        hasChildren: false,
    },
    {
        code: '7139',
        name: 'Other Amusement and Recreation Industries',
        level: 2,
        parentCode: '713',
        hasChildren: true,
    },
    {
        code: '71391',
        name: 'Golf Courses and Country Clubs',
        level: 3,
        parentCode: '7139',
        hasChildren: true,
    },
    {
        code: '713910',
        name: 'Golf Courses and Country Clubs',
        level: 4,
        parentCode: '71391',
        hasChildren: false,
    },
    {
        code: '71392',
        name: 'Skiing Facilities',
        level: 3,
        parentCode: '7139',
        hasChildren: true,
    },
    {
        code: '713920',
        name: 'Skiing Facilities',
        level: 4,
        parentCode: '71392',
        hasChildren: false,
    },
    {
        code: '71393',
        name: 'Marinas',
        level: 3,
        parentCode: '7139',
        hasChildren: true,
    },
    {
        code: '713930',
        name: 'Marinas',
        level: 4,
        parentCode: '71393',
        hasChildren: false,
    },
    {
        code: '71394',
        name: 'Fitness and Recreational Sports Centers',
        level: 3,
        parentCode: '7139',
        hasChildren: true,
    },
    {
        code: '713940',
        name: 'Fitness and Recreational Sports Centers',
        level: 4,
        parentCode: '71394',
        hasChildren: false,
    },
    {
        code: '71395',
        name: 'Bowling Centers',
        level: 3,
        parentCode: '7139',
        hasChildren: true,
    },
    {
        code: '713950',
        name: 'Bowling Centers',
        level: 4,
        parentCode: '71395',
        hasChildren: false,
    },
    {
        code: '71399',
        name: 'All Other Amusement and Recreation Industries',
        level: 3,
        parentCode: '7139',
        hasChildren: true,
    },
    {
        code: '713990',
        name: 'All Other Amusement and Recreation Industries',
        level: 4,
        parentCode: '71399',
        hasChildren: false,
    },
    {
        code: '72',
        name: 'Accommodation and Food Services',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '721',
        name: 'Accommodation',
        level: 1,
        parentCode: '72',
        hasChildren: true,
    },
    {
        code: '7211',
        name: 'Traveler Accommodation',
        level: 2,
        parentCode: '721',
        hasChildren: true,
    },
    {
        code: '72111',
        name: 'Hotels (except Casino Hotels) and Motels',
        level: 3,
        parentCode: '7211',
        hasChildren: true,
    },
    {
        code: '721110',
        name: 'Hotels (except Casino Hotels) and Motels',
        level: 4,
        parentCode: '72111',
        hasChildren: false,
    },
    {
        code: '72112',
        name: 'Casino Hotels',
        level: 3,
        parentCode: '7211',
        hasChildren: true,
    },
    {
        code: '721120',
        name: 'Casino Hotels',
        level: 4,
        parentCode: '72112',
        hasChildren: false,
    },
    {
        code: '72119',
        name: 'Other Traveler Accommodation',
        level: 3,
        parentCode: '7211',
        hasChildren: true,
    },
    {
        code: '721191',
        name: 'Bed-and-Breakfast Inns',
        level: 4,
        parentCode: '72119',
        hasChildren: false,
    },
    {
        code: '721199',
        name: 'All Other Traveler Accommodation',
        level: 4,
        parentCode: '72119',
        hasChildren: false,
    },
    {
        code: '7212',
        name: 'RV (Recreational Vehicle) Parks and Recreational Camps',
        level: 2,
        parentCode: '721',
        hasChildren: true,
    },
    {
        code: '72121',
        name: 'RV (Recreational Vehicle) Parks and Recreational Camps',
        level: 3,
        parentCode: '7212',
        hasChildren: true,
    },
    {
        code: '721211',
        name: 'RV (Recreational Vehicle) Parks and Campgrounds',
        level: 4,
        parentCode: '72121',
        hasChildren: false,
    },
    {
        code: '721214',
        name: 'Recreational and Vacation Camps (except Campgrounds)',
        level: 4,
        parentCode: '72121',
        hasChildren: false,
    },
    {
        code: '7213',
        name: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
        level: 2,
        parentCode: '721',
        hasChildren: true,
    },
    {
        code: '72131',
        name: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
        level: 3,
        parentCode: '7213',
        hasChildren: true,
    },
    {
        code: '721310',
        name: "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
        level: 4,
        parentCode: '72131',
        hasChildren: false,
    },
    {
        code: '722',
        name: 'Food Services and Drinking Places',
        level: 1,
        parentCode: '72',
        hasChildren: true,
    },
    {
        code: '7223',
        name: 'Special Food Services',
        level: 2,
        parentCode: '722',
        hasChildren: true,
    },
    {
        code: '72231',
        name: 'Food Service Contractors',
        level: 3,
        parentCode: '7223',
        hasChildren: true,
    },
    {
        code: '722310',
        name: 'Food Service Contractors',
        level: 4,
        parentCode: '72231',
        hasChildren: false,
    },
    {
        code: '72232',
        name: 'Caterers',
        level: 3,
        parentCode: '7223',
        hasChildren: true,
    },
    {
        code: '722320',
        name: 'Caterers',
        level: 4,
        parentCode: '72232',
        hasChildren: false,
    },
    {
        code: '72233',
        name: 'Mobile Food Services',
        level: 3,
        parentCode: '7223',
        hasChildren: true,
    },
    {
        code: '722330',
        name: 'Mobile Food Services',
        level: 4,
        parentCode: '72233',
        hasChildren: false,
    },
    {
        code: '7224',
        name: 'Drinking Places (Alcoholic Beverages)',
        level: 2,
        parentCode: '722',
        hasChildren: true,
    },
    {
        code: '72241',
        name: 'Drinking Places (Alcoholic Beverages)',
        level: 3,
        parentCode: '7224',
        hasChildren: true,
    },
    {
        code: '722410',
        name: 'Drinking Places (Alcoholic Beverages)',
        level: 4,
        parentCode: '72241',
        hasChildren: false,
    },
    {
        code: '7225',
        name: 'Restaurants and Other Eating Places',
        level: 2,
        parentCode: '722',
        hasChildren: true,
    },
    {
        code: '72251',
        name: 'Restaurants and Other Eating Places',
        level: 3,
        parentCode: '7225',
        hasChildren: true,
    },
    {
        code: '722511',
        name: 'Full-Service Restaurants',
        level: 4,
        parentCode: '72251',
        hasChildren: false,
    },
    {
        code: '722513',
        name: 'Limited-Service Restaurants',
        level: 4,
        parentCode: '72251',
        hasChildren: false,
    },
    {
        code: '722514',
        name: 'Cafeterias, Grill Buffets, and Buffets',
        level: 4,
        parentCode: '72251',
        hasChildren: false,
    },
    {
        code: '722515',
        name: 'Snack and Nonalcoholic Beverage Bars',
        level: 4,
        parentCode: '72251',
        hasChildren: false,
    },
    {
        code: '81',
        name: 'Other Services (except Public Administration)',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '811',
        name: 'Repair and Maintenance',
        level: 1,
        parentCode: '81',
        hasChildren: true,
    },
    {
        code: '8111',
        name: 'Automotive Repair and Maintenance',
        level: 2,
        parentCode: '811',
        hasChildren: true,
    },
    {
        code: '81111',
        name: 'Automotive Mechanical and Electrical Repair and Maintenance',
        level: 3,
        parentCode: '8111',
        hasChildren: true,
    },
    {
        code: '811111',
        name: 'General Automotive Repair',
        level: 4,
        parentCode: '81111',
        hasChildren: false,
    },
    {
        code: '811112',
        name: 'Automotive Exhaust System Repair',
        level: 4,
        parentCode: '81111',
        hasChildren: false,
    },
    {
        code: '811113',
        name: 'Automotive Transmission Repair',
        level: 4,
        parentCode: '81111',
        hasChildren: false,
    },
    {
        code: '811118',
        name: 'Other Automotive Mechanical and Electrical Repair and Maintenance',
        level: 4,
        parentCode: '81111',
        hasChildren: false,
    },
    {
        code: '81112',
        name: 'Automotive Body, Paint, Interior, and Glass Repair',
        level: 3,
        parentCode: '8111',
        hasChildren: true,
    },
    {
        code: '811121',
        name: 'Automotive Body, Paint, and Interior Repair and Maintenance',
        level: 4,
        parentCode: '81112',
        hasChildren: false,
    },
    {
        code: '811122',
        name: 'Automotive Glass Replacement Shops',
        level: 4,
        parentCode: '81112',
        hasChildren: false,
    },
    {
        code: '81119',
        name: 'Other Automotive Repair and Maintenance',
        level: 3,
        parentCode: '8111',
        hasChildren: true,
    },
    {
        code: '811191',
        name: 'Automotive Oil Change and Lubrication Shops',
        level: 4,
        parentCode: '81119',
        hasChildren: false,
    },
    {
        code: '811192',
        name: 'Car Washes',
        level: 4,
        parentCode: '81119',
        hasChildren: false,
    },
    {
        code: '811198',
        name: 'All Other Automotive Repair and Maintenance',
        level: 4,
        parentCode: '81119',
        hasChildren: false,
    },
    {
        code: '8112',
        name: 'Electronic and Precision Equipment Repair and Maintenance',
        level: 2,
        parentCode: '811',
        hasChildren: true,
    },
    {
        code: '81121',
        name: 'Electronic and Precision Equipment Repair and Maintenance',
        level: 3,
        parentCode: '8112',
        hasChildren: true,
    },
    {
        code: '811211',
        name: 'Consumer Electronics Repair and Maintenance',
        level: 4,
        parentCode: '81121',
        hasChildren: false,
    },
    {
        code: '811212',
        name: 'Computer and Office Machine Repair and Maintenance',
        level: 4,
        parentCode: '81121',
        hasChildren: false,
    },
    {
        code: '811213',
        name: 'Communication Equipment Repair and Maintenance',
        level: 4,
        parentCode: '81121',
        hasChildren: false,
    },
    {
        code: '811219',
        name: 'Other Electronic and Precision Equipment Repair and Maintenance',
        level: 4,
        parentCode: '81121',
        hasChildren: false,
    },
    {
        code: '8113',
        name: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
        level: 2,
        parentCode: '811',
        hasChildren: true,
    },
    {
        code: '81131',
        name: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
        level: 3,
        parentCode: '8113',
        hasChildren: true,
    },
    {
        code: '811310',
        name: 'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
        level: 4,
        parentCode: '81131',
        hasChildren: false,
    },
    {
        code: '8114',
        name: 'Personal and Household Goods Repair and Maintenance',
        level: 2,
        parentCode: '811',
        hasChildren: true,
    },
    {
        code: '81141',
        name: 'Home and Garden Equipment and Appliance Repair and Maintenance',
        level: 3,
        parentCode: '8114',
        hasChildren: true,
    },
    {
        code: '811411',
        name: 'Home and Garden Equipment Repair and Maintenance',
        level: 4,
        parentCode: '81141',
        hasChildren: false,
    },
    {
        code: '811412',
        name: 'Appliance Repair and Maintenance',
        level: 4,
        parentCode: '81141',
        hasChildren: false,
    },
    {
        code: '81142',
        name: 'Reupholstery and Furniture Repair',
        level: 3,
        parentCode: '8114',
        hasChildren: true,
    },
    {
        code: '811420',
        name: 'Reupholstery and Furniture Repair',
        level: 4,
        parentCode: '81142',
        hasChildren: false,
    },
    {
        code: '81143',
        name: 'Footwear and Leather Goods Repair',
        level: 3,
        parentCode: '8114',
        hasChildren: true,
    },
    {
        code: '811430',
        name: 'Footwear and Leather Goods Repair',
        level: 4,
        parentCode: '81143',
        hasChildren: false,
    },
    {
        code: '81149',
        name: 'Other Personal and Household Goods Repair and Maintenance',
        level: 3,
        parentCode: '8114',
        hasChildren: true,
    },
    {
        code: '811490',
        name: 'Other Personal and Household Goods Repair and Maintenance',
        level: 4,
        parentCode: '81149',
        hasChildren: false,
    },
    {
        code: '812',
        name: 'Personal and Laundry Services',
        level: 1,
        parentCode: '81',
        hasChildren: true,
    },
    {
        code: '8121',
        name: 'Personal Care Services',
        level: 2,
        parentCode: '812',
        hasChildren: true,
    },
    {
        code: '81211',
        name: 'Hair, Nail, and Skin Care Services',
        level: 3,
        parentCode: '8121',
        hasChildren: true,
    },
    {
        code: '812111',
        name: 'Barber Shops',
        level: 4,
        parentCode: '81211',
        hasChildren: false,
    },
    {
        code: '812112',
        name: 'Beauty Salons',
        level: 4,
        parentCode: '81211',
        hasChildren: false,
    },
    {
        code: '812113',
        name: 'Nail Salons',
        level: 4,
        parentCode: '81211',
        hasChildren: false,
    },
    {
        code: '81219',
        name: 'Other Personal Care Services',
        level: 3,
        parentCode: '8121',
        hasChildren: true,
    },
    {
        code: '812191',
        name: 'Diet and Weight Reducing Centers',
        level: 4,
        parentCode: '81219',
        hasChildren: false,
    },
    {
        code: '812199',
        name: 'Other Personal Care Services',
        level: 4,
        parentCode: '81219',
        hasChildren: false,
    },
    {
        code: '8122',
        name: 'Death Care Services',
        level: 2,
        parentCode: '812',
        hasChildren: true,
    },
    {
        code: '81221',
        name: 'Funeral Homes and Funeral Services',
        level: 3,
        parentCode: '8122',
        hasChildren: true,
    },
    {
        code: '812210',
        name: 'Funeral Homes and Funeral Services',
        level: 4,
        parentCode: '81221',
        hasChildren: false,
    },
    {
        code: '81222',
        name: 'Cemeteries and Crematories',
        level: 3,
        parentCode: '8122',
        hasChildren: true,
    },
    {
        code: '812220',
        name: 'Cemeteries and Crematories',
        level: 4,
        parentCode: '81222',
        hasChildren: false,
    },
    {
        code: '8123',
        name: 'Drycleaning and Laundry Services',
        level: 2,
        parentCode: '812',
        hasChildren: true,
    },
    {
        code: '81231',
        name: 'Coin-Operated Laundries and Drycleaners',
        level: 3,
        parentCode: '8123',
        hasChildren: true,
    },
    {
        code: '812310',
        name: 'Coin-Operated Laundries and Drycleaners',
        level: 4,
        parentCode: '81231',
        hasChildren: false,
    },
    {
        code: '81232',
        name: 'Drycleaning and Laundry Services (except Coin-Operated)',
        level: 3,
        parentCode: '8123',
        hasChildren: true,
    },
    {
        code: '812320',
        name: 'Drycleaning and Laundry Services (except Coin-Operated)',
        level: 4,
        parentCode: '81232',
        hasChildren: false,
    },
    {
        code: '81233',
        name: 'Linen and Uniform Supply',
        level: 3,
        parentCode: '8123',
        hasChildren: true,
    },
    {
        code: '812331',
        name: 'Linen Supply',
        level: 4,
        parentCode: '81233',
        hasChildren: false,
    },
    {
        code: '812332',
        name: 'Industrial Launderers',
        level: 4,
        parentCode: '81233',
        hasChildren: false,
    },
    {
        code: '8129',
        name: 'Other Personal Services',
        level: 2,
        parentCode: '812',
        hasChildren: true,
    },
    {
        code: '81291',
        name: 'Pet Care (except Veterinary) Services',
        level: 3,
        parentCode: '8129',
        hasChildren: true,
    },
    {
        code: '812910',
        name: 'Pet Care (except Veterinary) Services',
        level: 4,
        parentCode: '81291',
        hasChildren: false,
    },
    {
        code: '81292',
        name: 'Photofinishing',
        level: 3,
        parentCode: '8129',
        hasChildren: true,
    },
    {
        code: '812921',
        name: 'Photofinishing Laboratories (except One-Hour)',
        level: 4,
        parentCode: '81292',
        hasChildren: false,
    },
    {
        code: '812922',
        name: 'One-Hour Photofinishing',
        level: 4,
        parentCode: '81292',
        hasChildren: false,
    },
    {
        code: '81293',
        name: 'Parking Lots and Garages',
        level: 3,
        parentCode: '8129',
        hasChildren: true,
    },
    {
        code: '812930',
        name: 'Parking Lots and Garages',
        level: 4,
        parentCode: '81293',
        hasChildren: false,
    },
    {
        code: '81299',
        name: 'All Other Personal Services',
        level: 3,
        parentCode: '8129',
        hasChildren: true,
    },
    {
        code: '812990',
        name: 'All Other Personal Services',
        level: 4,
        parentCode: '81299',
        hasChildren: false,
    },
    {
        code: '813',
        name: 'Religious, Grantmaking, Civic, Professional, and Similar Organizations',
        level: 1,
        parentCode: '81',
        hasChildren: true,
    },
    {
        code: '8131',
        name: 'Religious Organizations',
        level: 2,
        parentCode: '813',
        hasChildren: true,
    },
    {
        code: '81311',
        name: 'Religious Organizations',
        level: 3,
        parentCode: '8131',
        hasChildren: true,
    },
    {
        code: '813110',
        name: 'Religious Organizations',
        level: 4,
        parentCode: '81311',
        hasChildren: false,
    },
    {
        code: '8132',
        name: 'Grantmaking and Giving Services',
        level: 2,
        parentCode: '813',
        hasChildren: true,
    },
    {
        code: '81321',
        name: 'Grantmaking and Giving Services',
        level: 3,
        parentCode: '8132',
        hasChildren: true,
    },
    {
        code: '813211',
        name: 'Grantmaking Foundations',
        level: 4,
        parentCode: '81321',
        hasChildren: false,
    },
    {
        code: '813212',
        name: 'Voluntary Health Organizations',
        level: 4,
        parentCode: '81321',
        hasChildren: false,
    },
    {
        code: '813219',
        name: 'Other Grantmaking and Giving Services',
        level: 4,
        parentCode: '81321',
        hasChildren: false,
    },
    {
        code: '8133',
        name: 'Social Advocacy Organizations',
        level: 2,
        parentCode: '813',
        hasChildren: true,
    },
    {
        code: '81331',
        name: 'Social Advocacy Organizations',
        level: 3,
        parentCode: '8133',
        hasChildren: true,
    },
    {
        code: '813311',
        name: 'Human Rights Organizations',
        level: 4,
        parentCode: '81331',
        hasChildren: false,
    },
    {
        code: '813312',
        name: 'Environment, Conservation and Wildlife Organizations',
        level: 4,
        parentCode: '81331',
        hasChildren: false,
    },
    {
        code: '813319',
        name: 'Other Social Advocacy Organizations',
        level: 4,
        parentCode: '81331',
        hasChildren: false,
    },
    {
        code: '8134',
        name: 'Civic and Social Organizations',
        level: 2,
        parentCode: '813',
        hasChildren: true,
    },
    {
        code: '81341',
        name: 'Civic and Social Organizations',
        level: 3,
        parentCode: '8134',
        hasChildren: true,
    },
    {
        code: '813410',
        name: 'Civic and Social Organizations',
        level: 4,
        parentCode: '81341',
        hasChildren: false,
    },
    {
        code: '8139',
        name: 'Business, Professional, Labor, Political, and Similar Organizations',
        level: 2,
        parentCode: '813',
        hasChildren: true,
    },
    {
        code: '81391',
        name: 'Business Associations',
        level: 3,
        parentCode: '8139',
        hasChildren: true,
    },
    {
        code: '813910',
        name: 'Business Associations',
        level: 4,
        parentCode: '81391',
        hasChildren: false,
    },
    {
        code: '81392',
        name: 'Professional Organizations',
        level: 3,
        parentCode: '8139',
        hasChildren: true,
    },
    {
        code: '813920',
        name: 'Professional Organizations',
        level: 4,
        parentCode: '81392',
        hasChildren: false,
    },
    {
        code: '81393',
        name: 'Labor Unions and Similar Labor Organizations',
        level: 3,
        parentCode: '8139',
        hasChildren: true,
    },
    {
        code: '813930',
        name: 'Labor Unions and Similar Labor Organizations',
        level: 4,
        parentCode: '81393',
        hasChildren: false,
    },
    {
        code: '81394',
        name: 'Political Organizations',
        level: 3,
        parentCode: '8139',
        hasChildren: true,
    },
    {
        code: '813940',
        name: 'Political Organizations',
        level: 4,
        parentCode: '81394',
        hasChildren: false,
    },
    {
        code: '81399',
        name: 'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)',
        level: 3,
        parentCode: '8139',
        hasChildren: true,
    },
    {
        code: '813990',
        name: 'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)',
        level: 4,
        parentCode: '81399',
        hasChildren: false,
    },
    {
        code: '814',
        name: 'Private Households',
        level: 1,
        parentCode: '81',
        hasChildren: true,
    },
    {
        code: '8141',
        name: 'Private Households',
        level: 2,
        parentCode: '814',
        hasChildren: true,
    },
    {
        code: '81411',
        name: 'Private Households',
        level: 3,
        parentCode: '8141',
        hasChildren: true,
    },
    {
        code: '814110',
        name: 'Private Households',
        level: 4,
        parentCode: '81411',
        hasChildren: false,
    },
    {
        code: '92',
        name: 'Public Administration',
        level: 0,
        parentCode: null,
        hasChildren: true,
    },
    {
        code: '921',
        name: 'Executive, Legislative, and Other General Government Support',
        level: 1,
        parentCode: '92',
        hasChildren: true,
    },
    {
        code: '9211',
        name: 'Executive, Legislative, and Other General Government Support',
        level: 2,
        parentCode: '921',
        hasChildren: true,
    },
    {
        code: '92111',
        name: 'Executive Offices',
        level: 3,
        parentCode: '9211',
        hasChildren: true,
    },
    {
        code: '921110',
        name: 'Executive Offices',
        level: 4,
        parentCode: '92111',
        hasChildren: false,
    },
    {
        code: '92112',
        name: 'Legislative Bodies',
        level: 3,
        parentCode: '9211',
        hasChildren: true,
    },
    {
        code: '921120',
        name: 'Legislative Bodies',
        level: 4,
        parentCode: '92112',
        hasChildren: false,
    },
    {
        code: '92113',
        name: 'Public Finance Activities',
        level: 3,
        parentCode: '9211',
        hasChildren: true,
    },
    {
        code: '921130',
        name: 'Public Finance Activities',
        level: 4,
        parentCode: '92113',
        hasChildren: false,
    },
    {
        code: '92114',
        name: 'Executive and Legislative Offices, Combined',
        level: 3,
        parentCode: '9211',
        hasChildren: true,
    },
    {
        code: '921140',
        name: 'Executive and Legislative Offices, Combined',
        level: 4,
        parentCode: '92114',
        hasChildren: false,
    },
    {
        code: '92115',
        name: 'American Indian and Alaska Native Tribal Governments',
        level: 3,
        parentCode: '9211',
        hasChildren: true,
    },
    {
        code: '921150',
        name: 'American Indian and Alaska Native Tribal Governments',
        level: 4,
        parentCode: '92115',
        hasChildren: false,
    },
    {
        code: '92119',
        name: 'Other General Government Support',
        level: 3,
        parentCode: '9211',
        hasChildren: true,
    },
    {
        code: '921190',
        name: 'Other General Government Support',
        level: 4,
        parentCode: '92119',
        hasChildren: false,
    },
    {
        code: '922',
        name: 'Justice, Public Order, and Safety Activities',
        level: 1,
        parentCode: '92',
        hasChildren: true,
    },
    {
        code: '9221',
        name: 'Justice, Public Order, and Safety Activities',
        level: 2,
        parentCode: '922',
        hasChildren: true,
    },
    {
        code: '92211',
        name: 'Courts',
        level: 3,
        parentCode: '9221',
        hasChildren: true,
    },
    {
        code: '922110',
        name: 'Courts',
        level: 4,
        parentCode: '92211',
        hasChildren: false,
    },
    {
        code: '92212',
        name: 'Police Protection',
        level: 3,
        parentCode: '9221',
        hasChildren: true,
    },
    {
        code: '922120',
        name: 'Police Protection',
        level: 4,
        parentCode: '92212',
        hasChildren: false,
    },
    {
        code: '92213',
        name: 'Legal Counsel and Prosecution',
        level: 3,
        parentCode: '9221',
        hasChildren: true,
    },
    {
        code: '922130',
        name: 'Legal Counsel and Prosecution',
        level: 4,
        parentCode: '92213',
        hasChildren: false,
    },
    {
        code: '92214',
        name: 'Correctional Institutions',
        level: 3,
        parentCode: '9221',
        hasChildren: true,
    },
    {
        code: '922140',
        name: 'Correctional Institutions',
        level: 4,
        parentCode: '92214',
        hasChildren: false,
    },
    {
        code: '92215',
        name: 'Parole Offices and Probation Offices',
        level: 3,
        parentCode: '9221',
        hasChildren: true,
    },
    {
        code: '922150',
        name: 'Parole Offices and Probation Offices',
        level: 4,
        parentCode: '92215',
        hasChildren: false,
    },
    {
        code: '92216',
        name: 'Fire Protection',
        level: 3,
        parentCode: '9221',
        hasChildren: true,
    },
    {
        code: '922160',
        name: 'Fire Protection',
        level: 4,
        parentCode: '92216',
        hasChildren: false,
    },
    {
        code: '92219',
        name: 'Other Justice, Public Order, and Safety Activities',
        level: 3,
        parentCode: '9221',
        hasChildren: true,
    },
    {
        code: '922190',
        name: 'Other Justice, Public Order, and Safety Activities',
        level: 4,
        parentCode: '92219',
        hasChildren: false,
    },
    {
        code: '923',
        name: 'Administration of Human Resource Programs',
        level: 1,
        parentCode: '92',
        hasChildren: true,
    },
    {
        code: '9231',
        name: 'Administration of Human Resource Programs',
        level: 2,
        parentCode: '923',
        hasChildren: true,
    },
    {
        code: '92311',
        name: 'Administration of Education Programs',
        level: 3,
        parentCode: '9231',
        hasChildren: true,
    },
    {
        code: '923110',
        name: 'Administration of Education Programs',
        level: 4,
        parentCode: '92311',
        hasChildren: false,
    },
    {
        code: '92312',
        name: 'Administration of Public Health Programs',
        level: 3,
        parentCode: '9231',
        hasChildren: true,
    },
    {
        code: '923120',
        name: 'Administration of Public Health Programs',
        level: 4,
        parentCode: '92312',
        hasChildren: false,
    },
    {
        code: '92313',
        name: "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs) ",
        level: 3,
        parentCode: '9231',
        hasChildren: true,
    },
    {
        code: '923130',
        name: "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs) ",
        level: 4,
        parentCode: '92313',
        hasChildren: false,
    },
    {
        code: '92314',
        name: "Administration of Veterans' Affairs ",
        level: 3,
        parentCode: '9231',
        hasChildren: true,
    },
    {
        code: '923140',
        name: "Administration of Veterans' Affairs ",
        level: 4,
        parentCode: '92314',
        hasChildren: false,
    },
    {
        code: '924',
        name: 'Administration of Environmental Quality Programs',
        level: 1,
        parentCode: '92',
        hasChildren: true,
    },
    {
        code: '9241',
        name: 'Administration of Environmental Quality Programs',
        level: 2,
        parentCode: '924',
        hasChildren: true,
    },
    {
        code: '92411',
        name: 'Administration of Air and Water Resource and Solid Waste Management Programs',
        level: 3,
        parentCode: '9241',
        hasChildren: true,
    },
    {
        code: '924110',
        name: 'Administration of Air and Water Resource and Solid Waste Management Programs',
        level: 4,
        parentCode: '92411',
        hasChildren: false,
    },
    {
        code: '92412',
        name: 'Administration of Conservation Programs',
        level: 3,
        parentCode: '9241',
        hasChildren: true,
    },
    {
        code: '924120',
        name: 'Administration of Conservation Programs',
        level: 4,
        parentCode: '92412',
        hasChildren: false,
    },
    {
        code: '925',
        name: 'Administration of Housing Programs, Urban Planning, and Community Development',
        level: 1,
        parentCode: '92',
        hasChildren: true,
    },
    {
        code: '9251',
        name: 'Administration of Housing Programs, Urban Planning, and Community Development',
        level: 2,
        parentCode: '925',
        hasChildren: true,
    },
    {
        code: '92511',
        name: 'Administration of Housing Programs',
        level: 3,
        parentCode: '9251',
        hasChildren: true,
    },
    {
        code: '925110',
        name: 'Administration of Housing Programs',
        level: 4,
        parentCode: '92511',
        hasChildren: false,
    },
    {
        code: '92512',
        name: 'Administration of Urban Planning and Community and Rural Development',
        level: 3,
        parentCode: '9251',
        hasChildren: true,
    },
    {
        code: '925120',
        name: 'Administration of Urban Planning and Community and Rural Development',
        level: 4,
        parentCode: '92512',
        hasChildren: false,
    },
    {
        code: '926',
        name: 'Administration of Economic Programs',
        level: 1,
        parentCode: '92',
        hasChildren: true,
    },
    {
        code: '9261',
        name: 'Administration of Economic Programs',
        level: 2,
        parentCode: '926',
        hasChildren: true,
    },
    {
        code: '92611',
        name: 'Administration of General Economic Programs',
        level: 3,
        parentCode: '9261',
        hasChildren: true,
    },
    {
        code: '926110',
        name: 'Administration of General Economic Programs',
        level: 4,
        parentCode: '92611',
        hasChildren: false,
    },
    {
        code: '92612',
        name: 'Regulation and Administration of Transportation Programs',
        level: 3,
        parentCode: '9261',
        hasChildren: true,
    },
    {
        code: '926120',
        name: 'Regulation and Administration of Transportation Programs',
        level: 4,
        parentCode: '92612',
        hasChildren: false,
    },
    {
        code: '92613',
        name: 'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
        level: 3,
        parentCode: '9261',
        hasChildren: true,
    },
    {
        code: '926130',
        name: 'Regulation and Administration of Communications, Electric, Gas, and Other Utilities',
        level: 4,
        parentCode: '92613',
        hasChildren: false,
    },
    {
        code: '92614',
        name: 'Regulation of Agricultural Marketing and Commodities',
        level: 3,
        parentCode: '9261',
        hasChildren: true,
    },
    {
        code: '926140',
        name: 'Regulation of Agricultural Marketing and Commodities',
        level: 4,
        parentCode: '92614',
        hasChildren: false,
    },
    {
        code: '92615',
        name: 'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
        level: 3,
        parentCode: '9261',
        hasChildren: true,
    },
    {
        code: '926150',
        name: 'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors',
        level: 4,
        parentCode: '92615',
        hasChildren: false,
    },
    {
        code: '927',
        name: 'Space Research and Technology',
        level: 1,
        parentCode: '92',
        hasChildren: true,
    },
    {
        code: '9271',
        name: 'Space Research and Technology',
        level: 2,
        parentCode: '927',
        hasChildren: true,
    },
    {
        code: '92711',
        name: 'Space Research and Technology',
        level: 3,
        parentCode: '9271',
        hasChildren: true,
    },
    {
        code: '927110',
        name: 'Space Research and Technology',
        level: 4,
        parentCode: '92711',
        hasChildren: false,
    },
    {
        code: '928',
        name: 'National Security and International Affairs',
        level: 1,
        parentCode: '92',
        hasChildren: true,
    },
    {
        code: '9281',
        name: 'National Security and International Affairs',
        level: 2,
        parentCode: '928',
        hasChildren: true,
    },
    {
        code: '92811',
        name: 'National Security',
        level: 3,
        parentCode: '9281',
        hasChildren: true,
    },
    {
        code: '928110',
        name: 'National Security',
        level: 4,
        parentCode: '92811',
        hasChildren: false,
    },
    {
        code: '92812',
        name: 'International Affairs',
        level: 3,
        parentCode: '9281',
        hasChildren: true,
    },
    {
        code: '928120',
        name: 'International Affairs',
        level: 4,
        parentCode: '92812',
        hasChildren: false,
    },
];
