import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useLazyGetRmmMessageQuery, useGetJobDescriptionsQuery } from 'services/jobDescription';
import { simulateTextStream } from 'views/JDBuilder/JDBuilderOutput/utils/simulateTextStream';
import { useDispatch } from 'react-redux';
import {
    changeTemplateBody,
    changeTemplateSubject,
    setIsUsingRmmMessage,
    changeTemplateName,
} from '../MessageTemplateSlice';
import { useForcedFlow } from 'features/ForcedFlow';
import { getTemplateFromRmm } from '../utils/messageTemplateHelpers';

const INIT_VALUE = {
    id: '',
    name: '',
};

export function useRmmTemplate(props) {
    const {
        open,
        onTemplateCreateView,
        onHandleClose,
        jobDescriptionName,
        jobDescriptionSelectedId,
        openDrawer,
    } = props;

    const dispatch = useDispatch();

    const requestRef = useRef(null);
    const cancelTextStreamRef = useRef(() => {});
    const [isGenerating, setIsGenerating] = useState(false);
    const [isTextStreamComplete, setIsTextStreamComplete] = useState(false);
    const [active, setActive] = useState(INIT_VALUE);
    const [target, setTarget] = useState(INIT_VALUE);
    const [jobDescription, setJobDescription] = useState(null);
    const { data: jobDescriptions, isLoading } = useGetJobDescriptionsQuery(null, {
        skip: !open,
    });
    const [getRmmMessage, { isLoading: isLoadingRmmMessage }] = useLazyGetRmmMessageQuery();
    const { isForcedFlow, handleForcedFlowNext } = useForcedFlow();

    const processRmmMessage = useCallback(
        async ({ id, name }) => {
            setActive({ id, name });
            setIsGenerating(true);
            setIsTextStreamComplete(false);
            dispatch(changeTemplateName(name));
            requestRef.current?.abort?.();
            requestRef.current = getRmmMessage({ params: { jd: id } }, false);
            const res = await requestRef.current;
            if (res.data) {
                setJobDescription(null);
                setActive(INIT_VALUE);
                setTarget(INIT_VALUE);
                setIsGenerating(false);
                const { subject, body } = getTemplateFromRmm(res.data);

                dispatch(changeTemplateSubject(subject));
                if (isForcedFlow) {
                    handleForcedFlowNext();
                }
                const handleTextStreamFinished = () => {
                    setIsGenerating(false);
                    dispatch(changeTemplateBody(body));
                    setIsTextStreamComplete(true);
                };
                const cancelTextStream = simulateTextStream(
                    body,
                    (text) => {
                        dispatch(changeTemplateBody(text));
                    },
                    handleTextStreamFinished
                );
                cancelTextStreamRef.current = cancelTextStream;
                dispatch(setIsUsingRmmMessage(true));
                onHandleClose();
            }
        },
        [dispatch, getRmmMessage, onHandleClose, isForcedFlow, handleForcedFlowNext]
    );

    const handleJdClick = useCallback(
        ({ id, name }) => {
            // cancel any current text stream if any
            cancelTextStreamRef.current?.();
            setTarget({ id, name });
            onTemplateCreateView();
            processRmmMessage({ id, name });
        },
        [onTemplateCreateView, processRmmMessage]
    );

    useEffect(() => {
        const eventHandler = (event) => {
            if (event.key === 'Enter') {
                cancelTextStreamRef.current?.({ finish: true });
            }
        };
        if (isGenerating && !isLoadingRmmMessage) {
            document.addEventListener('keydown', eventHandler);
        }
        return () => {
            document.removeEventListener('keydown', eventHandler);
        };
    }, [isGenerating, isLoadingRmmMessage]);

    useEffect(() => {
        // cancel text streaming on unmount
        return () => {
            setIsGenerating(false);
            cancelTextStreamRef.current({ finish: false });
        };
    }, []);

    useEffect(() => {
        if (jobDescriptionSelectedId && jobDescriptionName && openDrawer) {
            setJobDescription({ id: jobDescriptionSelectedId, name: jobDescriptionName });
        }
    }, [openDrawer, jobDescriptionSelectedId, jobDescriptionName]);

    useEffect(() => {
        if (jobDescription?.id) {
            handleJdClick(jobDescription);
        }
    }, [handleJdClick, jobDescription]);

    return useMemo(
        () => ({
            processRmmMessage,
            isLoading,
            jobDescriptions,
            handleJdClick,
            active,
            target,
            isGenerating,
            isTextStreamComplete,
        }),
        [
            active,
            handleJdClick,
            isLoading,
            jobDescriptions,
            processRmmMessage,
            target,
            isGenerating,
            isTextStreamComplete,
        ]
    );
}
