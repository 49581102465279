import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

const BASE_URL = '/api/aggregator/v1/';

export const reportsApi = createApi({
    reducerPath: 'reportsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getTableauJwt: builder.query({
            query: () => ({
                url: 'metrics/auth',
            }),
        }),
    }),
});

export const { useGetTableauJwtQuery } = reportsApi;
