import { useMemo, useState } from 'react';
import { useNovaEnv } from 'features/Switchboard';
import { NOTES_ENV_URLS } from 'utils/constants';
import { useCustomQuery } from 'hooks/useCustomQuery';
import { getAllNotesApi, getNotesCountApi } from 'features/TalentNotes/services';
import { toast } from 'react-toastify';
import { sortArrayItemsByDate } from 'utils/helpers';
import { useNotesStore } from 'features/TalentNotes/store/useNotesStore';
import { filterNotesByCategory } from 'features/TalentNotes/utils/helpers';
import { TALENT_DRAWER_TABS } from 'utils/constants';

export function useTalentNotesInfo(props) {
    const { shouldFetchData, currentTab, currentTalentId } = props;

    const { name: envName } = useNovaEnv();
    const envUrl = NOTES_ENV_URLS[envName];

    const { isAscMode, setIsAscMode, setFilter, filter } = useNotesStore((state) => state);
    const [notes, setNotes] = useState([]);

    const shouldFetchAllNotes = currentTab === TALENT_DRAWER_TABS.NOTES_TAB && shouldFetchData;

    const { data: talentNotesCountObj } = useCustomQuery(
        [
            'talent-notes-count',
            {
                entityIds: [{ entity_id: currentTalentId }],
                envUrl,
            },
        ],
        getNotesCountApi,
        {
            refetchOnWindowFocus: false,
            disabled: !!shouldFetchData,
            onError: () => {
                toast.error('There was an error fetching Notes');
            },
        }
    );

    const { isLoading, isFetching } = useCustomQuery(
        ['talent-notes', { talentId: currentTalentId, envUrl }],
        getAllNotesApi,
        {
            refetchOnWindowFocus: false,
            disabled: !!shouldFetchAllNotes,
            retry: 1,
            onSuccess: (data) => {
                const sorted = sortArrayItemsByDate(isAscMode, data);
                setNotes(sorted);
            },
            onError: () => {
                toast.error('There was an error fetching Notes');
            },
        }
    );
    const newNotes = useMemo(() => {
        const sortedNotes = sortArrayItemsByDate(isAscMode, notes);
        const filtered = filterNotesByCategory(filter, sortedNotes);
        return filtered;
    }, [filter, isAscMode, notes]);

    const handleOptimisticDelete = (noteId) => {
        const updatedNotes = notes.filter((note) => note._id !== noteId);
        setNotes(updatedNotes);
    };

    return {
        handleOptimisticDelete,
        newNotes,
        isLoading,
        isFetching,
        talentNotesCountObj,
        setFilter,
        setIsAscMode,
        setNotes,
    };
}
