import { extractMentions, extractUserIds } from './extractMentions';

export const convertTextToTextWithMentionTags = (text, _mentions) => {
    const mentions = extractMentions(extractUserIds(text), _mentions);
    let mentionIndex = 0;
    return text.replace(/{{(.*?)}}/g, (match) => {
        if (mentions[mentionIndex]) {
            return `<span style="color:#3A8DD3;font-weight:700">${
                mentions[mentionIndex++].name
            }</span>`;
        } else {
            return match;
        }
    });
};
