import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';

import {
    conversationsBaseUrl,
    useCompleteGenerateMessageMutation,
    useGenerateMessageMutation,
} from 'services/conversations';
import { MESSAGE_LENGTH_OPTIONS, MESSAGE_SOURCE_OPTIONS, TARGET_ENUMS } from '../utils';
import {
    setHasGeneratedMessage,
    setInitialBody,
    setIsGeneratingMessage,
    setIsPreparingStream,
    setIsMessageGenerationCollapsed,
    applyMessageGenerationOptions,
} from '../messageSlice';
import { debounce } from 'lodash';

export function useStreamMessage() {
    const { selectedProfiles, messageGenerationOptions } = useSelector((state) => state.message);
    const { source, sourceId, length } = messageGenerationOptions;

    const dispatch = useDispatch();

    const eventSource = useRef(null);

    const [generateMessage, { isLoading: isGenerateLoading }] = useGenerateMessageMutation();
    const [completeMessageGeneration] = useCompleteGenerateMessageMutation();

    const handleUpdateBody = useCallback(
        (value) => {
            dispatch(
                setInitialBody({
                    target: TARGET_ENUMS.BODY,
                    initialBody: value,
                })
            );
        },
        [dispatch]
    );

    const debouncedHandleUpdateBody = debounce(handleUpdateBody, 15);

    const handleSuccessResponse = useCallback(
        (response) => {
            const { data: generateData } = response;

            const handleTextStreamFinished = (_data) => {
                eventSource.current?.close?.();
                dispatch(setIsGeneratingMessage(false));
                dispatch(setHasGeneratedMessage(true));
                debouncedHandleUpdateBody(_data);
                toast.success('Message successfully generated');
            };

            const revertChangesOnError = () => {
                eventSource.current?.close?.();
                debouncedHandleUpdateBody('');
                dispatch(setIsGeneratingMessage(false));
                toast.error('Something went wrong generating your message. Please try again.');
            };

            eventSource.current = new EventSource(
                `${conversationsBaseUrl}/generate/stream/begin?promptId=${generateData?.promptId}`,
                {
                    withCredentials: true,
                }
            );

            let streamingMessage = '';
            let messageBuffer = '';

            eventSource.current.onmessage = async function (event) {
                const eventData = JSON.parse(event.data);

                dispatch(setIsPreparingStream(false));

                if (eventData.type === 'message') {
                    streamingMessage += eventData.data;
                    messageBuffer += eventData.data;

                    // prevent updating too quickly
                    if (messageBuffer.length > 32) {
                        debouncedHandleUpdateBody(streamingMessage);
                        messageBuffer = '';
                    }
                } else if (eventData.type === 'output') {
                    const _response = await completeMessageGeneration({
                        params: { promptId: generateData?.promptId },
                    });
                    if (_response.error || _response.isError) {
                        revertChangesOnError();
                    } else {
                        handleTextStreamFinished(eventData.data);
                    }
                } else if (eventData.type === 'error') {
                    revertChangesOnError();
                }
            };
        },
        [completeMessageGeneration, debouncedHandleUpdateBody, dispatch]
    );

    const handleGenerateMessage = useCallback(
        async (sourceOptions) => {
            if (!isEmpty(sourceOptions)) {
                dispatch(applyMessageGenerationOptions(sourceOptions));
            }

            const _source = sourceOptions?.source || source;
            const _sourceId = sourceOptions?.sourceId || sourceId;
            const _length = sourceOptions?.length || length || MESSAGE_LENGTH_OPTIONS.medium.value;

            const handleFailureResponse = (response) => {
                toast.error(
                    response.error?.data?.message ||
                        'There was an error generating your message. Please try again in a few minutes.'
                );
                dispatch(setIsGeneratingMessage(false));
                dispatch(setIsPreparingStream(false));
            };

            debouncedHandleUpdateBody('');

            dispatch(setIsMessageGenerationCollapsed(false));
            dispatch(setIsGeneratingMessage(true));
            dispatch(setIsPreparingStream(true));

            const params = {
                context: _source,
                body: {
                    talentId: selectedProfiles[0].talentId,
                    length: _length,
                },
            };

            if (_sourceId) {
                params.body[MESSAGE_SOURCE_OPTIONS[_source]?.queryParam] = _sourceId;
            }

            const response = await generateMessage(params);

            if (response.error) {
                handleFailureResponse(response);
            } else {
                handleSuccessResponse(response);
            }
        },
        [
            selectedProfiles,
            source,
            sourceId,
            length,
            generateMessage,
            debouncedHandleUpdateBody,
            handleSuccessResponse,
            dispatch,
        ]
    );

    useEffect(() => {
        return () => {
            eventSource.current?.close?.();
            dispatch(setIsGeneratingMessage(false));
        };
    }, [dispatch]);

    return {
        isGenerateLoading,
        handleGenerateMessage,
    };
}
