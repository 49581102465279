import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/system/Box';

import { useSelector } from 'react-redux';

import { Typography } from 'components/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import { useSwitchboard } from 'features/Switchboard';
import { LocationIcon } from 'components/Icons';
import { NotesButton } from 'features/TalentNotes';
import { ContactDetails } from 'features/TalentCard/ContactDetails';
import { ShortlistButton } from 'features/TalentCard/TalentSearchActions/ShortlistButton';
import {
    getTalentCardHeader,
    getTalentLocationString,
    getTalentName,
} from 'utils/formatters/talentFormatting';
import { useLazyGetContactMetadataQuery } from 'services/contacts';
import { AddToProjectButton } from 'features/TalentCard/TalentSearchActions/AddToProjectButton';
import { ActionsDropdownButton } from 'components/ActionsMenu';
import { useTalentProfileDrawer } from 'features/TalentProfileDrawer/hooks/useTalentProfileDrawer';
import { IconButton, Link } from '@mui/material';
import { NOTES_SOURCES } from 'features/TalentNotes/utils/constants';

export function ContactHeader(props) {
    const { talentData, isCompact } = props;

    const params = useParams();
    const { conversationId } = params;

    const { openTalentProfileDrawer } = useTalentProfileDrawer();

    const byeBias = useSelector((state) => state.byeBias.on);
    const isContactDetailsEnabled = useSwitchboard('FEATURE_NN-1390_CONTACT_DETAILS_UI');

    const [getContactMetadata, { data: contactMetadata, isFetching: metaDataIsFetching }] =
        useLazyGetContactMetadataQuery();
    const contactCounts = contactMetadata?.contactCounts;

    const title = getTalentCardHeader(talentData);
    const location = getTalentLocationString(talentData);

    const talentName = getTalentName(talentData, byeBias);
    const profileId = talentData?.profileId;
    const talentId = useMemo(() => {
        return talentData?.talentId;
    }, [talentData?.talentId]);

    useEffect(() => {
        if (talentId) {
            getContactMetadata([talentId]);
        }
    }, [talentId, getContactMetadata]);

    return (
        <Box
            sx={{
                width: { sm: '100%', xs: '100%', md: '70%' },
                display: 'flex',
                flexFlow: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                {talentData && (
                    <Typography variant="smallHeadline">
                        Conversation with &nbsp;
                        <span
                            data-testid="talent-profile-name"
                            data-trackid="conversation-header-name"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() =>
                                openTalentProfileDrawer(
                                    talentId,
                                    {
                                        notesSourceConfig: {
                                            source: NOTES_SOURCES.MESSAGE,
                                            sourceId: conversationId,
                                        },
                                    },
                                    undefined,
                                    profileId
                                )
                            }
                        >
                            {talentName}
                        </span>
                        <Link
                            href={`/talent/${talentId}`}
                            aria-label="talent-profile-link"
                            target="_blank"
                            data-testid="talent-profile-link"
                            data-trackid="conversation-header-new-tab"
                        >
                            <IconButton>
                                <LaunchIcon color="primary" fontSize="small" />
                            </IconButton>
                        </Link>
                    </Typography>
                )}
                {isContactDetailsEnabled && (
                    <Box sx={{ ml: 2 }}>
                        <ContactDetails
                            talent={{
                                ...talentData,
                                contactCounts: contactCounts?.[talentId],
                            }}
                            isLoading={metaDataIsFetching}
                            dataTrackIds={{
                                emails: 'conversation-header-emails',
                                phone: 'conversation-header-sms',
                                socials: 'conversation-header-socials',
                                lookup: '',
                            }}
                        />
                    </Box>
                )}
                {!isCompact && (
                    <Box
                        sx={{
                            height: '18px',
                            padding: '5px',
                            position: 'relative',
                            width: '75px',
                            marginLeft: '8px',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 6,
                                top: 7,
                            }}
                        >
                            <NotesButton
                                style={{ top: '4px' }}
                                talentData={{
                                    ...talentData,
                                    contactCounts: contactCounts?.[talentId],
                                }}
                                talentNotesCount={talentData?.notesCount || 0}
                                source={NOTES_SOURCES.MESSAGE}
                                sourceId={conversationId}
                                data-trackid="conversation-header-notes"
                            />
                        </Box>
                    </Box>
                )}
                <Box sx={{ marginLeft: isCompact ? 2 : 'inherit' }}>
                    <ActionsDropdownButton data-trackid="conversation-header-actions">
                        <ShortlistButton talentId={talentId} />
                        <AddToProjectButton talentId={talentId} profileId={profileId} />
                    </ActionsDropdownButton>
                </Box>
            </Box>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Box
                        sx={{
                            mr: 2,
                            maxWidth: 280,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <Typography variant="bodyBaseBold" noWrap>
                            {title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <LocationIcon
                            sx={{ fontSize: 20, color: 'text.primary', opacity: 0.32, mb: 0.652 }}
                        />
                        <Typography
                            variant="body"
                            noWrap
                            sx={{ color: 'text.primary', opacity: 0.32 }}
                        >
                            {location}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

ContactHeader.propTypes = {
    talentData: PropTypes.object,
    isCompact: PropTypes.bool,
};
