import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import {
    clearSearchSelections,
    clearShortlistSelections,
} from 'features/TalentCard/TalentBatchActions';
import { fluffApi } from 'services/fluff';
import { usersApi } from 'services/users';
import { watchApi } from 'services/watch';
import { resetSearch } from 'views/Search';
import { talentApi } from 'services/talent';
import { searchApi } from 'services/search';
import { contactsApi } from 'services/contacts';
import { feedbackApi } from 'services/feedback';
import { insightsApi } from 'services/insights';
import { activityApi } from 'services/activity';
import { resetJDBuilder } from 'views/JDBuilder';
import { schedulerApi } from 'services/scheduler';
import { resetSignature } from 'features/Signature';
import { switchboardApi } from 'services/switchboard';
import { compensationApi } from 'services/compensation';
import { systemEmailsApi } from 'services/systemEmails';
import { organizationsApi } from 'services/organizations';
import { conversationsApi } from 'services/conversations';
import { jobDescriptionApi } from 'services/jobDescription';
import { knowledgeBasesApi } from 'services/knowledgeBases';
import { clearContacts } from 'views/Communications/features';
import { clearCommunicationsState } from 'views/Communications';
import { clearDynamicFieldState } from 'features/DynamicFields';
import { messagingWorkflowApi } from 'services/messagingWorkflow';
import { resetEntireTemplateState } from 'features/MessageTemplate';
import { clearActivityState } from 'features/Activity/activitySlice';
import { resetMessageState } from 'features/MessageDrawer/messageSlice';
import { clearScheduling } from 'views/UserSettings/features/Scheduling';
import { clearState } from 'views/UserSettings/features/YourMedia/slices/mediaSlice';
import { resetModalData } from 'features/TalentCard/ContactDetails/contactDetailSlice';

export function useClearStateAndCache() {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    return () => {
        // redux store state
        dispatch(clearShortlistSelections());
        dispatch(clearSearchSelections());
        dispatch(clearState());
        dispatch(clearActivityState());
        dispatch(resetSearch());
        dispatch(clearScheduling());
        dispatch(resetJDBuilder());
        dispatch(clearCommunicationsState());
        dispatch(clearContacts());
        dispatch(resetEntireTemplateState());
        dispatch(clearDynamicFieldState());
        dispatch(resetSignature());
        dispatch(resetMessageState());
        dispatch(resetModalData());

        // rtk-query state
        dispatch(switchboardApi.util.invalidateTags(['AdminFeatureSwitches', 'FeatureSwitches']));
        dispatch(usersApi.util.resetApiState());
        dispatch(fluffApi.util.resetApiState());
        dispatch(talentApi.util.resetApiState());
        dispatch(searchApi.util.resetApiState());
        dispatch(organizationsApi.util.resetApiState());
        dispatch(compensationApi.util.resetApiState());
        dispatch(insightsApi.util.resetApiState());
        dispatch(schedulerApi.util.resetApiState());
        dispatch(conversationsApi.util.resetApiState());
        dispatch(contactsApi.util.resetApiState());
        dispatch(jobDescriptionApi.util.resetApiState());
        dispatch(systemEmailsApi.util.resetApiState());
        dispatch(knowledgeBasesApi.util.resetApiState());
        dispatch(feedbackApi.util.resetApiState());
        dispatch(watchApi.util.resetApiState());
        dispatch(messagingWorkflowApi.util.resetApiState());
        dispatch(activityApi.util.resetApiState());

        // react-query
        queryClient.clear();
    };
}
