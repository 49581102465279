import SvgIcon from '@mui/material/SvgIcon';

export function BuildingIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M19.7143 3H12C11.6591 3.00034 11.3323 3.13591 11.0912 3.37695C10.8502 3.618 10.7146 3.94483 10.7143 4.28571V10.7143H4.28571C3.94483 10.7146 3.618 10.8502 3.37695 11.0912C3.13591 11.3323 3.00034 11.6591 3 12V21H21V4.28571C20.9997 3.94483 20.8641 3.618 20.623 3.37695C20.382 3.13591 20.0552 3.00034 19.7143 3ZM7.5 19.7143V15.2143H10.0714V19.7143H7.5ZM19.7143 19.7143H11.3571V14.5714C11.3571 14.4009 11.2894 14.2374 11.1689 14.1169C11.0483 13.9963 10.8848 13.9286 10.7143 13.9286H6.85714C6.68665 13.9286 6.52313 13.9963 6.40257 14.1169C6.28202 14.2374 6.21429 14.4009 6.21429 14.5714V19.7143H4.28571V12H12V4.28571H19.7143V19.7143Z"
                fill="currentColor"
            />
            <path
                d="M13.2857 6.85714H14.5714V8.14286H13.2857V6.85714ZM17.1429 6.85714H18.4286V8.14286H17.1429V6.85714ZM13.2857 10.7143H14.5714V12H13.2857V10.7143ZM17.1429 10.7143H18.4286V12H17.1429V10.7143ZM13.2857 14.5714H14.5714V15.8571H13.2857V14.5714ZM17.1429 14.5714H18.4286V15.8571H17.1429V14.5714Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
