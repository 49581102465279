import { MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from 'components/Icons';
import { getValueByMode } from 'utils/copy';

export function NotesSelectDropDown({ setPrivacy, options, styles, sx }) {
    const renderCustomIcon = (props) => <ChevronDownIcon {...props} />;

    return (
        <Select
            inputProps={{ 'aria-label': 'filter by visibility' }}
            value=""
            onChange={(e) => setPrivacy(e.target.value)}
            IconComponent={renderCustomIcon}
            MenuProps={{
                PaperProps: {
                    sx: {
                        marginTop: getValueByMode(styles, styles?.mt, '-80px'),
                        '& .MuiMenuItem-root': {
                            fontSize: 13.5,
                        },
                    },
                },
            }}
            sx={{
                height: 18,
                width: getValueByMode(styles, styles?.width, 30),
                '.MuiOutlinedInput-input': {
                    paddingLeft: '0px !important',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '0px !important',
                },
                ...sx,
            }}
        >
            {options.map((option) => (
                <MenuItem
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                    aria-label={`privacy option ${option.label}`}
                    data-trackid={option?.trackId}
                >
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
}

NotesSelectDropDown.propTypes = {
    setPrivacy: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    styles: PropTypes.object,
    sx: PropTypes.object,
};
