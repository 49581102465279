import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const contactAdapter = createEntityAdapter({
    selectId: (talent) => talent.talentId,
});

export const contactSelectionSlice = createSlice({
    name: 'contactSelections',
    initialState: contactAdapter.getInitialState(),
    reducers: {
        setContacts: contactAdapter.setAll,
        addContact: contactAdapter.addOne,
        addManyContacts: contactAdapter.addMany,
        updateContact: contactAdapter.updateOne,
        updateContacts: contactAdapter.updateMany,
        removeContact: contactAdapter.removeOne,
        removeManyContacts: contactAdapter.removeMany,
        clearContacts: contactAdapter.removeAll,
    },
});

export const {
    setContacts,
    addContact,
    updateContact,
    removeContact,
    clearContacts,
    addManyContacts,
    removeManyContacts,
    updateContacts,
} = contactSelectionSlice.actions;

export const {
    selectAll: selectAllContacts,
    selectIds: selectContactIds,
    selectById: selectContactById,
    selectTotal: selectContactTotal,
} = contactAdapter.getSelectors((state) => state.contactSelections);

export const contactSelectionReducer = contactSelectionSlice.reducer;
