import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    SummaryActions,
    SummaryChip,
    SummaryAction,
    FilterDetails,
    FilterCaption,
    FilterAccordion,
    FilterSummary,
    FilterChipContainer,
} from './styledComponents';
import {
    clearFilters,
    removeStandardFilter,
    convertExcludeToInclude,
    convertIncludeToExclude,
    deleteStandardFilter,
    deleteStandardExcludeFilter,
} from 'views/Search/searchSlice';
import {
    getFilterLabel,
    selectStandardFilters,
    selectStandardExcludedFilters,
} from 'utils/filters/formatFilters';
import { deleteKeyword } from './utils';
import { FilterChip } from './FilterChip';
import { IncludedChip } from './IncludedChip';
import { ExcludedChip } from './ExcludedChip';
import { Typography } from 'components/Typography';
import { EXCLUDABLE_FILTERS, EXPERIENCE_FILTER } from './constants';
import { CloseIcon, ChevronDownIcon, CheckIcon, ExcludeIcon } from 'components/Icons';

const isAnExcludableFilter = (filterKey) => EXCLUDABLE_FILTERS.includes(filterKey);

export function ActiveFilters() {
    const dispatch = useDispatch();

    const standardFilters = useSelector((state) => selectStandardFilters(state.search.filters));
    const standardExcludedFilters = useSelector((state) =>
        selectStandardExcludedFilters(state.search.exclusionFilters)
    );
    const keywords = useSelector((state) => state.search.filters.keywords);

    const [expanded, setExpanded] = useState(false);

    const activeFiltersCount =
        keywords.length + standardFilters.length + standardExcludedFilters.length;

    const handleDeleteKeyword = (keyword) => deleteKeyword(keyword, keywords, dispatch);

    const handleClickInclude = (property, index) => {
        dispatch(convertExcludeToInclude({ property, index }));
    };

    const handleClickExclude = (property, index) => {
        dispatch(convertIncludeToExclude({ property, index }));
    };

    const handleClearAll = (e) => {
        e.stopPropagation();
        dispatch(clearFilters());
    };

    const handleDeleteExcludedFilter = (property, index) => {
        return dispatch(deleteStandardExcludeFilter({ property, index }));
    };

    const handleDeleteIncludedFilter = (property, index) => {
        if (property === EXPERIENCE_FILTER.YEARS_OF_EXPERIENCE) {
            dispatch(removeStandardFilter(EXPERIENCE_FILTER.YEARS_OF_EXPERIENCE));
            return;
        }
        return dispatch(deleteStandardFilter({ property, index }));
    };

    const toggleExpanded = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {
        if (activeFiltersCount) {
            setExpanded(true);
        }
    }, [activeFiltersCount]);

    return (
        <FilterAccordion
            id="active-search-filters"
            expanded={expanded}
            onChange={toggleExpanded}
            data-trackid={
                expanded ? 'active-filters-drawer-collapse' : 'active-filters-drawer-expand'
            }
        >
            <FilterSummary expandIcon={<ChevronDownIcon />} aria-label="active-filters-summary">
                <Typography variant="labelSmall" sx={{ display: 'flex', alignItems: 'center' }}>
                    Active Filters
                </Typography>
                <SummaryActions>
                    {activeFiltersCount > 0 && (
                        <SummaryAction
                            variant="text"
                            onClick={handleClearAll}
                            aria-label="filter-keyword-clear"
                            data-trackid="active-filters-drawer-clear-all"
                        >
                            Clear All
                        </SummaryAction>
                    )}
                    <SummaryChip label={activeFiltersCount} />
                </SummaryActions>
            </FilterSummary>
            {Boolean(activeFiltersCount) && (
                <FilterDetails sx={{ pt: 0, mt: -2 }}>
                    {keywords.length + standardFilters.length > 0 && (
                        <>
                            <FilterCaption variant="bodySmall">
                                <CheckIcon
                                    sx={{
                                        cursor: 'auto',
                                        fontSize: '.75rem',
                                        mr: '3px',
                                        color: 'success.main',
                                    }}
                                />
                                Included
                            </FilterCaption>
                            <FilterChipContainer>
                                {keywords.map((keyword, index) => (
                                    <FilterChip
                                        key={`keyword-chip-${keyword}-${index}`}
                                        label={keyword}
                                        deleteIcon={
                                            <CloseIcon
                                                aria-label={`keyword-chip-delete-${keyword}`}
                                            />
                                        }
                                        onDelete={() => handleDeleteKeyword(keyword)}
                                    />
                                ))}
                                {standardFilters.map((filter, index) => {
                                    const label = getFilterLabel(filter.property, filter.value);
                                    return (
                                        <IncludedChip
                                            key={`${filter.property}-chip-${label}-${index}`}
                                            index={filter.index}
                                            label={label}
                                            filterKey={filter.property}
                                            onClick={handleClickExclude}
                                            onDelete={handleDeleteIncludedFilter}
                                            disableExclusion={
                                                !isAnExcludableFilter(filter.property)
                                            }
                                        />
                                    );
                                })}
                            </FilterChipContainer>
                        </>
                    )}

                    {Boolean(standardExcludedFilters.length) && (
                        <>
                            <FilterCaption variant="bodySmall">
                                <ExcludeIcon
                                    sx={{ fontSize: '.75rem', color: 'error.main', mr: '3px' }}
                                />
                                Excluded
                            </FilterCaption>
                            <FilterChipContainer>
                                {standardExcludedFilters.map((filter, index) => {
                                    const label = getFilterLabel(filter.property, filter.value);
                                    return (
                                        <ExcludedChip
                                            key={`${filter.property}-excluded-chip-${label}-${index}`}
                                            index={filter.index}
                                            label={label}
                                            filterKey={filter.property}
                                            onClick={handleClickInclude}
                                            onDelete={handleDeleteExcludedFilter}
                                        />
                                    );
                                })}
                            </FilterChipContainer>
                        </>
                    )}
                </FilterDetails>
            )}
        </FilterAccordion>
    );
}
