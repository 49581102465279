import PropTypes from 'prop-types';

import { ContactCtas } from 'features/Contact';
import { useSwitchboard } from 'features/Switchboard';
import { ActionsDropdownButton } from 'components/ActionsMenu';
import { getTalentEmailStatus } from 'features/TalentCard/utils/formatEmailStatus';
import { FindSimilarButton } from 'features/TalentCard/components/FindSimilarButton';
import { ShortlistButton } from 'features/TalentCard/TalentSearchActions/ShortlistButton';
import { DownloadTalentPdfButton } from 'views/Talent/components/DownloadTalentPdfButton';
import { HideFromSearchButton } from 'features/TalentCard/TalentSearchActions/HideFromSearchButton';
import { AddToProjectButton } from 'features/TalentCard/TalentSearchActions/AddToProjectButton';

export function SearchActions(props) {
    const { talent, ...restProps } = props;

    const isHideTalentFeatureEnabled = useSwitchboard('FEATURE_HIDE_TALENT_PROFILES');

    const emailStatus = getTalentEmailStatus(talent);

    return (
        <ActionsDropdownButton {...restProps}>
            {({ handleClose: handleCloseActionsDropdown }) => [
                <ShortlistButton
                    key="shortlist-button"
                    talentId={talent.talentId}
                    profileId={talent.profileId}
                />,
                <AddToProjectButton
                    key="add-to-projects-button"
                    talentId={talent.talentId}
                    profileId={talent.profileId}
                />,
                <ContactCtas
                    key="contact-ctas"
                    talentSource="search"
                    selectedCandidates={[{ ...talent, currentStatus: emailStatus?.currentStatus }]}
                    handleCloseMenu={handleCloseActionsDropdown}
                />,
                <DownloadTalentPdfButton key="dl-talent-pdf" talent={talent} />,
                <FindSimilarButton key="find-similar-button" talent={talent} />,
                isHideTalentFeatureEnabled && (
                    <HideFromSearchButton
                        key="hide-from-search-button"
                        talentId={talent.talentId}
                    />
                ),
            ]}
        </ActionsDropdownButton>
    );
}

SearchActions.propTypes = {
    talent: PropTypes.object,
};
