import SvgIcon from '@mui/material/SvgIcon';

export function PhoneIcon(props) {
    return (
        <SvgIcon viewBox="0 0 18 18" {...props}>
            <path
                d="M15.7495 12.1054C15.7495 11.3104 15.172 10.6429 14.3845 10.5229L12.097 10.1704C11.5945 10.0954 11.077 10.2604 10.7095 10.6204L9.1045 12.8254C8.272 12.3604 7.4995 11.7829 6.832 11.1079C6.16449 10.4329 5.587 9.69039 5.122 8.91039L7.31199 7.34289L7.402 7.26039C7.762 6.90039 7.9345 6.38289 7.852 5.87289L7.49949 3.59289C7.37949 2.80539 6.712 2.23539 5.917 2.22789L3.7345 2.21289H3.727C2.9245 2.21289 2.27199 2.85789 2.26449 3.65289C2.22699 6.84039 3.472 9.86289 5.7745 12.1654C7.9795 14.3704 11.077 15.6679 14.272 15.7354H14.302C14.6845 15.7354 15.052 15.5854 15.322 15.3154C15.5995 15.0379 15.7645 14.6554 15.757 14.2579L15.742 12.0979L15.7495 12.1054ZM3.7345 3.72039L5.9245 3.73539C5.977 3.73539 6.022 3.77289 6.0295 3.82539L6.382 6.10539C6.382 6.10539 6.38199 6.15039 6.37449 6.17289L4.462 7.54539C3.982 6.33789 3.7345 5.04789 3.7345 3.71289V3.72039ZM10.492 13.4854L11.8045 11.6629C11.8045 11.6629 11.8495 11.6479 11.872 11.6479L14.152 12.0004C14.2045 12.0079 14.242 12.0529 14.242 12.1054L14.2945 14.2354C12.982 14.2129 11.6845 13.9429 10.477 13.4779L10.492 13.4854Z"
                fill="currentColor"
            />
            <path
                d="M15.7495 12.1054C15.7495 11.3104 15.172 10.6429 14.3845 10.5229L12.097 10.1704C11.5945 10.0954 11.077 10.2604 10.7095 10.6204L9.1045 12.8254C8.272 12.3604 7.4995 11.7829 6.832 11.1079C6.16449 10.4329 5.587 9.69039 5.122 8.91039L7.31199 7.34289L7.402 7.26039C7.762 6.90039 7.9345 6.38289 7.852 5.87289L7.49949 3.59289C7.37949 2.80539 6.712 2.23539 5.917 2.22789L3.7345 2.21289H3.727C2.9245 2.21289 2.27199 2.85789 2.26449 3.65289C2.22699 6.84039 3.472 9.86289 5.7745 12.1654C7.9795 14.3704 11.077 15.6679 14.272 15.7354H14.302C14.6845 15.7354 15.052 15.5854 15.322 15.3154C15.5995 15.0379 15.7645 14.6554 15.757 14.2579L15.742 12.0979L15.7495 12.1054ZM3.7345 3.72039L5.9245 3.73539C5.977 3.73539 6.022 3.77289 6.0295 3.82539L6.382 6.10539C6.382 6.10539 6.38199 6.15039 6.37449 6.17289L4.462 7.54539C3.982 6.33789 3.7345 5.04789 3.7345 3.71289V3.72039ZM10.492 13.4854L11.8045 11.6629C11.8045 11.6629 11.8495 11.6479 11.872 11.6479L14.152 12.0004C14.2045 12.0079 14.242 12.0529 14.242 12.1054L14.2945 14.2354C12.982 14.2129 11.6845 13.9429 10.477 13.4779L10.492 13.4854Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
