import { useDrop } from 'react-dnd';
import { DND_ITEM_TYPES } from 'utils/constants';

export function useContactDrop(category, index) {
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: DND_ITEM_TYPES.CONTACT_LIST,
            drop: () => ({ dst: category, dstIndex: index }),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [category, index]
    );

    const isActive = canDrop && isOver;

    return [isActive, drop];
}
