import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { useSidebar } from 'hooks/useSidebar';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons';

export function getDrawerWidth(theme) {
    return theme.spacing(50);
}

const openedMixin = (theme) => ({
    width: getDrawerWidth(theme),
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
        width: '100vw',
    },
});

const closedMixin = (theme) => ({
    width: 0,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
});

const Root = styled(Box)(({ theme }) => ({
    position: 'relative',
    zIndex: theme.zIndex.drawer,
}));

const SidebarExpander = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        boxSizing: 'border-box',
        position: 'absolute',
        height: '100%',
        boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(38, 55, 70, 0.3);',
        overflowX: 'hidden',
        ...(open && openedMixin(theme)),
        ...(!open && closedMixin(theme)),
    })
);

const SidebarContentWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'disableGutters',
})(({ theme, disableGutters }) => ({
    position: 'relative',
    boxSizing: 'border-box',
    maxWidth: getDrawerWidth(theme),
    minWidth: getDrawerWidth(theme),
    height: '100%',
    padding: disableGutters ? 0 : theme.spacing(3),
    backgroundColor: theme.palette.background.sidebar,
    boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(38, 55, 70, 0.3);',
    overflowY: 'auto',
    scrollbarColor: `${alpha(theme.palette.common.lightGray, 0.62)} transparent`, // for Firefox
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: alpha(theme.palette.common.lightGray, 0.62),
    },
    [theme.breakpoints.down('sm')]: {
        padding: disableGutters ? 0 : theme.spacing(6, 3),
        maxWidth: '100vw',
        minWidth: '100vw',
    },
}));

const ToggleButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        position: 'absolute',
        color: theme.palette.common.white,
        background: theme.palette.background.sidebar,
        borderRadius: theme.shape.borderRadius,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        padding: 0,
        paddingLeft: theme.spacing(1.1),
        height: 48,
        width: 48,
        zIndex: 2,
        ...(open && {
            top: theme.spacing(2.2),
            right: `calc(${theme.spacing(-4.5)} - ${getDrawerWidth(theme)})`,
            transition: theme.transitions.create(['right', 'top'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down('sm')]: {
                right: `calc(${theme.spacing(1)} - 100vw)`,
            },
        }),
        ...(!open && {
            top: theme.spacing(2.2),
            right: theme.spacing(-4.5),
            boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(38, 55, 70, 0.3);',
            transition: theme.transitions.create(['right', 'top'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
    })
);

export function Sidebar({
    children,
    disableGutters,
    ExpandIcon = ChevronRightIcon,
    forceCollapsible,
    customBreakpoint,
}) {
    const { isExpanded, setIsExpanded, collapse } = useSidebar();

    const isCollapsible =
        useMediaQuery((theme) => theme.breakpoints.down(customBreakpoint || 'lg')) ||
        forceCollapsible ||
        collapse;

    const handleOpenDrawer = () => {
        setIsExpanded(true);
    };

    const handleCloseDrawer = useCallback(() => {
        setIsExpanded(false);
    }, [setIsExpanded]);

    useEffect(() => {
        if (isCollapsible) {
            handleCloseDrawer();
        }
    }, [isCollapsible, handleCloseDrawer]);

    if (isCollapsible) {
        return (
            <ClickAwayListener mouseEvent="onMouseUp" onClickAway={handleCloseDrawer}>
                <Root>
                    <ToggleButton
                        open={isExpanded}
                        aria-label="open-side-drawer"
                        data-trackid="filter-menu-tab"
                        onClick={isExpanded ? handleCloseDrawer : handleOpenDrawer}
                        disableRipple
                    >
                        {isExpanded ? <ChevronLeftIcon /> : <ExpandIcon />}
                    </ToggleButton>
                    <SidebarExpander open={isExpanded}>
                        <SidebarContentWrapper
                            aria-hidden={isExpanded ? 'false' : 'true'}
                            disableGutters={disableGutters}
                        >
                            {children}
                        </SidebarContentWrapper>
                    </SidebarExpander>
                </Root>
            </ClickAwayListener>
        );
    }

    return (
        <SidebarContentWrapper disableGutters={disableGutters}>{children}</SidebarContentWrapper>
    );
}

Sidebar.propTypes = {
    disableGutters: PropTypes.bool,
    forceCollapsible: PropTypes.bool,
    ExpandIcon: PropTypes.elementType,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    customBreakpoint: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
