import SvgIcon from '@mui/material/SvgIcon';

export function ReyIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M11.1111 2.60556C12.3668 2.60556 13.3944 3.63317 13.3944 4.88889V11.1111C13.3944 12.3668 12.3668 13.3944 11.1111 13.3944H4.88889C3.63317 13.3944 2.60556 12.3668 2.60556 11.1111V4.88889C2.60556 3.63317 3.63317 2.60556 4.88889 2.60556H11.1111ZM11.1111 0.95H4.88889C2.71461 0.95 0.95 2.71461 0.95 4.88889V11.1111C0.95 13.2854 2.71461 15.05 4.88889 15.05H11.1111C13.2854 15.05 15.05 13.2854 15.05 11.1111V4.88889C15.05 2.71461 13.2854 0.95 11.1111 0.95Z"
                fill="url(#paint0_linear_18850_23936)"
                stroke="url(#paint1_linear_18850_23936)"
                strokeWidth="0.1"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_18850_23936"
                    x1="1"
                    y1="6.02817"
                    x2="15.0035"
                    y2="6.09552"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_18850_23936"
                    x1="1"
                    y1="6.02817"
                    x2="15.0035"
                    y2="6.09552"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}
