import SvgIcon from '@mui/material/SvgIcon';

export function CheckBoxUndefinedIcon(props) {
    return (
        <SvgIcon viewBox="0 0 18 18" {...props}>
            <path
                d="M4.06514 1C3.25222 1 2.47259 1.32293 1.89776 1.89776C1.32293 2.47259 1 3.25222 1 4.06514V13.9349C1 15.6286 2.37143 17 4.06514 17H13.9349C15.6286 17 17 15.6286 17 13.9349V4.06514C17 2.37143 15.6286 1 13.9349 1H4.06514Z"
                fill="currentColor"
            />
            <line x1="4.5" y1="8.5" x2="13.5" y2="8.5" stroke="white" stroke-linecap="round" />
        </SvgIcon>
    );
}
