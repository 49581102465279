import SvgIcon from '@mui/material/SvgIcon';

export function AddIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M8 12C8 11.8674 8.05268 11.7402 8.14645 11.6464C8.24021 11.5527 8.36739 11.5 8.5 11.5H11.5V8.5C11.5 8.36739 11.5527 8.24021 11.6464 8.14645C11.7402 8.05268 11.8674 8 12 8C12.1326 8 12.2598 8.05268 12.3536 8.14645C12.4473 8.24021 12.5 8.36739 12.5 8.5V11.5H15.5C15.6326 11.5 15.7598 11.5527 15.8536 11.6464C15.9473 11.7402 16 11.8674 16 12C16 12.1326 15.9473 12.2598 15.8536 12.3536C15.7598 12.4473 15.6326 12.5 15.5 12.5H12.5V15.5C12.5 15.6326 12.4473 15.7598 12.3536 15.8536C12.2598 15.9473 12.1326 16 12 16C11.8674 16 11.7402 15.9473 11.6464 15.8536C11.5527 15.7598 11.5 15.6326 11.5 15.5V12.5H8.5C8.36739 12.5 8.24021 12.4473 8.14645 12.3536C8.05268 12.2598 8 12.1326 8 12ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 19C11.0807 19 10.1705 18.8189 9.32122 18.4672C8.47194 18.1154 7.70026 17.5998 7.05025 16.9497C6.40024 16.2997 5.88463 15.5281 5.53284 14.6788C5.18106 13.8295 5 12.9193 5 12C5 11.0807 5.18106 10.1705 5.53284 9.32122C5.88463 8.47194 6.40024 7.70026 7.05025 7.05025C7.70026 6.40024 8.47194 5.88463 9.32122 5.53284C10.1705 5.18106 11.0807 5 12 5C13.8565 5 15.637 5.7375 16.9497 7.05025C18.2625 8.36301 19 10.1435 19 12C19 13.8565 18.2625 15.637 16.9497 16.9497C15.637 18.2625 13.8565 19 12 19Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
