export const KEYCLOAK_ROLES = {
    SALES_MANAGER: 'sales-manager',
    ORGANIZATION_ADMIN: 'org-admin',
    USER: 'default-roles-nova',
    NOVA_TOOLS_ADMIN: 'nova-tools-admin',
    NOVA_QUICKLAUNCH_DEV: 'nova-quicklaunch-dev',
    NOVA_QUICKLAUNCH_ADMIN: 'nova-quicklaunch-admin',
    IMPERSONATORS: 'impersonation',
};

export const ENVIRONMENTS = {
    LOCAL: 'dev',
    TEST: 'test',
    STAGING: 'staging',
    PRODUCTION: 'production',
};

export const USER_TYPE = {
    SYSTEM_ADMIN: {
        label: 'System Admin',
        value: `${KEYCLOAK_ROLES.USER};${KEYCLOAK_ROLES.SALES_MANAGER}`,
    },
    ORGANIZATION_ADMIN: {
        label: 'Organization Admin',
        value: `${KEYCLOAK_ROLES.USER};${KEYCLOAK_ROLES.ORGANIZATION_ADMIN}`,
    },
    USER: { label: 'User', value: KEYCLOAK_ROLES.USER },
};

export const SYSTEM_USER_TYPES = [
    USER_TYPE.SYSTEM_ADMIN,
    USER_TYPE.ORGANIZATION_ADMIN,
    USER_TYPE.USER,
];

export const ORGANIZATION_USER_TYPES = [USER_TYPE.ORGANIZATION_ADMIN, USER_TYPE.USER];

export const TOKEN_TYPE = {
    email: { label: 'Email', value: 'email' },
    phone: { label: 'Phone Only', value: 'phone' },
    social: { label: 'Social Only', value: 'social' },
    'email+phone': { label: 'Email + Phone', value: 'email+phone' },
    'email+phone+social': { label: 'Email + Phone + Social', value: 'email+phone+social' },
};

export const MESSAGE_TYPES = {
    SMS: 'sms',
    EMAIL: 'email',
};

export const FEATURE_STATUS = {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
};

export const CREDITS = {
    JD: 'jobDescription',
    SEARCH: 'search',
    LOOKUP: 'emailLookup',
};

export const SCHEDULED_DATE_FMT = 'MMMM d, yyyy';

export const SCHEDULED_TIME_FMT = 'HH:mm';

export const SCHEDULED_TIME_FMT_12_HRS = 'hh:mm a';

export const TEST_IDS = {
    PHONE_SECONDARY_VIEW: 'phone-secondary-view',
    WARNING_INDICATOR: 'warning-indicator',
    EMAIL_SECONDARY_VIEW: 'email-secondary-view',
    CONTACT_LIST_ITEM: 'contact-list-item',
    STAR_ICON: 'star-icon',
    PROGRESS_ICON: 'progress-icon',
};

export const NOTES_ENV_URLS = {
    app: 'https://functions.nebula.io/notes-api',
    'nova-staging': 'https://functions-staging.nebula.io/notes-api',
    'nova-test': 'https://functions-test.nebula.io/notes-api',
    local: 'https://functions-test.nebula.io/notes-api',
    'local-msw-test': 'http://localhost/notes-api',
};

export const KNOWLEDGE_BASE_FILTER_VALUES = {
    INCOMPLETE: 'incomplete',
};

export const KNOWLEDGE_BASE_SORT_VALUES = {
    ALPHA_ASCENDING: 'alpha-ascending',
    ALPHA_DESCENDING: 'alpha-descending',
    NEWEST: 'newest',
    OLDEST: 'oldest',
    MOST_COMPLETE: 'most-complete',
    LEAST_COMPLETE: 'least-complete',
};

export const KNOWLEDGE_BASE_FILTER_OPTIONS = [
    {
        value: KNOWLEDGE_BASE_FILTER_VALUES.INCOMPLETE,
        label: 'Only Incomplete',
    },
];

export const KNOWLEDGE_BASE_SORT_OPTIONS = [
    {
        value: KNOWLEDGE_BASE_SORT_VALUES.NEWEST,
        label: 'Newest',
    },
    {
        value: KNOWLEDGE_BASE_SORT_VALUES.OLDEST,
        label: 'Oldest',
    },
    {
        value: KNOWLEDGE_BASE_SORT_VALUES.ALPHA_ASCENDING,
        label: 'Alpha Ascending',
    },
    {
        value: KNOWLEDGE_BASE_SORT_VALUES.ALPHA_DESCENDING,
        label: 'Alpha Descending',
    },
    {
        value: KNOWLEDGE_BASE_SORT_VALUES.MOST_COMPLETE,
        label: 'Most Complete',
    },
    {
        value: KNOWLEDGE_BASE_SORT_VALUES.LEAST_COMPLETE,
        label: 'Least Complete',
    },
];
export const DND_ITEM_TYPES = {
    CONTACT_LIST: 'ContactList',
};

export const SUBSCRIPTION_STATUS = {
    freemium: { label: 'Freemium', value: false },
    premium: { label: 'Premium', value: true },
};

export const CUSTOM_CONTACT_CATEGORIES_DROPDOWN_OPTIONS = [
    'personal',
    'home',
    'work',
    'mobile',
    'professional',
    'uncategorized',
];

export const MESSAGE_ATTACHMENT_FILE_SIZE_LIMIT = {
    [MESSAGE_TYPES.EMAIL]: 25 * 1024 * 1024, // 25MB
    [MESSAGE_TYPES.SMS]: 5 * 1024 * 1024, // 5MB
};

export const MINIMUM_EMAIL_SUBJECT_LENGTH = 6;

export const TALENT_DOC_TYPES = {
    PDF: 'pdf',
};

export const JD_OUTPUT_SECTIONS = [
    'Position Title',
    'Position Summary',
    'Company Description',
    'Responsibilities',
    'Desired Experience and Skills',
];

export const SEARCH_EMPHASIS_WEIGHT = 3;

export const SEARCH_MIN_CHAR_COUNT = 25;

export const NEBULINK_ENV_URLS = {
    app: 'https://signup.nebula.io',
    'nova-staging': 'https://nova-staging.nebula.io',
    'nova-test': 'https://nova-test.nebula.io',
    local: 'https://nova-test.nebula.io',
};
export const NOVA_ENVIRONMENTS = {
    PROD: 'app',
    STAGING: 'nova-staging',
    TEST: 'nova-test',
    LOCAL: 'local',
};

export const SEND_TYPE = {
    TRAVERSAL: 'traversal',
    DIRECT: 'direct',
};

export const SESSION_STORAGE = {
    CONTACT_VERIFICATION: 'contact.verification',
};

export const TASK_STATES = {
    CREATED: 'CREATED',
    SUBMITTED: 'SUBMITTED',
    EXECUTING: 'EXECUTING',
    EXECUTION_COMPLETE: 'EXECUTION_COMPLETE',
    CANCELLED: 'CANCELLED',
    EXCEPTION_OCCURRED: 'EXCEPTION_OCCURRED',
};

export const TASK_TERMINAL_STATES = [
    TASK_STATES.EXCEPTION_OCCURRED,
    TASK_STATES.EXECUTION_COMPLETE,
    TASK_STATES.CANCELLED,
];

export const FEEDBACK_VARIANTS = {
    POSITIVE: 'positive',
    NEUTRAL: 'neutral',
    NEGATIVE: 'negative',
};

export const FEEDBACK_TYPE_MAP = {
    '-1': FEEDBACK_VARIANTS.NEGATIVE,
    0: FEEDBACK_VARIANTS.NEUTRAL,
    1: FEEDBACK_VARIANTS.POSITIVE,
};

export const MEDIA_TYPES = {
    IMAGE: { value: 'Image', label: 'Images' },
    PDF: { value: 'PDF', label: 'PDF documents' },
    DOC: { value: 'Doc', label: 'Documents' },
    VIDEO: { value: 'Video', label: 'Videos' },
    SPREADSHEET: { value: 'Spreadsheet', label: 'Spreadsheets' },
    SLIDESHOW: { value: 'Slideshow', label: 'Presentations' },
    AUDIO: { value: 'Audio', label: 'Audio files' },
    OTHER: { value: 'Other', label: 'Other' },
};

export const MEDIA_SOURCE_FILTERS = {
    YOU: { label: 'You', value: 'sourceUserIds' },
    YOUR_ORG: { label: 'Your Org', value: 'org' },
    TALENT: { label: 'Talent', value: 'talent' },
};

export const MEDIA_SOURCE_FILTER_ARRAY = [
    MEDIA_SOURCE_FILTERS.YOU.label,
    MEDIA_SOURCE_FILTERS.YOUR_ORG.label,
    MEDIA_SOURCE_FILTERS.TALENT.label,
];

export const USE_PICKER_MAX_FILES = 5;

export const FEEDBACK_TYPES = {
    WATCH: 'watch',
    SHORTLIST: 'shortlist',
};

export const WATCH_TYPES = {
    SEARCH: 'saved-search',
    SHORTLIST: 'shortlist',
};

export const PROJECT_FIELD_TYPE = {
    LINK: 'link',
    MEDIA: 'media',
};

export const PROJECT_DETAIL_TYPES = {
    LINKS: 'links',
    DETAILS: 'details',
};

export const TALENT_DRAWER_TABS = {
    PROFILE_TAB: 0,
    NOTES_TAB: 2,
    ATTACHMENTS_TAB: 3,
    ACTIVITY_TAB: 1,
};

export const SEARCH_EVENT_TYPES = {
    RESULTS: 'results_received',
    EXPAND_PROFILE: 'profile_expanded',
    OPEN_PROFILE_DRAWER: 'profile_drawer_opened',
    LI_CLICK: 'li_button_clicked',
    VIEW_COMP: 'viewed_estimated_comp',
    ADD_NOTE: 'note_added',
    ADD_TO_SHORTLIST: 'added_to_shortlist',
    ADD_TO_PROJECT: 'added_to_project',
    SMS_CLICK: 'send_sms_clicked',
    EMAIL_CLICK: 'send_email_clicked',
    PATHS_CLICK: 'paths_clicked',
    DA_CLICK: 'digital_assistant_clicked',
    PDF_DL: 'pdf_downloaded',
    FIND_SIMILAR: 'find_similar_clicked',
    HIDE: 'hide_profile_clicked',
    OPEN_CONTACT: 'contact_modal_opened',
};
