import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { ChevronDownIcon } from 'components/Icons';
import { MediaSectionLabel } from 'views/UserSettings/features/YourMedia/components/MediaModals/MediaSectionLabel';
import { MESSAGE_GENERATION_TARGETS, MESSAGE_SOURCE_OPTIONS } from 'features/MessageDrawer/utils';
import { CircularProgress, FormControl, styled } from '@mui/material';
import { useMessageGeneration } from 'features/MessageDrawer/hooks/useMessageGeneration';
import { lowerCase } from 'lodash';
import { useEffect } from 'react';

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    width: '16px !important',
    height: '16px !important',
}));

export function MessageOptionSourceSelector(props) {
    const { initialSource, source, value, setValue } = props;

    const { options, isFetching } = useMessageGeneration({
        target: MESSAGE_GENERATION_TARGETS.SOURCE_ID,
        source,
    });

    const lowerCaseSource = lowerCase(MESSAGE_SOURCE_OPTIONS?.[source]?.label);

    const handleChangeOption = (event) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        if (
            options?.length &&
            !value &&
            source === MESSAGE_SOURCE_OPTIONS.talentSearchHistory.value &&
            initialSource === MESSAGE_SOURCE_OPTIONS.talentSearchHistory.value
        ) {
            setValue(options[0]?.id || '');
        }
    }, [value, source, initialSource, options, setValue]);

    if (!source) {
        return null;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <MediaSectionLabel>{MESSAGE_SOURCE_OPTIONS[source].label}</MediaSectionLabel>
            <FormControl>
                <Select
                    displayEmpty
                    disabled={isFetching}
                    value={value}
                    onChange={handleChangeOption}
                    IconComponent={isFetching ? StyledCircularProgress : ChevronDownIcon}
                    inputProps={{
                        'aria-label': 'message source id options',
                        'data-testid': 'message-gen-source-id-options',
                        sx: {
                            color: value === '' ? 'text.disabled' : 'text.primary',
                            py: 1.5,
                        },
                    }}
                    sx={{
                        '.MuiSelect-icon': {
                            color: 'primary.main',
                        },
                    }}
                >
                    {value === '' && (
                        <MenuItem
                            value=""
                            sx={{
                                color: 'text.disabled',
                                opacity: '1 !important',
                                background: 'transparent !important',
                            }}
                            disabled
                        >
                            Choose a relevant {lowerCaseSource} for the message
                        </MenuItem>
                    )}
                    {options?.map((option) => (
                        <MenuItem
                            sx={{ maxWidth: '416px' }}
                            value={option.id}
                            data-testid={`${option.id}-option`}
                            key={option.id}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

MessageOptionSourceSelector.propTypes = {
    initialSource: PropTypes.string,
    source: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
};
