import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';

import { CloseIcon, ExcludeIcon } from 'components/Icons';
import { Tooltip } from 'components/Tooltip';
import { FilterChip } from './FilterChip';

export function IncludedChip(props) {
    const {
        label,
        filterKey,
        index,
        onClick,
        onDelete,
        disableExclusion,
        isCustomLabel,
        disableTooltip,
        ...restProps
    } = props;

    let icon = null;
    if (!disableExclusion) {
        icon = (
            <Tooltip title="Exclude this filter">
                <div className="MuiChip-icon">
                    <ExcludeIcon
                        aria-label={`exclude-${isCustomLabel ? index : label}-chip`}
                        onClick={() => onClick(filterKey, index)}
                        data-trackid="filter-chip-exclude-toggle"
                        sx={{
                            fontSize: '13px',
                            color: (theme) => alpha(theme.palette.common.white, 0.48),
                            '&:hover': { color: 'error.main' },
                        }}
                    />
                </div>
            </Tooltip>
        );
    }

    return (
        <FilterChip
            label={label}
            icon={icon}
            deleteIcon={
                <CloseIcon
                    data-trackid="filter-chip-remove"
                    aria-label={`delete-${isCustomLabel ? index : label}-included-chip`}
                />
            }
            onDelete={() => onDelete(filterKey, index)}
            {...restProps}
            disableTooltip={disableTooltip}
        />
    );
}

IncludedChip.propTypes = {
    label: PropTypes.any,
    index: PropTypes.number,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    filterKey: PropTypes.any,
    disableExclusion: PropTypes.bool,
    isCustomLabel: PropTypes.bool,
    disableTooltip: PropTypes.bool,
};
