import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { MessageSummary } from './MessageSummary';

export function Conversations(props) {
    const { isLoading, isSuccess, conversations, talents, isTalentsLoading } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                color: 'common.white',
                m: [1, 0, 0, 0],
                flexDirection: 'column',
                maxWidth: '100%',
                overflowY: 'auto',
                padding: [1, 0, 1, 0],
            }}
        >
            {isLoading && (
                <Box
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        mt: 2,
                    }}
                >
                    <CircularProgress size={120} />
                </Box>
            )}
            {!isLoading &&
                isSuccess &&
                conversations?.map((conversation, index) => (
                    <MessageSummary
                        key={conversation.conversationId}
                        isTalentsLoading={isTalentsLoading}
                        {...conversation}
                        talent={talents[conversation.talent.talentId]}
                        isRead={conversation?.messages?.[0]?.isRead}
                    />
                ))}
        </Box>
    );
}

Conversations.propTypes = {
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    isTalentsLoading: PropTypes.bool,
    talents: PropTypes.object,
    conversations: PropTypes.array,
};
