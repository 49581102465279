import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { styled, alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { MediaSectionLabel } from './MediaSectionLabel';
import { Typography } from 'components/Typography';
import { CloseIcon } from 'components/Icons';

const StyledChip = styled(Chip)(({ theme }) => ({
    border: 'none',
    background: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    height: theme.spacing(3),
    '& .MuiChip-deleteIcon': {
        fontSize: '16px',
        color: '#2637467A',
    },
}));

export function FileTags(props) {
    const {
        tagOptions,
        selectedValues,
        onValueChange,
        inputValue,
        onInputChange,
        onInputEnter,
        showLabel = true,
        InputStyleSx,
        mediaId,
    } = props;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {showLabel && <MediaSectionLabel>Tags</MediaSectionLabel>}
            <Autocomplete
                multiple
                freeSolo
                filterSelectedOptions
                id="tags-filled"
                options={tagOptions}
                value={selectedValues}
                onChange={(event, data) => {
                    const dataKey = mediaId;
                    onValueChange(event, data, dataKey);
                }}
                inputValue={inputValue}
                onInputChange={onInputChange}
                getOptionLabel={(option) => {
                    return option;
                }}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                        <StyledChip
                            label={<Typography variant="titleSmall500">{option}</Typography>}
                            {...getTagProps({ index })}
                            deleteIcon={<CloseIcon sx={{ color: 'text.secondary', fontSize: 8 }} />}
                        />
                    ));
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        sx={{
                            width: '100%',
                            ...InputStyleSx,
                        }}
                        placeholder="Start typing to add tags"
                        autoFocus
                        InputProps={{
                            ...params.InputProps,
                            'data-testid': 'file-tags-input',
                        }}
                        onKeyDown={onInputEnter}
                    />
                )}
            />
        </Box>
    );
}

FileTags.propTypes = {
    tagOptions: PropTypes.array,
    selectedValues: PropTypes.array,
    inputValue: PropTypes.string,
    onValueChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onInputEnter: PropTypes.func,
    showLabel: PropTypes.bool,
    InputStyleSx: PropTypes.object,
    mediaId: PropTypes.string,
};
