import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

import { MatchIcon } from 'components/Icons';
import { Typography } from 'components/Typography';

const Button = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== 'small',
})(({ theme, small }) => ({
    borderRadius: theme.shape.borderRadius,
    boxShadow: small
        ? '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'
        : 'none',
    color: theme.palette.common.white,
    background: theme.palette.common.matchInputGradient,
    width: small ? theme.spacing(15) : '100%',
    height: theme.spacing(small ? 3.5 : 7.5),
    textTransform: 'none',
    transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.standard,
    }),
    '&.Mui-disabled': {
        opacity: 0.32,
    },
}));

export function ApplyChangesButton(props) {
    const { onClick, disabled, small, ...restProps } = props;
    return (
        <Button
            id="apply-search-changes"
            aria-label="apply-search-changes"
            data-trackid="search-menu-resume-current-search"
            disableRipple
            onClick={onClick}
            disabled={disabled}
            small={small}
            {...restProps}
        >
            <MatchIcon fontSize={small ? 'small' : 'large'} sx={{ pr: small ? 0.5 : 1 }} />
            <Typography variant={small ? 'bodySmall' : 'titleMedium'} sx={{ mt: -0.25 }}>
                Apply Changes
            </Typography>
        </Button>
    );
}

ApplyChangesButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
};
