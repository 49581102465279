import { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { Typography } from 'components/Typography';
import { TemplateListItem } from './TemplateListItem';

export const TemplateLists = memo((props) => {
    const {
        templates,
        onTemplateCreateView,
        onClose,
        onUseTemplate,
        onViewTemplate,
        onDeleteTemplate,
        disableUseTemplate,
    } = props;

    return (
        <Box sx={{ pb: 7 }}>
            {templates?.length === 0 && (
                <Typography
                    variant="smallHeadline"
                    color="text.primary"
                    sx={{
                        marginTop: 3,
                    }}
                >
                    Create a message template and you will see it here
                </Typography>
            )}
            {templates?.length > 0 && (
                <>
                    {templates?.map((template, index) => (
                        <TemplateListItem
                            key={template.templateId}
                            template={template}
                            onTemplateCreateView={onTemplateCreateView}
                            onClose={onClose}
                            onUseTemplate={onUseTemplate}
                            onViewTemplate={onViewTemplate}
                            onDeleteTemplate={onDeleteTemplate}
                            disableUseTemplate={disableUseTemplate}
                            sx={{ mb: index === templates.length - 1 ? 5 : 0 }}
                        />
                    ))}
                </>
            )}
        </Box>
    );
});

TemplateLists.propTypes = {
    onTemplateCreateView: PropTypes.func,
    onViewTemplate: PropTypes.func,
    onUseTemplate: PropTypes.func,
    onClose: PropTypes.func,
    templates: PropTypes.array,
    isCreateTemplateDisabled: PropTypes.bool,
    onDeleteTemplate: PropTypes.func,
    disableUseTemplate: PropTypes.bool,
};
