import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useCallback, useState, useRef } from 'react';
import { useSwitchboard } from 'features/Switchboard';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';

import {
    FilterAccordion,
    FilterSummary,
    StyledDivider,
    FilterDetails,
    SummaryChip,
    SummaryActions,
    SummaryAction,
    FilterMenuItem,
    StyledSelect,
    StyledPaper,
    FilterChipContainer,
    CustomWidthTooltip,
} from './styledComponents';
import {
    ChevronDownIcon,
    CheckCircleIcon,
    BuildingIcon,
    SuitcaseIcon,
    PastCompanyIcon,
    PastJobTitleIcon,
    JobTitleIcon,
    InfoIcon,
} from 'components/Icons';
import {
    changeStandardFilter,
    deleteStandardFilter,
    deleteStandardExcludeFilter,
    convertIncludeToExclude,
    convertExcludeToInclude,
    resetMultipleStandardFilters,
    changeStandardExcludeFilter,
    removeStandardFilter,
} from 'views/Search/searchSlice';
import { FilterLabel } from './FilterLabel';
import { Tooltip } from 'components/Tooltip';
import { IncludedChip } from './IncludedChip';
import { ExcludedChip } from './ExcludedChip';
import { MultiTextInput } from 'components/Inputs';
import { Typography } from 'components/Typography';
import { ExperienceSlider } from './ExperienceSlider';
import { FilterIndustries } from './FilterIndustries';
import { IncludeExcludeLabel } from './IncludeExcludeLabel';
import { EMPLOYMENT_FILTER, EXPERIENCE_FILTER } from './constants';
import { FilterOccupations } from './FilterOccupations';
import { AnchorOrigin, TransformOrigin, AutocompleteSx, InputProps } from './props';

export const IconContainer = styled('div')(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
    textAlign: 'center',
    paddingBottom: theme.spacing(0.5),
    height: theme.spacing(2),
    width: theme.spacing(2),
}));

const StyledPastCompanyIcon = styled(PastCompanyIcon)(({ theme }) => ({
    fontSize: '14px',
    marginLeft: theme.spacing(0.3),
    marginTop: theme.spacing(0.1),
}));

const StyledBuildingIcon = styled(BuildingIcon)(({ theme }) => ({
    fontSize: '18px',
    marginLeft: theme.spacing(0.3),
    marginTop: theme.spacing(0.1),
}));

const StyledPastJobTitleIcon = styled(PastJobTitleIcon)(({ theme }) => ({
    fontSize: '16px',
    marginLeft: theme.spacing(0.6),
}));

const StyledJobTitleIcon = styled(JobTitleIcon)(({ theme }) => ({
    fontSize: '16px',
    marginLeft: theme.spacing(0.6),
}));

function experienceFilterCountSelector(state) {
    const experienceFilter = state.search.filters[EXPERIENCE_FILTER.YEARS_OF_EXPERIENCE];
    if (
        experienceFilter?.length &&
        (experienceFilter?.[0] !== 0 || experienceFilter?.[1] !== 100)
    ) {
        return 1;
    }
    return 0;
}

const jobLabel = {
    [EMPLOYMENT_FILTER.JOB_TITLE]: 'Filter by Past & Current Job Titles',
    [EMPLOYMENT_FILTER.CURRENT_JOB_TITLE]: 'Filter by Current Job Title',
};
const companyLabel = {
    [EMPLOYMENT_FILTER.COMPANY_NAME]: 'Filter by Past & Current Company Names',
    [EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME]: 'Filter by Current Company Name',
};

const selectJobTitle = (state) => state.search.filters[EMPLOYMENT_FILTER.JOB_TITLE] || [];
const selectExcludeJobTitle = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.JOB_TITLE] || [];
const selectCurrentJobTitle = (state) =>
    state.search.filters[EMPLOYMENT_FILTER.CURRENT_JOB_TITLE] || [];
const selectExcludeCurrentJobTitle = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.CURRENT_JOB_TITLE] || [];
const selectCompanyName = (state) => state.search.filters[EMPLOYMENT_FILTER.COMPANY_NAME] || [];
const selectExcludeCompanyName = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.COMPANY_NAME] || [];
const selectCurrentCompanyName = (state) =>
    state.search.filters[EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME] || [];
const selectExcludeCurrentCompanyName = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME] || [];
const selectOccupation = (state) => state.search.filters[EMPLOYMENT_FILTER.OCCUPATION] || [];
const selectExcludeOccupation = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.OCCUPATION] || [];
const selectIndustry = (state) => state.search.filters[EMPLOYMENT_FILTER.INDUSTRY] || [];
const selectExcludeIndustry = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.INDUSTRY] || [];
const selectCurrentOccupation = (state) =>
    state.search.filters[EMPLOYMENT_FILTER.CURRENT_OCCUPATION] || [];
const selectExcludeCurrentOccupation = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.CURRENT_OCCUPATION] || [];
const selectCurrentIndustry = (state) =>
    state.search.filters[EMPLOYMENT_FILTER.CURRENT_INDUSTRY] || [];
const selectExcludeCurrentIndustry = (state) =>
    state.search.exclusionFilters[EMPLOYMENT_FILTER.CURRENT_INDUSTRY] || [];

export const employmentMenuProps = (menu, mt) => {
    //account for root element margin, as well as 0.5 border radius
    return {
        anchorEl: menu.current,
        sx: {
            marginTop: mt ? mt : -1.5,
            '& .MuiMenu-paper': {
                width: menu.current?.offsetWidth,
                backgroundColor: 'text.primary',
                borderRadius: '0 0 4px 4px',
            },
            '& .MuiMenu-list': {
                backgroundColor: 'inherit',
                color: 'common.white',
            },
        },
        anchorOrigin: AnchorOrigin,
        transformOrigin: TransformOrigin,
    };
};

const JobTitleTooltip =
    'This filter searches on the job titles candidates have used to describe their current or past job positions.';

export function FilterEmployment() {
    const employmentMenu = useRef();
    const companyMenu = useRef();

    const isOccupationEnabled = useSwitchboard('FEATURE_OCCUPATION_FILTER');
    const isIndustryEnabled = useSwitchboard('FEATURE_INDUSTRY_FILTER');

    const dispatch = useDispatch();

    //Needed to trigger a refresh and update refs, and by extension anchorElements and menu widths
    const [open, setOpen] = useState(false);
    const handleAccordionChange = (_event, expanded) => setOpen(expanded);

    const [excludeMatch, setExcludeMatch] = useState(false);
    const [excludeCompanyMatch, setExcludeCompanyMatch] = useState(false);

    const jobTitles = useSelector(selectJobTitle);
    const excludedJobTitles = useSelector(selectExcludeJobTitle);
    const currentJobTitles = useSelector(selectCurrentJobTitle);
    const excludedCurrentJobTitles = useSelector(selectExcludeCurrentJobTitle);
    const occupations = useSelector(selectOccupation);
    const excludedOccupations = useSelector(selectExcludeOccupation);
    const currentOccupations = useSelector(selectCurrentOccupation);
    const excludedCurrentOccupations = useSelector(selectExcludeCurrentOccupation);
    const companyNames = useSelector(selectCompanyName);
    const excludedCompanyNames = useSelector(selectExcludeCompanyName);
    const currentCompanyNames = useSelector(selectCurrentCompanyName);
    const excludedCurrentCompanyNames = useSelector(selectExcludeCurrentCompanyName);
    const experienceFilterCount = useSelector(experienceFilterCountSelector);
    const industries = useSelector(selectIndustry);
    const excludedIndustries = useSelector(selectExcludeIndustry);
    const currentIndustries = useSelector(selectCurrentIndustry);
    const excludedCurrentIndustries = useSelector(selectExcludeCurrentIndustry);

    const [employmentFilter, setEmploymentFilter] = useState(EMPLOYMENT_FILTER.JOB_TITLE);
    const [companyFilter, setCompanyFilter] = useState(EMPLOYMENT_FILTER.COMPANY_NAME);

    const handleExcludeMatchChange = (event, excludedEmploymentType) => {
        excludedEmploymentType === 'job-employment'
            ? setExcludeMatch(event.target.checked)
            : setExcludeCompanyMatch(event.target.checked);
    };

    const handleEmploymentChange = useCallback(
        (event) => {
            const action = excludeMatch ? changeStandardExcludeFilter : changeStandardFilter;

            dispatch(
                action({
                    property: employmentFilter,
                    matchText: event.target.value,
                })
            );
        },
        [dispatch, excludeMatch, employmentFilter]
    );

    const handleCompanyEmploymentChange = useCallback(
        (event) => {
            const action = excludeCompanyMatch ? changeStandardExcludeFilter : changeStandardFilter;

            dispatch(
                action({
                    property: companyFilter,
                    matchText: event.target.value,
                })
            );
        },
        [dispatch, excludeCompanyMatch, companyFilter]
    );

    const getSelectedCompanyFilterValue = () => {
        if (companyFilter === EMPLOYMENT_FILTER.COMPANY_NAME) {
            return excludeCompanyMatch ? excludedCompanyNames : companyNames;
        } else if (companyFilter === EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME) {
            return excludeCompanyMatch ? excludedCurrentCompanyNames : currentCompanyNames;
        }
    };

    const handleCompanyPaste = (event) => {
        const pastedText = event.clipboardData.getData('text');
        if (/[,\;\n]/.test(pastedText)) {
            event.preventDefault(); // prevent the input from populating
            const currentFilterValue = getSelectedCompanyFilterValue();
            const valuesToAdd = pastedText
                .split(/[,\;\n]/)
                .map((str) => str.trim())
                .filter((str) => str);
            const nextValue = [...new Set(currentFilterValue.concat(valuesToAdd))];
            handleCompanyEmploymentChange({ target: { value: nextValue } });
        }
    };

    const handleJobFilter = useCallback((event) => setEmploymentFilter(event.target.value), []);
    const handleCompanyFilter = useCallback((event) => setCompanyFilter(event.target.value), []);

    const handleDeleteEmployment = (property, index) => {
        dispatch(deleteStandardFilter({ property, index }));
    };

    const handleDeleteExcludedEmployment = (property, index) => {
        dispatch(deleteStandardExcludeFilter({ property, index }));
    };

    const handleClearEmployment = (event) => {
        event.stopPropagation();
        dispatch(
            resetMultipleStandardFilters([
                EMPLOYMENT_FILTER.JOB_TITLE,
                EMPLOYMENT_FILTER.CURRENT_JOB_TITLE,
                EMPLOYMENT_FILTER.COMPANY_NAME,
                EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME,
                EMPLOYMENT_FILTER.OCCUPATION,
                EMPLOYMENT_FILTER.CURRENT_OCCUPATION,
                EMPLOYMENT_FILTER.INDUSTRY,
                EMPLOYMENT_FILTER.CURRENT_INDUSTRY,
            ])
        );
        dispatch(removeStandardFilter(EXPERIENCE_FILTER.YEARS_OF_EXPERIENCE));
    };

    const handleClickInclude = (property, index) => {
        dispatch(convertExcludeToInclude({ property, index }));
    };

    const handleClickExclude = (property, index) => {
        dispatch(convertIncludeToExclude({ property, index }));
    };

    const employmentValue = () => {
        if (employmentFilter === EMPLOYMENT_FILTER.JOB_TITLE && excludeMatch) {
            return excludedJobTitles;
        } else if (employmentFilter === EMPLOYMENT_FILTER.JOB_TITLE) {
            return jobTitles;
        } else if (employmentFilter === EMPLOYMENT_FILTER.CURRENT_JOB_TITLE && excludeMatch) {
            return excludedCurrentJobTitles;
        } else {
            return currentJobTitles;
        }
    };
    const companyEmploymentValue = () => {
        if (companyFilter === EMPLOYMENT_FILTER.COMPANY_NAME && excludeCompanyMatch) {
            return excludedCompanyNames;
        } else if (companyFilter === EMPLOYMENT_FILTER.COMPANY_NAME) {
            return companyNames;
        } else if (
            companyFilter === EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME &&
            excludeCompanyMatch
        ) {
            return excludedCurrentCompanyNames;
        } else {
            return currentCompanyNames;
        }
    };

    const handleYearsOfExperience = useCallback(
        (value) => {
            if (value.includes(0) && value.includes(100)) {
                dispatch(removeStandardFilter(EXPERIENCE_FILTER.YEARS_OF_EXPERIENCE));
            } else {
                dispatch(
                    changeStandardFilter({
                        property: EXPERIENCE_FILTER.YEARS_OF_EXPERIENCE,
                        matchText: value,
                    })
                );
            }
        },
        [dispatch]
    );

    const isFilteringJobTitles = employmentFilter === EMPLOYMENT_FILTER.JOB_TITLE;
    const isFilteringCurrentJobTitles = employmentFilter === EMPLOYMENT_FILTER.CURRENT_JOB_TITLE;
    const isFilteringCompanyNames = companyFilter === EMPLOYMENT_FILTER.COMPANY_NAME;
    const isFilteringCurrentCompanyNames = companyFilter === EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME;

    const activeJobFiltersCount =
        jobTitles.length +
        currentJobTitles.length +
        excludedJobTitles.length +
        excludedCurrentJobTitles.length;

    const activeCompanyFiltersCount =
        companyNames.length +
        currentCompanyNames.length +
        excludedCurrentCompanyNames.length +
        excludedCompanyNames.length;

    const activeOccupationFiltersCount =
        occupations.length +
        currentOccupations.length +
        excludedOccupations.length +
        excludedCurrentOccupations.length;

    const activeIndustryFiltersCount =
        industries.length +
        excludedIndustries.length +
        currentIndustries.length +
        excludedCurrentIndustries.length;

    const activeExperienceFiltersCount = experienceFilterCount;

    const activeFiltersCount =
        activeJobFiltersCount +
        activeCompanyFiltersCount +
        activeExperienceFiltersCount +
        activeOccupationFiltersCount +
        activeIndustryFiltersCount;

    return (
        <FilterAccordion
            id="filter-employment"
            onChange={handleAccordionChange}
            data-trackid={
                open ? 'experience-filters-drawer-collapse' : 'experience-filters-drawer-expand'
            }
        >
            <FilterSummary
                expandIcon={<ChevronDownIcon />}
                aria-controls="filter-employment-content"
                aria-label="filter-employment-header"
            >
                <SuitcaseIcon sx={{ color: (theme) => alpha(theme.palette.common.white, 0.48) }} />
                <Typography
                    variant="labelSmall"
                    sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
                >
                    Work Experience
                </Typography>
                <SummaryActions>
                    {Boolean(activeFiltersCount) && (
                        <SummaryAction
                            variant="text"
                            onClick={handleClearEmployment}
                            aria-label="filter-employment-clear"
                            data-trackid="experience-filters-drawer-clear"
                        >
                            Clear
                        </SummaryAction>
                    )}
                    <SummaryChip label={activeFiltersCount} />
                </SummaryActions>
            </FilterSummary>

            <FilterDetails id="filter-employment-content">
                <MultiTextInput
                    fullWidth
                    disableTags
                    freeSolo
                    disableClearable
                    value={employmentValue()}
                    ref={employmentMenu}
                    openOnFocus={false}
                    forcePopupIcon={false}
                    PaperComponent={StyledPaper}
                    onChange={handleEmploymentChange}
                    id="filter-employment-multi-input"
                    label={
                        <FilterLabel
                            label={
                                <Box sx={{ display: 'flex' }}>
                                    <Typography variant="bodySmall">
                                        {jobLabel[employmentFilter]}
                                    </Typography>
                                    <CustomWidthTooltip
                                        title={JobTitleTooltip}
                                        placement="top"
                                        arrow
                                        sx={{ ml: 0.5 }}
                                    >
                                        <Box>
                                            <InfoIcon
                                                sx={{
                                                    mb: -0.5,
                                                    ml: 0.3,
                                                    fontSize: 16,
                                                    cursor: 'pointer',
                                                    color: 'white',
                                                }}
                                            />
                                        </Box>
                                    </CustomWidthTooltip>
                                </Box>
                            }
                            checked={excludeMatch}
                            onChange={(e) => handleExcludeMatchChange(e, 'job-employment')}
                            dataTrackId="job-title-filter-exclude"
                        />
                    }
                    inputProps={{
                        'data-trackid': 'job-title-filter-input',
                    }}
                    TextInputProps={{
                        sx: AutocompleteSx,
                        InputProps: {
                            ...InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start" sx={{ mr: 0 }}>
                                        <StyledSelect
                                            data-trackid="job-title-filter-toggle-dropdown"
                                            inputProps={{
                                                id: 'filter-employment-menu',
                                            }}
                                            value={employmentFilter}
                                            onChange={handleJobFilter}
                                            IconComponent={ChevronDownIcon}
                                            MenuProps={employmentMenuProps(employmentMenu)}
                                            renderValue={(value) => {
                                                if (value === EMPLOYMENT_FILTER.JOB_TITLE) {
                                                    return (
                                                        <StyledPastJobTitleIcon
                                                            sx={{
                                                                color: alpha('#ffffff', 0.72),
                                                                fontSize: '16px',
                                                                padding: '5px 5px',
                                                                margin: 0,
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <StyledJobTitleIcon
                                                        value={value}
                                                        sx={{
                                                            color: alpha('#ffffff', 0.72),
                                                            fontSize: '16px',
                                                            padding: '5px 5px',
                                                            margin: 0,
                                                        }}
                                                    />
                                                );
                                            }}
                                        >
                                            <FilterMenuItem
                                                active={isFilteringJobTitles}
                                                value={EMPLOYMENT_FILTER.JOB_TITLE}
                                                aria-label={`${EMPLOYMENT_FILTER.JOB_TITLE}-menu-item`}
                                            >
                                                <ListItemIcon sx={{ color: 'common.white' }}>
                                                    <StyledPastJobTitleIcon />
                                                </ListItemIcon>
                                                <Typography variant="titleSmall">
                                                    {jobLabel[EMPLOYMENT_FILTER.JOB_TITLE]}
                                                </Typography>
                                                {isFilteringJobTitles && (
                                                    <CheckCircleIcon
                                                        color="success"
                                                        sx={{ ml: 'auto' }}
                                                    />
                                                )}
                                            </FilterMenuItem>
                                            <FilterMenuItem
                                                active={isFilteringCurrentJobTitles}
                                                value={EMPLOYMENT_FILTER.CURRENT_JOB_TITLE}
                                                aria-label={`${EMPLOYMENT_FILTER.CURRENT_JOB_TITLE}-menu-item`}
                                            >
                                                <ListItemIcon sx={{ color: 'common.white' }}>
                                                    <StyledJobTitleIcon />
                                                </ListItemIcon>
                                                <Typography variant="titleSmall">
                                                    {jobLabel[EMPLOYMENT_FILTER.CURRENT_JOB_TITLE]}
                                                </Typography>
                                                {isFilteringCurrentJobTitles && (
                                                    <CheckCircleIcon
                                                        color="success"
                                                        sx={{ ml: 'auto' }}
                                                    />
                                                )}
                                            </FilterMenuItem>
                                        </StyledSelect>
                                    </InputAdornment>
                                </>
                            ),
                        },
                    }}
                />
                <>
                    {Boolean(jobTitles.length) && (
                        <>
                            <IncludeExcludeLabel
                                label="Include"
                                filterKey={EMPLOYMENT_FILTER.JOB_TITLE}
                                enableAnyAllSelect
                            />
                            <FilterChipContainer>
                                {jobTitles.map((jobTitle, index) => (
                                    <IncludedChip
                                        key={`${EMPLOYMENT_FILTER.JOB_TITLE}-included-chip-${jobTitle}-${index}`}
                                        index={index}
                                        label={
                                            <>
                                                <Tooltip title="Past & Current Job Title Filter">
                                                    <IconContainer>
                                                        <StyledPastJobTitleIcon sx={{ mb: -0.2 }} />
                                                    </IconContainer>
                                                </Tooltip>
                                                {jobTitle}
                                            </>
                                        }
                                        onClick={handleClickExclude}
                                        onDelete={handleDeleteEmployment}
                                        filterKey={EMPLOYMENT_FILTER.JOB_TITLE}
                                        isCustomLabel
                                    />
                                ))}
                            </FilterChipContainer>
                        </>
                    )}
                    {Boolean(currentJobTitles.length) && (
                        <>
                            <IncludeExcludeLabel
                                label="Include"
                                filterKey={EMPLOYMENT_FILTER.CURRENT_JOB_TITLE}
                                enableAnyAllSelect
                            />
                            <FilterChipContainer>
                                {currentJobTitles.map((jobTitle, index) => (
                                    <IncludedChip
                                        key={`${EMPLOYMENT_FILTER.CURRENT_JOB_TITLE}-included-chip-${jobTitle}-${index}`}
                                        label={
                                            <>
                                                <Tooltip title="Current Job Title Filter">
                                                    <IconContainer>
                                                        <StyledJobTitleIcon sx={{ mb: -0.2 }} />
                                                    </IconContainer>
                                                </Tooltip>
                                                {jobTitle}
                                            </>
                                        }
                                        index={index}
                                        onClick={handleClickExclude}
                                        onDelete={handleDeleteEmployment}
                                        filterKey={EMPLOYMENT_FILTER.CURRENT_JOB_TITLE}
                                        isCustomLabel
                                    />
                                ))}
                            </FilterChipContainer>
                        </>
                    )}
                </>
                {(Boolean(excludedJobTitles.length) ||
                    Boolean(excludedCurrentJobTitles.length)) && (
                    <>
                        <IncludeExcludeLabel label="Exclude" />
                        <FilterChipContainer>
                            {excludedJobTitles.map((jobTitle, index) => (
                                <ExcludedChip
                                    key={`${EMPLOYMENT_FILTER.JOB_TITLE}-excluded-chip-${jobTitle}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Past & Current Job Title Filter">
                                                <IconContainer>
                                                    <StyledPastJobTitleIcon sx={{ mb: -0.2 }} />
                                                </IconContainer>
                                            </Tooltip>

                                            {jobTitle}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={handleDeleteExcludedEmployment}
                                    filterKey={EMPLOYMENT_FILTER.JOB_TITLE}
                                    isCustomLabel
                                />
                            ))}
                            {excludedCurrentJobTitles.map((jobTitle, index) => (
                                <ExcludedChip
                                    key={`${EMPLOYMENT_FILTER.CURRENT_JOB_TITLE}-excluded-chip-${jobTitle}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Current Job Title Filter">
                                                <IconContainer>
                                                    <StyledJobTitleIcon sx={{ mb: -0.2 }} />
                                                </IconContainer>
                                            </Tooltip>

                                            {jobTitle}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={handleDeleteExcludedEmployment}
                                    filterKey={EMPLOYMENT_FILTER.CURRENT_JOB_TITLE}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
            </FilterDetails>

            <Box sx={{ px: 2, pb: 0.75 }}>
                <StyledDivider />
            </Box>

            <FilterDetails id="filter-company-content">
                <MultiTextInput
                    fullWidth
                    disableTags
                    freeSolo
                    disableClearable
                    value={companyEmploymentValue()}
                    ref={companyMenu}
                    openOnFocus={false}
                    forcePopupIcon={false}
                    PaperComponent={StyledPaper}
                    onChange={handleCompanyEmploymentChange}
                    id="filter-company-multi-input"
                    label={
                        <FilterLabel
                            label={companyLabel[companyFilter]}
                            checked={excludeCompanyMatch}
                            onChange={(e) => handleExcludeMatchChange(e, 'company-employment')}
                            dataTrackId="company-name-filter-exclude"
                        />
                    }
                    inputProps={{
                        'data-trackid': 'job-title-filter-input',
                        onPaste: handleCompanyPaste,
                    }}
                    TextInputProps={{
                        sx: AutocompleteSx,
                        InputProps: {
                            ...InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start" sx={{ mr: 0 }}>
                                        <StyledSelect
                                            data-trackid="company-name-filter-toggle-dropdown"
                                            inputProps={{
                                                id: 'filter-company-menu',
                                            }}
                                            value={companyFilter}
                                            onChange={handleCompanyFilter}
                                            IconComponent={ChevronDownIcon}
                                            MenuProps={employmentMenuProps(companyMenu)}
                                            renderValue={(value) => {
                                                if (value === EMPLOYMENT_FILTER.COMPANY_NAME) {
                                                    return (
                                                        <StyledPastCompanyIcon
                                                            data-testid="company-icon"
                                                            sx={{
                                                                color: alpha('#ffffff', 0.48),
                                                                fontSize: '19px',
                                                                margin: 0,
                                                                padding: '2.6px 2.6px',
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <BuildingIcon
                                                        data-testid="current-company-icon"
                                                        value={value}
                                                        sx={{
                                                            color: alpha('#ffffff', 0.48),
                                                        }}
                                                    />
                                                );
                                            }}
                                        >
                                            <FilterMenuItem
                                                active={isFilteringCompanyNames}
                                                value={EMPLOYMENT_FILTER.COMPANY_NAME}
                                                aria-label={`${EMPLOYMENT_FILTER.COMPANY_NAME}-menu-item`}
                                            >
                                                <ListItemIcon
                                                    sx={{ color: 'common.white', minWidth: 20 }}
                                                >
                                                    <StyledPastCompanyIcon
                                                        sx={{ fontSize: '19px' }}
                                                    />
                                                </ListItemIcon>
                                                <Typography
                                                    variant="titleSmall"
                                                    sx={{ fontSize: '12px', pr: '5px' }}
                                                >
                                                    {companyLabel[EMPLOYMENT_FILTER.COMPANY_NAME]}
                                                </Typography>
                                                {isFilteringCompanyNames && (
                                                    <CheckCircleIcon
                                                        color="success"
                                                        sx={{ ml: 'auto' }}
                                                    />
                                                )}
                                            </FilterMenuItem>
                                            <FilterMenuItem
                                                active={isFilteringCurrentCompanyNames}
                                                value={EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME}
                                                aria-label={`${EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME}-menu-item`}
                                            >
                                                <ListItemIcon sx={{ color: 'common.white' }}>
                                                    <BuildingIcon />
                                                </ListItemIcon>
                                                <Typography variant="titleSmall">
                                                    {
                                                        companyLabel[
                                                            EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME
                                                        ]
                                                    }
                                                </Typography>
                                                {isFilteringCurrentCompanyNames && (
                                                    <CheckCircleIcon
                                                        color="success"
                                                        sx={{ ml: 'auto' }}
                                                    />
                                                )}
                                            </FilterMenuItem>
                                        </StyledSelect>
                                    </InputAdornment>
                                </>
                            ),
                        },
                    }}
                />
                {Boolean(companyNames.length) && (
                    <>
                        <IncludeExcludeLabel
                            label="Include"
                            filterKey={EMPLOYMENT_FILTER.COMPANY_NAME}
                            enableAnyAllSelect
                        />
                        <FilterChipContainer>
                            {companyNames.map((companyName, index) => (
                                <IncludedChip
                                    key={`${EMPLOYMENT_FILTER.COMPANY_NAME}-included-chip-${companyName}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Past & Current Company Name Filter">
                                                <IconContainer>
                                                    <StyledPastCompanyIcon />
                                                </IconContainer>
                                            </Tooltip>
                                            {companyName}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickExclude}
                                    onDelete={handleDeleteEmployment}
                                    filterKey={EMPLOYMENT_FILTER.COMPANY_NAME}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
                {Boolean(currentCompanyNames.length) && (
                    <>
                        <IncludeExcludeLabel
                            label="Include"
                            filterKey={EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME}
                            enableAnyAllSelect
                        />
                        <FilterChipContainer>
                            {currentCompanyNames.map((companyName, index) => (
                                <IncludedChip
                                    key={`${EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME}-included-chip-${companyName}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Current Company Name Filter">
                                                <IconContainer>
                                                    <StyledBuildingIcon />
                                                </IconContainer>
                                            </Tooltip>
                                            {companyName}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickExclude}
                                    onDelete={handleDeleteEmployment}
                                    filterKey={EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
                {(Boolean(excludedCompanyNames.length) ||
                    Boolean(excludedCurrentCompanyNames.length)) && (
                    <>
                        <IncludeExcludeLabel label="Exclude" />
                        <FilterChipContainer>
                            {excludedCompanyNames.map((companyName, index) => (
                                <ExcludedChip
                                    key={`${EMPLOYMENT_FILTER.COMPANY_NAME}-excluded-chip-${companyName}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Past & Current Company Name Filter">
                                                <IconContainer>
                                                    <StyledPastCompanyIcon />
                                                </IconContainer>
                                            </Tooltip>
                                            {companyName}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={handleDeleteExcludedEmployment}
                                    filterKey={EMPLOYMENT_FILTER.COMPANY_NAME}
                                    isCustomLabel
                                />
                            ))}
                            {excludedCurrentCompanyNames.map((companyName, index) => (
                                <ExcludedChip
                                    key={`${EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME}-excluded-chip-${companyName}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Current Company Name Filter">
                                                <IconContainer>
                                                    <StyledBuildingIcon />
                                                </IconContainer>
                                            </Tooltip>
                                            {companyName}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={handleDeleteExcludedEmployment}
                                    filterKey={EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
            </FilterDetails>
            {isOccupationEnabled && (
                <>
                    <Box sx={{ px: 2 }}>
                        <StyledDivider />
                    </Box>

                    <FilterOccupations
                        handleClickExclude={handleClickExclude}
                        handleClickInclude={handleClickInclude}
                        handleDeleteEmployment={handleDeleteEmployment}
                        handleDeleteExcludedEmployment={handleDeleteExcludedEmployment}
                        occupations={occupations}
                        currentOccupations={currentOccupations}
                        excludedOccupations={excludedOccupations}
                        excludedCurrentOccupations={excludedCurrentOccupations}
                    />
                </>
            )}

            {isIndustryEnabled && (
                <>
                    <Box sx={{ px: 2 }}>
                        <StyledDivider />
                    </Box>

                    <FilterIndustries
                        handleClickExclude={handleClickExclude}
                        handleClickInclude={handleClickInclude}
                        handleDeleteEmployment={handleDeleteEmployment}
                        handleDeleteExcludedEmployment={handleDeleteExcludedEmployment}
                        industries={industries}
                        currentIndustries={currentIndustries}
                        excludedIndustries={excludedIndustries}
                        excludedCurrentIndustries={excludedCurrentIndustries}
                    />
                </>
            )}

            <Box sx={{ px: 2 }}>
                <StyledDivider />
            </Box>

            <FilterDetails id="filter-experience-content">
                <Box>
                    <ExperienceSlider
                        title="Years of Professional Experience"
                        tooltip="This filter will use total years of professional experience, and is not restricted to total years of relevant experience."
                        onChangeYearsOfExperience={handleYearsOfExperience}
                    />
                </Box>
            </FilterDetails>
        </FilterAccordion>
    );
}
