import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetBulkActivityQuery } from 'services/activity';
import { setActivities, setIsLoading } from '../activitySlice';

export function useBulkActivity(activityDepth) {
    const dispatch = useDispatch();

    const talentIds = useSelector((state) => state.activity.talentIds);

    const args = useMemo(
        () => ({ body: { talentIds }, params: { activityDepth } }),
        [talentIds, activityDepth]
    );

    const { data, isFetching } = useGetBulkActivityQuery(args, { skip: talentIds.length === 0 });

    useEffect(() => {
        dispatch(setIsLoading(isFetching));
    }, [dispatch, isFetching]);

    useEffect(() => {
        if (data?.talentActivity) dispatch(setActivities(data.talentActivity));
    }, [dispatch, data]);
}
