import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiMenu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import { alpha, styled } from '@mui/material/styles';

import {
    KeyIcon,
    CloseIcon,
    SuitcaseIcon,
    BuildingIcon,
    EducationIcon,
    PastCompanyIcon,
    PastOccupationsIcon,
} from 'components/Icons';
import {
    EDUCATION_FILTER,
    EMPLOYMENT_FILTER,
} from 'views/Search/features/FiltersBar/components/constants';
import { Typography } from 'components/Typography';
import { FilterMenuOption } from './FilterMenuOption';

const Menu = styled(MuiMenu)(({ theme }) => ({
    '& .MuiMenu-paper': {
        background: theme.palette.text.primary,
        marginTop: theme.spacing(0.25),
        width: theme.spacing(30),
        color: theme.palette.common.white,
        '& ul': {
            paddingTop: 0,
            paddingBottom: theme.spacing(0.5),
        },
    },
}));

const InfoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    background: alpha(theme.palette.primary.main, 0.24),
}));

export function FilterMenu(props) {
    const {
        onAddKeywordFilter,
        onAddKeywordExcludeFilter,
        createStandardFilterHandler,
        createStandardExcludeFilterHandler,
        ...restProps
    } = props;

    const [infoPanelOpen, setInfoPanelOpen] = useState(true);

    const menuOptionsProps = [
        {
            onAddFilter: onAddKeywordFilter,
            onAddExcludeFilter: onAddKeywordExcludeFilter,
            Icon: KeyIcon,
            iconFontSize: 17.5,
            name: 'keyword',
            label: 'Keyword',
        },
        {
            onAddFilter: createStandardFilterHandler(EDUCATION_FILTER.DEGREES),
            onAddExcludeFilter: createStandardExcludeFilterHandler(EDUCATION_FILTER.DEGREES),
            Icon: EducationIcon,
            iconFontSize: 17.5,
            name: 'degrees',
            label: 'Majors/Degrees',
        },
        {
            onAddFilter: createStandardFilterHandler(EDUCATION_FILTER.SCHOOLS),
            onAddExcludeFilter: createStandardExcludeFilterHandler(EDUCATION_FILTER.SCHOOLS),
            Icon: EducationIcon,
            iconFontSize: 17.5,
            name: 'past-and-current-school',
            label: 'Past & Current Schools',
        },
        {
            onAddFilter: createStandardFilterHandler(EMPLOYMENT_FILTER.JOB_TITLE),
            onAddExcludeFilter: createStandardExcludeFilterHandler(EMPLOYMENT_FILTER.JOB_TITLE),
            Icon: PastOccupationsIcon,
            iconFontSize: 12,
            name: 'past-and-current-job',
            label: 'Past & Current Job Titles',
        },
        {
            onAddFilter: createStandardFilterHandler(EMPLOYMENT_FILTER.CURRENT_JOB_TITLE),
            onAddExcludeFilter: createStandardExcludeFilterHandler(
                EMPLOYMENT_FILTER.CURRENT_JOB_TITLE
            ),
            Icon: SuitcaseIcon,
            iconFontSize: 19,
            name: 'current-job',
            label: 'Current Job Title',
        },
        {
            onAddFilter: createStandardFilterHandler(EMPLOYMENT_FILTER.COMPANY_NAME),
            onAddExcludeFilter: createStandardExcludeFilterHandler(EMPLOYMENT_FILTER.COMPANY_NAME),
            Icon: PastCompanyIcon,
            iconFontSize: 12,
            name: 'past-and-current-companies',
            label: 'Past & Current Companies',
        },
        {
            onAddFilter: createStandardFilterHandler(EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME),
            onAddExcludeFilter: createStandardExcludeFilterHandler(
                EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME
            ),
            Icon: BuildingIcon,
            iconFontSize: 16,
            name: 'current-company',
            label: 'Current Company',
        },
    ];

    return (
        <Menu
            id="add-to-search-filter-menu"
            aria-labelledby="add-to-search-filter-menu"
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            {...restProps}
        >
            <Box sx={{ pt: 0.5, px: 1 }}>
                <Typography variant="bodySmallBold" component="p" sx={{ mb: 1 }}>
                    Add selection as a filter
                </Typography>
                {infoPanelOpen && (
                    <InfoBox>
                        <Typography variant="bodyXSmall" sx={{ fontWeight: 400 }} component="p">
                            Adding filters will remove results that do not fit your filter criteria.
                        </Typography>
                        <IconButton
                            aria-label="dismiss-info"
                            data-trackid="add-as-filter-close-tooltip"
                            onClick={() => setInfoPanelOpen(false)}
                            sx={{ p: '1px', color: '#fff', opacity: 0.48 }}
                            disableRipple
                        >
                            <CloseIcon sx={{ fontSize: 20.55 }} />
                        </IconButton>
                    </InfoBox>
                )}
            </Box>
            {menuOptionsProps.map((optionProps) => (
                <FilterMenuOption key={`aaf-${optionProps.name}`} {...optionProps} />
            ))}
        </Menu>
    );
}

FilterMenu.propTypes = {
    onAddKeywordFilter: PropTypes.func,
    onAddKeywordExcludeFilter: PropTypes.func,
    createStandardFilterHandler: PropTypes.func,
    createStandardExcludeFilterHandler: PropTypes.func,
};
