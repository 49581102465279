import SvgIcon from '@mui/material/SvgIcon';

export function PencilIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M30.6077 2.47454L29.8999 1.768C28.6705 0.540876 27.497 -0.0354965 26.3049 0.00168924C25.001 0.0388749 24.0883 0.819775 23.5109 1.39615L3.37524 21.6252C2.7978 22.2016 2.38802 22.9081 2.1645 23.7076L0.618496 29.0623C0.339093 29.8432 0.562611 30.6985 1.19592 31.3307C1.64297 31.7769 2.20173 32 2.77916 32C2.98406 32 3.18899 31.9628 3.39388 31.907L8.62803 30.3638C9.46624 30.1221 10.2486 29.6573 10.8633 29.0437L31.0362 8.83329C32.4332 7.43883 33.6439 5.43082 30.6264 2.45596L30.6077 2.47454ZM8.19959 26.4407C8.03195 26.6081 7.80842 26.7382 7.54764 26.8126L4.88401 27.5935L5.7036 24.7488C5.75948 24.5628 5.8526 24.3955 5.98299 24.2654L20.9404 9.24234L23.157 11.4549L8.181 26.4593L8.19959 26.4407ZM28.3725 6.24889L26.4726 8.16395L24.2374 5.9514L26.1373 4.03633C26.3049 3.869 26.3981 3.79464 26.4167 3.75745C26.5284 3.81323 26.8079 3.96198 27.2736 4.4268L27.9813 5.13331C28.3725 5.52376 28.5774 5.78406 28.6706 5.9514C28.5774 6.06295 28.447 6.1931 28.3725 6.26747V6.24889Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
