import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { useMemo, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

import { Typography } from 'components/Typography';
import { ActionsMenuItem } from 'components/ActionsMenu';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';
import { ProjectsIcon, PeopleIcon, CheckIcon, IconWithTooltip, InfoIcon } from 'components/Icons';
import { useCustomMutation, useCustomQuery } from 'hooks/useCustomQuery';
import { addTalentsToProjectApi, getProjectsApi } from 'views/Projects/services';
import { useSwitchboard } from 'features/Switchboard';
import { useSelector } from 'react-redux';
import {
    selectSearchTalentIds,
    selectShortlistTalentIds,
    selectSearchTalent,
    selectShortlistTalent,
} from 'features/TalentCard/TalentBatchActions';
import { areAllElementsIncluded } from 'utils/helpers';
import { pluralize } from 'utils/copy';

const MENU_WIDTH = 280;

const AddToProjectMenuItem = styled(MenuItem)(({ theme }) => ({
    width: MENU_WIDTH,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        minHeight: theme.spacing(7),
    },
    '&.Mui-disabled': {
        opacity: 1,
    },
}));

const ProjectsCheck = styled(Box)(({ theme }) => ({
    marginLeft: 'auto',
    backgroundColor: alpha(theme.palette.success.main, 0.24),
    color: theme.palette.success.main,
    borderRadius: '100%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
}));

const getSelector = (source) => {
    return source === 'search' ? selectSearchTalentIds : selectShortlistTalentIds;
};
const getEntitySelector = (source) =>
    source === 'search' ? selectSearchTalent : selectShortlistTalent;

export function AddToProjectButton(props) {
    const { talentId, profileId, isBulk, talentSource } = props;

    const isProjectsFeatureEnabled = useSwitchboard('FEATURE_PROJECTS');
    const selectedTalentIds = useSelector(getSelector(talentSource));
    const selectedTalentProfiles = useSelector(getEntitySelector(talentSource));

    const [anchorEl, setAnchorEl] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchedProjects, setFetchedProjects] = useState([]);
    const [currentLoadingProject, setCurrentLoadingProject] = useState({});

    const isMenuOpen = Boolean(anchorEl);

    const [logSearchEvent] = useSearchTelemetry();

    const { isLoading: isProjectsLoading, isFetching: isFetchingProjects } = useCustomQuery(
        ['projects', { view: 'context_menu', status: 'active' }],
        getProjectsApi,
        {
            disabled: isMenuOpen && !!isProjectsFeatureEnabled,
            refetchOnWindowFocus: false,
            onSuccess: (data) => setFetchedProjects(data),
            onError: () => toast.error('Something went wrong loading your projects'),
        }
    );

    const {
        mutate: addTalentsToProject,
        isError: addTalentErr,
        isLoading: isAddTalentLoading,
    } = useCustomMutation(addTalentsToProjectApi, {
        onError: () => {
            const errMsg = `Failed to add ${pluralize(
                'Candidate',
                selectedTalentProfiles.length
            )} to project`;
            toast.error(`${errMsg} please try again or try another project`);
            setErrorMessage(errMsg);
        },
        onSuccess: (data) => {
            const newProjects = fetchedProjects.map((project) => {
                if (project.id === data.id) {
                    project['talentIds'] = [...data.talentIds];
                }
                return project;
            });
            setFetchedProjects([...newProjects]);
            setCurrentLoadingProject({});
            toast.success(
                `${pluralize(
                    'Candidate',
                    selectedTalentProfiles.length
                )} successfully added to project`
            );
        },
    });

    const isLoading = isProjectsLoading || isFetchingProjects || isAddTalentLoading;

    const isTalentInProject = (project) => {
        const projectTalentIds = project.talentIds;
        return isBulk
            ? areAllElementsIncluded(selectedTalentIds, projectTalentIds)
            : projectTalentIds.includes(talentId);
    };

    const projectsData = useMemo(() => {
        const projects = fetchedProjects.map((project) => ({
            id: project.id,
            name: project.name,
            totalTalentCount: project.talentIds.length,
            isInProject: isTalentInProject(project),
        }));
        return projects;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedProjects]);

    const hasProjects = (projectsData) => projectsData && projectsData.length;

    if (!isProjectsFeatureEnabled) {
        return null;
    }

    const buttonRect = anchorEl?.getBoundingClientRect();
    const isMenuOnRight = buttonRect?.right > window.innerWidth - MENU_WIDTH;

    const horizontalTransform = isMenuOnRight ? 'right' : 'left';

    const handleOpenMenu = (event) => {
        setErrorMessage('');
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setErrorMessage('');
        setAnchorEl(null);
    };

    const handleAddToProject = (projectId) => {
        setErrorMessage('');
        setCurrentLoadingProject({
            [projectId]: true,
        });
        addTalentsToProject({
            projectId,
            data: {
                talents: isBulk
                    ? selectedTalentProfiles.map(({ profileId, talentId }) => ({
                          profileId,
                          talentId,
                      }))
                    : [{ talentId, profileId }],
            },
        });
        logSearchEvent(SEARCH_EVENT_TYPES.ADD_TO_PROJECT, {
            projectId,
            profileIds: isBulk ? selectedTalentIds : [talentId],
        });
    };

    if (!isProjectsFeatureEnabled) {
        return null;
    }

    return (
        <>
            <ActionsMenuItem
                data-trackid="talent-actions-add-to-project"
                aria-label="add-to-project"
                id="add-to-project"
                aaria-controls={isMenuOpen ? 'projects-menu' : undefined}
                aria-expanded={isMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
                variant="primary"
                open={isMenuOpen}
                onClick={handleOpenMenu}
                Icon={ProjectsIcon}
                IconProps={{ height: 20, width: 20, ml: 0.2, mr: 0.3 }}
                label="Add To Project"
            />

            <Menu
                sx={{
                    borderRadius: 0,
                    '& .MuiPaper-root': {
                        maxHeight: 240,
                        boxShadow:
                            '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                        borderRadius: (theme) =>
                            horizontalTransform === 'left'
                                ? theme.spacing(0, 0.5, 0.5, 0.0)
                                : theme.spacing(0.5, 0, 0.0, 0.5),
                    },
                }}
                aria-label="projects-menu"
                id="projects-menu"
                MenuListProps={{
                    'aria-labelledby': 'add-to-project',
                    sx: { p: 0 },
                }}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: horizontalTransform === 'right' ? 'left' : 'right',
                }}
                transformOrigin={{ vertical: 'top', horizontal: horizontalTransform }}
            >
                {hasProjects(projectsData)
                    ? projectsData.map((project) => {
                          return (
                              <AddToProjectMenuItem
                                  key={project.id}
                                  aria-label={`add-to-project-${project.name}`}
                                  onClick={() => handleAddToProject(project.id)}
                                  disabled={isLoading || project.isInProject}
                                  disableRipple
                              >
                                  <Typography
                                      variant="titleSmall"
                                      sx={{ mr: 1, maxWidth: '70%' }}
                                      noWrap
                                  >
                                      {project.name}
                                  </Typography>
                                  <Box
                                      sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          mr: 'auto',
                                      }}
                                  >
                                      <PeopleIcon sx={{ pl: 0.5, mb: -0.25, fontSize: '1rem' }} />
                                      <Typography
                                          variant="labelLarge"
                                          sx={{
                                              ml: 0.5,
                                              lineHeight: '16px',
                                              color: (theme) =>
                                                  alpha(theme.palette.text.primary, 0.72),
                                          }}
                                          noWrap
                                      >
                                          {project.totalTalentCount}
                                      </Typography>
                                  </Box>
                                  {!currentLoadingProject[project.id] && project.isInProject && (
                                      <ProjectsCheck>
                                          <CheckIcon />
                                      </ProjectsCheck>
                                  )}
                                  {currentLoadingProject[project.id] &&
                                      addTalentErr &&
                                      Boolean(errorMessage) && (
                                          <IconWithTooltip
                                              title={errorMessage}
                                              Icon={InfoIcon}
                                              IconProps={{
                                                  sx: { color: 'red', mt: 1 },
                                                  fontSize: 'small',
                                              }}
                                          />
                                      )}
                                  {currentLoadingProject[project.id] && isAddTalentLoading && (
                                      <CircularProgress size={20} color="success" />
                                  )}
                              </AddToProjectMenuItem>
                          );
                      })
                    : !isLoading &&
                      !hasProjects(projectsData) && (
                          <AddToProjectMenuItem disabled>
                              <Typography variant="labelSmall" sx={{ mr: 'auto' }}>
                                  You don't have any Projects
                              </Typography>
                          </AddToProjectMenuItem>
                      )}
                {isLoading && (
                    <AddToProjectMenuItem disabled>
                        <Typography variant="labelSmall" sx={{ mr: 'auto' }}>
                            Loading Projects...
                        </Typography>
                        <CircularProgress size={20} color="success" />
                    </AddToProjectMenuItem>
                )}
            </Menu>
        </>
    );
}

AddToProjectButton.propTypes = {
    talentId: PropTypes.string,
    profileId: PropTypes.string,
    isBulk: PropTypes.bool,
    talentSource: PropTypes.string,
};
