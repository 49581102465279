import { subDays } from 'date-fns';

export const getToday = () => {
    return new Date();
};

export const getThirtyDaysAgo = (today) => {
    return subDays(today, 30);
};

export const getMostRecentSunday = (today) => {
    return new Date(today.setDate(today.getDate() - today.getDay()));
};

export const getLastWeekSunday = (today) => {
    const thisSunday = getMostRecentSunday(today);
    return new Date(today.setDate(thisSunday.getDate() - 7));
};

export const getLastWeekSaturday = (today) => {
    const thisSunday = getMostRecentSunday(today);
    return new Date(today.setDate(thisSunday.getDate() - 1));
};

export const getFirstDayInTheMonth = (today) => {
    return new Date(today.getFullYear(), today.getMonth(), 1);
};

export const getFirstDayInPreviousMonth = (today) => {
    return new Date(today.getFullYear(), today.getMonth() - 1, 1);
};

export const getLastDayInPreviousMonth = (today) => {
    return new Date(today.getFullYear(), today.getMonth(), 0);
};
