import { useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { FlagIcon, VerifyContactIcon, ReportContactIcon } from 'components/Icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CONTACT_TYPES } from '../utils/constants';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(0),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const getContactType = (contactType) => {
    if (contactType === CONTACT_TYPES.EMAIL) {
        return 'email';
    }
    if (contactType === CONTACT_TYPES.PHONE) {
        return 'sms';
    }
    return 'social';
};

export function FlagContact(props) {
    const { flagContact, contactId, contactMedium } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleVerifyContact = () => {
        setAnchorEl(null);
        flagContact({ body: { feedback: 1, contactId } });
    };
    const handleReportContact = () => {
        setAnchorEl(null);
        flagContact({ body: { feedback: 0, contactId } });
    };

    return (
        <>
            <FlagIcon
                sx={{ mt: 1.5, ml: 0.5, cursor: 'pointer' }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                data-testid="flag-contact"
                data-trackid={`lookup-modal-${getContactType(contactMedium)}-flag`}
            />

            <StyledMenu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
                <MenuItem
                    onClick={handleVerifyContact}
                    disableRipple
                    aria-label="verify-contact"
                    data-trackid={`${getContactType(contactMedium)}-flag-verify`}
                >
                    <VerifyContactIcon />
                    Verify Contact
                </MenuItem>
                <MenuItem
                    onClick={handleReportContact}
                    disableRipple
                    aria-label="report-bad-contact"
                    data-trackid={`${getContactType(contactMedium)}-flag-report`}
                >
                    <ReportContactIcon />
                    Report Bad Contact
                </MenuItem>
            </StyledMenu>
        </>
    );
}

FlagContact.propTypes = {
    verifyContact: PropTypes.func,
    flagContact: PropTypes.func,
    contactId: PropTypes.string,
    contactMedium: PropTypes.string,
};
