import { createSlice } from '@reduxjs/toolkit';

export const initialDynamicFieldState = {
    dynamicFields: {},
    flatDynamicFields: [],
    defaultDynamicFields: {},
};

export const dynamicFieldsSlice = createSlice({
    name: 'dynamicFields',
    initialState: initialDynamicFieldState,
    reducers: {
        setDynamicFields: (state, { payload }) => {
            state.dynamicFields = payload.dynamicFields;
            state.flatDynamicFields = payload.flatDynamicFields;
        },
        changeDefaultDynamicFields: (state, { payload }) => {
            state.defaultDynamicFields = payload;
        },
        resetDynamicfields: (state) => {
            state.dynamicFields = initialDynamicFieldState.dynamicFields;
            state.flatDynamicFields = initialDynamicFieldState.flatDynamicFields;
        },
        resetDefaultDynamicFields: (state) => {
            state.defaultDynamicFields = initialDynamicFieldState.defaultDynamicFields;
        },
        clearDynamicFieldState: () => {
            return initialDynamicFieldState;
        },
    },
});

export const {
    setDynamicFields,
    resetDynamicfields,
    resetDefaultDynamicFields,
    changeDefaultDynamicFields,
    clearDynamicFieldState,
} = dynamicFieldsSlice.actions;

export const dynamicFieldsReducer = dynamicFieldsSlice.reducer;
