import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { CalendarIcon } from 'components/Icons';
import { useState } from 'react';

export function FilterDate({
    value,
    handleDateChange,
    dateStr,
    dateType,
    name,
    minDate,
    setValue,
    placeholder,
    ...restProps
}) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    value={value}
                    minDate={minDate}
                    onChange={handleDateChange}
                    InputAdornmentProps={{ position: 'start' }}
                    components={{
                        OpenPickerIcon: CalendarIcon,
                    }}
                    open={isOpen}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: placeholder,
                            }}
                            sx={{
                                svg: { color: 'common.white' },
                                input: {
                                    color: 'common.white',
                                    borderBottom: '2px solid rgba(255, 255, 255, 0.12)',
                                    pb: 1,
                                },
                            }}
                            variant="standard"
                            fullWidth
                            aria-label="date-picker"
                            onClick={() => setIsOpen(true)}
                        />
                    )}
                    onClose={() => setIsOpen(false)}
                    name={name}
                    {...restProps}
                />
            </LocalizationProvider>
        </>
    );
}

FilterDate.propTypes = {
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    handleDateChange: PropTypes.func,
    dateStr: PropTypes.string,
    dateType: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    setValue: PropTypes.func,
    minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};
