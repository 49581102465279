import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { CREDITS } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useCreditUsage } from 'hooks/useCreditUsage';
const creditLabel = {
    [CREDITS.JD]: 'JD',
    [CREDITS.SEARCH]: 'Search',
    [CREDITS.LOOKUP]: 'Lookup',
};

const Container = styled('div')({
    position: 'relative',
});

const CreditContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '100%',
    textAlign: 'center',
    width: '100%',
    paddingTop: theme.spacing(0.25),
}));

const CreditTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'belowThreshold',
})(({ theme, belowThreshold }) => ({
    color: theme.palette.text.primary,
    '.credits-spend': {
        fontWeight: 700,
    },
    '.credits-slash': {
        color: alpha(theme.palette.text.primary, 0.72),
    },
    '.credits-remaining': {
        color: belowThreshold ? theme.palette.error.main : null,
    },
}));

export const StyledCreditLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'belowThreshold',
})(({ theme, disabled, belowThreshold }) => {
    const styles = {
        color: theme.palette.primary.main,
        fontWeight: 700,
    };
    if (disabled) {
        styles.pointerEvents = 'none';
        styles.color = theme.palette.text.disabled;
    }
    if (belowThreshold) {
        styles.color = theme.palette.error.main;
    }
    return styles;
});

export function CreditUsage(props) {
    const { creditToSpend, creditType, children, ...restProps } = props;

    const { data, isBelowThreshold, remainingCredits, isUnlimited, isLoading, isError } =
        useCreditUsage(creditType);

    return (
        <Container {...restProps}>
            {typeof children === 'function' ? children(data?.metrics?.[creditType]) : children}

            {!isUnlimited && (
                <CreditContainer>
                    <CreditTypography variant="bodySmall" belowThreshold={isBelowThreshold}>
                        <span className="credits-spend"> {creditToSpend ?? 1}</span>
                        <span className="credits-slash">/</span>
                        {isLoading && <CircularProgress size={8} />}
                        {!isLoading && (
                            <span className="credits-remaining">
                                {isError ? '?' : remainingCredits}
                            </span>
                        )}
                        &nbsp;&nbsp;
                        <StyledCreditLink
                            aria-label="go-to-settings"
                            aria-disabled={isLoading}
                            to="/user-settings"
                            target="_blank"
                            disabled={isLoading}
                            belowThreshold={isBelowThreshold}
                        >
                            {creditLabel[creditType]} Credits
                        </StyledCreditLink>
                    </CreditTypography>
                </CreditContainer>
            )}
        </Container>
    );
}

CreditUsage.propTypes = {
    creditToSpend: PropTypes.number,
    creditType: PropTypes.oneOf([...Object.values(CREDITS)]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.func,
    ]),
};
