import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';

import { CloseIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { TalentDetails } from 'features/TalentNotes/NotesDrawer/components/TalentDetails';
import { TALENT_DRAWER_TABS } from 'utils/constants';
import { StyledIconButton } from '../utils/constants';
import { SourcePoolProfileSelector } from './SourcePoolProfileSelector';

function getDrawerTitle(currentTab) {
    switch (currentTab) {
        case TALENT_DRAWER_TABS.ATTACHMENTS_TAB:
            return 'Attachments';
        case TALENT_DRAWER_TABS.NOTES_TAB:
            return 'Notes';
        case TALENT_DRAWER_TABS.PROFILE_TAB:
            return 'Profile';
        case TALENT_DRAWER_TABS.ACTIVITY_TAB:
            return 'Activity';
        default:
            return '';
    }
}

export function TalentProfileDrawerHeader(props) {
    const {
        talentProfiles,
        setSelectedTalentProfile,
        selectedProfile,
        currentTab,
        handleCloseDrawer,
        isLoading,
        talentData,
        onNextProfile,
        onPreviousProfile,
        disablePrevTalentProfileButton,
        disableNextTalentProfileButton,
        showSourcePoolChip,
    } = props;

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    pt: 0,
                    pb: 0,
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                    }}
                >
                    <Typography variant="headline" aria-label="profile-drawer-header">
                        {getDrawerTitle(currentTab)}
                    </Typography>
                    {!(disablePrevTalentProfileButton && disableNextTalentProfileButton) && (
                        <Box
                            aria-label="navigation-icons"
                            sx={{
                                display: 'inline-flex',
                                gap: 1,
                            }}
                        >
                            <StyledIconButton
                                aria-label="previous-profile-button"
                                onClick={onPreviousProfile}
                                disabled={disablePrevTalentProfileButton}
                                fontSize="small"
                                sx={{ fontWeight: '100' }}
                            >
                                <ArrowBackIosIcon
                                    sx={(theme) => ({
                                        paddingLeft: '9px',
                                        fill: disablePrevTalentProfileButton
                                            ? '#3A8DD333'
                                            : theme.palette.primary.main,
                                    })}
                                />
                            </StyledIconButton>
                            <StyledIconButton
                                aria-label="next-profile-button"
                                onClick={onNextProfile}
                                disabled={disableNextTalentProfileButton}
                                fontSize="small"
                                sx={{ fontWeight: '100' }}
                            >
                                <ArrowForwardIosIcon
                                    sx={(theme) => ({
                                        fill: disableNextTalentProfileButton
                                            ? '#3A8DD333'
                                            : theme.palette.primary.main,
                                    })}
                                />
                            </StyledIconButton>
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {currentTab === 0 && showSourcePoolChip && isLoading && (
                        <Box sx={{ marginTop: '8px' }}>
                            <SourcePoolProfileSelector
                                profiles={talentProfiles}
                                selectedProfile={selectedProfile}
                                onSelect={(profile) => setSelectedTalentProfile(profile)}
                            />
                        </Box>
                    )}
                    <IconButton
                        aria-label="close-talent-profile-drawer"
                        data-trackid="profile-drawer-header-close-drawer-click"
                        onClick={handleCloseDrawer}
                        sx={{ ml: 'auto', mt: 1 }}
                    >
                        <CloseIcon sx={{ color: 'common.charcoal', fontSize: 24 }} />
                    </IconButton>
                </Box>
            </Box>
            {isLoading && (
                <Box sx={{ mt: 2, pl: 2, pr: 2 }}>
                    <TalentDetails talentData={talentData} />
                </Box>
            )}
        </Box>
    );
}

TalentProfileDrawerHeader.propTypes = {
    handleCloseDrawer: PropTypes.func,
    setSelectedTalentProfile: PropTypes.func,
    talentProfiles: PropTypes.array,
    selectedProfile: PropTypes.object,
    isLoading: PropTypes.bool,
    talentData: PropTypes.object,
    currentTab: PropTypes.number,
    onNextProfile: PropTypes.func,
    onPreviousProfile: PropTypes.func,
    disablePrevTalentProfileButton: PropTypes.bool,
    disableNextTalentProfileButton: PropTypes.bool,
    showSourcePoolChip: PropTypes.bool,
};
