import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Typography } from 'components/Typography';
import { alpha, styled } from '@mui/material/styles';

import { ChevronDownIcon, KeyIcon } from 'components/Icons';
import { Tooltip } from 'components/Tooltip';
import ListItemButton from '@mui/material/ListItemButton';
import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { AddToExcludeFilterButton, AddToFilterButton } from './AddToFilterButton';

const ChevronIcon = styled(ChevronDownIcon, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme, isOpen }) => ({
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
    }),
    transform: `rotate(${isOpen ? 180 : 0}deg)`,
}));

const ListItemIconWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    columnGap: theme.spacing(2),
}));

const ListItemContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.2, 1),
}));

function SkillLink({ url }) {
    if (!!url) {
        return (
            <Link
                aria-label="open-skill-in-new-tab"
                data-trackid="talent-skill-see-definition-link"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: '5px',
                    mt: 0.2,
                    textDecoration: 'none',
                    color: 'background.default',
                }}
                href={url}
                target="_blank"
            >
                <ListItemButton sx={{ p: 0 }}>
                    <ListItemContentWrapper>
                        <Typography variant="bodySmall" sx={{ mr: 0.5, mt: 0.2 }}>
                            See Definition
                        </Typography>
                        <OpenInNewOutlined sx={{ width: '12px', height: '12px', mt: 0.2 }} />
                    </ListItemContentWrapper>
                </ListItemButton>
            </Link>
        );
    } else {
        return (
            <ListItemButton sx={{ p: 0 }} disabled>
                <ListItemContentWrapper>
                    <Tooltip title="No definition link exists for this skill.">
                        <Typography variant="bodySmall" sx={{ mr: 0.5, mt: 0.2, mb: 0.5 }}>
                            See Definition
                        </Typography>
                    </Tooltip>
                </ListItemContentWrapper>
            </ListItemButton>
        );
    }
}

export function SkillPopOverLinks(props) {
    const {
        handlePopoverOpen,
        isSkillOpen,
        skill,
        handleSkillOpen,
        isSearch,
        handleAddKeywordFilter,
        handleAddKeywordExcludeFilter,
    } = props;

    return (
        <Paper
            sx={{
                background: 'transparent',
                color: 'background.default',
                boxShadow: 'none',
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverOpen}
        >
            <Tooltip
                placement="top"
                open={Boolean(!isSkillOpen && skill?.match)}
                title="This skill matches a skill found in your search"
                arrow
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: 1,
                        mb: '5px',
                        cursor: 'pointer',
                    }}
                    onClick={handleSkillOpen}
                    data-testid="skill-popover"
                    data-trackid="talent-skill-open"
                >
                    <Typography
                        variant="bodySmall"
                        sx={{
                            mr: 1,
                            fontWeight: skill?.match ? '900' : '400',
                        }}
                        data-testid="skill-popover-text"
                    >
                        {skill.name}
                    </Typography>
                    <ChevronIcon
                        sx={{
                            color: 'background.default',
                            height: 12,
                            width: 12,
                            mt: 0.4,
                        }}
                        isOpen={isSkillOpen}
                    />
                </Box>
            </Tooltip>

            <Divider sx={{ borderColor: alpha('#fff', 0.48) }} />

            {isSkillOpen && (
                <List aria-label="skill-options" sx={{ p: 0 }}>
                    {isSearch && (
                        <>
                            <ListItem sx={{ p: 0 }}>
                                <ListItemContentWrapper
                                    sx={{
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <KeyIcon sx={{ fontSize: '12px' }} />
                                    <Typography variant="bodySmall" sx={{ mr: 2 }}>
                                        Add as Keyword Filter
                                    </Typography>
                                    <ListItemIconWrapper>
                                        <AddToFilterButton
                                            data-trackid="talent-skill-keyword-include"
                                            aria-label="add-to-keyword-filter"
                                            onClick={() => handleAddKeywordFilter(skill.name)}
                                        />
                                        <AddToExcludeFilterButton
                                            data-trackid="talent-skill-keyword-exclude"
                                            aria-label="add-to-exclude-keyword-filter"
                                            onClick={() =>
                                                handleAddKeywordExcludeFilter(skill.name)
                                            }
                                        />
                                    </ListItemIconWrapper>
                                </ListItemContentWrapper>
                            </ListItem>
                            <Divider
                                sx={{
                                    borderColor: alpha('#fff', 0.48),
                                }}
                            />
                        </>
                    )}
                    <SkillLink url={skill.url} />
                </List>
            )}
        </Paper>
    );
}

SkillLink.propTypes = {
    url: PropTypes.string,
};

SkillPopOverLinks.propTypes = {
    skill: PropTypes.object,
    isSkillOpen: PropTypes.bool,
    handlePopoverOpen: PropTypes.func,
    handleAddKeywordFilter: PropTypes.func,
    handleAddKeywordExcludeFilter: PropTypes.func,
    handleSkillOpen: PropTypes.func,
    isSearch: PropTypes.bool,
};
