import { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import Lottie from 'lottie-react';

import { MESSAGE_TYPES, MINIMUM_EMAIL_SUBJECT_LENGTH } from 'utils/constants';
import { Typography, GradientTypography } from 'components/Typography';
import { useDynamicFields } from 'features/DynamicFields';
import { RichTextInput, TextInput } from 'components/Inputs';
import jdLoadingAnimation from 'assets/lottiefiles/nebula-nlg-loading.json';

import { Tooltip } from 'components/Tooltip';
import { TemplateFooter } from './TemplateFooter';
import { SignatureForm } from 'features/Signature';
import { FirstTimeRmmOverlay } from './FirstTimeRmmOverlay';
import { FormattingButton } from 'components/MessageContent/FormattingButton';
import { defaultTooltips, getTooltipProps } from 'features/MessageDrawer/utils/tooltipHelpers';

const TemplateField = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
}));

const LoaderContainer = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    pointerEvents: 'none',
    position: 'absolute',
    top: theme.spacing(2.25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    background: 'linear-gradient(93deg, rgba(80, 76, 224, 0.08) 0%, rgba(49, 141, 222, 0.08) 100%)',
    zIndex: 0.5,
}));

export const CreateTemplate = memo((props) => {
    const {
        isFetching,
        innerHtml,
        innerSubjectHtml,
        initialBody,
        initialSubject,
        templateName,
        selectedChannels,
        showTemplateOverlay,
        handleChangeBody,
        handleChangeName,
        handleChangeSubject,
        editorTemplateBodyRef,
        editorTemplateSubjectRef,
        isGenerating,
        isTextStreamComplete,
    } = props;

    const {
        defaultDynamicFields,
        addDefaultDynamicFields,
        handleChangeDefaultDynamicField,
        dynamicFieldDecorator,
    } = useDynamicFields();

    const [tooltipsState, setTooltipsState] = useState(defaultTooltips);

    const [isFormatting, setIsFormatting] = useState(false);
    const [hideOverlay, setHideOverlay] = useState(true);

    const handleHideOverlay = (callback) => {
        setHideOverlay(true);
        typeof callback === 'function' && callback();
    };

    useEffect(() => {
        if (showTemplateOverlay && isTextStreamComplete) {
            setHideOverlay(!(showTemplateOverlay && isTextStreamComplete));
        }
    }, [showTemplateOverlay, isTextStreamComplete]);

    const overlayStyles = useMemo(
        () => ({
            ...(isGenerating && !showTemplateOverlay ? { opacity: 0, pointerEvents: 'none' } : {}),
            ...(!hideOverlay ? { opacity: 0.1, pointerEvents: 'none' } : {}),
        }),
        [isGenerating, showTemplateOverlay, hideOverlay]
    );

    return (
        <>
            <TemplateField aria-describedby="template name">
                <Typography variant="labelSmall">Template Name</Typography>
                <TextInput
                    value={templateName}
                    onChange={handleChangeName}
                    trackid="template-name-input"
                />
            </TemplateField>
            <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
                {selectedChannels[0] !== MESSAGE_TYPES.SMS && (
                    <TemplateField aria-describedby="template subject" sx={overlayStyles}>
                        <Box>
                            <Typography variant="labelSmall">Subject</Typography>
                            <Typography
                                variant="bodySmall"
                                sx={{ ml: 0.5, color: 'rgba(38, 55, 70, 0.48)' }}
                            >
                                Will not be used for SMS Templates
                            </Typography>
                        </Box>
                        <RichTextInput
                            name="message-template-rich-text-subject"
                            value={innerSubjectHtml}
                            initialValue={initialSubject}
                            onChange={handleChangeSubject}
                            disabled={isFetching}
                            InputProps={{
                                'aria-describedby': 'message-template-subject-input',
                                'data-testid': 'template-subject-input',
                            }}
                            fullWidth
                            isToolbarDisabled
                            isInline
                            initiateMount={false}
                            decorator={dynamicFieldDecorator}
                            ref={editorTemplateSubjectRef}
                            autoFocus
                            bodyHeight="30px"
                            isBlurred={showTemplateOverlay && isTextStreamComplete}
                        />
                        {editorTemplateSubjectRef?.current?.plainText?.()?.trimEnd()?.length <=
                            MINIMUM_EMAIL_SUBJECT_LENGTH && (
                            <Typography
                                data-testid="subject-error-message"
                                sx={{ fontSize: 10, color: 'red' }}
                            >
                                email subject must not be less than 6 characters
                            </Typography>
                        )}
                    </TemplateField>
                )}
                <TemplateField aria-describedby="template message" sx={overlayStyles}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="labelSmall">Message</Typography>
                        {selectedChannels[0] !== MESSAGE_TYPES.SMS && (
                            <Tooltip
                                {...getTooltipProps(
                                    tooltipsState,
                                    'formatting-options',
                                    setTooltipsState
                                )}
                            >
                                <span>
                                    <FormattingButton
                                        isCollapsed={false}
                                        isFormatting={isFormatting}
                                        onToggleFormatting={() =>
                                            setIsFormatting((prevState) => !prevState)
                                        }
                                        disableShadow={showTemplateOverlay}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </Box>
                    <RichTextInput
                        CustomFooter={TemplateFooter}
                        CustomFooterProps={{
                            disableShadow: showTemplateOverlay,
                            defaultDynamicFields,
                            addDefaultDynamicFields,
                            handleChangeDefaultDynamicField,
                        }}
                        formattingButtonPopperStyles={{
                            zIndex: 9998,
                            width: 440,
                        }}
                        isFormatting={isFormatting}
                        value={innerHtml}
                        initialValue={initialBody}
                        onChange={handleChangeBody}
                        name="message-template-rich-text"
                        InputProps={{
                            'aria-describedby': 'message-template-input',
                            'data-trackid': 'template-message-input',
                        }}
                        disabled={isFetching}
                        isToolbarDisabled={selectedChannels[0] === MESSAGE_TYPES.SMS}
                        decorator={dynamicFieldDecorator}
                        fullWidth
                        initiateMount={false}
                        formattingToolbarPlacement="bottom"
                        ref={editorTemplateBodyRef}
                    />
                </TemplateField>
                {isGenerating && !showTemplateOverlay && (
                    <LoaderContainer data-testid="rmm-generating-overlay">
                        <Lottie animationData={jdLoadingAnimation} style={{ width: '192px' }} />
                        <GradientTypography
                            variant="titleMedium"
                            sx={{ textAlign: 'center', width: '70%' }}
                        >
                            I&apos;m working on writing your Recruitment Marketing Message...
                        </GradientTypography>
                    </LoaderContainer>
                )}
                <FirstTimeRmmOverlay
                    hideOverlay={hideOverlay}
                    handleHideOverlay={handleHideOverlay}
                />
            </Box>
            {selectedChannels[0] !== MESSAGE_TYPES.SMS && (
                <TemplateField aria-describedby="template signature" sx={{ marginBottom: '80px' }}>
                    <SignatureForm />
                </TemplateField>
            )}
        </>
    );
});

CreateTemplate.propTypes = {
    isFetching: PropTypes.bool,
    innerHtml: PropTypes.string,
    innerSubjectHtml: PropTypes.string,
    initialBody: PropTypes.string,
    initialSubject: PropTypes.string,
    templateName: PropTypes.string,
    selectedChannels: PropTypes.arrayOf(PropTypes.string),
    showTemplateOverlay: PropTypes.bool,
    disableSaveTemplate: PropTypes.bool,
    handleChangeBody: PropTypes.func,
    handleChangeName: PropTypes.func,
    handleChangeSubject: PropTypes.func,
    handleSaveTemplate: PropTypes.func,
    editorTemplateBodyRef: PropTypes.object,
    editorTemplateSubjectRef: PropTypes.object,
    isGenerating: PropTypes.bool,
    isTextStreamComplete: PropTypes.bool,
};
