import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { theme } from 'theme';
import { PROJECT_ID_STORAGE_KEY } from 'views/Projects/Project/constants';

export const showNavigateToProjectToast = (storageKey = PROJECT_ID_STORAGE_KEY) => {
    const originatingProjectId = sessionStorage.getItem(storageKey);
    if (originatingProjectId) {
        toast.info(
            <Link
                to={`/projects/${originatingProjectId}/outreach`}
                style={{ color: theme.palette.common.white }}
                onClick={() => {
                    sessionStorage.removeItem(storageKey);
                }}
            >
                Back to Project
            </Link>,
            {
                autoClose: false,
                position: toast.POSITION.TOP_CENTER,
                onClose: () => {
                    sessionStorage.removeItem(storageKey);
                },
            }
        );
    }
};
