import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    FilterAccordion,
    FilterSummary,
    FilterDetails,
    SummaryChip,
    SummaryActions,
    SummaryAction,
    StyledPaper,
    CustomWidthTooltip,
    FilterChipContainer,
    FilterCaption,
} from './styledComponents';
import {
    addKeywordFilter,
    toggleKeywordFilter,
    changeKeywordsFilter,
} from 'views/Search/searchSlice';
import { deleteKeyword } from './utils';
import { TextInputProps } from './props';
import { FilterLabel } from './FilterLabel';
import { AnyAllSelect } from './AnyAllSelect';
import { IncludedChip } from './IncludedChip';
import { ExcludedChip } from './ExcludedChip';
import { Typography } from 'components/Typography';
import { MultiTextInput } from 'components/Inputs';
import { KeywordsDetailModal } from './KeywordsDetailModal';
import { KeyIcon, ChevronDownIcon, InfoIcon, CheckIcon } from 'components/Icons';
import { checkValidDblQuotes, checkValidParenthesis, checkValidApostrophe } from 'utils/validation';

function TooltipContent(props) {
    const { onOpen } = props;
    return (
        <Box>
            <Typography variant="bodySmall">
                The keyword filter allows you to create boolean queries.
            </Typography>
            <br />
            <br />
            <Typography variant="bodySmall">
                Learn more about how to structure a boolean query&nbsp;
                <ButtonBase
                    component={Typography}
                    onClick={onOpen}
                    variant="bodySmall"
                    sx={{ color: 'primary.main', verticalAlign: 'unset' }}
                >
                    here
                </ButtonBase>
                .
            </Typography>
        </Box>
    );
}

TooltipContent.propTypes = {
    onOpen: PropTypes.func,
};

const selectIncludedKeywords = (state) => {
    return state.search.filters.keywords.filter((keyword) => !/^NOT \(.+\)$/.test(keyword.trim()));
};

const selectExcludedKeywords = (state) => {
    return state.search.filters.keywords.filter((keyword) => /^NOT \(.+\)$/.test(keyword.trim()));
};

export function FilterKeywords() {
    const dispatch = useDispatch();

    const allKeywords = useSelector((state) => state.search.filters.keywords);
    const includedKeywords = useSelector(selectIncludedKeywords);
    const excludedKeywords = useSelector(selectExcludedKeywords);

    const currentKeywordRef = useRef('');

    const [excludeMatch, setExcludeMatch] = useState(false);

    const [isModalOpan, setIsModalOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const handleOpenModal = () => setIsModalOpen(true);

    const handleCloseModal = () => setIsModalOpen(false);

    const handleInputChange = useCallback((_, value) => {
        currentKeywordRef.current = value;
        if (!checkValidDblQuotes(value)) {
            setErrorMessage('Invalid quotation marks.');
        } else if (!checkValidParenthesis(value)) {
            setErrorMessage('Invalid parentheses.');
        } else if (!checkValidApostrophe(value)) {
            setErrorMessage('Keywords can not start with an apostrophe.');
        } else {
            setErrorMessage('');
        }
    }, []);

    const handleKeywordChange = useCallback(
        (event) => {
            if (!errorMessage && currentKeywordRef.current) {
                dispatch(
                    addKeywordFilter({
                        asWritten: true,
                        isExclude: excludeMatch,
                        keyword: currentKeywordRef.current,
                    })
                );
                currentKeywordRef.current = '';
            }
        },
        [errorMessage, excludeMatch, dispatch]
    );

    const handleClearKeywords = useCallback(
        (event) => {
            event.stopPropagation();
            dispatch(changeKeywordsFilter([]));
        },
        [dispatch]
    );

    const handleDeleteKeyword = (keyword) => deleteKeyword(keyword, allKeywords, dispatch);

    const handleExcludeMatchClick = (event) => {
        setExcludeMatch(event.target.checked);
    };

    const handleClickExclude = (keyword) => {
        dispatch(toggleKeywordFilter({ convertToExclude: true, keyword }));
    };

    const handleClickInclude = (keyword) => {
        dispatch(toggleKeywordFilter({ convertToExclude: false, keyword }));
    };

    return (
        <FilterAccordion id="filter-keyword">
            <FilterSummary
                expandIcon={<ChevronDownIcon />}
                aria-controls="filter-keyword-content"
                aria-label="filter-keyword-header"
            >
                <KeyIcon sx={{ color: alpha('#ffffff', 0.48) }} />
                <Typography
                    variant="labelSmall"
                    sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
                >
                    Keyword
                    <CustomWidthTooltip
                        title={<TooltipContent onOpen={handleOpenModal} />}
                        placement="top"
                    >
                        <Box>
                            <InfoIcon
                                sx={{
                                    ml: 0.3,
                                    fontSize: '1.4rem',
                                    cursor: 'pointer',
                                    color: 'white',
                                }}
                            />
                        </Box>
                    </CustomWidthTooltip>
                </Typography>

                <ButtonBase
                    component={Typography}
                    onClick={handleOpenModal}
                    variant="bodySmall"
                    noWrap
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'primary.main',
                        verticalAlign: 'unset',
                        textDecoration: 'underline',
                        ml: 1,
                    }}
                >
                    Learn More
                </ButtonBase>

                <SummaryActions>
                    {Boolean(allKeywords.length) && (
                        <SummaryAction
                            variant="text"
                            onClick={handleClearKeywords}
                            aria-label="filter-keyword-clear"
                        >
                            Clear
                        </SummaryAction>
                    )}
                    <SummaryChip label={allKeywords.length} />
                </SummaryActions>
            </FilterSummary>
            <FilterDetails id="filter-keyword-content">
                <MultiTextInput
                    fullWidth
                    disableTags
                    disableClearable
                    value={allKeywords}
                    openOnFocus={false}
                    forcePopupIcon={false}
                    PaperComponent={StyledPaper}
                    onChange={handleKeywordChange}
                    onInputChange={handleInputChange}
                    TextInputProps={{
                        ...TextInputProps,
                        helperText: errorMessage,
                    }}
                    error={Boolean(errorMessage)}
                    id="filter-keyword-multi-input"
                    label={
                        <FilterLabel
                            label="Narrow candidates by keywords"
                            checked={excludeMatch}
                            onChange={handleExcludeMatchClick}
                        />
                    }
                />
                {Boolean(allKeywords.length) && (
                    <>
                        <FilterCaption variant="labelMedium">
                            <CheckIcon
                                sx={{
                                    cursor: 'auto',
                                    fontSize: '13px',
                                    marginRight: '3px',
                                    color: 'success.main',
                                }}
                            />
                            <Typography variant="bodySmall">
                                Include profiles that satisfy
                                <AnyAllSelect filterKey="keywords" />
                                of these queries
                            </Typography>
                        </FilterCaption>
                        <FilterChipContainer>
                            {includedKeywords.map((keyword) => (
                                <IncludedChip
                                    key={`keyword-chip-${keyword}`}
                                    label={keyword}
                                    onClick={() => handleClickExclude(keyword)}
                                    onDelete={() => handleDeleteKeyword(keyword)}
                                />
                            ))}
                            {excludedKeywords.map((keyword) => (
                                <ExcludedChip
                                    key={`keyword-chip-${keyword}`}
                                    label={keyword}
                                    onClick={() => handleClickInclude(keyword)}
                                    onDelete={() => handleDeleteKeyword(keyword)}
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
            </FilterDetails>
            <KeywordsDetailModal open={isModalOpan} onClose={handleCloseModal} />
        </FilterAccordion>
    );
}
