import { useCallback, useState } from 'react';
import { ACCESS_OPTIONS, PERMISSIONS_OPTIONS } from 'utils/entityEnums';

export function accessResponseToAccessValue(access) {
    // if it is bull or every permission (edit, view ...) is set to false on the org, return null
    // they are all set to false mostly after editting
    if (!access || Object.values(access).every((v) => !v)) return null;
    if (access.view && !access.edit) return PERMISSIONS_OPTIONS.VIEWER.value.toLowerCase();
    else if (access.view && access.edit) return PERMISSIONS_OPTIONS.EDITOR.value.toLowerCase();
    return PERMISSIONS_OPTIONS.VIEWER.value.toLowerCase();
}

export function accessValueToAccessResponse(accessValue) {
    if (!accessValue) return accessValue;
    if (accessValue.toLowerCase() === PERMISSIONS_OPTIONS.EDITOR.value)
        return {
            view: true,
            edit: true,
            delete: true,
            'edit-permission': false,
        };
    else
        return {
            view: true,
            edit: false,
            delete: false,
            'edit-permission': false,
        };
}

export function useMediaPermissions({ organizationId, access }) {
    const orgAccess = access?.org_access?.[organizationId];

    const [organizationAccess, setOrganizationAccess] = useState(() => {
        if (Boolean(access) && Boolean(orgAccess)) {
            return accessResponseToAccessValue(orgAccess);
        }
        return null;
    });

    const handleChangeAccess = (accessVal) => {
        if (accessVal === ACCESS_OPTIONS.ORG.value) {
            setOrganizationAccess(PERMISSIONS_OPTIONS.EDITOR.value);
        } else {
            setOrganizationAccess(null);
        }
    };

    const handleChangePermissions = (permissionsVal) => {
        if (permissionsVal) setOrganizationAccess(permissionsVal);
    };

    const resetPermissions = useCallback(() => {
        if (Boolean(access) && Boolean(orgAccess)) {
            setOrganizationAccess(accessResponseToAccessValue(orgAccess));
        }
        return null;
    }, [orgAccess, access]);

    return {
        organizationAccess,
        handleChangeAccess,
        handleChangePermissions,
        resetPermissions,
    };
}
