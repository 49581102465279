import SvgIcon from '@mui/material/SvgIcon';

export function MessagesIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M13.2 3.35714C13.2 2.73199 12.9517 2.13244 12.5096 1.69039C12.0676 1.24834 11.468 1 10.8429 1H2.35714C1.73199 1 1.13244 1.24834 0.690391 1.69039C0.248341 2.13244 0 2.73199 0 3.35714V9.95714C0 10.5823 0.248341 11.1818 0.690391 11.6239C1.13244 12.0659 1.73199 12.3143 2.35714 12.3143H10.8429C11.468 12.3143 12.0676 12.0659 12.5096 11.6239C12.9517 11.1818 13.2 10.5823 13.2 9.95714V3.35714ZM6.83949 7.53494L12.2571 4.73466V9.95714C12.2571 10.3322 12.1081 10.692 11.8429 10.9572C11.5777 11.2224 11.2179 11.3714 10.8429 11.3714H2.35714C1.98205 11.3714 1.62232 11.2224 1.35709 10.9572C1.09186 10.692 0.942857 10.3322 0.942857 9.95714V4.73654L6.36051 7.53494L6.43783 7.57077C6.50326 7.59488 6.57323 7.60417 6.64268 7.59797C6.71214 7.59177 6.77935 7.57025 6.83949 7.53494ZM2.35714 1.94286H10.8429C11.2179 1.94286 11.5777 2.09186 11.8429 2.35709C12.1081 2.62232 12.2571 2.98205 12.2571 3.35714V3.64094L6.6 6.58171L0.942857 3.64189V3.35714C0.942857 2.98205 1.09186 2.62232 1.35709 2.35709C1.62232 2.09186 1.98205 1.94286 2.35714 1.94286ZM4.24286 14.2C3.87687 14.2003 3.51586 14.1152 3.18851 13.9515C2.86116 13.7879 2.5765 13.5501 2.35714 13.2571H10.8429C11.7181 13.2571 12.5574 12.9095 13.1763 12.2906C13.7952 11.6717 14.1429 10.8324 14.1429 9.95714V3.35714C14.7152 3.78709 15.0857 4.4716 15.0857 5.24286V9.95714C15.0857 11.0824 14.6387 12.1616 13.843 12.9573C13.0473 13.753 11.9681 14.2 10.8429 14.2H4.24286Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
