import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Editor, Element as SlateElement, Transforms } from 'slate';

import { sxProp, TEXT_ALIGN_TYPES, LIST_TYPES } from './common';

const toggleBlock = (editor, format, properties) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    });

    let newProperties;
    let type = isList ? 'list-item' : format;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
            ...properties,
        };
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : type,
            ...properties,
        };
    }
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor;
    if (!selection) return false;
    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n) =>
                !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
        })
    );
    return !!match;
};

export const BlockButton = memo(({ format, label, Icon, disabled }) => {
    const editor = useSlate();

    const active = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    );

    return (
        <Box sx={sxProp.flex}>
            <IconButton
                tabIndex={1}
                title={label}
                disabled={disabled}
                disableFocusRipple
                aria-label={`format-${format}`}
                sx={active ? sxProp.activeBtn : sxProp.inactiveBtn}
                onClick={(event) => {
                    event.preventDefault();
                    toggleBlock(editor, format);
                }}
                disableRipple
            >
                <Icon />
            </IconButton>
        </Box>
    );
});

BlockButton.propTypes = {
    format: PropTypes.string,
    label: PropTypes.string,
    Icon: PropTypes.any,
    disabled: PropTypes.bool,
};
