import SvgIcon from '@mui/material/SvgIcon';

export function EmailIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M4 9.2532V16.2C4 16.9426 4.295 17.6548 4.8201 18.1799C5.3452 18.705 6.05739 19 6.8 19H18C18.7426 19 19.4548 18.705 19.9799 18.1799C20.505 17.6548 20.8 16.9426 20.8 16.2V7.8C20.8 7.05739 20.505 6.3452 19.9799 5.8201C19.4548 5.295 18.7426 5 18 5H6.8C6.05739 5 5.3452 5.295 4.8201 5.8201C4.295 6.3452 4 7.05739 4 7.8V9.2532ZM6.8 6.4H18C18.3713 6.4 18.7274 6.5475 18.9899 6.81005C19.2525 7.0726 19.4 7.4287 19.4 7.8V8.836L12.4 12.6048L5.4 8.836V7.8C5.4 7.4287 5.5475 7.0726 5.81005 6.81005C6.0726 6.5475 6.4287 6.4 6.8 6.4ZM5.4 10.4264L12.0682 14.016C12.1702 14.0709 12.2842 14.0996 12.4 14.0996C12.5158 14.0996 12.6298 14.0709 12.7318 14.016L19.4 10.4264V16.2C19.4 16.5713 19.2525 16.9274 18.9899 17.1899C18.7274 17.4525 18.3713 17.6 18 17.6H6.8C6.4287 17.6 6.0726 17.4525 5.81005 17.1899C5.5475 16.9274 5.4 16.5713 5.4 16.2V10.4264Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
