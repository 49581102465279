import SvgIcon from '@mui/material/SvgIcon';

export function ImagePlaceholderIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 17" {...props}>
            <path
                d="M11.558 0.5H4.44198C1.99001 0.5 0 2.49001 0 4.94198V12.058C0 14.51 1.99001 16.5 4.44198 16.5H11.558C14.01 16.5 16 14.51 16 12.058V4.94198C16 2.49001 14.01 0.5 11.558 0.5ZM4.44198 2.27679H11.558C13.0239 2.27679 14.2232 3.47612 14.2232 4.94198V11.6494L13.0061 10.6455C12.3842 10.1302 11.4425 10.1569 10.8562 10.6988L9.64797 11.827L6.48528 8.92199C5.82787 8.33565 4.77957 8.3623 4.16657 8.96641L1.77679 11.2229V4.94198C1.77679 3.47612 2.97612 2.27679 4.44198 2.27679ZM2.12326 13.3373L5.34814 10.2901L10.1632 14.7143H4.44198C3.43809 14.7143 2.57635 14.1546 2.12326 13.3373ZM12.5708 14.51L10.9539 13.0264L11.9667 12.0847L13.7524 13.5594C13.4592 13.9858 13.0505 14.3145 12.5708 14.51Z"
                fill="currentColor"
            />
            <path
                d="M10.6607 8.49556C12.1355 8.49556 13.3259 7.30511 13.3259 5.83037C13.3259 4.35564 12.1355 3.16519 10.6607 3.16519C9.18601 3.16519 7.99556 4.35564 7.99556 5.83037C7.99556 7.30511 9.18601 8.49556 10.6607 8.49556ZM10.6607 4.94198C11.1494 4.94198 11.5491 5.34175 11.5491 5.83037C11.5491 6.31899 11.1494 6.71877 10.6607 6.71877C10.1721 6.71877 9.77235 6.31899 9.77235 5.83037C9.77235 5.34175 10.1721 4.94198 10.6607 4.94198Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
