import SvgIcon from '@mui/material/SvgIcon';

export function CommentIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M23.5603 0.00976562H9.33811C4.43144 0.00976562 0.449219 3.99199 0.449219 8.89865V19.5653C0.449219 24.472 4.43144 28.4542 9.33811 28.4542H20.0048C22.3514 28.4542 25.3381 32.0098 27.987 32.0098C31.1159 32.0098 32.4492 29.5387 32.4492 26.6764V8.89865C32.4492 3.99199 28.467 0.00976562 23.5603 0.00976562ZM28.8937 26.6764C28.8937 28.4542 28.3248 28.4542 28.0404 28.4542C27.5959 28.4009 26.4048 27.6186 25.6937 27.1564C24.0581 26.0898 22.1914 24.8809 20.0048 24.8809H9.33811C6.40477 24.8809 4.00477 22.4809 4.00477 19.5475V8.88087C4.00477 5.94754 6.40477 3.54754 9.33811 3.54754H23.5603C26.4937 3.54754 28.8937 5.94754 28.8937 8.88087V26.6764Z"
                fill="currentColor"
            />
            <path
                d="M9.33811 12.4542H18.227C19.2048 12.4542 20.0048 11.6542 20.0048 10.6764C20.0048 9.69865 19.2048 8.89865 18.227 8.89865H9.33811C8.36033 8.89865 7.56033 9.69865 7.56033 10.6764C7.56033 11.6542 8.36033 12.4542 9.33811 12.4542Z"
                fill="currentColor"
            />
            <path
                d="M23.5603 14.232H9.33811C8.36033 14.232 7.56033 15.032 7.56033 16.0098C7.56033 16.9875 8.36033 17.7875 9.33811 17.7875H23.5603C24.5381 17.7875 25.3381 16.9875 25.3381 16.0098C25.3381 15.032 24.5381 14.232 23.5603 14.232Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
