import { useCallback, useState } from 'react';
import { useBlocker } from './useBlocker';

export function usePrompt(message, prompt = true) {
    const [userConfirmed, setUserConfirmed] = useState(false);
    const blocker = useCallback(
        (tx) => {
            if (window.confirm(message) && !userConfirmed) {
                tx.retry();
                setUserConfirmed(true);
            }
        },
        [message, userConfirmed]
    );

    return [...useBlocker(blocker, prompt, setUserConfirmed), userConfirmed];
}
