import { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { ChevronDownIcon } from 'components/Icons';
import { useSwitchboard } from 'features/Switchboard';
import { MessageBody } from 'components/MessageContent/MessageBody';
import { Typography } from 'components/Typography';
import { MessageComposeHeader } from './MessageComposeHeader';
import { SendButton } from './SendButton';
import { useCheckSmsSent, useMessageCompose } from 'views/Communications/hooks';
import { getBodyValidator } from 'views/Communications/features/Compose/utils';
import { MESSAGE_TYPES, MINIMUM_EMAIL_SUBJECT_LENGTH } from 'utils/constants';
import { ScheduledMessagePopup, TcpaDialog } from 'components/Dialog';
import { THREAD_COLUMN_PROPS } from 'views/Communications/utils/constants';
import { MessageTypeDropdown } from 'components/MessageContent/MessageTypeDropdown';
import { ContactSelection } from 'components/MessageContent/ContactSelection';
import { MessageSubInfo } from '../../Message/components';
import { MessageEditActions } from './MessageEditActions';

const MessageContainer = styled(Box)(({ theme }) => ({
    ...THREAD_COLUMN_PROPS.CONTAINER(theme),
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'end',
    ...THREAD_COLUMN_PROPS.SIDE(theme),
}));

const MessageComposeContainer = styled(MuiAccordion, {
    shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme, variant }) => ({
    '&.MuiAccordion-root': {
        margin: theme.spacing(0, 1),
        marginLeft: variant === 'edit' ? 0 : theme.spacing(1),
        padding: theme.spacing(3),
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.32), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);',
        borderRadius: theme.spacing(2, 2, 0, 2),
        background: '#ffffff',
        ...THREAD_COLUMN_PROPS.MAIN(theme),
    },
}));

const SendContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: theme.spacing(93.5),
    paddingTop: theme.spacing(3),
    margin: '0 auto',
}));

const SendPanel = styled('form')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    marginLeft: 'auto',
    '&.MuiAccordionSummary-root': {
        backgroundColor: 'inherit',
        flexDirection: 'row-reverse',
        minHeight: 'inherit',
        padding: theme.spacing(0),
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
        },
        '& .MuiAccordionSummary-content': {
            margin: theme.spacing(0.125, 0),
        },
        '& .Mui-expanded': {
            margin: theme.spacing(0.125, 0),
        },
    },
}));

function MessageHeader(props) {
    const { variant, messageType, profiles, handleContact, onContactsChange, handleSetCooldown } =
        props;
    return (
        <>
            {variant === 'compose' && (
                <MessageComposeHeader messageType={messageType} profiles={profiles} />
            )}
            {variant === 'reply' && (
                <ContactSelection
                    messageType={messageType}
                    talentId={profiles?.[0]?.talentId}
                    onChange={handleContact}
                    onContactsChange={onContactsChange}
                    handleSetCooldown={handleSetCooldown}
                />
            )}
        </>
    );
}

export function MessageCompose(props) {
    const {
        profiles = [],
        subject: initialSubject,
        variant = 'compose',
        messageType,
        shortlistId,
        onChangeMessageType,
        message,
        messageId,
        media,
        isEditing,
        onCancelEdit,
        refreshMessages,
        onFinishEdit,
        MessageSubInfoProps,
    } = props;

    const params = useParams();

    const { conversationId } = params || {};

    const {
        attachments,
        setAttachments,
        innerHtml,
        innerText,
        initialBody,
        isSending,
        clearInput,
        handleSend,
        handleMessageBody,
        handleGreeting,
        handleSubject,
        handleUseTemplate,
        handleConfirmScheduledDate,
        setClearInput,
        setSignature,
        openTcpaDialog,
        setOpenTcpaDialog,
        initialSubjectValue,
        innerSubjectText,
        innerSubjectHtml,
        editorBodyRef,
        editorSubjectRef,
        invalidDynamicFields,
        handleContact,
        handleRemoveMessageAttachment,
    } = useMessageCompose({
        variant,
        profiles,
        messageType,
        shortlistId,
        conversationId,
        initialSubject,
        onChangeMessageType,
        isEditing,
        message,
        media,
        refreshMessages,
    });

    const disabled = isSending || (!isEditing && profiles.length === 0);
    const { isFirstSMSMessage } = useCheckSmsSent({ profiles });

    const navigate = useNavigate();

    const anchor = useRef(null);

    const [expanded, setExpanded] = useState(true);
    const [open, setOpen] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [subjectError, setSubjectError] = useState('');
    const [noAvailableContacts, setNoAvailableContacts] = useState(false);
    const [selectedContactInCoolDown, setSelectedContactInCoolDown] = useState(false);

    const isScheduleFeatureEnabled = useSwitchboard('FEATURE_SCHEDULED_MESSAGES');

    const isDisabled =
        disabled ||
        !getBodyValidator(
            messageType,
            innerText?.trim?.()?.length,
            isEditing ? 10 : (innerSubjectText || initialSubjectValue)?.trim?.()?.length
        ) ||
        invalidDynamicFields ||
        open ||
        noAvailableContacts ||
        selectedContactInCoolDown;

    useEffect(() => {
        const subjectLength = innerSubjectText?.trim?.()?.length;
        if (!subjectLength) setSubjectError('');
        else if (subjectLength < MINIMUM_EMAIL_SUBJECT_LENGTH) {
            setSubjectError('email subject must not be less than 6 characters');
        } else setSubjectError('');
    }, [innerSubjectText]);

    const handleConfirmTcpa = useCallback(() => {
        setOpenTcpaDialog(false);
        setShowCalendar(true);
        setOpen(true);
    }, [setOpenTcpaDialog]);

    const handleChange = useCallback((e) => {
        e.stopPropagation();
        setExpanded((prevValue) => !prevValue);
    }, []);

    const handleContactChange = useCallback((contacts) => {
        setNoAvailableContacts(contacts?.length === 0);
    }, []);

    const handleSetCooldown = useCallback((contact) => {
        setSelectedContactInCoolDown(contact?.isCoolingDown);
    }, []);

    const sendMessage = () => {
        handleSend({
            onSuccess: (response) => {
                const conversationId = response?.data?.[0]?.conversationId;
                if (!conversationId) {
                    return navigate('/communications');
                }
                return navigate(`/communications/messages/${conversationId}`);
            },
        });
    };

    const updateMessage = () => {
        handleSend({ messageId, onSuccess: onFinishEdit, disableRefresh: true });
    };

    const hasDeclineText = messageType === MESSAGE_TYPES.SMS && isFirstSMSMessage;

    return (
        <>
            <Box
                data-testid="message-compose-box"
                onClick={() => !expanded && setExpanded(true)}
                sx={{ cursor: !expanded ? 'pointer' : 'initial', width: '100%' }}
            >
                <MessageContainer isEditing={isEditing}>
                    <ButtonWrapper sx={{ alignItems: 'center', justifyContent: 'end' }}>
                        {isEditing && (
                            <MessageEditActions
                                isDisabled={isDisabled}
                                updateMessage={updateMessage}
                                onCancelEdit={onCancelEdit}
                            />
                        )}
                    </ButtonWrapper>
                    <Box sx={{ mr: variant !== 'edit' && 2, maxWidth: 744, width: '100%' }}>
                        <MessageComposeContainer
                            variant={variant}
                            expanded={expanded}
                            onChange={handleChange}
                        >
                            <Box sx={{ display: 'flex' }}>
                                {variant === 'reply' && (
                                    <MessageTypeDropdown
                                        messageType={messageType}
                                        onChangeMessageType={onChangeMessageType}
                                    />
                                )}
                                {messageType === MESSAGE_TYPES.EMAIL && (
                                    <Typography
                                        variant="bodySmall"
                                        color="primary"
                                        sx={{
                                            background: (theme) => theme.palette.common.white,
                                            padding: 1,
                                            fontWeight: 500,
                                            borderRadius: 1,
                                        }}
                                    >
                                        Email signatures are not automatically added to your
                                        message.
                                    </Typography>
                                )}
                                <AccordionSummary
                                    aria-label={expanded ? 'collapse-panel' : 'expand-panel'}
                                    data-trackid={
                                        expanded
                                            ? 'conversation-message-collapse'
                                            : 'conversation-message-expand'
                                    }
                                    expandIcon={<ChevronDownIcon sx={{ fontSize: '1rem' }} />}
                                >
                                    <Typography>
                                        {expanded && 'Collapse'}
                                        {!expanded && variant === 'reply' && 'Reply'}
                                        {!expanded && variant !== 'reply' && 'Compose'}
                                    </Typography>
                                </AccordionSummary>
                            </Box>
                            <AccordionDetails>
                                <MessageHeader
                                    variant={variant}
                                    messageType={messageType}
                                    profiles={profiles}
                                    handleContact={handleContact}
                                    onContactsChange={handleContactChange}
                                    handleSetCooldown={handleSetCooldown}
                                />
                                <MessageBody
                                    subjectError={subjectError}
                                    conversationId={conversationId}
                                    innerText={innerText}
                                    innerHtml={innerHtml}
                                    subject={innerSubjectHtml}
                                    attachments={attachments}
                                    setAttachments={setAttachments}
                                    onAttachmentDelete={handleRemoveMessageAttachment}
                                    initialBody={initialBody}
                                    initialSubject={initialSubjectValue}
                                    variant={variant}
                                    messageType={messageType}
                                    clearInput={clearInput}
                                    onChange={handleMessageBody}
                                    onChangeSubject={handleSubject}
                                    onChangeGreeting={handleGreeting}
                                    onChangeMessageType={onChangeMessageType}
                                    onUseTemplate={handleUseTemplate}
                                    onUseSignature={setSignature}
                                    hideToolbar={false}
                                    autofocus={true}
                                    openInNewTab={true}
                                    setClearInput={setClearInput}
                                    initiateMount={false}
                                    isEditMode={isEditing}
                                    editorBodyRef={editorBodyRef}
                                    editorSubjectRef={editorSubjectRef}
                                    isCollapsed={!expanded}
                                    hasDeclineText={hasDeclineText}
                                />
                            </AccordionDetails>
                        </MessageComposeContainer>
                        {variant === 'edit' && MessageSubInfoProps && (
                            <MessageSubInfo {...MessageSubInfoProps} isEditing />
                        )}
                    </Box>
                    <ButtonWrapper>
                        {!isEditing && (
                            <SendButton
                                isSending={isSending}
                                isDisabled={isDisabled}
                                isEditing={false}
                                setOpen={setOpen}
                                anchor={anchor}
                                onClick={() => {
                                    sendMessage();
                                    setOpen(false);
                                }}
                            />
                        )}
                    </ButtonWrapper>
                </MessageContainer>
            </Box>
            {variant !== 'edit' && (
                <SendContainer>
                    <SendPanel aria-label="compose-message-send-form">
                        {isScheduleFeatureEnabled && (
                            <ScheduledMessagePopup
                                anchorEl={anchor.current}
                                open={open}
                                onClose={() => setOpen(false)}
                                onSend={sendMessage}
                                onConfirm={handleConfirmScheduledDate}
                                showCalendar={showCalendar}
                                setShowCalendar={setShowCalendar}
                                messageType={messageType}
                            />
                        )}
                    </SendPanel>
                </SendContainer>
            )}
            <TcpaDialog
                open={openTcpaDialog}
                onClose={() => setOpenTcpaDialog(false)}
                onConfirm={handleConfirmTcpa}
            />
        </>
    );
}

MessageCompose.propTypes = {
    profiles: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            talentId: PropTypes.string,
            imageUrl: PropTypes.string,
        })
    ),
    variant: PropTypes.string,
    messageType: PropTypes.string,
    shortlistId: PropTypes.string,
    subject: PropTypes.string,
    message: PropTypes.string,
    isEditing: PropTypes.bool,
    onCancelEdit: PropTypes.func,
    onFinishEdit: PropTypes.func,
    refreshMessages: PropTypes.func,
    media: PropTypes.array,
    messageId: PropTypes.string,
    onChangeMessageType: PropTypes.func,
    MessageSubInfoProps: PropTypes.object,
};

MessageHeader.propTypes = {
    variant: PropTypes.string,
    messageType: PropTypes.string,
    profiles: PropTypes.array,
    handleContact: PropTypes.func,
    onContactsChange: PropTypes.func,
    handleSetCooldown: PropTypes.func,
};
