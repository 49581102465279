import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { alpha, styled } from '@mui/material/styles';
import { useDrag } from 'react-dnd';

import { TEST_IDS, DND_ITEM_TYPES } from 'utils/constants';
import { DeleteIcon, StarIcon } from 'components/Icons';
import { StarOutlined } from '@mui/icons-material';
import { CONTACT_STATUS_MAP, CONTACT_MEDIUM } from '../utils/constants';
import { useContactDrop } from '../hooks/useContactDrop';
import { EmailListItem } from './EmailListItem';
import { PhoneListItem } from './PhoneListItem';
import { DeleteCustomContact } from './DeleteCustomContact';
import { CircularProgress } from '@mui/material';
import { Tooltip } from 'components/Tooltip';
import { FlagContact } from './FlagContact';
import { useUserVerfiedContactsStore } from 'services/store/store';

const ListItemStyled = styled(ListItem, {
    shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isDeleting',
})(({ theme, isActive, isDeleting }) => ({
    '&.MuiListItem-root': {
        padding: theme.spacing(0, 1),
        cursor: 'grab',
        minHeight: theme.spacing(4.5),
        backgroundColor: isActive
            ? alpha(theme.palette.primary.main, 0.125)
            : isDeleting
            ? 'transparent'
            : 'inherit',
        borderRadius: isActive ? theme.spacing(0.25) : 'inherit',
        '&:hover': {
            background: isDeleting ? 'transparent' : 'rgba(49, 141, 222, 0.08)',
            borderRadius: theme.spacing(0.5),
        },
    },
}));

export function ContactListItem({
    contact,
    talentId,
    onUpdate,
    index,
    isPrimaryContactChangeLoading,
    handleFlagContact,
    onPrimaryContactChange,
    isFlagingContact,
    staleContacts,
}) {
    const [isHovered, setIsHovered] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { verifiedContacts, reportedContacts } = useUserVerfiedContactsStore();

    const { contactId, category, contactMedium, primary: isPrimary, source, accessType } = contact;
    const contactData = contactMedium === CONTACT_MEDIUM.PHONE ? contact.phone : contact.email;
    const isLoading = isPrimaryContactChangeLoading || isFlagingContact;

    const isCustomContact = source === 'USER' && accessType === 'PRIVATE';
    const isVerified =
        contact.status === CONTACT_STATUS_MAP.VERIFIED ||
        verifiedContacts.includes(contact.contactId);

    const isReported =
        reportedContacts.includes(contact.contactId) ||
        contact.status === CONTACT_STATUS_MAP.REPORTED;

    const isOldContact = staleContacts?.some(
        (staleContact) => staleContact.contactId === contactId
    );

    const hideFlag = isVerified || isReported;

    const [, drag] = useDrag(
        () => ({
            type: DND_ITEM_TYPES.CONTACT_LIST,
            item: { talentId, contactId, srcIndex: index, src: category },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            }),
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (item && dropResult) {
                    const { dst, dstIndex } = dropResult;
                    const { src, srcIndex, contactId, talentId } = item;
                    const args = { dst, dstIndex, src, srcIndex, contactId, talentId };
                    onUpdate(args);
                }
            },
        }),
        [talentId, contactId, category, index, onUpdate]
    );

    const [isActive, drop] = useContactDrop(category, index);

    const showPrimaryIcon = isHovered && !isDeleting;

    const handleMouseEnter = () => {
        if (isPrimaryContactChangeLoading) return;
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        if (isPrimaryContactChangeLoading) return;
        setIsHovered(false);
    };

    const renderSecondaryView = () => {
        switch (contactMedium) {
            case CONTACT_MEDIUM.PHONE:
                return (
                    <PhoneListItem
                        contact={contact}
                        contactData={contactData}
                        isCustomContact={isCustomContact}
                        isVerified={isVerified}
                        isReported={isReported}
                        isNewContact={!isOldContact}
                    />
                );
            case CONTACT_MEDIUM.EMAIL:
                return (
                    <EmailListItem
                        contact={contact}
                        contactData={contactData}
                        isCustomContact={isCustomContact}
                        isVerified={isVerified}
                        isReported={isReported}
                        isNewContact={!isOldContact}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box
            data-testid={`contact-list-item-${contactId}`}
            ref={drop}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: (theme) => (isDeleting ? theme.spacing(0.5) : 'inherit'),
                background: (theme) =>
                    isDeleting ? alpha(theme.palette.error.main, 0.24) : 'inherit',
            }}
        >
            <ListItemStyled
                ref={drag}
                data-testid={TEST_IDS.CONTACT_LIST_ITEM}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                key={contactId}
                disableGutters
                disablePadding
                isActive={isActive}
                isDeleting={isDeleting}
            >
                <ListItemText
                    primary={renderSecondaryView()}
                    disableTypography
                    sx={{ mt: 0, mb: 0 }}
                />
                {isPrimary && (
                    <StarOutlined
                        color="primary"
                        fontSize="small"
                        aria-label="Primary contact"
                        data-trackid={
                            contactMedium === CONTACT_MEDIUM.PHONE
                                ? 'lookup-modal-sms-primary'
                                : 'lookup-modal-email-primary'
                        }
                        sx={{ cursor: 'pointer', ml: 1, mr: 1 }}
                        onClick={() => onPrimaryContactChange(contact.contactId, contactMedium)}
                    />
                )}
                {!isPrimary &&
                    showPrimaryIcon &&
                    !isLoading &&
                    contact.status !== CONTACT_STATUS_MAP.OPT_OUT && (
                        <Tooltip title="Make Primary Contact" placement="top-end" arrow>
                            <ListItemIcon sx={{ minWidth: '24px' }}>
                                <StarIcon
                                    data-testid={TEST_IDS.STAR_ICON}
                                    color="primary"
                                    onClick={() => onPrimaryContactChange(contact.contactId)}
                                    sx={{ ml: 1, mr: 1, cursor: 'pointer' }}
                                />
                            </ListItemIcon>
                        </Tooltip>
                    )}
                {isLoading && (
                    <CircularProgress
                        data-testid={TEST_IDS.PROGRESS_ICON}
                        size={16}
                        color="primary"
                        thickness={2}
                        sx={{ ml: 1, mr: 1 }}
                    />
                )}
                {isCustomContact && !isDeleting && (
                    <Tooltip title="Delete Contact" placement="top-end" arrow>
                        <ListItemIcon
                            onClick={() => {
                                setIsDeleting(true);
                            }}
                            sx={{ cursor: 'pointer', minWidth: '24px' }}
                        >
                            <DeleteIcon
                                color="error"
                                sx={{ fontSize: 16 }}
                                aria-label="Delete contact"
                            />
                        </ListItemIcon>
                    </Tooltip>
                )}
                {isHovered && !isLoading && !hideFlag && (
                    <Tooltip title="Verify or Report Contact" placement="top-end" arrow>
                        <ListItemIcon sx={{ minWidth: '24px' }}>
                            <FlagContact
                                flagContact={handleFlagContact}
                                contactId={contactId}
                                contactMedium={contactMedium}
                            />
                        </ListItemIcon>
                    </Tooltip>
                )}
            </ListItemStyled>
            {isCustomContact && (
                <DeleteCustomContact
                    contactId={contactId}
                    expanded={isDeleting}
                    setExpanded={setIsDeleting}
                />
            )}
        </Box>
    );
}

const ContactPropType = PropTypes.shape({
    contactId: PropTypes.string.isRequired,
    type: PropTypes.string,
    category: PropTypes.string,
    contactMedium: PropTypes.oneOf(Object.values(CONTACT_MEDIUM)).isRequired,
    phone: PropTypes.string,
    email: PropTypes.string,
    primary: PropTypes.bool.isRequired,
    source: PropTypes.string,
    accessType: PropTypes.string,
    status: PropTypes.oneOf(Object.values(CONTACT_STATUS_MAP)),
}).isRequired;

ContactListItem.propTypes = {
    isPrimaryContactChangeLoading: PropTypes.bool,
    contact: ContactPropType,
    onPrimaryContactChange: PropTypes.func,
    index: PropTypes.number,
    talentId: PropTypes.string,
    onUpdate: PropTypes.func,
    isFlagingContact: PropTypes.bool,
    handleFlagContact: PropTypes.func,
    staleContacts: PropTypes.arrayOf(PropTypes.shape({ contactId: PropTypes.string })),
};
