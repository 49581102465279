import SvgIcon from '@mui/material/SvgIcon';

export function BrushIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 15" {...props}>
            <path
                d="M9.45655 0.381988H2.5579C1.37667 0.381988 0.412109 1.34654 0.412109 2.53476V6.42093C0.412109 7.67904 1.01321 8.87425 2.02669 9.62213L3.04715 10.377C3.17296 10.4749 3.24985 10.6286 3.24985 10.7894V11.4534C3.24985 12.9981 4.43808 14.3121 5.89888 14.375C5.94082 14.375 5.98275 14.375 6.02469 14.375C6.73762 14.375 7.41561 14.1024 7.93284 13.6062C8.47802 13.0819 8.79253 12.348 8.79253 11.5862V10.7754C8.79253 10.6146 8.86942 10.4609 8.99524 10.37L10.0017 9.61514C10.9942 8.86726 11.5883 7.67904 11.5883 6.43491V2.52777C11.5883 1.33955 10.6238 0.375 9.44258 0.375L9.45655 0.381988ZM2.5579 1.77989H3.20092V3.17081C3.20092 3.55523 3.51545 3.86976 3.89987 3.86976C4.2843 3.86976 4.59882 3.55523 4.59882 3.17081V1.77989H5.29778V2.47186C5.29778 2.85628 5.6123 3.17081 5.99673 3.17081C6.38115 3.17081 6.69568 2.85628 6.69568 2.47186V1.77989H9.44955C9.86193 1.77989 10.1974 2.11539 10.1974 2.53476V5.21873C7.51346 6.2392 4.49399 6.23221 1.81001 5.21873V2.53476C1.81001 2.11539 2.14551 1.77989 2.5579 1.77989ZM9.16997 8.50381L8.16348 9.25867C7.68819 9.61514 7.40163 10.1883 7.40163 10.7824V11.5932C7.40163 11.9776 7.24786 12.3341 6.96828 12.5997C6.69569 12.8583 6.33922 12.9911 5.96179 12.9771C5.23488 12.9421 4.64776 12.2642 4.64776 11.4534V10.7894C4.64776 10.1883 4.35421 9.60815 3.87193 9.25169L2.85144 8.49682C2.2783 8.07745 1.92184 7.42043 1.84495 6.72148C3.17995 7.16182 4.57087 7.39946 6.00372 7.39946C7.43657 7.39946 8.8275 7.16182 10.1625 6.72148C10.0856 7.42742 9.73612 8.07745 9.16997 8.50381Z"
                fill="#263746"
                fillOpacity="0.48"
            />
        </SvgIcon>
    );
}
