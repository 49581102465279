import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { ENVIRONMENTS } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useGetEnvironmentNameQuery } from 'services/switchboard';

const nameMap = {
    [ENVIRONMENTS.LOCAL]: 'Local',
    [ENVIRONMENTS.TEST]: 'Test',
    [ENVIRONMENTS.STAGING]: 'Demo',
};

const Root = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    opacity: 0.96,
    zIndex: theme.zIndex.tooltip,
    [theme.breakpoints.up('md')]: {
        left: theme.spacing(32),
    },
    [theme.breakpoints.down('md')]: {
        right: 0,
        top: '38%',
    },
    '& *': {
        pointerEvents: 'none',
    },
}));

const ContentContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'envName' })(
    ({ theme, envName }) => {
        let color = theme.palette.common.black;
        let backgroundColor = theme.palette.error.main;
        if (envName === ENVIRONMENTS.LOCAL) {
            color = theme.palette.common.white;
            backgroundColor = theme.palette.primary.main;
        } else if (envName === ENVIRONMENTS.TEST) {
            backgroundColor = '#FFD84F';
        } else if (envName === ENVIRONMENTS.STAGING) {
            backgroundColor = theme.palette.success.main;
        }
        return {
            color,
            backgroundColor,
            padding: theme.spacing(0.25, 5, 1),
            boxShadow: theme.shadows[2],
            [theme.breakpoints.up('md')]: {
                borderBottomRightRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
            },
            [theme.breakpoints.down('md')]: {
                borderTopLeftRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
                padding: theme.spacing(4, 1),
                right: 0,
                top: '50%',
            },
        };
    }
);

const Type = styled(Typography)(({ theme }) => ({
    fontSize: '.8rem',
    fontWeight: 600,
    textTransform: 'capitalize',
    [theme.breakpoints.down('md')]: {
        fontSize: '.8rem',
        writingMode: 'vertical-lr',
        transform: 'rotate(180deg)',
    },
}));

export function EnvironmentIndicator() {
    const { data, isUninitialized, isFetching } = useGetEnvironmentNameQuery();

    if (isUninitialized || isFetching || !nameMap[data]) {
        return null;
    }

    return (
        <Root>
            <ContentContainer envName={data}>
                <Type variant="body">{nameMap[data]}</Type>
            </ContentContainer>
        </Root>
    );
}
