import { memo } from 'react';
import PropTypes from 'prop-types';

import { alpha } from '@mui/system';
import {
    MediaDocIcon,
    MediaGeneralIcon,
    MediaImageIcon,
    MediaPdfIcon,
    MediaSheetIcon,
    MediaVideoIcon,
    MediaAudioIcon,
    MediaPresentationIcon,
} from 'components/Icons';
import { MEDIA_TYPES } from 'utils/constants';

const IconStyleSx = {
    width: (theme) => theme.spacing(4),
    height: (theme) => theme.spacing(4),
    color: (theme) => alpha(theme.palette.primary.main, 0.48),
};

export const FileIcon = memo(({ mediaType, IconStyleSx: customIconStyleSx }) => {
    if (mediaType === MEDIA_TYPES.DOC.value)
        return (
            <MediaDocIcon
                sx={{ ...IconStyleSx, ...customIconStyleSx }}
                aria-label="Document file"
            />
        );
    else if (mediaType === MEDIA_TYPES.PDF.value)
        return <MediaPdfIcon sx={{ ...IconStyleSx, ...customIconStyleSx }} aria-label="PDF file" />;
    else if (mediaType === MEDIA_TYPES.SPREADSHEET.value)
        return (
            <MediaSheetIcon
                sx={{ ...IconStyleSx, ...customIconStyleSx }}
                aria-label="Spreadsheet file"
            />
        );
    else if (mediaType === MEDIA_TYPES.SLIDESHOW.value)
        return (
            <MediaPresentationIcon
                sx={{ ...IconStyleSx, ...customIconStyleSx }}
                aria-label="Presentation file"
            />
        );
    else if (mediaType === MEDIA_TYPES.IMAGE.value)
        return (
            <MediaImageIcon sx={{ ...IconStyleSx, ...customIconStyleSx }} aria-label="Image file" />
        );
    else if (mediaType === MEDIA_TYPES.VIDEO.value)
        return (
            <MediaVideoIcon sx={{ ...IconStyleSx, ...customIconStyleSx }} aria-label="Video file" />
        );
    else if (mediaType === MEDIA_TYPES.AUDIO.value)
        return (
            <MediaAudioIcon sx={{ ...IconStyleSx, ...customIconStyleSx }} aria-label="Audio file" />
        );
    else
        return <MediaGeneralIcon sx={{ ...IconStyleSx, ...customIconStyleSx }} aria-label="File" />;
});

FileIcon.propTypes = {
    mediaType: PropTypes.string,
    IconStyleSx: PropTypes.object,
};
