import { useCallback, useEffect, useState } from 'react';
import find from 'lodash/find';

import { MESSAGE_TYPES } from 'utils/constants';
import { CONTACT_MEDIUM } from 'features/TalentCard/utils/constants';
import { useGetTalentContactsQuery, useGetUserContactSettingsQuery } from 'services/contacts';

export function useGetPrimaryContacts({ talentId, messageType, conversationId, skip }) {
    const [primaryUserContact, setPrimaryUserContact] = useState('');

    const { data: userContactSettings, isSuccess: isUserSettingsLoaded } =
        useGetUserContactSettingsQuery(talentId, {
            skip: !conversationId || !talentId,
        });

    const { data: talentContacts, isSuccess: isTalentContactsLoaded } = useGetTalentContactsQuery(
        [talentId],
        { skip: !talentId }
    );

    const getPrimaryEmailContact = useCallback(() => {
        const primaryContactId = userContactSettings?.primary?.[CONTACT_MEDIUM.EMAIL];
        const primaryContact = find(talentContacts?.[talentId]?.emails?.data, {
            contactId: primaryContactId,
        })?.email;
        setPrimaryUserContact(primaryContact);
    }, [talentContacts, talentId, userContactSettings]);

    const getPrimaryPhoneContact = useCallback(() => {
        const primaryContactId = userContactSettings?.primary?.[CONTACT_MEDIUM.PHONE];
        const primaryContact = find(talentContacts?.[talentId]?.phones?.data, {
            contactId: primaryContactId,
        })?.phone;
        setPrimaryUserContact(primaryContact);
    }, [talentContacts, talentId, userContactSettings]);

    const getPrimaryContact = useCallback(() => {
        switch (messageType) {
            case MESSAGE_TYPES.EMAIL:
                getPrimaryEmailContact();
                break;
            case MESSAGE_TYPES.SMS:
                getPrimaryPhoneContact();
                break;
            default:
                return;
        }
    }, [messageType, getPrimaryEmailContact, getPrimaryPhoneContact]);

    useEffect(() => {
        setPrimaryUserContact('');
    }, [conversationId]);

    useEffect(() => {
        if (skip) {
            return;
        }
        if (isUserSettingsLoaded && isTalentContactsLoaded) {
            getPrimaryContact();
        }
    }, [skip, getPrimaryContact, isUserSettingsLoaded, isTalentContactsLoaded]);

    return primaryUserContact;
}
