import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { Typography } from 'components/Typography';
import { ChevronUpIcon } from 'components/Icons';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'unset',
    width: '80px',
    backgroundColor: theme.palette.common.blueGray,
    padding: theme.spacing(1.38),
    '&:hover': {
        backgroundColor: theme.palette.common.blueGray,
    },
}));

export function LocationDropDownMenu(props) {
    const {
        children,
        locationRadius,
        locationUnit,
        openMenu,
        handleButtonClick,
        setIsSliderVisible,
    } = props;

    return (
        <>
            <StyledButton
                id="filter-location-dropDown-button"
                aria-label="filter-location-dropDown-button"
                data-trackid={
                    openMenu
                        ? 'location-filter-radial-dropdown-collapse'
                        : 'location-filter-radial-dropdown-expand'
                }
                aria-controls={openMenu ? 'filter-location-dropDown-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                variant="contained"
                sx={{ borderRadius: openMenu ? '4px 0 0 0px' : '4px 0 0 4px' }}
                disableElevation
                onClick={handleButtonClick}
                endIcon={
                    <ChevronUpIcon
                        sx={
                            !openMenu
                                ? {
                                      transform: 'rotate(-180deg)',
                                      transition: 'transform 0.1s ease-in-out',
                                  }
                                : null
                        }
                    />
                }
            >
                <Typography variant="titleSmall">
                    {locationRadius === 0 ? 'Exact' : `${locationRadius} ${locationUnit}`}
                </Typography>
            </StyledButton>
            {openMenu && (
                <ClickAwayListener onClickAway={() => setIsSliderVisible(false)}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            bgcolor: 'common.blueGray',
                            height: 70,
                            borderRadius: '0 0 4px 4px',
                            width: '100%',
                            zIndex: 'tooltip',
                        }}
                        aria-label={openMenu ? 'filter-location-dropDown-menu' : undefined}
                        aria-hidden={!openMenu}
                    >
                        {children}
                    </Box>
                </ClickAwayListener>
            )}
        </>
    );
}

LocationDropDownMenu.propTypes = {
    children: PropTypes.node,
    locationRadius: PropTypes.number,
    locationUnit: PropTypes.string,
    openMenu: PropTypes.bool,
    handleButtonClick: PropTypes.func,
    setIsSliderVisible: PropTypes.func,
};
