import { Text } from 'slate';
import escapeHtml from 'escape-html';

const serializeLeaf = (node) => {
    let string = escapeHtml(node.text);
    if (node.bold) {
        string = `<strong>${string}</strong>`;
    }
    if (node.code) {
        string = `<code>${string}</code>`;
    }
    if (node.italic) {
        string = `<em>${string}</em>`;
    }
    if (node.underline) {
        string = `<u>${string}</u>`;
    }
    if (node.strikethrough) {
        string = `<del>${string}</del>`;
    }
    return string;
};

const serializeElement = (node, children) => {
    let styles = node.domStyle || '';
    if (node.align) styles += ` text-align: ${node.align};`;
    let tags = node.id ? ` id="${node.id}"` : '';
    tags += styles ? ` style="${styles.trim()}"` : '';

    switch (node.type) {
        case 'block-quote':
            return `<blockquote${tags}>${children}</blockquote>`;
        case 'paragraph':
            return `<p${tags}>${children}</p>`;
        case 'link':
            return `<a${tags} href="${escapeHtml(node.href)}">${children}</a>`;
        case 'img':
            return `<img${tags} src="${escapeHtml(node.src)}" />`;
        case 'heading-one':
            return `<h1${tags}>${children}</h1>`;
        case 'heading-two':
            return `<h2${tags}>${children}</h2>`;
        case 'heading-three':
            return `<h3${tags}>${children}</h3>`;
        case 'heading-four':
            return `<h4${tags}>${children}</h4>`;
        case 'heading-five':
            return `<h5${tags}>${children}</h5>`;
        case 'heading-six':
            return `<h6${tags}>${children}</h6>`;
        case 'list-item':
            return `<li${tags}>${children}</li>`;
        case 'numbered-list':
            return `<ol${tags}>${children}</ol>`;
        case 'div':
            if (!Boolean(children)) {
                return '<br/>';
            }
            return `<div${tags}>${children}</div>`;
        case 'code':
            return `<pre${tags}>${children}</pre>`;
        case 'bulleted-list':
            return `<ul${tags}>${children}</ul>`;
        default:
            if (!Boolean(children)) {
                return '<br/>';
            }
            return `<div>${children}</div>`;
    }
};

const serialize = (node) => {
    let html = '';
    if (Array.isArray(node)) {
        html += node.map((n) => serialize(n)).join('');
        return html;
    }

    if (Text.isText(node)) {
        return serializeLeaf(node);
    }

    let children = '';
    if (node.children) {
        children = node.children.map((_node) => serialize(_node)).join('');
    }

    return serializeElement(node, children);
};

const serializeToPlainText = (node) => {
    let result = '';
    node.forEach((nodeItem) => {
        nodeItem.children?.forEach((child) => {
            if (child.children && child.children.length > 0) {
                result += serializeToPlainText([child]);
            } else {
                if (child.text && child.text !== '') {
                    let childText = child.text.replace(/<[^>]*>/g, '');
                    result += childText + '\n';
                } else {
                    result += '\n';
                }
            }
        });
    });
    return result;
};

export { serialize, serializeToPlainText };
