import { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { formatDate } from 'utils/formatters';
import { SCHEDULED_TIME_FMT_12_HRS } from 'utils/constants';
import { Typography } from 'components/Typography';
import { EditIcon, TimeIcon } from 'components/Icons';
import { MessageTypeIcon } from './MessageTypeIcon';
import { MessageTypeLabel } from './MessageTypeLabel';
import { DeleteMessageButton } from './DeleteMessageButton';
import { UpdateScheduledDateTimePopup } from './UpdateScheduledDateTimePopup';
import { useUpdateMessageMutation } from 'services/conversations';
import { MessageFailureSubInfo } from './MessageFailureSubInfo';

const TimeIconSxProps = {
    fontSize: 16,
    mr: 0.5,
    color: (t) => alpha(t.palette.text.primary, 0.72),
};

export function MessageSubInfo(props) {
    const {
        status,
        messageType,
        sendDate,
        sendType,
        isMatchingMessages,
        isScheduledMessage,
        isDigitalAssistantMessage,
        isSequenceMessage,
        isOutgoing,
        isEditing,
        onJumpToMessage,
        messageId,
        conversationId,
        onEditMessage,
        getInitialMessages,
        sender,
        recipient,
        contactTraversal,
        talent,
    } = props;

    const anchorRef = useRef();

    const [open, setOpen] = useState(false);

    const [updateMessage, { isFetching }] = useUpdateMessageMutation();

    const utcDateTime = useMemo(() => {
        if (sendDate?.includes('Z')) return sendDate;
        return `${sendDate}Z`;
    }, [sendDate]);

    const formattedDate = formatDate(utcDateTime, 'PPP');

    const formattedTime = formatDate(
        utcDateTime,
        isScheduledMessage ? SCHEDULED_TIME_FMT_12_HRS : 'pp'
    );

    const isFailed = status === 'failed';

    return (
        <Box
            sx={{
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                height: '24px',
                ml: isEditing ? 1 : 0,
                gap: '12px',
            }}
        >
            <MessageTypeIcon
                messageType={messageType}
                isDigitalAssistantMessage={isDigitalAssistantMessage}
                isSequenceMessage={isSequenceMessage}
            />
            <MessageTypeLabel
                isOutgoing={isOutgoing}
                sender={sender}
                recipient={recipient}
                messageType={messageType}
                isDigitalAssistantMessage={isDigitalAssistantMessage}
                isSequenceMessage={isSequenceMessage}
            />
            {isFailed && (
                <MessageFailureSubInfo
                    talent={talent}
                    contactTraversal={contactTraversal}
                    sendType={sendType}
                    messageType={messageType}
                />
            )}
            <Typography
                variant="titleSmall"
                aria-label="Send date"
                role={isScheduledMessage ? 'button' : ''}
                ref={anchorRef}
                onClick={() => !isFetching && setOpen(true)}
                sx={{
                    color: (theme) =>
                        alpha(
                            isScheduledMessage
                                ? theme.palette.primary.main
                                : theme.palette.text.primary,
                            isScheduledMessage ? 1 : 0.48
                        ),
                    fontWeight: 500,
                    fontSize: '13px',
                    lineHeight: '13px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: isScheduledMessage ? 'pointer' : 'inherit',
                }}
            >
                {isFetching ? (
                    'Updating...'
                ) : (
                    <>
                        {isScheduledMessage && (
                            <>
                                <TimeIcon sx={TimeIconSxProps} />
                                <span style={{ color: 'rgba(38, 55, 70, 1)' }}>
                                    Scheduled for&nbsp;
                                </span>
                            </>
                        )}
                        {formattedDate}&nbsp;&nbsp;&nbsp;{formattedTime}
                    </>
                )}
            </Typography>
            {isScheduledMessage && (
                <UpdateScheduledDateTimePopup
                    anchorEl={anchorRef.current}
                    open={open}
                    setOpen={setOpen}
                    utcDateTime={utcDateTime}
                    messageId={messageId}
                    messageType={messageType}
                    conversationId={conversationId}
                    updateMessage={updateMessage}
                    getInitialMessages={getInitialMessages}
                />
            )}
            {isMatchingMessages && (
                <Button
                    aria-label="jump-to-message-button"
                    sx={{ ml: 'auto', textTransform: 'none' }}
                    onClick={onJumpToMessage}
                >
                    <Typography
                        variant="titleSmall"
                        sx={{
                            color: (theme) => alpha(theme.palette.text.primary, 0.48),
                            fontWeight: 500,
                        }}
                    >
                        Jump to message in thread
                    </Typography>
                </Button>
            )}
            {isScheduledMessage && !isEditing && (
                <Box
                    sx={{
                        display: 'flex',
                        marginLeft: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        disableRipple
                        disabled={isFetching}
                        aria-label="Edit message"
                        onClick={onEditMessage}
                    >
                        <EditIcon color="primary" sx={{ fontSize: 16 }} />
                    </IconButton>
                    <DeleteMessageButton
                        messageId={messageId}
                        messageType={messageType}
                        conversationId={conversationId}
                        getInitialMessages={getInitialMessages}
                    />
                </Box>
            )}
        </Box>
    );
}

MessageSubInfo.propTypes = {
    status: PropTypes.string,
    messageType: PropTypes.string,
    sendDate: PropTypes.string,
    sendType: PropTypes.string,
    messageId: PropTypes.string,
    sender: PropTypes.string,
    recipient: PropTypes.string,
    conversationId: PropTypes.string,
    isMatchingMessages: PropTypes.bool,
    isScheduledMessage: PropTypes.bool,
    isDigitalAssistantMessage: PropTypes.bool,
    isSequenceMessage: PropTypes.bool,
    isEditing: PropTypes.bool,
    onJumpToMessage: PropTypes.func,
    onEditMessage: PropTypes.func,
    getInitialMessages: PropTypes.func,
    isOutgoing: PropTypes.bool,
    talent: PropTypes.object,
    contactTraversal: PropTypes.array,
};
