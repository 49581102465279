import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { styled, alpha } from '@mui/material';

import { Typography } from 'components/Typography';
import { usePhoneInput } from '../hooks/usePhoneInput';

const CustomTextField = styled(TextField)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    '& .MuiInputBase-root': {
        height: '45px',
    },
    '& .MuiInputBase-input::placeholder': {
        color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },
        '& .MuiOutlinedInput-input': {
            paddingLeft: 0,
            lineHeight: '20px',
            fontWeight: 300,
            letterSpacing: 0.25,
            color: theme.palette.text.primary,
            WebkitTextFillColor: theme.palette.text.primary,
        },
    },
}));

export const HelperSubText = styled(Typography)(({ theme }) => ({
    lineHeight: '14px',
    fontWeight: 400,
    marginLeft: theme.spacing(0.5),
    color: alpha(theme.palette.text.primary, 0.48),
}));

export const FormLabelText = styled(Typography)(({ theme }) => ({
    lineHeight: '14px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 800,
    letterSpacing: 0.5,
    color: theme.palette.text.primary,
}));

export function CustomInputField({
    id,
    label,
    testid,
    variant = 'outlined',
    type,
    fullWidth = true,
    name,
    register,
    validationOptions,
    errors,
    disabled,
    subText,
    defaultValue,
    placeholder,
    inputProps,
    startAdornment,
    isPhoneInput = false,
}) {
    const phoneInput = usePhoneInput(isPhoneInput, defaultValue);

    const configureInputProps = () => {
        if (isPhoneInput) {
            return {
                startAdornment: startAdornment,
                ...phoneInput,
                inputProps: inputProps,
            };
        } else if (startAdornment || inputProps) {
            return {
                startAdornment: startAdornment,
                inputProps: inputProps,
            };
        }
        return {};
    };

    return (
        <>
            <FormLabelText>
                <span style={{ color: 'red' }}>*</span>
                {label}
            </FormLabelText>
            <CustomTextField
                data-testid={testid}
                aria-label="user-input-field"
                margin="dense"
                id={id}
                variant={variant}
                type={type}
                disabled={Boolean(disabled)}
                fullWidth={fullWidth}
                InputProps={configureInputProps()}
                name={name}
                placeholder={placeholder}
                value={isPhoneInput ? phoneInput.value : null}
                defaultValue={isPhoneInput ? null : defaultValue}
                onChange={isPhoneInput ? phoneInput.onChange : null}
                {...register(name, validationOptions)}
                error={Boolean(errors[name])}
                helperText={errors[name]?.message}
            />
            {subText && <HelperSubText>{subText}</HelperSubText>}
        </>
    );
}

CustomInputField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    variant: PropTypes.string,
    type: PropTypes.string,
    fullWidth: PropTypes.bool,
    name: PropTypes.string.isRequired,
    register: PropTypes.func,
    validationOptions: PropTypes.object,
    errors: PropTypes.object,
    disabled: PropTypes.bool,
    subText: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    testid: PropTypes.string,
    inputProps: PropTypes.object,
    isPhoneInput: PropTypes.bool,
    startAdornment: PropTypes.node,
};
