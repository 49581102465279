import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';

export function MatchIcon(props) {
    const { gradient, ...restProps } = props;
    const stroke = gradient ? 'url(#match_gradient)' : 'currentColor';
    return (
        <SvgIcon viewBox="0 0 34 24" {...restProps}>
            <path
                d="M17 12C17 16.4183 13.4183 20 9 20C4.58172 20 1 16.4183 1 12C1 7.58172 4.58172 4 9 4C13.4183 4 17 7.58172 17 12ZM17 12C17 16.4183 20.5817 20 25 20C29.4183 20 33 16.4183 33 12C33 7.58172 29.4183 4 25 4C20.5817 4 17 7.58172 17 12ZM25 12C25 16.4183 21.4183 20 17 20C12.5817 20 9 16.4183 9 12C9 7.58172 12.5817 4 17 4C21.4183 4 25 7.58172 25 12Z"
                stroke={stroke}
                strokeWidth="1.3"
                fill="none"
            />
            <defs>
                <linearGradient
                    id="match_gradient"
                    x1="1"
                    y1="9.74648"
                    x2="33.0057"
                    y2="10.0543"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

MatchIcon.propTypes = {
    gradient: PropTypes.bool,
};
