import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';

import { CloseIcon, CheckIcon } from 'components/Icons';
import { Tooltip } from 'components/Tooltip';
import { FilterChip } from './FilterChip';

export function ExcludedChip(props) {
    const {
        label,
        filterKey,
        index,
        onClick,
        onDelete,
        isCustomLabel,
        disableTooltip,
        ...restProps
    } = props;
    return (
        <FilterChip
            label={label}
            icon={
                <Tooltip title="Include this filter">
                    <div className="MuiChip-icon">
                        <CheckIcon
                            aria-label={`include-${isCustomLabel ? index : label}-chip`}
                            onClick={() => onClick(filterKey, index)}
                            data-trackid="filter-chip-include-toggle"
                            sx={{
                                fontSize: '13px',
                                mr: '3px',
                                color: (theme) => alpha(theme.palette.common.white, 0.48),
                                '&:hover': { color: 'success.main' },
                            }}
                        />
                    </div>
                </Tooltip>
            }
            deleteIcon={
                <CloseIcon
                    data-trackid="filter-chip-remove"
                    aria-label={`delete-${isCustomLabel ? index : label}-excluded-chip`}
                />
            }
            onDelete={() => onDelete(filterKey, index)}
            sx={{
                '&.MuiChip-root': {
                    bgcolor: (theme) => alpha(theme.palette.error.main, 0.24),
                },
            }}
            disableTooltip={disableTooltip}
            {...restProps}
        />
    );
}

ExcludedChip.propTypes = {
    label: PropTypes.any,
    index: PropTypes.number,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
    filterKey: PropTypes.any,
    isCustomLabel: PropTypes.bool,
    disableTooltip: PropTypes.bool,
};
