import SvgIcon from '@mui/material/SvgIcon';

export function CheckCircleIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                fill="currentColor"
                fillOpacity="0.24"
            />
            <path
                d="M18.71 7.2101C18.617 7.11638 18.5064 7.04198 18.3846 6.99121C18.2627 6.94044 18.132 6.91431 18 6.91431C17.868 6.91431 17.7373 6.94044 17.6154 6.99121C17.4936 7.04198 17.383 7.11638 17.29 7.2101L9.84001 14.6701L6.71001 11.5301C6.61349 11.4369 6.49955 11.3636 6.37469 11.3143C6.24984 11.2651 6.11651 11.241 5.98233 11.2433C5.84815 11.2457 5.71574 11.2744 5.59266 11.3279C5.46959 11.3814 5.35825 11.4586 5.26501 11.5551C5.17177 11.6516 5.09846 11.7656 5.04925 11.8904C5.00005 12.0153 4.97592 12.1486 4.97824 12.2828C4.98056 12.417 5.00929 12.5494 5.06278 12.6725C5.11628 12.7955 5.19349 12.9069 5.29001 13.0001L9.13001 16.8401C9.22297 16.9338 9.33358 17.0082 9.45543 17.059C9.57729 17.1098 9.708 17.1359 9.84001 17.1359C9.97202 17.1359 10.1027 17.1098 10.2246 17.059C10.3464 17.0082 10.457 16.9338 10.55 16.8401L18.71 8.68011C18.8115 8.58646 18.8925 8.47281 18.9479 8.34631C19.0033 8.21981 19.0319 8.08321 19.0319 7.94511C19.0319 7.807 19.0033 7.6704 18.9479 7.5439C18.8925 7.4174 18.8115 7.30375 18.71 7.2101Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
