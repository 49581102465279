import SvgIcon from '@mui/material/SvgIcon';

export function EducationIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M10.5068 5.39851C10.9615 5.1374 11.4766 5 12.0009 5C12.5253 5 13.0404 5.1374 13.4951 5.39851L20.7508 9.56494C20.8271 9.60877 20.8904 9.67192 20.9345 9.74804C20.9785 9.82416 21.0017 9.91055 21.0017 9.99849C21.0017 10.0864 20.9785 10.1728 20.9345 10.2489C20.8904 10.3251 20.8271 10.3882 20.7508 10.432L18.0016 12.0102V16.4987C18.0017 16.5644 17.9888 16.6295 17.9638 16.6902C17.9387 16.751 17.9019 16.8062 17.8555 16.8527L17.8535 16.8537L17.8515 16.8567L17.8445 16.8627L17.8215 16.8847L17.7415 16.9587C17.6438 17.0445 17.5434 17.1272 17.4405 17.2067C17.0813 17.4825 16.6996 17.7278 16.2994 17.9398C14.9758 18.6419 13.4992 19.0061 12.0009 18.9999C10.5027 19.0061 9.02608 18.6419 7.70249 17.9398C7.30264 17.7277 6.92126 17.4825 6.56237 17.2067C6.43048 17.1049 6.30302 16.9975 6.18033 16.8847C6.12535 16.8362 6.08098 16.7769 6.05 16.7105C6.01903 16.6441 6.00211 16.5719 6.00031 16.4987V12.0102L4.0001 10.8621V15.4986C4.0001 15.6312 3.94742 15.7584 3.85364 15.8522C3.75986 15.9459 3.63267 15.9986 3.50005 15.9986C3.36743 15.9986 3.24024 15.9459 3.14646 15.8522C3.05268 15.7584 3 15.6312 3 15.4986V9.99799C2.99993 9.9082 3.02404 9.82006 3.0698 9.7428C3.11555 9.66555 3.18126 9.60204 3.26003 9.55894L10.5068 5.39851ZM13.4951 14.5975C13.0404 14.8586 12.5253 14.996 12.0009 14.996C11.4766 14.996 10.9615 14.8586 10.5068 14.5975L7.00042 12.5843V16.2756C7.36458 16.5746 7.75755 16.8365 8.17354 17.0577C9.05363 17.5278 10.3488 17.9988 12.0009 17.9988C13.3347 18.0056 14.6495 17.6827 15.8283 17.0587C16.2444 16.8369 16.6374 16.5743 17.0015 16.2746V12.5843L13.4951 14.5965V14.5975ZM12.997 6.2666C12.6939 6.09253 12.3505 6.00093 12.0009 6.00093C11.6514 6.00093 11.3079 6.09253 11.0048 6.2666L4.50416 9.99799L11.0048 13.7304C11.3079 13.9044 11.6514 13.996 12.0009 13.996C12.3505 13.996 12.6939 13.9044 12.997 13.7304L19.4977 9.99799L12.997 6.2656V6.2666Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
