import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PROJECT_ACTIVITY_SUBTYPE } from 'utils';
import { ButtonBase } from '@mui/material';
import { LinkOutIcon } from 'components/Icons';
import { TruncatedText } from 'views/Projects/components/TruncatedText';
import { Link } from 'react-router-dom';

function LinkButton({ title, url }) {
    return (
        <ButtonBase LinkComponent={Link} to={url} target="_blank" sx={{ mb: 0.5 }}>
            <TruncatedText
                text={title}
                color="primary.main"
                noWrap
                variant="bodyBase"
                sx={{
                    fontWeight: 500,
                    maxWidth: 360,
                }}
            />
            <LinkOutIcon
                sx={{
                    ml: title ? 1 : 0,
                    color: 'primary.main',
                    fontSize: 12,
                }}
            />
        </ButtonBase>
    );
}

LinkButton.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
};

export function ProjectActivityContent({ activitySubType, metadata, entityConnections }) {
    const label = useMemo(() => {
        switch (activitySubType) {
            case PROJECT_ACTIVITY_SUBTYPE.TALENT_ADDED:
                return <>Added to Project</>;
            case PROJECT_ACTIVITY_SUBTYPE.ARCHIVED:
                return (
                    <>
                        {metadata?.stage ? (
                            <>
                                Archived from Project in <b>{metadata.stage}</b>
                            </>
                        ) : (
                            <>
                                Archived in Project{' '}
                                <LinkButton
                                    title={entityConnections[0]?.data?.name}
                                    url={`/projects/${entityConnections[0]?.entityId}`}
                                />
                            </>
                        )}
                    </>
                );
            case PROJECT_ACTIVITY_SUBTYPE.PROGRESSED:
                if (metadata.previousStage) {
                    return (
                        <>
                            Moved from <b>{metadata.previousStage}</b> to{' '}
                            <b>{metadata.nextStage}</b> in Project
                        </>
                    );
                }
                return (
                    <>
                        Moved to <b>{metadata.nextStage}</b> in Project
                    </>
                )
            case PROJECT_ACTIVITY_SUBTYPE.PROJECT_AUTOMATION_TRIGGERED:
                return <>Automation triggered in Project</>;
            default:
                return null;
        }
    }, [
        activitySubType,
        entityConnections,
        metadata.nextStage,
        metadata.previousStage,
        metadata.stage,
    ]);

    return <>{label}</>;
}

ProjectActivityContent.propTypes = {
    activitySubType: PropTypes.string,
    metadata: PropTypes.object,
    entityConnections: PropTypes.array,
};
