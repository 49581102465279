import { useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { ChevronDownIcon } from 'components/Icons';
import { Typography } from 'components/Typography';

const DropdownButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'isBulk' && prop !== 'fullWidth',
})(({ theme, open, isBulk, fullWidth }) => ({
    boxSizing: 'border-box',
    height: theme.spacing(isBulk ? 3.25 : 5),
    width: theme.spacing(isBulk ? 15 : 10),
    padding: theme.spacing(0, 1),
    textTransform: 'unset',
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: open ? theme.spacing(0.5, 0.5, 0, 0) : theme.spacing(0.5),
    '&:hover': {
        backgroundColor: theme.palette.success.light,
    },
    transition: theme.transitions.create(['border-radius', 'background-color', 'box-shadow'], {
        duration: theme.transitions.duration.shortest,
    }),
    '& .chevron-down': {
        marginLeft: -6,
        marginBottom: -3,
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.shortest,
        }),
        transform: `rotate(${open ? 180 : 0}deg)`,
    },
    '&.Mui-disabled': {
        opacity: 0.32,
        color: theme.palette.common.white,
    },
    ...(fullWidth && {
        width: '100%',
    }),
}));

const MENU_WIDTH = 200;

export function ActionsDropdownButton(props) {
    const {
        title = 'Actions',
        actionsContent,
        variant,
        fullWidth,
        children,
        disablePortal = false,
        ...restProps
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const isDropdownOpen = Boolean(anchorEl);

    const isBulk = variant === 'bulk';
    const horizontalTransform = anchorEl?.offsetLeft < MENU_WIDTH ? 'left' : 'right';

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <DropdownButton
                aria-label="actions-dropdown-button"
                id="actions-dropdown-button"
                aria-controls={isDropdownOpen ? 'actions-dropdown-menu' : undefined}
                aria-expanded={isDropdownOpen ? 'true' : undefined}
                aria-haspopup="true"
                endIcon={<ChevronDownIcon className="chevron-down" />}
                open={isDropdownOpen}
                onClick={handleOpenMenu}
                disableRipple
                isBulk={isBulk}
                fullWidth={fullWidth}
                {...restProps}
            >
                {actionsContent || (
                    <Typography variant={isBulk ? 'bodySmall' : 'titleSmall'}>{title}</Typography>
                )}
            </DropdownButton>
            <Menu
                aria-label="actions-dropdown-menu"
                id="actions-dropdown-menu"
                anchorEl={anchorEl}
                open={isDropdownOpen}
                onClose={handleCloseMenu}
                disablePortal={disablePortal}
                keepMounted
                sx={{
                    '&.MuiMenu-root': {
                        zIndex: disablePortal ? 2000 : null,
                    },
                    '& .MuiPaper-root': {
                        boxShadow:
                            '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                        borderRadius: (theme) =>
                            horizontalTransform === 'left'
                                ? theme.spacing(0, 0.5, 0.5, 0.5)
                                : theme.spacing(0.5, 0, 0.5, 0.5),
                        ...(fullWidth && {
                            borderRadius: (theme) => theme.spacing(0, 0, 0.5, 0.5),
                            minWidth: fullWidth ? anchorEl?.clientWidth : MENU_WIDTH,
                        }),
                    },
                }}
                MenuListProps={{
                    'aria-labelledby': 'actions-dropdown-menu',
                    sx: { p: 0 },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: horizontalTransform,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: horizontalTransform,
                }}
            >
                {typeof children === 'function'
                    ? children({ handleClose: handleCloseMenu })
                    : children}
            </Menu>
        </>
    );
}

ActionsDropdownButton.propTypes = {
    title: PropTypes.string,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.func,
    ]),
    actionsContent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    disablePortal: PropTypes.bool,
};
