import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { StarOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import { MESSAGE_TYPES } from 'utils/constants';
import { getCooldownProps } from 'utils/helpers';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';
import { CooldownIcon, ChevronDownIcon } from 'components/Icons';
import { useContactDetails } from 'features/TalentCard/hooks/useContactDetails';
import { CONTACT_STATUS_MAP } from 'features/TalentCard/utils/constants';
import { useChannelPolicies } from 'views/Communications/hooks/useChannelPolicies';
import { formatPhoneNumber } from 'utils/formatters';
import { cooldownBlue } from 'theme';

function findPrimary(element) {
    return element.primary === true;
}

function filterSubscribed(element) {
    return (
        element.status === CONTACT_STATUS_MAP.UNKNOWN ||
        element.status === CONTACT_STATUS_MAP.VERIFIED
    );
}

const mapContacts = (talentId, policies, messageType) => (contact) => ({
    ...contact,
    value: contact.phone || contact.email,
    label: contact.phone ? formatPhoneNumber(contact.phone) : contact.email,
    ...getCooldownProps(contact, policies?.[talentId], messageType),
});

const StyledButton = styled(Button)(({ theme }) => ({
    marginRight: theme.spacing(1),
    justifyContent: 'left',
}));

export function ContactSelection(props) {
    const { talentId, messageType, onChange, onContactsChange, handleSetCooldown } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [contact, setContact] = useState({});
    const [contacts, setContacts] = useState([]);

    const handleOpen = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    const {
        emailContacts,
        phoneContacts,
        loadingState: { isLoading },
    } = useContactDetails({
        talent: { talentId },
        isOpen: Boolean(talentId),
    });

    const { data: policies, isFetching } = useChannelPolicies({
        talent: { id: talentId },
        skip: !Boolean(talentId),
    });

    const isInitialising = isFetching || isLoading;

    useEffect(() => {
        if (isInitialising) return;
        const mapFunc = (element) => mapContacts(talentId, policies, messageType)(element);
        const staging = (messageType === MESSAGE_TYPES.EMAIL ? emailContacts : phoneContacts) || [];
        setContacts(staging.filter(filterSubscribed).map(mapFunc));
    }, [isInitialising, emailContacts, phoneContacts, policies, talentId, messageType]);

    useEffect(() => {
        onContactsChange?.(contacts);
        const primary = contacts.find(findPrimary);
        if (primary) {
            setContact(primary);
            return;
        }
        if (contacts.length) {
            setContact(contacts[0]);
        }
    }, [contacts, onContactsChange]);

    useEffect(() => {
        if (contact.contactId) {
            onChange?.(contact);
            handleSetCooldown?.(contact);
        }
    }, [contact, onChange, handleSetCooldown]);

    if (!isInitialising && contacts?.length === 0) {
        return (
            <Typography data-testid="no-contact-message" sx={{ fontSize: 10, color: 'red' }}>
                {`Talent has no ${messageType?.toUpperCase()} contacts`}
            </Typography>
        );
    }
    return (
        <>
            <StyledButton
                aria-label="contact-selection-dropdown-button"
                aria-controls={open ? 'contact-selection-menu' : null}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : null}
                data-trackid={`conversation-message-${messageType}-select-dropdown`}
                onClick={isInitialising ? null : handleOpen}
            >
                {isInitialising && <CircularProgress size={20} />}
                {!isInitialising && (
                    <Tooltip title={contact.policyViolationReason || ''} arrow>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            <ChevronDownIcon sx={{ mr: 1, fontSize: 20 }} />
                            <Typography variant="labelSmall">{contact.label || ''}</Typography>
                            {contact.isCoolingDown && (
                                <CooldownIcon
                                    sx={{
                                        ml: 0.5,
                                        fontSize: 16,
                                        color: cooldownBlue,
                                    }}
                                />
                            )}
                        </Box>
                    </Tooltip>
                )}
            </StyledButton>
            <Menu id="contact-selection-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                {contacts?.map(
                    ({
                        value,
                        label,
                        primary,
                        contactId,
                        isCoolingDown,
                        policyViolationReason,
                    }) => (
                        <MenuItem
                            selected={value === contact.value}
                            aria-label={value}
                            key={contactId}
                            value={value}
                            title={label}
                            onClick={(e) => {
                                e.preventDefault();
                                if (isCoolingDown) return;
                                setContact({ contactId, value, label, messageType });
                                handleClose();
                            }}
                        >
                            <Tooltip title={policyViolationReason} arrow>
                                <Box
                                    sx={{
                                        width: (t) => t.spacing(32),
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="labelSmall"
                                        sx={{
                                            color: isCoolingDown ? 'text.disabled' : 'text.primary',
                                        }}
                                        noWrap
                                    >
                                        {label}
                                    </Typography>
                                    <Box
                                        sx={{
                                            ml: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {primary && (
                                            <StarOutlined
                                                color={isCoolingDown ? 'disabled' : 'primary'}
                                                fontSize="small"
                                                sx={{ ml: 0.5 }}
                                            />
                                        )}
                                        {isCoolingDown && (
                                            <CooldownIcon
                                                fontSize="small"
                                                sx={{ ml: 0.5, color: cooldownBlue }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Tooltip>
                        </MenuItem>
                    )
                )}
            </Menu>
        </>
    );
}

ContactSelection.propTypes = {
    talentId: PropTypes.string,
    messageType: PropTypes.string,
    onChange: PropTypes.func,
    onContactsChange: PropTypes.func,
    handleSetCooldown: PropTypes.func,
};
