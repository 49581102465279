import SvgIcon from '@mui/material/SvgIcon';

export function EditIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M13.245 2.817C13.7554 2.30752 14.4436 2.01605 15.1646 2.00399C15.8857 1.99193 16.5832 2.26022 17.1103 2.75234C17.6375 3.24446 17.953 3.92198 17.9904 4.64214C18.0279 5.3623 17.7843 6.06888 17.311 6.613L17.181 6.753L7.57502 16.359C7.37026 16.5635 7.12359 16.7211 6.85202 16.821L6.68702 16.874L2.63202 17.98C2.55347 18.0016 2.47082 18.0038 2.39127 17.9862C2.31171 17.9686 2.23766 17.9318 2.17556 17.8791C2.11345 17.8263 2.06518 17.7592 2.03493 17.6836C2.00469 17.6079 1.99339 17.526 2.00202 17.445L2.01802 17.365L3.12402 13.311C3.20002 13.031 3.33602 12.771 3.52202 12.551L3.63902 12.423L13.245 2.817ZM12.385 5.092L4.34602 13.13C4.25417 13.222 4.18114 13.331 4.13102 13.451L4.08902 13.574L3.21202 16.784L6.42402 15.909C6.50769 15.8862 6.58803 15.8526 6.66302 15.809L6.77002 15.737L6.86802 15.652L14.906 7.612L12.385 5.092ZM16.474 3.524C16.1595 3.20929 15.7388 3.02352 15.2944 3.00317C14.8499 2.98282 14.4139 3.12935 14.072 3.414L13.952 3.524L13.092 4.384L15.612 6.906L16.474 6.046C16.7887 5.73152 16.9745 5.31076 16.9948 4.86633C17.0152 4.4219 16.8687 3.98592 16.584 3.644L16.474 3.524Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
