import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { findSimilar } from 'views/Search';
import { FindSimilarIcon } from 'components/Icons';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { ActionsMenuItem } from 'components/ActionsMenu';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';

export function FindSimilarButton(props) {
    const { talent } = props;

    const [logSearchEvent] = useSearchTelemetry();

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(findSimilar({ talent }));
        logSearchEvent(SEARCH_EVENT_TYPES.FIND_SIMILAR, { profileId: talent.profileId });
    };

    return (
        <ActionsMenuItem
            data-trackid="talent-actions-find-similar-profiles"
            onClick={handleClick}
            label="Find Similar Profiles"
            Icon={FindSimilarIcon}
            component={Link}
            to="/search/results"
        />
    );
}

FindSimilarButton.propTypes = {
    talent: PropTypes.object,
};
