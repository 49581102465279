import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { TextInput } from 'components/Inputs';
import { MediaSectionLabel } from './MediaSectionLabel';

export function FileDisplayName(props) {
    const { displayName, filename, onChange, mediaId, showLabel = true } = props;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {showLabel && (
                <MediaSectionLabel as="label" htmlFor="displayName">
                    Display name
                </MediaSectionLabel>
            )}
            <TextInput
                placeholder="Display Name"
                inputProps={{
                    'data-testid': `display-name-input-${mediaId}`,
                    id: 'displayName',
                    'data-key': mediaId,
                    defaultValue: filename,
                }}
                value={displayName}
                onChange={onChange}
            />
        </Box>
    );
}

FileDisplayName.propTypes = {
    displayName: PropTypes.string,
    filename: PropTypes.string,
    onChange: PropTypes.func,
    mediaId: PropTypes.string,
    showLabel: PropTypes.bool,
};
