import PropTypes from 'prop-types';
import { useRef, useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { Typography } from 'components/Typography';
import { ThreeDotsHorizontalIcon } from 'components/Icons';
import { formatLocalDate } from 'utils/formatters';
import { MediaActions } from './MediaActions';

export function DateColumn({
    attachment,
    showActions = true,
    showDate = true,
    showRemoveAttachment = false,
    showShareAttachment = true,
    talentId,
}) {
    const anchorRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {showDate && (
                    <Typography variant="bodyBase">
                        {formatLocalDate(attachment?.lastUpdated, 'P')}
                    </Typography>
                )}
                {showActions && (
                    <IconButton aria-label="Actions" ref={anchorRef} onClick={handleOpen}>
                        <ThreeDotsHorizontalIcon />
                    </IconButton>
                )}
            </Box>
            <MediaActions
                attachment={attachment}
                anchorEl={anchorRef.current}
                open={isOpen}
                onClose={handleClose}
                showRemoveAttachment={showRemoveAttachment}
                showShareAttachment={showShareAttachment}
                talentId={talentId}
            />
        </>
    );
}

DateColumn.propTypes = {
    attachment: PropTypes.shape({
        lastUpdated: PropTypes.string,
    }),
    showActions: PropTypes.bool,
    showRemoveAttachment: PropTypes.bool,
    showShareAttachment: PropTypes.bool,
    talentId: PropTypes.string,
    showDate: PropTypes.bool,
};
