import SvgIcon from '@mui/material/SvgIcon';

export function LinkedResourcesIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M16.6574 11.2715V8.72846C18.5675 8.22874 19.9889 6.50749 19.9889 4.44197C19.9889 1.98778 18.0011 0 15.5469 0C13.4814 0 11.749 1.43255 11.2604 3.3426H8.72846C8.22874 1.42144 6.50749 0 4.44197 0C1.98778 0 0 1.98778 0 4.44197C0 6.50749 1.42143 8.22874 3.33148 8.72846V11.2715C1.41033 11.7713 0 13.4925 0 15.558C0 18.0122 1.98778 20 4.44197 20C6.50749 20 8.22874 18.5786 8.72846 16.6685H11.2715C11.7712 18.5786 13.4925 20 15.558 20C18.0122 20 20 18.0122 20 15.558C20 13.4925 18.5786 11.7713 16.6685 11.2715H16.6574ZM11.2604 14.4586H8.72846C8.32868 12.8929 7.10716 11.6713 5.55247 11.2715V8.72846C7.10716 8.32868 8.31758 7.10717 8.72846 5.56358H11.2715C11.6824 7.11827 12.8928 8.32868 14.4475 8.72846V11.2715C12.8817 11.6824 11.6602 12.904 11.2604 14.4586ZM15.5469 2.2321C16.7685 2.2321 17.7679 3.23155 17.7679 4.45309C17.7679 5.67463 16.7685 6.67408 15.5469 6.67408C14.3254 6.67408 13.3259 5.67463 13.3259 4.45309C13.3259 3.23155 14.3254 2.2321 15.5469 2.2321ZM2.22099 4.45309C2.22099 3.23155 3.22043 2.2321 4.44197 2.2321C5.66352 2.2321 6.66296 3.23155 6.66296 4.45309C6.66296 5.67463 5.66352 6.67408 4.44197 6.67408C3.22043 6.67408 2.22099 5.67463 2.22099 4.45309ZM4.44197 17.779C3.22043 17.779 2.22099 16.7796 2.22099 15.558C2.22099 14.3365 3.19822 13.3592 4.40866 13.337C4.40866 13.337 4.41975 13.337 4.43086 13.337C4.43086 13.337 4.45307 13.337 4.46417 13.337C5.67461 13.3481 6.66296 14.3365 6.66296 15.558C6.66296 16.7796 5.66352 17.779 4.44197 17.779ZM15.5469 17.779C14.3254 17.779 13.337 16.7907 13.3259 15.5803V15.558C13.3259 14.3476 14.3032 13.3592 15.5136 13.337C15.5136 13.337 15.5247 13.337 15.5358 13.337C15.5358 13.337 15.558 13.337 15.5691 13.337C16.7795 13.3481 17.7679 14.3365 17.7679 15.558C17.7679 16.7796 16.7685 17.779 15.5469 17.779Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
