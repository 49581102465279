import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { Typography } from 'components/Typography';
import { formatLocalDate } from 'utils/formatters';

export function ActivityHeader(props) {
    const { name, createdDate } = props;

    const date = formatLocalDate(createdDate, 'ccc, M/d/yy');

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Typography variant="bodySmallBold">{name}</Typography>
            <Typography color="text.primary" variant="bodySmall" sx={{ ml: 'auto', opacity: 0.48 }}>
                {date}
            </Typography>
        </Box>
    );
}

ActivityHeader.propTypes = {
    name: PropTypes.string,
    createdDate: PropTypes.string,
};
