import PropTypes from 'prop-types';
import List from '@mui/material/List';

import { Typography } from 'components/Typography';

export function CategoryHeader(props) {
    const { category, children } = props;

    return (
        <List
            disablePadding
            sx={{ overflow: 'auto' }}
            subheader={<Typography variant="labelSmall">{category}</Typography>}
        >
            {children}
        </List>
    );
}

CategoryHeader.propTypes = {
    category: PropTypes.string,
    children: PropTypes.node,
};
