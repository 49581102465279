export const CONTACT_LABEL_ENUMS = {
    EMAIL: 'emails',
    SMS: 'phones',
};

export const TARGET_ENUMS = {
    SUBJECT: 'subject',
    BODY: 'body',
};

export const SIGNATURE_DIV_PREFIX = '<div id="signature-block"';

export const SIGNATURE_DIV = `${SIGNATURE_DIV_PREFIX}>`;

export const VALIDATION_ERRORS = {
    SUBJECT: {
        SUBJECT_TOO_SHORT: 'Email subject must not be less than 6 characters',
    },
    BODY: {
        BODY_EMPTY: 'Body must not be empty',
        SMS_BODY_TOO_LONG: 'Body must be less than 1600 characters',
    },
};

export const MESSAGE_SOURCE_OPTIONS = {
    talentJobDescription: {
        value: 'talentJobDescription',
        label: 'Job Description',
        queryParam: 'jobDescriptionId',
    },
    talentSavedSearch: {
        value: 'talentSavedSearch',
        label: 'Saved Search',
        queryParam: 'savedSearchId',
    },
    talentSearchHistory: {
        value: 'talentSearchHistory',
        label: 'Search History',
        queryParam: 'searchHistoryId',
    },
};

export const MESSAGE_LENGTH_OPTIONS = {
    short: {
        value: 'short',
        label: 'Short',
    },
    medium: {
        value: 'medium',
        label: 'Medium',
    },
    long: {
        value: 'long',
        label: 'Long',
    },
};

export const MESSAGE_GENERATION_TARGETS = {
    SOURCE: 'source',
    SOURCE_ID: 'sourceId',
    LENGTH: 'length',
};