import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material';
import { Typography } from 'components/Typography';
import { ContactDetails } from 'features/TalentCard/ContactDetails';
import { useSwitchboard } from 'features/Switchboard';

const EmptyContainer = styled(Box)(({ theme }) => ({
    margin: 'auto',
    textAlign: 'center',
    color: alpha(theme.palette.text.primary, 0.72),
}));

export function RevealFromSocialProfile({ talentData, isContactDataLoading }) {
    const isContactDetailsEnabled = useSwitchboard('FEATURE_NN-1390_CONTACT_DETAILS_UI');

    return (
        <EmptyContainer>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }} variant="bodySmall">
                We match profiles using both public and private databases.
                <br />
                Hidden private data may be revealed through linked social profiles.
                {isContactDetailsEnabled && (
                    <Box sx={{ display: 'inline-block', pl: 0.5 }}>
                        <ContactDetails
                            talent={talentData}
                            isLoading={isContactDataLoading}
                            onlySocialIcon
                        />
                    </Box>
                )}
            </Typography>
        </EmptyContainer>
    );
}

RevealFromSocialProfile.propTypes = {
    talentData: PropTypes.object.isRequired,
    isContactDataLoading: PropTypes.bool,
};
