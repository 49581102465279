import { styled } from '@mui/material';
import { Typography } from 'components/Typography';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { EditIcon, DeleteIcon } from 'components/Icons';
import { getValueByMode } from 'utils/copy/getValueByMode';
import Link from '@mui/material/Link';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { NOTES_SOURCES } from 'features/TalentNotes/utils/constants';

const NoteFooterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    alignItems: 'center',
}));

export function NoteFooter({
    isDeleteMode,
    isEditMode,
    setDeleteMode,
    setEditMode,
    handleCancelEdit,
    handleDelete,
    handleUpdateNote,
    isDeletingNote,
    isUpdatingNote,
    note,
    editNoteValue,
    isNoteOwner,
    envUrl,
}) {
    const getColorByMode = (theme) =>
        getValueByMode(isDeleteMode, theme.palette.error.main, theme.palette.primary.main);
    const labelByMode = getValueByMode(isDeleteMode, 'delete', 'edit');

    const getIsNoteDirty = () => {
        return getValueByMode(
            isEditMode,
            isEqual(omit(editNoteValue, ['updated_at']), omit(note, ['updated_at'])),
            false
        );
    };

    const getNoteSource = (source, sourceId) => {
        const linkMap = {
            [NOTES_SOURCES.MESSAGE]: `/communications/messages/${sourceId}/`,
            [NOTES_SOURCES.PROJECTS]: `/projects/${sourceId}/`,
            [NOTES_SOURCES.SHORTLIST]: `/shortlist/${sourceId}/`,
        };

        if (sourceId && linkMap[source]) {
            return (
                <Link
                    sx={{
                        textTransform: 'capitalize',
                        textDecoration: 'none',
                        fontSize: '12px',
                    }}
                    aria-label="source-of-note"
                    href={linkMap[source]}
                >
                    {source}
                </Link>
            );
        }
        return (
            <Typography
                variant="bodySmall"
                aria-label="source-of-note"
                sx={{
                    color: 'rgba(38, 55, 70, 0.48)',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                }}
            >
                {source}
            </Typography>
        );
    };

    return (
        <NoteFooterContainer>
            <Box>
                {note?.source && (
                    <Box sx={{ color: 'rgba(38, 55, 70, 0.48)', fontSize: '12px' }}>
                        via {getNoteSource(note.source, note.source_id)}
                    </Box>
                )}
            </Box>
            <Box>
                {(isDeleteMode || isEditMode) && (
                    <>
                        <Button
                            variant="text"
                            sx={{
                                color: (theme) => theme.palette.text.primary,
                                '&:hover': { color: (theme) => theme.palette.text.primary },
                                textTransform: 'none',
                                height: '28px',
                                mr: 1,
                                mb: 1,
                            }}
                            onClick={() => {
                                isDeleteMode ? setDeleteMode(false) : handleCancelEdit();
                            }}
                            aria-label={`cancel-${labelByMode}-note`}
                            data-trackid="edit-note-cancel"
                            disabled={isDeletingNote || isUpdatingNote}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                background: (theme) => getColorByMode(theme),
                                '&:hover': {
                                    background: (theme) => getColorByMode(theme),
                                },
                                textTransform: 'none',
                                height: '28px',
                                mb: 1,
                                mr: 0.3,
                            }}
                            onClick={() =>
                                isDeleteMode
                                    ? handleDelete()
                                    : handleUpdateNote({
                                          data: {
                                              note_id: note._id,
                                              content: editNoteValue.content,
                                              privacy: editNoteValue.privacy,
                                          },
                                          envUrl,
                                      })
                            }
                            disabled={isDeletingNote || isUpdatingNote || getIsNoteDirty()}
                            aria-label={`confirm-${labelByMode}-note`}
                            data-trackid={isDeleteMode ? null : 'edit-note-save'}
                        >
                            {getValueByMode(isDeleteMode, 'Delete', 'Save')}
                        </Button>
                    </>
                )}
                {!isDeleteMode && !isEditMode && isNoteOwner && (
                    <>
                        <IconButton
                            disableRipple
                            onClick={() => setEditMode(true)}
                            aria-label="edit-note"
                            sx={{ padding: 0 }}
                            data-trackid="note-edit"
                        >
                            <EditIcon
                                sx={{
                                    height: '15px',
                                    width: '15px',
                                    marginRight: '2px',
                                    color: '#3A8DD3',
                                    mt: 0.3,
                                }}
                            />
                        </IconButton>
                        <IconButton
                            disableRipple
                            onClick={() => setDeleteMode(true)}
                            aria-label="delete-note"
                            data-trackid="note-delete"
                        >
                            <DeleteIcon
                                sx={{
                                    height: '15px',
                                    width: '15px',
                                    color: 'red',
                                }}
                            />
                        </IconButton>
                    </>
                )}
            </Box>
        </NoteFooterContainer>
    );
}

NoteFooter.propTypes = {
    isDeleteMode: PropTypes.bool,
    isEditMode: PropTypes.bool,
    setDeleteMode: PropTypes.func,
    setEditMode: PropTypes.func,
    handleCancelEdit: PropTypes.func,
    handleDelete: PropTypes.func,
    handleUpdateNote: PropTypes.func,
    isDeletingNote: PropTypes.bool,
    isUpdatingNote: PropTypes.bool,
    note: PropTypes.object,
    editNoteValue: PropTypes.object,
    isNoteOwner: PropTypes.bool,
    envUrl: PropTypes.string,
};
