const BASE_SEQUENCE_URL = '/api/messaging-workflow/v1';
const SEQUENCE_API_ENDPOINT = `${BASE_SEQUENCE_URL}/sequences`;

const buildGetRequest = (url, params) => {
    return url + '?' + new URLSearchParams(params).toString();
};

const mutateFetch = async (url, data = {}, headers, method) => {
    const response = await fetch(url, {
        method,
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data }),
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

const getFetch = async (url, headers) => {
    const response = await fetch(url, {
        method: 'GET',
        headers,
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * GET /api/messaging-workflow/v1/sequences
 * Returns a list of sequence templates
 */
export const getSequencesApi = async ({ queryKey, meta, headers }) => {
    // pageSize set to 1000 because the backend defaults to 10 and we want them all
    const [, params] = queryKey;
    const url = buildGetRequest(SEQUENCE_API_ENDPOINT, { pageSize: 1000, ...params });

    const response = await fetch(url, {
        method: 'GET',
        headers,
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * GET /api/messaging-workflow/v1/sequences/:sequenceId
 * Returns a sequence template
 */
export const getSequenceApi = async ({ queryKey, headers }) => {
    const [, { sequenceId }] = queryKey;

    const response = await fetch(`${SEQUENCE_API_ENDPOINT}/${sequenceId}`, {
        method: 'GET',
        headers,
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * GET /api/messaging-workflow/v1/sequences/:sequenceId/enrollees
 * Returns a sequence enrollees
 */
export const getSequenceEnrollees = async ({ queryKey, headers }) => {
    const [, { sequenceId, pageSize }] = queryKey;

    const url = buildGetRequest(`${SEQUENCE_API_ENDPOINT}/${sequenceId}/enrollees`, {
        pageSize: pageSize || 10,
    });
    const response = await fetch(url, {
        method: 'GET',
        headers,
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * POST /api/messaging-workflow/v1/sequences
 * Creates a new sequence template and returns the sequence_id
 */
export const createNewSequenceApi = async ({ data, headers }) => {
    const url = SEQUENCE_API_ENDPOINT;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json(); // sequence_id
};

/**
 *
 * POST /api/messaging-workflow/v1/sequences/:sequenceId/enrollees/:instanceId/unenroll
 * Unenrolls a recipient
 */

export const unenrollRecipientApi = async ({ sequenceId, instanceId, headers }) => {
    const response = await fetch(
        `${SEQUENCE_API_ENDPOINT}/${sequenceId}/enrollees/${instanceId}/unenroll`,
        {
            method: 'POST',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        }
    );

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * PUT /api/messaging-workflow/v1/sequences/:sequenceId
 * Updates existing sequence template and returns the updated template
 */

export const updateSequenceApi = async ({ sequenceId, data, headers }) => {
    const response = await fetch(`${SEQUENCE_API_ENDPOINT}/${sequenceId}`, {
        method: 'PUT',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data }),
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * PUT /api/messaging-workflow/v1/sequences/:sequenceId/status
 * Updates sequence status and returns the updated template
 */

export const updateSequenceStatusApi = async ({ sequenceId, data, headers }) => {
    const response = await fetch(`${SEQUENCE_API_ENDPOINT}/${sequenceId}/status`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...data }),
    });

    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

/**
 *
 * POST /api/messaging-workflow/v1/sequences/:sequenceId/archive
 * Returns { archived: true } if sequence has been archived
 */
export const archiveSequenceApi = async ({ sequenceId, archived, headers }) => {
    const url = `${SEQUENCE_API_ENDPOINT}/${sequenceId}/archive`;
    const response = await mutateFetch(url, { archived }, headers, 'POST');
    return response;
};

/**
 *
 * POST /api/messaging-workflow/v1/sequences/:sequenceId/clone
 * Returns {sequence_id: newly_created_sequence_id} if sequence has been copied
 */
export const duplicateSequenceApi = async ({ sequenceId, headers }) => {
    const url = `${SEQUENCE_API_ENDPOINT}/${sequenceId}/clone`;
    const response = await mutateFetch(url, {}, headers, 'POST');
    return response;
};

/**
 *
 * GET /api/messaging-workflow/v1/sequences/metrics
 * Returns sequences metrics
 */
export const getSequencesMetrics = async ({ queryKey, headers }) => {
    const response = await getFetch(`${SEQUENCE_API_ENDPOINT}/metrics`, headers);
    return response;
};

/**
 *
 * PUT /api/messaging-workflow/v1/sequences/:sequenceId/attachment/delete
 * Deletes attachments from a node
 */
export const deleteNodeAttachments = async ({ sequenceId, nodeId, data, headers }) => {
    const url = `${SEQUENCE_API_ENDPOINT}/${sequenceId}/${nodeId}/attachment/delete`;
    const response = await mutateFetch(url, data, headers, 'PUT');
    return response;
};
