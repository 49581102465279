import { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { alpha, styled } from '@mui/system';

import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';

import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';
import { Typography, TYPOGRAPHY_VARIANTS } from 'components/Typography';
import { setMatchText } from '../../slices/mediaSlice';
import { MediaTypeContent, TagContent, SourceContent, MatchText } from './components';
import { useGetMediaTagsQuery } from 'services/knowledgeBases';
import { MEDIA_SOURCE_FILTER_ARRAY } from 'utils/constants';

const FilterButtonSxProps = {
    textTransform: 'none',
    color: 'text.primary',
    display: 'flex',
    alignItems: 'center',
    minWidth: 'fit-content',
};

const ChevronSxProps = { ml: 1, fontSize: '14px' };

const AnchorOriginProps = { vertical: 'bottom', horizontal: 'left' };

export const StyledChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-root': {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        ...TYPOGRAPHY_VARIANTS.titleSmall,
    },
}));

export function MediaFilters({ onOpenPicker, showAddButton = true }) {
    const fileFilterRef = useRef(null);
    const tagFilterRef = useRef(null);
    const sourceFilterRef = useRef(null);

    const [matchText, updateMatchText] = useState('');
    const [isFilePopoverOpen, setFilePopoverOpen] = useState(false);
    const [isTagPopoverOpen, setTagPopoverOpen] = useState(false);
    const [isSourcePopoverOpen, setSourcePopoverOpen] = useState(false);

    const tags = useSelector((state) => state.media.tags);
    const mediaTypes = useSelector((state) => state.media.mediaTypes);
    const sources = useSelector((state) => state.media.sources);

    const { data: mediaTags, isFetching: isFetchingMediaTags } = useGetMediaTagsQuery();

    const dispatch = useDispatch();

    const handleCloseFileFilter = () => {
        setFilePopoverOpen(false);
    };

    const handleCloseTagFilter = () => {
        setTagPopoverOpen(false);
    };

    const handleCloseSourceFilter = () => {
        setSourcePopoverOpen(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceMatchText = useCallback(
        debounce((value) => {
            dispatch(setMatchText(value));
        }, 1000),
        [dispatch]
    );

    const handleMatchText = (e) => {
        updateMatchText(e.target.value);
    };

    useEffect(() => {
        debounceMatchText(matchText);
    }, [matchText, debounceMatchText]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <MatchText value={matchText} onChange={handleMatchText} />

                    <Button
                        variant="text"
                        sx={FilterButtonSxProps}
                        ref={fileFilterRef}
                        disableRipple
                        disableFocusRipple
                        onClick={() => {
                            setFilePopoverOpen(true);
                        }}
                    >
                        <Typography variant="titleSmall">File Type</Typography>
                        {Boolean(mediaTypes.length) && (
                            <StyledChip label={mediaTypes.length} sx={{ ml: 1 }} />
                        )}
                        {!isFilePopoverOpen && <ChevronDownIcon sx={ChevronSxProps} />}
                        {isFilePopoverOpen && <ChevronUpIcon sx={ChevronSxProps} />}
                    </Button>

                    <Button
                        variant="text"
                        sx={FilterButtonSxProps}
                        ref={tagFilterRef}
                        disableRipple
                        disableFocusRipple
                        onClick={() => {
                            setTagPopoverOpen(true);
                        }}
                    >
                        <Typography variant="titleSmall">Tag</Typography>
                        {Boolean(tags.length) && <StyledChip label={tags.length} sx={{ ml: 1 }} />}
                        {!isTagPopoverOpen && <ChevronDownIcon sx={ChevronSxProps} />}
                        {isTagPopoverOpen && <ChevronUpIcon sx={ChevronSxProps} />}
                    </Button>

                    <Button
                        variant="text"
                        sx={FilterButtonSxProps}
                        ref={sourceFilterRef}
                        disableRipple
                        disableFocusRipple
                        onClick={() => {
                            setSourcePopoverOpen(true);
                        }}
                    >
                        <Typography variant="titleSmall">Source</Typography>
                        {Boolean(sources.length) && (
                            <StyledChip label={sources.length} sx={{ ml: 1 }} />
                        )}
                        {!isSourcePopoverOpen && <ChevronDownIcon sx={ChevronSxProps} />}
                        {isSourcePopoverOpen && <ChevronUpIcon sx={ChevronSxProps} />}
                    </Button>
                </Box>
                {showAddButton && (
                    <Button
                        variant="contained"
                        sx={{ textTransform: 'none', marginLeft: 'auto' }}
                        onClick={() => onOpenPicker()}
                    >
                        <Typography variant="titleSmall">Upload New Media</Typography>
                    </Button>
                )}
            </Box>

            <Popover
                open={isFilePopoverOpen}
                anchorEl={fileFilterRef.current}
                onClose={handleCloseFileFilter}
                anchorOrigin={AnchorOriginProps}
            >
                <MediaTypeContent />
            </Popover>

            <Popover
                open={isTagPopoverOpen}
                anchorEl={tagFilterRef.current}
                onClose={handleCloseTagFilter}
                anchorOrigin={AnchorOriginProps}
            >
                <TagContent tags={mediaTags?.tags} isLoading={isFetchingMediaTags} />
            </Popover>

            <Popover
                open={isSourcePopoverOpen}
                anchorEl={sourceFilterRef.current}
                onClose={handleCloseSourceFilter}
                anchorOrigin={AnchorOriginProps}
            >
                <SourceContent sources={MEDIA_SOURCE_FILTER_ARRAY} />
            </Popover>
        </>
    );
}

MediaFilters.propTypes = {
    showAddButton: PropTypes.bool,
    onOpenPicker: PropTypes.func,
};
