import { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';

import { Typography } from 'components/Typography';
import { RefreshIcon } from 'components/Icons';

export const SignatureList = memo((props) => {
    const { signatures, onClose, onUseSignature, onRefreshSignature } = props;

    return (
        <Box>
            <Box sx={{ display: 'flex', pl: 2, pt: 1, alignItems: 'center' }}>
                <Typography variant="labelXSmall" sx={{ color: 'rgba(38, 55, 70, 0.72)', mr: 0.5 }}>
                    Your signatures
                </Typography>
                <IconButton
                    onClick={onRefreshSignature}
                    color="primary"
                    data-testid="signature-refresh-link"
                >
                    <RefreshIcon sx={{ fontSize: 13 }} />
                </IconButton>
            </Box>

            {signatures?.map((signature) => (
                <MenuItem
                    key={signature?.id}
                    onClick={() => {
                        onUseSignature(signature);
                        onClose();
                    }}
                    disableRipple
                    aria-label="signature-item"
                >
                    {signature?.name}
                </MenuItem>
            ))}
        </Box>
    );
});

SignatureList.propTypes = {
    onClose: PropTypes.func,
    onUseSignature: PropTypes.func,
    signatures: PropTypes.array,
    onRefreshSignature: PropTypes.func,
};
