import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { alpha, styled } from '@mui/material/styles';
import { useCallback, useRef, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import InputAdornment from '@mui/material/InputAdornment';

import { IncludedChip } from './IncludedChip';
import { ExcludedChip } from './ExcludedChip';
import { Tooltip } from 'components/Tooltip';
import { EMPLOYMENT_FILTER } from './constants';
import { Typography } from 'components/Typography';
import { AutocompleteSx, InputProps } from './props';
import { industryData } from './filterData/industryData';
import { IncludeExcludeLabel } from './IncludeExcludeLabel';
import { filteredOptions, updateRelationships } from './utils';
import { employmentMenuProps, IconContainer } from './FilterEmployment';
import { changeStandardExcludeFilter, changeStandardFilter } from 'views/Search/searchSlice';
import { OccupationsAndIndustriesAutocomplete } from './OccupationsAndIndustriesAutocomplete';
import {
    CheckCircleIcon,
    ChevronDownIcon,
    IndustryIcon,
    InfoIcon,
    PastIndustryIcon,
} from 'components/Icons';
import {
    CustomWidthTooltip,
    FilterChipContainer,
    FilterDetails,
    FilterMenuItem,
    StyledPaper,
    StyledSelect,
} from './styledComponents';

const StyledPastIndustryIcon = styled(PastIndustryIcon)(({ theme }) => ({
    fontSize: '16px',
    marginLeft: theme.spacing(0.6),
}));

const StyledIndustryIcon = styled(IndustryIcon)(({ theme }) => ({
    fontSize: '16px',
    marginLeft: theme.spacing(0.6),
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginBottom: theme.spacing(-0.5),
    marginLeft: theme.spacing(0.3),
    fontSize: '16px',
    cursor: 'pointer',
    color: theme.palette.common.white,
    mb: -0.5,
    ml: 0.3,
}));

const industriesLabel = {
    [EMPLOYMENT_FILTER.INDUSTRY]: 'Filter by Past & Current Industries',
    [EMPLOYMENT_FILTER.CURRENT_INDUSTRY]: 'Filter by Current Industry',
};

const industriesTooltip = 'This filter searches on standardized industries';
const staticValue = [];

export const getIndustryLabel = (value) => {
    const industry = industryData.find((item) => item.code === value);
    return industry ? `${industry.code} - ${industry.name}` : '';
};

export function FilterIndustries(props) {
    const {
        handleClickExclude,
        industries,
        excludedIndustries,
        currentIndustries,
        excludedCurrentIndustries,
        handleDeleteEmployment,
        handleDeleteExcludedEmployment,
        handleClickInclude,
    } = props;

    const [industryFilter, setIndustryFilter] = useState(EMPLOYMENT_FILTER.INDUSTRY);
    const industriesMenu = useRef();
    const dispatch = useDispatch();

    const isFilteringIndustries = industryFilter === EMPLOYMENT_FILTER.INDUSTRY;
    const isFilteringCurrentIndustries = industryFilter === EMPLOYMENT_FILTER.CURRENT_INDUSTRY;

    const [expanded, setExpanded] = useState({});
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const handleIndustryChange = useCallback(
        (selectedValues) => {
            dispatch(
                changeStandardFilter({
                    property: industryFilter,
                    matchText: selectedValues,
                })
            );
        },
        [dispatch, industryFilter]
    );

    const handleIndustryExcludeChange = useCallback(
        (excludedValues) => {
            dispatch(
                changeStandardExcludeFilter({
                    property: industryFilter,
                    matchText: excludedValues,
                })
            );
        },
        [dispatch, industryFilter]
    );

    const toggleSelect = useCallback(
        (option, isChecked) => {
            let newSelectedCodes =
                industryFilter === EMPLOYMENT_FILTER.INDUSTRY
                    ? [...industries]
                    : [...currentIndustries];

            const index = newSelectedCodes.indexOf(option.code);
            updateRelationships(option, isChecked, newSelectedCodes, index, industryData);
            handleIndustryChange(newSelectedCodes);
        },
        [currentIndustries, handleIndustryChange, industryFilter, industries]
    );

    const toggleExclude = useCallback(
        (option, isChecked) => {
            let newExcludedCodes =
                industryFilter === EMPLOYMENT_FILTER.INDUSTRY
                    ? [...excludedIndustries]
                    : [...excludedCurrentIndustries];

            const index = newExcludedCodes.indexOf(option.code);
            updateRelationships(option, isChecked, newExcludedCodes, index, industryData);
            handleIndustryExcludeChange(newExcludedCodes);
        },
        [excludedCurrentIndustries, excludedIndustries, handleIndustryExcludeChange, industryFilter]
    );

    const toggleExpand = useCallback(
        (code) => {
            const newState = { ...expanded, [code]: !expanded[code] };
            const collapseChildren = (code) => {
                const children = industryData.filter((item) => item.parentCode === code);
                if (children.length) {
                    children.forEach((child) => {
                        newState[child.code] = false;
                        if (child.hasChildren) {
                            collapseChildren(child.code);
                        }
                    });
                }
            };
            if (!newState[code]) {
                collapseChildren(code);
            }

            setExpanded(newState);
        },
        [expanded]
    );

    const filterOptions = useCallback(
        (options, params) => {
            const inputValue = params.inputValue.toLowerCase();
            const expandedStates = { ...expanded };

            if (!inputValue || inputValue.length < 2) return options;

            const newOptions = filteredOptions(options, inputValue, expandedStates, industryData);

            // Update the expanded state if changed.
            if (JSON.stringify(expanded) !== JSON.stringify(expandedStates)) {
                setTimeout(() => setExpanded(expandedStates), 0);
            }
            return newOptions;
        },
        [expanded, setExpanded]
    );

    const handleIndustryFilter = useCallback((event) => {
        setIndustryFilter(event.target.value);
    }, []);

    return (
        <FilterDetails id="filter-industry-content">
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <OccupationsAndIndustriesAutocomplete
                    filterData={industryData}
                    filterOptions={filterOptions}
                    expanded={expanded}
                    toggleExpand={toggleExpand}
                    selected={
                        industryFilter === EMPLOYMENT_FILTER.INDUSTRY
                            ? industries
                            : currentIndustries
                    }
                    excluded={
                        industryFilter === EMPLOYMENT_FILTER.INDUSTRY
                            ? excludedIndustries
                            : excludedCurrentIndustries
                    }
                    toggleSelect={toggleSelect}
                    toggleExclude={toggleExclude}
                    setExpanded={setExpanded}
                    key={industryFilter}
                    fullWidth
                    disableClearable
                    multiple
                    ref={industriesMenu}
                    openOnFocus
                    forcePopupIcon={false}
                    PaperComponent={StyledPaper}
                    onChange={handleIndustryChange}
                    value={staticValue}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    includeCodeInLabel
                    label={
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <Box sx={{ display: 'flex' }}>
                                    {industriesLabel[industryFilter]}
                                    <CustomWidthTooltip
                                        title={
                                            <Typography variant="bodySmall">
                                                {industriesTooltip}
                                            </Typography>
                                        }
                                        placement="top"
                                        arrow
                                        sx={{ ml: 0.5 }}
                                    >
                                        <Box>
                                            <StyledInfoIcon />
                                        </Box>
                                    </CustomWidthTooltip>
                                </Box>
                            </div>
                        </Box>
                    }
                    aria-label="industries-filter-autocomplete"
                    noOptionsText={
                        <Typography variant="labelLarge" noWrap>
                            No Industry Found
                        </Typography>
                    }
                    sx={{ flex: 1 }}
                    TextInputProps={{
                        sx: AutocompleteSx,
                        InputProps: {
                            ...InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start" sx={{ mr: 0 }}>
                                        <StyledSelect
                                            inputProps={{
                                                id: 'filter-industry-menu',
                                                'aria-label': 'industry-filter-type-select',
                                            }}
                                            value={industryFilter}
                                            onChange={handleIndustryFilter}
                                            IconComponent={ChevronDownIcon}
                                            MenuProps={employmentMenuProps(industriesMenu, -0.5)}
                                            renderValue={(value) => {
                                                if (value === EMPLOYMENT_FILTER.INDUSTRY) {
                                                    return (
                                                        <PastIndustryIcon
                                                            sx={{
                                                                color: alpha('#ffffff', 0.48),
                                                                fontSize: '16px',
                                                                padding: '5px 5px',
                                                                margin: 0,
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <StyledIndustryIcon
                                                        value={value}
                                                        sx={{
                                                            color: alpha('#ffffff', 0.48),
                                                            fontSize: '16px',
                                                            padding: '5px 5px',
                                                            margin: 0,
                                                        }}
                                                    />
                                                );
                                            }}
                                        >
                                            <FilterMenuItem
                                                active={isFilteringIndustries}
                                                value={EMPLOYMENT_FILTER.INDUSTRY}
                                                aria-label={`${EMPLOYMENT_FILTER.INDUSTRY}-menu-item`}
                                            >
                                                <ListItemIcon sx={{ color: 'common.white' }}>
                                                    <StyledPastIndustryIcon />
                                                </ListItemIcon>
                                                <Typography variant="titleSmall">
                                                    {industriesLabel[EMPLOYMENT_FILTER.INDUSTRY]}
                                                </Typography>
                                                {isFilteringIndustries && (
                                                    <CheckCircleIcon
                                                        color="success"
                                                        sx={{ ml: 'auto' }}
                                                    />
                                                )}
                                            </FilterMenuItem>
                                            <FilterMenuItem
                                                active={isFilteringCurrentIndustries}
                                                value={EMPLOYMENT_FILTER.CURRENT_INDUSTRY}
                                                aria-label={`${EMPLOYMENT_FILTER.CURRENT_INDUSTRY}-menu-item`}
                                            >
                                                <ListItemIcon sx={{ color: 'common.white' }}>
                                                    <StyledIndustryIcon />
                                                </ListItemIcon>
                                                <Typography variant="titleSmall">
                                                    {
                                                        industriesLabel[
                                                            EMPLOYMENT_FILTER.CURRENT_INDUSTRY
                                                        ]
                                                    }
                                                </Typography>
                                                {isFilteringCurrentIndustries && (
                                                    <CheckCircleIcon
                                                        color="success"
                                                        sx={{ ml: 'auto' }}
                                                    />
                                                )}
                                            </FilterMenuItem>
                                        </StyledSelect>
                                    </InputAdornment>
                                </>
                            ),
                        },
                    }}
                />
            </Box>
            <>
                {Boolean(industries.length) && (
                    <>
                        <IncludeExcludeLabel
                            label="Include"
                            filterKey={EMPLOYMENT_FILTER.INDUSTRY}
                            enableAnyAllSelect
                        />
                        <FilterChipContainer>
                            {industries.map((industryCode, index) => (
                                <IncludedChip
                                    key={`${EMPLOYMENT_FILTER.INDUSTRY}-included-chip-${industryCode}-${index}`}
                                    index={index}
                                    label={
                                        <>
                                            <Tooltip title="Past & Current Industries Filter">
                                                <IconContainer>
                                                    <StyledPastIndustryIcon
                                                        sx={{ fontSize: '14px' }}
                                                    />
                                                </IconContainer>
                                            </Tooltip>
                                            {getIndustryLabel(industryCode)}
                                        </>
                                    }
                                    onClick={handleClickExclude}
                                    onDelete={() =>
                                        handleDeleteEmployment(EMPLOYMENT_FILTER.INDUSTRY, index)
                                    }
                                    filterKey={EMPLOYMENT_FILTER.INDUSTRY}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
                {Boolean(currentIndustries.length) && (
                    <>
                        <IncludeExcludeLabel
                            label="Include"
                            filterKey={EMPLOYMENT_FILTER.CURRENT_INDUSTRY}
                            enableAnyAllSelect
                        />
                        <FilterChipContainer>
                            {currentIndustries.map((industryCode, index) => (
                                <IncludedChip
                                    key={`${EMPLOYMENT_FILTER.CURRENT_INDUSTRY}-included-chip-${industryCode}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Current Industries Filter">
                                                <IconContainer>
                                                    <StyledIndustryIcon sx={{ fontSize: '14px' }} />
                                                </IconContainer>
                                            </Tooltip>
                                            {getIndustryLabel(industryCode)}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickExclude}
                                    onDelete={() =>
                                        handleDeleteEmployment(
                                            EMPLOYMENT_FILTER.CURRENT_INDUSTRY,
                                            index
                                        )
                                    }
                                    filterKey={EMPLOYMENT_FILTER.CURRENT_INDUSTRY}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
                {(Boolean(excludedIndustries.length) ||
                    Boolean(excludedCurrentIndustries.length)) && (
                    <>
                        <IncludeExcludeLabel label="Exclude" />
                        <FilterChipContainer>
                            {excludedIndustries.map((industryCode, index) => (
                                <ExcludedChip
                                    key={`${EMPLOYMENT_FILTER.INDUSTRY}-excluded-chip-${industryCode}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Past & Current Industries Filter">
                                                <IconContainer>
                                                    <StyledPastIndustryIcon
                                                        sx={{ fontSize: '14px' }}
                                                    />
                                                </IconContainer>
                                            </Tooltip>
                                            {getIndustryLabel(industryCode)}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={() =>
                                        handleDeleteExcludedEmployment(
                                            EMPLOYMENT_FILTER.INDUSTRY,
                                            index
                                        )
                                    }
                                    filterKey={EMPLOYMENT_FILTER.INDUSTRY}
                                    isCustomLabel
                                />
                            ))}
                            {excludedCurrentIndustries.map((industryCode, index) => (
                                <ExcludedChip
                                    key={`${EMPLOYMENT_FILTER.CURRENT_INDUSTRY}-excluded-chip-${industryCode}-${index}`}
                                    label={
                                        <>
                                            <Tooltip title="Current Industries Filter">
                                                <IconContainer>
                                                    <StyledIndustryIcon sx={{ fontSize: '14px' }} />
                                                </IconContainer>
                                            </Tooltip>
                                            {getIndustryLabel(industryCode)}
                                        </>
                                    }
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={() =>
                                        handleDeleteExcludedEmployment(
                                            EMPLOYMENT_FILTER.CURRENT_INDUSTRY,
                                            index
                                        )
                                    }
                                    filterKey={EMPLOYMENT_FILTER.CURRENT_INDUSTRY}
                                    isCustomLabel
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
            </>
        </FilterDetails>
    );
}

FilterIndustries.propTypes = {
    handleClickExclude: PropTypes.func,
    handleClickInclude: PropTypes.func,
    industries: PropTypes.array,
    excludedIndustries: PropTypes.array,
    currentIndustries: PropTypes.array,
    excludedCurrentIndustries: PropTypes.array,
    handleDeleteEmployment: PropTypes.func,
    handleDeleteExcludedEmployment: PropTypes.func,
};
