import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import {
    DialogButton,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogButtonSecondary,
} from 'components/Dialog/DialogComponents';
import { CREDITS } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useZeroCreditPrompt } from './hooks/useZeroCreditPrompt';
import { useGetSignedInOrganizationQuery } from 'services/organizations';
import { useUpgradeAccountPrompt } from 'components/Dialog/UpgradeAccountPrompt';
import { isAboveMaxSafeInteger } from 'utils/helpers';
import { InfinityIcon } from 'components/Icons';
import { PricingTable } from 'features/Stripe/PricingTable';
import { getValueByMode } from 'utils/copy';
import UpgradeAccountTable from 'components/Dialog/UpgradeAccountPrompt/UpgradeAccountTable';

const creditLabels = {
    [CREDITS.JD]: 'JD Builder',
    [CREDITS.SEARCH]: 'Search',
    [CREDITS.LOOKUP]: 'Lookup',
};

const CancelButton = styled(DialogButtonSecondary)(({ theme }) => ({
    minWidth: theme.spacing(42),
    marginTop: theme.spacing(1),
    borderColor: 'transparent',
    '&:not(:first-of-type)': {
        marginLeft: 0,
    },
}));

const CreditDisplayContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isEmpty',
})(({ theme, isEmpty }) => ({
    color: isEmpty ? theme.palette.error.main : theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.spacing(10),
}));

function CreditDisplay(props) {
    const { creditType, creditCount, isLoading, isError } = props;

    let isUnlimitedCredits = false;

    if (!isError) {
        isUnlimitedCredits = isAboveMaxSafeInteger(creditCount);
    }

    return (
        <CreditDisplayContainer isEmpty={creditCount < 1 || isError}>
            <Typography variant="labelXSmall" color="text.primary">
                {creditLabels[creditType]}
            </Typography>
            {isLoading && <Skeleton variant="rounded" height={24} width={16} />}
            {!isLoading && !isUnlimitedCredits && (
                <Typography variant="titleMedium" color="inherit">
                    {isError ? '?' : creditCount}
                </Typography>
            )}
            {!isLoading && isUnlimitedCredits && <InfinityIcon />}
        </CreditDisplayContainer>
    );
}

export function ZeroCreditPrompt() {
    const {
        requestCredits,
        isZeroCreditPromptOpen,
        setZeroCreditPromptClosed,
        isLoading: isCreditRequestLoading,
    } = useZeroCreditPrompt();

    const {
        requestUpgrade,
        setStripeConfig,
        stripeConfig,
        isLoading: isUpgradeRequestLoading,
    } = useUpgradeAccountPrompt();

    const isLoading = isCreditRequestLoading || isUpgradeRequestLoading;

    const isPremiumUser = useSelector((state) => state.user?.isPaid);

    const myOrgResponse = useGetSignedInOrganizationQuery(null, {
        refetchOnMountOrArgChange: true,
    });
    const { data, isUninitialized, isFetching, isError } = myOrgResponse;
    const isOrgDataLoading = isUninitialized || isFetching;

    const remainingLookupCredits = Math.max(data?.metrics?.[CREDITS.LOOKUP]?.availableTokens, 0);
    const remainingJDCredits = Math.max(data?.metrics?.[CREDITS.JD]?.availableTokens, 0);
    const remainingSearchCredits = Math.max(data?.metrics?.[CREDITS.SEARCH]?.availableTokens, 0);
    const hasAnyCredits = remainingLookupCredits + remainingJDCredits + remainingSearchCredits > 0;

    const updgradeUserAccount = isPremiumUser ? requestCredits : requestUpgrade;
    const handleCloseModal = () => {
        setStripeConfig(null);
        setZeroCreditPromptClosed();
    };

    return (
        <Dialog
            open={isZeroCreditPromptOpen}
            disableEscapeKeyDown
            aria-describedby="zero-credit-prompt-copy"
            onClose={() => handleCloseModal()}
            PaperProps={{ sx: { maxWidth: 544 } }}
        >
            <DialogContent sx={{ pb: isPremiumUser ? 2 : 0, px: 8 }}>
                <Box sx={{ display: getValueByMode(stripeConfig, 'none', 'block') }}>
                    <DialogContentText
                        id="zero-credit-prompt-copy"
                        sx={{
                            pb: 3,
                            px: 11,
                            color: 'error.main',
                        }}
                    >
                        {hasAnyCredits &&
                            'Your Organization has run out of 1 or more credit types.'}
                        {!hasAnyCredits && 'You have 0 remaining credits!'}
                    </DialogContentText>
                    <Stack direction="row" spacing={6} justifyContent="center">
                        <CreditDisplay
                            creditType={CREDITS.LOOKUP}
                            creditCount={remainingLookupCredits}
                            isLoading={isOrgDataLoading}
                            isError={isError}
                        />
                        <CreditDisplay
                            creditType={CREDITS.JD}
                            creditCount={remainingJDCredits}
                            isLoading={isOrgDataLoading}
                            isError={isError}
                        />
                        <CreditDisplay
                            creditType={CREDITS.SEARCH}
                            creditCount={remainingSearchCredits}
                            isLoading={isOrgDataLoading}
                            isError={isError}
                        />
                    </Stack>
                </Box>
                {!isPremiumUser && (
                    <Box sx={{ pt: 2 }}>
                        <Typography
                            variant="headline"
                            component="p"
                            sx={{ fontWeight: 400, textAlign: 'center', px: 2 }}
                        >
                            Upgrade your account now to access this functionality.
                        </Typography>
                        <Box sx={{ pt: 2 }}>
                            {stripeConfig ? (
                                <PricingTable {...stripeConfig} />
                            ) : (
                                <UpgradeAccountTable handleRequestUpgrade={updgradeUserAccount} />
                            )}
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ flexWrap: 'wrap', px: 8 }}>
                <DialogButton
                    disabled={isLoading}
                    onClick={() => updgradeUserAccount()}
                    sx={{
                        minWidth: '100%',
                        height: '60px',
                        borderColor: 'success.main',
                        bgcolor: 'success.main',
                        display: !!stripeConfig ? 'none' : 'block',
                    }}
                    aria-label={
                        isPremiumUser ? 'request-additional-credits' : 'request-account-upgrade'
                    }
                >
                    <Typography variant="titleSmall">
                        {isPremiumUser ? 'Request additional credits' : 'Upgrade Your Account'}
                    </Typography>
                </DialogButton>
                <CancelButton onClick={() => handleCloseModal()} aria-label="cancel">
                    <Typography variant="titleSmall">Cancel</Typography>
                </CancelButton>
            </DialogActions>
        </Dialog>
    );
}

CreditDisplay.propTypes = {
    creditType: PropTypes.oneOf([...Object.values(CREDITS)]),
    creditCount: PropTypes.number,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
};
