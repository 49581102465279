import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

import { MediaInfo } from './MediaInfo';
import { MediaTags } from './MediaTags';
import { MediaOwner } from './MediaOwner';
import { setSortModel, clearState } from '../slices/mediaSlice';
import { DateColumn } from './DateColumn';
import { MEDIA_SOURCE_FILTERS } from 'utils/constants';
import { useLazyGetManyTalentsQuery } from 'services/talent';

import { useGetMediaQuery } from 'services/knowledgeBases';
import { isMediaRelatedToEntity, useRowSelection } from './MediaModals/useRowSelection';
import { Pagination } from 'components/Pagination';
import { isEqual } from 'lodash';

const GridContainer = styled(Box)(() => ({
    width: '100%',
    margin: 'auto',
}));

const BASE_COLUMN_PROPS = {
    hideable: false,
    filterable: false,
    sortable: false,
    disableColumnMenu: true,
};

const selectMedia = (mediaIds, data) =>
    Boolean(data)
        ? mediaIds.filter((mediaId) =>
              data?.find?.((fetchedMedia) => fetchedMedia.mediaId === mediaId)
          )
        : [];

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '.MuiDataGrid-sortIcon': {
        color: theme.palette.primary.main,
    },
    '.MuiDataGrid-colCellWrapper': {
        borderLeft: 'none',
    },
    '.MuiDataGrid-columnHeaders ': {
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
        borderRadius: 0,
    },
    '.MuiDataGrid-columnSeparator': {
        display: 'none',
    },
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },

    '.MuiDataGrid-cell': {
        borderBottom: 'none',
    },
    '.MuiDataGrid-row': {
        cursor: 'pointer',
    },
    '.MuiDataGrid-selectedRowCount': {
        visibility: 'hidden',
    },
    border: 'none',
    fontSize: '12px',
}));

const PAGE_SIZE = 10;
const QUERY_FIELDS = [
    'displayName',
    'name',
    'size',
    'lastUpdated',
    'owner',
    'created',
    'tags',
    'sourceTalentId',
];

export const columns = (showActions, showManageMediaModal, talent) =>
    [
        {
            ...BASE_COLUMN_PROPS,
            sortable: true,
            field: 'displayName',
            headerName: 'MEDIA NAME',
            flex: 2,
            renderCell: ({ row }) => (
                <MediaInfo attachment={row} showManageMediaModal={showManageMediaModal} />
            ),
        },
        {
            ...BASE_COLUMN_PROPS,
            field: 'owner',
            headerName: 'SOURCE',
            flex: 1,
            renderCell: ({ row }) => (
                <MediaOwner
                    sourceTalentId={row.sourceTalentId}
                    sourceUserId={row.owner.userId}
                    talent={talent}
                />
            ),
        },
        {
            ...BASE_COLUMN_PROPS,
            field: 'tags',
            headerName: 'TAGS',
            flex: 2.5,
            renderCell: ({ row }) => <MediaTags {...row} size="medium" />,
        },
        {
            ...BASE_COLUMN_PROPS,
            sortable: true,
            field: 'lastUpdated',
            headerName: 'LAST UPDATED',
            flex: 1,
            renderCell: ({ row }) => (
                <DateColumn attachment={row} key={row.id} showActions={showActions} />
            ),
        },
    ].filter(Boolean);

export function processMatchText(matchText, dst) {
    if (matchText) {
        dst.matchText = matchText;
    }
}

export function processFilters(mediaTypes, tags, dst) {
    if (tags.length) {
        dst.tags = tags.join(',');
    }
    if (mediaTypes.length) {
        dst.mediaType = mediaTypes.join(',');
    }
}

export function processSourceFilters(sources, args, dst) {
    sources.forEach((source) => {
        switch (source) {
            case MEDIA_SOURCE_FILTERS.YOU.label:
                dst[MEDIA_SOURCE_FILTERS.YOU.value] = args.userId;
                break;
            case MEDIA_SOURCE_FILTERS.YOUR_ORG.label:
                dst[MEDIA_SOURCE_FILTERS.YOUR_ORG.value] = true;
                break;
            default:
                dst[MEDIA_SOURCE_FILTERS.TALENT.value] = true;
                break;
        }
    });
}

export function processSortModel(sortModel, dst) {
    sortModel.forEach(({ field, sort }) => {
        switch (field) {
            case 'displayName':
                dst.sortByDisplayName = sort;
                break;
            case 'lastUpdated':
                dst.sortByLastUpdated = sort;
                break;
            case 'mediaType':
                //implies adding a mediaType column else wont work
                dst.sortByMediaType = sort;
                break;
            default:
                break;
        }
    });
}

export function MediaGrid({
    initialAttachments,
    showActions = true,
    isRowSelectable = false,
    onSelectedRowsChange,
    showManageMediaModal = true,
    onAttachmentsLoadedCallback,
    entityId,
    clearSelectedRows = false,
    onAlreadyAttachedMediaClick,
    maxAttachmentsSize,
    onFileSizeLimitExceeded,
    maxAttachmentsSelectionCount,
    onAttachmentSelectionCountExceeded,
}) {
    const dispatch = useDispatch();

    const pageRef = useRef(1);

    const [page, setPage] = useState(1);
    const [talent, setTalent] = useState({});

    const sortModel = useSelector((state) => state.media.sortModel);
    const matchText = useSelector((state) => state.media.matchText);
    const sources = useSelector((state) => state.media.sources);

    const userId = useSelector((state) => state.user.id);
    const sourceArgs = useMemo(() => ({ userId }), [userId]);

    const tags = useSelector((state) => state.media.tags);
    const mediaTypes = useSelector((state) => state.media.mediaTypes);

    const [getManyTalents] = useLazyGetManyTalentsQuery();

    const args = useMemo(() => {
        const originalArgs = { page, pageSize: PAGE_SIZE, fields: QUERY_FIELDS };
        processMatchText(matchText, originalArgs);
        processSortModel(sortModel, originalArgs);
        processFilters(mediaTypes, tags, originalArgs);
        processSourceFilters(sources, sourceArgs, originalArgs);
        return originalArgs;
    }, [page, sortModel, tags, mediaTypes, matchText, sources, sourceArgs]);

    const { data, isFetching } = useGetMediaQuery(args, { refetchOnMountOrArgChange: true });

    const { selectionModel, handleCellClick } = useRowSelection({
        onAlreadyAttachedMediaClick,
        maxAttachmentsSize,
        onFileSizeLimitExceeded,
        maxAttachmentsSelectionCount,
        onAttachmentSelectionCountExceeded,
        initialAttachments: initialAttachments || [],
        isRowSelectable,
        onSelectedRowsChange,
        clearSelectedRows,
        entityId,
        data,
        isFetching,
        page,
    });

    useEffect(() => {
        return () => {
            dispatch(clearState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!isFetching && isEqual(Object.keys(args), ['page', 'pageSize', 'fields'])) {
            onAttachmentsLoadedCallback?.(data);
        }
    }, [args, data, isFetching, onAttachmentsLoadedCallback]);

    const handleSortModel = useCallback(
        (args) => {
            dispatch(setSortModel(args));
        },
        [dispatch]
    );

    const updatePage = useCallback((_page) => {
        pageRef.current = _page;
        setPage(pageRef.current);
    }, []);

    useEffect(() => {
        if (data?.results.length) {
            const delegate = async () => {
                const talentIds = [];
                data?.results.forEach((file) => {
                    if (file.sourceTalentId) talentIds.push(file.sourceTalentId);
                });
                if (talentIds.length) {
                    const { data } = await getManyTalents({ talentIds });
                    if (data) {
                        setTalent(
                            data.reduce((acc, talent) => {
                                const { id } = talent;
                                return {
                                    ...acc,
                                    [id]: talent,
                                };
                            }, {})
                        );
                    }
                }
            };
            delegate();
        } else {
            if (pageRef.current !== 1) setPage(1);
        }
    }, [data?.results, getManyTalents]);

    return (
        <GridContainer>
            <StyledDataGrid
                aria-label="Media data grid"
                rows={data?.results || []}
                getRowId={(row) => row.mediaId}
                columns={columns(showActions, showManageMediaModal, talent)}
                disableColumnSelector={true}
                loading={isFetching}
                paginationMode="server"
                onPageChange={setPage}
                selectionModel={selectMedia(selectionModel, data?.results)}
                disableSelectionOnClick
                sortingMode="server"
                onSortModelChange={handleSortModel}
                filterMode="server"
                autoHeight
                isRowSelectable={(row) =>
                    row.row !== undefined &&
                    isRowSelectable &&
                    !isMediaRelatedToEntity(row.row, entityId)
                }
                disableVirtualization
                autoPageSize
                onCellClick={handleCellClick}
                components={{
                    Pagination: Pagination,
                }}
                componentsProps={{
                    pagination: {
                        page,
                        pageLength: PAGE_SIZE,
                        infinite: data?.results?.length === PAGE_SIZE,
                        onChangePage: updatePage,
                        showTotal: false,
                        disabled: isFetching,
                    },
                }}
            />
        </GridContainer>
    );
}

MediaGrid.propTypes = {
    initialAttachments: PropTypes.arrayOf(PropTypes.object),
    showActions: PropTypes.bool,
    isRowSelectable: PropTypes.bool,
    onSelectedRowsChange: PropTypes.func,
    showManageMediaModal: PropTypes.bool,
    onAttachmentsLoadedCallback: PropTypes.func,
    entityId: PropTypes.string,
    clearSelectedRows: PropTypes.bool,
    onAlreadyAttachedMediaClick: PropTypes.func,
    maxAttachmentsSize: PropTypes.number,
    onFileSizeLimitExceeded: PropTypes.func,
    maxAttachmentsSelectionCount: PropTypes.number,
    onAttachmentSelectionCountExceeded: PropTypes.func,
};
