import SvgIcon from '@mui/material/SvgIcon';

export function CloseIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <path
                d="M6.73 6L9.5 8.77V9.5H8.77L6 6.73L3.23 9.5H2.5V8.77L5.27 6L2.5 3.23V2.5H3.23L6 5.27L8.77 2.5H9.5V3.23L6.73 6Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
