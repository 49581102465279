import { parsePhoneNumber } from 'awesome-phonenumber';

const numberFormatter = Intl.NumberFormat();

export function formatNumber(number) {
    return numberFormatter.format(number);
}

export function formatPhoneNumber(number, regionCode = 'US') {
    const formattedNumberObj = parsePhoneNumber(number, { regionCode });
    return formattedNumberObj?.number?.international;
}

export function PhoneNumberWithoutCountryCode(phoneNumber) {
    return phoneNumber?.slice(-10);
}
