import SvgIcon from '@mui/material/SvgIcon';

export function ConnectionIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M21.3335 19.4624C20.0801 19.4624 18.9601 20.0442 18.2268 20.9234L13.2535 19.0616C13.2935 18.8289 13.3201 18.6091 13.3201 18.3634C13.3201 17.6523 13.1335 16.9929 12.8401 16.3982L18.0668 13.1529C18.9734 13.8382 20.0935 14.2648 21.3335 14.2648C24.2801 14.2648 26.6668 11.9505 26.6668 9.10604C26.6668 6.2616 24.2801 3.94727 21.3335 3.94727C18.3868 3.94727 16.0001 6.2616 16.0001 9.10604C16.0001 9.81715 16.1468 10.5024 16.4268 11.1101L11.0268 14.4717C10.3201 14.0709 9.52014 13.8123 8.64014 13.8123C6.05347 13.8123 3.94678 15.8551 3.94678 18.3634C3.94678 20.8717 6.05347 22.9145 8.64014 22.9145C10.0268 22.9145 11.2535 22.3198 12.1201 21.4018L17.3601 23.3541C17.3601 25.4874 19.1601 27.22 21.3601 27.22C23.5601 27.22 25.3601 25.4745 25.3601 23.3412C25.3601 21.2079 23.5734 19.4624 21.3601 19.4624H21.3335ZM21.3335 6.53312C22.8001 6.53312 24.0001 7.68382 24.0001 9.10604C24.0001 10.5283 22.8001 11.679 21.3335 11.679C19.8668 11.679 18.6668 10.5283 18.6668 9.10604C18.6668 7.68382 19.8668 6.53312 21.3335 6.53312ZM8.62679 20.3416C7.50679 20.3416 6.60014 19.4624 6.60014 18.3764C6.60014 17.2903 7.50679 16.4111 8.62679 16.4111C9.74679 16.4111 10.6534 17.2903 10.6534 18.3764C10.6534 19.4624 9.74679 20.3416 8.62679 20.3416ZM21.3335 24.6341C20.6001 24.6341 20.0001 24.0523 20.0001 23.3412C20.0001 22.6301 20.6001 22.0483 21.3335 22.0483C22.0668 22.0483 22.6668 22.6301 22.6668 23.3412C22.6668 24.0523 22.0668 24.6341 21.3335 24.6341Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
