import PropType from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CloseIcon } from 'components/Icons';
import { Button, CircularProgress, Dialog, IconButton } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import filesize from 'filesize';
import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { NewMediaUpload } from 'views/UserSettings/features/YourMedia/components/MediaModals/NewMediaUpload';
import { UploadIcon } from 'components/Icons/UploadIcon';
import { MediaFilters, MediaGrid } from 'views/UserSettings/features/YourMedia/components';
import { useCreateMedia } from 'views/UserSettings/features/YourMedia/components/MediaModals/hooks/useCreateMedia';
import { useBulkUpdateMediaConnectionsMutation } from 'services/knowledgeBases';
import { toast } from 'react-toastify';
import { ENTITY_TYPE_ENUMS } from 'utils/entityEnums';
import { MESSAGE_ATTACHMENT_FILE_SIZE_LIMIT, USE_PICKER_MAX_FILES } from 'utils/constants';
import { FileAccessControl } from './FileAccessControl';
import { find } from 'lodash';
import { useDialog } from 'components/Dialog';
import { ConnectionWarningDialog } from './ConnectionWarningDialog';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        padding: theme.spacing(2, 0, 2, 0),
    },
}));

function AttachmentTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ height: index === 0 ? '400px' : '460px', overflow: 'auto' }}
            role="tabpanel"
            hidden={value !== index}
            id={`add-attachment-tabpanel-${index}`}
            aria-labelledby={`add-attachment-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        sx: {
            width: '500px !important',
            textTransform: 'none',
        },
        id: `add-attachment-tab-${index}`,
        'aria-controls': `add-attachment-tabpanel-${index}`,
    };
}

function getResumeAttachment(attachments) {
    return find(attachments, (attachment) => {
        const resumeKeywords = ['Resume', 'resume', 'cv'];
        const requiredFileTypes = [
            'application/pdf',
            'application/vnd.openxmlformats',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/msword',
            'text/plain',
            'text/rtf',
        ];
        const hasResumeKeywords = resumeKeywords.some(
            (keyword) =>
                attachment.filename?.toLowerCase().includes(keyword) ||
                attachment.displayName?.toLowerCase().includes(keyword) ||
                attachment.name?.toLowerCase().includes(keyword) ||
                attachment.tags?.includes(keyword)
        );
        const hasRequiredFileTypes = requiredFileTypes.some((type) =>
            attachment.contentType?.includes(type)
        );
        if (hasResumeKeywords && hasRequiredFileTypes) {
            return attachment;
        }
        return null;
    });
}

export function EntityConnectionModal({
    initialAttachments,
    isOpen,
    onClose = () => {},
    onConfirm,
    onUploadNewMedia,
    attachments,
    setAttachments = () => {},
    onRemoveAttachment,
    onReplaceAttachment,
    clearAttachments,
    selectedTab,
    entityId,
    entityType,
    messageType,
}) {
    const [value, setValue] = useState(selectedTab || 0);
    const [selectedRows, setSelectedRow] = useState([]);
    const [attachmentCount, setAttachmentsCount] = useState(null);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const {
        multiTagsValue,
        displayNames,
        tagOptions,
        handleNameChange,
        handleTagChange,
        handleTagInputValue,
        handleTagInputEnter,
        handleAddMedia,
        handleRemoveUploadMedia,
        isLoading,
        organizationAccess,
        handleChangeAccess,
        handleChangePermissions,
    } = useCreateMedia({
        attachments,
        onClose,
        clearAttachments,
        entityId,
        attachToTalent: true,
        onRemoveAttachment,
        onSuccess: onConfirm,
        entityType,
    });

    const {
        isOpen: isWarningModalOpen,
        handleOpen: handleOpenWarningModal,
        handleClose: handleCloseWarningModal,
    } = useDialog();

    const handleChange = (event, newValue) => {
        setAttachments([]);
        setSelectedRow([]);
        setValue(newValue);
    };

    useEffect(() => {
        if (value === 1 || !isOpen) {
            setAttachments([]);
            setErrorMessage(null);
        }
    }, [isOpen, value]);

    useEffect(() => {
        let timeoutId = null;
        if (errorMessage && value === 1) {
            timeoutId = setTimeout(() => {
                if (errorMessage) setErrorMessage(null);
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [errorMessage, value]);

    const [updateMediaConnections, { isLoading: isUpdatingMediaConnections }] =
        useBulkUpdateMediaConnectionsMutation();

    const handleAttachMediaToTalent = async () => {
        const params = {};
        for (const selectedMedia of selectedRows) {
            params[selectedMedia.mediaId] = {
                entityConnections: [
                    ...(selectedMedia?.entityConnections || []),
                    {
                        type: ENTITY_TYPE_ENUMS[entityType.toUpperCase()].value,
                        entityId,
                    },
                ],
            };
        }

        const res = await updateMediaConnections(params);
        if (res?.data) {
            toast.success('Attached media to talent successfully');
            setAttachments([]);
            setSelectedRow([]);
            setClearSelectedRows(true);
        } else toast.error('Failed to attach media to talent');
        return res;
    };
    const handleConfirm = async () => {
        const resumeFile = getResumeAttachment(attachments);

        if (!isWarningModalOpen && resumeFile && entityType === ENTITY_TYPE_ENUMS.TALENT.label) {
            const profile =
                find(resumeFile.entityConnections, ['type', ENTITY_TYPE_ENUMS.TALENT.label]) ||
                null;
            if (profile && profile?.entityId !== entityId) {
                handleOpenWarningModal();
                return;
            }
        }

        if (value === 0) {
            handleAddMedia();
        } else {
            if (typeof onConfirm === 'function') {
                onConfirm();
                onClose();
                handleCloseWarningModal();
            } else {
                const res = await handleAttachMediaToTalent();
                if (res?.data) {
                    onClose();
                    handleCloseWarningModal();
                }
            }
        }
    };

    const onFileSizeLimitExceeded = (size) => {
        const formattedSize = filesize(MESSAGE_ATTACHMENT_FILE_SIZE_LIMIT[messageType], {
            round: 0,
        });
        setErrorMessage(`Total size of attachments cannot exceed ${formattedSize}`);
    };

    useEffect(() => {
        if (!(value === 0 && entityType === ENTITY_TYPE_ENUMS.CONVERSATION.label)) return;
        const initialAttachmentsLength = initialAttachments?.length || 0;
        if (initialAttachmentsLength === 0) return;
        if (USE_PICKER_MAX_FILES - initialAttachmentsLength === 0) {
            setErrorMessage('You cannot upload new files. Only 5 files can be attached');
        } else {
            setErrorMessage(
                `You can only upload ${
                    USE_PICKER_MAX_FILES - initialAttachmentsLength
                } more file(s). Only 5 files can be attached`
            );
        }
    }, [entityType, initialAttachments?.length, value]);

    const maxAttachmentSelectionCount = useMemo(() => {
        if (entityType === ENTITY_TYPE_ENUMS.CONVERSATION.label) return 5;
        if (entityType === ENTITY_TYPE_ENUMS.PROJECT.label) return 1;
        if (entityType === ENTITY_TYPE_ENUMS.TALENT.label) return Infinity;
    }, [entityType]);

    return (
        <Box>
            <ConnectionWarningDialog
                isOpen={isWarningModalOpen}
                onClose={handleCloseWarningModal}
                onConfirm={handleConfirm}
                isLoading={isUpdatingMediaConnections}
            />
            <StyledDialog open={isOpen} onClose={() => onClose()} fullWidth maxWidth="md">
                <Box>
                    <Box
                        id="upload-new-media-modal"
                        sx={(theme) => ({
                            display: 'flex',
                            mb: 3.5,
                            p: theme.spacing(0, 2.5, 0, 2.5),
                        })}
                    >
                        <Typography sx={{ mr: 'auto', fontWeight: 'bold' }}>
                            Add Attachments
                        </Typography>
                        <IconButton
                            onClick={() => {
                                clearAttachments?.();
                                onClose();
                            }}
                            aria-label="close-upload-new-media-modal"
                            sx={{ p: 0, mr: -1.5 }}
                            disableRipple
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                variant="fullWidth"
                                value={value}
                                onChange={handleChange}
                                aria-label="add-attachments-tab"
                            >
                                <Tab label="Upload New" {...a11yProps(0)} />
                                <Tab
                                    label="Select Existing"
                                    {...a11yProps(1)}
                                    aria-label="select-existing-attachment-tab"
                                />
                            </Tabs>
                        </Box>
                        <AttachmentTabPanel value={value} index={0}>
                            {attachments?.length === 0 ? (
                                <Box
                                    sx={(theme) => ({
                                        border: `1px dashed ${theme.palette.primary.main}`,
                                        height: '350px',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    })}
                                >
                                    <Button
                                        startIcon={<UploadIcon />}
                                        aria-label="upload-button"
                                        disabled={
                                            entityType === ENTITY_TYPE_ENUMS.CONVERSATION.label &&
                                            initialAttachments.length === USE_PICKER_MAX_FILES
                                        }
                                    >
                                        <Typography
                                            sx={{ textTransform: 'none' }}
                                            onClick={() => {
                                                onUploadNewMedia(initialAttachments?.length || 0);
                                            }}
                                        >
                                            Click to Upload
                                        </Typography>
                                    </Button>
                                </Box>
                            ) : (
                                <NewMediaUpload
                                    attachments={attachments}
                                    onRemoveUploadMedia={handleRemoveUploadMedia}
                                    onReplaceAttachment={onReplaceAttachment}
                                    displayNames={displayNames}
                                    onDisplayNameChange={handleNameChange}
                                    onTagChange={handleTagChange}
                                    multiTagsValue={multiTagsValue}
                                    tagOptions={tagOptions}
                                    onTagInputValue={handleTagInputValue}
                                    onTagInputEnter={handleTagInputEnter}
                                    setAttachments={setAttachments}
                                />
                            )}
                        </AttachmentTabPanel>
                        <AttachmentTabPanel value={value} index={1}>
                            {attachmentCount === 0 ? (
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexFlow: 'column',
                                            height: '300px',
                                        }}
                                    >
                                        <Typography>You do not have any existing media</Typography>
                                        <Button
                                            onClick={() => {
                                                onClose();
                                                setValue(0);
                                                onUploadNewMedia();
                                            }}
                                        >
                                            <Typography sx={{ textTransform: 'none' }}>
                                                Upload New Media
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        gap: 2.5,
                                    }}
                                >
                                    <MediaFilters showAddButton={false} />
                                    <MediaGrid
                                        initialAttachments={initialAttachments}
                                        maxAttachmentsSize={
                                            entityType === ENTITY_TYPE_ENUMS.CONVERSATION.label
                                                ? MESSAGE_ATTACHMENT_FILE_SIZE_LIMIT[messageType]
                                                : Infinity
                                        }
                                        maxAttachmentsSelectionCount={maxAttachmentSelectionCount}
                                        onFileSizeLimitExceeded={onFileSizeLimitExceeded}
                                        entityId={entityId}
                                        showActions={false}
                                        isRowSelectable={true}
                                        onSelectedRowsChange={(rows) => {
                                            setErrorMessage(null);
                                            setSelectedRow(rows);
                                            setAttachments(rows);
                                        }}
                                        showManageMediaModal={false}
                                        onAttachmentsLoadedCallback={(data) => {
                                            setAttachmentsCount(data?.results?.length || 0);
                                        }}
                                        clearSelectedRows={clearSelectedRows}
                                        onAttachmentSelectionCountExceeded={() => {
                                            setErrorMessage(
                                                `You cannot select more than ${maxAttachmentSelectionCount} attachment(s)`
                                            );
                                        }}
                                        onAlreadyAttachedMediaClick={() => {
                                            setErrorMessage(
                                                'This media is already attached to your profile'
                                            );
                                        }}
                                    />
                                </Box>
                            )}
                        </AttachmentTabPanel>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: value === 1 ? 'space-between' : 'flex-end',
                            p: 2,
                            pr: 4,
                            borderTop: '1px solid #eee',
                        }}
                    >
                        <Box sx={{ mb: 3, height: value === 0 ? '60px' : '0px' }}>
                            {value === 0 && (
                                <FileAccessControl
                                    access={organizationAccess}
                                    onChangeAccess={handleChangeAccess}
                                    onChangePermissions={handleChangePermissions}
                                />
                            )}
                        </Box>
                        <Box sx={{ height: '20px' }}>
                            {errorMessage && (
                                <Typography sx={{ color: 'error.main', fontSize: '14px' }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            {!errorMessage && value === 1 && (
                                <Typography>{selectedRows?.length || 0} selected</Typography>
                            )}
                        </Box>
                        <Button
                            sx={{
                                width: 'fit-content',
                                ml: 'auto',
                            }}
                            aria-label="add-attachment-button"
                            variant="contained"
                            color="primary"
                            disabled={
                                isLoading ||
                                isUpdatingMediaConnections ||
                                (value === 0 ? !attachments?.length : !selectedRows.length)
                            }
                            onClick={handleConfirm}
                        >
                            <Typography sx={{ textTransform: 'none' }}>
                                {isLoading || isUpdatingMediaConnections ? (
                                    <CircularProgress size={16} />
                                ) : null}{' '}
                                {`Attach Media To ${entityType}`}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </StyledDialog>
        </Box>
    );
}

AttachmentTabPanel.propTypes = {
    children: PropType.node,
    value: PropType.number,
    index: PropType.number,
};

EntityConnectionModal.propTypes = {
    isOpen: PropType.bool,
    attachments: PropType.array,
    setAttachments: PropType.func,
    onClose: PropType.func,
    onConfirm: PropType.func,
    onUploadNewMedia: PropType.func,
    clearAttachments: PropType.func,
    onRemoveAttachment: PropType.func,
    onReplaceAttachment: PropType.func,
    selectedTab: PropType.number,
    entityId: PropType.string,
    entityType: PropType.string,
    messageType: PropType.string,
    initialAttachments: PropType.arrayOf(PropType.object),
};
