import SvgIcon from '@mui/material/SvgIcon';

export function BoltIcon(props) {
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <path
                d="M11.588 24.5C11.4201 24.5 11.2521 24.476 11.0722 24.428C10.2565 24.2001 9.70477 23.4804 9.70477 22.6288V15.3597H5.93828C5.24256 15.3597 4.61882 14.9758 4.28295 14.3761C3.95908 13.7643 3.99506 13.0326 4.37891 12.4569L11.7919 1.33732C12.2597 0.641603 13.0874 0.341718 13.8911 0.581621C14.6828 0.821525 15.2225 1.54124 15.2225 2.36891V8.28252H19.061C19.7327 8.28252 20.3565 8.64238 20.6923 9.23015C21.0282 9.81791 21.0162 10.5376 20.6684 11.1134L13.1954 23.5764C12.8475 24.1521 12.2477 24.488 11.6 24.488L11.588 24.5ZM6.92189 12.9606H10.2326C11.2641 12.9606 12.1038 13.8003 12.1038 14.8319V20.7215L18.1254 10.6936H14.6948C13.6632 10.6936 12.8235 9.85388 12.8235 8.8223V4.12019L6.92189 12.9606Z"
                fill="currentColor"
                fillOpacity="0.48"
            />
        </SvgIcon>
    );
}
