import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useState, useMemo } from 'react';
import difference from 'lodash/difference';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Typography } from 'components/Typography';
import { AddIcon } from 'components/Icons';
import { CONTACT_STATUS_MAP, CONTACT_MEDIUM } from '../utils/constants';
import { ContactListItem } from './ContactListItem';
import { CategorySelect } from './CategorySelect';
import { EmptyListItem } from './EmptyListItem';
import { CategoryHeader } from './CategoryHeader';
import { useContactUpdate } from '../hooks/useContactUpdate';
import { useReorderContact } from '../hooks/useReorderContact';
import { orderContactsByCategory } from '../utils/orderContactsByCategory';

export function arrayMove(array, from, to) {
    const newArray = array.slice();
    newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);
    return newArray;
}

export function ContactList({
    contacts,
    isPrimaryContactChangeLoading,
    onPrimaryContactChange,
    categories,
    onCreateCategory,
    isCreatingCategory,
    savedCategories,
    talentId,
    isFlagingContact,
    handleFlagContact,
    contactType,
    staleContacts,
    hasLookup,
}) {
    const updateContact = useContactUpdate();
    const { reorderContactsWithinCategory } = useReorderContact();

    const [openCategorySelect, setOpenCategorySelect] = useState(false);
    const [categoryLookup, setCategoryLookup] = useState({});
    const [isReAssigning, setIsReassigning] = useState(false);

    const availableCategories = useMemo(
        () => difference(categories, savedCategories),
        [categories, savedCategories]
    );

    const orderedCategories = useMemo(
        () => (savedCategories ? orderContactsByCategory(savedCategories) : []),
        [savedCategories]
    );

    const handleOpenCategorySelect = () => {
        setOpenCategorySelect(true);
    };

    const handleCloseCategorySelect = () => {
        setOpenCategorySelect(false);
    };

    const handleUpdate = useCallback(
        (args) => {
            const { src, dst, contactId, dstIndex, srcIndex, talentId } = args;
            if (src !== dst) {
                setCategoryLookup((state) =>
                    produce(state, (draftState) => {
                        const target =
                            draftState[src]?.filter?.(
                                (contact) => contact.contactId === contactId
                            ) || [];
                        target[0].category = dst;
                        draftState[src] =
                            draftState[src]?.filter?.(
                                (contact) => contact.contactId !== contactId
                            ) || [];
                        draftState[dst] = [...draftState[dst], ...target];
                    })
                );
                updateContact(args, toast, setIsReassigning);
            } else {
                const newCategory = arrayMove(categoryLookup[src] || [], srcIndex, dstIndex);
                setCategoryLookup(
                    produce(categoryLookup, (draftState) => {
                        draftState[src] = newCategory;
                    })
                );
                reorderContactsWithinCategory(
                    {
                        talentId,
                        dst,
                        src,
                        orderedContactIds: newCategory.map((item) => item.contactId),
                    },
                    toast
                );
            }
        },
        [updateContact, reorderContactsWithinCategory, categoryLookup]
    );

    useEffect(() => {
        if (!savedCategories || !contacts) return;
        savedCategories.forEach((t_category) => {
            setCategoryLookup((prev) =>
                produce(prev, (draft) => {
                    draft[t_category] = [];
                })
            );
        });
        contacts.forEach((t_contact) => {
            setCategoryLookup((prev) =>
                produce(prev, (draft) => {
                    draft[t_contact.category]?.push(t_contact);
                })
            );
        });
    }, [savedCategories, contacts]);

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                {orderedCategories?.map((category) => (
                    <CategoryHeader key={category} category={category}>
                        {categoryLookup[category]?.map((contact, i) => (
                            <ContactListItem
                                index={i}
                                onUpdate={handleUpdate}
                                talentId={talentId}
                                isPrimaryContactChangeLoading={
                                    isPrimaryContactChangeLoading || isReAssigning
                                }
                                key={contact.contactId}
                                contact={contact}
                                onPrimaryContactChange={onPrimaryContactChange}
                                handleFlagContact={handleFlagContact}
                                isFlagingContact={isFlagingContact}
                                staleContacts={staleContacts}
                            />
                        ))}
                        {!categoryLookup[category]?.length && <EmptyListItem category={category} />}
                    </CategoryHeader>
                ))}
            </DndProvider>
            {openCategorySelect && (
                <CategorySelect
                    onCancel={handleCloseCategorySelect}
                    isCreatingCategory={isCreatingCategory}
                    categories={availableCategories}
                    onCreateCategory={onCreateCategory}
                />
            )}
            {hasLookup && (
                <Button
                    aria-label="new-category"
                    role="button"
                    onClick={handleOpenCategorySelect}
                    endIcon={<AddIcon />}
                    disableRipple
                    disabled={availableCategories.length === 0}
                    data-trackid={`lookup-modal-new-${
                        contactType === CONTACT_MEDIUM.EMAIL ? 'email' : 'sms'
                    }-category`}
                >
                    <Typography variant="titleSmall" sx={{ textTransform: 'capitalize' }}>
                        New Category
                    </Typography>
                </Button>
            )}
        </>
    );
}

const ContactPropType = PropTypes.shape({
    contactId: PropTypes.string.isRequired,
    type: PropTypes.string,
    contactMedium: PropTypes.oneOf(Object.values(CONTACT_MEDIUM)).isRequired,
    phone: PropTypes.string,
    email: PropTypes.string,
    primary: PropTypes.bool.isRequired,
    status: PropTypes.oneOf(Object.values(CONTACT_STATUS_MAP)),
}).isRequired;

ContactList.propTypes = {
    isPrimaryContactChangeLoading: PropTypes.bool,
    contacts: PropTypes.arrayOf(ContactPropType),
    onPrimaryContactChange: PropTypes.func,
    categories: PropTypes.arrayOf(PropTypes.string),
    onCreateCategory: PropTypes.func,
    isCreatingCategory: PropTypes.bool,
    isFlagingContact: PropTypes.bool,
    talentId: PropTypes.string,
    handleFlagContact: PropTypes.func,
    savedCategories: PropTypes.arrayOf(PropTypes.string),
    contactType: PropTypes.string,
    staleContacts: PropTypes.arrayOf(PropTypes.shape({ contactId: PropTypes.string })),
    hasLookup: PropTypes.bool,
};
