import SvgIcon from '@mui/material/SvgIcon';

export function HighlightIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M11.5438 1.49775C11.7844 1.65401 11.9531 1.89947 12.0127 2.18013C12.0723 2.4608 12.018 2.75368 11.8618 2.99434L8.32602 8.43881C8.16973 8.67947 8.11543 8.97235 8.17506 9.25301C8.2347 9.53368 8.40338 9.77914 8.644 9.9354L24.9786 20.543C25.2192 20.6993 25.5121 20.7535 25.7927 20.6939C26.0734 20.6342 26.3188 20.4655 26.4751 20.2248L30.0109 14.7803C30.1672 14.5397 30.4126 14.3709 30.6933 14.3113C30.974 14.2516 31.2668 14.3058 31.5074 14.4621C31.7481 14.6184 31.9167 14.8638 31.9764 15.1445C32.036 15.4252 31.9817 15.718 31.8254 15.9587L28.2897 21.4032C27.924 21.9667 27.3924 22.4028 26.7684 22.6515C26.1444 22.9002 25.4586 22.9492 24.8056 22.7916L23.5269 24.7607C22.9017 25.7233 21.9198 26.3982 20.7972 26.637C19.6745 26.8757 18.5031 26.6586 17.5406 26.0336L17.5388 26.0324L15.4935 29.1937C15.1975 29.6513 14.7911 30.0269 14.3117 30.286C13.8323 30.5451 13.2955 30.6794 12.7506 30.6764L1.0758 30.6187C0.881568 30.6176 0.691212 30.5643 0.524715 30.4643C0.358219 30.3643 0.221723 30.2213 0.129556 30.0503C0.0373898 29.8793 -0.00704869 29.6867 0.000908349 29.4926C0.00886582 29.2985 0.0689235 29.1102 0.174777 28.9473L6.65696 18.9658L6.65151 18.9622C5.68902 18.3372 5.01428 17.3553 4.77575 16.2327C4.53721 15.11 4.75442 13.9385 5.37957 12.9759L6.65833 11.0068C6.24935 10.4743 6.0156 9.82803 5.98929 9.15705C5.96299 8.48608 6.14543 7.82344 6.51147 7.26044L10.0472 1.81598C10.2035 1.57531 10.449 1.40659 10.7296 1.34691C11.0103 1.28723 11.3031 1.34148 11.5438 1.49775ZM8.47151 20.1441L3.06772 28.4651L12.7631 28.512C12.9445 28.513 13.1232 28.4684 13.2828 28.3822C13.4425 28.2961 13.5779 28.1712 13.6766 28.019L15.7243 24.8541L8.47151 20.1441ZM7.19412 14.1542C6.88154 14.6355 6.77294 15.2213 6.89221 15.7826C7.01148 16.344 7.34884 16.8349 7.83009 17.1474L18.7174 24.2176C19.1986 24.5301 19.7843 24.6386 20.3457 24.5193C20.907 24.3999 21.3979 24.0625 21.7105 23.5811L22.8891 21.7663L8.3727 12.3394L7.19412 14.1542Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
