import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';
import { organizationsApi } from './organizations';

const GET_RMM_MESSAGE_ENDPOINT = 'getRmmMessage';

export const jobDescriptionApi = createApi({
    reducerPath: 'jobDescriptionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/job-description/v1',
        prepareHeaders: async (headers, { endpoint }) => {
            await prepareAuthHeaders(headers, {
                forceTokenRefresh: endpoint === GET_RMM_MESSAGE_ENDPOINT,
            });
            return headers;
        },
    }),
    tagTypes: ['JobDescriptions', 'RmmMetrics'],
    endpoints: (builder) => ({
        generateJobDescription: builder.mutation({
            query: ({ body, params }) => ({
                url: '/jd/stream/begin',
                method: 'POST',
                body,
                params,
            }),
        }),
        completeJdgeneration: builder.mutation({
            query: ({ body, params }) => ({
                url: '/jd/stream/complete',
                method: 'POST',
                body,
                params,
            }),
            invalidatesTags: (result, error, { params }) => [
                { type: 'JobDescriptions', id: params.id },
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so the following will refresh token count
                    dispatch(
                        organizationsApi.util.invalidateTags([{ type: 'Organizations', id: 'ME' }])
                    );
                } catch (error) {
                    // do nothing
                }
            },
        }),
        cancelJobDescriptionGeneration: builder.mutation({
            query: ({ body, params }) => ({
                url: '/jd/stream/cancel',
                method: 'POST',
                body,
                params,
            }),
        }),
        createJobDescription: builder.mutation({
            query: (body) => ({
                url: '/jd',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'JobDescriptions', id: 'LIST' }],
        }),
        getJobDescription: builder.query({
            query: (id) => `/jd/${id}`,
        }),
        getJobDescriptions: builder.query({
            query: () => '/jd',
            providesTags: (result) => [
                ...(result?.map(({ id }) => ({ type: 'JobDescriptions', id })) || []),
                { type: 'JobDescriptions', id: 'LIST' },
            ],
        }),
        editJobDescription: builder.mutation({
            query: ({ id, jobDescription }) => ({
                url: `/jd/${id}`,
                method: 'PUT',
                body: jobDescription,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'JobDescriptions', id }],
        }),
        deleteJobDescription: builder.mutation({
            query: ({ id }) => ({
                url: `/jd/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'JobDescriptions', id: 'LIST' }],
        }),
        [GET_RMM_MESSAGE_ENDPOINT]: builder.query({
            query: ({ params }) => ({
                url: '/rmm/generate',
                params,
            }),
            invalidatesTags: (result, error) => [{ type: 'RmmMetrics' }],
        }),
        getRmmMetrics: builder.query({
            query: (params) => ({
                url: 'rmm/requests',
                params,
            }),
            providesTags: (result, error) => [{ type: 'RmmMetrics' }],
        }),
    }),
});

export const {
    useGetJobDescriptionsQuery,
    useLazyGetRmmMessageQuery,
    useLazyGetJobDescriptionQuery,
    useLazyGetJobDescriptionsQuery,
    useEditJobDescriptionMutation,
    useDeleteJobDescriptionMutation,
    useCreateJobDescriptionMutation,
    useGenerateJobDescriptionMutation,
    useCompleteJdgenerationMutation,
    useCancelJobDescriptionGenerationMutation,
    useGetRmmMetricsQuery,
} = jobDescriptionApi;
