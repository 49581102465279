import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/system/Box';
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { CloseIcon } from 'components/Icons';
import { getInitials } from 'utils/formatters';
import { Typography } from 'components/Typography';
import { removeContact } from 'views/Communications/features/Contacts';

const ConversationAvatar = styled(Avatar)(({ theme }) => ({
    alignSelf: 'flex-end',
    width: theme.spacing(3),
    height: theme.spacing(3),
    background:
        'linear-gradient(180deg, rgba(65, 232, 211, 0) 0%, rgba(65, 232, 211, 0.7) 100%), #3A8DD3',
    '&:hover': {
        cursor: 'pointer',
    },
}));

const RemoveButton = styled(IconButton)(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: 'auto',
    fontSize: '14px',
}));

export function TalentPill(props) {
    const { talentId, talentName, imageUrl, hideName, onOpenContacts, isSupportProfile } = props;

    const dispatch = useDispatch();

    const handleRemoveTalent = () => {
        dispatch(removeContact(talentId));
    };

    if (hideName) {
        return (
            <ConversationAvatar src={imageUrl} aria-label="nova-talent-avatar-mini">
                <Typography variant="labelMedium">{getInitials(talentName, '')}</Typography>
            </ConversationAvatar>
        );
    }

    return (
        <Box
            aria-label="talent-pill-container"
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '135px',
                height: '24px',
                borderRadius: '30px',
                background: 'rgba(49, 141, 222, 0.08)',
                mr: 0.5,
                mb: 0.25,
            }}
        >
            <Button
                sx={{ padding: 0, textTransform: 'none' }}
                onClick={isSupportProfile ? null : onOpenContacts}
                title={talentName}
            >
                <ConversationAvatar
                    sx={{ mr: 1 }}
                    src={imageUrl}
                    aria-label="nova-talent-avatar-mini"
                >
                    <Typography variant="labelMedium">{getInitials(talentName, '')}</Typography>
                </ConversationAvatar>

                <Typography variant="bodySmall" noWrap>
                    {talentName}
                </Typography>
            </Button>
            <RemoveButton onClick={handleRemoveTalent}>
                <CloseIcon sx={{ fontSize: '14px' }} />
            </RemoveButton>
        </Box>
    );
}

TalentPill.propTypes = {
    talentId: PropTypes.string,
    imageUrl: PropTypes.string,
    talentName: PropTypes.string,
    hideName: PropTypes.bool,
    isSupportProfile: PropTypes.bool,
    onOpenContacts: PropTypes.func,
};
