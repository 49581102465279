import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useForcedFlowStore } from '../store';
import { FORCED_FLOW_STAGES } from '../constants';
import { finishForcedFlow } from 'features/Authentication';

const finalStage = FORCED_FLOW_STAGES.DONE;

export function useForcedFlow() {
    const dispatch = useDispatch();
    const isForcedFlow = useSelector((state) => state.user?.settings?.forcedFlow);

    const stage = useForcedFlowStore((state) => state.stage);
    const next = useForcedFlowStore((state) => state.next);

    const onNext = useCallback(() => {
        if (stage === finalStage - 1) {
            dispatch(finishForcedFlow());
        }
        next();
    }, [next, stage, dispatch]);

    return useMemo(
        () => ({
            forcedFlowStage: stage,
            handleForcedFlowNext: onNext,
            isForcedFlow: isForcedFlow && stage < finalStage,
        }),
        [stage, onNext, isForcedFlow]
    );
}
