import SvgIcon from '@mui/material/SvgIcon';

export function LetterIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M22.4032 10.9358L18.5115 13.7803C17.2703 14.6854 15.6024 14.6983 14.3483 13.8062L10.4048 10.9876C9.82299 10.5738 9.00845 10.7031 8.60764 11.285C8.1939 11.8668 8.32321 12.6684 8.90503 13.0821L12.8355 15.9007C13.9087 16.6635 15.1628 17.0514 16.404 17.0514C17.6452 17.0514 18.9381 16.6506 20.0242 15.8619L23.9159 13.0175C24.4978 12.5908 24.6141 11.7892 24.2004 11.2074C23.7737 10.6255 22.9721 10.5092 22.3903 10.9229L22.4032 10.9358Z"
                fill="currentColor"
            />
            <path
                d="M24.5494 6.16504H8.28431C5.64673 6.16504 3.48755 8.31133 3.48755 10.9618V20.7752C3.48755 23.4257 5.6338 25.5719 8.28431 25.5719H24.5494C27.1869 25.5719 29.3461 23.4257 29.3461 20.7752V10.9618C29.3461 8.32426 27.1999 6.16504 24.5494 6.16504ZM26.7603 20.7752C26.7603 21.9905 25.7647 22.9861 24.5494 22.9861H8.28431C7.06895 22.9861 6.07341 21.9905 6.07341 20.7752V10.9618C6.07341 9.74648 7.06895 8.7509 8.28431 8.7509H24.5494C25.7647 8.7509 26.7603 9.74648 26.7603 10.9618V20.7752Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
