import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Typography } from 'components/Typography';
import { WarningIcon } from 'components/Icons';
import { MESSAGE_TYPES } from 'utils/constants';
import { useLazyGetContactMetadataQuery } from 'services/contacts';
import { PopperWithArrow } from 'components/Poppers';
import { FailedTraversalContactsMenu } from './FailedTraversalContactsMenu';
import { useDispatch } from 'react-redux';
import { handleOpenModal } from 'features/TalentCard/ContactDetails/contactDetailSlice';

export function MessageFailureSubInfo(props) {
    const { talent, contactTraversal, messageType } = props;
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();
    const [getContactMetadata, { data: contactMetadata }] = useLazyGetContactMetadataQuery();
    const contactCounts = contactMetadata?.contactCounts;

    const talentId = useMemo(() => {
        return talent?.id || talent?.talentId;
    }, [talent?.id, talent?.talentId]);

    useEffect(() => {
        if (talentId) {
            getContactMetadata([talentId]);
        }
    }, [talentId, getContactMetadata]);

    const handleOpen = (event) => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const talentWithCounts = {
        ...talent,
        contactCounts,
    };

    const initialModalTab = messageType === MESSAGE_TYPES.EMAIL ? 0 : 1;

    const handleManageContacts = (e) => {
        e.stopPropagation();
        setIsOpen(false);
        dispatch(
            handleOpenModal({
                selectedTalent: talentWithCounts,
                initialTab: initialModalTab,
            })
        );
    };

    if (contactTraversal) {
        return (
            <>
                <Button
                    onClick={handleOpen}
                    sx={{ textTransform: 'none' }}
                    data-testid="contact-traversal-button"
                >
                    <PopperWithArrow
                        sx={{
                            width: 180,
                        }}
                        open={isOpen}
                        onClose={handleClose}
                        content={
                            <FailedTraversalContactsMenu
                                contactTraversal={contactTraversal}
                                messageType={messageType}
                                handleManageContacts={handleManageContacts}
                            />
                        }
                        arrowTransform={{ x: 78, y: 0 }}
                    >
                        <Box sx={{ width: 24 }}>
                            <WarningIcon sx={{ fontSize: 13 }} color="error" />
                        </Box>
                    </PopperWithArrow>
                    <Typography
                        variant="titleSmall"
                        color="error"
                        sx={{ fontSize: 13, lineHeight: '13px' }}
                    >
                        Message failed to send
                    </Typography>
                </Button>
            </>
        );
    }
    return (
        <>
            <WarningIcon sx={{ fontSize: 13 }} color="error" />
            <Typography
                variant="titleSmall"
                color="error"
                sx={{ fontSize: 13, lineHeight: '13px' }}
            >
                Message failed to send
            </Typography>
        </>
    );
}

MessageFailureSubInfo.propTypes = {
    talent: PropTypes.object,
    contactTraversal: PropTypes.array,
    messageType: PropTypes.string,
};
