import { toast } from 'react-toastify';
import { MESSAGE_TYPES } from 'utils/constants';

function handle403Error() {
    toast.error('Your organization does not have enough credits to send this message.');
}

function handle401Error() {
    toast.error(
        'Your session has timed out. Please refresh and log in again to send your message.'
    );
}

function handle404Error(messageType, errorData) {
    if (errorData?.message?.includes('not found')) {
        toast.error(
            `Talent contact ${
                messageType === MESSAGE_TYPES.EMAIL ? 'email' : 'phone number'
            } not found.`
        );
    }
}

function handle400Error(errorData, openDialogfunc) {
    const standard_messages = [
        'Unsupported file type',
        'The text:',
        'Email subject characters must be greater than 5',
        'Invalid Phone number(s) supplied',
        'Total number of files exceeds the maximum',
    ];

    if (errorData?.message?.includes('size of attachments exceeds the maximum')) {
        toast.error('Your total attachments size exceeds the maximum of 25mb');
    } else if (errorData?.message?.includes('Send date is not compliant with talent')) {
        openDialogfunc(true);
    } else if (errorData?.message?.includes('Attempt to send to unsubscribed recipient')) {
        toast.error('Cannot send message to an unsubscribed recipient');
    } else if (
        standard_messages.some((standardMessage) => errorData?.message?.includes(standardMessage))
    ) {
        toast.error(errorData?.message);
    } else {
        handleGenericError();
    }
}

function handle409Error(errorData) {
    if (errorData?.message?.includes('Send date is not compliant with talent')) {
        toast.error(errorData?.message);
    } else if (errorData?.message?.includes('Organizations cannot send more than 2 SMS messages')) {
        toast.error(errorData?.message);
    } else if (errorData?.message?.includes('SMS message cannot be sent on')) {
        toast.error(errorData?.message);
    } else if (
        errorData?.message?.includes(
            'Cannot send SMS until at least one email has been sent to the talent'
        )
    ) {
        toast.error(errorData?.message);
    } else if (errorData?.message?.includes('SMS Messages cannot be sent between the hours')) {
        toast.error(errorData?.message);
    } else if (errorData?.message?.includes('cannot be sent SMS until')) {
        toast.error(errorData?.message);
    } else {
        handleGenericError();
    }
}

function handleGenericError() {
    toast.error('Something went wrong, please try again');
}

export {
    handle403Error,
    handle401Error,
    handle404Error,
    handle400Error,
    handleGenericError,
    handle409Error,
};
