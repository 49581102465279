import SvgIcon from '@mui/material/SvgIcon';

export function MediaPdfIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.4082 31.984H22.5922V32C26.0162 32 28.8002 29.408 28.8002 26.224V25.4494H25.6002V26.192C25.6002 27.616 24.2562 28.768 22.5922 28.768H9.4082C7.7442 28.768 6.40019 27.616 6.40019 26.192V5.76C6.40019 4.336 7.7442 3.184 9.4082 3.184L9.39219 3.168H15.816V8.53288C15.816 10.9928 17.6157 13.2376 20.1271 13.2376H27.3209V13.2336H28.8002V11.808C28.8002 10.256 28.1282 8.752 26.9282 7.664L20.2882 1.648C19.1202 0.576004 17.5842 0 15.9522 0H9.4082C5.9842 0 3.2002 2.592 3.2002 5.776V26.208C3.2002 29.392 5.9842 31.984 9.4082 31.984ZM25.3954 10.8376C25.2553 10.5297 25.0449 10.2441 24.7682 10L18.216 4.06357V8.53288C18.216 9.94415 19.202 10.8376 20.1271 10.8376H25.3954ZM32.0001 15.5016V17.036H28.8753V18.66H31.3505V20.1832H28.8753V23.2184H27.1169V15.5016H32.0001ZM22.0845 15.5016H19.0381V23.2184H22.0845C24.3133 23.2184 25.6013 21.5608 25.6013 19.3768C25.6013 17.1816 24.3133 15.5016 22.0845 15.5016ZM22.0845 21.684H20.7965V17.036H22.0845C23.2381 17.036 23.8317 18.1672 23.8317 19.3768C23.8317 20.5752 23.2381 21.684 22.0845 21.684ZM11.9328 15.5016H15.1136C16.9056 15.5016 17.712 16.7784 17.712 18.0552C17.712 19.3432 16.9056 20.5976 15.1136 20.5976H13.6912V23.2184H11.9328V15.5016ZM13.6912 19.0632H15.0352C15.696 19.0632 15.9424 18.548 15.9424 18.0552C15.9424 17.5624 15.696 17.036 15.0464 17.036H13.6912V19.0632Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
