import { useSlateStatic, useSelected, useFocused } from 'slate-react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { removeLink } from './Toolbar';

const ElementLink = styled(Box)(() => ({
    display: 'inline',
    position: 'relative',
}));

const Popup = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
    position: 'absolute',
    left: 0,
    display: visible ? 'flex' : 'none',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: `${theme.spacing(0.25)} ${theme.spacing(2)}`,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.32), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);',
    transition: 'opacity 0.75s',
}));

export function LinkElement({ attributes, element, children }) {
    const editor = useSlateStatic();
    const selected = useSelected();
    const focused = useFocused();

    return (
        <ElementLink>
            <a {...attributes} href={element.href} aria-label={element.href}>
                {children}
            </a>
            {!element.linkType && (
                <Popup visible={selected && focused} contentEditable={false}>
                    <a href={element.href} rel="noreferrer" target="_blank">
                        {element.href}
                    </a>
                    <IconButton
                        onClick={() => removeLink(editor)}
                        size="small"
                        color="error"
                        disableRipple
                        disableFocusRipple
                        sx={{ ml: 1 }}
                        aria-label="format-remove-link"
                        title="Remove link"
                    >
                        <LinkOffIcon />
                    </IconButton>
                </Popup>
            )}
        </ElementLink>
    );
}

LinkElement.propTypes = {
    element: PropTypes.object,
    attributes: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
