import SvgIcon from '@mui/material/SvgIcon';

export function StatOpenedIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.12 24H19.88C22.1467 24 24 22.2953 24 20.2063V9.76181C24 8.23715 23.2267 6.82053 21.8667 5.88413L14.2667 0.625841C12.92 -0.214524 11.0933 -0.214521 9.68001 0.66186L2.13334 5.88413C0.773342 6.82053 0 8.23715 0 9.76181V20.2063C0 22.2953 1.84 24 4.12 24ZM11.24 2.6067C11.478 2.46382 11.7554 2.39179 12.0201 2.39062C12.271 2.39169 12.5083 2.45171 12.6933 2.57069L20.24 7.79296C20.9333 8.27317 21.3333 8.99348 21.3333 9.76181V20.2063C21.3333 20.9747 20.68 21.599 19.88 21.599H4.12C3.32 21.599 2.66667 20.9747 2.66667 20.2063V9.76181C2.66667 8.99348 3.06668 8.27317 3.76001 7.79296L11.24 2.6067ZM13.9441 13.056L17.5561 10.416L17.5441 10.404C18.0841 10.02 18.8281 10.128 19.2241 10.668C19.6081 11.208 19.5001 11.952 18.9601 12.348L15.3481 14.988C14.3401 15.72 13.1401 16.092 11.9881 16.092C10.8361 16.092 9.67214 15.732 8.67614 15.024L5.02815 12.408C4.48815 12.024 4.36814 11.28 4.75214 10.74C5.12414 10.2 5.88014 10.08 6.42014 10.464L10.0801 13.08C11.2441 13.908 12.7921 13.896 13.9441 13.056Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
