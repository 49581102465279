import SvgIcon from '@mui/material/SvgIcon';

export function MediaDocIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <g id="Media Thumbnail">
                <path
                    id="Union"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.40795 31.984H22.592V32C26.016 32 28.7999 29.408 28.7999 26.224V25.4494H25.5999V26.192C25.5999 27.616 24.256 28.768 22.592 28.768H9.40795C7.74395 28.768 6.39995 27.616 6.39995 26.192V5.76C6.39995 4.336 7.74395 3.184 9.40795 3.184L9.39195 3.168H15.816V8.53288C15.816 10.9928 17.6157 13.2376 20.127 13.2376H27.3209V13.2336H28.7999V11.808C28.7999 10.256 28.128 8.752 26.928 7.664L20.2879 1.648C19.1199 0.576004 17.584 0 15.952 0H9.40795C5.98395 0 3.19995 2.592 3.19995 5.776V26.208C3.19995 29.392 5.98395 31.984 9.40795 31.984ZM25.3951 10.8376C25.2551 10.5297 25.0446 10.2441 24.768 10L18.216 4.06374V8.53288C18.216 9.94415 19.202 10.8376 20.127 10.8376H25.3951ZM19.2314 23.6071C17.025 23.6071 15.289 21.7927 15.289 19.6087C15.289 17.4247 17.025 15.6215 19.2314 15.6215C21.4042 15.6215 23.1626 17.4247 23.1626 19.6087C23.1626 21.7927 21.4042 23.6071 19.2314 23.6071ZM19.2202 21.9495C20.5082 21.9495 21.393 20.8967 21.393 19.6087C21.393 18.3207 20.5082 17.2791 19.2202 17.2791C17.921 17.2791 17.0586 18.3207 17.0586 19.6087C17.0586 20.8967 17.921 21.9495 19.2202 21.9495ZM28.1192 23.6071C25.9464 23.6071 24.1992 21.7927 24.1992 19.5975C24.1992 17.4247 25.9464 15.6215 28.1192 15.6215C29.4744 15.6215 30.6616 16.3047 31.3672 17.3351L30.0008 18.2871C29.5976 17.6599 29.004 17.2791 28.1976 17.2791C26.8984 17.2791 25.9688 18.3207 25.9688 19.5975C25.9688 20.8743 26.8648 21.9495 28.1864 21.9495C29.004 21.9495 29.5976 21.5687 30.0008 20.9415L31.3672 21.8935C30.6616 22.9239 29.4744 23.6071 28.1192 23.6071ZM10.7048 15.7559H7.65835V23.4727H10.7048C12.9335 23.4727 14.2215 21.8151 14.2215 19.6311C14.2215 17.4359 12.9335 15.7559 10.7048 15.7559ZM10.7048 21.9383H9.41675V17.2903H10.7048C11.8583 17.2903 12.452 18.4215 12.452 19.6311C12.452 20.8295 11.8583 21.9383 10.7048 21.9383Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
