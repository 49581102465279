import SvgIcon from '@mui/material/SvgIcon';

export function NewSendMessageIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 29" {...props}>
            <path
                d="M29.2913 9.57136L8.6687 0.931193C6.3931 -0.0288257 3.8686 0.522294 2.21524 2.31788C0.632985 4.04236 0.295127 6.49576 1.36181 8.55802L3.70854 13.0736C4.2241 14.0692 4.2241 15.2603 3.70854 16.2559L1.36181 20.8071C0.295127 22.8872 0.615206 25.3228 2.21524 27.0473C3.33526 28.2562 4.84637 28.8962 6.41085 28.8962C7.17531 28.8962 7.9398 28.7539 8.6687 28.4339L29.2913 19.7938C31.4246 18.9049 32.7402 16.9493 32.7402 14.6737C32.7402 12.4158 31.4246 10.4425 29.2913 9.55359V9.57136ZM27.9223 16.5226L7.29975 25.1628C6.25084 25.6072 5.32635 25.1805 4.82857 24.6472C4.54412 24.345 3.95747 23.5272 4.52637 22.4427L6.87309 17.8915C7.10421 17.4293 7.28197 16.9493 7.40642 16.4515H17.5044C18.4822 16.4515 19.2822 15.6515 19.2822 14.6737C19.2822 13.6959 18.4822 12.8959 17.5044 12.8959H7.42421C7.29977 12.3981 7.12199 11.9003 6.87309 11.4381L4.52637 6.92241C3.97525 5.83794 4.54412 5.02017 4.82857 4.71794C5.18413 4.32682 5.73528 4.0068 6.41085 4.0068C6.6953 4.0068 6.97975 4.06014 7.29975 4.18459L27.9223 12.8248C28.7223 13.1625 29.1846 13.8381 29.1846 14.6559C29.1846 15.4737 28.7046 16.167 27.9223 16.487V16.5226Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
