import { useContext, useEffect, useState } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useBlocker(blocker, when = true, setUserConfirmed) {
    const { navigator } = useContext(NavigationContext);

    const [block, setBlock] = useState(when);

    useEffect(() => {
        if (!block) return;

        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return () => {
            unblock();
            setUserConfirmed(false);
        };
    }, [navigator, blocker, block, setUserConfirmed]);

    return [block, setBlock];
}
