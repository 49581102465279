import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiMenu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import MuiMenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { Typography } from 'components/Typography';
import { LikeIcon, CloseIcon, PlusAltIcon, ChevronRightIcon } from 'components/Icons';

const Menu = styled(MuiMenu)(({ theme }) => ({
    '& .MuiMenu-paper': {
        background: theme.palette.text.primary,
        width: theme.spacing(30),
        color: theme.palette.common.white,
        marginTop: theme.spacing(0.25),
        '& ul': {
            paddingTop: 0,
            paddingBottom: theme.spacing(0.5),
        },
    },
}));

const InfoBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    gap: theme.spacing(1),
    background: 'linear-gradient(93deg, rgba(80, 76, 224, 0.48) 0%, rgba(49, 141, 222, 0.48) 100%)',
}));

const MenuItemOption = styled(MuiMenuItem)(({ theme }) => ({
    color: theme.palette.common.white,
    padding: theme.spacing(0, 1),
    height: theme.spacing(3.5),
    '&:not(:hover) > .chevron': {
        display: 'none',
    },
}));

export function MatchInputMenu(props) {
    const { onAdd, onAddAndEmphasize, ...restProps } = props;

    const [infoPanelOpen, setInfoPanelOpen] = useState(true);

    return (
        <Menu
            id="add-to-profile-description-menu"
            aria-labelledby="add-to-profile-description-menu"
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            {...restProps}
        >
            <Box sx={{ pt: 0.5, px: 1 }}>
                <Typography variant="bodySmallBold" component="p" sx={{ mb: 1 }}>
                    Add selection to Profile Description
                </Typography>
                {infoPanelOpen && (
                    <InfoBox>
                        <Typography variant="bodyXSmall" sx={{ fontWeight: 400 }} component="p">
                            Adding text that better describes your ideal profile will help us find
                            you better matches.
                        </Typography>
                        <IconButton
                            aria-label="dismiss-info"
                            data-trackid="add-to-profile-description-close-tooltip"
                            onClick={() => setInfoPanelOpen(false)}
                            sx={{ p: '1px', color: '#fff', opacity: 0.48 }}
                            disableRipple
                        >
                            <CloseIcon sx={{ fontSize: 20.55 }} />
                        </IconButton>
                    </InfoBox>
                )}
            </Box>
            <MenuItemOption
                aria-label="add-to-profile-description"
                onClick={onAdd}
                disableRipple
                data-trackid="add-to-profile-description-add"
            >
                <PlusAltIcon sx={{ fontSize: 10, p: 1.5, pl: 0.5 }} />
                <Typography variant="bodyXSmall" sx={{ fontWeight: 400 }}>
                    Add
                </Typography>
                <ChevronRightIcon className="chevron" sx={{ fontSize: 23, ml: 'auto' }} />
            </MenuItemOption>
            <MenuItemOption
                data-trackid="add-to-profile-description-add-&-emphasize"
                aria-label="add-to-profile-description-and-emphasize"
                onClick={onAddAndEmphasize}
                disableRipple
            >
                <LikeIcon sx={{ fontSize: 12, p: 1.25, pl: 0.5 }} />
                <Typography variant="bodyXSmall" sx={{ fontWeight: 400 }}>
                    Add and Emphasize
                </Typography>
                <ChevronRightIcon className="chevron" sx={{ fontSize: 23, ml: 'auto' }} />
            </MenuItemOption>
        </Menu>
    );
}

MatchInputMenu.propTypes = {
    onAdd: PropTypes.func,
    onAddAndEmphasize: PropTypes.func,
};
