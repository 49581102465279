import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';

import { useDispatch, useSelector } from 'react-redux';

import { CheckBoxSelectedIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { setTags } from '../../../slices/mediaSlice';
import { SelectButtons } from './SelectButtons';
import { MatchText } from './MatchText';

export function TagContent({ tags, isLoading }) {
    const [matchText, setMatchText] = useState('');

    const selectedTags = useSelector((state) => state.media.tags);

    const dispatch = useDispatch();

    const filteredTags = useMemo(() => {
        if (matchText) {
            const staging = [];
            tags.forEach((tag) => {
                if (tag.toLocaleLowerCase().includes(matchText.toLocaleLowerCase())) {
                    staging.push(tag);
                }
            });
            return staging;
        }
        return tags || [];
    }, [tags, matchText]);

    const handleCheckoggle = (event) => {
        const staging = [...selectedTags];
        if (event.target.checked) {
            staging.push(event.target.value);
        } else {
            const index = staging.findIndex((e) => e === event.target.value);
            if (index >= 0) {
                staging.splice(index, 1);
            }
        }
        dispatch(setTags(staging));
    };

    const handleDeselectAll = () => {
        dispatch(setTags([]));
    };

    const handleSelectAll = () => {
        dispatch(setTags(filteredTags));
    };

    const handleMatchText = (e) => {
        setMatchText(e.target.value);
    };

    if (isLoading)
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: (t) => t.spacing(40),
                    minHeight: (t) => t.spacing(40),
                }}
            >
                <CircularProgress size={120} />
            </Box>
        );

    return (
        <Box
            aria-label="Filter by Tags"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 1,
                minWidth: (t) => t.spacing(40),
            }}
        >
            <MatchText value={matchText} onChange={handleMatchText} />
            <SelectButtons onSelectAll={handleSelectAll} onDeselectAll={handleDeselectAll} />
            {filteredTags.map((tag) => (
                <FormControlLabel
                    key={tag}
                    sx={{ mr: 0 }}
                    control={
                        <Checkbox
                            value={tag}
                            checked={selectedTags.includes(tag)}
                            onChange={handleCheckoggle}
                            checkedIcon={<CheckBoxSelectedIcon color="success" />}
                            inputProps={{ 'aria-label': `Option: ${tag}` }}
                            disableRipple
                        />
                    }
                    label={<Typography variant="titleSmall">{tag}</Typography>}
                    disableTypography
                />
            ))}
        </Box>
    );
}

TagContent.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
};
