import { createSlice } from '@reduxjs/toolkit';
import { MESSAGE_TYPES } from 'utils/constants';
import { MESSAGE_LENGTH_OPTIONS, TARGET_ENUMS } from './utils';
import { trimOffSignature } from './utils/helpers';

export const initialMessageState = {
    isOpen: false,
    isMinimized: true,
    isFormatting: false,
    isMessageGenerationCollapsed: false,
    isPreparingStream: false,
    isGeneratingMessage: false,
    hasGeneratedMessage: false,
    isTcpaDialogOpen: false,
    isScheduleModalOpen: false,
    clearInput: false,
    currentSignature: null,
    selectedProfiles: [],
    selectedContacts: {},
    selectedAttachments: [],
    messageType: MESSAGE_TYPES.EMAIL,
    initialEmailContents: '',
    scheduledDate: '',
    scheduledTime: '',
    subject: {
        initialBody: '',
        innerHtml: '',
        innerText: '',
        validationError: '',
    },
    body: {
        initialBody: '',
        innerHtml: '',
        innerText: '',
        validationError: '',
    },
    messageGenerationOptions: {
        source: '',
        sourceId: '',
        length: MESSAGE_LENGTH_OPTIONS.medium.value,
    },
};

const messageSlice = createSlice({
    name: 'message',
    initialState: initialMessageState,
    reducers: {
        resetMessageState: (_, { payload: override }) => {
            return { ...initialMessageState, ...override };
        },
        clearMessage: (state) => {
            state.subject = initialMessageState.subject;
            state.body = initialMessageState.body;
        },
        toggleFormatting: (state) => {
            state.isFormatting = !state.isFormatting;
        },
        toggleMessageGeneration: (state) => {
            state.isMessageGenerationCollapsed = !state.isMessageGenerationCollapsed;
        },
        setIsMessageGenerationCollapsed: (state, { payload }) => {
            state.isMessageGenerationCollapsed = payload;
        },
        handleOpenMessages: (state) => {
            state.isMinimized = false;
            state.isOpen = true;
        },
        handleCloseMessages: (state) => {
            state.isOpen = false;
        },
        setAttachments: (state, { payload }) => {
            state.selectedAttachments = payload;
        },
        handleMinimized: (state, { payload }) => {
            state.isMinimized = !state.isMinimized;
            if (payload !== undefined) state.isMinimized = payload;
        },
        setProfiles: (state, { payload }) => {
            state.selectedProfiles = payload;
        },
        setContacts: (state, { payload }) => {
            state.selectedContacts = payload;
        },
        setMessageType: (state, { payload }) => {
            state.messageType = payload;
            if (state.messageType === 'sms') {
                const newContent = trimOffSignature(
                    state[TARGET_ENUMS.BODY].innerHtml,
                    state.currentSignature?.signature
                );
                state.initialEmailContents = state[TARGET_ENUMS.BODY].innerHtml;
                state[TARGET_ENUMS.BODY].initialBody = newContent;
                state[TARGET_ENUMS.BODY].innerHtml = newContent;
            } else if (state.messageType === 'email' && Boolean(state.initialEmailContents)) {
                state[TARGET_ENUMS.BODY].initialBody = state.initialEmailContents;
                state[TARGET_ENUMS.BODY].innerHtml = state.initialEmailContents;
            }
        },
        setMessageBody: (state, { payload }) => {
            state[payload.target].innerHtml = payload.innerHtml;
            state[payload.target].innerText = payload.innerText;
            if (!payload.innerHtml?.trim()) {
                state.currentSignature = null;
            }
        },
        setInitialBody: (state, { payload }) => {
            state[payload.target].initialBody = payload.initialBody;
            if (!payload.initialBody?.trim()) {
                state.currentSignature = null;
            }
        },
        setSignature: (state, { payload }) => {
            // payload can be object, with these fields
            // signature: The original signature object, allows trimming of the signature from
            // the body accurately, see setMessageType
            // signatureBody: The processed signature string with the message body as HTML
            // If payload is a string , then it is the processed signature string with the message body as HTML
            state.currentSignature = payload.signature || {};

            state[TARGET_ENUMS.BODY].innerHtml = payload.signatureBody || payload;
            state[TARGET_ENUMS.BODY].initialBody = payload.signatureBody || payload;
        },
        resetMessageBody: (state, { payload }) => {
            state[payload.target].innerHtml = initialMessageState[payload.target].innerHtml;
            state[payload.target].innerText = initialMessageState[payload.target].innerText;
            state.currentSignature = null;
            state.initialEmailContents = '';
        },
        setScheduledDate: (state, { payload }) => {
            state.scheduledDate = payload.date;
            state.scheduledTime = payload.time;
        },
        setTcpaDialog: (state, { payload }) => {
            state.isTcpaDialogOpen = payload;
        },
        setScheduleModalOpen: (state, { payload }) => {
            state.isScheduleModalOpen = payload;
        },
        setValidationError: (state, { payload }) => {
            state[payload.target].validationError = payload.error;
        },
        setClearInput: (state, { payload }) => {
            state.clearInput = payload;
            state.currentSignature = null;
            state.initialEmailContents = '';
        },
        setMessageGenerationOption: (state, { payload }) => {
            state.messageGenerationOptions[payload.target] = payload.value;
        },
        applyMessageGenerationOptions: (state, { payload }) => {
            state.messageGenerationOptions.source = payload.source;
            state.messageGenerationOptions.sourceId = payload.sourceId;
            state.messageGenerationOptions.length = payload.length;
        },
        resetSourceId: (state) => {
            state.messageGenerationOptions.sourceId =
                initialMessageState.messageGenerationOptions.sourceId;
        },
        setIsPreparingStream: (state, { payload }) => {
            state.isPreparingStream = payload;
        },
        setIsGeneratingMessage: (state, { payload }) => {
            state.isGeneratingMessage = payload;
        },
        setHasGeneratedMessage: (state, { payload }) => {
            state.hasGeneratedMessage = payload;
        },
    },
});

export const {
    resetMessageState,
    clearMessage,
    toggleFormatting,
    toggleMessageGeneration,
    setIsMessageGenerationCollapsed,
    handleOpenMessages,
    handleCloseMessages,
    handleMinimized,
    setAttachments,
    updateContacts,
    setMessageType,
    setProfiles,
    setContacts,
    setMessageBody,
    setInitialBody,
    setSignature,
    resetMessageBody,
    setScheduledDate,
    setTcpaDialog,
    setScheduleModalOpen,
    setValidationError,
    setClearInput,
    setMessageGenerationOption,
    applyMessageGenerationOptions,
    resetSourceId,
    setIsPreparingStream,
    setIsGeneratingMessage,
    setHasGeneratedMessage,
} = messageSlice.actions;

export const messageReducer = messageSlice.reducer;
