import SvgIcon from '@mui/material/SvgIcon';

export function ArrowRefreshIcon(props) {
    return (
        <SvgIcon viewBox="0 0 17 16" {...props}>
            <path
                d="M2.34856 8.00014C2.34856 4.7302 5.0093 2.06946 8.27923 2.06946C9.57825 2.06946 10.8146 2.49053 11.8269 3.25202H10.7608C10.2681 3.25202 9.86493 3.65516 9.86493 4.14789C9.86493 4.64062 10.2681 5.04377 10.7608 5.04377H13.6366C13.9859 5.04377 14.3085 4.90043 14.5414 4.65855C14.7743 4.41666 14.8997 4.09415 14.8908 3.76268V0.895873C14.8908 0.403143 14.4876 0 13.9949 0C13.5022 0 13.099 0.403143 13.099 0.895873V1.96196C11.7463 0.868999 10.0531 0.268751 8.27028 0.268751C4.01488 0.268751 0.547852 3.73577 0.547852 7.99117C0.547852 8.4839 0.950994 8.88704 1.44372 8.88704C1.93645 8.88704 2.3396 8.4839 2.3396 7.99117L2.34856 8.00014Z"
                fill="currentColor"
            />
            <path
                d="M14.9983 7.10399C14.5056 7.10399 14.1024 7.50713 14.1024 7.99986C14.1024 11.2698 11.4417 13.9305 8.17177 13.9305C6.87276 13.9305 5.63645 13.5095 4.62411 12.748H5.6902C6.18293 12.748 6.58608 12.3448 6.58608 11.8521C6.58608 11.3594 6.18293 10.9562 5.6902 10.9562H2.81445C2.46506 10.9562 2.14255 11.0996 1.90962 11.3415C1.67669 11.5833 1.55127 11.9059 1.56023 12.2373V15.1041C1.56023 15.5969 1.96337 16 2.4561 16C2.94883 16 3.35198 15.5969 3.35198 15.1041V14.038C4.70474 15.131 6.39794 15.7312 8.18073 15.7312C12.4361 15.7312 15.9032 12.2642 15.9032 8.00883C15.9032 7.5161 15.5 7.11296 15.0073 7.11296L14.9983 7.10399Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
