import SvgIcon from '@mui/material/SvgIcon';

export function OpenedEmailIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <path
                d="M6.56545 0.798003C6.50691 0.766494 6.44148 0.75 6.375 0.75C6.30852 0.75 6.24309 0.766494 6.18455 0.798003L1.38402 3.38309C1.19245 3.48622 1.03236 3.63927 0.920736 3.82602C0.809112 4.01277 0.750114 4.22624 0.75 4.44381V9.18729C0.75 9.61353 0.919324 10.0223 1.22072 10.3237C1.52212 10.6251 1.9309 10.7944 2.35714 10.7944H10.3929C10.8191 10.7944 11.2279 10.6251 11.5293 10.3237C11.8307 10.0223 12 9.61353 12 9.18729V4.44381C11.9999 4.22624 11.9409 4.01277 11.8293 3.82602C11.7176 3.63927 11.5576 3.48622 11.366 3.38309L6.56545 0.798003ZM1.76491 4.09024L6.375 1.6072L10.9851 4.09024C11.0341 4.11673 11.0771 4.15312 11.1112 4.19711L6.375 6.72274L1.63875 4.19711C1.67294 4.15312 1.7159 4.11673 1.76491 4.09024ZM1.55357 5.06256L6.18616 7.53193C6.24429 7.56289 6.30914 7.57908 6.375 7.57908C6.44086 7.57908 6.50571 7.56289 6.56384 7.53193L11.1964 5.06256V9.18729C11.1964 9.40041 11.1118 9.6048 10.9611 9.7555C10.8104 9.9062 10.606 9.99086 10.3929 9.99086H2.35714C2.14402 9.99086 1.93963 9.9062 1.78893 9.7555C1.63823 9.6048 1.55357 9.40041 1.55357 9.18729V5.06256Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
