import { styled } from '@mui/material/styles';
import { ChevronDownIcon } from './ChevronDownIcon';

export const RotatingChevronIcon = styled(ChevronDownIcon, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme, isOpen }) => ({
    height: '16px',
    width: '16px',
    color: theme.palette.text.primary,
    transform: `rotate(${isOpen ? 180 : 0}deg)`,
    transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
    }),
}));
