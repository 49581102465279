export const validateSignUpEmail = (emailToValidate, isSelfSignup = true) => {
    if (typeof emailToValidate === 'string' && emailToValidate.includes('@')) {
        const emailProvider = emailToValidate.split('@')[1]?.split('.')[0];
        const invalidEmailProviders = [
            'gmail',
            'yahoo',
            'hotmail',
            'outlook',
            'protonmail',
            'aol',
            'icloud',
            'zoho',
        ];
        return !invalidEmailProviders.includes(emailProvider) || !isSelfSignup;
    }
    return false;
};
