import styled from '@emotion/styled';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography } from 'components/Typography';
import React from 'react';
import PropTypes from 'prop-types';
const GradientTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    padding: 12,
    backgroundSize: '100%',
    fontSize: '15px',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: theme.palette.common.matchInputGradient,
}));
const StyledTooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    fontStyle: 'normal',
    width: '300px',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    left: '100px',
    letterSpacing: 0.1,
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#eff6fd',
    },
    [`& .${tooltipClasses.arrow}::before`]: {
        backgroundColor: '#eff6fd',
    },
}));

function CoolDownTooltip({ message, placement, children, showTooltip }) {
    if (showTooltip)
        return (
            <StyledTooltip
                title={
                    <>
                        <GradientTypography>{message}</GradientTypography>
                    </>
                }
                placement={placement || 'bottom'}
                arrow
            >
                {children}
            </StyledTooltip>
        );
    else {
        return <>{children}</>;
    }
}

CoolDownTooltip.propTypes = {
    showTooltip: PropTypes.bool,
    message: PropTypes.string,
    placement: PropTypes.string,
    children: PropTypes.node.isRequired,
};
export default CoolDownTooltip;
