import SvgIcon from '@mui/material/SvgIcon';

export function LinkOutIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M18.8901 11.121C18.2797 11.121 17.7802 11.6204 17.7802 12.2309V13.9512C17.7802 16.0599 16.0599 17.7802 13.9511 17.7802H6.04882C3.94005 17.7802 2.21976 16.0599 2.21976 13.9512V6.04884C2.21976 3.94008 3.94005 2.21975 6.04882 2.21975H7.75804C8.36847 2.21975 8.86792 1.72031 8.86792 1.10988C8.86792 0.499445 8.36847 0 7.75804 0H6.04882C2.70808 0 0 2.71921 0 6.04884V13.9512C0 17.2919 2.71918 20 6.04882 20H13.9511C17.2919 20 20 17.2919 20 13.9512V12.2309C20 11.6204 19.5006 11.121 18.8901 11.121Z"
                fill="currentColor"
            />
            <path
                d="M9.99994 11.0982C10.2885 11.0982 10.566 10.9873 10.788 10.7764L17.7469 3.80633V6.44784C17.7469 7.05827 18.2463 7.55772 18.8568 7.55772C19.4672 7.55772 19.9667 7.05827 19.9667 6.44784V1.43124C19.9667 1.05388 19.8113 0.698697 19.556 0.432326C19.2896 0.165956 18.8901 0.0327759 18.5793 0.0216771H13.5516C12.9411 0.0216771 12.4417 0.521122 12.4417 1.13155C12.4417 1.74199 12.9411 2.24143 13.5516 2.24143H16.182L9.22304 9.21149C8.79019 9.64434 8.79019 10.3435 9.22304 10.7764C9.44502 10.9873 9.72248 11.0982 10.011 11.0982H9.99994Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
