import SvgIcon from '@mui/material/SvgIcon';

export function ArrowUpIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M28.6731 11.7968L17.8839 0.975657C17.6442 0.735897 17.3404 0.57606 17.0367 0.480156C16.8289 0.384252 16.6052 0.320312 16.3654 0.320312C16.1257 0.320312 15.9019 0.384252 15.6941 0.480156C15.3744 0.57606 15.0867 0.7359 14.831 0.991644L4.04175 11.8128C3.41837 12.4362 3.41837 13.4432 4.04175 14.0666C4.36143 14.3703 4.76102 14.5301 5.1766 14.5301C5.59219 14.5301 5.9918 14.3703 6.31148 14.0666L14.767 5.57905V30.7219C14.767 31.601 15.4703 32.3203 16.3654 32.3203C17.2605 32.3203 17.9638 31.601 17.9638 30.7219V5.57905L26.4353 14.0666C27.0587 14.6899 28.0657 14.6899 28.6891 14.0666C29.3125 13.4432 29.3125 12.4362 28.6891 11.8128L28.6731 11.7968Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
