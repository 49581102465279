import MuiButton from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';

export const NavbarButton = styled(MuiButton, { shouldForwardProp: (prop) => prop !== 'isActive' })(
    ({ theme, isActive }) => ({
        borderRadius: 0,
        textTransform: 'none',
        padding: theme.spacing(0.75, 2.5),
        color: isActive ? theme.palette.common.white : alpha(theme.palette.common.white, 0.72),
        height: theme.mixins.toolbar.minHeight,
        position: 'relative',
        transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.short,
        }),
        '&.Mui-disabled': {
            color: isActive ? theme.palette.common.white : alpha(theme.palette.common.white, 0.38),
        },
        '&:hover': {
            backgroundColor: 'inherit',
            color: isActive ? theme.palette.common.white : alpha(theme.palette.common.white, 0.85),
            '&::after': {
                width: '100%',
                backgroundColor: isActive
                    ? theme.palette.success.main
                    : alpha(theme.palette.success.main, 0.9),
            },
        },
        '& .MuiButton-startIcon': {
            marginRight: theme.spacing(0.5),
            '&>*:nth-of-type(1)': {
                fontSize: 16,
            },
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            maxWidth: '100%',
            width: isActive ? '100%' : 0,
            height: theme.spacing(0.5),
            backgroundColor: theme.palette.success.main,
            transition: theme.transitions.create(['width'], {
                duration: theme.transitions.duration.short,
            }),
        },
    })
);
