import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import MuiCheckbox from '@mui/material/Checkbox';
import { styled, alpha } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { tooltipClasses } from '@mui/material/Tooltip';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { Slider } from 'components/Slider';
import { Tooltip } from 'components/Tooltip';
import { Typography, TYPOGRAPHY_VARIANTS } from 'components/Typography';

export const AutocompleteRoot = styled(Box)(() => ({
    boxSizing: 'border-box',
}));

export const AutocompleteChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-root': {
        height: theme.spacing(2.5),
        margin: theme.spacing(0, 0.25),
        backgroundColor: theme.palette.text.primary,
        color: alpha(theme.palette.common.white, 0.72),
        fontWeight: 'normal',
        font: 'inherit',
        border: 'none',
        fontSize: theme.spacing(1.5),
    },
    '& .MuiChip-deleteIcon': {
        marginRight: theme.spacing(1),
        fontSize: theme.spacing(1.5),
        color: theme.palette.error.main,
        '&:hover': { color: theme.palette.error.main },
    },
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiAutocomplete-inputRoot': {
        position: 'relative',
        backgroundColor: 'inherit',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: theme.spacing(0.5),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.default,
            zIndex: -1,
        },
        '& input': {
            height: 'auto',
            paddingRight: 0,
        },
    },
    '& .MuiAutocomplete-endAdornment': {
        right: theme.spacing(1),
    },
    '& .MuiAutocomplete-listbox': {
        padding: theme.spacing(0.25, 0),
    },
}));

export const FilterAccordion = styled(Accordion)(({ theme }) => ({
    '&.MuiAccordion-root': {
        borderRadius: theme.spacing(0.25),
        color: theme.palette.common.white,
        backgroundColor: alpha(theme.palette.text.primary, 0.48),
    },
}));

export const FilterSummary = styled(AccordionSummary)(({ theme }) => ({
    '&.MuiAccordionSummary-root': {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'unset',
        height: theme.spacing(7),
        minHeight: theme.spacing(7),
        marginTop: theme.spacing(2),
        borderRadius: theme.spacing(0.25),
        color: theme.palette.common.white,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: theme.palette.common.white,
    },
    '& .MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(0, 0)}`,
    },
}));

export const SummaryChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-root': {
        pr: 0,
        pl: 0,
        width: theme.spacing(3),
        height: theme.spacing(3),
        borderRadius: theme.spacing(3),
        color: theme.palette.common.white,
        backgroundColor: alpha(theme.palette.common.white, 0.12),
    },
    '& .MuiChip-label': {
        ...TYPOGRAPHY_VARIANTS.titleSmall,
        lineHeight: 'inherit',
        padding: 0,
    },
}));

export const FilterResultChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-root': {
        padding: '2px 8px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.white,
        letterSpacing: ' 0.1px',
        height: theme.spacing(3),
    },
    '& .MuiChip-label': {
        ...TYPOGRAPHY_VARIANTS.titleSmall,
        lineHeight: 'inherit',
        fontWeight: '500px',
        padding: 0,
        marginTop: -2,
    },
}));

export const SummaryActions = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
}));

export const SummaryAction = styled(Button)(({ theme }) => ({
    '&.MuiButton-root': {
        ...TYPOGRAPHY_VARIANTS.bodySmall,
        color: theme.palette.common.white,
        textTransform: 'none',
        marginRight: theme.spacing(1),
    },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), ${theme.palette.text.primary};`,
    borderRadius: `${theme.spacing(0.5)} 0 0 ${theme.spacing(0.5)}`,
    color: theme.palette.common.white,
    height: theme.spacing(5.5),
    '& .MuiSelect-select': {
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        padding: 0,
    },
    '& .MuiSelect-icon': {
        color: theme.palette.common.white,
    },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: `0 0 ${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
    '& .MuiAutocomplete-noOptions': {
        display: 'none',
    },
    '& .MuiAutocomplete-listbox': {
        backgroundColor: theme.palette.text.primary,
        '& .MuiTypography-root': {
            color: theme.palette.common.white,
        },
    },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
    borderTop: `1px solid ${alpha(theme.palette.common.white, 0.24)}`, //0.24 to mimic pass through blend in Figma
    borderBottom: 'none',
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-root': {
        color: alpha(theme.palette.common.white, 0.72),
        fontWeight: 'normal',
        font: 'inherit',
        border: 'none',
        fontSize: theme.spacing(1.5),
        height: theme.spacing(2.5),
        backgroundColor: alpha(theme.palette.common.white, 0.12),
    },
    '& .MuiChip-icon': {
        order: 1,
        paddingRight: theme.spacing(0.5),
        marginLeft: 0,
        cursor: 'pointer',
        color: theme.palette.success.main,
        display: 'flex',
        borderRight: `1px solid ${alpha(theme.palette.common.white, 0.12)}`,
    },
    '& .MuiChip-deleteIcon': {
        order: 2,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1.25),
        fontSize: '14px',
        color: alpha(theme.palette.common.white, 0.48),
        '&:hover': { color: theme.palette.error.main },
    },
}));

export const FilterMenuItem = styled(MenuItem, {
    shouldForwardProp: (prop) => prop !== 'active',
})(({ active }) => ({
    opacity: active ? 1 : 0.48,
    '&:hover': { opacity: 1 },
}));

export const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 236,
    },
}));

export const FilterDetails = styled(AccordionDetails)(({ theme }) => ({
    '&.MuiAccordionDetails-root': {
        color: theme.palette.common.white,
        borderRadius: theme.spacing(0.25),
        backgroundColor: 'unset',
    },
}));

export const FilterCaption = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
}));

export const StyledSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-rail': {
        backgroundColor: alpha(theme.palette.common.charcoal, 0.76),
    },
    '& .MuiSlider-track': {
        background: alpha(theme.palette.common.white, 0.16),
    },
    '& .MuiSlider-thumb': {
        background: theme.palette.primary.main,
    },
    '& .MuiSlider-mark': {
        opacity: 0.5,
    },
    '& .MuiSlider-markLabel': {
        color: theme.palette.common.lightGray,
        fontWeight: 800,
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0.5px',
    },
    '& .MuiSlider-valueLabel': {
        top: '0',
    },
}));

export const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
    padding: 0,
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    color: alpha(theme.palette.common.white, 0.78),
    '& .MuiSvgIcon-root': {
        fontSize: 26,
    },
}));

export const FilterChipContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(0.75),
    paddingTop: theme.spacing(0.5),
}));

export const StyledIncludeExcludeLabel = styled(Typography)(({ theme }) => ({
    ...TYPOGRAPHY_VARIANTS.bodySmallBold,
    color: theme.palette.common.white,
    padding: theme.spacing(0.25, 0.5),
    margin: theme.spacing(0, 0.5),
    borderRadius: theme.spacing(0.25),
    backgroundColor: alpha(theme.palette.common.white, 0.24),
}));
