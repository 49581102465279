const today = Date.now();

export const DEFAULT_DYNAMIC_FIELD_FALLBACKS = {
    '{{CandidateFullName}}': 'there',
    '{{firstName}}': 'there',
    '{{lastName}}': 'Superstar',
    '{{locationCity}}': 'in your town',
    '{{CandidateCurrentEmployer}}': 'your current company',
    '{{CandidateJobTitle}}': 'your current role',
    '{{currentTenure}}': 'some time',
    '{{dayOfTheWeek}}': today.toString(),
    '{{TodaysDate}}': today.toString(),
};

export const DEFAULT_DYNAMIC_FIELD_FALLBACKS_OPENAI = {
    ...DEFAULT_DYNAMIC_FIELD_FALLBACKS,
    '{{fullName}}': 'there',
    '{{currentEmployer}}': 'your current company',
    '{{currentJobTitle}}': 'your current role',
    '{{todaysDate}}': today.toString(),
};

export const DEFAULT_DYNAMIC_FIELD_FALLBACKS_TINA = {
    ...DEFAULT_DYNAMIC_FIELD_FALLBACKS,
    '{{CandidateFullName}}': 'there',
    '{{CandidateCurrentEmployer}}': 'your current company',
    '{{CandidateJobTitle}}': 'your current role',
    '{{TodaysDate}}': today.toString(),
};