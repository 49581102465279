import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { MESSAGE_TYPES } from 'utils/constants';
import { useLazyGetConversationMessagesQuery } from 'services/conversations';

export function useGetOutboundContact({ conversationId, messageType, latestReplyContact, skip }) {
    const [outboundContact, setOutboundContact] = useState('');

    const [getMessages] = useLazyGetConversationMessagesQuery();

    const getLatestOutbound = useCallback(
        async (messageData) => {
            const requestParams = {
                conversationId,
                messageType,
                page: 1,
                pageSize: 1,
                sendMethod: 'direct',
                messageData,
            };
            try {
                const { data, error } = await getMessages(requestParams);
                if (error) {
                    throw new Error();
                }
                setOutboundContact(data?.results[0]?.data?.to);
            } catch {
                toast.error('Error encountered when retrieving contact data');
            }
        },
        [conversationId, messageType, getMessages]
    );

    useEffect(() => {
        setOutboundContact('');
    }, [conversationId]);

    useEffect(() => {
        if (skip) {
            return;
        }
        const fetchContacts = async () => {
            await getLatestOutbound(messageType === MESSAGE_TYPES.EMAIL && latestReplyContact);
        };
        if (conversationId) {
            fetchContacts();
        }
    }, [skip, conversationId, messageType, getLatestOutbound, latestReplyContact]);

    return outboundContact;
}
