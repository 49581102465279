import parsePhoneNumberFromString from 'libphonenumber-js';

export const validatePhoneNumberWithLib = (value) => {
    try {
        const number = value[0] === '+' ? value.slice(1) : value;
        const phoneNumber = parsePhoneNumberFromString(`+${number}`);
        if (!phoneNumber || !phoneNumber.isValid()) {
            return 'Please enter a valid phone number.';
        }
        return true;
    } catch (error) {
        return 'Something went wrong, please try again';
    }
};
