import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/user-mgmt/v1/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['SignedInUser', 'emailConnectedStatus', 'SourcePools'],
    endpoints: (builder) => ({
        getSignedInUser: builder.query({
            query: () => ({
                url: 'users/me',
            }),
            providesTags: (result, error, arg) => ['SignedInUser'],
        }),
        getUsers: builder.query({
            query: () => ({ url: 'users' }),
            providesTags: (data) =>
                data
                    ? [
                          ...data.map(({ id }) => ({ type: 'Users', id })),
                          { type: 'Users', id: 'LISTS' },
                      ]
                    : [{ type: 'Users', id: 'LISTS' }],
        }),
        getPaginatedUsers: builder.query({
            query: ({ params, body }) => ({
                url: 'users/paged',
                method: 'POST',
                params,
                body,
            }),
            providesTags: (data) =>
                data?.results
                    ? [
                          ...data.results.map(({ id }) => ({ type: 'Users', id })),
                          { type: 'Users', id: 'LISTS' },
                      ]
                    : [{ type: 'Users', id: 'LISTS' }],
        }),
        getUser: builder.query({
            query: (userId) => `users/${userId}`,
            providesTags: (result, error, talentId) => [{ type: 'UsersById', id: talentId }],
        }),
        getUserEmailStatus: builder.query({
            query: (userId) => `user-settings/${userId}/settings/email-authentication`,
            providesTags: ['emailConnectedStatus'],
        }),
        getBlacklistedEmailStatus: builder.query({
            query: () => ({
                url: 'users/is-blacklisted-domain',
            }),
            provideTags: (result, error, args) => {
                return [{ type: 'getEmailBlacklistedStatus', id: 1 }];
            },
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: 'users',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Users', id: id }],
        }),
        createUserOnSignUp: builder.mutation({
            query: (body) => ({
                url: 'users/signup',
                method: 'POST',
                body,
            }),
        }),
        updateUserOnSignUp: builder.mutation({
            query: (body) => ({
                url: 'users/signup',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => ['SignedInUser'],
        }),
        updateUser: builder.mutation({
            query: ({ id, requestBody }) => {
                return {
                    url: `users/${id}`,
                    method: 'PUT',
                    body: requestBody,
                };
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Users', id: id }],
        }),
        approveUser: builder.mutation({
            query: ({ id, requestBody }) => ({
                url: `users/approve/${id}`,
                method: 'PUT',
                body: requestBody,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Users', id: id }],
        }),
        getSourcePools: builder.query({
            query: () => ({
                url: 'organizations/pools',
            }),
            providesTags: (result, error, arg) => ['SourcePools'],
        }),
        getImpersonationToken: builder.mutation({
            query: (id) => ({
                url: `users/token-exchange/${id}`,
            }),
        }),
    }),
});

export const {
    useLazyGetBlacklistedEmailStatusQuery,
    useGetSignedInUserQuery,
    useLazyGetSignedInUserQuery,
    useGetPaginatedUsersQuery,
    useGetUserEmailStatusQuery,
    useLazyGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useCreateUserOnSignUpMutation,
    useUpdateUserOnSignUpMutation,
    useApproveUserMutation,
    useGetSourcePoolsQuery,
    useGetImpersonationTokenMutation,
} = usersApi;
