import SvgIcon from '@mui/material/SvgIcon';

export function PinterestIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 25" {...props}>
            <g clipPath="url(#clip0_17343_105847)">
                <path
                    d="M0 12.5158C0 17.4296 2.95481 21.651 7.18303 23.507C7.14928 22.669 7.17703 21.6631 7.39191 20.7514C7.62263 19.7772 8.93597 14.2125 8.93597 14.2125C8.93597 14.2125 8.55262 13.4463 8.55262 12.3139C8.55262 10.5355 9.58331 9.20737 10.8669 9.20737C11.9585 9.20737 12.4858 10.0272 12.4858 11.009C12.4858 12.1062 11.786 13.7475 11.4261 15.2677C11.1254 16.5406 12.0643 17.5789 13.3201 17.5789C15.5937 17.5789 17.125 14.6587 17.125 11.1987C17.125 8.56866 15.3536 6.60009 12.1317 6.60009C8.49159 6.60009 6.22388 9.31472 6.22388 12.347C6.22388 13.3925 6.53212 14.1297 7.01494 14.7007C7.23694 14.9629 7.26778 15.0683 7.18744 15.3695C7.12988 15.5902 6.99769 16.1218 6.94294 16.3325C6.86306 16.6364 6.61678 16.7451 6.34209 16.6328C4.66547 15.9484 3.88463 14.1123 3.88463 12.0483C3.88463 8.63944 6.75956 4.55194 12.4611 4.55194C17.0426 4.55194 20.0581 7.86731 20.0581 11.4262C20.0581 16.1336 17.441 19.6505 13.5832 19.6505C12.2876 19.6505 11.069 18.9502 10.6515 18.1547C10.6515 18.1547 9.95484 20.9196 9.80728 21.4535C9.55284 22.3787 9.05484 23.3034 8.5995 24.0242C9.70365 24.3507 10.8491 24.5164 12.0005 24.5163C18.6271 24.5163 24.0001 19.1436 24.0001 12.5158C24.0001 5.88834 18.6271 0.515625 12.0005 0.515625C5.37319 0.515625 0 5.88834 0 12.5158Z"
                    fill="#CB1F27"
                />
            </g>
            <defs>
                <clipPath id="clip0_17343_105847">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.515625)" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
