import PropTypes from 'prop-types';
import map from 'lodash/map';
import { useSlate } from 'slate-react';

import MenuItem from '@mui/material/MenuItem';
import { Editor, Transforms } from 'slate';
import useSlateEditor from 'components/Inputs/RichTextInput/hooks/useSlateEditor';

const handleInsert = (editor, text) => {
    if (!editor.selection) {
        Transforms.select(editor, {
            anchor: { path: [0, 0], offset: 0 },
            focus: { path: [0, 0], offset: 0 },
        });
    }
    const anchor = Editor.before(editor, editor.selection, { unit: 'character' });
    if (anchor) {
        const focus = editor.selection.focus;
        const lastChar = Editor.string(editor, { anchor, focus });
        editor.insertText(lastChar !== ' ' ? ` ${text}` : text);
    } else {
        editor.insertText(text);
    }
};

export function DynamicFieldRow(props) {
    const { dynamicFields, onClose, addDefaultDynamicFields } = props;
    const { editorContext } = useSlateEditor();
    const editorSlate = useSlate();

    const handleClick = (field) => {
        addDefaultDynamicFields?.({ name: field?.dynamicField, value: '' });
        const editor = editorContext || editorSlate;
        handleInsert(editor, field?.dynamicField);
        onClose();
    };

    return (
        <>
            {map(dynamicFields, (field, index) => {
                const name = field?.displayName;
                return (
                    <MenuItem
                        aria-label={name}
                        disableRipple
                        key={`${name}-${index}`}
                        onClick={() => handleClick(field)}
                    >
                        {name}
                    </MenuItem>
                );
            })}
        </>
    );
}

DynamicFieldRow.propTypes = {
    dynamicFields: PropTypes.object,
    onClose: PropTypes.func,
    addDefaultDynamicFields: PropTypes.func,
};
