import SvgIcon from '@mui/material/SvgIcon';

export function PlusAltIcon(props) {
    return (
        <SvgIcon viewBox="0 0 8 8" {...props}>
            <g id="plus">
                <path
                    id="Vector"
                    d="M7.55556 3.55556H4.44444V0.444444C4.44444 0.2 4.24444 0 4 0C3.75556 0 3.55556 0.2 3.55556 0.444444V3.55556H0.444444C0.2 3.55556 0 3.75556 0 4C0 4.24444 0.2 4.44444 0.444444 4.44444H3.55556V7.55556C3.55556 7.8 3.75556 8 4 8C4.24444 8 4.44444 7.8 4.44444 7.55556V4.44444H7.55556C7.8 4.44444 8 4.24444 8 4C8 3.75556 7.8 3.55556 7.55556 3.55556Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
