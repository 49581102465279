export const MIN_SIGNATURE_CHARACTERS = 1;
export const MAX_SIGNATURE_CHARACTERS = 10000;

const regex = new RegExp('\\{\\{[^}]*?\\}\\}', 'g');

export function signatureValidation(signature) {
    const escapedHtmlSignature = signature?.replace(/&nbsp;/g, ' ').replace(/<[^>]*>?/gm, '');

    const matches = [...escapedHtmlSignature.matchAll(regex)];

    const isWithoutFields = matches.length === 0;
    const isNotEmpty = escapedHtmlSignature?.trim() !== '';
    const isNotShort = escapedHtmlSignature?.length >= MIN_SIGNATURE_CHARACTERS;
    const isNotLarge = escapedHtmlSignature?.length <= MAX_SIGNATURE_CHARACTERS;

    const isValid = isWithoutFields && isNotEmpty && isNotShort && isNotLarge;

    let error = '';
    if (!isNotShort || !isNotLarge || !isNotEmpty)
        error += `Please enter a valid signature between ${MIN_SIGNATURE_CHARACTERS} and ${MAX_SIGNATURE_CHARACTERS} characters. `;
    if (!isWithoutFields) error += 'Signature templates cannot contain dynamic fields. ';

    return {
        escapedHtmlSignature,
        isValid,
        error,
    };
}
