/* 
  Products will show if there is no searchterm defined yet.
  If there is a searchterm they will be filtered like everything else.
 */
export const productLinkActions = [
    {
        name: 'Shortlists',
        url: '/shortlist',
    },
    {
        name: 'Search',
        url: '/search',
    },
    {
        name: 'Messaging Home',
        url: '/communications',
    },
    {
        name: 'Sequences',
        url: '/sequences',
    },
    {
        name: 'Digital Assistant',
        url: '/talk-tracks',
    },
    {
        name: 'Job Description Builder',
        url: '/job-description-builder',
    },
    {
        name: 'Projects',
        url: '/projects',
    },
    {
        name: 'User Settings',
        url: '/user-settings#user-settings-nav-header',
    },
];
