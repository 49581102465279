import { styled } from '@mui/material/styles';
import { Typography } from 'components/Typography';

const ContentContainer = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    maxHeight: '100vh',
    width: '100vw',
    maxWidth: '800px',
    margin: 'auto',
    padding: theme.spacing(4),
    overflow: 'hidden',
    background: theme.palette.background.default,
}));

export function SystemDown() {
    return (
        <ContentContainer>
            <Typography variant="headline">Whoops... </Typography>
            <Typography variant="smallHeadline" textAlign="center">
                We apologize for the inconvenience, but our website is currently down for
                maintenance. We are working hard to resolve the issue and restore access as soon as
                possible.
                <br />
                Thank you for your patience and understanding.
            </Typography>
        </ContentContainer>
    );
}
