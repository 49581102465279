import SvgIcon from '@mui/material/SvgIcon';

export function MediaPresentationIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.40795 31.984H22.592V32C26.016 32 28.7999 29.408 28.7999 26.224V25.4494H25.5999V26.192C25.5999 27.616 24.256 28.768 22.592 28.768H9.40795C7.74395 28.768 6.39995 27.616 6.39995 26.192V5.76C6.39995 4.336 7.74395 3.184 9.40795 3.184L9.39195 3.168H15.816V8.53288C15.816 10.9928 17.6157 13.2376 20.127 13.2376H27.3209V13.2336H28.7999V11.808C28.7999 10.256 28.128 8.752 26.928 7.664L20.2879 1.648C19.1199 0.576004 17.584 0 15.952 0H9.40795C5.98395 0 3.19995 2.592 3.19995 5.776V26.208C3.19995 29.392 5.98395 31.984 9.40795 31.984ZM25.3951 10.8376C25.2551 10.5297 25.0446 10.2441 24.768 10L18.216 4.06374V8.53288C18.216 9.94415 19.202 10.8376 20.127 10.8376H25.3951ZM23.3902 15.7559H29.2254V17.2903H27.187V23.4727H25.4286V17.2903H23.3902V15.7559ZM20.1265 15.7559H16.9457V23.4727H18.7041V20.8519H20.1265C21.9185 20.8519 22.7249 19.5975 22.7249 18.3095C22.7249 17.0327 21.9185 15.7559 20.1265 15.7559ZM20.0481 19.3175H18.7041V17.2903H20.0593C20.7089 17.2903 20.9553 17.8167 20.9553 18.3095C20.9553 18.8023 20.7089 19.3175 20.0481 19.3175ZM9.84038 15.7559H13.0212C14.8132 15.7559 15.6196 17.0327 15.6196 18.3095C15.6196 19.5975 14.8132 20.8519 13.0212 20.8519H11.5988V23.4727H9.84038V15.7559ZM11.5988 19.3175H12.9428C13.6036 19.3175 13.85 18.8023 13.85 18.3095C13.85 17.8167 13.6036 17.2903 12.954 17.2903H11.5988V19.3175Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
