import {
    setAttachmentFilter,
    setEnableAttachmentFilter,
} from 'views/Communications/communicationsSlice';
import { MultiSelectFilter } from './MultiSelectFilter';
import { useMultiSelectFilter } from '../hooks/useMultiSelectFilter';
import { MULTI_SELECT_FILTER_TYPES } from '../constants';

export function FilterAttachments() {
    const { isFilterEnabled, handleFilterCheck, filterValue, handleFilterChange } =
        useMultiSelectFilter(
            MULTI_SELECT_FILTER_TYPES.attachments,
            setAttachmentFilter,
            setEnableAttachmentFilter
        );

    return (
        <MultiSelectFilter
            isFilterEnabled={Boolean(isFilterEnabled)}
            handleFilterCheck={handleFilterCheck}
            filterValue={filterValue}
            handleFilterChange={handleFilterChange}
            label="Attachment(s)"
            filterOptions={[
                { label: 'Has', value: true },
                { label: 'Does not have', value: false },
            ]}
        />
    );
}
