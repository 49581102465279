import SvgIcon from '@mui/material/SvgIcon';

export function TextCursorFeatureIcon(props) {
    return (
        <SvgIcon viewBox="0 0 27 27" {...props}>
            <circle cx="13.5" cy="13.5" r="13.5" fill="#5F2BE1" />
            <path
                d="M11.6999 6.63386C11.8111 6.70608 11.8891 6.81951 11.9166 6.94921C11.9442 7.07891 11.9191 7.21426 11.8469 7.32547L10.2129 9.84149C10.1407 9.9527 10.1156 10.088 10.1432 10.2177C10.1707 10.3474 10.2487 10.4609 10.3599 10.5331L17.9083 15.4351C18.0195 15.5073 18.1549 15.5324 18.2846 15.5048C18.4143 15.4772 18.5277 15.3993 18.5999 15.2881L20.2338 12.772C20.3061 12.6608 20.4195 12.5829 20.5492 12.5553C20.6789 12.5277 20.8142 12.5528 20.9254 12.625C21.0366 12.6972 21.1146 12.8106 21.1421 12.9403C21.1697 13.07 21.1446 13.2054 21.0724 13.3166L19.4385 15.8326C19.2694 16.093 19.0238 16.2946 18.7354 16.4095C18.4471 16.5244 18.1302 16.547 17.8284 16.4742L17.2375 17.3842C16.9486 17.829 16.4948 18.1409 15.9761 18.2513C15.4573 18.3616 14.9159 18.2613 14.4711 17.9724L14.4703 17.9719L13.5252 19.4328C13.3884 19.6442 13.2005 19.8178 12.979 19.9376C12.7575 20.0573 12.5094 20.1194 12.2576 20.118L6.8625 20.0913C6.77274 20.0908 6.68478 20.0662 6.60784 20.02C6.53089 19.9737 6.46782 19.9077 6.42523 19.8286C6.38263 19.7496 6.3621 19.6606 6.36578 19.5709C6.36945 19.4812 6.39721 19.3942 6.44612 19.3189L9.44164 14.7062L9.43912 14.7046C8.99434 14.4158 8.68253 13.962 8.5723 13.4432C8.46207 12.9244 8.56244 12.383 8.85134 11.9382L9.44227 11.0282C9.25328 10.7821 9.14526 10.4835 9.1331 10.1734C9.12095 9.86333 9.20525 9.55711 9.37441 9.29694L11.0083 6.78093C11.0805 6.66971 11.194 6.59174 11.3237 6.56416C11.4534 6.53658 11.5887 6.56165 11.6999 6.63386ZM10.2802 15.2508L7.783 19.0961L12.2634 19.1178C12.3472 19.1182 12.4298 19.0976 12.5036 19.0578C12.5773 19.018 12.6399 18.9603 12.6855 18.8899L13.6318 17.4273L10.2802 15.2508ZM9.68987 12.4827C9.54542 12.7051 9.49524 12.9758 9.55035 13.2352C9.60547 13.4946 9.76137 13.7215 9.98376 13.8659L15.0149 17.1332C15.2373 17.2776 15.508 17.3278 15.7674 17.2726C16.0268 17.2175 16.2537 17.0615 16.3981 16.8391L16.9428 16.0004L10.2345 11.644L9.68987 12.4827Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
