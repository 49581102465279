import { deserialize } from './deserialize';

function isPlainText(node) {
    if (node.length === 1) {
        return !node[0].type;
    }
    return false;
}

function toHtml(node) {
    return [{ type: 'div', children: node }];
}

export function parseValue(value) {
    const prepValue = value.replace(/\n\n|\r\n/g, '<br/>');
    const document = new DOMParser().parseFromString(prepValue, 'text/html');
    const nodes = deserialize(document.body);

    if (isPlainText(nodes)) {
        return toHtml(nodes);
    }

    if (nodes.length > 1) {
        return nodes.map((node) => {
            if (node?.text) {
                return {
                    type: 'div',
                    children: [{ text: node.text }],
                };
            }
            return node;
        });
    }

    return nodes;
}
