import PropTypes from 'prop-types';
import { IconButton, alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import GlobeIcon from '@mui/icons-material/Language';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import { Typography } from 'components/Typography';
import { getValueByMode } from 'utils/copy/getValueByMode';
import { useNotesStore } from '../../store/useNotesStore';
import { NotesSelectDropDown } from './NotesSelectDropDown';
import { NOTES_FILTER_OPTIONS } from 'features/TalentNotes/utils/constants';

const Container = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isInProfileDrawer',
})(({ theme, isInProfileDrawer }) => ({
    height: '40px',
    borderWidth: '1px 0px',
    borderStyle: 'solid',
    borderColor: alpha(theme.palette.text.primary, 0.12),
    borderTopColor: isInProfileDrawer && 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
}));

export const ArrowIcon = styled(ArrowDownward, {
    shouldForwardProp: (prop) => prop !== 'isAsc',
})(({ theme, isAsc }) => ({
    color: '#3A8DD3',
    transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
    }),
    transform: `rotate(${getValueByMode(isAsc, 0, 180)}deg)`,
    width: '13px',
    height: '15px',
}));

export function SortNotesActions(props) {
    const { isInProfileDrawer } = props;

    const {
        isAscMode,
        filter,
        setFilter,
        setIsAscMode: toggleIsAsc,
    } = useNotesStore((state) => state);

    const showLabel = (filter, options) => {
        const option = options.find((option) => option.value === filter);
        return option.label;
    };

    return (
        <Container isInProfileDrawer={isInProfileDrawer}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <GlobeIcon
                    sx={{
                        mr: 0.5,
                        width: '13px',
                        height: '13px',
                        color: (theme) => alpha(theme.palette.text.primary, 0.12),
                        mt: 0.2,
                    }}
                />
                <Typography
                    variant="labelXSmall"
                    sx={{ fontWeight: 700, color: (theme) => theme.palette.text.primary }}
                    aria-label="selected-filter"
                >
                    {filter && showLabel(filter, NOTES_FILTER_OPTIONS)}
                </Typography>
                <NotesSelectDropDown
                    setPrivacy={setFilter}
                    options={NOTES_FILTER_OPTIONS}
                    styles={{ mt: '-7px', width: 30 }}
                />
            </Box>
            <IconButton
                sx={{ display: 'flex', alignItems: 'center' }}
                disableRipple
                onClick={() => toggleIsAsc(!isAscMode)}
                aria-label="sort-notes-by-date"
                data-trackid="sort-notes-date"
            >
                <Typography
                    variant="labelXSmall"
                    sx={{ fontWeight: 700, mr: 0.5, color: (theme) => theme.palette.text.primary }}
                >
                    DATE
                </Typography>
                <ArrowIcon isAsc={isAscMode} />
            </IconButton>
        </Container>
    );
}

SortNotesActions.propTypes = {
    isInProfileDrawer: PropTypes.bool,
};
