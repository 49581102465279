import SvgIcon from '@mui/material/SvgIcon';

export function StatScheduledIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="m22.7895 1.21052c-1.202-1.20155332-2.9517-1.535323-4.5544-.881144l-15.54628 6.354884c-1.709559.69423-2.7646919 2.33637-2.68455619 4.16544.06677969 1.7622 1.20202619 3.2308 2.87151619 3.7648l3.64619 1.1615c.80136.2537 1.44243.8812 1.69619 1.6956l1.16196 3.658c.53424 1.6689 2.00338 2.7903 3.76638 2.8704h.1736c1.763 0 3.3123-1.0414 3.9935-2.6968l6.3574-15.54014c.6544-1.60207.3206-3.33763-.8948-4.55254zm-1.5894 3.53791-6.3574 15.54007c-.3205.7877-1.0017 1.028-1.5893 1.028-.3072 0-1.0551-.1335-1.3356-1.0013l-1.162-3.658c-.1202-.3872-.2938-.761-.5075-1.1081l5.3824-5.367c.5209-.52064.5209-1.36173 0-1.88241-.5208-.52067-1.3623-.52067-1.8832 0l-5.40914 5.39361c-.32054-.1869-.65444-.3471-1.0017-.4539l-3.64614-1.1615c-.89485-.2803-1.0017-1.0947-1.0017-1.335-.02671-.5474.24039-1.255 1.02839-1.58877l15.54639-6.3549c.601-.24031 1.2153-.13349 1.6427.30708.4408.44057.5476 1.05469.3072 1.64212z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
