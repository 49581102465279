import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { AttachmentIcon } from 'components/Icons';

const StyledIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'disableShadow',
})(({ theme, disableShadow }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    boxShadow: disableShadow
        ? 'inherit'
        : '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    '&.Mui-disabled': { opacity: 0.32 },
}));

export function AttachmentButton(props) {
    const { onClick, disableShadow, ...restProps } = props;
    return (
        <StyledIconButton onClick={onClick} disableShadow={disableShadow} {...restProps}>
            <AttachmentIcon sx={{ fontSize: 14 }} />
        </StyledIconButton>
    );
}

AttachmentButton.propTypes = {
    onClick: PropTypes.func,
    disableShadow: PropTypes.bool,
};
