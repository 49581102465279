import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';

import { onLogout } from 'features/Navbar';
import { useKeycloak } from 'features/Authentication';
import { useDispatch } from 'react-redux';
import { Typography } from 'components/Typography';
import { LargeLogo, LogoContainer, SmallLogo } from './UserInfoLogo';

const largeLogoSrc = '/assets/logos/Nebula-color-horizonal.png';
const smallLogoSrc = '/assets/logos/Nebula-social-256.png';

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '20%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
        width: '95%',
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '35%',
    },
}));

const MainContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    marginBottom: theme.spacing(3.5),
    textTransform: 'none',
    padding: theme.spacing(1, 0.5),
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1.5),
    width: '100%',
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
        backgroundColor: alpha(theme.palette.text.primary, 0.15),
        color: alpha(theme.palette.text.primary, 0.69),
    },
    [theme.breakpoints.down('sm')]: {
        width: '60%',
    },
    [theme.breakpoints.up('md')]: {
        width: '60%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '45%',
    },
}));

const StyledLink = styled('a')(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
    marginLeft: theme.spacing(1),
}));

export function UserRequiresApprovalPage() {
    const dispatch = useDispatch();

    const { keycloak } = useKeycloak();

    const handleLogout = useCallback(() => {
        onLogout(keycloak, dispatch);
    }, [keycloak, dispatch]);

    return (
        <Container>
            <MainContainer>
                <LogoContainer>
                    <LargeLogo src={largeLogoSrc} alt="large-nebula-logo" />
                    <SmallLogo src={smallLogoSrc} alt="small-nebula-logo" />
                </LogoContainer>
                <Typography variant="headline" color="textPrimary">
                    Thank you for signing up for Nebula!
                </Typography>
                <Typography variant="smallHeadline" textAlign="center">
                    We've sent an email to your Organization’s Admin. You can access the application
                    once you receive approval from your Organizations Admin.
                </Typography>
                <Typography variant="smallHeadline" textAlign="center">
                    Contact Support:
                    <StyledLink href="mailto:sales@nebula.io">sales@nebula.io</StyledLink>
                </Typography>
                <StyledButton variant="contained" onClick={handleLogout}>
                    Return to Sign Up
                </StyledButton>
            </MainContainer>
        </Container>
    );
}
