import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DropdownMenu } from 'components/DropdownMenu';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { useEffect, useState } from 'react';
import {
    getFirstDayInPreviousMonth,
    getFirstDayInTheMonth,
    getLastDayInPreviousMonth,
    getLastWeekSaturday,
    getLastWeekSunday,
    getMostRecentSunday,
    getThirtyDaysAgo,
} from '../utils/dateRetrievalHelpers';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMetricDateRange,
    setMetricFilterDateRange,
} from 'views/Communications/communicationsSlice';

const DropdownButton = styled(ButtonBase)(() => ({
    display: 'flex',
    overflow: 'hidden',
    textAlign: 'center',
}));

export const METRIC_DATE_FILTER_OPTIONS = {
    PAST_30_DAYS: {
        options: {
            label: 'Past 30 days',
            value: 'PAST_30_DAYS',
            dataTrackId: 'past-30-days-past-30-days',
        },
        startDateFunc: getThirtyDaysAgo,
    },
    THIS_WEEK: {
        options: {
            label: 'This week',
            value: 'THIS_WEEK',
            dataTrackId: 'past-30-days-this-week',
        },
        startDateFunc: getMostRecentSunday,
    },
    LAST_WEEK: {
        options: {
            label: 'Last week',
            value: 'LAST_WEEK',
            dataTrackId: 'past-30-days-last-week',
        },
        startDateFunc: getLastWeekSunday,
        endDateFunc: getLastWeekSaturday,
    },
    THIS_MONTH: {
        options: {
            label: 'This month',
            value: 'THIS_MONTH',
            dataTrackId: 'past-30-days-this-month',
        },
        startDateFunc: getFirstDayInTheMonth,
    },
    LAST_MONTH: {
        options: {
            label: 'Last month',
            value: 'LAST_MONTH',
            dataTrackId: 'past-30-days-last-month',
        },
        startDateFunc: getFirstDayInPreviousMonth,
        endDateFunc: getLastDayInPreviousMonth,
    },
};

export function SendMetricDateFilterDropdown({ ButtonSxProps, ...restProps }) {
    const dispatch = useDispatch();
    const selectedFilter = useSelector((state) => state.communications.selectedDateRangeFilter);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (value) => {
        dispatch(selectMetricDateRange(value));
    };

    const options = map(METRIC_DATE_FILTER_OPTIONS, 'options');
    const {
        startDateFunc,
        endDateFunc,
        options: selectedOption,
    } = METRIC_DATE_FILTER_OPTIONS[selectedFilter];

    useEffect(() => {
        dispatch(
            setMetricFilterDateRange({
                startDate: startDateFunc(new Date()).toISOString().split('T')[0],
                endDate:
                    typeof endDateFunc !== 'function'
                        ? ''
                        : endDateFunc(new Date()).toISOString().split('T')[0],
            })
        );
    }, [selectedFilter, startDateFunc, endDateFunc, dispatch]);

    return (
        <>
            <DropdownButton
                sx={(theme) => ({ color: theme.palette.common.white, ...ButtonSxProps })}
                data-testid="send-metrics-date-filter-dropdown-button"
                onClick={handleOpen}
                disableRipple
                {...restProps}
            >
                <Typography sx={{ mr: 0.5 }} variant="bodyBaseBold">
                    {selectedOption.label}
                </Typography>
                {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </DropdownButton>
            <DropdownMenu
                selectedOptions={selectedOption ? [selectedOption] : []}
                anchorEl={anchorEl}
                open={open}
                options={options}
                onClose={handleClose}
                onSelectedOptionChange={handleSelect}
            />
        </>
    );
}

SendMetricDateFilterDropdown.propTypes = {
    ButtonSxProps: PropTypes.object,
};
