import SvgIcon from '@mui/material/SvgIcon';

export function PastJobTitleIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M1.45384 1.84131C1.32832 1.84131 1.22656 1.94306 1.22656 2.06858V12.2504C1.22656 12.3759 1.32832 12.4777 1.45384 12.4777H5.6692C5.79161 12.8269 5.94541 13.1614 6.1272 13.4777H1.45384C0.776031 13.4777 0.226562 12.9282 0.226562 12.2504V2.06858C0.226562 1.39078 0.776031 0.841309 1.45384 0.841309H14.5447C15.2225 0.841309 15.772 1.39078 15.772 2.06858V6.45556C15.4727 6.12658 15.1373 5.83099 14.772 5.57507V2.06858C14.772 1.94306 14.6703 1.84131 14.5447 1.84131H1.45384Z"
                fill="currentColor"
                fillOpacity="0.72"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.84244 8.06892C6.20302 7.25317 6.74006 6.53288 7.40516 5.95643C7.37774 5.47894 7.17593 5.02654 6.83591 4.68651C6.46936 4.31996 5.97221 4.11404 5.45384 4.11404C4.93546 4.11404 4.43831 4.31996 4.07176 4.68651C3.70521 5.05306 3.49929 5.5502 3.49929 6.06858C3.49929 6.57419 3.69519 7.05959 4.04496 7.42332C3.86949 7.53166 3.70574 7.66071 3.55754 7.80891C3.0546 8.31185 2.77205 8.99398 2.77205 9.70524C2.77205 9.98138 2.99591 10.2052 3.27205 10.2052C3.5482 10.2052 3.77205 9.98138 3.77205 9.70524C3.77205 9.25919 3.94925 8.83142 4.26465 8.51602C4.58005 8.20061 5.00783 8.02342 5.45387 8.02342C5.5858 8.02342 5.71614 8.03892 5.84244 8.06892ZM5.45384 5.11404C5.20067 5.11404 4.95788 5.2146 4.77887 5.39362C4.59986 5.57263 4.49929 5.81542 4.49929 6.06858C4.49929 6.32174 4.59986 6.56453 4.77887 6.74355C4.95788 6.92256 5.20067 7.02313 5.45384 7.02313C5.707 7.02313 5.94979 6.92256 6.1288 6.74355C6.30781 6.56453 6.40838 6.32174 6.40838 6.06858C6.40838 5.81542 6.30781 5.57263 6.1288 5.39362C5.94979 5.2146 5.707 5.11404 5.45384 5.11404Z"
                fill="currentColor"
                fillOpacity="0.72"
            />
            <path
                d="M11.3679 8.40519C11.6441 8.40519 11.8679 8.62905 11.8679 8.90519V10.2708L13.0307 10.1493C13.3054 10.1206 13.5513 10.32 13.58 10.5946C13.6087 10.8693 13.4093 11.1152 13.1346 11.1439L11.4199 11.3231C11.279 11.3378 11.1385 11.292 11.0332 11.1972C10.928 11.1024 10.8679 10.9674 10.8679 10.8258V8.90519C10.8679 8.62905 11.0918 8.40519 11.3679 8.40519Z"
                fill="white"
                fillOpacity="0.72"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.66602 10.4924C6.66602 7.92102 8.76135 5.82568 11.3327 5.82568C13.904 5.82568 15.9993 7.92102 15.9993 10.4924C15.9993 13.0637 13.904 15.159 11.3327 15.159C8.76135 15.159 6.66602 13.0637 6.66602 10.4924ZM11.3327 6.82568C9.31363 6.82568 7.66602 8.4733 7.66602 10.4924C7.66602 12.5114 9.31363 14.159 11.3327 14.159C13.3517 14.159 14.9993 12.5114 14.9993 10.4924C14.9993 8.4733 13.3517 6.82568 11.3327 6.82568Z"
                fill="currentColor"
                fillOpacity="0.72"
            />
        </SvgIcon>
    );
}
