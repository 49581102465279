import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import { SkillPopOverLinks } from 'features/TalentCard/components/SkillPopOverLinks';

export function SkillPopOver(props) {
    const { isSkillOpen, handleSkillOpen, skill, open, indx } = props;
    if (!open) return null;

    const handleSkillOpened = () => {
        handleSkillOpen(indx);
    };

    return (
        <Box
            sx={{
                cursor: 'pointer',
                position: 'absolute',
                zIndex: 1,
                borderRadius: '8px',
                width: 'max-content',
                left: 0,
                top: 0,
                backgroundColor: 'primary.main',
            }}
        >
            <Collapse in={isSkillOpen} collapsedSize={20} sx={{ borderRadius: '8px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                    }}
                    data-testid="skill-popover-box"
                >
                    <SkillPopOverLinks
                        isSkillOpen={isSkillOpen}
                        skill={skill}
                        handleSkillOpen={handleSkillOpened}
                    />
                </Box>
            </Collapse>
        </Box>
    );
}

SkillPopOver.propTypes = {
    skill: PropTypes.object,
    indx: PropTypes.number,
    isSkillOpen: PropTypes.bool,
    handleSkillOpen: PropTypes.func,
    open: PropTypes.bool,
};
