import { useState, useCallback, useRef } from 'react';

export function useCallbackRef() {
    const [clientHeight, setClientHeight] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [clientWidth, setClientWidth] = useState(0);

    const ref = useRef({ clientHeight, scrollHeight });

    const recompute = useCallback(() => {
        setClientHeight(ref.current.clientHeight);
        setScrollHeight(ref.current.scrollHeight);
        setClientWidth(ref.current.clientWidth);
    }, []);

    const resetScroll = useCallback(() => {
        ref.current.scrollTop = 0;
    }, []);

    const callbackRef = useCallback(
        (node) => {
            if (node) {
                ref.current = node;
                recompute();
            }
        },
        [recompute]
    );

    return [callbackRef, clientHeight, scrollHeight, recompute, resetScroll, clientWidth];
}
