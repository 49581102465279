import SvgIcon from '@mui/material/SvgIcon';

export function ViewIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M6.21521 12.7812V12.785C6.19416 12.8644 6.15766 12.9389 6.10781 13.0041C6.05796 13.0694 5.99573 13.1242 5.92469 13.1654C5.85364 13.2067 5.77516 13.2335 5.69375 13.2443C5.61233 13.2552 5.52958 13.2499 5.45021 13.2287C4.91146 13.0862 5.00646 12.465 5.00646 12.465L5.02896 12.3875C5.02896 12.3875 5.06146 12.2825 5.08771 12.2063C5.40848 11.3069 5.88071 10.4689 6.48396 9.72875C7.60521 8.36125 9.49146 7 12.4852 7C15.479 7 17.3652 8.36125 18.4877 9.72875C19.091 10.4689 19.5632 11.3069 19.884 12.2063C19.9108 12.2836 19.9358 12.3615 19.959 12.44L19.9627 12.4562V12.4613L19.964 12.4638C20.0036 12.6228 19.9792 12.7911 19.8959 12.9324C19.8126 13.0736 19.6772 13.1764 19.5188 13.2187C19.3604 13.261 19.1917 13.2394 19.0491 13.1585C18.9065 13.0776 18.8014 12.9439 18.7565 12.7862L18.7552 12.7812L18.7452 12.75C18.6743 12.5287 18.5909 12.3117 18.4952 12.1C18.2405 11.5335 17.9127 11.0027 17.5202 10.5212C16.5927 9.39 15.0402 8.25 12.4852 8.25C9.93021 8.25 8.37896 9.39 7.45021 10.5212C6.94083 11.1468 6.5414 11.8544 6.26896 12.6138C6.25351 12.6589 6.23893 12.7043 6.22521 12.75L6.21521 12.7812ZM12.4852 10.75C11.6564 10.75 10.8616 11.0792 10.2755 11.6653C9.68945 12.2513 9.36021 13.0462 9.36021 13.875C9.36021 14.7038 9.68945 15.4987 10.2755 16.0847C10.8616 16.6708 11.6564 17 12.4852 17C13.314 17 14.1089 16.6708 14.6949 16.0847C15.281 15.4987 15.6102 14.7038 15.6102 13.875C15.6102 13.0462 15.281 12.2513 14.6949 11.6653C14.1089 11.0792 13.314 10.75 12.4852 10.75ZM10.6102 13.875C10.6102 13.3777 10.8078 12.9008 11.1594 12.5492C11.511 12.1975 11.9879 12 12.4852 12C12.9825 12 13.4594 12.1975 13.811 12.5492C14.1627 12.9008 14.3602 13.3777 14.3602 13.875C14.3602 14.3723 14.1627 14.8492 13.811 15.2008C13.4594 15.5525 12.9825 15.75 12.4852 15.75C11.9879 15.75 11.511 15.5525 11.1594 15.2008C10.8078 14.8492 10.6102 14.3723 10.6102 13.875Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
