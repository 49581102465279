import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

export function PaperIcon(props) {
    const { gradient, ...restProps } = props;
    const fill = gradient ? 'url(#paint0_linear_12701_420564)' : 'currentColor';
    return (
        <SvgIcon viewBox="0 0 12 12" {...restProps}>
            <path
                d="M11.4733 3.80664L8.18667 0.519979C7.85333 0.186646 7.39999 0 6.92666 0H4C1.79333 0 0 1.79333 0 4V8C0 10.2067 1.79333 12 4 12H8C10.2067 12 12 10.2067 12 8V5.07332C12 4.59999 11.8133 4.14665 11.48 3.81331L11.4733 3.80664ZM7.82666 2.04667L9.95333 4.17334H8.89333C8.30666 4.17334 7.82666 3.72667 7.82666 3.17334V2.04667ZM7.99333 10.66H3.99333C2.51999 10.66 1.32666 9.46666 1.32666 7.99333V3.99333C1.32666 2.51999 2.51999 1.32666 3.99333 1.32666H6.48665V3.17334C6.48665 4.46001 7.56666 5.50667 8.88666 5.50667H10.6533V7.99333C10.6533 9.46666 9.45999 10.66 7.98665 10.66H7.99333Z"
                fill={fill}
            />
            <defs>
                <linearGradient
                    id="paint0_linear_12701_420564"
                    x1="-3.36069e-08"
                    y1="4.30986"
                    x2="12.003"
                    y2="4.36759"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

PaperIcon.propTypes = {
    gradient: PropTypes.bool,
};
