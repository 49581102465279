import { useCallback, useMemo } from 'react';

import { MESSAGE_TYPES } from 'utils/constants';
import { useGetPrimaryContacts } from './useGetPrimaryContacts';
import { useGetOutboundContact } from './useGetOutboundContact';
import { useGetReplyContact } from './useGetReplyContact';

// Retrieves talent contact information and arranges data prioritizing recent replies or outgoing messages
// Secondary contact information are primary contacts and default contacts
export function useTalentContact({
    conversationId,
    messageType,
    profiles,
    contact: initialContact,
    skip,
}) {
    const profile = profiles?.[0];
    const { talentId } = profile || {};

    const messageChannel = messageType === MESSAGE_TYPES.EMAIL ? 'email' : 'phone';

    const primaryUserContact = useGetPrimaryContacts({
        talentId,
        conversationId,
        messageType,
        skip,
    });

    const outboundContact = useGetOutboundContact({ conversationId, messageType, skip });

    const replyContact = useGetReplyContact({ conversationId, messageType, skip });

    const resolveContacts = useCallback(() => {
        const contacts = {
            [talentId]: {},
        };

        if (Boolean(initialContact?.value)) {
            contacts[talentId][messageChannel] = [initialContact.value];
        } else if (replyContact && messageType === MESSAGE_TYPES.SMS) {
            contacts[talentId][messageChannel] = [replyContact];
        } else if (outboundContact) {
            contacts[talentId][messageChannel] = [outboundContact];
        } else if (primaryUserContact) {
            contacts[talentId][messageChannel] = [primaryUserContact];
        } else if (profile?.[messageChannel]) {
            contacts[talentId][messageChannel] = profile?.[messageChannel];
        }
        return contacts;
    }, [
        initialContact,
        talentId,
        replyContact,
        outboundContact,
        primaryUserContact,
        profile,
        messageChannel,
        messageType,
    ]);

    const resolveDefaultContacts = useCallback(() => {
        const contacts = {
            [talentId]: {},
        };

        if (profile?.[messageChannel]) {
            contacts[talentId][messageChannel] = profile?.[messageChannel];
        } else if (primaryUserContact) {
            contacts[talentId][messageChannel] = [primaryUserContact];
        }
        return contacts;
    }, [primaryUserContact, profile, messageChannel, talentId]);

    return useMemo(() => {
        if (!messageType || skip) {
            return null;
        } else if (conversationId) {
            return resolveContacts();
        }
        return resolveDefaultContacts();
    }, [skip, conversationId, messageType, resolveContacts, resolveDefaultContacts]);
}
