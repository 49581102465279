import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

import { DEFAULT_DYNAMIC_FIELD_FALLBACKS_OPENAI } from '../constants';
import { useGetDynamicFieldsQuery } from 'services/conversations';
import {
    changeDefaultDynamicFields,
    resetDefaultDynamicFields,
    setDynamicFields,
} from '../DynamicFieldsSlice';

export function useDynamicFields(showDynamicFields) {
    const result = useGetDynamicFieldsQuery();
    const { data, isSuccess, isFetching } = result || {};

    const { dynamicFields, flatDynamicFields, defaultDynamicFields } = useSelector(
        (state) => state.dynamicFields
    );

    const dispatch = useDispatch();

    const addDefaultDynamicFields = ({ name, value }) => {
        if (name in defaultDynamicFields) return;
        dispatch(
            changeDefaultDynamicFields({
                ...defaultDynamicFields,
                [name]: value || DEFAULT_DYNAMIC_FIELD_FALLBACKS_OPENAI[name],
            })
        );
    };

    const handleChangeDefaultDynamicField = (key, value) => {
        dispatch(
            changeDefaultDynamicFields({
                ...defaultDynamicFields,
                [key]: value,
            })
        );
    };

    const handleResetDynamicFields = () => {
        dispatch(resetDefaultDynamicFields());
    };

    const handleRetrieveDefaultDynamicFields = (text) => {
        const usedFieldsKeys = text.match(/{{\w+}}/g);
        const validKeys = usedFieldsKeys?.filter((key) => flatDynamicFields?.includes(key));

        if (validKeys) {
            validKeys.forEach((key) => {
                const value =
                    defaultDynamicFields[key] || DEFAULT_DYNAMIC_FIELD_FALLBACKS_OPENAI[key];
                addDefaultDynamicFields({ name: key, value });
            });
        }
        const filteredFields = validKeys
            ? validKeys.reduce((obj, key) => {
                  obj[key] =
                      defaultDynamicFields[key] || DEFAULT_DYNAMIC_FIELD_FALLBACKS_OPENAI[key];
                  return obj;
              }, {})
            : {};

        dispatch(changeDefaultDynamicFields(filteredFields));
    };

    const validateDynamicFieldsValues = () => {
        if (!isEmpty(defaultDynamicFields)) {
            return Object.values(defaultDynamicFields).every((value) => value?.trim?.());
        }
        return true;
    };

    useEffect(() => {
        if (isSuccess && data) {
            const nextDynamicFields = {};
            const dynamicFieldList = [];
            forEach(data, (field, key) => {
                if (!Boolean(showDynamicFields) && field?.category === 'Knowledge Base') {
                    return;
                }
                if (key.toLowerCase() === '{{samplefield}}') return;

                nextDynamicFields[field?.category] = {
                    ...nextDynamicFields?.[field?.category],
                    [key]: {
                        ...field,
                        dynamicField: key,
                    },
                };
                dynamicFieldList.push(key);
            });
            dispatch(
                setDynamicFields({
                    dynamicFields: nextDynamicFields,
                    flatDynamicFields: dynamicFieldList,
                })
            );
        }
    }, [isSuccess, data, dispatch, showDynamicFields]);

    const dynamicFieldDecorator = useCallback(
        (node, path) => {
            const decoratedRanges = [];
            const regexp = /\{\{[^}]*\}\}/g;
            const matches = node.text.matchAll(regexp);
            for (const match of matches) {
                decoratedRanges.push({
                    highlighted: true,
                    isDynamicFieldValid: flatDynamicFields?.includes(match[0]),
                    description: data?.[match[0]]?.description || '',
                    anchor: { path, offset: match.index },
                    focus: { path, offset: match.index + match[0].length },
                });
            }
            return decoratedRanges;
        },
        [flatDynamicFields, data]
    );

    return {
        dynamicFieldsData: data,
        dynamicFields,
        flatDynamicFields,
        defaultDynamicFields,
        isSuccess,
        isFetching,
        dynamicFieldDecorator,
        addDefaultDynamicFields,
        handleResetDynamicFields,
        validateDynamicFieldsValues,
        handleChangeDefaultDynamicField,
        handleRetrieveDefaultDynamicFields,
    };
}