import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import findIndex from 'lodash/findIndex';

import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';
import { formatPhoneNumber } from 'utils/formatters/formatNumber';
import { MESSAGE_TYPES } from 'utils/constants';
import { updateContact } from '../ContactSelectionSlice';
import { StarOutlined } from '@mui/icons-material';
import { CONTACT_STATUS_MAP, CONTACT_MEDIUMS } from 'features/TalentCard/utils/constants';
import { CheckBoxSelectedIcon, CheckBoxUnselectedIcon, CooldownIcon } from 'components/Icons';
import { cooldownBlue } from 'theme';
import { filterValidOptions, reorderContacts } from '../utils/contactProcessHelpers';

function renderValues(values, numberOfValidOptions, format) {
    const isEmpty = numberOfValidOptions === 0;
    let text = 'No Contacts Selected';
    if (values?.length === 1) text = format?.(values[0]) || values[0];
    else if (values?.length === numberOfValidOptions && !isEmpty) text = 'All Contacts Selected';
    else if (values?.length >= 2) text = `${values.length} Contacts Selected`;
    return <>{text}</>;
}

export function ContactDropdown(props) {
    const { id, contactData, messageType } = props;

    const [disabled, setDisabled] = useState(false);

    const { entities } = useSelector((state) => state.contactSelections);
    const isFrcEnabled = useSelector((state) => state.communications.isFrcEnabled);

    const dispatch = useDispatch();

    const [initialValue, setInitialValue] = useState(entities?.[id]?.[messageType] || []);
    const [medium, setMedium] = useState('');

    const [options, checkedOptions] = useMemo(() => {
        const contacts = entities?.[id]?.[medium] || [];
        const selected = {};
        contacts.forEach((c) => {
            selected[c] = true;
        });
        return [contacts, selected];
    }, [entities, id, medium]);

    useEffect(() => {
        if (contactData?.length) {
            const contactMedium = contactData[0].contactMedium;
            const validOptions = contactData.filter(filterValidOptions);
            const reorderedContacts = reorderContacts(validOptions);
            const firstContact = reorderedContacts.length ? reorderedContacts[0] : {};
            const medium = CONTACT_MEDIUMS[contactMedium]?.label;
            const contactDetails = firstContact[medium];
            if (!initialValue || !entities?.[id]?.[medium]) {
                const defaultOption = contactDetails ? [contactDetails] : [];
                setInitialValue(entities?.[id]?.[messageType] || defaultOption);
                if (!contactDetails) {
                    dispatch(updateContact({ id, changes: { selected: false } })); //no valid options at all
                    setDisabled(true);
                }
            }
            setMedium(medium);
        }
    }, [initialValue, contactData, entities, id, dispatch, messageType]);

    useEffect(() => {
        if (initialValue?.length > 0) {
            dispatch(updateContact({ id, changes: { [medium]: initialValue } }));
        }
    }, [initialValue, medium, id, dispatch]);

    useEffect(() => {
        const violationIndex = findIndex(contactData, (contact) => contact?.policyViolated);

        if (violationIndex === 0) {
            dispatch(updateContact({ id, changes: { selected: false } }));
            if (contactData.length === 1) {
                setDisabled(true);
            }
        }
    }, [contactData, id, dispatch]);

    const handleOptionClick = ({
        currentTarget: {
            dataset: { value, disabled },
        },
    }) => {
        if (disabled === 'true') return;

        const isBulk = messageType === MESSAGE_TYPES.EMAIL;

        const values = isBulk ? [...options] : [];
        const index = options.findIndex((e) => e === value);
        if (index >= 0 && isBulk) values.splice(index, 1);
        else if (index === -1) values.push(value);

        dispatch(updateContact({ id, changes: { [medium]: values, selected: values.length > 0 } }));
    };

    const handlePropagation = (e) => {
        if (e.currentTarget.dataset.disabled === 'true') e.stopPropagation();
    };

    const numberOfOptions = useMemo(() => {
        return contactData.filter(filterValidOptions).length;
    }, [contactData]);

    const handleSelectAll = (e) => {
        e.preventDefault();

        const staging = contactData
            .filter(filterValidOptions)
            .map((contact) => contact[CONTACT_MEDIUMS[contact.contactMedium].label]);
        dispatch(updateContact({ id, changes: { [medium]: staging, selected: true } }));
    };

    const handleDeselectAll = (e) => {
        e.preventDefault();
        dispatch(updateContact({ id, changes: { [medium]: [], selected: false } }));
    };

    return (
        <Select
            inputProps={{ 'aria-label': `${id}-contact-select` }}
            variant="standard"
            value={options}
            disabled={disabled || isFrcEnabled}
            sx={{ width: '100%' }}
            multiple={true}
            displayEmpty={true}
            renderValue={(values) =>
                renderValues(
                    values,
                    numberOfOptions,
                    messageType === MESSAGE_TYPES.SMS ? formatPhoneNumber : null
                )
            }
        >
            <Box
                sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    onClick={handleSelectAll}
                    sx={{ textTransform: 'none' }}
                    disabled={messageType === MESSAGE_TYPES.SMS}
                >
                    Select All
                </Button>
                <Button onClick={handleDeselectAll} sx={{ textTransform: 'none' }}>
                    Deselect All
                </Button>
            </Box>
            {contactData?.map((contact) => {
                const contactValue = contact[CONTACT_MEDIUMS[contact.contactMedium].label];
                const optOut = contact.status === CONTACT_STATUS_MAP.OPT_OUT;
                const { isCoolingDown, policyViolationReason, isPrimary } = contact;
                const isDisabled = optOut || isCoolingDown;
                return (
                    <MenuItem
                        key={`${contact.contactId}-menu-item`}
                        aria-label={`${contact.contactId}-menu-item`}
                        value={contactValue}
                        selected={checkedOptions[contactValue]}
                        data-disabled={isDisabled}
                        onClick={handleOptionClick}
                        data-testid={`${contactValue}-menu-item`}
                    >
                        <Tooltip title={policyViolationReason}>
                            <Box
                                sx={{ display: 'flex', alignItems: 'center' }}
                                data-disabled={isDisabled}
                                onClick={handlePropagation}
                            >
                                {checkedOptions[contactValue] && (
                                    <CheckBoxSelectedIcon color="success" sx={{ mr: 1 }} />
                                )}
                                {!checkedOptions[contactValue] && (
                                    <CheckBoxUnselectedIcon
                                        sx={{
                                            mr: 1,
                                            color: isDisabled ? 'text.disabled' : 'text.primary',
                                        }}
                                    />
                                )}
                                <Typography
                                    sx={{ color: isDisabled ? 'text.disabled' : 'text.primary' }}
                                >
                                    {messageType === MESSAGE_TYPES.SMS
                                        ? formatPhoneNumber(contactValue)
                                        : contactValue}
                                    {optOut && '- (Opted out)'}
                                </Typography>
                                {isPrimary && <StarOutlined color="primary" sx={{ ml: 1 }} />}
                                {isCoolingDown && (
                                    <CooldownIcon
                                        fontSize="small"
                                        sx={{ ml: 1, color: cooldownBlue }}
                                    />
                                )}
                            </Box>
                        </Tooltip>
                    </MenuItem>
                );
            })}
        </Select>
    );
}

ContactDropdown.propTypes = {
    id: PropTypes.string,
    messageType: PropTypes.string,
    contactData: PropTypes.arrayOf(
        PropTypes.shape({
            accessType: PropTypes.string,
            contactId: PropTypes.string,
            contactMedium: PropTypes.string,
            created: PropTypes.string,
            email: PropTypes.string,
            lastUpdated: PropTypes.string,
            phone: PropTypes.string,
            social: PropTypes.shape({
                network: PropTypes.string,
                url: PropTypes.string,
            }),
            source: PropTypes.string,
            status: PropTypes.string,
            type: PropTypes.string,
        })
    ),
};
