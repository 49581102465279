import SvgIcon from '@mui/material/SvgIcon';

export function ThreeDotsHorizontalIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <g id="three-dots-horizontal">
                <g id="Vector">
                    <path
                        d="M8.0005 6.40002C7.12049 6.40002 6.40047 7.12004 6.40047 8.00005C6.40047 8.88006 7.12049 9.60005 8.0005 9.60005C8.88051 9.60005 9.60052 8.88006 9.60052 8.00005C9.60052 7.12004 8.88051 6.40002 8.0005 6.40002Z"
                        fill="currentColor"
                    />
                    <path
                        d="M14.4 6.40002C13.52 6.40002 12.8 7.12004 12.8 8.00005C12.8 8.88006 13.52 9.60005 14.4 9.60005C15.28 9.60005 16 8.88006 16 8.00005C16 7.12004 15.28 6.40002 14.4 6.40002Z"
                        fill="currentColor"
                    />
                    <path
                        d="M1.60003 6.40002C0.720015 6.40002 0 7.12004 0 8.00005C0 8.88006 0.720015 9.60005 1.60003 9.60005C2.48004 9.60005 3.20004 8.88006 3.20004 8.00005C3.20004 7.12004 2.48004 6.40002 1.60003 6.40002Z"
                        fill="currentColor"
                    />
                </g>
            </g>
        </SvgIcon>
    );
}
