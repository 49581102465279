import PropTypes from 'prop-types';
export function RichTextDisplay({ children }) {
    return (
        <span
            dangerouslySetInnerHTML={{
                __html: children,
            }}
        />
    );
}

RichTextDisplay.propTypes = {
    children: PropTypes.string,
};
