import PropTypes from 'prop-types';

import { useActivityContent } from './hooks';
import { TruncatedText } from 'views/Projects/components/TruncatedText';

export function ActivityContent({ activityType, activitySubType, metadata, entityConnections }) {
    const Content = useActivityContent(activityType);

    return (
        <TruncatedText
            text={
                <Content
                    activitySubType={activitySubType}
                    metadata={metadata}
                    entityConnections={entityConnections}
                />
            }
            variant="body"
        />
    );
}

ActivityContent.propTypes = {
    activityType: PropTypes.string,
    activitySubType: PropTypes.string,
    metadata: PropTypes.object,
    entityConnections: PropTypes.array,
};
