import SvgIcon from '@mui/material/SvgIcon';

export function JobTitleIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.07174 5.53032C4.43829 5.16377 4.93544 4.95785 5.45381 4.95785C5.97219 4.95785 6.46934 5.16377 6.83589 5.53032C7.20243 5.89687 7.40836 6.39401 7.40836 6.91239C7.40836 7.41796 7.21248 7.90333 6.86276 8.26706C7.03824 8.37539 7.202 8.50446 7.3502 8.65266C7.85314 9.1556 8.13569 9.83773 8.13569 10.549C8.13569 10.8251 7.91183 11.049 7.63569 11.049C7.35955 11.049 7.13569 10.8251 7.13569 10.549C7.13569 10.1029 6.9585 9.67517 6.6431 9.35977C6.3277 9.04436 5.89992 8.86717 5.45387 8.86717C5.00783 8.86717 4.58005 9.04436 4.26465 9.35977C3.94925 9.67517 3.77205 10.1029 3.77205 10.549C3.77205 10.8251 3.5482 11.049 3.27205 11.049C2.99591 11.049 2.77205 10.8251 2.77205 10.549C2.77205 9.83773 3.0546 9.1556 3.55754 8.65266C3.70572 8.50448 3.86946 8.37543 4.04491 8.2671C3.69516 7.90338 3.49927 7.41798 3.49927 6.91239C3.49927 6.39401 3.70519 5.89687 4.07174 5.53032ZM5.45381 5.95785C5.20065 5.95785 4.95786 6.05841 4.77885 6.23743C4.59984 6.41644 4.49927 6.65923 4.49927 6.91239C4.49927 7.16555 4.59984 7.40834 4.77885 7.58736C4.95786 7.76637 5.20065 7.86694 5.45381 7.86694C5.70697 7.86694 5.94977 7.76637 6.12878 7.58736C6.30779 7.40834 6.40836 7.16555 6.40836 6.91239C6.40836 6.65923 6.30779 6.41644 6.12878 6.23743C5.94977 6.05841 5.70697 5.95785 5.45381 5.95785Z"
                fill="currentColor"
                fillOpacity="0.72"
            />
            <path
                d="M9.45387 6.04899C9.17773 6.04899 8.95387 6.27285 8.95387 6.54899C8.95387 6.82513 9.17773 7.04899 9.45387 7.04899H12.363C12.6391 7.04899 12.863 6.82513 12.863 6.54899C12.863 6.27285 12.6391 6.04899 12.363 6.04899H9.45387Z"
                fill="currentColor"
                fillOpacity="0.72"
            />
            <path
                d="M10.1811 8.95808C9.905 8.95808 9.68114 9.18194 9.68114 9.45808C9.68114 9.73422 9.905 9.95808 10.1811 9.95808H12.363C12.6391 9.95808 12.863 9.73422 12.863 9.45808C12.863 9.18194 12.6391 8.95808 12.363 8.95808H10.1811Z"
                fill="currentColor"
                fillOpacity="0.72"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.226562 2.91233C0.226562 2.23453 0.776031 1.68506 1.45384 1.68506H14.5447C15.2225 1.68506 15.772 2.23453 15.772 2.91233V13.0942C15.772 13.772 15.2225 14.3214 14.5447 14.3214H1.45384C0.776031 14.3214 0.226562 13.772 0.226562 13.0942V2.91233ZM1.45384 2.68506C1.32832 2.68506 1.22656 2.78681 1.22656 2.91233V13.0942C1.22656 13.2197 1.32832 13.3214 1.45384 13.3214H14.5447C14.6703 13.3214 14.772 13.2197 14.772 13.0942V2.91233C14.772 2.78681 14.6703 2.68506 14.5447 2.68506H1.45384Z"
                fill="currentColor"
                fillOpacity="0.72"
            />
        </SvgIcon>
    );
}
