import { useSelector } from 'react-redux';
import { useGetRmmMetricsQuery } from 'services/jobDescription';

export const useRmmTelemetry = () => {
    const { isUsingRmmMessage } = useSelector((state) => state.messageTemplate);
    const { data } = useGetRmmMetricsQuery();
    return {
        showTemplateOverlay: (isUsingRmmMessage && data?.totalResults === 0) || false,
    };
};
