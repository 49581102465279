import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { Typography } from 'components/Typography';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    zIndex: 1401,
    '& .MuiDialog-paper': {
        padding: theme.spacing(4, 4, 2),
        width: theme.spacing(50),
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    width: '100%',
    textTransform: 'none',
    height: theme.spacing(5),
    margin: theme.spacing(0.5),
}));

export function ConfirmReplacementDialog(props) {
    const { isOpen, onClose, onConfirm } = props;

    return (
        <StyledDialog open={isOpen} onClose={onClose}>
            <Box>
                <Box sx={{ display: 'flex', mb: 3 }}>
                    <Typography variant="titleMedium" sx={{ textAlign: 'center' }}>
                        This will replace your existing file and cannot be undone. Do you want to
                        continue?
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        mb: 1.5,
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <StyledButton data-testid="cancel-replacement-dialog" onClick={onClose}>
                        <Typography color="text.primary" variant="titleSmall">
                            Cancel
                        </Typography>
                    </StyledButton>
                    <StyledButton
                        data-testid="confirm-replacement-dialog"
                        variant="contained"
                        onClick={onConfirm}
                    >
                        <Typography variant="titleSmall">Replace Media</Typography>
                    </StyledButton>
                </Box>
            </Box>
        </StyledDialog>
    );
}

ConfirmReplacementDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
};
