function deleteChip(value, values, onUpdate) {
    const index = values.findIndex((entry) => entry === value);
    if (index >= 0) {
        const temp = [...values];
        temp.splice(index, 1);
        onUpdate(temp);
    }
}

export { deleteChip };
