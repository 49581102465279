import { useMemo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { formatDate } from 'utils/formatters';

export function useConversationFilters(pageSize, page, resetPage) {
    const read = useSelector((state) => state.communications.filters.read);
    const unread = useSelector((state) => state.communications.filters.unread);
    const talentId = useSelector((state) => state.communications.filters.talentId);
    const talentIds = useSelector((state) => state.communications.filters.talentIds);
    const startDate = useSelector((state) => state.communications.filters.startDate.value);
    const hasAttachments = useSelector((state) => state.communications.filters.attachments.value);
    const filterAttachments = useSelector(
        (state) => state.communications.filters.attachments.enabled
    );
    const sequenceFilterValue = useSelector(
        (state) => state.communications.filters.sequences.value
    );
    const isSequenceFilterEnabled = useSelector(
        (state) => state.communications.filters.sequences.enabled
    );
    const digitalAssistantFilterValue = useSelector(
        (state) => state.communications.filters.digitalAssistant.value
    );
    const isDigitalAssistantFilterEnabled = useSelector(
        (state) => state.communications.filters.digitalAssistant.enabled
    );

    const endDate = useSelector((state) => state.communications.filters.endDate.value);
    const conversationSearchText = useSelector(
        (state) => state.communications.conversationSearchText
    );

    const [filters, setFilters] = useState({ page: 1, pageSize, conversationsMessage: 1 });

    const filterCount = useMemo(() => {
        return (
            Boolean(read) +
            Boolean(unread) +
            Boolean(talentId) +
            Boolean(talentIds?.length > 0) +
            (startDate && endDate ? 1 : 0) +
            Boolean(filterAttachments)
        );
    }, [read, unread, talentId, talentIds, startDate, endDate, filterAttachments]);

    const updateFilters = useCallback((func) => {
        setFilters((prev) => produce(prev, func));
    }, []);

    const handleResetPage = useCallback(() => {
        resetPage(1);
    }, [resetPage]);

    useEffect(() => {
        updateFilters((draft) => {
            if (!isEmpty(trim(conversationSearchText))) {
                draft.matchText = conversationSearchText;
                draft.page = 1;
                handleResetPage();
            } else delete draft.matchText;
        });
    }, [updateFilters, conversationSearchText]);

    useEffect(() => {
        updateFilters((draft) => {
            if (unread !== read) {
                draft.hasUnread = unread;
                draft.page = 1;
                handleResetPage();
            } else delete draft.hasUnread;
        });
    }, [updateFilters, read, unread, resetPage]);

    useEffect(() => {
        updateFilters((draft) => {
            if (filterAttachments) {
                draft.hasAttachment = hasAttachments;
                draft.page = 1;
                handleResetPage();
            } else delete draft.hasAttachment;
        });
    }, [updateFilters, filterAttachments, hasAttachments]);

    useEffect(() => {
        updateFilters((draft) => {
            if (isSequenceFilterEnabled) {
                draft.sequenceCandidateStatus = sequenceFilterValue;
                draft.page = 1;
                handleResetPage();
            } else {
                delete draft.sequenceCandidateStatus;
            }
        });
    }, [updateFilters, isSequenceFilterEnabled, sequenceFilterValue]);

    useEffect(() => {
        updateFilters((draft) => {
            if (isDigitalAssistantFilterEnabled) {
                draft.talktrackCandidateStatus = digitalAssistantFilterValue;
                draft.page = 1;
                handleResetPage();
            } else {
                delete draft.talktrackCandidateStatus;
            }
        });
    }, [updateFilters, isDigitalAssistantFilterEnabled, digitalAssistantFilterValue, ,]);

    useEffect(() => {
        updateFilters((draft) => {
            if (!isEmpty(talentId)) {
                draft.talent = talentId;
                draft.page = 1;
                handleResetPage();
            } else delete draft.talent;
        });
    }, [updateFilters, talentId]);

    useEffect(() => {
        updateFilters((draft) => {
            if (talentIds?.length > 0) {
                draft.talentIds = talentIds;
                draft.page = 1;
                handleResetPage();
            } else delete draft.talentIds;
        });
    }, [updateFilters, talentIds]);

    useEffect(() => {
        updateFilters((draft) => {
            if (!isEmpty(startDate) && !isEmpty(endDate)) {
                const dateGte = formatDate(startDate, 'yyyy-MM-dd');
                const dateLte = formatDate(endDate, 'yyyy-MM-dd');
                draft.dateRange = [dateGte, dateLte];
                draft.page = 1;
                handleResetPage();
            } else delete draft.dateRange;
        });
    }, [updateFilters, startDate, endDate]);

    useEffect(() => {
        updateFilters((draft) => {
            draft.page = page;
        });
    }, [updateFilters, page]);

    return [filters, filterCount];
}
