import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { CloseIcon, InfoIcon } from 'components/Icons';

import { MESSAGE_TYPES } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useSwitchboard } from 'features/Switchboard';
import { useTemplateSignature } from 'features/Signature';
import { MessageTemplate } from 'features/MessageTemplate';
import { useTemplateButton } from 'features/MessageTemplate/hooks';
import { TemplateButton } from 'features/MessageTemplate/components/TemplateButton';
import { AttachmentChip, AttachmentButton } from 'features/AttachmentUpload/components';
import { DynamicFieldsButton } from 'features/DynamicFields/components/DynamicFieldsButton';
import { InviteLinkButton } from 'components/MessageContent/InviteLinkButton';
import { FormattingButton } from 'components/MessageContent/FormattingButton';
import { SignatureButton } from 'features/Signature/components/SignatureButton';
import { Tooltip } from 'components/Tooltip';
import { useState } from 'react';

import { useNewMediaAttachmentsModal } from 'views/UserSettings/features/YourMedia/components/MediaModals/hooks/useNewMediaAttachmentsModal';
import { EntityConnectionModal } from 'views/UserSettings/features/YourMedia/components/MediaModals/EntityConnectionModal';
import { ENTITY_TYPE_ENUMS } from 'utils/entityEnums';
import { defaultTooltips, getTooltipProps } from 'features/MessageDrawer/utils/tooltipHelpers';
import {
    ToolbarButtonsWrapper,
    ToolbarContainer,
} from 'features/MessageDrawer/components/MessageContent/MessageComposeComponents/MessageToolbar';

export function MessageFooter(props) {
    const {
        messageType,
        innerHtml,
        subject,
        openInNewTab,
        onUseTemplate,
        attachments,
        setAttachments,
        setClearInput,
        onUseSignature,
        isFormatting,
        isToolbarDisabled,
        isCollapsed,
        onToggleFormatting,
        onAttachmentDelete,
    } = props;

    const {
        messageTemplateOpen,
        isCreateTemplateDisabled,
        isFetchingTemplates,
        handleOpenTemplate,
        handleCloseTemplate,
        handleTemplateListsView,
        handleTemplateCreateView,
        currentTemplateView,
        templates,
    } = useTemplateButton();

    const isDynamicFieldsEnabled = useSwitchboard('FEATURE_DYNAMIC_FIELDS');
    const isMessageTemplateEnabled = useSwitchboard('FEATURE_MESSAGE_TEMPLATES');

    const [tooltipsState, setTooltipsState] = useState(defaultTooltips);

    const {
        attachments: selectedAttachments,
        setAttachments: setSelectedAttachments,
        isModalOpen,
        handleOpen,
        handleClose,
        handleConfirm,
        handleOpenPicker,
        clearAttachments,
        handleRemoveAttachment,
    } = useNewMediaAttachmentsModal({ messageType });

    const { signatureData, isFetchingData, handleRefreshSignature } = useTemplateSignature(
        messageType !== MESSAGE_TYPES.EMAIL
    );

    const onConfirm = (mediaIds, displayNames) => {
        setAttachments(handleConfirm(mediaIds, displayNames));
    };

    return (
        <>
            {messageType === MESSAGE_TYPES.SMS && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <InfoIcon sx={{ fontSize: 20, color: 'primary.main' }} />
                    <Typography variant="labelXSmall" sx={{ color: 'text.secondary' }}>
                        SMS Messages have a 1600 character limit
                    </Typography>
                </Box>
            )}
            <Box sx={{ display: 'flex', mt: 1, overflow: 'scroll' }}>
                {attachments?.map?.((attachment) => (
                    <AttachmentChip
                        key={attachment.handle || attachment.mediaId}
                        label={attachment.filename || attachment.name}
                        title={attachment.filename || attachment.name}
                        disabled={attachment.isDeleting}
                        onDelete={() => onAttachmentDelete(attachment.handle || attachment.mediaId)}
                        deleteIcon={<CloseIcon aria-label="remove-attachment" />}
                    />
                ))}
            </Box>
            <>
                <ToolbarButtonsWrapper>
                    <ToolbarContainer>
                        {!isToolbarDisabled && (
                            <Tooltip
                                {...getTooltipProps(
                                    tooltipsState,
                                    'formatting-options',
                                    setTooltipsState
                                )}
                            >
                                <span>
                                    <FormattingButton
                                        isCollapsed={isCollapsed}
                                        isFormatting={isFormatting}
                                        onToggleFormatting={onToggleFormatting}
                                        data-trackid="message-options-formatting"
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {isDynamicFieldsEnabled && (
                            <Tooltip
                                {...getTooltipProps(
                                    tooltipsState,
                                    'dynamic-fields-tooltip',
                                    setTooltipsState
                                )}
                            >
                                <span>
                                    <DynamicFieldsButton data-trackid="message-options-dynamic-variables" />
                                </span>
                            </Tooltip>
                        )}

                        {Boolean(attachments) && (
                            <Tooltip
                                {...getTooltipProps(
                                    tooltipsState,
                                    'attachments-tooltip',
                                    setTooltipsState
                                )}
                            >
                                <span>
                                    <AttachmentButton
                                        onClick={handleOpen}
                                        aria-label="add-attachment-to-message"
                                        data-trackid="message-options-attachments"
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {messageType === MESSAGE_TYPES.EMAIL &&
                            typeof onUseSignature === 'function' && (
                                <Tooltip
                                    {...getTooltipProps(
                                        tooltipsState,
                                        'signatures-tooltip',
                                        setTooltipsState
                                    )}
                                >
                                    <span>
                                        <SignatureButton
                                            signatures={signatureData}
                                            isFetching={isFetchingData}
                                            onUseSignature={onUseSignature}
                                            onRefreshSignature={handleRefreshSignature}
                                            data-trackid="message-options-signature"
                                        />
                                    </span>
                                </Tooltip>
                            )}

                        {isMessageTemplateEnabled && (
                            <Tooltip
                                {...getTooltipProps(
                                    tooltipsState,
                                    'templates-tooltip',
                                    setTooltipsState
                                )}
                            >
                                <span>
                                    <TemplateButton
                                        initialHtml={innerHtml}
                                        initialSubject={subject}
                                        onSaveNewTemplate={handleOpenTemplate}
                                        isCreateTemplateDisabled={isCreateTemplateDisabled}
                                        isFetchingTemplates={isFetchingTemplates}
                                        onTemplateCreateView={handleTemplateCreateView}
                                        onTemplateListsView={handleTemplateListsView}
                                        onUseTemplate={onUseTemplate}
                                        templates={templates}
                                        messageType={messageType}
                                        data-trackid="message-options-message-templates"
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </ToolbarContainer>
                    <Tooltip
                        {...getTooltipProps(tooltipsState, 'invitation-tooltip', setTooltipsState)}
                    >
                        <span>
                            <InviteLinkButton
                                messageType={messageType}
                                openInNewTab={openInNewTab}
                                data-trackid="message-options-add-invite"
                            />
                        </span>
                    </Tooltip>
                </ToolbarButtonsWrapper>
                <MessageTemplate
                    open={messageTemplateOpen}
                    onClose={handleCloseTemplate}
                    templates={templates}
                    currentTemplateView={currentTemplateView}
                    isCreateTemplateDisabled={isCreateTemplateDisabled}
                    isFetchingTemplates={isFetchingTemplates}
                    onTemplateCreateView={handleTemplateCreateView}
                    onUseTemplate={onUseTemplate}
                    onTemplateListsView={handleTemplateListsView}
                    messageType={messageType}
                    setClearInput={setClearInput}
                />
            </>
            <EntityConnectionModal
                initialAttachments={attachments}
                entityType={ENTITY_TYPE_ENUMS.CONVERSATION.label}
                isOpen={isModalOpen}
                onClose={handleClose}
                onUploadNewMedia={(currentFilesCount) => {
                    handleClose();
                    handleOpenPicker(currentFilesCount);
                }}
                attachments={selectedAttachments}
                clearAttachments={clearAttachments}
                onRemoveAttachment={handleRemoveAttachment}
                onReplaceAttachment={() => {
                    handleClose();
                    handleOpenPicker();
                    clearAttachments();
                }}
                setAttachments={setSelectedAttachments}
                onConfirm={onConfirm}
                confirmText="Attach media to message"
                messageType={messageType}
            />
        </>
    );
}

MessageFooter.propTypes = {
    messageType: PropTypes.string,
    subject: PropTypes.string,
    innerText: PropTypes.string,
    innerHtml: PropTypes.string,
    openInNewTab: PropTypes.bool,
    onUseTemplate: PropTypes.func,
    attachments: PropTypes.array,
    setAttachments: PropTypes.func,
    onAttachmentDelete: PropTypes.func,
    setClearInput: PropTypes.func,
    onUseSignature: PropTypes.func,
    isFormatting: PropTypes.bool,
    isToolbarDisabled: PropTypes.bool,
    onToggleFormatting: PropTypes.func,
    isEditMode: PropTypes.bool,
    isCollapsed: PropTypes.bool,
};
