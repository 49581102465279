import SvgIcon from '@mui/material/SvgIcon';

export function ArrowCircleRight(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM10.5 18C6.09 18 2.5 14.41 2.5 10C2.5 5.59 6.09 2 10.5 2C14.91 2 18.5 5.59 18.5 10C18.5 14.41 14.91 18 10.5 18Z"
                fill="currentColor"
            />
            <path
                d="M15.4 9.58955C15.34 9.43955 15.26 9.28951 15.14 9.17951L12.2 6.2895C11.81 5.8995 11.17 5.90951 10.79 6.29951C10.4 6.68951 10.41 7.32954 10.8 7.70954L12.11 8.99952H6.5C5.95 8.99952 5.5 9.44952 5.5 9.99952C5.5 10.5495 5.95 10.9995 6.5 10.9995H12.11L10.8 12.2895C10.41 12.6795 10.4 13.3095 10.79 13.6995C10.99 13.8995 11.24 13.9995 11.5 13.9995C11.76 13.9995 12.01 13.8995 12.2 13.7095L15.14 10.8195C15.26 10.6995 15.34 10.5595 15.4 10.4095C15.46 10.2795 15.49 10.1495 15.49 9.99952C15.49 9.84952 15.46 9.71955 15.4 9.58955Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
