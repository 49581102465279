import { toast } from 'react-toastify';
import { useCallback, useMemo } from 'react';

import { useZeroCreditPromptStore } from '../store';
import { useSendCreditRequestMutation } from 'services/systemEmails';
import { useUpgradeAccountPrompt } from 'components/Dialog';

export function useZeroCreditPrompt() {
    const isOpen = useZeroCreditPromptStore((state) => state.isOpen);
    const setOpen = useZeroCreditPromptStore((state) => state.setOpen);
    const setClosed = useZeroCreditPromptStore((state) => state.setClosed);
    const isLoading = useZeroCreditPromptStore((state) => state.isLoading);
    const creditType = useZeroCreditPromptStore((state) => state.creditType);
    const setIsLoading = useZeroCreditPromptStore((state) => state.setIsLoading);

    const { openModal } = useUpgradeAccountPrompt();

    const [sendCreditRequest] = useSendCreditRequestMutation();

    const requestCredits = useCallback(async () => {
        setIsLoading(true);
        const { error } = await sendCreditRequest(creditType);
        if (!error) {
            setClosed();
            openModal('success');
        }
        if (error) {
            toast.error(
                'There was an error sending your request. Please try again in a few minutes.'
            );
        }
        setIsLoading(false);
    }, [creditType, sendCreditRequest, setClosed, setIsLoading, openModal]);

    return useMemo(
        () => ({
            isLoading,
            creditType,
            requestCredits,
            isZeroCreditPromptOpen: isOpen,
            setZeroCreditPromptOpen: setOpen,
            setZeroCreditPromptClosed: setClosed,
        }),
        [isLoading, creditType, isOpen, setOpen, setClosed, requestCredits]
    );
}
