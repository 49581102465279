import SvgIcon from '@mui/material/SvgIcon';

export function MinusCircleIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M16.5312 3.2C23.5873 3.2 29.3313 8.944 29.3313 16C29.3313 23.056 23.5873 28.8 16.5312 28.8C9.47525 28.8 3.73125 23.056 3.73125 16C3.73125 8.944 9.47525 3.2 16.5312 3.2ZM16.5312 0C7.69925 0 0.53125 7.168 0.53125 16C0.53125 24.832 7.69925 32 16.5312 32C25.3633 32 32.5312 24.832 32.5312 16C32.5312 7.168 25.3633 0 16.5312 0Z"
                fill="currentColor"
            />
            <path
                d="M21.3313 17.6H11.7313C10.8513 17.6 10.1313 16.88 10.1313 16C10.1313 15.12 10.8513 14.4 11.7313 14.4H21.3313C22.2113 14.4 22.9313 15.12 22.9313 16C22.9313 16.88 22.2113 17.6 21.3313 17.6Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
