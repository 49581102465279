import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { CloseIcon } from 'components/Icons';
import { AttachmentChip } from 'features/AttachmentUpload/components';

export function AttachmentList(props) {
    const { attachments, handleRemoveMessageAttachment } = props;
    return (
        <Box sx={{ display: 'flex', mt: 1, overflow: 'scroll' }}>
            {attachments?.map?.((attachment) => (
                <AttachmentChip
                    key={attachment.handle || attachment.mediaId}
                    label={attachment.filename || attachment.name}
                    title={attachment.filename || attachment.name}
                    disabled={attachment.isDeleting}
                    onDelete={() =>
                        handleRemoveMessageAttachment(attachment.handle || attachment.mediaId)
                    }
                    deleteIcon={<CloseIcon aria-label="remove-attachment" />}
                />
            ))}
        </Box>
    );
}

AttachmentList.propTypes = {
    attachments: PropTypes.array,
    handleRemoveMessageAttachment: PropTypes.func,
};
