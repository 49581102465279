import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

export function InsertInvitationIcon(props) {
    const { gradient, ...restProps } = props;
    const fillOne = gradient ? 'url(#paint0_linear_13870_18129)' : 'currentColor';
    const fillTwo = gradient ? 'url(#paint1_linear_13870_18129)' : 'currentColor';
    return (
        <SvgIcon viewBox="0 0 16 16" {...restProps}>
            <path
                d="M11.3333 2.66665V1.99998C11.3333 1.63331 11.0333 1.33331 10.6667 1.33331C10.3 1.33331 10 1.63331 10 1.99998V2.66665H6V1.99998C6 1.63331 5.7 1.33331 5.33333 1.33331C4.96667 1.33331 4.66667 1.63331 4.66667 1.99998V2.66665C3.19333 2.66665 2 3.85998 2 5.33331V11.3333C2 12.8066 3.19333 14 4.66667 14H11.3333C12.8067 14 14 12.8066 14 11.3333V5.33331C14 3.85998 12.8067 2.66665 11.3333 2.66665ZM12.6667 11.3333C12.6667 12.0666 12.0667 12.6666 11.3333 12.6666H4.66667C3.93333 12.6666 3.33333 12.0666 3.33333 11.3333V5.33331C3.33333 4.59998 3.93333 3.99998 4.66667 3.99998V4.66665C4.66667 5.03331 4.96667 5.33331 5.33333 5.33331C5.7 5.33331 6 5.03331 6 4.66665V3.99998H10V4.66665C10 5.03331 10.3 5.33331 10.6667 5.33331C11.0333 5.33331 11.3333 5.03331 11.3333 4.66665V3.99998C12.0667 3.99998 12.6667 4.59998 12.6667 5.33331V11.3333Z"
                fill={fillOne}
            />
            <path
                d="M10 8H8.66003V6.66667C8.66003 6.3 8.36003 6 7.99337 6C7.6267 6 7.3267 6.3 7.3267 6.66667V8H6.00004C5.63337 8 5.33337 8.3 5.33337 8.66667C5.33337 9.03333 5.63337 9.33333 6.00004 9.33333H7.3267V10.6667C7.3267 11.0333 7.6267 11.3333 7.99337 11.3333C8.36003 11.3333 8.66003 11.0333 8.66003 10.6667V9.33333H10C10.3667 9.33333 10.6667 9.03333 10.6667 8.66667C10.6667 8.3 10.3667 8 10 8Z"
                fill={fillTwo}
            />
            <defs>
                <linearGradient
                    id="paint0_linear_13870_18129"
                    x1="2"
                    y1="5.88261"
                    x2="14.003"
                    y2="5.9373"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_13870_18129"
                    x1="5.33337"
                    y1="7.91549"
                    x2="10.668"
                    y2="7.94115"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

InsertInvitationIcon.propTypes = {
    gradient: PropTypes.bool,
};
