import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { Typography } from 'components/Typography';
import { alpha, styled } from '@mui/material/styles';

import { Tooltip } from 'components/Tooltip';
import { ChevronDownIcon, SkillsIcon } from 'components/Icons';
import { AddToExcludeFilterButton, AddToFilterButton } from './AddToFilterButton';

const ChevronIcon = styled(ChevronDownIcon, {
    shouldForwardProp: (prop) => prop !== 'isExpanded',
})(({ theme, isExpanded }) => ({
    color: theme.palette.background.default,
    height: 12,
    width: 12,
    marginTop: theme.spacing(0.4),
    transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
    }),
    transform: `rotate(${isExpanded ? 180 : 0}deg)`,
}));

const ListItemIconWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    marginLeft: 'auto',
}));

const ListItemContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: theme.spacing(0.2, 1),
}));

export function CertificationPopover(props) {
    const {
        onToggleOpen,
        isExpanded,
        onExpand,
        certification,
        onAddCertificationFilter,
        onAddCertificationExcludeFilter,
        isSearch,
    } = props;

    return (
        <Paper
            sx={{ background: 'transparent', color: 'background.default', boxShadow: 'none' }}
            onMouseEnter={onToggleOpen}
            onMouseLeave={onToggleOpen}
            data-testid="certification-popover"
        >
            <Tooltip
                placement="top"
                open={Boolean(!isExpanded && certification?.match)}
                title="This matches a certification found in your search"
                arrow
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: 1,
                        mb: '5px',
                        cursor: isSearch && 'pointer',
                    }}
                    {...(isSearch
                        ? {
                              role: 'button',
                              onClick: onExpand,
                              'aria-label': 'expand-certification-options',
                              'data-trackid': 'talent-certification-open',
                          }
                        : {})}
                >
                    <SkillsIcon sx={{ fontSize: 12, mb: -0.5 }} />
                    <Typography
                        variant="bodySmall"
                        sx={{
                            mr: isSearch && 1,
                            fontWeight: certification?.match ? '900' : '400',
                        }}
                    >
                        {certification.name}
                    </Typography>
                    {isSearch && <ChevronIcon isExpanded={isExpanded} />}
                </Box>
            </Tooltip>

            {isSearch && <Divider sx={{ borderColor: alpha('#fff', 0.48) }} />}

            {isExpanded && isSearch && (
                <List sx={{ p: 0 }}>
                    <>
                        <ListItem sx={{ p: 0 }}>
                            <ListItemContentWrapper>
                                <SkillsIcon sx={{ fontSize: 12 }} />
                                <Typography variant="bodySmall" sx={{ mr: 2, mt: -0.5 }}>
                                    Add as Certification Filter
                                </Typography>
                                <ListItemIconWrapper>
                                    <AddToFilterButton
                                        data-trackid="talent-certification-filter-include"
                                        aria-label="add-to-certification-filter"
                                        onClick={() => onAddCertificationFilter(certification.name)}
                                    />
                                    <AddToExcludeFilterButton
                                        data-trackid="talent-certification-filter-exclude"
                                        aria-label="add-to-exclude-certification-filter"
                                        onClick={() =>
                                            onAddCertificationExcludeFilter(certification.name)
                                        }
                                    />
                                </ListItemIconWrapper>
                            </ListItemContentWrapper>
                        </ListItem>
                        <Divider sx={{ borderColor: alpha('#fff', 0.48) }} />
                    </>
                </List>
            )}
        </Paper>
    );
}

CertificationPopover.propTypes = {
    isExpanded: PropTypes.bool,
    onExpand: PropTypes.func,
    isSearch: PropTypes.bool,
    onToggleOpen: PropTypes.func,
    certification: PropTypes.object,
    onAddCertificationFilter: PropTypes.func,
    onAddCertificationExcludeFilter: PropTypes.func,
};
