import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';

export function ProtectedRoute(props) {
    const { children, isLoading = false, isAllowed, redirectPath = '/' } = props;

    if (!isLoading && !isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
}

ProtectedRoute.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    redirectPath: PropTypes.string,
    isLoading: PropTypes.bool,
    isAllowed: PropTypes.bool,
};
