import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import debounce from 'lodash/debounce';

import { Pagination } from 'components/Pagination';
import { Sidebar } from 'components/Sidebar';
import { Conversations } from '../features/Inbox';
import { FilterIcon, InboxIcon, RotatingChevronIcon } from 'components/Icons';
import { Typography, TYPOGRAPHY_VARIANTS } from 'components/Typography';
import { useLazyGetTalentConversationsQuery } from 'services/conversations';
import { useLazyGetManyTalentsProfilesQuery } from 'services/talent';
import {
    clearFilters,
    changeConversationSearchText,
    clearConversationSearchText,
} from 'views/Communications';
import { pluralize } from 'utils/copy';
import { TextInput } from 'components/Inputs';
import { useConversationFilters } from 'hooks/useConversationFilters';

import { FilterReadStatus, FilterDateRange, FilterAttachments } from './components';
import { useLocation } from 'react-router-dom';
import { FilterSequences } from './components/FilterSequences';
import { FilterDigitalAssistant } from './components/FilterDigitalAssistant';

const PAGE_SIZE = 6;

const ClearButton = styled(Button)(({ theme }) => ({
    '&.MuiButton-root': {
        ...TYPOGRAPHY_VARIANTS.bodySmall,
        color: theme.palette.common.white,
        textTransform: 'none',
        marginRight: theme.spacing(1),
    },
}));

const FilterWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.text.primary,
    padding: theme.spacing(5, 3, 1, 3),
}));

const FilterMenu = styled(Box)(() => ({
    position: 'relative',
}));

const ExtraFilters = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #263746;',
    borderRadius: `${theme.spacing(0.5)} ${theme.spacing(0.5)} ${theme.spacing(
        0.5
    )} ${theme.spacing(0.5)}`,
    paddingBottom: theme.spacing(3),
}));

const FilterIconWrapper = styled(Box)(({ theme }) => ({
    color: theme.palette.common.white,
    width: theme.spacing(5.5),
    background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #263746;',
    '& fieldset': { border: 'none' },
    borderRadius: `${theme.spacing(0.5)} 0 0 ${theme.spacing(0.5)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '8px',
}));

const FilterCountIndicator = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: theme.palette.primary.main,
        height: theme.spacing(0.5),
        minWidth: theme.spacing(0.5),
    },
    marginRight: theme.spacing(0.5),
}));

const IconButtonSx = {
    '&.Mui-disabled': {
        color: 'transparent',
    },
};

export function FilterBar() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [talents, setTalents] = useState({});
    const [showExtraFilters, setShowExtraFilters] = useState(false);

    const { pathname } = useLocation();

    const shouldRefreshMessages = useSelector(
        (state) => state.communications.shouldRefreshMessages
    );
    const shouldRefreshConversations = useSelector(
        (state) => state.communications.shouldRefreshConversations
    );

    const conversationsPage = useSelector((state) => state.communications.conversationsPage);

    const [filters, filterCount] = useConversationFilters(PAGE_SIZE, page, setPage);

    const [getConversations, { isError, isSuccess, isLoading, isFetching, data }] =
        useLazyGetTalentConversationsQuery(false);

    const resultCount = data?.results?.length || 0;
    const isFirstPageAndNoResults = page === 1 && data?.results?.length === 0;

    const [
        getManyTalentsProfiles,
        { isSuccess: isTalentLookupSuccessful, isFetching: isFetchingTalents, data: talentData },
    ] = useLazyGetManyTalentsProfilesQuery();

    const handleChangeConversationSearchText = useMemo(
        () => debounce((event) => dispatch(changeConversationSearchText(event.target.value)), 1000),
        [dispatch]
    );

    const handleChangePage = useCallback((nextPage) => {
        setPage(nextPage);
    }, []);

    const fetchConversations = useCallback(() => {
        getConversations({ page, pageSize: PAGE_SIZE, conversationsMessage: 1 });
    }, [getConversations, page]);

    const handleRetry = useCallback(() => {
        fetchConversations();
    }, [fetchConversations]);

    const handleClearFilter = useCallback(() => {
        dispatch(clearFilters());
        handleChangePage(1);
    }, [dispatch, handleChangePage]);

    const handleToggleExtraFilters = useCallback((e) => {
        if (e.target.tagName !== 'BODY') {
            //https://github.com/mui/material-ui/issues/25578
            setShowExtraFilters((prev) => !prev);
        }
    }, []);

    const refreshConversations = useCallback(
        (page = 0) => {
            // When refreshing, sets page to 1 w/ invalidated tags, will show most recent Conversation at top
            if (shouldRefreshMessages || page) {
                handleChangePage(1);
            }
            //if more props than page and pageSize set, initiate a filtered request
            if (Object.entries(filters).length > 2) {
                getConversations(filters);
            } else {
                fetchConversations();
            }
        },
        [shouldRefreshMessages, filters, handleChangePage, getConversations, fetchConversations]
    );

    useEffect(() => {
        refreshConversations();
    }, [refreshConversations]);

    useEffect(() => {
        if (conversationsPage) {
            refreshConversations(conversationsPage);
        }
    }, [refreshConversations, conversationsPage]);

    useEffect(() => {
        if (shouldRefreshConversations) {
            refreshConversations();
        }
    }, [refreshConversations, shouldRefreshConversations]);

    useEffect(() => {
        if (isSuccess && Boolean(resultCount)) {
            const talentsToQuery = data.results.map((result) => result?.talent?.talentId);
            getManyTalentsProfiles({
                talentIds: talentsToQuery,
                params: {
                    sortByCompletenessScore: 'desc',
                    sortByLastUpdated: 'desc',
                },
            });
        }
    }, [isSuccess, data, getManyTalentsProfiles, resultCount]);

    useEffect(() => {
        if (isTalentLookupSuccessful && talentData) {
            setTalents(
                Object.fromEntries(
                    Object.entries(talentData.results).map(([talentId, profiles]) => [
                        talentId,
                        profiles[0],
                    ])
                )
            );
        }
    }, [isTalentLookupSuccessful, talentData]);

    useEffect(() => {
        return () => {
            handleClearFilter();
            dispatch(clearConversationSearchText());
        };
    }, [handleClearFilter, dispatch]);

    if (pathname.includes('drilldown')) {
        return null;
    }

    return (
        <Sidebar disableGutters customBreakpoint={1381}>
            <FilterWrapper>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            ml: 1,
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                color: 'common.white',
                                cursor: 'pointer',
                            }}
                        >
                            <InboxIcon sx={{ mr: 1 }} />
                            <Typography variant="smallHeadline">Inbox</Typography>
                        </Box>
                    </Box>
                    <FilterMenu>
                        <Box sx={{ display: 'flex', mt: 1.5 }}>
                            <FilterIconWrapper
                                onClick={handleToggleExtraFilters}
                                data-testid="filter-icon"
                                data-trackid="conversations-nav-filter"
                            >
                                <FilterCountIndicator
                                    variant="dot"
                                    invisible={!Boolean(filterCount)}
                                    aria-label="filter count indicator"
                                >
                                    <FilterIcon />
                                </FilterCountIndicator>
                                <RotatingChevronIcon
                                    isOpen={showExtraFilters}
                                    sx={{ height: '20px', width: '20px', color: 'common.white' }}
                                />
                            </FilterIconWrapper>

                            <TextInput
                                fullWidth
                                placeholder="Search Inbox"
                                onChange={handleChangeConversationSearchText}
                                id="search-inbox-input"
                                inputProps={{
                                    'data-trackid': 'conversations-nav-search-input',
                                }}
                                InputProps={{
                                    sx: {
                                        '&.MuiInputBase-root': {
                                            color: 'common.white',
                                            backgroundColor: '#19222F',
                                            borderRadius: (theme) =>
                                                `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} 0`,
                                        },
                                    },
                                }}
                            />
                        </Box>

                        {showExtraFilters && (
                            <ClickAwayListener onClickAway={handleToggleExtraFilters}>
                                <ExtraFilters>
                                    <Box
                                        sx={{
                                            color: 'common.white',
                                            display: 'flex',
                                            my: 1,
                                            ml: 1.5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography aria-label="filter count" variant="bodySmall">
                                            {filterCount} {pluralize('Filter', filterCount)}
                                        </Typography>
                                        {filterCount > 0 ? (
                                            <ClearButton
                                                variant="text"
                                                onClick={handleClearFilter}
                                                aria-label="Clear filters"
                                                disableRipple
                                                disableFocusRipple
                                            >
                                                <Typography variant="bodySmall" color="primary">
                                                    Clear
                                                </Typography>
                                            </ClearButton>
                                        ) : null}
                                    </Box>
                                    {/* // TODO: uncomment when FilterCandidateName is re-implemented */}
                                    {/* <FilterCandidateName
                                        talents={talents}
                                        isLoading={isFetchingTalents}
                                    /> */}
                                    <FilterDateRange />
                                    <FilterReadStatus />
                                    <FilterAttachments />
                                    <FilterSequences />
                                    <FilterDigitalAssistant />
                                </ExtraFilters>
                            </ClickAwayListener>
                        )}
                    </FilterMenu>
                    {filterCount > 0 && (
                        <Box
                            sx={{
                                color: 'common.white',
                                display: 'flex',
                                my: 1,
                                ml: 1.5,
                                mr: 'auto',
                                alignItems: 'center',
                            }}
                        >
                            <Typography aria-label="filter count" variant="bodySmall">
                                {filterCount} {pluralize('Filter', filterCount)}
                            </Typography>
                            <ClearButton
                                variant="text"
                                onClick={handleClearFilter}
                                aria-label="Clear filters"
                                disableRipple
                                disableFocusRipple
                            >
                                <Typography variant="bodySmall" color="primary">
                                    Clear
                                </Typography>
                            </ClearButton>
                        </Box>
                    )}
                </Box>
            </FilterWrapper>

            {!isLoading && !isError && data?.results && !isFirstPageAndNoResults && (
                <Box
                    sx={{
                        py: 1,
                        mr: 1,
                        display: 'flex',
                        alignItems: 'center',
                        color: 'common.white',
                        justifyContent: 'end',
                    }}
                    data-testid="pagination"
                >
                    <Pagination
                        page={page}
                        pageLength={PAGE_SIZE}
                        total={
                            resultCount === PAGE_SIZE
                                ? resultCount * page
                                : PAGE_SIZE * page - PAGE_SIZE + resultCount
                        }
                        onChangePage={handleChangePage}
                        IconButtonSx={IconButtonSx}
                        prevTrackId="conversations-nav-previous-page"
                        nextTrackId="conversations-nav-next-page"
                        disableNextPageButton={resultCount < PAGE_SIZE || isFetching}
                        disablePrevPageButton={isFetching || page === 1}
                        totalResultBypassMode
                    />
                </Box>
            )}

            <Conversations
                isLoading={isLoading}
                isSuccess={isSuccess}
                conversations={data?.results}
                talents={talents}
                isTalentsLoading={isFetchingTalents}
            />

            {!isFetching && isError && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mx: 'auto' }}>
                    <Typography
                        role="button"
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: 'common.white',
                        }}
                        onClick={handleRetry}
                        aria-label="Click to retry"
                    >
                        An error occurred. Click to retry
                    </Typography>
                </Box>
            )}

            {!isFetching && !isError && !data?.results?.length && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mx: 'auto' }}>
                    <Typography color="common.white">No conversations</Typography>
                </Box>
            )}
        </Sidebar>
    );
}
