import SvgIcon from '@mui/material/SvgIcon';

export function CheckOutlineIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M16.5 3.91973C23.556 3.91973 29.3 9.66373 29.3 16.7197C29.3 23.7757 23.556 29.5197 16.5 29.5197C9.444 29.5197 3.7 23.7757 3.7 16.7197C3.7 9.66373 9.444 3.91973 16.5 3.91973ZM16.5 0.719727C7.668 0.719727 0.5 7.88773 0.5 16.7197C0.5 25.5517 7.668 32.7197 16.5 32.7197C25.332 32.7197 32.5 25.5517 32.5 16.7197C32.5 7.88773 25.332 0.719727 16.5 0.719727Z"
                fill="currentColor"
            />
            <path
                d="M14.9962 21.5038C14.3722 21.5038 13.7802 21.2478 13.3482 20.7998L10.6442 17.9518C10.0362 17.3118 10.0682 16.3038 10.7082 15.6958C11.3482 15.0878 12.3562 15.1198 12.9642 15.7598L14.9801 17.8878L20.0202 12.4638C20.6282 11.8078 21.6361 11.7758 22.2761 12.3838C22.9161 12.9918 22.9642 13.9998 22.3562 14.6398L16.6442 20.7998C16.2122 21.2638 15.6202 21.5198 14.9962 21.5198V21.5038Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
