import SvgIcon from '@mui/material/SvgIcon';

export function DownloadIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M15.0741 24.9853C15.2983 25.2095 15.5706 25.3536 15.8589 25.4497C16.0671 25.5458 16.2913 25.6099 16.5316 25.6099C16.7718 25.6099 16.996 25.5458 17.1882 25.4497C17.4765 25.3536 17.7488 25.2095 17.973 24.9853L26.3815 16.5607C27.0062 15.9361 27.0062 14.9271 26.3815 14.3025C25.7569 13.6778 24.7479 13.6778 24.1233 14.3025L18.1492 20.3086V1.60162C18.1492 0.72073 17.4445 0 16.5476 0C15.6507 0 14.946 0.72073 14.946 1.60162V20.3086L8.95588 14.3184C8.33125 13.6938 7.32223 13.6938 6.6976 14.3184C6.07296 14.943 6.07296 15.9521 6.6976 16.5768L15.1061 25.0013L15.0741 24.9853Z"
                fill="currentColor"
            />
            <path
                d="M27.7275 25.5775C26.8466 25.5775 26.1258 26.2982 26.1258 27.1791C26.1258 28.2362 25.1649 28.7968 24.268 28.7968H8.73222C7.81929 28.7968 6.90637 28.2362 6.90637 27.1791C6.90637 26.2982 6.18564 25.5775 5.30475 25.5775C4.42386 25.5775 3.70312 26.2982 3.70312 27.1791C3.70312 29.8858 5.91336 32 8.73222 32H24.268C27.1028 32 29.3291 29.8858 29.3291 27.1791C29.3291 26.2982 28.6084 25.5775 27.7275 25.5775Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
