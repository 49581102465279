import { useMemo } from 'react';
import GoogleAnalytics from 'react-ga4';

import { useSwitchboard } from 'features/Switchboard';

const logger =
    (method) =>
    (data, ...args) =>
        console.log(`GA stub: ${method}`, data, ...args);

export function useGoogleAnalytics() {
    const isTracking = useSwitchboard('FEATURE_GOOGLE_ANALYTICS');

    return useMemo(() => {
        if (isTracking) return GoogleAnalytics;

        return {
            ga: logger('ga'),
            set: logger('set'),
            gtag: logger('gtag'),
            send: logger('send'),
            event: logger('event'),
        };
    }, [isTracking]);
}
