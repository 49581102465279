import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useGetChannelPolicyQuery, useLazyGetChannelPolicyQuery } from 'services/conversations';

// @TODO: Extend this to do data transformations with channel policy data
export function useChannelPolicies({ talentIds, talent, talents, contactMetadata, skip = false }) {
    const results = useGetChannelPolicyQuery({ talentIds: talentIds || [talent?.id] }, { skip });
    const { data, isSuccess, isFetching, isError } = results || {};

    const [getChannelPolicy, channelPolicyResultData] = useLazyGetChannelPolicyQuery();
    const { isError: isPolicyError, data: channelPolicies } = channelPolicyResultData;

    useEffect(() => {
        if (talents?.length && contactMetadata) {
            const talentIdList = talents?.map((talent) => talent?.[0]?.talentId || talent.id);
            const filteredTalentIds = talentIdList.filter((talentId) => {
                const contactContactObject = contactMetadata?.contactCounts?.[talentId];
                return (
                    contactContactObject?.emails !== null ||
                    contactContactObject?.phones !== null ||
                    contactContactObject?.socials !== null
                );
            });

            if (filteredTalentIds.length) {
                getChannelPolicy({ talentIds: filteredTalentIds });
            }
        }
    }, [contactMetadata, getChannelPolicy, talents]);

    useEffect(() => {
        if (isError || isPolicyError) {
            toast.error('An error occurred while retrieving message channel policies');
        }
    }, [isError, isPolicyError]);

    return {
        data: data || channelPolicies,
        isSuccess,
        isFetching,
    };
}
