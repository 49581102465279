import SvgIcon from '@mui/material/SvgIcon';

export function UploadIcon(props) {
    return (
        <SvgIcon
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.65214 5.88217C3.85994 5.88217 4.05975 5.80227 4.21959 5.65042L7.20064 2.65338V11.9881C7.20064 12.4277 7.55229 12.7874 7.99985 12.7874C8.4474 12.7874 8.79906 12.4277 8.79906 11.9881V2.65338L11.7881 5.64242C12.0998 5.95411 12.6033 5.95411 12.915 5.64242C13.2267 5.33073 13.2267 4.8272 12.915 4.51551L8.72713 0.311704C8.62323 0.207806 8.48737 0.135854 8.3515 0.0879014C8.2476 0.0399488 8.12772 0 8.00783 0C7.88795 0 7.76808 0.0319567 7.66418 0.0879014C7.52831 0.135854 7.39245 0.207806 7.28855 0.311704L3.10069 4.51551C2.789 4.8272 2.789 5.33073 3.10069 5.64242C3.26053 5.79427 3.46034 5.87417 3.66813 5.87417L3.65214 5.88217Z"
                fill="currentColor"
            />
            <path
                d="M13.5946 12.7952C13.155 12.7952 12.7954 13.1548 12.7954 13.5944C12.7954 14.1218 12.3159 14.4016 11.8683 14.4016H4.11596C3.66041 14.4016 3.20486 14.1218 3.20486 13.5944C3.20486 13.1548 2.84522 12.7952 2.40566 12.7952C1.96609 12.7952 1.60645 13.1548 1.60645 13.5944C1.60645 14.945 2.70935 16 4.11596 16H11.8683C13.2829 16 14.3938 14.945 14.3938 13.5944C14.3938 13.1548 14.0342 12.7952 13.5946 12.7952Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
