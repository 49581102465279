import { useEffect, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useKeycloak } from 'features/Authentication';
import { useLazyGetFeatureSwitchesQuery } from 'services/switchboard';
import { populateLocalStorage, LOCAL_STORAGE_KEY } from './utils/populateLocalStorage';

const INIT_STATE = { features: {}, isLoading: true };

export const SwitchboardContext = createContext(INIT_STATE);

export function SwitchboardProvider(props) {
    const { children } = props;

    const { keycloak, initialized } = useKeycloak();

    const [getFeatureSwitches, response] = useLazyGetFeatureSwitchesQuery();
    const { data, isUninitialized, isFetching, isError, isSuccess } = response;

    useEffect(() => {
        if (isSuccess) {
            populateLocalStorage(data);
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (initialized && keycloak.authenticated) {
            getFeatureSwitches();
        }
    }, [getFeatureSwitches, keycloak.authenticated, initialized]);

    const providerValue = useMemo(() => {
        let features = INIT_STATE.features;
        const isLoading = isUninitialized || (isFetching && !Boolean(data));

        if (isSuccess) {
            features = data;
        } else if (isError) {
            const stringifiedFeatures = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (stringifiedFeatures && stringifiedFeatures[0] === '{') {
                features = JSON.parse(stringifiedFeatures);
            }
        }

        // This is workaround since we don't have proper environment variables in the frontend
        const domain = window.location.host;
        const envName = domain.includes('localhost') ? 'local' : domain.split('.')[0];
        const novaEnv = {
            name: envName,
            isProd: envName === 'app',
            isStaging: envName === 'nova-staging',
            isTest: envName === 'nova-test',
            isLocal: envName === 'local',
        };

        return { isLoading, features, novaEnv };
    }, [data, isUninitialized, isFetching, isSuccess, isError]);

    return (
        <SwitchboardContext.Provider value={providerValue}>{children}</SwitchboardContext.Provider>
    );
}

SwitchboardProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
