import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { LinkedInIcon } from 'components/Icons';

export function LinkedInIconCta(props) {
    const { disabled, profileUrl, size, ...restProps } = props;

    if (disabled || !profileUrl) {
        return null;
    }

    return (
        <IconButton
            aria-label="linkedIn-profile-link"
            data-trackid="talent-linkedin"
            sx={{ padding: 0 }}
            component="a"
            href={profileUrl}
            target="_blank"
            rel="noopener noreferrer"
            disableRipple
            {...restProps}
        >
            <LinkedInIcon sx={{ color: 'primary.main', fontSize: size || 20 }} />
        </IconButton>
    );
}

LinkedInIconCta.propTypes = {
    disabled: PropTypes.bool,
    profileUrl: PropTypes.string,
    size: PropTypes.number,
};
