import { useContext } from 'react';
import { SlateEditorContext } from '../SlateEditorProvider';

function useSlateEditor() {
    const context = useContext(SlateEditorContext);
    if (context === undefined) {
        throw new Error('useSlateEditor must be used within a SlateEditorProvider');
    }
    return context;
}

export default useSlateEditor;
