export const getTooltipProps = (tooltips, targetToolTipId, setterFunc) => {
    const targetTooltip = tooltips.find((tooltip) => tooltip.id === targetToolTipId);

    const handleClose = () => {
        setterFunc((state) =>
            state.map((tooltip) =>
                tooltip.id === targetToolTipId ? { ...tooltip, show: false } : tooltip
            )
        );
    };
    const handleOpen = () => {
        setterFunc((state) =>
            state.map((tooltip) =>
                tooltip.id === targetToolTipId ? { ...tooltip, show: true } : tooltip
            )
        );
    };

    return {
        'data-testid': `${targetTooltip.id}`,
        title: targetTooltip.title,
        placement: 'top',
        arrow: true,
        disableFocusListener: true,
        disableHoverListener: true,
        disableTouchListener: true,
        onClose: handleClose,
        onOpen: handleOpen,
        open: targetTooltip.show,
        onMouseEnter: handleOpen,
        onMouseLeave: handleClose,
        onClick: handleClose,
    };
};

export const defaultTooltips = [
    {
        id: 'templates-tooltip',
        title: 'Use or create a message template',
        show: false,
    },
    {
        id: 'attachments-tooltip',
        title: 'Add an attachment to your message',
        show: false,
    },
    {
        id: 'signatures-tooltip',
        title: 'Add a signature to your message',
        show: false,
    },
    {
        id: 'dynamic-fields-tooltip',
        title: 'Dynamically add personalization to your message from a Talent Profile',
        show: false,
    },
    {
        id: 'invitation-tooltip',
        title: 'Add a scheduling link to your message',
        show: false,
    },
    {
        id: 'formatting-options',
        title: 'Formatting options',
        show: false,
    },
];
