import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUpdateMediaFileMutation } from 'services/knowledgeBases';

export function useUpdateMedia({ onSuccess }) {
    const [updateMedia, result] = useUpdateMediaFileMutation();
    const { isSuccess, isError, isLoading, error, data } = result;

    const handleUpdateMedia = (body) => {
        updateMedia(body);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Successfully updated media');
            onSuccess(data);
        }
        if (isError) {
            toast.error(error?.data?.message || 'Error updating media');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, isError, error?.data?.message]);

    return {
        handleUpdateMedia,
        isLoading,
    };
}
