import Box from '@mui/material/Box';
import { Outlet, useMatch, useResolvedPath } from 'react-router-dom';

import { FilterBar } from './FilterBar';
import { FilterByTalentIdsIndicator } from './FilterBar/components/FilterByTalentIdsIndicator';
import { useSelector } from 'react-redux';

export function Communications() {
    const { pathname: contactsPath } = useResolvedPath('contacts');
    const isOnContacts = useMatch(contactsPath);

    const filteredTalentIds = useSelector((state) => state.communications.filters.talentIds);

    return (
        <>
            <FilterByTalentIdsIndicator />
            <Box
                sx={{
                    display: 'flex',
                    height: filteredTalentIds?.length ? 'calc(100% - 32px)' : '100%',
                    width: { md: '100%', lg: '100%', xl: '100%' },
                }}
            >
                {!isOnContacts && <FilterBar />}
                <Box
                    sx={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        justifyContent: 'center',
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
        </>
    );
}
