import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { Typography } from 'components/Typography';
import { RichTextInput } from 'components/Inputs';

const TextInputProps = {
    'aria-label': 'message-subject-input',
    'data-trackid': 'conversation-message-subject-input',
};

export const MessageSubject = forwardRef((props, ref) => {
    const { initialSubject, isFetching, onChangeSubject, autofocus, error, decorator, value } =
        props;

    const errorExists = Boolean(error);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pb: errorExists ? 0 : 1.8 }}>
            <Typography variant="labelSmall" sx={{ my: 1 }}>
                SUBJECT
            </Typography>
            <RichTextInput
                autoFocus={autofocus}
                name="compose-rich-text-subject"
                value={value}
                onChange={onChangeSubject}
                disabled={isFetching}
                inputProps={TextInputProps}
                initialValue={initialSubject}
                fullWidth
                isToolbarDisabled
                isInline
                error={errorExists}
                initiateMount={false}
                decorator={decorator}
                ref={ref}
                bodyHeight="30px"
            />
            {errorExists && (
                <Typography data-testid="subject-error-message" sx={{ fontSize: 10, color: 'red' }}>
                    {error}
                </Typography>
            )}
        </Box>
    );
});

MessageSubject.propTypes = {
    initialSubject: PropTypes.string,
    onChangeSubject: PropTypes.func,
    isFetching: PropTypes.bool,
    autofocus: PropTypes.bool,
    error: PropTypes.string,
    decorator: PropTypes.func,
    value: PropTypes.string,
};
