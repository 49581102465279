import SvgIcon from '@mui/material/SvgIcon';

export function SpeakerIcon(props) {
    return (
        <SvgIcon viewBox="0 0 25 24" {...props}>
            <path
                d="M24.8125 11.1137C24.8125 9.09067 23.2591 7.44089 21.2962 7.23618V4.53871C21.2962 3.20203 20.5857 1.98578 19.4537 1.38367C18.382 0.817692 17.1416 0.877895 16.1181 1.5643L9.75981 5.8513H5.6414C2.98008 5.8513 0.8125 8.0189 0.8125 10.6802V11.5352C0.8125 13.9195 2.55861 15.8945 4.83458 16.2798V20.0129C4.83458 21.6627 6.17126 22.9993 7.82103 22.9993H8.2425C9.89228 22.9993 11.229 21.6627 11.229 20.0129V17.1348L16.383 19.9166C16.8647 20.1815 17.3945 20.3139 17.9124 20.3139C18.4783 20.3139 19.0564 20.1574 19.5742 19.8563C20.6339 19.2181 21.2721 18.0741 21.2721 16.7856V15.0033C23.247 14.7986 24.7884 13.1488 24.7884 11.1258L24.8125 11.1137ZM3.23297 11.5472V10.6923C3.23297 9.35559 4.31676 8.27178 5.65344 8.27178H8.91687V13.9557H5.53302C4.25655 13.8955 3.23297 12.8478 3.23297 11.5472ZM8.26659 20.6029H7.84512C7.51998 20.6029 7.26709 20.338 7.26709 20.0249V16.3762H8.85665V20.0249C8.85665 20.3501 8.59172 20.6029 8.27863 20.6029H8.26659ZM18.8998 16.7735C18.8998 17.2071 18.7071 17.5804 18.37 17.7731C18.1893 17.8814 17.9003 17.9778 17.5511 17.7971L11.3373 14.4494V7.7058L17.4668 3.5633C17.8281 3.31041 18.1532 3.43086 18.3218 3.51516C18.671 3.70783 18.8878 4.08111 18.8878 4.53871V16.7735H18.8998ZM21.3082 12.5347V9.68073C21.9465 9.86136 22.4161 10.4153 22.4161 11.1137C22.4161 11.8122 21.9465 12.3661 21.3082 12.5468V12.5347Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
