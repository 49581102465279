import PropTypes from 'prop-types';
import Box from '@mui/system/Box';
import { useMemo, useEffect, useState, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import Collapse from '@mui/material/Collapse';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import addMonths from 'date-fns/addMonths';
import { styled } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { CalendarIcon, SaveIcon, SendMessageIcon, TimeIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { SCHEDULED_DATE_FMT, SCHEDULED_TIME_FMT, MESSAGE_TYPES } from 'utils/constants';
import { getIsValidTime, TIME_TEXT_ENUMS } from 'utils/validation';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';

const DATE_PLACEHOLDER = format(new Date(), SCHEDULED_DATE_FMT);

const ROUND_TO_OPTIONS = { nearestTo: 30, roundingMethod: 'ceil' };

const scheduleDateDefault = new Date();
scheduleDateDefault.setHours(scheduleDateDefault.getHours() + 1);
const QUARTER_HOUR_PLACEHOLDER = roundToNearestMinutes(scheduleDateDefault, ROUND_TO_OPTIONS);

const TIME_PLACEHOLDER = format(QUARTER_HOUR_PLACEHOLDER, SCHEDULED_TIME_FMT);

const MAX_DATE = addMonths(new Date(), 6);

const IconSxProps = { fontSize: 20, mr: 1, mt: 1 };

const PopperSxProps = {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    alignItems: 'flex-start',
    padding: (theme) => theme.spacing(1),
    borderRadius: (theme) => theme.spacing(1),
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.32), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
};

const TypographySxProps = { textTransform: 'none', color: 'common.black' };

const DatePicker = styled(StaticDatePicker)(({ theme }) => ({
    '&.MuiPickersDay-root': {
        '& .Mui-selected': {
            backgroundColor: theme.palette.success.main,
        },
    },
}));

/**
 * Regarding the fixed height of staticdatepicker: https://github.com/mui/material-ui/issues/27700
 */
export function ScheduledMessagePopup(props) {
    const {
        anchorEl,
        open,
        onSend,
        onClose,
        initialDate,
        initialTime,
        showCalendar,
        onConfirm,
        setShowCalendar,
        messageType,
        isUpdating,
        extendedPopperSxProps,
        placement,
    } = props;

    const [date, setDate] = useState(DATE_PLACEHOLDER);
    const [time, setTime] = useState(TIME_PLACEHOLDER);

    const [scheduledDate, setScheduledDate] = useState('');
    const [scheduledTime, setScheduledTime] = useState('');

    const [shouldSendMessage, setShouldSendMessage] = useState(false);

    const [isValidTime, helperText] = useMemo(
        () => getIsValidTime(date, time, messageType),
        [date, time, messageType]
    );
    const resetDateAndTime = () => {
        setDate(DATE_PLACEHOLDER);
        setTime(TIME_PLACEHOLDER);
    };

    const minMaxTimeFrame = messageType === MESSAGE_TYPES.SMS ? { min: '08:00', max: '20:00' } : {};

    useEffect(() => {
        if (initialDate) {
            setDate(initialDate);
        }
        if (initialTime) {
            setTime(initialTime);
        }
    }, [initialDate, initialTime]);

    useEffect(() => {
        if (date) {
            setScheduledDate(date);
        }
    }, [date]);

    useEffect(() => {
        if (time) {
            setScheduledTime(time);
        }
    }, [isValidTime, time]);

    const handleDate = (value) => {
        const dt = parseISO(value.toISOString());
        const fmt = format(dt, SCHEDULED_DATE_FMT);
        setDate(fmt);
    };

    const handleTime = (event) => {
        setTime(event.target.value);
    };

    const handleCustomDateTime = () => {
        setShowCalendar(true);
    };

    const handleSendNow = () => {
        resetDateAndTime();
        onSend('', '');
        onClose();
    };

    const handleDismiss = useCallback(() => {
        onConfirm?.({ date: '', time: '' });
        onClose();
        setShowCalendar(false);
    }, [onClose, setShowCalendar, onConfirm]);

    const handleConfirm = () => {
        onConfirm?.({ date: scheduledDate, time: scheduledTime });
        if (isUpdating) {
            handleDismiss();
        } else {
            setShouldSendMessage(true);
        }
        resetDateAndTime();
    };

    useEffect(() => {
        if (shouldSendMessage && !isUpdating) {
            onSend();
            handleDismiss();
            setShouldSendMessage(false);
        }
    }, [shouldSendMessage, onSend, isUpdating, handleDismiss]);

    return (
        <Popper
            open={open}
            sx={{ ...PopperSxProps, ...extendedPopperSxProps }}
            anchorEl={anchorEl}
            aria-label="scheduled message popup"
            placement={placement || 'bottom'}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ClickAwayListener onClickAway={handleDismiss}>
                    <Box>
                        <Collapse in={!showCalendar}>
                            <Box>
                                <Button
                                    variant="text"
                                    data-trackid="send-later-send-now"
                                    onClick={handleSendNow}
                                >
                                    <Typography variant="titleSmall" sx={TypographySxProps}>
                                        Send Now
                                    </Typography>
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    variant="text"
                                    data-trackid="send-later-choose-custom-date"
                                    onClick={handleCustomDateTime}
                                >
                                    <Typography variant="titleSmall" sx={TypographySxProps}>
                                        Choose a custom date/time
                                    </Typography>
                                </Button>
                            </Box>
                        </Collapse>
                        <Collapse in={showCalendar}>
                            <Box>
                                <DatePicker
                                    autoFocus
                                    view="day"
                                    disablePast
                                    value={date}
                                    maxDate={MAX_DATE}
                                    onChange={handleDate}
                                    renderInput={() => <></>}
                                    displayStaticWrapperAs="desktop"
                                    componentsProps={{
                                        paperContent: {
                                            sx: {
                                                '& .Mui-selected': {
                                                    backgroundColor: (theme) =>
                                                        theme.palette.success.main,
                                                },
                                            },
                                        },
                                        leftArrowIcon: {
                                            sx: {
                                                backgroundColor: (theme) =>
                                                    theme.palette.success.main,
                                            },
                                        },
                                        rightArrowIcon: {
                                            sx: {
                                                backgroundColor: (theme) =>
                                                    theme.palette.success.main,
                                            },
                                        },
                                    }}
                                />
                                <Box
                                    sx={{
                                        p: 0,
                                        mt: 0,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'start',
                                    }}
                                >
                                    <CalendarIcon sx={IconSxProps} color="success" />
                                    <TextField
                                        value={date}
                                        readOnly
                                        fullWidth
                                        variant="standard"
                                        inputProps={{
                                            'aria-label': 'Scheduled date',
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        p: 0,
                                        mt: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'start',
                                    }}
                                >
                                    <TimeIcon sx={IconSxProps} color="success" />
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        value={time}
                                        onChange={handleTime}
                                        error={Boolean(time) && !isValidTime}
                                        helperText={helperText}
                                        inputProps={{
                                            placeholder: TIME_PLACEHOLDER,
                                            'aria-label': 'Scheduled time',
                                            type: 'time',
                                            ...minMaxTimeFrame,
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        p: 0,
                                        mt: 2,
                                        display: 'flex',
                                        justifyContent: 'end',
                                    }}
                                >
                                    {isUpdating && (
                                        <Button
                                            onClick={handleConfirm}
                                            aria-label="Confirm date"
                                            disableRipple
                                            disabled={helperText === TIME_TEXT_ENUMS.PAST_ERROR}
                                        >
                                            <SaveIcon
                                                sx={{ mr: 1, fontSize: 16 }}
                                                color={
                                                    helperText === TIME_TEXT_ENUMS.PAST_ERROR
                                                        ? 'error'
                                                        : 'success'
                                                }
                                            />
                                            <Typography
                                                variant="labelSmall"
                                                sx={{
                                                    color: (theme) =>
                                                        helperText === TIME_TEXT_ENUMS.PAST_ERROR
                                                            ? theme.palette.error.main
                                                            : theme.palette.success.main,
                                                    textTransform: 'none',
                                                }}
                                            >
                                                Confirm
                                            </Typography>
                                        </Button>
                                    )}
                                    {!isUpdating && (
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={handleConfirm}
                                            aria-label="Confirm date"
                                            disableRipple
                                            disabled={
                                                !isValidTime ||
                                                helperText === TIME_TEXT_ENUMS.PAST_ERROR
                                            }
                                        >
                                            <SendMessageIcon
                                                sx={{
                                                    mr: 1,
                                                    fontSize: 16,
                                                    color: (theme) =>
                                                        helperText === TIME_TEXT_ENUMS.PAST_ERROR
                                                            ? theme.palette.error.main
                                                            : theme.palette.common.white,
                                                }}
                                            />
                                            <Typography
                                                variant="labelSmall"
                                                sx={{
                                                    color: (theme) =>
                                                        helperText === TIME_TEXT_ENUMS.PAST_ERROR ||
                                                        !isValidTime
                                                            ? theme.palette.error.main
                                                            : theme.palette.common.white,
                                                    textTransform: 'none',
                                                }}
                                            >
                                                Schedule Send
                                            </Typography>
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>
                </ClickAwayListener>
            </LocalizationProvider>
        </Popper>
    );
}

ScheduledMessagePopup.propTypes = {
    anchorEl: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
    open: PropTypes.bool,
    initialDate: PropTypes.string,
    initialTime: PropTypes.string,
    onSend: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    showCalendar: PropTypes.bool,
    isUpdating: PropTypes.bool,
    setShowCalendar: PropTypes.func,
    messageType: PropTypes.string,
    extendedPopperSxProps: PropTypes.object,
    placement: PropTypes.string,
};
