import { useCallback, useState } from 'react';
import produce from 'immer';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

import { useGetMediaTagsQuery } from 'services/knowledgeBases';

export function useFileTagAutocomplete(tags = []) {
    const [value, setValue] = useState(tags);
    const [inputValue, setInputValue] = useState('');
    const [multiTagsValue, setMultiTagsValue] = useState({});

    const { data } = useGetMediaTagsQuery();

    const handleChange = useCallback((event, data, dataKey) => {
        const areAllValuesNotEmpty = every(data, (tag) => !isEmpty(trim(tag)));

        if (areAllValuesNotEmpty) {
            setMultiTagsValue((prev) =>
                produce(prev, (draft) => {
                    draft[dataKey] = data;
                })
            );
            setValue(data);
            setInputValue('');
        }
    }, []);

    const handleInputValue = useCallback((event, newInputValue) => {
        setInputValue(newInputValue);
    }, []);

    const handleRemoveTag = useCallback((fileId) => {
        setMultiTagsValue((prev) => {
            const updatedMultiTagsValue = { ...prev };

            if (updatedMultiTagsValue.hasOwnProperty(fileId)) {
                delete updatedMultiTagsValue[fileId];
            }

            return updatedMultiTagsValue;
        });
    }, []);

    const handleInputEnter = useCallback((event) => {
        if (event.code === 'enter' && event.target.value && !isEmpty(trim(event.target.value))) {
            setValue((prevState) => prevState.concat(event.target.value));
            setInputValue('');
        }
    }, []);

    return {
        value,
        inputValue,
        multiTagsValue,
        handleChange,
        handleInputValue,
        handleInputEnter,
        tagOptions: data?.tags || [],
        setMultiTagsValue,
        handleRemoveTag,
        setValue,
    };
}
