import SvgIcon from '@mui/material/SvgIcon';

export function FolderIcon(props) {
    return (
        <SvgIcon viewBox="0 0 64 65" {...props}>
            <path
                d="M53.3227 14.8697H38.4256C36.0919 14.8697 33.9501 13.9106 32.8312 12.3442C30.3696 8.92361 26.3097 6.87769 21.994 6.87769H13.2028C5.91408 6.87769 0 12.5999 0 19.6009V47.7967C0 53.487 4.79521 58.1224 10.6773 58.1224H54.3456C59.5245 58.1224 63.8401 54.0305 63.9361 49.0115L64 25.2273C64 19.537 59.2048 14.9016 53.3227 14.9016V14.8697ZM57.6064 45.527L57.5424 48.8517C57.5424 50.4182 56.0719 51.6968 54.3456 51.6968H10.6773C8.31169 51.6968 6.39361 49.9385 6.39361 47.7647V19.569C6.39361 16.0845 9.46253 13.2393 13.2028 13.2393H21.994C24.2637 13.2393 26.3736 14.2943 27.6204 16.0525C29.954 19.3132 33.982 21.2313 38.3936 21.2313H53.2907C55.6563 21.2313 57.5744 22.9896 57.5744 25.1634V45.495L57.6064 45.527Z"
                fill="currentColor"
            />
            <path
                d="M36.7002 28.7431L29.6352 36.3515L27.1737 33.7301C25.9589 32.4513 23.9449 32.3874 22.6662 33.6022C21.3875 34.817 21.3236 36.8309 22.5383 38.1097L26.6302 42.4254C27.4295 43.2565 28.5164 43.7361 29.6672 43.7361C30.8181 43.7361 31.9369 43.2245 32.7042 42.3934L41.3355 33.0907C42.5503 31.78 42.4544 29.766 41.1757 28.5832C39.897 27.3684 37.851 27.4643 36.6682 28.7431H36.7002Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
