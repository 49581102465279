import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useLazyGetConversationsQuery } from 'services/conversations';
import { MESSAGE_TYPES } from 'utils/constants';

const smsMessageExists = (messages) => {
    return messages.filter((message) => message.messageType === MESSAGE_TYPES.SMS).length > 0;
};

export function useCheckSmsSent(props) {
    const { profiles } = props;

    const [getConversationSummary] = useLazyGetConversationsQuery();
    const [isFirstSMSMessage, setIsFirstSMSMessage] = useState(false);

    const getFirstSmsMessage = useCallback(
        async (talentId) => {
            const requestParams = {
                talent: talentId,
            };
            try {
                const { data, error } = await getConversationSummary(requestParams);
                if (error) {
                    throw new Error();
                }
                if (data.totalResults === 0) setIsFirstSMSMessage(true);
                else {
                    if (smsMessageExists(data.results[0].messages)) setIsFirstSMSMessage(false);
                    else setIsFirstSMSMessage(true);
                }
            } catch {
                toast.error('Error encountered while retrieving messages');
            }
        },
        [getConversationSummary]
    );

    useEffect(() => {
        if (profiles.length !== 1) {
            // this is a bulk message, do not use the prepended text
            setIsFirstSMSMessage(false);
        } else {
            const talentId = profiles[0]?.talentId;
            if (!talentId) return;
            getFirstSmsMessage(talentId);
        }
    }, [getFirstSmsMessage, profiles]);

    return {
        isFirstSMSMessage,
    };
}
