import { useDialog } from 'components/Dialog';
import { useCallback, useState } from 'react';
import { useLazyGetMediaUrlQuery } from 'services/knowledgeBases';

export const useMediaDownload = ({ onAttachmentDownload }) => {
    const [filename, setFilename] = useState('');
    const [getFilePath, { data, isError, isFetching }] = useLazyGetMediaUrlQuery();
    const { url } = data || {};

    const { isOpen, handleOpen, handleClose, handleConfirm } = useDialog({
        onConfirm: onAttachmentDownload,
    });

    const handleDownload = useCallback(
        (mediaId, name) => () => {
            getFilePath(mediaId);
            setFilename(name);
            handleOpen();
        },
        [getFilePath, handleOpen]
    );

    return {
        handleDownload,
        isOpen,
        handleOpen,
        handleClose,
        handleConfirm,
        filename,
        isError,
        isFetching,
        url,
    };
};
