import { styled } from '@mui/material/styles';

import { Typography, TYPOGRAPHY_VARIANTS } from 'components/Typography';

export const MediaSectionLabel = styled(Typography)(() => ({
    ...TYPOGRAPHY_VARIANTS['labelSmall'],
    lineHeight: '14px',
    marginBottom: '8px',
    textTransform: 'uppercase',
}));
