import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { alpha } from '@mui/material/styles';

import { FilterLabel } from './FilterLabel';
import { ExcludedChip } from './ExcludedChip';
import { IncludedChip } from './IncludedChip';
import { Typography } from 'components/Typography';
import { AutocompleteSx, InputProps } from './props';
import { useSwitchboard } from 'features/Switchboard';
import { IncludeExcludeLabel } from './IncludeExcludeLabel';
import { CERTIFICATION_FILTER, SKILLS_FILTER } from './constants';
import { resetMultipleStandardFilters } from 'views/Search/searchSlice';
import { ChevronDownIcon, InfoIcon, SkillsIcon } from 'components/Icons';
import { useStandardFiltersState } from '../hooks/useStandardFiltersState';
import { CertificationsAndSkillsAutocomplete } from './CertificationsAndSkillsAutocomplete';
import {
    FilterAccordion,
    FilterSummary,
    FilterDetails,
    SummaryChip,
    SummaryActions,
    SummaryAction,
    StyledPaper,
    FilterChipContainer,
    CustomWidthTooltip,
    StyledDivider,
} from './styledComponents';

const filterTooltipCopy = (filterName) =>
    `Adding a ${filterName} filter will only show candidates with these ${filterName}s in their profile. Adding the same ${filterName} to your match input text will do a better job of surfacing relevant candidates.`;

export function FilterCertificationsAndSkills() {
    const dispatch = useDispatch();
    const isSkillsEnabled = useSwitchboard('FEATURE_SKILLS_FILTER');
    const isCertificationFilterEnabled = useSwitchboard('FEATURE_CERTIFICATION_FILTER');

    const {
        filters: certifications,
        excludedFilters: excludedCertifications,
        excludeMatch: excludeMatchCertifications,
        handleDelete: handleDeleteCertification,
        handleDeleteExcluded: handleDeleteExcludedCertification,
        handleChange: handleChangeCertifications,
        handleClickInclude: handleClickIncludeCertification,
        handleClickExclude: handleClickExcludeCertification,
        handleExcludeMatchChange: handleExcludeMatchChangeCertifications,
    } = useStandardFiltersState(CERTIFICATION_FILTER);

    const {
        filters: skills,
        excludedFilters: excludedSkills,
        excludeMatch: excludeMatchSkills,
        handleDelete: handleDeleteSkill,
        handleDeleteExcluded: handleDeleteExcludedSkill,
        handleChange: handleChangeSkills,
        handleClickInclude: handleClickIncludeSkill,
        handleClickExclude: handleClickExcludeSkill,
        handleExcludeMatchChange: handleExcludeMatchChangeSkills,
    } = useStandardFiltersState(SKILLS_FILTER);

    const activeFiltersCount =
        certifications.length +
        excludedCertifications.length +
        skills.length +
        excludedSkills.length;

    const handleClearFilters = (event) => {
        event.stopPropagation();
        dispatch(resetMultipleStandardFilters([CERTIFICATION_FILTER, SKILLS_FILTER]));
    };

    if (!isCertificationFilterEnabled && !isSkillsEnabled) {
        return null;
    }

    return (
        <FilterAccordion id="filter-certifications">
            <FilterSummary
                expandIcon={<ChevronDownIcon />}
                aria-controls="filter-certifications-and-skills-content"
                aria-label="filter-certifications-and-skills-header"
            >
                <SkillsIcon sx={{ color: alpha('#ffffff', 0.48), mb: -1 }} />
                <Typography
                    variant="labelSmall"
                    sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
                >
                    {isCertificationFilterEnabled && 'Certifications'}
                    {isSkillsEnabled && isCertificationFilterEnabled && ' & '}
                    {isSkillsEnabled && 'Skills'}
                </Typography>
                <SummaryActions>
                    {Boolean(activeFiltersCount) && (
                        <SummaryAction
                            variant="text"
                            onClick={handleClearFilters}
                            aria-label="filter-certifications-and-skills-clear"
                        >
                            Clear
                        </SummaryAction>
                    )}
                    <SummaryChip label={activeFiltersCount} />
                </SummaryActions>
            </FilterSummary>

            {isCertificationFilterEnabled && (
                <FilterDetails id="filter-certifications-content">
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <CertificationsAndSkillsAutocomplete
                            fullWidth
                            disableTags
                            disableClearable
                            multiple
                            openOnFocus={false}
                            forcePopupIcon={false}
                            PaperComponent={StyledPaper}
                            onChange={handleChangeCertifications}
                            value={
                                excludeMatchCertifications ? excludedCertifications : certifications
                            }
                            label={
                                <FilterLabel
                                    label={
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography variant="bodySmall">
                                                Filter by certifications
                                            </Typography>
                                            <CustomWidthTooltip
                                                title={filterTooltipCopy('certification')}
                                                placement="top"
                                                arrow
                                                sx={{ ml: 0.5 }}
                                            >
                                                <Box>
                                                    <InfoIcon
                                                        sx={{
                                                            mb: -0.5,
                                                            ml: 0.3,
                                                            fontSize: 16,
                                                            cursor: 'pointer',
                                                            color: 'white',
                                                        }}
                                                    />
                                                </Box>
                                            </CustomWidthTooltip>
                                        </Box>
                                    }
                                    checked={excludeMatchCertifications}
                                    onChange={handleExcludeMatchChangeCertifications}
                                    dataTrackId="certifications-filters-drawer-exclude"
                                />
                            }
                            sx={{ flex: 1 }}
                            TextInputProps={{
                                sx: AutocompleteSx,
                                InputProps: {
                                    ...InputProps,
                                },
                            }}
                        />
                    </Box>
                    {Boolean(certifications.length) && (
                        <>
                            <IncludeExcludeLabel
                                label="Include"
                                filterKey={CERTIFICATION_FILTER}
                                enableAnyAllSelect
                            />
                            <FilterChipContainer>
                                {certifications.map((certification, index) => {
                                    return (
                                        <IncludedChip
                                            key={`included-${certification}`}
                                            label={certification}
                                            index={index}
                                            filterKey={CERTIFICATION_FILTER}
                                            onClick={handleClickExcludeCertification}
                                            onDelete={handleDeleteCertification}
                                        />
                                    );
                                })}
                            </FilterChipContainer>
                        </>
                    )}

                    {Boolean(excludedCertifications.length) && (
                        <>
                            <IncludeExcludeLabel label="Exclude" />
                            <FilterChipContainer>
                                {excludedCertifications.map((certification, index) => (
                                    <ExcludedChip
                                        key={`excluded-${certification}-${index}`}
                                        label={certification}
                                        index={index}
                                        filterKey={CERTIFICATION_FILTER}
                                        onClick={handleClickIncludeCertification}
                                        onDelete={handleDeleteExcludedCertification}
                                    />
                                ))}
                            </FilterChipContainer>
                        </>
                    )}
                </FilterDetails>
            )}

            {isCertificationFilterEnabled && isSkillsEnabled && (
                <Box sx={{ px: 2, pb: 0.75 }}>
                    <StyledDivider />
                </Box>
            )}

            {isSkillsEnabled && (
                <FilterDetails id="filter-skills-content">
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <CertificationsAndSkillsAutocomplete
                            isSkills
                            fullWidth
                            disableTags
                            disableClearable
                            multiple
                            openOnFocus={false}
                            forcePopupIcon={false}
                            PaperComponent={StyledPaper}
                            onChange={handleChangeSkills}
                            value={excludeMatchSkills ? excludedSkills : skills}
                            label={
                                <FilterLabel
                                    label={
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography variant="bodySmall">
                                                Filter by skills
                                            </Typography>
                                            <CustomWidthTooltip
                                                title={filterTooltipCopy('skill')}
                                                placement="top"
                                                arrow
                                                sx={{ ml: 0.5 }}
                                            >
                                                <Box>
                                                    <InfoIcon
                                                        sx={{
                                                            mb: -0.5,
                                                            ml: 0.3,
                                                            fontSize: 16,
                                                            cursor: 'pointer',
                                                            color: 'white',
                                                        }}
                                                    />
                                                </Box>
                                            </CustomWidthTooltip>
                                        </Box>
                                    }
                                    checked={excludeMatchSkills}
                                    onChange={handleExcludeMatchChangeSkills}
                                    dataTrackId="skills-filters-drawer-exclude"
                                />
                            }
                            sx={{ flex: 1 }}
                            TextInputProps={{
                                sx: AutocompleteSx,
                                InputProps: {
                                    ...InputProps,
                                },
                            }}
                        />
                    </Box>
                    {Boolean(skills.length) && (
                        <>
                            <IncludeExcludeLabel
                                label="Include"
                                filterKey={SKILLS_FILTER}
                                enableAnyAllSelect
                            />
                            <FilterChipContainer>
                                {skills.map((skill, index) => (
                                    <IncludedChip
                                        key={`included-${skill}`}
                                        label={skill}
                                        index={index}
                                        filterKey={SKILLS_FILTER}
                                        onClick={handleClickExcludeSkill}
                                        onDelete={handleDeleteSkill}
                                    />
                                ))}
                            </FilterChipContainer>
                        </>
                    )}

                    {Boolean(excludedSkills.length) && (
                        <>
                            <IncludeExcludeLabel label="Exclude" />
                            <FilterChipContainer>
                                {excludedSkills.map((skill, index) => (
                                    <ExcludedChip
                                        key={`excluded-${skill}-${index}`}
                                        label={skill}
                                        index={index}
                                        filterKey={SKILLS_FILTER}
                                        onClick={handleClickIncludeSkill}
                                        onDelete={handleDeleteExcludedSkill}
                                    />
                                ))}
                            </FilterChipContainer>
                        </>
                    )}
                </FilterDetails>
            )}
        </FilterAccordion>
    );
}
