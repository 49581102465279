import SvgIcon from '@mui/material/SvgIcon';

export function EyeSlashIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M15.2443 4.48625C13.6691 2.59926 11.1425 0.344474 8.00818 0.344474C7.4085 0.344474 6.79284 0.432427 6.18516 0.600336C5.76139 0.720272 5.51352 1.16003 5.63346 1.58381C5.75339 2.00758 6.19317 2.25545 6.61694 2.13551C7.08069 2.00758 7.55243 1.93562 8.00818 1.93562C10.6468 1.93562 12.9016 4.16642 14.013 5.5017C14.5167 6.10937 14.5247 6.96491 14.0369 7.57258C13.845 7.81245 13.6292 8.06032 13.3893 8.31618C13.0855 8.63601 13.1014 9.14774 13.4213 9.44358C13.5732 9.5875 13.7731 9.65946 13.973 9.65946C14.1889 9.65946 14.3967 9.57151 14.5567 9.4116C14.8285 9.12375 15.0684 8.8439 15.2843 8.58004C16.2518 7.38868 16.2358 5.66161 15.2523 4.47825L15.2443 4.48625Z"
                fill="currentColor"
            />
            <path
                d="M4.842 2.21528C4.81801 2.15131 4.80201 2.08735 4.76204 2.02338C4.65809 1.87147 4.51417 1.78351 4.36225 1.72754L2.97101 0.336291C2.65917 0.024459 2.14745 0.024459 1.84361 0.336291C1.53178 0.648123 1.53178 1.15185 1.84361 1.46368L2.81909 2.43916C2.11547 3.00685 1.43583 3.67849 0.77219 4.46207C-0.227273 5.64543 -0.259261 7.31654 0.692228 8.53188C1.89958 10.0671 4.45021 12.6497 7.97631 12.6497C9.31159 12.6497 10.6229 12.2659 11.8942 11.5383L13.0136 12.6657C13.1735 12.8256 13.3734 12.8975 13.5813 12.8975C13.7892 12.8975 13.9891 12.8176 14.149 12.6657C14.4608 12.3538 14.4608 11.8501 14.149 11.5383L4.842 2.21528ZM6.45713 6.10119L8.40008 8.04414C8.27215 8.07613 8.13622 8.10011 8.0003 8.10011C7.12077 8.10011 6.40116 7.3805 6.40116 6.50097C6.40116 6.36505 6.42515 6.22912 6.45713 6.10119ZM7.96832 11.0585C5.12185 11.0585 2.971 8.8597 1.93955 7.54841C1.45981 6.94073 1.47581 6.09319 1.97954 5.50151C2.6112 4.74992 3.26684 4.11026 3.93848 3.59054L5.24977 4.90183C4.96193 5.38957 4.79403 5.93328 4.79403 6.50897C4.79403 8.27602 6.22526 9.70725 7.99231 9.70725C8.56 9.70725 9.1117 9.53934 9.59145 9.25949L10.7028 10.3789C9.79933 10.8346 8.87982 11.0665 7.96032 11.0665L7.96832 11.0585Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
