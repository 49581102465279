import SvgIcon from '@mui/material/SvgIcon';
export function PlusIconDark(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 -0.00012207C5.376 -0.00012207 0 5.37588 0 11.9999C0 18.6239 5.376 23.9999 12 23.9999C18.624 23.9999 24 18.6239 24 11.9999C24 5.37588 18.624 -0.00012207 12 -0.00012207ZM12 21.5999C6.708 21.5999 2.4 17.2919 2.4 11.9999C2.4 6.70788 6.708 2.39988 12 2.39988C17.292 2.39988 21.6 6.70788 21.6 11.9999C21.6 17.2919 17.292 21.5999 12 21.5999Z"
                fill="currentColor"
                fillOpacity="0.24"
            />
            <path
                d="M15.6 10.7999H13.1881V8.39988C13.1881 7.73988 12.6481 7.19988 11.9881 7.19988C11.3281 7.19988 10.7881 7.73988 10.7881 8.39988V10.7999H8.4C7.74 10.7999 7.2 11.3399 7.2 11.9999C7.2 12.6599 7.74 13.1999 8.4 13.1999H10.7881V15.5999C10.7881 16.2599 11.3281 16.7999 11.9881 16.7999C12.6481 16.7999 13.1881 16.2599 13.1881 15.5999V13.1999H15.6C16.26 13.1999 16.8 12.6599 16.8 11.9999C16.8 11.3399 16.26 10.7999 15.6 10.7999Z"
                fill="currentColor"
                fillOpacity="0.24"
            />
        </SvgIcon>
    );
}
