import SvgIcon from '@mui/material/SvgIcon';

export function LikeIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <path
                d="M2.5 4.5V10.5H0.5V4.5H2.5ZM4.5 10.5C4.23478 10.5 3.98043 10.3946 3.79289 10.2071C3.60536 10.0196 3.5 9.76522 3.5 9.5V4.5C3.5 4.225 3.61 3.975 3.795 3.795L7.085 0.5L7.615 1.03C7.75 1.165 7.835 1.35 7.835 1.555L7.82 1.715L7.345 4H10.5C10.7652 4 11.0196 4.10536 11.2071 4.29289C11.3946 4.48043 11.5 4.73478 11.5 5V6C11.5 6.13 11.475 6.25 11.43 6.365L9.92 9.89C9.77 10.25 9.415 10.5 9 10.5H4.5ZM4.5 9.5H9.015L10.5 6V5H6.105L6.67 2.34L4.5 4.515V9.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
