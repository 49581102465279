import SvgIcon from '@mui/material/SvgIcon';

export function BullhornIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <rect y="0.165283" width="16" height="16" fill="url(#pattern0)" />
            <defs>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_14136_26" transform="scale(0.03125)" />
                </pattern>
                <image
                    id="image0_14136_26"
                    width="32"
                    height="32"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACshmLzAAACnElEQVRIDb1WS2gTURQ9k6QNYoP4bRWNhRSsRDfSWIMFqwUt0urCuBCxgqALK2o3ulK6tCAqIkEXYlFcqjt/0NIiLqyCRPwExJYqTW1pUNuEGptkvG/GzJtfxpmY+hiY+z3nzdz77owgnsa8Lte8ohO4c4JIFF1DWL/b5s7c3VtsRhbCvrzC6k1o70GoA5ULkZpCJoV8tuDW34USaxBsx75rWOxneIk3+NiH+FPEH+vhgVIJZKRtXWg5A1/NH9znUdw/iXxOTeO8BurswcsYuMQNW49jaYCrkuTR6Q5UXzXoCcJHecrXd5ge56okKQSCpIo6N1crFqDhEKrrMTOB8beoa0boMKqW8wCy34ogM8MtkqQQFIemOI8XJwbg36xL5moihtsHMBnnloKkEBQM6jttsH4XltQi0FwU/Vcag1fQ12Pcu4xUnCDYhv03sGiVmtFETich5tHazThSk8aAIl3kD+Hgnb+jEx4dhZ3n8G3UFJ38HqzciNnvEHPw+lhHr2nAhj1Y28jeu/3V1Mka9MEpY4YgiiLoouoTgSD3kjHMhiX7E/0XkfyEiQ/sbM/NyjmCOBZDTRAutw0MeyHZDHtdQ714dJ4SpFGxLMAekA6Otwq5OTQeQW3YHphl1PAzxO6ZzaLOftRtt0x14DR0EXVn+dBpI1oCmgdtFxxsz0aoliB8jBW8rEtLQNUu99ISGIbtv9P9Z4L3D9nYKOvSTlM6gXc72PCiQZSeYt8WGpbTCSRHWPFbzsJd4ZTd7KAJLrgrQbNFt+QRu2Kdzmytamsgx9J8N6KT6/NLRHdg9IU1os5rRqALUas/ErjahPgTtc1adkhAYPQT1xvB2GtrXMXrnIBS6V/xeiuSwwqKhVASAeFRv93ca14qLdtv/R+2B/GLUKcAAAAASUVORK5CYII="
                />
            </defs>
        </SvgIcon>
    );
}
