import { useCallback } from 'react';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';

import { CheckboxField } from './CheckboxField';
import { toggleReadFilter, toggleUnreadFilter } from 'views/Communications/communicationsSlice';

export const checkBoxLabels = {
    readMessage: 'All Read Messages',
    unreadMessage: 'All Unread Messages',
};

export function FilterReadStatus() {
    const dispatch = useDispatch();
    const { read, unread } = useSelector((state) => state.communications.filters);

    const _handleFilterReadMessages = useCallback(() => {
        dispatch(toggleReadFilter());
    }, [dispatch]);

    const _handleFilterUnreadMessages = useCallback(() => {
        dispatch(toggleUnreadFilter());
    }, [dispatch]);

    return (
        <Box data-testid="message-filter">
            <CheckboxField
                checked={read}
                onChange={_handleFilterReadMessages}
                label={checkBoxLabels.readMessage}
            />
            <CheckboxField
                checked={unread}
                onChange={_handleFilterUnreadMessages}
                label={checkBoxLabels.unreadMessage}
            />
        </Box>
    );
}
