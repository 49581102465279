import { formatDistanceToNow } from 'date-fns';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';

export const formatDate = (date, fmt) => {
    const parsedDate = parseISO(date);
    if (isValid(parsedDate)) {
        return format(parsedDate, fmt);
    }
    return 'Invalid Date';
};

export const formatLocalDate = (date, fmt) => {
    return formatDate(date + 'Z', fmt);
};

// Based on formatDistanceToNow returns in date-fns https://date-fns.org/v3.4.0/docs/formatDistanceToNow
export const formatDateDistanceToNow = (date) => {
    const distance = formatDistanceToNow(date);

    const splitDistance = distance.split(' ');
    switch (true) {
        case splitDistance.includes('minute') || splitDistance.includes('minutes'):
            if (splitDistance[0] === 'less') {
                return '> 1min';
            }
            return `${splitDistance[0]}min`;
        case splitDistance.includes('hour') || splitDistance.includes('hours'):
            return `${splitDistance[1]}h`;
        case splitDistance.includes('day') || splitDistance.includes('days'):
            return `${splitDistance[0]}d`;
        case splitDistance.includes('month') || splitDistance.includes('months'):
            if (splitDistance.length === 2) {
                return `${splitDistance[0]}m`;
            }
            return `${splitDistance[1]}m`;
        case splitDistance.includes('year') || splitDistance.includes('years'):
            return `${splitDistance[1]}y`;
        default:
            return null;
    }
};
