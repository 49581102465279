import { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { MESSAGE_TYPES } from 'utils/constants';
import { TemplateButton } from 'features/MessageTemplate/components/TemplateButton';
import { AttachmentButton } from 'features/AttachmentUpload/components';
import { DynamicFieldsButton } from 'features/DynamicFields/components/DynamicFieldsButton';
import { InviteLinkButton } from 'components/MessageContent/InviteLinkButton';
import { FormattingButton } from 'components/MessageContent/FormattingButton';
import { SignatureButton } from 'features/Signature/components/SignatureButton';
import { Tooltip } from 'components/Tooltip';
import { useSwitchboard } from 'features/Switchboard';
import { defaultTooltips, getTooltipProps } from 'features/MessageDrawer/utils/tooltipHelpers';
import { DraftMessageButton } from './DraftMessageButton';

export const ToolbarButtonsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export const ToolbarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: '6px',
}));

export function MessageToolbar(props) {
    const {
        isToolbarDisabled,
        isCollapsed,
        isFormatting,
        attachments,
        handleOpen,
        messageType,
        signatureData,
        isFetchingData,
        handleSetSignature,
        handleRefreshSignature,
        body,
        subject,
        handleToggleFormatting,
        handleOpenTemplate,
        isCreateTemplateDisabled,
        isFetchingTemplates,
        handleTemplateCreateView,
        handleTemplateListsView,
        handleUseTemplate,
        templates,
        isGeneratingMessage,
    } = props;
    const disableAllToolbarButtons = isGeneratingMessage;
    const isDynamicFieldsEnabled = useSwitchboard('FEATURE_DYNAMIC_FIELDS');
    const isMessageTemplateEnabled = useSwitchboard('FEATURE_MESSAGE_TEMPLATES');

    const [tooltipsState, setTooltipsState] = useState(defaultTooltips);

    return (
        <ToolbarButtonsWrapper>
            <ToolbarContainer>
                {!isToolbarDisabled && (
                    <Tooltip
                        {...getTooltipProps(tooltipsState, 'formatting-options', setTooltipsState)}
                    >
                        <span>
                            <FormattingButton
                                isCollapsed={isCollapsed}
                                isFormatting={isFormatting}
                                onToggleFormatting={handleToggleFormatting}
                                data-trackid="message-options-formatting"
                                disabled={disableAllToolbarButtons}
                                disableShadow
                            />
                        </span>
                    </Tooltip>
                )}
                {isDynamicFieldsEnabled && (
                    <Tooltip
                        {...getTooltipProps(
                            tooltipsState,
                            'dynamic-fields-tooltip',
                            setTooltipsState
                        )}
                    >
                        <span>
                            <DynamicFieldsButton
                                data-trackid="message-options-dynamic-variables"
                                disabled={disableAllToolbarButtons}
                                disableShadow
                            />
                        </span>
                    </Tooltip>
                )}

                {Boolean(attachments) && (
                    <Tooltip
                        {...getTooltipProps(tooltipsState, 'attachments-tooltip', setTooltipsState)}
                    >
                        <span>
                            <AttachmentButton
                                onClick={handleOpen}
                                aria-label="add-attachment-to-message"
                                data-trackid="message-options-attachments"
                                disabled={disableAllToolbarButtons}
                                disableShadow
                            />
                        </span>
                    </Tooltip>
                )}
                {messageType === MESSAGE_TYPES.EMAIL && typeof handleSetSignature === 'function' && (
                    <Tooltip
                        {...getTooltipProps(tooltipsState, 'signatures-tooltip', setTooltipsState)}
                    >
                        <span>
                            <SignatureButton
                                signatures={signatureData}
                                isFetching={isFetchingData}
                                onUseSignature={handleSetSignature}
                                onRefreshSignature={handleRefreshSignature}
                                data-trackid="message-options-signature"
                                disabled={disableAllToolbarButtons}
                                disableShadow
                            />
                        </span>
                    </Tooltip>
                )}
                <Tooltip
                    {...getTooltipProps(tooltipsState, 'invitation-tooltip', setTooltipsState)}
                >
                    <span>
                        <InviteLinkButton
                            messageType={messageType}
                            openInNewTab
                            data-trackid="message-options-add-invite"
                            disabled={disableAllToolbarButtons}
                            disableShadow
                            compact
                        />
                    </span>
                </Tooltip>
                {isMessageTemplateEnabled && (
                    <Tooltip
                        {...getTooltipProps(tooltipsState, 'templates-tooltip', setTooltipsState)}
                    >
                        <span>
                            <TemplateButton
                                initialHtml={body.innerHtml}
                                initialSubject={subject.innerHtml}
                                onSaveNewTemplate={handleOpenTemplate}
                                isCreateTemplateDisabled={isCreateTemplateDisabled}
                                isFetchingTemplates={isFetchingTemplates}
                                onTemplateCreateView={handleTemplateCreateView}
                                onTemplateListsView={handleTemplateListsView}
                                onUseTemplate={handleUseTemplate}
                                templates={templates}
                                messageType={messageType}
                                data-trackid="message-options-message-templates"
                                disabled={disableAllToolbarButtons}
                                disableShadow
                                compact
                            />
                        </span>
                    </Tooltip>
                )}
            </ToolbarContainer>
            <DraftMessageButton />
        </ToolbarButtonsWrapper>
    );
}

MessageToolbar.propTypes = {
    isToolbarDisabled: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    isFormatting: PropTypes.bool,
    attachments: PropTypes.array,
    handleOpen: PropTypes.func,
    messageType: PropTypes.string,
    signatureData: PropTypes.array,
    isFetchingData: PropTypes.bool,
    handleSetSignature: PropTypes.func,
    handleRefreshSignature: PropTypes.func,
    body: PropTypes.object,
    subject: PropTypes.object,
    handleToggleFormatting: PropTypes.func,
    handleOpenTemplate: PropTypes.func,
    isCreateTemplateDisabled: PropTypes.bool,
    isFetchingTemplates: PropTypes.bool,
    handleTemplateCreateView: PropTypes.func,
    handleTemplateListsView: PropTypes.func,
    handleUseTemplate: PropTypes.func,
    templates: PropTypes.array,
    isGeneratingMessage: PropTypes.bool,
};
