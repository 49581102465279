import SvgIcon from '@mui/material/SvgIcon';

export function CanceledStatusIndicator(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <rect width="12" height="12" rx="6" fill="currentColor" />
            <path
                d="M6.56891 6.00051L8.87301 3.6929C9.03149 3.53417 9.03149 3.27777 8.87301 3.11904C8.71453 2.96032 8.45851 2.96032 8.30003 3.11904L5.99594 5.42666L3.69184 3.11904C3.53336 2.96032 3.27734 2.96032 3.11886 3.11904C2.96038 3.27777 2.96038 3.53417 3.11886 3.6929L5.42296 6.00051L3.11886 8.30812C2.96038 8.46685 2.96038 8.72325 3.11886 8.88198C3.20014 8.96337 3.30173 9 3.40738 9C3.51304 9 3.61463 8.9593 3.6959 8.88198L6 6.57436L8.30409 8.88198C8.38537 8.96337 8.48696 9 8.59262 9C8.69827 9 8.79986 8.9593 8.88114 8.88198C9.03962 8.72325 9.03962 8.46685 8.88114 8.30812L6.57704 6.00051H6.56891Z"
                fill="white"
            />
        </SvgIcon>
    );
}
