import SvgIcon from '@mui/material/SvgIcon';

export function ExclamationWarningIcon(props) {
    return (
        <SvgIcon viewBox="0 0 14 14" {...props}>
            <path
                d="M3.30532 9.36662H1.50806L1.20851 0H3.58489L3.30532 9.36662ZM2.36675 14C1.548 14 0.84906 13.3809 0.84906 12.5221C0.84906 11.6833 1.548 11.0442 2.36675 11.0442C3.20547 11.0442 3.92438 11.6833 3.92438 12.5221C3.92438 13.3809 3.20547 14 2.36675 14Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
