import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Skeleton } from 'components/Skeleton';
import { Typography } from 'components/Typography';

export function MediaOwner({ sourceTalentId, sourceUserId, talent }) {
    const userId = useSelector((state) => state.user.id);

    if (sourceTalentId && !talent?.[sourceTalentId]) {
        return (
            <Skeleton
                height={16}
                width="100%"
                sx={{ borderRadius: 1 }}
                animation="wave"
                variant="rectangular"
            />
        );
    }

    let label = userId === sourceUserId ? 'You' : 'Your Org';
    if (sourceTalentId) label = talent?.[sourceTalentId]?.name;
    return <Typography variant="bodyBase">{label}</Typography>;
}

MediaOwner.propTypes = {
    talent: PropTypes.object,
    sourceTalentId: PropTypes.string,
    sourceUserId: PropTypes.string,
};
