import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';

import { useDispatch, useSelector } from 'react-redux';

import { CheckBoxSelectedIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { setSources } from '../../../slices/mediaSlice';
import { SelectButtons } from './SelectButtons';
import { MatchText } from './MatchText';

export function SourceContent({ sources, isLoading }) {
    const [matchText, setMatchText] = useState('');

    const selectedSources = useSelector((state) => state.media.sources);

    const dispatch = useDispatch();

    const filteredSources = useMemo(() => {
        if (matchText) {
            const staging = [];
            sources.forEach((tag) => {
                if (tag.toLocaleLowerCase().includes(matchText.toLocaleLowerCase())) {
                    staging.push(tag);
                }
            });
            return staging;
        }
        return sources;
    }, [sources, matchText]);

    const handleCheckoggle = (event) => {
        const staging = [...selectedSources];
        if (event.target.checked) {
            staging.push(event.target.value);
        } else {
            const index = staging.findIndex((e) => e === event.target.value);
            if (index >= 0) {
                staging.splice(index, 1);
            }
        }
        dispatch(setSources(staging));
    };

    const handleDeselectAll = () => {
        dispatch(setSources([]));
    };

    const handleSelectAll = () => {
        dispatch(setSources(filteredSources));
    };

    const handleMatchText = (e) => {
        setMatchText(e.target.value);
    };

    if (isLoading)
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: (t) => t.spacing(40),
                    minHeight: (t) => t.spacing(40),
                }}
            >
                <CircularProgress size={120} />
            </Box>
        );

    return (
        <Box
            aria-label="Filter by Source"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 1,
                minWidth: (t) => t.spacing(40),
            }}
        >
            <MatchText value={matchText} onChange={handleMatchText} />
            <SelectButtons onSelectAll={handleSelectAll} onDeselectAll={handleDeselectAll} />
            {filteredSources.map((source) => (
                <FormControlLabel
                    key={source}
                    sx={{ mr: 0 }}
                    control={
                        <Checkbox
                            value={source}
                            checked={selectedSources.includes(source)}
                            onChange={handleCheckoggle}
                            checkedIcon={<CheckBoxSelectedIcon color="success" />}
                            inputProps={{ 'aria-label': `Option: ${source}` }}
                            disableRipple
                        />
                    }
                    label={<Typography variant="titleSmall">{source}</Typography>}
                    disableTypography
                />
            ))}
        </Box>
    );
}

SourceContent.propTypes = {
    sources: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
};
