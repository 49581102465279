import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { CheckCircleOutlineIcon, CloseIcon } from 'components/Icons';

export function MessageEditActions(props) {
    const { updateMessage, isDisabled, onCancelEdit } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mr: 2,
            }}
        >
            <IconButton
                onClick={updateMessage}
                disabled={isDisabled}
                data-testid="confirm-message-edit-button"
                color="success"
                aria-label="Confirm changes"
            >
                <CheckCircleOutlineIcon sx={{ fontSize: 24 }} />
            </IconButton>
            <IconButton
                onClick={onCancelEdit}
                aria-label="Dismiss changes"
                sx={{
                    color: (theme) => alpha(theme.palette.text.primary, 0.24),
                    fontSize: 16,
                }}
            >
                <CloseIcon />
            </IconButton>
        </Box>
    );
}

MessageEditActions.propTypes = {
    updateMessage: PropTypes.func,
    isDisabled: PropTypes.bool,
    onCancelEdit: PropTypes.func,
};
