import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import { ChevronRightIcon } from 'components/Icons';

export function DrawerMinimizeButton(props) {
    const { isMinimized, onMinimizeToggle } = props;

    return (
        <Button
            aria-label="minimize-drawer-button"
            sx={{
                position: 'fixed',
                width: 48,
                height: 48,
                minWidth: 48,
                background: 'white',
                transform: 'translate(-48px, 18px)',
                boxShadow:
                    '-1px 0px 2px 0px rgba(63, 83, 100, 0.32), -1px 0px 6px 2px rgba(0, 0, 0, 0.15)',
                borderRadius: '4px 0 0 4px',
                '&:hover': {
                    background: 'white',
                },
            }}
            onClick={onMinimizeToggle}
        >
            <ChevronRightIcon
                sx={{
                    fontSize: 36,
                    color: 'primary.main',
                    transform: isMinimized ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 200ms',
                }}
            />
        </Button>
    );
}

DrawerMinimizeButton.propTypes = {
    isMinimized: PropTypes.bool,
    onMinimizeToggle: PropTypes.func,
};
