import SvgIcon from '@mui/material/SvgIcon';

export function CommunicationIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M26.6733 9.65807H17.4415C14.2575 9.65807 11.6656 12.25 11.6656 15.434V22.3619C11.6656 25.5458 14.2575 28.1378 17.4415 28.1378H24.3694C25.8893 28.1378 27.8413 30.4417 29.5533 30.4417C31.5852 30.4417 32.4492 28.8418 32.4492 26.9698V15.418C32.4492 12.234 29.8573 9.64208 26.6733 9.64208V9.65807ZM29.2493 26.9858C29.0093 26.8578 28.7533 26.6978 28.5453 26.5538C27.4413 25.8338 26.0493 24.9378 24.3694 24.9378H17.4415C16.0175 24.9378 14.8655 23.7858 14.8655 22.3619V15.434C14.8655 14.01 16.0175 12.858 17.4415 12.858H26.6733C28.0973 12.858 29.2493 14.01 29.2493 15.434V26.9858Z"
                fill="currentColor"
            />
            <path
                d="M7.64962 22.7619L7.24963 23.0179C6.52964 23.4818 5.63364 24.0578 5.13765 24.0578C4.46566 24.0578 4.20971 23.8018 4.03371 23.5938C3.76172 23.2259 3.60171 22.6339 3.61771 21.9299V9.46608C3.6337 6.92212 5.71368 4.84216 8.27363 4.84216H18.2575C19.5694 4.85816 20.8334 5.41814 21.7134 6.41013C21.9374 6.66612 22.1454 6.93813 22.3054 7.22613C22.7374 7.99411 23.7134 8.26609 24.4814 7.8341C25.2494 7.40211 25.5213 6.42612 25.0893 5.65814C24.8173 5.16214 24.4813 4.69816 24.0973 4.26617C22.6094 2.6022 20.4814 1.6582 18.2735 1.6582H8.2896C3.96967 1.6582 0.46574 5.14615 0.449741 9.46608V21.9299C0.433741 23.3379 0.785725 24.5858 1.48971 25.5138C2.3377 26.6498 3.63369 27.2738 5.15367 27.2738C6.59364 27.2738 7.87361 26.4418 8.99359 25.7058L9.3616 25.4658C10.1136 24.9858 10.3216 23.9938 9.85759 23.2578C9.3776 22.5059 8.38561 22.2979 7.64962 22.7619Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
