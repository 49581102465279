import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

import { Tooltip } from 'components/Tooltip';
import { useDynamicFields } from 'features/DynamicFields';
import { DynamicFieldsButton } from 'features/DynamicFields/components/DynamicFieldsButton';
import { DynamicFieldsFallback } from 'features/DynamicFields/components/DynamicFieldsFallback';
import { defaultTooltips, getTooltipProps } from 'features/MessageDrawer/utils/tooltipHelpers';

const ToolbarButtonsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(-1.5),
    justifyContent: 'space-between',
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    '& > *:not(:last-child)': {
        marginRight: theme.spacing(0.75),
    },
}));

export function TemplateFooter({ disableShadow }) {
    const { defaultDynamicFields } = useDynamicFields();

    const [tooltipsState, setTooltipsState] = useState(defaultTooltips);

    return (
        <>
            <ToolbarButtonsWrapper>
                <StyledBox>
                    <Tooltip
                        {...getTooltipProps(
                            tooltipsState,
                            'dynamic-fields-tooltip',
                            setTooltipsState
                        )}
                    >
                        <span>
                            <DynamicFieldsButton disableShadow={disableShadow} />
                        </span>
                    </Tooltip>
                </StyledBox>
            </ToolbarButtonsWrapper>

            {Boolean(Object.keys(defaultDynamicFields)?.length) && <DynamicFieldsFallback />}
        </>
    );
}

TemplateFooter.propTypes = {
    disableShadow: PropTypes.bool,
};
