import SvgIcon from '@mui/material/SvgIcon';

export function FilterAltIcon(props) {
    return (
        <SvgIcon viewBox="0 0 14 14" {...props}>
            <path
                d="M1.00599 3.67917C0.999988 3.12117 1.21 2.59316 1.59999 2.19716C1.98399 1.80717 2.49398 1.59717 3.03397 1.59717H11.0499C11.5599 1.59717 12.0339 1.78919 12.3939 2.14919C12.7839 2.53918 13.0059 3.10318 12.9999 3.68517C12.9999 4.27917 12.7359 4.84313 12.2919 5.23313L9.20191 7.98115C9.08191 8.08915 8.94992 8.40109 8.94992 8.67709V10.6091C8.94992 11.0111 8.73392 11.3891 8.38593 11.5991L8.29593 11.6471L6.72393 12.265C6.55593 12.355 6.36994 12.4031 6.18394 12.4031C5.99194 12.4031 5.79994 12.3551 5.61994 12.2531C5.25395 12.0491 5.03196 11.6591 5.03196 11.2391V8.56313C5.03196 8.42513 4.89395 8.14913 4.67795 7.95713L1.67199 5.17915C1.25199 4.80116 1.006 4.25516 1 3.67917H1.00599ZM7.76793 10.5611L7.74993 8.67108C7.74993 8.16109 7.95392 7.47115 8.40991 7.07515L11.4999 4.32717C11.6919 4.15917 11.7999 3.92516 11.7999 3.67316C11.7999 3.40916 11.7039 3.15716 11.5419 2.98916C11.4459 2.89316 11.2899 2.78518 11.0499 2.78518H3.03998C2.81798 2.79118 2.60797 2.88117 2.45198 3.03717C2.28998 3.20517 2.19998 3.42115 2.20598 3.65514V3.67316C2.20598 3.90716 2.31398 4.13517 2.48797 4.29117L5.49395 7.06915C5.83595 7.38114 6.24394 7.95713 6.24394 8.56313V11.2391"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
