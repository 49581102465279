import { useState, useEffect } from 'react';
import { MESSAGE_TYPES } from 'utils/constants';
import { useDialog } from './useDialog';
import { useSelector } from 'react-redux';
import { useSwitchboard } from 'features/Switchboard';
import { useUpgradeAccountPrompt } from './UpgradeAccountPrompt';

export function useContactDialog(props) {
    const {
        talentEmailStatuses,
        selectedCandidates,
        setShouldReserveEmails,
        profiles,
        handleSetProfiles,
    } = props;
    const [previouslyContactedCandidates, setPreviouslyContactedCandidates] = useState([]);
    const [messageType, setMessageType] = useState(MESSAGE_TYPES.EMAIL);
    const isSmsEnabled = useSwitchboard('FEATURE_SMS_CAPABILITY');
    const isPaid = useSelector((state) => state.user?.isPaid);
    const { openModal } = useUpgradeAccountPrompt();

    const isSingleCandidate = selectedCandidates?.length === 1;

    const onConfirm = () => {
        setShouldReserveEmails(true);
        if (profiles?.length) handleSetProfiles(profiles);
        // clean up previous contact state
        setPreviouslyContactedCandidates([]);
    };

    const { isOpen, handleOpen, handleConfirm, handleClose } = useDialog({
        onConfirm: onConfirm,
    });

    const handleMessage = (type) => {
        if (type === MESSAGE_TYPES.SMS && (!isPaid || (!isSmsEnabled && isPaid))) {
            openModal();
            return;
        } else {
            setMessageType(type);
        }
        if (
            (!isSingleCandidate && previouslyContactedCandidates.length === 0) ||
            (isSingleCandidate && !selectedCandidates[0]?.['currentStatus'])
        ) {
            onConfirm();
        } else {
            handleOpen();
        }
    };

    useEffect(() => {
        if (!isSingleCandidate && selectedCandidates?.length) {
            setPreviouslyContactedCandidates(() => {
                const result = [];
                selectedCandidates?.forEach((talent) => {
                    if (talentEmailStatuses?.user?.[talent.talentId]) {
                        result.push({
                            ...talent,
                            userEmailStatus: talentEmailStatuses?.user?.[talent.talentId]?.[0],
                            lastUserEmailDate: talentEmailStatuses?.user?.[talent.talentId]?.[1],
                            organizationEmailStatus:
                                talentEmailStatuses?.organization?.[talent.talentId]?.[0],
                            lastOrganizationEmailDate:
                                talentEmailStatuses?.organization?.[talent.talentId]?.[1],
                        });
                    }
                    if (talent?.lastUserEmailDate) {
                        result.push(talent);
                    }
                });
                return result;
            });
        } else if (isSingleCandidate && selectedCandidates?.length) {
            setPreviouslyContactedCandidates([selectedCandidates[0]]);
        }
    }, [selectedCandidates, talentEmailStatuses, isSingleCandidate]);

    return {
        previouslyContactedCandidates,
        isOpen,
        messageType,
        handleConfirm,
        handleMessage,
        handleClose,
    };
}
