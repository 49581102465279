import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import { ListItemIcon, Typography } from '@mui/material';
import { CheckIcon } from 'components/Icons';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        marginTop: theme.spacing(1),
        minWidth: 180,
        boxShadow: theme.shadows[2],
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
        },
    },
}));

export function DropdownMenu(props) {
    const {
        anchorEl,
        selectedOptions = [],
        options = [],
        onSelectedOptionChange,
        onClose,
        open,
        multiple,
        useCheckboxes,
    } = props;

    return (
        <StyledMenu anchorEl={anchorEl} open={open} onClose={onClose}>
            {options.map((option, index) => {
                const { label: optionLabel, value, dataTrackId } = option;

                const isSelectedOption = selectedOptions.find(
                    (selectedOption) => selectedOption.value === value
                );

                return (
                    <MenuItem
                        data-testid={`${value}-menu-item`}
                        data-trackid={dataTrackId}
                        onClick={() => {
                            if (multiple) {
                                if (isSelectedOption) {
                                    onSelectedOptionChange(
                                        selectedOptions.filter(
                                            (selectedOption) => selectedOption.value !== value
                                        )
                                    );
                                } else {
                                    onSelectedOptionChange([...selectedOptions, option]);
                                }
                            } else {
                                onSelectedOptionChange([option]);
                            }
                            onClose();
                        }}
                        key={index}
                        disableRipple
                    >
                        {useCheckboxes && isSelectedOption && (
                            <ListItemIcon>
                                <CheckIcon />
                            </ListItemIcon>
                        )}

                        <ListItemText inset={useCheckboxes && !isSelectedOption}>
                            <Typography
                                sx={{
                                    color: 'text.primary',
                                    mr: 1,
                                    fontSize: 14,
                                }}
                            >
                                {optionLabel}
                            </Typography>
                        </ListItemText>
                    </MenuItem>
                );
            })}
        </StyledMenu>
    );
}

const OptionPropType = PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
});

DropdownMenu.propTypes = {
    anchorEl: PropTypes.object,
    selectedOptions: PropTypes.arrayOf(OptionPropType),
    options: PropTypes.arrayOf(OptionPropType),
    onSelectedOptionChange: PropTypes.func,
    useCheckboxes: PropTypes.bool,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    multiple: PropTypes.bool,
};
