import { toast } from 'react-toastify';
import { useUpdateMediaConnectionsMutation } from 'services/knowledgeBases';

export const useRemoveMediaConnection = (attachment) => {
    const [updateMedia, { isLoading }] = useUpdateMediaConnectionsMutation();

    const handleRemoveMediaConnection = async (talentId) => {
        if (!attachment.entityConnections) return;
        const res = await updateMedia({
            body: {
                entityConnections: attachment.entityConnections.filter(
                    (connection) => connection.entityId !== talentId
                ),
            },
            mediaId: attachment.mediaId,
            mediaType: 'file',
        });
        if (res?.data) {
            toast.success('Removed attachment from profile successfully');
        } else {
            toast.error('Could not remove attachment from profile');
        }
        return res?.data;
    };

    return { handleRemoveMediaConnection, isLoading };
};
