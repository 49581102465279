import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { SendMessageIcon, TimeIcon } from 'components/Icons';
import { useSwitchboard } from 'features/Switchboard';
import { CircularProgress } from '@mui/material';

const SendNowSxProps = {
    py: (theme) => theme.spacing(1),
    m: (theme) => theme.spacing(0, 1, 0, 0),
};

const ScheduleIconSxProps = {
    p: (theme) => theme.spacing(1, 1, 1, 0),
    m: (theme) => theme.spacing(0),
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
        content: '"|"',
    },
};
export function SendButton(props) {
    const { isDisabled, isEditing, isSending, setOpen, anchor, onClick } = props;

    const isScheduleFeatureEnabled = useSwitchboard('FEATURE_SCHEDULED_MESSAGES');

    return (
        <Button
            data-trackid="message-send-now"
            ref={anchor}
            role="button"
            variant="contained"
            color="success"
            startIcon={
                isSending ? (
                    <CircularProgress size={20} sx={{ color: 'gray' }} />
                ) : (
                    <SendMessageIcon />
                )
            }
            aria-label="compose-message-send-button"
            disabled={isDisabled}
            onClick={() => {
                onClick();
                setOpen(false);
            }}
            sx={{
                padding: (theme) => theme.spacing(0, 0, 0, 1),
                color: (theme) => theme.palette.common.white,
            }}
        >
            <Box sx={SendNowSxProps}>Send</Box>
            {!isEditing && isScheduleFeatureEnabled && (
                <Box
                    sx={ScheduleIconSxProps}
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen((prev) => !prev);
                    }}
                    data-trackid="message-send-later-options"
                >
                    <TimeIcon
                        title="Open scheduled message popup"
                        aria-label="Open scheduled message popup"
                        sx={{ ml: 0.75, fontSize: 24 }}
                    />
                </Box>
            )}
        </Button>
    );
}

SendButton.propTypes = {
    isSending: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isEditing: PropTypes.bool,
    setOpen: PropTypes.func,
    anchor: PropTypes.object,
    onClick: PropTypes.func,
};
