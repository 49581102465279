import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const systemEmailsApi = createApi({
    reducerPath: 'systemEmailsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/system-email/v1',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    endpoints: (builder) => ({
        sendCreditRequest: builder.mutation({
            query: (creditType) => ({
                url: '/notifications/credits',
                method: 'POST',
                body: { creditType },
            }),
        }),
        sendFeedbackRequest: builder.mutation({
            query: () => ({
                url: '/notifications/feedback-session',
                method: 'POST',
            }),
        }),
        sendJoinWaitlistRequest: builder.mutation({
            query: (body) => ({
                url: '/notifications/personal_signup',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useSendCreditRequestMutation,
    useSendFeedbackRequestMutation,
    useSendJoinWaitlistRequestMutation,
} = systemEmailsApi;
