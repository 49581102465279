export const buildGetRequestWithMultipleParams = (url, paramsArr) => {
    const urlObj = new URL(url);
    let params = new URLSearchParams(urlObj.search);
    paramsArr.forEach((paramObj) => {
        params.append(Object.keys(paramObj)[0], Object.values(paramObj)[0]);
    });
    return url + '?' + params.toString();
};

export const buildGetRequest = (url, params) => {
    return url + '?' + new URLSearchParams(params).toString();
};

export const getFetch = async (url, headers) => {
    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            ...headers,
        },
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

export const mutateFetch = async (url, method, headers, data = {}) => {
    const response = await fetch(url, {
        method,
        mode: 'cors',
        credentials: 'include',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
        body: Array.isArray(data) ? JSON.stringify(data) : JSON.stringify({ ...data }),
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};

export const deleteFetch = async (url, headers) => {
    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
    });
    if (!response.ok) {
        throw new Error(response.status);
    }
    return response.json();
};
