import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { Box } from '@mui/material';

import { useUpgradeAccountPrompt } from 'components/Dialog/UpgradeAccountPrompt';
import { useSwitchboard } from 'features/Switchboard';
import { Typography } from 'components/Typography';
import { AddIcon } from 'components/Icons';
import { CONTACT_TYPES } from '../utils/constants';
import { CustomContactInput } from './CustomContactInput';

const getDataTrackId = (contactType) => {
    if (contactType === CONTACT_TYPES.EMAIL) {
        return 'lookup-modal-new-email';
    }
    if (contactType === CONTACT_TYPES.PHONE) {
        return 'lookup-modal-new-sms';
    }
    if (contactType === CONTACT_TYPES.SOCIAL) {
        return 'lookup-modal-new-social';
    }
};
export function CustomContactInputButtonToggle({
    contactType,
    contactCategories,
    onCreateCustomContact,
    isLoading,
}) {
    const [uiState, setUIState] = useState('button');
    const isPaid = useSelector((state) => state.user?.isPaid);
    const isSmsEnabled = useSwitchboard('FEATURE_SMS_CAPABILITY');
    const { openModal } = useUpgradeAccountPrompt();

    const handleClick = () => {
        if (!isPaid || (!isSmsEnabled && isPaid)) {
            openModal();
            return;
        }
        setUIState('input');
    };

    return (
        <Box aria-label="add-custom-contact-button">
            <Fade
                in={uiState === 'button'}
                style={{ display: uiState === 'button' ? 'block' : 'none' }}
                timeout={500}
            >
                <Box sx={{ height: 40 }}>
                    <Button
                        sx={{
                            paddingLeft: 0,
                            '&:hover': { background: 'transparent' },
                        }}
                        data-testid="add-custom-contact-button"
                        data-trackid={getDataTrackId(contactType)}
                        role="button"
                        endIcon={<AddIcon />}
                        onClick={handleClick}
                        disableRipple
                    >
                        <Typography variant="titleSmall" sx={{ textTransform: 'capitalize' }}>
                            {contactType === CONTACT_TYPES.EMAIL && 'New Custom Email'}
                            {contactType === CONTACT_TYPES.PHONE && 'New Custom Phone Number'}
                            {contactType === CONTACT_TYPES.SOCIAL && 'New Social Link'}
                        </Typography>
                    </Button>
                </Box>
            </Fade>
            <Fade
                in={uiState === 'input'}
                style={{ display: uiState === 'input' ? 'block' : 'none' }}
                timeout={500}
            >
                <Box data-testid="add-custom-contact-input" sx={{ height: 40 }}>
                    <CustomContactInput
                        isLoading={isLoading}
                        onSave={(formValue) => {
                            if (contactType !== CONTACT_TYPES.SOCIAL && !formValue.option?.value)
                                return;
                            onCreateCustomContact({
                                value: formValue.textValue,
                                category: formValue.option?.value || '',
                                medium: contactType,
                                primary: formValue.primary,
                            });
                        }}
                        contactType={contactType}
                        onClose={() => setUIState('button')}
                        contactCategories={
                            contactCategories?.map?.((value) => ({
                                label: value.charAt(0).toUpperCase() + value.slice(1),
                                value,
                            })) || []
                        }
                    />
                </Box>
            </Fade>
        </Box>
    );
}

CustomContactInputButtonToggle.propTypes = {
    contactType: PropTypes.oneOf(Object.values(CONTACT_TYPES)),
    contactCategories: PropTypes.arrayOf(PropTypes.string),
    onCreateCustomContact: PropTypes.func,
    isLoading: PropTypes.bool,
};
