import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { CopyIcon } from 'components/Icons';
import { toast } from 'react-toastify';

export function CopyContactButton({ ariaLabel, contactData }) {
    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(contactData);
        toast.success('Copied to Clipboard');
    };

    return (
        <IconButton
            aria-label={ariaLabel}
            onClick={handleCopyClick}
            disableRipple
            sx={{ p: 0, mb: 0.1 }}
        >
            <CopyIcon sx={{ width: '10px', height: '10px', color: 'primary.main' }} />
        </IconButton>
    );
}

CopyContactButton.propTypes = {
    ariaLabel: PropTypes.string,
    contactData: PropTypes.string,
};
