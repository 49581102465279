import SvgIcon from '@mui/material/SvgIcon';

export function NewChevronLeftIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M22.9758 32.3203C22.5137 32.3203 22.0693 32.1426 21.7138 31.8049L9.66216 19.7532C7.77798 17.869 7.77798 14.7939 9.66216 12.8919L21.7138 0.84024C22.407 0.147003 23.5269 0.147003 24.2201 0.84024C24.9134 1.53348 24.9134 2.65338 24.2201 3.34662L12.1685 15.3982C11.6708 15.8959 11.6708 16.7136 12.1685 17.2291L24.2201 29.2807C24.9134 29.9739 24.9134 31.0938 24.2201 31.7871C23.8646 32.1426 23.4202 32.3025 22.958 32.3025L22.9758 32.3203Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
