import SvgIcon from '@mui/material/SvgIcon';

export function MediaImageIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M21.6928 3.20001H10.3071C6.38396 3.20001 3.19995 6.38402 3.19995 10.3072V21.6929C3.19995 25.616 6.38396 28.8 10.3071 28.8H21.6928C25.6159 28.8 28.7999 25.616 28.7999 21.6929V10.3072C28.7999 6.38402 25.6159 3.20001 21.6928 3.20001ZM10.3071 6.04288H21.6928C24.0382 6.04288 25.9571 7.96181 25.9571 10.3072V21.039L24.0097 19.4328C23.0147 18.6083 21.508 18.651 20.5699 19.5181L18.6367 21.3233L13.5764 16.6752C12.5245 15.7371 10.8473 15.7797 9.86647 16.7463L6.04282 20.3567V10.3072C6.04282 7.96181 7.96175 6.04288 10.3071 6.04288ZM6.59717 23.7397L11.757 18.8642L19.4611 25.9429H10.3071C8.70089 25.9429 7.3221 25.0474 6.59717 23.7397ZM23.3132 25.616L20.7262 23.2422L22.3466 21.7355L25.2037 24.0951C24.7347 24.7774 24.0808 25.3033 23.3132 25.616Z"
                fill="currentColor"
            />
            <path
                d="M20.2571 15.9929C22.6167 15.9929 24.5214 14.0882 24.5214 11.7286C24.5214 9.36903 22.6167 7.46431 20.2571 7.46431C17.8976 7.46431 15.9928 9.36903 15.9928 11.7286C15.9928 14.0882 17.8976 15.9929 20.2571 15.9929ZM20.2571 10.3072C21.0389 10.3072 21.6786 10.9468 21.6786 11.7286C21.6786 12.5104 21.0389 13.15 20.2571 13.15C19.4754 13.15 18.8357 12.5104 18.8357 11.7286C18.8357 10.9468 19.4754 10.3072 20.2571 10.3072Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
