import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { MEDIA_ACTIVITY_SUBTYPE } from 'utils';

export function MediaContent({ activitySubType }) {
    const label = useMemo(() => {
        return activitySubType === MEDIA_ACTIVITY_SUBTYPE.ATTACHED_TO_TALENT
            ? 'Attached media to talent'
            : 'Removed media from talent';
    }, [activitySubType]);

    return <>{label}</>;
}

MediaContent.propTypes = {
    activitySubType: PropTypes.string,
};
