import SvgIcon from '@mui/material/SvgIcon';

export function MediaSheetIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M21.6888 3.20001H10.3111C6.38573 3.20001 3.19995 6.38579 3.19995 10.3111V21.6889C3.19995 25.6142 6.38573 28.8 10.3111 28.8H21.6888C25.6142 28.8 28.7999 25.6142 28.7999 21.6889V10.3111C28.7999 6.38579 25.6142 3.20001 21.6888 3.20001ZM10.3111 6.04446H21.6888C24.0355 6.04446 25.9413 7.95023 25.9555 10.2827H6.0444C6.0444 7.93601 7.9644 6.04446 10.3111 6.04446ZM13.1555 13.1271H18.8444V25.9556H13.1555V13.1271ZM6.0444 21.6889V13.1271H10.3111V25.9556C7.9644 25.9556 6.0444 24.0356 6.0444 21.6889ZM21.6888 25.9556V13.1271H25.9555V21.6889C25.9555 24.0356 24.0355 25.9556 21.6888 25.9556Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
