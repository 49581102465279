import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    height: theme.spacing(5),
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
        backgroundColor: theme.palette.success.light,
    },
    '&.Mui-disabled': {
        backgroundColor: alpha(theme.palette.text.primary, 0.12),
        color: alpha(theme.palette.text.primary, 0.48),
    },
}));
