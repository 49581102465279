import SvgIcon from '@mui/material/SvgIcon';

export function NotesEyeIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M7.9822 12.6113C5.33764 12.6113 3.42468 10.6744 2.51917 9.52298C1.80556 8.61148 1.82954 7.35816 2.57913 6.47064C3.76649 5.06141 5.67944 3.38232 8.01217 3.38232C10.3449 3.38232 12.2579 5.06741 13.4392 6.48863C14.1768 7.37615 14.1888 8.67145 13.4632 9.56496C12.3358 10.9562 10.4708 12.6173 7.98819 12.6173L7.9822 12.6113ZM8.01217 4.58167C6.14119 4.58167 4.52207 6.03288 3.49663 7.24422C3.11884 7.69398 3.10684 8.32363 3.46665 8.78538C4.24023 9.76885 5.85335 11.4179 7.98819 11.4179C10.0031 11.4179 11.5742 10.0027 12.5337 8.80937C12.9055 8.35362 12.8995 7.71197 12.5157 7.25621C11.6822 6.26076 9.99709 4.58167 8.01217 4.58167Z"
                fill="currentColor"
            />
            <path
                d="M8.0061 10.3925C6.68083 10.3925 5.60741 9.31906 5.60741 7.99378C5.60741 6.6685 6.68083 5.59508 8.0061 5.59508C9.33138 5.59508 10.4048 6.6685 10.4048 7.99378C10.4048 9.31906 9.33138 10.3925 8.0061 10.3925ZM8.0061 6.79443C7.34646 6.79443 6.80676 7.33414 6.80676 7.99378C6.80676 8.65342 7.34646 9.19312 8.0061 9.19312C8.66575 9.19312 9.20545 8.65342 9.20545 7.99378C9.20545 7.33414 8.66575 6.79443 8.0061 6.79443Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
