import {
    forwardRef,
    useRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
    useMemo,
} from 'react';
import { isValidElementType } from 'react-is';
import { Editor, Text, Transforms } from 'slate';
import isHotkey from 'is-hotkey';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { ReactEditor, Editable, Slate } from 'slate-react';

import { Typography } from 'components/Typography';

import { Toolbar } from './Toolbar';
import { LeafWrapper } from './Leaf';
import { useEditor } from './hooks/useEditor';
import { toggleMark } from './MarkButton';
import { ElementWrapper } from './Element';
import { serialize, serializeToPlainText } from './serialize';

import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useDeclineText } from 'views/Communications';
import { DisabledTextWithTooltip } from './DisabledText';
import { theme } from 'theme';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
};

const sxProp = {
    root: {
        boxSizing: 'border-box',
    },
    label: {
        transition: (theme) => theme.transitions.create(['color']),
    },
    error: {
        color: (theme) => theme.palette.error.main,
    },
    disabled: {
        color: (theme) => theme.palette.action.disabled,
    },
};

export function createHandleKeyDown(editor) {
    return (event) => {
        for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
                event.preventDefault();
                toggleMark(editor, HOTKEYS[hotkey]);
            }
        }
    };
}

const calculateMinBodyHeight = (isInline, isCondensed, defaultHeight) => {
    if (isInline) {
        return theme.spacing(3);
    } else {
        if (defaultHeight) {
            return defaultHeight;
        }
        if (isCondensed) {
            return theme.spacing(10);
        }
        return theme.spacing(20);
    }
};

const calculateMaxBodyHeight = (isEditMode, isCondensed, defaultHeight) => {
    if (defaultHeight) {
        return defaultHeight;
    }
    if (isEditMode) {
        return theme.spacing(10);
    }
    if (isCondensed) {
        return theme.spacing(20);
    }
    return theme.spacing(40);
};

export const RichTextInput = memo(
    forwardRef((props, ref) => {
        const {
            readOnly: _readOnly,
            value,
            initialValue,
            onChange,
            FormHelperTextProps,
            InputLabelProps,
            InputProps,
            autoFocus,
            disabled,
            error,
            name,
            id,
            label,
            isCondensed,
            isToolbarDisabled,
            clearInput,
            helperText,
            fullWidth,
            CustomHeader,
            CustomHeaderProps,
            isEditMode,
            CustomFooter,
            CustomFooterProps,
            formattingButtonPopperStyles = {},
            decorator,
            initiateMount,
            bodyHeight,
            isInline,
            isFormatting,
            hasDeclineText,
            formattingToolbarPlacement,
            isBlurred,
            EditorWrapperSxProps,
            ...restProps
        } = props;

        const theme = useTheme();
        const editorRef = useRef();
        const richTextEditorWrapperRef = useRef();
        const firstMount = useRef(initiateMount);
        const [isFocused, setIsFocused] = useState(false);
        const minBodyHeight = calculateMinBodyHeight(isInline, isCondensed, bodyHeight);
        const maxBodyHeight = calculateMaxBodyHeight(isEditMode, isCondensed, bodyHeight);

        useImperativeHandle(
            ref,
            () => ({
                innerHTML() {
                    return editorRef.current?.innerHTML;
                },
                plainText() {
                    return editorRef.current?.plainText;
                },
                clientHeight() {
                    return editorRef.current?.clientHeight;
                },
            }),
            []
        );

        const { editor, editorValue, initialEditorValue, setEditorContext } = useEditor({
            clearInput,
            initialValue,
            value,
        });

        const { declineText, isLoading: isDeclineTextLoading } = useDeclineText({ hasDeclineText });

        const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
        const inputLabelId = label && id ? `${id}-label` : undefined;
        const readOnly = InputProps?.readOnly || _readOnly;

        const editableStyle = useMemo(
            () => ({
                minHeight: minBodyHeight,
                maxHeight: maxBodyHeight,
            }),
            [minBodyHeight, maxBodyHeight]
        );

        const handleChange = useCallback(
            (val) => {
                if (!firstMount.current && typeof onChange === 'function') {
                    const textarea = editorRef.current;
                    const plainText = serializeToPlainText(val);
                    editorRef.current.plainText = plainText;
                    const target = {
                        innerText: textarea?.innerText,
                        value: serialize(val),
                        plainText,
                        id,
                        name,
                    };
                    onChange({ target });
                    editorRef.current.focus();
                }
                firstMount.current = false;
            },
            [name, id, onChange]
        );

        const decorate = useCallback(
            ([node, path]) => {
                if (Text.isText(node) && decorator) {
                    return decorator(node, path);
                }
                return [];
            },
            [decorator]
        );

        // https://github.com/ianstormtaylor/slate/issues/3618#issuecomment-653635644
        // https://github.com/fakob/plug-and-play/pull/152#discussion_r991600747
        useEffect(() => {
            editorRef.current = ReactEditor.toDOMNode(editor, editor);
        }, [editor]);

        useEffect(() => {
            if (isBlurred) {
                ReactEditor.blur(editor);
            }
        }, [isBlurred, editor]);

        return (
            <FormControl
                sx={sxProp.root}
                disabled={disabled || readOnly}
                aria-readonly={readOnly}
                aria-disabled={disabled}
                error={error}
                fullWidth={fullWidth}
                {...restProps}
            >
                {label && (
                    <Typography
                        htmlFor={id}
                        id={inputLabelId}
                        component="label"
                        variant="labelMedium"
                        color="textPrimary"
                        sx={{
                            ...sxProp.label,
                            ...(disabled && sxProp.disabled),
                            ...(error && sxProp.error),
                        }}
                        {...InputLabelProps}
                    >
                        {label}
                    </Typography>
                )}

                <Slate
                    editor={editor}
                    initialValue={editorValue || initialEditorValue}
                    onChange={handleChange}
                >
                    {isValidElementType(CustomHeader) && <CustomHeader {...CustomHeaderProps} />}
                    <Box
                        data-testid="richtext-editor-wrapper"
                        ref={richTextEditorWrapperRef}
                        sx={{
                            borderRadius: '4px',
                            border: `1px solid ${
                                error ? theme.palette.error.main : theme.palette.divider
                            }`,
                            marginTop: theme.spacing(0.5),
                            position: 'relative',
                            overflowY: 'auto',
                            padding: theme.spacing(1),
                            minHeight: minBodyHeight,
                            maxHeight: maxBodyHeight,
                            width: fullWidth ? 'auto' : theme.spacing(87),
                            fontFamily: theme.typography.fontFamily,
                            outline: isFocused && '-webkit-focus-ring-color auto 1px',
                            '.nowheel:focus-visible': {
                                outline: 'none',
                            },
                            ...EditorWrapperSxProps,
                        }}
                        onClick={(e) => {
                            ReactEditor.focus(editor);
                            if (!editor?.selection) {
                                Transforms.select(editor, Editor.end(editor, []));
                            }
                        }}
                    >
                        {hasDeclineText && declineText && !isDeclineTextLoading && (
                            <Box>
                                <DisabledTextWithTooltip>{declineText}</DisabledTextWithTooltip>
                            </Box>
                        )}
                        <Editable
                            className="nowheel nodrag"
                            data-testid="richtext-editor-input"
                            id={id}
                            style={editableStyle}
                            renderElement={ElementWrapper}
                            renderLeaf={LeafWrapper}
                            autoFocus={autoFocus}
                            readOnly={disabled || readOnly}
                            onKeyDown={createHandleKeyDown(editor)}
                            onFocus={() => {
                                setIsFocused(true);
                                setEditorContext();
                            }}
                            onBlur={() => {
                                setIsFocused(false);
                            }}
                            decorate={decorate}
                            {...InputProps}
                        />
                    </Box>
                    {isValidElementType(CustomFooter) && <CustomFooter {...CustomFooterProps} />}

                    {!isToolbarDisabled && (
                        <Popper
                            sx={{
                                ...formattingButtonPopperStyles,
                            }}
                            open={isFormatting}
                            anchorEl={richTextEditorWrapperRef.current}
                            placement={formattingToolbarPlacement || 'top'}
                        >
                            <Box
                                sx={{
                                    borderRadius: 2,
                                    backgroundColor: 'common.white',
                                    boxShadow:
                                        '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(63, 83, 100, 0.30)',
                                }}
                            >
                                <Toolbar editor={editor} readOnly={readOnly} disabled={disabled} />
                            </Box>
                        </Popper>
                    )}
                </Slate>

                <Collapse in={Boolean(helperText)}>
                    <FormHelperText id={helperTextId} {...FormHelperTextProps}>
                        {helperText}
                    </FormHelperText>
                </Collapse>
            </FormControl>
        );
    })
);

RichTextInput.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    helperText: PropTypes.string,
    FormHelperTextProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.string,
    formattingButtonPopperStyles: PropTypes.object,
    initialValue: PropTypes.string,
    fullWidth: PropTypes.bool,
    clearInput: PropTypes.bool,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    autoFocus: PropTypes.bool,
    isCondensed: PropTypes.bool,
    isInline: PropTypes.bool,
    isToolbarDisabled: PropTypes.bool,
    name: PropTypes.string,
    CustomHeader: PropTypes.elementType,
    CustomHeaderProps: PropTypes.object,
    isEditMode: PropTypes.bool,
    CustomFooter: PropTypes.elementType,
    CustomFooterProps: PropTypes.object,
    decorator: PropTypes.func,
    initiateMount: PropTypes.bool,
    isFormatting: PropTypes.bool,
    bodyHeight: PropTypes.string,
    hasDeclineText: PropTypes.bool,
    isBlurred: PropTypes.bool,
    formattingToolbarPlacement: PropTypes.string,
    EditorWrapperSxProps: PropTypes.object,
};
