import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function PostHogTracker() {
    const location = useLocation();
    const user = useSelector((state) => state.user);

    useEffect(() => {
        const posthog = window.posthog;
        const orgId = user?.attributes?.organizationId?.[0];
        const orgName = user?.attributes?.organizationName?.[0];

        if (user?.id && orgId && posthog) {
            posthog.identify(user.id, {
                email: user.email,
                name: user.displayName,
                premium: user.isPaid,
                orgId,
                orgName,
            });
            posthog.group('organization', orgId, { name: orgName, premium: user.isPaid });
        }
    }, [user]);

    useEffect(() => {
        if (window.posthog) {
            // https://posthog.com/docs/libraries/js#one-page-apps-and-page-views
            window.posthog.capture('$pageview');
        }
    }, [location]);

    return null;
}
