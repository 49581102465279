import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import { alpha } from '@mui/material/styles';

import { useContactDrop } from '../hooks/useContactDrop';

export function EmptyListItem(props) {
    const { category } = props;

    const [isActive, drop] = useContactDrop(category, 0);

    return (
        <ListItem
            ref={drop}
            data-testid={`${category} section`}
            sx={{
                minHeight: (t) => t.spacing(4),
                backgroundColor: (t) =>
                    isActive ? alpha(t.palette.primary.main, 0.125) : 'inherit',
                borderRadius: (t) => (isActive ? t.spacing(0.25) : 'inherit'),
            }}
        />
    );
}

EmptyListItem.propTypes = {
    category: PropTypes.string,
    children: PropTypes.node,
};
