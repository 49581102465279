import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const schedulerApi = createApi({
    reducerPath: 'schedulerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/engagement-scheduler/v1/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['EmailTemplate', 'Threads', 'Messages'],
    endpoints: (builder) => ({
        getAllSchedules: builder.query({
            query: () => ({ url: 'schedule/pages' }),
            providesTags: (data) =>
                data
                    ? [
                          ...data.results.map(({ id }) => ({ type: 'Schedulers', id })),
                          { type: 'Schedules', id: 'LISTS' },
                      ]
                    : [{ type: 'Schedules', id: 'LISTS' }],
        }),
        createSchedule: builder.mutation({
            query: (body) => ({
                url: 'schedule/pages',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Schedules', id: 'LISTS' }],
        }),
        updateSchedule: builder.mutation({
            query: (body) => ({
                url: 'schedule/pages',
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Schedules', id: 'LISTS' }],
        }),
        deleteSchedule: builder.mutation({
            query: (pageId) => ({
                url: `schedule/pages/${pageId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Schedules', id: 'LISTS' }],
        }),
    }),
});

export const {
    useGetAllSchedulesQuery,
    useLazyGetAllSchedulesQuery,
    useCreateScheduleMutation,
    useUpdateScheduleMutation,
    useDeleteScheduleMutation,
} = schedulerApi;
