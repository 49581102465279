import SvgIcon from '@mui/material/SvgIcon';

export function RocketIcon(props) {
    return (
        <SvgIcon viewBox="0 0 25 25" {...props}>
            <path
                d="M23.3625 1.37563C22.793 0.805146 22.038 0.5 21.2433 0.5H21.23L16.1967 1.21643C14.7264 1.4287 13.3357 2.13183 12.276 3.17992L9.87856 5.58124L7.6798 5.16997C6.05059 4.85156 4.38166 5.38225 3.21605 6.54975L0.805349 8.96435C0.315264 9.45523 0.129825 10.1849 0.328508 10.8482C0.527192 11.5249 1.08352 12.029 1.73255 12.1617L4.40814 12.7852C4.18297 14.3507 4.59359 15.9826 5.60025 17.2827L4.64657 18.238C4.13 18.7554 4.13 19.5912 4.64657 20.1086C4.91148 20.374 5.24261 20.4934 5.587 20.4934C5.93138 20.4934 6.26253 20.3607 6.52744 20.1086L7.48112 19.1534C8.56726 20.029 9.87856 20.4934 11.2164 20.4934C11.4813 20.4934 11.7462 20.4535 12.0111 20.4137L12.6071 22.9743C12.7528 23.6642 13.2429 24.2081 13.9185 24.4204C14.1039 24.4735 14.2893 24.5 14.4615 24.5C14.9516 24.5 15.4417 24.301 15.7993 23.9428L18.21 21.5282C19.3756 20.3607 19.8922 18.689 19.5876 17.0572L19.1902 14.9743L21.6141 12.5464C22.6738 11.4851 23.3758 10.0655 23.5877 8.5796L24.25 3.68407V3.49831C24.25 2.68903 23.9453 1.93283 23.3758 1.36235L23.3625 1.37563ZM5.08368 8.40712C5.63999 7.86317 6.42147 7.61111 7.18972 7.75705L7.53409 7.82337L5.14991 10.2115L3.6399 9.85322L5.09692 8.39384L5.08368 8.40712ZM16.9649 17.5613C17.1106 18.3308 16.8722 19.1136 16.3159 19.6708L14.8589 21.1302L14.5013 19.6178L15.1106 19.0074C15.2033 18.9278 15.3092 18.8615 15.402 18.7686L16.8987 17.2695L16.9517 17.5746L16.9649 17.5613ZM20.9386 8.2214C20.8194 9.15009 20.3823 10.0124 19.72 10.6758L18.5676 11.83V11.7902L13.2297 17.1236C12.0906 18.0257 10.5011 18.092 9.30901 17.2827L14.8854 11.6974C15.402 11.1799 15.402 10.3441 14.8854 9.8267C14.3688 9.30929 13.5343 9.30929 13.0178 9.8267L7.45462 15.3988C6.64665 14.1119 6.79235 12.3872 7.91823 11.2728L14.1171 5.06385C14.7662 4.41377 15.6271 3.97595 16.5411 3.85655L21.2698 3.17992C21.3625 3.19319 21.4287 3.24628 21.4684 3.28608C21.5082 3.32588 21.5611 3.39221 21.5744 3.48508L20.9254 8.24792L20.9386 8.2214Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
