import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Popover from '@mui/material/Popover';
import { Tooltip } from 'components/Tooltip';
import { alpha, styled } from '@mui/system';

import { TYPOGRAPHY_VARIANTS } from 'components/Typography';

export const StyledChip = styled(Chip)(({ theme }) => ({
    '&.MuiChip-root': {
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        ...TYPOGRAPHY_VARIANTS.titleSmall,
    },
}));

const MAX_INLINE = 3;

export function MediaTags({ tags, size = 'medium' }) {
    const anchor = useRef(null);
    const remainder = useRef(0);

    const [summary, setSummary] = useState([]);
    const [expandedView, setExpandedView] = useState(false);

    useEffect(() => {
        const staging = [];
        tags.forEach((tag, i) => {
            if (i < MAX_INLINE) staging.push(tag);
        });
        setSummary(staging);
        remainder.current = tags.length - MAX_INLINE;
    }, [tags]);

    const toggleExpandedView = (e) => {
        e.stopPropagation();
        setExpandedView((prevState) => !prevState);
    };

    return (
        <>
            <>
                {summary.map((tag, i) => (
                    <StyledChip ref={i === 0 ? anchor : null} key={tag} label={tag} size={size} />
                ))}
                {remainder.current > 0 && (
                    <Tooltip
                        title="See all tags"
                        placement="bottom"
                        enterDelay={100}
                        data-testid="tooltip"
                    >
                        <StyledChip
                            size={size}
                            label={`+${remainder.current}`}
                            onClick={toggleExpandedView}
                            aria-label="See all tags"
                            sx={{ '&.MuiChip-root': { color: 'text.primary' } }}
                        />
                    </Tooltip>
                )}
            </>
            <Popover
                open={expandedView}
                anchorEl={anchor.current}
                onClose={toggleExpandedView}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box
                    sx={{ margin: (t) => t.spacing(1), width: (t) => t.spacing(50) }}
                    aria-label="All tags popover"
                >
                    {tags.map((tag) => (
                        <StyledChip
                            key={tag}
                            label={tag}
                            sx={{ marginTop: 0.5, marginBottom: 0.5 }}
                        />
                    ))}
                </Box>
            </Popover>
        </>
    );
}

MediaTags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string),
    size: PropTypes.string,
};
