import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { Typography } from 'components/Typography';
import { NoteUserIcon, NotesEyeIcon } from 'components/Icons';
import { useState } from 'react';
import { getValueByMode } from 'utils/copy/getValueByMode';
import { formatLocalDate } from 'utils/formatters/formatDate';
import { NotesSelectDropDown } from '../NotesSelectDropDown';
import { Tooltip } from 'components/Tooltip';
import { NOTE_PRIVACY_OPTIONS } from 'features/TalentNotes/utils/constants';

const NoteHeaderContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

export function NoteHeader({
    isNoteOwner,
    editNoteValue,
    handleEditNotePrivacy,
    isEditMode,
    note,
}) {
    const [showPrivacyOptions, setShowPrivacyOptions] = useState(false);
    const formatNoteDate = (dateString) =>
        formatLocalDate(new Date(dateString).toISOString(), 'E, M/d/yy p');
    const noteEditor = getValueByMode(isNoteOwner, 'You', note.name);

    return (
        <NoteHeaderContainer>
            {isNoteOwner ? (
                <Box sx={{ display: 'flex' }}>
                    <Box>
                        <Tooltip
                            title={getValueByMode(
                                editNoteValue.privacy === 'private',
                                'Only you can see this note',
                                'Everyone in your organization can see this note'
                            )}
                            placement="top"
                        >
                            <Box>
                                <NotesEyeIcon
                                    sx={{
                                        height: '20px',
                                        width: '20px',
                                        paddingTop: '7px',
                                        cursor: 'pointer',
                                        color: 'primary.main',
                                    }}
                                />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box
                        onMouseEnter={() => {
                            setShowPrivacyOptions(true);
                        }}
                        onMouseLeave={() => {
                            setShowPrivacyOptions(false);
                        }}
                        sx={{ display: 'flex', alignItems: 'center', marginLeft: 0.5 }}
                        data-testid="note-privacy"
                        data-trackid="edit-note-visibility"
                    >
                        <Typography
                            variant="bodySmallBold"
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                        >
                            {getValueByMode(
                                editNoteValue.privacy === 'private',
                                'Only You',
                                'Your Org'
                            )}
                        </Typography>

                        {(isEditMode || showPrivacyOptions) && (
                            <NotesSelectDropDown
                                setPrivacy={handleEditNotePrivacy}
                                options={NOTE_PRIVACY_OPTIONS}
                                sx={{
                                    '& .MuiSelect-icon': {
                                        color: 'primary.main',
                                    },
                                }}
                            />
                        )}
                    </Box>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <NoteUserIcon sx={{ height: '19px', width: '19px' }} />
                    <Typography
                        variant="bodySmallBold"
                        color="text.primary"
                        sx={{ marginLeft: 0.5 }}
                    >
                        {noteEditor}
                    </Typography>
                </Box>
            )}
            <Tooltip
                title={
                    <>
                        <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>
                            {`Last Edited by ${noteEditor}`}
                        </Typography>
                        {formatNoteDate(note.updated_at || note.created_at)}
                        <Typography sx={{ fontSize: 10, fontWeight: 'bold', mt: 1 }}>
                            {`Created by ${noteEditor}`}
                        </Typography>
                        <Box sx={{ mb: 1.3 }}>{formatNoteDate(note.created_at)}</Box>
                    </>
                }
                placement="top"
                sx={{ lineHeight: 0.5 }}
            >
                <Typography
                    variant="bodySmall"
                    sx={{
                        color: 'rgba(38, 55, 70, 0.48)',
                        fontSize: '14px',
                        mt: '-2px',
                    }}
                >
                    <Typography
                        variant="bodySmall"
                        sx={{
                            color: 'rgba(38, 55, 70, 0.48)',
                            fontSize: '14px',
                            cursor: 'pointer',
                        }}
                    >
                        {formatNoteDate(note.created_at)}
                    </Typography>
                </Typography>
            </Tooltip>
        </NoteHeaderContainer>
    );
}

NoteHeader.propTypes = {
    isNoteOwner: PropTypes.bool.isRequired,
    editNoteValue: PropTypes.object.isRequired,
    handleEditNotePrivacy: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    note: PropTypes.object.isRequired,
};
