import SvgIcon from '@mui/material/SvgIcon';

export function SendIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <g>
                <path
                    d="M29.2913 11.5714L8.6687 2.93119C6.3931 1.97117 3.8686 2.52229 2.21524 4.31788C0.632985 6.04236 0.295127 8.49576 1.36181 10.558L3.70854 15.0736C4.2241 16.0692 4.2241 17.2603 3.70854 18.2559L1.36181 22.8071C0.295127 24.8872 0.615206 27.3228 2.21524 29.0473C3.33526 30.2562 4.84637 30.8962 6.41085 30.8962C7.17531 30.8962 7.9398 30.7539 8.6687 30.4339L29.2913 21.7938C31.4246 20.9049 32.7402 18.9493 32.7402 16.6737C32.7402 14.4158 31.4246 12.4425 29.2913 11.5536V11.5714ZM27.9223 18.5226L7.29975 27.1628C6.25084 27.6072 5.32635 27.1805 4.82857 26.6472C4.54412 26.345 3.95747 25.5272 4.52637 24.4427L6.87309 19.8915C7.10421 19.4293 7.28197 18.9493 7.40642 18.4515H17.5044C18.4822 18.4515 19.2822 17.6515 19.2822 16.6737C19.2822 15.6959 18.4822 14.8959 17.5044 14.8959H7.42421C7.29977 14.3981 7.12199 13.9003 6.87309 13.4381L4.52637 8.92241C3.97525 7.83794 4.54412 7.02017 4.82857 6.71794C5.18413 6.32682 5.73528 6.0068 6.41085 6.0068C6.6953 6.0068 6.97975 6.06014 7.29975 6.18459L27.9223 14.8248C28.7223 15.1625 29.1846 15.8381 29.1846 16.6559C29.1846 17.4737 28.7046 18.167 27.9223 18.487V18.5226Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
