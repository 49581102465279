import { Component } from 'react';
import PropTypes from 'prop-types';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    render() {
        const { hasError } = this.state;
        const { children, fallbackText } = this.props;

        if (hasError) {
            if (fallbackText) {
                return fallbackText;
            }
            return <span>An error has occurred</span>;
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    fallbackText: PropTypes.string,
};
