import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useMultiSelectFilter = (filterName, setFilter, setEnableFilter) => {
    const dispatch = useDispatch();
    const filterValue = useSelector((s) => s.communications.filters[filterName].value);
    const isFilterEnabled = useSelector((s) => s.communications.filters[filterName].enabled);
    const handleFilterChange = useCallback(
        (event) => {
            dispatch(setFilter(event.target.value));
        },
        [dispatch, setFilter]
    );
    const handleFilterCheck = useCallback(
        (event) => {
            dispatch(setEnableFilter(event.target.checked));
        },
        [dispatch, setEnableFilter]
    );
    return {
        isFilterEnabled,
        handleFilterCheck,
        filterValue,
        handleFilterChange,
    };
};
