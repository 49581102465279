import { styled } from '@mui/material/styles';
import MuiSlider from '@mui/material/Slider';

export const Slider = styled(MuiSlider)(({ theme }) => ({
    '& .MuiSlider-rail': {
        opacity: 1,
        height: theme.spacing(1),
        background: 'rgba(63, 83, 100, 0.12)',
    },
    '& .MuiSlider-track': {
        border: 'none',
        height: theme.spacing(1),
        background: theme.palette.common.matchScoreGradient,
    },
    '& .MuiSlider-thumb': {
        width: theme.spacing(2),
        height: theme.spacing(2),
        background: '#00EEE4',
        boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.32), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.32), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
        },
    },
    '& .MuiSlider-valueLabel': {
        backgroundColor: 'unset',
        textTransform: 'uppercase',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: 11,
        lineHeight: '16px',
        letterSpacing: 0.5,
        color: '#A3BCC3',
        padding: 0,
        top: '225%',
        '&:before': {
            display: 'none',
        },
    },
}));
