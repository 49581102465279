import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { alpha, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { Typography } from 'components/Typography';

import { useSendFeedbackRequestMutation } from 'services/systemEmails';
import { DialogActions, DialogContent } from 'components/Dialog/DialogComponents';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    CloseIcon,
    NotesCalendarIcon,
    PiggyBankIcon,
    LinkIcon,
} from 'components/Icons';
import { CopyToClipBoardButton } from 'views/JDBuilder/JDBuilderOutput/components/CopyToClipBoardButton';
import { useNovaEnv } from 'features/Switchboard';
import { NEBULINK_ENV_URLS } from 'utils/constants';

const feedbackIcon = '/assets/svgs/feedback-emojis-icon.svg';
const nebulinkIcon = '/assets/svgs/nebulink-icon.svg';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        padding: theme.spacing(3, 6),
        maxWidth: theme.spacing(68),
    },
}));

const StyledDialogTitle = styled(Typography)(() => ({
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: 0.1,
}));

const BookFeedbackSession = styled(Button)(({ theme }) => ({
    background: theme.palette.success.main,
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: theme.shape.borderRadius,
    color: 'white',
    padding: theme.spacing(1.5, 1),
    '&.MuiButton-root:hover': {
        backgroundColor: theme.palette.success.main,
    },
    maxWidth: theme.spacing(32),
    textTransform: 'none',
}));

const CancelButton = styled(Button)(({ theme }) => ({
    alignSelf: 'center',
    margin: theme.spacing(1, 'auto'),
    textTransform: 'none',
}));

const CloseButtonWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '12px',
    right: '12px',
    marginRight: theme.spacing(1),
}));

const PiggyBank = styled(PiggyBankIcon)(({ theme }) => ({
    height: theme.spacing(8),
    width: theme.spacing(8),
    display: 'block',
    margin: theme.spacing(2, 'auto'),
}));

const FeedbackIcon = styled('img')(({ theme }) => ({
    display: 'block',
    margin: theme.spacing(2, 'auto'),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderColor: theme.palette.success.main,
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.success.main,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.success.main,
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: theme.spacing(1.5, 2),
    },
    '& fieldset': {
        borderColor: theme.palette.success.main,
    },
    '& .MuiInputBase-input': {
        paddingLeft: theme.spacing(0),
    },
    maxWidth: theme.spacing(32),
}));

const ClipBoardStyledButton = styled(CopyToClipBoardButton)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(-2),
    '& .MuiSvgIcon-root': {
        fontSize: '24px',
    },
    '& .MuiSvgIcon-root path': {
        fill: theme.palette.common.white,
    },
}));

const LinkStyledIcon = styled(LinkIcon)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.2),
    fontSize: theme.spacing(2.5),
    marginRight: theme.spacing(1),
}));

export function EarnCreditDialog(props) {
    const { open, openModal, closeModal } = props;

    const [sendFeedbackRequest, { isLoading }] = useSendFeedbackRequestMutation();
    const handleFeedbackRequest = async () => {
        await sendFeedbackRequest();
        closeModal();
    };

    const user = useSelector((state) => state.user);
    const { name: envName } = useNovaEnv();
    const baseUrl = NEBULINK_ENV_URLS[envName];
    const nebulink = `${baseUrl}?utm_source=nebula_user&utm_campaign=${user.id}`;

    const switchToPreviousModal = () => {
        openModal('upgrade');
        closeModal();
    };

    return (
        <StyledDialog open={open} onClose={closeModal} sx={{ overflowY: 'auto' }}>
            <CloseButtonWrapper>
                <IconButton edge="end" color="inherit" onClick={closeModal} aria-label="close">
                    <CloseIcon
                        sx={{
                            color: (theme) => alpha(theme.palette.text.primary, 0.48),
                        }}
                    />
                </IconButton>
            </CloseButtonWrapper>
            <DialogContent sx={{ px: 3, py: 0 }}>
                <PiggyBank />
                <StyledDialogTitle sx={{ py: 1, mb: 4 }} id="alert-dialog-slide-description">
                    You can earn additional credits in a couple of different ways:
                </StyledDialogTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                        sx={{
                            width: '70%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <Typography variant="smallHeadline">Tell us what you think</Typography>
                        <Typography variant="body">
                            Earn 15 Free credits by booking a feedback session with our team!
                        </Typography>
                        <BookFeedbackSession
                            startIcon={<NotesCalendarIcon />}
                            endIcon={<ChevronRightIcon sx={{ mb: -0.6 }} />}
                            component={Link}
                            disabled={isLoading}
                            href="https://calendar.app.google/pYYsSYD6DTsMMBQJ7"
                            aria-label="book-feedback-session"
                            target="_blank"
                            fullWidth
                            onClick={handleFeedbackRequest}
                        >
                            <Typography
                                variant="titleSmall"
                                sx={{
                                    fontWeight: 500,
                                    lineHeight: '18px',
                                }}
                            >
                                Book feedback session
                            </Typography>
                        </BookFeedbackSession>
                    </Box>
                    <FeedbackIcon src={feedbackIcon} alt="feedback Icon" />
                </Box>
                <Divider
                    sx={{
                        my: 4,
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            width: '70%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <Typography variant="smallHeadline">Share your Nebulink!</Typography>
                        <Typography variant="body">
                            Spread the word about Nebula and earn 5 credits for each new signup!
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            value={nebulink}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" aria-label="copy-to-clipboard">
                                        <ClipBoardStyledButton value={nebulink} showText={false} />
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LinkStyledIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <FeedbackIcon src={nebulinkIcon} alt="nebulink Icon" />
                </Box>
            </DialogContent>
            <DialogActions>
                <CancelButton
                    aria-label="close-earn-credit-dialog"
                    onClick={switchToPreviousModal}
                    variant="Text"
                    startIcon={
                        <ChevronLeftIcon
                            sx={{
                                height: '30px',
                                width: '30px',
                            }}
                        />
                    }
                >
                    <Typography variant="titleSmall">No Thanks</Typography>
                </CancelButton>
            </DialogActions>
        </StyledDialog>
    );
}

EarnCreditDialog.propTypes = {
    open: PropTypes.bool,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
};
