import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { Typography } from 'components/Typography';
import { styled } from '@mui/material/styles';
import { ChevronDownIcon } from 'components/Icons';
import { DropdownMenu } from 'components/DropdownMenu';

const DropdownButton = styled(Button)(() => ({
    textTransform: 'none',
    width: 140,
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    textAlign: 'center',
}));

export function CustomContactCategoryDropdown(props) {
    const { label = '', selectedOption = [], options = [], onSelectedOptionChange } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <DropdownButton
                data-testid="custom-contact-category-dropdown-button"
                disableRipple
                onClick={handleClick}
            >
                <Typography sx={{ color: 'text.primary', mr: 1 }} variant="titleSmall">
                    {selectedOption?.label || label}
                </Typography>
                <ChevronDownIcon sx={{ color: 'text.primary' }} />
            </DropdownButton>
            <DropdownMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                options={options}
                selectedOptions={!!selectedOption ? [] : [selectedOption]}
                onSelectedOptionChange={(options) => onSelectedOptionChange(options[0])}
                multiple={false}
                useCheckboxes={false}
            />
        </div>
    );
}

const OptionPropType = PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
});

CustomContactCategoryDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(OptionPropType).isRequired,
    selectedOption: OptionPropType,
    onSelectedOptionChange: PropTypes.func,
};
