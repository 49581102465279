import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { useLazyGetConversationMessagesQuery } from 'services/conversations';
import {
    appendMessages,
    incrementMessagePage,
    replaceMessages,
    resetMessagePage,
    resetMessageState,
    setHasMoreMessages,
    setMessagePage,
    scrollToMessage,
    setMessageSearchingStatus,
    setShouldRefreshMessages,
    setShouldRefreshConversations,
    prependMessages,
} from '../communicationsSlice';
import {
    buildMessageTypeFilter,
    buildSendMethodFilter,
} from '../CommunicationsMetrics/utils/filterBuilders';
import { useInterval } from 'hooks/useInterval';

const PAGE_SIZE = 10;
const DEFAULT_POLLING_INTERVAL = 2; // representative of seconds
const POLLING_INTERVAL_MULTIPLIER = 1000; // converts polling into milliseconds
const MAXIMUM_POLLING_INTERVAL = 60 * 5 * POLLING_INTERVAL_MULTIPLIER; //5 minutes as top timeout

export function useInfiniteScrollMessages({ conversationId }) {
    const [intervalDelay, setIntervalDelay] = useState(
        DEFAULT_POLLING_INTERVAL * POLLING_INTERVAL_MULTIPLIER
    );

    const deltaRef = useRef(0);
    const totalMessagesRef = useRef(0);
    const previousTotalMessagesRef = useRef(0);
    const pollingIntervals = useRef(0);

    const dispatch = useDispatch();

    const page = useSelector((state) => state.communications.messagesPage);
    const messages = useSelector((state) => state.communications.messages);
    const matchText = useSelector((state) => state.communications.messageMatchText);
    const isMatchingText = useSelector((state) => state.communications.isMatchingMessages);
    const { messageChannels, messageTypes } = useSelector((state) => state.communications.filters);
    const { DIRECT, SEQUENCE, BOT } = messageChannels;
    const { SMS, EMAIL } = messageTypes;
    const shouldRefreshMessages = useSelector(
        (state) => state.communications.shouldRefreshMessages
    );

    const [getMessages, { data, isSuccess, isFetching }] = useLazyGetConversationMessagesQuery();

    const defaultParams = useMemo(() => {
        const params = {
            conversationId,
            pageSize: PAGE_SIZE,
        };
        dispatch(resetMessagePage());
        params['sendMethod'] = buildSendMethodFilter(messageChannels, true);
        if (!EMAIL || !SMS) {
            params['messageType'] = buildMessageTypeFilter(messageTypes);
        }
        return params;
    }, [conversationId, dispatch, messageChannels, messageTypes, EMAIL, SMS]);

    const handleMessages = useCallback(
        async (
            queryParams,
            onSuccess,
            errorMessage = 'Error retrieving messages',
            onError = () => {}
        ) => {
            const response = await getMessages({ ...defaultParams, ...queryParams });
            if (response?.error) {
                toast.error(errorMessage);
                onError();
            }
            if (response?.isSuccess) {
                return onSuccess(response);
            }
        },
        [getMessages, defaultParams]
    );

    const getInitialMessages = useCallback(
        (isRefreshing) => {
            const requestParams = {
                conversationId,
                page: 1,
                pageSize: PAGE_SIZE,
            };
            const onSuccess = (response) => {
                if (
                    (response?.data?.totalResults &&
                        response?.data?.totalResults !== totalMessagesRef.current) ||
                    isRefreshing
                ) {
                    dispatch(setShouldRefreshConversations({ value: true }));
                }
                totalMessagesRef.current = response?.data?.totalResults || 0;
                dispatch(resetMessagePage());
                dispatch(replaceMessages(response?.data?.results));
            };
            handleMessages(requestParams, onSuccess);
        },
        [conversationId, dispatch, handleMessages]
    );

    const getNextMessages = useCallback(() => {
        if (isFetching) {
            return;
        }
        const nextPage = page + 1;

        const requestParams = {
            conversationId,
            page: nextPage,
            pageSize: PAGE_SIZE,
        };

        if (matchText) {
            requestParams['matchText'] = matchText;
        }

        const onSuccess = (response) => {
            dispatch(appendMessages(response?.data?.results));
            dispatch(incrementMessagePage());
        };
        handleMessages(requestParams, onSuccess, 'Error retrieving messages');
    }, [conversationId, matchText, page, isFetching, dispatch, handleMessages]);

    const handleSearchMessages = useCallback(() => {
        const requestParams = {
            conversationId,
            page: 1,
            pageSize: PAGE_SIZE,
        };

        if (matchText) {
            requestParams['matchText'] = matchText;
        }

        const onSuccess = (response) => {
            dispatch(resetMessagePage());
            dispatch(setMessageSearchingStatus(true));
            dispatch(replaceMessages(response?.data?.results));
        };
        handleMessages(requestParams, onSuccess, 'Error searching messages');
    }, [conversationId, matchText, dispatch, handleMessages]);

    const getTargetMessagePages = useCallback(
        async (targetPage, messageId) => {
            const messages = [];

            const onSuccess = (response) => {
                messages.push(...response?.data?.results);
            };
            let errorFlag = false;
            const onError = () => {
                errorFlag = true;
            };
            for (let i = 0; i < targetPage; i++) {
                await handleMessages(
                    { conversationId, pageSize: PAGE_SIZE, page: i + 1 },
                    onSuccess,
                    'Error retrieving messages',
                    onError
                );
                if (errorFlag) break;
            }
            if (!errorFlag) {
                dispatch(replaceMessages(messages));
                dispatch(setMessagePage(targetPage));
                dispatch(scrollToMessage({ shouldScrollToMessage: true, messageId }));
            }
        },
        [conversationId, dispatch, handleMessages]
    );

    const getMessagePage = useCallback(
        (messageId) => {
            const requestParams = { conversationId, pageSize: PAGE_SIZE, messageId };
            const onSuccess = (response) => {
                getTargetMessagePages(response?.data?.page, messageId);
            };

            handleMessages(requestParams, onSuccess, 'Error retrieving message information');
        },
        [conversationId, handleMessages, getTargetMessagePages]
    );

    const onGetNewMessages = useCallback(
        (response) => {
            const { data } = response || {};
            if (data?.totalResults === totalMessagesRef.current) {
                deltaRef.current += data.results.length;
                dispatch(prependMessages(data?.results));
                const pageOffset = Math.floor(deltaRef.current / PAGE_SIZE);
                const currentPage = page + pageOffset;
                dispatch(setMessagePage(currentPage));
                dispatch(setShouldRefreshConversations({ page: 1 }));
            }
            if (data?.totalResults !== totalMessagesRef.current) {
                const pageSize = Math.max(data.totalResults - previousTotalMessagesRef.current, 1);
                totalMessagesRef.current = data.totalResults;
                const getNewMessagesParams = { conversationId, page: 1, pageSize };
                handleMessages(getNewMessagesParams, onGetNewMessages, 'Error refreshing messages');
            }
        },
        [page, conversationId, dispatch, handleMessages]
    );

    const onGetTotalMessagesSuccess = useCallback(
        (response) => {
            if (response?.data?.totalResults !== totalMessagesRef.current) {
                const resultDifference = Math.max(
                    response.data.totalResults - totalMessagesRef.current,
                    0
                );
                previousTotalMessagesRef.current = totalMessagesRef.current;
                totalMessagesRef.current = response.data.totalResults;
                const getNewMessagesParams = {
                    conversationId,
                    page: 1,
                    pageSize: resultDifference || 1, // Ensures that pageSize will be 1 in cases when the result difference is 0
                    sortBy: 'messageId',
                };
                handleMessages(getNewMessagesParams, onGetNewMessages, 'Error refreshing messages');
            }
        },
        [conversationId, handleMessages, onGetNewMessages]
    );

    const getRefreshedMessages = useCallback(() => {
        const getTotalMessageParams = {
            conversationId,
            page: 1,
            pageSize: 1,
        };

        handleMessages(
            getTotalMessageParams,
            onGetTotalMessagesSuccess,
            'Error retrieving refreshed message data'
        );
    }, [conversationId, handleMessages, onGetTotalMessagesSuccess]);

    const resetMessages = useCallback(() => {
        dispatch(resetMessageState());
    }, [dispatch]);

    const refreshMessages = useCallback(
        (res) => {
            if (res) {
                const messageId = res.data?.[0]?.messageId;
                dispatch(scrollToMessage({ shouldScrollToMessage: true, messageId }));
                setIntervalDelay(POLLING_INTERVAL_MULTIPLIER * DEFAULT_POLLING_INTERVAL);
                pollingIntervals.current = 0;
            } else {
                setIntervalDelay((prevState) => {
                    pollingIntervals.current++;
                    const next = prevState * pollingIntervals.current;
                    return Math.min(next, MAXIMUM_POLLING_INTERVAL);
                });
                dispatch(setShouldRefreshConversations({ value: false }));
                getRefreshedMessages();
            }
        },
        [dispatch, getRefreshedMessages]
    );

    useInterval(() => {
        refreshMessages();
    }, intervalDelay);

    useEffect(() => {
        if (page === 1 && conversationId && !isMatchingText && !matchText) {
            getInitialMessages();
            setIntervalDelay(POLLING_INTERVAL_MULTIPLIER * DEFAULT_POLLING_INTERVAL);
            deltaRef.current = 0;
            totalMessagesRef.current = 0;
            pollingIntervals.current = 0;
            previousTotalMessagesRef.current = 0;
        }
    }, [page, conversationId, isMatchingText, matchText, getInitialMessages, dispatch]);

    useEffect(() => {
        if (shouldRefreshMessages) {
            dispatch(setShouldRefreshMessages(false));
            getInitialMessages(true);
        }
    }, [shouldRefreshMessages, getInitialMessages, dispatch]);

    useEffect(() => {
        if (isMatchingText) {
            handleSearchMessages();
        }
    }, [handleSearchMessages, isMatchingText, DIRECT, SEQUENCE, BOT, SMS, EMAIL]);

    useEffect(() => {
        resetMessages();
    }, [conversationId, resetMessages]);

    useEffect(() => {
        if (isSuccess && data?.totalResults === messages.length) {
            dispatch(setHasMoreMessages(false));
        } else {
            dispatch(setHasMoreMessages(true));
        }
    }, [isSuccess, data, messages, dispatch]);

    return {
        resetMessages,
        getMessagePage,
        getNextMessages,
        getInitialMessages,
        refreshMessages,
        handleSearchMessages,
    };
}
