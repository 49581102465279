import SvgIcon from '@mui/material/SvgIcon';

export function LaunchIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M12.8867 12.9416H4.88672C4.52005 12.9416 4.22005 12.6416 4.22005 12.2749V4.2749C4.22005 3.90824 4.52005 3.60824 4.88672 3.60824H8.22005C8.58672 3.60824 8.88672 3.30824 8.88672 2.94157C8.88672 2.5749 8.58672 2.2749 8.22005 2.2749H4.22005C3.86643 2.2749 3.52729 2.41538 3.27724 2.66543C3.02719 2.91548 2.88672 3.25461 2.88672 3.60824V12.9416C2.88672 13.6749 3.48672 14.2749 4.22005 14.2749H13.5534C14.2867 14.2749 14.8867 13.6749 14.8867 12.9416V8.94157C14.8867 8.5749 14.5867 8.2749 14.2201 8.2749C13.8534 8.2749 13.5534 8.5749 13.5534 8.94157V12.2749C13.5534 12.6416 13.2534 12.9416 12.8867 12.9416ZM10.2201 2.94157C10.2201 3.30824 10.5201 3.60824 10.8867 3.60824H12.6134L6.52672 9.6949C6.40207 9.81955 6.33204 9.98862 6.33204 10.1649C6.33204 10.3412 6.40207 10.5103 6.52672 10.6349C6.65137 10.7596 6.82043 10.8296 6.99672 10.8296C7.173 10.8296 7.34207 10.7596 7.46672 10.6349L13.5534 4.54824V6.2749C13.5534 6.64157 13.8534 6.94157 14.2201 6.94157C14.5867 6.94157 14.8867 6.64157 14.8867 6.2749V2.94157C14.8867 2.5749 14.5867 2.2749 14.2201 2.2749H10.8867C10.5201 2.2749 10.2201 2.5749 10.2201 2.94157Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
