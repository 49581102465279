import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

export function DataObjectIcon(props) {
    const { gradient, ...restProps } = props;
    const stroke = gradient ? 'url(#paint0_linear_13964_14553)' : 'currentColor';
    return (
        <SvgIcon viewBox="0 0 16 16" {...restProps}>
            <path
                d="M4.66667 2.66667C4.31304 2.66667 3.97391 2.80715 3.72386 3.0572C3.47381 3.30724 3.33333 3.64638 3.33333 4.00001V6.00001C3.33333 6.53044 3.19286 7.03915 2.94281 7.41422C2.69276 7.78929 2.35362 8.00001 2 8.00001C2.35362 8.00001 2.69276 8.21072 2.94281 8.58579C3.19286 8.96086 3.33333 9.46957 3.33333 10V12C3.33333 12.3536 3.47381 12.6928 3.72386 12.9428C3.97391 13.1929 4.31304 13.3333 4.66667 13.3333M11.3333 2.66667C11.687 2.66667 12.0261 2.80715 12.2761 3.0572C12.5262 3.30724 12.6667 3.64638 12.6667 4.00001V6.00001C12.6667 6.53044 12.8071 7.03915 13.0572 7.41422C13.3072 7.78929 13.6464 8.00001 14 8.00001C13.6464 8.00001 13.3072 8.21072 13.0572 8.58579C12.8071 8.96086 12.6667 9.46957 12.6667 10V12C12.6667 12.3536 12.5262 12.6928 12.2761 12.9428C12.0261 13.1929 11.687 13.3333 11.3333 13.3333"
                stroke={stroke}
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_13964_14553"
                    x1="2"
                    y1="6.49766"
                    x2="14.0029"
                    y2="6.5626"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}

DataObjectIcon.propTypes = {
    gradient: PropTypes.bool,
};
