import { useCallback, useMemo } from 'react';
import * as filestack from 'filestack-js';
import { toast } from 'react-toastify';
import produce from 'immer';

import { useLazyGetUploadCredentialsQuery } from 'services/knowledgeBases';
import { MESSAGE_ATTACHMENT_FILE_SIZE_LIMIT } from 'utils/constants';

const MAX_FILES = 5;
/**
 * https://filestack.github.io/filestack-js/interfaces/pickeruploaddonecallback.html
 * https://www.filestack.com/docs/uploads/pickers/web/#picker-options
 * https://filestack.github.io/filestack-js/interfaces/clientoptions.html
 * https://filestack.github.io/filestack-js/interfaces/security.html
 */
export function usePicker(
    user,
    setAttachments,
    onAttachmentDelete,
    isEditMode,
    messageType,
    callBack
) {
    const [getUploadCredentials] = useLazyGetUploadCredentialsQuery();

    const handleUploadDone = useCallback(
        ({ filesUploaded, filesFailed }) => {
            setAttachments((prev) =>
                produce(prev, (draft) => {
                    filesUploaded.forEach((file) => {
                        draft.push({ ...file });
                    });
                })
            );

            const failures = filesFailed.length;
            if (Boolean(failures)) {
                toast.warning(
                    `Failed to upload ${failures} file${
                        failures === 1 ? '' : 's'
                    }. Please try again shortly`
                );
            } else {
                callBack?.(filesUploaded);
            }
        },
        [callBack, setAttachments]
    );

    const pickerOptions = useMemo(
        () => ({
            maxFiles: MAX_FILES,
            maxSize: MESSAGE_ATTACHMENT_FILE_SIZE_LIMIT[messageType],
            disableThumbnails: true,
            disableTransformer: true,
            uploadInBackground: false,
            onUploadDone: handleUploadDone,
            storeTo: {
                location: 'gcs',
                path: `/media/${user?.attributes?.organizationId?.[0]}/${user?.id}/`,
            },
            fromSources: ['local_file_system', 'url', 'googledrive', 'onedrive', 'box'],
        }),
        [messageType, handleUploadDone, user]
    );

    const handleDeleteFile = useCallback(
        (handle) =>
            async ({ api_key, policy, signature }) => {
                setAttachments((prev) =>
                    produce(prev, (draft) => {
                        const index = draft.findIndex(
                            (attachment) =>
                                attachment.handle === handle || attachment.mediaId === handle
                        );
                        if (index >= 0) draft[index].isDeleting = true;
                    })
                );

                const clientOptions = {
                    security: {
                        policy,
                        signature,
                    },
                    sessionCache: true,
                };
                const client = isEditMode ? null : filestack.init(api_key, clientOptions);
                const res = isEditMode ? null : await client.remove(handle);
                if (isEditMode || res?.data === 'success') {
                    setAttachments((prev) =>
                        produce(prev, (draft) => {
                            const index = draft.findIndex(
                                (attachment) =>
                                    attachment.handle === handle || attachment.mediaId === handle
                            );
                            if (index >= 0) draft.splice(index, 1);
                        })
                    );
                    onAttachmentDelete?.(handle);
                } else {
                    toast.error('Could not remove file, please try again shortly');
                }
            },
        [setAttachments, onAttachmentDelete, isEditMode]
    );

    const handleOpenPicker = useCallback(
        ({ api_key, policy, signature }, maxFiles) => {
            const clientOptions = {
                security: {
                    policy,
                    signature,
                },
                sessionCache: true,
            };
            filestack
                .init(api_key, clientOptions)
                .picker({ ...pickerOptions, maxFiles })
                .open();
        },
        [pickerOptions]
    );

    const handleInitiateUpload = useCallback(
        (currentFiles = 0, maxFiles = MAX_FILES) =>
            async () => {
                const response = await getUploadCredentials();
                if (response?.isSuccess) {
                    handleOpenPicker(response.data, maxFiles - currentFiles);
                } else {
                    toast.error('Could not initialize the file-picker, please try again shortly');
                }
            },
        [getUploadCredentials, handleOpenPicker]
    );

    const handleInitiateDelete = useCallback(
        (handle) => async () => {
            const response = await getUploadCredentials();
            if (response?.isSuccess) {
                handleDeleteFile(handle)(response.data);
            } else if (response?.isError) {
                toast.error('Could not complete the operation, please try again shortly');
            }
        },
        [getUploadCredentials, handleDeleteFile]
    );

    return [handleInitiateUpload, handleInitiateDelete];
}
