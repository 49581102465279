import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FEEDBACK_ACTIVITY_SUBTYPE } from 'utils';
import { Typography } from 'components/Typography';

export function FeedbackContent({ activitySubType, metadata }) {
    const jsx = useMemo(() => {
        switch (activitySubType) {
            case FEEDBACK_ACTIVITY_SUBTYPE.ADDED:
                return <>Added to Feedback List</>;
            case FEEDBACK_ACTIVITY_SUBTYPE.REMOVED:
                return <>Removed from Feedback List</>;
            default:
                return (
                    <>
                        Provided Feedback:{' '}
                        <Typography variant="bodyBaseBold">{metadata?.feedback}</Typography>
                    </>
                );
        }
    }, [activitySubType, metadata]);

    return <>{jsx}</>;
}

FeedbackContent.propTypes = {
    activitySubType: PropTypes.string,
    metadata: PropTypes.shape({
        feedback: PropTypes.string,
    }),
};
