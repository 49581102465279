import SvgIcon from '@mui/material/SvgIcon';

export function SearchIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M9.09674 10.1551C8.17851 10.808 7.04852 11.092 5.93073 10.951C4.81293 10.81 3.78891 10.2541 3.06161 9.39368C2.33431 8.53322 1.95681 7.43092 2.00393 6.30525C2.05106 5.17958 2.51935 4.11269 3.31602 3.31602C4.11269 2.51935 5.17958 2.05106 6.30525 2.00393C7.43092 1.95681 8.53322 2.33431 9.39368 3.06161C10.2541 3.78891 10.81 4.81293 10.951 5.93073C11.092 7.04852 10.808 8.17851 10.1551 9.09675L13.7626 12.7033C13.8362 12.7718 13.8952 12.8545 13.9362 12.9464C13.9771 13.0382 13.9991 13.1374 14.0009 13.2379C14.0027 13.3385 13.9842 13.4384 13.9465 13.5316C13.9088 13.6248 13.8528 13.7096 13.7817 13.7807C13.7105 13.8518 13.6258 13.9078 13.5326 13.9455C13.4394 13.9832 13.3395 14.0017 13.2389 13.9999C13.1384 13.9981 13.0392 13.9761 12.9474 13.9352C12.8555 13.8942 12.7728 13.8352 12.7043 13.7617L9.09674 10.1551ZM9.48915 6.4927C9.48915 5.69826 9.17356 4.93636 8.6118 4.3746C8.05005 3.81285 7.28814 3.49726 6.4937 3.49726C5.69926 3.49726 4.93736 3.81285 4.3756 4.3746C3.81385 4.93636 3.49826 5.69826 3.49826 6.4927C3.49826 7.28715 3.81385 8.04905 4.3756 8.6108C4.93736 9.17256 5.69926 9.48815 6.4937 9.48815C7.28814 9.48815 8.05005 9.17256 8.6118 8.6108C9.17356 8.04905 9.48915 7.28715 9.48915 6.4927Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
