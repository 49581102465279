import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';

import { useDialog } from 'components/Dialog';
import { useTokenLookupStage } from './useTokenLookupStage';
import { useGetOrganizationTokensMutation } from 'services/conversations';
import { useCheckContactData } from './useCheckContactData';
import { useSwitchboard } from 'features/Switchboard';
import { useMessageDrawer, useTalentData } from 'features/MessageDrawer/hooks';
import isEmpty from 'lodash/isEmpty';

export const STAGE_ENUMS = {
    TOKEN_LOOKUP: 0,
    TOKEN_ERROR: 1,
    LOOKUP_ERROR: 2,
    PIPL_ERROR: 3,
};

export function useOrganizationTokenDialog(props) {
    const {
        name,
        isContactDialogOpen,
        shouldReserveEmails,
        setShouldReserveEmails,
        selectedTalents,
        navigationState,
        isProjectOutreachContactLookup,
        handleCloseMenu = () => {},
    } = props;
    const [currentStage, setCurrentStage] = useState(STAGE_ENUMS.TOKEN_LOOKUP);
    const [shouldOpenDrawer, setShouldOpenDrawer] = useState(false);

    const isMessageDrawerEnabled = useSwitchboard('FEATURE_MESSAGE_DRAWER');

    const [getOrganizationTokens, tokensResult] = useGetOrganizationTokensMutation();
    const { data: tokensData, error, isError, isSuccess, isLoading, reset } = tokensResult;

    const {
        getTalentContacts,
        isContactsSuccess,
        lookupFailure,
        hasContacts,
        talentLookups: talentContactLookups,
    } = useCheckContactData({ selectedTalents, messageType: navigationState.messageType });

    const {
        isOpen: isMessageDrawerOpen,
        isMinimized: isMessageDrawerMinimized,
        handleOpen: handleOpenDrawer,
    } = useMessageDrawer();

    const { handleSetProfiles } = useTalentData();

    const navigate = useNavigate();

    const handleNavigate = useCallback(
        (filteredProfiles) => {
            navigate('/communications/contacts', {
                state: {
                    ...navigationState,
                    profiles: filteredProfiles?.length
                        ? filteredProfiles
                        : navigationState.profiles,
                },
            });
        },
        [navigationState, navigate]
    );

    const handleOpenMessageDrawer = useCallback(
        (filteredProfiles) => {
            if (filteredProfiles?.length) {
                handleSetProfiles(filteredProfiles);
            }
            handleOpenDrawer();
        },
        [handleSetProfiles, handleOpenDrawer]
    );

    const handleConfirm = useCallback(
        (args) => {
            if (isMessageDrawerEnabled) {
                handleOpenMessageDrawer(args);
                setShouldOpenDrawer(false);
            } else {
                handleNavigate(args);
            }
        },
        [isMessageDrawerEnabled, handleOpenMessageDrawer, handleNavigate]
    );

    const { isOpen, handleOpen, handleClose } = useDialog({
        autoOpenDialog:
            (isSuccess && isContactsSuccess && !hasContacts && tokensData?.requiredTokens !== 0) ||
            (isError && error?.status === 403),
        onConfirm: handleConfirm,
    });

    const {
        talentLookups,
        isLookupLoading,
        allLookupsFailed,
        resetPiplLookup,
        handleLookupStageConfirm,
        handleLookupErrorStageConfirm,
    } = useTokenLookupStage({
        name,
        profiles: navigationState.profiles,
        messageType: navigationState.messageType,
        isProjectOutreachContactLookup,
        currentStage,
        selectedTalents,
        initialLookups: talentContactLookups,
        setCurrentStage,
        handleConfirm,
        handleCloseMenu,
        isOpen,
        handleClose,
    });

    const handleStagedConfirm = () => {
        if (currentStage === STAGE_ENUMS.TOKEN_LOOKUP) {
            handleLookupStageConfirm(selectedTalents);
        }
        if (currentStage === STAGE_ENUMS.LOOKUP_ERROR) {
            handleLookupErrorStageConfirm();
        }
    };

    const handleCloseCleanup = () => {
        reset();
        resetPiplLookup();
        setCurrentStage(STAGE_ENUMS.TOKEN_LOOKUP);
        handleClose();
    };

    useEffect(() => {
        if (shouldReserveEmails) {
            if (isArray(selectedTalents)) {
                const candidateIds = selectedTalents.map((talent) => talent.talentId);
                getOrganizationTokens({ candidates: candidateIds });
                getTalentContacts(candidateIds);
            }
            if (isString(selectedTalents)) {
                getOrganizationTokens({ candidates: [selectedTalents] });
                getTalentContacts([selectedTalents]);
            }
            setShouldReserveEmails(false);
            setShouldOpenDrawer(true);
        }
    }, [
        selectedTalents,
        shouldReserveEmails,
        setShouldReserveEmails,
        getTalentContacts,
        getOrganizationTokens,
    ]);

    useEffect(() => {
        if (isError && error?.status === 403) {
            setCurrentStage(STAGE_ENUMS.TOKEN_ERROR);
        }
        if (isSuccess && tokensData?.requiredTokens === 0) {
            if (isContactsSuccess && lookupFailure) {
                handleOpen();
                setCurrentStage(STAGE_ENUMS.LOOKUP_ERROR);
            } else if (isContactsSuccess && !lookupFailure && !isEmpty(talentContactLookups)) {
                if (
                    (!isMessageDrawerOpen || isMessageDrawerMinimized) &&
                    shouldOpenDrawer &&
                    isMessageDrawerEnabled
                ) {
                    handleOpenMessageDrawer();
                    setShouldOpenDrawer(false);
                    handleCloseMenu();
                    handleClose();
                } else if (!isMessageDrawerEnabled) {
                    handleNavigate();
                }
            }
        } else if (tokensData?.requiredTokens > 0 && hasContacts) {
            handleOpenMessageDrawer();
            setShouldOpenDrawer(false);
            handleCloseMenu();
            handleClose();
        }
    }, [
        error,
        isError,
        isSuccess,
        lookupFailure,
        shouldOpenDrawer,
        isContactsSuccess,
        isContactDialogOpen,
        isMessageDrawerOpen,
        isMessageDrawerMinimized,
        talentContactLookups,
        isMessageDrawerEnabled,
        tokensData?.requiredTokens,
        handleOpen,
        handleClose,
        handleNavigate,
        handleCloseMenu,
        getOrganizationTokens,
        handleOpenMessageDrawer,
        hasContacts,
    ]);

    const talentLookupData = useMemo(
        () =>
            tokensData?.requiredTokens === 0 && lookupFailure
                ? talentContactLookups
                : talentLookups,
        [tokensData, lookupFailure, talentContactLookups, talentLookups]
    );

    return {
        isOpen,
        isLoading,
        currentStage,
        talentLookups: talentLookupData,
        isLookupLoading,
        allLookupsFailed,
        tokensUsed: tokensData?.requiredTokens,
        handleClose: handleCloseCleanup,
        handleConfirm: handleStagedConfirm,
    };
}
