import PropTypes from 'prop-types';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';
import { useEffect, useRef, useState } from 'react';

export function TruncatedText({ text, variant, style, tooltipPlacement = 'bottom', ...props }) {
    const textRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            const element = textRef.current;

            if (element) {
                setIsTruncated(element.scrollWidth > element.clientWidth);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => window.removeEventListener('resize', checkOverflow);
    }, [text]);

    return (
        <Tooltip title={text} placement={tooltipPlacement} disableHoverListener={!isTruncated}>
            <Typography
                {...props}
                ref={textRef}
                variant={variant}
                sx={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    ...style,
                }}
            >
                {text}
            </Typography>
        </Tooltip>
    );
}

TruncatedText.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
    variant: PropTypes.string,
    style: PropTypes.object,
    tooltipPlacement: PropTypes.string,
};
