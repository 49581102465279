import SvgIcon from '@mui/material/SvgIcon';

export function StatTrophyIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="m22.5681 2.65185h-1.5725c-.1733-1.49251-1.4259-2.65185-2.9584-2.65185h-10.00775c-1.53248 0-2.78508 1.15934-2.95831 2.65185h-1.61247c-1.35924 0-2.465262 1.10601-2.465262 2.46526v1.54585c0 2.50527 1.719002 4.58404 4.037712 5.18374v.12c0 3.6646 2.97166 6.6496 6.63628 6.6763v2.6918h-1.3192c-.73298 0-1.33264.5997-1.33264 1.3326s.59966 1.3326 1.33264 1.3326h5.3437c.7329 0 1.3326-.5997 1.3326-1.3326s-.5997-1.3326-1.3326-1.3326h-1.3593v-2.6785c3.6779 0 6.6763-2.9984 6.6763-6.6763v-.1333c2.292-.613 3.9978-2.69178 3.9978-5.1704v-1.54585c0-1.35925-1.1061-2.46527-2.4653-2.46527zm-18.89625 3.99777v-1.33259h1.37261v3.69131c-.82621-.46641-1.37261-1.34595-1.37261-2.35872zm10.68745 9.31478h-1.2793s-.04 0-.0533 0c-.0134 0-.04 0-.0533 0h-1.2393c-2.2121 0-4.01112-1.799-4.01112-3.9977v-8.98171c0-.17323.14657-.31981.31981-.31981h10.00781c.1332 0 .2265.09325.2798.21318v9.15494h.04c-.04 2.1854-1.8124 3.9445-4.0111 3.9445zm8.0089-9.31478c0 .98612-.5331 1.85229-1.3326 2.3187v-3.65129h1.3326z"
                fill="currentColor"
                fillOpacity=".48"
            />
        </SvgIcon>
    );
}
