import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const IconDropDownButton = styled(Button)(({ theme }) => ({
    boxSizing: 'border-box',
    height: theme.spacing(3.5),
    width: theme.spacing(7.25),
    textTransform: 'unset',
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
        backgroundColor: theme.palette.success.light,
    },
    transition: theme.transitions.create(['border-radius', 'background-color', 'box-shadow'], {
        duration: theme.transitions.duration.shortest,
    }),
    '& .chevron-down': {
        marginLeft: -6,
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.shortest,
        }),
    },
    '&.Mui-disabled': {
        backgroundColor: 'transparent',
    },
}));
