import { configureStore } from '@reduxjs/toolkit';

import { usersApi } from 'services/users';
import { fluffApi } from 'services/fluff';
import { searchApi } from 'services/search';
import { talentApi } from 'services/talent';
import { knowledgeBasesApi } from 'services/knowledgeBases';
import { contactsApi } from 'services/contacts';
import { jdBuilderReducer } from 'views/JDBuilder';
import { switchboardApi } from 'services/switchboard';
import { compensationApi } from 'services/compensation';
import { insightsApi } from 'services/insights';
import { organizationsApi } from 'services/organizations';
import { conversationsApi } from 'services/conversations';
import { searchReducer } from 'views/Search';
import { byeBiasReducer } from 'features/ByeBias';
import { jobDescriptionApi } from 'services/jobDescription';
import { userReducer } from 'features/Authentication';
import {
    searchBatchActionsReducer,
    shortlistBatchActionsReducer,
} from 'features/TalentCard/TalentBatchActions';
import { schedulerApi } from 'services/scheduler';
import { scheduleReducer } from 'views/UserSettings/features/Scheduling';
import { communicationsReducer } from 'views/Communications';
import { contactSelectionReducer } from 'views/Communications/features/Contacts';
import { messageTemplateReducer } from 'features/MessageTemplate/MessageTemplateSlice';
import { dynamicFieldsReducer } from 'features/DynamicFields';
import { systemEmailsApi } from 'services/systemEmails';
import { signatureReducer } from 'features/Signature';
import { feedbackApi } from './services/feedback';
import { watchApi } from './services/watch';
import { messagingWorkflowApi } from 'services/messagingWorkflow';
import { mediaReducer } from 'views/UserSettings/features/YourMedia/slices/mediaSlice';
import { activityReducer } from 'features/Activity/activitySlice';
import { activityApi } from 'services/activity';
import { messageReducer } from 'features/MessageDrawer/messageSlice';
import { contactDetailReducer } from 'features/TalentCard/ContactDetails/contactDetailSlice';
import { reportsApi } from 'services/reports';
import { projectsApi } from 'services/projects';

export const store = configureStore({
    reducer: {
        shortlistBatchActions: shortlistBatchActionsReducer,
        searchBatchActions: searchBatchActionsReducer,
        byeBias: byeBiasReducer,
        media: mediaReducer,
        activity: activityReducer,
        search: searchReducer,
        user: userReducer,
        schedule: scheduleReducer,
        jdBuilder: jdBuilderReducer,
        communications: communicationsReducer,
        contactSelections: contactSelectionReducer,
        messageTemplate: messageTemplateReducer,
        dynamicFields: dynamicFieldsReducer,
        signature: signatureReducer,
        message: messageReducer,
        contactDetails: contactDetailReducer,
        [usersApi.reducerPath]: usersApi.reducer,
        [fluffApi.reducerPath]: fluffApi.reducer,
        [talentApi.reducerPath]: talentApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [switchboardApi.reducerPath]: switchboardApi.reducer,
        [organizationsApi.reducerPath]: organizationsApi.reducer,
        [compensationApi.reducerPath]: compensationApi.reducer,
        [insightsApi.reducerPath]: insightsApi.reducer,
        [schedulerApi.reducerPath]: schedulerApi.reducer,
        [conversationsApi.reducerPath]: conversationsApi.reducer,
        [contactsApi.reducerPath]: contactsApi.reducer,
        [jobDescriptionApi.reducerPath]: jobDescriptionApi.reducer,
        [systemEmailsApi.reducerPath]: systemEmailsApi.reducer,
        [knowledgeBasesApi.reducerPath]: knowledgeBasesApi.reducer,
        [feedbackApi.reducerPath]: feedbackApi.reducer,
        [watchApi.reducerPath]: watchApi.reducer,
        [messagingWorkflowApi.reducerPath]: messagingWorkflowApi.reducer,
        [activityApi.reducerPath]: activityApi.reducer,
        [reportsApi.reducerPath]: reportsApi.reducer,
        [projectsApi.reducerPath]: projectsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            switchboardApi.middleware,
            fluffApi.middleware,
            searchApi.middleware,
            talentApi.middleware,
            usersApi.middleware,
            organizationsApi.middleware,
            compensationApi.middleware,
            insightsApi.middleware,
            schedulerApi.middleware,
            contactsApi.middleware,
            conversationsApi.middleware,
            jobDescriptionApi.middleware,
            systemEmailsApi.middleware,
            knowledgeBasesApi.middleware,
            feedbackApi.middleware,
            watchApi.middleware,
            messagingWorkflowApi.middleware,
            activityApi.middleware,
            reportsApi.middleware,
            projectsApi.middleware
        ),
});
