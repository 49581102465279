import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { Typography } from 'components/Typography';
import { WarningIcon } from 'components/Icons';
import { MESSAGE_TYPES } from 'utils/constants';
import { formatPhoneNumber } from 'utils/formatters';

export function FailedTraversalContactsMenu(props) {
    const { contactTraversal, messageType, handleManageContacts } = props;

        return (
            <>
                <Box
                    data-testid="contact-traversal-menu"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 0.5,
                    }}
                >
                    <Typography
                        variant="bodySmallBold"
                        color="primary"
                        data-testid="manage-contacts-button"
                    >
                        Manage Contacts
                    </Typography>
                </Box>
                {contactTraversal.map((traversal, i) => (
                    <MenuItem
                        sx={{ padding: 0.5 }}
                        onClick={handleManageContacts}
                        key={`contact-traversal-${i}`}
                        data-testid={`contact-traversal-${i}`}
                    >
                        <WarningIcon sx={{ color: 'error.main', fontSize: 13 }} />
                        <Typography
                            sx={{
                                ml: 0.5,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                            variant="bodySmallBold"
                        >
                            {messageType === MESSAGE_TYPES.SMS
                                ? formatPhoneNumber(traversal.contact)
                                : traversal.contact}
                        </Typography>
                    </MenuItem>
                ))}
            </>
        );
}

FailedTraversalContactsMenu.propTypes = {
    contactTraversal: PropTypes.array,
    messageType: PropTypes.string,
    handleManageContacts: PropTypes.func,
};
