import { createSlice } from '@reduxjs/toolkit';

import { MESSAGE_TYPES } from 'utils/constants';

export const initialMessageTemplateState = {
    template: {},
    isUpdatingTemplate: false,
    templateId: '',
    // active template id stores the template id being used actively
    // in composing a message only, as opposed to templateId which stores
    // the templateId used for updating/viewing/deleting
    activeTemplateId: '',
    templateName: '',
    templateSubject: '',
    templateBody: '',
    messageChannels: {
        sms: true,
        email: true,
    },
    isUsingRmmMessage: false,
};

export const messageTemplateSlice = createSlice({
    name: 'messageTemplate',
    initialState: initialMessageTemplateState,
    reducers: {
        resetEntireTemplateState: () => {
            return initialMessageTemplateState;
        },
        setTemplate: (state, { payload }) => {
            state.template = payload;
        },
        clearTemplate: (state) => {
            state.template = {};
        },
        setActiveTemplateId: (state, { payload }) => {
            state.activeTemplateId = payload;
        },
        setIsUpdatingTemplate: (state, { payload }) => {
            state.isUpdatingTemplate = payload;
        },
        resetTemplate: (state) => {
            state.template = initialMessageTemplateState.template;
            state.isUpdatingTemplate = initialMessageTemplateState.isUpdatingTemplate;
            state.templateId = initialMessageTemplateState.templateId;
            state.templateName = initialMessageTemplateState.templateName;
            state.templateSubject = initialMessageTemplateState.templateSubject;
            state.templateBody = initialMessageTemplateState.templateBody;
            state.messageChannels = initialMessageTemplateState.messageChannels;
            state.defaultDynamicFields = initialMessageTemplateState.defaultDynamicFields;
        },
        setTemplateId: (state, { payload }) => {
            state.templateId = payload;
        },
        changeTemplateName: (state, { payload }) => {
            state.templateName = payload;
        },
        changeTemplateSubject: (state, { payload }) => {
            state.templateSubject = payload;
        },
        changeTemplateBody: (state, { payload }) => {
            state.templateBody = payload;
        },
        setMessageChannels: (state, { payload }) => {
            state.messageChannels.email = payload?.includes(MESSAGE_TYPES.EMAIL);
            state.messageChannels.sms = payload?.includes(MESSAGE_TYPES.SMS);
        },
        changeMessageChannels: (state, { payload }) => {
            state.messageChannels[payload] = !state.messageChannels[payload];
        },
        setIsUsingRmmMessage: (state, { payload }) => {
            state.isUsingRmmMessage = payload;
        },
        resetActiveTemplateState: (state) => {
            state.templateId = initialMessageTemplateState.templateId;
            state.templateName = initialMessageTemplateState.templateName;
            state.templateSubject = initialMessageTemplateState.templateSubject;
            state.templateBody = initialMessageTemplateState.templateBody;
            state.messageChannels = initialMessageTemplateState.messageChannels;
            state.isUpdatingTemplate = false;
            state.isUsingRmmMessage = false;
        },
    },
});

export const {
    setTemplate,
    clearTemplate,
    setIsUpdatingTemplate,
    resetTemplate,
    setTemplateId,
    setActiveTemplateId,
    changeTemplateName,
    changeTemplateSubject,
    changeTemplateBody,
    setMessageChannels,
    changeMessageChannels,
    setIsUsingRmmMessage,
    resetActiveTemplateState,
    resetEntireTemplateState,
} = messageTemplateSlice.actions;

export const messageTemplateReducer = messageTemplateSlice.reducer;
