import SvgIcon from '@mui/material/SvgIcon';

export function ActivityIcon(props) {
    return (
        <SvgIcon viewBox="0 0 23 23" {...props}>
            <rect width="22.5" height="22.5" rx="11.25" fill="url(#paint0_linear_14033_12317)" />
            <path
                d="M8.68542 7.06931L11.0666 18.0374C11.1956 18.6311 11.9446 18.6617 12.1128 18.0809L14.4744 9.92652L14.8993 11.6149C14.9312 11.7419 14.9997 11.8537 15.0942 11.9334C15.1887 12.0131 15.3041 12.0563 15.4228 12.0563H17.9562C18.1004 12.0563 18.2387 11.9927 18.3407 11.8794C18.4427 11.7661 18.5 11.6124 18.5 11.4522C18.5 11.292 18.4427 11.1383 18.3407 11.025C18.2387 10.9117 18.1004 10.848 17.9562 10.848H15.8354L15.0342 7.6662C14.8891 7.08864 14.1583 7.07333 13.9929 7.64365L11.6698 15.6643L9.23937 4.46261C9.10741 3.85283 8.32941 3.84316 8.18512 4.44973L6.66102 10.848H4.5438C4.39958 10.848 4.26126 10.9117 4.15928 11.025C4.05729 11.1383 4 11.292 4 11.4522C4 11.6124 4.05729 11.7661 4.15928 11.8794C4.26126 11.9927 4.39958 12.0563 4.5438 12.0563H7.07866C7.19864 12.0563 7.31525 12.0123 7.41033 11.931C7.50541 11.8497 7.57362 11.7357 7.60434 11.6069L8.68542 7.06931Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0666 18.0374L8.68542 7.06931L7.60434 11.6069C7.57362 11.7357 7.50541 11.8497 7.41033 11.931C7.31525 12.0123 7.19864 12.0563 7.07866 12.0563H4.5438C4.39958 12.0563 4.26126 11.9927 4.15928 11.8794C4.05729 11.7661 4 11.6124 4 11.4522C4 11.292 4.05729 11.1383 4.15928 11.025C4.26126 10.9117 4.39958 10.848 4.5438 10.848H6.66102L8.18512 4.44973C8.32941 3.84316 9.10741 3.85283 9.23937 4.46261L11.6698 15.6643L13.9929 7.64365C14.1583 7.07333 14.8891 7.08864 15.0342 7.6662L15.8354 10.848H17.9562C18.1004 10.848 18.2387 10.9117 18.3407 11.025C18.4427 11.1383 18.5 11.292 18.5 11.4522C18.5 11.6124 18.4427 11.7661 18.3407 11.8794C18.2387 11.9927 18.1004 12.0563 17.9562 12.0563H15.4228C15.3041 12.0563 15.1887 12.0131 15.0942 11.9334C14.9997 11.8537 14.9312 11.7419 14.8993 11.6149L14.4744 9.92652L12.1128 18.0809C11.9446 18.6617 11.1956 18.6311 11.0666 18.0374ZM14.4667 10.3602L12.2216 18.1124C12.1232 18.4522 11.8475 18.6242 11.5703 18.6127C11.2935 18.6013 11.0311 18.4077 10.9559 18.0615L8.68029 7.57962L7.71453 11.6331C7.67861 11.7838 7.59835 11.9192 7.48395 12.0171C7.36931 12.1151 7.22686 12.1696 7.07866 12.1696H4.5438C4.36546 12.1696 4.19716 12.0908 4.07508 11.9552C3.95335 11.8199 3.88672 11.6388 3.88672 11.4522C3.88672 11.2655 3.95335 11.0844 4.07508 10.9492C4.19716 10.8136 4.36546 10.7348 4.5438 10.7348H6.57155L8.07491 4.42351C8.15916 4.06933 8.43558 3.88329 8.71826 3.88677C9.00083 3.89024 9.2731 4.08286 9.35009 4.43865L11.6856 15.2027L13.8841 7.61213C13.9808 7.27851 14.2499 7.10537 14.5236 7.11122C14.7971 7.11706 15.0593 7.30131 15.144 7.63862L15.9237 10.7348H17.9562C18.1345 10.7348 18.3028 10.8136 18.4249 10.9492C18.5466 11.0844 18.6133 11.2655 18.6133 11.4522C18.6133 11.6388 18.5466 11.8199 18.4249 11.9552C18.3028 12.0908 18.1345 12.1696 17.9562 12.1696H15.4228C15.2762 12.1696 15.1352 12.1162 15.0212 12.02C14.9074 11.9241 14.8268 11.7911 14.7894 11.6426L14.4667 10.3602Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_14033_12317"
                    x1="-6.30129e-08"
                    y1="8.08099"
                    x2="22.5056"
                    y2="8.18923"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}
