import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { CircularProgress, alpha, styled } from '@mui/material';
import { Typography } from 'components/Typography';
import ReCAPTCHA from 'react-google-recaptcha';
import { CustomInputField, FormLabelText, HelperSubText } from './CustomInputField';
import { extractCompanyName } from '../utils/extractCompanyName';
import { validateSignUpEmail } from 'utils/validation/validateSignUpEmail';
import { validateWorkEmail } from '../utils/validateWorkEmail';
import { validatePhoneNumberWithLib } from '../utils/validatePhoneNumberWithLib';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const FormOuterContainer = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    backgroundColor:
        'linear-gradient(110.27deg, rgba(58, 141, 211, 0.08) 8.61%, rgba(58, 141, 211, 0.16) 22.8%, rgba(58, 141, 211, 0.08) 41.93%, rgba(58, 141, 211, 0.16) 69.07%, rgba(58, 141, 211, 0.08) 84.5%, rgba(58, 141, 211, 0.16) 107.32%, rgba(58, 141, 211, 0.08) 127.06%)',
}));

const FormContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 2),
    width: '90%',
    margin: theme.spacing(4, 'auto'),
    backgroundColor: '#FFFFFF',
    borderTop: '4px solid #318DDE',
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.up('sm')]: {
        width: '70%',
        padding: theme.spacing(3, 4),
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
        width: '35%',
    },
}));

const NameFields = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));

const CenteredReCAPTCHA = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
}));

export const FormTitleText = styled(Typography)(({ theme }) => ({
    fontWeight: 300,
    fontSize: 24,
    lineHeight: '32px',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: 'auto',
    marginBottom: theme.spacing(3),
    textTransform: 'none',
    padding: theme.spacing(1, 0.5),
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
        backgroundColor: alpha(theme.palette.text.primary, 0.12),
        color: alpha(theme.palette.text.primary, 0.48),
    },
}));

const InputFieldContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
}));

export function UserInfoForm({
    onSubmit,
    isLoading,
    register,
    errors,
    handleSubmit,
    handleRecaptchaChange,
    recaptchaError,
    userProfile,
    userData,
    isChangingBlacklistedEmail,
    clearErrors,
    setError,
    watch,
    setValue,
}) {
    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
    const [isFocused, setIsFocused] = useState(false);
    const [inputWidth, setInputWidth] = useState('inherit');
    const [isInitiallyFocused, setIsInitiallyFocused] = useState(false);
    const inputContainerRef = useRef();

    useEffect(() => {
        if (inputContainerRef.current) {
            const inputWidth = inputContainerRef.current.offsetWidth + 'px';
            setInputWidth(inputWidth);
        }
    }, []);

    useEffect(() => {
        if (!isInitiallyFocused && isFocused) {
            setIsInitiallyFocused(true);
        }
    }, [isInitiallyFocused, isFocused]);

    const isCompanyNameInEmail = (data) => {
        const email = data?.email;
        return Boolean(email) && Boolean(extractCompanyName(email));
    };

    const companyName = isCompanyNameInEmail(userProfile) || isCompanyNameInEmail(userData);

    const workEmailValue = watch('workEmail');

    useEffect(() => {
        if (
            isChangingBlacklistedEmail &&
            (!workEmailValue || !validateSignUpEmail(workEmailValue, true))
        ) {
            setError('workEmail', {
                type: 'manual',
                message: '*Enter a work email associated with your organization\u2019s domain',
            });
        } else {
            clearErrors('workEmail');
        }
    }, [workEmailValue, isChangingBlacklistedEmail, setError, clearErrors]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const validatePhoneNumber = (value) => {
        if (isInitiallyFocused) {
            return validatePhoneNumberWithLib(value);
        }
        return true;
    };

    return (
        <FormOuterContainer>
            <form data-testid="user-info-form" onSubmit={handleSubmit(onSubmit)}>
                <FormContainer>
                    <FormTitleText>Additional Information</FormTitleText>
                    <NameFields>
                        <Box sx={{ flex: '1' }}>
                            <CustomInputField
                                inputProps={{
                                    'data-trackid': 'signup-additional-info-firstname-input',
                                }}
                                testid="firstName-input"
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                disabled={
                                    Boolean(userProfile?.firstName) ||
                                    Boolean(isChangingBlacklistedEmail && userData?.firstName)
                                }
                                type="text"
                                register={register}
                                validationOptions={{
                                    required: 'First Name is required.',
                                }}
                                errors={errors}
                            />
                        </Box>
                        <Box sx={{ flex: '1' }}>
                            <CustomInputField
                                testid="lastName-input"
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                disabled={
                                    Boolean(userProfile?.lastName) ||
                                    Boolean(isChangingBlacklistedEmail && userData?.lastName)
                                }
                                type="text"
                                register={register}
                                validationOptions={{
                                    required: 'Last Name is required.',
                                }}
                                errors={errors}
                                inputProps={{
                                    'data-trackid': 'signup-additional-info-lastname-input',
                                }}
                            />
                        </Box>
                    </NameFields>
                    <InputFieldContainer>
                        <CustomInputField
                            inputProps={{
                                'data-trackid': 'signup-additional-info-jobtitle-input',
                            }}
                            testid="jobTitle-input"
                            id="jobTitle"
                            label="Job Title"
                            name="jobTitle"
                            disabled={Boolean(isChangingBlacklistedEmail && userData?.jobTitle)}
                            type="text"
                            register={register}
                            validationOptions={{
                                required: 'Job Title is required.',
                            }}
                            errors={errors}
                        />
                    </InputFieldContainer>
                    <InputFieldContainer>
                        <CustomInputField
                            testid="organization-input"
                            id="organization"
                            label="Organization"
                            name="organization"
                            disabled={companyName}
                            type="text"
                            register={register}
                            validationOptions={{
                                required: 'Organization is required.',
                            }}
                            errors={errors}
                        />
                    </InputFieldContainer>
                    <InputFieldContainer>
                        <CustomInputField
                            testid="workEmail-input"
                            id="workEmail"
                            label="Work Email"
                            name="workEmail"
                            disabled={!isChangingBlacklistedEmail && Boolean(userProfile?.email)}
                            type="email"
                            register={register}
                            validationOptions={{
                                required: 'Work Email is required.',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address',
                                },
                                validate: (value) =>
                                    validateWorkEmail(value, isChangingBlacklistedEmail),
                            }}
                            errors={errors}
                        />
                    </InputFieldContainer>
                    <InputFieldContainer
                        data-testid="mobilePhonenumber-input"
                        ref={inputContainerRef}
                    >
                        <FormLabelText sx={{ mb: 2 }}>
                            <span style={{ color: 'red' }}>*</span>
                            Mobile Phone Number
                        </FormLabelText>
                        <StyledPhoneInput
                            enableAreaCodes={false}
                            inputProps={{
                                name: 'mobilePhoneNumber',
                                id: 'mobilePhoneNumber',
                                'aria-label': 'user-input-field',
                                'data-trackid': 'signup-additional-info-phone-input',
                                ...{
                                    ...register('mobilePhoneNumber', {
                                        required: 'Mobile Phone Number is required.',
                                        validate: (value) => validatePhoneNumberWithLib(value),
                                    }),
                                },
                            }}
                            placeholder="enter a valid number"
                            onChange={(value) => setValue('mobilePhoneNumber', value)}
                            value={watch('mobilePhoneNumber')}
                            country="us"
                            inputStyle={{
                                height: '45px',
                                fontSize: '16px',
                                width: 'inherit',
                                border: 'none',
                                outline: '1px solid #CACACA',
                                ...(isFocused && { border: '1px solid #318DDE' }),
                            }}
                            dropdownStyle={{
                                width: inputWidth,
                            }}
                            buttonStyle={{
                                border: 'none',
                                ...(isFocused && { border: '1px solid #318DDE' }),
                            }}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            isValid={(value) => validatePhoneNumber(value)}
                            autocompleteSearch
                        />
                        <HelperSubText>SMS Verification required</HelperSubText>
                    </InputFieldContainer>
                    <CenteredReCAPTCHA>
                        <ReCAPTCHA
                            data-trackid="signup-additional-info-not-a-robot"
                            data-testid="recaptcha-checkbox"
                            sitekey={recaptchaSiteKey}
                            onChange={handleRecaptchaChange}
                            error={errors.recaptcha && errors.recaptcha.message}
                        />
                    </CenteredReCAPTCHA>
                    {recaptchaError && (
                        <span
                            style={{
                                color: 'red',
                                textAlign: 'center',
                                marginTop: '-15px',
                            }}
                        >
                            {recaptchaError}
                        </span>
                    )}
                    <StyledButton
                        data-trackid="signup-additional-info-complete-signup"
                        data-testid="submit-button"
                        variant="contained"
                        type="submit"
                        disabled={isLoading}
                    >
                        Complete Signup{' '}
                        {isLoading && <CircularProgress size={20} sx={{ marginLeft: '1rem' }} />}
                    </StyledButton>
                </FormContainer>
            </form>
        </FormOuterContainer>
    );
}

UserInfoForm.propTypes = {
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    register: PropTypes.func,
    errors: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleRecaptchaChange: PropTypes.func,
    recaptchaError: PropTypes.string,
    userProfile: PropTypes.object,
    userData: PropTypes.object,
    isChangingBlacklistedEmail: PropTypes.bool,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    watch: PropTypes.func,
    setValue: PropTypes.func,
};
