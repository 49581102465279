import { useState, useEffect, useCallback } from 'react';
import { useLazyGetTalentContactsQuery } from 'services/contacts';

import { MESSAGE_TYPES } from 'utils/constants';

export function useCheckContactData(props) {
    const { selectedTalents, messageType } = props;
    const [talentLookups, setTalentLookups] = useState([]);
    const [lookupFailure, setLookupFailure] = useState(false);
    const [hasContacts, setHasContacts] = useState(true);

    const [getTalentContacts, result] = useLazyGetTalentContactsQuery();
    const { data, isSuccess } = result;

    const handleGetContacts = useCallback(
        (talents) => {
            if (lookupFailure) {
                setLookupFailure(false); // used to reset failure flag whenever function runs
            }
            setTalentLookups(() => {
                return talents.map((talent) => {
                    const talentCopy = { ...talent };
                    const lookup = data[talent.talentId];
                    if (
                        hasContacts &&
                        ((messageType === MESSAGE_TYPES.EMAIL && !lookup?.emails?.data?.length) ||
                            (messageType === MESSAGE_TYPES.SMS && !lookup?.phones?.data?.length))
                    ) {
                        setHasContacts(false);
                    }
                    talentCopy['lookupFailed'] =
                        (messageType === MESSAGE_TYPES.EMAIL && !lookup?.emails?.data?.length) ||
                        (messageType === MESSAGE_TYPES.SMS && !lookup?.phones?.data?.length) ||
                        false;
                    if (talentCopy['lookupFailed']) {
                        setLookupFailure(true);
                    }
                    return talentCopy;
                });
            });
        },
        [messageType, data, lookupFailure, hasContacts]
    );

    useEffect(() => {
        if (data && isSuccess) {
            handleGetContacts(selectedTalents);
        }
    }, [data, isSuccess, selectedTalents, handleGetContacts]);

    return {
        getTalentContacts,
        contactsData: data,
        isContactsSuccess: isSuccess,
        talentLookups,
        lookupFailure,
        hasContacts,
    };
}
