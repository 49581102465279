import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';

import { Typography } from 'components/Typography';
import { WarningIcon } from 'components/Icons';

export function ConfirmDownloadDialog({
    url,
    filename,
    onClose,
    isFetching,
    isError,
    onConfirm,
    isOpen,
}) {
    return (
        <Dialog
            open={isOpen}
            aria-label="confirm-download-dialog"
            aria-describedby="confirm-download-action"
        >
            <DialogContent>
                <DialogContentText>
                    <Typography id="modal-modal-title" variant="smallHeadline">
                        Are you sure you want to download <b>{filename}</b> to your device?
                    </Typography>
                </DialogContentText>
                <DialogActions>
                    <Button aria-label="cancel-download" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        href={url}
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="confirm-file-download"
                        disabled={isFetching || isError}
                        sx={{ textDecoration: 'none', color: 'primary.main' }}
                        onClick={onConfirm}
                    >
                        {isFetching && <CircularProgress size={12} sx={{ mr: 0.5 }} />}
                        {isError && <WarningIcon sx={{ fontSize: 12, mr: 0.5 }} />}
                        {!isFetching && !isError && 'Download'}
                        {isFetching && 'Preparing'}
                        {isError && 'Error'}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

ConfirmDownloadDialog.propTypes = {
    url: PropTypes.string,
    filename: PropTypes.string,
    onClose: PropTypes.func,
    isFetching: PropTypes.bool,
    isError: PropTypes.bool,
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
};
