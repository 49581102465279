import { styled } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from 'components/Typography';
import { Fragment, useRef, useState } from 'react';
import { MentionEditor } from 'components/MentionEditor';
import { convertTextToTextWithMentionTags } from 'utils/mentionEditorUtils/convertTextToMentionTags';
import { RichTextDisplay } from 'components/RichTextDisplay';
import { useCustomQuery } from 'hooks/useCustomQuery';
import { getOrganisationUsers } from 'features/TalentNotes/services';
import { convertApiFormatToEditorState } from 'utils/mentionEditorUtils/convertApiFormatToEditorState';

const MentionEditorContainer = styled(Box)(() => ({
    resize: 'none',
    fontFamily: 'inherit',
    background: 'transparent',
    fontSize: 15,
    outlineWidth: 0,
    boxSizing: 'border-box',
    borderRadius: '3px',
    border: 'solid 1px rgba(38, 55, 70, 0.12)',
    '&:focus, &:focus-within': {
        borderColor: '#3A8DD3',
    },
    '& .public-DraftEditor-content': {
        minHeight: '82px',
        maxHeight: '747px',
    },
    '& .mentionSuggestions': {
        zIndex: 1000,
        borderRadius: '4px',
        background: '#fff',
        boxShadow: '0 0 0 1px rgba(16,22,26,.2), 0 2px 4px rgba(16,22,26,.4)',
        maxHeight: '210px',
        overflowY: 'auto !important',
    },
    '& .mentionSuggestionsContainer': {
        height: '210px',
        position: 'absolute',
    },
    '& .mention': {
        textDecoration: 'none',
        color: '#3A8DD3',
        fontWeight: '700',
    },
    padding: '5px',
    overflowY: 'scroll',
}));

export function NoteBody({
    isEditMode,
    note,
    handleSetEditNote,
    handleShiftEnter,
    noteCanvasRef,
    mentionEditorRef,
    editNoteValue,
}) {
    const mentionEditorContainerRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);

    const { data: orgUsersData } = useCustomQuery(
        ['organisation-users', { searchTerm }],
        getOrganisationUsers,
        {
            keepPreviousData: true,
        }
    );

    const mappedOrgUsers = orgUsersData?.map((user, i) => ({
        ...user,
        name: `${user.firstName} ${user.lastName}`,
        email: user.primaryEmail,
        index: i,
    }));

    const mappedMentionedUsers = note?.mentionedUsers?.map((mentionedUser) => ({
        ...mentionedUser,
        name: `@${mentionedUser.firstName} ${mentionedUser.lastName}`,
        keycloak_id: mentionedUser.keycloakId,
    }));

    const rawState = convertApiFormatToEditorState(note.content, mappedMentionedUsers);

    return (
        <Box sx={{ boxSizing: 'border-box', overflowX: 'hidden' }}>
            {isEditMode ? (
                <MentionEditorContainer ref={mentionEditorContainerRef}>
                    <MentionEditor
                        editorState={rawState}
                        suggestions={mappedOrgUsers}
                        onChange={handleSetEditNote}
                        onSearchChange={setSearchTerm}
                        handleSubmit={handleShiftEnter}
                        onAddMention={setSelectedUsers}
                        mentionEditorContainerRef={mentionEditorContainerRef}
                        getPopoverOpenState={(open) => {
                            if (open) {
                                mentionEditorContainerRef.current.style.overflowY = 'hidden';
                                noteCanvasRef.current.style.overflowY = 'hidden';
                            } else {
                                mentionEditorContainerRef.current.style.overflowY = 'scroll';
                                noteCanvasRef.current.style.overflowY = 'scroll';
                            }
                        }}
                        editorElementRef={mentionEditorRef}
                    />
                </MentionEditorContainer>
            ) : (
                <Typography
                    variant="body"
                    sx={{ wordWrap: 'break-word', fontSize: '14px' }}
                    data-testid="note-text-content"
                >
                    {convertTextToTextWithMentionTags(editNoteValue.content, [
                        ...(mappedMentionedUsers || []),
                        ...selectedUsers, // fallback for when the mentioned users have not yet been returned from the API after an optimistic update.
                    ])
                        .split('\n')
                        .map((line, index) => (
                            <Fragment key={index}>
                                <RichTextDisplay>{line}</RichTextDisplay>
                                <br />
                            </Fragment>
                        ))}
                </Typography>
            )}
        </Box>
    );
}

NoteBody.propTypes = {
    isEditMode: PropTypes.bool,
    note: PropTypes.object,
    handleSetEditNote: PropTypes.func,
    handleShiftEnter: PropTypes.func,
    noteCanvasRef: PropTypes.object,
    mentionEditorRef: PropTypes.object,
    editNoteValue: PropTypes.object,
};
