import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { selectContactById, updateContact } from '../ContactSelectionSlice';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({}));

function isNullOrEmpty(source) {
    if (Array.isArray(source) && source.length === 0) return true;
    return !source;
}

export function ContactCheckbox(props) {
    const { id } = props;

    const dispatch = useDispatch();

    const talent = useSelector((state) => selectContactById(state, id));
    const isFrcEnabled = useSelector((state) => state.communications.isFrcEnabled);

    const checked = talent?.selected;
    const noOptions = isNullOrEmpty(talent?.email) && isNullOrEmpty(talent?.phone);

    const handleCheckboxClick = () => {
        const changes = { selected: !checked };
        if (!changes.selected) {
            changes.phone = [];
            changes.email = [];
        }
        dispatch(updateContact({ id, changes }));
    };

    return (
        <StyledCheckbox
            aria-label={`${id}-selection`}
            checked={checked}
            disabled={isFrcEnabled || noOptions}
            onChange={handleCheckboxClick}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}

ContactCheckbox.propTypes = {
    id: PropTypes.string,
};
