import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

import { Tooltip } from 'components/Tooltip';
import { ClipboardIcon } from 'components/Icons';
import { GradientTypography } from 'components/Typography';

const CtaButton = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '&.Mui-disabled': {
        color: theme.palette.text.disabled,
        '& > .MuiTypography-root': {
            backgroundImage: 'unset',
            backgroundColor: 'unset',
            WebkitBackgroundClip: 'unset',
            WebkitTextFillColor: 'unset',
        },
    },
}));

export function CopyToClipBoardButton(props) {
    const { value, disabled, showText = true, ...restProps } = props;

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleCopyToClipBoard = async () => {
        try {
            await navigator.clipboard.writeText(value);
            setTooltipOpen(true);
            setTimeout(() => {
                setTooltipOpen(false);
            }, 1000);
        } catch (error) {
            console.error('Failed to copy to clipboard', error);
        }
    };

    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            title="Copied!"
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
        >
            <CtaButton
                aria-label="copy-job-description-to-clipboard"
                onClick={handleCopyToClipBoard}
                disabled={disabled}
                disableRipple
                {...restProps}
            >
                <ClipboardIcon sx={{ fontSize: 16 }} gradient={!disabled} />
                {showText && (
                    <GradientTypography sx={{ fontWeight: 500 }} variant="titleSmall">
                        Copy to Clipboard
                    </GradientTypography>
                )}
            </CtaButton>
        </Tooltip>
    );
}

CopyToClipBoardButton.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
    showText: PropTypes.bool,
};
