import { create } from 'zustand';

export const useZeroCreditPromptStore = create((set) => ({
    isOpen: false,
    isLoading: false,
    creditType: null,
    setOpen: (creditType) => set({ isOpen: true, creditType }),
    setClosed: () => set({ isOpen: false, creditType: null }),
    setIsLoading: (isLoading) => set({ isLoading }),
}));
