import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { InsertInvitationIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { LoadWrapper } from 'components/Loaders';

import { MyInvitationLinks } from './MyInvitationLinks';
import { UserSettingsButton } from './UserSettingsButton';
import { StyledDropdownMenu } from './StyledDropdownMenu';
import { useLazyGetAllSchedulesQuery } from 'services/scheduler';
import { MESSAGE_TYPES } from 'utils/constants';

const Button = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'disableShadow' && prop !== 'compact',
})(({ theme, disableShadow, compact }) => ({
    width: compact ? theme.spacing(3) : 'auto',
    height: theme.spacing(3),
    borderRadius: compact ? '50%' : theme.spacing(2),
    boxShadow: disableShadow
        ? 'inherit'
        : '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&.Mui-disabled': { opacity: 0.32 },
}));

export function InviteLinkButton(props) {
    const { messageType, openInNewTab, disableShadow, compact = false, ...restProps } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        getSchedules();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [getSchedules, { data, isFetching: isFetchingSchedules }] = useLazyGetAllSchedulesQuery();

    return (
        <>
            <Button
                onClick={handleClick}
                disabled={messageType === MESSAGE_TYPES.SMS}
                disableShadow={disableShadow}
                compact={compact}
                disableRipple
                data-testid="invitation-button"
                {...restProps}
            >
                <InsertInvitationIcon sx={{ fontSize: 16 }} gradient />
                {!compact && (
                    <Typography variant="titleSmall" color="primary" sx={{ fontWeight: 500 }}>
                        Add Invitation Link
                    </Typography>
                )}
            </Button>
            <StyledDropdownMenu
                id="invite-link-dropdown-menu"
                MenuListProps={{
                    'aria-labelledby': 'invite-link-dropdown-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <UserSettingsButton onClose={handleClose} openInNewTab={openInNewTab} />
                <LoadWrapper isLoading={isFetchingSchedules}>
                    {Boolean(data?.results?.length) && (
                        <MyInvitationLinks onClick={handleClose} data={data} />
                    )}
                </LoadWrapper>
            </StyledDropdownMenu>
        </>
    );
}

InviteLinkButton.propTypes = {
    messageType: PropTypes.string,
    refresh: PropTypes.bool,
    openInNewTab: PropTypes.bool,
    disableShadow: PropTypes.bool,
    compact: PropTypes.bool,
};
