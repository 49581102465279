import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';

import { BullhornIcon } from 'components/Icons';
import { useGetSourcePools } from 'features/SourcePoolDropdown/hooks/useGetSourcePools';

export function BullhornIconCta(props) {
    const { talent, size } = props;
    const { data } = useGetSourcePools();
    const poolSource = data?.find((item) => item.talentPoolIds.includes(talent.talentPoolId));

    if (!poolSource?.urlTemplate || !talent.sourceId) return null;

    const profileUrl = poolSource.urlTemplate.replace(/{source_id}/, talent.sourceId);

    return (
        <IconButton
            aria-label="bullhorn-profile-link"
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={profileUrl}
            disableRipple
            sx={{ padding: 0 }}
        >
            <BullhornIcon sx={{ fontSize: size || 16 }} />
        </IconButton>
    );
}

BullhornIconCta.propTypes = {
    talent: PropTypes.object,
    size: PropTypes.number,
};
