import { useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';

import { TalentBio } from './TalentBio';
import { TalentSkills } from './TalentSkills';
import { TalentExperience } from './TalentExperience';
import { TalentEducation } from './TalentEducation';
import { TalentCertifications } from './TalentCertifications';
import { Highlighter } from 'features/TalentCard/Highlighter';
import { useHighlighter } from 'features/TalentCard/Highlighter/hooks/useHighlighter';
import { RevealFromSocialProfile } from 'features/TalentCard/components/RevealFromSocialProfile';

const TalentProfileWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isSearch',
})(({ theme, isSearch }) => ({
    position: 'relative',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    ...(isSearch
        ? {
              '& ::selection': {
                  background: alpha(theme.palette.secondary.main, 0.12),
              },
              '& ::-moz-selection': {
                  background: alpha(theme.palette.secondary.main, 0.12),
              },
          }
        : {}),
}));

export function TalentProfileInfo({
    talentData,
    isExperienceDataHidden,
    isFetchingData,
    skillsData,
    currentTalentId,
    isSearch,
}) {
    const wrapperRef = useRef(null);

    const {
        emphasisToAdd,
        emphasisTooltipPosition,
        handleSelect,
        handleDeselect,
        handleHighlighterActionTaken,
    } = useHighlighter(wrapperRef);

    return (
        <TalentProfileWrapper
            onMouseUp={isSearch ? handleSelect : null}
            onMouseDown={isSearch ? handleDeselect : null}
            isSearch={isSearch}
            ref={wrapperRef}
        >
            <TalentBio bio={talentData?.bio} />
            {isExperienceDataHidden?.(talentData) ? (
                <RevealFromSocialProfile
                    isContactDataLoading={isFetchingData}
                    talentData={talentData}
                />
            ) : (
                <>
                    <TalentExperience experience={talentData.experience} />
                    <TalentCertifications certifications={talentData.certifications} />
                    {Boolean(skillsData?.length) && <TalentSkills skills={skillsData} />}
                    <TalentEducation education={talentData.education} />
                </>
            )}
            {isSearch && Boolean(emphasisToAdd) && Boolean(emphasisTooltipPosition) && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: emphasisTooltipPosition[0],
                        top: emphasisTooltipPosition[1],
                    }}
                >
                    <Highlighter
                        onActionTaken={handleHighlighterActionTaken}
                        onClickAway={handleDeselect}
                        selection={emphasisToAdd}
                    />
                </Box>
            )}
        </TalentProfileWrapper>
    );
}

TalentProfileInfo.propTypes = {
    talentData: PropTypes.object,
    isExperienceDataHidden: PropTypes.func,
    isFetchingData: PropTypes.bool,
    skillsData: PropTypes.object,
    currentTalentId: PropTypes.string,
    isSearch: PropTypes.bool,
};
