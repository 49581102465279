import PropTypes from 'prop-types';

import { MESSAGE_TYPES } from 'utils/constants';
import { EmailIcon, ReyIcon, SequenceArrowIcon, SmsIcon } from 'components/Icons';

export function MessageTypeIcon(props) {
    const { messageType, isDigitalAssistantMessage, isSequenceMessage } = props;

    if (isDigitalAssistantMessage) {
        // PLACEHOLDER UNTIL ICON IS FINALIZED
        return <ReyIcon sx={{ fontSize: 20, mr: 0.5 }} data-testid="rey-sub-icon" />;
    }
    if (isSequenceMessage) {
        return (
            <SequenceArrowIcon
                data-testid="sequence-sub-icon"
                sx={{ fontSize: 20, color: '#5F2BE1', mr: 0.5 }}
            />
        );
    }
    if (messageType === MESSAGE_TYPES.EMAIL) {
        return <EmailIcon sx={{ fontSize: 20, color: 'success.main', mr: 0.5 }} />;
    }
    if (messageType === MESSAGE_TYPES.SMS) {
        return <SmsIcon sx={{ fontSize: 20, color: 'success.main', mr: 0.5 }} />;
    }
    return null;
}

MessageTypeIcon.propTypes = {
    messageType: PropTypes.string,
    isDigitalAssistantMessage: PropTypes.bool,
    isSequenceMessage: PropTypes.bool,
};
