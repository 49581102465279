import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import { LoadingTab } from './LoadingTab';
import { Typography } from 'components/Typography';
import { CustomContactInputButtonToggle } from './CustomContactInputButtonToggle';
import { CONTACT_STATUS_MAP, CONTACT_TYPES } from '../utils/constants';
import { SocialListItem } from './SocialListItem';
import { useUserVerfiedContactsStore } from 'services/store/store';
import { useSwitchboard } from 'features/Switchboard';
import { useSelector } from 'react-redux';

export function SocialsTab(props) {
    const {
        socials,
        hasLookup,
        loadingState,
        onCreateCustomContact,
        handleFlagContact,
        staleSocialContacts,
    } = props;

    const { isLoading, isCreatingCustomContact, isFlagingContact } = loadingState;

    const { verifiedContacts, reportedContacts } = useUserVerfiedContactsStore();

    const isCustomContactEnabled = useSwitchboard('FEATURE_CUSTOM_CONTACTS');
    const isPremiumUser = useSelector((state) => state.user?.isPaid);
    const showAddSocialMediaButton =
        isPremiumUser && isCustomContactEnabled && (!!socials?.length || hasLookup);

    if (isLoading) {
        return <LoadingTab />;
    }

    const renderMainView = () => {
        if (!socials?.length && hasLookup) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                    <Typography variant="body">No social media data is available</Typography>
                </Box>
            );
        }

        const getIsReported = (contactDetails) =>
            reportedContacts.includes(contactDetails.contactId) ||
            contactDetails.status === CONTACT_STATUS_MAP.REPORTED;

        const getIsVerified = (contactDetails) =>
            contactDetails.status === CONTACT_STATUS_MAP.VERIFIED ||
            verifiedContacts.includes(contactDetails.contactId);

        return (
            <List disablePadding>
                {socials?.map((socialData, idx) => (
                    <SocialListItem
                        key={socialData.contactId + idx}
                        socialData={socialData}
                        flagContact={handleFlagContact}
                        isFlagingContact={isFlagingContact}
                        isVerified={getIsVerified(socialData)}
                        isReported={getIsReported(socialData)}
                        staleSocialContacts={staleSocialContacts}
                    />
                ))}
            </List>
        );
    };

    return (
        <Box>
            {showAddSocialMediaButton && (
                <CustomContactInputButtonToggle
                    isLoading={isCreatingCustomContact}
                    contactType={CONTACT_TYPES.SOCIAL}
                    onCreateCustomContact={onCreateCustomContact}
                />
            )}
            {renderMainView()}
        </Box>
    );
}

SocialsTab.propTypes = {
    loadingState: PropTypes.shape({
        isLoading: PropTypes.bool,
        isLookupLoading: PropTypes.bool,
        isPrimaryContactChangeLoading: PropTypes.bool,
        isCreatingCustomContact: PropTypes.bool,
        isFlagingContact: PropTypes.bool,
    }),
    socials: PropTypes.arrayOf(
        PropTypes.shape({
            contactType: PropTypes.string,
            network: PropTypes.string,
            url: PropTypes.string,
        })
    ),
    hasLookup: PropTypes.bool,
    onCreateCustomContact: PropTypes.func,
    isCreatingCustomContact: PropTypes.bool,
    handleFlagContact: PropTypes.func,
    staleSocialContacts: PropTypes.arrayOf(PropTypes.shape({ contactId: PropTypes.string })),
};
