import { useState, useEffect, useMemo } from 'react';

import { useGetBulkUserContactSettingsQuery, useGetTalentContactsQuery } from 'services/contacts';
import { useChannelPolicies } from 'views/Communications/hooks/useChannelPolicies';
import { processContactData, processPolicyData } from '../utils/dataProcessors';
import { useTalentData } from './useTalentData';

export function useContactsProcessing({ skip }) {
    const { selectedProfiles, handleSetContacts } = useTalentData();

    const [processedContacts, setProcessedContacts] = useState({});

    const talentIds = useMemo(() => {
        return selectedProfiles?.map((profile) => profile.talentId);
    }, [selectedProfiles]);

    const { data: policyData } = useChannelPolicies({
        talentIds,
        skip: !Boolean(talentIds?.length),
    });

    // We want to disable re-fetching this query when the Contacts Component is opened in modal
    // This is because we will have all the data already set in the redux handler after the first
    const result = useGetTalentContactsQuery(talentIds, { skip });
    const { data, isFetching, isSuccess } = result || {};

    const settingsResults = useGetBulkUserContactSettingsQuery(talentIds, { skip });
    const { data: contactSettingsData, isSuccess: isSettingsRetrieved } = settingsResults;

    useEffect(() => {
        if (data && isSuccess && contactSettingsData && isSettingsRetrieved && policyData) {
            const processedContacts = processContactData(data, contactSettingsData);
            const processedPolicyData = processPolicyData(processedContacts || data, policyData);
            setProcessedContacts(processedPolicyData);
            if (processedPolicyData) handleSetContacts(processedPolicyData);
        }
    }, [data, isSuccess, contactSettingsData, policyData, isSettingsRetrieved, handleSetContacts]);

    return {
        processedContacts,
        isFetching,
        policyData,
    };
}
