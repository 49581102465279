import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export function YourMediaButton() {
    return (
        <Button
            variant="contained"
            LinkComponent={Link}
            to="/user-settings/your-media"
            sx={{ textTransform: 'none' }}
        >
            Manage Your Media
        </Button>
    );
}
