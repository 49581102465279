export const NOTE_PRIVACY_OPTIONS = [
    { value: 'private', label: 'Only You', trackId: 'note-visibility-only-you' },
    { value: 'org', label: 'Your Org', trackId: 'note-visibility-your-org' },
];
export const NOTES_FILTER_OPTIONS = [
    { value: 'any', label: 'All Notes', trackId: 'filter-notes-all-notes' },
    { value: 'private', label: 'My Notes', trackId: 'filter-notes-my-notes' },
    { value: 'org', label: 'Org Notes', trackId: 'filter-notes-org-notes' },
];
export const NOTES_SOURCES = {
    SHORTLIST: 'shortlist',
    PROJECTS: 'projects',
    MESSAGE: 'message',
    SEARCH: 'search',
    PROFILE: 'profile',
};
