import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import { styled, alpha } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import { FlagContact } from './FlagContact';
import { DeleteIcon } from 'components/Icons';
import { getAbsoluteUrl } from 'utils/helpers';
import { Typography } from 'components/Typography';
import { CopyContactButton } from './CopyContactButton';
import { SocialMediaIconType } from './SocialMediaIconType';
import { DeleteCustomContact } from './DeleteCustomContact';
import { ContactStatusBadge } from './ContactStatusBadge.styled';

const Root = styled(ListItem)(({ theme }) => ({
    '&.MuiListItem-root': {
        padding: theme.spacing(1),
    },
}));

const SocialsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    '#flag-contact': {
        opacity: 0,
    },
    '#social-url': {
        marginLeft: theme.spacing(1),
        textDecoration: 'none',
        textTransform: 'none',
        opacity: 0,
    },
    '&:hover': {
        background: 'rgba(49, 141, 222, 0.08)',
        borderRadius: theme.spacing(0.5),
        '#social-url': {
            opacity: 1,
            transition: theme.transitions.create(['opacity'], {
                duration: theme.transitions.duration.shortest,
            }),
        },
        '#flag-contact': {
            opacity: 1,
            transition: theme.transitions.create(['opacity'], {
                duration: theme.transitions.duration.shortest,
            }),
        },
    },
}));

export function SocialListItem(props) {
    const {
        socialData,
        flagContact,
        isFlagingContact,
        isReported,
        isVerified,
        staleSocialContacts,
    } = props;
    const { source, accessType, contactId } = socialData;

    const isCustomContact = source === 'USER' && accessType === 'PRIVATE';

    const [isDeleting, setIsDeleting] = useState(false);

    const hideFlag = isReported || isVerified;

    const isOldContact = staleSocialContacts?.some(
        (staleContact) => staleContact.contactId === contactId
    );

    const renderFlags = () => {
        if (isReported) {
            return (
                <Box sx={{ display: 'inline' }}>
                    <ContactStatusBadge label="Reported" color="error" />
                </Box>
            );
        } else if (isVerified) {
            return (
                <Box sx={{ display: 'inline' }}>
                    <ContactStatusBadge label="Verified" color="success" />
                </Box>
            );
        } else if (!isOldContact) {
            return (
                <Box sx={{ display: 'inline' }}>
                    <ContactStatusBadge label="New" color="success" />
                </Box>
            );
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: (theme) => (isDeleting ? theme.spacing(0.5) : 'inherit'),
                background: (theme) =>
                    isDeleting ? alpha(theme.palette.error.main, 0.24) : 'inherit',
            }}
        >
            <SocialsWrapper>
                <Root
                    alignItems="flex-start"
                    disableGutters
                    disablePadding
                    aria-label="open-social-link"
                    rel="noopener noreferrer"
                    component="a"
                    href={getAbsoluteUrl(socialData.social.url)}
                    target="_blank"
                >
                    <ListItemIcon sx={{ minWidth: 32 }}>
                        <SocialMediaIconType socialMediaType={socialData?.social?.network} />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography
                                variant="labelSmall"
                                sx={{ textDecoration: 'none', color: 'primary.main' }}
                            >
                                {socialData.social.network || socialData.social.url}
                                {renderFlags()}
                                <span id="social-url">{socialData.social.url}</span>
                            </Typography>
                        }
                        disableTypography
                    />

                    {isCustomContact && !isDeleting && (
                        <ListItemIcon
                            onClick={(e) => {
                                e.preventDefault();
                                setIsDeleting(true);
                            }}
                            sx={{ cursor: 'pointer', minWidth: '24px' }}
                        >
                            <DeleteIcon
                                color="error"
                                sx={{ fontSize: 16 }}
                                aria-label="Delete contact"
                            />
                        </ListItemIcon>
                    )}
                </Root>
                <Box sx={{ ml: 1, mt: 1 }}>
                    <CopyContactButton
                        ariaLabel="copy-social-media-profile-url"
                        contactData={getAbsoluteUrl(socialData.social.url)}
                    />
                </Box>
                {!isFlagingContact && !hideFlag && (
                    <Box id="flag-contact" sx={{ mt: 0.6 }}>
                        <FlagContact contactId={contactId} flagContact={flagContact} />
                    </Box>
                )}
            </SocialsWrapper>

            {isCustomContact && (
                <DeleteCustomContact
                    contactId={socialData.contactId}
                    expanded={isDeleting}
                    setExpanded={setIsDeleting}
                />
            )}
        </Box>
    );
}

SocialListItem.propTypes = {
    socialData: PropTypes.shape({
        contactId: PropTypes.string,
        source: PropTypes.string,
        accessType: PropTypes.string,
        social: PropTypes.shape({
            url: PropTypes.string,
            network: PropTypes.string,
        }),
    }),
    isFlagingContact: PropTypes.bool,
    flagContact: PropTypes.func,
    isReported: PropTypes.bool,
    isVerified: PropTypes.bool,
    staleSocialContacts: PropTypes.arrayOf(PropTypes.shape({ contactId: PropTypes.string })),
};
