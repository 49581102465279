import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useCallback, useState, useEffect, useRef } from 'react';

import { InfoIcon } from 'components/Icons';
import { Tooltip } from 'components/Tooltip';
import { EXPERIENCE_FILTER } from './constants';
import { StyledSlider } from './styledComponents';
import { Typography } from 'components/Typography';

const DEFAULT_VALUE = [0, 31];

const marks = [0, 5, 10, 15, 20, 25, 31].map((value) => ({
    value,
    label: calculateLabel(value),
}));

const transformText = (value) => {
    return value === '30+' ? 100 : Number(value);
};

// export for testing
export function convertFilterValueToSliderRange(filterValue) {
    if (!Array.isArray(filterValue)) {
        return DEFAULT_VALUE;
    }
    // if any data is bad, return default values
    if (!filterValue.some((value) => value >= 0 && value <= 31)) {
        return DEFAULT_VALUE;
    }
    return [filterValue[0], filterValue[1]];
}

// export for testing
export function calculateLabel(value) {
    if (value === 31) {
        return '30+';
    }
    return `${value}`;
}

// export for testing
export function createChangeHandler(originalValue, setNewValue, callback) {
    return (newValues) => {
        if (newValues[0] === newValues[1]) {
            return;
        }
        if (newValues !== originalValue) {
            const rangeData = newValues.map((newValue) => transformText(calculateLabel(newValue)));
            callback(rangeData);
            setNewValue(newValues);
        }
    };
}

export function ExperienceSlider(props) {
    const { title, tooltip, onChangeYearsOfExperience } = props;

    const experienceFilterValue = useSelector(
        (state) => state.search.filters[EXPERIENCE_FILTER.YEARS_OF_EXPERIENCE]
    );

    const isChanging = useRef(false);

    const [value, setValue] = useState(DEFAULT_VALUE);

    const handleChange = useCallback(
        (_, newValues) => {
            isChanging.current = true;
            const changeHandler = createChangeHandler(value, setValue, () => {});
            changeHandler(newValues);
        },
        [value]
    );

    const handleChangeCommitted = useCallback(
        (_, newValues) => {
            isChanging.current = false;
            const changeHandler = createChangeHandler(value, setValue, onChangeYearsOfExperience);
            changeHandler(newValues);
        },
        [value, onChangeYearsOfExperience]
    );

    useEffect(() => {
        if (!isChanging.current && !experienceFilterValue && (value[0] !== 1 || value[1] !== 31)) {
            setValue(DEFAULT_VALUE);
        } else if (!isChanging.current && experienceFilterValue) {
            const sliderRangeFromFilter = convertFilterValueToSliderRange(experienceFilterValue);
            if (sliderRangeFromFilter[0] !== value[0] || sliderRangeFromFilter[1] !== value[1]) {
                setValue(sliderRangeFromFilter);
            }
        }
    }, [experienceFilterValue, value]);

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="labelMedium">{title}</Typography>
                {tooltip && (
                    <Tooltip title={tooltip} placement="top">
                        <div>
                            <InfoIcon
                                sx={{
                                    ml: 0.5,
                                    mb: -0.25,
                                    fontSize: '1rem',
                                    cursor: 'pointer',
                                    color: 'common.white',
                                }}
                            />
                        </div>
                    </Tooltip>
                )}
            </Box>
            <Box sx={{ px: 1, mt: 1 }}>
                <StyledSlider
                    step={1}
                    value={value}
                    onChange={handleChange}
                    onChangeCommitted={handleChangeCommitted}
                    valueLabelDisplay="on"
                    marks={marks}
                    scale={calculateLabel}
                    min={0}
                    max={31}
                    data-testid="years-of-experience-slider"
                    data-trackid="yoe-filter-slider"
                    disableSwap
                />
            </Box>
        </Box>
    );
}

ExperienceSlider.propTypes = {
    title: PropTypes.string,
    tooltip: PropTypes.string,
    onChangeYearsOfExperience: PropTypes.func,
};
