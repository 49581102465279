export function generateRepeatingSearchQueryString(params = {}) {
    const searchParams = new URLSearchParams();
    const filteredParams = Object.entries(params).filter(([_, value]) => Boolean(value));
    filteredParams.forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((val) => {
                searchParams.append(key, val);
            });
        } else {
            searchParams.append(key, value);
        }
    });
    return searchParams.toString();
}
