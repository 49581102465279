import SvgIcon from '@mui/material/SvgIcon';

export function FlagIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M11.7376 6.97197L11.1146 6.05497C10.4916 5.13797 10.4916 3.95498 11.1146 3.04498L11.7376 2.12798C11.9336 1.84798 11.9476 1.47699 11.7936 1.17599C11.6396 0.881995 11.3386 0.7 11.0096 0.7H3.49863C3.49863 0.315 3.18363 0 2.79863 0C2.41363 0 2.09863 0.315 2.09863 0.7V13.3C2.09863 13.685 2.41363 14 2.79863 14C3.18363 14 3.49863 13.685 3.49863 13.3V8.4H11.0096C11.3386 8.4 11.6396 8.21796 11.7936 7.92396C11.9546 7.62296 11.9336 7.25197 11.7446 6.97197H11.7376ZM3.49863 7V2.1H10.0576L9.95263 2.25398C9.00063 3.64698 9.00063 5.44597 9.95263 6.83897L10.0576 6.99299H3.49163L3.49863 7Z"
                fill="currentColor"
                fillOpacity="0.48"
            />
        </SvgIcon>
    );
}
