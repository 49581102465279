import SvgIcon from '@mui/material/SvgIcon';

export function ProjectsMessageIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M11.4731 4.20355H7.43411C6.04113 4.20355 4.90716 5.33754 4.90716 6.73051V9.76146C4.90716 11.1544 6.04113 12.2884 7.43411 12.2884H10.4651C11.1301 12.2884 11.984 13.2964 12.733 13.2964C13.622 13.2964 14 12.5964 14 11.7774V6.72352C14 5.33054 12.866 4.19656 11.4731 4.19656V4.20355ZM12.6 11.7844C12.495 11.7284 12.383 11.6584 12.292 11.5954C11.809 11.2804 11.2001 10.8884 10.4651 10.8884H7.43411C6.81112 10.8884 6.30714 10.3845 6.30714 9.76146V6.73051C6.30714 6.10752 6.81112 5.60353 7.43411 5.60353H11.4731C12.096 5.60353 12.6 6.10752 12.6 6.73051V11.7844Z"
                fill="url(#paint0_linear_10459_60272)"
            />
            <path
                d="M3.15018 9.93646L2.97518 10.0485C2.66018 10.2515 2.26819 10.5034 2.05119 10.5034C1.75719 10.5034 1.64522 10.3915 1.56822 10.3005C1.44922 10.1395 1.37921 9.88047 1.38621 9.57247V4.11956C1.39321 3.00658 2.3032 2.09659 3.42318 2.09659H7.7911C8.36509 2.10359 8.91809 2.34859 9.30308 2.78258C9.40108 2.89458 9.49207 3.01358 9.56207 3.13958C9.75107 3.47557 10.1781 3.59457 10.5141 3.40557C10.8501 3.21657 10.969 2.78958 10.78 2.45358C10.661 2.23659 10.5141 2.03359 10.3461 1.8446C9.69507 1.11661 8.76409 0.703613 7.79811 0.703613H3.43017C1.5402 0.703613 0.00722822 2.22959 0.000228335 4.11956V9.57247C-0.00677155 10.1885 0.147222 10.7344 0.455216 11.1404C0.82621 11.6374 1.39321 11.9104 2.0582 11.9104C2.68818 11.9104 3.24817 11.5464 3.73816 11.2244L3.89917 11.1194C4.22816 10.9094 4.31916 10.4754 4.11616 10.1535C3.90617 9.82446 3.47217 9.73346 3.15018 9.93646Z"
                fill="url(#paint1_linear_10459_60272)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_10459_60272"
                    x1="-3.9208e-08"
                    y1="5.22638"
                    x2="14.0034"
                    y2="5.30125"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_10459_60272"
                    x1="-3.9208e-08"
                    y1="5.22638"
                    x2="14.0034"
                    y2="5.30125"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}
