import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled';

export const StyledIconButton = styled(IconButton)(
    ({ theme, disabled }) => ({
        color: theme.palette.text.primary,
        border: !disabled ? `1px solid ${theme.palette.primary.main}` : '1px solid #3A8DD333',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: disabled ? 'none' : '0px 1px 3px 1px #00000026',
        width: '32px',
        height: '32px',
    }),
    { shouldForwardProp: (prop) => prop !== 'disabled' }
);
