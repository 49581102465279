import { useEffect } from 'react';
import Lottie from 'lottie-react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import escape from 'lodash/escape';

import { RichTextInput } from 'components/Inputs';
import { useMessageEditor } from 'features/MessageDrawer/hooks/useMessageEditor';
import { useDynamicFields } from 'features/DynamicFields';
import { TARGET_ENUMS } from 'features/MessageDrawer/utils';
import { ComposeHeader } from './ComposeHeader';
import { ComposeFooter } from './ComposeFooter';
import { useCheckSmsSent } from 'views/Communications';
import { MESSAGE_TYPES } from 'utils/constants';
import { setClearInput, setInitialBody } from 'features/MessageDrawer/messageSlice';
import { useBulkMessageTemplateText } from 'views/Communications/hooks/useBulkMessageTemplateText';
import { getContactAccessor } from 'utils/helpers';
import { MessageGenerationOptions } from '../MessageGenerationComponents/MessageGenerationOptions';
import jdLoadingAnimation from 'assets/lottiefiles/nebula-nlg-loading.json';
import { GradientTypography } from 'components/Typography';

const TextInputProps = {
    'aria-label': 'body-input',
    'data-trackid': 'message-drawer-body-input',
};

const LoaderContainer = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
    background: '#fff',
    zIndex: 1,
    borderRadius: theme.spacing(0.5),
}));

export function BodyEditor(props) {
    // @TODO: add body validation - can be done with props or within this component up to dev's discretion
    const { autofocus, isFetching, variant = 'compose' } = props;

    const { isFormatting, messageType, selectedProfiles, selectedContacts, isPreparingStream } =
        useSelector((state) => state.message);

    const accessor = getContactAccessor(messageType);

    const dispatch = useDispatch();

    const { isFirstSMSMessage } = useCheckSmsSent({ profiles: selectedProfiles });
    const isFrcEnabled = useSelector((state) => state.communications.isFrcEnabled);

    const { innerHtml, innerText, initialBody, clearInput, innerHtmlRef, handleMessageBody } =
        useMessageEditor({
            target: TARGET_ENUMS.BODY,
        });

    const { dynamicFieldDecorator } = useDynamicFields();

    const hasDeclineText = messageType === MESSAGE_TYPES.SMS && isFirstSMSMessage;

    const setInitialBodyFromBulkMessageText = (templateText) =>
        dispatch(
            setInitialBody({
                target: TARGET_ENUMS.BODY,
                initialBody: templateText,
            })
        );

    const onRemoveBulkMessageText = (templateText) => {
        const newInnerHTML = innerHtml
            .replace(escape(templateText.trim()), '')
            .replace(/^\s*<br\/?>/i, '');

        dispatch(
            setInitialBody({
                target: TARGET_ENUMS.BODY,
                initialBody: newInnerHTML,
            })
        );
    };

    useBulkMessageTemplateText({
        onSuccess: setInitialBodyFromBulkMessageText,
        showBulkMessageText:
            !isFrcEnabled &&
            Object.entries(selectedContacts).some(([_, contactDetails]) => {
                const contactData = contactDetails[accessor].data;
                return contactData.length > 1 && contactData.every((contact) => contact.selected);
            }),
        shouldRemoveBulkMessageText: true,
        onRemoveBulkMessageText,
    });

    useEffect(() => {
        // resets messages
        if (clearInput && (innerText === '' || innerHtml === '<br/>' || Boolean(initialBody))) {
            dispatch(setClearInput(false));
        }
    }, [dispatch, clearInput, innerText, innerHtml, initialBody]);

    const overlayHeight = innerHtmlRef.current?.clientHeight?.();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pb: 1.8, position: 'relative' }}>
            <RichTextInput
                fullWidth
                EditorWrapperSxProps={{
                    maxWidth: '448px',
                }}
                ref={innerHtmlRef}
                autoFocus={autofocus}
                name={`compose-rich-text-${variant}`}
                value={innerHtml}
                disabled={isFetching}
                inputProps={TextInputProps}
                initialValue={initialBody}
                isFormatting={isFormatting}
                hasDeclineText={hasDeclineText}
                isToolbarDisabled={false}
                initiateMount={false}
                clearInput={clearInput}
                formattingButtonPopperStyles={{
                    zIndex: 9998,
                    width: 440,
                }}
                isCondensed={false}
                isEditMode={false}
                onChange={handleMessageBody}
                decorator={dynamicFieldDecorator}
                CustomHeader={ComposeHeader}
                CustomHeaderProps={{
                    variant,
                }}
                CustomFooter={ComposeFooter}
                formattingToolbarPlacement="bottom"
            />
            <MessageGenerationOptions overlayHeight={overlayHeight} />
            {isPreparingStream && (
                <LoaderContainer
                    sx={{
                        minHeight: 332,
                        top: 69,
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            background:
                                'linear-gradient(90.28deg, rgba(80, 76, 224, 0.08) 0.17%, rgba(49, 141, 222, 0.08) 99.72%)',
                        }}
                    >
                        <Lottie animationData={jdLoadingAnimation} style={{ width: '192px' }} />
                        <GradientTypography variant="titleMedium">
                            Drafting your message...
                        </GradientTypography>
                    </Box>
                </LoaderContainer>
            )}
        </Box>
    );
}

BodyEditor.propTypes = {
    autofocus: PropTypes.bool,
    isFetching: PropTypes.bool,
    variant: PropTypes.string,
};
