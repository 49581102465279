import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

import { ContactIcon } from './ContactIcon';
import { PhoneIcon, LetterIcon, WorldIcon } from 'components/Icons';
import { Button, Typography, alpha } from '@mui/material';
import styled from '@emotion/styled';
import isNil from 'lodash/isNil';

function createClickHandler(callback, index) {
    return () => {
        callback(index);
    };
}

const StyledButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(3.25),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.7, 1),
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.9),
    },
}));

const buttonContentStyles = { fontSize: '13px', ml: 0.25, mr: 0.25 };

export function ContactDetailsOverview(props) {
    const { counts, onClick, isLoading, onlySocialIcon, dataTrackIds } = props;

    const { emails, phones, socials } = counts || {};

    const showLookupButton =
        !onlySocialIcon &&
        isNil(emails) &&
        isNil(phones) &&
        isNil(socials) &&
        !isLoading &&
        Boolean(counts);

    const isContactsLoading = !Boolean(counts) || isLoading;

    if (showLookupButton) {
        return (
            <StyledButton
                data-testid="lookup-button"
                data-trackid={dataTrackIds?.lookup}
                onClick={createClickHandler(onClick, 0)}
            >
                <Typography sx={buttonContentStyles}>Lookup</Typography>
                <LetterIcon sx={buttonContentStyles} />
                <PhoneIcon sx={buttonContentStyles} />
                <WorldIcon sx={buttonContentStyles} />
            </StyledButton>
        );
    }

    return (
        <Stack direction="row" spacing={1}>
            {!onlySocialIcon && (
                <>
                    <ContactIcon
                        count={emails}
                        icon={<LetterIcon sx={{ fontSize: '18px' }} />}
                        onClick={createClickHandler(onClick, 0)}
                        isLoading={isContactsLoading}
                        aria-label="view-email-contacts"
                        data-trackid={dataTrackIds?.emails}
                    />
                    <ContactIcon
                        count={phones}
                        icon={<PhoneIcon sx={{ fontSize: '18px' }} />}
                        onClick={createClickHandler(onClick, 1)}
                        isLoading={isContactsLoading}
                        aria-label="view-phone-contacts"
                        data-trackid={dataTrackIds?.phone}
                    />
                </>
            )}
            <ContactIcon
                count={socials}
                icon={<WorldIcon sx={{ fontSize: '18px' }} />}
                onClick={createClickHandler(onClick, 2)}
                isLoading={isContactsLoading}
                aria-label="view-social-contacts"
                data-trackid={dataTrackIds?.socials}
            />
        </Stack>
    );
}

ContactDetailsOverview.propTypes = {
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    onlySocialIcon: PropTypes.bool,
    counts: PropTypes.shape({
        emails: PropTypes.number,
        phones: PropTypes.number,
        socials: PropTypes.number,
    }),
    dataTrackIds: PropTypes.shape({
        lookup: PropTypes.string,
        emails: PropTypes.string,
        phone: PropTypes.string,
        socials: PropTypes.string,
    }),
};
