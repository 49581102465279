import trimStart from 'lodash/trimStart';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Typography } from 'components/Typography';
import Box from '@mui/material/Box';
import { useRef } from 'react';
import { getValueByMode } from 'utils/copy';

const StyledListItem = styled('li', { shouldForwardProp: (prop) => prop !== 'isFocused' })(
    ({ isFocused }) => ({
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '36px',
        padding: '8px 16px',
        background: getValueByMode(isFocused, '#F3F8FD', '#fff'),
        cursor: 'pointer',
        width: '242px',
        zIndex: 1000,
    })
);

export function UserSuggestionItem(props) {
    const { mention, isFocused, ...rest } = props;
    const name = trimStart(mention.name, '@');
    const entryRef = useRef(null);

    return (
        <Box ref={entryRef} {...rest}>
            <StyledListItem isFocused={isFocused}>
                <Box
                    sx={{
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <Typography variant="bodyBaseBold" sx={{ mr: 1 }}>
                        {name}
                    </Typography>
                    <Typography variant="body" sx={{ mr: 1 }}>
                        {mention.email}
                    </Typography>
                </Box>
            </StyledListItem>
        </Box>
    );
}

UserSuggestionItem.propTypes = {
    isFocused: PropTypes.bool,
    mention: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
    }),
    selectMention: PropTypes.func,
};
