import { MultiSelectFilter } from './MultiSelectFilter';
import {
    setEnableSequencesFilter,
    setSequencesFilter,
} from 'views/Communications/communicationsSlice';
import { useMultiSelectFilter } from '../hooks/useMultiSelectFilter';
import { messagingWorkflowFilterOptions, MULTI_SELECT_FILTER_TYPES } from '../constants';

export function FilterSequences() {
    const { isFilterEnabled, handleFilterCheck, filterValue, handleFilterChange } =
        useMultiSelectFilter(
            MULTI_SELECT_FILTER_TYPES.sequences,
            setSequencesFilter,
            setEnableSequencesFilter
        );

    return (
        <MultiSelectFilter
            isFilterEnabled={isFilterEnabled}
            handleFilterCheck={handleFilterCheck}
            filterValue={filterValue}
            handleFilterChange={handleFilterChange}
            label="Sequences"
            filterOptions={messagingWorkflowFilterOptions}
        />
    );
}
