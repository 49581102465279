import { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { DataObjectIcon } from 'components/Icons';
import { DynamicFieldsDropdown } from 'features/DynamicFields/components/DynamicFieldsDropdown';
import { IconButton } from '@mui/material';

const StyledIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'disableShadow',
})(({ theme, disableShadow }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    boxShadow: disableShadow
        ? 'inherit'
        : '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    '&.Mui-disabled': { opacity: 0.32 },
}));

export function DynamicFieldsButton({ disableShadow, ...restProps }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <StyledIconButton
                role="button"
                onClick={handleClick}
                data-testid="dynamic-fields-button"
                disableShadow={disableShadow}
                {...restProps}
            >
                <DataObjectIcon sx={{ fontSize: 16 }} gradient />
            </StyledIconButton>
            <DynamicFieldsDropdown anchorEl={anchorEl} onClose={handleClose} />
        </>
    );
}

DynamicFieldsButton.propTypes = {
    disableShadow: PropTypes.bool,
};
