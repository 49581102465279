import { convertToRaw } from 'draft-js';

export const convertEditorStateToApiFormat = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const { blocks, entityMap } = rawContentState;

    const commentBlocks = blocks.reduce((acc, block) => {
        acc.push(
            block.entityRanges.reduce((mutableText, { key }) => {
                const { mention } = entityMap[key].data;
                if (mention) {
                    mutableText = mutableText.replace(mention.name, `{{${mention.keycloak_id}}}`);
                }
                return mutableText;
            }, block.text)
        );
        return acc;
    }, []);
    return commentBlocks.join('\n');
};
