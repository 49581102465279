import { useState } from 'react';
import PropTypes from 'prop-types';
import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import { FilterMenuItem } from './styledComponents';
import { toggleAndFilter } from 'views/Search/searchSlice';
import { ChevronDownIcon, CheckCircleIcon } from 'components/Icons';
import { Typography, TYPOGRAPHY_VARIANTS } from 'components/Typography';

const StyledChevron = styled(ChevronDownIcon)({
    width: 16,
});

const Select = styled(MuiSelect)(({ theme }) => ({
    ...TYPOGRAPHY_VARIANTS.bodySmallBold,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSelect-select': {
        backgroundColor: theme.palette.common.blueGray,
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        paddingLeft: 6,
        paddingRight: '20px !important',
    },
    '& .MuiSelect-icon': {
        color: theme.palette.common.white,
        marginTop: 1,
        marginRight: -4,
    },
}));

export function AnyAllSelect(props) {
    const { filterKey } = props;

    const dispatch = useDispatch();

    const [anyAllSelectOpen, setAnyAllSelectOpen] = useState(false);

    const andFilters = useSelector((state) => state.search.andFilters);

    const selectValue = andFilters.includes(filterKey);

    const handleOpenSelect = () => {
        setAnyAllSelectOpen(true);
    };

    const handleCloseSelect = () => {
        setAnyAllSelectOpen(false);
    };

    const createClickHandler = (value) => () => {
        if (value === selectValue) {
            dispatch(toggleAndFilter(filterKey));
        }
    };

    return (
        <Select
            open={anyAllSelectOpen}
            onClose={handleCloseSelect}
            onOpen={handleOpenSelect}
            value={selectValue}
            IconComponent={StyledChevron}
            renderValue={(value) => (value ? 'ALL' : 'ANY')}
            MenuProps={{
                MenuListProps: {
                    sx: {
                        color: 'common.white',
                        backgroundColor: 'text.primary',
                    },
                },
                transformOrigin: { horizontal: 'left', vertical: 'top' },
                anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            }}
        >
            <FilterMenuItem value={true} active={!selectValue} onClick={createClickHandler(true)}>
                <Typography variant="bodySmall">
                    ANY - Results must include at least one the selected values
                </Typography>
                {!selectValue && (
                    <CheckCircleIcon color="success" sx={{ ml: 0.5, mb: -0.5, width: 16 }} />
                )}
            </FilterMenuItem>
            <FilterMenuItem value={false} active={selectValue} onClick={createClickHandler(false)}>
                <Typography variant="bodySmall">
                    ALL - Results must include all of the selected values
                </Typography>
                {selectValue && (
                    <CheckCircleIcon color="success" sx={{ ml: 0.5, mb: -0.5, width: 16 }} />
                )}
            </FilterMenuItem>
        </Select>
    );
}

AnyAllSelect.propTypes = {
    filterKey: PropTypes.string,
};
