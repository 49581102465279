import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { resetMessageBody, setMessageBody, setValidationError } from '../messageSlice';
import { TARGET_ENUMS, VALIDATION_ERRORS } from '../utils';
import { MESSAGE_TYPES, MINIMUM_EMAIL_SUBJECT_LENGTH } from 'utils/constants';

const validateSubject = (subjectLength) => {
    const validSubject = subjectLength > MINIMUM_EMAIL_SUBJECT_LENGTH;
    return [validSubject, VALIDATION_ERRORS.SUBJECT.SUBJECT_TOO_SHORT];
};

const validateBody = (type, bodyLength) => {
    const validEmailBody = bodyLength > 0;
    const validSmsBody = bodyLength > 0 && bodyLength < 1600;

    if (type === MESSAGE_TYPES.EMAIL) {
        return [validEmailBody, VALIDATION_ERRORS.BODY.BODY_EMPTY];
    }
    if (type === MESSAGE_TYPES.SMS) {
        return [
            validSmsBody,
            bodyLength === 0
                ? VALIDATION_ERRORS.BODY.BODY_EMPTY
                : VALIDATION_ERRORS.BODY.SMS_BODY_TOO_LONG,
        ];
    }
};

export function useMessageEditor({ target }) {
    const clearInput = useSelector((state) => state.message.clearInput);

    const innerHtmlRef = useRef('');

    const { messageType } = useSelector((state) => state.message);
    const { innerHtml, innerText, initialBody, validationError } = useSelector(
        (state) => state.message[target]
    );
    const dispatch = useDispatch();

    const handleMessageBody = useCallback(
        (event) => {
            dispatch(
                setMessageBody({
                    target,
                    innerHtml: event.target.value,
                    innerText: event.target.plainText,
                })
            );
        },
        [dispatch, target]
    );

    const debouncedHandleMessageBody = debounce(handleMessageBody, 500);

    const handleResetMessageBody = useCallback(() => {
        dispatch(
            resetMessageBody({
                target,
            })
        );
    }, [dispatch, target]);

    useEffect(() => {
        if (target === TARGET_ENUMS.SUBJECT) {
            const [isValid, error] = validateSubject(innerText.trim().length);
            dispatch(
                setValidationError({
                    target,
                    error: isValid ? '' : error,
                })
            );
        } else {
            const [isValid, error] = validateBody(messageType, innerText.trim().length);
            dispatch(
                setValidationError({
                    target,
                    error: isValid ? '' : error,
                })
            );
        }
        return () => {
            dispatch(
                setValidationError({
                    target,
                    error: '',
                })
            );
        };
    }, [target, innerText, messageType, dispatch]);

    return {
        innerHtml,
        innerText,
        initialBody,
        innerHtmlRef,
        validationError,
        clearInput,
        handleMessageBody: debouncedHandleMessageBody,
        handleResetMessageBody,
    };
}
