import { createSlice } from '@reduxjs/toolkit';

export const initialSignatureState = {
    selectedSignature: {},
    hasSignatureContentChange: false,
    signatureBody: '',
    isValidSignature: false,
    isValidMaxSignatureLength: false,
};

export const signatureSlice = createSlice({
    name: 'signature',
    initialState: initialSignatureState,
    reducers: {
        setSelectedSignature: (state, { payload }) => {
            state.selectedSignature = payload;
        },
        clearSelectedSignature: (state) => {
            state.selectedSignature = {};
        },
        setHasSignatureContentChange: (state, { payload }) => {
            state.hasSignatureContentChange = payload;
        },
        resetSignature: () => initialSignatureState,
        changeSignatureBody: (state, { payload }) => {
            state.signatureBody = payload;
        },
        setIsValidSignature: (state, { payload }) => {
            state.isValidSignature = payload;
        },
        setIsValidMaxSignatureLength: (state, { payload }) => {
            state.isValidMaxSignatureLength = payload;
        },
    },
});

export const {
    setSelectedSignature,
    clearSelectedSignature,
    setHasSignatureContentChange,
    resetSignature,
    changeSignatureBody,
    setIsValidSignature,
    setIsValidMaxSignatureLength,
} = signatureSlice.actions;

export const signatureReducer = signatureSlice.reducer;
