import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiDivider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import { alpha, styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import { SkillsIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { CertificationPopover } from './CertificationPopover';
import { changeStandardExcludeFilter, changeStandardFilter } from 'views/Search';
import { CERTIFICATION_FILTER } from 'views/Search/features/FiltersBar/components/constants';

const Divider = styled(MuiDivider)(({ theme }) => ({
    borderColor: alpha(theme.palette.text.primary, 0.24),
    marginRight: theme.spacing(1),
    mt: theme.spacing(0.5),
    mb: theme.spacing(0.25),
}));

const NameContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
}));

const Popover = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isMatch',
})(({ theme, isMatch }) => ({
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1000,
    width: 'max-content',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    background: isMatch
        ? 'linear-gradient(93deg, #504CE0 0%, #318DDE 100%)'
        : theme.palette.primary.main,
    backgroundColor: !isMatch ? theme.palette.primary.main : 'unset',
    '& .MuiPaper-root': {
        borderRadius: theme.shape.borderRadius * 2,
    },
}));

const selectCertificationFilter = (state) => state.search.filters[CERTIFICATION_FILTER] || [];
const selectExcludedCertificationFilter = (state) =>
    state.search.exclusionFilters[CERTIFICATION_FILTER] || [];

export function Certification(props) {
    const { certification, showDivider, isSearch, ...restProps } = props;

    const dispatch = useDispatch();
    const certificationFilter = useSelector(selectCertificationFilter);
    const excludedCertificationFilter = useSelector(selectExcludedCertificationFilter);

    const [isOpen, setOpen] = useState(false);
    const [isPopoverOpen, setPopoverOpen] = useState(false);

    const handleOpen = () => {
        setOpen((prevState) => !prevState);
    };

    const handleTogglePopover = () => {
        setPopoverOpen((prevState) => !prevState);
        setOpen(false);
    };

    const handleAddCertificationFilter = (cert) => {
        dispatch(
            changeStandardFilter({
                property: CERTIFICATION_FILTER,
                matchText: [...certificationFilter, cert],
            })
        );
    };

    const handleAddCertificationExcludeFilter = (cert) => {
        dispatch(
            changeStandardExcludeFilter({
                property: CERTIFICATION_FILTER,
                matchText: [...excludedCertificationFilter, cert],
            })
        );
    };

    const getTextMatchSX = (certification) => {
        return certification?.match
            ? {
                  background: 'linear-gradient(93deg, #504CE0 0%, #318DDE 100%)',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontWeight: 900,
              }
            : {
                  fontWeight: 400,
              };
    };

    return (
        <Box sx={{ mb: 0.5, maxWidth: '135px', position: 'relative' }} {...restProps}>
            <NameContainer
                aria-haspopup="true"
                onMouseEnter={handleTogglePopover}
                onMouseLeave={handleTogglePopover}
            >
                <SkillsIcon
                    sx={{ fontSize: 12, mb: -0.5 }}
                    color="primary"
                    gradient={certification?.match}
                />
                <Typography
                    variant="bodySmall"
                    color="primary"
                    sx={{ mr: 1, ...getTextMatchSX(certification) }}
                    noWrap
                >
                    {certification.name}
                </Typography>
                {showDivider && <Divider orientation="vertical" flexItem />}
            </NameContainer>
            {isPopoverOpen && (
                <Popover isMatch={certification?.match}>
                    <Collapse in={isOpen} collapsedSize={20} sx={{ borderRadius: 2 }}>
                        <CertificationPopover
                            onToggleOpen={handleTogglePopover}
                            isExpanded={isOpen}
                            onExpand={handleOpen}
                            certification={certification}
                            onAddCertificationFilter={handleAddCertificationFilter}
                            onAddCertificationExcludeFilter={handleAddCertificationExcludeFilter}
                            isSearch={isSearch}
                        />
                    </Collapse>
                </Popover>
            )}
        </Box>
    );
}

Certification.propTypes = {
    certification: PropTypes.object,
    showDivider: PropTypes.bool,
    isSearch: PropTypes.bool,
};
