import { isValidElement } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

/*
Simple component used to wrap bodies of JSX elements to render a loader component
*/
export function LoadWrapper(props) {
    const { isLoading, LoadComponent, LoadComponentProps, size = 25, children } = props;

    if (isLoading) {
        if (LoadComponent && isValidElement(<LoadComponent />)) {
            return <LoadComponent {...LoadComponentProps} />;
        }
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress size={size} />
            </Box>
        );
    }

    return children;
}

LoadWrapper.propTypes = {
    size: PropTypes.number,
    isLoading: PropTypes.bool,
    LoadComponent: PropTypes.elementType,
    LoadComponentProps: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
