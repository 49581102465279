import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

import { ChevronRightIcon, MatchIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { CircularProgress } from '@mui/material';

const Button = styled(ButtonBase)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
    background: theme.palette.common.matchInputGradient,
    padding: theme.spacing(1, 0, 1, 1),
    height: theme.spacing(5),
    textTransform: 'none',
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.32), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    transition: theme.transitions.create(['box-shadow', 'color'], {
        duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
        boxShadow: '0px 0px 1px rgba(63, 83, 100, 0.16), 0px 1px 4px 1px rgba(0, 0, 0, 0.07)',
    },
    '&.Mui-disabled': {
        color: theme.palette.common.white,
        background: theme.palette.common.lightGray,
    },
}));

const ProgressContainer = styled('div')(({ theme }) => ({
    width: theme.spacing(5.5),
    height: theme.spacing(4.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export function LookupButton(props) {
    const { onClick, Icon = MatchIcon, label, isLoading, ...restProps } = props;

    return (
        <Button
            onClick={onClick}
            disableRipple
            data-trackid="lookup-modal-lookup-contact-info"
            {...restProps}
        >
            <Icon fontSize="36px" sx={{ pr: 1 }} />
            <Typography variant="titleSmall" sx={{ mt: -0.25, mr: 1 }}>
                {label}
            </Typography>
            {!isLoading && <ChevronRightIcon fontSize="large" />}
            {isLoading && (
                <ProgressContainer>
                    <CircularProgress size={16} sx={{ color: 'white' }} />
                </ProgressContainer>
            )}
        </Button>
    );
}

LookupButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    isLoading: PropTypes.bool,
    Icon: PropTypes.elementType,
};
