export const LOCAL_STORAGE_KEY = 'previous_saved-search';

export function usePreviousSavedSearch() {
    const previousSavedSearch = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEY));

    const setPreviousSavedSearch = (savedSearch) => {
        sessionStorage.removeItem(LOCAL_STORAGE_KEY);
        if (savedSearch) {
            sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(savedSearch));
        }
    };

    return [previousSavedSearch, setPreviousSavedSearch];
}
