import { MESSAGE_TYPES } from 'utils/constants';

const getSelectedChannels = (messageChannels) => {
    const selectedChannels = [];
    if (messageChannels.email) {
        selectedChannels.push(MESSAGE_TYPES.EMAIL);
    }
    if (messageChannels.sms) {
        selectedChannels.push(MESSAGE_TYPES.SMS);
    }
    return selectedChannels;
};

function removeSignatureInMessageBody(messageBody, signature) {
    if (!signature) return messageBody;
    const pattern = new RegExp(`(.*?)${signature}(?=[^${signature}]*$)`, 'g');
    return messageBody.replace(pattern, `$1${''}`);
}

function remove(text, pattern) {
    return text?.replace(pattern, '');
}

function getTemplateFromRmm({ content, rmmSource }) {
    let bodyMatchString = '';
    ['Body:', 'Email Body:', 'Email body:'].forEach((e) => {
        if (content.includes(e)) {
            bodyMatchString = e;
        }
    });
    const arr = content.split(bodyMatchString);
    let subject = remove(arr[0], 'Subject: ')?.trim();
    let body = remove(arr[1], `${bodyMatchString} `);
    if (rmmSource === 'TINA') {
        body = remove(body, '\n\nWarm regards,\n{{SendersName}}');
    }
    body = remove(body, '\n###')?.trim();
    return { body, subject };
}

export { getSelectedChannels, removeSignatureInMessageBody, remove, getTemplateFromRmm };
