import { useTalentData } from './useTalentData';
import { useGetTalentConversationsQuery } from 'services/conversations';

export function useTalentConversations() {
    const { selectedProfiles } = useTalentData();
    const talentIds = selectedProfiles?.map((profile) => profile.talentId);

    const { data, isFetching, isSuccess, isError } = useGetTalentConversationsQuery({
        page: 1,
        pageSize: selectedProfiles?.length || 10,
        conversationsMessage: 1,
        talentIds,
    });

    return {
        conversations: data?.results,
        isFetching,
        isSuccess,
        isError,
    };
}
