import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const compensationApi = createApi({
    reducerPath: 'compensationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/compensation/v1',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    endpoints: (builder) => ({
        extractSkills: builder.query({
            query: (matchText) => ({
                url: '/skills/extract',
                method: 'POST',
                body: { matchText },
            }),
        }),
        getTalentCompensation: builder.query({
            query: (params) => ({
                url: 'talent/',
                params,
            }),
        }),
    }),
});

export const { useGetTalentCompensationQuery, useLazyExtractSkillsQuery } = compensationApi;
