import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTalentData } from './useTalentData';

import { MESSAGE_TYPES, SEND_TYPE } from 'utils/constants';
import { useMessageDrawer } from './useMessageDrawer';
import { generateTimestamp } from '../utils/helpers';
import { useDynamicFields } from 'features/DynamicFields';
import { useMessageData } from './useMessageData';
import { CONTACT_LABEL_ENUMS } from '../utils';
import { isEmpty } from 'lodash';

export function useMessageQueryBuilder(props) {
    const { shortlistId, conversationId, messageId } = props || {};
    const { messageType } = useMessageDrawer();

    const { body, subject, selectedAttachments, scheduledDate, scheduledTime } = useMessageData({
        shouldProcessDynamicFields: true,
    });

    const { selectedContacts, selectedProfiles } = useTalentData();

    const { defaultDynamicFields } = useDynamicFields();

    const user = useSelector((state) => state.user);
    const isFrcEnabled = useSelector((state) => state.communications.isFrcEnabled);

    let sendType = SEND_TYPE.TRAVERSAL;
    if (!isFrcEnabled) sendType = SEND_TYPE.DIRECT;

    const resolveContacts = useCallback(
        (contacts) => {
            const contactType =
                messageType === MESSAGE_TYPES.EMAIL
                    ? CONTACT_LABEL_ENUMS.EMAIL
                    : CONTACT_LABEL_ENUMS.SMS;
            const contactMedium = messageType === MESSAGE_TYPES.EMAIL ? 'email' : 'phone';
            return {
                [contactMedium]:
                    contacts?.[contactType].data
                        .filter((contact) => contact.selected)
                        .map((contact) => contact[contactMedium]) || [],
            };
        },
        [messageType]
    );

    const createSendArgs = () => {
        const to = [];
        const contacts = {};
        selectedProfiles?.forEach((profile) => {
            to.push(profile?.talentId);
            if (!isEmpty(selectedContacts)) {
                contacts[profile.talentId] = resolveContacts(selectedContacts[profile.talentId]);
            }
        });

        const query = {
            from: user.id,
            subject: subject.innerText,
            customFields: contacts,
            candidateList: shortlistId,
            message: messageType === MESSAGE_TYPES.SMS ? body.innerText : body.innerHtml,
            media: selectedAttachments.map(({ mediaId }) => mediaId),
            to,
            sendType,
            messageType,
            conversationId,
            defaultDynamicFields,
        };

        if (scheduledDate && scheduledTime) {
            query.sendDate = generateTimestamp(scheduledDate, scheduledTime);
        }

        return query;
    };

    const createUpdateArgs = () => {
        const query = {
            body: {
                body: messageType === MESSAGE_TYPES.SMS ? body.innerText : body.innerHtml,
                subject: subject.innerText,
                media: selectedAttachments.map(({ mediaId }) => mediaId),
                messageType,
                defaultDynamicFields,
                sendType,
            },
            messageId,
            messageType,
            conversationId,
        };

        if (scheduledDate && scheduledTime) {
            query.sendDate = generateTimestamp(scheduledDate, scheduledTime);
        }

        return query;
    };

    return {
        createSendArgs,
        createUpdateArgs,
    };
}
