import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { MessageCompose } from './components';
import { MESSAGE_TYPES } from 'utils/constants';

export function Reply(props) {
    const { profiles, subject, messageType: initialMessageType, refreshMessages } = props;

    const [currentMessageType, setCurrentMessageType] = useState(MESSAGE_TYPES.EMAIL);

    const handleChangeMessageType = (value) => {
        setCurrentMessageType(value);
    };

    useEffect(() => {
        if (initialMessageType) {
            setCurrentMessageType(initialMessageType);
        }
    }, [initialMessageType]);

    return (
        <Box sx={{ mt: 'auto', mb: 1, width: '100%' }}>
            <MessageCompose
                variant="reply"
                messageType={currentMessageType}
                profiles={profiles}
                onChangeMessageType={handleChangeMessageType}
                subject={subject}
                refreshMessages={refreshMessages}
            />
        </Box>
    );
}

Reply.propTypes = {
    subject: PropTypes.string,
    messageType: PropTypes.string.isRequired,
    profiles: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            talentId: PropTypes.string,
            imageUrl: PropTypes.string,
        })
    ),
    refreshMessages: PropTypes.func,
};
