import SvgIcon from '@mui/material/SvgIcon';

export function OrganizationIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 25" {...props}>
            <path d="M19.2312 3.12444H0.768776L0 0H20L19.2312 3.12444Z" fill="currentColor" />
            <path
                d="M1.53755 4.68741V25H8.46097V20.3126H11.5376V25H18.461L18.4624 4.68741H1.53755ZM6.92342 18.7496H3.84683V15.6252H6.92342V18.7496ZM6.92342 14.0622H3.84683V10.9378H6.92342V14.0622ZM6.92342 9.37481H3.84683V6.25037H6.92342V9.37481ZM11.539 18.7496H8.46245V15.6252H11.539V18.7496ZM11.539 14.0622H8.46245V10.9378H11.539V14.0622ZM11.539 9.37481H8.46245V6.25037H11.539V9.37481ZM16.1546 18.7496H13.0781V15.6252H16.1546V18.7496ZM16.1546 14.0622H13.0781V10.9378H16.1546V14.0622ZM16.1546 9.37481H13.0781V6.25037H16.1546V9.37481Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
