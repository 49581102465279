import SvgIcon from '@mui/material/SvgIcon';

export function MediaAudioIcon(props) {
    return (
        <SvgIcon viewBox="0 0 40 40" {...props}>
            <path
                d="M34.3737 24.7984H34.4643V8.32219C34.4643 6.99902 33.8662 5.76648 32.8511 4.95083C31.818 4.11705 30.4767 3.82704 29.2079 4.09893L17.9337 6.60026C15.9762 7.03527 14.5443 8.81158 14.5443 10.8235V23.9465C13.7105 23.5477 12.8042 23.3121 11.8254 23.3121C8.32717 23.3121 5.48145 26.1578 5.48145 29.656C5.48145 33.1543 8.32717 36 11.8254 36C15.3237 36 17.8975 33.3899 18.115 30.091H18.1694V15.6993L30.8573 12.8717V20.3032C30.0417 19.9226 29.1354 19.687 28.1747 19.687C24.6765 19.687 21.8307 22.5327 21.8307 26.0309C21.8307 29.5292 24.6765 32.3749 28.1747 32.3749C31.673 32.3749 34.5187 29.5292 34.5187 26.0309C34.5187 25.614 34.4643 25.1971 34.3918 24.7984H34.3737ZM11.8073 32.3749C10.3029 32.3749 9.08844 31.1605 9.08844 29.656C9.08844 28.1516 10.3029 26.9372 11.8073 26.9372C13.3117 26.9372 14.5261 28.1516 14.5261 29.656C14.5261 31.1605 13.3117 32.3749 11.8073 32.3749ZM18.1512 11.9836V10.8235C18.1512 10.4973 18.3869 10.2073 18.695 10.1348L29.9691 7.63342C30.2592 7.56091 30.4585 7.6878 30.5673 7.77842C30.676 7.86905 30.8392 8.03218 30.8392 8.32219V9.15597L18.1512 11.9836ZM28.1566 28.7498C26.6522 28.7498 25.4377 27.5353 25.4377 26.0309C25.4377 24.5265 26.6522 23.3121 28.1566 23.3121C29.661 23.3121 30.8754 24.5265 30.8754 26.0309C30.8754 27.5353 29.661 28.7498 28.1566 28.7498Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
