import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogButton,
    DialogButtonSecondary,
} from 'components/Dialog';
import { Typography } from 'components/Typography';

export function NylasConnectConfirmDialog(props) {
    const { open, onClose, redirectUrl } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-label="leaving-nebula-dialog"
            aria-describedby="nylas-confirm-dialog-description"
        >
            <DialogContent sx={{ pb: 2, width: 400 }}>
                <DialogContentText
                    sx={{
                        textAlign: 'center',
                        mb: 1,
                    }}
                    id="nylas-confirm-dialog-description"
                >
                    You are about to be taken to a third-party authentication page where you will be
                    asked to give Nebula permissions to access your email.
                </DialogContentText>
                <Typography
                    variant="body"
                    component="p"
                    sx={{
                        px: 0,
                        mb: 1,
                        textAlign: 'center',
                    }}
                >
                    Note: Be sure to use the same email account you used to sign up for Nebula.
                </Typography>
            </DialogContent>
            <DialogActions sx={{ flexWrap: 'wrap', px: 8 }}>
                <DialogButtonSecondary onClick={onClose} data-trackid="avatar-menu-connect-cancel">
                    <Typography variant="titleSmall">Cancel</Typography>
                </DialogButtonSecondary>
                <DialogButton
                    aria-label="confirm-leaving-nebula"
                    data-trackid="avatar-menu-connect-continue"
                    sx={{ color: 'common.white', ml: 1 }}
                    autoFocus
                    component={Link}
                    href={redirectUrl}
                    disableRipple
                >
                    <Typography variant="titleSmall">Continue</Typography>
                </DialogButton>
            </DialogActions>
        </Dialog>
    );
}

NylasConnectConfirmDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    redirectUrl: PropTypes.string,
};
