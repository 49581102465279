import SvgIcon from '@mui/material/SvgIcon';

export function CheckIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M18.71 7.20986C18.617 7.11613 18.5064 7.04174 18.3846 6.99097C18.2627 6.9402 18.132 6.91406 18 6.91406C17.868 6.91406 17.7373 6.9402 17.6154 6.99097C17.4936 7.04174 17.383 7.11613 17.29 7.20986L9.84001 14.6699L6.71001 11.5299C6.61349 11.4366 6.49955 11.3633 6.37469 11.3141C6.24984 11.2649 6.11651 11.2408 5.98233 11.2431C5.84815 11.2454 5.71574 11.2741 5.59266 11.3276C5.46959 11.3811 5.35825 11.4583 5.26501 11.5549C5.17177 11.6514 5.09846 11.7653 5.04925 11.8902C5.00005 12.015 4.97592 12.1484 4.97824 12.2825C4.98056 12.4167 5.00929 12.5491 5.06278 12.6722C5.11628 12.7953 5.19349 12.9066 5.29001 12.9999L9.13001 16.8399C9.22297 16.9336 9.33358 17.008 9.45543 17.0588C9.57729 17.1095 9.708 17.1357 9.84001 17.1357C9.97202 17.1357 10.1027 17.1095 10.2246 17.0588C10.3464 17.008 10.457 16.9336 10.55 16.8399L18.71 8.67986C18.8115 8.58622 18.8925 8.47257 18.9479 8.34607C19.0033 8.21957 19.0319 8.08296 19.0319 7.94486C19.0319 7.80676 19.0033 7.67015 18.9479 7.54365C18.8925 7.41715 18.8115 7.3035 18.71 7.20986Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
