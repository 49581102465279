import PropTypes from 'prop-types';
import { FileEntityRelationship } from './components/FileEntityRelationship';

export function EntityConnections({
    entityConnections,
    actor,
    talentName,
    ignoredFields = [],
    metadata,
}) {
    return (
        <>
            {entityConnections?.map?.((entity) => {
                if (ignoredFields.includes(entity.type)) {
                    return null;
                }
                return (
                    <FileEntityRelationship
                        key={`${entity.type}-${entity.entityId}`}
                        type={entity.type}
                        entityId={entity.entityId}
                        data={entity?.data}
                        userId={actor?.id}
                        actorType={actor?.type}
                        talentName={talentName}
                        isReadOnly={true}
                        metadata={metadata}
                    />
                );
            })}
        </>
    );
}

EntityConnections.propTypes = {
    entityConnections: PropTypes.array,
    actor: PropTypes.object,
    talentName: PropTypes.string,
    ignoredFields: PropTypes.array,
    metadata: PropTypes.shape,
};
