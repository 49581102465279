import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { styled, alpha } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLayoutEffect, useRef, useState } from 'react';

import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';
import { LikeIcon, InfoIcon, HighlightIcon, CloseIcon } from 'components/Icons';
import {
    removeSearchEmphasis,
    removeProfileHighlight,
    removeAllProfileHighlights,
    removeAllUnappliedSearchEmphasis,
} from 'views/Search/searchSlice';

const tooltipCopy =
    'You can improve your results by highlighting relevant profile text or emphasizing critical profile description text. Review your selections here before applying them to your search.';

function selectUnappliedSearchEmphasis(state) {
    const returnValue = [];
    const { matchText, searchEmphasis } = state.search;
    searchEmphasis.forEach((emphasis) => {
        const { unapplied, startIndex, endIndex } = emphasis;
        if (unapplied) {
            returnValue.push({
                ...emphasis,
                value: matchText.slice(startIndex, endIndex),
            });
        }
    });
    return returnValue;
}

function selectUnappliedProfileHighlights(state) {
    return state.search.unappliedProfileHighlights;
}

const ChipContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(0.75),
}));

const ChipWrapper = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'cemter',
    border: `1px solid ${alpha(theme.palette.common.white, 0.48)}`,
    borderRadius: theme.shape.borderRadius / 2,
    padding: theme.spacing(0.25, 0.75),
    maxWidth: '100%',
    color: alpha(theme.palette.common.white, 0.72),
    '& .MuiTypography-root': {
        marginTop: '-2px',
    },
    '& .MuiSvgIcon-root:not(.emphasis-icon)': {
        cursor: 'pointer',
        marginLeft: 4,
        marginRight: -2,
        fontSize: 14,
        transition: theme.transitions.create(['color'], {
            duration: theme.transitions.duration.standard,
        }),
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    '& .MuiSvgIcon-root.emphasis-icon': {
        marginRight: 2,
        fontSize: 14,
        color: theme.palette.common.white,
    },
}));

function Chip(props) {
    const { value, isEmphasized, onClick } = props;
    const labelRef = useRef({ scrollWidth: 0, clientWidth: 0 });
    const [isOverflowed, setIsOverflowed] = useState(false);

    useLayoutEffect(() => {
        setIsOverflowed(labelRef.current.scrollWidth > labelRef.current.clientWidth);
    }, []);

    return (
        <ChipWrapper>
            {isEmphasized && <LikeIcon className="emphasis-icon" />}
            <Tooltip
                title={value}
                placement="right"
                disableFocusListener={!isOverflowed}
                disableHoverListener={!isOverflowed}
                disableTouchListener={!isOverflowed}
            >
                <Typography ref={labelRef} variant="bodySmall" noWrap>
                    {value}
                </Typography>
            </Tooltip>
            <CloseIcon
                role="button"
                aria-label="remove"
                data-trackid="pending-changes-remove"
                onClick={onClick}
            />
        </ChipWrapper>
    );
}

export function PendingHighlights() {
    const dispatch = useDispatch();
    const unappliedSearchEmphasis = useSelector(selectUnappliedSearchEmphasis);
    const unappliedProfileHighlights = useSelector(selectUnappliedProfileHighlights);

    const hasAny =
        Boolean(unappliedSearchEmphasis.length) || Boolean(unappliedProfileHighlights.length);

    const createHandleRemoveProfileHighlight = (highlight) => {
        return () => {
            dispatch(removeProfileHighlight(highlight));
        };
    };

    const createHandleRemoveSearchEmphasis = (emphasis) => {
        return () => {
            dispatch(removeSearchEmphasis(emphasis));
        };
    };

    const handleRemoveAllUnappliedProfileHighlights = () => {
        dispatch(removeAllProfileHighlights());
    };

    const handleRemoveAllUnappliedSearchEmphasis = () => {
        dispatch(removeAllUnappliedSearchEmphasis());
    };

    return (
        <Box>
            <Box sx={{ opacity: hasAny ? 1 : 0.42 }}>
                <Typography variant="labelLarge" color="common.white">
                    Profile Description Changes
                </Typography>
                <Tooltip title={tooltipCopy} placement="top-start" sx={{ maxWidth: 252 }}>
                    <span>
                        <InfoIcon sx={{ color: 'common.white', opacity: 0.72, mb: -0.75 }} />
                    </span>
                </Tooltip>
            </Box>

            {Boolean(unappliedSearchEmphasis.length) && (
                <Box sx={{ pt: 2 }}>
                    <Box sx={{ color: 'common.white', display: 'flex', pb: 1 }}>
                        <LikeIcon sx={{ fontSize: '12px', pr: 0.5, pt: 0.25 }} />
                        <Typography variant="bodySmall">Emphasized Text</Typography>
                        <ButtonBase
                            sx={{ ml: 'auto' }}
                            aria-label="clear-all-unapplied-search-emphasis"
                            data-trackid="profile-description-changes-clear-all-emphasis"
                            onClick={handleRemoveAllUnappliedSearchEmphasis}
                        >
                            <Typography variant="bodySmall">Clear All</Typography>
                        </ButtonBase>
                    </Box>
                    <ChipContainer>
                        {unappliedSearchEmphasis.map((emphasis, idx) => (
                            <Chip
                                key={`unapplied-search-emphasis-${idx}`}
                                value={emphasis.value}
                                onClick={createHandleRemoveSearchEmphasis(emphasis)}
                            />
                        ))}
                    </ChipContainer>
                </Box>
            )}

            {Boolean(unappliedProfileHighlights.length) && (
                <Box sx={{ pt: 2 }}>
                    <Box sx={{ color: 'common.white', display: 'flex', pb: 1 }}>
                        <HighlightIcon sx={{ fontSize: '12px', pr: 0.5, pt: 0.25 }} />
                        <Typography variant="bodySmall">Profile Highlights</Typography>
                        <ButtonBase
                            sx={{ ml: 'auto' }}
                            aria-label="clear-all-unapplied-profile-highlights"
                            data-trackid="profile-description-changes-clear-all-highlights"
                            onClick={handleRemoveAllUnappliedProfileHighlights}
                        >
                            <Typography variant="bodySmall">Clear All</Typography>
                        </ButtonBase>
                    </Box>
                    <ChipContainer>
                        {unappliedProfileHighlights.map((highlight, idx) => (
                            <Chip
                                key={`unapplied-profile-highlight-${idx}`}
                                value={highlight.value}
                                onClick={createHandleRemoveProfileHighlight(highlight)}
                                isEmphasized={highlight.emphasize}
                            />
                        ))}
                    </ChipContainer>
                </Box>
            )}
        </Box>
    );
}

Chip.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    isEmphasized: PropTypes.bool,
};
