import { createSlice } from '@reduxjs/toolkit';

export const initialUserState = {
    id: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    displayName: '',
    enabled: false,
    emailVerified: false,
    settings: {},
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser: (_, action) => {
            return action.payload;
        },
        updateUser: (state, action) => {
            return { ...state, ...action.payload };
        },
        finishForcedFlow: (state) => {
            state.settings.forcedFlow = false;
        },
        clearUser: () => {
            return initialUserState;
        },
    },
});

export const { setUser, updateUser, clearUser, finishForcedFlow } = userSlice.actions;

export const userReducer = userSlice.reducer;
