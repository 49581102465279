import PropType from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { AttachmentIcon } from 'components/Icons';
import { ProfileAttachment } from './ProfileAttachment';
import { ArrowIcon } from 'features/TalentNotes/NotesDrawer/components/SortNotesActions';

import { useNewMediaAttachmentsModal } from 'views/UserSettings/features/YourMedia/components/MediaModals/hooks/useNewMediaAttachmentsModal';
import { EntityConnectionModal } from 'views/UserSettings/features/YourMedia/components/MediaModals/EntityConnectionModal';
import { ENTITY_TYPE_ENUMS } from 'utils/entityEnums';
import { useGetMediaQuery } from 'services/knowledgeBases';
import { useEffect } from 'react';

const ProgressWrapper = styled(Box)({
    display: 'flex',
    height: '100%',
    width: '100%',
    minHeight: '30vh',
    justifyContent: 'center',
    alignItems: 'center',
});

const HeaderText = styled(Typography)({
    fontWeight: '600',
    textTransform: 'uppercase',
    fontSize: '12px',
});

function AddAttachmentButton(props) {
    return (
        <Button
            aria-label="add-attachment-button"
            variant="contained"
            color="primary"
            {...props}
            startIcon={<AttachmentIcon fill="white" />}
        >
            <Typography sx={{ textTransform: 'none' }}>Add Attachments</Typography>
        </Button>
    );
}

function AttachmentsLists(props) {
    const { attachments, onAddAttachmentClick, talentId } = props;

    if (!attachments.length) {
        return (
            <Box
                aria-label="no-attachments-container"
                sx={{
                    mt: 20,
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 3,
                        width: '85%',
                        m: 'auto',
                    }}
                >
                    <AttachmentIcon
                        aria-label="attachment-icon"
                        fill="#3A8DD3"
                        sx={{
                            fontSize: '48px',
                        }}
                    />

                    <Typography sx={{ fontWeight: '200' }}>
                        No Attachments. Private files attached by other users will not be visible.
                    </Typography>
                    <AddAttachmentButton
                        sx={{ width: '85%', m: '25px 0' }}
                        onClick={onAddAttachmentClick}
                    />
                </Box>
            </Box>
        );
    }

    return (
        <Box aria-label="attachments-container">
            <Box sx={{ height: '70vh', overflow: 'auto' }}>
                {attachments.map((attachment) => (
                    <ProfileAttachment
                        key={attachment.id}
                        attachment={attachment}
                        talentId={talentId}
                    />
                ))}
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 5,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                }}
            >
                <AddAttachmentButton
                    sx={{ width: '85%', m: '25px 0' }}
                    onClick={onAddAttachmentClick}
                />
            </Box>
        </Box>
    );
}

export function ProfileAttachments(props) {
    const {
        talentId,
        sortOrder,
        onSortByUploadedButtonClicked,
        attachments: prefetchedAttachments,
        isLoading: prefetchedAttachmentsLoading,
        shouldFetchMedia,
        onAttachmentsLoaded,
    } = props;

    const { data: media, isLoading: isCurrentAttachmentLoading } = useGetMediaQuery(
        {
            talentId,
            sortOrder,
        },
        {
            skip: !talentId || !shouldFetchMedia,
        }
    );

    const attachments = shouldFetchMedia ? media?.results || [] : prefetchedAttachments;

    useEffect(() => {
        onAttachmentsLoaded?.(media || {});
    }, [media, onAttachmentsLoaded]);

    const isLoading = prefetchedAttachmentsLoading || isCurrentAttachmentLoading;

    const {
        isModalOpen,
        handleOpenPicker,
        attachments: newAttachments,
        clearAttachments,
        handleRemoveAttachment,
        handleOpen,
        handleClose,
        setAttachments,
    } = useNewMediaAttachmentsModal();

    return (
        <Box
            aria-label="profile-attachments"
            sx={(theme) => ({
                marginBottom: theme.spacing(3),
                overflow: 'auto',
            })}
        >
            <Box
                sx={{
                    display: 'flex',
                    p: '5px',
                    pl: 4,
                    borderBottom: '1px solid #eee',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ width: '42%' }}>
                    <HeaderText>MEDIA NAME</HeaderText>
                </Box>
                <Box sx={{ width: '38%' }}>
                    <HeaderText> TAGS </HeaderText>
                </Box>
                <Box
                    sx={{
                        width: '20%',
                        display: 'flex',
                        gap: 0.5,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <HeaderText>UPLOADED</HeaderText>
                    <IconButton
                        onClick={() =>
                            onSortByUploadedButtonClicked(sortOrder === 'asc' ? 'desc' : 'asc')
                        }
                    >
                        <ArrowIcon isAsc={sortOrder === 'asc'} />
                    </IconButton>
                </Box>
            </Box>

            <Box>
                {isLoading || !attachments ? (
                    <ProgressWrapper aria-label="attachment-loading-progress">
                        <CircularProgress size={50} />
                    </ProgressWrapper>
                ) : (
                    <Box>
                        <AttachmentsLists
                            attachments={attachments}
                            onAddAttachmentClick={handleOpen}
                            talentId={talentId}
                        />
                    </Box>
                )}
            </Box>
            <EntityConnectionModal
                entityId={talentId}
                entityType={ENTITY_TYPE_ENUMS.TALENT.label}
                isOpen={isModalOpen}
                onClose={handleClose}
                onUploadNewMedia={() => {
                    handleClose();
                    handleOpenPicker();
                }}
                attachments={newAttachments}
                clearAttachments={clearAttachments}
                onRemoveAttachment={handleRemoveAttachment}
                onReplaceAttachment={() => {
                    handleClose();
                    handleOpenPicker();
                    clearAttachments();
                }}
                setAttachments={setAttachments}
            />
        </Box>
    );
}

AttachmentsLists.propTypes = {
    attachments: PropType.array.isRequired,
    onAddAttachmentClick: PropType.func,
    talentId: PropType.string,
};

ProfileAttachments.propTypes = {
    attachments: PropType.array.isRequired,
    isLoading: PropType.bool.isRequired,
    talentId: PropType.string,
    onSortByUploadedButtonClicked: PropType.func,
    sortOrder: PropType.string,
    shouldFetchMedia: PropType.bool,
    onAttachmentsLoaded: PropType.func,
};
