import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareAuthHeaders } from 'services/utils';

import { contactsApi } from './contacts';

export const conversationsBaseUrl = '/api/conversations/v1';
const aggregatorBaseUrl = '/api/aggregator/v1';
const messagingWorkflowBaseUrl = '/api/messaging-workflow/v1';

export const conversationsApi = createApi({
    reducerPath: 'conversationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: [
        'Conversations',
        'ConversationMessages',
        'Signatures',
        'MessageTemplates',
        'Policies',
    ],
    endpoints: (builder) => ({
        getConversations: builder.query({
            query: (args) => {
                return {
                    url: `${conversationsBaseUrl}/conversations`,
                    method: 'GET',
                    params: args,
                };
            },
            providesTags: [{ type: 'Conversations', id: 'LISTS' }],
        }),
        getTalentConversations: builder.query({
            query: ({ talentIds, ...args }) => {
                return {
                    url: `${aggregatorBaseUrl}/conversations`,
                    method: 'POST',
                    params: args,
                    body: {
                        talentIds,
                    },
                };
            },
            providesTags: [{ type: 'Conversations', id: 'LISTS' }],
        }),
        getConversationMessages: builder.query({
            query: ({
                conversationId,
                messageId,
                matchText,
                sendMethod,
                messageType,
                page,
                pageSize,
                sortBy,
            }) => {
                return {
                    url: `${conversationsBaseUrl}/conversations/${conversationId}/messages`,
                    method: 'GET',
                    params: {
                        matchText,
                        sendMethod,
                        messageType,
                        page,
                        sortBy,
                        pageSize,
                        pageByMessageId: messageId,
                    },
                };
            },
        }),
        getMessagesDrilldown: builder.query({
            query: ({ params }) => {
                return {
                    url: `${aggregatorBaseUrl}/talent/message-drilldown`,
                    method: 'GET',
                    params,
                };
            },
        }),

        sendConversationMessage: builder.mutation({
            query: ({
                messageType,
                conversationId,
                shortlistId,
                message,
                subject,
                to,
                from,
                customFields,
                media,
                defaultDynamicFields,
                sendDate,
                sendType,
            }) => {
                return {
                    url: `${conversationsBaseUrl}/messages/${messageType}`,
                    method: 'POST',
                    body: {
                        conversationId,
                        shortlistId,
                        message,
                        subject,
                        to,
                        from,
                        customFields,
                        sendType,
                        media,
                        defaultDynamicFields,
                        ...(sendDate ? { sendDate } : {}),
                    },
                };
            },
            invalidatesTags: (result, error, { conversationId }) => {
                return [
                    { type: 'ConversationMessages', id: conversationId },
                    { type: 'Conversations', id: 'LISTS' },
                    { type: 'Policies', id: 'LISTS' },
                ];
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    // request was successful so the following will refresh token count
                    dispatch(contactsApi.util.invalidateTags(['Contacts']));
                } catch (error) {
                    // do nothing
                }
            },
        }),
        deleteMessage: builder.mutation({
            query: ({ messageType, messageId }) => {
                return {
                    url: `${conversationsBaseUrl}/messages/${messageType}/${messageId}`,
                    method: 'DELETE',
                };
            },
        }),
        updateMessage: builder.mutation({
            query: ({ messageType, messageId, body }) => {
                return {
                    url: `${conversationsBaseUrl}/messages/${messageType}/${messageId}`,
                    method: 'PUT',
                    body,
                };
            },
        }),
        getAllSignatures: builder.query({
            query: () => ({ url: `${conversationsBaseUrl}/signatures` }),
            providesTags: (data) =>
                data
                    ? [
                          ...data.map(({ id }) => ({ type: 'Signatures', id })),
                          { type: 'Signatures', id: 'LISTS' },
                      ]
                    : [{ type: 'Signatures', id: 'LISTS' }],
        }),
        createEmailSignature: builder.mutation({
            query: (body) => ({
                url: `${conversationsBaseUrl}/signatures`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Signatures', id: 'LISTS' }],
        }),
        updateEmailSignature: builder.mutation({
            query: (signature) => ({
                url: `${conversationsBaseUrl}/signatures/${signature.id}`,
                method: 'PUT',
                body: signature,
            }),
            invalidatesTags: [{ type: 'Signatures', id: 'LISTS' }],
        }),
        deleteEmailSignature: builder.mutation({
            query: (signatureId) => ({
                url: `${conversationsBaseUrl}/signatures/${signatureId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Signatures', id: 'LISTS' }],
        }),
        getNylasAuthenticationUrl: builder.mutation({
            query: ({ userEmail, userId }) => ({
                url: `${conversationsBaseUrl}/messages/nylas-authentication`,
                method: 'POST',
                body: {
                    userEmail,
                    userId,
                },
            }),
        }),
        getOrganizationTokens: builder.mutation({
            query: (body) => ({
                url: `${conversationsBaseUrl}/messages/tokens`,
                method: 'POST',
                body,
            }),
        }),
        updateMessageStatus: builder.mutation({
            query: ({ messageType, messageId, body }) => ({
                url: `${conversationsBaseUrl}/messages/${messageType}/${messageId}/status`,
                method: 'PUT',
                body,
            }),
        }),
        createMessageTemplate: builder.mutation({
            query: ({ body }) => ({
                url: `${conversationsBaseUrl}/templates`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { templateId }) => [
                { type: 'MessageTemplates', id: templateId },
            ],
        }),
        updateMessageTemplate: builder.mutation({
            query: ({ templateId, body }) => ({
                url: `${conversationsBaseUrl}/templates/${templateId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { templateId }) => [
                { type: 'MessageTemplates', id: templateId },
            ],
        }),
        getMessageTemplates: builder.query({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const response = await fetchWithBQ({
                    url: `${conversationsBaseUrl}/templates`,
                    method: 'GET',
                    params: {
                        name: _arg?.name,
                        templateType: _arg?.templateType,
                    },
                });

                if (response.error) {
                    return { error: response.error };
                }

                const templatesWithSignatureId = response?.data?.filter(
                    (template) => template?.signatureId
                );

                const signaturesResult = templatesWithSignatureId.map(({ signatureId }) =>
                    fetchWithBQ({
                        url: `${conversationsBaseUrl}/signatures/${signatureId}`,
                        method: 'GET',
                    })
                );

                let data = null;
                let error = null;

                try {
                    const signatures = await Promise.all(signaturesResult);

                    const transformedTemplates = response?.data?.map((template) => {
                        if (!template?.signatureId) {
                            return template;
                        }
                        const signature = signatures.find(
                            ({ data }) => data?.id === template?.signatureId
                        );
                        return {
                            ...template,
                            signature: signature?.data,
                        };
                    });

                    data = transformedTemplates;
                } catch (err) {
                    error = err;
                }

                return data ? { data } : { error };
            },
            providesTags: (data) =>
                data
                    ? [
                          ...data.map(({ templateId }) => ({
                              type: 'MessageTemplates',
                              id: templateId,
                          })),
                          { type: 'MessageTemplates', id: 'LISTS' },
                      ]
                    : [{ type: 'MessageTemplates', id: 'LISTS' }],
        }),
        deleteMessageTemplate: builder.mutation({
            query: ({ templateId }) => ({
                url: `${conversationsBaseUrl}/templates/${templateId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { templateId }) => [
                { type: 'MessageTemplates', id: templateId },
            ],
        }),
        getDynamicFields: builder.query({
            query: () => ({
                url: `${conversationsBaseUrl}/messages/dynamic-fields`,
                method: 'GET',
            }),
        }),
        getUploadCredentials: builder.query({
            query: () => ({
                url: `${conversationsBaseUrl}/messages/media/upload-credentials`,
                method: 'GET',
            }),
        }),
        getMessageMetrics: builder.query({
            query: (params) => ({
                url: `${conversationsBaseUrl}/messages`,
                method: 'GET',
                params: {
                    ...params,
                    pageSize: 1,
                },
            }),
        }),
        getTopPathMetrics: builder.query({
            query: (params) => ({
                url: `${messagingWorkflowBaseUrl}/sequences/top-engagement-sequences`,
                method: 'GET',
                params: {
                    limit: 5,
                    ...params,
                },
            }),
        }),
        getTopTalkTrackMetrics: builder.query({
            query: (params) => ({
                url: `${messagingWorkflowBaseUrl}/talk-tracks/top-engagement-talk-tracks`,
                method: 'GET',
                params: {
                    limit: 5,
                    ...params,
                },
            }),
        }),
        getChannelPolicy: builder.query({
            query: (body) => ({
                url: `${conversationsBaseUrl}/policies/channel-policy`,
                method: 'POST',
                body,
            }),
            providesTags: () => [{ type: 'Policies', id: 'LISTS' }],
        }),
        getSoftOptOutKeywords: builder.query({
            query: () => ({
                url: `${conversationsBaseUrl}/policies/soft-opt-out-keywords`,
                method: 'GET',
            }),
        }),
        generateMessage: builder.mutation({
            query: ({ context, body }) => ({
                url: `${conversationsBaseUrl}/generate/${context || 'talent'}/stream/begin`,
                method: 'POST',
                body,
            }),
        }),
        streamMessage: builder.query({
            query: ({ params }) => ({
                url: `${conversationsBaseUrl}/generate/stream/begin`,
                method: 'GET',
                params,
            }),
        }),
        completeGenerateMessage: builder.mutation({
            query: ({ params }) => ({
                url: `${conversationsBaseUrl}/generate/stream/complete`,
                method: 'POST',
                params,
            }),
        }),
        cancelGenerateMessage: builder.mutation({
            query: ({ params }) => ({
                url: `${conversationsBaseUrl}/generate/stream/cancel`,
                method: 'POST',
                params,
            }),
        }),
    }),
});

export const {
    useGetConversationsQuery,
    useGetTalentConversationsQuery,
    useLazyGetTalentConversationsQuery,
    useLazyGetConversationsQuery,
    useGetNylasAuthenticationUrlMutation,
    useLazyGetConversationMessagesQuery,
    useSendConversationMessageMutation,
    useDeleteMessageMutation,
    useUpdateMessageMutation,
    useGetAllSignaturesQuery,
    useLazyGetAllSignaturesQuery,
    useCreateEmailSignatureMutation,
    useUpdateEmailSignatureMutation,
    useDeleteEmailSignatureMutation,
    useGetOrganizationTokensMutation,
    useUpdateMessageStatusMutation,
    useCreateMessageTemplateMutation,
    useUpdateMessageTemplateMutation,
    useGetMessageTemplatesQuery,
    useLazyGetMessageTemplatesQuery,
    useGetDynamicFieldsQuery,
    useDeleteMessageTemplateMutation,
    useGetMessageMetricsQuery,
    useGetMessagesDrilldownQuery,
    useGetTopPathMetricsQuery,
    useGetTopTalkTrackMetricsQuery,
    useGetChannelPolicyQuery,
    useLazyGetChannelPolicyQuery,
    useGetSoftOptOutKeywordsQuery,
    useGenerateMessageMutation,
    useLazyStreamMessageQuery,
    useCompleteGenerateMessageMutation,
    useCancelGenerateMessageMutation,
} = conversationsApi;
