import SvgIcon from '@mui/material/SvgIcon';

export function UnlikeIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.31456 6.00224L0.306322 2.09485L1.73608 0.701057L16 14.6061L14.5702 15.9999L12.511 13.9925C12.4185 14.0106 12.3227 14.02 12.2246 14.02H5.75275C5.37132 14.02 5.00551 13.8723 4.7358 13.6094C4.46609 13.3465 4.31456 12.9899 4.31456 12.618V6.00224ZM11.1011 12.618H5.75275V7.40425L11.1011 12.618ZM8.87362 2.57969L8.13907 5.95091L8.50642 6.30902H14.3819V7.71103L13.06 10.7481L14.145 11.8057L15.7194 8.22276C15.7841 8.06153 15.8201 7.89329 15.8201 7.71103V6.30902C15.8201 5.93719 15.6685 5.58058 15.3988 5.31765C15.1291 5.05473 14.7633 4.90702 14.3819 4.90702H9.8444L10.5275 1.70344L10.5491 1.47911C10.5491 1.1917 10.4269 0.932333 10.2327 0.743062L9.47047 0L5.75534 3.62716L6.77964 4.62569L8.87362 2.57969ZM2.87638 5.60802V14.02H0V5.60802H2.87638Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
