import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { ButtonBase } from '@mui/material';
import { ChevronRightIcon } from 'components/Icons';
import { GradientTypography, Typography } from 'components/Typography';

const OverlayWrapper = styled(Box)(({ theme }) => ({
    pointerEvents: 'none',
    position: 'absolute',
    top: theme.spacing(2.25),
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    alignItems: 'top',
    paddingTop: theme.spacing(4),
    justifyContent: 'center',
    background: 'linear-gradient(90deg, rgba(80, 76, 224, 0.08) 0%, rgba(49, 141, 222, 0.08) 100%)',
    zIndex: 0.5,
}));

const Content = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(4),
    width: theme.spacing(43),
    height: theme.spacing(36.25),
    background: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: theme.spacing(-5),
        display: 'block',
        width: theme.spacing(6),
        height: theme.spacing(6),
        background: theme.palette.common.white,
        clipPath: 'polygon(100% 0%, 0% 0%, 0% 100%)',
        zIndex: -1,
    },
}));

const GradientButton = styled(ButtonBase)(({ theme }) => ({
    height: theme.spacing(5),
    padding: theme.spacing(1.5, 1),
    borderRadius: theme.spacing(0.5),
    background: theme.palette.common.matchInputGradient,
    pointerEvents: 'all',
}));

export function FirstTimeRmmOverlay(props) {
    const { hideOverlay, handleHideOverlay } = props;

    if (hideOverlay) {
        return null;
    }

    return (
        <OverlayWrapper data-testid="rmm-overlay">
            <Content>
                <GradientTypography variant="headline" sx={{ fontSize: 28 }} gutterBottom>
                    Tada!
                </GradientTypography>
                <GradientTypography
                    variant="bodyBase"
                    sx={{ fontWeight: 500, textAlign: 'center' }}
                >
                    I completed a draft of your recruitment marking message based on your job
                    description.
                </GradientTypography>
                <br />
                <GradientTypography
                    variant="bodyBase"
                    sx={{ fontWeight: 500, textAlign: 'center', pb: 3 }}
                >
                    Once you're happy with it, click the "Create Template" button below to save it
                    and start using it to engage with candidates!
                </GradientTypography>
                <GradientButton onClick={handleHideOverlay}>
                    <Typography color="white" variant="titleSmall">
                        Awesome, show me!
                    </Typography>
                    <ChevronRightIcon sx={{ color: 'white' }} />
                </GradientButton>
            </Content>
        </OverlayWrapper>
    );
}

FirstTimeRmmOverlay.propTypes = {
    hideOverlay: PropTypes.bool,
    handleHideOverlay: PropTypes.func,
};
