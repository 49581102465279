import { useSelector } from 'react-redux';

export function useShareMediaAccess({ access, owner }) {
    const user = useSelector((state) => state.user);

    const { organization } = user;
    const organizationId = organization?.id;
    const userId = user?.id;

    const orgMediaShareAccess = access?.org_access?.[organizationId];
    const userMediaShareAccess = access?.user_access?.[userId];

    const isOwner = owner?.userId === userId;

    const showShareIcon = !isOwner;

    return {
        isOrganizationAdmin: user?.isOrganizationAdmin,
        orgMediaShareAccess,
        userMediaShareAccess,
        canManageMedia:
            isOwner ||
            user?.isOrganizationAdmin ||
            orgMediaShareAccess?.edit ||
            userMediaShareAccess?.edit ||
            false,
        canEditPermissions:
            isOwner ||
            orgMediaShareAccess?.['edit-permission'] ||
            userMediaShareAccess?.['edit-permission'] ||
            false,
        canDownloadMedia:
            isOwner || orgMediaShareAccess?.view || userMediaShareAccess?.view || true,
        canDeleteMedia:
            isOwner || orgMediaShareAccess?.delete || userMediaShareAccess?.delete || false,
        showShareIcon,
    };
}
