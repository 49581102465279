import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from 'components/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { CompanyLink } from 'features/CompanyLink';
import { useCreateRefsArray } from '../hooks/useCreateRefsArray';
import { TalentExperienceTimeLines } from './TalentExperienceTimelines';
import Collapse from '@mui/material/Collapse';
import { useCallbackRef } from 'hooks/useCallbackRef';

export function TalentExperience(props) {
    const { experience } = props;

    const [contentRef, clientHeight] = useCallbackRef();
    const { refsById } = useCreateRefsArray(experience);

    const collapsedSize = 120;
    const [isExpanded, setIsExpanded] = useState(false);
    const [collapseElementHeight, setCollapseElementHeight] = useState(collapsedSize);
    const [hiddenItemsCount, setHiddenItemsCount] = useState(null);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const getHiddenItemsCount = () => {
        let totalVisibleHeight = 0;
        const outOfView = [];

        experience.forEach((exp, idx) => {
            if (collapsedSize < refsById?.[idx]?.current?.clientHeight + totalVisibleHeight) {
                outOfView.push(exp);
            } else {
                totalVisibleHeight = totalVisibleHeight + refsById?.[idx]?.current?.clientHeight;
            }
        });
        const hidden = outOfView.length;
        return 0 < hidden ? `+ ${hidden} more` : '';
    };

    useEffect(() => {
        const totalItemsHeight = experience
            .map((_, idx) => refsById?.[idx]?.current?.clientHeight)
            .reduce((acc, currVal) => acc + currVal, 0);

        if (collapsedSize < totalItemsHeight) {
            const itemCount = getHiddenItemsCount();
            setHiddenItemsCount(itemCount);
            setCollapseElementHeight(collapsedSize);
        } else {
            setHiddenItemsCount(null);
            setCollapseElementHeight(clientHeight);
        }
    }, [clientHeight]);

    return (
        <Box sx={{ mb: 1 }}>
            <Box sx={{ mb: 0.8 }}>
                <Typography variant="labelSmall" color="textPrimary">
                    EXPERIENCE
                </Typography>
            </Box>
            <Collapse in={isExpanded} collapsedSize={collapseElementHeight}>
                <Box sx={{ maxWidth: '100%' }} ref={contentRef}>
                    {experience.map((exp, idx) => (
                        <Box key={idx} sx={{ display: 'flex', pb: 1 }} ref={refsById?.[idx]}>
                            <Typography sx={{ fontWeight: 'bold', mr: 0.5 }}>
                                {exp.title},
                            </Typography>
                            <Typography sx={{ pb: 0.3 }}>
                                <CompanyLink companyName={exp.company}>
                                    <Typography variant="body">{exp.company}</Typography>
                                </CompanyLink>{' '}
                            </Typography>
                            <TalentExperienceTimeLines timelineItem={exp} />
                        </Box>
                    ))}
                </Box>
            </Collapse>
            {hiddenItemsCount && (
                <Box>
                    <ButtonBase
                        aria-label="talent-experience-show-more"
                        onClick={toggleExpanded}
                        sx={{ ml: 'auto', marginTop: '-15px' }}
                        disableRipple
                    >
                        <Typography color="primary" variant="bodySmallBold">
                            {isExpanded ? 'Show Less' : hiddenItemsCount}
                        </Typography>
                    </ButtonBase>
                </Box>
            )}
        </Box>
    );
}

TalentExperience.propTypes = {
    experience: PropTypes.array,
};
