export const ACTIVITY_TYPE = {
    ENGAGEMENT: 'Engagement',
    SHORTLISTS: 'Shortlists',
    PROJECTS: 'Projects',
    FEEDBACK: 'Feedback',
    PROFILE: 'Profile',
    MEDIA: 'Media',
    INTEGRATIONS: 'Integrations',
};

export const ENGAGEMENT_ACTIVITY_SUBTYPE = {
    INITIAL_EMAIL_MESSAGE_SENT: 'EngagementInitialEmailMessageSent',
    INITIAL_SMS_MESSAGE_SENT: 'EngagementInitialSMSMessageSent',
    ENGAGEMENT_EMAIL_REPLY_SENT: 'EngagementEmailReplySent',
    ENGAGEMENT_SMS_REPLY_SENT: 'EngagementSMSReplySent',
    ENGAGEMENT_MESSAGE_SENT: 'EngagementMessageSent',
    MESSAGE_FAILED: 'EngagementMessageFailed',
    MESSAGE_SCHEDULED: 'EngagementMessageScheduled',
    ENGAGEMENT_EMAIL_REPLY_RECEIVED: 'EngagementEmailReplyReceived',
    ENGAGEMENT_SMS_REPLY_RECEIVED: 'EngagementSMSReplyReceived',
    SMS_OPT_OUT: 'EngagementSMSOptOut',
    ENROLLED_TALENT: 'EngagementEnrolledTalent',
    UNENROLLED_TALENT: 'EngagementUnenrolledTalent',
};

export const PROJECT_ACTIVITY_SUBTYPE = {
    TALENT_ADDED: 'TalentAdded',
    PATH_ADDED: 'PathAdded',
    SEQUENCE_ADDED: 'SequenceAdded',
    TALENT_REMOVED: 'TalentRemoved',
    PATH_REMOVED: 'PathRemoved',
    SEQUENCE_REMOVED: 'SequenceRemoved',
    PROGRESSED: 'ProjectTalentProgressed',
    ARCHIVED: 'ProjectTalentArchived',
    PROJECT_AUTOMATION_TRIGGERED: 'ProjectAutomationTriggered',
};

export const SHORTLIST_ACTIVITY_SUBTYPE = {
    ADDED: 'TalentAdded',
    REMOVED: 'TalentRemoved',
};

export const FEEDBACK_ACTIVITY_SUBTYPE = {
    ADDED: 'FeedbackAdded',
    REMOVED: 'FeedbackRemoved',
    PROVIDED: 'FeedbackProvided',
};

export const MEDIA_ACTIVITY_SUBTYPE = {
    ATTACHED_TO_TALENT: 'MediaAttachedToTalent',
    REMOVED_FROM_TALENT: 'MediaRemovedFromTalent',
};

export const PROFILE_ACTIVITY_SUBTYPE = {
    EDIT: 'Edit',
};

export const INTEGRATION_ACTIVITY_SUBTYPE = {
    PUSH: 'Push',
    PULL: 'Pull',
};
