import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { alpha, styled } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { ChevronUpIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { LocationRadiusSlider } from './LocationRadiusSlider';
import { FILTER_RADII, LOCATION_FILTER, LOCATION_FILTER_UNIT } from './constants';
import { changeStandardFilter, changeStandardExcludeFilter } from 'views/Search/searchSlice';

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'unset',
    width: '70px',
    borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.72)}`,
    margin: theme.spacing(0, 0.5, 0.5, 1.2),
    padding: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    color: alpha(theme.palette.common.white, 0.72),
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '&.Mui-disabled': {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        borderBottom: `1px solid ${theme.palette.text.primary}`,
    },
}));
export function LocationFilterChipDropDown(props) {
    const { location, index, locations } = props;
    const dispatch = useDispatch();

    const [locationUnit, setLocationUnit] = useState(LOCATION_FILTER_UNIT.MI);
    const [locationRadius, setLocationRadius] = useState(location?.radius);
    const [isSliderVisible, setIsSliderVisible] = useState(false);

    const toggleSliderVisibility = () => {
        setIsSliderVisible(!isSliderVisible);
    };
    const handleUpdateLocation = (event, radius) => {
        const action = location.excludeMatch ? changeStandardExcludeFilter : changeStandardFilter;
        const matchText = [...locations];
        matchText[index] = {
            ...locations[index],
            radius: FILTER_RADII[radius],
            exactMatch: radius === 0,
        };

        dispatch(action({ property: LOCATION_FILTER, matchText }));
    };

    const handleUnitChange = (unit) => {
        setLocationUnit(unit);

        dispatch(
            changeStandardFilter({
                property: LOCATION_FILTER,
                matchText: locations.map((loc, i) => {
                    if (i === index) {
                        return {
                            ...loc,
                            unit: unit,
                        };
                    }
                    return loc;
                }),
            })
        );
    };

    if (location?.isState) {
        return null;
    }

    return (
        <>
            <StyledButton
                id="filter-chip-dropDown-button"
                className="hide-on-tooltip"
                aria-label="filter-chip-dropDown-button"
                variant="contained"
                size="small"
                disabled={location.isState}
                disableElevation
                disableRipple
                onClick={toggleSliderVisibility}
                endIcon={
                    <ChevronUpIcon
                        sx={
                            !isSliderVisible
                                ? {
                                      transform: 'rotate(-180deg)',
                                      transition: 'transform 0.1s ease-in-out',
                                  }
                                : null
                        }
                    />
                }
            >
                <Typography variant="bodySmall">
                    {locationRadius === 0 ? 'Exact' : `${locationRadius} ${locationUnit}`}
                </Typography>
            </StyledButton>
            {isSliderVisible && (
                <ClickAwayListener
                    onClickAway={() => {
                        setIsSliderVisible(false);
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '25px',
                            left: 0,
                            bgcolor: 'common.blueGray',
                            height: 70,
                            width: 300,
                            borderRadius: '0 0 4px 4px',
                            zIndex: 'tooltip',
                        }}
                    >
                        <LocationRadiusSlider
                            locationRadius={locationRadius}
                            locationUnit={locationUnit}
                            onChangeCommitted={handleUpdateLocation}
                            setLocationUnit={handleUnitChange}
                            markValueMapping={FILTER_RADII}
                            onChange={setLocationRadius}
                        />
                    </Box>
                </ClickAwayListener>
            )}
        </>
    );
}
LocationFilterChipDropDown.propTypes = {
    location: PropTypes.object,
    index: PropTypes.number,
    locations: PropTypes.array,
};
