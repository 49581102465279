import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { MessageCompose } from './components';
import { selectAllContacts } from '../Contacts';
import { Typography } from 'components/Typography';
import { ContactHeader } from 'views/Communications/components';

const ContentArea = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    margin: theme.spacing(1, 7),
}));

export function Compose() {
    const location = useLocation();
    const { messageType, shortlistId } = location?.state || {};

    const profiles = useSelector(selectAllContacts);

    if (!profiles?.length || !messageType) {
        return <Navigate to="/communications" />;
    }

    return (
        <ContentArea>
            <Box sx={{ height: 120, mb: 1.2, display: 'flex', alignItems: 'center' }}>
                {profiles?.length > 1 && (
                    <Typography
                        variant="smallHeadline"
                        sx={{ mr: 'auto', color: (theme) => theme.palette.text.primary }}
                    >
                        Compose a message
                    </Typography>
                )}
                {profiles?.length === 1 && profiles[0]?.name && (
                    <ContactHeader talentData={profiles[0]} isCompact />
                )}
            </Box>
            <MessageCompose
                messageType={messageType}
                profiles={profiles}
                shortlistId={shortlistId}
            />
        </ContentArea>
    );
}
