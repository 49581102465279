import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import MuiMenuItem from '@mui/material/MenuItem';

import { Typography } from 'components/Typography';

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    width: '100%',
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    '&.Mui-disabled': {
        opacity: 1,
        'svg, .MuiTypography-root': {
            opacity: 0.35,
        },
    },
    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },
}));

export function ActionsMenuItem(props) {
    const { onClick, Icon, label, disabled, IconProps, textStyles, ...rest } = props;
    return (
        <MenuItem onClick={onClick} disabled={disabled} dense {...rest}>
            <Icon sx={{ fontSize: 24, ...IconProps }} />
            <Typography variant="titleSmall" sx={{ ml: 0.5, textTransform: 'none', ...textStyles }}>
                {label}
            </Typography>
        </MenuItem>
    );
}

ActionsMenuItem.propTypes = {
    onClick: PropTypes.func,
    Icon: PropTypes.elementType,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    IconProps: PropTypes.object,
    textStyles: PropTypes.object,
};
