import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    FilterAccordion,
    FilterSummary,
    StyledDivider,
    FilterDetails,
    SummaryChip,
    SummaryActions,
    SummaryAction,
    StyledPaper,
    FilterChipContainer,
} from './styledComponents';
import {
    changeStandardFilter,
    changeStandardExcludeFilter,
    deleteStandardFilter,
    deleteStandardExcludeFilter,
    convertIncludeToExclude,
    convertExcludeToInclude,
    resetMultipleStandardFilters,
} from 'views/Search/searchSlice';
import { FilterLabel } from './FilterLabel';
import { IncludedChip } from './IncludedChip';
import { ExcludedChip } from './ExcludedChip';
import { EDUCATION_FILTER } from './constants';
import { Typography } from 'components/Typography';
import { MultiTextInput } from 'components/Inputs';
import { AutocompleteSx, InputProps } from './props';
import { IncludeExcludeLabel } from './IncludeExcludeLabel';
import { ChevronDownIcon, EducationIcon } from 'components/Icons';

const degreeFilter = EDUCATION_FILTER.DEGREES;
const schoolFilter = EDUCATION_FILTER.SCHOOLS;
const labels = {
    [EDUCATION_FILTER.DEGREES]: 'Filter by Majors/Degree',
    [EDUCATION_FILTER.SCHOOLS]: 'Filter by Past & Current Schools',
};

const selectDegrees = (state) => state.search.filters[EDUCATION_FILTER.DEGREES] || [];
const selectExcludeDegrees = (state) =>
    state.search.exclusionFilters[EDUCATION_FILTER.DEGREES] || [];

const selectSchools = (state) => state.search.filters[EDUCATION_FILTER.SCHOOLS] || [];
const selectExcludeSchools = (state) =>
    state.search.exclusionFilters[EDUCATION_FILTER.SCHOOLS] || [];

export function FilterEducation() {
    const dispatch = useDispatch();

    //Needed to trigger a refresh and update refs, and by extension anchorElements and menu widths
    const [open, setOpen] = useState(false);
    const handleAccordionChange = (_event, expanded) => setOpen(expanded);

    const [excludeMatch, setExcludeMatch] = useState(false);
    const [excludeSchoolMatch, setExcludeSchoolMatch] = useState(false);

    const degrees = useSelector(selectDegrees);
    const excludedDegrees = useSelector(selectExcludeDegrees);
    const schools = useSelector(selectSchools);
    const excludedSchools = useSelector(selectExcludeSchools);

    const handleEducationDegreeChange = useCallback(
        (event) => {
            const action = excludeMatch ? changeStandardExcludeFilter : changeStandardFilter;

            dispatch(
                action({
                    property: degreeFilter,
                    matchText: event.target.value,
                })
            );
        },
        [dispatch, excludeMatch]
    );
    const handleEducationSchoolChange = useCallback(
        (event) => {
            const action = excludeSchoolMatch ? changeStandardExcludeFilter : changeStandardFilter;

            dispatch(
                action({
                    property: schoolFilter,
                    matchText: event.target.value,
                })
            );
        },
        [dispatch, excludeSchoolMatch]
    );

    const handleDeleteEducation = (property, index) => {
        dispatch(deleteStandardFilter({ property, index }));
    };

    const handleDeleteExludedEducation = (property, index) => {
        dispatch(deleteStandardExcludeFilter({ property, index }));
    };

    const handleClearEducation = (event) => {
        event.stopPropagation();
        dispatch(
            resetMultipleStandardFilters([EDUCATION_FILTER.DEGREES, EDUCATION_FILTER.SCHOOLS])
        );
    };

    const handleClickInclude = (property, index) => {
        dispatch(convertExcludeToInclude({ property, index }));
    };

    const handleClickExclude = (property, index) => {
        dispatch(convertIncludeToExclude({ property, index }));
    };

    const handleExcludeMatchChange = (event, excludedEduationType) => {
        excludedEduationType === 'degree'
            ? setExcludeMatch(event.target.checked)
            : setExcludeSchoolMatch(event.target.checked);
    };

    const educationValue = () => {
        if (degreeFilter === EDUCATION_FILTER.DEGREES && excludeMatch) {
            return excludedDegrees;
        } else {
            return degrees;
        }
    };
    const schoolValue = () => {
        if (schoolFilter === EDUCATION_FILTER.SCHOOLS && excludeSchoolMatch) {
            return excludedSchools;
        } else {
            return schools;
        }
    };

    const activeDegreeCount = degrees.length + excludedDegrees.length;
    const activeFiltersSchoolCount = schools.length + excludedSchools.length;
    const activeFiltersCount = activeDegreeCount + activeFiltersSchoolCount;

    return (
        <FilterAccordion
            id="filter-education"
            onChange={handleAccordionChange}
            data-trackid={
                open ? 'education-filters-drawer-collapse' : 'education-filters-drawer-expand'
            }
        >
            <FilterSummary
                expandIcon={<ChevronDownIcon />}
                aria-controls="filter-education-content"
                aria-label="filter-education-header"
            >
                <EducationIcon sx={{ color: alpha('#ffffff', 0.48) }} />
                <Typography
                    variant="labelSmall"
                    sx={{ display: 'flex', alignItems: 'center', ml: 1 }}
                >
                    Education/institutions
                </Typography>
                <SummaryActions>
                    {Boolean(activeFiltersCount) && (
                        <SummaryAction
                            variant="text"
                            onClick={handleClearEducation}
                            aria-label="filter-education-clear"
                            data-trackid="education-filters-drawer-clear"
                        >
                            Clear
                        </SummaryAction>
                    )}
                    <SummaryChip label={activeFiltersCount} />
                </SummaryActions>
            </FilterSummary>

            <FilterDetails id="filter-education-major-and-degree-content">
                <MultiTextInput
                    fullWidth
                    disableTags
                    freeSolo
                    disableClearable
                    value={educationValue()}
                    openOnFocus={false}
                    forcePopupIcon={false}
                    PaperComponent={StyledPaper}
                    onChange={handleEducationDegreeChange}
                    id="filter-education-major-and-degree-multi-input"
                    data-testid="label"
                    label={
                        <FilterLabel
                            label={labels[degreeFilter]}
                            checked={excludeMatch}
                            onChange={(e) => handleExcludeMatchChange(e, 'degree')}
                            dataTrackId="majors-degrees-filter-exclude"
                        />
                    }
                    inputProps={{
                        'data-trackid': 'majors-degrees-filter-input',
                    }}
                    TextInputProps={{
                        sx: AutocompleteSx,
                        InputProps: {
                            ...InputProps,
                        },
                    }}
                />
                {Boolean(degrees.length) && (
                    <>
                        <IncludeExcludeLabel
                            label="Include"
                            filterKey={EDUCATION_FILTER.DEGREES}
                            enableAnyAllSelect
                        />
                        <FilterChipContainer>
                            {degrees.map((degree, index) => (
                                <IncludedChip
                                    key={`${EDUCATION_FILTER.DEGREES}-included-chip-${degree}-${index}`}
                                    label={degree}
                                    index={index}
                                    onClick={handleClickExclude}
                                    onDelete={handleDeleteEducation}
                                    filterKey={EDUCATION_FILTER.DEGREES}
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}

                {Boolean(excludedDegrees.length) && (
                    <>
                        <IncludeExcludeLabel label="Exclude" />
                        <FilterChipContainer>
                            {excludedDegrees.map((degree, index) => (
                                <ExcludedChip
                                    key={`${EDUCATION_FILTER.DEGREES}-excluded-chip-${degree}-${index}`}
                                    label={degree}
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={handleDeleteExludedEducation}
                                    filterKey={EDUCATION_FILTER.DEGREES}
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
            </FilterDetails>

            <Box sx={{ px: 2, pb: 0.75 }}>
                <StyledDivider />
            </Box>

            <FilterDetails id="filter-education-content">
                <MultiTextInput
                    fullWidth
                    disableTags
                    freeSolo
                    disableClearable
                    value={schoolValue()}
                    openOnFocus={false}
                    forcePopupIcon={false}
                    PaperComponent={StyledPaper}
                    onChange={handleEducationSchoolChange}
                    id="filter-education-multi-input"
                    data-testid="label"
                    label={
                        <FilterLabel
                            label={labels[schoolFilter]}
                            checked={excludeSchoolMatch}
                            onChange={(e) => handleExcludeMatchChange(e, 'school')}
                            dataTrackId="schools-filter-exclude"
                        />
                    }
                    inputProps={{
                        'data-trackid': 'schools-filter-input',
                    }}
                    TextInputProps={{
                        sx: AutocompleteSx,
                        InputProps: {
                            ...InputProps,
                        },
                    }}
                />
                {Boolean(schools.length) && (
                    <>
                        <IncludeExcludeLabel
                            label="Include"
                            filterKey={EDUCATION_FILTER.SCHOOLS}
                            enableAnyAllSelect
                        />
                        <FilterChipContainer>
                            {schools.map((school, index) => (
                                <IncludedChip
                                    key={`${EDUCATION_FILTER.SCHOOLS}-included-chip-${school}-${index}`}
                                    label={school}
                                    index={index}
                                    onClick={handleClickExclude}
                                    onDelete={handleDeleteEducation}
                                    filterKey={EDUCATION_FILTER.SCHOOLS}
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}

                {Boolean(excludedSchools.length) && (
                    <>
                        <IncludeExcludeLabel label="Exclude" />
                        <FilterChipContainer>
                            {excludedSchools.map((school, index) => (
                                <ExcludedChip
                                    key={`${EDUCATION_FILTER.SCHOOLS}-excluded-chip-${school}-${index}`}
                                    label={school}
                                    index={index}
                                    onClick={handleClickInclude}
                                    onDelete={handleDeleteExludedEducation}
                                    filterKey={EDUCATION_FILTER.SCHOOLS}
                                />
                            ))}
                        </FilterChipContainer>
                    </>
                )}
            </FilterDetails>
        </FilterAccordion>
    );
}
