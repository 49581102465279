import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

import { MESSAGE_TYPES } from 'utils/constants';
import { getCooldownProps } from 'utils/helpers';
import { Typography } from 'components/Typography';
import { ContactCheckbox } from './ContactCheckbox';
import { ContactDropdown } from './ContactDropdown';
import { selectAllContacts, selectContactTotal, updateContacts } from '../ContactSelectionSlice';
import { isContactDetailsPresent } from '../utils/isContactDetailsPresent';
import { CooldownIcon, IconWithTooltip } from 'components/Icons';
import { cooldownBlue } from 'theme';
import { filterValidOptions } from '../utils/contactProcessHelpers';
import { CONTACT_MEDIUMS } from 'features/TalentCard/utils/constants';
import { toast } from 'react-toastify';

const GridContainer = styled(Box)(({ theme }) => ({
    width: 'inherit',
    height: '70vh',
    margin: 'auto',
    background: theme.palette.background.default,
}));

const NoContactFound = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.6),
    fontStyle: 'italic',
}));

export const contactCheckboxCell = (type, params) => {
    const channel = type === MESSAGE_TYPES.EMAIL ? 'emails' : 'phones';
    const contacts = params.row[channel]?.data;
    const policyViolated = contacts?.[0]?.policyViolated;
    const policyViolationReason = contacts?.[0]?.policyViolationReason;
    if (!isContactDetailsPresent(type, params.row)) return [];
    if (contacts.length === 1 && policyViolated) {
        return [
            <IconWithTooltip
                title={policyViolationReason}
                Icon={CooldownIcon}
                IconProps={{ sx: { color: cooldownBlue }, fontSize: 'small' }}
            />,
        ];
    }
    return [<ContactCheckbox id={params.id} />];
};

export const contactsColumns = ({ type }) =>
    [
        {
            field: 'name',
            headerName: 'Name',
            hideable: false,
            flex: 2,
        },
        {
            field: 'title',
            headerName: 'Current Position',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            flex: 2,
        },
        type === MESSAGE_TYPES.EMAIL && {
            field: 'emails',
            headerName: 'Target Email',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            flex: 2,
            renderCell: (params) => (
                <>
                    {Boolean(params.row.emails?.data?.length) ? (
                        <ContactDropdown
                            id={params.id}
                            contactData={params.row.emails.data}
                            messageType={type}
                        />
                    ) : (
                        <NoContactFound>No contact details found</NoContactFound>
                    )}
                </>
            ),
        },
        type === MESSAGE_TYPES.SMS && {
            field: 'phones',
            headerName: 'Target Phone',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            flex: 2,
            renderCell: (params) => (
                <>
                    {Boolean(params.row.phones?.data?.length) ? (
                        <ContactDropdown
                            id={params.id}
                            contactData={params.row.phones.data}
                            messageType={type}
                        />
                    ) : (
                        <NoContactFound>No contact details found</NoContactFound>
                    )}
                </>
            ),
        },
        {
            field: 'selected',
            headerName: 'Selected',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            type: 'actions',
            flex: 1,
            getActions: (params) => contactCheckboxCell(type, params),
        },
    ].filter(Boolean);

export function ContactsGrid(props) {
    const { isFetching, messageType = MESSAGE_TYPES.EMAIL, policies } = props;

    const contacts = useSelector(selectAllContacts);
    const rowCount = useSelector(selectContactTotal);
    const isFrcEnabled = useSelector((state) => state.communications.isFrcEnabled);
    const dispatch = useDispatch();

    const [talents, setTalents] = useState([]);

    useEffect(() => {
        const accessor = messageType === MESSAGE_TYPES.SMS ? 'phones' : 'emails';
        const stagedContacts = contacts.map((talent) => ({
            ...talent,
            [accessor]: {
                ...talent[accessor],
                data: talent[accessor]?.data?.map((contact) => ({
                    ...contact,
                    ...getCooldownProps(contact, policies?.[talent.talentId], messageType),
                })),
            },
        }));

        setTalents(stagedContacts);
    }, [policies, contacts, messageType]);

    useEffect(() => {
        if (talents.length && isFrcEnabled) {
            const accessor = messageType === MESSAGE_TYPES.SMS ? 'phones' : 'emails';
            const stagedContacts = talents.map((talent) => ({
                id: talent.talentId,
                changes: {
                    [messageType]: talent[accessor]?.data
                        .filter(filterValidOptions)
                        .map((contact) => contact[CONTACT_MEDIUMS[contact.contactMedium].label]),
                    selected: true,
                },
            }));

            dispatch(updateContacts(stagedContacts));
        }
    }, [dispatch, isFrcEnabled, messageType, talents.length]);

    const showToast = useMemo(() => {
        const accessor = messageType === MESSAGE_TYPES.SMS ? 'phones' : 'emails';

        if (!talents?.length) return false;
        return talents.every((talentContacts) => {
            return (
                Boolean(talentContacts[accessor].data?.length) &&
                talentContacts[accessor].data.every((data) => data.isCoolingDown)
            );
        });
    }, [messageType, talents]);

    useEffect(() => {
        if (showToast)
            toast.error(
                'All talents selected have all their contact in cool down state. You cannot confirm selections.'
            );
    }, [showToast]);

    return (
        <GridContainer>
            <DataGrid
                aria-label="contacts-data-grid"
                loading={isFetching}
                rows={talents}
                getRowId={(row) => row.talentId || row.id}
                rowCount={rowCount}
                columns={contactsColumns({ type: messageType })}
                disableColumnSelector={true}
            />
        </GridContainer>
    );
}

ContactsGrid.propTypes = {
    isFetching: PropTypes.bool,
    messageType: PropTypes.string,
    policies: PropTypes.object,
};
