import { createSlice } from '@reduxjs/toolkit';
import { formatLocalDate } from 'utils/formatters';
import { METRIC_DATE_FILTER_OPTIONS } from './CommunicationsMetrics/components/SendMetricDateFilterDropdown';

export const initialCommunicationsState = {
    filters: {
        read: false,
        unread: false,
        talentId: '',
        talentIds: [],
        startDate: {
            value: '',
            label: '',
        },
        endDate: {
            value: '',
            label: '',
        },
        attachments: {
            enabled: false,
            value: true,
        },
        sequences: {
            enabled: false,
            value: 'all',
        },
        digitalAssistant: {
            enabled: false,
            value: 'all',
        },
        messageChannels: {
            DIRECT: true,
            SEQUENCE: true,
            BOT: true,
        },
        messageTypes: {
            SMS: true,
            EMAIL: true,
        },
    },
    metricFilters: {
        startDate: {
            value: '',
            label: '',
        },
        endDate: {
            value: '',
            label: '',
        },
        messageChannels: {
            DIRECT: true,
            SEQUENCE: true,
            BOT: true,
        },
        messageTypes: {
            SMS: true,
            EMAIL: true,
        },
    },
    selectedDateRangeFilter: METRIC_DATE_FILTER_OPTIONS.PAST_30_DAYS.options.value,
    messages: [],
    messagesPage: 1,
    conversationsPage: 0,
    selectedMessageId: '',
    hasMoreMessages: true,
    isMatchingMessages: false,
    shouldScrollToMessage: false,
    shouldRefreshMessages: false,
    shouldRefreshConversations: false,
    isFrcEnabled: false,
    messageMatchText: '',
    conversationSearchText: '',
    conversationTalent: {},
};

export const communicationsSlice = createSlice({
    name: 'communications',
    initialState: initialCommunicationsState,
    reducers: {
        clearCommunicationsState: () => {
            return initialCommunicationsState;
        },
        toggleReadFilter: (state) => {
            state.filters.read = !state.filters.read;
        },
        toggleUnreadFilter: (state) => {
            state.filters.unread = !state.filters.unread;
        },
        changeTalentIdFilter: (state, action) => {
            state.filters.talentId = action.payload;
        },
        clearTalentIdFilter: (state) => {
            state.filters.talentId = '';
        },
        changeTalentIdsFilter: (state, action) => {
            state.filters.talentIds = action.payload;
        },
        clearTalentIdsFilter: (state, action) => {
            state.filters.talentIds = [];
        },
        changeFilterStartDate: (state, { payload }) => {
            state.filters.startDate.label = payload ? formatLocalDate(payload, 'MM/dd/yyyy') : '';
            state.filters.startDate.value = payload;
        },
        changeFilterEndDate: (state, { payload }) => {
            state.filters.endDate.label = payload ? formatLocalDate(payload, 'MM/dd/yyyy') : '';
            state.filters.endDate.value = payload;
        },
        clearFilters: (state) => {
            state.filters = initialCommunicationsState.filters;
        },
        changeMessageMatchText: (state, action) => {
            state.messageMatchText = action.payload;
        },
        setMessageSearchingStatus: (state, action) => {
            state.isMatchingMessages = action.payload;
        },
        resetMessageMatchText: (state) => {
            state.messageMatchText = '';
            state.isMatchingMessages = false;
            state.messagesPage = 1;
        },
        changeConversationSearchText: (state, action) => {
            state.conversationSearchText = action.payload;
        },
        clearConversationSearchText: (state) => {
            state.conversationSearchText = initialCommunicationsState.conversationSearchText;
        },
        appendMessages: (state, action) => {
            state.messages = state.messages.concat(action.payload);
        },
        prependMessages: (state, { payload }) => {
            if (payload?.length >= 1) {
                state.messages.unshift(...payload);
            }
        },
        replaceMessages: (state, action) => {
            state.messages = action.payload;
        },
        setFindTheRightContact: (state, { payload }) => {
            state.isFrcEnabled = payload;
        },
        incrementMessagePage: (state) => {
            state.messagesPage++;
        },
        decrementMessagePage: (state) => {
            state.messagesPage--;
        },
        setMessagePage: (state, actions) => {
            state.messagesPage = actions.payload;
        },
        resetMessagePage: (state) => {
            state.messagesPage = 1;
        },
        setHasMoreMessages: (state, actions) => {
            state.hasMoreMessages = actions.payload;
        },
        resetMessageState: (state) => {
            state.messages = [];
            state.messagesPage = 1;
            state.hasMoreMessages = true;
            state.isMatchingMessages = false;
            state.messageMatchText = '';
        },
        scrollToMessage: (state, actions) => {
            const { shouldScrollToMessage, messageId } = actions.payload;
            state.shouldScrollToMessage = shouldScrollToMessage;
            state.selectedMessageId = messageId;
        },
        setConversationTalent: (state, { payload }) => {
            state.conversationTalent = payload;
        },
        setShouldRefreshMessages: (state, { payload }) => {
            state.shouldRefreshMessages = payload;
        },
        setShouldRefreshConversations: (state, { payload: { value, page } }) => {
            state.shouldRefreshConversations = Boolean(value);
            state.conversationsPage = page || initialCommunicationsState.conversationsPage;
        },
        setAttachmentFilter: (state, { payload }) => {
            state.filters.attachments.value = payload;
        },
        setEnableAttachmentFilter: (state, { payload }) => {
            state.filters.attachments.enabled = payload;
        },
        setSequencesFilter: (state, { payload }) => {
            state.filters.sequences.value = payload;
        },
        setEnableSequencesFilter: (state, { payload }) => {
            state.filters.sequences.enabled = payload;
        },
        setDigitalAssistantFilter: (state, { payload }) => {
            state.filters.digitalAssistant.value = payload;
        },
        setEnableDigitalAssistantFilter: (state, { payload }) => {
            state.filters.digitalAssistant.enabled = payload;
        },
        setToggleMessageChannel: (state, { payload }) => {
            state.filters.messageChannels[payload] = !state.filters.messageChannels[payload];
        },
        setToggleMessageType: (state, { payload }) => {
            state.filters.messageTypes[payload] = !state.filters.messageTypes[payload];
        },
        resetMessageChannelsAndTypes: (state) => {
            state.filters.messageChannels = initialCommunicationsState.filters.messageChannels;
            state.filters.messageTypes = initialCommunicationsState.filters.messageTypes;
        },
        selectMetricDateRange: (state, { payload }) => {
            state.selectedDateRangeFilter = payload[0].value;
        },
        setMetricFilterDateRange: (state, { payload }) => {
            state.metricFilters.startDate.label = payload.startDate
                ? formatLocalDate(payload, 'MM/dd/yyyy')
                : '';
            state.metricFilters.startDate.value = payload.startDate;
            state.metricFilters.endDate.label = payload.endDate
                ? formatLocalDate(payload, 'MM/dd/yyyy')
                : '';
            state.metricFilters.endDate.value = payload.endDate;
        },
        resetMetricDateRangeFilters: (state) => {
            state.selectedDateRangeFilter = initialCommunicationsState.selectedDateRangeFilter;
            state.metricFilters.startDate = initialCommunicationsState.metricFilters.startDate;
            state.metricFilters.endDate = initialCommunicationsState.metricFilters.endDate;
        },
        setToggleMetricMessageChannel: (state, { payload }) => {
            state.metricFilters.messageChannels[payload] =
                !state.metricFilters.messageChannels[payload];
        },
        setToggleMetricMessageType: (state, { payload }) => {
            state.metricFilters.messageTypes[payload] = !state.metricFilters.messageTypes[payload];
        },
        resetMetricMessageChannelAndTypes: (state) => {
            state.metricFilters.messageChannels =
                initialCommunicationsState.metricFilters.messageChannels;
            state.metricFilters.messageTypes =
                initialCommunicationsState.metricFilters.messageTypes;
        },
    },
});

export const {
    clearFilters,
    clearCommunicationsState,
    clearTalentIdFilter,
    changeTalentIdFilter,
    clearTalentIdsFilter,
    changeTalentIdsFilter,
    toggleReadFilter,
    toggleUnreadFilter,
    changeFilterStartDate,
    changeFilterEndDate,
    changeMessageMatchText,
    setMessageSearchingStatus,
    resetMessageMatchText,
    changeConversationSearchText,
    clearConversationSearchText,
    appendMessages,
    prependMessages,
    replaceMessages,
    incrementMessagePage,
    decrementMessagePage,
    setMessagePage,
    resetMessagePage,
    setHasMoreMessages,
    resetMessageState,
    scrollToMessage,
    setConversationTalent,
    setFindTheRightContact,
    setShouldRefreshMessages,
    setShouldRefreshConversations,
    setAttachmentFilter,
    setEnableAttachmentFilter,
    setSequencesFilter,
    setEnableSequencesFilter,
    setDigitalAssistantFilter,
    setEnableDigitalAssistantFilter,
    setToggleMessageChannel,
    setToggleMessageType,
    resetMessageChannelsAndTypes,
    selectMetricDateRange,
    setMetricFilterDateRange,
    resetMetricDateRangeFilters,
    setToggleMetricMessageChannel,
    setToggleMetricMessageType,
    resetMetricMessageChannelAndTypes,
} = communicationsSlice.actions;

export const communicationsReducer = communicationsSlice.reducer;
