import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import { UserSuggestionItem } from './UserSuggestionItem';
import { convertEditorStateToApiFormat } from 'utils/mentionEditorUtils/convertEditorStateToApiFormat';
import Box from '@mui/material/Box';
import { getValueByMode } from 'utils/copy';

export function MentionEditor({
    editorState: _editorState,
    suggestions: _suggestions,
    onChange,
    onSearchChange: _onSearchChange,
    handleSubmit,
    onAddMention,
    mentionEditorContainerRef,
    getPopoverOpenState,
    ...props
}) {
    const editorRef = useRef(null);
    const [editorState, setEditorState] = useState(_editorState);
    const [open, setOpen] = useState(false);
    const suggestionsWithFallback = _suggestions || [];

    useEffect(() => {
        // note: this is a hack to close the popover when the user hits escape.
        // The escape event is being intercepted by the noteDrawer,
        // so we need to send this ref to the noteDrawer so it knows not to close the drawer when the user hits escape
        if (props.editorElementRef && open) {
            props.editorElementRef.current = editorRef.current?.editor;
        }
        if (props.editorElementRef && !open) {
            props.editorElementRef.current = null;
        }
    }, [props.editorElementRef, editorRef, open]);

    useEffect(() => {
        if (getPopoverOpenState) getPopoverOpenState(open);
    }, [open, getPopoverOpenState]);

    const { MentionSuggestions: MentionSuggestionComp, plugins: mentionsPlugin } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            theme: {
                mention: 'mention',
                mentionSuggestions: 'mentionSuggestions',
            },
            mentionPrefix: '@',
            mentionRegExp: '[\\w.,;:!?"\'`@#$%^&*()_+=-]+',
            supportWhitespace: true,
            positionSuggestions: (settings) => {
                const mentionEditorContainerElement = mentionEditorContainerRef.current;
                const mentionEditorContainerElementRect =
                    mentionEditorContainerElement?.getBoundingClientRect();
                const popoverRect = settings.popover?.getBoundingClientRect();

                const rightOverFlow =
                    settings.decoratorRect.left +
                    popoverRect.width -
                    mentionEditorContainerElementRect.right;

                const suggestionsElementLeft = getValueByMode(
                    settings.decoratorRect.left + popoverRect.width >
                        mentionEditorContainerElementRect.right,
                    settings.decoratorRect.left - rightOverFlow,
                    settings.decoratorRect.left
                );

                return {
                    left: suggestionsElementLeft + 'px',
                    top: settings.decoratorRect.top + 'px',
                    display: 'block',
                    transform: 'scale(1) translateY(-100%)',
                    transformOrigin: '1em 0% 0px',
                    transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',

                    position: 'fixed',
                };
            },
        });
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, [mentionEditorContainerRef]);

    const onSearchChange = useCallback(
        ({ value }) => {
            _onSearchChange(value);
        },
        [_onSearchChange]
    );

    const onChangeHandler = useCallback(
        (editorStateParam) => {
            setEditorState(editorStateParam);
            const textWithStrippedAtSymbol = convertEditorStateToApiFormat(
                editorStateParam
            ).replace(/@(?={{)/g, '');
            onChange(textWithStrippedAtSymbol);
        },
        [onChange]
    );

    return (
        <div
            onClick={() => {
                editorRef.current?.focus();
            }}
        >
            <Editor
                handleKeyCommand={(command) => {
                    if (handleSubmit && command === 'submit') {
                        handleSubmit();
                        return 'handled';
                    }

                    if (command === 'closeSuggestions') {
                        setOpen(false);

                        return 'handled';
                    }

                    return 'not-handled';
                }}
                editorKey="editor"
                editorState={editorState}
                onChange={onChangeHandler}
                plugins={mentionsPlugin}
                ref={editorRef}
                keyBindingFn={(e) => {
                    if (e.key === 'Enter' && e.shiftKey) return 'submit';
                    if (e.key === 'Escape') return 'closeSuggestions';
                }}
            />
            <Box
                className="mentionSuggestionsContainer"
                style={{
                    disaplay: suggestionsWithFallback.length > 0 ? 'block' : 'none',
                }}
            >
                <MentionSuggestionComp
                    open={open}
                    onOpenChange={setOpen}
                    suggestions={suggestionsWithFallback}
                    onSearchChange={onSearchChange}
                    entryComponent={UserSuggestionItem}
                    onAddMention={(selectedMention) =>
                        onAddMention && onAddMention((prevState) => [...prevState, selectedMention])
                    }
                />
            </Box>
        </div>
    );
}

MentionEditor.propTypes = {
    editorState: PropTypes.object.isRequired,
    suggestions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        })
    ),
    onChange: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    onAddMention: PropTypes.func,
    mentionEditorContainerRef: PropTypes.object,
    getPopoverOpenState: PropTypes.func,
    editorElementRef: PropTypes.object,
};
