import { useLayoutEffect, useRef, useState } from 'react';

import { useCallbackRef } from 'hooks/useCallbackRef';

export function useExpandableCard() {
    const containerRef = useRef(null);
    const [contentRef, clientHeight, scrollHeight, recompute, resetScroll] = useCallbackRef();

    const [collapsed, setCollapsed] = useState(true);
    const [isCollapsable, setIsCollapsable] = useState(false);
    const [displayExpandButton, setDisplayExpandButton] = useState(false);

    const handleExpand = () => {
        setCollapsed(false);
        setIsCollapsable(true);
    };

    const handleCollapse = (callback) => {
        callback?.();
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
        setCollapsed(true);
    };

    useLayoutEffect(() => {
        recompute();
        if (collapsed) {
            setDisplayExpandButton(clientHeight < scrollHeight);
        }
    }, [collapsed, clientHeight, scrollHeight, recompute]);

    return {
        containerRef,
        contentRef,
        collapsed,
        isCollapsable,
        displayExpandButton,
        handleExpand,
        handleCollapse,
        resetScroll,
    };
}
