import { useMemo } from 'react';

export function useTalentProfileInfo(props) {
    const { currentTalentId, isFetchingTalentData, talentProfile, contactMeta } = props;

    const isExperienceDataHidden = (talentData) => {
        const { experience, education } = talentData;
        return !experience?.length && !education?.length;
    };

    const emailStatusData = contactMeta?.emailStatuses;
    const contactCounts = contactMeta?.contactCounts;

    const talentData = useMemo(
        () => ({
            ...talentProfile,
            userEmailStatus: emailStatusData?.user?.[currentTalentId]?.[0],
            contactCounts: contactCounts?.[currentTalentId],
        }),
        [talentProfile, emailStatusData, contactCounts, currentTalentId]
    );

    return {
        isFetchingData: isFetchingTalentData,
        isExperienceDataHidden,
        talentData,
    };
}
