import { MESSAGE_TYPES, MINIMUM_EMAIL_SUBJECT_LENGTH } from 'utils/constants';

export const getBodyValidator = (type, bodyLength, subjectLength) => {
    const validEmailBody = bodyLength > 0 && subjectLength >= MINIMUM_EMAIL_SUBJECT_LENGTH;
    const validSmsBody = bodyLength > 0 && bodyLength < 1600;

    if (type === MESSAGE_TYPES.EMAIL) {
        return validEmailBody;
    }
    if (type === MESSAGE_TYPES.SMS) {
        return validSmsBody;
    }
};
