import SvgIcon from '@mui/material/SvgIcon';

export function WorkIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <path
                d="M4 3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H7C7.26522 2 7.51957 2.10536 7.70711 2.29289C7.89464 2.48043 8 2.73478 8 3V4H8.5C8.89782 4 9.27936 4.15804 9.56066 4.43934C9.84196 4.72064 10 5.10218 10 5.5V8.5C10 8.89782 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89782 10 8.5 10H3.5C3.10218 10 2.72064 9.84196 2.43934 9.56066C2.15804 9.27936 2 8.89782 2 8.5V5.5C2 5.10218 2.15804 4.72064 2.43934 4.43934C2.72064 4.15804 3.10218 4 3.5 4H4V3ZM7 3H5V4H7V3ZM3.5 5C3.36739 5 3.24021 5.05268 3.14645 5.14645C3.05268 5.24021 3 5.36739 3 5.5V8.5C3 8.63261 3.05268 8.75979 3.14645 8.85355C3.24021 8.94732 3.36739 9 3.5 9H8.5C8.63261 9 8.75979 8.94732 8.85355 8.85355C8.94732 8.75979 9 8.63261 9 8.5V5.5C9 5.36739 8.94732 5.24021 8.85355 5.14645C8.75979 5.05268 8.63261 5 8.5 5H3.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
