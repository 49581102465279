const openParentheses = ['('];
const closeParentheses = [')'];
const parenthesesMap = {
    ')': '(',
};

export function checkValidDblQuotes(str) {
    if (typeof str !== 'string') {
        return false;
    }
    const quotationMarks = str.match(/"/g);
    const quotationMarkCount = quotationMarks ? quotationMarks.length : 0;
    return quotationMarkCount % 2 === 0;
}

export function checkValidApostrophe(str) {
    if (typeof str !== 'string') {
        return false;
    }
    return /^(?!')/g.test(str);
}

export function checkValidParenthesis(str) {
    if (typeof str !== 'string') {
        return false;
    }

    const stack = [];
    for (let i = 0; i < str.length; i++) {
        if (openParentheses.includes(str[i])) {
            stack.push(str[i]);
        } else if (stack[stack.length - 1] === parenthesesMap[str[i]]) {
            stack.pop();
        } else if (!stack.length && closeParentheses.includes(str[i])) {
            return false;
        }
    }
    return !stack.length;
}
