import { useDispatch, useSelector } from 'react-redux';
import { setMessageGenerationOption } from '../messageSlice';
import { useGetSavedSearchesQuery, useGetSearchHistoryQuery } from 'services/search';
import { MESSAGE_GENERATION_TARGETS, MESSAGE_SOURCE_OPTIONS } from '../utils';
import { useGetJobDescriptionsQuery } from 'services/jobDescription';
import { useCallback, useMemo } from 'react';

export function useMessageGeneration({ target, source }) {
    const value = useSelector((state) => state.message.messageGenerationOptions[target]);
    const dispatch = useDispatch();

    const shouldSkipFetchSavedSearch =
        target === MESSAGE_GENERATION_TARGETS.SOURCE_ID &&
        source !== MESSAGE_SOURCE_OPTIONS.talentSavedSearch.value;
    const shouldSkipFetchSearchHistory =
        target === MESSAGE_GENERATION_TARGETS.SOURCE_ID &&
        source !== MESSAGE_SOURCE_OPTIONS.talentSearchHistory.value;
    const shouldSkipFetchJD =
        target === MESSAGE_GENERATION_TARGETS.SOURCE_ID &&
        source !== MESSAGE_SOURCE_OPTIONS.talentJobDescription.value;

    const { data: savedSearches, isFetching: isSavedSearchesFetching } = useGetSavedSearchesQuery(
        null,
        {
            skip: shouldSkipFetchSavedSearch,
        }
    );

    const { data: searchHistory, isFetching: isSearchHistoryFetching } = useGetSearchHistoryQuery(
        { page: 1, pageLength: 5 },
        {
            skip: shouldSkipFetchSearchHistory,
        }
    );

    const { data: jobDescriptions, isFetching: isJobDescriptionsFetching } =
        useGetJobDescriptionsQuery(null, {
            skip: shouldSkipFetchJD,
        });

    const handleChangeOption = useCallback(
        (event) => {
            dispatch(
                setMessageGenerationOption({
                    target,
                    value: event.target.value,
                })
            );
        },
        [target, dispatch]
    );

    const options = useMemo(() => {
        if (target !== MESSAGE_GENERATION_TARGETS.SOURCE_ID || !source) {
            return null;
        }
        if (source === MESSAGE_SOURCE_OPTIONS.talentSavedSearch.value && savedSearches) {
            return savedSearches;
        }
        if (source === MESSAGE_SOURCE_OPTIONS.talentSearchHistory.value && searchHistory) {
            return searchHistory?.results?.map((search) => ({
                id: search.id,
                name: search.details.body.query.matchText,
            }));
        }
        if (source === MESSAGE_SOURCE_OPTIONS.talentJobDescription.value && jobDescriptions) {
            return jobDescriptions;
        }
    }, [target, source, savedSearches, searchHistory, jobDescriptions]);

    const isFetching = useMemo(() => {
        if (target !== MESSAGE_GENERATION_TARGETS.SOURCE_ID || !source) {
            return null;
        }
        if (source === MESSAGE_SOURCE_OPTIONS.talentSavedSearch.value) {
            return isSavedSearchesFetching;
        }
        if (source === MESSAGE_SOURCE_OPTIONS.talentSearchHistory.value) {
            return isSearchHistoryFetching;
        }
        if (source === MESSAGE_SOURCE_OPTIONS.talentJobDescription.value) {
            return isJobDescriptionsFetching;
        }
    }, [
        target,
        source,
        isSavedSearchesFetching,
        isSearchHistoryFetching,
        isJobDescriptionsFetching,
    ]);

    return {
        value,
        handleChangeOption,
        options,
        isFetching,
    };
}
