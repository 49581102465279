import SvgIcon from '@mui/material/SvgIcon';

export function AstronautIcon(props) {
    return (
        <SvgIcon viewBox="0 0 64 64" fill="none" {...props}>
            <g clipPath="url(#clip0_21851_47628)">
                <rect width="64" height="64" rx="32" fill="url(#paint0_linear_21851_47628)" />
                <path
                    d="M42.255 52.621C50.6065 48.7171 56.4044 40.2809 56.4044 30.4728C56.4044 16.9569 45.4198 6 31.8692 6C18.3186 6 7.33411 16.9569 7.33411 30.4728C7.33411 40.2806 13.1319 48.7171 21.4835 52.621H42.255Z"
                    fill="white"
                    fillOpacity="0.48"
                />
                <g opacity="0.4">
                    <path
                        d="M52.2506 37.1502C53.7677 32.6568 53.6855 27.6551 52.0529 23.2057"
                        stroke="white"
                        strokeWidth="1.52978"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M48.7719 17.3229C48.2081 16.585 47.5703 15.9082 46.9211 15.2456"
                        stroke="white"
                        strokeWidth="1.52978"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <path
                    d="M18.2598 33.3956C18.2598 33.3956 10.7472 45.2572 26.491 45.6375H37.2478C52.9916 45.2572 45.479 33.3956 45.479 33.3956H18.2598Z"
                    fill="#3A8DD3"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M44.6863 69.6601H19.0528C13.189 69.6601 8.43524 64.9063 8.43524 59.0425C8.43524 54.012 12.5134 49.9338 17.5439 49.9338H46.1953C51.2258 49.9338 55.3039 54.012 55.3039 59.0425C55.3039 64.9063 50.5502 69.6601 44.6863 69.6601Z"
                    fill="#1E2B38"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.1064 32.8622C19.1064 32.8622 19.2007 32.4659 19.4306 31.8398L19.1064 32.8622Z"
                    fill="url(#paint1_linear_21851_47628)"
                />
                <path
                    d="M19.1064 32.8622C19.1064 32.8622 19.2007 32.4659 19.4306 31.8398"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M44.725 28.0983V21.1538C44.725 15.6687 40.2669 11.222 34.7679 11.222H28.9638C23.4647 11.222 19.0067 15.6687 19.0067 21.1538V29.2038"
                    fill="#3A8DD3"
                />
                <path
                    d="M44.725 28.0983V21.1538C44.725 15.6687 40.2669 11.222 34.7679 11.222H28.9638C23.4647 11.222 19.0067 15.6687 19.0067 21.1538V29.2038"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M37.2481 39.328H26.491V49.1404H37.2481V39.328Z"
                    fill="#F3F8FD"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M45.9687 27.0267C45.5119 26.801 45.0216 26.7006 44.5251 26.7003L42.6241 35.711C44.3649 36.0324 46.3307 34.9338 47.3224 32.9382C48.4775 30.6142 47.8713 27.9675 45.9687 27.0267Z"
                    fill="#F3F8FD"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.35 22.1818C30.6088 22.3256 30.8788 22.4502 31.1583 22.5534C32.6806 23.1159 34.3488 23.0833 35.9536 23.3303C38.8801 23.7806 42.2414 24.6729 44.3017 26.7945V29.2044C44.3017 35.9748 40.7457 42.036 35.37 44.3625L33.0063 45.3955C32.2743 45.7187 31.4688 45.7187 30.7368 45.3955L28.3626 44.3625C22.9974 42.036 19.4309 35.9751 19.4309 29.2044V26.7009C21.4357 21.2146 27.1858 25.5639 29.8688 21.9274L30.35 22.1818Z"
                    fill="#F3F8FD"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.0563 15.4827C26.6141 18.1011 28.0394 20.8987 30.3501 22.1814"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.831 52.0713C25.8182 53.1126 36.4609 53.186 47.9078 52.0713V56.6602C37.21 58.3848 26.5174 58.3848 15.831 56.6602V52.0713Z"
                    fill="#374755"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.831 47.7424C25.8182 48.7837 36.4609 48.8572 47.9078 47.7424V52.331C37.21 54.0557 26.5174 54.0557 15.831 52.331V47.7424Z"
                    fill="#A3BCC3"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.4306 34.5194H15.355C14.204 34.5194 13.2708 33.5862 13.2708 32.4352V28.3032C13.2708 27.1521 14.204 26.219 15.355 26.219H19.4309V34.5194H19.4306Z"
                    fill="#5F2BE1"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.548 34.5194V36.9987C18.548 38.5464 19.8059 39.8012 21.3576 39.8012H31.0771"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M34.4341 39.8012C34.4341 41.0545 33.1157 42.071 31.4893 42.071C29.8629 42.071 28.5445 41.0548 28.5445 39.8012C28.5445 38.5476 29.8629 37.5314 31.4893 37.5314C33.1157 37.5314 34.4341 38.5476 34.4341 39.8012Z"
                    fill="#5F2BE1"
                    stroke="#263746"
                    strokeWidth="1.52978"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M53.9934 6.78355C53.9585 6.75592 53.9159 6.74098 53.8721 6.74097C53.8284 6.74098 53.7858 6.75592 53.7508 6.78355C53.7159 6.81117 53.6905 6.84997 53.6784 6.89407L53.46 7.69502C53.413 7.86752 53.3248 8.02461 53.2037 8.15146C53.0826 8.27831 52.9327 8.37073 52.768 8.41999L52.0035 8.6488C51.9614 8.66142 51.9244 8.68805 51.8981 8.72466C51.8717 8.76127 51.8575 8.80587 51.8575 8.85171C51.8575 8.89755 51.8717 8.94215 51.8981 8.97876C51.9244 9.01537 51.9614 9.042 52.0035 9.05462L52.768 9.28343C52.9327 9.33269 53.0826 9.42511 53.2037 9.55196C53.3248 9.67881 53.413 9.8359 53.46 10.0084L53.6784 10.8094C53.6905 10.8534 53.7159 10.8922 53.7509 10.9198C53.7858 10.9474 53.8284 10.9623 53.8721 10.9623C53.9159 10.9623 53.9585 10.9474 53.9934 10.9198C54.0284 10.8922 54.0538 10.8534 54.0658 10.8094L54.2842 10.0084C54.3313 9.8359 54.4195 9.67881 54.5406 9.55196C54.6616 9.42511 54.8116 9.33269 54.9762 9.28343L55.7408 9.05462C55.7829 9.042 55.8199 9.01537 55.8462 8.97876C55.8725 8.94215 55.8868 8.89755 55.8868 8.85171C55.8868 8.80587 55.8725 8.76127 55.8462 8.72466C55.8199 8.68805 55.7829 8.66142 55.7408 8.6488L54.9762 8.41999C54.8116 8.37073 54.6616 8.27831 54.5406 8.15146C54.4195 8.02461 54.3313 7.86752 54.2842 7.69502L54.0658 6.89407C54.0538 6.84997 54.0284 6.81117 53.9934 6.78355Z"
                    fill="white"
                />
                <path
                    d="M55.8916 45.4958C55.8566 45.4682 55.814 45.4533 55.7703 45.4532C55.7265 45.4533 55.6839 45.4682 55.649 45.4958C55.614 45.5235 55.5886 45.5622 55.5766 45.6063L55.3582 46.4073C55.3112 46.5798 55.2229 46.7369 55.1018 46.8637C54.9808 46.9906 54.8308 47.083 54.6662 47.1323L53.9016 47.3611C53.8595 47.3737 53.8225 47.4003 53.7962 47.4369C53.7699 47.4735 53.7556 47.5181 53.7556 47.564C53.7556 47.6098 53.7699 47.6544 53.7962 47.691C53.8225 47.7276 53.8595 47.7543 53.9016 47.7669L54.6662 47.9957C54.8308 48.045 54.9808 48.1374 55.1018 48.2642C55.2229 48.3911 55.3112 48.5482 55.3582 48.7207L55.5766 49.5216C55.5886 49.5657 55.6141 49.6045 55.649 49.6321C55.6839 49.6597 55.7265 49.6746 55.7703 49.6746C55.814 49.6746 55.8566 49.6597 55.8915 49.6321C55.9265 49.6045 55.9519 49.5657 55.964 49.5216L56.1824 48.7207C56.2294 48.5482 56.3176 48.3911 56.4387 48.2642C56.5598 48.1374 56.7097 48.045 56.8744 47.9957L57.6389 47.7669C57.681 47.7543 57.718 47.7276 57.7443 47.691C57.7707 47.6544 57.7849 47.6098 57.7849 47.564C57.7849 47.5181 57.7707 47.4735 57.7443 47.4369C57.718 47.4003 57.681 47.3737 57.6389 47.3611L56.8744 47.1323C56.7097 47.083 56.5598 46.9906 56.4387 46.8637C56.3176 46.7369 56.2294 46.5798 56.1824 46.4073L55.964 45.6063C55.9519 45.5622 55.9265 45.5235 55.8916 45.4958Z"
                    fill="white"
                />
                <path
                    d="M1.48985 39.8589C1.46518 39.8394 1.43513 39.8289 1.40423 39.8289C1.37333 39.8289 1.34328 39.8394 1.31861 39.8589C1.29395 39.8784 1.27602 39.9058 1.26753 39.9369L1.11339 40.5022C1.0802 40.6239 1.01794 40.7348 0.932482 40.8243C0.847026 40.9139 0.741197 40.9791 0.62499 41.0139L0.0853987 41.1753C0.0557089 41.1842 0.0295951 41.203 0.0110055 41.2289C-0.00758423 41.2547 -0.0176392 41.2862 -0.0176392 41.3185C-0.0176392 41.3509 -0.00758423 41.3824 0.0110055 41.4082C0.0295951 41.434 0.0557089 41.4528 0.0853987 41.4618L0.62499 41.6232C0.741197 41.658 0.847026 41.7232 0.932482 41.8128C1.01794 41.9023 1.0802 42.0131 1.11339 42.1349L1.26753 42.7002C1.27604 42.7313 1.29398 42.7586 1.31864 42.7781C1.3433 42.7976 1.37335 42.8081 1.40423 42.8081C1.43511 42.8081 1.46516 42.7976 1.48982 42.7781C1.51448 42.7586 1.53242 42.7313 1.54093 42.7002L1.69507 42.1349C1.72826 42.0131 1.79052 41.9023 1.87598 41.8128C1.96144 41.7232 2.06726 41.658 2.18347 41.6232L2.72306 41.4618C2.75275 41.4528 2.77887 41.434 2.79746 41.4082C2.81605 41.3824 2.8261 41.3509 2.8261 41.3185C2.8261 41.2862 2.81605 41.2547 2.79746 41.2289C2.77887 41.203 2.75275 41.1842 2.72306 41.1753L2.18347 41.0139C2.06726 40.9791 1.96144 40.9139 1.87598 40.8243C1.79052 40.7348 1.72826 40.6239 1.69507 40.5022L1.54093 39.9369C1.53244 39.9058 1.51451 39.8784 1.48985 39.8589Z"
                    fill="white"
                />
                <path
                    d="M55.532 12.3625C55.4809 12.3222 55.4187 12.3003 55.3547 12.3003C55.2907 12.3003 55.2285 12.3222 55.1774 12.3625C55.1263 12.4029 55.0892 12.4596 55.0716 12.5241L54.7524 13.6947C54.6837 13.9468 54.5547 14.1764 54.3778 14.3618C54.2008 14.5472 53.9816 14.6823 53.741 14.7543L52.6236 15.0887C52.5621 15.1071 52.508 15.146 52.4695 15.1995C52.431 15.253 52.4102 15.3182 52.4102 15.3852C52.4102 15.4522 52.431 15.5174 52.4695 15.5709C52.508 15.6244 52.5621 15.6633 52.6236 15.6818L53.741 16.0162C53.9816 16.0882 54.2008 16.2233 54.3778 16.4087C54.5547 16.5941 54.6837 16.8237 54.7524 17.0758L55.0716 18.2464C55.0892 18.3108 55.1264 18.3675 55.1774 18.4078C55.2285 18.4481 55.2907 18.4699 55.3547 18.4699C55.4186 18.4699 55.4809 18.4481 55.5319 18.4078C55.583 18.3675 55.6202 18.3108 55.6378 18.2464L55.957 17.0758C56.0257 16.8237 56.1546 16.5941 56.3316 16.4087C56.5086 16.2233 56.7277 16.0882 56.9684 16.0162L58.0858 15.6818C58.1473 15.6633 58.2014 15.6244 58.2399 15.5709C58.2784 15.5174 58.2992 15.4522 58.2992 15.3852C58.2992 15.3182 58.2784 15.253 58.2399 15.1995C58.2014 15.146 58.1473 15.1071 58.0858 15.0887L56.9684 14.7543C56.7277 14.6823 56.5086 14.5472 56.3316 14.3618C56.1546 14.1764 56.0257 13.9468 55.957 13.6947L55.6378 12.5241C55.6202 12.4596 55.5831 12.4029 55.532 12.3625Z"
                    fill="white"
                />
                <path
                    d="M7.70187 44.0065C7.65889 43.9725 7.60652 43.9541 7.55268 43.9541C7.49884 43.9541 7.44647 43.9725 7.40349 44.0065C7.36051 44.0404 7.32927 44.0882 7.31448 44.1424L7.04588 45.1274C6.98805 45.3396 6.87955 45.5328 6.73064 45.6888C6.58173 45.8448 6.39732 45.9584 6.19482 46.019L5.25456 46.3004C5.20282 46.3159 5.15732 46.3487 5.12493 46.3937C5.09253 46.4387 5.07501 46.4936 5.07501 46.5499C5.07501 46.6063 5.09253 46.6612 5.12493 46.7062C5.15732 46.7512 5.20282 46.784 5.25456 46.7995L6.19482 47.0809C6.39732 47.1415 6.58173 47.2551 6.73064 47.4111C6.87955 47.5671 6.98805 47.7603 7.04588 47.9725L7.31448 48.9575C7.3293 49.0117 7.36056 49.0594 7.40354 49.0933C7.44651 49.1272 7.49887 49.1456 7.55268 49.1456C7.6065 49.1456 7.65885 49.1272 7.70183 49.0933C7.74481 49.0594 7.77607 49.0117 7.79089 48.9575L8.05949 47.9725C8.11732 47.7603 8.22582 47.5671 8.37473 47.4111C8.52364 47.2551 8.70805 47.1415 8.91055 47.0809L9.85081 46.7995C9.90254 46.784 9.94805 46.7512 9.98044 46.7062C10.0128 46.6612 10.0304 46.6063 10.0304 46.5499C10.0304 46.4936 10.0128 46.4387 9.98044 46.3937C9.94805 46.3487 9.90254 46.3159 9.85081 46.3004L8.91055 46.019C8.70805 45.9584 8.52364 45.8448 8.37473 45.6888C8.22582 45.5328 8.11732 45.3396 8.05949 45.1274L7.79089 44.1424C7.7761 44.0882 7.74485 44.0404 7.70187 44.0065Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_21851_47628"
                    x1="32"
                    y1="-1.95349"
                    x2="32"
                    y2="64"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7950DF" />
                    <stop offset="1" stopColor="#3A8DD3" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_21851_47628"
                    x1="19.2685"
                    y1="31.8086"
                    x2="19.2685"
                    y2="32.8622"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7950DF" />
                    <stop offset="1" stopColor="#3A8DD3" />
                </linearGradient>
                <clipPath id="clip0_21851_47628">
                    <rect width="64" height="64" rx="32" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
