import SvgIcon from '@mui/material/SvgIcon';

export function SuitcaseIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M18.5 8.5H15V7L14.5 6.5H9.5L9 7V8.5H5.5L5 9V17L5.5 17.5H18.5L19 17V9L18.5 8.5ZM10 7.5H14V8.5H10V7.5ZM18 9.5V10.26L14 12.5V12L13.51 11.5H10.51L10 12V12.5L6 10.21V9.5H18ZM13 12.5V13.5H11V12.5H13ZM6 16.5V11.36L10 13.65V14L10.5 14.5H13.5L14 14V13.69L18 11.41V16.5H6Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
