import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const INIT_STATE = { editorContext: null, setEditorContext: () => {} };

export const SlateEditorContext = createContext(INIT_STATE);

export function SlateEditorProvider(props) { 
    const { children } = props;
    const [editorContext, setEditorContext] = useState(null);

    const providerValue = useMemo(
        () => ({ editorContext, setEditorContext }),
        [editorContext, setEditorContext]
    );

    return (
        <SlateEditorContext.Provider value={providerValue}>{children}</SlateEditorContext.Provider>
    );
}

SlateEditorProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};