import { useMemo } from 'react';

import {
    MediaContent,
    ShortlistContent,
    FeedbackContent,
    ProjectActivityContent,
    ProfileContent,
    EngagementContent,
} from '../components';
import { ACTIVITY_TYPE } from 'utils';

export function useActivityContent(activityType) {
    const Content = useMemo(() => {
        switch (activityType) {
            case ACTIVITY_TYPE.MEDIA:
                return MediaContent;
            case ACTIVITY_TYPE.SHORTLISTS:
                return ShortlistContent;
            case ACTIVITY_TYPE.FEEDBACK:
                return FeedbackContent;
            case ACTIVITY_TYPE.PROJECTS:
                return ProjectActivityContent;
            case ACTIVITY_TYPE.ENGAGEMENT:
                return EngagementContent;
            default:
                return ProfileContent;
        }
    }, [activityType]);

    return Content;
}
