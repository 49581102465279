import { useState } from 'react';

export function useScheduledMessageDateTime(props) {
    const [scheduledDate, setScheduledDate] = useState('');
    const [scheduledTime, setScheduledTime] = useState('');
    const [openTcpaDialog, setOpenTcpaDialog] = useState(false);

    const handleConfirmScheduledDate = ({ date, time }) => {
        setScheduledDate(date);
        setScheduledTime(time);
    };

    return {
        scheduledDate,
        setScheduledDate,
        scheduledTime,
        setScheduledTime,
        openTcpaDialog,
        setOpenTcpaDialog,
        handleConfirmScheduledDate,
    };
}
