import Box from '@mui/material/Box';
import { MessageTypeToggle } from '../MessageContent/MessageTypeToggle';
import { MessageRecipients } from '../MessageContent/MessageRecipients';
import { SubjectEditor } from '../MessageContent/MessageComposeComponents/SubjectEditor';
import { BodyEditor } from '../MessageContent/MessageComposeComponents/BodyEditor';
import { useMessageData } from 'features/MessageDrawer/hooks/useMessageData';
import { MESSAGE_TYPES } from 'utils/constants';

export function DrawerMainContent() {
    const { messageType, isGeneratingMessage } = useMessageData();
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                rowGap: '16px',
            }}
        >
            <MessageTypeToggle />
            <MessageRecipients />
            {messageType === MESSAGE_TYPES.EMAIL && (
                <SubjectEditor isFetching={isGeneratingMessage} />
            )}
            <BodyEditor autofocus />
        </Box>
    );
}
