import { create } from 'zustand';

export const useNotesStore = create((set) => ({
    isAscMode: true,
    notesEnvUrl: '',
    filter: 'any',
    notesSource: {},
    setFilter: (filter) => set({ filter }),
    setNotesSource: (notesSource) => set({ notesSource }),
    setIsAscMode: (isAscMode) => set({ isAscMode }),
    setNotesEnvUrl: (notesEnvUrl) => set({ notesEnvUrl }),
}));
