import { SCHEDULED_DATE_FMT, SCHEDULED_TIME_FMT } from 'utils/constants';
import parse from 'date-fns/parse';
import { SIGNATURE_DIV, SIGNATURE_DIV_PREFIX } from './enums';

const wrapWithElement = (htmlString) => {
    const regex = /<div>.*?<\/div>/g;
    const extractedText = htmlString.replace(regex, '');

    const wrappedText = `<div>${extractedText}</div>`;
    return htmlString.replace(extractedText, wrappedText);
};

export function generateTimestamp(scheduledDate, scheduledTime) {
    const dateString = `${scheduledDate} ${scheduledTime}`;
    const dateFormat = `${SCHEDULED_DATE_FMT} ${SCHEDULED_TIME_FMT}`;
    return parse(dateString, dateFormat, new Date()).toISOString();
}

export function processSignature(innerHtml, signature) {
    let processedString = innerHtml;

    const offset = innerHtml.search(SIGNATURE_DIV_PREFIX); //search on prefix to avoid style overrides

    if (offset >= 0) {
        processedString = innerHtml.slice(0, offset);
        if (!processedString.includes('<')) {
            processedString = `<div>${processedString}</div>`;
        }
        processedString = wrapWithElement(processedString);
    }
    return `${processedString}<br/>${SIGNATURE_DIV}${signature.signature}</div>`;
}

export function trimOffSignature(innerHTML, signature) {
    let newInnerHTML = innerHTML;
    if (signature) {
        newInnerHTML = innerHTML.replace(signature, '');
    }
    newInnerHTML = newInnerHTML.replace(/(<br \/>)?<div id="signature-block">.*<\/div>/g, '');

    return newInnerHTML;
}