import filesize from 'filesize';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';

import { FileTags } from './FileTags';
import { FileIcon } from '../FileIcon';
import { CloseIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { FileDisplayName } from './FileDisplayName';
import { getMediaType } from 'views/UserSettings/utils/getMediaType';
import { SuggestedTags } from './SuggestedTags';

const HeaderText = styled(Typography)({
    fontWeight: '700',
});

const FileNameText = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.72),
    lineHeight: '14px',
    fontStyle: 'italic',
}));

const FileSizeText = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.48),
    fontWeight: 700,
    marginLeft: theme.spacing(0.5),
}));

export function NewMultipleMediaUpload({
    attachments,
    displayNames,
    onDisplayNameChange,
    onTagChange,
    tagOptions,
    multiTagsValue,
    onTagInputValue,
    onTagInputEnter,
    onRemoveUploadMedia,
    onSuggestedTagClick,
}) {
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    py: '5px',
                    pl: 0,
                    borderBottom: '1px solid #eee',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Box sx={{ width: '42%' }}>
                    <HeaderText variant="labelXSmall">Display Name</HeaderText>
                </Box>
                <Box sx={{ width: '38%' }}>
                    <HeaderText variant="labelXSmall">Tags</HeaderText>
                </Box>
            </Box>

            {attachments?.map((attachment, index) => {
                const { filename, size, mimetype, handle } = attachment;
                return (
                    <Grid container spacing={1} key={handle}>
                        <Grid item xs={6}>
                            <Box sx={{ display: 'flex', marginBottom: 3 }}>
                                <FileIcon
                                    mediaType={getMediaType(mimetype)}
                                    IconStyleSx={{ marginTop: '6px' }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        ml: 1,
                                        flex: 1,
                                        mr: 1,
                                    }}
                                >
                                    <FileDisplayName
                                        displayName={displayNames[handle]}
                                        filename={filename}
                                        onChange={onDisplayNameChange}
                                        mediaId={handle}
                                        showLabel={false}
                                    />
                                    <FormHelperText sx={{ mt: 1 }}>
                                        <FileNameText variant="bodySmall">{filename}</FileNameText>
                                        <FileSizeText variant="labelXSmall">
                                            {filesize(size, {
                                                standard: 'jedec',
                                            })}
                                        </FileSizeText>
                                    </FormHelperText>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box sx={{ marginBottom: 3 }}>
                                <Box sx={{ display: 'flex' }}>
                                    <FileTags
                                        tagOptions={tagOptions}
                                        selectedValues={multiTagsValue[handle] || []}
                                        onValueChange={onTagChange}
                                        onInputChange={onTagInputValue}
                                        onInputEnter={onTagInputEnter}
                                        showLabel={false}
                                        InputStyleSx={{
                                            '& input': {
                                                height: 11,
                                            },
                                        }}
                                        mediaId={handle}
                                    />
                                    <IconButton
                                        data-testid={`delete-media-${handle}`}
                                        sx={{ p: 0, ml: 1 }}
                                        disableRipple
                                        onClick={() => onRemoveUploadMedia(handle)}
                                    >
                                        <CloseIcon sx={{ width: '16px', height: '16px' }} />
                                    </IconButton>
                                </Box>
                                {!isEmpty(attachment?.suggestedTags) && (
                                    <Box sx={{ mt: 1 }}>
                                        <SuggestedTags
                                            suggestedTags={attachment.suggestedTags}
                                            onSuggestedTagClick={onSuggestedTagClick}
                                            handle={handle}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                );
            })}
        </Box>
    );
}

NewMultipleMediaUpload.propTypes = {
    displayNames: PropTypes.object,
    onDisplayNameChange: PropTypes.func,
    onTagChange: PropTypes.func,
    attachments: PropTypes.array,
    tagOptions: PropTypes.array,
    multiTagsValue: PropTypes.object,
    onTagInputValue: PropTypes.func,
    onTagInputEnter: PropTypes.func,
    onRemoveUploadMedia: PropTypes.func,
    onSuggestedTagClick: PropTypes.func,
};
