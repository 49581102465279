import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import { useLocation, useMatch } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { CREDITS } from 'utils/constants';
import { Typography } from 'components/Typography';
import { isAboveMaxSafeInteger } from 'utils/helpers';
import { CloseIcon, InfinityIcon } from 'components/Icons';
import { EarnCreditDialog } from 'components/Dialog/EarnCreditDialog';
import { useGetSignedInOrganizationQuery } from 'services/organizations';
import { useUpgradeAccountPrompt } from 'components/Dialog/UpgradeAccountPrompt';
import {
    DialogButton,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogButtonSecondary,
} from 'components/Dialog';
import { PricingTable } from 'features/Stripe/PricingTable';
import { getValueByMode } from 'utils/copy';
import UpgradeAccountTable from 'components/Dialog/UpgradeAccountPrompt/UpgradeAccountTable';

const creditsLabels = {
    [CREDITS.JD]: 'JD Builder',
    [CREDITS.SEARCH]: 'Search',
    [CREDITS.LOOKUP]: 'Lookup',
};

const CloseButtonWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '20px',
    right: '25px',
    color: theme.palette.text.primary,
}));

const EarnCreditsButton = styled(DialogButtonSecondary)(({ theme }) => ({
    minWidth: theme.spacing(42),
    marginTop: theme.spacing(1),
    borderColor: 'transparent',
    '&:not(:first-of-type)': {
        marginLeft: 0,
    },
    '& > .MuiTypography-root': {
        fontWeight: 700,
        fontSize: 16,
        color: theme.palette.primary.main,
    },
}));

const CreditDisplayWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isEmpty',
})(({ theme, isEmpty }) => ({
    color: isEmpty ? theme.palette.error.main : theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.spacing(10),
}));

function WelcomeBackCreditDisplay(props) {
    const { creditType, creditCount, isLoading, isError } = props;

    let isUnlimitedCredits = false;

    if (!isError) {
        isUnlimitedCredits = isAboveMaxSafeInteger(creditCount);
    }

    return (
        <CreditDisplayWrapper isEmpty={creditCount < 1 || isError}>
            <Typography variant="labelXSmall" color="text.primary">
                {creditsLabels[creditType]}
            </Typography>
            {isLoading && <Skeleton variant="rounded" height={24} width={16} />}
            {!isLoading && !isUnlimitedCredits && (
                <Typography variant="titleMedium" color="inherit">
                    {isError ? '?' : creditCount}
                </Typography>
            )}
            {!isLoading && isUnlimitedCredits && <InfinityIcon />}
        </CreditDisplayWrapper>
    );
}

export function WelcomeBackPrompt() {
    const [isWelcomeBackModalOpen, setWelcomeBackModalOpen] = useState(false);
    const [isEarnCreditOpen, setIsEarnCreditOpen] = useState(false);
    const { pathname } = useLocation();

    const {
        stripeConfig,
        requestUpgrade,
        setStripeConfig,
        isLoading: isUpgradeRequestLoading,
    } = useUpgradeAccountPrompt();

    const isLoading = isUpgradeRequestLoading;

    const user = useSelector((state) => state.user);
    const isPremiumUser = user?.isPaid;
    const isUserLoading = !('isPaid' in user);
    const isSubscriptionCompletePage = pathname.includes('subscription-complete');

    const myOrgDataResponse = useGetSignedInOrganizationQuery(null, {
        refetchOnMountOrArgChange: true,
    });
    const { data, isUninitialized, isFetching, isError } = myOrgDataResponse;
    const isOrgDataLoading = isUninitialized || isFetching;

    const remainingLookupCreditsCount = Math.max(
        data?.metrics?.[CREDITS.LOOKUP]?.availableTokens,
        0
    );
    const remainingJDCreditsCount = Math.max(data?.metrics?.[CREDITS.JD]?.availableTokens, 0);
    const remainingSearchCreditsCount = Math.max(
        data?.metrics?.[CREDITS.SEARCH]?.availableTokens,
        0
    );
    const hasAnyCreditsRemaining =
        remainingLookupCreditsCount + remainingJDCreditsCount + remainingSearchCreditsCount > 0;
    const hasAllCreditsRemaining =
        remainingLookupCreditsCount > 0 &&
        remainingJDCreditsCount > 0 &&
        remainingSearchCreditsCount > 0;

    const talentPathname = '/talent/*';
    const match = useMatch(talentPathname);

    useEffect(() => {
        if (!isPremiumUser && !match && !isUserLoading && !isSubscriptionCompletePage) {
            setWelcomeBackModalOpen(true);
        }
    }, [isPremiumUser, match, isUserLoading, isSubscriptionCompletePage]);

    function handleCloseModal() {
        setStripeConfig(null);
        setWelcomeBackModalOpen(false);
    }

    const handleOpenEarnCreditModal = () => setIsEarnCreditOpen(true);

    const handleCloseEarnCreditModal = () => {
        setIsEarnCreditOpen(false);
        setWelcomeBackModalOpen(true);
    };

    return (
        <>
            <Dialog
                open={isWelcomeBackModalOpen}
                disableEscapeKeyDown
                aria-describedby="welcome-back-prompt-copy"
                onClose={handleCloseModal}
                PaperProps={{ sx: { maxWidth: 544 } }}
            >
                <CloseButtonWrapper>
                    <IconButton edge="end" onClick={handleCloseModal} aria-label="close-modal">
                        <CloseIcon />
                    </IconButton>
                </CloseButtonWrapper>
                <DialogContent sx={{ px: 4 }}>
                    <Box sx={{ display: getValueByMode(stripeConfig, 'none', 'block') }}>
                        <Box>
                            <Typography
                                variant="headline"
                                component="p"
                                sx={{ fontWeight: 400, textAlign: 'center' }}
                            >
                                Welcome to Nebula!
                            </Typography>
                        </Box>
                        <DialogContentText
                            id="welcome-back-prompt-copy"
                            sx={{
                                pb: 3,
                                px: hasAllCreditsRemaining ? 5 : 11,
                                color: hasAllCreditsRemaining ? 'text.primary' : 'error.main',
                            }}
                        >
                            {hasAllCreditsRemaining &&
                                hasAnyCreditsRemaining &&
                                'Here are your organization\u2019s remaining credits:'}
                            {hasAnyCreditsRemaining &&
                                !hasAllCreditsRemaining &&
                                'Your Organization has run out of 1 or more credit types.'}
                            {!hasAnyCreditsRemaining &&
                                !hasAllCreditsRemaining &&
                                'You have 0 remaining credits!'}
                        </DialogContentText>
                        <Stack direction="row" spacing={6} justifyContent="center">
                            <WelcomeBackCreditDisplay
                                isLoading={isOrgDataLoading}
                                isError={isError}
                                creditType={CREDITS.LOOKUP}
                                creditCount={remainingLookupCreditsCount}
                            />
                            <WelcomeBackCreditDisplay
                                isLoading={isOrgDataLoading}
                                isError={isError}
                                creditType={CREDITS.JD}
                                creditCount={remainingJDCreditsCount}
                            />
                            <WelcomeBackCreditDisplay
                                isLoading={isOrgDataLoading}
                                isError={isError}
                                creditType={CREDITS.SEARCH}
                                creditCount={remainingSearchCreditsCount}
                            />
                        </Stack>
                        <Divider sx={{ mt: 1 }} />
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <Typography
                            variant="headline"
                            component="p"
                            sx={{ fontWeight: 400, textAlign: 'center' }}
                        >
                            Upgrade your account to experience all that Nebula has to offer!
                        </Typography>
                        <Box sx={{ pt: 2 }}>
                            {stripeConfig ? (
                                <PricingTable {...stripeConfig} />
                            ) : (
                                <UpgradeAccountTable handleRequestUpgrade={requestUpgrade} />
                            )}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexWrap: 'wrap', px: 8 }}>
                    <DialogButton
                        disabled={isLoading}
                        onClick={() => requestUpgrade()}
                        sx={{
                            minWidth: '100%',
                            height: '60px',
                            borderColor: 'success.main',
                            bgcolor: 'success.main',
                            mt: -4,
                            display: !!stripeConfig ? 'none' : 'block',
                        }}
                        aria-label="request-account-upgrade"
                    >
                        <Typography variant="titleSmall">Upgrade Your Account</Typography>
                    </DialogButton>
                    <EarnCreditsButton
                        aria-label="earn-additional-credits"
                        data-trackid="earn-additional-credits"
                        onClick={() => {
                            handleCloseModal();
                            handleOpenEarnCreditModal();
                        }}
                    >
                        <Typography variant="titleSmall">Earn additional credits</Typography>
                    </EarnCreditsButton>
                </DialogActions>
            </Dialog>
            <EarnCreditDialog
                open={isEarnCreditOpen}
                openModal={handleOpenEarnCreditModal}
                closeModal={handleCloseEarnCreditModal}
            />
        </>
    );
}

WelcomeBackCreditDisplay.propTypes = {
    creditType: PropTypes.oneOf([...Object.values(CREDITS)]),
    creditCount: PropTypes.number,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
};
