import SvgIcon from '@mui/material/SvgIcon';

export function BellIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M29.3282 20.5806L28.4153 13.9178C27.6625 8.36027 23.3703 4.05197 18.149 3.31523V1.6016C18.149 0.720721 17.4283 0 16.5474 0C15.6665 0 14.9458 0.720721 14.9458 1.6016V3.31523C9.7246 4.06799 5.46432 8.36027 4.69555 13.9178L3.78265 20.5806C3.54241 22.3103 4.0549 24.0401 5.19204 25.3213C6.26511 26.5386 7.78665 27.2432 9.38826 27.2432H11.0699C11.4223 29.9179 13.6966 32 16.4673 32C19.2381 32 21.5124 29.9179 21.8647 27.2432H23.6746C25.2762 27.2432 26.7977 26.5386 27.8707 25.3213C29.0079 24.024 29.5204 22.2943 29.2802 20.5806H29.3282ZM16.4994 28.8128C15.4904 28.8128 14.6575 28.1561 14.3532 27.2592H18.6455C18.3412 28.1561 17.5084 28.8128 16.4994 28.8128ZM25.5004 23.2232C25.0359 23.7518 24.3953 24.056 23.7066 24.056H9.40426C8.71557 24.056 8.09096 23.7678 7.61048 23.2232C7.09796 22.6307 6.85771 21.8138 6.96982 21.013L7.88275 14.3503C8.49136 9.81777 12.2231 6.39038 16.5634 6.39038C20.9038 6.39038 24.6195 9.81777 25.2441 14.3503L26.157 21.013C26.2692 21.8138 26.0289 22.6307 25.5164 23.2232H25.5004Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
