import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';
import { generateRepeatingSearchQueryString } from 'services/utils/generateRepeatingSearchQueryString';

const compensationBaseUrl = '/api/compensation/v1';
const talentServiceBaseUrl = 'api/talent-mgmt/v1';
const aggregatorBaseUrl = 'api/aggregator/v1';
const talentDownloadBaseUrl = '/api/document-generator/v1';

export const talentApi = createApi({
    reducerPath: 'talentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: [
        'ShortlistsByTalent',
        'Shortlists',
        'Shortlist',
        'Compensation',
        'Geography',
        'Graduates',
        'Competition',
    ],
    endpoints: (builder) => ({
        createShortlist: builder.mutation({
            query: (body) => ({
                url: `${talentServiceBaseUrl}/lists/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, body) => {
                const tags = [{ type: 'Shortlists', id: 'LISTS' }];
                if (body?.initialTalent?.length) {
                    body.initialTalent.forEach(({ talentId }) => {
                        tags.push({ type: 'ShortlistsByTalent', id: talentId });
                    });
                }
                return tags;
            },
        }),
        getShortlists: builder.query({
            query: (args) => {
                return {
                    url: `${aggregatorBaseUrl}/lists/feedback`,
                    method: 'GET',
                    params: args,
                };
            },
            providesTags: (data) =>
                data
                    ? [
                          ...data.results.map(({ id }) => ({
                              type: 'Shortlists',
                              id,
                          })),
                          { type: 'Shortlists', id: 'LISTS' },
                      ]
                    : [{ type: 'Shortlists', id: 'LISTS' }],
        }),
        getShortlist: builder.query({
            query: ({ listId, params, ...body }) => ({
                url: `${talentServiceBaseUrl}/lists/${listId}`,
                method: 'POST',
                params,
                body,
            }),
            providesTags: (result, error, { listId }) => [{ type: 'Shortlist', id: listId }],
        }),
        getShortlistsByTalentId: builder.query({
            query: (talentId) => `${talentServiceBaseUrl}/lists/talent/${talentId}`,
            providesTags: (result, error, talentId) => [
                { type: 'ShortlistsByTalent', id: talentId },
            ],
        }),
        editShortlist: builder.mutation({
            query: ({ listId, name }) => ({
                url: `${talentServiceBaseUrl}/lists/${listId}`,
                method: 'PUT',
                body: { name },
            }),
            invalidatesTags: (result, error, { listId }) => [
                { type: 'Shortlists', id: listId },
                { type: 'Shortlist', id: listId },
            ],
        }),
        deleteShortlist: builder.mutation({
            query: (listId) => ({
                url: `${talentServiceBaseUrl}/lists/${listId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { listId }) => [
                { type: 'Shortlists', id: listId },
                { type: 'Shortlist', id: listId },
            ],
        }),
        addToShortlist: builder.mutation({
            query: ({ listId, talentId, profileId }) => ({
                url: `${talentServiceBaseUrl}/lists/${listId}/talent`,
                method: 'PUT',
                body: { talentId, profileId },
            }),
            invalidatesTags: (result, error, { listId, talentId }) => [
                { type: 'ShortlistsByTalent', id: talentId },
                { type: 'Compensation', id: listId },
                { type: 'Geography', id: listId },
                { type: 'Graduates', id: listId },
                { type: 'Competition', id: listId },
                { type: 'Shortlists', id: listId },
                { type: 'Shortlist', id: listId },
            ],
        }),
        addManyToManyShortlist: builder.mutation({
            query: ({ shortlistIds, talents }) => ({
                url: `${talentServiceBaseUrl}/lists/talent`,
                method: 'PUT',
                body: { shortlistIds, talents },
            }),
            invalidatesTags: (result, error, { shortlistIds, talents }) =>
                talents
                    .map(({ talentId }) => ({ type: 'ShortlistsByTalent', id: { talentId } }))
                    .concat(
                        shortlistIds.reduce(
                            (prev, shorlistId) =>
                                prev.concat([
                                    { type: 'Compensation', id: shorlistId },
                                    { type: 'Geography', id: shorlistId },
                                    { type: 'Graduates', id: shorlistId },
                                    { type: 'Competition', id: shorlistId },
                                    { type: 'Shortlists', id: shorlistId },
                                    { type: 'Shortlist', id: shorlistId },
                                ]),
                            []
                        )
                    ),
        }),
        removeFromShortlist: builder.mutation({
            query: ({ listId, talentId }) => ({
                url: `${talentServiceBaseUrl}/lists/${listId}/talent/${talentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { listId }) => [
                { type: 'Compensation', id: listId },
                { type: 'Geography', id: listId },
                { type: 'Graduates', id: listId },
                { type: 'Competition', id: listId },
                { type: 'Shortlists', id: listId },
                { type: 'Shortlist', id: listId },
            ],
        }),
        removeManyFromShortlist: builder.mutation({
            query: ({ listId, talentIds }) => ({
                url: `${talentServiceBaseUrl}/lists/${listId}/talent`,
                method: 'DELETE',
                body: { talentIds },
            }),
            invalidatesTags: (result, error, { listId }) => [
                { type: 'Compensation', id: listId },
                { type: 'Geography', id: listId },
                { type: 'Graduates', id: listId },
                { type: 'Competition', id: listId },
                { type: 'Shortlists', id: listId },
                { type: 'Shortlist', id: listId },
            ],
        }),
        getTalent: builder.query({
            query: ({ supportProfile, liUrl }) => ({
                method: 'GET',
                url: `${talentServiceBaseUrl}/talent/`,
                params: { supportProfile, liUrl },
            }),
        }),
        getTalentProfile: builder.query({
            query: (talentId) => `${talentServiceBaseUrl}/talent/${talentId}`,
        }),
        getTalentProfiles: builder.query({
            query: ({ talentId, params }) => ({
                method: 'GET',
                url: `${talentServiceBaseUrl}/talent/profiles/${talentId}`,
                params,
            }),
        }),
        getShortlistTalentsWithEmailStatus: builder.query({
            query: ({ shortlistId, page, pageSize, pool }) => {
                const params = { page, pageSize, pool };
                const searchString = generateRepeatingSearchQueryString(params);
                return {
                    url: `${aggregatorBaseUrl}/lists/${shortlistId}/talent/profiles/email-status?${searchString}`,
                    method: 'GET',
                };
            },
            providesTags: (result, error, { shortlistId }) => [
                { type: 'Shortlist', id: shortlistId },
                { type: 'Shortlist', id: 'LIST' },
            ],
        }),
        getShortlistTalentsForFeedbackList: builder.query({
            query: ({ shortlistId, page, pageSize, pool }) => {
                const params = { page, pageSize, pool };
                const searchString = generateRepeatingSearchQueryString(params);
                return {
                    url: `${aggregatorBaseUrl}/lists/${shortlistId}/feedback?${searchString}`,
                    method: 'GET',
                };
            },
            providesTags: (result, error, { shortlistId }) => [
                { type: 'Shortlist', id: shortlistId },
                { type: 'Shortlist', id: 'LIST' },
            ],
        }),
        getShortlistCompensation: builder.query({
            query: (shortlistId) => `${compensationBaseUrl}/talent/shortlist/${shortlistId}`,
            providesTags: (result, error, shortlistId) => [
                { type: 'Compensation', id: shortlistId },
            ],
        }),
        getShortlistGeography: builder.query({
            query: (shortlistId) => `${compensationBaseUrl}/role-density/shortlist/${shortlistId}`,
            providesTags: (result, error, shortlistId) => [{ type: 'Geography', id: shortlistId }],
        }),
        getShortlistGraduates: builder.query({
            query: (shortlistId) =>
                `${compensationBaseUrl}/role-graduates/shortlist/${shortlistId}`,
            providesTags: (result, error, shortlistId) => [{ type: 'Graduates', id: shortlistId }],
        }),
        getShortlistCompetition: builder.query({
            query: ({ shortlistId, duration, includeRecruiters }) => ({
                url: `${compensationBaseUrl}/role-posting/shortlist/${shortlistId}`,
                method: 'GET',
                params: {
                    duration,
                    topN: 60,
                    includeRecruiters,
                },
            }),

            providesTags: (result, error, { shortlistId }) => [
                { type: 'Competition', id: shortlistId },
            ],
        }),
        getManyTalents: builder.query({
            query: ({ talentIds }) => ({
                url: `${talentServiceBaseUrl}/talent/`,
                method: 'POST',
                body: { talentIds },
            }),
        }),
        getBulkTalentProfiles: builder.query({
            query: ({ params }) => ({
                url: `${talentServiceBaseUrl}/talent/profiles`,
                method: 'GET',
                params,
            }),
        }),
        getManyTalentsProfiles: builder.query({
            query: ({ talentIds, params }) => ({
                url: `${talentServiceBaseUrl}/talent/profiles`,
                method: 'POST',
                params,
                body: { talentIds },
            }),
        }),
        getOptoutMessages: builder.query({
            query: (params) => ({
                url: `${aggregatorBaseUrl}/talent/opt-out`,
                method: 'GET',
                params,
            }),
        }),
        reorderShortlist: builder.mutation({
            query: ({ talentIds, shortlistId }) => ({
                url: `${talentServiceBaseUrl}/lists/${shortlistId}/reorder`,
                method: 'PUT',
                body: { talentIds },
            }),
            invalidatesTags: (result, error, { shortlistId }) => [
                { type: 'Shortlists', id: shortlistId },
                { type: 'Shortlist', id: shortlistId },
            ],
        }),
        getAllTalentInShortlist: builder.query({
            query: (shortlistId) => ({
                url: `${talentServiceBaseUrl}/lists/${shortlistId}/talent`,
                method: 'GET',
            }),
            providesTags: (result, error, shortlistId) => [
                { type: 'Shortlists', id: shortlistId },
                { type: 'Shortlist', id: shortlistId },
            ],
        }),
        bulkDownloadTalentPdf: builder.mutation({
            query: ({ ids }) => ({
                url: `${talentDownloadBaseUrl}/talent`,
                method: 'POST',
                body: { ids },
            }),
        }),
    }),
});

export const {
    useGetShortlistsQuery,
    useLazyGetShortlistQuery,
    useLazyGetTalentQuery,
    useGetTalentProfileQuery,
    useGetBulkTalentProfilesQuery,
    useLazyGetTalentProfileQuery,
    useGetShortlistTalentsWithEmailStatusQuery,
    useLazyGetShortlistTalentsWithEmailStatusQuery,
    useGetShortlistTalentsForFeedbackListQuery,
    useEditShortlistMutation,
    useLazyGetShortlistsQuery,
    useAddToShortlistMutation,
    useDeleteShortlistMutation,
    useCreateShortlistMutation,
    useRemoveFromShortlistMutation,
    useAddManyToManyShortlistMutation,
    useRemoveManyFromShortlistMutation,
    useLazyGetShortlistsByTalentIdQuery,
    useGetShortlistCompensationQuery,
    useGetShortlistGeographyQuery,
    useGetShortlistGraduatesQuery,
    useLazyGetShortlistCompetitionQuery,
    useGetManyTalentsQuery,
    useLazyGetManyTalentsQuery,
    useGetOptoutMessagesQuery,
    useReorderShortlistMutation,
    useGetAllTalentInShortlistQuery,
    useBulkDownloadTalentPdfMutation,
    useLazyGetTalentProfilesQuery,
    useGetTalentProfilesQuery,
    useGetManyTalentsProfilesQuery,
    useLazyGetManyTalentsProfilesQuery,
} = talentApi;
