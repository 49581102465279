import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

import { SESSION_STORAGE } from 'utils/constants';
import { setContacts } from '../ContactSelectionSlice';
import { isContactDetailsPresent } from '../utils/isContactDetailsPresent';
import { useGetBulkUserContactSettingsQuery, useGetTalentContactsQuery } from 'services/contacts';
import { useChannelPolicies } from 'views/Communications/hooks/useChannelPolicies';
import { processContactData, processPolicyData } from 'features/MessageDrawer/utils';

export function useProcessedContacts({ profiles, messageType, shortlistId, skip }) {
    const talentIds = profiles?.map((profile) => profile.talentId);

    const [isDoneProcessing, setIsDoneProcessing] = useState(false);
    const [processedContacts, setProcessedContacts] = useState({});

    const dispatch = useDispatch();

    const { data: policyData } = useChannelPolicies({
        talentIds,
        skip: !Boolean(talentIds?.length),
    });

    // We want to disable re-fetching this query when the Contacts Component is opened in modal
    // This is because we will have all the data already set in the redux handler after the first
    const result = useGetTalentContactsQuery(talentIds, { skip });
    const { data, isFetching, isSuccess } = result || {};

    const settingsResults = useGetBulkUserContactSettingsQuery(talentIds, { skip });
    const { data: contactSettingsData, isSuccess: isSettingsRetrieved } = settingsResults;

    useEffect(() => {
        if (
            data &&
            isSuccess &&
            contactSettingsData &&
            isSettingsRetrieved &&
            policyData &&
            !isDoneProcessing
        ) {
            const processedContacts = processContactData(data, contactSettingsData, messageType);
            const processedPolicyData = processPolicyData(
                processedContacts || data,
                policyData,
                messageType
            );
            setProcessedContacts(processedPolicyData);
            setIsDoneProcessing(true);
        }
    }, [
        data,
        isSuccess,
        contactSettingsData,
        policyData,
        isSettingsRetrieved,
        isDoneProcessing,
        messageType,
    ]);

    useEffect(() => {
        if (talentIds && isDoneProcessing && processedContacts) {
            const contactProfiles = profiles.map((profile) => ({
                ...profile,
                ...processedContacts[profile.talentId],
                selected: isContactDetailsPresent(messageType, processedContacts[profile.talentId]),
            }));
            dispatch(setContacts(contactProfiles));
            const cache = { contacts: contactProfiles, messageType, shortlistId };
            sessionStorage.setItem(SESSION_STORAGE.CONTACT_VERIFICATION, JSON.stringify(cache));
        }
    }, [
        talentIds,
        isSuccess,
        isDoneProcessing,
        profiles,
        dispatch,
        messageType,
        shortlistId,
        processedContacts,
    ]);

    return {
        processedContacts,
        isFetching,
        policyData,
    };
}
