import SvgIcon from '@mui/material/SvgIcon';

export function FacebookIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 25" {...props}>
            <g clipPath="url(#clip0_17343_58983)">
                <path
                    d="M24 12.5156C24 5.88825 18.6274 0.515625 12 0.515625C5.37262 0.515625 0 5.88816 0 12.5156C0 18.5051 4.38825 23.4697 10.125 24.3698V15.9844H7.07812V12.5156H10.125V9.87188C10.125 6.86438 11.9166 5.20312 14.6575 5.20312C15.9705 5.20312 17.3438 5.4375 17.3438 5.4375V8.39062H15.8306C14.3398 8.39062 13.875 9.31566 13.875 10.2647V12.5156H17.2031L16.6711 15.9844H13.875V24.3698C19.6117 23.4697 24 18.5052 24 12.5156Z"
                    fill="#1877F2"
                />
                <path
                    d="M16.6711 15.9844L17.2031 12.5156H13.875V10.2647C13.875 9.31556 14.3399 8.39062 15.8306 8.39062H17.3438V5.4375C17.3438 5.4375 15.9705 5.20312 14.6575 5.20312C11.9166 5.20312 10.125 6.86438 10.125 9.87188V12.5156H7.07812V15.9844H10.125V24.3698C10.7453 24.467 11.3722 24.5158 12 24.5156C12.6278 24.5158 13.2547 24.4671 13.875 24.3698V15.9844H16.6711Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_17343_58983">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.515625)" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
}
