import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import { FormattingIcon, CloseIcon } from 'components/Icons';

const StyledIconButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'disableShadow',
})(({ theme, disableShadow }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    boxShadow: disableShadow
        ? 'inherit'
        : '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    '&.Mui-disabled': { opacity: 0.32 },
}));

export function FormattingButton(props) {
    const { isFormatting, onToggleFormatting, disableShadow, ...restProps } = props;

    return (
        <StyledIconButton
            onClick={onToggleFormatting}
            disableShadow={disableShadow}
            aria-label="Formatting options"
            data-testid="formatting-button"
            {...restProps}
        >
            {!isFormatting && (
                <FormattingIcon
                    sx={{ fontSize: 14 }}
                    data-testid="formatting-button-formatting-icon"
                />
            )}
            {isFormatting && (
                <CloseIcon
                    data-testid="formatting-button-close-icon"
                    sx={{ fontSize: 14, color: '#504CE0' }}
                />
            )}
        </StyledIconButton>
    );
}

FormattingButton.propTypes = {
    disableShadow: PropTypes.bool,
    isFormatting: PropTypes.bool,
    onToggleFormatting: PropTypes.func,
};
