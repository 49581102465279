import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

export const LargeLogo = styled('img')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'block',
    },
}));

export const SmallLogo = styled('img')(({ theme }) => ({
    display: 'block',
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
}));

export const LogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(4, 0),
}));

export function UserInfoLogo() {
    const largeLogoSrc = '/assets/logos/Nebula-color-horizonal.png';
    const smallLogoSrc = '/assets/logos/Nebula-social-256.png';

    return (
        <LogoContainer>
            <LargeLogo src={largeLogoSrc} alt="large-nebula-logo" />
            <SmallLogo src={smallLogoSrc} alt="small-nebula-logo" />
        </LogoContainer>
    );
}
