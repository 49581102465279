import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import Box from '@mui/system/Box';
import MenuItem from '@mui/material/MenuItem';

import { Typography } from 'components/Typography';

const handleInsert = (editor, text, href, linkType) => {
    const link = {
        type: 'link',
        href,
        linkType,
        children: [{ text }],
    };
    editor.insertNode(link);
};

export function MyInvitationLinks(props) {
    const { data, onClick } = props;

    const editor = useSlate();

    return (
        <Box>
            <Typography
                variant="labelXSmall"
                sx={{ color: 'rgba(38, 55, 70, 0.72)', pl: 2, pt: 1 }}
            >
                Your links
            </Typography>
            {data?.results?.map(({ id, pageTitle }) => (
                <MenuItem
                    key={id}
                    aria-label={id}
                    onClick={() => {
                        handleInsert(editor, pageTitle, `{% schedule.${id} %}`, 'invitation');
                        onClick?.();
                    }}
                    disableRipple
                >
                    {pageTitle}
                </MenuItem>
            ))}
        </Box>
    );
}

MyInvitationLinks.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
};
