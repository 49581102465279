import { useMemo } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import { ENGAGEMENT_ACTIVITY_SUBTYPE } from 'utils';
import { capitalize } from 'lodash';
import { formatDate } from 'utils/formatters';
import { ENTITY_TYPE_ENUMS } from 'utils/entityEnums';

const applyOrigin = (baseString, connectingString, origin) => {
    if (!origin) {
        return baseString;
    }
    return `${baseString} ${connectingString} ${origin}`;
};

export function EngagementContent({ activitySubType, metadata, entityConnections }) {
    const activityOrigin = useMemo(() => {
        const origin = find(entityConnections, (entity) => {
            if (entity.type === ENTITY_TYPE_ENUMS.SEQUENCE.label) {
                return true;
            }
            if (entity.type === ENTITY_TYPE_ENUMS.TALKTRACK.label) {
                return true;
            }
            return null;
        });

        return origin?.type ? capitalize(origin.type) : '';
    }, [entityConnections]);

    const activityDate = metadata?.sendTime ? formatDate(metadata.sendTime, 'P') : '';
    const activityReason = metadata?.failureReason || '';
    const messageType = metadata?.messageType || '';
    const phoneNumber = metadata?.phoneNumber || '';

    const label = useMemo(() => {
        switch (activitySubType) {
            case ENGAGEMENT_ACTIVITY_SUBTYPE.INITIAL_EMAIL_MESSAGE_SENT:
                return <>Started engagement via Email</>;
            case ENGAGEMENT_ACTIVITY_SUBTYPE.INITIAL_SMS_MESSAGE_SENT:
                return <>Started engagement via SMS</>;
            case ENGAGEMENT_ACTIVITY_SUBTYPE.ENGAGEMENT_MESSAGE_SENT:
                return (
                    <>{applyOrigin(`Sent ${capitalize(messageType)}`, 'from', activityOrigin)}</>
                );
            case ENGAGEMENT_ACTIVITY_SUBTYPE.ENGAGEMENT_EMAIL_REPLY_SENT:
                return <>{applyOrigin('Responded via Email', 'from', activityOrigin)}</>;
            case ENGAGEMENT_ACTIVITY_SUBTYPE.ENGAGEMENT_SMS_REPLY_SENT:
                return <>{applyOrigin('Responded via SMS', 'from', activityOrigin)}</>;
            case ENGAGEMENT_ACTIVITY_SUBTYPE.ENGAGEMENT_EMAIL_REPLY_RECEIVED:
                return <>{applyOrigin('Replied via Email', 'from', activityOrigin)}</>;
            case ENGAGEMENT_ACTIVITY_SUBTYPE.ENGAGEMENT_SMS_REPLY_RECEIVED:
                return <>{applyOrigin('Replied via SMS', 'from', activityOrigin)}</>;
            case ENGAGEMENT_ACTIVITY_SUBTYPE.MESSAGE_SCHEDULED:
                return (
                    <>
                        {applyOrigin(
                            `Scheduled message to send for ${activityDate}`,
                            'via',
                            activityOrigin
                        )}
                    </>
                );
            case ENGAGEMENT_ACTIVITY_SUBTYPE.SMS_OPT_OUT:
                return <>Opted out from receiving SMS ({phoneNumber})</>;
            case ENGAGEMENT_ACTIVITY_SUBTYPE.ENROLLED_TALENT:
                return <>Enrolled in {activityOrigin}</>;
            case ENGAGEMENT_ACTIVITY_SUBTYPE.MESSAGE_FAILED:
                return (
                    <>
                        {applyOrigin(`Failed to send ${messageType}`, 'from', activityOrigin)} due
                        to <b>{activityReason}</b>
                    </>
                );
            default:
                return <></>;
        }
    }, [activitySubType, activityOrigin, activityDate, activityReason, messageType, phoneNumber]);

    return <>{label}</>;
}

EngagementContent.propTypes = {
    activitySubType: PropTypes.string,
    metadata: PropTypes.object,
    entityConnections: PropTypes.array,
};
