import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';

import { USER_NAVIGATION } from 'views/UserSettings/utils/constants';

export function UserSettingsButton(props) {
    const { onClose, openInNewTab } = props;
    return (
        <MenuItem
            aria-label="user-setting-button"
            component={Link}
            onClick={onClose}
            to={`/user-settings#${USER_NAVIGATION.SCHEDULES.navId}`}
            target={openInNewTab ? '_blank' : undefined}
            disableRipple
        >
            Create New Link
        </MenuItem>
    );
}

UserSettingsButton.propTypes = {
    onClose: PropTypes.func,
    openInNewTab: PropTypes.bool,
};
