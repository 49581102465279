import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { Typography } from 'components/Typography';
import { useTalentConversations, useTalentData } from 'features/MessageDrawer/hooks';
import { MessageHistoryContent } from './MessageHistoryComponents/MessageHistoryContent';

export function MessageHistory(props) {
    const { selectedProfiles } = useTalentData();
    const { conversations, isSuccess, isError } = useTalentConversations();

    if (
        (isSuccess || isError) &&
        conversations &&
        (conversations?.length === 0 || conversations[0].messages.length === 0)
    ) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" rowGap="16px">
            <Divider sx={{ borderColor: 'rgba(38, 55, 70, 0.12)' }} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '8px',
                }}
            >
                {selectedProfiles.length === 1 && (
                    <Typography variant="labelSmall" lineHeight={14}>
                        History
                    </Typography>
                )}
                <MessageHistoryContent conversations={conversations} />
            </Box>
            <Divider sx={{ borderColor: 'rgba(38, 55, 70, 0.12)' }} />
        </Box>
    );
}

MessageHistory.propTypes = {
    onClose: PropTypes.func,
};
