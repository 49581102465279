import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';

import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';
import { useGetFeedbackListsMetadataQuery } from 'services/feedback';

const noFeedbackListsTooltip =
    'You have no feedback requests. When a user requests your feedback on a list of candidates, the list will appear here.';

const NewNotice = styled('div')(({ theme }) => ({
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(4.5),
    height: theme.spacing(2.5),
}));

export function ShortlistsMenu(props) {
    const { menuOptions, onSelect } = props;

    const { data, isLoading, isError, isUninitialized } = useGetFeedbackListsMetadataQuery();
    const hasNoFeedbackLists = isError || !data?.hasLists;

    return menuOptions.map((menuOption) => {
        const isFeedbackListLink = menuOption.to === 'shortlist-feedback';
        if (!isFeedbackListLink) {
            return (
                <MenuItem
                    key={menuOption.to}
                    component={Link}
                    to={menuOption.to}
                    state={menuOption.state}
                    disableRipple
                    onClick={onSelect}
                    disabled={isFeedbackListLink}
                    data-trackid="shortlist-menu-your-shortlists"
                >
                    <Typography variant="titleSmall">{menuOption.label}</Typography>
                </MenuItem>
            );
        }

        return (
            <Tooltip
                title={
                    isError
                        ? 'Feedback lists failed to load. PLease try again in a few minutes.'
                        : noFeedbackListsTooltip
                }
                key={menuOption.to}
                placement="bottom"
                disableFocusListener={!hasNoFeedbackLists}
                disableHoverListener={!hasNoFeedbackLists}
                disableTouchListener={!hasNoFeedbackLists}
            >
                <span>
                    <MenuItem
                        component={Link}
                        to={menuOption.to}
                        state={menuOption.state}
                        disableRipple
                        onClick={onSelect}
                        disabled={isUninitialized || isLoading || hasNoFeedbackLists}
                        data-trackid="shortlist-menu-feedback-requests"
                    >
                        <Typography variant="titleSmall">{menuOption.label}</Typography>
                        {data?.hasNew && (
                            <NewNotice sx={{ ml: 'auto', mr: -2 }}>
                                <Typography variant="bodySmall" sx={{ m: 'auto' }}>
                                    New!
                                </Typography>
                            </NewNotice>
                        )}
                    </MenuItem>
                </span>
            </Tooltip>
        );
    });
}

ShortlistsMenu.propTypes = {
    menuOptions: PropTypes.array,
    onSelect: PropTypes.func,
};
