import SvgIcon from '@mui/material/SvgIcon';

export function NotesIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 17" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0618 0.800049H5.6C3.1289 0.800049 1.7775 2.78137 1.6 5.00005H0.8C0.36 5.00005 0 5.36005 0 5.80005C0 6.24005 0.36 6.60005 0.8 6.60005H1.6V8.20005H0.8C0.36 8.20005 0 8.56005 0 9.00005C0 9.44005 0.36 9.80005 0.8 9.80005H1.6V11.4H0.8C0.36 11.4 0 11.76 0 12.2C0 12.64 0.36 13 0.8 13H1.6C1.92589 15.0367 3.41376 16.7 5.6 16.7H12C14.208 16.7 16 14.908 16 12.7V10.3278C16 9.61206 15.1321 9.2565 14.6299 9.76651C14.4826 9.91617 14.4 10.1178 14.4 10.3278V12.7C14.4 14.02 13.32 15.1 12 15.1H10.392H5.59199C4.28805 15.1 3.52085 14.1418 3.19199 13H4.06799H4.79199C5.23199 13 5.59199 12.64 5.59199 12.2C5.59199 11.76 5.23199 11.4 4.79199 11.4H3.19199V9.80005H4.79199C5.23199 9.80005 5.59199 9.44005 5.59199 9.00005C5.59199 8.56005 5.23199 8.20005 4.79199 8.20005H3.19199V6.60005H4.79199C5.23199 6.60005 5.59199 6.24005 5.59199 5.80005C5.59199 5.36005 5.23199 5.00005 4.79199 5.00005H3.19199C3.38589 3.65372 3.985 2.40005 5.59199 2.40005H10.0618C10.2733 2.40005 10.4761 2.31631 10.6261 2.16714C11.132 1.66375 10.7755 0.800049 10.0618 0.800049ZM14.8729 3.45145L15.1647 3.74329V3.7356C15.6793 4.25014 15.925 4.63412 15.9865 5.02578C16.0709 5.59408 15.7254 5.94736 15.5718 6.10096L10.2804 11.3999C10.05 11.6303 9.73518 11.7686 9.38192 11.7993L7.73844 11.9375H7.65398C7.42358 11.9375 7.19316 11.8377 7.00117 11.6457C6.77846 11.423 6.67863 11.1542 6.71703 10.8777L6.84757 9.21891C6.87061 8.881 7.0012 8.5815 7.22391 8.35879L12.4999 3.05209C12.8301 2.72187 13.2141 2.59131 13.6365 2.66811C14.0205 2.74491 14.4044 2.98299 14.8729 3.45145ZM8.29905 10.3555L9.236 10.2787V10.2863L14.3661 5.14863C14.3123 5.07184 14.2201 4.97202 14.0819 4.83379L13.7901 4.54196C13.6518 4.39604 13.5443 4.31157 13.4752 4.25781L8.37587 9.38784L8.29905 10.3555Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
