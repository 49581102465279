import PropTypes from 'prop-types';
import { NewMultipleMediaUpload } from './NewMultipleMediaUpload';
import { NewSingleMediaUpload } from './NewSingleMediaUpload';
import Box from '@mui/material/Box';
import { useTagSuggestions } from './hooks/useTagSuggestions';

export function NewMediaUpload({
    attachments,
    onRemoveUploadMedia,
    onReplaceAttachment,
    displayNames,
    onDisplayNameChange,
    onTagChange,
    multiTagsValue,
    tagOptions,
    onTagInputValue,
    onTagInputEnter,
    setAttachments,
}) {
    const { attachmentsWithSuggestedTags, onSuggestedTagClick } = useTagSuggestions({
        attachments,
        setAttachments,
        handleTagChange: onTagChange,
        multiTagsValue,
    });

    return (
        <Box>
            {attachments?.length && attachments?.length === 1 && (
                <NewSingleMediaUpload
                    displayName={displayNames[attachments[0]?.handle]}
                    onDisplayNameChange={onDisplayNameChange}
                    onTagChange={onTagChange}
                    tags={multiTagsValue[attachments[0]?.handle]}
                    attachment={attachmentsWithSuggestedTags[0]}
                    tagOptions={tagOptions}
                    onTagInputValue={onTagInputValue}
                    onTagInputEnter={onTagInputEnter}
                    onReplaceAttachment={onReplaceAttachment}
                    onSuggestedTagClick={onSuggestedTagClick}
                />
            )}

            {attachments?.length > 1 && (
                <NewMultipleMediaUpload
                    displayNames={displayNames}
                    onDisplayNameChange={onDisplayNameChange}
                    onTagChange={onTagChange}
                    multiTagsValue={multiTagsValue}
                    attachments={attachmentsWithSuggestedTags}
                    tagOptions={tagOptions}
                    onTagInputValue={onTagInputValue}
                    onTagInputEnter={onTagInputEnter}
                    onRemoveUploadMedia={onRemoveUploadMedia}
                    onSuggestedTagClick={onSuggestedTagClick}
                />
            )}
        </Box>
    );
}

NewMediaUpload.propTypes = {
    attachments: PropTypes.array,
    onClose: PropTypes.func,
    onRemoveUploadMedia: PropTypes.func,
    onReplaceAttachment: PropTypes.func,
    displayNames: PropTypes.object,
    onDisplayNameChange: PropTypes.func,
    onTagChange: PropTypes.func,
    multiTagsValue: PropTypes.object,
    tagOptions: PropTypes.object,
    onTagInputValue: PropTypes.func,
    onTagInputEnter: PropTypes.func,
    onSuggestedTagClick: PropTypes.func,
    setAttachments: PropTypes.func,
};
