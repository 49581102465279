import SvgIcon from '@mui/material/SvgIcon';

export function UserCircleIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM4.95999 16.2C4.96999 16.13 5 16.07 5 16C5 14.9 5.9 14 7 14H13C14.1 14 15 14.9 15 16C15 16.07 15.03 16.14 15.04 16.2C13.66 17.32 11.91 18 10 18C8.09 18 6.33999 17.33 4.95999 16.2ZM16.67 14.41C16.05 12.99 14.64 12 13 12H7C5.36 12 3.94999 12.99 3.32999 14.41C2.48999 13.14 2 11.63 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 11.63 17.51 13.15 16.67 14.41Z"
                fill="currentColor"
            />
            <path
                d="M10 3C7.79 3 6 4.79 6 7C6 9.21 7.79 11 10 11C12.21 11 14 9.21 14 7C14 4.79 12.21 3 10 3ZM10 9C8.9 9 8 8.1 8 7C8 5.9 8.9 5 10 5C11.1 5 12 5.9 12 7C12 8.1 11.1 9 10 9Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
