export const STATUS_MAP = {
    PENDING: 'pending',
    SENT: 'sent',
    FAILED: 'failed',
    OPENED: 'opened',
    LINK_ACCESSED: 'link_accessed',
    REPLIED: 'replied',
};

export const STATUS_ORIGIN_ENUMS = {
    USER: 'user',
    ORGANIZATION: 'organization',
};

export const CONTACT_TYPE_LABEL_MAP = {
    HOME: 'Home',
    WORK: 'Work',
    OTHER: 'Misc',
};

export const CONTACT_STATUS_MAP = {
    UNKNOWN: 'UNKNOWN',
    VERIFIED: 'VERIFIED',
    INVALID: 'INVALID',
    OPT_OUT: 'OPT-OUT',
    REPORTED: 'REPORTED',
};

export const CONTACT_MEDIUM = {
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
};

export const CONTACT_TYPES = {
    ...CONTACT_MEDIUM,
    SOCIAL: 'SOCIAL',
};

export const SOCIAL_NETWORKS = {
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
    TWITTER: 'twitter',
    PINTEREST: 'pinterest',
    LINKEDIN: 'linkedin',
};

export const CONTACT_MEDIUMS = {
    EMAIL: {
        label: 'email',
        value: 'EMAIL',
    },
    PHONE: {
        label: 'phone',
        value: 'PHONE',
    },
};
