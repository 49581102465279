import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

export function LoadingTab(props) {
    const { count = 4 } = props;
    return (
        <Box>
            <Stack spacing={2}>
                {[...new Array(count)].map((_, idx) => (
                    <Skeleton variant="rounded" key={idx} height={66} />
                ))}
            </Stack>
        </Box>
    );
}

LoadingTab.propTypes = {
    count: PropTypes.number,
};
