import { useState, useEffect } from 'react';

export const usePhoneInput = (isPhoneInput, defaultValue = '') => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        if (isPhoneInput) {
            setValue(formatPhoneNumber(defaultValue));
        }
    }, [defaultValue, isPhoneInput]);

    const formatPhoneNumber = (phoneNumber) => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            cleaned = `${match[1]}${match[2] ? '-' : ''}${match[2]}${match[3] ? '-' : ''}${
                match[3]
            }`;
        }
        return cleaned;
    };

    const onChange = (e) => {
        if (isPhoneInput) {
            setValue(formatPhoneNumber(e.target.value));
        } else {
            setValue(e.target.value);
        }
    };

    return {
        value,
        onChange,
    };
};
