import { useState } from 'react';

export function usePaginationSort() {
    const [page, setPage] = useState(0);
    const [sortModel, setSortModel] = useState([]);
    const [filterModel, setFilterModel] = useState({});

    return {
        page,
        setPage,
        sortModel,
        filterModel,
        setSortModel,
        setFilterModel,
    };
}
