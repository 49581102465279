import PropTypes from 'prop-types';

import { MESSAGE_TYPES } from 'utils/constants';
import { formatPhoneNumber } from 'utils/formatters';
import { MessageLabelTypography } from './MessageLabelTypography.styled';

const formatContact = (type, contact) => {
    return type === MESSAGE_TYPES.EMAIL ? contact : formatPhoneNumber(contact);
};

export function MessageContactSentDetails({ recipient, sender, messageType }) {
    return (
        <>
            <MessageLabelTypography component="div" variant="labelXSmall">
                Sent to {formatContact(messageType, recipient)}
            </MessageLabelTypography>
            <MessageLabelTypography component="div" variant="labelXSmall">
                from {formatContact(messageType, sender)}
            </MessageLabelTypography>
        </>
    );
}

MessageContactSentDetails.propTypes = {
    recipient: PropTypes.string,
    sender: PropTypes.string,
    messageType: PropTypes.string,
};
