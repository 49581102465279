import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { CloseIcon } from 'components/Icons';
import { Typography } from 'components/Typography';
import { useSwitchboard } from 'features/Switchboard';
import { EarnCreditDialog } from '../EarnCreditDialog';
import { useUpgradeAccountPrompt } from './hooks/useUpgradeAccountPrompt';
import {
    DialogContentText,
    DialogActions,
    DialogContent,
    DialogButtonSecondary,
} from 'components/Dialog/DialogComponents';
import { PricingTable } from 'features/Stripe/PricingTable';
import UpgradeAccountTable from './UpgradeAccountTable';

const logoSrc = '/assets/logos/Nebula-social-256.png';
const upgradeSuccessImage = '/assets/imgs/upgrade-success.png';

const Logo = styled('img')(({ theme }) => ({
    height: theme.spacing(7.5),
    width: theme.spacing(15),
    display: 'block',
    margin: theme.spacing(4, 'auto'),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        padding: theme.spacing(3, 6),
        maxWidth: theme.spacing(68),
    },
}));

const StyledImageContainer = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(2),
}));

const StyledDialogTitle = styled(Typography)(() => ({
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: 0.1,
}));

const StyledSuccessImage = styled('img')(() => ({
    maxWidth: '100%',
    height: 'auto',
}));

const StyledUpgradeButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'hideButton',
})(({ theme, hideButton }) => ({
    display: hideButton ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0, 'auto'),
    background: theme.palette.success.main,
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: theme.spacing(1),
    color: 'white',
    padding: theme.spacing(2, 4),
    '&.MuiButton-root:hover': {
        backgroundColor: theme.palette.success.main,
    },
}));

const CloseButtonWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '12px',
    right: '12px',
    marginRight: theme.spacing(1),
}));

const EarnCreditButton = styled(DialogButtonSecondary)(({ theme }) => ({
    minWidth: theme.spacing(42),
    marginTop: theme.spacing(2),
    borderColor: 'transparent',
    '&:not(:first-of-type)': {
        marginLeft: 0,
    },
    '& > .MuiTypography-root': {
        fontWeight: 700,
        fontSize: 16,
        color: theme.palette.primary.main,
    },
}));

export function UpgradeAccountPrompt() {
    const isSmsEnabled = useSwitchboard('FEATURE_SMS_CAPABILITY');
    const isPaid = useSelector((state) => state.user?.isPaid);

    const {
        view,
        openModal,
        closeModal,
        requestUpgrade,
        isLoading,
        isModalOpen,
        stripeConfig,
        setStripeConfig,
    } = useUpgradeAccountPrompt();

    const handleEarnCreditOpen = () => openModal('earnCredit');
    const handleUpgradeClose = () => {
        setStripeConfig(null);
        closeModal();
    };

    const switchToPreviousModal = () => {
        closeModal();
        openModal('upgrade');
    };

    return (
        <StyledDialog
            open={isModalOpen}
            onClose={closeModal}
            aria-describedby="alert-dialog-slide-description"
        >
            {view === 'upgrade' && (
                <>
                    <CloseButtonWrapper>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => handleUpgradeClose()}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </CloseButtonWrapper>
                    <DialogContent sx={{ px: 3, py: 0 }}>
                        <StyledDialogTitle sx={{ py: 2 }} id="alert-dialog-slide-description">
                            Upgrade your account now to access this functionality.
                        </StyledDialogTitle>
                        {!(isSmsEnabled && isPaid) && (
                            <StyledImageContainer>
                                {stripeConfig ? (
                                    <PricingTable {...stripeConfig} />
                                ) : (
                                    <UpgradeAccountTable handleRequestUpgrade={requestUpgrade} />
                                )}
                            </StyledImageContainer>
                        )}
                    </DialogContent>
                    <DialogActions sx={{ flexWrap: 'wrap' }}>
                        <StyledUpgradeButton
                            disabled={isLoading}
                            hideButton={!!stripeConfig}
                            aria-label="accept-upgrade"
                            fullWidth
                            onClick={() => requestUpgrade()}
                        >
                            {isLoading && (
                                <CircularProgress
                                    color="inherit"
                                    size={10}
                                    sx={{ marginRight: '5px', marginTop: '3px' }}
                                />
                            )}
                            &nbsp;Upgrade Your Account
                        </StyledUpgradeButton>
                        <EarnCreditButton
                            disabled={isLoading}
                            aria-label="earn-additional-credits"
                            data-trackid="earn-additional-credits"
                            onClick={() => {
                                handleEarnCreditOpen();
                            }}
                        >
                            <Typography variant="titleSmall">Earn additional credits</Typography>
                        </EarnCreditButton>
                    </DialogActions>
                </>
            )}

            <EarnCreditDialog
                open={view === 'earnCredit'}
                openModal={openModal}
                closeModal={switchToPreviousModal}
            />

            {view === 'success' && (
                <Box>
                    <Logo src={logoSrc} alt="nebula-logo" />
                    <CloseButtonWrapper>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={closeModal}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </CloseButtonWrapper>

                    <DialogContent sx={{ px: 2, py: 0 }}>
                        <StyledDialogTitle sx={{ color: 'text.primary', pb: 3 }}>
                            We&apos;ve received your upgrade request!
                        </StyledDialogTitle>

                        {!(isSmsEnabled && isPaid) && (
                            <StyledImageContainer>
                                <StyledSuccessImage
                                    src={upgradeSuccessImage}
                                    alt="upgrade-success"
                                />
                            </StyledImageContainer>
                        )}
                        <DialogContentText id="alert-dialog-slide-description">
                            Our customer success team will be in touch shortly using the email
                            associated with your account.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            data-testid="close-success"
                            onClick={closeModal}
                            disableRipple
                            sx={{
                                margin: '0 auto',
                                color: 'text.primary',
                                textTransform: 'none',
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Box>
            )}
        </StyledDialog>
    );
}
