import { jsx } from 'slate-hyperscript';

const getJsxStyle = (el) => {
    return el.getAttribute('style') ? parseStyleAttribute(el.getAttribute('style')) : null;
};

const getElementProps = (el) => ({
    style: getJsxStyle(el),
    domStyle: el.getAttribute('style'),
    id: el.getAttribute('id'),
});

const ELEMENT_TAGS = {
    A: (el) => ({ type: 'link', href: el.getAttribute('href'), ...getElementProps(el) }),
    BLOCKQUOTE: (el) => ({ type: 'block-quote', ...getElementProps(el) }),
    H1: (el) => ({ type: 'heading-one', ...getElementProps(el) }),
    H2: (el) => ({ type: 'heading-two', ...getElementProps(el) }),
    H3: (el) => ({ type: 'heading-three', ...getElementProps(el) }),
    H4: (el) => ({ type: 'heading-four', ...getElementProps(el) }),
    H5: (el) => ({ type: 'heading-five', ...getElementProps(el) }),
    H6: (el) => ({ type: 'heading-six', ...getElementProps(el) }),
    IMG: (el) => ({ type: 'image', ...getElementProps(el) }),
    LI: (el) => ({ type: 'list-item', ...getElementProps(el) }),
    OL: (el) => ({ type: 'numbered-list', ...getElementProps(el) }),
    P: (el) => ({ type: 'paragraph', ...getElementProps(el) }),
    PRE: (el) => ({ type: 'code', ...getElementProps(el) }),
    UL: (el) => ({ type: 'bulleted-list', ...getElementProps(el) }),
    DIV: (el) => ({ type: 'div', ...getElementProps(el) }),
    BR: () => ({ type: 'break' }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
};

const deserialize = (el) => {
    if (el.nodeType === 3) return el.textContent;
    else if (el.nodeType !== 1) return null;

    const { nodeName } = el;
    const nodes = el.childNodes[0];
    let parent = nodeName === 'PRE' && nodes && nodes.nodeName === 'CODE' ? nodes : el;

    let children = Array.from(parent.childNodes).map(deserialize).flat();

    if (children.length === 0) {
        children = [{ text: '' }];
    }

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children);
    }

    if (ELEMENT_TAGS[nodeName]) {
        const attrs = ELEMENT_TAGS[nodeName](el);
        return jsx('element', attrs, children);
    }

    if (TEXT_TAGS[nodeName]) {
        const attrs = TEXT_TAGS[nodeName](el);
        return children.map((child) => jsx('text', attrs, child));
    }
};

const parseStyleAttribute = (styleAttribute) => {
    return styleAttribute
        .split(';')
        .map((styleDeclaration) => styleDeclaration.trim())
        .filter((styleDeclaration) => styleDeclaration.length > 0)
        .reduce((styleObject, styleDeclaration) => {
            const [property, value] = styleDeclaration.split(':').map((s) => s.trim());
            const formattedProperty = property
                .trim()
                .replace(/-([a-z])/g, (g) => g[1].toUpperCase());
            styleObject[formattedProperty] = value;
            return styleObject;
        }, {});
};

export { deserialize };
