import SvgIcon from '@mui/material/SvgIcon';

export function PastIndustryIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 1.73354C0 0.865746 0.632431 0.00292969 1.6 0.00292969H3.42857C4.39614 0.00292969 5.02857 0.865746 5.02857 1.73354V5.45026L8.45783 3.34052C8.93447 3.04773 9.47912 3.10139 9.87982 3.37382C10.2717 3.64026 10.5143 4.09969 10.5143 4.61354V5.38736C10.0371 5.45165 9.57764 5.57204 9.14286 5.74167V4.61354C9.14286 4.5788 9.13641 4.55431 9.12912 4.53772L3.65714 7.90417V1.73354C3.65714 1.60527 3.61179 1.50588 3.5593 1.4459C3.50811 1.3874 3.46057 1.37436 3.42857 1.37436H1.6C1.568 1.37436 1.52046 1.3874 1.46927 1.4459C1.41678 1.50588 1.37143 1.60527 1.37143 1.73354V13.7499C1.37143 14.3253 1.76185 14.6315 2.05714 14.6315H6.30992C6.65212 15.1506 7.07276 15.6133 7.55498 16.0029H2.05714C0.838145 16.0029 0 14.905 0 13.7499V1.73354ZM11.3229 5.33333C11.0928 5.33333 10.8657 5.34629 10.6423 5.37151L13.9435 3.34052C14.4202 3.04773 14.9648 3.10139 15.3655 3.37382C15.7574 3.64026 16 4.09969 16 4.61354V7.57469C15.6107 7.0909 15.1479 6.66878 14.6286 6.32528V4.61354C14.6286 4.5788 14.6221 4.55431 14.6148 4.53772L12.956 5.55826C12.4368 5.41173 11.889 5.33333 11.3229 5.33333ZM11.3229 7.66667C9.30387 7.66667 7.65625 9.31428 7.65625 11.3333C7.65625 13.3524 9.30387 15 11.3229 15C13.342 15 14.9896 13.3524 14.9896 11.3333C14.9896 9.31428 13.342 7.66667 11.3229 7.66667ZM6.65625 11.3333C6.65625 8.762 8.75158 6.66667 11.3229 6.66667C13.8942 6.66667 15.9896 8.762 15.9896 11.3333C15.9896 13.9047 13.8942 16 11.3229 16C8.75158 16 6.65625 13.9047 6.65625 11.3333ZM11.3582 9.24618C11.6343 9.24618 11.8582 9.47003 11.8582 9.74618V11.1118L13.021 10.9903C13.2956 10.9616 13.5415 11.161 13.5702 11.4356C13.5989 11.7103 13.3995 11.9562 13.1249 11.9849L11.4101 12.164C11.2692 12.1788 11.1287 12.133 11.0235 12.0382C10.9182 11.9434 10.8582 11.8084 10.8582 11.6667V9.74618C10.8582 9.47003 11.082 9.24618 11.3582 9.24618Z"
                fill="currentColor"
                fillOpacity="0.72"
            />
        </SvgIcon>
    );
}
