export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const sxProp = {
    flex: {
        display: 'flex',
    },
    activeBtn: {
        color: (theme) => theme.palette.grey[700],
    },
    inactiveBtn: {
        color: (theme) => theme.palette.grey[500],
    },
};
