import SvgIcon from '@mui/material/SvgIcon';

export function KeyIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M11.25 5.66683C11.4931 5.66683 11.7263 5.57025 11.8982 5.39834C12.0701 5.22644 12.1667 4.99328 12.1667 4.75016C12.1667 4.50705 12.0701 4.27389 11.8982 4.10198C11.7263 3.93007 11.4931 3.8335 11.25 3.8335C11.0069 3.8335 10.7737 3.93007 10.6018 4.10198C10.4299 4.27389 10.3333 4.50705 10.3333 4.75016C10.3333 4.99328 10.4299 5.22644 10.6018 5.39834C10.7737 5.57025 11.0069 5.66683 11.25 5.66683Z"
                fill="currentColor"
            />
            <path
                d="M8.04167 11.1667V10.7083H8.95833C9.07989 10.7083 9.19647 10.66 9.28242 10.5741C9.36838 10.4881 9.41667 10.3716 9.41667 10.25V9.33333H10.3333C11.1033 9.33315 11.8536 9.09061 12.478 8.64009C13.1024 8.18956 13.5691 7.55393 13.8119 6.8233C14.0548 6.09267 14.0615 5.30412 13.8311 4.56946C13.6007 3.8348 13.1449 3.1913 12.5283 2.73022C11.9117 2.26913 11.1656 2.01384 10.3958 2.00055C9.62595 1.98726 8.87147 2.21664 8.23932 2.65617C7.60716 3.0957 7.1294 3.72307 6.87379 4.44934C6.61817 5.17561 6.59766 5.96392 6.81517 6.7025L3.26767 10.25C3.09608 10.422 2.99981 10.6551 3 10.8981V12.0833C3 12.3264 3.09658 12.5596 3.26849 12.7315C3.44039 12.9034 3.67355 13 3.91667 13H5.75C5.99312 13 6.22627 12.9034 6.39818 12.7315C6.57009 12.5596 6.66667 12.3264 6.66667 12.0833V11.625H7.58333C7.70489 11.625 7.82147 11.5767 7.90742 11.4908C7.99338 11.4048 8.04167 11.2882 8.04167 11.1667ZM7.58333 5.66667C7.58333 5.12277 7.74462 4.59108 8.04679 4.13885C8.34897 3.68661 8.77846 3.33414 9.28095 3.126C9.78345 2.91786 10.3364 2.8634 10.8698 2.96951C11.4033 3.07562 11.8933 3.33753 12.2779 3.72212C12.6625 4.10672 12.9244 4.59672 13.0305 5.13017C13.1366 5.66362 13.0821 6.21655 12.874 6.71905C12.6659 7.22154 12.3134 7.65103 11.8612 7.95321C11.4089 8.25538 10.8772 8.41667 10.3333 8.41667H8.95833C8.83678 8.41667 8.7202 8.46496 8.63424 8.55091C8.54829 8.63686 8.5 8.75344 8.5 8.875V9.79167H7.58333C7.46178 9.79167 7.3452 9.83995 7.25924 9.92591C7.17329 10.0119 7.125 10.1284 7.125 10.25V10.7083H6.20833C6.08678 10.7083 5.9702 10.7566 5.88424 10.8426C5.79829 10.9285 5.75 11.0451 5.75 11.1667V12.0833H3.91667V10.8981L7.66492 7.14983C7.72776 7.08706 7.77101 7.00737 7.78939 6.92047C7.80778 6.83356 7.80053 6.74319 7.7685 6.66033C7.64933 6.35233 7.58333 6.01867 7.58333 5.66667Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
