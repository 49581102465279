import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Tooltip } from 'components/Tooltip';

export const DisabledText = styled('span')(({ theme }) => ({
    color: theme.palette.text.disabled,
    display: 'block',
    marginBottom: theme.spacing(2),
}));

export function DisabledTextWithTooltip(props) {
    const { children, contentEditable, ...restProps } = props;
    return (
        <Box contentEditable={contentEditable}>
            <Tooltip
                placement="top"
                title="An initial opt-out message is required to comply with TCPA SMS regulations. Any future messages to a contact will not include this message."
            >
                <DisabledText {...restProps}>{children}</DisabledText>
            </Tooltip>
        </Box>
    );
}

DisabledTextWithTooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    contentEditable: PropTypes.bool,
};
