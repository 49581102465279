import { useMemo } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { formatDate } from 'utils/formatters';
import { SCHEDULED_DATE_FMT, SCHEDULED_TIME_FMT } from 'utils/constants';
import { ScheduledMessagePopup } from 'components/Dialog';
import { generateTimestamp } from 'views/Communications';

const handleResponse = (res, onSuccess) => {
    if (res?.error) {
        toast.error(`Something went wrong, please try again shortly. ${res.error.data.message}`);
    } else {
        toast.success('Successfully updated message');
        onSuccess();
    }
};

export function UpdateScheduledDateTimePopup(props) {
    const {
        messageId,
        messageType,
        conversationId,
        open,
        setOpen,
        utcDateTime,
        anchorEl,
        updateMessage,
        getInitialMessages,
    } = props;

    const initialDate = useMemo(() => formatDate(utcDateTime, SCHEDULED_DATE_FMT), [utcDateTime]);

    const initialTime = useMemo(() => formatDate(utcDateTime, SCHEDULED_TIME_FMT), [utcDateTime]);

    const handleRefresh = () => getInitialMessages();

    const handleConfirm = async ({ date, time }) => {
        if (date && time) {
            const result = await updateMessage({
                messageId,
                messageType,
                conversationId,
                body: { sendDate: generateTimestamp(date, time) },
            });
            handleResponse(result, handleRefresh);
        }
    };

    return (
        <ScheduledMessagePopup
            open={open}
            showCalendar={true}
            onClose={() => {}}
            onSend={() => {}}
            initialDate={initialDate}
            initialTime={initialTime}
            setShowCalendar={setOpen}
            anchorEl={anchorEl}
            onConfirm={handleConfirm}
            messageType={messageType}
            isUpdating
        />
    );
}

UpdateScheduledDateTimePopup.propTypes = {
    messageType: PropTypes.string,
    messageId: PropTypes.string,
    conversationId: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    utcDateTime: PropTypes.string,
    anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(Element), PropTypes.oneOf([null])]),
    updateMessage: PropTypes.func,
    getInitialMessages: PropTypes.func,
};
