import { useCallback, useEffect, useState, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { alpha, CircularProgress, styled, Tooltip } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import {
    StyledButton,
    JDDropdownList,
    TemplateLists,
    CreateTemplate,
    ChannelToggleButton,
} from './components';
import { MESSAGE_TYPES } from 'utils/constants';
import { GradientTypography, Typography } from 'components/Typography';
import {
    CloseIcon,
    InfoIcon,
    ChevronLeftIcon,
    AiIcon,
    ChevronUpIcon,
    ChevronDownIcon,
} from 'components/Icons';
import { TEMPLATE_VIEWS } from './utils/constants';
import { useRmmTelemetry, useRmmTemplate, useMessageTemplate } from './hooks';
import { OverwriteRmmTemplateWarningDialog } from './components/OverwriteRmmTemplateWarningDialog';
import { setMessageChannels } from './MessageTemplateSlice';
import { useNavigate } from 'react-router-dom';

const STICKY_BUTTON_HEIGHT = 5;

const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: theme.spacing(55),
    height: 'auto',
    padding: theme.spacing(0, 3),
    zIndex: 102,
    overflow: 'scroll',
    minHeight: '100vh',
    paddingBottom: theme.spacing(STICKY_BUTTON_HEIGHT * 2),
}));

const TemplateHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(3),
}));

const StickyButtonWrapper = styled(Paper)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    width: 'fill-available',
    zIndex: 1,
    height: theme.spacing(STICKY_BUTTON_HEIGHT),
    padding: theme.spacing(2, 2, 3, 2),
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-around',
}));

const CreateJDBanner = styled(Box)(() => ({
    backgroundColor: '#EFF2FC',
    width: '100%',
    height: '45px',
    marginTop: '16px',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const MessageTemplate = memo((props) => {
    const {
        open,
        onClose,
        templates,
        currentTemplateView,
        onTemplateCreateView,
        isCreateTemplateDisabled,
        onUseTemplate,
        setClearInput,
        onTemplateListsView,
        disableUseTemplate,
        jobDescriptionName,
        jobDescriptionSelectedId,
        showCreateJDBanner,
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const openAnchorEl = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const {
        isFetching,
        innerHtml,
        innerSubjectHtml,
        initialBody,
        initialSubject,
        templateName,
        templateSubject,
        messageChannels,
        selectedChannels,
        disableSaveTemplate,
        handleChangeBody,
        handleChangeName,
        handleChangeSubject,
        handleToggleChannel,
        handleSaveTemplate,
        handleViewTemplate,
        handleDeleteTemplate,
        invalidDynamicFields,
        editorTemplateBodyRef,
        editorTemplateSubjectRef,
        activeTemplateId,
        isUpdatingTemplate,
        templateId,
    } = useMessageTemplate({
        onClose,
        open,
        setClearInput,
    });

    const { showTemplateOverlay } = useRmmTelemetry();

    const {
        processRmmMessage,
        isLoading,
        jobDescriptions,
        handleJdClick,
        active,
        target,
        isGenerating,
        isTextStreamComplete,
    } = useRmmTemplate({
        open: openAnchorEl,
        openDrawer: open,
        onTemplateCreateView,
        onHandleClose: handleClose,
        jobDescriptionName,
        jobDescriptionSelectedId,
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const generatedRef = useRef(false);

    const [openBanner, setOpenBanner] = useState(true);

    useEffect(() => {
        dispatch(setMessageChannels([MESSAGE_TYPES.EMAIL]));
    }, [dispatch]);

    useEffect(() => {
        if (isGenerating) {
            generatedRef.current = true;
        }
    }, [isGenerating]);

    const updateActiveTemplateAfterResponse = (res, signature) => {
        if (res.error) return;
        if (isUpdatingTemplate && !!activeTemplateId && templateId === activeTemplateId) {
            onUseTemplate({ ...res.data, signature });
        }
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} aria-label="message-template-drawer">
            <Box sx={{ height: 'inherit' }}>
                <ContentWrapper>
                    <Box sx={{ display: 'flex' }}>
                        {currentTemplateView === TEMPLATE_VIEWS.CREATE && (
                            <IconButton
                                aria-label="close-message-template-drawer"
                                data-trackid="template-close-drawer"
                                onClick={onTemplateListsView}
                                sx={{ mt: 1 }}
                            >
                                <ChevronLeftIcon sx={{ color: 'primary.main', fontSize: 24 }} />
                            </IconButton>
                        )}
                        <IconButton
                            aria-label="close-message-template-drawer"
                            data-trackid={
                                currentTemplateView === TEMPLATE_VIEWS.CREATE
                                    ? 'template-back'
                                    : 'templates-list-close'
                            }
                            onClick={onClose}
                            sx={{ ml: 'auto', mt: 1 }}
                        >
                            <CloseIcon sx={{ color: 'primary.main', fontSize: 16 }} />
                        </IconButton>
                    </Box>
                    <TemplateHeader>
                        <Typography variant="headline" data-testid="message-template-header">
                            Message Templates
                        </Typography>
                        <Tooltip
                            placement="bottom-end"
                            title="A Message Template is a message that can be saved and reused for future Email or SMS messages. You can have a maximum of 10 templates."
                        >
                            <span>
                                <InfoIcon
                                    sx={{
                                        color: (theme) => alpha(theme.palette.primary.main, 0.48),
                                    }}
                                />
                            </span>
                        </Tooltip>
                        {currentTemplateView === TEMPLATE_VIEWS.CREATE && (
                            <Box
                                sx={{
                                    ml: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="labelXSmall"
                                    sx={{ color: 'rgba(38, 55, 70, 0.72)' }}
                                >
                                    Channels
                                </Typography>
                                <Box aria-describedby="template channels">
                                    <ChannelToggleButton
                                        type={MESSAGE_TYPES.EMAIL}
                                        selected={messageChannels[MESSAGE_TYPES.EMAIL]}
                                        onChange={handleToggleChannel}
                                    />
                                    <ChannelToggleButton
                                        type={MESSAGE_TYPES.SMS}
                                        selected={messageChannels[MESSAGE_TYPES.SMS]}
                                        onChange={handleToggleChannel}
                                    />
                                </Box>
                            </Box>
                        )}
                    </TemplateHeader>
                    {currentTemplateView === TEMPLATE_VIEWS.CREATE && (
                        <Box sx={{ mt: 1 }}>
                            <Button
                                data-testid="generate-template-button"
                                startIcon={<AiIcon />}
                                endIcon={<ChevronDownIcon />}
                                onClick={handleClick}
                                sx={{
                                    height: 40,
                                    textTransform: 'none',
                                }}
                                disabled={isGenerating}
                            >
                                <Typography variant="titleSmall">
                                    Generate template for me
                                </Typography>
                            </Button>
                            <JDDropdownList
                                open={openAnchorEl}
                                onHandleClose={handleClose}
                                anchorEl={anchorEl}
                                isLoading={isLoading}
                                jobDescriptions={jobDescriptions}
                                handleJdClick={handleJdClick}
                                fullWidth
                                anchorBottom
                            />
                        </Box>
                    )}

                    {showCreateJDBanner && openBanner && (
                        <CreateJDBanner>
                            <GradientTypography variant="bodySmallBold" sx={{ mb: 0.5 }}>
                                Want to create a recruitment message? Just choose an existing job
                                description above or click&nbsp;
                                <Link
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/job-description-builder')}
                                    aria-label="create new job description"
                                >
                                    here
                                </Link>
                                &nbsp;to start a new one!
                            </GradientTypography>

                            <IconButton
                                aria-label="close create jd banner"
                                onClick={() => setOpenBanner(false)}
                            >
                                <CloseIcon
                                    sx={{
                                        color: (theme) => alpha(theme.palette.common.black, 0.7),
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        </CreateJDBanner>
                    )}

                    {currentTemplateView === TEMPLATE_VIEWS.CREATE && (
                        <CreateTemplate
                            isFetching={isFetching}
                            innerHtml={innerHtml}
                            innerSubjectHtml={innerSubjectHtml}
                            initialBody={initialBody}
                            initialSubject={initialSubject}
                            templateName={templateName}
                            templateSubject={templateSubject}
                            selectedChannels={selectedChannels}
                            disableSaveTemplate={disableSaveTemplate}
                            showTemplateOverlay={showTemplateOverlay}
                            handleChangeBody={handleChangeBody}
                            handleChangeName={handleChangeName}
                            handleChangeSubject={handleChangeSubject}
                            handleSaveTemplate={handleSaveTemplate}
                            editorTemplateBodyRef={editorTemplateBodyRef}
                            editorTemplateSubjectRef={editorTemplateSubjectRef}
                            isGenerating={isGenerating}
                            isTextStreamComplete={isTextStreamComplete}
                        />
                    )}

                    {currentTemplateView === TEMPLATE_VIEWS.LISTS && (
                        <TemplateLists
                            templates={templates}
                            onTemplateCreateView={onTemplateCreateView}
                            isCreateTemplateDisabled={isCreateTemplateDisabled}
                            onClose={onClose}
                            onUseTemplate={onUseTemplate}
                            onViewTemplate={handleViewTemplate}
                            onDeleteTemplate={handleDeleteTemplate}
                            disableUseTemplate={disableUseTemplate}
                        />
                    )}
                </ContentWrapper>
                <StickyButtonWrapper elevation={6}>
                    {currentTemplateView === TEMPLATE_VIEWS.LISTS && (
                        <>
                            <StyledButton
                                onClick={onTemplateCreateView}
                                variant="success"
                                disabled={isCreateTemplateDisabled}
                                data-trackid="templates-list-create-template"
                            >
                                <Typography variant="titleSmall">Create Template</Typography>
                            </StyledButton>
                            <Button
                                variant="contained"
                                startIcon={<AiIcon />}
                                endIcon={<ChevronUpIcon />}
                                onClick={handleClick}
                                data-trackid="templates-list-ai-create-template"
                                sx={{
                                    height: 40,
                                    background: (theme) => theme.palette.common.matchInputGradient,
                                    textTransform: 'none',
                                }}
                            >
                                <Typography variant="titleSmall">Create Template For Me</Typography>
                            </Button>
                            <JDDropdownList
                                open={openAnchorEl}
                                onHandleClose={handleClose}
                                anchorEl={anchorEl}
                                isLoading={isLoading}
                                jobDescriptions={jobDescriptions}
                                handleJdClick={handleJdClick}
                                showDescription
                            />
                        </>
                    )}
                    {currentTemplateView === TEMPLATE_VIEWS.CREATE && (
                        <StyledButton
                            data-testid="save-template-button"
                            data-trackid="template-save"
                            onClick={() => {
                                handleSaveTemplate(updateActiveTemplateAfterResponse);
                            }}
                            variant="success"
                            disabled={
                                disableSaveTemplate ||
                                isFetching ||
                                invalidDynamicFields ||
                                isGenerating ||
                                (generatedRef.current && !isTextStreamComplete)
                            }
                            sx={{ width: '100%', display: 'flex', gap: 2 }}
                        >
                            {isFetching && (
                                <CircularProgress data-testid="template-loading-icon" size={18} />
                            )}
                            <Typography variant="titleSmall">Save Template</Typography>
                        </StyledButton>
                    )}
                </StickyButtonWrapper>
            </Box>
            <OverwriteRmmTemplateWarningDialog
                processRmmMessage={processRmmMessage}
                active={active}
                target={target}
            />
        </Drawer>
    );
});

MessageTemplate.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    templates: PropTypes.array,
    currentTemplateView: PropTypes.oneOf([TEMPLATE_VIEWS.CREATE, TEMPLATE_VIEWS.LISTS]),
    onTemplateCreateView: PropTypes.func,
    isCreateTemplateDisabled: PropTypes.bool,
    onUseTemplate: PropTypes.func,
    flatDynamicFields: PropTypes.array,
    setClearInput: PropTypes.func,
    onTemplateListsView: PropTypes.func,
    disableUseTemplate: PropTypes.bool,
    jobDescriptionName: PropTypes.string,
    jobDescriptionSelectedId: PropTypes.string,
    showCreateJDBanner: PropTypes.bool,
};
