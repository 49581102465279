import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { Typography } from 'components/Typography';
import { CloseIcon } from 'components/Icons';
import { FileInfo } from './FileInfo';
import { FileTags } from './FileTags';
import { FileDisplayName } from './FileDisplayName';
import { FileEntityConnections } from './FileEntityConnections';
import { Button, CircularProgress } from '@mui/material';
import { useMediaManager } from './hooks/useMediaManager';
import { useRemoveMediaConnection } from './hooks/useRemoveMediaConnection';
import { FileAccessControl } from './FileAccessControl';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        padding: theme.spacing(2, 2.5, 1, 2.5),
        width: theme.spacing(85),
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    width: '100%',
    textTransform: 'none',
    height: theme.spacing(5),
}));

export function ManageMediaModal(props) {
    const { isOpen, onClose, file, canEditPermissions, onMediaUpdated } = props;
    const {
        value,
        inputValue,
        tagOptions,
        size,
        filename,
        mediaType,
        displayName,
        organizationAccess,
        handleChange,
        handleInputValue,
        handleInputEnter,
        handleSaveMedia,
        handleReplaceAttachment,
        handleDisplayNameChange,
        handleChangeAccess,
        handleChangePermissions,
        isDirty,
        isUpdatingMedia,
    } = useMediaManager(file, isOpen, onClose, onMediaUpdated);

    const { handleRemoveMediaConnection, isLoading: isRemovingMediaConnection } =
        useRemoveMediaConnection({
            entityConnections: file?.entityConnections,
            mediaId: file.mediaId,
        });

    const removeMediaConnection = async (_, talentId) => {
        const res = await handleRemoveMediaConnection(talentId);
        if (res?.data) {
            onClose();
        }
    };

    return (
        <StyledDialog open={isOpen} onClose={onClose} keepMounted={false}>
            <Box aria-label="manage-media-modal">
                <Box id="manage-media-modal" sx={{ display: 'flex', mb: 1.5 }}>
                    <Typography variant="titleMedium" sx={{ mr: 'auto' }}>
                        Manage Media
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        aria-label="close-upload-new-media-modal"
                        sx={{ p: 0, mr: -1.5 }}
                        disableRipple
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Box sx={{ display: 'flex', mb: 6 }}>
                    <FileInfo
                        filename={filename}
                        size={size}
                        mediaType={mediaType}
                        onReplaceAttachment={handleReplaceAttachment}
                    />
                </Box>

                <Box sx={{ marginBottom: 3 }}>
                    <FileDisplayName
                        displayName={displayName}
                        filename={filename}
                        onChange={handleDisplayNameChange}
                    />
                </Box>

                <Box sx={{ mb: 3 }}>
                    <FileTags
                        tagOptions={tagOptions}
                        selectedValues={value}
                        onValueChange={handleChange}
                        inputValue={inputValue}
                        onInputChange={handleInputValue}
                        onInputEnter={handleInputEnter}
                        mediaId={file?.mediaId}
                    />
                </Box>

                <Box sx={{ mb: 3 }}>
                    <FileEntityConnections
                        entityConnections={file?.entityConnections || []}
                        handleRemoveMediaConnection={removeMediaConnection}
                        isRemovingMediaConnection={isRemovingMediaConnection}
                    />
                </Box>

                {canEditPermissions && (
                    <Box sx={{ display: 'flex', mb: 3 }}>
                        <FileAccessControl
                            file={file}
                            access={organizationAccess}
                            onChangeAccess={handleChangeAccess}
                            onChangePermissions={handleChangePermissions}
                        />
                    </Box>
                )}

                <Box
                    sx={{
                        display: 'flex',
                        mb: 1.5,
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <StyledButton onClick={onClose}>
                        <Typography color="text.primary" variant="titleSmall">
                            Cancel
                        </Typography>
                    </StyledButton>
                    <StyledButton
                        variant="contained"
                        disabled={isUpdatingMedia || !isDirty}
                        onClick={handleSaveMedia}
                    >
                        <Typography variant="titleSmall">
                            {isUpdatingMedia ? <CircularProgress size={16} /> : null} Save
                        </Typography>
                    </StyledButton>
                </Box>
            </Box>
        </StyledDialog>
    );
}

ManageMediaModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    file: PropTypes.object,
    canEditPermissions: PropTypes.bool,
    onMediaUpdated: PropTypes.func,
};
