import { lighten, styled } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const Tooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.1,
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.text.primary,
    },
    [`& .${tooltipClasses.arrow}::before`]: {
        backgroundColor: theme.palette.text.primary,
    },
}));

export const LightTooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[4],
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 10,
        lineHeight: '12px',
        letterSpacing: 0.1,
        color: theme.palette.text.primary,
        background:
            'linear-gradient(0deg, rgba(38, 55, 70, 0.12) 0%, rgba(38, 55, 70, 0.12) 100%), #fff',
    },
    [`& .${tooltipClasses.arrow}::before`]: {
        background: lighten(theme.palette.text.primary, 0.88),
    },
}));
