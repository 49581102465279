import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';

import { Typography } from 'components/Typography';
import { MediaSectionLabel } from './MediaSectionLabel';
import { FileEntityRelationship } from 'features/Activity/components/FileEntityRelationship';
import { useState } from 'react';

export function FileEntityConnections(props) {
    const {
        entityConnections = [],
        isRemovingMediaConnection,
        handleRemoveMediaConnection,
    } = props;

    const [mediaEntityConnections, setMediaEntityConnections] = useState(entityConnections);

    const removeEntityConnection = (targetEntityType, targetEntityId) =>
        setMediaEntityConnections((connections) =>
            connections.filter(
                ({ type, entityId }) => !(type === targetEntityType && entityId === targetEntityId)
            )
        );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <MediaSectionLabel as="label" htmlFor="displayName">
                Attached to
            </MediaSectionLabel>
            <Box sx={{ maxHeight: 66, overflow: 'auto' }}>
                {!mediaEntityConnections?.length && (
                    <Typography
                        sx={{ color: (theme) => alpha(theme.palette.text.secondary, 0.48) }}
                        variant="bodyBase"
                    >
                        This media is not attached to anything
                    </Typography>
                )}
                {mediaEntityConnections?.length > 0 &&
                    mediaEntityConnections?.map?.((entity) => (
                        <FileEntityRelationship
                            key={`${entity.type}-${entity.entityId}`}
                            type={entity.type}
                            entityId={entity.entityId}
                            data={entity?.data}
                            userId={entity?.userId}
                            isRemovingMediaConnection={isRemovingMediaConnection}
                            handleRemoveMediaConnection={handleRemoveMediaConnection}
                            onEntityConnectionFetchError={removeEntityConnection}
                        />
                    ))}
            </Box>
        </Box>
    );
}

FileEntityConnections.propTypes = {
    entityConnections: PropTypes.array,
    isRemovingMediaConnection: PropTypes.bool,
    handleRemoveMediaConnection: PropTypes.func,
};
