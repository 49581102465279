import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { setActiveTemplateId } from 'features/MessageTemplate';
import { useMessageQueryBuilder } from './useMessageQueryBuilder';
import {
    useSendConversationMessageMutation,
    useUpdateMessageMutation,
} from 'services/conversations';
import { handleMessageSendError } from 'views/Communications/utils';
import { clearMessage, setClearInput, setTcpaDialog } from '../messageSlice';
import { usePrompt } from 'hooks/usePrompt';
import { useMessageData } from './useMessageData';
import { useEffect } from 'react';

export function useSendMessage(props) {
    const {
        shortlistId = '',
        conversationId = '',
        onSuccess,
        disableRefresh,
        refreshMessages,
    } = props || {};

    const dispatch = useDispatch();
    const { messageType } = useMessageData();
    const { body, subject } = useSelector((state) => state.message);
    const isMessageComposeFormDirty =
        body.innerText.trim() !== '' || subject.innerText.trim() !== '';

    const { createSendArgs, createUpdateArgs } = useMessageQueryBuilder({
        shortlistId,
        conversationId,
    });

    const [, setPrompt, userConfirmed] = usePrompt(
        'Are you sure you want to navigate away from this page? Your changes will not be saved.',
        false
    );

    useEffect(() => {
        if (userConfirmed) {
            dispatch(clearMessage());
            dispatch(setClearInput(true));
        }

        return () => {
            if (userConfirmed) {
                dispatch(setClearInput(false));
            }
        };
    }, [dispatch, userConfirmed]);

    useEffect(() => {
        if (isMessageComposeFormDirty) {
            setPrompt(true);
        } else {
            setPrompt(false);
        }
    }, [isMessageComposeFormDirty, setPrompt]);

    const [sendMessage, { isLoading: isSending }] = useSendConversationMessageMutation();
    const [updateMessage, { isLoading: isUpdating }] = useUpdateMessageMutation();

    const handleSetTcpaDialog = (value) => {
        dispatch(setTcpaDialog(value));
    };

    const handleResponse = ({ response, type, onSuccess }) => {
        if (response?.error) {
            handleMessageSendError(response?.error, type, handleSetTcpaDialog);
        } else {
            handleSuccess(response);
            dispatch(setActiveTemplateId(''));
            onSuccess?.(response);
        }
    };

    const handleSuccess = (response) => {
        toast.success('Your message will be sent');
        dispatch(clearMessage());
        dispatch(setClearInput(true));
        setPrompt(false);
        if (!disableRefresh) {
            refreshMessages?.(response);
        }
    };

    const handleSend = async () => {
        const sendArgs = createSendArgs();
        const response = await sendMessage(sendArgs);
        handleResponse({ response, messageType, onSuccess });
        dispatch(setClearInput(false));
    };

    const handleUpdate = async () => {
        const updateArgs = createUpdateArgs();
        const response = await updateMessage(updateArgs);
        handleResponse({ response, messageType, onSuccess });
        dispatch(setClearInput(false));
    };

    return {
        isSending,
        isUpdating,
        handleSend,
        handleUpdate,
    };
}
