import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import map from 'lodash/map';

import { InfoIcon } from 'components/Icons';
import { TextInput } from 'components/Inputs';
import { Typography } from 'components/Typography';
import { useDynamicFields } from '../hooks';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
}));

const NumericBullet = styled(Typography)(({ theme }) => ({
    color: alpha(theme.palette.text.primary, 0.48),
    alignSelf: 'baseline',
    marginRight: theme.spacing(0.5),
}));

const DynamicFieldText = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.25, 0.5),
    color: theme.palette.primary.main,
    display: 'flex',
    alignSelf: 'flex-start',
}));

export function DynamicFieldsFallback() {
    const { defaultDynamicFields, handleChangeDefaultDynamicField } = useDynamicFields();

    const handleChange = (key, event) => {
        handleChangeDefaultDynamicField?.(key, event.target.value);
    };

    return (
        <Box>
            <Box sx={{ mt: 3 }}>
                <Typography variant="labelSmall">Dynamic Fields Fallbacks</Typography>
                <Tooltip
                    placement="bottom-end"
                    title="Choose a value for each Dynamic Field you use  in case a profile does not have any information we can insert"
                >
                    <span>
                        <InfoIcon
                            sx={{
                                color: (theme) => alpha(theme.palette.primary.main, 0.48),
                            }}
                        />
                    </span>
                </Tooltip>
                <List>
                    {map(Object.entries(defaultDynamicFields), ([key, value], index) => {
                        if (key === '{{todaysDate}}' || key === '{{dayOfTheWeek}}') {
                            return null;
                        }
                        return (
                            <ListItem key={`${key}-${index}`} sx={{ pr: 0, pl: 0 }}>
                                <NumericBullet variant="bodyBaseBold">{index + 1}.</NumericBullet>
                                <StyledBox>
                                    <DynamicFieldText variant="bodyBaseBold">
                                        {key}
                                    </DynamicFieldText>
                                    <TextInput
                                        value={value}
                                        onChange={(event) => handleChange(key, event)}
                                    />
                                </StyledBox>
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Box>
    );
}
