import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const insightsApi = createApi({
    reducerPath: 'insightsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/compensation/v1/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCompanyData: builder.query({
            query: (companyName) => ({
                url: 'company/',
                params: { name: companyName },
            }),
        }),
    }),
});

export const { useLazyGetCompanyDataQuery } = insightsApi;
