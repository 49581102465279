import SvgIcon from '@mui/material/SvgIcon';

export function TimeAltIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M16 0C7.184 0 0 7.184 0 16C0 24.816 7.184 32 16 32C24.816 32 32 24.816 32 16C32 7.184 24.816 0 16 0ZM16 28.8C8.944 28.8 3.2 23.056 3.2 16C3.2 8.944 8.944 3.2 16 3.2C23.056 3.2 28.8 8.944 28.8 16C28.8 23.056 23.056 28.8 16 28.8Z"
                fill="currentColor"
            />
            <path
                d="M17.6 15.3448V9.56875C17.6 8.68875 16.88 7.96875 16 7.96875C15.12 7.96875 14.4 8.68875 14.4 9.56875V15.4568C14.4 15.5528 14.432 15.6328 14.448 15.7128C14.352 16.2088 14.48 16.7367 14.864 17.1207L18.064 20.3207C18.384 20.6407 18.784 20.7847 19.2 20.7847C19.616 20.7847 20.016 20.6247 20.336 20.3207C20.96 19.6967 20.96 18.6888 20.336 18.0648L17.6 15.3288V15.3448Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
