import SvgIcon from '@mui/material/SvgIcon';

export function SendMessageIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M4.72096 4.05097L20.076 11.617C20.1597 11.6582 20.2302 11.7221 20.2795 11.8014C20.3288 11.8806 20.3549 11.9721 20.3549 12.0655C20.3549 12.1588 20.3288 12.2503 20.2795 12.3296C20.2302 12.4088 20.1597 12.4727 20.076 12.514L4.71996 20.08C4.63393 20.1222 4.53757 20.1389 4.44235 20.1279C4.34713 20.117 4.25707 20.0789 4.18288 20.0182C4.1087 19.9575 4.05353 19.8767 4.02393 19.7856C3.99434 19.6944 3.99157 19.5967 4.01596 19.504L5.98496 12.07L4.01496 4.62797C3.99017 4.53508 3.99268 4.43702 4.02219 4.34552C4.05171 4.25403 4.10697 4.17298 4.18137 4.11209C4.25576 4.0512 4.34614 4.01305 4.44166 4.00221C4.53719 3.99137 4.63381 4.0083 4.71996 4.05097H4.72096ZM5.26396 5.43397L6.87396 11.516L6.93596 11.504L6.99996 11.5H14C14.1249 11.4997 14.2454 11.5463 14.3377 11.6305C14.4301 11.7147 14.4875 11.8304 14.4988 11.9548C14.5101 12.0792 14.4744 12.2034 14.3987 12.3028C14.3231 12.4022 14.2129 12.4697 14.09 12.492L14 12.5H6.99996C6.96911 12.5001 6.93832 12.4974 6.90796 12.492L5.26496 18.698L18.723 12.066L5.26396 5.43397Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
