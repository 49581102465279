import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { Typography } from 'components/Typography';

export function KeywordsDetailModalList(props) {
    const { commandTitle, description, exampleCode, exampleNote } = props;
    return (
        <Box
            sx={{
                mt: 2,
                p: '8px 0px 24px 0px',
                borderTop: '1px solid rgba(255, 255, 255, 0.48)',
                '&:last-of-type': {
                    pb: 0,
                },
            }}
        >
            <Typography variant="titleMedium" component="h2">
                {commandTitle}
            </Typography>
            <Typography variant="bodySmall" component="p" sx={{ pb: '8px' }}>
                {description}
            </Typography>
            <Typography variant="labelXSmall" component="p">
                Example
            </Typography>
            <Typography
                variant="bodySmall"
                component="p"
                sx={{
                    background: 'rgba(255, 218, 124, 0.12)',
                    borderRadius: 1,
                    p: 0.5,
                    fontStyle: 'italic',
                }}
            >
                {exampleCode}
            </Typography>
            {exampleCode && (
                <Typography
                    variant="bodySmall"
                    component="p"
                    sx={{ fontStyle: 'italic', pt: '5px' }}
                >
                    {exampleNote}
                </Typography>
            )}
        </Box>
    );
}

KeywordsDetailModalList.propTypes = {
    commandTitle: PropTypes.string,
    description: PropTypes.string,
    exampleCode: PropTypes.string,
    exampleNote: PropTypes.string,
};
