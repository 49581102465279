import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from 'components/Typography';

export function MessageHeader(props) {
    const { variant = 'compose' } = props;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: variant === 'compose' && 2,
                    mb: variant === 'reply' && 1,
                }}
            >
                {variant === 'compose' && (
                    <Typography variant="labelSmall" sx={{ pr: 2.5 }}>
                        MESSAGE
                    </Typography>
                )}
            </Box>
        </>
    );
}

MessageHeader.propTypes = {
    variant: PropTypes.string,
    messageType: PropTypes.string,
    onChange: PropTypes.func,
    onChangeMessageType: PropTypes.func,
    greeting: PropTypes.bool,
    isFetching: PropTypes.bool,
};
