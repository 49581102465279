import { useMemo, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { parseISO, isValid, format, isToday, isYesterday, isThisYear } from 'date-fns';
import { Markup } from 'interweave';
import { useDispatch } from 'react-redux';

import { Typography } from 'components/Typography';
import { LoadingPlaceholder } from 'components/LoadingPlaceholder';
import { getInitials, getTalentCardHeader } from 'utils/formatters';
import { setConversationTalent } from 'views/Communications/communicationsSlice';
import { clearTemplate, resetActiveTemplateState } from 'features/MessageTemplate';
import { AttachmentIcon } from 'components/Icons';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { MessageBotIcons } from './components/MessageBotIcons';

export function printDate(ts = '') {
    const parsedDate = parseISO(ts);
    if (isValid(parsedDate)) {
        if (isToday(parsedDate)) return format(parsedDate, 'p');
        else if (isYesterday(parsedDate)) return 'Yesterday';
        else if (isThisYear(parsedDate)) return format(parsedDate, 'LLL dd');
        else return format(parsedDate, 'PP');
    }
    return '';
}

const TalentAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 12,
    background:
        'linear-gradient(180deg, rgba(65, 232, 211, 0) 0%, rgba(65, 232, 211, 0.7) 100%), #3A8DD3',
}));

export function MessageSummary(props) {
    const {
        conversationId,
        sender,
        talent,
        messages,
        timestamp,
        isRead,
        isTalentsLoading,
        hasAttachment,
        ...rest
    } = props;

    const { name, imageUrl } = talent || {};
    const { body, bodyPlaintext } = messages[0] || {};

    const { conversationId: activeConversationId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selected = useMemo(
        () => conversationId === activeConversationId,
        [conversationId, activeConversationId]
    );

    const handleViewMessage = useCallback(
        (replace = false) => {
            dispatch(clearTemplate());
            dispatch(resetActiveTemplateState());
            navigate(`/communications/messages/${conversationId}`, { state: { sender } });
        },
        [navigate, dispatch, sender, conversationId]
    );

    const timeCaption = useMemo(() => printDate(timestamp), [timestamp]);

    useEffect(() => {
        if (selected) {
            dispatch(setConversationTalent(talent));
        }
    }, [selected, talent, dispatch]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 'inherit',
                borderBottom: '1px solid #1E2B38',
                background: selected
                    ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #263746;'
                    : 'inherit',
                p: 2,
                mt: 0.5,
                cursor: 'pointer',
            }}
            role="button"
            onClick={handleViewMessage}
            aria-label="Click to view conversation details"
            data-trackid="conversations-nav-conversation"
        >
            <Box sx={{ display: 'flex', ml: 'auto', alignItems: 'center', opacity: 0.48 }}>
                {hasAttachment && (
                    <AttachmentIcon fill="currentColor" sx={{ fontSize: 10, mr: 0.5 }} />
                )}
                <Typography variant="bodySmall">{timeCaption}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', maxWidth: 'inherit', width: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        rowGap: '4px',
                        maxWidth: '20%',
                        px: 1,
                    }}
                >
                    <TalentAvatar alt="Talent Avatar" src={imageUrl} title="MB">
                        {getInitials(name, '')}
                    </TalentAvatar>
                    <MessageBotIcons bots={rest.users || []} />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        maxWidth: '80%',
                        flexDirection: 'column',
                        opacity: isRead ? 0.72 : 1,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 0.5 }}>
                        <LoadingPlaceholder width="100%" isLoading={isTalentsLoading}>
                            <Typography variant="bodyBaseBold" noWrap sx={{ mr: 1, flex: 'auto' }}>
                                {name}
                            </Typography>
                            <Typography variant="bodyBase" noWrap sx={{ flex: '50' }}>
                                {getTalentCardHeader(talent)}
                            </Typography>
                        </LoadingPlaceholder>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant={isRead ? 'bodySmall' : 'bodySmallBold'} noWrap>
                            <ErrorBoundary
                                fallbackText={
                                    bodyPlaintext ||
                                    'Cannot display message as it contains unsupported formatting'
                                }
                            >
                                <Markup content={body} noHtml />
                            </ErrorBoundary>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

MessageSummary.propTypes = {
    isRead: PropTypes.bool,
    hasAttachment: PropTypes.bool,
    isTalentsLoading: PropTypes.bool,
    timestamp: PropTypes.string,
    conversationId: PropTypes.string,
    sender: PropTypes.shape({
        displayName: PropTypes.string,
        title: PropTypes.string,
    }),
    talent: PropTypes.object,
    messages: PropTypes.array,
};
