import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { alpha, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useEffect, useRef, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';
import { getValueByMode } from 'utils/copy/getValueByMode';
import { ContactDetails } from 'features/TalentCard/ContactDetails';
import { LocationIcon, OpenLinkInNewTabIcon } from 'components/Icons';
import {
    getTalentCardHeader,
    getTalentLocationString,
    getTalentName,
} from 'utils/formatters/talentFormatting';
import { SearchActions } from 'features/TalentCardModular/SearchActions';
import { BullhornIconCta } from 'features/TalentCardModular/components/BullhornIconCta';
import { LinkedInIconCta } from 'features/TalentCard/LinkedInIconCta';

const unavailableSrc = '/assets/logos/Nebula-color-stacked.png';

const TalentAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginBottom: theme.spacing(1),
    backgroundColor: alpha(theme.palette.text.primary, 0.12),
    '&:hover': {
        cursor: 'pointer',
    },
}));

const FallbackImg = styled('img')({
    width: '75%',
});

const Truncate = styled(Box)({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '250px',
});

const actionsButtonStyles = (theme) => ({
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 1px 3px 1px #00000026',
    backgroundColor: 'white',
    width: '32px',
    height: '32px',
    minWidth: '32px',
    padding: '0px',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
});

const dataTrackIds = {
    lookup: 'profile-drawer-header-contact-lookup-click',
    emails: 'profile-drawer-header-contact-view-emails-click',
    phone: 'profile-drawer-header-contact-view-sms-click',
    socails: 'profile-drawer-header-contact-view-socials-click',
};

export function TalentDetails({ talentData }) {
    const byeBias = useSelector((state) => state.byeBias.on) || talentData.isAnonymized;
    const talentName = getTalentName(talentData, byeBias);
    const talentCardHeader = getTalentCardHeader(talentData);
    const headerRef = useRef({ scrollWidth: 0, clientWidth: 0 });
    const nameRef = useRef({ scrollWidth: 0, clientWidth: 0 });
    const locationRef = useRef({ scrollWidth: 0, clientWidth: 0 });
    const [headerOverflow, setHeaderOverflow] = useState(false);
    const [nameOverflow, setNameOverflow] = useState(false);
    const [locationOverflow, setLocationOverflow] = useState(false);

    useEffect(() => {
        setHeaderOverflow(headerRef.current.scrollWidth > headerRef.current.clientWidth);
    }, [headerRef.current.scrollWidth, headerRef.current.clientWidth]);

    useEffect(() => {
        setNameOverflow(nameRef.current.scrollWidth > nameRef.current.clientWidth);
    }, [nameRef.current.scrollWidth, nameRef.current.clientWidth]);

    useEffect(() => {
        setLocationOverflow(locationRef.current.scrollWidth > locationRef.current.clientWidth);
    }, [locationRef.current.scrollWidth, locationRef.current.clientWidth]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <TalentAvatar
                    src={getValueByMode(byeBias, null, talentData?.imageUrl)}
                    aria-label="nova-talent-avatar"
                    component="a"
                    href={`/talent/${talentData.talentId}?byeBias=${byeBias}&profileId=${talentData.profileId}`}
                    target="_blank"
                    sx={{ mr: 1.5 }}
                >
                    <FallbackImg src={unavailableSrc} alt="nova-avatar" />
                </TalentAvatar>
                <Box
                    sx={{
                        width: '97%',
                        minWidth: '230px',
                        boxSizing: 'border-box',
                        mr: '8px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 'auto',
                                columnGap: '3px',
                            }}
                        >
                            <Tooltip
                                arrow
                                title={talentName}
                                disableFocusListener={!nameOverflow}
                                disableHoverListener={!nameOverflow}
                                disableTouchListener={!nameOverflow}
                            >
                                <Truncate ref={nameRef}>
                                    <Typography variant="smallHeadline" sx={{ mr: 0.5 }}>
                                        {talentName}
                                    </Typography>
                                </Truncate>
                            </Tooltip>
                            <BullhornIconCta talent={talentData} />
                            <LinkedInIconCta
                                profileUrl={talentData.profileUrl}
                                data-trackid="profile-drawer-header-linkedin-click"
                                aria-label="linkedIn-profile-link"
                            />
                            <IconButton
                                aria-label="open-profile-link"
                                component="a"
                                href={`/talent/${talentData.talentId}?byeBias=${byeBias}&profileId=${talentData.profileId}`}
                                data-trackid="profile-drawer-header-open-full-profile-click"
                                target="_blank"
                                rel="noopener noreferrer"
                                disableRipple
                                sx={{ padding: 0, mb: -0.5 }}
                            >
                                <OpenLinkInNewTabIcon
                                    sx={{ color: 'primary.main', fontSize: 20 }}
                                />
                            </IconButton>
                        </Box>
                        <SearchActions
                            talent={talentData}
                            endIcon={null}
                            actionsContent={<MoreHorizIcon aria-label="actions-icon" />}
                            sx={actionsButtonStyles}
                            disablePortal
                        />
                    </Box>
                    <Tooltip
                        arrow
                        title={talentCardHeader}
                        disableFocusListener={!headerOverflow}
                        disableHoverListener={!headerOverflow}
                        disableTouchListener={!headerOverflow}
                    >
                        <Truncate ref={headerRef}>
                            <Typography variant="smallHeadline" sx={{ fontSize: '16px' }}>
                                {talentCardHeader}
                            </Typography>
                        </Truncate>
                    </Tooltip>
                    <Box sx={{ display: 'flex' }}>
                        <LocationIcon
                            sx={{ fontSize: 20, color: 'rgba(22, 30, 41, 0.32)', mt: 0.5 }}
                        />
                        <Tooltip
                            arrow
                            title={getTalentLocationString(talentData)}
                            disableFocusListener={!locationOverflow}
                            disableHoverListener={!locationOverflow}
                            disableTouchListener={!locationOverflow}
                        >
                            <Truncate ref={locationRef}>
                                <Typography variant="body">
                                    {getTalentLocationString(talentData)}
                                </Typography>
                            </Truncate>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 0,
                    marginLeft: '78px',
                }}
            >
                <ContactDetails talent={talentData} isLoading={false} dataTrackIds={dataTrackIds} />
            </Box>
        </Box>
    );
}

TalentDetails.propTypes = {
    talentData: PropTypes.object.isRequired,
};
