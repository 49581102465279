import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import {
    useLazyGetMediaUrlQuery,
    useDeleteMediaMutation,
    useGetBulkMediaMutation,
} from 'services/knowledgeBases';
import { ATTACHMENT_TYPE } from 'utils/entityEnums';
import { Typography } from 'components/Typography';
import { ManageMediaModal } from './MediaModals/ManageMediaModal';
import { useRemoveMediaConnection } from './MediaModals/hooks/useRemoveMediaConnection';
import { RemoveAttachmentDialog } from './MediaModals/RemoveAttachmentDialog';
import { useShareMediaAccess } from './MediaModals/hooks/useShareMediaAccess';
import { downloadFile } from 'views/UserSettings/utils/download';

const DialogButton = styled(Button)(({ theme }) => ({
    boxSizing: 'border-box',
    textTransform: 'unset',
    padding: theme.spacing(1.2),
    minWidth: theme.spacing(20.5),
    borderRadius: theme.spacing(0.5),
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
}));

const Content = styled(DialogContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: theme.spacing(50),
}));

export function MediaActions({
    attachment,
    anchorEl,
    open,
    onClose,
    talentId,
    showRemoveAttachment,
    showShareAttachment,
    showManageMediaMenu = true,
    showDeleteMenu = true,
    removeProjectAttachment,
    removeAttachmentDialogCopy,
    projectMediaMenu,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isShowingWarning, setIsShowingWarning] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [attachmentDetails, setAttachmentDetails] = useState(null);
    const [isRemoveMediaConnectionDialogOpen, setIsRemoveMediaConnectionDialogOpen] =
        useState(false);

    const [getMediaUrl] = useLazyGetMediaUrlQuery();
    const [deleteMedia] = useDeleteMediaMutation();
    const [getBulkMedia, { isLoading }] = useGetBulkMediaMutation();

    const { canManageMedia, canEditPermissions, canDownloadMedia, canDeleteMedia } =
        useShareMediaAccess({
            access: attachmentDetails?.access,
            owner: attachmentDetails?.owner,
        });

    const { handleRemoveMediaConnection, isLoading: isRemovingMediaConnection } =
        useRemoveMediaConnection(attachmentDetails);

    useEffect(() => {
        async function getMedialDetails() {
            const { data, error } = await getBulkMedia({ mediaIds: [attachment.mediaId] });
            if (data) {
                setAttachmentDetails(data.medias[0]);
            } else if (error) {
                toast.error('Could not retrieve media. Please try again later');
            }
        }

        if (open) {
            getMedialDetails();
        }
    }, [open, getBulkMedia, attachment]);

    const handleDelete = () => {
        setIsShowingWarning(true);
    };

    const handleDismissWarning = () => {
        setIsShowingWarning(false);
    };

    const handleDeletion = async () => {
        setIsDeleting(true);
        const { data, error } = await deleteMedia({
            mediaIds: [attachmentDetails.mediaId],
        });
        setIsDeleting(false);
        if (data) {
            toast.success('Successfully deleted media');
            handleDismissWarning();
            onClose();
        } else if (error?.data?.message?.includes('connected to conversation(s).')) {
            toast.error('Could not delete media as it is connected to one or more conversations.');
        } else {
            toast.error('Could not delete media. Please try again shortly');
        }
    };

    const handleDownload = async () => {
        setIsDownloading(true);
        const res = await getMediaUrl(attachment.mediaId);
        setIsDownloading(false);
        if (res.data) {
            onClose?.();
            downloadFile(res.data.url, attachment?.name);
        } else {
            toast.error('Could not retrieve media URL. Please try again shortly');
        }
    };

    const removeMediaConnection = useCallback(async () => {
        if (projectMediaMenu) {
            removeProjectAttachment();
        } else {
            await handleRemoveMediaConnection(talentId);
        }
        setIsRemoveMediaConnectionDialogOpen(false);
        setIsModalOpen(false);
        onClose?.();
    }, [handleRemoveMediaConnection, onClose, projectMediaMenu, removeProjectAttachment, talentId]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        onClose();
    };

    const handleCloseModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    return (
        <>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {!attachmentDetails && isLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 1,
                            width: '100px',
                        }}
                    >
                        <CircularProgress size={16} />
                    </Box>
                ) : (
                    <Box
                        aria-label="Action menu"
                        sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}
                    >
                        {canDownloadMedia && (
                            <MenuItem onClick={handleDownload} disabled={isDownloading}>
                                {!isDownloading && (
                                    <Typography variant="titleSmall">Download</Typography>
                                )}
                                {isDownloading && (
                                    <>
                                        <Typography variant="titleSmall">Downloading</Typography>
                                        <CircularProgress
                                            size={16}
                                            sx={{ ml: 1, color: 'text.primary' }}
                                        />
                                    </>
                                )}
                            </MenuItem>
                        )}
                        {canManageMedia && showManageMediaMenu && (
                            <MenuItem onClick={handleOpenModal}>
                                <Typography variant="titleSmall">Manage media</Typography>
                            </MenuItem>
                        )}
                        {canEditPermissions && showShareAttachment && (
                            <MenuItem disabled>
                                <Typography variant="titleSmall">Share (Coming soon)</Typography>
                            </MenuItem>
                        )}
                        {(canDeleteMedia || projectMediaMenu) && showRemoveAttachment && (
                            <MenuItem
                                onClick={() => setIsRemoveMediaConnectionDialogOpen(true)}
                                disabled={isRemovingMediaConnection}
                            >
                                <Typography
                                    variant="titleSmall"
                                    aria-label="remove-attachment-item"
                                >
                                    Remove attachment
                                </Typography>
                            </MenuItem>
                        )}
                        {canDeleteMedia && showDeleteMenu && (
                            <MenuItem onClick={handleDelete}>
                                <Typography variant="titleSmall" color="error">
                                    Delete
                                </Typography>
                            </MenuItem>
                        )}
                    </Box>
                )}
            </Popover>
            <Dialog
                open={isShowingWarning}
                aria-label="Confirm deletion"
                sx={{
                    minWidth: (t) => t.spacing(50),
                    minHeight: (t) => t.spacing(18),
                }}
            >
                <Content sx={{ padding: [4, 2, 0, 2] }}>
                    <Typography variant="titleMedium" sx={{ textAlign: 'center' }}>
                        This will delete your file and cannot be
                    </Typography>
                    <Typography
                        variant="titleMedium"
                        sx={{
                            textAlign: 'center',
                            paddingBottom: 0.5,
                        }}
                    >
                        undone. Do you want to continue?
                    </Typography>
                </Content>
                <DialogActions sx={{ justifyContent: 'center', paddingTop: 0 }}>
                    <DialogButton
                        variant="contained"
                        onClick={handleDismissWarning}
                        sx={{
                            backgroundColor: 'inherit',
                            color: 'text.primary',
                            boxShadow: 'none',
                            border: (t) => `1px solid ${t.palette.text.primary}`,
                            '&:hover': {
                                backgroundColor: 'inherit',
                            },
                        }}
                    >
                        <Typography variant="titleSmall">Cancel</Typography>
                    </DialogButton>
                    <DialogButton
                        color="error"
                        variant="contained"
                        onClick={handleDeletion}
                        disabled={isDeleting || isDownloading}
                    >
                        {!isDeleting && <Typography variant="titleSmall">Delete Media</Typography>}
                        {isDeleting && <CircularProgress size={16} sx={{ color: 'white' }} />}
                    </DialogButton>
                </DialogActions>
            </Dialog>
            <ManageMediaModal
                file={attachmentDetails || {}}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                canEditPermissions={canEditPermissions}
            />
            <RemoveAttachmentDialog
                isRemovingConnection={isRemovingMediaConnection}
                onCancel={() => setIsRemoveMediaConnectionDialogOpen(false)}
                onRemove={removeMediaConnection}
                showDialog={isRemoveMediaConnectionDialogOpen}
                dialogCopy={removeAttachmentDialogCopy}
            />
        </>
    );
}

MediaActions.propTypes = {
    attachment: PropTypes.shape(ATTACHMENT_TYPE),
    anchorEl: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    showRemoveAttachment: PropTypes.bool,
    showShareAttachment: PropTypes.bool,
    showManageMediaMenu: PropTypes.bool,
    showDeleteMenu: PropTypes.bool,
    removeProjectAttachment: PropTypes.func,
    projectMediaMenu: PropTypes.bool,
    removeAttachmentDialogCopy: PropTypes.string,
    talentId: PropTypes.string,
};
