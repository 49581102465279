import { styled } from '@mui/material/styles';

import { GradientTypography, TYPOGRAPHY_VARIANTS } from 'components/Typography';
import { ChevronDownIcon, AiIcon } from 'components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMessageGeneration } from 'features/MessageDrawer/messageSlice';
import { useTalentData } from 'features/MessageDrawer/hooks';
import { ButtonBase } from '@mui/material';
import { useStreamMessage } from 'features/MessageDrawer/hooks/useStreamMessage';
import { Tooltip } from 'components/Tooltip';

const tooltipCopy = 'Only available on single messages at the moment';

const StyledButton = styled('div', {
    shouldForwardProp: (prop) => prop !== 'disabled',
})(({ theme, disabled }) => ({
    display: 'flex',
    columnGap: '6px',
    height: '24px',
    alignItems: 'center',
    borderRadius: '6px',
    color: theme.palette.common.white,
    background: disabled ? '#fff' : theme.palette.common.matchInputGradient,
    textTransform: 'none',
    '&:hover': {
        background: disabled ? '#fff' : theme.palette.common.matchInputGradient,
        opacity: 0.9,
    },
    '.Mui-disabled': { opacity: 0.32 },
}));

const ChevronIcon = styled(ChevronDownIcon, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme, isOpen }) => ({
    transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
    }),
    transform: `rotate(${isOpen ? 180 : 0}deg)`,
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(1.5, 1),
        gap: theme.spacing(2),
        ...TYPOGRAPHY_VARIANTS.titleSmall,
        minWidth: theme.spacing(40.5),
    },
}));

export function DraftMessageButton(props) {
    const dispatch = useDispatch();
    const { selectedProfiles } = useTalentData();
    const {
        isGeneratingMessage,
        isMessageGenerationCollapsed: isCollapsed,
        hasGeneratedMessage,
    } = useSelector((state) => state.message);

    const isBulk = selectedProfiles.length > 1;
    const disabled = isBulk || isGeneratingMessage || isCollapsed;

    const { handleGenerateMessage } = useStreamMessage();

    const handleToggleCollapse = () => {
        dispatch(toggleMessageGeneration());
    };

    return (
        <StyledTooltip title={isBulk ? tooltipCopy : ''} placement="top">
            <StyledButton disabled={disabled}>
                <ButtonBase
                    sx={{ height: '100%', padding: '4px 0px 4px 8px', columnGap: '6px' }}
                    onClick={() => handleGenerateMessage()}
                    disabled={selectedProfiles.length > 1 || isGeneratingMessage}
                    aria-label="draft-message-button"
                >
                    <AiIcon
                        data-testid="draft-ai-icon"
                        gradient={disabled}
                        sx={{ fontSize: '17px' }}
                    />
                    <GradientTypography variant="titleSmall" disabled={!disabled}>
                        {hasGeneratedMessage ? 'Regenerate Message' : 'Draft Message'}
                    </GradientTypography>
                </ButtonBase>
                <ButtonBase
                    sx={{
                        height: '100%',
                        padding: '4px 6px 4px 0px',
                    }}
                    onClick={handleToggleCollapse}
                    disableRipple
                    aria-label="draft-message-options-button"
                    disabled={selectedProfiles.length > 1 || isGeneratingMessage}
                >
                    <ChevronIcon
                        data-testid="draft-chevron-icon"
                        isOpen={isCollapsed}
                        gradient={disabled}
                        sx={{ fontSize: '20px' }}
                    />
                </ButtonBase>
            </StyledButton>
        </StyledTooltip>
    );
}
