import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { DateColumn } from 'views/UserSettings/features/YourMedia/components/DateColumn';

import { MediaInfo } from 'views/UserSettings/features/YourMedia/components/MediaInfo';
import { MediaTags } from 'views/UserSettings/features/YourMedia/components/MediaTags';

export function ProfileAttachment(props) {
    const { attachment, talentId } = props;
    const { tags } = attachment;

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1.5,
                justifyContent: 'center',
                alignItems: 'center',
                transform: 'scale(0.95)',
                m: '10px',
            }}
        >
            <Box sx={{ width: '42%', overflow: 'hidden' }}>
                <MediaInfo attachment={attachment} showManageMediaModal={true} />
            </Box>
            <Box sx={{ width: '38%' }}>
                <MediaTags tags={tags} size="small" />
            </Box>
            <Box sx={{ width: '20%' }}>
                <DateColumn
                    attachment={attachment}
                    showActions={true}
                    showRemoveAttachment={true}
                    showShareAttachment={false}
                    talentId={talentId}
                />
            </Box>
        </Box>
    );
}

ProfileAttachment.propTypes = {
    attachment: PropTypes.object,
    talentId: PropTypes.string,
};
