import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const searchBatchActionAdapter = createEntityAdapter({ selectId: (item) => item.talentId });

export const searchBatchActionSlice = createSlice({
    name: 'searchBatchActions',
    initialState: searchBatchActionAdapter.getInitialState(),
    reducers: {
        addSearchSelection: searchBatchActionAdapter.addOne,
        addManySearchSelections: searchBatchActionAdapter.addMany,
        updateSearchSelection: searchBatchActionAdapter.updateOne,
        removeSearchSelection: searchBatchActionAdapter.removeOne,
        removeManySearchSelections: searchBatchActionAdapter.removeMany,
        clearSearchSelections: searchBatchActionAdapter.removeAll,
    },
});

export const {
    addSearchSelection,
    removeSearchSelection,
    clearSearchSelections,
    addManySearchSelections,
    removeManySearchSelections,
    updateSearchSelection,
} = searchBatchActionSlice.actions;

export const {
    selectAll: selectSearchTalent,
    selectIds: selectSearchTalentIds,
    selectById: selectSearchTalentById,
    selectTotal: selectSearchTalentTotal,
} = searchBatchActionAdapter.getSelectors((state) => state.searchBatchActions);

export const searchBatchActionsReducer = searchBatchActionSlice.reducer;
