import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ButtonBase from '@mui/material/ButtonBase';

import { hideTalent } from 'views/Search';
import { Tooltip } from 'components/Tooltip';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { ActionsMenuItem } from 'components/ActionsMenu';
import { notifyError, notifySuccess } from 'utils/notifications';
import { USER_NAVIGATION } from 'views/UserSettings/utils/constants';
import { EyeSlashIcon, OpenLinkInNewTabIcon } from 'components/Icons';
import { useHideTalentInSearchResultsMutation } from 'services/search';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';

function SuccessMessage() {
    return (
        <span>
            Profile added to your&nbsp;
            <ButtonBase
                component={Link}
                to={`/user-settings#${USER_NAVIGATION.HIDDEN_PROFILES.navId}`}
                disableRipple
                aria-label="manage-hidden-talent"
                sx={{ textDecoration: 'underline' }}
                target="_blank"
            >
                Hidden Profiles
                <OpenLinkInNewTabIcon sx={{ fontSize: 'inherit', ml: 1 }} />
            </ButtonBase>
        </span>
    );
}

export function HideFromSearchButton(props) {
    const { talentId } = props;

    const dispatch = useDispatch();

    const [logSearchEvent] = useSearchTelemetry();

    const [hideTalentInSearchResults, { isLoading }] = useHideTalentInSearchResultsMutation();
    const handleHideTalent = async () => {
        const { error } = await hideTalentInSearchResults({
            talentId: talentId,
            body: {
                hide: true,
            },
        });

        if (error) {
            notifyError();
            return;
        }

        notifySuccess(<SuccessMessage />);

        dispatch(hideTalent(talentId));

        logSearchEvent(SEARCH_EVENT_TYPES.HIDE, { profileId: talentId });
    };
    return (
        <Tooltip placement="right" arrow title="This will hide this candidate for all searches">
            <span>
                <ActionsMenuItem
                    aria-label="hide-talent-button"
                    data-trackid="talent-actions-hide"
                    color="error"
                    onClick={handleHideTalent}
                    disabled={isLoading}
                    Icon={EyeSlashIcon}
                    label="Hide"
                    IconProps={{
                        color: 'error.main',
                        fontSize: 20,
                        marginRight: 0.5,
                    }}
                />
            </span>
        </Tooltip>
    );
}

HideFromSearchButton.propTypes = {
    talentId: PropTypes.string,
};
