import SvgIcon from '@mui/material/SvgIcon';

export function ArrowRightIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="M31.968 16.075C31.984 15.499 31.792 14.907 31.344 14.475L20.512 3.67503C19.888 3.05103 18.88 3.05103 18.256 3.67503C17.632 4.29903 17.632 5.30704 18.256 5.93104L26.848 14.491H1.6C0.72 14.491 0 15.195 0 16.091C0 16.987 0.72 17.691 1.6 17.691H26.688L18.272 26.075C17.648 26.699 17.648 27.707 18.272 28.331C18.592 28.651 18.992 28.795 19.408 28.795C19.824 28.795 20.224 28.635 20.544 28.331L31.376 17.531C31.616 17.291 31.776 17.003 31.872 16.715C31.952 16.523 32 16.315 32 16.091L31.968 16.075Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
