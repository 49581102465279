import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import produce from 'immer';
import { styled } from '@mui/system';
import Box from '@mui/system/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StarOutlined } from '@mui/icons-material';

import { CheckBoxSelectedIcon, CheckBoxUndefinedIcon, CooldownIcon } from 'components/Icons';
import { CONTACT_STATUS_MAP } from 'features/TalentCard/utils/constants';
import { cooldownBlue } from 'theme';
import { ChevronDownIcon } from 'components/Icons';
import { formatPhoneNumber, getInitials } from 'utils/formatters';
import { Typography } from 'components/Typography';
import { FindRightContact } from 'components/MessageContent/FindRightContact';
import { useTalentData } from '../hooks';
import { getContactAccessor, getContactValue } from 'utils/helpers';
import { Tooltip } from 'components/Tooltip';
import { MESSAGE_TYPES } from 'utils/constants';

const ConversationAvatar = styled(Avatar)(({ theme }) => ({
    alignSelf: 'flex-end',
    width: theme.spacing(3),
    height: theme.spacing(3),
    background:
        'linear-gradient(180deg, rgba(65, 232, 211, 0) 0%, rgba(65, 232, 211, 0.7) 100%), #3A8DD3',
    '&:hover': {
        cursor: 'pointer',
    },
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '14px',
}));

const AnchorOriginProps = { vertical: 'bottom', horizontal: 'left' };

const CAPTION_OPTIONS = { offset: 1, isUpperCase: false };

const emptyFormat = (value) => {
    return value;
};

export function RecipientPill(props) {
    const { profileIndex, onClick } = props;

    const dropdownRef = useRef();

    const [isOpen, setIsOpen] = useState(false);

    const { selectedProfiles, selectedContacts, handleSetContacts } = useTalentData();

    const { name, talentId, imageUrl } = selectedProfiles[profileIndex];

    const isFrcEnabled = useSelector((state) => state.communications.isFrcEnabled);
    const messageType = useSelector((state) => state.message.messageType);
    const accessor = getContactAccessor(messageType);
    const value = getContactValue(messageType);

    const contacts = selectedContacts[talentId]?.[accessor].data;

    const isSingleTalent = selectedProfiles.length === 1;

    const noAvailableOptions = !Boolean(contacts?.length);

    const numberSelected = useMemo(() => {
        let sum = 0;
        contacts
            ?.filter((contact) => contact.selected)
            .forEach(() => {
                sum++;
            });
        return sum;
    }, [contacts]);

    const additionalSelections = numberSelected - 1;

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const isUsingAll = useMemo(() => {
        return Boolean(contacts?.every((contact) => contact.selected));
    }, [contacts]);

    const firstSelection = useMemo(() => {
        const first = contacts?.find((contact) => Boolean(contact.selected));
        return first;
    }, [contacts]);

    const handleContactToggle = (event) => {
        const index = parseInt(event.target.value);

        const updates = selectedContacts[talentId][accessor].data.map((contact, i) => ({
            ...contact,
            selected: i === index ? !Boolean(contact.selected) : Boolean(contact.selected),
        }));

        handleSetContacts(
            produce(selectedContacts, (staging) => {
                staging[talentId][accessor].data = updates;
            })
        );
    };

    const handleToggleAll = () => {
        const isAll = !isUsingAll;

        const updates = selectedContacts[talentId][accessor].data.map((contact) => ({
            ...contact,
            selected: isAll,
        }));

        handleSetContacts(
            produce(selectedContacts, (staging) => {
                staging[talentId][accessor].data = updates;
            })
        );
    };

    const postfix = additionalSelections >= 1 ? ',' : '';

    const renderContacts = !isFrcEnabled && isSingleTalent && firstSelection;

    const renderInitials =
        isFrcEnabled || !isSingleTalent || (isSingleTalent && numberSelected === 0);

    const Icon = isFrcEnabled ? CheckBoxUndefinedIcon : CheckBoxSelectedIcon;

    const formatter = messageType === MESSAGE_TYPES.EMAIL ? emptyFormat : formatPhoneNumber;
    return (
        <>
            <Tooltip
                title={
                    noAvailableOptions ? `This profile has no ${messageType} options available` : ''
                }
            >
                <Box
                    ref={dropdownRef}
                    aria-label="recipient-pill-container"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        minWidth: (t) => t.spacing(15),
                        height: '24px',
                        borderRadius: '30px',
                        background: 'inherit',
                        border: '1px solid rgba(38, 55, 70, 0.12)',
                        mr: 0.5,
                        mb: 0.25,
                        p: 0.5,
                    }}
                >
                    <Button
                        aria-label={`recipient-button-${talentId}`}
                        disabled={noAvailableOptions}
                        sx={{
                            padding: 0,
                            textTransform: 'none',
                            color: 'text.primary',
                            flex: 1,
                            justifyContent: 'flex-start',
                        }}
                        title={name}
                        onClick={() => {
                            onClick?.(talentId);
                        }}
                    >
                        <ConversationAvatar
                            sx={{ mr: 1 }}
                            src={imageUrl}
                            aria-label="nova-recipient-avatar-mini"
                        >
                            <Typography variant="labelMedium">{getInitials(name, '')}</Typography>
                        </ConversationAvatar>

                        <Typography variant="bodySmall" noWrap>
                            {renderInitials && getInitials(name, ' ', CAPTION_OPTIONS)}
                            {renderContacts && `${formatter(firstSelection[value])}${postfix}`}
                        </Typography>
                    </Button>
                    <Box sx={{ marginLeft: 'auto' }}>
                        {!isFrcEnabled && isSingleTalent && additionalSelections >= 1 && (
                            <Typography variant="bodySmall">
                                {`+${additionalSelections}`}
                            </Typography>
                        )}
                        <ExpandButton
                            disabled={noAvailableOptions}
                            onClick={handleOpen}
                            aria-label="Manage contacts"
                        >
                            <ChevronDownIcon color="primary" sx={{ fontSize: '14px' }} />
                        </ExpandButton>
                    </Box>
                </Box>
            </Tooltip>
            <Popover
                open={isOpen}
                anchorEl={dropdownRef.current}
                onClose={handleClose}
                anchorOrigin={AnchorOriginProps}
            >
                <Box
                    aria-label="Talent contacts"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 1,
                        minWidth: (t) => t.spacing(40),
                    }}
                >
                    <FormControlLabel
                        sx={{ mr: 0 }}
                        control={
                            <Checkbox
                                disabled={isFrcEnabled}
                                checked={isFrcEnabled ? true : isUsingAll}
                                onChange={handleToggleAll}
                                checkedIcon={
                                    <CheckBoxUndefinedIcon
                                        color="success"
                                        sx={{ opacity: isFrcEnabled ? 0.48 : 1 }}
                                    />
                                }
                                inputProps={{ 'aria-label': 'Option: All' }}
                                disableRipple
                            />
                        }
                        label={<Typography variant="titleSmall">All</Typography>}
                        disableTypography
                    />
                    {contacts?.map((source, index) => {
                        const optOut = source.status === CONTACT_STATUS_MAP.OPT_OUT;
                        const { policyViolated, policyViolationReason, isPrimary } = source;
                        const isDisabled = optOut || policyViolated;
                        return (
                            <FormControlLabel
                                key={source.contactId}
                                sx={{ mr: 0 }}
                                control={
                                    <Checkbox
                                        disabled={isFrcEnabled || isDisabled}
                                        value={index}
                                        checked={isFrcEnabled ? true : Boolean(source.selected)}
                                        onChange={handleContactToggle}
                                        checkedIcon={
                                            <Icon
                                                color="success"
                                                sx={{ opacity: isFrcEnabled ? 0.48 : 1 }}
                                            />
                                        }
                                        inputProps={{
                                            'aria-label': `Option: ${source[value]}`,
                                        }}
                                        disableRipple
                                    />
                                }
                                label={
                                    <Tooltip title={policyViolationReason}>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                justifyContent: 'space-between',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="titleSmall"
                                                sx={{
                                                    color: isDisabled
                                                        ? 'text.disabled'
                                                        : 'text.primary',
                                                }}
                                            >
                                                {formatter(source[value])}
                                                {optOut && '- (Opted out)'}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {isPrimary && (
                                                    <StarOutlined color="primary" sx={{ ml: 1 }} />
                                                )}
                                                {policyViolated && (
                                                    <CooldownIcon
                                                        fontSize="small"
                                                        sx={{ ml: 1, color: cooldownBlue }}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </Tooltip>
                                }
                                disableTypography
                            />
                        );
                    })}
                    {isSingleTalent && (
                        <>
                            <Divider />
                            <FindRightContact />
                        </>
                    )}
                </Box>
            </Popover>
        </>
    );
}

RecipientPill.propTypes = {
    profileIndex: PropTypes.number,
    onClick: PropTypes.func,
    contacts: PropTypes.array,
};
