import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import App from './App';
import { theme } from './theme';
import { store } from './store';
import { initializeGTM } from 'gtm';
import reportWebVitals from './reportWebVitals';
import { PendoTracker } from 'features/PendoTracker';
import { PostHogTracker } from 'features/PostHogTracker';
import { KeycloakProvider } from 'features/Authentication';
import { SwitchboardProvider } from 'features/Switchboard';
import { QueryClient, QueryCache, QueryClientProvider } from '@tanstack/react-query';
import { SlateEditorProvider } from 'components/Inputs/RichTextInput/SlateEditorProvider';

/*
 To enable or disable the mock service worker you can run one of the following in you chrome developers tools console

 localStorage.setItem('mswState', 'disabled');
 OR
 localStorage.setItem('mswState', 'enabled');
*/
if (process.env.NODE_ENV === 'development') {
    const mswState = localStorage.getItem('mswState') === 'enabled';
    if (mswState) {
        const { worker } = require('./mocks/browser');
        worker.start();
        window.__mswStop = worker.stop;
    }
}

// Initilialize Google Tag Manager (will only initialize in the Production env)
initializeGTM();

const queryClient = new QueryClient({
    queryCache: new QueryCache({}),
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <KeycloakProvider>
                    <SwitchboardProvider>
                        <BrowserRouter>
                            <ThemeProvider theme={theme}>
                                <SlateEditorProvider>
                                    <App />
                                    <PendoTracker />
                                    <PostHogTracker />
                                </SlateEditorProvider>
                            </ThemeProvider>
                        </BrowserRouter>
                    </SwitchboardProvider>
                </KeycloakProvider>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
