import SvgIcon from '@mui/material/SvgIcon';

export function InterviewIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.77234 15.1633L4.34073 14.6037L4.35072 14.6137C4.88018 14.4338 5.44959 14.7037 5.62941 15.2233C5.80922 15.7429 5.5395 16.3225 5.02003 16.5024L3.19191 17.132C3.07204 17.162 2.96216 17.162 2.85227 17.162H2.85225C2.3228 17.162 1.81332 16.9521 1.41373 16.5823C0.934222 16.1327 0.694466 15.5031 0.764394 14.8436L0.794362 14.6837L1.21393 13.0948C0.234938 11.3959 -0.154663 9.53723 0.0551222 7.63855C0.504661 3.60134 3.77131 0.403537 7.81717 0.0337926C10.8241 -0.226028 13.6911 1.02311 15.5093 3.41146C15.6292 3.57135 15.749 3.74123 15.8589 3.91111C16.1586 4.3708 16.0287 4.99037 15.5692 5.29016C15.1097 5.58996 14.4903 5.46004 14.1906 5.00036L14.1903 4.99994C14.1005 4.87017 14.0107 4.7404 13.9209 4.62062C12.5223 2.78189 10.3046 1.81258 7.99698 2.02244C4.94011 2.30224 2.37275 4.8105 2.03309 7.8584C1.86327 9.31739 2.17295 10.7464 2.9022 11.9955C3.19191 12.4852 3.27182 13.0548 3.13197 13.5944L2.74237 15.0534L2.77234 15.1633ZM10.5887 7.01015H16.3586V7.00016C18.3486 7.00016 19.9685 8.62013 19.9685 10.6101V17.83C19.9685 19 19.4285 19.9999 18.1586 19.9999C17.6077 19.9999 17.017 19.6182 16.4439 19.2479C15.9039 18.8989 15.3795 18.56 14.9186 18.56H10.5887C8.59872 18.56 6.97876 16.94 6.97876 14.95V10.6201C6.97876 8.63013 8.59872 7.01015 10.5887 7.01015ZM17.5286 17.57C17.6586 17.66 17.8186 17.76 17.9686 17.84V10.6201C17.9686 9.73011 17.2486 9.01012 16.3586 9.01012H10.5887C9.6987 9.01012 8.97873 9.73011 8.97873 10.6201V14.95C8.97873 15.84 9.6987 16.56 10.5887 16.56H14.9186C15.9686 16.56 16.8386 17.12 17.5286 17.57Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
