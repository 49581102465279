import SvgIcon from '@mui/material/SvgIcon';

export function SpiralIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" {...props}>
            <path
                d="m25.288 8.41599 2.244-2.256c.468-.468.468-1.22401 0-1.69201s-1.224-.468-1.692 0l-2.244 2.244c-2.064-1.692-4.716-2.71198-7.596-2.71198-6.624 0-12 5.376-12 12s5.376 12 12 12 12-5.376 12-12c0-2.88-1.02-5.52-2.712-7.59602zm-9.288 17.19601c-5.292 0-9.6-4.308-9.6-9.6s4.308-9.60002 9.6-9.60002c2.22 0 4.26.768 5.88 2.028l-1.704 1.70402c-1.176-.82801-2.604-1.32001-4.14-1.32001-3.972 0-7.2 3.21601-7.2 7.20001s3.216 7.2 7.2 7.2 7.2-3.216 7.2-7.2c0-1.56-.504-3-1.344-4.188l1.692-1.704c1.26 1.62 2.028 3.66 2.028 5.868 0 5.292-4.308 9.6-9.6 9.6zm4.836-9.588c0 2.64-2.148 4.8-4.8 4.8s-4.8-2.148-4.8-4.8 2.148-4.8 4.8-4.8c.876 0 1.692.252 2.4.672l-3.252 3.264c-.468.468-.468 1.224 0 1.692.228.228.54.348.852.348s.612-.12.852-.348l3.252-3.276c.432.72.684 1.548.684 2.436z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
