import { createSlice } from '@reduxjs/toolkit';

const PAGE_SIZE_DEFAULT = 10;

export const initialActivityState = {
    activities: {},
    activityParams: {
        page: 1,
        pageSize: PAGE_SIZE_DEFAULT,
    },
    talentIds: [],
    isLoading: true,
};

export const activitySlice = createSlice({
    name: 'activity',
    initialState: initialActivityState,
    reducers: {
        clearActivityState: () => {
            return initialActivityState;
        },
        setActivities: (state, { payload }) => {
            state.activities = payload;
        },
        setTalentIds: (state, { payload }) => {
            state.talentIds = payload;
        },
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setPage: (state, { payload }) => {
            state.activityParams.page = payload;
        },
    },
});

export const { clearActivityState, setActivities, setIsLoading, setTalentIds, setPage } =
    activitySlice.actions;

export const activityReducer = activitySlice.reducer;
