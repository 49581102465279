import SvgIcon from '@mui/material/SvgIcon';

export function InboxIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 18" {...props}>
            <path
                d="M16.3901 5.0051H10.6202C8.63019 5.0051 7.01023 6.62508 7.01023 8.61504V12.945C7.01023 14.9349 8.63019 16.5549 10.6202 16.5549H14.9501C15.9001 16.5549 17.1201 17.9949 18.19 17.9949C19.46 17.9949 20 16.9949 20 15.8249V8.60505C20 6.61508 18.38 4.99511 16.3901 4.99511V5.0051ZM18 15.8349C17.85 15.7549 17.69 15.6549 17.56 15.5649C16.8701 15.1149 16.0001 14.5549 14.9501 14.5549H10.6202C9.73017 14.5549 9.01019 13.835 9.01019 12.945V8.61504C9.01019 7.72506 9.73017 7.00507 10.6202 7.00507H16.3901C17.2801 7.00507 18 7.72506 18 8.61504V15.8349Z"
                fill="white"
            />
            <path
                d="M4.50025 13.195L4.25025 13.355C3.80026 13.645 3.24026 14.005 2.93027 14.005C2.51028 14.005 2.35031 13.845 2.24031 13.715C2.07031 13.485 1.9703 13.115 1.9803 12.675V4.88511C1.9903 3.29514 3.29029 1.99516 4.89026 1.99516H11.1301C11.9501 2.00516 12.7401 2.35515 13.2901 2.97514C13.4301 3.13514 13.5601 3.30514 13.6601 3.48514C13.9301 3.96513 14.5401 4.13512 15.0201 3.86512C15.5001 3.59513 15.6701 2.98514 15.4001 2.50515C15.2301 2.19515 15.0201 1.90516 14.7801 1.63517C13.8501 0.595183 12.5201 0.00518799 11.1402 0.00518799H4.90024C2.20028 0.00518799 0.010326 2.18516 0.000326193 4.88511V12.675C-0.00967364 13.555 0.210317 14.335 0.650309 14.9149C1.1803 15.6249 1.9903 16.0149 2.94028 16.0149C3.84026 16.0149 4.64024 15.4949 5.34023 15.0349L5.57024 14.8849C6.04023 14.5849 6.17023 13.965 5.88023 13.505C5.58024 13.035 4.96024 12.905 4.50025 13.195Z"
                fill="white"
            />
        </SvgIcon>
    );
}
