import SvgIcon from '@mui/material/SvgIcon';

export function StatDeliveredIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M17.556 10.4244L13.944 13.0604C12.792 13.8991 11.244 13.9111 10.08 13.0844L6.42002 10.4723C5.88002 10.0889 5.12404 10.2087 4.75204 10.7479C4.36804 11.2871 4.48802 12.0299 5.02802 12.4134L8.68804 15.0255C9.68404 15.7324 10.848 16.0919 12 16.0919C13.152 16.0919 14.352 15.7204 15.36 14.9895L18.972 12.3535C19.512 11.9581 19.62 11.2152 19.236 10.676C18.84 10.1368 18.096 10.029 17.556 10.4124V10.4244Z"
                fill="currentColor"
            />
            <path
                d="M19.548 6.01496H4.45203C2.00403 6.01496 0 8.00401 0 10.4603V19.5547C0 21.999 1.99203 24 4.45203 24H19.548C21.996 24 24 22.011 24 19.5547V10.4603C24 8.01599 22.008 6.01496 19.548 6.01496ZM21.6 19.5547C21.6 20.681 20.676 21.6036 19.548 21.6036H4.45203C3.32403 21.6036 2.4 20.681 2.4 19.5547V10.4603C2.4 9.33401 3.32403 8.41137 4.45203 8.41137H19.548C20.676 8.41137 21.6 9.33401 21.6 10.4603V19.5547Z"
                fill="currentColor"
            />
            <path
                d="M8.38803 4.82876C9.04803 4.82876 9.58803 4.28956 9.58803 3.63055V2.27658C9.58803 1.61757 9.04803 1.07838 8.38803 1.07838C7.72802 1.07838 7.18802 1.61757 7.18802 2.27658V3.63055C7.18802 4.28956 7.72802 4.82876 8.38803 4.82876Z"
                fill="currentColor"
            />
            <path
                d="M11.988 4.82876C12.648 4.82876 13.188 4.28956 13.188 3.63055V1.1982C13.188 0.539191 12.648 0 11.988 0C11.328 0 10.788 0.539191 10.788 1.1982V3.63055C10.788 4.28956 11.328 4.82876 11.988 4.82876Z"
                fill="currentColor"
            />
            <path
                d="M15.588 4.82876C16.248 4.82876 16.788 4.28956 16.788 3.63055V3.03145C16.788 2.37244 16.248 1.83325 15.588 1.83325C14.928 1.83325 14.388 2.37244 14.388 3.03145V3.63055C14.388 4.28956 14.928 4.82876 15.588 4.82876Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
