import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

const userServiceBaseUrl = 'api/user-mgmt/v1';
const aggregatorBaseUrl = 'api/aggregator/v1';
const subscriptionBaseUrl = 'api/subscription/v1';

export const organizationsApi = createApi({
    reducerPath: 'organizationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['Organizations'],
    endpoints: (builder) => ({
        getSignedInOrganization: builder.query({
            query: () => ({
                url: `${aggregatorBaseUrl}/organizations/me`,
            }),
            providesTags: () => [{ type: 'Organizations', id: 'ME' }],
        }),
        getOrganizations: builder.query({
            query: (params) => ({
                url: `${aggregatorBaseUrl}/organizations`,
                params,
            }),
            providesTags: (data) =>
                data
                    ? [
                          ...data.map(({ id }) => ({ type: 'Organizations', id })),
                          { type: 'Organizations', id: 'LISTS' },
                      ]
                    : [{ type: 'Organizations', id: 'LISTS' }],
        }),
        getPaginatedOrganizations: builder.query({
            query: ({ params, body }) => ({
                url: `${aggregatorBaseUrl}/organizations`,
                method: 'POST',
                params,
                body,
            }),
            providesTags: (data) =>
                data?.results
                    ? [
                          ...data.results.map(({ id }) => ({ type: 'Organizations', id })),
                          { type: 'Organizations', id: 'LISTS' },
                      ]
                    : [{ type: 'Organizations', id: 'LISTS' }],
        }),
        getOrganization: builder.query({
            query: (organizationId) => `${userServiceBaseUrl}/organizations/${organizationId}`,
            providesTags: (result, error, organizationId) => [
                { type: 'OrganizationsById', id: organizationId },
            ],
        }),
        getOrganizationsByName: builder.query({
            query: (organizationName = '') =>
                `${userServiceBaseUrl}/organizations/search?name=${organizationName}`,
        }),
        createOrganization: builder.mutation({
            query: (body) => ({
                url: `${userServiceBaseUrl}/organizations`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Organizations', id: id }],
        }),
        updateOrganization: builder.mutation({
            query: ({ org_id, requestBody }) => {
                return {
                    url: `${userServiceBaseUrl}/organizations/${org_id}`,
                    method: 'PUT',
                    body: requestBody,
                };
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Organizations', id: id }],
        }),
        updateOrganizationAvailableTokens: builder.mutation({
            query: ({ org_id, requestBody }) => {
                return {
                    url: `${subscriptionBaseUrl}/subscriptions/organizations/${org_id}/metrics`,
                    method: 'PUT',
                    body: requestBody,
                };
            },
            transformResponse(baseQueryReturnValue, meta, arg) {
                return { status: meta.response.status, message: baseQueryReturnValue.message, arg };
            },

            invalidatesTags: (result, error, { id }) => [{ type: 'Organizations', id: id }],
        }),
        updateOrganizationUnlimitedTokens: builder.mutation({
            query: ({ orgId, metric, unlimited }) => {
                return {
                    url: `${subscriptionBaseUrl}/subscriptions/organizations/${orgId}/metric/${metric}/unlimited?value=${unlimited}`,
                    method: 'POST',
                };
            },
            invalidatesTags: (result, error, { id }) => [{ type: 'Organizations', id: id }],
        }),

        upgradeRequest: builder.query({
            query: () => 'api/subscription/v1/payments/config',
            transformResponse: (data) => ({
                PRICING_TABLE_ID: data.pricingTableId,
                PUBLISH_KEY: data.publishableKey,
                CUSTOMER_SESSION_ID: data.customerSessionId,
            }),
        }),

        customerPortal: builder.query({
            query: () => 'api/subscription/v1/payments/customer-portal',
        }),

        getOrganizationUsers: builder.query({
            query: (searchTerm = '') => ({
                url: `${userServiceBaseUrl}/users/get_organization_users`,
                params: {
                    startsWith: searchTerm,
                },
            }),
        }),
    }),
});

export const {
    useGetSignedInOrganizationQuery,
    useLazyGetSignedInOrganizationQuery,
    useGetPaginatedOrganizationsQuery,
    useLazyGetOrganizationsQuery,
    useLazyGetOrganizationsByNameQuery,
    useCreateOrganizationMutation,
    useUpdateOrganizationMutation,
    useUpdateOrganizationAvailableTokensMutation,
    useLazyUpgradeRequestQuery,
    useLazyCustomerPortalQuery,
    useUpdateOrganizationUnlimitedTokensMutation,
    useLazyGetOrganizationUsersQuery,
} = organizationsApi;
