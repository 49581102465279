import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

const aggregatorBaseUrl = 'api/aggregator/v1';
export const contactsBaseUrl = 'api/contacts/v1';

export const contactsApi = createApi({
    reducerPath: 'contactsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['Contacts', 'ContactMetadata'],
    endpoints: (builder) => ({
        getContactMetadata: builder.query({
            query: (talentIds) => ({
                url: `${aggregatorBaseUrl}/talent/contact-metadata`,
                method: 'POST',
                body: { talentIds },
            }),
            providesTags: ['Contacts', 'ContactMetadata'], //TODO Good chance Contacts tag should be removed entirely
        }),
        getTalentContacts: builder.query({
            query: (talentIds) => ({
                url: `${contactsBaseUrl}/contacts`,
                method: 'POST',
                body: { talentIds },
            }),
            providesTags: ['Contacts'],
            invalidatesTags: ['ContactMetadata', 'Lookups'],
        }),
        setContactAsPrimary: builder.mutation({
            query: ({ talentId, body, params }) => ({
                url: `${contactsBaseUrl}/contacts/primary/${talentId}`,
                method: 'PUT',
                params,
                body,
            }),
            invalidatesTags: ['Contacts'],
        }),
        unsetContactAsPrimary: builder.mutation({
            query: ({ talentId, body }) => ({
                url: `${contactsBaseUrl}/contacts/primary/${talentId}`,
                method: 'DELETE',
                body,
            }),
            invalidatesTags: ['Contacts'],
        }),
        deleteCustomContact: builder.mutation({
            query: ({ contactId }) => ({
                url: `${contactsBaseUrl}/contacts/custom/${contactId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Contacts'],
        }),
        createCustomContact: builder.mutation({
            query: ({ talentId, payload }) => ({
                url: `${contactsBaseUrl}/contacts/custom/${talentId}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Contacts'],
        }),
        lookupTalentContacts: builder.mutation({
            query: (talentIds) => ({
                url: `${contactsBaseUrl}/contacts/resolve`,
                method: 'POST',
                body: { talentIds },
            }),
        }),
        getCategories: builder.query({
            query: () => ({
                url: `${contactsBaseUrl}/contacts/categories`,
                method: 'GET',
            }),
        }),
        getTalentLookups: builder.query({
            query: ({ talentId, params }) => ({
                url: `${contactsBaseUrl}/contacts/talent/${talentId}/lookups`,
                method: 'GET',
                params,
            }),
            providesTags: ['Lookups'],
        }),
        createCategory: builder.mutation({
            query: ({ talentId, category }) => ({
                url: `${contactsBaseUrl}/contacts/categories/${talentId}/${category}`,
                method: 'PUT',
            }),
            invalidatesTags: ['Contacts'],
        }),
        updateCategoryOrder: builder.mutation({
            query: ({ talentId, body }) => ({
                url: `${contactsBaseUrl}/contacts/categories/${talentId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Contacts'],
        }),
        updateContact: builder.mutation({
            query: ({ contactId, params }) => ({
                url: `${contactsBaseUrl}/contacts/${contactId}`,
                method: 'PUT',
                params,
            }),
            invalidatesTags: ['Contacts'],
        }),
        reorderContact: builder.mutation({
            query: ({ talentId, body }) => ({
                url: `${contactsBaseUrl}/contacts/categories/${talentId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Contacts'],
        }),
        getUserContactSettings: builder.query({
            query: (talentId) => ({
                url: `${contactsBaseUrl}/contacts/settings/${talentId}`,
                method: 'GET',
            }),
            providesTags: ['Contacts'],
        }),
        getBulkUserContactSettings: builder.query({
            query: (talentIds) => ({
                url: `${contactsBaseUrl}/contacts/setting/by_talents`,
                method: 'POST',
                body: { talentIds },
            }),
            providesTags: ['Contacts', 'ContactMetadata'],
        }),
        flagContact: builder.mutation({
            query: ({ talentId, body }) => ({
                url: `${contactsBaseUrl}/contacts/feedback/${talentId}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Contacts'],
        }),
        getContactsResolveTask: builder.query({
            query: ({ taskId }) => ({
                url: `${contactsBaseUrl}/contacts/task/${taskId}`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetTalentContactsQuery,
    useGetTalentLookupsQuery,
    useLazyGetTalentContactsQuery,
    useLookupTalentContactsMutation,
    useLazyGetContactsResolveTaskQuery,
    useUpdateCategoryOrderMutation,
    useUpdateContactMutation,
    useReorderContactMutation,
    useDeleteCustomContactMutation,
    useGetContactMetadataQuery,
    useLazyGetContactMetadataQuery,
    useSetContactAsPrimaryMutation,
    useFlagContactMutation,
    useUnsetContactAsPrimaryMutation,
    useGetCategoriesQuery,
    useCreateCategoryMutation,
    useGetUserContactSettingsQuery,
    useCreateCustomContactMutation,
    useGetBulkUserContactSettingsQuery,
} = contactsApi;
