import { createSlice } from '@reduxjs/toolkit';

export const DEFAULT_PAYLOAD = {
    externalUrl: '',
    pageTitle: '',
};

export const initialScheduleState = {
    isEditing: false,
    payload: DEFAULT_PAYLOAD,
    schedules: [],
    validation: { pageTitle: '', externalUrl: '' },
};

const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: initialScheduleState,
    reducers: {
        setSchedules: (state, { payload }) => {
            state.schedules = payload;
        },
        editSchedule: (state, { payload }) => {
            state.payload = payload.payload;
            state.isEditing = true;
        },
        clearScheduling: () => {
            return initialScheduleState;
        },
        clearEdits: (state) => {
            state.payload = DEFAULT_PAYLOAD;
            state.isEditing = false;
        },
        updatePayload: (state, { payload }) => {
            Object.entries(payload).forEach(([key, value]) => {
                state.payload[key] = value;
            });
        },
        setValidation: (state, { payload }) => {
            Object.entries(payload).forEach(([key, value]) => {
                state.validation[key] = value;
            });
        },
    },
});

export const {
    editSchedule,
    clearScheduling,
    updatePayload,
    setValidation,
    setSchedules,
    clearEdits,
} = scheduleSlice.actions;

export const scheduleReducer = scheduleSlice.reducer;
