import { useMemo } from 'react';

/**
 * Used to filter the items in the actionlist so that:
 * - If they have a search term we only show items that match that
 * - If they don't have access to a certain feature we don't show it in the list.
 */
export const useFilteredItems = (
    productLinkActions,
    devActions,
    adminActions,
    searchTerm,
    featureFlags
) => {
    return useMemo(() => {
        let finalItems = [];
        //Remove any items from the productLinkActions the user doesn't have access to
        const approvedProductLinkActions = productLinkActions.filter((product) => {
            if (!featureFlags.isPathsEnabled && product.name === 'Paths') {
                return false;
            }
            if (!featureFlags.isTalkTrackEnabled && product.name === 'Digital Assistant') {
                return false;
            }
            if (!featureFlags.isProjectsFeatureEnabled && product.name === 'Projects') {
                return false;
            }
            return true;
        });

        if (Boolean(searchTerm)) {
            finalItems.push(
                ...approvedProductLinkActions.filter((product) =>
                    product.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        } else {
            finalItems.push(...approvedProductLinkActions);
        }

        const isDev = process.env.NODE_ENV === 'development';

        if (featureFlags.isNovaQuicklaunchDev || isDev) {
            const enabledInternalActions = devActions.filter((action) => {
                if (action.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    if ('enabled' in action) {
                        return typeof action.enabled === 'function'
                            ? action.enabled()
                            : action.enabled;
                    } else {
                        return true;
                    }
                }
                return false;
            });
            finalItems.push(...enabledInternalActions);
        }

        if (featureFlags.isNovaQuicklaunchAdmin || isDev) {
            const enabledInternalActions = adminActions.filter((action) => {
                if (action.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    if ('enabled' in action) {
                        return typeof action.enabled === 'function'
                            ? action.enabled()
                            : action.enabled;
                    } else {
                        return true;
                    }
                }
                return false;
            });
            finalItems.push(...enabledInternalActions);
        }

        return finalItems;
    }, [productLinkActions, devActions, adminActions, searchTerm, featureFlags]);
};
