import SvgIcon from '@mui/material/SvgIcon';

export function LockedFeatureIcon(props) {
    return (
        <SvgIcon viewBox="0 0 27 27" {...props}>
            <circle cx="13.5" cy="13.5" r="13.5" fill="#E46868" />
            <path
                d="M13.5 7.25C10.0562 7.25 7.25 10.0562 7.25 13.5C7.25 16.9438 10.0562 19.75 13.5 19.75C16.9438 19.75 19.75 16.9438 19.75 13.5C19.75 10.0562 16.9438 7.25 13.5 7.25ZM18.5 13.5C18.5 14.55 18.175 15.525 17.6188 16.3312L10.2188 9.7375C11.1 8.96875 12.2438 8.5 13.5 8.5C16.2563 8.5 18.5 10.7438 18.5 13.5ZM8.5 13.5C8.5 12.45 8.82498 11.475 9.38123 10.6688L16.7812 17.2625C15.9 18.0313 14.7562 18.5 13.5 18.5C10.7438 18.5 8.5 16.2563 8.5 13.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
