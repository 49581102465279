import TagManager from 'react-gtm-module';

// This is workaround since we don't have proper environment variables in the frontend
const domain = window.location.host;
const envName = domain.split('.')[0];
const isProd = envName === 'app';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
};

export function initializeGTM() {
    if (isProd) {
        TagManager.initialize(tagManagerArgs);
    }
}
