import { useCallback } from 'react';

import { useReorderContactMutation } from 'services/contacts';

export function useReorderContact() {
    const [reorderContact] = useReorderContactMutation();

    const reorderContactsWithinCategory = useCallback(
        async (args, toast, onProcessing) => {
            const { dst, src, orderedContactIds, talentId } = args;
            if (dst === src) {
                const body = { categoryName: dst, contacts: orderedContactIds };
                onProcessing?.(true);
                const res = await reorderContact({ talentId, body });
                if (res?.error) {
                    toast?.error(
                        `Could not reorder contacts within '${body.categoryName}' category. Please try again soon`
                    );
                }
                if (!res?.error) {
                    toast?.success('Successfully reordered');
                }
                onProcessing?.(false);
            }
        },
        [reorderContact]
    );

    return { reorderContactsWithinCategory };
}
