import { useSelector } from 'react-redux';
import some from 'lodash/some';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Typography } from 'components/Typography';
import { ACCESS_OPTIONS, PERMISSIONS_OPTIONS } from 'utils/entityEnums';
import { MediaSectionLabel } from './MediaSectionLabel';
import { ChevronDownIcon, ChevronUpIcon } from 'components/Icons';
import { AccessControlDropdown } from './AccessControlDropdown';
import { PermissionsControlDropdown } from './PermissionsControlDropdown';
import { useDialog } from 'components/Dialog';
import { AccessPermissionsWarningModal } from './AccessPermissionsWarningModal';

export function FileAccessControl(props) {
    const { file, access, onChangeAccess, onChangePermissions } = props;

    const user = useSelector((state) => state.user);
    const { organization } = user;

    const orgAccess = file?.access?.org_access?.[organization.id];

    const [accessControlEl, setAccessControlAnchor] = useState(null);
    const [accessValue, setAccessValue] = useState(() => {
        if (orgAccess && some(orgAccess, Boolean)) {
            return ACCESS_OPTIONS.ORG.value;
        }
        return ACCESS_OPTIONS.YOU.value;
    });

    const [permissionsControlEl, setPermissionsControlAnchor] = useState(null);
    const [permissionsValue, setPermissionsValue] = useState(() => {
        if (orgAccess?.edit) {
            return PERMISSIONS_OPTIONS.EDITOR.label.toUpperCase();
        }
        if (orgAccess?.view) {
            return PERMISSIONS_OPTIONS.VIEWER.label.toUpperCase();
        }
        return PERMISSIONS_OPTIONS.EDITOR.label.toUpperCase();
    });

    const handleOpenAccessMenu = (event) => {
        setAccessControlAnchor(event.currentTarget);
    };

    const handleCloseAccessMenu = () => {
        setAccessControlAnchor(null);
    };

    const onConfirm = () => {
        setAccessValue(ACCESS_OPTIONS.YOU.value);
        onChangeAccess?.(ACCESS_OPTIONS.YOU.value);
        handleCloseAccessMenu();
    };

    const { isOpen, handleOpen, handleConfirm, handleClose } = useDialog({
        onConfirm,
    });

    const handleChangeAccessValue = (value) => {
        if ((orgAccess?.edit || orgAccess?.view) && access && value === ACCESS_OPTIONS.YOU.value) {
            handleOpen();
        } else {
            setAccessValue(value);
            onChangeAccess?.(value);
            handleCloseAccessMenu();
        }
    };

    const handleOpenPermissionsMenu = (event) => {
        setPermissionsControlAnchor(event.currentTarget);
    };

    const handleClosePermissionsMenu = () => {
        setPermissionsControlAnchor(null);
    };

    const handleChangePermissionsValue = (value) => {
        setPermissionsValue(value === PERMISSIONS_OPTIONS.EDITOR.value ? 'EDITOR' : 'VIEWER');
        onChangePermissions?.(value);
        handleClosePermissionsMenu();
    };

    useEffect(() => {
        return () => {
            setPermissionsControlAnchor(null);
            setPermissionsValue(() => {
                if (orgAccess?.view) {
                    return PERMISSIONS_OPTIONS.VIEWER.label.toUpperCase();
                }
                return PERMISSIONS_OPTIONS.EDITOR.label.toUpperCase();
            });
        };
    }, [orgAccess]);

    const Icon = ACCESS_OPTIONS[accessValue].icon;
    const isAccessControlOpen = Boolean(accessControlEl);
    const isPermissionsControlOpen = Boolean(permissionsControlEl);

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <MediaSectionLabel as="label" htmlFor="displayName">
                    Access
                </MediaSectionLabel>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Button
                            data-testid="access-menu-button"
                            sx={{ color: 'black', textTransform: 'none' }}
                            onClick={handleOpenAccessMenu}
                        >
                            <Typography
                                sx={{ display: 'inline-flex', alignItems: 'center' }}
                                variant="titleSmall"
                            >
                                <Icon sx={{ fontSize: 14, mr: 0.5 }} />
                                {ACCESS_OPTIONS[accessValue].selectionCopy}
                                {isAccessControlOpen ? (
                                    <ChevronUpIcon sx={{ ml: 0.5, fontSize: 14 }} />
                                ) : (
                                    <ChevronDownIcon sx={{ ml: 0.5, fontSize: 14 }} />
                                )}
                            </Typography>
                        </Button>
                        <AccessControlDropdown
                            open={isAccessControlOpen}
                            anchorEl={accessControlEl}
                            onClose={handleCloseAccessMenu}
                            onChangeValue={handleChangeAccessValue}
                        />
                        <Typography variant="bodySmall" color="text.secondary">
                            {ACCESS_OPTIONS[accessValue].subtitleCopy}
                        </Typography>
                    </Box>
                    {accessValue === ACCESS_OPTIONS.ORG.value && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                ml: 'auto',
                            }}
                        >
                            <Button
                                data-testid="permissions-menu-button"
                                sx={{ color: 'black', textTransform: 'none' }}
                                onClick={handleOpenPermissionsMenu}
                            >
                                <Typography
                                    sx={{ display: 'inline-flex', alignItems: 'center' }}
                                    variant="titleSmall"
                                >
                                    {PERMISSIONS_OPTIONS[permissionsValue].label}
                                    {isAccessControlOpen ? (
                                        <ChevronUpIcon sx={{ ml: 0.5, fontSize: 14 }} />
                                    ) : (
                                        <ChevronDownIcon sx={{ ml: 0.5, fontSize: 14 }} />
                                    )}
                                </Typography>
                            </Button>
                            <PermissionsControlDropdown
                                open={isPermissionsControlOpen}
                                anchorEl={permissionsControlEl}
                                onClose={handleClosePermissionsMenu}
                                onChangeValue={handleChangePermissionsValue}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <AccessPermissionsWarningModal
                isOpen={isOpen}
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
        </>
    );
}

FileAccessControl.propTypes = {
    file: PropTypes.object,
    access: PropTypes.string,
    onChangeAccess: PropTypes.func,
    onChangePermissions: PropTypes.func,
};
