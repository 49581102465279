import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { DrawerHeader } from './components/DrawerContent/DrawerHeader';
import { DrawerMinimizeButton } from './components/DrawerContent/DrawerMinimizeButton';
import { useContactsProcessing, useMessageDrawer } from './hooks';
import { useCallbackRef } from 'hooks/useCallbackRef';
import { DrawerMainContent } from './components/DrawerContent/DrawerMainContent';
import { TalentProfileDrawerContent } from 'features/TalentProfileDrawer/TalentProfileDrawerContent';
import { Divider } from '@mui/material';
import { useEffect, useRef } from 'react';
import { DrawerFooter } from './components/DrawerContent/DrawerFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setClearInput } from './messageSlice';
import { useSidebar } from 'hooks/useSidebar';

const Content = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'xl',
})(({ theme, xl }) => ({
    width: theme.spacing(xl ? 120 : 60),
    display: 'flex',
    height: 'calc(100vh - 56px)',
}));

const TalentProfileContentWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'xl',
})(({ theme, xl }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: theme.spacing(1),
    zIndex: 102,
    position: 'relative',
    overflowX: 'hidden',
    width: xl ? '100%' : '53%',
    overflow: 'auto',
}));

export function MessageDrawer() {
    const {
        isOpen: showMessageSection,
        isMinimized,
        handleClose,
        handleMinimizeToggle,
        showTalentProfile,
        closeTalentProfile,
    } = useMessageDrawer();
    useContactsProcessing({ skip: !showMessageSection });
    const [ref, , , recompute, , clientWidth] = useCallbackRef();
    const mentionEditorRef = useRef(null);
    const { body, subject } = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const isMessageComposeFormDirty =
        body.innerText.trim() !== '' || subject.innerText.trim() !== '';

    const isOpen = showMessageSection || showTalentProfile;

    const { setCollapsed } = useSidebar();

    const handleCloseDrawer = (override) => {
        if (isMessageComposeFormDirty) {
            const userConfirmed = window.confirm('Are you sure? Your progress will be lost');
            if (userConfirmed) {
                handleClose(override);
                dispatch(setClearInput(true));
            }
        } else {
            handleClose(override);
            dispatch(setClearInput(true));
        }
    };

    const handleCloseMessages = () => {
        handleCloseDrawer({ showTalentProfile, isMinimized: !showTalentProfile });
    };

    useEffect(() => {
        recompute();
    }, [showTalentProfile]);

    useEffect(() => {
        setCollapsed(showTalentProfile);
    }, [showTalentProfile, setCollapsed]);

    return (
        <Drawer
            data-testid="message-drawer"
            sx={{ pointerEvents: 'none', position: 'relative' }}
            PaperProps={{
                sx: {
                    pointerEvents: 'all',
                    right: isMinimized ? `-${clientWidth}px` : 0,
                    transition:
                        'right 200ms, transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important',
                    height: 'calc(100vh - 56px)',
                    bottom: 0,
                    top: 'unset',
                    overflow: 'hidden',
                    boxShadow:
                        '0px 1px 2px 0px rgba(63, 83, 100, 0.32), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
                },
                ref: ref,
            }}
            ModalProps={{ disableScrollLock: true }}
            open={isOpen}
            onClose={handleCloseDrawer}
            anchor="right"
            hideBackdrop
            disableScrollLock
            disableEnforceFocus
        >
            <DrawerMinimizeButton
                isMinimized={isMinimized}
                onMinimizeToggle={handleMinimizeToggle}
            />
            <Content xl={showTalentProfile && showMessageSection}>
                {showTalentProfile && (
                    <TalentProfileContentWrapper xl={!showMessageSection}>
                        <TalentProfileDrawerContent
                            onClose={closeTalentProfile}
                            mentionEditorRef={mentionEditorRef}
                            inMessageDrawer={true}
                        />
                    </TalentProfileContentWrapper>
                )}
                {showTalentProfile && <Divider orientation="vertical" flexItem />}
                {showMessageSection && (
                    <Box
                        sx={(theme) => ({
                            width: showTalentProfile ? '50%' : '100%',
                            padding: theme.spacing(2, 3),
                            position: 'relative',
                        })}
                    >
                        <Box
                            sx={{
                                overflowY: 'auto',
                                height: 'calc(100vh - 124px)',
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '16px',
                            }}
                        >
                            <DrawerHeader onClose={handleCloseMessages} />
                            <DrawerMainContent />
                        </Box>
                        <DrawerFooter isMessageComposeFormDirty={isMessageComposeFormDirty} />
                    </Box>
                )}
            </Content>
        </Drawer>
    );
}
