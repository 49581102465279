import React, { useEffect, forwardRef, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material';
import { Typography } from 'components/Typography';
import isEmpty from 'lodash/isEmpty';
import { getValueByMode } from 'utils/copy';
import { Note } from '../Note/Note';
import { NotesFooter } from '../NotesFooter/NotesFooter';
import { useNotesStore } from 'features/TalentNotes/store/useNotesStore';
import { NOTES_ENV_URLS } from 'utils/constants';
import { useNovaEnv } from 'features/Switchboard';

const NotesContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isInProfileDrawer',
})(({ theme, isInProfileDrawer }) => ({
    paddingTop: theme.spacing(1.5),
    height: `calc(100% - ${isInProfileDrawer ? '345px' : '280px'})`,
    minHeight: '280px',
    overflowY: 'scroll',
    marginBottom: '100px',
}));

const blinkAnimation = `
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;
const BlinkingBox = styled(Box)(() => blinkAnimation);

export const NotesCanvas = forwardRef(
    (
        {
            handleOptimisticDelete,
            notes,
            loggedInUser,
            scrollToNoteId,
            mentionEditorRef,
            updateNotes,
            isInProfileDrawer,
            talentId,
            source,
            sourceId,
        },
        ref
    ) => {
        const noteRef = useRef(null);
        const notesCanvasRef = useRef(null);
        const [shouldOutlineScrolledNote, setShouldOutlineScrolledNote] = useState(false);
        const { setNotesEnvUrl, notesEnvUrl } = useNotesStore((state) => state);

        const { name: envName } = useNovaEnv();
        const envUrl = NOTES_ENV_URLS[envName];

        const handleAddNoteRef = (ref, id) => {
            if (id === scrollToNoteId) {
                noteRef.current = ref;
            }
        };

        useEffect(() => {
            if (scrollToNoteId && noteRef.current && !isEmpty(notes) && ref.current) {
                setShouldOutlineScrolledNote(true);
                noteRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });
                setTimeout(() => {
                    setShouldOutlineScrolledNote(false);
                }, 5000);
                ref.current = false;
            }
        }, [notes, ref, scrollToNoteId]);

        useEffect(() => {
            if (!notesEnvUrl) {
                setNotesEnvUrl(envUrl);
            }
        }, []);

        const shouldOutlineScrolledNoteFunc = (_id) =>
            shouldOutlineScrolledNote && _id === scrollToNoteId;

        return (
            <NotesContainer
                ref={notesCanvasRef}
                isInProfileDrawer={isInProfileDrawer}
                arial-label="notes-canvas"
            >
                {notes.length === 0 ? (
                    <Box
                        sx={{
                            display: 'flex',
                            height: '100%',
                            minHeight: '280px',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="bodySmall" sx={{ color: 'rgba(38, 55, 70, 0.48)' }}>
                            You have no notes for this candidate.
                        </Typography>
                    </Box>
                ) : (
                    notes.map(
                        (note) =>
                            !isEmpty(note) && (
                                <BlinkingBox
                                    key={note?._id}
                                    ref={(ref) => handleAddNoteRef(ref, note?._id)}
                                    data-testid="blinkin-box"
                                    sx={{
                                        border: getValueByMode(
                                            shouldOutlineScrolledNoteFunc(note?._id),
                                            `3px solid ${alpha('#FF7F50', 0.5)}`,
                                            'none'
                                        ),
                                        borderRadius: '4px',
                                        marginBottom: (theme) => theme.spacing(1.5),
                                        marginLeft: (theme) =>
                                            isInProfileDrawer ? theme.spacing(2) : 0,
                                        marginRight: (theme) =>
                                            isInProfileDrawer ? theme.spacing(1.5) : 0,
                                        animation: getValueByMode(
                                            shouldOutlineScrolledNoteFunc(note?._id),
                                            'blink 1s infinite',
                                            'none'
                                        ),
                                    }}
                                >
                                    <Note
                                        note={note}
                                        handleOptimisticDelete={handleOptimisticDelete}
                                        loggedInUser={loggedInUser}
                                        ref={notesCanvasRef}
                                        mentionEditorRef={mentionEditorRef}
                                        scrollToNoteId={scrollToNoteId}
                                    />
                                </BlinkingBox>
                            )
                    )
                )}
                <NotesFooter
                    talentId={talentId}
                    notes={notes}
                    updateNotes={updateNotes}
                    notesCanvasRef={notesCanvasRef}
                    source={source}
                    sourceId={sourceId}
                />
            </NotesContainer>
        );
    }
);

NotesCanvas.propTypes = {
    notes: PropTypes.array.isRequired,
    handleOptimisticDelete: PropTypes.func.isRequired,
    talentId: PropTypes.string.isRequired,
    updateNotes: PropTypes.func.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    scrollToNoteId: PropTypes.string,
    isInProfileDrawer: PropTypes.bool,
    mentionEditorRef: PropTypes.object.isRequired,
    sourceId: PropTypes.string,
    source: PropTypes.string,
};
