import { useEffect, useState } from 'react';
import { useGetMessageTemplatesQuery } from 'services/conversations';

export function useBulkMessageTemplateText(props) {
    const { showBulkMessageText, onSuccess, shouldRemoveBulkMessageText, onRemoveBulkMessageText } =
        props;
    const [bulkMessageText, setBulkMessageText] = useState('');

    const { data: bulkMessageTemplateText, isLoading } = useGetMessageTemplatesQuery(
        {
            name: 'Prepended bulk message text Template',
            templateType: 'system_template',
        },
        {
            skip: !showBulkMessageText,
        }
    );

    useEffect(() => {
        if (!isLoading && Boolean(bulkMessageTemplateText?.length)) {
            let text = bulkMessageTemplateText[0]?.body || '';
            text = text.length ? text + '\n\n' : text;
            setBulkMessageText(text);
            if (showBulkMessageText) {
                onSuccess?.(text);
            } else if (!showBulkMessageText && shouldRemoveBulkMessageText) {
                onRemoveBulkMessageText?.(text);
            }
        }
    }, [
        bulkMessageTemplateText,
        bulkMessageTemplateText?.length,
        bulkMessageText,
        showBulkMessageText,
        isLoading,
        onSuccess,
        shouldRemoveBulkMessageText,
        onRemoveBulkMessageText,
    ]);

    return {
        bulkMessageText,
        isLoading,
    };
}
