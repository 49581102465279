import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { FileInfo } from './FileInfo';
import { FileTags } from './FileTags';
import { FileDisplayName } from './FileDisplayName';
import { getMediaType } from 'views/UserSettings/utils/getMediaType';
import { SuggestedTags } from './SuggestedTags';

export function NewSingleMediaUpload({
    attachment,
    onDisplayNameChange,
    onTagChange,
    displayName,
    tags,
    tagOptions,
    onTagInputValue,
    onTagInputEnter,
    onReplaceAttachment,
    onSuggestedTagClick,
}) {
    return (
        <Box>
            <Box sx={{ display: 'flex', mb: 3 }}>
                <FileInfo
                    onReplaceAttachment={onReplaceAttachment}
                    filename={attachment?.filename}
                    size={attachment?.size}
                    mediaType={getMediaType(attachment?.mimetype)}
                    mediaId={attachment?.handle}
                />
            </Box>

            <Box mb={1}>
                <Box sx={{ marginBottom: 3 }}>
                    <FileDisplayName
                        displayName={displayName}
                        filename={attachment?.filename}
                        onChange={onDisplayNameChange}
                        mediaId={attachment?.handle}
                    />
                </Box>
                <Box sx={{ mb: 3 }}>
                    <FileTags
                        tagOptions={tagOptions}
                        selectedValues={tags || []}
                        onValueChange={onTagChange}
                        onInputChange={onTagInputValue}
                        onInputEnter={onTagInputEnter}
                        mediaId={attachment?.handle}
                    />
                    {!isEmpty(attachment?.suggestedTags) && (
                        <Box sx={{ mt: 1 }}>
                            <SuggestedTags
                                suggestedTags={attachment.suggestedTags}
                                onSuggestedTagClick={onSuggestedTagClick}
                                handle={attachment.handle}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

NewSingleMediaUpload.propTypes = {
    displayName: PropTypes.string,
    onReplaceAttachment: PropTypes.func,
    attachment: PropTypes.object,
    tags: PropTypes.array,
    tagOptions: PropTypes.array,
    onDisplayNameChange: PropTypes.func,
    onTagChange: PropTypes.func,
    onTagInputValue: PropTypes.func,
    onTagInputEnter: PropTypes.func,
    onSuggestedTagClick: PropTypes.func,
};
