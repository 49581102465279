import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { TEST_IDS } from 'utils/constants';
import { Typography } from 'components/Typography';
import { CONTACT_STATUS_MAP } from '../utils/constants';
import { ContactStatusBadge } from './ContactStatusBadge.styled';
import { CopyContactButton } from './CopyContactButton';

export function EmailListItem({
    contactData,
    contact,
    isCustomContact,
    isVerified,
    isReported,
    isNewContact,
}) {
    const { status } = contact;

    const labelId = `checkbox-label-${contact.contactId}`;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography data-testid={TEST_IDS.EMAIL_SECONDARY_VIEW} variant="body" id={labelId}>
                {contactData}
            </Typography>
            <Box sx={{ ml: 1 }}>
                <CopyContactButton ariaLabel="copy-email-address" contactData={contactData} />
            </Box>
            {isNewContact && <ContactStatusBadge label="New" color="success" />}
            {isCustomContact && <ContactStatusBadge label="Custom" color="text" />}
            {isVerified && <ContactStatusBadge label="Verified" color="success" />}
            {status === CONTACT_STATUS_MAP.INVALID && (
                <ContactStatusBadge label="Bounced" color="error" />
            )}
            {isReported && <ContactStatusBadge label="Reported" color="error" sx={{ mt: 0.4 }} />}
        </Box>
    );
}

EmailListItem.propTypes = {
    contactData: PropTypes.string.isRequired,
    contact: PropTypes.shape({
        contactId: PropTypes.string.isRequired,
        status: PropTypes.oneOf(Object.values(CONTACT_STATUS_MAP)),
    }).isRequired,
    isCustomContact: PropTypes.bool,
    isVerified: PropTypes.bool,
    isReported: PropTypes.bool,
    isNewContact: PropTypes.bool,
};
