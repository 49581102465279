import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';

import { MESSAGE_TYPES } from 'utils/constants';
import { CONTACT_STATUS_MAP } from 'features/TalentCard/utils/constants';
import { CONTACT_LABEL_ENUMS } from './enums';

const isEligible = (contact) => {
    return contact.status !== CONTACT_STATUS_MAP.OPT_OUT;
};

export const processContactData = (contacts, contactSettings) => {
    const contactDataCopy = cloneDeep(contacts);
    forEach(contactSettings, (setting) => {
        const talentContacts = contactDataCopy[setting.talentId];
        const primaryEmail = setting.primary?.EMAIL;
        const primaryPhone = setting.primary?.PHONE;
        if (primaryEmail) {
            const index = findIndex(talentContacts?.emails?.data, (email) => {
                return email.contactId === primaryEmail;
            });
            if (talentContacts?.emails?.data?.[index]) {
                contactDataCopy[setting.talentId].emails.data[index]['isPrimary'] = true;
            }
        }
        if (primaryPhone) {
            const index = findIndex(
                talentContacts?.phones?.data,
                (phone) => phone.contactId === primaryPhone
            );
            if (talentContacts?.phones?.data?.[index]) {
                contactDataCopy[setting.talentId].phones.data[index]['isPrimary'] = true;
            }
        }
    });
    return contactDataCopy;
};

const determineContactDataPath = (talent, contactChannel) => {
    if (talent?.[contactChannel]?.data) {
        return talent[contactChannel].data;
    }
    return talent[contactChannel];
};

export const processPolicyData = (contacts, policySettings, messageType) => {
    const contactDataCopy = cloneDeep(contacts);
    const contactChannels = [];
    if (messageType === MESSAGE_TYPES.EMAIL) {
        contactChannels.push('emails');
    }
    if (messageType === MESSAGE_TYPES.SMS) {
        contactChannels.push('phones');
    }
    if (!messageType) {
        contactChannels.push('emails');
        contactChannels.push('phones');
    }

    forEach(contactDataCopy, (talent, talentId) => {
        contactChannels.forEach((contactChannel) => {
            const messageType =
                contactChannel === CONTACT_LABEL_ENUMS.EMAIL
                    ? MESSAGE_TYPES.EMAIL
                    : MESSAGE_TYPES.SMS;
            const contactPolicies = policySettings?.[talentId]?.[messageType]?.contactDetails;
            const contactPath = determineContactDataPath(talent, contactChannel);

            const policyUpdate = contactPath.map((contact) => {
                const medium = contact.contactMedium.toLowerCase();
                const policy = contactPolicies?.[contact[medium]];
                if (policy) {
                    return {
                        ...contact,
                        policyViolated: policy.policyViolated,
                        policyViolationReason: policy.policyViolationReason || '',
                        selected:
                            !Boolean(policy.policyViolated) &&
                            Boolean(contact.isPrimary) &&
                            isEligible(contact),
                    };
                }
                return {
                    ...contact,
                    policyViolated: false,
                    policyViolationReason: '',
                    selected: Boolean(contact.isPrimary) && isEligible(contact),
                };
            });

            if (talent[contactChannel]?.data) {
                talent[contactChannel].data = policyUpdate;
            } else {
                talent[contactChannel] = policyUpdate;
            }
        });
    });
    return contactDataCopy;
};
