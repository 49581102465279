import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/utils';

export const switchboardApi = createApi({
    reducerPath: 'switchboardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/switchboard/',
        prepareHeaders: async (headers, { getState }) => {
            await prepareAuthHeaders(headers, { getState });
            return headers;
        },
    }),
    tagTypes: ['AdminFeatureSwitches', 'FeatureSwitches'],
    endpoints: (builder) => ({
        getFeatureSwitches: builder.query({
            query: () => ({
                url: 'v1/switchboard',
            }),
            providesTags: ['FeatureSwitches'],
        }),
        getEnvironmentName: builder.query({
            query: () => ({
                url: 'current-env',
                responseHandler: 'text',
            }),
        }),
        getKeycloakUrl: builder.query({
            query: () => ({
                url: 'keycloak-client-url',
                responseHandler: 'text',
            }),
        }),
        getAdminFeatureSwitches: builder.query({
            query: (params) => {
                return {
                    url: 'v1/switchboard/admin',
                    params,
                };
            },
            providesTags: (result, error, { org, user }) => [
                { type: 'AdminFeatureSwitches', id: org || user },
            ],
        }),
        updateFeatureOverride: builder.mutation({
            query: ({ feature, body }) => ({
                url: `v1/switchboard/${feature}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { org, user }) => [
                { type: 'AdminFeatureSwitches', id: org || user },
            ],
        }),
        deleteFeatureOverride: builder.mutation({
            query: ({ feature, ...params }) => ({
                url: `v1/switchboard/${feature}`,
                method: 'DELETE',
                params,
            }),
            invalidatesTags: (result, error, { org, user }) => [
                { type: 'AdminFeatureSwitches', id: org || user },
            ],
        }),
    }),
});

export const {
    useGetEnvironmentNameQuery,
    useLazyGetFeatureSwitchesQuery,
    useGetKeycloakUrlQuery,
    useUpdateFeatureOverrideMutation,
    useDeleteFeatureOverrideMutation,
    useGetAdminFeatureSwitchesQuery,
} = switchboardApi;
