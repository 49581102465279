import SvgIcon from '@mui/material/SvgIcon';

export function DragIcon(props) {
    return (
        <SvgIcon viewBox="0 0 18 44" {...props}>
            <path
                d="M6.74983 20.7999C6.08982 20.7999 5.5498 21.3399 5.5498 22C5.5498 22.66 6.08982 23.2 6.74983 23.2C7.40984 23.2 7.94986 22.66 7.94986 22C7.94986 21.3399 7.40984 20.7999 6.74983 20.7999Z"
                fill="currentColor"
            />
            <path
                d="M6.74983 25.5999C6.08982 25.5999 5.5498 26.14 5.5498 26.8C5.5498 27.46 6.08982 28 6.74983 28C7.40984 28 7.94986 27.46 7.94986 26.8C7.94986 26.14 7.40984 25.5999 6.74983 25.5999Z"
                fill="currentColor"
            />
            <path
                d="M6.74983 18.4C7.40984 18.4 7.94986 17.86 7.94986 17.2C7.94986 16.54 7.40984 16 6.74983 16C6.08982 16 5.5498 16.54 5.5498 17.2C5.5498 17.86 6.08982 18.4 6.74983 18.4Z"
                fill="currentColor"
            />
            <path
                d="M11.2498 20.7999C10.5898 20.7999 10.0498 21.3399 10.0498 22C10.0498 22.66 10.5898 23.2 11.2498 23.2C11.9098 23.2 12.4499 22.66 12.4499 22C12.4499 21.3399 11.9098 20.7999 11.2498 20.7999Z"
                fill="currentColor"
            />
            <path
                d="M11.2498 25.5999C10.5898 25.5999 10.0498 26.14 10.0498 26.8C10.0498 27.46 10.5898 28 11.2498 28C11.9098 28 12.4499 27.46 12.4499 26.8C12.4499 26.14 11.9098 25.5999 11.2498 25.5999Z"
                fill="currentColor"
            />
            <path
                d="M11.2498 18.4C11.9098 18.4 12.4499 17.86 12.4499 17.2C12.4499 16.54 11.9098 16 11.2498 16C10.5898 16 10.0498 16.54 10.0498 17.2C10.0498 17.86 10.5898 18.4 11.2498 18.4Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
