import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from 'components/Typography';
import { Link, useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';

import { NavbarButton } from './NavbarButton';

const StyledMenu = styled(Menu, {
    shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.text.primary,
        borderTop: `${theme.spacing(0.5)} solid ${theme.palette.success.main}`,
        paddingRight: theme.spacing(2),
        color: theme.palette.common.white,
        minWidth: 200,
        borderRadius: theme.spacing(0, 0, 1, 1),
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
}));

export function NavbarMenu(props) {
    const { menuOptions, label, Icon, renderMenuItems, ...restProps } = props;

    const location = useLocation();
    const validMenuOptions = menuOptions?.filter((menuOption) => !isEmpty(menuOption));
    const activeOption = validMenuOptions?.find((menuOption) =>
        location.pathname.startsWith(`/${menuOption.to}`)
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <NavbarButton
                sx={{
                    bgcolor: open
                        ? (theme) => alpha(theme.palette.common.charcoal, 0.48)
                        : 'inherit',
                    '&::after': {
                        width: open || !Boolean(activeOption) ? '0' : '100%',
                    },
                    '&:hover': {
                        '&::after': {
                            width: open ? '0' : '100%',
                        },
                    },
                }}
                disableRipple
                startIcon={<Icon />}
                isActive={Boolean(activeOption)}
                aria-label={`${label}-dropdown-menu`}
                onClick={handleClick}
                {...restProps}
            >
                <Typography variant="titleMedium">{label}</Typography>
            </NavbarButton>
            <StyledMenu
                id="nav-menu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {Boolean(renderMenuItems)
                    ? renderMenuItems(menuOptions, { handleClose })
                    : validMenuOptions?.map((menuOption) => {
                          return (
                              <MenuItem
                                  data-trackid={menuOption?.trackId}
                                  key={menuOption.to}
                                  component={Link}
                                  to={menuOption.to}
                                  state={menuOption.state}
                                  disableRipple
                                  onClick={(e) => {
                                      handleClose();
                                      if (menuOption.onClick) {
                                          menuOption.onClick(e);
                                      }
                                  }}
                              >
                                  <Typography variant="titleSmall">{menuOption.label}</Typography>
                              </MenuItem>
                          );
                      })}
            </StyledMenu>
        </>
    );
}

NavbarMenu.propTypes = {
    menuOptions: PropTypes.array,
    label: PropTypes.string,
    Icon: PropTypes.elementType,
    renderMenuItems: PropTypes.func,
};
