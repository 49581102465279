import { createSlice } from '@reduxjs/toolkit';

export const initialMediaState = {
    matchText: '',
    tags: [],
    sortModel: [],
    mediaTypes: [],
    sources: [],
};

const mediaSlice = createSlice({
    name: 'media',
    initialState: initialMediaState,
    reducers: {
        clearState: () => {
            return initialMediaState;
        },
        setMediaTypes: (state, { payload }) => {
            state.mediaTypes = payload;
        },
        setTags: (state, { payload }) => {
            state.tags = payload;
        },
        setSources: (state, { payload }) => {
            state.sources = payload;
        },
        setMatchText: (state, { payload }) => {
            state.matchText = payload;
        },
        setSortModel: (state, { payload }) => {
            state.sortModel = payload;
        },
        setPage: (state, { payload }) => {
            state.page = payload;
        },
    },
});

export const { clearState, setTags, setMediaTypes, setMatchText, setSources, setSortModel } =
    mediaSlice.actions;

export const mediaReducer = mediaSlice.reducer;
