export function extractCompanyName(email) {
    if (typeof email === 'string') {
        const domain = email.split('@');
        if (domain.length === 2) {
            // eslint-disable-next-line no-unused-vars
            const [, domainName] = domain;
            return domainName;
        }
    }
    return '';
}
