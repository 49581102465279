import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiFormControl from '@mui/material/FormControl';
import { styled, alpha } from '@mui/material/styles';

import { Typography as MuiTypography, TYPOGRAPHY_VARIANTS } from 'components/Typography';
import { CheckCircleIcon, ChevronDownIcon } from 'components/Icons';

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 1.5),
    alignItems: 'flex-end',
}));

const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
    color: alpha(theme.palette.common.white, 0.72),
    padding: '0px 0px 4px 0px',
    '&.Mui-checked': {
        color: alpha(theme.palette.common.white, 0.72),
    },
}));

const FormControl = styled(MuiFormControl)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
}));

const Typography = styled(MuiTypography, { shouldForwardProp: (prop) => prop !== 'disabled' })(
    ({ theme, disabled }) => ({
        color: alpha(theme.palette.common.white, disabled ? 0.48 : 0.72),
        ...TYPOGRAPHY_VARIANTS.bodyBase,
    })
);

const Option = styled(MuiTypography)(() => ({
    ...TYPOGRAPHY_VARIANTS.bodyBase,
}));

const SelectSxProps = {
    color: (theme) => alpha(theme.palette.common.white, 0.72),
    ...TYPOGRAPHY_VARIANTS.bodyBase,
    '.MuiSelect-select': {
        borderBottom: (theme) => `2px solid ${alpha(theme.palette.common.white, 0.12)}`,
    },
    '.MuiSelect-icon': {
        color: (theme) => alpha(theme.palette.common.white, 0.72),
        ...TYPOGRAPHY_VARIANTS.bodyBase,
    },
};

export function MultiSelectFilter({
    isFilterEnabled,
    handleFilterCheck,
    filterValue,
    handleFilterChange,
    label,
    filterOptions,
}) {
    return (
        <Wrapper aria-label="multiselect">
            <Checkbox
                inputProps={{
                    'aria-label': 'multiselect-checkbox',
                }}
                checked={isFilterEnabled}
                onChange={handleFilterCheck}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <FormControl variant="standard">
                    <Select
                        disableUnderline
                        readOnly={!isFilterEnabled}
                        inputProps={{ 'aria-label': 'multiselect-combobox' }}
                        value={filterValue}
                        onChange={handleFilterChange}
                        IconComponent={ChevronDownIcon}
                        sx={SelectSxProps}
                        renderValue={(selected) => {
                            const selectedOption = filterOptions.find(
                                (option) => option.value === selected
                            );

                            return selectedOption.label;
                        }}
                        MenuProps={{
                            sx: {
                                '& .MuiMenu-paper': {
                                    backgroundColor: (theme) => theme.palette.text.primary,
                                    color: (theme) => theme.palette.common.white,
                                    width: '180px',
                                },
                                '& .Mui-selected': {
                                    backgroundColor: (theme) =>
                                        `${theme.palette.text.primary} !important`,
                                },
                            },
                        }}
                    >
                        {filterOptions.map((option) => (
                            <MenuItem
                                key={option.value}
                                aria-label={`multiselect-option-${option.label}`}
                                value={option.value}
                                sx={{ justifyContent: 'space-between' }}
                            >
                                <Option>{option.label}</Option>
                                {filterValue === option.value && (
                                    <CheckCircleIcon
                                        sx={{
                                            color: (theme) => theme.palette.success.main,
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                )}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Typography disabled={!isFilterEnabled} sx={{ ml: 1, mb: 0.75 }}>
                    {label}
                </Typography>
            </Box>
        </Wrapper>
    );
}

MultiSelectFilter.propTypes = {
    isFilterEnabled: PropTypes.bool.isRequired,
    handleFilterCheck: PropTypes.func.isRequired,
    filterValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    filterOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
        })
    ).isRequired,
};
