import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';

import { Typography } from 'components/Typography';
import { useDeleteCustomContactMutation } from 'services/contacts';

const ERROR_PATTERNS = [
    'Only custom contact can be deleted',
    'You are not authorized to delete this contact',
    'Error occurred during the deletion of custom contact:',
];

export function handleError(error) {
    if (ERROR_PATTERNS.some((pattern) => error?.data?.message?.includes(pattern))) {
        toast.error(error?.data.message);
    } else {
        toast.error('Something went wrong, please try again');
    }
}

export function DeleteCustomContact(props) {
    const { contactId, expanded, setExpanded } = props;

    const [deleteCustomContact, { isFetching: isDeleting }] = useDeleteCustomContactMutation();

    const handleCollapse = () => {
        setExpanded(false);
    };

    const handleDelete = async () => {
        const res = await deleteCustomContact({ contactId });
        if (res?.error) handleError(res.error);
        if (!res?.error) toast.success('Successfully deleted contact');
    };

    return (
        <>
            <Collapse in={expanded}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', p: 1 }}>
                    <Typography variant="bodySmall" sx={{ fontWeight: 600 }}>
                        Deleting this contact may affect the behavior of existing conversations and
                        sequences.
                    </Typography>
                    <Button
                        variant="outlined"
                        disabled={isDeleting}
                        onClick={handleCollapse}
                        sx={{
                            ml: 1,
                            color: (t) => t.palette.text.primary,
                            border: '1px solid rgba(38, 55, 70, 0.24)',
                        }}
                    >
                        <Typography variant="bodySmall">Cancel</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ ml: 1 }}
                        color="error"
                        disabled={isDeleting}
                        onClick={handleDelete}
                    >
                        {!isDeleting && <Typography variant="bodySmall">Delete</Typography>}
                        {isDeleting && <CircularProgress size={12} color="inherit" />}
                    </Button>
                </Box>
            </Collapse>
        </>
    );
}

DeleteCustomContact.propTypes = {
    contactId: PropTypes.string,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
};
