export const EDUCATION_FILTER = {
    DEGREES: 'education.degree',
    SCHOOLS: 'education.institution',
};

export const EMPLOYMENT_FILTER = {
    JOB_TITLE: 'experience.title',
    COMPANY_NAME: 'experience.company',
    OCCUPATION: 'experience.resolvedOccupationCode',
    INDUSTRY: 'experience.naicsCode',
    CURRENT_JOB_TITLE: 'current.title',
    CURRENT_COMPANY_NAME: 'current.company',
    CURRENT_OCCUPATION: 'current.resolvedOccupationCode',
    CURRENT_INDUSTRY: 'current.naicsCode',
};

export const LOCATION_FILTER_UNIT = {
    KM: 'KM',
    MI: 'MI',
};

export const CANDIDATE_INFO_FILTER = {
    CANDIDATE_NAME: 'name',
};

export const EXPERIENCE_FILTER = {
    YEARS_OF_EXPERIENCE: 'experience.totalYears',
};

export const CERTIFICATION_FILTER = 'certifications';

export const SKILLS_FILTER = 'normalizedSkills.name';

export const LOCATION_FILTER = 'radialLocation';

export const EXCLUDABLE_FILTERS = [
    LOCATION_FILTER,
    EDUCATION_FILTER.DEGREES,
    EDUCATION_FILTER.SCHOOLS,
    EMPLOYMENT_FILTER.JOB_TITLE,
    EMPLOYMENT_FILTER.COMPANY_NAME,
    EMPLOYMENT_FILTER.CURRENT_JOB_TITLE,
    EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME,
    CERTIFICATION_FILTER,
    SKILLS_FILTER,
    EMPLOYMENT_FILTER.OCCUPATION,
    EMPLOYMENT_FILTER.INDUSTRY,
    EMPLOYMENT_FILTER.CURRENT_OCCUPATION,
    EMPLOYMENT_FILTER.CURRENT_INDUSTRY,
];

export const READABLE_FILTER_LABELS = {
    [LOCATION_FILTER]: 'Location',
    [EDUCATION_FILTER.DEGREES]: 'Majors/Degrees',
    [EDUCATION_FILTER.SCHOOLS]: 'Past/Current School',
    [EMPLOYMENT_FILTER.JOB_TITLE]: 'Job Title',
    [EMPLOYMENT_FILTER.CURRENT_JOB_TITLE]: 'Current Job Title',
    [EMPLOYMENT_FILTER.COMPANY_NAME]: 'Company',
    [EMPLOYMENT_FILTER.CURRENT_COMPANY_NAME]: 'Current Company',
    [EXPERIENCE_FILTER.YEARS_OF_EXPERIENCE]: 'Experience',
    [CANDIDATE_INFO_FILTER.CANDIDATE_NAME]: 'Candidate Name',
    [CERTIFICATION_FILTER]: 'Certifications',
    [SKILLS_FILTER]: 'Skills',
};

export const FILTER_RADII = [0, 25, 50, 100, 500, 750, 1000];
