import PropTypes from 'prop-types';

import { DownloadIcon } from 'components/Icons';
import { TALENT_DOC_TYPES } from 'utils/constants';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { ActionsMenuItem } from 'components/ActionsMenu';
import { useDownloadTalentDocument } from 'services/talentDownload';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';

export function DownloadTalentPdfButton(props) {
    const { talent } = props;

    const [logSearchEvent] = useSearchTelemetry();

    const [downloadTalentDocument, { isLoading: isDocumentLoading }] = useDownloadTalentDocument();
    const createDownloadHandler = (docType) => async () => {
        await downloadTalentDocument(talent, docType);
        logSearchEvent(SEARCH_EVENT_TYPES.PDF_DL, { profileId: talent.profileId });
    };

    return (
        <ActionsMenuItem
            aria-label="download-pdf"
            data-trackid="talent-actions-download-as-pdf"
            onClick={createDownloadHandler(TALENT_DOC_TYPES.PDF)}
            disabled={!talent || isDocumentLoading}
            Icon={DownloadIcon}
            label="Download as PDF"
            IconProps={{
                fontSize: 20,
                marginRight: 0.5,
            }}
        />
    );
}

DownloadTalentPdfButton.propTypes = {
    talent: PropTypes.any,
};
