import ButtonBase from '@mui/material/ButtonBase';

import { LightTooltip } from 'components/Tooltip';
import { CheckIcon, ExcludeIcon } from 'components/Icons';

export function AddToFilterButton(props) {
    return (
        <LightTooltip title="Include Filter" placement="top" disableInteractive arrow>
            <ButtonBase sx={{ display: 'flex' }} disableRipple {...props}>
                <CheckIcon sx={{ fontSize: 24, m: -1.25 }} />
            </ButtonBase>
        </LightTooltip>
    );
}

export function AddToExcludeFilterButton(props) {
    return (
        <LightTooltip title="Exclude Filter" placement="top" disableInteractive arrow>
            <ButtonBase sx={{ display: 'flex' }} disableRipple {...props}>
                <ExcludeIcon sx={{ fontSize: 14 }} />
            </ButtonBase>
        </LightTooltip>
    );
}
