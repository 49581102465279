import SvgIcon from '@mui/material/SvgIcon';

export function CheckBoxSelectedIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M2 5.06514C2 3.37143 3.37143 2 5.06514 2H14.9349C16.6286 2 18 3.37143 18 5.06514V14.9349C18 16.6286 16.6286 18 14.9349 18H5.06514C4.25222 18 3.47259 17.6771 2.89776 17.1022C2.32293 16.5274 2 15.7478 2 14.9349V5.06514Z"
                fill="black"
                fillOpacity="0.0"
            />
            <path
                d="M2 5.06514C2 3.37143 3.37143 2 5.06514 2H14.9349C16.6286 2 18 3.37143 18 5.06514V14.9349C18 16.6286 16.6286 18 14.9349 18H5.06514C4.25222 18 3.47259 17.6771 2.89776 17.1022C2.32293 16.5274 2 15.7478 2 14.9349V5.06514ZM14.4046 7.54743C14.5119 7.44013 14.5722 7.2946 14.5722 7.14286C14.5722 6.99111 14.5119 6.84558 14.4046 6.73829C14.2973 6.63099 14.1517 6.57071 14 6.57071C13.8483 6.57071 13.7027 6.63099 13.5954 6.73829L8.28571 12.0491L6.40457 10.1669C6.29727 10.0596 6.15174 9.99928 6 9.99928C5.84826 9.99928 5.70273 10.0596 5.59543 10.1669C5.48813 10.2742 5.42785 10.4197 5.42785 10.5714C5.42785 10.7232 5.48813 10.8687 5.59543 10.976L7.88114 13.2617C7.93422 13.3149 7.99728 13.3572 8.06671 13.386C8.13613 13.4148 8.21055 13.4296 8.28571 13.4296C8.36088 13.4296 8.4353 13.4148 8.50472 13.386C8.57415 13.3572 8.63721 13.3149 8.69029 13.2617L14.4046 7.54743Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
