import { useSelector } from 'react-redux';
import { useMatch, Navigate } from 'react-router-dom';

export function ForcedFlowRedirect() {
    const isForcedFlow = useSelector((state) => state.user?.settings?.forcedFlow);
    const isOnJDBuilder = useMatch('/job-description-builder/*');

    if (isForcedFlow && !isOnJDBuilder) {
        return <Navigate to="/" replace />;
    }

    return null;
}
