import PropTypes from 'prop-types';
import {
    ConversationsIcon,
    ProjectIcon,
    UserCircleIcon,
    EyeIcon,
    OrganizationIcon,
    SequenceArrowIcon,
    ActivityIcon,
    ShortlistIcon,
    DigitalAssistantIcon,
    MediaGeneralIcon,
    ShareIcon,
} from 'components/Icons';

export const ENTITY_TYPE_ENUMS = {
    PROJECT: {
        value: 'project',
        label: 'Project',
        Icon: ProjectIcon,
    },
    CONVERSATION: {
        value: 'conversation',
        label: 'Conversation',
        Icon: ConversationsIcon,
    },
    PROFILE: {
        value: 'profile',
        label: 'Profile',
        Icon: UserCircleIcon,
    },
    TALKTRACK: {
        value: 'talktrack',
        label: 'TalkTrack',
        Icon: DigitalAssistantIcon,
    },
    TALENT: {
        value: 'talent',
        label: 'Talent',
        Icon: UserCircleIcon,
    },
    SEQUENCE: {
        value: 'sequence',
        label: 'Sequence',
        Icon: SequenceArrowIcon,
    },
    SHORTLIST: {
        value: 'shortlist',
        label: 'Shortlist',
        Icon: ShortlistIcon,
    },
    FEEDBACK: {
        value: 'feedback',
        label: 'Feedback',
        Icon: ShareIcon,
    },
    MEDIA: {
        value: 'media',
        label: 'Media',
        Icon: MediaGeneralIcon,
    },
    INTEGRATION: {
        value: 'integration',
        label: 'Integration',
        Icon: () => null,
    },
};

export const ACCESS_OPTIONS = {
    YOU: {
        value: 'YOU',
        icon: EyeIcon,
        selectionCopy: 'Only You',
        subtitleCopy: 'Only you can view or edit this media',
    },
    ORG: {
        value: 'ORG',
        icon: OrganizationIcon,
        selectionCopy: 'Your Org',
        subtitleCopy: 'All members of your organization can view or edit this media',
    },
};

export const PERMISSIONS_OPTIONS = {
    EDITOR: {
        value: 'edit',
        label: 'Editor',
    },
    VIEWER: {
        value: 'view',
        label: 'Viewer',
    },
};

export const ATTACHMENT_TYPE = {
    displayName: PropTypes.string,
    filename: PropTypes.string,
    name: PropTypes.string,
    mediaType: PropTypes.string,
    size: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.string),
    mediaId: PropTypes.string,
    entityConnections: PropTypes.arrayOf(PropTypes.object),
    access: PropTypes.shape({
        org_access: PropTypes.shape(PropTypes.object),
        user_access: PropTypes.shape(PropTypes.object),
    }),
};
