import SvgIcon from '@mui/material/SvgIcon';

export function MoreIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M8.25 12C8.25 12.3315 8.1183 12.6495 7.88388 12.8839C7.64946 13.1183 7.33152 13.25 7 13.25C6.66848 13.25 6.35054 13.1183 6.11612 12.8839C5.8817 12.6495 5.75 12.3315 5.75 12C5.75 11.6685 5.8817 11.3505 6.11612 11.1161C6.35054 10.8817 6.66848 10.75 7 10.75C7.33152 10.75 7.64946 10.8817 7.88388 11.1161C8.1183 11.3505 8.25 11.6685 8.25 12ZM13.25 12C13.25 12.3315 13.1183 12.6495 12.8839 12.8839C12.6495 13.1183 12.3315 13.25 12 13.25C11.6685 13.25 11.3505 13.1183 11.1161 12.8839C10.8817 12.6495 10.75 12.3315 10.75 12C10.75 11.6685 10.8817 11.3505 11.1161 11.1161C11.3505 10.8817 11.6685 10.75 12 10.75C12.3315 10.75 12.6495 10.8817 12.8839 11.1161C13.1183 11.3505 13.25 11.6685 13.25 12ZM17 13.25C17.3315 13.25 17.6495 13.1183 17.8839 12.8839C18.1183 12.6495 18.25 12.3315 18.25 12C18.25 11.6685 18.1183 11.3505 17.8839 11.1161C17.6495 10.8817 17.3315 10.75 17 10.75C16.6685 10.75 16.3505 10.8817 16.1161 11.1161C15.8817 11.3505 15.75 11.6685 15.75 12C15.75 12.3315 15.8817 12.6495 16.1161 12.8839C16.3505 13.1183 16.6685 13.25 17 13.25Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
