import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';

import { MESSAGE_TYPES } from 'utils/constants';
import { MessageContactSentDetails } from './MessageContactSentDetails';
import { MessageLabelTypography } from './MessageLabelTypography.styled';

export function MessageLabelBoxContent({ messageType, sender, recipient, isOutgoing }) {
    const [labelHovered, setLabelHovered] = useState(false);
    const user = useSelector((state) => state.user);

    const handleMouseEnter = () => {
        setLabelHovered(true);
    };

    const handleMouseLeave = () => {
        setLabelHovered(false);
    };

    const getMessage = () => {
        if (labelHovered) {
            if (messageType === MESSAGE_TYPES.EMAIL) {
                return isOutgoing ? (
                    <MessageContactSentDetails
                        recipient={recipient}
                        sender={user?.email || '(email not available)'}
                        messageType={messageType}
                    />
                ) : (
                    <MessageContactSentDetails
                        recipient={user?.email || '(email not available)'}
                        sender={sender || '(email not available)'}
                        messageType={messageType}
                    />
                );
            } else if (messageType === MESSAGE_TYPES.SMS) {
                return (
                    <MessageContactSentDetails
                        recipient={recipient}
                        sender={sender}
                        messageType={messageType}
                    />
                );
            }
        } else if (!labelHovered) {
            return (
                <MessageLabelTypography variant="labelXSmall">{messageType}</MessageLabelTypography>
            );
        }
        return '';
    };

    return (
        <Box
            data-testid={`message-type-${messageType}-label`}
            sx={{
                ml: -1.2,
                lineHeight: '5px',
                width: '200px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Collapse in={labelHovered}>{getMessage()}</Collapse>
            <Collapse in={!labelHovered}>{getMessage()}</Collapse>
        </Box>
    );
}

MessageLabelBoxContent.propTypes = {
    isOutgoing: PropTypes.bool,
    sender: PropTypes.string,
    recipient: PropTypes.string,
    messageType: PropTypes.string,
};
