import PropTypes from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';
import { alpha } from '@mui/material/styles';

import { TextInput } from 'components/Inputs';
import { SearchIcon } from 'components/Icons';

export function MatchText({ value, onChange }) {
    return (
        <TextInput
            fullWidth
            placeholder="Search"
            value={value}
            onChange={onChange}
            inputProps={{ 'aria-label': 'Search' }}
            InputProps={{
                sx: {
                    '&.MuiInputBase-root': {
                        borderRadius: (t) => t.spacing(0.5),
                    },
                },
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon sx={{ color: alpha('#2637467A', 0.48), mr: 1 }} />
                    </InputAdornment>
                ),
            }}
        />
    );
}

MatchText.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};
