import { useState, useEffect, useCallback } from 'react';

export const useDetectInternetConnectionStatus = () => {
    const [online, setOnline] = useState(window.navigator.onLine);

    const toggleOnlineStatus = useCallback(() => {
        setOnline(window.navigator.onLine);
    }, []);

    useEffect(() => {
        window.addEventListener('online', toggleOnlineStatus);
        window.addEventListener('offline', toggleOnlineStatus);
        return () => {
            window.removeEventListener('online', toggleOnlineStatus);
            window.removeEventListener('offline', toggleOnlineStatus);
        };
    }, [toggleOnlineStatus]);

    return online;
};
