import SvgIcon from '@mui/material/SvgIcon';

export function RepliedIcon(props) {
    return (
        <SvgIcon viewBox="0 0 12 12" {...props}>
            <path
                d="M1.51881 6.03612L3.90512 8.42243C3.98394 8.5009 4.03051 8.60604 4.03565 8.71714C4.04079 8.82824 4.00413 8.93723 3.9329 9.02264C3.86167 9.10806 3.76103 9.16369 3.65081 9.17858C3.5406 9.19347 3.4288 9.16654 3.33746 9.10309L3.27607 9.05238L0.103214 5.87597L0.0587267 5.81368L0.0347035 5.76386L0.0142392 5.70335L0.00979038 5.68556C0.00316391 5.65456 -0.000117221 5.62294 3.19751e-06 5.59125L0.00445205 5.65709L0.00178278 5.62951V5.55388L0.0151289 5.47736L0.0329239 5.4213L0.0685139 5.35368L0.11745 5.2914L3.27607 2.13101C3.35453 2.05219 3.45967 2.00562 3.57077 2.00048C3.68187 1.99533 3.79086 2.03199 3.87628 2.10322C3.96169 2.17446 4.01732 2.2751 4.03221 2.38531C4.0471 2.49553 4.02017 2.60732 3.95673 2.69867L3.90512 2.76095L1.51881 5.14548L5.33851 5.14637C8.47221 5.14637 10.5747 6.90452 10.6735 9.41095L10.677 9.59512C10.677 9.71311 10.6301 9.82627 10.5467 9.9097C10.4633 9.99313 10.3501 10.04 10.2321 10.04C10.1142 10.04 10.001 9.99313 9.91756 9.9097C9.83413 9.82627 9.78726 9.71311 9.78726 9.59512C9.78726 7.57272 8.17948 6.12154 5.57162 6.03968L5.33851 6.03612H1.51881Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
