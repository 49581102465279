import SvgIcon from '@mui/material/SvgIcon';

export function SaveIcon(props) {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                d="M4 3C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V12C3 12.2652 3.10536 12.5196 3.29289 12.7071C3.48043 12.8946 3.73478 13 4 13V9.5C4 9.10218 4.15804 8.72064 4.43934 8.43934C4.72064 8.15804 5.10218 8 5.5 8H10.5C10.8978 8 11.2794 8.15804 11.5607 8.43934C11.842 8.72064 12 9.10218 12 9.5V13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12V5.621C12.9999 5.3558 12.8945 5.10149 12.707 4.914L11.086 3.293C10.8985 3.10545 10.6442 3.00006 10.379 3H10V4.5C10 4.89782 9.84196 5.27936 9.56066 5.56066C9.27936 5.84196 8.89782 6 8.5 6H6.5C6.10218 6 5.72064 5.84196 5.43934 5.56066C5.15804 5.27936 5 4.89782 5 4.5V3H4ZM6 3V4.5C6 4.63261 6.05268 4.75979 6.14645 4.85355C6.24021 4.94732 6.36739 5 6.5 5H8.5C8.63261 5 8.75979 4.94732 8.85355 4.85355C8.94732 4.75979 9 4.63261 9 4.5V3H6ZM11 13V9.5C11 9.36739 10.9473 9.24021 10.8536 9.14645C10.7598 9.05268 10.6326 9 10.5 9H5.5C5.36739 9 5.24021 9.05268 5.14645 9.14645C5.05268 9.24021 5 9.36739 5 9.5V13H11ZM2 4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H10.379C10.9094 2.00011 11.418 2.2109 11.793 2.586L13.414 4.207C13.7891 4.58199 13.9999 5.09061 14 5.621V12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14H4C3.46957 14 2.96086 13.7893 2.58579 13.4142C2.21071 13.0391 2 12.5304 2 12V4Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
