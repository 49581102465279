import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
const PricingTableContainer = styled('div')(({ theme }) => ({
    paddingBottom: theme.spacing(2),
}));
export function PricingTable(props) {
    const { PRICING_TABLE_ID, PUBLISH_KEY, CUSTOMER_SESSION_ID, CLIENT_REF_ID } = props;

    return (
        <PricingTableContainer aria-label="pricing-table">
            <stripe-pricing-table
                pricing-table-id={PRICING_TABLE_ID}
                publishable-key={PUBLISH_KEY}
                customer-session-client-secret={CUSTOMER_SESSION_ID}
                client-reference-id={CLIENT_REF_ID}
            />
        </PricingTableContainer>
    );
}
PricingTable.propTypes = {
    PRICING_TABLE_ID: PropTypes.string,
    PUBLISH_KEY: PropTypes.string,
    CUSTOMER_SESSION_ID: PropTypes.string,
    CLIENT_REF_ID: PropTypes.string,
};
