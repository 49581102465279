import { toast } from 'react-toastify';

function handle400Error(errorData) {
    if (errorData?.message?.includes('signature with same name')) {
        toast.error(errorData?.message);
    }
    if (errorData?.message?.includes('Formatting, Subject or Signature')) {
        toast.error('Formatting, subject, or signature fields are not allowed in SMS templates');
    } else if (errorData?.message?.includes('User has reached maximum')) {
        toast.error('User has reached the maximum allowed templates');
    } else if (errorData?.message?.includes('Template with the given name already exist')) {
        toast.error('Template with the given name already exists');
    } else {
        handleGenericError();
    }
}

function handle404Error(errorData) {
    if (errorData?.message?.includes('Signature matching query does not exist')) {
        toast.error('Selected signature is not available or has been deleted, refresh and retry');
    } else {
        handleGenericError();
    }
}

function handle500Error() {
    toast.error('Internal server error');
}

function handleGenericError() {
    toast.error('Something went wrong, please try again');
}

const handleError = (res) => {
    const errorData = res?.error?.data;
    switch (res.error.status || res.error.originalStatus) {
        case 404:
            handle404Error(errorData);
            break;
        case 400:
            handle400Error(errorData);
            break;
        case 500:
            handle500Error();
            break;
        default:
            handleGenericError();
    }
};

const handleDeleteTemplateSuccess = (
    templateId,
    template,
    dispatch,
    resetTemplate,
    setClearInput,
    onClose
) => {
    toast.success('Successfully deleted template');
    if (templateId === template?.templateId) {
        dispatch(resetTemplate());
        if (typeof setClearInput === 'function') setClearInput(true);
    }
    if (typeof onClose === 'function') onClose();
};

const handleResponse = (error, onSuccess, onError) => {
    if (error) {
        onError();
    } else {
        onSuccess();
    }
};

const handleSaveTemplateSuccess = (resetTemplate, onClose) => {
    toast.success('Successfully saved template');
    resetTemplate();
    if (typeof onClose === 'function') onClose();
};

export {
    handle400Error,
    handleGenericError,
    handle500Error,
    handleError,
    handleDeleteTemplateSuccess,
    handleSaveTemplateSuccess,
    handle404Error,
    handleResponse,
};
