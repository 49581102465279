import { useMemo } from 'react';

import { useGetSourcePoolsQuery } from 'services/users';

const emptyArray = [];

export function usePoolsMap(obfuscatePoolNames) {
    const { data: talentPools = emptyArray } = useGetSourcePoolsQuery();

    return useMemo(() => {
        const poolsMap = {};
        let altIndex = 1;
        talentPools.forEach((poolData) => {
            poolData.talentPoolIds.forEach((poolId) => {
                if (
                    !obfuscatePoolNames ||
                    poolData.name === 'Nebula' ||
                    poolData.name === 'Resume'
                ) {
                    poolsMap[poolId] = { name: poolData.name, isPublic: poolData.isPublic };
                } else {
                    poolsMap[poolId] = { name: `Alt ${altIndex}`, isPublic: poolData.isPublic };
                    altIndex++;
                }
            });
        });
        return poolsMap;
    }, [talentPools, obfuscatePoolNames]);
}
