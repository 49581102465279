import { useMemo, createRef } from 'react';

export function useCreateRefsArray(arr) {
    const refsById = useMemo(() => {
        const itemRefs = {};
        arr.forEach((_, idx) => {
            itemRefs[idx] = createRef(null);
        });
        return itemRefs;
    }, []);

    return useMemo(
        () => ({
            refsById,
        }),
        [refsById]
    );
}
