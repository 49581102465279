import SvgIcon from '@mui/material/SvgIcon';

export function CollapseIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M13.1532 5.62466C12.1746 5.62466 11.3739 6.42498 11.3739 7.40315V8.45245L3.75864 0.840521C3.06473 0.14691 1.94384 0.14691 1.24993 0.840521C0.556014 1.53413 0.556014 2.65459 1.24993 3.3482L8.84731 10.9423H7.81542C6.83682 10.9423 6.03615 11.7426 6.03615 12.7208C6.03615 13.699 6.83682 14.4993 7.81542 14.4993H12.8507C13.3311 14.4637 13.936 14.2681 14.3275 13.8946C14.7189 13.5034 14.9325 12.9698 14.9325 12.4363V7.38535C14.9325 6.40718 14.1318 5.60686 13.1532 5.60686V5.62466Z"
                fill="currentColor"
            />
            <path
                d="M32.2091 29.2969L24.5582 21.6494H25.6081C26.5867 21.6494 27.3873 20.8491 27.3873 19.8709C27.3873 18.8927 26.5867 18.0924 25.6081 18.0924H20.5727C20.0567 18.128 19.4873 18.3058 19.0959 18.6971C18.7044 19.0883 18.491 19.6219 18.491 20.1555V25.2064C18.491 26.1845 19.2917 26.9848 20.2703 26.9848C21.2489 26.9848 22.0495 26.1845 22.0495 25.2064V24.1748L29.6825 31.8046C30.0384 32.1603 30.4832 32.3203 30.9458 32.3203C31.4085 32.3203 31.8532 32.1425 32.2091 31.8046C32.903 31.111 32.903 29.9905 32.2091 29.2969Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
