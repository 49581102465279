import { useEffect, useMemo } from 'react';
import { useGetTalentProfileQuery } from 'services/talent';
import { ENTITY_TYPE_ENUMS } from 'utils/entityEnums';
import { getProjectApi } from 'views/Projects/services';
import { useCustomQuery } from './useCustomQuery';
import { useGetMediaDetailsQuery } from 'services/knowledgeBases';

function getURLFromMetaData(type, typeID, feedbackID) {
    if (!type || !typeID) return '';
    if (type === 'shortlist') {
        return `/shortlist/${typeID}`;
    } else if (type === 'project') {
        return `/projects/${typeID}`;
    } else if (type === 'watch') {
        return `/shortlist-feedback/${feedbackID}/watch/${typeID}`;
    }
}

export function useRetrieveEntityConnection({
    type,
    entityId,
    data: entityData,
    talentName,
    onEntityConnectionFetchError,
    metadata,
}) {
    const { data: profileData, isLoading: isFetchingTalent } = useGetTalentProfileQuery(entityId, {
        skip: type !== ENTITY_TYPE_ENUMS.TALENT.label,
    });

    const { data: projectData, error } = useCustomQuery(
        ['project', { projectId: entityId }],
        getProjectApi,
        {
            disabled: type === ENTITY_TYPE_ENUMS.PROJECT.label && Boolean(entityId),
        }
    );

    const { data: mediaResponse, isLoading: isFetchingMedia } = useGetMediaDetailsQuery(entityId, {
        skip: type !== ENTITY_TYPE_ENUMS.MEDIA.label,
    });

    const isLoading = isFetchingTalent || isFetchingMedia;

    useEffect(() => {
        if (error) {
            onEntityConnectionFetchError?.(type, entityId);
        }
    }, [entityId, error, onEntityConnectionFetchError, type]);

    const connectionString = useMemo(() => {
        if (type === ENTITY_TYPE_ENUMS.TALENT.label) {
            return talentName || profileData?.name || entityData?.talentName;
        }
        if (entityData && type === ENTITY_TYPE_ENUMS.CONVERSATION.label) {
            return `Thread with ${talentName || entityData.talentName}`;
        }
        if (type === ENTITY_TYPE_ENUMS.PROJECT.label && projectData) {
            return projectData?.name;
        }
        if (type === ENTITY_TYPE_ENUMS.MEDIA.label && Boolean(mediaResponse)) {
            return mediaResponse?.medias[0]?.displayName;
        }
        if (type === ENTITY_TYPE_ENUMS.INTEGRATION.label) {
            return entityData?.name || 'Integration';
        }
        if (entityData && entityData?.name) {
            return entityData.name;
        }
        return '';
    }, [type, entityData, projectData, mediaResponse, profileData?.name, talentName]);

    const connectionUrl = useMemo(() => {
        if (type === ENTITY_TYPE_ENUMS.TALENT.label) {
            return `/talent/${entityId}`;
        }
        if (type === ENTITY_TYPE_ENUMS.CONVERSATION.label) {
            return `/communications/messages/${entityId}`;
        }
        if (type === ENTITY_TYPE_ENUMS.SHORTLIST.label) {
            return `/shortlist/${entityId}`;
        }
        if (type === ENTITY_TYPE_ENUMS.SEQUENCE.label) {
            return `/sequences/${entityId}`;
        }
        if (type === ENTITY_TYPE_ENUMS.TALKTRACK.label) {
            return `/talk-tracks/${entityId}`;
        }
        if (type === ENTITY_TYPE_ENUMS.TALENT.label) {
            return `/talent/${entityId}`;
        }
        if (type === ENTITY_TYPE_ENUMS.FEEDBACK.label) {
            return getURLFromMetaData(
                metadata.derivedFrom?.type,
                metadata.derivedFrom?.typeId,
                entityId
            );
        }
        if (type === ENTITY_TYPE_ENUMS.PROJECT.label) {
            return `/projects/${entityId}`;
        }
        return '';
    }, [entityId, metadata, type]);

    return {
        connectionString,
        connectionUrl,
        isLoading,
    };
}
