import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { isNil } from 'lodash/fp';
import { CircularProgress } from '@mui/material';

const Indicator = styled(Box, { shouldForwardProp: (prop) => prop !== 'isEmailConnected' })(
    ({ theme, isEmailConnected }) => [
        {
            width: 12,
            height: 12,
            borderRadius: '100%',
        },
        !isEmailConnected && {
            background: `radial-gradient(95.83% 95.83% at 50% 4.17%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(360deg, rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0) 50%), ${theme.palette.error.main}`,
            boxShadow:
                '0px 0px 16px 6px rgba(228, 104, 104, 0.15), 0px 0px 8px rgba(228, 104, 104, 0.3)',
        },
        isEmailConnected && {
            background: `radial-gradient(95.83% 95.83% at 50% 4.17%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(360deg, rgba(0, 0, 0, 0.26) 0%, rgba(0, 0, 0, 0) 50%), ${theme.palette.success.main}`,
            boxShadow:
                '0px 0px 16px 6px rgba(41, 204, 154, 0.15), 0px 0px 8px rgba(41, 204, 154, 0.3)',
        },
    ]
);

export function ConnectedIndicator(props) {
    const { isEmailConnected, ...restProps } = props;

    if (isNil(isEmailConnected)) return <CircularProgress size={20} />;

    return (
        <Indicator
            data-testid="connected-indicator"
            aria-label="email-connected-indicator"
            isEmailConnected={isEmailConnected}
            {...restProps}
        />
    );
}

ConnectedIndicator.propTypes = {
    isEmailConnected: PropTypes.bool,
};
