import SvgIcon from '@mui/material/SvgIcon';

export function TwitterIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 21" {...props}>
            <path
                d="M24 3.07255C23.1013 3.47068 22.148 3.73201 21.1719 3.84786C22.1887 3.23848 22.9693 2.27352 23.3369 1.12377C22.3704 1.69731 21.3129 2.10142 20.2103 2.31861C19.312 1.3617 18.0324 0.763672 16.6162 0.763672C13.8968 0.763672 11.6921 2.9683 11.6921 5.68742C11.6921 6.07339 11.7357 6.44914 11.8196 6.80961C7.72744 6.6042 4.09931 4.64398 1.67072 1.66498C1.24697 2.3922 1.00416 3.23811 1.00416 4.14036C1.00416 5.84867 1.8735 7.3557 3.19462 8.23873C2.4127 8.21425 1.64798 8.00306 0.964313 7.6228C0.964031 7.64342 0.964031 7.66405 0.964031 7.68477C0.964031 10.0704 2.66128 12.0605 4.91372 12.5129C4.18864 12.7101 3.42809 12.7389 2.69016 12.5973C3.31669 14.5535 5.13516 15.977 7.28972 16.0168C5.60456 17.3374 3.48141 18.1246 1.17459 18.1246C0.777094 18.1246 0.385219 18.1012 0 18.0558C2.17903 19.4528 4.76719 20.268 7.54781 20.268C16.6047 20.268 21.5573 12.7651 21.5573 6.25845C21.5573 6.04489 21.5526 5.83255 21.543 5.62142C22.507 4.92456 23.339 4.06144 24 3.07255Z"
                fill="#55ACEE"
                />
        </SvgIcon>
    );
}
