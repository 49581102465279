import SvgIcon from '@mui/material/SvgIcon';

export function ExcludeIcon(props) {
    return (
        <SvgIcon viewBox="0 0 9 8" {...props}>
            <path
                d="M4.5 0C2.2908 0 0.5 1.7908 0.5 4C0.5 6.2092 2.2908 8 4.5 8C6.7092 8 8.5 6.2092 8.5 4C8.5 1.7908 6.7092 0 4.5 0ZM1.3 4C1.3 3.15131 1.63714 2.33737 2.23726 1.73726C2.83737 1.13714 3.65131 0.8 4.5 0.8C5.2392 0.8 5.918 1.0532 6.46 1.4744L1.9744 5.96C1.5372 5.40022 1.2998 4.71028 1.3 4ZM4.5 7.2C3.78971 7.20026 3.09975 6.96286 2.54 6.5256L7.0256 2.04C7.4628 2.59978 7.7002 3.28972 7.7 4C7.7 4.84869 7.36286 5.66263 6.76274 6.26274C6.16263 6.86286 5.34869 7.2 4.5 7.2Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
