import { removeShowLess } from 'utils/copy/removeShowLess';
import { JD_OUTPUT_SECTIONS, SEARCH_EMPHASIS_WEIGHT } from 'utils/constants';

const JOB_TITLE_SECTION_HEADER = `${JD_OUTPUT_SECTIONS[0]}:`;
const JOB_SUMMARY_SECTION_HEADER = `${JD_OUTPUT_SECTIONS[1]}:`;
const RESPONSIBILITIES_SECTION_HEADER = `${JD_OUTPUT_SECTIONS[3]}:`;
const REQUIREMENTS_SECTION_HEADER = `${JD_OUTPUT_SECTIONS[4]}:`;

const sectionsToPull = [
    [JOB_TITLE_SECTION_HEADER, JOB_SUMMARY_SECTION_HEADER],
    [RESPONSIBILITIES_SECTION_HEADER, REQUIREMENTS_SECTION_HEADER],
    [REQUIREMENTS_SECTION_HEADER],
];

function contructEmphasisFromStringMatch(str, searchString) {
    const startIndex = searchString.indexOf(str);
    const endIndex = startIndex + str.length;
    return {
        source: 'MATCH_TEXT',
        startIndex,
        endIndex,
        weight: SEARCH_EMPHASIS_WEIGHT,
    };
}

/**
 * This approach may not handle all edge cases perfectly, such as
 * comments or scripts within the HTML. If your input HTML is
 * complex, it's better to use a dedicated HTML parsing library like
 * DOMParser to parse the HTML and then extract the text nodes while
 * ignoring the HTML elements.
 */
function stripHtmlTags(input) {
    return input.replace(/<\/?[^>]+(>|$)/g, '');
}

function getTalentJobTitle(talent) {
    if (!talent?.occupation && (!talent?.experience?.[0] || !Array.isArray(talent?.experience))) {
        return '';
    }

    const experience = talent.experience[0];
    if (experience?.title) {
        return experience.title;
    } else if (talent.occupation) {
        return talent.occupation;
    }
    return '';
}

function getTalentCompany(talent) {
    if (!talent?.experience?.[0] || !Array.isArray(talent?.experience)) {
        return '';
    }
    return talent.experience[0]?.company || '';
}

export function parseJDForSearch(jobDescription) {
    let invalid = false;
    const outputSections = [];

    sectionsToPull.forEach((section) => {
        const sectionStart = jobDescription.indexOf(section[0]);
        const sectionEnd = jobDescription.indexOf(section[1], sectionStart + section[0].length);
        if (sectionStart !== -1 && sectionEnd !== -1) {
            outputSections.push(
                jobDescription.substring(sectionStart + section[0].length, sectionEnd)
            );
        } else if (sectionStart !== -1 && sectionEnd === -1) {
            outputSections.push(jobDescription.substring(sectionStart + section[0].length));
        } else {
            invalid = true;
        }
    });
    if (invalid) {
        return [null, jobDescription];
    }
    const jobTitle = outputSections[0].trim();
    const outputString = outputSections.join('');
    return [contructEmphasisFromStringMatch(jobTitle, outputString), outputString];
}

export function parseTalentForSimilarSearch(talent) {
    let matchText = '';
    const emphasis = [];

    // assume if there is no id, it is not a valid talent object
    if (talent?.talentId) {
        const jobTitle = getTalentJobTitle(talent);
        const companyName = getTalentCompany(talent);
        if (jobTitle) {
            matchText += jobTitle;
            emphasis.push(contructEmphasisFromStringMatch(jobTitle, matchText));
        }
        if (companyName) {
            if (jobTitle) {
                matchText += ', ';
            }
            matchText += companyName;
            emphasis.push(contructEmphasisFromStringMatch(companyName, matchText));
        }
        if (talent.bio) {
            matchText += `\n\n${stripHtmlTags(talent.bio)}\n\n`;
        }
        talent.experience.forEach((exp) => {
            matchText += exp.title ? `${exp.title}\n` : '';
            matchText += exp.company ? `${exp.company}\n` : '';
            matchText += exp.description
                ? `${removeShowLess(stripHtmlTags(exp.description))}\n`
                : '';
            if (exp.title || exp.company || exp.description) {
                matchText += '\n';
            }
        });
        talent.education.forEach((edu) => {
            matchText += edu.degree ? `${edu.degree}\n` : '';
            matchText += edu.institution || edu.school ? `${edu.institution || edu.school}\n` : '';
            if (edu.degree || edu.institution || edu.school) {
                matchText += '\n';
            }
        });
        matchText = matchText.trimEnd();
    }

    return [emphasis, matchText];
}
