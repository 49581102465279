import SvgIcon from '@mui/material/SvgIcon';

export function CheckBoxUnselectedIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props}>
            <path
                d="M2 4.28571C2 3.67951 2.24082 3.09812 2.66947 2.66947C3.09812 2.24082 3.67951 2 4.28571 2H15.7143C16.3205 2 16.9019 2.24082 17.3305 2.66947C17.7592 3.09812 18 3.67951 18 4.28571V15.7143C18 16.3205 17.7592 16.9019 17.3305 17.3305C16.9019 17.7592 16.3205 18 15.7143 18H4.28571C3.67951 18 3.09812 17.7592 2.66947 17.3305C2.24082 16.9019 2 16.3205 2 15.7143V4.28571ZM4.28571 3.14286C3.98261 3.14286 3.69192 3.26327 3.47759 3.47759C3.26327 3.69192 3.14286 3.98261 3.14286 4.28571V15.7143C3.14286 16.0174 3.26327 16.3081 3.47759 16.5224C3.69192 16.7367 3.98261 16.8571 4.28571 16.8571H15.7143C16.0174 16.8571 16.3081 16.7367 16.5224 16.5224C16.7367 16.3081 16.8571 16.0174 16.8571 15.7143V4.28571C16.8571 3.98261 16.7367 3.69192 16.5224 3.47759C16.3081 3.26327 16.0174 3.14286 15.7143 3.14286H4.28571Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
