import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { pluralize } from 'utils/copy';
import { Typography } from 'components/Typography';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { useCreateShortlistMutation } from 'services/talent';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';
import { AddIcon, CheckIcon, ChevronRightIcon, ChevronLeftIcon } from 'components/Icons';

const NewShortlistItem = styled(MenuItem, {
    shouldForwardProp: (prop) => prop !== 'step' && prop !== 'error',
})(({ theme, step, error }) => {
    const styles = {
        display: 'flex',
        maxWidth: theme.spacing(35),
        overflowX: 'hidden',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(41, 204, 154, 0.24)',
        '&:hover': {
            backgroundColor: step === 2 && theme.palette.success.main,
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.common.white,
        },
    };
    if (step === 2) {
        styles.backgroundColor = theme.palette.success.main;
        styles['&:hover'] = {
            backgroundColor: theme.palette.success.main,
        };
    }
    if (step === 2 && error) {
        styles.backgroundColor = theme.palette.error.main;
        styles['&:hover'] = {
            backgroundColor: theme.palette.error.main,
        };
    }
    return styles;
});

const StepContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(35),
    height: theme.spacing(3),
}));

const ShortlistCheck = styled(Box)(({ theme }) => ({
    marginLeft: 'auto',
    backgroundColor: theme.palette.success.light,
    color: theme.palette.common.white,
    borderRadius: '100%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
}));

function StepOne() {
    return (
        <StepContainer aria-label="add-to-new-shortlist-step-1">
            <Typography variant="labelSmall">ADD TO NEW SHORTLIST</Typography>
            <ChevronRightIcon sx={{ marginLeft: 'auto' }} />
        </StepContainer>
    );
}

function StepTwo(props) {
    const { shortlistName, onChange, onClick, isLoading } = props;

    const handleKeyDown = (event) => {
        // prevent default behavior of key down events for the parent MenuList component
        event.stopPropagation();
    };

    return (
        <StepContainer aria-label="add-to-new-shortlist-step-2">
            <TextField
                sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.success.main}`,
                    '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: (theme) => theme.palette.text.primary,
                    },
                }}
                inputProps={{ 'aria-label': 'new-shortlist-name' }}
                InputProps={{
                    disableUnderline: true,
                    sx: {
                        color: (theme) => theme.palette.text.primary,
                        fontSize: 14,
                        '&:after': {
                            borderBottom: 'none',
                        },
                    },
                }}
                color="success"
                disabled={isLoading}
                value={shortlistName}
                onKeyDown={handleKeyDown}
                onChange={onChange}
                variant="standard"
                autoFocus
            />
            {isLoading && <CircularProgress size={20} color="success" sx={{ ml: 'auto' }} />}
            {!isLoading && (
                <Typography
                    sx={{
                        color: (theme) =>
                            !shortlistName
                                ? alpha(
                                      theme.palette.success.main,
                                      theme.palette.action.disabledOpacity
                                  )
                                : theme.palette.success.main,
                        ml: 'auto',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                    role="button"
                    aria-label="create-new-shortlist"
                    data-trackid="bulk-add-to-shortlist-create-new"
                    onClick={onClick}
                    variant="titleSmall"
                >
                    Add <AddIcon />
                </Typography>
            )}
        </StepContainer>
    );
}

function StepThree(props) {
    const { error, onBack } = props;
    return (
        <StepContainer aria-label="add-to-new-shortlist-step-3">
            {!error && (
                <>
                    <Typography variant="labelSmall" sx={{ color: 'common.white' }}>
                        ADDED!
                    </Typography>
                    <ShortlistCheck>
                        <CheckIcon />
                    </ShortlistCheck>
                </>
            )}
            {Boolean(error) && (
                <>
                    <IconButton
                        aria-label="try-again"
                        onClick={onBack}
                        size="small"
                        edge="start"
                        sx={{ color: 'white', mr: 2 }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <Typography variant="labelSmall">
                        Something went wrong.
                        <br />
                        Please Try Again
                    </Typography>
                </>
            )}
        </StepContainer>
    );
}

export function ShortlistCreationItem(props) {
    const { talents } = props;

    const [logSearchEvent] = useSearchTelemetry();

    const [createShortlist, result] = useCreateShortlistMutation();
    const { isFetching, isLoading, isError } = result;

    const [step, setStep] = useState(0);
    const [shortlistName, setShortlistName] = useState('');
    const containerRef = useRef(null);

    const handleSteps = () => {
        if (step === 0) {
            setStep(1);
        }
    };

    const handleChange = (event) => {
        event.preventDefault();
        setShortlistName(event.target.value);
    };

    const handleCreateClick = async () => {
        if (!shortlistName) return;
        const res = await createShortlist({ name: shortlistName, initialTalent: talents });
        if (res?.data?.id) {
            logSearchEvent(SEARCH_EVENT_TYPES.ADD_TO_SHORTLIST, {
                shortlistId: res.data.id,
                profileIds: talents.map(({ profileId }) => profileId),
            });
        }
        setStep(2);
        if (res?.error) {
            toast.error(`Failed to create shortlist ${shortlistName}.`);
        } else {
            toast.success(
                <Typography
                    component={Link}
                    to={`/shortlist/${res?.data?.id}`}
                    aria-label="success--go-to-linked-shortlist"
                    variant="labelSmall"
                    color="common.white"
                    sx={{ textDecoration: 'none' }}
                >
                    {talents.length}&nbsp;{pluralize('candidate', talents.length)}
                    &nbsp;added to your&nbsp;
                    <span style={{ textDecoration: 'underline' }}>{shortlistName}</span>
                    &nbsp;shortlist.
                </Typography>
            );
        }
    };

    const handleReset = () => {
        setStep(0);
    };

    return (
        <NewShortlistItem
            aria-label="add-to-new-shortlist"
            data-trackid="bulk-add-to-shortlist-create-new"
            ref={containerRef}
            onClick={handleSteps}
            error={isError}
            step={step}
            disableRipple
        >
            {step === 0 && <StepOne />}
            {step === 1 && (
                <StepTwo
                    shortlistName={shortlistName}
                    onClick={handleCreateClick}
                    onChange={handleChange}
                    isLoading={isFetching || isLoading}
                />
            )}
            {step === 2 && <StepThree onBack={handleReset} error={isError} />}
        </NewShortlistItem>
    );
}

ShortlistCreationItem.propTypes = {
    talents: PropTypes.array,
};
StepTwo.propTypes = {
    shortlistName: PropTypes.string,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
};

StepThree.propTypes = {
    error: PropTypes.bool,
    onBack: PropTypes.func,
};
