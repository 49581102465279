import {
    getFetch,
    buildGetRequestWithMultipleParams,
    mutateFetch,
    buildGetRequest,
    deleteFetch,
} from 'utils/fetch';

/**
 *
 * GET {{envUrl}}
 * params: talent
 * Returns talent profiles with notes from logged in user
 */
export const getAllNotesApi = async ({ queryKey, headers }) => {
    const [, { talentId, envUrl }] = queryKey;
    const url = buildGetRequest(envUrl, { entity_id: talentId });
    const response = await getFetch(url, headers);
    return response;
};

/**
 *
 * GET {{envUrl}}
 * params: [entity_id]
 * Returns notes count for one or more talents
 */
export const getNotesCountApi = async ({ queryKey, headers }) => {
    const [, { entityIds, envUrl, privacy = 'all' }] = queryKey;
    const paramsArr = [...entityIds, { privacy }, { counts_only: 1 }];
    const url = buildGetRequestWithMultipleParams(envUrl, paramsArr);
    return await getFetch(url, headers);
};

/**
 *
 * DELETE {{envUrl}}/?note_id=123
 * Deletes a note
 */
export const deleteNoteApi = async ({ noteId, headers, envUrl }) => {
    const url = buildGetRequest(envUrl, { note_id: noteId });
    return await deleteFetch(url, headers);
};

/**
 *
 * CREATE {{envUrl}}
 * Creates a new note
 */
export const createNoteApi = async ({ data, headers, envUrl }) => {
    return await mutateFetch(envUrl, 'POST', headers, data);
};

/**
 *
 * PUT {{envUrl}}
 * Updates a new note
 */
export const updateNoteApi = async ({ data, headers, envUrl }) => {
    return await mutateFetch(envUrl, 'PUT', headers, data);
};

/**
 *
 * GET api/user-mgmt/v1/users/get_organization_users?startsWith={search_term}
 * Returns organisation users filtered by search term
 */
export const getOrganisationUsers = async ({ queryKey, headers }) => {
    const [, { searchTerm }] = queryKey;
    const url = buildGetRequest('/api/user-mgmt/v1/users/get_organization_users', {
        startsWith: searchTerm,
    });
    const response = await getFetch(url, headers);
    return response;
};
