import SvgIcon from '@mui/material/SvgIcon';

export function PastCompanyIcon(props) {
    return (
        <SvgIcon viewBox="0 0 18 18" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 0H16.7143C17.0552 0.000340378 17.382 0.135908 17.623 0.376953C17.8641 0.617997 17.9997 0.944826 18 1.28571V8.507C17.627 8.04606 17.1945 7.63523 16.7143 7.28617V1.28571H9V7.13666C8.26359 7.6296 7.6296 8.26359 7.13666 9H1.28571V16.7143H3.21429V11.5714C3.21429 11.4009 3.28202 11.2374 3.40257 11.1169C3.52313 10.9963 3.68665 10.9286 3.85714 10.9286H6.24861C6.1329 11.3425 6.05564 11.7724 6.02095 12.2143H4.5V16.7143H7.07143V16.4007C7.46231 17.0074 7.94778 17.5475 8.507 18H0V9C0.000340378 8.65911 0.135908 8.33228 0.376953 8.09124C0.617997 7.85019 0.944826 7.71463 1.28571 7.71429H7.71429V1.28571C7.71463 0.944826 7.85019 0.617997 8.09124 0.376953C8.33228 0.135908 8.65911 0.000340378 9 0ZM17.8498 18H18V17.8498C17.9699 17.9169 17.9531 17.9531 17.9531 17.9531C17.9531 17.9531 17.9169 17.9699 17.8498 18ZM11.5714 3.85714H10.2857V5.14286H11.5714V3.85714ZM15.4286 3.85714H14.1429V5.14286H15.4286V3.85714Z"
                fill="currentColor"
            />
            <path
                d="M12.7896 10.9644V13.125L14.7187 12.9234"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M8.25 12.75C8.25 10.2715 10.2715 8.25 12.75 8.25C15.2285 8.25 17.25 10.2715 17.25 12.75C17.25 15.2285 15.2285 17.25 12.75 17.25C10.2715 17.25 8.25 15.2285 8.25 12.75Z"
                stroke="currentColor"
                strokeWidth="1.5"
                fill="none"
            />
        </SvgIcon>
    );
}
