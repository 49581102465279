import SvgIcon from '@mui/material/SvgIcon';

export function CrossCircleIcon(props) {
    return (
        <SvgIcon viewBox="0 0 33 33" {...props}>
            <path
                d="M16.5 0.719727C7.668 0.719727 0.5 7.88773 0.5 16.7197C0.5 25.5517 7.668 32.7197 16.5 32.7197C25.332 32.7197 32.5 25.5517 32.5 16.7197C32.5 7.88773 25.332 0.719727 16.5 0.719727ZM16.5 29.5197C9.444 29.5197 3.7 23.7757 3.7 16.7197C3.7 9.66373 9.444 3.91973 16.5 3.91973C23.556 3.91973 29.3 9.66373 29.3 16.7197C29.3 23.7757 23.556 29.5197 16.5 29.5197Z"
                fill="currentColor"
            />
            <path
                d="M21.62 11.584C20.996 10.96 19.988 10.96 19.364 11.584L16.5 14.448L13.636 11.584C13.012 10.96 12.004 10.96 11.38 11.584C10.756 12.208 10.756 13.216 11.38 13.84L14.244 16.704L11.38 19.568C10.756 20.192 10.756 21.2 11.38 21.824C11.7 22.144 12.1 22.288 12.516 22.288C12.932 22.288 13.332 22.128 13.652 21.824L16.516 18.96L19.38 21.824C19.7 22.144 20.1 22.288 20.516 22.288C20.932 22.288 21.332 22.128 21.652 21.824C22.276 21.2 22.276 20.192 21.652 19.568L18.788 16.704L21.652 13.84C22.276 13.216 22.276 12.208 21.652 11.584H21.62Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
