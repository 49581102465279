import { useState, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from 'components/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useCreateRefsArray } from '../hooks/useCreateRefsArray';
import Collapse from '@mui/material/Collapse';
import { SkillPopOver } from './SkillPopOver';
import { useCallbackRef } from 'hooks/useCallbackRef';
import isEmpty from 'lodash/isEmpty';

export function TalentSkills(props) {
    const { skills } = props;

    const { refsById } = useCreateRefsArray(skills);
    const [skillsWrapperRef, clientHeight, , , , clientWidth] = useCallbackRef();
    const dividerRef = useRef();
    const collapseContentTracker = useRef({ visibleRows: 0, skillsPerRow: [] });

    const collapsedSize = 100;
    const [hiddenSkillsCount, setHiddenSkillsCount] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [collapseElementHeight, setCollapseElementHeight] = useState(collapsedSize);
    const [isSkillOpen, setSkillOpen] = useState(false);
    const [hoveredSkill, setHoveredSkill] = useState('');

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const sliceAndReduce = (arr, start, end) =>
        arr.slice(start, end).reduce((acc, currVal) => acc + currVal, 0);

    const getHiddenSkillsCount = () => {
        const style = window.getComputedStyle(dividerRef.current);
        const dividerMargin = parseInt(style.marginRight);
        const rowHeight = refsById?.[0]?.current?.clientHeight;
        let counter = 0;
        let widthCounter = 0;
        const totalSkillsPerRow = [];

        skills.forEach((_, idx) => {
            if (
                clientWidth <
                widthCounter + (refsById?.[idx]?.current?.scrollWidth + dividerMargin)
            ) {
                totalSkillsPerRow.push(counter);
                // reset and break out to a new row
                counter = 1;
                widthCounter = refsById?.[idx]?.current?.scrollWidth + dividerMargin;
            } else {
                counter = counter + 1;
                widthCounter =
                    widthCounter + (refsById?.[idx]?.current?.scrollWidth + dividerMargin);
            }
        });

        // visibile rows
        const visibleRows = Math.round(collapsedSize / rowHeight);
        collapseContentTracker.current = {
            ...collapseContentTracker.current,
            visibleRows,
            skillsPerRow: totalSkillsPerRow,
        };
        const totalVisibleSkills = sliceAndReduce(totalSkillsPerRow, 0, visibleRows);

        const hiddenSkillsCount = skills.length - totalVisibleSkills;
        return 0 < hiddenSkillsCount ? `+ ${hiddenSkillsCount} more` : '';
    };

    useEffect(() => {
        if (collapsedSize < clientHeight && dividerRef.current && !isEmpty(refsById)) {
            const hiddenSkills = getHiddenSkillsCount();
            setHiddenSkillsCount(hiddenSkills);
            setCollapseElementHeight(collapsedSize);
        } else {
            setHiddenSkillsCount(null);
            setCollapseElementHeight(clientHeight);
        }
    }, [clientHeight, dividerRef.current, refsById]);

    const handleSkillOpen = (skillIndex) => {
        const { visibleRows, skillsPerRow } = collapseContentTracker.current;
        const min = sliceAndReduce(skillsPerRow, 0, visibleRows - 1);
        setSkillOpen((prevState) => !prevState);
        /* if Opened skill is near collapse overflow cutoff point,
         expand collapse for skill link to be visible*/
        if (hiddenSkillsCount && min <= skillIndex) {
            setIsExpanded(true);
        }
    };

    const handleMouseIn = (innerText) => {
        setHoveredSkill(innerText);
        setSkillOpen(false);
    };
    const handleMouseOut = () => {
        setHoveredSkill('');
        setSkillOpen(false);
    };

    if (!skills.length) return null;

    return (
        <Box sx={{ mb: 1.3 }}>
            <Box sx={{ mb: 0.8 }}>
                <Typography variant="labelSmall" color="textPrimary">
                    SKILLS
                </Typography>
            </Box>
            <Collapse
                in={isExpanded}
                collapsedSize={collapseElementHeight}
                sx={{ overflow: hiddenSkillsCount ? 'hidden' : 'visible' }}
            >
                <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }} ref={skillsWrapperRef}>
                    {skills.map((skill, idx) => (
                        <Fragment key={idx}>
                            <Typography
                                variant="bodySmall"
                                color="primary"
                                sx={{ pr: 1, pb: 1, position: 'relative' }}
                                ref={refsById?.[idx]}
                                data-testid="talent-skill"
                                onMouseEnter={() => handleMouseIn(skill.name)}
                                onMouseLeave={handleMouseOut}
                            >
                                {skill.name}
                                <SkillPopOver
                                    isSkillOpen={isSkillOpen}
                                    skill={skill}
                                    handleSkillOpen={handleSkillOpen}
                                    open={hoveredSkill === skill.name}
                                    indx={idx}
                                />
                            </Typography>
                            {skills.length - 1 !== idx && (
                                <Divider
                                    orientation="vertical"
                                    ref={dividerRef}
                                    sx={{
                                        borderColor: (theme) =>
                                            alpha(theme.palette.text.primary, 0.24),
                                        height: '14px',
                                        mr: 1,
                                    }}
                                />
                            )}
                        </Fragment>
                    ))}
                </Box>
            </Collapse>
            {hiddenSkillsCount && (
                <ButtonBase
                    aria-label="skills-show-more"
                    onClick={toggleExpanded}
                    sx={{ ml: 'auto', marginTop: '-15px' }}
                    disableRipple
                >
                    <Typography color="primary" variant="bodySmallBold">
                        {isExpanded ? 'Show Less' : hiddenSkillsCount}
                    </Typography>
                </ButtonBase>
            )}
        </Box>
    );
}

TalentSkills.propTypes = {
    skills: PropTypes.array,
};
