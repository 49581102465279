const showLess = 'show less';
const showMore = 'show more';

export const removeShowLess = (description) => {
    const desc = description.trim(); // trim whitespace
    if (desc.toLowerCase().endsWith(showLess)) {
        return desc.slice(0, -showLess.length);
    }
    if (desc.toLowerCase().endsWith(showMore)) {
        return desc.slice(0, -showMore.length);
    }
    return desc;
};
