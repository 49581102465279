import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from 'components/Typography';
import { TalentExperienceTimeLines } from './TalentExperienceTimelines';

function EducationItem(props) {
    const { educationItem } = props;

    const hasDegree = Boolean(educationItem?.degree);
    const hasSchool = Boolean(educationItem?.institution || educationItem?.school);
    const school = educationItem?.institution || educationItem?.school;

    return (
        <>
            {hasDegree && (
                <Typography sx={{ fontWeight: 'bold', mr: 0.5 }}>
                    {educationItem.degree},
                </Typography>
            )}
            {hasSchool && (
                <Typography
                    sx={{
                        pb: 0.3,
                    }}
                >
                    {school}
                </Typography>
            )}
            <TalentExperienceTimeLines timelineItem={educationItem} />
        </>
    );
}

export function TalentEducation(props) {
    const { education } = props;

    if (!education.length) return null;

    return (
        <Box>
            <Box sx={{ mb: 0.8 }}>
                <Typography variant="labelSmall" color="textPrimary">
                    Education
                </Typography>
            </Box>
            <Box sx={{ maxWidth: '100%' }}>
                {education.map((ed, idx) => (
                    <Box key={`${idx}-educ`} sx={{ display: 'flex', pb: 1 }}>
                        <EducationItem educationItem={ed} />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

TalentEducation.propTypes = {
    education: PropTypes.array,
};

EducationItem.propTypes = {
    educationItem: PropTypes.object,
};
