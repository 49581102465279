import SvgIcon from '@mui/material/SvgIcon';

export function ClockIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 25" {...props}>
            <path
                d="M12 0.375C5.388 0.375 0 5.763 0 12.375C0 18.987 5.388 24.375 12 24.375C18.612 24.375 24 18.987 24 12.375C24 5.763 18.612 0.375 12 0.375ZM12 21.975C6.708 21.975 2.4 17.667 2.4 12.375C2.4 7.083 6.708 2.775 12 2.775C17.292 2.775 21.6 7.083 21.6 12.375C21.6 17.667 17.292 21.975 12 21.975Z"
                fill="url(#paint0_linear_16403_87108)"
            />
            <path
                d="M13.2 11.8836V7.55156C13.2 6.89156 12.66 6.35156 12 6.35156C11.34 6.35156 10.8 6.89156 10.8 7.55156V11.9676C10.8 12.0396 10.824 12.0996 10.836 12.1596C10.764 12.5316 10.86 12.9276 11.148 13.2156L13.548 15.6156C13.788 15.8556 14.088 15.9636 14.4 15.9636C14.712 15.9636 15.012 15.8436 15.252 15.6156C15.72 15.1476 15.72 14.3916 15.252 13.9236L13.2 11.8716V11.8836Z"
                fill="url(#paint1_linear_16403_87108)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_16403_87108"
                    x1="-6.72137e-08"
                    y1="8.99472"
                    x2="24.0059"
                    y2="9.11018"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_16403_87108"
                    x1="-6.72137e-08"
                    y1="8.99472"
                    x2="24.0059"
                    y2="9.11018"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#504CE0" />
                    <stop offset="1" stopColor="#318DDE" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
}
