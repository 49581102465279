import uuid from 'react-uuid';
import { useState } from 'react';

export function useHighlighter(containerRef) {
    const [emphasisToAdd, setEmphasisToAdd] = useState(null);
    const [emphasisTooltipPosition, setEmphasisTooltipPosition] = useState(null);

    const handleSelect = (event) => {
        const selection = window.getSelection();
        if (selection.isCollapsed) {
            // nothing to select
            return;
        }
        const range = selection.getRangeAt(0);
        const selectionRect = range.getBoundingClientRect();
        const cardRect = containerRef.current.getBoundingClientRect();
        const maxX = cardRect.right - cardRect.left;
        const maxY = cardRect.bottom - cardRect.top;
        let tooltipX = selectionRect.left - cardRect.left - 1;
        let tooltipY = selectionRect.top - cardRect.top - 22;
        if (tooltipX < 0) {
            tooltipX = 0;
        } else if (tooltipX > maxX) {
            tooltipX = maxX;
        }
        if (tooltipY < 0) {
            tooltipY = 0;
        } else if (tooltipY > maxY) {
            tooltipY = maxY;
        }
        setEmphasisTooltipPosition([tooltipX, tooltipY]);
        setEmphasisToAdd({
            id: uuid(),
            value: selection.toString(),
        });
    };

    const handleDeselect = () => {
        const selection = window.getSelection();
        if (
            selection.type === 'Range' ||
            Boolean(emphasisToAdd) ||
            Boolean(emphasisTooltipPosition)
        ) {
            selection.removeAllRanges();
            setEmphasisToAdd(null);
            setEmphasisTooltipPosition(null);
        }
    };

    const handleHighlighterActionTaken = () => {
        setEmphasisToAdd(null);
        setEmphasisTooltipPosition(null);
    };

    return {
        emphasisToAdd,
        emphasisTooltipPosition,
        handleSelect,
        handleDeselect,
        handleHighlighterActionTaken,
    };
}
