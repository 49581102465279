import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const PopoverButtonSxProps = {
    textTransform: 'none',
};

export function SelectButtons({ onSelectAll, onDeselectAll }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button variant="text" sx={PopoverButtonSxProps} onClick={onSelectAll}>
                Select all
            </Button>
            <Button variant="text" sx={PopoverButtonSxProps} onClick={onDeselectAll}>
                Deselect all
            </Button>
        </Box>
    );
}

SelectButtons.propTypes = {
    onSelectAll: PropTypes.func,
    onDeselectAll: PropTypes.func,
};
