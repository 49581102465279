import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { Typography } from 'components/Typography';
import DialogContent from '@mui/material/DialogContent';

import { CloseIcon, KeyIcon } from 'components/Icons';
import { KeywordsDetailModalList } from './KeywordsDetailModalList';

const copyJson = {
    left: [
        {
            commandTitle: 'AND',
            description: 'The AND operator indicates that all terms are required.',
            exampleCode: 'Java AND Oracle AND SQL AND AJAX',
        },
        {
            commandTitle: 'Implied OR',
            description: 'Spaces between words or segments should imply an OR operator.',
            exampleCode:
                'Java Oracle "Javascript Engineer" Is equivalent to Java OR Oracle OR "Javascript Engineer"',
        },
        {
            commandTitle: 'OR',
            description:
                'The OR operator indicates that at least one term is required, and that more than one or all terms can be returned.',
            exampleCode: 'RN OR "Registered Nurse" OR "Registered Professional Nurse"',
            exampleNote:
                'The returned results must mention at least one of the following: RN, "Registered Nurse", "Registered Professional Nurse". However, if a matching candidate mentions 2 or more of these terms, they will also be returned.',
        },
        {
            commandTitle: 'NOT',
            description:
                'The NOT operator excludes specific search terms. The query will not return any results that contain that term (or terms).',
            exampleCode: 'Advertising AND NOT Marketing',
        },
    ],
    right: [
        {
            commandTitle: 'ASTERISK *',
            description:
                'The * operator Indicates a root word/stem/truncation search. In other words, the search engine will return and highlight any word that begins with the root/stem of the word truncated by the asterisk.',
            exampleCode: 'admin*',
            exampleNote:
                'This will return: administrator, administration, administer, administered, etc.',
        },
        {
            commandTitle: 'PARENTHESES ()',
            description: 'The () operator is used to encapsulate a boolean query.',
            exampleCode: 'Chef AND (Head OR Private OR Sous)',
        },
        {
            commandTitle: 'QUOTATION MARKS ""',
            description:
                'The "" operator allows you to search for an exact phrase of more than one word.',
            exampleCode: '"Director of Tax" AND Accountant',
        },
        {
            commandTitle: 'COMPLEX QUERIES',
            exampleCode: 'Javascript AND NOT (Java OR JSP OR J2EE OR "Software Engineer")',
        },
    ],
};

export function KeywordsDetailModal(props) {
    const { open, onClose } = props;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-label="keyword-modal"
            aria-describedby="keyword-modal-description"
            maxWidth={false}
            PaperProps={{ sx: { borderRadius: 2 } }}
        >
            <DialogContent
                sx={{
                    boxSizing: 'border-box',
                    minWidth: 0,
                    maxWidth: 730,
                    color: 'text.primary',
                    py: 3.5,
                    px: 4,
                }}
            >
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: -0.5 }}>
                        <KeyIcon sx={{ mr: '8.5px' }} />
                        <Typography id="modal-modal-title" variant="labelSmall">
                            KEYWORD SEARCH QUERIES
                        </Typography>
                    </Box>
                    <CloseIcon
                        aria-label="close-modal"
                        role="button"
                        onClick={onClose}
                        sx={{ cursor: 'pointer' }}
                    />
                </Box>
                <Typography id="keyword-modal-description" sx={{ mt: 1 }}>
                    The Keyword filter allows you to structure a simple boolean search query. Below
                    is an overview of the supported parameters and how to use them
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box sx={{ maxWidth: 321, mr: 'auto' }}>
                        {copyJson.left.map((sectionProps, idx) => (
                            <KeywordsDetailModalList
                                key={`${sectionProps.commandTitle}-${idx}`}
                                {...sectionProps}
                            />
                        ))}
                    </Box>
                    <Box sx={{ maxWidth: 321 }}>
                        {copyJson.right.map((sectionProps, idx) => (
                            <KeywordsDetailModalList
                                key={`${sectionProps.commandTitle}-${idx}`}
                                {...sectionProps}
                            />
                        ))}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

KeywordsDetailModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
