import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { alpha, styled, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Typography } from 'components/Typography';
import { useKeycloak } from 'features/Authentication';
import { LargeLogo, LogoContainer, SmallLogo } from './UserInfoLogo';
import { useSendJoinWaitlistRequestMutation } from 'services/systemEmails';
import { ToastContainer } from 'components/ToastContainer';

const largeLogoSrc = '/assets/logos/Nebula-color-horizonal.png';
const smallLogoSrc = '/assets/logos/Nebula-social-256.png';
const blacklistErrorMessage = (
    <>
        <span>
            We are thrilled that you are interested in trying Nebula! Currently, immediate access to
            the platform is limited to only those that sign up using a work email. Please return to
            the Sign Up page and try again with an email associated with a unique domain.
        </span>
        <br />
        <br />
        <span>Examples of unauthorized domains: @gmail.com, @yahoo.com.</span>
        <br />
        <span>Examples of authorized domains: @yourCompaniesName.com.</span>
    </>
);

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
        width: '95%',
    },
}));

const MainContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(6),
}));

const StyledText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    padding: theme.spacing(1, 0.5),
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    width: '100%',
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
        backgroundColor: alpha(theme.palette.text.primary, 0.12),
        color: alpha(theme.palette.text.primary, 0.48),
    },
}));

const StyledButtonSecondary = styled(Button)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
    boxShadow: theme.shadows[0],
    backgroundColor: 'transparent',
    textTransform: 'none',
    padding: theme.spacing(1, 0.5),
    borderColor: 'transparent',
    '&:not(:first-of-type)': {
        marginLeft: 0,
    },
}));

export function BlacklistedDomainErrorPage({ setIsChangingBlacklistedEmail, userData }) {
    const { keycloak } = useKeycloak();

    const handleSignUpRedirectToUserInfo = () => {
        if (keycloak.authenticated) {
            setIsChangingBlacklistedEmail(true);
        }
    };

    const [sendJoinWaitlistRequest, { isLoading, isSuccess }] =
        useSendJoinWaitlistRequestMutation();

    const requestJoinWaitlist = () => {
        const { primaryPhone, firstName, lastName, primaryEmail } = userData;
        const requestBody = {
            phone: primaryPhone,
            name: `${firstName} ${lastName}`,
            email: primaryEmail,
        };
        sendJoinWaitlistRequest(requestBody);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success(
                'We have been notified of your request and someone will be in touch shortly.'
            );
        }
    }, [isSuccess]);

    return (
        <Container>
            <MainContainer>
                <LogoContainer>
                    <LargeLogo src={largeLogoSrc} alt="large-nebula-logo" />
                    <SmallLogo src={smallLogoSrc} alt="small-nebula-logo" />
                </LogoContainer>
                <StyledText variant="titleMedium">{blacklistErrorMessage}</StyledText>
                <Box sx={{ width: '100%' }}>
                    <StyledButton variant="contained" onClick={handleSignUpRedirectToUserInfo}>
                        Sign up using a work email
                    </StyledButton>
                    <StyledButtonSecondary onClick={requestJoinWaitlist} disabled={isLoading}>
                        Join the waitlist to use a personal email{' '}
                        {isLoading && <CircularProgress size={20} sx={{ marginLeft: '1rem' }} />}
                    </StyledButtonSecondary>
                </Box>
            </MainContainer>
            <ToastContainer />
        </Container>
    );
}

BlacklistedDomainErrorPage.propTypes = {
    setIsChangingBlacklistedEmail: PropTypes.func,
    userData: PropTypes.object,
};
