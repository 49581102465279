import { useCallback, useMemo } from 'react';
import { useUpgradeAccountPromptStore } from '../store';
import { useLazyUpgradeRequestQuery } from 'services/organizations';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export function useUpgradeAccountPrompt() {
    const {
        view,
        openModal,
        closeModal,
        isLoading,
        setLoading,
        isModalOpen,
        setStripeConfig,
        stripeConfig,
    } = useUpgradeAccountPromptStore();
    const user = useSelector((state) => state.user);

    const [upgradeRequest, { isSuccess }] = useLazyUpgradeRequestQuery();

    const requestUpgrade = useCallback(async () => {
        setLoading(true);
        const { isError, isSuccess, data } = await upgradeRequest();
        if (isSuccess) {
            setStripeConfig({ ...data, CLIENT_REF_ID: user.id });
        }
        if (isError) {
            toast.error('Upgrade request failed, we could not receive your request.');
        }
        setLoading(false);
    }, [setLoading, upgradeRequest, setStripeConfig, user]);

    return useMemo(
        () => ({
            isLoading,
            requestUpgrade,
            isSuccess,
            stripeConfig,
            isModalOpen,
            view,
            openModal,
            closeModal,
            setStripeConfig,
        }),
        [
            isLoading,
            requestUpgrade,
            stripeConfig,
            isSuccess,
            isModalOpen,
            closeModal,
            openModal,
            view,
            setStripeConfig,
        ]
    );
}
