import SvgIcon from '@mui/material/SvgIcon';

export function TimeIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                d="M12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4ZM12 5C11.0807 5 10.1705 5.18106 9.32122 5.53284C8.47194 5.88463 7.70026 6.40024 7.05025 7.05025C6.40024 7.70026 5.88463 8.47194 5.53284 9.32122C5.18106 10.1705 5 11.0807 5 12C5 12.9193 5.18106 13.8295 5.53284 14.6788C5.88463 15.5281 6.40024 16.2997 7.05025 16.9497C7.70026 17.5998 8.47194 18.1154 9.32122 18.4672C10.1705 18.8189 11.0807 19 12 19C13.8565 19 15.637 18.2625 16.9497 16.9497C18.2625 15.637 19 13.8565 19 12C19 10.1435 18.2625 8.36301 16.9497 7.05025C15.637 5.7375 13.8565 5 12 5ZM11.5 7C11.617 6.99996 11.7304 7.04097 11.8203 7.11589C11.9102 7.19081 11.9709 7.29489 11.992 7.41L12 7.5V12H14.5C14.6249 11.9998 14.7455 12.0463 14.8378 12.1305C14.9301 12.2147 14.9876 12.3304 14.9989 12.4548C15.0102 12.5793 14.9745 12.7034 14.8988 12.8028C14.8231 12.9023 14.7129 12.9697 14.59 12.992L14.5 13H11.5C11.383 13 11.2696 12.959 11.1797 12.8841C11.0898 12.8092 11.0291 12.7051 11.008 12.59L11 12.5V7.5C11 7.36739 11.0527 7.24021 11.1464 7.14645C11.2402 7.05268 11.3674 7 11.5 7Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}
