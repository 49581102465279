import { Typography } from 'components/Typography';

export function TokenErrorStage() {
    return (
        <>
            <Typography
                variant="titleMedium"
                sx={{ color: 'text.primary', textAlign: 'center', mb: 0.5 }}
            >
                This action cannot be performed because your organization does not have enough
                lookup credits.
            </Typography>
            <Typography
                variant="bodyLarge"
                sx={{ color: 'text.primary', textAlign: 'center', mb: 3 }}
            >
                Please contact your sales manager for more information.
            </Typography>
        </>
    );
}
