import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import MuiIconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { Typography } from 'components/Typography';
import { SEARCH_EVENT_TYPES } from 'utils/constants';
import { ActionsMenuItem } from 'components/ActionsMenu';
import { useSearchTelemetry } from 'views/Search/hooks/useSearchTelemetry';
import { ShortlistAddIcon, CheckIcon, PeopleIcon, ChevronDownIcon } from 'components/Icons';
import { ShortlistCreationItem } from 'features/BatchAddToShortlists/ShortlistCreationItem';
import {
    useLazyGetShortlistsQuery,
    useLazyGetShortlistsByTalentIdQuery,
    useRemoveFromShortlistMutation,
    useAddToShortlistMutation,
} from 'services/talent';

const MENU_WIDTH = 280;

const ShortlistMenuItem = styled(MenuItem, { shouldForwardProp: (prop) => prop !== 'error' })(
    ({ theme, error }) => ({
        width: MENU_WIDTH,
        color: theme.palette.text.primary,
        padding: theme.spacing(2),
        ...(error && {
            backgroundColor: theme.palette.error.main,
        }),
        [theme.breakpoints.up('sm')]: {
            minHeight: theme.spacing(7),
        },
        '&.Mui-disabled': {
            opacity: 1,
        },
    })
);

const IconButton = styled(MuiIconButton)(({ theme, open }) => ({
    height: theme.spacing(3.25),
    width: theme.spacing(8.5),
    boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
        backgroundColor: theme.palette.success.light,
    },
    '&.Mui-disabled': {
        border: 'none',
        boxShadow: '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    },
    borderRadius: open ? theme.spacing(0.5, 0.5, 0, 0) : theme.spacing(0.5),
    transition: theme.transitions.create(['border-radius', 'background-color'], {
        duration: theme.transitions.duration.shortest,
    }),
    '& .chevron-down': {
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.shortest,
        }),
        transform: `rotate(${open ? 180 : 0}deg)`,
    },
}));

const ShortlistCheck = styled(Box)(({ theme }) => ({
    marginLeft: 'auto',
    backgroundColor: alpha(theme.palette.success.main, 0.24),
    color: theme.palette.success.main,
    borderRadius: '100%',
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
}));

export function ShortlistButton(props) {
    const { talentId, profileId, isIconButton, dataTrackId } = props;

    const [logSearchEvent] = useSearchTelemetry();

    const [addToShortlist] = useAddToShortlistMutation();
    const [removeFromShortlist] = useRemoveFromShortlistMutation();

    const [getShortlists, shortlistsResult] = useLazyGetShortlistsQuery();
    const { data: shortlistsResponse } = shortlistsResult;

    const [getTalentShortlists, talentShortlistsResults] = useLazyGetShortlistsByTalentIdQuery();
    const { data: talentShortlistsResponse } = talentShortlistsResults;

    const isError = shortlistsResult.isError || talentShortlistsResults.isError;
    const isSuccess = shortlistsResult.isSuccess && talentShortlistsResults.isSuccess;
    const isLoading =
        (shortlistsResult.isUninitialized || shortlistsResult.isLoading) &&
        (talentShortlistsResults.isUninitialized || talentShortlistsResults.isFetching);

    const [anchorEl, setAnchorEl] = useState(null);

    const [shortlistsLoading, setShortlistsLoading] = useState({});

    const [shortlistData, setShortlistData] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');

    const open = Boolean(anchorEl);

    const buttonRect = anchorEl?.getBoundingClientRect();
    const isMenuOnRight = buttonRect?.right > window.innerWidth - MENU_WIDTH;

    const horizontalTransform = isMenuOnRight ? 'right' : 'left';

    const handleOpenMenu = (event) => {
        setErrorMessage('');
        // TODO: find a better UI solution to accomodate pagination here
        getShortlists({ page: 1, pageSize: 99 });
        getTalentShortlists(talentId);
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setErrorMessage('');
        setAnchorEl(null);
    };

    const handleAddToShortlistClick = (shortlistId) => async () => {
        setShortlistsLoading((prevState) => ({
            ...prevState,
            [shortlistId]: true,
        }));
        const res = await addToShortlist({
            listId: shortlistId,
            talentId,
            profileId,
        });
        setShortlistsLoading((prevState) => ({
            ...prevState,
            [shortlistId]: false,
        }));
        if (res.error) {
            const _errorMessage = res.error?.message || 'Something went wrong.';
            setErrorMessage(_errorMessage);
        } else {
            setErrorMessage('');
            setShortlistData((prevShortlistData) => {
                const nextShortlistData = [...prevShortlistData];
                nextShortlistData.forEach((shortlist) => {
                    if (shortlist.id === shortlistId) {
                        shortlist.isInShortlist = true;
                    }
                });
                return nextShortlistData;
            });
            toast.success('Candidate successfully added to shortlist');
            logSearchEvent(SEARCH_EVENT_TYPES.ADD_TO_SHORTLIST, {
                shortlistId,
                profileIds: [talentId],
            });
            handleCloseMenu();
        }
    };

    const handleRemoveFromShortlistClick = (shortlistId) => async () => {
        setShortlistsLoading((prevState) => ({
            ...prevState,
            [shortlistId]: true,
        }));
        const res = await removeFromShortlist({ listId: shortlistId, talentId });
        setShortlistsLoading((prevState) => ({
            ...prevState,
            [shortlistId]: false,
        }));
        if (res.error) {
            const _errorMessage = res.error?.message || 'Something went wrong.';
            setErrorMessage(_errorMessage);
        } else {
            setErrorMessage('');
            setShortlistData((prevShortlistData) => {
                const nextShortlistData = [...prevShortlistData];
                nextShortlistData.forEach((shortlist) => {
                    if (shortlist.id === shortlistId) {
                        shortlist.isInShortlist = false;
                    }
                });
                return nextShortlistData;
            });
            toast.success('Candidate successfully removed from shortlist');
            handleCloseMenu();
        }
    };

    useEffect(() => {
        if (isSuccess) {
            const shortlists = shortlistsResponse.results.map((shortlist) => ({
                id: shortlist.id,
                name: shortlist.name,
                totalTalent: shortlist.totalTalent,
                isInShortlist: talentShortlistsResponse.includes(shortlist.id),
            }));
            setShortlistData(shortlists);
        }
    }, [isSuccess, shortlistsResponse, talentShortlistsResponse]);

    return (
        <>
            {isIconButton ? (
                <IconButton
                    data-trackid={dataTrackId || 'talent-actions-add-to-shortlist'}
                    aria-label="add-to-shortlist"
                    id="add-to-shortlist"
                    aria-controls={open ? 'shortlist-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    variant="primary"
                    open={open}
                    onClick={handleOpenMenu}
                >
                    <ShortlistAddIcon sx={{ fontSize: 18 }} />
                    <ChevronDownIcon className="chevron-down" />
                </IconButton>
            ) : (
                <ActionsMenuItem
                    data-trackid={dataTrackId || 'talent-actions-add-to-shortlist'}
                    aria-label="add-to-shortlist"
                    id="add-to-shortlist"
                    aria-controls={open ? 'shortlist-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    variant="primary"
                    open={open}
                    onClick={handleOpenMenu}
                    Icon={ShortlistAddIcon}
                    label="Add To Shortlist"
                />
            )}
            <Menu
                sx={{
                    borderRadius: 0,
                    '& .MuiPaper-root': {
                        maxHeight: 240,
                        boxShadow:
                            '0px 1px 2px rgba(63, 83, 100, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                        borderRadius: (theme) =>
                            horizontalTransform === 'left'
                                ? theme.spacing(0, 0.5, 0.5, 0.0)
                                : theme.spacing(0.5, 0, 0.0, 0.5),
                    },
                }}
                aria-label="shortlist-menu"
                id="shortlist-menu"
                MenuListProps={{
                    'aria-labelledby': 'add-to-shortlist',
                    sx: { p: 0 },
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                anchorOrigin={
                    isIconButton
                        ? { vertical: 'bottom', horizontal: 'right' }
                        : {
                              vertical: 'top',
                              horizontal: horizontalTransform === 'right' ? 'left' : 'right',
                          }
                }
                transformOrigin={
                    isIconButton
                        ? { vertical: 'top', horizontal: 'right' }
                        : { vertical: 'top', horizontal: horizontalTransform }
                }
            >
                {(isError || Boolean(errorMessage)) && (
                    <ShortlistMenuItem error disabled>
                        <Typography variant="labelSmall" sx={{ color: 'common.white' }} noWrap>
                            {errorMessage || <>We&apos;re sorry, something went wrong.</>}
                        </Typography>
                    </ShortlistMenuItem>
                )}
                {shortlistData.map((shortlist) => {
                    const actionLabel = shortlist.isInShortlist ? 'remove-from' : 'add-to';
                    return (
                        <ShortlistMenuItem
                            key={shortlist.id}
                            aria-label={`${actionLabel}-shortlist-${shortlist.name}`}
                            data-trackid={`${actionLabel}-shortlist-menu-item`}
                            onClick={
                                shortlist.isInShortlist
                                    ? handleRemoveFromShortlistClick(shortlist.id)
                                    : handleAddToShortlistClick(shortlist.id)
                            }
                            disabled={shortlistsLoading[shortlist.id]}
                            disableRipple
                        >
                            <Typography variant="titleSmall" sx={{ mr: 1, maxWidth: '70%' }} noWrap>
                                {shortlist.name}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 'auto',
                                }}
                            >
                                <PeopleIcon sx={{ pl: 0.5, mb: -0.25, fontSize: '1rem' }} />
                                <Typography
                                    variant="labelLarge"
                                    sx={{
                                        ml: 0.5,
                                        lineHeight: '16px',
                                        color: (theme) => alpha(theme.palette.text.primary, 0.72),
                                    }}
                                    noWrap
                                >
                                    {shortlist.totalTalent}
                                </Typography>
                            </Box>
                            {!shortlistsLoading[shortlist.id] && shortlist.isInShortlist && (
                                <ShortlistCheck>
                                    <CheckIcon />
                                </ShortlistCheck>
                            )}
                            {shortlistsLoading[shortlist.id] && (
                                <CircularProgress size={20} color="success" />
                            )}
                        </ShortlistMenuItem>
                    );
                })}
                {isLoading && (
                    <ShortlistMenuItem disabled>
                        <Typography variant="labelSmall" sx={{ mr: 'auto' }}>
                            Loading Shortlists...
                        </Typography>
                        <CircularProgress size={20} color="success" />
                    </ShortlistMenuItem>
                )}

                {isSuccess && <ShortlistCreationItem talents={[{ talentId, profileId }]} />}
            </Menu>
        </>
    );
}

ShortlistButton.propTypes = {
    talentId: PropTypes.string,
    profileId: PropTypes.string,
    isIconButton: PropTypes.bool,
    dataTrackId: PropTypes.string,
};
