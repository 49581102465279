import { alpha, styled } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useCustomMutation } from 'hooks/useCustomQuery';
import { deleteNoteApi, updateNoteApi } from 'features/TalentNotes/services';
import { toast } from 'react-toastify';
import { useNotesStore } from '../../../store/useNotesStore';
import { theme } from 'theme';
import { MentionPrivacyDialog } from '../MentionPrivacyDialog.js/MentionPrivacyDialog';
import { NoteHeader } from './NoteHeader';
import { NoteBody } from './NoteBody';
import { NoteFooter } from './NoteFooter';

const NoteWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'noteBgColor',
})(({ noteBgColor }) => ({
    background: noteBgColor,
    padding: theme.spacing(1.2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
}));

export const Note = forwardRef(
    ({ note, handleOptimisticDelete, loggedInUser, mentionEditorRef }, ref) => {
        const noteRef = useRef(null);
        const queryClient = useQueryClient();
        const mentionEditorContainerRef = useRef(null);
        const [isDeleteMode, setDeleteMode] = useState(false);
        const [isEditMode, setEditMode] = useState(false);
        const isNoteOwner = note.user_id === loggedInUser.id;
        const [editNoteValue, setEditNoteValue] = useState(note);
        const { notesEnvUrl: envUrl } = useNotesStore((state) => state);
        const [openMentionPrivacyDialog, setOpenMentionPrivacyDialog] = useState(false);

        const { mutate: deleteNote, isLoading: isDeletingNote } = useCustomMutation(deleteNoteApi, {
            onSuccess: () => {
                queryClient.invalidateQueries('talent-notes-count');
            },
            onError: () => {
                queryClient.invalidateQueries('talent-notes-count');
                toast.error('Sorry, something went wrong, this note could not be deleted');
            },
        });

        const { mutate: updateNote, isLoading: isUpdatingNote } = useCustomMutation(updateNoteApi, {
            onSuccess: () => {
                setEditMode(false);
                queryClient.invalidateQueries('talent-notes');
                setOpenMentionPrivacyDialog(false);
            },
            onError: () => {
                if (!isEditMode) {
                    setEditNoteValue((prevState) => ({ ...prevState, privacy: note.privacy }));
                }
                toast.error('Sorry, something went wrong, this note could not be updated');
            },
        });

        useEffect(() => {
            if (isEditMode && mentionEditorContainerRef.current) {
                mentionEditorContainerRef.current.scrollTop =
                    mentionEditorContainerRef.current.scrollHeight;
            }
        }, [isEditMode]);

        const handleCancelEdit = () => {
            setEditMode(false);
            setEditNoteValue((prevState) => ({ ...prevState, content: note.content }));
        };

        const handleDelete = () => {
            handleOptimisticDelete(note._id);
            deleteNote({ noteId: note._id, envUrl });
        };

        useEffect(() => {
            const container = ref.current;
            const containerRect = container.getBoundingClientRect();
            const noteRect = noteRef.current.getBoundingClientRect();
            if (
                isEditMode &&
                (noteRect.top < containerRect.top || noteRect.bottom > containerRect.bottom)
            ) {
                const scrollOffset =
                    noteRect.top - containerRect.top - (containerRect.height - noteRect.height) / 2;

                container.scrollTo({
                    top: container.scrollTop + scrollOffset,
                    behavior: 'smooth',
                });
            }
        }, [isEditMode, noteRef, ref]);

        const handleUpdateNote = (data) => {
            const noteTextHasMentions = /{{(.*?)}}/g.test(editNoteValue.content);
            if (noteTextHasMentions && editNoteValue.privacy === 'private') {
                setOpenMentionPrivacyDialog(true);
                return;
            }
            updateNote(data);
        };

        const handleShiftEnter = () => {
            handleUpdateNote({
                data: {
                    note_id: note._id,
                    content: editNoteValue.content,
                    privacy: editNoteValue.privacy,
                },
                envUrl,
            });
        };

        const getNoteBgColor = (deleteMode, isOwner) => {
            if (deleteMode) {
                return alpha(theme.palette.error.main, 0.08);
            } else if (!isOwner) {
                return 'transparent';
            } else {
                return theme.palette.background.default;
            }
        };

        const handleEditNotePrivacy = (privacy) => {
            if (!isEditMode && privacy !== editNoteValue.privacy) {
                updateNote({
                    data: {
                        note_id: note._id,
                        content: editNoteValue.content,
                        privacy,
                    },
                    envUrl,
                });
            }
            setEditNoteValue((prevState) => ({
                ...prevState,
                privacy,
            }));
        };

        const handleSetEditNote = (content) => {
            setEditNoteValue({
                ...editNoteValue,
                content,
            });
        };

        const handleCloseMentionPrivacyDialog = () => {
            setOpenMentionPrivacyDialog(false);
        };

        const keepTagsAndPostNoteToOrganization = () => {
            setEditNoteValue((prevState) => ({
                ...prevState,
                content: editNoteValue.content,
                privacy: 'org',
            }));
            updateNote({
                data: {
                    note_id: note._id,
                    content: editNoteValue.content,
                    privacy: 'org',
                },
                envUrl,
            });
        };

        const removeTagsAndPostPrivateNote = () => {
            const strippedMentionsNoteDetails = editNoteValue.content.replace(/{{(.*?)}}/g, '');
            setEditNoteValue((prevState) => ({
                ...prevState,
                privacy: 'private',
                content: strippedMentionsNoteDetails,
            }));
            updateNote({
                data: {
                    note_id: note._id,
                    content: strippedMentionsNoteDetails,
                    privacy: 'private',
                },
                envUrl,
            });
        };

        return (
            <NoteWrapper
                isDeleteMode={isDeleteMode}
                data-testid="note-container"
                ref={noteRef}
                noteBgColor={getNoteBgColor(isDeleteMode, isNoteOwner)}
            >
                <Box data-testid="note-body-container">
                    <NoteHeader
                        isNoteOwner={isNoteOwner}
                        editNoteValue={editNoteValue}
                        handleEditNotePrivacy={handleEditNotePrivacy}
                        isEditMode={isEditMode}
                        note={note}
                    />
                    <NoteBody
                        isEditMode={isEditMode}
                        note={note}
                        handleSetEditNote={handleSetEditNote}
                        handleShiftEnter={handleShiftEnter}
                        noteCanvasRef={ref}
                        mentionEditorRef={mentionEditorRef}
                        editNoteValue={editNoteValue}
                    />
                </Box>
                <NoteFooter
                    isDeleteMode={isDeleteMode}
                    isEditMode={isEditMode}
                    setDeleteMode={setDeleteMode}
                    setEditMode={setEditMode}
                    handleCancelEdit={handleCancelEdit}
                    handleDelete={handleDelete}
                    handleUpdateNote={handleUpdateNote}
                    isDeletingNote={isDeletingNote}
                    isUpdatingNote={isUpdatingNote}
                    note={note}
                    editNoteValue={editNoteValue}
                    isNoteOwner={isNoteOwner}
                    envUrl={envUrl}
                />
                <MentionPrivacyDialog
                    open={openMentionPrivacyDialog}
                    onClose={handleCloseMentionPrivacyDialog}
                    removeTagsAndPostPrivateNote={removeTagsAndPostPrivateNote}
                    keepTagsAndPostNoteToOrganization={keepTagsAndPostNoteToOrganization}
                    isLoading={isUpdatingNote}
                />
            </NoteWrapper>
        );
    }
);
Note.propTypes = {
    note: PropTypes.object.isRequired,
    handleOptimisticDelete: PropTypes.func.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    mentionEditorRef: PropTypes.object,
};
