import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { formatLocalDate } from 'utils/formatters';
import { Typography, GradientTypography } from 'components/Typography';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme, fullwidth, anchorbottom }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: anchorbottom ? theme.spacing(6.25) : theme.spacing(-7.5),
        minWidth: 180,
        width: fullwidth === 'true' ? 440 : 309,
        maxHeight: '80vh',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

export function JDDropdownList(props) {
    const {
        open,
        onHandleClose,
        anchorEl,
        fullWidth = false,
        anchorBottom,
        showDescription,
        isLoading,
        jobDescriptions,
        handleJdClick,
    } = props;

    return (
        <StyledMenu
            id="JD-dropdown-menu"
            MenuListProps={{
                'aria-labelledby': 'JD-dropdown-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onHandleClose}
            elevation={0}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            fullwidth={fullWidth.toString()}
            anchorbottom={anchorBottom}
            sx={{ maxHeight: '66vh' }}
        >
            {showDescription && (
                <Box sx={{ px: 1.5, py: 1 }} data-testid="description-text">
                    <Typography variant="bodySmall">
                        We can write email drafts for you by using one of your Saved Job
                        Descriptions as inspiration
                    </Typography>
                </Box>
            )}
            {isLoading && (
                <Box
                    sx={{ px: 1.5, py: 1, display: 'flex', justifyContent: 'center' }}
                    data-testid="circular-loader"
                >
                    <CircularProgress color="inherit" size={20} />
                </Box>
            )}
            {jobDescriptions?.map((jobDescription) => (
                <MenuItem
                    disableRipple
                    key={jobDescription.id}
                    aria-label={jobDescription.name}
                    data-trackid="ai-create-template-select-option"
                    onClick={() => {
                        onHandleClose();
                        handleJdClick({ id: jobDescription.id, name: jobDescription.name });
                    }}
                >
                    <GradientTypography variant="titleSmall">
                        {formatLocalDate(jobDescription.lastUpdated, 'MM/dd, p')}&nbsp;–&nbsp;
                        {jobDescription.name}
                    </GradientTypography>
                </MenuItem>
            ))}
            {jobDescriptions?.length === 0 && (
                <Box sx={{ px: 1.5, py: 1, display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="bodySmall">You have no saved Job Descriptions</Typography>
                </Box>
            )}
            <Box sx={{ px: 1.5, mt: 1, mb: 1 }}>
                {jobDescriptions?.length === 0 ? (
                    <Button
                        variant="contained"
                        component={Link}
                        to="/job-description-builder"
                        data-trackid="ai-create-template-create-new"
                        sx={{
                            width: '100%',
                            background: (theme) => theme.palette.common.matchInputGradient,
                            textTransform: 'none',
                        }}
                    >
                        <Typography variant="bodySmall">Create a New Job Description</Typography>
                    </Button>
                ) : (
                    <Button
                        variant="text"
                        component={Link}
                        to="/job-description-builder"
                        data-trackid="ai-create-template-create-new"
                        sx={{
                            textTransform: 'none',
                        }}
                    >
                        <GradientTypography variant="bodySmallBold">
                            Create a New Job Description
                        </GradientTypography>
                    </Button>
                )}
            </Box>
        </StyledMenu>
    );
}

JDDropdownList.propTypes = {
    open: PropTypes.bool,
    onHandleClose: PropTypes.func,
    anchorEl: PropTypes.object,
    fullWidth: PropTypes.bool,
    anchorBottom: PropTypes.bool,
    showDescription: PropTypes.bool,
    isLoading: PropTypes.bool,
    jobDescriptions: PropTypes.array,
    handleJdClick: PropTypes.func,
};
