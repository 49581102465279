import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogContentText from '@mui/material/DialogContentText';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const Dialog = styled(MuiDialog)(() => ({
    '.MuiDialog-paper': {
        boxSizing: 'border-box',
        maxWidth: '500px',
        padding: '30px',
    },
}));

const DialogActions = styled(MuiDialogActions)(() => ({
    '& > :not(:first-of-type)': {
        marginLeft: 0,
        marginTop: '10px',
    },
}));

const DialogContentText = styled(MuiDialogContentText)(() => ({
    '&': {
        textAlign: 'center',
    },
}));

const DialogTitle = styled(MuiDialogTitle)(() => ({
    '&': {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

export function MentionPrivacyDialog({
    open,
    onClose,
    removeTagsAndPostPrivateNote,
    keepTagsAndPostNoteToOrganization,
    isLoading,
}) {
    return (
        <Dialog
            open={open}
            onClose={() => {
                if (!isLoading) onClose();
            }}
            aria-describedby="alert-dialog-slide-description"
            data-testid="mention-privacy-dialog"
        >
            <DialogTitle>You have tagged a teammate in a private note</DialogTitle>

            <DialogContent sx={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <DialogContentText id="alert-dialog-slide-description">
                    Tagged users can only see notes shared with your organization. How do you want
                    to continue?
                </DialogContentText>
            </DialogContent>

            <DialogActions
                sx={{ justifyContent: 'center', flexDirection: 'column', alignItems: 'stretch' }}
            >
                <Button
                    aria-label="keep-tags-and-post-note-to-organization"
                    data-trackid="private-tag-warning-keep-tags"
                    variant="contained"
                    onClick={keepTagsAndPostNoteToOrganization}
                    disabled={isLoading}
                >
                    Keep Tags and Post Note to Organization
                </Button>
                <Button
                    aria-label="remove-tags-and-post-private-note"
                    data-trackid="private-tag-warning-remove-tags"
                    variant="outlined"
                    onClick={removeTagsAndPostPrivateNote}
                    disabled={isLoading}
                >
                    Remove Tags and Post as a Private Note
                </Button>

                <Button
                    aria-label="cancel-change-note-privacy-button"
                    data-trackid="private-tag-warning-cancel"
                    variant="text"
                    onClick={onClose}
                    disabled={isLoading}
                    sx={{ color: '#000' }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

MentionPrivacyDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    removeTagsAndPostPrivateNote: PropTypes.func.isRequired,
    keepTagsAndPostNoteToOrganization: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
