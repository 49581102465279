import { useCallback } from 'react';

import { useUpdateContactMutation } from 'services/contacts';

export function useContactUpdate() {
    const [updateContact] = useUpdateContactMutation();

    //TODO address sorting across categories and contact types. i.e. if (dst === src && srcIndex !== dstIndex)
    const updateFunction = useCallback(
        async (args, toast, onProcessing) => {
            const { dst, src, contactId } = args;
            if (dst !== src) {
                const params = { category: dst };
                onProcessing?.(true);
                const res = await updateContact({ contactId, params });
                if (res?.error)
                    toast?.error(
                        `Could not reassign to the '${params.category}' category. Please try again soon`
                    );
                if (!res?.error) toast?.success('Successfully reassigned');
                onProcessing?.(false);
            }
        },
        [updateContact]
    );

    return updateFunction;
}
